<app-patient-tabs *ngIf="currTab" [resetToFirst]="resetToFirst" [ActiveTabId]="currTab" [patientID]="patientID"
(addPatientData)="addPatientFrmTab($event)" (ActivePatientTab)="selectedPatTab($event)" [currTab]="currTab"
[patientInfo]="patientInfo$ | async">
</app-patient-tabs>

<div class="editpatient--body newrx--body edit--drug insurance--body ">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-7 padding-0">
                <div class="row">
                    <div class="icon">
                        <span class="prescriber-icon">
                            <i class="far fa-user-md"></i>
                        </span>
                    </div>
                    <div class="content--heading__title col-10 p-0 text--ellipsis" *ngIf="patientID">
                        {{headerEnum[[currTab]]}} |
                        <span class="content--name">
                            {{
                            (patientInfo$ | async)?.Person?.LastName | uppercase
                            }},&nbsp;
                            {{(patientInfo$ | async)?.Person?.FirstName | uppercase }}
                        </span>
                    </div>
                    <div class="content--heading__title" *ngIf="!patientID">
                        {{headerEnum[[currTab]]}} |
                        <span class="content--name"> Add New Patient</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 edit-patient--group--icon padding-0">
                <div class="modal-icon">
                    <div class="insurance--group--icon padding-0" *ngIf="patientDetailsUpdates">
                        <app-common-header [patientInfo]="patientInfo$ | async" (closePopupModal)="onCancel('update')" (getPatData)="getPatinetInfo()" [originalPatInfo]="originalPatInfo"
                         [patientId]="patientID" [patientInfoFG]="patientInfoFG" [Insurances]="insuranceData$ | async" [systemData]="systemData$ | async"
                         (openEligibilityPopUp)="checkAndOpenEligibility()"></app-common-header>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-2 padding-0">
                <div>
                    <button class="hotkey_success" id="SaveButton" title="Alt+S" appShortcutKey [AltKey]="'s'"
                        (click)="addOrUpdatePatientInfo()">
                        <span>S</span>
                        Save
                    </button>
                    <button class="hotkey_success mr-0" title="Alt+C" appShortcutKey [AltKey]="'c'"
                         (click)="onCancel('cancel')">
                        <span>C</span> Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div [ngSwitch]="currTab">
        <div *ngSwitchCase="tabEnum.Patient1">
            <app-patient-info  [focusId]="focusField" [PatInsuId]="patientInfoFG.value['Insurances']['InsurerId']" [formGroupInvalid]="formGroupInvalid" [FrmFamily]="FrmFamily" [patientInfo]="patientInfo$ | async" (After340bInsurancePopupClose)="closePopUpFor340BInsurance($event)" (Exist340bInsurancePopupClose)="exist340bInsurancePopupClose($event)"
                [systemData]="systemData$ | async" [patientInfoFG]="patientInfoFG" (emitPreferred)="savePreferred($event)"
                (GetandPatchPatientInfo)="getPatinetInfo($event)" (AddtnlInsurncTab)="addtnlInsurncTab($event)" [EditPatInfo] = "originalPatInfo"
                [patientId]="patientID" [insuranceList]="insuranceData$ | async" [isFromDashboard]="isFromDashboard"></app-patient-info>
        </div>
        <div *ngSwitchCase="tabEnum.Patient2">
            <app-additional-insurance [patientInfo]="patientInfo$ | async" [systemData]="systemData$ | async" [IsNavigateFromPatientInfoTab]="isNavigateFromPatientInfoTab" [IsNavigateFromPatTabForRefresh]=""isNavigateFromPatTabForRefresh [editInsuranceData]="editInsuranceData"
                [patientInfoFG]="patientInfoFG" [patientInsurances]="patientInsuList$ | async"
                [insuranceData]="insuranceData$ | async"></app-additional-insurance>
        </div>
        <div *ngSwitchCase="tabEnum.Patient3">
            <app-more-patient-info [focusId]="focusField" [systemData]="systemData$ | async" [patientId]="patientID" [morePatFG]="morePatFG" [morePatInfo]="morePatInfo" [patientInfoFG]="patientInfoFG"  [formGroupInvalid]="formGroupInvalid">
            </app-more-patient-info>
        </div>
        <div *ngSwitchCase="tabEnum.Patient4">
            <app-facility-info  [focusId]="focusField" [patientId]="patientID" [facilityFG]="patFacilityFG" [facilityInfo]="facilityInfo$ | async">
            </app-facility-info>
        </div>
        <div *ngSwitchCase="tabEnum.Patient5">
            <app-diagnosis-details [patientId]="patientID" [patientFG]="patientInfoFG"></app-diagnosis-details>
        </div>
        <div *ngSwitchCase="tabEnum.Patient6">
            <app-delivery-address [patientInfoFG]="patientInfoFG" [patientId]="patientID"></app-delivery-address>
        </div>
        <div *ngSwitchCase="tabEnum.Patient7">
            <app-notes-log [PatientInfo]="patientInfo$ | async" [NotesFr]="'Patient'"></app-notes-log>
        </div>
        <div *ngSwitchCase="tabEnum.Patient8">
            <app-document-ref [documentFr]="{From: 'Patient', Id: this.patientID}" (RefreshParentEntity)="getPatinetInfo('Patient8',true)"></app-document-ref>
        </div>
        <div *ngSwitchCase="tabEnum.Patient9">
            <app-clinical-info [patientID]="patientID" [systemData]="systemData$ | async"></app-clinical-info>
        </div>
        <div *ngSwitchCase="tabEnum.Patient10">
            <app-custom-category [patientId]="patientID"></app-custom-category>
        </div>
        <div *ngSwitchCase="tabEnum.Patient11">
            <app-life-style-concept [patientId]="patientID"></app-life-style-concept>
        </div>
        <div *ngSwitchCase="tabEnum.Patient12">
            <app-messaging [patientInfoFG]="patientInfoFG" ></app-messaging>
        </div>
        <div *ngSwitchCase="tabEnum.Patient13">
            <app-track-patient [patientId]="patientID" [patientFG]="patientInfoFG"></app-track-patient>
        </div>
    </div>


    <ng-template #UNIQUEINSURNUMBER let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Warning!!</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close"
                (click)="resetPolicyNumber(); d('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Already Policy No / Insurance ID exists. </p>
        </div>
        <div class="modal-footer">
            <button autofocus type="button" class="hotkey_primary" (click)="resetPolicyNumber(); d('Cross click')"
                (keydown.enter)="resetPolicyNumber(); d('Cross click')" appShortcutKey InputKey="o"><b>O</b> Ok</button>
        </div>
    </ng-template>

    <app-diagnosis-details *ngIf="openModal === 'DIAGNOSIS'" (IsPopUpClosed)="openDiagnosis($event)"
        [OpenDiagPopUp]="true" [patientId]="patientID" [patientFG]="patientInfoFG"></app-diagnosis-details>

    <app-pat-allergies *ngIf="openModal === 'ALLERGIES'" (IsPopUpClosed)="openDiagnosis($event)"
        [patientFG]="patientInfoFG" [openModal]="true" [systemData]="systemData$ | async"></app-pat-allergies>

<app-cancel-pop-up *ngIf="popUpType === 'cancel'" (IsPopUpClosed)="onCancel($event)"></app-cancel-pop-up>

<ng-template #warning let-c="close" let-d="dismiss">
    <app-custom-modal (oncrossBtnClick)="c('Close click');resetCurrTab()">
            <h4 header>Patient</h4>
            <span body>Please save Patient record first.
            </span>
            <button footer autofocus  type="button" class="hotkey_primary" (click)="c('Close click');" (click)="checkndAddorUpdatePatient(patientID? 'UPDATE' : 'ADD')" appShortcutKey InputKey="y"> <b>Y</b>YES</button>
            <button footer type="button" class="hotkey_primary" (click)="c('Close click');resetCurrTab()" appShortcutKey InputKey="n"> <b>N</b> NO</button>
        </app-custom-modal>

</ng-template>

<ng-template #PATALGER let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Missing Allergies</h4>
        <button type="button" class="close" aria-label="Close"
            (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Patient allergies required. Please specify any known allergies to the patient.</p>
    </div>
    <div class="modal-footer">
        <a (click)="openModal = 'ALLERGIES';d('Cross click')">
            <button class="hotkey_success hotkey_primary--Override" autofocus title="Alt+A" appShortcutKey
                [AltKey]="'a'"><span>A</span>ADD ALLERGIES</button>
        </a>
        <a (click)="d('Cross click')">
            <button class="hotkey_success hotkey_primary--Override" title="Alt+C" appShortcutKey
                [AltKey]="'c'"><span>C</span>Cancel</button>
        </a>
    </div>
</ng-template>
