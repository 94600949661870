import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import * as _ from "lodash";
import { InsuranceService } from "src/app/services";
import { FormGroup } from "@angular/forms";
import * as wjcCore from "@grapecity/wijmo";
import { NRxUtils, WijimoUtil } from "src/app/utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SortByProp } from "src/app/pipes";
@Component({
    selector: "app-insurance-list",
    templateUrl: "./insurance-list.component.html",
    styles: []
})
export class InsuranceListComponent implements OnInit {
    insureList: any;
    checkedInsurance: any;
    wjHeaders: any;
    @Input() patInsurList: any;
    @Input() erxObj: FormGroup;
    @Input() payorIdsFrmFG: any;
    @Input() payorList: any;
    @Input() forPayor: boolean;
    @Input() inActInsu: boolean;
    insurncListModified: CollectionView;
    primaryInsuFromErxtoRequest: any;
    insuranceObject: any = {};
    @Output() closeInusranceModal = new EventEmitter();
    @Output() closePayorModal = new EventEmitter();
    prevSearchValue: any;
    optedPayorList: any[];
    payorWJ: CollectionView;
    activeHeadersIns: string[];
    payorModelRef: any;
    isCheckedInsurance = false;
    tertCheck = false;
    secCheck = false;
    primCheck = false;
    cashCheck = false;
    optedData: any;
    optedDataInsurance: any;
    count = 0;
    disabled: boolean;

    @ViewChild("payorPopUp", { static: true })
    payorPopUp: any;


    constructor(private _cdr: ChangeDetectorRef, private _insServ: InsuranceService,
         private _nrxUtls: NRxUtils, private _modalSvc: NgbModal, private _wijimoUtils: WijimoUtil) { }

    ngOnInit() {
        if (this.forPayor) {
            this.openPayorPOPUP();
        } else {
            this.openBillToPopupModal();
        }
        this.patchDefaultValHeaders();
    }

    openBillToPopupModal() {
        this.insureList = _.clone(this.patInsurList);
        this.insureList = this._nrxUtls.getSortedInusuDtls(this.insureList);
        this.generateBucketListWJ();
        this._cdr.detectChanges();
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("insuranceListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("insuranceListWJ", storedWJ);
    }

    generateBucketListWJ() {
        this.insurncListModified = new wjcCore.CollectionView(
            this.insureList.map((item, i) => {
                const j = {};
                j["Ins Code"] = item.insuType.toUpperCase();
                j["Insurance Name"] = item.insurancename.toUpperCase();
                j["Insuranceid"] = item.insuranceid;
                j["Insurance Id#"] = (item && item.policynum )? item.policynum : "";
                j["Bill Order"] = (item && item.InsuPriority) ? item.InsuPriority.toUpperCase() : "";
                j["Bin#"] =j["BIN"] =( item && item.binnum) ? item.binnum : "";
                j["PCN"] = (item && item.ProcessorNum) ? item.ProcessorNum : "";
                j["Active Status"] = item.insurerActiveStatus;
                j["groupName"] = item && item.groupname ? item.groupname : "";
                return j;
            })
        );
    }

    selectedInsurence(isChecked: boolean, checkedVal: any, id, item) {
        if (item && item["Ins Code"]) {
            this.erxObj.controls["insuranceItem"].patchValue(item["Ins Code"]);
            this.erxObj.controls["policynum"].patchValue(item["Insurance Id#"]);
            this.erxObj.controls["InsuranceId"].patchValue(item["Insuranceid"]);
            this.erxObj.controls["groupname"].patchValue(item["groupname"]);
            this.insuranceObject = { InsuranceId: this.erxObj.value["InsuranceId"],
             insuType: this.erxObj.value["insuranceItem"], policynum: this.erxObj.value["policynum"],
            groupname: this.erxObj.value["groupname"] };
        }
        if (isChecked) {
            this.checkedInsurance = checkedVal;
            this.getInsuSettings(id);
        }
    }

    async getInsuSettings(id) {
        const insuDetails = await this._insServ.getInsSettingsInfo(id).toPromise();
        this._insServ.saveInsuSettings(insuDetails);
    }

    closeBillModal(isCloseTypeModal: boolean = false) {
        if (isCloseTypeModal) {
            this.closeInusranceModal.emit(this.insuranceObject);
        } else {
            this.closeInusranceModal.emit(false);
        }

    }

// payor code starting

    openPayorPOPUP() {
        this.optedPayorList = Object.assign([], this.payorIdsFrmFG) || [];
        this.prevSearchValue = null;
        this.generatePayorWJ(this.payorList || []);
        if (this.payorList && this.payorList.length) {
            this.payorModelRef = this._modalSvc.open(this.payorPopUp, {
                centered: true, size: "lg",
                windowClass: "md-x-lg", backdrop: false
            });
        }
    }

    generatePayorWJ(data: any) {
        this.payorWJ = new CollectionView(
            data.map(item => {
                const k = {};
                k["Id"] = item["PayorId"];
                k["Payor Ins ID"] = item["PayorInsId"];
                k["Payor Name"] = item["PayorName"];
                k["Contact Name"] = item["ContactName"];
                k["Address"] = item["Address1"];
                k["City"] = item["CityName"];
                k["State"] = item["StateName"];
                k["Zip"] = item["ZipCode"] + (item["ZipExtension"] ? ("-" + item["ZipExtension"]) : "");
                k["Phone"] = item["PhoneNum"];
                k["Fax"] = item["FaxNum"];
                k["Notes"] = item["PayorNotes"];
                k["RowId"] = item["PayorId"];
                k["IsSelected"] = false;
                if (this.optedPayorList && this.optedPayorList.length) {
                    k["IsSelected"] = this.optedPayorList.indexOf(+k["Id"]) > -1 ? true : false;
                }
                return k;
            })
        );
        this.activeHeadersIns = [
            "Payor Ins ID",
            "Payor Name",
            "Address"
        ];
    }

    selectCode(item, event) {
        if ((!item["IsSelected"] || (event && event.target.checked))) {
          item["IsSelected"] = !item["IsSelected"];
          this.optedPayorList = this.optedPayorList || [];
          this.optedPayorList.push(item["Id"]);
        } else {
          item["IsSelected"] = !item["IsSelected"];
          const index = this.optedPayorList.findIndex(val => val === item["Id"]);
          this.optedPayorList.splice(index, 1);
        }
      }

    selectedPayor() { // after selecting the payors.
        const payorIds = [];
        const payorCodes = [];
        this.optedPayorList.forEach(payorId => {
            const payor = this.payorList.find(item => item["PayorId"] === +payorId) || null;
            if (payor && payor["PayorId"] && payor["PayorInsId"]) {
                payorIds.push(payor["PayorId"]);
                payorCodes.push(payor["PayorInsId"]);
            }
        });
        this.closeInsPopUp({code: payorCodes, Ids: payorIds});
    }



    fiterPayor(value) { // filtering the payor.
        if (value !== this.prevSearchValue) {
            if (value && value.trim() !== "") {
                this.prevSearchValue = value;
                const payorList = Object.assign([], this.payorList);
                const startList1 = new SortByProp().returnFilterList("PayorInsId", payorList, value);
                const startList2 = new SortByProp().returnFilterList("PayorName", payorList, value, "PayorInsId");
                const filteredData = [...startList1, ...startList2];
                this.generatePayorWJ(filteredData);
            } else {
                this.prevSearchValue = null;
                this.generatePayorWJ(this.payorList || []);
            }
        }
    }

    closeInsPopUp(val?: any) { // close the payor select pop up.
        if (this.payorModelRef) {
            this.payorModelRef.close();
            this.payorModelRef = null;
        }
        this.closePayorModal.emit(val ? val : null);
    }

    isCheckedIns(data: any) {
        if (data["Active Status"]) {
            this.isCheckedInsurance = false;
            if (data["Bill Order"].toLowerCase() === "primary" && this.isCheckedInsurance) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.primCheck = true;
                this.optedData = data;
            } else if (data["Bill Order"].toLowerCase() === "secondary" && !this.isCheckedInsurance && !this.primCheck) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.secCheck = true;
                this.optedData = data;
            } else if (data["Bill Order"].toLowerCase() === "tertiary" && !this.isCheckedInsurance && (!this.secCheck && !this.primCheck)) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.tertCheck = true;
                this.optedData = data;
            } else if ((data["Ins Code"].toLowerCase() === "cash" || data["Ins Code"] === "C") &&
             !this.isCheckedInsurance && (!this.tertCheck && !this.secCheck && !this.primCheck)) {
                this.count = 1;
                this.isCheckedInsurance = true;
                this.disabled = false;
                this.optedData = data;
                this.cashCheck = true;
            } else if (!this.isCheckedInsurance && (!this.tertCheck && !this.secCheck && !this.primCheck && !this.cashCheck)) {
                this.count = 1;
                this.disabled = false;
                this.isCheckedInsurance = true;
            }
            return (this.isCheckedInsurance && this.count === 1) ? true : false;
        } else {
            this.isCheckedInsurance = false;
            if (data["Bill Order"].toLowerCase() === "primary" && !this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            } else if (data["Bill Order"].toLowerCase() === "secondary" && !this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            } else if (data["Bill Order"].toLowerCase() === "tertiary" && !this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            } else if (!this.isCheckedInsurance) {
                this.isCheckedInsurance = false;
                this.disabled = true;
            }
            return (!this.isCheckedInsurance) ? this.isCheckedInsurance : false;
        }
    }

    isDisabled(item: any) {
        return (item ? false : true);
    }
}
