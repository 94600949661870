import { Injectable } from "@angular/core";
import { CommonService } from "../services";
import * as moment from "moment";
import { DateCheckUtil } from "./date-checks.util";



@Injectable({
    providedIn: "root"
})
export class StaticVaribles {
    constructor(private _commonServ: CommonService, private _dateCheck: DateCheckUtil) {}

    getRxExpiredClasses() {
      return  ["his-bg--peach__color--white", "his-bg--blue_peach__text--black", "his-bg--peach_grey__text--black", "his-bg--peach_grey__text--black", "his-bg--peach_red__text--black","his-bg--blue_peach__text--black"];
    }

}