import { Injectable } from "@angular/core";
import { State, City } from "../models";
import { PatientService } from "../services";

@Injectable({
    providedIn: "root"
})

export class FamilyUtil {
    constructor(private _patService: PatientService) {}

    setDefaultValues(FG) {
        FG.controls["FamilyAddress"].patchValue({IsActive: true, CountryId: 1});
        FG.controls["PatFamily"].patchValue({IsActive: true});
        FG.controls["FamilyInsurance"].patchValue({ ActiveStatusId: 1, IsPatAssigned: false, InsuGrpId: null});

        if (FG.value["FamilyInsuGrp"]["Name"] !== null) {
            FG.controls["FamilyInsuGrp"].patchValue({IsActive: true});
        }

        if (FG.value["FamilyPhone"]["xPhone"] !== null) {
            FG.controls["FamilyPhone"].patchValue({IsActive: true, PhoneCatId: 3});
        }

        if (FG.value["FamilyInsuCard"]["PolicyNum"] !== null) {
            FG.controls["FamilyInsuCard"].patchValue({IsActive: true});
        }
    }

    // Add Multiple members
    async addMultipleMembers(Members, FamilyId) {
        const valueForLink = [];
        Members.map(val => {
            const MembersSelected = {
                PatientId: val["PatientId"],
                FamilyId: FamilyId,
                IsOverrideGeneralDetails: false,
                IsOverrideInsuranceDetails: false,
                FamilyLastName: null
            };
            valueForLink.push(MembersSelected);
        });
        await this._patService.linkFamilyInfo(valueForLink).toPromise();
    }

    patchCityAndState(data, formGroup) {
        const result = {stateSelected: null, citySelected: null};
        if (data) {
            result["stateSelected"] = new State();
            result["stateSelected"].Id = data["StateId"];
            result["stateSelected"].Name = data["StateName"];
            result["citySelected"] = this.onStateSelected(result["stateSelected"], formGroup);
            result["citySelected"].Id = data["CityId"];
            result["citySelected"].StateId = data["StateId"];
            result["citySelected"].Name = data["CityName"];
            this.onCitySelected(result["citySelected"], formGroup);
        }
        return result;
    }

    onCitySelected(val: any, FG) {
        FG.controls["FamilyAddress"].patchValue({CityId: val.Id, CityName: val.Name});
    }

    onStateSelected(val: any, FG) {
        FG.controls["FamilyAddress"].patchValue({StateId: val.Id, StateName: val.Name});
        const citySelected = new City();
        return citySelected;
    }
}
