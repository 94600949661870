import { EditDrugService } from "./../../../../services/edit-drug.service";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, HostListener } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as wjcChart from "@grapecity/wijmo.chart";

@Component({
    selector: "app-drug-sales-summary",
    templateUrl: "./drug-sales-summary.component.html"
})
export class DrugSalesSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
    drugId: any;
    countries: any = [];
    Xaxis: any = [];
    data: {
        country: string;
        SalesAmount: number;
        SalesDispensed: number;
        SalesReceived: number;
    }[] = [];
    minDate: { year: number; month: number; day: number };
    monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    RxCount = 0;
    Quantity = 0;
    Amount = 0;
    avgRxCount: any;
    avgQty: any;
    avgAmt: any;
    qtyVal: string;
    amt: string;
    salesData: any;
    SalesModal: any;
    drugName: any;
    drugCode: any;
    hasSaleSummary: boolean;
    @Input()
    set DrugId(data: any) {
        this.drugId = data;
    }
    @Input()
    set DrugCode(data: any) {
        this.drugCode = data;
    }
    @Input()
    set DrugName(data: any) {
        this.drugName = data;
    }
    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modalService.hasOpenModals()) { // esc
            this.closePopUp();
            event.preventDefault();
        }
    }


    @ViewChild("sales")
    sales: any;
    constructor(
        private _drg: EditDrugService,
        private modalService: NgbModal,
        private _cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.salesPopUp();
    }
    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
    }
    closePopUp() {
        this.SalesModal.close();
        this.IsPopUpClosed.emit("close");
    }
    init(flex: wjcChart.FlexChart) {
    }
    salesPopUp() {
        this.defaultSalesData();
        this.getSalesData();
    }
    defaultSalesData() {
        const today = new Date();
        const year = today.getFullYear() - 1;
        const Cyear = today.getFullYear();
        for (let i = today.getMonth() - 12 + 12 + 2; i <= 12; i++) {
            if (i <= 9) {
                const date = "01/0" + i + "/" + year;
                this.countries.push(date);
                const monthly =
                    this.monthNames[i - 1].substring(0, 3) +
                    year.toString().substring(2, 4);
                this.Xaxis.push(monthly);
            } else {
                const date = "01/" + i + "/" + year;
                this.countries.push(date);
                const monthly =
                    this.monthNames[i - 1].substring(0, 3) +
                    year.toString().substring(2, 4);
                this.Xaxis.push(monthly);
            }
        }
        for (let i = 1; i <= today.getMonth() + 1; i++) {
            if (i <= 9) {
                const date = "01/0" + i + "/" + Cyear;
                this.countries.push(date);
                const monthly =
                    this.monthNames[i - 1].substring(0, 3) +
                    Cyear.toString().substring(2, 4);
                this.Xaxis.push(monthly);
            } else {
                const date = "01/" + i + "/" + Cyear;
                this.countries.push(date);
                const monthly =
                    this.monthNames[i - 1].substring(0, 3) +
                    Cyear.toString().substring(2, 4);
                this.Xaxis.push(monthly);
            }
        }
    }
    getSalesData() {
        const data = [];
        for (let i = 0; i < this.countries.length; i++) {
            data.push({
                country: this.Xaxis[i],
                SalesAmount: 0,
                SalesDispensed: 0,
                SalesReceived: 0
            });
        }
        this._drg.getSalesSummary(this.drugId).subscribe(resp => {
            if (resp && resp["length"] > 0) {
                this.salesData = resp;
                for (const key in this.salesData) {
                    if (key) {
                        this.RxCount = this.RxCount + this.salesData[key].Rxcount;
                        this.Quantity = this.Quantity + this.salesData[key].DispQty;
                        this.Amount = this.Amount + this.salesData[key].SalesAmount;
                        for (let i = 0; i < this.countries.length; i++) {
                            if (
                                this.countries[i] === this.salesData[key].Datetime
                            ) {
                                data[i].SalesAmount = this.salesData[
                                    key
                                ].SalesAmount;
                                data[i].SalesDispensed = this.salesData[
                                    key
                                ].DispQty;
                            }
                        }
                    }
                }
                this.qtyVal = this.Quantity.toFixed(2);
                this.amt = this.Amount.toFixed(2);
                this.avgQty = (this.Quantity / 12).toFixed(2);
                this.avgAmt = (this.Amount / 12).toFixed(2);
                this.avgRxCount = (this.RxCount / 12).toFixed(0);
                this.data = data;
                this.hasSaleSummary = true;
                this.countries = [];
                this.Xaxis = [];
            } else {
                this.hasSaleSummary = false;
            }
            this.SalesModal = this.modalService.open(this.sales, {
                centered: true,
                size: "lg",
                keyboard:false
            });
            });
    }
}
