export class SigInfo {
    Id: number = null;
    TenantId: number = null;
    SigCode: string = null;
    DoseSchId: number = null;
    DailyDose: number = null;
    Priority: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}
export class SigDesc {
    Id: number = null;
    TenantId: number = null;
    SigId: number = null;
    LangId: number = null;
    LangCode: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}
