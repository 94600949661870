import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "PendingTransLength" })
export class PendTransLength implements PipeTransform {
    transform(data) {
        const trans = data.filter(i => {
            return i.TransmissionStatus !== 1 && i.TransmissionStatus !== 2;
        });
        if (trans) {
            return trans.length;
        } else {
            return 0;
        }
    }
}
