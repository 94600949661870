<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        <ng-content select="[header]"></ng-content>
        <button type= button [tabindex]="-1" class="close"
            aria-label="close" (click)="closeModal()" *ngIf="!hideCross">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </h4>
</div>
<div class="modal-body" [ngClass]=" applyCustmCSS? 'modal--body_height max--height_55' : ''">
    <div class="eprx--block__content">
        <ng-content select="[body]"></ng-content>
    </div>
</div>
<div class="modal-footer" *ngIf="!hideFooter">
    <ng-content select="[footer]"></ng-content>
</div>
