import { RxUtils } from "src/app/utils/rx.util";
import { Subscription } from "rxjs";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { Drug } from "../../../models";
import { FormGroup, FormBuilder } from "@angular/forms";
import { EditDrugService } from "../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-drug-price-update",
    templateUrl: "./drug-price-update.component.html"
})
export class DrugPriceUpdateComponent implements OnInit {
    drugInfo: Drug;
    drugName: string;
    drugPUWJ: CollectionView;
    actvHeaders: string[];
    drugId: number;
    drugPriceFG: FormGroup;
    Size = 15;
    pageNu = 1;
    hideFilterForm = false;
    fromDate: null;
    toDate: null;
    totalCount: number;
    hasData = false;
    hasContent = true;
    @ViewChild("priceUpdateList")
    flex: wjcGrid.FlexGrid;
    today: any;
    minDate: any;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
           if (event.which === 67) { // c
                event.preventDefault();
                this.routeBackToPrevScreen();
            }
        }
    }

    constructor(
        private _drg: EditDrugService,
        private _fb: FormBuilder,
        private _actvRoute: ActivatedRoute,
        private _rxutils: RxUtils,
        private _modalService: NgbModal
    ) {
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (params && params.fm) {
                    this.routeFrom = params.fm;
                }
                if (params && params.focus) {
                    if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                        this.focusOnDU = params.focus;
                    }
                }
            }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(params => {
            this.drugId = +params["pid"];
        }));
    }

    ngOnInit() {
        this.today = moment().format("MM/DD/YYYY");
        this.drugPriceFG = this._fb.group({
            DrugId: this.drugId,
            Pagenumber: this.pageNu,
            Pagesize: this.Size,
            FromDateTime: this.today,
            ToDateTime: this.today
        });
        this.filter();
    }

    show() {
        this.hideFilterForm = !this.hideFilterForm;
        this.drugPriceFG.controls["FromDateTime"].patchValue(this.today);
        this.drugPriceFG.controls["ToDateTime"].patchValue(this.today);
    }

    filter() {
        this.fromDate = this.drugPriceFG.value["FromDateTime"];
        this.toDate = this.drugPriceFG.value["ToDateTime"];
        this.getInfo();
    }
    getInfo() {
        this._drg.getDrugPriceUpdate(this.drugPriceFG.value).subscribe(resp => {
            if (resp && resp["length"] > 0) {
                this.hasData = true;
                this.hasContent = true;
                this.generateWJ(resp);
            } else {
                this.totalCount = 0;
                this.hasContent = false;
            }
        });
    }
    setPage(page: number) {
        this.pageNu = page;
        const dataInfo = this.drugPriceFG.value;
        dataInfo.Pagenumber = page;
        this.getInfo();
    }
    setSize(size: any) {
        this.pageNu=1
        this.Size = size;
        const dataInfo = this.drugPriceFG.value;
        dataInfo.Pagesize = size;
        dataInfo.Pagenumber = 1;
        this.getInfo();
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        for (let i = 0; i < flex.columns.length; i++) {
            flex.columns[i].align = "center";
        }
    }
    generateWJ(info: any) {
        if (info) {
            this.totalCount = info[0].TotalCount;
            const mapDataSet=[];
                info.map((priceUpdate, i) => {
                    const j = {};
                    j["New Price"] = priceUpdate.NewPrice;
                    j["Date Updated"] = moment(priceUpdate.ModifiedDtTm).format(
                        "MM/DD/YY h:mm:ss a"
                    );
                    j["Manuf. Update Date"] = moment.utc(
                        priceUpdate.UpdateDtTm
                    ).local().format("MM/DD/YY h:mm:ss a");
                    if (priceUpdate.PriceCatId === 1) {
                        j["Type"] = "AWP PACK";
                    } else if (priceUpdate.PriceCatId === 2) {
                        j["Type"] = "COST PACK";
                    } else if (priceUpdate.PriceCatId === 3) {
                        j["Type"] = "UNIT PRICE DIR";
                    }
                    j["Updated By"] = "MANUAL";
                    j["Old Price"] = priceUpdate.OldPrice;
                    j["Update History Id"] = (priceUpdate.Id).toString();
                    // return j;
                    mapDataSet.push(j);
                });
                    this.drugPUWJ = new CollectionView(mapDataSet,
                        {
                           sortComparer: (a: any, b: any) => {
                                return moment(a).isValid() && moment(b).isValid()
                                    ? moment(a).diff(moment(b)) : null;
                            }
                        });
                
              this.actvHeaders = [
                "Date Updated",
                "New Price",
                "Old Price",
                "Type",
                "Manuf. Update Date",
                "Updated By",
            ];
        }
    }
    exportExcel() {
        wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
            this.flex,
            {formatItem: (args) =>  args.xlsxCell.formula = null},
            "drugPriceUpdate.xlsx"
        );
    }
    routeBackToPrevScreen() {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
      //  this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
}
