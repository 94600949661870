import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from "@angular/core";
import { RxBillingCalculationInput } from "src/app/models/rx-billing-calculation.model";
import { Subject } from "rxjs";
import { RxService, CommonService, InsuranceService, AlertService } from "src/app/services";
import { BaseUtils } from "src/app/utils/base.utils";
import { UdpCurrencyMaskPipe } from "src/app/pipes/number-format.pipe";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NRxSaveUtils } from "src/app/utils";
import { MsgConfig } from "src/app";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-bill-cash-pop-up",
    templateUrl: "./bill-cash-pop-up.component.html",
    styles: []
})
export class BillCashPopUpComponent implements OnInit {
    priceScheduleList: any[];
    rxInfo: any;
    insuSettings: any;
    totalPriceForCash: number;
    numberPipe = new UdpCurrencyMaskPipe();
    routeFrom: any;
    isFrmTransHistory: boolean;
    isChangeToCash: boolean;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxInfo(ri: any) {
        if (ri) {
            this.rxInfo = ri;
        }
    }

    @Input()
    set RXFG(ri: FormGroup) {
        if (ri) {
            this.rxInfo = ri.getRawValue();
        }
    }


    @Input()
    set IsFrmTransHistory(ri: boolean) {
        this.isFrmTransHistory = ri;
    }

    @Input()
    set IsChangeToCash(is: string) {
        if (is === "true") {
            this.isChangeToCash = true;
        }
    }

    @Output()
    IsBillToCashPopUpClosed = new EventEmitter<any>();

    @Output()
    CancelCashPopUp = new EventEmitter<any>();

    constructor(private _rxService: RxService, private _baseUtils: BaseUtils,
         private _cdr: ChangeDetectorRef, private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService, private _insurService: InsuranceService,
         private _alertServ: AlertService, private _nrxSaveUtil: NRxSaveUtils) {
                this._actvRoute.queryParams
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(params => {
                    if (Object.keys(params).length > 0) {
                        this.routeFrom = params.fm;
                    }
                })
        }

    ngOnInit() {
            this._commonServ.priceSchedule$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp =>
                resp && resp.PriceScheduleList
                    ? (this.priceScheduleList = resp.PriceScheduleList)
                    : (this.priceScheduleList = [])
            )

        this._insurService.insuSettings$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.InsuPlanPrice) {
                this.insuSettings = resp;
            }
        })
    }

    getInsuranceSettings() {
        let insuList = null;
        insuList = this.rxInfo.Patient.insurance.filter((ins: any) =>
            ins.insuranceid === this.rxInfo.RefillInsu.InsuId)[0];
        if(insuList.insuplanId)
             this._insurService
            .getInsSettingsInfoForRx(insuList.insuplanId);
        this.triggerPriceSchedule();
    }

    triggerPriceSchdlValue(e) {
        this.rxInfo.PrescReFill.PriceSchId = e.value.Id;
        this.triggerPriceSchedule("PriceSch");
        this._cdr.detectChanges();
    }

    triggerPriceSchedule(type?: any) {
        // const PrescRefillFG: any = this.rxInfo.["PrescReFill"];

        if (this.rxInfo.PrescReFill.DispQty && +this.rxInfo.PrescReFill.DispQty !== 0) {
            const inputData = new RxBillingCalculationInput();
            inputData.Discount = 0; // Todo: pavan This needs to be filled from other amt popup
            inputData.DrugId = this.rxInfo.Drug.id;
            inputData.OtherAmount = 0; // Todo: pavan This needs to be filled from other amt popup
            inputData.PriceScheduleId = this.rxInfo.PrescReFill.PriceSchId;
            inputData.QuantityDispense = +this.rxInfo.PrescReFill.DispQty;
            inputData.BucketId = +this.rxInfo.PrescReFill.BucketId;
            if (type === "PriceSch") {
                inputData.TypeOfCalculation = "PriceScheduleId";
            } else {
                inputData.TypeOfCalculation = "Drug";
            }
            inputData.PatientId = this.rxInfo.Patient.patientid;
            inputData.InsuranceId = this.rxInfo.RefillInsu.InsuId;
            if (inputData.QuantityDispense) {
                    this._rxService
                        .getPricingDetails(inputData)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((resp: any) => {
                            if (resp) {
                                this.rxInfo["pricingValues"] = resp;
                                if (type !== "PriceSch") {
                                    this.rxInfo.PrescReFill.PriceSchId = +resp.PriceSchId;
                                }
                                this.rxInfo.PrescReFill.Price = resp.Price ? resp.Price : "0.00";

                                this.rxInfo.PrescReFill.BalDue =
                                    resp.BalDue ? resp.BalDue : "0.00";

                                this.rxInfo.PrescReFill.ProfFee =
                                    resp.ProFee ? resp.ProFee : (0);

                                this.rxInfo.PrescReFill.TotalAmt =
                                    resp.TotalAwp
                                        ? resp.TotalAwp
                                        : "0.00";

                                let AWP = null;
                                let rxCost = null;
                                if (this.rxInfo && this.rxInfo.PrescReFill.DispQty) {
                                    AWP =
                                        +this.rxInfo.PrescReFill.DispQty *
                                        this.rxInfo.Drug.unitpriceawp;
                                    this.rxInfo.PrescReFill[
                                        "UnitPriceAWP"
                                    ] = AWP ? AWP : "0.00";
                                    Object.assign(this.rxInfo["pricingValues"], {Awp: parseFloat(this.rxInfo.Drug.unitpriceawp)});
                                    rxCost = (+this.rxInfo.PrescReFill.DispQty *
                                        this.rxInfo.Drug.unitpricecost);
                                    this.rxInfo.PrescReFill[
                                        "TotalRxCost"
                                    ] = rxCost ? rxCost : "0.00";
                                } else if (this.rxInfo) {
                                    this.rxInfo.PrescReFill["UnitPriceAWP"] = "0.00";
                                    this.rxInfo.PrescReFill["TotalRxCost"] = "0.00";
                                }
                                this.rxInfo.PrescReFill.UnitPriceUNC =
                                    resp ? resp.UAndC : "0.00";

                                // sessionStorage.setItem("RxForQuick", JSON.stringify(this.rxFG.getRawValue()));
                                this._cdr.detectChanges();
                                this.calculateSalesTax();
                            } else {
                                if (type !== "PriceSch") {
                                    if (
                                        this.priceScheduleList &&
                                        this.priceScheduleList.length > 0
                                    ) {
                                        this.rxInfo.PrescReFill.PriceSchId =
                                            this.priceScheduleList[0].Id;
                                    }
                                }
                                this.rxInfo.PrescReFill.Price = "0.00";
                                this.rxInfo.PrescReFill.BalDue = "0.00";
                                this.rxInfo.PrescReFill.ProfFee = "0.00";
                                this.rxInfo.PrescReFill.TotalAmt = "0.00";
                                this.rxInfo.PrescReFill.UnitPriceAWP = "0.00";
                                this.rxInfo.PrescReFill.UnitPriceUNC = "0.00";
                                this.rxInfo.PrescReFill.OtherAmnt = "0.00";
                            }
                        })
            }
        } else {
            this.rxInfo.PrescReFill.Price = "0.00";
            this.rxInfo.PrescReFill.BalDue = "0.00";
            this.rxInfo.PrescReFill.ProfFee = "0.00";
            this.rxInfo.PrescReFill.TotalAmt = "0.00";
            this.rxInfo.PrescReFill.UnitPriceAWP = "0.00";
            this.rxInfo.PrescReFill.TotalRxCost = "0.00";
            this.rxInfo.PrescReFill.UnitPriceUNC = "0.00";
            this.rxInfo.PrescReFill.OtherAmnt = "0.00";
        }
        this._cdr.detectChanges();
    }

    calculateSalesTax() {
        const otherAmount: any = this.rxInfo.RxOtherAmount;
        if (otherAmount && otherAmount.value && !(+otherAmount.value.SalesTax)) {
            if (this.insuSettings && this.insuSettings.InsuPlanPrice.IsTaxOnRx && this.insuSettings.InsuPlanPrice.SalesTaxBasisId === 2) {
                otherAmount.controls["SalesTax"].setValue(
                    (
                        ((this.rxInfo.PrescReFill.Price
                            ? +this.rxInfo.PrescReFill.Price
                            : 0.0) *
                            (this.insuSettings.InsuPlanPrice.TaxRatePer
                                ? +this.insuSettings.InsuPlanPrice.TaxRatePer
                                : 0.0)) /
                        100
                    )
                );
            } else if (this.insuSettings && this.insuSettings.InsuPlanPrice.IsTaxOnRx && (this.insuSettings.InsuPlanPrice.SalesTaxBasisId === 1 ||
                this.insuSettings.InsuPlanPrice.SalesTaxBasisId === 3)) {
                otherAmount.controls["SalesTax"].setValue(
                    (
                        (((this.rxInfo.PrescReFill.Price
                            ? +this.rxInfo.PrescReFill.Price
                            : 0.0) + (this.rxInfo.PrescReFill.ProfFee
                                ? +this.rxInfo.PrescReFill.ProfFee
                                : 0.0)) *
                            (this.insuSettings.InsuPlanPrice.TaxRatePer
                                ? +this.insuSettings.InsuPlanPrice.TaxRatePer
                                : 0.0)) /
                        100
                    )
                );
            } else {
                otherAmount.controls["SalesTax"].setValue("0.00");
            }
        }
        this._cdr.detectChanges();
        this.calOtherAmount();
    }

    calOtherAmount() {
        this.initiateManualCal();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initiateManualCal() {
        let rxOthAmnt = 0;
        // const PrescRefillFG: any = this.rxFG.controls["PrescReFill"];

        const rxOtherAmnt = this.rxInfo.RxOtherAmount;
        if (this.rxInfo.RxOtherAmount) {
            rxOthAmnt =
                (rxOtherAmnt.ProfFee ? +rxOtherAmnt.ProfFee : 0)
                + (rxOtherAmnt.Incentive ? +rxOtherAmnt.Incentive : 0)
                + (rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0)
                + (rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0);
            if (rxOtherAmnt.OtherAmntMisc && rxOtherAmnt.OtherAmntMisc.length > 0) {
                rxOtherAmnt.OtherAmntMisc.map(misc => {
                    if (misc && misc.Amount) {
                        rxOthAmnt = rxOthAmnt + (+misc.Amount);
                    }
                });
            }
        }

        this.rxInfo.PrescReFill.OtherAmnt = rxOthAmnt;
        this.rxInfo.PrescReFill.TotalAmt = (+((+rxOthAmnt -
            (this.rxInfo.RxOtherAmount.FlatSalesTax ? +this.rxInfo.RxOtherAmount.FlatSalesTax : 0)) +
            (this.rxInfo.PrescReFill.Price ? +this.rxInfo.PrescReFill.Price : 0) +
            (this.rxInfo.PrescReFill.ProfFee ? +this.rxInfo.PrescReFill.ProfFee : 0)));

        const totalAmountForPopup = (this.rxInfo.PrescReFill.ProfFee ? +this.rxInfo.PrescReFill.ProfFee : 0)
            + (this.rxInfo.PrescReFill.Price ? +this.rxInfo.PrescReFill.Price : 0);
        this.totalPriceForCash = +(+totalAmountForPopup);

        const balDue = (this.rxInfo.PrescReFill.TotalAmt ? +this.rxInfo.PrescReFill.TotalAmt : 0.00) -
            (this.rxInfo.PrescReFill.Discount ? +this.rxInfo.PrescReFill.Discount : 0);

        this.rxInfo.PrescReFill.BalDue = balDue > 0 ? balDue : 0.00;
        // sessionStorage.setItem("RxForQuick", JSON.stringify(this.rxInfo));
        // this.calculationComplete.emit(true);
        this._cdr.detectChanges();
    }

    keytab(event: any, fg?: any, fc?: string) {
        this._baseUtils.keytab(event, true);
    }

    setNumberFormat(ControlName, event) {
        this.rxInfo.PrescReFill[ControlName] = this.numberPipe.transform(parseFloat(event.value), 2);
    }

    crossCheckIngredientCost(val) {
        this.initiateManualCal();
    }

    focusOnSaveButton(e) {
        e.preventDefault();
        document.getElementById("saveCnfmBillCash").focus();
    }

    focusOnPriceCodeForCashCnfm(e) {
        e.preventDefault();
        document.getElementById(this.RXFG.value["rxType"] === "er" ? "actionInputer" : "actionInput").focus();
        // document.getElementById("cnfmrxPriceSchId").getElementsByTagName("input")[0].focus();
    }

    cancelSaveCashRx() {
        this.CancelCashPopUp.emit();
    }

    async saveCashInfo() {
        if (!(this.rxInfo["Prescription"]["RxStatusId"] === 2 || this.rxInfo["PrescReFill"]["StatusId"] === 2)) {
            this.rxInfo["RxFile"] = null;
        }
        const resp = await this._nrxSaveUtil.updateRxInfo(this.rxInfo);
        if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
        } else if (resp && isNaN(+resp.Message)) {
            if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                this._alertServ.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
            } else if (typeof resp.Message === "string") {
            this._alertServ.error(resp.Message);
            }
        } else {
            this._alertServ.error("Unable to convert Insurance to Cash.");
        }
        this.IsBillToCashPopUpClosed.emit(this.rxInfo);
    }

    saveCashInfoForNewRx() {
        this.IsBillToCashPopUpClosed.emit(this.rxInfo);
    }
}
