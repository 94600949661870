import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-somthng-wrong",
    templateUrl: "./somthng-wrong.component.html",
    styles: []
})
export class SomthngWrongComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
