<div class="modal-header">
        <h4 class="modal-title">Stock Qty By Bucket </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body exprx--common-block erx--body edit--drug newrx--body">
        <wj-flex-grid  #BucketListGrid [headersVisibility]="'Column'" [isReadOnly]="true" [columnPicker]="'bucketListDisplaywj'" [itemsSource]="BucketListDisplayWJ"
            [selectionMode]="'Row'">
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders;"
                [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <div>
                            {{ item[rxheader['hName']] }}
                        </div>
                    </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
        <div class="col-12 row">
            <div class="col px-0">
                <div class="row">
                    <div class="col-2"><label><b>Total Inventory Qty</b></label></div>
                    <div><span>: </span></div>
                    <!-- <div class="col-5 px-0 font-weight-bold"><span> {{totalList?.bucket340b + totalList?.general.toFixed(3)}} </span></div> -->
                    <div class="col-5 pr-0 font-weight-bold"><span> {{totalSum?.toFixed(3)}}</span></div>
                </div>
                <div class="row">
                    <div class="col-2"><label><b>Total  General Qty</b></label></div>
                    <div ><span>: </span></div>
                    <div class="col-5 pr-0 font-weight-bold"><span> {{totalList?.general.toFixed(3)}} </span></div>
                </div>
                <div class="row">
                    <div class="col-2"><label><b>Total 340B Qty</b></label></div>
                    <div ><span>: </span></div>
                    <div class="col-5 pr-0 font-weight-bold"><span> {{totalList?.bucket340b.toFixed(3)}} </span></div>
                </div>
                <br>
            </div>
        </div>
        <div class="modal-footer pr-0">
        <button footer type="submit" class="hotkey_primary" appShortcutKey InputKey="c"
        (click)="closeModal()"> <b>c</b> CLOSE
        </button></div>
    </div>
