import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
    providedIn: "root"
})

export class DrugStore {
    _drugInfo$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    drugInfo$: Observable<any> = this._drugInfo$.asObservable();

    _drugCompInfo$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    drugCompInfo$: Observable<any> = this._drugCompInfo$.asObservable();

    _drugPrescribedCompInfo$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    drugPrescribedCompInfo$: Observable<any> = this._drugPrescribedCompInfo$.asObservable();

    constructor() {}

    storeDrugInfo(data) {
        this._drugInfo$.next(data);
    }

    storeCompInfo(data) {
        this._drugCompInfo$.next(data);
    }

    storePrescribedCompInfo(data) {
        this._drugPrescribedCompInfo$.next(data);
    }
}
