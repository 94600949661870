import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, ElementRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ValidateFormFieldsUtils } from "src/app/utils";

@Component({
    selector: "app-rx-select",
    templateUrl: "./rx-select.component.html",
    styles: []
})
export class RxSelectComponent implements OnInit, AfterViewInit {
    hasFGInvalid: boolean;
    autoFocus: boolean;
    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set PlaceHolder(ph: string) {
        this.placeHolder = ph;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }

    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }

    @Input()
    set IsDisabled(ir: boolean) {
        this.isDisabled = ir;
    }

    @Input()
    set List(ls: any[]) {
        this.dropdownList = ls;
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }

    @Input()
    set BindLabel(hm: string) {
        this.bindLabel = hm;
    }

    @Input()
    set BindValue(hm: string) {
        this.bindValue = hm;
    }

    @Input()
    set BindLabel2(hm: string) {
        this.bindLabel2 = hm;
    }

    @Input()
    set LabelForId(hm: string) {
        this.labelForId = hm;
    }

    @Input()
    set HasMultiple(hm: boolean) {
        this.hasMultiple = hm;
    }
    @Input()
    set HasBindLabel2(hb: boolean) {
        this.hasBindLabel2 = hb;
    }
    @Input()
    set HasControl(hc: boolean) {
        this.hasControl = hc;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
    }
    @Input()
    set Show2Values(ir: boolean) {
        this.show2values = ir;
    }
    @Input()
    set ShowLabel2(ir: boolean) {
        this.showlabel2 = ir;
    }
    @Input()
    set Title(title: string) {
        this.titleText = title;
    }

    @Input()
    set FormGroupInvalid(hc: boolean) {
        this.hasFGInvalid = hc;
        if (this.hasFGInvalid) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set AutoFocus(ti: boolean) {
        this.autoFocus = ti;
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set InputValue(iv: any) {
        this.inputValue = iv;
    }

    @Input()
    set IsInputElemHidden(id: any) {
        this.isInputElemHidden = id;
    }

    @Input() secondCntrlN: string;
    @Input() scndFormGroupN: FormGroup;
    @Input() bindScnCntrlV: string;
    @Input() nextElement: string;
    @Input() changeText: boolean;


    // @Output() TriggerChangeValue = new EventEmitter();
    @Output()
    TriggerSelectValue = new EventEmitter<{ value: string }>();
    @Output()
    AddSelectedValue = new EventEmitter<string>();

    @Output()
    TriggerOnBlur = new EventEmitter<string>();

    @Output()
    TriggerOnEnterValue = new EventEmitter<{ value: string }>();

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();

    @Output()
    TriggerOnFocus = new EventEmitter<any>();

    labelText: string;
    placeHolder: string;
    inputErrors: any;
    errorDefs: any;
    errorMessage: string;
    inputFormGroup: FormGroup;
    custContrlName: string;
    isDisabled = false;
    dropdownList: any[];
    hasMultiple = false;
    bindLabel: string;
    bindValue: string;
    bindLabel2: string;
    labelForId: string;
    hasControl = true;
    isRequired: boolean;
    hasBindLabel2: boolean;
    show2values: boolean;
    showlabel2: boolean;
    titleText: string;
    tabIndex: number;
    inputValue: any;
    rxID: any;
    isInputElemHidden = false;
    isTouched = false;

    constructor(private _validFormFieldsUtils: ValidateFormFieldsUtils, private cd: ChangeDetectorRef, private _element: ElementRef) {}

    ngOnInit() {
        // if (document.getElementById("rxdaw")) {
        //     document.getElementById("rxdaw").focus();
        // }
    }

    // ngOnChanges(changes: any): void {
    //     if (changes && changes.InputErrors) {
    //         const errors: any = changes.InputErrors.currentValue;
    //         this.errorMessage = "";
    //         if (errors) {
    //             Object.keys(this.errorDefs).some(key => {
    //                 if (errors[key]) {
    //                     this.errorMessage = this.errorDefs[key];
    //                     return true;
    //                 }
    //             });
    //         }
    //     }
    // }

    triggerChange($event) {
        this.TriggerSelectValue.emit({ value: $event });
        if (this.secondCntrlN) {
            const FG = this.scndFormGroupN ? this.scndFormGroupN : this.inputFormGroup;
            FG.controls[this.secondCntrlN].patchValue($event && $event.target && this.dropdownList[$event.target.selectedIndex] ?
            this.dropdownList[$event.target.selectedIndex][this.bindScnCntrlV] : null);
        }
        if (this.hasControl) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    validateAllFormFields(formGroup?: FormGroup, formCntrl?: string) {
        if (this.hasControl) {
            this.errorMessage = this._validFormFieldsUtils.validateFormFields(
                formGroup,
                formCntrl,
                this.errorDefs
            );
        }
    }

    addSelectedValue($event) {
        this.AddSelectedValue.emit($event);
    }

    triggerOnBlur(value?: any): void {
        this.TriggerOnBlur.emit();
    }

    onEnter(event?: any): void {
        if (event.key === "Enter") {
            this.TriggerOnEnterValue.emit(event);
        } else if (event.key === "Tab") {
            this.validateAllFormFields( this.inputFormGroup,
                this.custContrlName
            );
            if (this.hasControl) {
                if (this.inputFormGroup.controls[this.custContrlName].invalid || this.inputFormGroup.controls[this.custContrlName].valid) {
                    event.preventDefault();
                    let input = this._element.nativeElement.getElementsByTagName("input");
                    if (input && input.length) {
                        input[0].focus();
                    } else {
                        this.TriggerOnShiftTabbing.emit(event);
                    }
                }
            }
        }
    }

    setInputElemHidden() {
        this.isInputElemHidden = !this.isInputElemHidden;
        this.isTouched = true;
    }

    setIsTouchedFalse() {
        this.isInputElemHidden = !this.isInputElemHidden;
        this.isTouched = false;
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab") {
            this.TriggerOnShiftTabbing.emit(event);
        }
    }

    focusOnInput() {
        this.TriggerOnFocus.emit();
    }

    focusonTab(){
    if(this.nextElement && document.getElementById(this.nextElement) ){
        if(this.nextElement){
            const elem = document.getElementById(this.nextElement);
            let inputEle = elem.getElementsByTagName("input");
            if (inputEle && inputEle.length) {
                inputEle[0].focus();
            } else {
                document.getElementById(this.nextElement).focus();
            }
        } else {
            document.getElementById(this.nextElement).focus();
        }
        }
    }
}
