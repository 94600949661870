import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constant } from "..";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ReturnToStockService {

    constructor(private _http: HttpClient) { }

    getStockFilterData(payload): Observable<any> {
        return this._http.post<any>(constant.POST_STOCK_RETURN_FILTER_DATA, payload);
    }

    groupReverseTrans(data): Observable<any> {
        return this._http.post(constant.POST_REVERSE_TRANS, data);
    }
    ReturnToStockReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_RETURN_TO_STOCK, data, {
            headers: headers,
            responseType: "blob"
        });
    }
}
