import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { constant } from "..";
@Injectable({
    providedIn: "root"
})
export class DocumentsService {
    constructor(private _http: HttpClient) {}

    getDocList(data: any): Observable<any> {
        return this._http.post<any>(constant.POST_Shared_Documents, data);
    }

    getDocumentByName(data: any): Observable<any> {
        return this._http.post(constant.POST_Shared_DocumentByName, data,{
            responseType: "blob"
        });
    }

    getDocumentThumbnails(data: any): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frDocumnetBaseUrl", "true")
        };
        return this._http.post<any>(constant.GET_Documents_Thumbnails, data, reqOptns);
    }

    getDocDetails(
        requestFrom: any,
        patientId: any,
        drugId: any,
        prescId: any,
        insuCarrierId: any,
        prescriptionID: any
    ): Observable<any> {
        return this._http.get<any>(
            constant.GET_Shared_AttachedDocInfoByPatientId.replace(
                "{patientId}",
                "" + patientId
            )
                .replace("{drugId}", "" + drugId)
                .replace("{prescId}", "" + prescId)
                .replace("{prescNum}", "" + prescriptionID)
                .replace("{insuCarrierId}", "" + insuCarrierId)
                .replace("{requestFrom}", "" + requestFrom)
        );
    }

    downloadDocument(
        docId: any,
        extn: any,
        isControlFile: any
    ): Observable<Blob> {
        return this._http.get(
            constant.GET_Shared_DownloadDoc.replace("{docId}", "" + docId)
                .replace("{extn}", "" + extn)
                .replace("{isControlFile}", "" + isControlFile),
            { responseType: "blob" }
        );
    }

    deleteDocument(payload): Observable<any> {
        return this._http.post<any>( constant.POST_Shared_AttachDocumentDelete,payload );
    }
    deleteMultiDocuments(payload): Observable<any> {
        return this._http.post<any>( constant.POST_Shared_AttachDocumentDeleteMultiple,payload );
    }

    uploadNewDocument(payload,size?): Observable<any> {
        return this._http.post<any>(constant.POST_Shared_AttachDocument.replace("{size}",size), payload);
    }

    updateDocuments(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Shared_AttachDocument, data);
    }
    rotateDocuments(data: any): Observable<any> {
        return this._http.post<any>(constant.PostRotate_Shared_AttachDocument, data);
    }

    uploadDocument(uploadFile: any, docCatId, docSubCatId, remarks): any {
        const formData: FormData = new FormData();
        formData.append("fileKey", uploadFile, uploadFile.name);
        return this._http.post<any>(constant.POST_shared_UploadDocument
            .replace("{docCatId}", docCatId)
            .replace("{docSubCatId}", docSubCatId)
            .replace("{remarks}", remarks), formData);
    }

    attachDocToRx(dataTOPost: any): Observable<any>  {
        return this._http.post<any>(constant.POST_shared_AttachDocToRx, dataTOPost);
    }

    getDocumentSubCats(payload): Observable<any>{
        return this._http.post<any>(constant.POST_Document_SubCategory, payload);
    }

    addDeletedDocToQueue(docId): Observable<any> {
        return this._http.delete<any>(constant.DELETE_MOVE_TO_QUEUE.replace("{docId}", "" + docId));
    }

    uploadNewLogo(payload,size?): Observable<any> {
        return this._http.post<any>(constant.POST_Shared_UploadLogo.replace("{docSize}",size), payload);
    }
    isSharedFileExist(filename: string, extension: string) {
        return this._http.get<any>(
            constant.GET_Shared_File_Exist.replace(
                "{fileName}",
                filename
            ).replace("{extension}", "" + extension)
        );
    }
    deletePharmacyLogo(payLoad): Observable<any> {
        return this._http.post<any>(constant.POST_Delete_PharmacyLogo, payLoad);
    }
}
