import { constant } from "./../app.constants";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { share } from "rxjs/internal/operators/share";

@Injectable({
    providedIn: "root"
})
export class MorePatientInfoService {
    constructor(private _http: HttpClient) {}

    getMorePatientInfo(patientId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_MoreInfo.replace("{patientId}", "" + patientId)
        ).pipe(
            share());
    }

    updateMorePatientInfo(data: any) {
        return this._http.put<boolean>(constant.PUT_Patient_MoreInfo, data);
    }

    getFacilityInfo(patientId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_FacilityInfo.replace("{patientId}", "" + patientId)
        );
    }

    updateFacilityInfo(data: any) {
        return this._http.put<boolean>(constant.PUT_Patient_Facility, data);
    }

    getFacilityLocations(facilityId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_FacilityLocation.replace(
                "{facilityId}",
                "" + facilityId
            )
        );
    }
}
