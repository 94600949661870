<ng-template #CheckDur let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal(); RefreshRxHistoryGrid()">
        <h4 header>Check DUR</h4>
        <span body>
            <div class="eprx--block__content check-dur-block">
                <div class="row check--dur__block">
                    <div class="col-md-3 vertical--tabs">
                        
                      <!---  <ngb-tabset type="pills" orientation="vertical" (tabChange)="beforeTabChange($event)">
                            <ngb-tab id="allergyCheck">
                                <ng-template ngbTabTitle><div [ngClass]="(allergiesToShow && allergiesToShow.length) ? 'text-danger' : ''"><b>Allergy Check</b></div></ng-template>
                            </ngb-tab>
                            <ngb-tab id="dupDrugTher">
                                <ng-template ngbTabTitle><div  [ngClass]="(duplicateDrugs && duplicateDrugs.length) ? 'text-danger' : ''"><b>Duplicate Drug Therapy</b></div></ng-template>
                            </ngb-tab>
                            <ngb-tab id="drugToDrugIntr">
                                <ng-template ngbTabTitle><div  [ngClass]="(drugToDrugIntrctns && drugToDrugIntrctns.length) ? 'text-danger' : ''"><b>Drug-Drug Interaction</b></div></ng-template>
                            </ngb-tab>
                            <ngb-tab id="drugDiseCont">
                                <ng-template ngbTabTitle><div  [ngClass]="(drugDiseaseContrdns && drugDiseaseContrdns.length) ? 'text-danger' : ''"><b>Drug-Disease Contraindication</b></div></ng-template>
                            </ngb-tab>
                            <ngb-tab id="drugToLife">
                                <ng-template ngbTabTitle><div  [ngClass]="(drugLifestyles && drugLifestyles.length) ? 'text-danger' : ''"><b>Drug-Lifestyle Interaction</b></div></ng-template>
                            </ngb-tab>
                            <ngb-tab id="advDrugReact">
                                <ng-template ngbTabTitle><div  [ngClass]="(adverseDrugReacts && adverseDrugReacts.length) ? 'text-danger' : ''"><b>Adverse Drug Reaction</b></div></ng-template>
                            </ngb-tab>
                            <ngb-tab id="ACBScore">
                                <ng-template ngbTabTitle><div  [ngClass]="(acbScoreInfo && acbScoreInfo.length) ? 'text-danger' : ''"><b>ACB Scoring</b></div></ng-template>
                            </ngb-tab>
                            <ngb-tab id="pregAndLact">
                                <ng-template ngbTabTitle><div  [ngClass]="(pregAndLactInfo && pregAndLactInfo.length) ? 'text-danger' : ''"><b>Pregnancy And Lactation</b></div></ng-template>
                            </ngb-tab>
                        </ngb-tabset>-->


                        <ul ngbNav #nav="ngbNav"  class="nav-pills" 
                        (tabChange)="beforeChange($event?.nextId)" orientation="vertical" #CheckDurTabs >
                        <li [ngbNavItem]="'allergyCheck'"  (click)="beforeChange('allergyCheck')">
                            <a ngbNavLink class="nav-link" id="Patient1">
                                <div class="txt" [ngClass]="(allergiesToShow && allergiesToShow.length) ? 'text-danger' : ''">
                                    <div ><b>Allergy Check</b></div>
                                </div>
                            </a>
                        </li>
                        <li [ngbNavItem]="'dupDrugTher'"  (click)="beforeChange('dupDrugTher')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt" [ngClass]="(duplicateDrugs && duplicateDrugs.length) ? 'text-danger' : ''">
                                <div><b>Duplicate Drug Therapy</b></div>
                                </div>
                            </a>
                        </li>
                        <li [ngbNavItem]="'drugToDrugIntr'"  (click)="beforeChange('drugToDrugIntr')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt" [ngClass]="(drugToDrugIntrctns && drugToDrugIntrctns.length) ? 'text-danger' : ''">
                                    <div><b>Drug-Drug Interaction</b></div>
                                </div>
                            </a>
                        </li>
                        <li [ngbNavItem]="'drugDiseCont'"  (click)="beforeChange('drugDiseCont')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt"  [ngClass]="(drugDiseaseContrdns && drugDiseaseContrdns.length) ? 'text-danger' : ''">
                                    <div ><b>Drug-Disease Contraindication</b></div>
                                </div>
                            </a>
                        </li>
                        
                        <li [ngbNavItem]="'drugToLife'"  (click)="beforeChange('drugToLife')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt" [ngClass]="(drugLifestyles && drugLifestyles.length) ? 'text-danger' : ''" >
                                    <div><b>Drug-Lifestyle Interaction</b></div>
                                </div>
                            </a>
                        </li>
                        
                        <li [ngbNavItem]="'advDrugReact'"  (click)="beforeChange('advDrugReact')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt" [ngClass]="(adverseDrugReacts && adverseDrugReacts.length) ? 'text-danger' : ''" >
                                    <div ><b>Adverse Drug Reaction</b></div>
                                </div>
                            </a>
                        </li>

                        <li [ngbNavItem]="'ACBScore'"  (click)="beforeChange('ACBScore')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt" [ngClass]="(acbScoreInfo && acbScoreInfo.length) ? 'text-danger' : ''" >
                                    <div ><b>ACB Scoring</b></div>
                                </div>
                            </a>
                        </li>

                        <li [ngbNavItem]="'pregAndLact'"  (click)="beforeChange('pregAndLact')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt"  >
                                    <div  [ngClass]="(acbScoreInfo && acbScoreInfo.length) ? 'text-danger' : ''"><b>Pregnancy And Lactation</b></div>
                                </div>
                            </a>
                        </li>
                    </ul>


                    </div>
                    <div class="col-9">
                        <div class="row" *ngIf="selctdTab === 'allergyCheck'">
                            <h4 class="text-primary col-md-12">
                                Allergy Check
                                <hr />
                            </h4>
                            <span *ngIf="allergiesToShow?.length">
                                <div class="col-md-12" *ngFor="let allerg of allergiesToShow">
                                    <b><span [innerHTML]="allerg.AllergyInfo"></span></b>
                                </div>
                            </span>
                            <div class="col-md-12 text-center" *ngIf="!allergiesToShow || !allergiesToShow?.length">
                                Patient has no allergies.
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'dupDrugTher'">
                            <h4 class="text-primary col-md-12">Duplicate Drug Therapy
                                <hr />
                            </h4>
                            <div class="col-md-12 accordion-section-over">
                                <span *ngIf="duplicateDrugs?.length">
                                    <wj-flex-grid #VerifHist [headersVisibility]="'Column'" [itemsSource]="duplicateDrugsNewWJ" [isReadOnly]="true"
                                        [selectionMode]="'None'" class="dur-duplicate-drugs-wijmo">
                                        <wj-flex-grid-column *ngFor="let head of duplicateDrugsHeadrs" [binding]="head" [header]="head" [width]="'*'">                                            
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="head === 'DRUG 1'">
                                                    {{ item['DRUG 1'] }} &nbsp;<label class="rx-label">{{ ' Rx: ' + item['Rx1'] }}</label>
                                                </div>
                                                <div *ngIf="head === 'DRUG 2'">
                                                    {{ item['DRUG 2'] }} &nbsp;<label class="rx-label">{{ ' Rx: ' + item['Rx2'] }}</label>
                                                </div>
                                                <div *ngIf="head !== 'DRUG 1' && head !== 'DRUG 2'">
                                                    {{ item[head] }}
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </span>
                                <div *ngIf="!duplicateDrugs?.length" class="text-center">
                                    No Duplicate Drugs for this Patient
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'drugToDrugIntr'">
                            <h4 class="text-primary col-md-12">
                                Drug To Drug Interaction
                                <hr />
                            </h4>
                            <div class="col-12 accordion-section-over" *ngIf="drugToDrugIntrctns && drugToDrugIntrctns.length">
                                <ngb-accordion [closeOthers]="false">
                                    <ngb-panel *ngFor="let drugToDrugIntrn of drugToDrugIntrctns">
                                        <ng-template ngbPanelTitle>
                                            <div class="d-flex accordion-custom-header" (click)="drugToDrugIntrn.isExpand=!drugToDrugIntrn.isExpand">
                                                <div class="d-flex accord-ttl-icon">
                                                    <i *ngIf="!drugToDrugIntrn.isExpand" class="far fa-plus fa-lg"></i>
                                                    <i *ngIf="drugToDrugIntrn.isExpand" class="far fa-minus fa-lg"></i>
                                                </div>
                                                <div class="d-flex">{{drugToDrugIntrn.DrugName}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="check--Dur__accs">
                                                <ngb-accordion [closeOthers]="true">
                                                    <ngb-panel  *ngFor="let drugToDrugInteractions of drugToDrugIntrn?.drugToDrugInteraction">
                                                        <ng-template ngbPanelTitle class="bg-light">
                                                            <h5 class="text-primary">
                                                                <!-- {{drugToDrugInteractions?.Drug1NDC}} (Drug: {{drugToDrugInteractions?.DRUGNAME}} , Class: {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}}) -->
                                                                {{this._formatsUtil.getNDCFormat(drugToDrugInteractions?.Drug1NDC)}} (Drug: {{drugToDrugInteractions?.DRUGNAME}} , Class: {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}})
                                                            </h5>
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            <span>
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <label>Drug2: </label>
                                                                        <div class="label--data">{{this._formatsUtil.getNDCFormat(drugToDrugInteractions?.Drug2NDC)}}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <label>Class1: </label>
                                                                        <div class="label--data"> {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}} </div>
                                                                    </div>
                                                                    <div class="col"></div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="col">
                                                                            <label>Effects: </label>
                                                                            <div class="label--data">{{drugToDrugInteractions?.EFFECTS}} </div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <label>Mechanism: </label>
                                                                            <div class="label--data">{{drugToDrugInteractions?.MECHANISM}}</div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <label>Management: </label>
                                                                            <div class="label--data">{{drugToDrugInteractions?.MANAGEMENT}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <label>
                                                                        Onset
                                                                    </label>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="col">
                                                                            <label>Severity 3 3 - Moderate: </label>
                                                                            <div class="label--data"> {{drugToDrugInteractions?.SEVERITYDESCRIPTION}} </div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <label> Documentation Established: </label>
                                                                            <div class="label--data">{{drugToDrugInteractions?.DOCUM}}</div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <label> Customer notes: </label>
                                                                            <div class="label--data">{{drugToDrugInteractions?.CONSUMERNOTES}}</div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <label> Refrence: </label>
                                                                            <div class="label--data">{{drugToDrugInteractions?.REFERENCE}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </ng-template>
                                                    </ngb-panel>
                                                </ngb-accordion>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>

                            <!-- <div class="col-md-12" *ngIf="drugInteractions?.DrugToDrugInteraction && drugInteractions?.DrugToDrugInteraction.length>0">
                                <div class="check--Dur__accs">
                                    <ngb-accordion #DDI="ngbAccordion" activeIds="ngb-panel-0" [closeOthers]="true">
                                        <ngb-panel  *ngFor="let drugToDrugInteractions of drugInteractions?.DrugToDrugInteraction">
                                            <ng-template ngbPanelTitle class="bg-light">
                                                <h5 class="text-primary">
                                                    {{drugToDrugInteractions?.Drug1NDC}} (Class: {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}})</h5>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <span>
                                                    <div class="row">
                                                        <div class="col">
                                                            <label>Drug2: </label>
                                                            <div class="label--data">{{drugToDrugInteractions?.Drug2NDC}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <label>Class1: </label>
                                                            <div class="label--data"> {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}} </div>
                                                        </div>
                                                        <div class="col"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="col">
                                                                <label>Effects: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.EFFECTS}} </div>
                                                            </div>
                                                            <div class="col">
                                                                <label>Mechanism: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.MECHANISM}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <label>Management: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.MANAGEMENT}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <label>
                                                            Onset
                                                        </label>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="col">
                                                                <label>Severity 3 3 - Moderate: </label>
                                                                <div class="label--data"> {{drugToDrugInteractions?.SEVERITYDESCRIPTION}} </div>
                                                            </div>
                                                            <div class="col">
                                                                <label> Documentation Established: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.DOCUM}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <label> Customer notes: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.CONSUMERNOTES}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <label> Refrence: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.REFERENCE}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div> -->
                            <div class="col-md-12 text-center" *ngIf="!drugToDrugIntrctns?.length">
                                No drug to drug Interactions
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'drugDiseCont'">
                            <h4 class="text-primary col-md-12">
                                Drug Disease Contraindication
                                <hr>
                            </h4>
                            <div class="col-md-12 padding-0 accordion-section-over" *ngIf="drugDiseaseContrdns?.length">
                                <ngb-accordion [closeOthers]="false">
                                    <ngb-panel *ngFor="let diseaseContrad of drugDiseaseContrdns">
                                        <ng-template ngbPanelTitle>
                                            <div class="d-flex accordion-custom-header" (click)="diseaseContrad.isExpand=!diseaseContrad.isExpand">
                                                <div class="d-flex accord-ttl-icon">
                                                    <i *ngIf="!diseaseContrad.isExpand" class="far fa-plus fa-lg"></i>
                                                    <i *ngIf="diseaseContrad.isExpand" class="far fa-minus fa-lg"></i>
                                                </div>
                                                <div class="d-flex">{{diseaseContrad.DrugName}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="col-xs-12">
                                                <div class="col-xs-12" *ngFor="let singDisContrad of diseaseContrad.DiseaseContradication?.DrillDownData | keyvalue">
                                                    <label>{{singDisContrad.key | uppercase}}</label>
                                                    <div class="col-xs-12">
                                                        <table class="table">
                                                            <thead class="thead-light">
                                                                <tr>
                                                                    <th scope="col"> ICD10 Code </th>
                                                                    <th scope="col"> ICD10 Name </th>
                                                                    <th scope="col"> Min Age Year </th>
                                                                    <th scope="col"> Max Age Year </th>
                                                                    <th scope="col"> Gender </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let drugDes of singDisContrad.value">
                                                                    <th scope="row"> {{drugDes?.ICD_CODE}}</th>
                                                                    <td>{{drugDes?.CHILDDISEASENAME}}</td>
                                                                    <td>{{drugDes?.MINAGE_YEARS}}</td>
                                                                    <td>{{drugDes?.MAXAGE_YEARS}}</td>
                                                                    <td>{{drugDes?.GENDER}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12">
                                                    <label class="padding_bottom_25px"> CONTRADICTION TEXT FROM ({{diseaseContrad?.DrugName}})'S MONOGRAPH </label>
                                                    <div class="col-xs-12">
                                                        <div class="label--data height_40vh overflow_scroll">
                                                            {{diseaseContrad?.DiseaseContradication?.MonographText}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                            <div class="col text-center" *ngIf="!drugDiseaseContrdns?.length">
                                No drug disease contraindication
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'drugToLife'">
                            <h4 class="text-primary col-md-12">
                                Drug To Life Style Interaction
                                <hr>
                            </h4>
                            <div class="col-md-12 accordion-section-over" *ngIf="drugLifestyles && drugLifestyles.length">
                                <ngb-accordion [closeOthers]="false" >
                                    <ngb-panel *ngFor="let drugLifeStyl  of drugLifestyles">
                                        <ng-template ngbPanelTitle>
                                            <div class="d-flex accordion-custom-header" (click)="drugLifeStyl.isExpand=!drugLifeStyl.isExpand">
                                                <div class="d-flex accord-ttl-icon">
                                                    <i *ngIf="!drugLifeStyl.isExpand" class="far fa-plus fa-lg"></i>
                                                    <i *ngIf="drugLifeStyl.isExpand" class="far fa-minus fa-lg"></i>
                                                </div>
                                                <div class="d-flex">{{drugLifeStyl.DrugName}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="check--Dur__accs">
                                                <ngb-accordion  [closeOthers]="true">
                                                    <ngb-panel [id]="lifeStyle.LifestyleName + i" *ngFor="let lifeStyle of drugLifeStyl?.lifeStyle;let i = index">
                                                        <ng-template ngbPanelTitle class="bg-light">
                                                            <h5 class="text-primary">
                                                                {{lifeStyle?.LifestyleName}} </h5>
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            <span>
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <label>Life Style Name</label>
                                                                        <div class="label--data">{{lifeStyle?.LifestyleName}}</div>
                                                                    </div>
                                                                    <div class="col">
                                                                        <label>Concept Name</label>
                                                                        <div class="label--data">{{lifeStyle?.CONCEPTNAME}}</div>
                                                                    </div>
                                                                    <br>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <label>Concept Description</label>
                                                                        <div class="label--data"> {{lifeStyle?.ConceptDescription}} </div>
                                                                    </div><br>
                                                                    <div class="col-md-12">
                                                                        <label>Mechanism</label>
                                                                        <div class="label--data"> {{lifeStyle?.ProfessionalNotes}} </div>
                                                                    </div><br>
                                                                    <div class="col-md-12">
                                                                        <label>Management</label>
                                                                        <div class="label--data"> {{lifeStyle?.CLINICALMANAGEMENTTEXT}} </div>
                                                                    </div><br>
                                                                    <div class="col-md-12">
                                                                        <label>Severity</label>
                                                                        <div class="label--data"> {{lifeStyle?.SEVERITYTEXT}} </div>
                                                                    </div><br>
                                                                    <div class="col-md-12">
                                                                        <label>Severity Description</label>
                                                                        <div class="label--data"> {{lifeStyle?.SEVERITYDESCRIPTION}} </div>
                                                                    </div><br>
                                                                    <div class="col-md-12">
                                                                        <label>Documentation</label>
                                                                        <div class="label--data"> {{lifeStyle?.DOCUMENTATIONTEXT}} </div>
                                                                    </div><br>
                                                                    <div class="col-md-12">
                                                                        <label>Documentation Description</label>
                                                                        <div class="label--data"> {{lifeStyle?.DocumentationDescription}} </div>
                                                                    </div><br>
                                                                    <div class="col-md-12">
                                                                        <label>Consumer Note</label>
                                                                        <div class="label--data"> {{lifeStyle?.ConsumerNotes}} </div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </ng-template>
                                                    </ngb-panel>
                                                </ngb-accordion>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>

                            <div class="col text-center" *ngIf="!drugLifestyles?.length">
                                No drug to life style Interactions
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'advDrugReact'">
                            <h4 class="text-primary col-md-12">Adverse Drug Reaction
                                <hr>
                            </h4>
                            <div class="col-md-12 accordion-section-over" *ngIf="adverseDrugReacts && adverseDrugReacts.length">
                                <ngb-accordion [closeOthers]="false">
                                    <ngb-panel *ngFor="let adeverReact of adverseDrugReacts">
                                        <ng-template ngbPanelTitle>
                                            <div class="d-flex accordion-custom-header" (click)="adeverReact.isExpand=!adeverReact.isExpand">
                                                <div class="d-flex accord-ttl-icon">
                                                    <i *ngIf="!adeverReact.isExpand" class="far fa-plus fa-lg"></i>
                                                    <i *ngIf="adeverReact.isExpand" class="far fa-minus fa-lg"></i>
                                                </div>
                                                <div class="d-flex">{{adeverReact.DrugName}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="col-xs-12">
                                                <wj-flex-grid #VerifHist [headersVisibility]="'Column'" [itemsSource]="adeverReact.adverseReaction" [isReadOnly]="true"
                                                    [selectionMode]="'None'">
                                                    <wj-flex-grid-column [header]="'SEVERITY'" [width]="'*'" [binding]="'Severity'">
                                                    </wj-flex-grid-column>
                                                    <wj-flex-grid-column [header]="'ONSET'" [width]="'*'" [binding]="'Onset'">
                                                    </wj-flex-grid-column>
                                                    <wj-flex-grid-column [header]="'DISEASE NAME'" [width]="'*'" [binding]="'DISEASENAME'">
                                                    </wj-flex-grid-column>
                                                    <wj-flex-grid-column [header]="'INCIDENCE'" [width]="'*'" [binding]="'INCIDENCE'">
                                                    </wj-flex-grid-column>
                                                    <wj-flex-grid-column [header]="'IS BLACKBOX?'" [width]="'*'" [binding]="'ISBLACKBOX'">
                                                    </wj-flex-grid-column>
                                                </wj-flex-grid>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                            <div *ngIf="!adverseDrugReacts?.length" class="text-center col">
                                No Adverse Reactions for this Rx
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'ACBScore'">
                            <h4 class="text-primary col-md-12">ACB Scoring
                                <hr>
                            </h4>
                            <div class="col-12 accordion-section-over" *ngIf="acbScoreInfo && acbScoreInfo.length">
                                <div class="col-12" *ngFor="let acbScr of acbScoreInfo">
                                    <div class="row">
                                        <h5 class="col-12">
                                            Severe Anticholinergic effects associated with '{{acbScr.DrugName | uppercase}}'
                                        </h5>
                                        <div class="col-12">
                                            The prescribed drug '{{acbScr.DrugName | uppercase}}' is assigned ACB score of {{acbScr.AcbScore}}. <br><br>
                                            The Anticholinergic Cognitive Burden (ACB) Score (or ACB Scale) is a tool used to identify the severity of anticholinergic effects of prescribed or over-the-counter medications on cognition in older adults. Drugs with anticholinergic effects are associated with adverse events such as delirium and falls as well as cognitive decline, loss of independence, and even death.<br>
                                            An alternate medication with lower ACB scores may be considered to lower the overall risk of cognitive impairment.
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-center" *ngIf="!acbScoreInfo?.length">
                                No ACB Score for this Patient
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'pregAndLact'">
                            <h4 class="text-primary col-md-12">
                                Pregnancy And Lactation
                                <hr />
                            </h4>
                            <div class="col-12 accordion-section-over" *ngIf="pregAndLactInfo && pregAndLactInfo.length">
                                <ngb-accordion [closeOthers]="false">
                                    <ngb-panel *ngFor="let pregAndLact of pregAndLactInfo">
                                        <ng-template ngbPanelTitle>
                                            <div class="d-flex accordion-custom-header" (click)="pregAndLact.isExpand=!pregAndLact.isExpand">
                                                <div class="d-flex accord-ttl-icon">
                                                    <i *ngIf="!pregAndLact.isExpand" class="far fa-plus fa-lg"></i>
                                                    <i *ngIf="pregAndLact.isExpand" class="far fa-minus fa-lg"></i>
                                                </div>
                                                <div class="d-flex">{{pregAndLact.DrugName}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="row">
                                                <div class="col-xs-12">
                                                    <label class="col-md-12">
                                                        Pregnancy Information
                                                    </label>
                                                    <div class="col-md-12">
                                                        <table class="table">
                                                            <thead class="thead-light">
                                                                <tr>
                                                                    <th scope="col">TRIMESTER</th>
                                                                    <th scope="col">
                                                                        PREGNANCY RATING CODE
                                                                    </th>
                                                                    <th scope="col">
                                                                        RATING DESCRIPTION
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="
                                                                        let pregnancyInfo of pregAndLact?.pregnancyLactationInfo?.dtPregnancyInformation
                                                                    ">
                                                                    <th scope="row">
                                                                        {{pregnancyInfo?.TRIMESTER}}
                                                                    </th>
                                                                    <td>
                                                                        {{pregnancyInfo?.PREGNANCYRATINGCODE}}
                                                                    </td>
                                                                    <td>
                                                                        {{pregnancyInfo?.RATINGDESCRIPTION}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <label class="col-md-12">
                                                        LACTATION Information
                                                    </label>
                                                    <div class="col-md-12">
                                                        <table class="table">
                                                            <thead class="thead-light">
                                                                <tr>
                                                                    <th scope="col">
                                                                        IS SAFE FOR CHILD BEARING
                                                                        AGE
                                                                    </th>
                                                                    <th scope="col">
                                                                        IS SAFE FOR LABOUR AND
                                                                        DELIVERY
                                                                    </th>
                                                                    <th scope="col">
                                                                        LOCATION RATING
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let lactationInfo of pregAndLact?.pregnancyLactationInfo?.dtLactationInformation">
                                                                    <th scope="row">
                                                                        {{lactationInfo["SAFE FOR CHILD BEARING AGE"]}}
                                                                    </th>
                                                                    <td>
                                                                        {{lactationInfo["SAFE FOR LABOR AND DELIVERY"]}}
                                                                    </td>
                                                                    <td>
                                                                        {{lactationInfo["LACTATION RATING"]}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                            <div class="col-md-12 text-center" *ngIf="!pregAndLactInfo?.length">No Pregnancy and Lactation Information</div>
                        </div>
                    </div>
                    <!-- <div class="col-md-9">
                        <div class="row" *ngIf="selctdTab === 'allergyCheck'">
                            <h4 class="text-primary col-md-12">
                                Allergy Check
                                <hr />
                            </h4>
                            <div class="col-md-12" *ngIf="drugInteractions?.Allergies?.IsAllergy">
                                This Patient is Allergic to Class "{{drugInteractions?.Allergies.AllergyInfo}}".<br>
                                "{{rxInfo.Drug.genericname}}" May Cause An Allergic Reaction.
                            </div>
                            <div class="col-md-12 text-center" *ngIf="!drugInteractions?.Allergies?.IsAllergy">
                                Patient has no allergies.
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'dupDrugTher'">
                            <h4 class="text-primary col-md-12">Duplicate Drug Therapy
                                <hr />
                            </h4>
                            <div class="col-md-12">
                                <div *ngIf="!duplicateDrugsWJ" class="text-center">
                                    No Duplicate Drugs for this Rx
                                </div>
                                <span *ngIf="duplicateDrugsWJ">
                                    <wj-flex-grid #VerifHist [headersVisibility]="'Column'" [itemsSource]="duplicateDrugsWJ" [isReadOnly]="true"
                                        [selectionMode]="'None'">
                                        <wj-flex-grid-column header="DRUG 1" [width]="'*'">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                                <span>{{rxInfo.Drug.genericname}}</span>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'DRUG 2'" [width]="'*'" [binding]="'DrugName'">
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'CLASS NAME'" [width]="'*'" [binding]="'ClassName'">
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </span>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'drugToDrugIntr'">
                            <h4 class="text-primary col-md-12">
                                Drug To Drug Interaction
                                <hr />
                            </h4>
                            <div class="col-md-12" *ngIf="drugInteractions?.DrugToDrugInteraction && drugInteractions?.DrugToDrugInteraction.length>0">
                                <div class="check--Dur__accs">
                                    <ngb-accordion #DDI="ngbAccordion" activeIds="ngb-panel-0" [closeOthers]="true">
                                        <ngb-panel  *ngFor="let drugToDrugInteractions of drugInteractions?.DrugToDrugInteraction">
                                            <ng-template ngbPanelTitle class="bg-light">
                                                <h5 class="text-primary">
                                                    {{drugToDrugInteractions?.Drug1NDC}} (Class: {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}})</h5>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <span>
                                                    <div class="row">
                                                        <div class="col">
                                                            <label>Drug2: </label>
                                                            <div class="label--data">{{drugToDrugInteractions?.Drug2NDC}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <label>Class1: </label>
                                                            <div class="label--data"> {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}} </div>
                                                        </div>
                                                        <div class="col"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="col">
                                                                <label>Effects: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.EFFECTS}} </div>
                                                            </div>
                                                            <div class="col">
                                                                <label>Mechanism: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.MECHANISM}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <label>Management: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.MANAGEMENT}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <label>
                                                            Onset
                                                        </label>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="col">
                                                                <label>Severity 3 3 - Moderate: </label>
                                                                <div class="label--data"> {{drugToDrugInteractions?.SEVERITYDESCRIPTION}} </div>
                                                            </div>
                                                            <div class="col">
                                                                <label> Documentation Established: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.DOCUM}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <label> Customer notes: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.CONSUMERNOTES}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <label> Refrence: </label>
                                                                <div class="label--data">{{drugToDrugInteractions?.REFERENCE}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div>
                            <div class="col-md-12 text-center" *ngIf="!drugInteractions?.DrugToDrugInteraction">
                                No drug to drug Interactions
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'drugDiseCont'">
                            <h4 class="text-primary col-md-12">
                                Drug Disease Contraindication
                                <hr>
                            </h4>
                            <div class="col-md-12 padding-0" *ngIf="drugInteractions?.DiseaseContradication && drugInteractions?.DiseaseContradication?.DrillDownData">
                                <div class="col-md-12"> Drug Disease Contraindication predicted for the Drug
                                </div>
                                <div class="col-md-12">
                                    <label>Aneurysm </label>
                                    <div class="col-md-12 padding-0">
                                        <table class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col"> ICD10 Code </th>
                                                    <th scope="col"> ICD10 Name </th>
                                                    <th scope="col"> Min Age Year </th>
                                                    <th scope="col"> Max Age Year </th>
                                                    <th scope="col"> Gender </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"> {{drugInteractions?.DiseaseContradication?.DrillDownData?.ICD_CODE}}</th>
                                                    <td>{{drugInteractions?.DiseaseContradication?.DrillDownData?.CHILDDISEASENAME}}</td>
                                                    <td>{{drugInteractions?.DiseaseContradication?.DrillDownData?.MINAGE_YEARS}}</td>
                                                    <td>{{drugInteractions?.DiseaseContradication?.DrillDownData?.MAXAGE_YEARS}}</td>
                                                    <td>{{drugInteractions?.DiseaseContradication?.DrillDownData?.GENDER}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-12 padding-0">
                                        <label> CONTRADICTION TEXT FROM {{rxInfo['Drug Name']}}'S MONOGRAPH </label>
                                        <div class="col-xs-12">
                                            <div class="label--data">{{drugInteractions?.DiseaseContradication?.MonographText}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col text-center" *ngIf="!drugInteractions?.DiseaseContradication">
                                No drug disease contraindication
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'drugToLife'">
                            <h4 class="text-primary col-md-12">
                                Drug To Life Style Interaction
                                <hr>
                            </h4>
                            <div class="col-md-12" *ngIf="drugInteractions?.LifeStyle && drugInteractions?.LifeStyle.length>0">
                                <div class="check--Dur__accs">
                                    <ngb-accordion #DLSI="ngbAccordion" activeIds="ngb-panel-0" [closeOthers]="true">
                                        <ngb-panel [id]="lifeStyle.LifestyleName" *ngFor="let lifeStyle of drugInteractions?.LifeStyle">
                                            <ng-template ngbPanelTitle class="bg-light">
                                                <h5 class="text-primary">
                                                    {{lifeStyle?.LifestyleName}} </h5>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <span>
                                                    <div class="row">
                                                        <div class="col">
                                                            <label>Life Style Name</label>
                                                            <div class="label--data">{{lifeStyle?.LifestyleName}}</div>
                                                        </div>
                                                        <div class="col">
                                                            <label>Concept Name</label>
                                                            <div class="label--data">{{lifeStyle?.CONCEPTNAME}}</div>
                                                        </div>
                                                        <br>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <label>Concept Description</label>
                                                            <div class="label--data"> {{lifeStyle?.ConceptDescription}} </div>
                                                        </div><br>
                                                        <div class="col-md-12">
                                                            <label>Mechanism</label>
                                                            <div class="label--data"> {{lifeStyle?.ProfessionalNotes}} </div>
                                                        </div><br>
                                                        <div class="col-md-12">
                                                            <label>Management</label>
                                                            <div class="label--data"> {{lifeStyle?.CLINICALMANAGEMENTTEXT}} </div>
                                                        </div><br>
                                                        <div class="col-md-12">
                                                            <label>Severity</label>
                                                            <div class="label--data"> {{lifeStyle?.SEVERITYTEXT}} </div>
                                                        </div><br>
                                                        <div class="col-md-12">
                                                            <label>Severity Description</label>
                                                            <div class="label--data"> {{lifeStyle?.SEVERITYDESCRIPTION}} </div>
                                                        </div><br>
                                                        <div class="col-md-12">
                                                            <label>Documentation</label>
                                                            <div class="label--data"> {{lifeStyle?.DOCUMENTATIONTEXT}} </div>
                                                        </div><br>
                                                        <div class="col-md-12">
                                                            <label>Documentation Description</label>
                                                            <div class="label--data"> {{lifeStyle?.DocumentationDescription}} </div>
                                                        </div><br>
                                                        <div class="col-md-12">
                                                            <label>Consumer Note</label>
                                                            <div class="label--data"> {{lifeStyle?.ConsumerNotes}} </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div>
                            <div *ngIf="!drugInteractions?.LifeStyle && drugInteractions?.LifeStyle.length!>0">
                                <h4 class="text-primary col-md-12">No drug to life style Interactions
                                    <hr>
                                </h4>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'advDrugReact'">
                            <h4 class="text-primary col-md-12">Adverse Drug Reaction
                                <hr>
                            </h4>
                            <div class="col-md-12">
                                <div *ngIf="!adverseReactionWJ" class="text-center">
                                    No Adverse Reactions for this Rx
                                </div>
                                <span *ngIf="adverseReactionWJ">
                                    <wj-flex-grid #VerifHist [headersVisibility]="'Column'" [itemsSource]="adverseReactionWJ" [isReadOnly]="true"
                                        [selectionMode]="'None'">
                                        <wj-flex-grid-column [header]="'SEVERITY'" [width]="'*'" [binding]="'Severity'">
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'ONSET'" [width]="'*'" [binding]="'Onset'">
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'DISEASE NAME'" [width]="'*'" [binding]="'DISEASENAME'">
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'INCIDENCE'" [width]="'*'" [binding]="'INCIDENCE'">
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'IS BLACKBOX?'" [width]="'*'" [binding]="'IsBlackboxWarning'">
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </span>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'ACBScore'">
                            <h4 class="text-primary col-md-12">ACB Scoring
                                <hr>
                            </h4>
                            <div class="col-md-12">
                                <div *ngIf="drugInteractions?.AcbScore">
                                    Acb score for this Rx: {{drugInteractions?.AcbScore}}
                                </div>
                                <div class="col-md-12 text-center" *ngIf="!drugInteractions?.AcbScore">
                                    No ACB Score for this Rx
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab === 'pregAndLact'">
                            <h4 class="text-primary col-md-12">
                                Pregnancy And Lactation
                                <hr />
                            </h4>
                            <div class="col-md-12 padding-0" *ngIf="drugInteractions?.PregnancyLactationInfo">
                                <label class="col-md-12">
                                    Pregnancy Information
                                </label>
                                <div class="col-md-12">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">TRIMESTER</th>
                                                <th scope="col">
                                                    PREGNANCY RATING CODE
                                                </th>
                                                <th scope="col">
                                                    RATING DESCRIPTION
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                    let pregnancyInfo of drugInteractions?.PregnancyLactationInfo?.dtPregnancyInformation
                                                ">
                                                <th scope="row">
                                                    {{pregnancyInfo?.TRIMESTER}}
                                                </th>
                                                <td>
                                                    {{pregnancyInfo?.PREGNANCYRATINGCODE}}
                                                </td>
                                                <td>
                                                    {{pregnancyInfo?.RATINGDESCRIPTION}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <label class="col-md-12">
                                    LACTATION Information
                                </label>
                                <div class="col-md-12">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">
                                                    IS SAFE FOR CHILD BEARING
                                                    AGE
                                                </th>
                                                <th scope="col">
                                                    IS SAFE FOR LABOUR AND
                                                    DELIVERY
                                                </th>
                                                <th scope="col">
                                                    LOCATION RATING
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let lactationInfo of drugInteractions?.PregnancyLactationInfo?.dtPregnancyInformation">
                                                <th scope="row">
                                                    {{lactationInfo["SAFE FOR CHILD BEARING AGE"]}}
                                                </th>
                                                <td>
                                                    {{lactationInfo["SAFE FOR LABOR AND DELIVERY"]}}
                                                </td>
                                                <td>
                                                    {{lactationInfo["LACTATION RATING"]}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-12 text-center" *ngIf="!drugInteractions?.PregnancyLactationInfo">No Pregnancy and Lactation
                                Information</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="closeModal(); RefreshRxHistoryGrid()" appShortcutKey InputKey="o">
          <b>O</b>  OK
        </button>
    </app-custom-modal>
</ng-template>
