export const PrescTabEnum = {
    Patient1: "PRESCRIBERINFO",
    Patient2: "DOCUMENTS",
    Patient3: "CLINICALADDRESS",
    Patient4: "PRESCRIBERHISTORY",
    Patient5: "NOTESLOG"
};

export const PrescHeaderTitles = {
    Patient1: "Prescriber Information",
    Patient2: "Documents",
    Patient3: "Clinical Address",
    Patient4: "Prescriber History",
    Patient5: "Notes"
};

export const PrescTabTitles = {
    PRESCRIBERINFO: "Patient1",
    DOCUMENTS: "Patient2",
    CLINICALADDRESS: "Patient3",
    PRESCRIBERHISTORY: "Patient4",
    NOTESLOG: "Patient5"
};

export const MMSDataHeaders = [
    "SELECT",
    "LAST NAME",
    "FIRST NAME",
    "NPI#",
    "PHONE#",
    "FAX#",
    "STREET",
    "STATE",
    "CITY",
    "ZIP",
    "LICENSE#",
    "DEA#",
    "SPI#",
    "ACTIVE",
    "SERVICE LEVEL NAME",
    "SERVICE LEVEL"
];
