<div class="form-group custom--input zip_line_height" [ngClass]="{'has-error':(errorMessage && stateTouched)}">
    <label class="control-label">
        <span class="label_color"> State
            <span class="help--text__danger" *ngIf="isRequired">*</span>
        </span>
        <input [id]="rxSelectId" type="text" name="searchBoxStateType" class="form-control"  placeholder="State" [ngbTypeahead]="searchState" (selectItem)="optedStateValue($event)"
        (blur)="validateAllFormFields(inputFormGroup, custContrlName);checkIfNull()" [required]="isRequired"   [(ngModel)]="stateSelected" [resultTemplate]="rt" [inputFormatter]="formatter" [tabindex]="tabIndex"
        (focus)="stateTouched=true" (keydown)="onKeydownValue($event)">
    </label>
    <ng-template #rt let-r="result" let-t="term">
        <div class="card patient-card secondary-patient-card" *ngIf="r">
            <div class="row">
                {{r?.Name}}
            </div>
        </div>
    </ng-template>
    <div class="help-block" *ngIf="errorMessage && stateTouched">
           {{errorMessage}}
    </div>
</div>
