import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { constant } from "..";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class PatientHistoryPrintService {
    constructor(private _http: HttpClient) { }

    getRxReport(searchData: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_PatientHistPrint_RxReport,
            searchData, { headers: headers, responseType: "blob" }
        );
    }

    getRxDeliveryReport(searchData: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_PatientHistPrint_RxDelivery,
            searchData, { headers: headers, responseType: "blob" }
        );
    }

    getRxReceiptReport(searchData: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_PatientHistPrint_RxReceipt,
            searchData, { headers: headers, responseType: "blob" }
        );
    }

    getCopayReport(searchData: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_PatientHistPrint_Copay,
            searchData, { headers: headers, responseType: "blob" }
        );
    }

    getRefillExpirationReport(searchData: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_PatientHistPrint_RefillExpirationReport,
            searchData, { headers: headers, responseType: "blob" }
        );
    }


    // getDrugMedguide(drugId: number): Observable<any> {
    //     let headers = new HttpHeaders();
    //     headers = headers.set("Accept", "application/pdf");
    //     return this._http.get(
    //         constant.GET_Drug_MedGuide.replace("{drugId}", "" + drugId),
    //         { headers: headers, responseType: "blob" }
    //     );
    // }

    getPatientAddresses(patId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_PatientHistPrint_PatientAddresses.replace("{patientId}", "" + patId)
        );
    }

    getPatientInsurances(patId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_PatientHistPrint_Insurances.replace("{patientId}", "" + patId)
        );
    }

    getPrintConfig(): Observable<any> {
        return this._http.get<any>(
            constant.GET_PatientHistory_ReportConfig
        );
    }

    postFaxNumber(params):Observable<any> {
        return this._http.post<any>(
            constant.POST_FAX_HISTORY,params
        );

    }

    savePrintConfig(configData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_PatientHistory_SaveReportConfig,
            configData
        );
    }
}
