import { Component, OnInit } from '@angular/core';
import { WijimoUtil } from 'src/app/utils';
import { CollectionView } from  '@grapecity/wijmo';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-data-set',
  templateUrl: './view-data-set.component.html',
  styleUrls: ['./view-data-set.component.scss']
})
export class ViewDataSetComponent implements OnInit {
  viewDataGrid: CollectionView;
  groupByNames = [];
  unsubscribe$: Subject<void> = new Subject();
  viewDataList: any;
  wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
  constructor(private _wijimoUtils: WijimoUtil) { }

  ngOnInit() {
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(w => {
      if (w && w["WijmoKey"] && w["WijmoKey"] === "viewDatSetWj") {
          this.patchDefaultValHeaders();
      }
  });
  }
  getDataViewSetList() {
    this.viewDataGrid = new CollectionView(
        this.viewDataList.map((Rx, i) => {
            const k = {};
            k["RowId"] = i;
            k["Rx#"] = Rx.Prescription.PrescNum;
            k["Auth Ref"] = Rx.Prescription.ReFillNum;
            return k;
        }), {
        groupDescriptions: this.groupByNames
    }
    );
}
ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}

async patchDefaultValHeaders() {
  const storedWJ = await this._wijimoUtils.getWJSavedData("viewDatSetWj");
  this.wjHeaders = this._wijimoUtils.patchDefHeader("viewDatSetWj", storedWJ);
}

}
