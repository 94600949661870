<div class="modal-header">
    <h4 class="modal-title"> Partial Fill Quantity remaining for the Rx#: <b>{{RxNumber}}</b>  and Auth#: <b>{{AuthNumber}} </b></h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body exprx--common-block erx--body edit--drug newrx--body">
    <div class="eprx--block__content">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <!-- <th>Rx#</th>
                            <th>Auth Ref#</th> -->
                            <th>Ref#</th>
                            <th>Fill#</th>
                            <th>Qty Rem on Refill</th>
                            <th>Quantity ordered</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr *ngFor="let item of totalRxs">
                                <!-- <td>{{item?.PrescNum}}</td>
                                <td>{{item?.ReFillNum}}</td> -->
                                <td>{{item?.PrescReFill}}</td>
                                <td>{{item?.fillNumber}}</td>
                                <td>{{item?.qtyRem}}</td>
                                <td>{{item?.qtyOrdered}}</td>
                                <td [title]="(item?.IsDisabled) ? item?.titleHoverOver : (item?.qtyRem && item.islatestpartialrefill) ? '' :'No Quantity Remaining'">
                                    <input #itemEvent
                                     (click)="optedRx($event, item?.index)"
                                     [disabled]="(item?.qtyRem && item.islatestpartialrefill && !item?.IsDisabled) ? false : true" class="form-check-input" type="checkbox">
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <div class="modal-footer px-0">
        <button type="button"  *ngIf="isSelected" class="hotkey_success"  placement="top" ngbTooltip="Deferred Rx's Can be Filled, and non Deferred Rx's can be Refilled."
        (click)="IsQuickRefill? quickRefillProcess():goToRefillForSelectedRx()" appShortcutKey
        [AltKey]="'r'"><span>R</span>
        {{IsQuickRefill ? 'Quick Refill': 'Refill/Fill'}}</button>
        <button type="button"  [title]="(item?.IsDisabled) ? item?.titleHoverOver : ''" *ngIf="!isSelected" class="inactive"><span>R</span>
            {{IsQuickRefill ? 'Quick Refill': 'Refill/Fill'}}</button>
        <button type="button" class="hotkey_success" (click)="closeModal()" appShortcutKey
            [AltKey]="'c'"><span>C</span>
            CANCEL</button>
    </div>
</div>
