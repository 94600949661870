import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services';
import { CommonStore } from 'src/app/store';

@Component({
  selector: 'app-printer-config',
  templateUrl: './printer-config.component.html'
})
export class PrinterConfigComponent implements OnInit {

    totalPrintSets: any;
    filteredPrintsets: any;
    DefaultLabelForm: FormGroup;

    @ViewChild("PrinterConfig", { static: true })
    PrinterConfig: any;
    selectedPrintset: any;
    optedPrintset: any;
    modalRef: any;
    unsubscribe$: Subject<void> = new Subject();

    @Output()
    IsPopUpClosed = new EventEmitter<any>();
    printerList: any;
    selectedDefaultPrinter: any;
    selectedReportPrinter: any;
    selectedReceiveMode: any;
    recvModeList: { Id: string; Name: string; }[];
    actvList: any;

    constructor(
        private _commonSvc: CommonService,
        private _modalService: NgbModal,
        private _cmnstr: CommonStore
    ) { 
        this.recvModeList = [{Id: 'B', Name: 'Buffer Mode'}, {Id: 'I', Name: 'Immediate Mode'}]
    }

    ngOnInit() {
        const InterfaceSet = this._cmnstr.interfacePharmConfigSetting$["source"]["value"];
        const configDet = InterfaceSet && InterfaceSet.length ? InterfaceSet[0].configurationDetail : null;
        this.actvList = configDet && configDet.sendData && configDet.sendData.length ? configDet.sendData.filter((item: any) => item.active) : null;
        this._cmnstr.allPrinters$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.printerList = resp;
                if (this.printerList && this.printerList.length) {
                    this.checkForDefaultLabelPrinter();
                }
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    checkForDefaultLabelPrinter() {
        if (this.printerList && this.printerList.length) {
            if (localStorage.getItem("DefaultLabelPrinter")) {
                let defLabPrinter = this._commonSvc.DecryptData(localStorage.getItem("DefaultLabelPrinter"));
                if (this.printerList.find(elm => elm === defLabPrinter)) {
                    this.selectedDefaultPrinter = defLabPrinter;
                }
            }

            if (localStorage.getItem("DefaultReportPrinter")) {
                let defRepPrinter = this._commonSvc.DecryptData(localStorage.getItem("DefaultReportPrinter"));
                if (this.printerList.find(elm => elm === defRepPrinter)) {
                    this.selectedReportPrinter = defRepPrinter;
                }
            }
            if (localStorage.getItem("DefaultReceiveMode")) {
                let defRecvMode = this._commonSvc.DecryptData(localStorage.getItem("DefaultReceiveMode"));
                const selectedMode = this.recvModeList.find(elm => elm.Id === defRecvMode);
                this.selectedReceiveMode = selectedMode.Id;
            }
            this.modalRef = this._modalService.open(this.PrinterConfig, { centered: true, keyboard: false, backdrop: false });
        }

    }

    optedInterfaceMode(eve) {
        if (eve) {
            this.selectedReceiveMode = eve.Id;
            localStorage.setItem("DefaultReceiveMode", this._commonSvc.encryptData(eve.Id));
        } else {
            this.selectedReceiveMode = null;
            localStorage.removeItem("DefaultReceiveMode")
        }
    }

    optedPrinter(eve) {
        this.selectedDefaultPrinter = eve;
        if (eve) {
            localStorage.setItem("DefaultLabelPrinter", this._commonSvc.encryptData(eve));
        }
    }

    optedReportPrinter(eve) {
        this.selectedReportPrinter = eve;
        if (eve) {
            localStorage.setItem("DefaultReportPrinter", this._commonSvc.encryptData(eve));
        }
    }

    closeModal() {
        if (this.modalRef) {
        this.modalRef.close();
        this.modalRef = null;
    }
        this.IsPopUpClosed.emit(true);
    }
}
