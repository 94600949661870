import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    DateFormatPipe,
    StringSepWithSpace,
    UdpCurrencyMaskPipe,
    PendTransLength,
    SortByProp,
    SettingKeysPipe,
    StringSepWithEnter,
    TokenSeparatorPipe,
    FilterRelationCat,
    FilterInsurance,
    TrimStringsPipe
} from "./";
import { LocalTimePipe } from './local-time.pipe';
import { CheckBillStatusPipe } from './check-bill-status.pipe';
import { DatetimeUtcPipe } from "./datetime-utc.pipe";
import { TitlecaseForTextPipe } from './titlecase-for-text.pipe';
import { DateTimeUtcPipePipe } from './date-time-utc-pipe.pipe';
import { ConvertTimeFormatPipe } from './convert-time-format.pipe';
import { ThreefixedPipe } from './threefixed.pipe';
import { ThreeFixedDollarPipe } from './three-fixed-dollar.pipe';
import { ToFixedDollarPipe } from './to-fixed-dollar.pipe';
import { IntegerFloatPipe } from "./integer-float-format.pipe";

@NgModule({
    imports: [CommonModule],
    declarations: [
        DateFormatPipe,
        StringSepWithSpace,
        UdpCurrencyMaskPipe,
        PendTransLength,
        SortByProp,
        SettingKeysPipe,
        StringSepWithEnter,
        TokenSeparatorPipe,
        FilterInsurance,
        FilterRelationCat,
        LocalTimePipe,
        CheckBillStatusPipe,
        TrimStringsPipe,
        DatetimeUtcPipe,
        TitlecaseForTextPipe,
        DateTimeUtcPipePipe,
        ConvertTimeFormatPipe,
        ThreefixedPipe,
        ThreeFixedDollarPipe,
        ToFixedDollarPipe,
        IntegerFloatPipe
    ],
    exports: [
        DateFormatPipe,
        StringSepWithSpace,
        UdpCurrencyMaskPipe,
        PendTransLength,
        SortByProp,
        SettingKeysPipe,
        StringSepWithEnter,
        TokenSeparatorPipe,
        FilterInsurance,
        FilterRelationCat,
        LocalTimePipe,
        CheckBillStatusPipe,
        TrimStringsPipe,
        DatetimeUtcPipe,
        TitlecaseForTextPipe,
        DateTimeUtcPipePipe,
        ConvertTimeFormatPipe,
        ThreefixedPipe,
        ThreeFixedDollarPipe,
        ToFixedDollarPipe,
        IntegerFloatPipe
    ],
    providers: [
        DateFormatPipe,
        StringSepWithSpace,
        UdpCurrencyMaskPipe,
        PendTransLength,
        SortByProp,
        SettingKeysPipe,
        StringSepWithEnter,
        TokenSeparatorPipe,
        FilterInsurance,
        FilterRelationCat,
        LocalTimePipe,
        CheckBillStatusPipe,
        TrimStringsPipe,
        DatetimeUtcPipe,
        ConvertTimeFormatPipe
    ]
})
export class PipeModule {}
