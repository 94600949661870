<ng-template #ErxResult let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">eRx Result</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Close click');closeModal()"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div
        class="col-md-12 text-right top_1rem left_1_2rem"
    >
        <button
            class="hotkey_success pull-right"
            type="submit"
            (click)="printDocument()"
            appShortcutKey [AltKey]="'p'"
        >
          <span>P</span>  Print
        </button>
        <button type="button" class="hotkey_success" (click)="c('Close click');closeModal()" appShortcutKey [AltKey]="'c'">
            <span>C</span>Close
        </button>
    </div>
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <div class="editpatient--body newrx--body insurance--body">
                            <div class="row">
                                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 padding--right__7">
                                    <div class="eprx--block__content" id="tab77">
                                        <div class="row padding_1_428_rem padding_top_1_5rem padding_left_5rem" id="tab77">
                                            <div class="col-md-12 padding_0_714_rem border_1px_solid" id="tab77">
                                                <div class="row text-center">
                                                    <div class="col-md-1">
                                                        <h2 class="padding_top_2rem">
                                                            eRx
                                                        </h2>
                                                    </div>
                                                    <div class="col-md-8 text-center">
                                                        <h5>
                                                            <label>{{ detailsInfo?.PrescName ? (detailsInfo?.PrescName | titlecase) : "--" }}, {{
                                                                detailsInfo?.PrescSpi }}
                                                            </label>
                                                        </h5>
                                                        <h5>
                                                            <label>A Clinic {{ detailsInfo?.DigestValue }}
                                                            </label>
                                                        </h5>
                                                        <p>
                                                            {{ detailsInfo?.PrescAddress }}
                                                        </p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>NPI#</label>
                                                            <div class="label--data">
                                                                {{ detailsInfo?.PrescNpi ? detailsInfo?.PrescNpi : "--" }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                            <label>Phone#</label>
                                                            <div class="label--data">
                                                                {{ detailsInfo?.PrescPhone ? (detailsInfo?.PrescPhone | mask : "(000) 000-0000") : "--" }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>DEA#</label>
                                                            <div class="label--data">
                                                                {{ detailsInfo?.PrescDea ? _formatsUtil.getDeaFormat(detailsInfo?.PrescDea) : "--" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="exprx--common-block">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading word_break_all text-nowrap overflow-hidden text-truncate" [title]="detailsInfo?.PatName">
                                                            Patient - {{ detailsInfo?.PatName ? (detailsInfo?.PatName | uppercase) : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <label>ID</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.ID ? detailsInfo?.ID : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-3 ">
                                                                <label>DOB</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PatBirthDt | localTime | date : "MM/dd/yyyy" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <label>Gender</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PatGender ? detailsInfo?.PatGender : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <button class="hotkey_primary" (click)="openMoreInfo()" appShortcutKey [InputKey]="'m'"><b>M</b>More Info</button>
                                                            </div>
                                                            <div class="col-3">
                                                                <label>Phone#</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PatPhone | mask : "(000) 000-0000" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8">
                                                                <label>Address</label>
                                                                <div class="label--data" [title]="detailsInfo?.PatAddress">
                                                                    {{ detailsInfo?.PatAddress ? detailsInfo?.PatAddress : "--" }}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="exprx--common-block" *ngIf="detailsInfo?.Prescriberagent">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading">
                                                            Prescriber Agent
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>First Name</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.FirstName : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                                <label>Last Name
                                                                </label>
                                                                <div class="label--data">
                                                                        {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.LastName : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Middle Name</label>
                                                                <div class="label--data">
                                                                        {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.MiddleName : "--" }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- drug -->
                                                <div class="exprx--common-block">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading">
                                                            Drug - <span [ngClass]="detailsInfo?.DrugClass && detailsInfo?.DrugClass > 1 ? 'background--orange' : ''"> {{ detailsInfo?.DrugName ? (detailsInfo?.DrugName  | uppercase) : "--" }} </span>
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>Drug NDC
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.DrugNdc ? detailsInfo?.DrugNdc : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                                <label>Strength</label>
                                                                <div class="label--data" title="{{ detailsInfo?.DrugStrength ? (detailsInfo?.DrugStrength | uppercase) : '--' }}">
                                                                    {{ detailsInfo?.DrugStrength ? detailsInfo?.DrugStrength : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Form</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.DrugForm ? detailsInfo?.DrugForm : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                <label>
                                                                    Potency Unit
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.DrugPotency ? detailsInfo?.DrugPotency : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <button *ngIf="detailsInfo?.isCompoundDrug" class="hotkey_primary" (click)="openCompDrug()" appShortcutKey [InputKey]="'c'"><b>C</b>Comp. Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="exprx--common-block">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading">
                                                            Rx# - {{ detailsInfoObject?.RxNo ? detailsInfoObject?.RxNo : (detailsInfo?.RxNum ? detailsInfo?.RxNum : "--")}}
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>Auth Refills
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RefillNum ? detailsInfo?.RefillNum : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                                <label>Quantity</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RxQty ? detailsInfo?.RxQty : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Days Supply</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RxDays ? detailsInfo?.RxDays : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    DAW
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RxDaw ? detailsInfo?.RxDaw : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Order Date
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ rxOrdDt? rxOrdDt : '--'}}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Effective Date
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ effectiveDate | date : "MM/dd/yyyy" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Prs. order Ref
                                                                </label>
                                                                <div class="label--data" [title]="detailsInfo?.PrsOrdRef">
                                                                    {{ detailsInfo?.PrsOrdRef ? detailsInfo?.PrsOrdRef : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Diagnosis Code</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.diagnosis ? detailsInfo?.diagnosis : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="detailsInfo?.prohibitRenewalRequest">
                                                                <label>
                                                                    Renewal Request Prohibited</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.prohibitRenewalRequest ? "YES" : "NO" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="detailsInfo?.RxFillIndicator">
                                                                <label>
                                                                    RxFillIndicator</label>
                                                                <div class="label--data" [title]="detailsInfo?.RxFillIndicator" >
                                                                    {{ detailsInfo?.RxFillIndicator ? detailsInfo?.RxFillIndicator : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="detailsInfo?.PriorAuthStatus">
                                                                <label>
                                                                    Prior Authorization Status</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PriorAuthStatus ? detailsInfo?.PriorAuthStatus : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12" *ngIf="detailsInfo?.PriorAuthNumber">
                                                                <label>
                                                                    Prior Authorization Number</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PriorAuthNumber ? detailsInfo?.PriorAuthNumber : "--" }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="exprx--common-block">
                                                        <div class="row">
                                                            <div class="col-md-6 padding-0">
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label class="control-label full--width">
                                                                            <span>
                                                                                Sig Info
                                                                            </span>
                                                                            <textarea rows="2" cols="30" class="custom--text__area" placeholder="Sig Info" readonly wrap="hard">
                                                                            {{
                                                                                        detailsInfo?.RxSig
                                                                                            ? detailsInfo?.RxSig
                                                                                            : "--"
                                                                                    }}</textarea
                                                                                >
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-md-6 padding-0"
                                                                >
                                                                    <div
                                                                        class="col-md-12"
                                                                    >
                                                                        <div
                                                                            class="form-group"
                                                                        >
                                                                            <label
                                                                                class="control-label full--width"
                                                                            >
                                                                                <span>
                                                                                    Pharmacist
                                                                                    Note</span
                                                                                >
                                                                                <textarea
                                                                                    rows="2"
                                                                                    cols="30"
                                                                                    class="custom--text__area"
                                                                                    placeholder="Pharmacist Note"
                                                                                    readonly
                                                                                >
     {{ detailsInfo?.RxMsg ? detailsInfo?.RxMsg : "--" }}</textarea
                                                                                >
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div
                                                                    class="col form-group "
                                                                >
                                                                    <label
                                                                        class="control-label full--width"
                                                                    >
                                                                        <span>
                                                                            The
                                                                            Prescription
                                                                            will be
                                                                            filled
                                                                            generically
                                                                            unless
                                                                            prescriber
                                                                            writes
                                                                            "DAW" in
                                                                            the box
                                                                            below</span
                                                                        >
                                                                        <textarea
                                                                            rows="1"
                                                                            cols="30"
                                                                            class="custom--text__area"
                                                                            readonly
                                                                        >
                                                    {{
                                                                                detailsInfo?.DawName
                                                                                    ? detailsInfo?.DawName
                                                                                    : "--"
                                                                            }}</textarea
                                                                        >
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 top_1rem">
                                        <div class="exprx--common-block margin_right_2_5rem">
                                            <div class="eprx--block__header">
                                                <div class="eprx--header__heading">
                                                    <div class="col-sm-12">
                                                        <span class="patient">Transaction Information
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="eprx--block__content">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Response Status</label>
                                                        <div class="label--data">
                                                            {{ responseState ? responseState : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                        <label>Transaction</label>
                                                        <div>
                                                            {{ detailsInfo?.Transaction ? detailsInfo?.Transaction : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Message Type</label>
                                                        <div class="label--data">
                                                            {{ detailsInfo?.MessageDescription ? detailsInfo?.MessageDescription : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Date</label>
                                                        <div class="label--data">
                                                            {{ (eRxListingWJ ?.currentItem)[ "Date & Time" ] | date : "MM/dd/yyyy" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Document Ids</label>
                                                        <div class="label--data">
                                                            {{ detailsInfo?.DocIDs ? detailsInfo?.DocIDs : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Message Ref</label>
                                                        <div class="label--data">
                                                            {{ detailsInfo?.MsgRef | uppercase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

            <div class="exprx--common-block margin_right_2_5rem" *ngIf="detailsInfo?.Diagnoses">
                    <div class="box box-default box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Diagnosis Details
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-12 dose--schedule__block">
                                    <table class="table table-striped table-bordered table-sm width_100per">
                                        <thead>
                                            <tr class="background_primary">
                                                <th scope="col">
                                                    CODE
                                                </th>
                                                <th scope="col">
                                                   DESCRIPTION
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let diag of detailsInfo?.Diagnoses; let k=index">
                                                <td>{{ diag.DIAGCODE | uppercase }}
                                                </td>
                                                <td>{{ diag.DIAGDESCR | uppercase}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                        <div class="row margin_right_2_5rem">
                                            <div class="col-md-12 form-group" id="tab7">
                                                <label class="control-label full--width">
                                                    <span> Other Information</span>
                                                    <textarea rows="4" cols="30" class="custom--text__area" readonly>
                                                                                                                                    {{
                                                                                                                                            detailsInfo?.OtherInfo
                                                                                                                                                ? detailsInfo?.OtherInfo
                                                                                                                                                : "--"
                                                                                                                                        }}</textarea
                                                                                                                                    >
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-md-12 form-group">
                                                                                                                                <label
                                                                                                                                    class="control-label full--width"
                                                                                                                                >
                                                                                                                                    <span> Messages</span>
                                                                                                                                    <textarea
                                                                                                                                        rows="4"
                                                                                                                                        cols="30"
                                                                                                                                        class="custom--text__area"
                                                                                                                                        readonly
                                                                                                                                    >
                                                                                                                                    {{
                                                                                                                                        detailsInfo?.Message
                                                                                                                                            ? detailsInfo?.Message
                                                                                                                                            : "--"
                                                                                                                                    }}</textarea>
                                                                                                                                </label>


                                                                                    </div>
                                                                                    <div class="col-md-12 form-group">
                                                                                        <label
                                                                                            class="control-label full--width"
                                                                                        >
                                                                                            <span>DEA# compliance</span>
                                                                                            <textarea
                                                                                                rows="4"
                                                                                                cols="30"
                                                                                                class="custom--text__area"
                                                                                                readonly
                                                                                            >
                                                                                            {{
                                                                                                detailsInfo?.EPCSMessage
                                                                                                    ? detailsInfo?.EPCSMessage
                                                                                                    : "--"
                                                                                            }}</textarea>
                                                                                        </label>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    <!-- <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey [CtrlKey]="'c'">
                <span>C</span>Close
        </button>
    </div> -->
</ng-template>

<ng-template #erxExtraInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">eRx Extra Info</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Observation Measurements</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12" >
                                <wj-flex-grid #previewControl [headersVisibility]="'Column'" [itemsSource]="detailsInfo?.observationDetails" [isReadOnly]="true"
                                    [selectionMode]="'None'" [ngClass]="!detailsInfo?.observationDetails ? 'no-data':''">
                                    <wj-flex-grid-column [header]="'BODY MASS INDEX (BMI)'" [width]="300" [binding]="'biometricName'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'VALUE'" [width]="300" [binding]="'value'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'UNIT'" [width]="300" [binding]="'unit'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'OBSERVATION DATE'" [width]="300">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <div> {{item['observationDate'] | date: 'MM/dd/yyyy'}} </div>
                                        </ng-template>
                                    </wj-flex-grid-column>
                                    <app-no-data-found *ngIf="!detailsInfo?.observationDetails"></app-no-data-found>
                                </wj-flex-grid>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Observation Notes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12">
                                <textarea  [value]="detailsInfo?.observationNotes" class="form-control custom--text__area" [rows]="3" [readOnly]="true"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Benefits Co-ordination</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content erx--body margin-bottom-1rem">
                        <span>
                            <wj-flex-grid #flex [headersVisibility]="'Column'" [itemsSource]="insuranceWJ"
                            [isReadOnly]="true"  [allowSorting]="false" [ngClass]="!detailsInfo?.insuranceDetails.length ? 'no-data' : ''"
                            [selectionMode]="'Row'">
                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of activeHeaders"
                                    [width]="
                                        actvHeader === 'PAYER IDENTIFICATION' ||
                                        actvHeader === 'CARD HOLDER ID' ||
                                        actvHeader === 'CARD HOLDER FIRSTNAME' ||
                                        actvHeader === 'CARD HOLDER LASTNAME' ||
                                        actvHeader === 'CARD HOLDER SUFFIX' ||
                                        actvHeader === 'CARD HOLDER PREFIX'
                                            ? 200
                                            : 100
                                    ">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            {{ item[actvHeader] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="inActv"  [binding]="inActv" [visible]="false" *ngFor="let inActv of inactiveHeader;
                                let i = index" [format]="'d'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div>
                                    {{ item[inActv] }}
                                </div>
                        </ng-template>
                </wj-flex-grid-column>
                <app-no-data-found *ngIf="detailsInfo?.insuranceDetails && !detailsInfo?.insuranceDetails.length"></app-no-data-found>
                            </wj-flex-grid>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="o">
            <b>o</b> OK
        </button>
    </div>
</ng-template>
<ng-template #compList let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Compound Drug Info</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Compound Drug Ingredients</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12">
                                <wj-flex-grid #previewControl [headersVisibility]="'Column'" [itemsSource]="detailsInfo?.compoundIngredients" [isReadOnly]="true"
                                    [selectionMode]="'None'" [ngClass]="detailsInfo?.compoundIngredients && !detailsInfo?.compoundIngredients.length ? 'no-data':''">
                                    <wj-flex-grid-column [header]="'DRUG NAME'" [width]="230" [binding]="'DrugName'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'NDC'" [width]="230" [binding]="'DrugNdc'">
                                    </wj-flex-grid-column>
                                   <wj-flex-grid-column [header]="'STRENGTH'" [width]="'*'" [binding]="'DrugStrength'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'FORM'" [width]="'*'" [binding]="'StrengthForm'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'QUANTITY'" [width]="'*'" [binding]="'Quantity'">
                                    </wj-flex-grid-column>
                                    <app-no-data-found *ngIf="detailsInfo?.compoundIngredients && !detailsInfo?.compoundIngredients.length"></app-no-data-found>
                                </wj-flex-grid>
                            </div>
                            <!-- <ng-template #noRecords>
                                <div class="col-12 text-center">
                                    <h4>No records to display.</h4>
                                </div>
                            </ng-template> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="o">
            <b>o</b> OK
        </button>
    </div>
</ng-template>
