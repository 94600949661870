
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";

@Injectable()
export class PatientCategoryService {
    constructor(private _http: HttpClient) {}

    isPatCatExist(patCatId: string): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Patient_IsCategoryCodeExists.replace(
                "{code}",
                "" + patCatId
            )
        );
    }

    addPatientCategory(patCatData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_AddCategory,
            patCatData
        );
    }

    updatePatientCategory(patCatData: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Patient_UpdateCategory,
            patCatData
        );
    }

    deletePatCat(patCatId: string): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Patient_DeleteCategory.replace(
                "{categoryId}",
                "" + patCatId
            )
        );
    }

    getPatientCatDeleteResponse(patCatId: string): Observable<any> {
        return this._http.get<any>(constant.GET_Patient_CatDeleteResponse.replace("{categoryId}", "" + patCatId));
    }
}
