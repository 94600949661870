<div class="row">
    <div class="col-4">
            <div class="row">
                <div class="col-4 pr-0" >
                    <eprx-input [RxSelectId]="'erxReqQtyNew'" [LabelText]="'Qty'" [ErrorDefs]="{
                        required: 'Required' 
                    }"  [IsRequired]="true" [InputType]="'NUMBER'" [MaskPattern]="'0999999.000'" [ControlName]="'Quantity'" [FormGroupName]="erxReq" 
                         [DecimalValues]="3"  
                        (TriggerSearchValue)=" 
                            setNumberFormat('Qty')
                        "   (EmitOnChangeValue)="patchQtyDispAndQtyOrdered()" [PlaceHolder]="'Qty'" [MaxLength]="11">
                    </eprx-input>
                </div>
                <div class="col-4 pr-0">
                    <eprx-input [RxSelectId]="'erxReqDays'" [LabelText]="'Days'"
                        [PlaceHolder]="'Days'" [ControlName]="'SupplyDays'" [FormGroupName]="erxReq"
                        [InputType]="'NUMBER'" [MaxLength]="3"
                        [DecimalValues]="0" >
                    </eprx-input>
                </div>
                <div class="col-4" >
                    <eprx-select [RxSelectId]="'DAW'" [LabelText]="'DAW'" [PlaceHolder]="''"
                        [ControlName]="'Daw'" [FormGroupName]="erxReq" [List]="DAWList"
                        [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Code'"
                        [LabelForId]="'Name'">
                    </eprx-select>
                </div>
                <div class="col-12 margin_top_9">
                    <eprx-input [RxSelectId]="'rxSigCode'"
                        [LabelText]="'Sig Code List'" [PlaceHolder]="''"
                        (TriggerChangeValue)="getSigCodeDescOnChange($event)"
                        (TriggerOnEnterValue)="getSigCodeDescOnEnter($event)"
                        [DebounceTime]="'75'"
                        [ControlName]="'SigCode'"  [IsRequired]="true"
                        [FormGroupName]="erxReq" [FormGroupInvalid]="false"
                        [InputErrors]="(erxReq?.controls)['SigCode']?.errors"
                        [ErrorDefs]="{ required: 'Required'}"
                        (TriggerOnTabbing)="getSigCodeDescOnEnter($event)"
                        (TriggerSearchValue)="trimSigCodeOnBlur($event)">
                    </eprx-input>
                </div>
                <div class="col-12 margin_top_9">
                    <eprx-text-area [LabelText]="'Sig Description '" [PlaceHolder]="'Sig'"  [AutoFocus]="false" [ControlName]="'SigDesc'" [FormGroupName]="erxReq" [Rows]="1"
                        [FormGroupInvalid]="false"
                        [IsRequired]="true"
                        [InputErrors]="(erxReq?.controls)['SigDesc']?.errors"
                        [ErrorDefs]="{ required: 'Required'}" >
                    </eprx-text-area>
                </div>
            </div>
    </div>
    <div class="col-8 pl-0">
        <div class="row">
            <div class="col-3">
                <eprx-input [LabelText]="' AWP'" [PlaceHolder]="'AWP'"
                    [ControlName]="'AWPPack'" [FormGroupName]="erxReq"
                    [DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'"
                    [MaskPattern]="'099999.00'" [RxSelectId]="'AWPPack'">
                </eprx-input>
            </div>
            <div class="col-3">
                <eprx-input
                [RxSelectId]="'rxUnitPriceUNC'"
                [LabelText]="'U&amp;C'"
                [PlaceHolder]="''"
                [ControlName]="'UnitPriceUNC'"
                [FormGroupName]="erxReq"
                [TabIndex]="0"
                [InputType]="'CURRENCY'"
                (TriggerSearchValue)="setNumberFormat('UnitPriceUNC')"
                [IsInputElemHidden]="false"
                [IsDisabled]="false"
                [DecimalValues]="2"
                [ErrorDefs]="{zeroValue: 'Value must be greater than zero'}"
            >
            </eprx-input>
            </div>
            <div class="col-3">
                <eprx-select
                [RxSelectId]="'rxPriceSchId'"
                [LabelText]="'Price CD'"
                [PlaceHolder]="''"
                [ControlName]="'PriceSchId'"
                [TabIndex]="0"
                [FormGroupName]="erxReq"
                [List]="priceSchdlList"
                [HasMultiple]="false"
                [BindLabel]="'PriceCode'"
                [BindLabel2]="'Name'"
                [BindValue]="'Id'"
                [LabelForId]="'Name'"
                (TriggerSelectValue)="patchQtyDispAndQtyOrdered('PriceScheduleId')"
                [IsDisabled]="false"
            >
            </eprx-select>
            </div>
            <div class="col-3">
                <eprx-select
                [RxSelectId]="'rxDiscSchId'"
                [LabelText]="'Disc CD'"
                [PlaceHolder]="''"
                [ControlName]="'DiscSchId'"
                [FormGroupName]="erxReq"
                [List]="discntCodeList"
                [HasMultiple]="false"
                [BindLabel]="'DiscCode'"
                [BindLabel2]="'Name'"
                [BindValue]="'Id'"
                [TabIndex]="0"
                [LabelForId]="'Name'"
                (TriggerSelectValue)="triggerDiscountSchedule()"
                [Title]=""
                [IsDisabled]="false"
                [IsInputElemHidden]="false"
            >
            </eprx-select>


        </div>

        </div>
        <div class="row margin_top_6">

            <div class="col-3">
                <eprx-input
                [RxSelectId]="'rxPrice'"
                [LabelText]="'Price'"
                [PlaceHolder]="''"
                [ControlName]="'Price'"
                [FormGroupName]="erxReq"
                [TabIndex]="'-1'"
                [InputType]="'CURRENCY'"
                [ErrorDefs]="{zeroValue: 'Value must be greater than zero'}"
                (TriggerSearchValue)="setNumberFormat('Price')"
            >
          </eprx-input>
            </div>
            <div class="col-3">
                <eprx-input [LabelText]="'Other Amount'" [PlaceHolder]="''"
                        [ControlName]="'OthAMTPaid'" [FormGroupName]="erxReq"  [IsDisabled]="true"
                        [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                        (TriggerSearchValue)="setNumberFormat('OthAMTPaid')">
                    </eprx-input>
            </div>
         <div class="col-3">
                <eprx-input
                [RxSelectId]="'rxDiscount'"
                [LabelText]="'Discount'"
                [PlaceHolder]="''"
                [ControlName]="'Discount'"
                [FormGroupName]="erxReq"
                [TabIndex]="0"
                [InputType]="'CURRENCY'"
                [Title]="'Discount'"
                [IsDisabled]="false"
                (TriggerSearchValue)="setNumberFormat('Discount')"
            >
            </eprx-input>
            </div>
            <div class="col-3">
                <eprx-input
            [RxSelectId]="'rxBalDue'"
            [LabelText]="'Bal Due'"
            [PlaceHolder]="''"
            [ControlName]="'BalDue'"
            [FormGroupName]="erxReq"
            [InputType]="'CURRENCY'"
            [TabIndex]="0"
            (TriggerSearchValue)="setNumberFormat('BalDue')"
            [IsDisabled]="false"
        >
        </eprx-input>
            </div>

        </div>

        <div class="row margin_top_9">

        <div class="col-3">
            <eprx-input
                [RxSelectId]="'rxProfFee'"
                [LabelText]="'Professional Fee'"
                [PlaceHolder]="''"
                [ControlName]="'ProfFee'"
                [FormGroupName]="erxReq"
                [TabIndex]="-1"
                [InputType]="'CURRENCY'"
                (TriggerSearchValue)="setNumberFormat('ProfFee')"
                [IsDisabled]="false"
            >
            </eprx-input>
        </div>
            <div class="col-3">
                <eprx-input
                [RxSelectId]="'rxTotalAmt'"
                [LabelText]="'Total Price'"
                [PlaceHolder]=""
                [ControlName]="'TotalAmt'"
                [TabIndex]="0"
                [FormGroupName]="erxReq"
                [InputType]="'CURRENCY'"
                [InputValue]=""
                (TriggerSearchValue)="setNumberFormat('TotalAmt')"
                [IsDisabled]="true"
            >
            </eprx-input>
            </div>
            <div class="col-3">
                <eprx-input [LabelText]="'Copay'" [PlaceHolder]="" [ControlName]="'Copay'"
                [FormGroupName]="erxReq" [DropSpclChar]="false" [DecimalValues]="2"
                [MaskPattern]="'099999.00'" [RxSelectId]="'Copay'" [ReadOnly]="false"
                (TriggerOnBlur)="convertToDecimal($event,'Copay')">
            </eprx-input>
            </div>
        </div>
    </div>
</div>
