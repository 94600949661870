<ng-template #IOUPOPUP let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>IOU</h4>
        <span body>
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="exprx--common-block insurance--body erx--body">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span> Rx Dispensed Summary</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                                <button class="hotkey_success hotkey_primary--Overriden" (click)="addIOUToArray()" *ngIf="!disableAdd && QtyOwed" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label> Rx# </label>
                                        <div class="label--data"> {{presc ? presc : "--"}} </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label> Refill# </label>
                                        <div class="label--data"> {{refillNum ? refillNum : 0}}
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label> Rx Date </label>
                                        <div class="label--data"> {{TodayDate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                                        <label> Drug </label>
                                        <div class="label--data"> {{drugName ? drugName : "--"}}
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label> Drug NDC </label>
                                        <div class="label--data"> {{drugNDC ? drugNDC : "--"}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label> Total Qty. </label>
                                        <div class="label--data">{{totalQty ? (+totalQty?.OrdQty).toFixed(3) : "--"}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label> Actually Dispensed </label>
                                        <div class="label--data">{{actualDisp ? (actualDisp).toFixed(3) : "0.000"}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label> Qty. Owed </label>
                                        <div class="label--data">{{QtyOwed ? (QtyOwed).toFixed(3) : "0.000"}}</div>
                                    </div>
                                </div>
                                <!-- <div [ngClass]="true ? 'hide-transistion' : 'show-transistion'" class="show-transistion"> -->
                                <hr>
                                <div [formGroup]="IOUFG" class="col-md-12 col-lg-12 col-sm-12 col-xs-12 dose--schedule__block">
                                    <table class="table table-striped table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Actions
                                                </th>
                                                <th scope="col">
                                                    Date Dispensed
                                                </th>
                                                <th scope="col">
                                                    Actually Dispensed &nbsp; &nbsp;
                                                </th>
                                                <th scope="col">
                                                    Owed
                                                </th>
                                                <th scope="col">
                                                    User
                                                </th>
                                                <th scope="col">
                                                    Comments
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="Iou">
                                            <tr class="h-auto" *ngFor="let IouData of Iou.controls; let i=index" style="height: auto;">
                                                <td>
                                                    <i class="far  fa-edit  actions " title="edit" (click)="editIOUValues(i)"></i>
                                                    <i class="far fa-trash-alt  actions danger-actve" title="delete" (click)="deleteIOUValue(i)"></i>
                                                </td>
                                                <td id=i *ngIf="!editIOU[i]">
                                                    <eprx-date-picker [LabelText]="''" [PlaceHolder]="'Disp Dt'" [ControlName]="'DispDt'" [FormGroupName]="IouData" [MinDate]="minDate"
                                                        [MaxDate]="" [IsDisabled]="false">
                                                    </eprx-date-picker>
                                                </td>
                                                <td *ngIf="editIOU[i]">
                                                    <eprx-date-picker [LabelText]="''" [PlaceHolder]="'Disp Dt'" [ReadOnly]="true" [ControlName]="'DispDt'" [FormGroupName]="IouData"
                                                        [MinDate]="minDate" [MaxDate]="" [IsDisabled]="false">
                                                    </eprx-date-picker>
                                                </td>
                                                <td id=i *ngIf="!editIOU[i]">
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Disp qty'" (TriggerChangeValue)="checkDispensedQty($event, i)"
                                                    (TriggerOnEnterValue)="updateDispensedQty(i)" (TriggerSearchValue)="updateDispensedQty(i)"
                                                    [ControlName]="'DispQty'"  [FormGroupName]="IouData" [IsDisabled]="!QtyOwed">
                                                    </eprx-input>
                                                </td>
                                                <td id=i *ngIf="editIOU[i]">
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Disp qty'" [ReadOnly]="true" [ControlName]="'DispQty'"
                                                    [FormGroupName]="IouData" [IsDisabled]="true">
                                                    </eprx-input>
                                                </td>
                                                <td id=i *ngIf="!editIOU[i]">
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'OWED'" [ControlName]="'OWED'" [FormGroupName]="IouData" [IsDisabled]="true">
                                                    </eprx-input>
                                                </td>
                                                <td id=i *ngIf="editIOU[i]">
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'OWED'" [ReadOnly]="true" [ControlName]="'OWED'" [FormGroupName]="IouData" [IsDisabled]="true">
                                                    </eprx-input>
                                                </td>
                                                <td id=i>
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'App Uer'" [ReadOnly]="true" [ControlName]="'AppUser'" [FormGroupName]="IOUFG" [IsDisabled]="true">
                                                    </eprx-input>
                                                </td>
                                                <td id=i *ngIf="!editIOU[i]">
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Comments'" [ControlName]="'Comments'" [FormGroupName]="IouData">
                                                    </eprx-input>
                                                </td>
                                                <td id=i *ngIf="editIOU[i]">
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Comments'" [ReadOnly]="true" [ControlName]="'Comments'" [FormGroupName]="IouData">
                                                    </eprx-input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </span>
        <button footer class="hotkey_success" (click)="addComplete()" *ngIf="!disableAdd && QtyOwed" appShortcutKey [AltKey]="'d'"><span>D</span> Complete Dispensing</button>
        <button footer class="hotkey_success" (click)="updateIOUValues()" appShortcutKey [AltKey]="'s'"><span>S</span> Save</button>
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel</button>
    </app-custom-modal>
</ng-template>
<ng-template #DISPQTY let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePopUpModal()">
        <h4 header> Warning!! </h4>
        <span body>
            Dispensed Quantity should be less than or equal to owed Qty.
        </span>
        <button footer class="hotkey_primary" ngbAutofocus (click)="closePopUpModal()" appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>
