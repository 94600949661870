import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: "input[ngbtypeaheadpopup]"
})
export class TypeaheadPopupDirective {
    isCompleted = false;
    @HostListener("focus", ["$event.target"])
    onClick(t) {
        if (!this.isCompleted) {
            t.dispatchEvent(new Event("input"));
            this.isCompleted = true;
        }
    }

    @HostListener("keydown.enter", ["$event.target"])
    onClick2(t) {
        if(this.isCompleted) {
            this.isCompleted = false;
        }
        if (!this.isCompleted) {
            t.dispatchEvent(new Event("input"));
            this.isCompleted = true;
        }
    }
}


