<ng-template #PrintFax let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal('cancel')">
        <h4 header>FAX</h4>
        <span body>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-md-7">
                        <div class="exprx--common-block" *ngIf="!isFromTrans">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        Currently Selected Rxs
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <wj-flex-grid #printRef [headersVisibility]="'Column'" [itemsSource]="printRefWJ"
                                    class="print-ref-Wijmo wijmo-hide-scroll-x" [isReadOnly]="true" [selectionMode]="'Row'"
                                    [columnPicker]="'faxRefReqWJ'" (initialized)="init(printRef)" (dblclick)="onlyOneValue(printRef,$event)">
                                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                        [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index" [align]="'center'">
                                    </wj-flex-grid-column>
                                    <app-no-data-found *ngIf="!hasDataCrBtcFax"></app-no-data-found>
                                </wj-flex-grid>
                            </div>
                        </div>
                        <div class="exprx--common-block">
                            <div clas="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                        <eprx-input [LabelText]="'Fax Subject'" [PlaceHolder]="''" [HasControl]="true"
                                            [ControlName]="'FaxSubject'" [FormGroupName]="faxFG"
                                            (TriggerOnBlur)="faxSubject($event)">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                        <eprx-radio [LabelText]="'Send Fax To'" [ControlName]="'prescOrFac'"
                                            [FormGroupName]="faxFG" [List]="['Prescriber', 'Facility']"
                                            [ValueList]="[0,1]" [IDForList]="['Presc', 'Fac']" [IsDisabled]="true">
                                        </eprx-radio>
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                        <eprx-input [LabelText]="'From Name'" [PlaceHolder]="'From Name'"
                                            [HasControl]="true" [ControlName]="'FromName'" [FormGroupName]="faxFG"
                                            (TriggerOnBlur)="fromNamee($event)">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                        <eprx-input [LabelText]="'To Name'" [PlaceHolder]="'To Name'"
                                            [HasControl]="true" [ControlName]="'ToName'" [FormGroupName]="faxFG"
                                            (TriggerOnBlur)="toName($event)">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                        <eprx-input [LabelText]="'From Fax#'" [PlaceHolder]="'From Fax#'"
                                            [HasControl]="true" [ControlName]="'FromFaxNumber'" [FormGroupName]="faxFG"
                                            [MaskPattern]="'(000)000-0000'"
                                            (TriggerChangeValue)="fromfaxNumber($event)"
                                            (TriggerOnBlur)="fromfaxNumber($event)" [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                        <eprx-input [LabelText]="'To Fax#'" [PlaceHolder]="'To Fax#'"
                                            [IsDisabled]="disabled" [HasControl]="true" [MaskPattern]="'(000)000-0000'"
                                            [RxSelectId]="'tofaxno'" [ShowMask]="true" [ControlName]="'ToFaxNumber'"
                                            [FormGroupName]="faxFG"
                                            (TriggerChangeValue)="tofaxNumber($event)"
                                            (TriggerOnBlur)="tofaxNumber($event)"
                                            (paste)="toFaxNumberPasteEvent($event)">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-text-area [LabelText]="'Notes'" [ControlName]="'Notes'" [FormGroupName]="faxFG"  (TriggerOnBlur)="notesChange($event)" [HasControl]="true"
                                        [PlaceHolder]="'Notes'" [Rows]="2">
                                      </eprx-text-area>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="exprx--common-block">
                                <div class="eprx--block__header">
                                    <div class="eprx--header__heading">
                                        <div class="col-sm-12 padding-0">
                                            Already Batched Rxs
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <wj-flex-grid #batchedRxss [headersVisibility]="'Column'" [itemsSource]="prepareBatchedRxss" [ngClass]="hasDataFrAlrFax ? 'fax-batch-rxs-wijmo' : 'no-data'" [isReadOnly]="true"   [columnPicker]="'faxbatchedRxsRefReqWJ'" (initialized)="initBatchedRxss(batchedRxss)">
                                        <wj-flex-grid-column [header]="rxBatchheader['hName']" [binding]="rxBatchheader['hName']" [visible]="rxBatchheader['isVisible']"
                                        [width]="rxBatchheader['width']" *ngFor="let rxBatchheader of wjHeadersForprepareBatchedRxs; let i = index" [align]="'center'">
                                        </wj-flex-grid-column>
                                        <app-no-data-found *ngIf="!hasDataFrAlrFax"></app-no-data-found>
                                    </wj-flex-grid>
                                </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-md-11 col-xs-11 col-sm-11 col-lg-11">
                                    <p><b>Request Would be sent using SFax service</b></p>
                                </div>
                                <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                    <eprx-date-picker [LabelText]="'Date'" [ControlName]="'Date'"
                                        [FormGroupName]="faxFG" [MinDate]="" [MaxDate]="maxDate"
                                        [ErrorDefs]="{ inValid:'Invalid Date'}">
                                    </eprx-date-picker>
                                </div>
                                <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                    <eprx-input type="'time'" [LabelText]="'Time'" [ControlName]="'Time'"
                                        [PlaceHolder]="'HH:MM:SS'" [FormGroupName]="faxFG">
                                    </eprx-input>
                                </div>
                                <div class="col-md-4 col-xs-4 col-sm-4 col-lg-4">
                                </div>
                                
                                <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                    <div class="d-flex flex-row">
                                        <div class="col-8">
                                            <eprx-radio [ControlName]="'MultiSingleRxPg'" [LabelText]="'Format'"
                                            [FormGroupName]="faxFG" [List]="['Multiple Rxs Per Page', 'Single Rx Per Page']"
                                            [ValueList]="['1','0']" [IDForList]="['Multiple', 'Single']"
                                            [IsDisabled]="isFromTrans" (TriggerSelectValue)="rxPage($event)">
                                        </eprx-radio>
                                        </div>
                                        <div class="col-4">
                                            <eprx-check-box [LabelText]="'Include Cover Page'"
                                            [ControlName]="'IncludeCoverPage'" [FormGroupName]="faxFG"
                                            [IsDisabled]="isFromTrans" (TriggerChangeValue)="isChecked($event)">
                                        </eprx-check-box>
                                        </div>
                                    </div>
                                    <div  *ngIf="showPreview" >
                                        <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" 
                                            height="550px" type="application/pdf" ></iframe>
                                    </div>
                                </div>
                                <div class="col-12">&nbsp;</div>
                                <!-- <div class="col-md-12 pull-right padding-1">
                                    <button footer class="hotkey_primary" *ngIf="!isFromTrans" (click)="printLocally()"
                                        appShortcutKey [CtrlKey]="'p'">
                                        <span>P</span>
                                        Print Locally
                                    </button>
                                    <button footer class="inactive" *ngIf="isFromTrans">
                                        Print Locally
                                    </button>
                                    <button *ngIf="!disableFaxAndBatch" footer class="hotkey_primary" id="faxnow"
                                        appShortcutKey [CtrlKey]="'f'" (click)="batchFax(false)">
                                        <span>F</span>
                                        Fax Now
                                    </button>
                                    <button footer class="inactive" *ngIf="disableFaxAndBatch">
                                        Fax Now
                                    </button>
                                    <button *ngIf="!disableFaxAndBatch" class="hotkey_primary" (click)="batchFax(true)"
                                        appShortcutKey [CtrlKey]="'b'">
                                        <span>B</span>
                                        Batch Fax
                                    </button>
                                    <button footer class="inactive" *ngIf="disableFaxAndBatch">
                                        Batch Fax
                                    </button>
                                    <button footer class="hotkey_primary" (click)="closeModal()" appShortcutKey
                                        [CtrlKey]="'c'">
                                        <span>C</span>
                                        Cancel
                                    </button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-right padding-1">
                        <button footer [ngClass]="!isFromTrans ? 'hotkey_success' : 'inactive'" (click)="!isFromTrans && printLocally()"
                            appShortcutKey [AltKey]="'p'">
                            <span>P</span>
                            Print Locally
                        </button>
                        <button footer [ngClass]="!disableFaxAndBatch ? 'hotkey_success' : 'inactive'" id="faxnow"
                            appShortcutKey [AltKey]="'f'" (click)="!disableFaxAndBatch && batchFax(false)">
                            <span>F</span>
                            Fax Now
                        </button>
                        <button footer [ngClass]="!disableFaxAndBatch ? 'hotkey_success' : 'inactive'" (click)="!disableFaxAndBatch && batchFax(true)"
                            appShortcutKey [AltKey]="'b'">
                            <span>B</span>
                            Batch Fax
                        </button>
                        <button footer class="hotkey_success" (click)="closeModal('cancel')" appShortcutKey
                            [AltKey]="'c'">
                            <span>C</span>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </span>
    </app-custom-modal>
</ng-template>


<ng-template #MissingPop let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalforMissing()">
        <h4 header>Warning!!</h4>
        <span body>
            <p>Fax# missing for the following prescribers:</p>
            <div *ngFor="let showName of showPrescNames">
                <label>{{showName | uppercase}} </label>
            </div>
            <br>
            <p>Do you want to Update the Fax#'s for the above prescribers or skip it?</p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="updateFaxNo()" appShortcutKey
            InputKey="y"><b>Y</b> Yes</button>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="closeModalforMissing()" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </app-custom-modal>
</ng-template>


<ng-template #AddFaxNo let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalforfax()">
        <h4 header>Missing Fax#</h4>
        <span body>
            <div class="row">
                <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    <h4>Prescriber</h4>
                </div>
                <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    <h4>Fax#</h4>
                </div>
            </div>
            <div [formGroup]="prescNamesFG">
                <div formArrayName="prescNames">
                    <div *ngFor="let name of prescNamesFA.controls; index as i" class="row mt-3">
                        <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                            {{showPrescNames[i] | uppercase}}
                        </div>
                        <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                            <input [mask]="'00000000000'" class="form-control" [formControlName]="i" [minlength]="11"
                                [placeholder]="'To Fax Number'" [required]="true"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="u"
            (click)="updateFaxNoToPrescriber()"><b>U</b>UPDATE</button>
        <button footer class="hotkey_primary" appShortcutKey InputKey="s" (click)="skip()"> <b>S</b>SKIP</button>
    </app-custom-modal>
</ng-template>


<ng-template #Batchque let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="batchQue('no')">
        <h4 header>Batch Queue</h4>
        <span body>
            <p>Do you wish to empty the Batch Queue after printing locally?</p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="batchQue('yes')" appShortcutKey
            InputKey="y"><b>Y</b> Yes</button>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="batchQue('no')" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </app-custom-modal>
</ng-template>



<ng-template #Batchque1 let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="no()">
        <h4 header>Follow Up</h4>
        <span body>
            <p>Do you wish to add Refill Request for Rx#(s): {{selectedRxFollowup}} to the Follow-Up Faxed Refill Log? </p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="yes()" appShortcutKey
            InputKey="y"><b>Y</b> Yes</button>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="no()" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #Batchque2 let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="noControlRx()">
        <h4 header>Follow Up</h4>
        <span body>
            <p>Do you wish to add Refill Request for Rx#(s): {{selectedRxFollowup}} to the Follow-Up Faxed Refill Log?</p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="yesControlRx()" appShortcutKey
            InputKey="y"><b>Y</b> Yes</button>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="noControlRx()" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </app-custom-modal>
</ng-template>
