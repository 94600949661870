<div class="form-group custom--input zip_line_height" [ngClass]="{'has-error':(errorMessage && cityTouched)}">
    <label class="control-label ">
        <span class="label_color">
            City <span  class="help--text__danger" *ngIf="isRequired">*</span>
        </span>
        <input
            type="text"
            [id]="rxSelectId"
            name="searchBoxCityType"
            class="form-control search--input__box"
            placeholder="City"
            [ngbTypeahead]="searchCity"
            (selectItem)="optedCityValue($event)"
            [(ngModel)]="citySelected"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            [required]="isRequired"
            (blur)="validateAllFormFields(inputFormGroup, custContrlName);checkIfNull()"
            [tabindex]="tabIndex"
            (focus)="cityTouched=true"
            [maxLength]="30"
            (keydown)="onKeydownValue($event)"
            KeyUpDown
        />
    </label>
    <ng-template #rt let-r="result" let-t="term">
        <div class="card patient-card secondary-patient-card" *ngIf="r">
            <div class="row">{{ r?.Name | uppercase }}</div>
        </div>
    </ng-template>
    <div class="help-block" *ngIf="errorMessage && cityTouched">
            {{errorMessage}}
     </div>
</div>
