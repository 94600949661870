import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EditDrugService, BucketService, DrugService ,CommonService } from "../services";
import { CollectionView } from "@grapecity/wijmo";
import { DrugInsuRest, DrugBucketPrice, Vendor, DrugMisc } from "../models";
import * as moment from "moment";
import { CommonUtil } from "./common.util";
import { DrugStore } from "../store";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { compileDeclareClassMetadata } from "@angular/compiler";
import { FormatsUtil } from "./formats.util";
@Injectable({
    providedIn: "root"
})

export class DrugTabUtils {
    constructor(private _fb: FormBuilder, private _comnUtil: CommonUtil, private _drugStore: DrugStore, private _commonSvc: CommonService,
        private _editDrugSvc: EditDrugService, private _drugSvc: DrugService, private _buckSvc: BucketService,
        private commonUtil: CommonUtil, private _hoverOver: HoverOver,private _formatsUtil:FormatsUtil,
        private _colorCodeRx: ColorCodeRx) {}

    async getDrugInsuRest(Id, PageNu, Size) {
        return await this._editDrugSvc.getInsuranceRestrictionWithoutBS(Id, PageNu, Size).toPromise();
    }

    async getDrugInsuRestData(Id, PageNu, Size, VaccineData) {
        const insuResData: any = await this.getDrugInsuRest(Id, PageNu, Size);
        let insuWJ = null;
        let totalCount = 0;
        this._editDrugSvc.storeInsrestData(insuResData);
        if (insuResData && insuResData["length"] > 0) {
            totalCount = insuResData[0].TotalCount;
            insuWJ = new CollectionView(
                insuResData.map((drug, i) => {
                    const j = {};
                    j["Ins.Code"] = drug.InsurerCode;
                    j["Ins.Name"] = drug.InsName;
                    // j["Billing NDC"] = drug.BillingNDC;
                    // j["NDC Qualifier"] = drug.NdcQualifier;
                    // j["Allowed"] = drug.IsAllowed === true ? "YES" : "NO";
                    // j["PA.?"] = drug.IsPriorApprReqd === true ? "YES" : "NO";
                    j["Max.Refills"] = drug.MaxRefillsAllowed;
                    j["Apply to All Ins"] = drug.IsAllInsu === true ? "YES" : "NO";
                    j["MaxQty"] = drug.MaxQtyAllowed ? (drug.MaxQtyAllowed).toFixed(3) : "0.000";
                    j["Id"] = drug.Id;
                    j["InsuranceId"] = drug.InsurId;
                    // j["NdcQualiId"] = drug.NDCQualiId;
                    j["Vaccine Group"] = VaccineData ? VaccineData.VaccineGroupName : "";
                    j["Incentive Amount"]= drug.Incentive ? "$ "+ drug.Incentive.toFixed(2) : "$ 0.00";
                    j["Submission Clarification"] = drug.SubClarification ? (drug.SubClarification+ " - " +drug.SubClarificationName) : "";
                    j["Basis of Cost"] = drug.CostBasis ? (drug.CostBasis+ " - " + drug.CostBasisName) : "";
                    let reasonOfServ: any;
                    let profServ : any;
                    let resOfServ : any;
                    let effrtLevel: any;
                    let coAgent: any;
                    let coAgentType: any;
                    let clinicalSigfNm: any;
                    reasonOfServ = drug.ServReasonName ? (drug.ServReasonName) : "";
                    profServ = drug.ProfServName ? (", "+ drug.ProfServName) : "";
                    resOfServ = drug.ServResult ? (", "+ drug.ServResult) : "";
                    effrtLevel = drug.EffortLvlName ? (", "+ drug.EffortLvlName) : "";
                    coAgent = drug.CoAgentNum ? (", "+ drug.CoAgentNum) : "";
                    coAgentType = drug.CoAgentIdType ? (", "+ drug.CoAgentIdType) : "";
                    clinicalSigfNm = drug.ClinicalSignfName ? (", "+ drug.ClinicalSignfName) : "";
                    j["DUR"]= reasonOfServ+profServ+resOfServ+effrtLevel+coAgent+coAgentType+clinicalSigfNm;
                    j["Pharmacist Comment"]= drug.PhComment;
                    return j;
                })
            );
        }
        return {count: totalCount, wjSource: insuWJ, result: insuResData};
    }

    createInsuRestFG() {
        return this._fb.group(new DrugInsuRest());
    }

    createAndPatchInsuRestFG(data) {
        const FG: FormGroup = this._fb.group(new DrugInsuRest());
        FG.patchValue(data);
        FG.patchValue({
            InsurId: data["InsuranceId"] ? data["InsuranceId"] : null,
            IsPriorApprReqd: data["PriorApp"],
            Id: data["Id"],
            BillingNDC: data["BillingNdc"],
            NDCQualiId: data["NdcQualiId"]
        });
        return FG;
    }

    async addInsuRest(data) {
        return await this._editDrugSvc.addInsuRest(data).toPromise();
    }

    async editInsuRest(data) {
        return await this._editDrugSvc.editInsuRest(data).toPromise();
    }

    async deleteInsuRest(Id) {
        return await this._editDrugSvc.deleteInsuRest(Id).toPromise();
    }

    async getDrugBucketPricing(Id, pageNu, Size) {
        return await this._editDrugSvc.getBucketPricing(Id, pageNu, Size).toPromise();
    }

    async getDrugBuckpriceData(Id, pageNu, Size) {
        const response: any = await this.getDrugBucketPricing(Id, pageNu, Size);
        let wjItemSorce = null;
        let totalCount = 0;
        if (response && response.length > 0) {
            totalCount = response[0].TotalCount;
            wjItemSorce = new CollectionView(
                response.map((drugWarn, i) => {
                    const j = {};
                    j["Bucket Name"] = drugWarn.BucketName.toUpperCase();
                    j["Unit Pricing"] = drugWarn.UnitPrice ? (drugWarn.UnitPrice).toFixed(3) : "$0.000";
                    j["bucketId"] = drugWarn.DrugBucketId;
                    j["Id"] = drugWarn.Id;
                    return j;
                })
            );
        }
        return {count: totalCount, wjSource: wjItemSorce, result: response};
    }

    async getBucketList() {
        return await this._buckSvc.getBucketsForDropdowns().toPromise();
    }

    createBucketFG() {
        return this._fb.group(new DrugBucketPrice());
    }

    createAndPatchBucketFG(value) {
        const FG: FormGroup = this.createBucketFG();
        FG.patchValue(value);
        return FG;
    }

    async addBucketprice(data) {
        return await this._editDrugSvc.addBucketPricing(data).toPromise();
    }

    async editBucketprice(data) {
        return await this._editDrugSvc.editBucketPricing(data).toPromise();
    }

    async deleteBucketprice(Id) {
        return await this._editDrugSvc.deleteBucketPricing(Id).toPromise();
    }

    async getDrugPriceUpdate(data) {
        const response: any  = await this._editDrugSvc.getDrugPriceUpdate(data).toPromise();
        let totalCount = 0;
        let wjItemS = null;
        if (response && response["length"] > 0) {
            totalCount = response[0].TotalCount;
            wjItemS = new CollectionView(
                response.map((priceUpdate, i) => {
                    const j = {};
                    j["New Price"] = priceUpdate.NewPrice;
                    j["Date Updated"] = moment(priceUpdate.ModifiedDtTm).format(
                        "MM/DD/YYYY h:mm:ss a"
                    );
                    j["Manuf. Update Date"] = "";
                    j["Type"] = ((priceUpdate.PriceCatId === 1) ? "Pack AWP" :
                    ((priceUpdate.PriceCatId === 2) ? "Pack COST" : ((priceUpdate.PriceCatId === 4) ? "Unit AWP" : ((priceUpdate.PriceCatId === 5) ? "Unit Cost" : "DIR"))));
                    j["Updated By"] =  priceUpdate?.Updatedby;
                    j["Old Price"] = priceUpdate.OldPrice;
                    j["Update History Id"] = (priceUpdate.Id).toString();
                    return j;
                })
            );
        }
        return {count: totalCount, wjSource: wjItemS};
    }

    async getDrugDispHis(data, systemData) {
        let totalCount: any;
        const response: any = await this._editDrugSvc.getDispnsHistory(data).toPromise();
        let wjItemS = null;
        if (response && response["TotalCount"] > 0) {
            totalCount = response["TotalCount"];
            const mapDataSet = [];
                response.DispensedHistory.map((drug, i) => {
                const j = {};
                j["RowId"] = i;
                j["Patient Name"] = drug.Patient.lastname + ", " + drug.Patient.firstname;
                j["Date Filled"] =(drug.PrescReFill.FillDtTm) ? moment.utc(drug.PrescReFill.FillDtTm).local().format("MM/DD/YYYY") : "";
                j["Rx#"] = drug.Prescription.PrescNum;
                j["Ref#"] = drug.PrescReFill.ReFillNum;
                j["Status"] = this._comnUtil.getRxStatus(drug.Prescription.RxStatusId, drug.PrescReFill.StatusId, drug.RxBill.StatusId);
                // j["TotalAmount"] = this._comnUtil.getRxTotalAmnt(drug.PrescReFill);
                j["TotalAmount"] = "$" + (drug.PrescReFill.TotalAmt ? drug.PrescReFill.TotalAmt.toFixed(2) : "0.00");
                let insurance = null;
                if (drug.Patient && drug.Patient.insurance && drug.Patient.insurance.length > 0) {
                    insurance = drug.Patient.insurance.filter(val => val["insuranceid"] === +drug.RefillInsu.InsuId)[0];
                }
                if (insurance && (insurance.IsCash || insurance.insurerid === systemData["DefaultValuese"][0]["CashInsurerId"])) {
                    j["Patient Copay"] = "$" + (drug.PrescReFill.TotalAmt ?  drug.PrescReFill.TotalAmt.toFixed(2) : "0.00");
                } else {
                    j["Patient Copay"] = "$" + (drug.PrescReFill.CoPay ? drug.PrescReFill.CoPay.toFixed(2) : "0.00");
                }
                j["QtyDisp"] = drug.PrescReFill.DispQty ? (drug.PrescReFill.DispQty).toFixed(3) : "0.000";
                j["Fill#"] = this._commonSvc.checkIsPartialFill(drug.PrescReFill, drug.Prescription)
                ? drug.PrescReFill.PartialFillNo : " ";
                j["Days"] = drug.Prescription.Days;
                j["Ins.Type"] = (insurance && insurance.insuType) ? insurance.insuType : "";
                j["Prescriber Name"] = drug.Prescriber.prescriberlastname + ", " + drug.Prescriber.prescriberfirstname;
                j["PrescRefillId"] = drug.PrescReFill.Id;
                j["IsBilled"] = this._comnUtil.checkIsGreenBilled(drug, systemData);
                j["DrugClass"] = drug.Drug.drugclass;
                j["IsPicked"] = drug.pickupRx && drug.pickupRx.IsPickedUp  ? true : false;
                j["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(j);
                const rowId = j["RowId"] >= 0 ? j["RowId"] : "";
                const RxDetails = rowId >= 0 ? response.DispensedHistory[rowId] : null;
                j["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
                mapDataSet.push(j);
            });
            wjItemS = new CollectionView(mapDataSet,
                {
                    sortComparer: (a: any, b: any) => {
                        return moment(a).isValid() && moment(b).isValid()
                            ? moment(a).diff(moment(b)) : null;
                    }
                });
        }
        return {count: totalCount, wjSource: wjItemS, resp: response};
    }

    getTransHistory(data, drugCode) {
        let bucketList: any[];
        this._commonSvc.bucketList$.subscribe(resp => {
            if (resp) {
                bucketList = resp;
            }
        });
        return new CollectionView(
            data.map((inventory, i) => {
                const j = {};
                j["Date Received"] = moment(inventory.ReceiptDtTm).format("MM/DD/YY");
                j["Vendor Name"] = inventory.VendorName;
                j["Qty.Received"] = inventory.Qty ? (inventory.Qty).toFixed(3) : "0.000";
                j["Price"] = inventory.AqCostPerPkg;
                j["Drug Lot"] = inventory.LotNum;
                j["LotNumData"] = inventory.LotNumData;
                j["ExpDateList"] = inventory.ExpDateList;
                j["Reference"] = inventory.Ref;
                j["PO Number"] = j["PO#"] = inventory.PONum;
                j["Received By"] = inventory.Name;
                j["Drug NDC"] = drugCode;
                const bucket = bucketList.find(item => item.Id === inventory.BucketId);
                j["Bucket"] = bucket ? bucket.Name : "";
                j["ID"] = bucket ? bucket.Id : null;
                return j;
            })
        );
    }

    async getTransDispHis(Id) {
        return await this._editDrugSvc.getInventoryhistory(Id).toPromise();
    }

    async getDrugVendors(Id) {
        const response = await this._editDrugSvc.getVendorInfobyDrugId(Id).toPromise();
        let wjItemS = null;
        if (response && response.length > 0) {
            wjItemS = new CollectionView(
                response.map((vend, i) => {
                    const j = {};
                    j["Id"] = vend.Id;
                    j["Vendor Id"] = vend.VendorId;
                    j["Contract Id"]= vend.ContractId;
                    j["Account"] = vend.ContractName;
                    j["Vendor Name"] = vend.VendorName;
                    j["Vendor Item Code"] = vend.ItemCode;
                    j["Unit Price Cost"] = vend.UnitCost ? vend.UnitCost : 0;
                    j["Cost Pack"] = vend.PackCost ? vend.PackCost : 0;
                    j["AWP Pack"] = vend.AWP ? vend.AWP : 0;
                    j["Unit Price AWP"] = vend.UnitAWP ? vend.UnitAWP : 0;
                    j["Discontinue"] = vend.IsDiscont;
                    j["Last Updated"] = moment.utc(vend.ModifiedDtTm).local().format(
                        "MM/DD/YYYY h:mm:ss a"
                    );
                    j["Actions"] = " ";
                    return j;
                })
            );
        }
        return  {result: response, itemSWJ: wjItemS};
    }

    createVendorItemFG() {
        const fg = this._fb.group(new Vendor());
        fg.patchValue({IsDiscont: false, IsActive: true});
        return fg;
    }

    patchVendorItems(data) {
        const fg = this.createVendorItemFG();
        Object.keys(data).map(cntrl => {
            if (cntrl && fg.controls[cntrl]) {
                if (cntrl === "UnitCost" || cntrl === "UnitAWP") {
                    fg.controls[cntrl].patchValue(data[cntrl] ? (parseFloat(data[cntrl])).toFixed(3) : "0.000");
                } else if (cntrl === "AWP" || cntrl === "PackCost") {
                    fg.controls[cntrl].patchValue(data[cntrl] ? (parseFloat(data[cntrl])).toFixed(2)  : "0.00");
                } else {
                fg.controls[cntrl].patchValue(data[cntrl]);
                }
            }
        });
        fg.markAsPristine();
        return fg;
    }

    async addOrEditVendorItems(type, value) {
        return type === "VAdd" ?  await this._editDrugSvc.addvendorItems(value).toPromise() :
        await this._editDrugSvc.updatevendorItems(value).toPromise();
    }

    async deleteVendor(Id) {
        return await this._editDrugSvc.vendorInfoDelete(Id).toPromise();
    }

    async discVendor(data) {
        return await this._editDrugSvc.updateVendorStatus(data.Id, data.Discontinue).toPromise();
    }

    createMiscFG() {
        return this._fb.group({
            DrugMisc: this._fb.group(new DrugMisc()),
            PkgMc: this._fb.array([]),
            DrugLot: this._fb.array([])});
    }

    async getDrugMiscInfo(Id) {
        return await this._editDrugSvc.getMiscInfo(Id).toPromise();
    }

    patchMiscInfo(info, formGroupName) {
        for (const key in info) {
            if (key === "DrugMisc" && info[key]) {
                formGroupName.controls[key].patchValue(info[key]);
            } else if ((key === "DrugLot" || key === "PkgMc") && info[key]) {
                this._comnUtil.generateFA(key, info[key], formGroupName);
            }
        }
    }

    generateDrugLotWj(data) {
        return new CollectionView(
            data.map((drug, i) => {
                    const j = {};
                    j["rowId"] = i;
                    j["Expiration Date"] = moment.utc(drug.ExpDt).local().format("MM/DD/YYYY");
                    j["LotNumber"] = j["Lot#"] = drug.LotNum;
                    j["drugId"] = drug.Id;
                    j["IsActive"] = drug.IsActive && new Date(j["Expiration Date"]) >= new Date(moment().format("MM/DD/YYYY"))
                     ? true : false;
                    j["Id"] = drug.Id;
                    j["Date Updated"] = moment(drug.ModifiedDtTm).format("MM/DD/YYYY");
                    return j;
            })
        );
    }

    async getDrugCategory(drugId) {
        const response: any = await this._editDrugSvc.getDrugCategory(drugId).toPromise();
        const destination = [];
        if (response && response["length"] > 0) {
            response.map(val => {
                if (val["IsActive"]) {
                    destination.push(val["Name"]);
                }
            });
        }
        const fg = this._fb.group({categories: []});
        fg.controls["categories"].patchValue(destination ? destination : []);
        return {FG: fg, isNew: !(response && response["length"] > 0), categoryData: response};
    }
    async getDrugCounsellingData(data) {
        let dosageWJ = null;
        let drugWarnCode = null;
        const counsellingInfo: any = await this._drugSvc.getDrugCounsellingData(data).toPromise();
        const dosageInfo: any = await this._drugSvc.getDosageInfo(data["TherapeuticCode"]).toPromise();
        if (dosageInfo && dosageInfo.length > 0) {
            dosageWJ = this.generateDosageWJ(dosageInfo);
        }
        if (counsellingInfo && counsellingInfo["DrugWarnings"]) {
            drugWarnCode = this.generateDrugWarnWJ(counsellingInfo["DrugWarnings"]);
        }
        return {result: counsellingInfo, dosageItemS: dosageWJ, drugWarnItemS: drugWarnCode};
    }

    generateDosageWJ(dosageInfoList) {
        return new CollectionView(
            dosageInfoList.map((patient, i) => {
                const j = {};
                j["Age Category"] = patient.AGEBANDNAME;
                j["From Age Days"] = patient.FromAgeDays
                    ? patient.FromAgeDays
                    : "";
                j["To Age Days"] = patient.ToAgeDays
                    ? patient.ToAgeDays
                    : "";
                j["Min Daily Units"] = patient.MINDAILYUNITS;
                j["Max Daily Units"] = patient.MAXDAILYUNITS;
                j["Usual Daily Units"] = patient.USUALDAILYUNITS;
                j["Ingredient Name"] = patient.INGREDIENTNAME;
                j["Min Daily Dose"] = patient.MINDAILYDOSE;
                j["Max Daily Dose"] = patient.MAXDAILYDOSE;
                j["Usual Daily Dose"] = patient.USUALDAILYDOSE;
                j["Frequency"] = patient.FREQUENCY;
                j["Frequency Days"] = patient.FREQUENCYDAYS;
                j["LifeTime Max"] = patient.LIFETIMEMAX;
                j["Patient Experience"] = patient.PATIENTEXPERIENCE;
                j["Dosing Type"] = patient.DOSINGTYPE;
                j[
                    "Hepatic Impairement May Require Dose Adjustment"
                ] = patient.HEPATICIMPAIRMENTMAYREQUIREDOSEADJUSTMENT
                        ? "True"
                        : "False";
                j[
                    "Renal Impairement May Require Dose Adjustment"
                ] = patient.RENALIMPAIRMENTMAYREQUIREDOSEADJUSTMENT
                        ? "True"
                        : "False";
                return j;
            })
        );
    }

    generateDrugWarnWJ(data) {
        return new CollectionView(
            data.map((drug, i) => {
                const j = {};
                j["WARNING"] = drug.WARNING;
                j["Warning"] = drug.WARNING;
                j["GENDER"] = drug.GENDER;
                j["Gender"] = drug.GENDER;
                j["AGE START"] = drug["AGE START"];
                j["Age Start"] = drug["AGE START"];
                j["AGE END"] = drug["AGE END"];
                j["Age End"] = drug["AGE END"];
                j["Warn_Desc1"] = drug["WARN_DESC1"];
                j["WARN_DESC1"] = drug["WARN_DESC1"];
                j["WARN_DESC2"] = drug["WARN_DESC2"];
                j["Warn_Desc2"] = drug["WARN_DESC2"];
                j[" "] = drug.GROUPNAME;
                return j;
            }),
            {
                groupDescriptions: [" "]
            }
        );
    }

    async getMedGuidById(drugId) {
        return await this._drugSvc.getDrugMedguide(drugId).toPromise();
    }

    async getPregancyInfo(drugId) {
        return await this._drugSvc.getDrugPregnancyInfo(drugId, "RxEntry").toPromise();
    }

    generateMMSWIJIMO(data) {
        return new CollectionView(
            data.map((drug, i) => {
                const j = {};
                j["Quick Code"] = drug.QckCode;
                j["Drug Name"] = drug.DrugName;
                j["Drug NDC"] = this._formatsUtil.getNDCFormat(drug.DrugNDC);
                j["Bill NDC"] = drug.BillNDC;
                j["Brand Name"] = drug.BrandName;
                j["Generic Name"] = drug.GenericName;
                j["Made By"] = drug.Madeby;
                j["Drug Type"] = drug.DrugType;
                j["Strength"] = drug.Strength;
                j["Drug Form"] = drug.DrugForm;
                j["Units"] = drug.unitofMeasure;
                j["Brand"] = drug.Brand;
                j["Size"] = drug.Quanitypack;
                j["upc"] = drug.upc;
                j["AWP Price"] = drug.UnitPrice ? "$" + parseFloat(drug.UnitPrice).toFixed(2) : " ";
                j["Cost Price"] = drug.CostPrice ? "$" + parseFloat(drug.CostPrice).toFixed(2) : " ";
                j["DIR Price"] = drug.DirPrice ? "$" + parseFloat(drug.DirPrice).toFixed(2) : " ";
                j["DrugClass"] = drug.DrugClass;
                j["DrugCategory"] = drug.DrugCategory;
                j["TxrCode"] = drug.TxrCode;
                j["Warning"] = drug.Warning;
                j["DosageForm"] = drug.DosageForm;
                j["RouteOfAdministration"] = drug.RouteOfAdministration;
                j["Select"] = i;
                j["IsSelected"] = false;
                return j;
            })
        );
    }
}
