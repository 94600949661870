import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

    navigateToLogin() {
        window.open(window.location.origin+'/#/login','ePrimeRx','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no')
    }

}
