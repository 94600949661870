import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
    Phone,
    PrescAddress,
    Person,
    Prescriber,
    Email,
    PrescSpeciality,
    PrescAdds,
    PrescriberSalesPerson,
    PrescriberSplProg,
    PrescriberCat,
    City,
    State
} from "../models";
import { CommonService, EditPrescriberService } from "../services";
import { PrescriberStore } from "../store";
import { CollectionView } from "@grapecity/wijmo";
import { CommonUtil } from "./common.util";
import { FormatsUtil } from "./formats.util";

@Injectable({
    providedIn: "root"
})
export class PrescriberUtil {
    prescriberFG: FormGroup;
    stateCityCntrlNames = {
        State: { StateId: "Id", StateName: "Name", CityId: "CityId" },
        City: { StateId: "StateId", CityName: "Name", CityId: "Id" }
    };
    tenatDetails$: any;


    constructor(private _fb: FormBuilder, private _commonServ: CommonService, private _commonUtil: CommonUtil,
        private _prescbStore: PrescriberStore, private _editPrescriber: EditPrescriberService, private _formatsUtil: FormatsUtil) { }

    generatePrescriberFG() {
        return this._fb.group({
            Mobil: this._fb.group(new Phone()),
            Telephone1: this._fb.group(new Phone()),
            Telephone2: this._fb.group(new Phone()),
            Fax: this._fb.group(new Phone()),
            Address: this._fb.group(new PrescAddress()),
            Person: this._fb.group(new Person()),
            Prescriber: this._fb.group(new Prescriber()),
            EMail: this._fb.group(new Email()),
            PrimarySpeciality: this._fb.group(new PrescSpeciality()),
            SecondarySpeciality: this._fb.group(new PrescSpeciality()),
            TertiarySpeciality: this._fb.group(new PrescSpeciality()),
            PrescAdds: this._fb.group(new PrescAdds()),
            InsuBlackList: null,
            InsuCarrier: null,
            PrescriberSalesPerson: this._fb.group(new PrescriberSalesPerson()),
            SpecialProgram: this._fb.group(new PrescriberSplProg()),
            PrescriberCat: this._fb.group(new PrescriberCat()),
            IsDocumentExists: null,
            PageNumber: null,
            PageSize: null,
            OldAddress: this._fb.group(new PrescAddress()),
            PrescFacility: null,
        });
    }

    patchPrescInfo(prescriberInfo, formGroupName) {
        for (const key in prescriberInfo) {
            if (key && prescriberInfo[key] && key !== "Specialities") {
                formGroupName.controls[key].patchValue(
                    prescriberInfo[key]
                );
            }
        }
        if(prescriberInfo.Address)
            formGroupName.controls["OldAddress"].patchValue(prescriberInfo.Address);
        prescriberInfo.Address ? this.patchCityAndStateValue(prescriberInfo.Address, formGroupName) : this.makeStateCityNull();
        formGroupName.markAsPristine();
    }

    makeStateCityNull() {
        this._prescbStore.storePrescriberState(null);
        this._prescbStore.storePrescriberCity(null);
    }

    async patchCityAndStateValue(data, formGroup, type?: any) {
        if (!data) {
            this.makeStateCityNull();
        } else{
            const resp = await this._commonUtil.patchStateAndCity(data, formGroup, type ? type : null);
            if (!type || type === "State") {
                this._prescbStore.storePrescriberState(resp["state"]);
            }
            this._prescbStore.storePrescriberCity(resp["city"]);
        }
    }

    patchNewPrescInfo(formGroupName, isFromErxToAdd?: any) {
        formGroupName.controls["Person"].patchValue({
            ActiveStatusId : 1
        });
        if (!isFromErxToAdd) {
            this.tenatDetails$ = this._commonServ.newTenatInfo$;
            const data = this.tenatDetails$["source"]["value"];
            if (data && data["PharmacyDetails"]) {
                this.patchCityAndStateValue(data["PharmacyDetails"], formGroupName);
                if (!formGroupName.controls.Address.value.ZipCode) {
                    formGroupName.controls["Address"].patchValue({
                    ZipCode: data["PharmacyDetails"]["ZipCode"]
                    });
                }
            } else {
                this.makeStateCityNull();
            }
        }
    }

    async getPrescriberInfo(Id) {
        return await this._commonServ.getPrescriberData(Id).toPromise();
    }

    async updatePrescriber(data) {
        return await this._editPrescriber.updatePrescriberData(data).toPromise();
    }

    async addPrescriber(data) {
        return await this._editPrescriber.savePrescriberInfo(data).toPromise();
    }

    async checkDuplicatePrescb(npi, id, deanumber) {
        return await this._editPrescriber.checkPrescriberExist(npi, id, deanumber).toPromise();
    }

    async getPrescriberMMSInfo(value) {
        return await this._editPrescriber.getPrescMMSInfo(value).toPromise();
    }

    async patchErxValues(erxPrescValues, formGroupName) {
        formGroupName.controls.Person.patchValue({
            LastName: erxPrescValues.PresLastName,
            FirstName: erxPrescValues.PresFirstName,
            NPINum: erxPrescValues.PrescNpi
        });
        formGroupName.controls.EMail.patchValue({
            xeMail: erxPrescValues.PrescMail
        });
        formGroupName.controls.Address.patchValue({
            ZipCode: erxPrescValues.PresZip,
            AddressLine1: erxPrescValues.PresAddrL1 ? erxPrescValues.PresAddrL1 : null
        });
        formGroupName.controls.PrescAdds.patchValue({
            SPINum: erxPrescValues.PrescSpi
        });
        formGroupName.controls.Telephone1.patchValue({
            xPhone: erxPrescValues.PrescPhone
        });
        formGroupName.controls.Prescriber.patchValue({
            DEANum: erxPrescValues.PrescDea
        });
        if (erxPrescValues.PresZip &&
            erxPrescValues.PresZip.trim() && erxPrescValues.PresZip !== " ") {
            const zipMaster = await this._commonServ.getStateCityDataByZip(erxPrescValues.PresZip).toPromise();
            zipMaster ? this.patchCityAndStateValue(zipMaster, formGroupName) : this.makeStateCityNull();
        }
    }


    // async patchErxValues(erxPrescValues, formGroupName) {
    //     const erxPrescModel = {
    //         Person: { LastName: "PresLastName", FirstName: "PresFirstName", NPINum: "PrescNpi" },
    //         EMail: { xeMail: "PrescMail" },
    //         Address: { ZipCode: "PresZip", AddressLine1: "PresAddrL1" },
    //         PrescAdds: { SPINum: "PrescSpi" },
    //         Telephone1: { xPhone: "PrescPhone" },
    //         Prescriber: { DEANum: "PrescDea" }
    //     };
    //     Object.keys(erxPrescModel).map(mainCntrl => {
    //         const fg: any = formGroupName.controls[mainCntrl];
    //         Object.keys(mainCntrl).map(subCntrl => {
    //             const erxValue =
    //                 erxPrescValues[erxPrescModel[mainCntrl][subCntrl]];
    //             fg.controls[subCntrl].patchValue(erxValue ? erxValue : null);
    //         });
    //     });
    //     if (erxPrescValues.PresZip &&
    //         erxPrescValues.PresZip.trim() && erxPrescValues.PresZip !== " ") {
    //         const zipMaster = await this._commonServ.getStateCityDataByZip(erxPrescValues.PresZip).toPromise();
    //         zipMaster ? this.patchCityAndStateValue(zipMaster, formGroupName) : this.makeStateCityNull();
    //     }
    // }

    async getStateAndCityByZip(zipcode) {
        return await this._commonServ.getStateCityDataByZip(zipcode).toPromise();
    }

    async getStateByName(StateName) {
        return await this._commonServ.getStatesData(StateName).toPromise();
    }
    async getCityByName(cityName, stateId) {
        return await this._commonServ.getCitiesData(cityName, stateId).toPromise();
    }

    async getStateAndCityId(zipcode, stateName, cityName) {
        let response: any;
        if (zipcode) {
            const resp = await this.getStateAndCityByZip(zipcode);
            if (resp) {
                response = { stateId: resp["StateId"], cityId: resp["CityId"] };
            } else {
                response = this.getStateAndCityByName(stateName, cityName);
            }
        } else {
            response = this.getStateAndCityByName(stateName, cityName);
        }
        return response;
    }

    async getStateAndCityByName(stateName, cityName) {
        let response: any;
        const stateData = await this.getStateByName(stateName);
        if (stateData && stateData["Id"]) {
            const cityData = await this.getCityByName(cityName, stateData["Id"]);
            response = { stateId: stateData["Id"], cityId: cityData ? cityData["Id"] : null };
        }
        return response;
    }

    generateMMSWIJIMO(data) {
        return new CollectionView(
            data.map((insurance, i) => {
                const j = {};
                j["row"] = i;
                j["PRESC NO"] = insurance.PRESNO;
                j["LAST NAME"] = insurance.PRESLNM;
                j["FIRST NAME"] = insurance.PRESFNM;
                j["NPI#"] = insurance.NPINO;
                j["PHONE#"] = insurance.PHONE;
                j["STREET"] = insurance.ADDRSTR;
                j["STATE"] = insurance.ADDRST;
                j["CITY"] = insurance.ADDRCT;
                j["ZIP"] = insurance.ADDRZP ? ((insurance.ADDRZP).toString()).substring(0, 5) : null;
                j["LICENSE#"] = insurance.PRESLIC;
                j["DEA#"] = this._formatsUtil.getDeaFormat(insurance.PRESDEA);
                j["SPI#"] = insurance.SPINO;
                j["FAX#"] = insurance.FAXNO;
                j["ACTIVE"] = insurance.ACTIVE;
                j["SERVICE LEVEL NAME"] = insurance.SERVICELEVELNAME;
                j["SERVICE LEVEL"] = insurance.SERVICELEVEL;
                return j;
            })
        );
    }

    generateMMSModelForAdd(mmsData, prescriberInfo) {
       return [
            {
                head: "LAST NAME",
                currentvalue: prescriberInfo.Person ? prescriberInfo.Person.LastName : " ",
                MMsvalue: mmsData.PRESLNM,
                ControlName: "LastName",
                keyName: "Person",
            },
            {
                head: "FIRST NAME",
                currentvalue: prescriberInfo.Person ? prescriberInfo.Person.FirstName : " ",
                MMsvalue: mmsData.PRESFNM,
                ControlName: "FirstName",
                keyName: "Person",
            },
            {
                head: "NPI NO",
                currentvalue: prescriberInfo.Person ? prescriberInfo.Person.NPINum : " ",
                MMsvalue: mmsData.NPINO,
                ControlName: "NPINum",
                keyName: "Person",
            },
            {
                head: "SPI NO",
                currentvalue: prescriberInfo.PrescAdds ? prescriberInfo.PrescAdds.SPINum : " ",
                MMsvalue: mmsData.SPINO,
                ControlName: "SPINum",
                keyName: "PrescAdds"
            },
            {
                head: "DEA NO",
                currentvalue: prescriberInfo.Prescriber ? this._formatsUtil.getDeaFormat(prescriberInfo.Prescriber.DEANum) : " ",
                MMsvalue: mmsData.PRESDEA,
                ControlName: "DEANum",
                keyName: "Prescriber",
            },

            {
                head: "ADDRESS",
                currentvalue: prescriberInfo.Address ? prescriberInfo.Address.AddressLine1 : "",
                MMsvalue: mmsData.ADDRSTR,
                ControlName: "AddressLine1",
                keyName: "Address",
            },
            ,
            {
                head: "STATE",
                currentvalue: prescriberInfo.Address ? prescriberInfo.Address.StateName : "",
                MMsvalue: mmsData.ADDRST,
                ControlName: "StateName",
                keyName: "Address"
            },
            {
                head: "CITY",
                currentvalue: prescriberInfo.Address ? prescriberInfo.Address.CityName : "",
                MMsvalue: mmsData.ADDRCT,
                ControlName: "CityName",
                keyName: "Address"
            },
            {
                head: "ZIP",
                currentvalue: prescriberInfo.Address ? prescriberInfo.Address.ZipCode : "",
                MMsvalue: mmsData.ADDRZP ? mmsData.ADDRZP.substring(0, 5) : mmsData.ADDRZP,
                ControlName: "ZipCode",
                keyName: "Address"
            },
            {
                head: "PHONE NO",
                currentvalue: prescriberInfo.Telephone1 ? prescriberInfo.Telephone1.xPhone : "",
                MMsvalue: mmsData.PHONE,
                ControlName: "xPhone",
                keyName: "Telephone1"
            },

            {
                head: "FAX",
                currentvalue: prescriberInfo.Fax ? prescriberInfo.Fax.xPhone : "",
                MMsvalue: mmsData.FAXNO,
                ControlName: "xPhone",
                keyName: "Fax"
            },
            {
                head: "LICENSE NO",
                currentvalue: prescriberInfo.Prescriber ? prescriberInfo.Prescriber.PrescbNum : "",
                MMsvalue: mmsData.PRESLIC,
                ControlName: "PrescbNum",
                keyName: "Prescriber"
            },
            {
                head: "SERVICE LEVEL",
                currentvalue: prescriberInfo.PrescAdds ? prescriberInfo.PrescAdds.ServiceLevel : "",
                MMsvalue: mmsData.SERVICELEVEL,
                ControlName: "ServiceLevel",
                keyName: "PrescAdds"
            },
            {
                head: "SERVICE LEVEL NAME",
                currentvalue: prescriberInfo.PrescAdds ? prescriberInfo.PrescAdds.ServiceLevelName : "",
                MMsvalue: mmsData.SERVICELEVELNAME,
                ControlName: "ServiceLevelName",
                keyName: "PrescAdds"
            }
        ];
    }

    generateMMSModelForUpdate(mmsData, prescInfo) {
       return [
            {
                head: "NPI NO",
                currentvalue: prescInfo.Person ? prescInfo.Person.NPINum : " ",
                MMsvalue: mmsData.NPINO,
                link: mmsData.NPINO ? "USE NPI" : " ",
                ControlName: "NPINum",
                keyName: "Person",
                display: mmsData.NPINO
                    ? mmsData.NPINO.trim() ===
                   ((prescInfo.Person && prescInfo.Person.NPINum) ? prescInfo.Person.NPINum.trim() : "")
                        ? false
                        : true
                    : false
            },
            {
                head: "SPI NO",
                currentvalue: prescInfo.PrescAdds ? prescInfo.PrescAdds.SPINum : " ",
                MMsvalue: mmsData.SPINO ? mmsData.SPINO.toString() : mmsData.SPINO,
                link: mmsData.SPINO ? "USE SPI" : " ",
                ControlName: "SPINum",
                keyName: "PrescAdds",
                display: mmsData.SPINO
                    ? mmsData.SPINO.toString().trim() ===
                    ((prescInfo.PrescAdds && prescInfo.PrescAdds.SPINum) ? prescInfo.PrescAdds.SPINum.toString().trim() : "")
                        ? false
                        : true
                    : false
            },
            {
                head: "DEA NO",
                currentvalue: prescInfo.Prescriber ? this._formatsUtil.getDeaFormat(prescInfo.Prescriber.DEANum) : " ",
                MMsvalue: mmsData.PRESDEA,
                link: mmsData.PRESDEA ? "USE DEA" : " ",
                ControlName: "DEANum",
                keyName: "Prescriber",
                display: mmsData.PRESDEA
                    ? mmsData.PRESDEA === prescInfo.Prescriber.DEANum
                        ? false
                        : true
                    : false
            },

            {
                head: "ADDRESS",
                currentvalue: prescInfo.Address ? prescInfo.Address.AddressLine1 : "",
                MMsvalue: mmsData.ADDRSTR,
                link: mmsData.ADDRSTR ? "USE ADDRESS" : " ",
                ControlName: "AddressLine1",
                keyName: "Address",
                display: mmsData.ADDRSTR
                    ? prescInfo.Address.AddressLine1 ? (mmsData.ADDRSTR.toLowerCase() ===
                       prescInfo.Address.AddressLine1.toLowerCase())
                        ? false
                        :true
                        : true
                    : false
            },
            {
                head: "STATE",
                currentvalue: (prescInfo.Address && prescInfo.Address.StateName) ? (prescInfo.Address.StateName).toString() : "",
                MMsvalue: mmsData.ADDRST,
                link: mmsData.ADDRST ? "USE STATE" : " ",
                ControlName: "StateName",
                keyName: "Address",
                display: mmsData.ADDRST
                    ? mmsData.ADDRST.toLowerCase() ===
                        ((prescInfo.Address && prescInfo.Address.StateName) ?
                        prescInfo.Address.StateName.toLowerCase() : "")
                        ? false
                        : true
                    : false
            },
            {
                head: "CITY",
                currentvalue: (prescInfo.Address && prescInfo.Address.CityName) ? (prescInfo.Address.CityName).toString() : "",
                MMsvalue: mmsData.ADDRCT,
                link: mmsData.ADDRCT ? "USE City" : " ",
                ControlName: "CityName",
                keyName: "Address",
                display: mmsData.ADDRCT
                    ? mmsData.ADDRCT.toLowerCase() ===
                    ((prescInfo.Address && prescInfo.Address.CityName) ?
                    prescInfo.Address.CityName.toLowerCase() : "")
                        ? false
                        : true
                    : false
            },
            {
                head: "ZIP",
                currentvalue: (prescInfo.Address && prescInfo.Address.ZipCode) ? prescInfo.Address.ZipCode.toString() : "",
                MMsvalue: mmsData.ADDRZP.toString() ? mmsData.ADDRZP.toString().substring(0, 5) : "",
                link: mmsData.ADDRZP ? "USE ZIP" : " ",
                ControlName: "ZipCode",
                keyName: "Address",
                display: mmsData.ADDRZP.toString()
                    ? mmsData.ADDRZP.toString().substring(0, 5) ===
                    ((prescInfo.Address && prescInfo.Address.ZipCode) ?
                    prescInfo.Address.ZipCode.toString().substring(0, 5) : "")
                        ? false
                        : true
                    : false
            },
            {
                head: "PHONE NO",
                currentvalue: prescInfo.Telephone1 ? prescInfo.Telephone1.xPhone : "",
                MMsvalue: mmsData.PHONE,
                link: mmsData.PHONE ? "USE MOBILE" : " ",
                ControlName: "xPhone",
                keyName: "Telephone1",
                display: mmsData.PHONE
                    ? mmsData.PHONE === prescInfo.Telephone1.xPhone
                        ? false
                        : true
                    : false
            },
            {
                head: "FAX",
                currentvalue: prescInfo.Fax ? prescInfo.Fax.xPhone : "",
                MMsvalue: mmsData.FAXNO,
                link: mmsData.FAXNO ? "USE FAX" : " ",
                ControlName: "xPhone",
                keyName: "Fax",
                display: mmsData.FAXNO
                    ? mmsData.FAXNO.toString().trim() ===
                    ((prescInfo.Fax && prescInfo.Fax.xPhone) ? prescInfo.Fax.xPhone.trim() : "")
                        ? false
                        : true
                    : false
            },
            {
                head: "LICENSE NO",
                currentvalue: prescInfo.Prescriber ? prescInfo.Prescriber.PrescbNum : "",
                MMsvalue: mmsData.PRESLIC,
                link: mmsData.PRESLIC ? "USE LICENSE" : " ",
                ControlName: "PrescbNum",
                keyName: "Prescriber",
                display: mmsData.PRESLIC
                    ? mmsData.PRESLIC === prescInfo.Prescriber.PrescbNum
                        ? false
                        : true
                    : false
            }
        ];
    }
}
