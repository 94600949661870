import { Component, OnInit, Input } from "@angular/core";
import { TransmissionUtils } from "src/app/utils";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, Rx2E, RxE, ViewProfitE } from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import { CommonService } from "src/app/services";

@Component({
    selector: "app-tr-rx-pay-detls",
    templateUrl: "./tr-rx-pay-detls.component.html",
    styles: []
})
export class TrRxPayDetlsComponent implements OnInit {
    @Input() transmResp: any;
    @Input() transmissionType: any;
    @Input() transcRespStatus: any;
    @Input() rxCost: any;
    @Input() transmitParams: any;

    totalTransData: number;
    hasViewProfPrivs: boolean;
    accessPrivCate: typeof RxE;
    viewProfitE: typeof ViewProfitE;
    prevRxPays: any;
    patInsuInfoWJ: CollectionView;
    prefDrugInfoWJ: CollectionView;
    prefDrugHeaders: any;
    isVouchList: any;
    restrictRejectTrans: boolean;
    InsRecExistInPrevPay: any;
    profitLoss: any;
    ProfitLossPriv = true;

    constructor(private _transUtils: TransmissionUtils, private _privMaskGuard: PrivMaskGuard, private _commonServ: CommonService, private _privMask: PrivMaskGuard) {
        this.accessPrivCate = RxE;
        this.viewProfitE = ViewProfitE;
        // this.hasViewProfPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.ViewProfit, this.viewProfitE.View);
    }

    ngOnInit() {
        this.ProfitLossPriv = this._privMask.canActivate(AccCategoryE.Rx2, Rx2E.ShowProfitOrLossInTransmissionResult);
        this.restrictRejectTrans = (this.transmissionType === "ReversReTrans" && (this.transmResp && this.transmResp.nCPDPRecv && this.transmResp.nCPDPRecv.ResponseStatus === "R")) ? true : false;
        if (this.transmResp) {
            this.rxCost = this._transUtils.calculateRxCost(this.transmResp);
            this.profitLoss = this._transUtils.calculateProfitLoss(this.transmResp);
            this.totalTransData = this._transUtils.calculateTotalTransData(this.transmResp, this.transmissionType);
            if ( this.transmResp &&  this.transmResp.ClaimProcess &&
                this.transmResp.ClaimProcess.lstRxPay &&
                this.transmResp.ClaimProcess.lstRxPay.length > 0
            ) {
                this.prevRxPays = this.transmResp.ClaimProcess.lstRxPay.filter(
                    pay => {
                        return this.transmResp.ClaimProcess.BILLAS === "S" ? pay.RECTYPE !== "R" && pay.COVERAGECODE !== 2
                        : pay.RECTYPE !== "R";
                    }
                );
                this.InsRecExistInPrevPay = this.prevRxPays && this.prevRxPays.length ? this.prevRxPays.find((item: any) => {
                    return (item.INS_CODE.toLowerCase().trim() === this.transmResp.transmissionDetails.InsType.toLowerCase().trim())
                }) : "";
            }
            if (this.transmResp.nCPDPRecv && this.transmResp.nCPDPRecv.lstInsInfo &&
                this.transmResp.nCPDPRecv.lstInsInfo.length) {
                    this.generatePatInsuWJ();
            } else {
                this.patInsuInfoWJ = null;
            }
            if (this.transmResp && this.transmResp.preferredDrugDetails &&
                this.transmResp.preferredDrugDetails.length) {
                        this.generatePrefDrugWJ();
            } else {
                this.prefDrugInfoWJ = null;
            }
        }
    }

    generatePrefDrugWJ() {
        this.prefDrugInfoWJ = new CollectionView(
            this.transmResp.preferredDrugDetails.map(item => {
                const k = {};
                k["NDC"] = item.NDC;
                k["Qualifier"] = item.NDCQualifier;
                k["DrugName"] = item.DrugName;
                k["Manufacturer"] = item.Manfacturer;
                k["Cost"] = (parseFloat(item.CostPack ? item.CostPack : 0)).toFixed(2);
                k["AWP"] = (parseFloat(item.AwpPack ? item.AwpPack : 0)).toFixed(2);
                return k;
            })
        );
        this.prefDrugHeaders = [
            "NDC",
            "Qualifier",
            "DrugName",
            "Manufacturer",
            "Cost",
            "AWP"];
    }

    generatePatInsuWJ() {
        this.patInsuInfoWJ = new CollectionView(
            this.transmResp.nCPDPRecv.lstInsInfo.map((result, i) => {
                const k = {};
                k["INSORD"] = result.INSORD;
                k["PAYIDQ"] = result.PAYIDQ;
                k["BN"] = result.BN ? result.BN.trim() : "";
                k["PN"] = result.PC ? result.PC.trim() : "";
                k["ID"] = result.ID;
                k["GP"] = result.GP;
                k["PC"] = result.PC;
                k["PH"] = result.PH;
                k["RELATIONCD"] = result.RELATIONCD;
                k["EFFECTIVEDATE"] = result.EFECTIVEDATE;
                k["TERMINATIONDATE"] = result.TERMINATIONDATE;
                const insuDtl =  this._commonServ.insuranceData$["source"]["value"];
                let insu = null;
                if (insuDtl && insuDtl.length) {
                    insu = insuDtl.find(elm => (elm.BinNum == k["Bin#"] && elm.ProcessorNum == k["Process Control"]));
                }
                k["INSUCODE"] = insu ? insu.InsurerCode : "";
                return k;
            })
        );
    }

    checkHasPrivsToAccess(category: any, privType: any) {
        return this._privMaskGuard.canActivate(category, privType);
    }

}
