import { MsgConfig } from "./../../../app.messages";
import { AuditLogUtils } from "./../../../utils/audit-log.util";
import {
    DrugData,
    DrugDefaultVal,
    PriceHis
} from "./../../../models/edit-drug.model";
import { environment } from "../../../../environments/environment";
import { SystemData } from "./../../../models/system-data.model";
import { EditDrugService } from "./../../../services/edit-drug.service";
import { Drug } from "./../../../models/patient-Info.model";
import { CommonService } from "./../../../services/common.service";
import { RegEx } from "./../../../app.regex";
import {
    Component,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    OnDestroy,
    OnChanges,
    SimpleChange,
    SimpleChanges,
    HostListener,
    ViewChild,
    AfterViewChecked,
    AfterContentChecked,
    DoCheck,
    Input,
    Output,
    EventEmitter
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import * as moment from "moment";
import { AlertService, PreviousRouteService } from "../../../services";
import { Location } from "@angular/common";
import { RxUtils } from "src/app/utils";
import * as _ from "lodash";
import { diff } from "deep-diff";
import { Subscription } from "rxjs";
import { UdpCurrencyMaskPipe } from "../../../pipes";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CompIngredListComponent } from "../../shared";

const drugImage = environment.DrugImage;
@Component({
    selector: "app-drug-information",
    templateUrl: "./drug-information.component.html"
})
export class DrugInformationComponent
    implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    drugEditFG: FormGroup;
    drugInfo: DrugData;
    regex: any;
    drugId: number;
    imgURL: string;
    systemData: SystemData;
    isBranded: boolean;
    filename: string;
    extension: string;
    isFileExist: boolean;
    minDate: any;
    drugNotes = false;
    route: any;
    defVal: any;
    formGroupInvalid: boolean;
    originalData: any;
    focusOnDU: any;
    sharedCancel = false;
    routeFrom: any;
    subscriptions = new Subscription();
    numberPipe = new UdpCurrencyMaskPipe();
    openMMS: boolean;
    canDeactivateRoute = true;
    compoundDrugData: any;
    isCompDrug: boolean;
    isFromErxToAdd: boolean;
    focusOn: string;
    erxDrugValues: any;
    strenthReq: boolean;
    saveBeforeRoute: boolean;

    @Input()
    set DefDrugName(dpn: any) {
        if (dpn) {
            this.erxDrugValues = dpn;
            this.patchErxValues(dpn);
        }
    }

    @Input()
    set IsFromErx(ife: any) {
        if (ife) {
            this.isFromErxToAdd = true;
        }
    }


    @Input()
    set openMMSPopup(ife: any) {
        if (ife) {
            this.openMMS = true;
        }
    }

    @Output()
    IsAddNewDrugCompleted = new EventEmitter<any>();

    @ViewChild("COMPDRUGPATCH", { static: true })
    COMPDRUGPATCH: any;

    @ViewChild("COMPDRUGMISC", { static: true })
    COMPDRUGMISC: any;

    modalRef: any;


    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && (!this._modalService.hasOpenModals() || this.isFromErxToAdd)) {
            if (event.which === 83) { // s
                event.preventDefault();
                if (this.drugId) {
                    this.updateDrug();
                } else {
                    this.addNewDrug();
                }
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.CancelPopTaggle();
            } else if (event.which === 71 && this.isCompDrug) { // g
                event.preventDefault();
                this.openCompound();
            }
        }
    }


    constructor(
        private _fb: FormBuilder,
        private _data: CommonService,
        private _drugSvc: EditDrugService,
        private _cdr: ChangeDetectorRef,
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private _route: Router,
        private _alertSer: AlertService,
        private _location: Location,
        private _rxUtils: RxUtils,
        private _auditUtils: AuditLogUtils,
        private _modalService: NgbModal
    ) {
        this.getDrugDefault();
        this._commonServ.systemData$.subscribe(resp => {
            this.systemData = resp;
        });
        this.regex = RegEx;
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (params && params.fm) {
                    this.routeFrom = params.fm;
                }
                if (params && params.focus) {
                    if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                        this.focusOnDU = params.focus;
                    }
                }
                if (params && params.openMMS) {
                    if (params.openMMS) {
                        this.openMMS = true;
                    }
                }
            })
        );
        if (this._actvRoute && this._actvRoute.parent) {
            this.subscriptions.add(
                this._actvRoute.parent.params.subscribe((params: any) => {
                    this.drugId = +params["pid"];
                    if (this.drugId) {
                        this.getInfo(this.drugId);
                        this.createFG();
                    } else {
                        this.createNew();
                        this.patchNewDrugInfo();
                    }
                    if (this._location.path() !== "") {
                        this.route = this._location.path();
                    }
                })
            );
        } else {
            this.createNew();
            this.patchNewDrugInfo();
        }
    }

    ngOnInit() {
        this.saveBeforeRoute = false;
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        this._actvRoute.data.subscribe(resp => resp);

        if (!this.drugId) {
            this.imgURL = drugImage.replace("{imagefile}", "empty.jpg");
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    focusOutFmCan(event) {
        if (document.getElementById("QuickCode")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("QuickCode").focus();
            }, 10);
        }
    }

    patchNewDrugInfo(drugName?: any) {
        let drugInfo = null;
        if (drugName) {
            drugInfo = drugName;
        } else {
            drugInfo = sessionStorage.getItem("AddDrugInfo");
        }
        if (!this.openMMS) {
            sessionStorage.removeItem("AddDrugInfo");
        }
        if (drugInfo && drugInfo !== "" && drugInfo !== "undefined") {
            if (/^([0-9]{11})$/.test(drugInfo)) {
                if (drugInfo != null && drugInfo !== undefined) {
                    this.Drug.controls["DrugCode"].patchValue(+drugInfo ? +drugInfo : null);
                }
            } else {
                if (drugInfo.substring(0, 2) === "d:") {
                    drugInfo = drugInfo.substring(2, drugInfo.length + 1);
                }
                const drugNames = drugInfo.split(/[\s,]+/);
                this.Drug.controls["Name"].patchValue(drugNames[0] ? drugNames[0].trim() : null);
                this.Drug.controls["Strength"].patchValue(drugNames[1] ? drugNames[1] : null);
            }
        }
    }

    patchErxValues(erxDrugValues) {
        // let drugForm=this.defVal.FDA_Form
        //             .find(formElem => (formElem.FormName && formElem.FormName.toLowerCase() === erxDrugValues.DrugForm.toLowerCase()));
        this.Drug.patchValue({
            Name: erxDrugValues.DrugName.substring(0, 35),
            DrugCode: erxDrugValues.DrugNdc,
            DrugClass: erxDrugValues.DrugClass,
            IsActive: true,
            IsDeleted: false
        });
        if (erxDrugValues.DrugNdc) {
            this.optedNDC(erxDrugValues.DrugNdc);
        }
        this.Drug.controls["DrugFormId"].patchValue((erxDrugValues.DrugFormId) ? erxDrugValues.DrugFormId : null);
        this.Drug.controls["DrugUnitId"].patchValue((erxDrugValues.DrugUnitId) ? erxDrugValues.DrugUnitId : null);
        this.Drug.controls["MeasureUnitId"].patchValue((erxDrugValues.DrugUnitId) ? erxDrugValues.DrugUnitId : null);
        this.Drug.controls["Strength"].patchValue(erxDrugValues.strength);
        if (erxDrugValues.isCompoundDrug) {
            this.Drug.controls.DrugTypeId.patchValue(2);
            this.selectDrugType({ Id: 2 });
            // this.openCompound();
        }
    }

    optedNDC(drugData: string) {
        let re = /\-/gi;
        drugData = drugData.replace(re, "");
        this._commonServ.getDrugSuggest(drugData).subscribe(resp => {
            if (resp && resp.length === 1) {
                let drug = resp[0];
                this.Drug.patchValue({
                    QuickCode: drug.quickcode,
                    ManufactId: drug.manufactid,
                    DrugTypeId: drug.drugtypeid,
                    Strength: drug.strength,
                    DrugUnitId: drug.drugunitid,
                    GenericName: drug.genericname
                });
            }
        });
    }

    checkDuplicateQuickCode() {
        if (this.Drug.value["QuickCode"]) {
            if (
                this._commonServ.getSetttingValue(
                    "DrugSettings",
                    "UNIQUE_DRUG_QUICK_CODE"
                )=== "1") {
                if (this.drugId) {
                    this._drugSvc.checkDuplicateQuickCode(this.Drug.value["Id"], this.Drug.value["QuickCode"])
                        .subscribe(resp => {
                            if (resp === 2) {
                                this.Drug.controls[
                                    "QuickCode"
                                ].setErrors({ invalid: true });
                                this.Drug.controls["QuickCode"].markAsTouched();
                            } else {
                                this.Drug.controls[
                                    "QuickCode"
                                ].setErrors(null);
                                this.Drug.controls["QuickCode"].markAsUntouched();
                            }
                        });
                } else {
                    this._drugSvc.checkDuplicateQuickCode(0, this.Drug.value["QuickCode"])
                        .subscribe(resp => {
                            if (resp === 2) {
                                this.Drug.controls[
                                    "QuickCode"
                                ].setErrors({ invalid: true });
                                this.Drug.controls["QuickCode"].markAsTouched();
                            } else {
                                this.Drug.controls[
                                    "QuickCode"
                                ].setErrors(null);
                                this.Drug.controls["QuickCode"].markAsUntouched();
                            }
                        });
                }
            }
        }
    }

    selectdrugUnit(e?: any) {
        if (this.focusOnDU === "drugUnit") {
            if (this.drugInfo) {
                document.getElementById("UnitPriceCost")["value"] = this.drugInfo.Drug["UnitPriceCost"];
            } else {
                document.getElementById("UnitPriceCost")["value"] = "0.000";
            }
            const element = document.getElementById("UnitPriceCost") as HTMLInputElement;
            document.getElementById("UnitPriceCost").focus();
            element.select();
        }
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
        this.selectdrugUnit();
    }

    ngOnChanges(changes: SimpleChanges) {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        let drugFormName = null;
        let drugUnitType = null;
        this.defVal.FDA_Form.map((form: any) => {
            if (form.FDAFormID === this.drugEditFG.value.Drug.DrugFormId) {
                drugFormName = form.FormName;
            }
        });
        this.systemData.DrugUnit.map((unit: any) => {
            if (unit.Id === this.drugEditFG.value.Drug.DrugUnitId) {
                drugUnitType = unit.Remarks;
            }
        });

        this._rxUtils.saveBrowseHostory(
            "EditDrug",
            this.drugEditFG.value.Drug.Name +
            " " +
            this.drugEditFG.value.Drug.Strength +
            drugUnitType +
            " " +
            drugFormName,
            this.drugId
        );
        this._cdr.detach();
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    TruncateTo2OR3Dec(value, type) {
        let returnValue = value;
        const data = new RegExp("^-?\\d+(?:\.\\d{0," + (type || -1) + "})?");
        const convertedvalue = value.toString().match(data)[0];
        const decimalValue = convertedvalue.split(".");
        if (decimalValue && decimalValue[1] && decimalValue[1].length === 2) {
            returnValue = convertedvalue;
        } else {
            returnValue = (parseFloat(convertedvalue)).toFixed(type);
        }
        return returnValue;
    }

    convertByMouseDown(changes: any) {
        const dataInfo: any = this.drugEditFG.controls["Drug"];
        if (changes === "UnitPriceAWP" && dataInfo.controls["UnitPriceAWP3Digits"].dirty) {
            dataInfo.controls["UnitPriceAWP"].patchValue(
                (Number(dataInfo.controls["UnitPriceAWP3Digits"].value)).toFixed(4)
            );
            dataInfo.controls["UnitPriceAWP3Digits"].patchValue(
                (Number(dataInfo.controls["UnitPriceAWP3Digits"].value)).toFixed(4)
            );
            dataInfo.controls["AWPPack"].patchValue(
                (dataInfo.controls["UnitPriceAWP"].value * dataInfo.controls["QtyPack"].value).toFixed(2));
        } else if (changes === "UnitPriceCost" && dataInfo.controls["UnitPriceCost"].dirty) {
            dataInfo.controls["UnitPriceCost"].patchValue(
                (Number(dataInfo.controls["UnitPriceCost"].value)).toFixed(3)
            );
            dataInfo.controls["CostPack"].patchValue(
                (dataInfo.controls["UnitPriceCost"].value * dataInfo.controls["QtyPack"].value).toFixed(2));
        } else if (changes === "AWPPack" && dataInfo.controls["AWPPack"].dirty) {
            dataInfo.controls["AWPPack"].patchValue(
                (Number(dataInfo.controls["AWPPack"].value)).toFixed(2)
            );
            if (
                ((
                    dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceAWP"].patchValue(
                    (dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(4));
                    dataInfo.controls["UnitPriceAWP3Digits"].patchValue(
                        (dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value).toFixed(3));
            } else {
                dataInfo.controls["UnitPriceAWP3Digits"].patchValue("0.000");
                dataInfo.controls["UnitPriceAWP"].patchValue("0.0000");
            }
            dataInfo.controls["UnitPriceAWP"].markAsPristine();
            dataInfo.controls["UnitPriceAWP3Digits"].markAsPristine();
        } else if (changes === "CostPack" && dataInfo.controls["CostPack"].dirty) {
            dataInfo.controls["CostPack"].patchValue(
                (Number(dataInfo.controls["CostPack"].value)).toFixed(2)
            );
            if (
                ((
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceCost"].patchValue(
                    (dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(3));
            } else {
                dataInfo.controls["UnitPriceCost"].patchValue("0.000");
            }
            dataInfo.controls["UnitPriceCost"].markAsPristine();
        } else if (changes === "QtyPack" && dataInfo.controls["QtyPack"].dirty) {
            dataInfo.controls["QtyPack"].patchValue(
                (Number(dataInfo.controls["QtyPack"].value)).toFixed(3)
            );
            if (
                ((dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceAWP"].patchValue(
                    (dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(4));
                    dataInfo.controls["UnitPriceAWP3Digits"].patchValue(
                        (dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value).toFixed(3));
            } else {
                dataInfo.controls["UnitPriceAWP3Digits"].patchValue("0.000");
                dataInfo.controls["UnitPriceAWP"].patchValue("0.0000");
            }

            if (
                ((
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceCost"].patchValue(
                    (dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(3));
            } else {
                dataInfo.controls["UnitPriceCost"].patchValue("0.000");
            }
        }
    }

    createFG() {
        this.drugEditFG = this._fb.group({
            Drug: this._fb.group(new Drug()),
            PriceHistory: this._fb.group(new PriceHis())
        });
    }

    getInfo(drugId: number) {
        this._commonServ.getDrugInfo(drugId).subscribe(resp => {
            if (resp) {
                this._commonServ._drugInfo$.next(resp);
                this.drugInfo = resp;
                const PriceHisdata: any = this.drugEditFG.controls[
                    "PriceHistory"
                ];
                PriceHisdata.controls["OldAWPPrice"].patchValue(
                    this.drugInfo.Drug.AWPPack
                );
                PriceHisdata.controls["OldCostPack"].patchValue(
                    this.drugInfo.Drug.CostPack
                );
                PriceHisdata.controls["OldUnitDIR"].patchValue(
                    this.drugInfo.Drug.DirectUnitPrice
                );
                if (this.drugInfo.Drug.QtyPack) {
                    this.drugInfo.Drug.QtyPack = this.drugInfo.Drug.QtyPack.toFixed(
                        3
                    ).toString();
                }
                if (this.drugInfo.Drug.DefaultQty) {
                    this.drugInfo.Drug.DefaultQty = this.drugInfo.Drug.DefaultQty.toFixed(
                        3
                    ).toString();
                }

                if (this.drugInfo.Drug.AWPPack) {
                    this.drugInfo.Drug.AWPPack = this.drugInfo.Drug.AWPPack.toFixed(
                        2
                    ).toString();
                }

                if (this.drugInfo.Drug.DirectUnitPrice) {
                    this.drugInfo.Drug.DirectUnitPrice = this.drugInfo.Drug.DirectUnitPrice.toFixed(
                        3
                    ).toString();
                }
                if (this.drugInfo.Drug.CostPack) {
                    this.drugInfo.Drug.CostPack = this.drugInfo.Drug.CostPack.toFixed(
                        2
                    ).toString();
                }
                if (this.drugInfo.Drug.UnitPriceAWP) {
                    this.drugInfo.Drug.UnitPriceAWP = this.drugInfo.Drug.UnitPriceAWP.toFixed(
                        4
                    ).toString();
                }
                if (this.drugInfo.Drug.UnitPriceCost) {
                    this.drugInfo.Drug.UnitPriceCost = this.drugInfo.Drug.UnitPriceCost.toFixed(
                        3
                    ).toString();
                }
                if (this.drugInfo && this.drugInfo.Drug && this.drugInfo.Drug.DrugTypeId === 1) {
                    this.strenthReq = true;
                } else {
                    this.strenthReq = false;
                }

                if (this.drugInfo && this.drugInfo.Drug && this.drugInfo.Drug.Strength) {
                    this.drugInfo.Drug.Strength = this.drugInfo.Drug.Strength.trim();
                }
                // this.patchValue();
                for (const key in this.drugInfo) {
                    if (["Drug"].indexOf(key) > -1) {
                        for (const keys in this.drugInfo[key]) {
                            if (
                                [
                                    "DirectUnitPrice",
                                    "UnitPriceAWP",
                                    "UnitPriceCost",
                                    "DefaultQty",
                                    "QtyPack"
                                ].indexOf(keys) > -1
                            ) {
                                if (!this.drugInfo.Drug[keys]) {
                                    if (keys === "UnitPriceAWP") {
                                        this.drugInfo.Drug["UnitPriceAWP3Digits"] = "0.000";
                                    }
                                    this.drugInfo.Drug[keys] = "0.000";
                                }
                            } else if (
                                ["AWPPack", "CostPack"].indexOf(keys) > -1
                            ) {
                                if (!this.drugInfo.Drug[keys]) {
                                    this.drugInfo.Drug[keys] = "0.00";
                                }
                            } else if (["LastUpdateDt"].indexOf(keys) > -1) {
                                if (this.drugInfo.Drug.LastUpdateDt) {
                                    this.drugInfo.Drug.LastUpdateDt = moment(
                                        this.drugInfo.Drug.LastUpdateDt
                                    ).format("MM-DD-YYYY");
                                } else {
                                    this.drugInfo.Drug.LastUpdateDt = null;
                                }
                            }
                        }
                    }
                }

                this.originalData = this.drugInfo;
                this.patchValue();
                this.isBranded = this.drugInfo.Drug.IsBranded;
                if (this.drugInfo.ImageName) {
                    const drugImageFileSplit = this.drugInfo.ImageName.split(".");

                    this._data
                        .isFileExist(
                            drugImageFileSplit[0],
                            drugImageFileSplit[1]
                        )
                        .subscribe(isExist => {
                            if (isExist) {
                                this.imgURL = drugImage.replace(
                                    "{imagefile}",
                                    "" +
                                    drugImageFileSplit[0] +
                                    "." +
                                    drugImageFileSplit[1]
                                );
                            } else {
                                this.imgURL = drugImage.replace(
                                    "{imagefile}",
                                    "empty.jpg"
                                );
                            }
                        });
                } else {
                    this.imgURL = drugImage.replace("{imagefile}", "empty.jpg");
                }
                if (this.focusOnDU === "unitOfMeasure") {
                    if (this.drugInfo["Drug"]["Strength"]) {
                        this.drugInfo["Drug"]["Strength"].trim();
                    }
                    if (!this.drugInfo["Drug"]["Strength"] || this.drugInfo["Drug"]["Strength"] === "") {
                        document.getElementById("Strength").focus();
                        this.focusOn = "Strength";
                    } else if (!this.drugInfo["Drug"]["MeasureUnitId"]) {
                        const data: any = document.getElementById("strengthUnit").getElementsByTagName("input")[0];
                        data.focus();
                        this.focusOn = "StrengthUnit";
                    } else {
                        const data: any = document.getElementById("MeasureUnitId").getElementsByTagName("input")[0];
                        data.focus();
                        this.focusOn = "DrugUnit";
                    }
                }
            }
        });
    }

    createNew() {
        this.createFG();
        this.patchNewValue();
    }

    getDrugDefault() {
        this._drugSvc.getDrugDefaultval().subscribe(resp => {
            if (resp) {
                this.defVal = resp;
            }
        });
    }

    patchNewValue() {
        this.Drug.controls["DrugTypeId"].patchValue(1);
        this.Drug.controls["DrugFormId"].patchValue(1);
        this.Drug.controls["NDCQualiId"].patchValue(1);
        this.Drug.controls["UnitDoseIndId"].patchValue(1);
        this.Drug.controls["IsActive"].patchValue(true);
        this.Drug.controls["IsPreferred"].patchValue(false);
        this.Drug.controls["IsIncInRefilRem"].patchValue(false);
        this.Drug.controls["IsFDAApprovedNULL"].patchValue(1);
        this.Drug.controls["IsExclFrmUpdate"].patchValue(false);
        this.Drug.controls["IsPriceUpdAuto"].patchValue(true);
        this.Drug.controls["IsBranded"].patchValue(true);
        this.Drug.controls["DrugClass"].patchValue(0);
        this.Drug.controls["QtyPack"].patchValue(parseFloat("0.00"));
        this.Drug.controls["UnitPriceAWP"].patchValue(parseFloat("0.0000"));
        this.Drug.controls["UnitPriceAWP3Digits"].patchValue(parseFloat("0.000"));
        this.Drug.controls["UnitPriceCost"].patchValue(parseFloat("0.000"));
        this.Drug.controls["CostPack"].patchValue(parseFloat("0.00"));
        this.Drug.controls["AWPPack"].patchValue(parseFloat("0.000"));
        this.Drug.controls["DefaultQty"].patchValue(parseFloat("0.000"));
        this.Drug.controls["DirectUnitPrice"].patchValue(parseFloat("0.000"));
        this.drugEditFG.controls["Drug"].patchValue(this.Drug.value);
    }

    drugUnitAndUnitOfMeasureValidationCheck() {
        const dataInfo: any = this.drugEditFG.controls["Drug"];
        const contrlName = ["MeasureUnitId", "DrugUnitId", "Strength"];
        if (this.strenthReq) {
            contrlName.map(val => {
                if (val) {
                    if (!dataInfo.value[val] || (val === "Strength" && !dataInfo.value[val].trim())) {
                    dataInfo.controls[val].setErrors({"required": true});
                    }
                }
            });
        } else {
            contrlName.map(val => {
                if (val) {
                    dataInfo.controls[val].setValidators([
                        Validators.nullValidator
                    ]);
                    dataInfo.controls[val].setErrors(null);
                    dataInfo.controls[val].markAsTouched();
                }
            });
        }
    }

    addNewDrug() {
        const addDrugData = this.Drug.getRawValue();
        this.drugUnitAndUnitOfMeasureValidationCheck();
        addDrugData["Instructions"] = " ";
        addDrugData["QtyPack"] = parseFloat(this.Drug.value["QtyPack"]);
        addDrugData["UnitPriceAWP"] = parseFloat(this.Drug.value["UnitPriceAWP"]);
        addDrugData["UnitPriceCost"] = parseFloat(this.Drug.value["UnitPriceCost"]);
        addDrugData["CostPack"] = parseFloat(this.Drug.value["CostPack"]);
        addDrugData["AWPPack"] = parseFloat(this.Drug.value["AWPPack"]);
        addDrugData["DirectUnitPrice"] = parseFloat(this.Drug.value["DirectUnitPrice"]);
        addDrugData["QuickCode"] = addDrugData["QuickCode"] ? addDrugData["QuickCode"].trim() : null;
        if (this.Drug.valid && addDrugData["UnitPriceAWP"] && addDrugData["UnitPriceAWP"] !== 0) {
            this.formGroupInvalid = false;
            this._drugSvc.addNewDrug(addDrugData).subscribe(resp => {
                if (!(resp === -1)) {
                    if (resp === 1) {
                        this._alertSer.error(
                            "Drug already exists in the system. Duplicate NDC code.", false, "long"
                        );
                    } else if (resp === 2) {
                        this._alertSer.error("Quick Code must be unique", false, "long");
                        document.getElementById("QuickCode").focus();
                    } else {
                        if (this.isCompDrug) {
                            this.saveCompoundDrug(resp);
                        } else {
                            if (this.isFromErxToAdd) {
                                this.IsAddNewDrugCompleted.emit(resp);
                            } else {
                                this._alertSer.success(MsgConfig.SAVE_SUCCESS);
                                this._auditUtils.getChangedValues(
                                    null,
                                    this.Drug.value,
                                    "Add Drug",
                                    "Drug",
                                    this.drugId
                                );
                                this.canDeactivateRoute = true;
                                this._rxUtils.updateLatestRxWithEditDrugInfo(resp, this.routeFrom, null, "add", null);
                            }
                        }
                    }
                } else {
                    this._alertSer.error(MsgConfig.SAVE_FAIL);
                }
            });
        } else if (!addDrugData["UnitPriceAWP"] || addDrugData["UnitPriceAWP"] === 0) {
            if (document.getElementById("UnitPriceAWP")) {
                document.getElementById("UnitPriceAWP").focus();
            }
            this._alertSer.error("UnitPrice Awp Cannot be zero.", false, "long");
        } else {
            this.formGroupInvalid = true;
            this._alertSer.error("Enter required fields.");
        }
    }
    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.drugInfo) {
            if (["Drug"].indexOf(key) > -1 && this.drugInfo[key]) {
                this.drugEditFG.controls[key].patchValue(this.drugInfo[key]);
            }
        }
        if (this.drugInfo.Drug.UnitPriceAWP) {
            this.Drug.controls["UnitPriceAWP3Digits"].patchValue((Number(this.drugInfo.Drug.UnitPriceAWP)).toFixed(3));
        } else {
            this.Drug.controls["UnitPriceAWP3Digits"].patchValue("0.000");
        }
        this.drugEditFG.controls["Drug"].markAsPristine();

        this.drugEditFG.controls["Drug"].valueChanges.subscribe(value => {
            if (this.Drug.dirty) {
                this.canDeactivateRoute = false;
            } else {
                this.canDeactivateRoute = true;
            }
        });
        if (this.Drug.value.DrugTypeId === 2) {
            this.isCompDrug = true;
        }
    }

    get Drug(): FormGroup {
        return this.drugEditFG.get("Drug") as FormGroup;
    }

    get QuickCode(): FormControl {
        return this.drugEditFG.controls["Drug"].get("QuickCode") as FormControl;
    }

    get Name(): FormControl {
        return this.drugEditFG.controls["Drug"].get("Name") as FormControl;
    }

    get DrugCode(): FormControl {
        return this.drugEditFG.controls["Drug"].get("DrugCode") as FormControl;
    }

    get BillingNDC(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "BillingNDC"
        ) as FormControl;
    }

    get ManufactId(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "ManufactId"
        ) as FormControl;
    }

    get ReplaceNDC(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "ReplaceNDC"
        ) as FormControl;
    }

    get OldNDC(): FormControl {
        return this.drugEditFG.controls["Drug"].get("OldNDC") as FormControl;
    }

    get BrandOrGenNDC(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "BrandOrGenNDC"
        ) as FormControl;
    }

    get UPC(): FormControl {
        return this.drugEditFG.controls["Drug"].get("UPC") as FormControl;
    }

    get BrandName(): FormControl {
        return this.drugEditFG.controls["Drug"].get("BrandName") as FormControl;
    }

    get GenericName(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "GenericName"
        ) as FormControl;
    }

    get NDCQualiId(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "NDCQualiId"
        ) as FormControl;
    }

    get DrugFormSigCodeId(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "DrugFormSigCodeId"
        ) as FormControl;
    }

    get DrugUseSigCodeId(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "DrugUseSigCodeId"
        ) as FormControl;
    }
    get DefaultSigCode(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "DefaultSigCode"
        ) as FormControl;
    }

    get DefaultQty(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "DefaultQty"
        ) as FormControl;
    }

    get UnitDoseIndId(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "UnitDoseIndId"
        ) as FormControl;
    }

    get QtyPack(): FormControl {
        return this.drugEditFG.controls["Drug"].get("QtyPack") as FormControl;
    }

    get DrugTypeId(): FormControl {
        return this.drugEditFG.controls["Drug"].get("DrugTypeId") as FormControl;
    }

    get DrugFormId(): FormControl {
        return this.drugEditFG.controls["Drug"].get("DrugFormId") as FormControl;
    }

    get Strength(): FormControl {
        return this.drugEditFG.controls["Drug"].get("Strength") as FormControl;
    }

    get DrugUnitId(): FormControl {
        return this.drugEditFG.controls["Drug"].get("DrugUnitId") as FormControl;
    }

    get MeasureUnitId(): FormControl {
        return this.drugEditFG.controls["Drug"].get("MeasureUnitId") as FormControl;
    }

    get DrugClass(): FormControl {
        return this.drugEditFG.controls["Drug"].get("DrugClass") as FormControl;
    }

    get AWPPack(): FormControl {
        return this.drugEditFG.controls["Drug"].get("AWPPack") as FormControl;
    }
    get DefaultDays(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "DefaultDays"
        ) as FormControl;
    }

    get UnitPriceAWP(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "UnitPriceAWP"
        ) as FormControl;
    }

    get DirectUnitPrice(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "DirectUnitPrice"
        ) as FormControl;
    }

    get CostPack(): FormControl {
        return this.drugEditFG.controls["Drug"].get("CostPack") as FormControl;
    }

    get UnitPriceCost(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "UnitPriceCost"
        ) as FormControl;
    }

    get LastUpdateDt(): FormControl {
        return this.drugEditFG.controls["Drug"].get(
            "LastUpdateDt"
        ) as FormControl;
    }

    get IsFDAApprovedNULL(): FormControl {
        return this.drugEditFG.controls["Drug"].get("IsFDAApprovedNULL") as FormControl;
    }

    get IsIncInRefilRem(): FormControl {
        return this.drugEditFG.controls["Drug"].get("IsIncInRefilRem") as FormControl;
    }

    get IsPreferred(): FormControl {
        return this.drugEditFG.controls["Drug"].get("IsPreferred") as FormControl;
    }

    updateDrug() {
        const dataInfo = this.drugEditFG.value;
        this.drugUnitAndUnitOfMeasureValidationCheck();
        const PriceHisdata: any = this.drugEditFG.controls["PriceHistory"];

        PriceHisdata.controls["NewAWPPrice"].patchValue(
            dataInfo["Drug"]["AWPPack"]
        );
        PriceHisdata.controls["NewCostPack"].patchValue(
            dataInfo["Drug"]["CostPack"]
        );
        PriceHisdata.controls["NewUnitDIR"].patchValue(
            dataInfo["Drug"]["DirectUnitPrice"]
        );
        this.Drug.controls["Instructions"].setValue(" ");

        const editDrugData = this.drugEditFG.getRawValue();
        editDrugData["QtyPack"] = parseFloat(this.Drug.value["QtyPack"]);
        editDrugData["UnitPriceAWP"] = parseFloat(this.Drug.value["UnitPriceAWP"]);
        editDrugData["UnitPriceCost"] = parseFloat(this.Drug.value["UnitPriceCost"]);
        editDrugData["CostPack"] = parseFloat(this.Drug.value["CostPack"]);
        editDrugData["AWPPack"] = parseFloat(this.Drug.value["AWPPack"]);
        editDrugData["DirectUnitPrice"] = parseFloat(this.Drug.value["DirectUnitPrice"]);
        editDrugData["QuickCode"] = editDrugData["QuickCode"] ? editDrugData["QuickCode"].trim() : null;
        if (this.drugEditFG.valid && editDrugData["UnitPriceAWP"] && editDrugData["UnitPriceAWP"] !== 0) {
            this._drugSvc
                .updateDrugInfo(editDrugData)
                .subscribe(resp => {
                    if (resp === 0 || resp === 3 || resp === 5) {
                        this._auditUtils.getChangedValues(
                            this.originalData.Drug,
                            editDrugData.Drug,
                            "Edit Drug",
                            "Drug",
                            this.drugId
                        );
                        this.canDeactivateRoute = true;
                        this._rxUtils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "update", this.focusOnDU);
                        this._alertSer.success("Updated successfully.");
                    } else if (resp === 2) {
                        this._alertSer.error("Quick Code must be unique.", false, "long");
                        document.getElementById("QuickCode").focus();
                    } else if (resp === 1) {
                        this._alertSer.error("NDC code must be unique.", false, "long");
                    } else {
                        this._alertSer.error(MsgConfig.UPDATE_FAIL);
                    }
                });
        } else if (!editDrugData["UnitPriceAWP"] || editDrugData["UnitPriceAWP"] === 0) {
            if (document.getElementById("UnitPriceAWP")) {
                document.getElementById("UnitPriceAWP").focus();
            }
            this._alertSer.error("UnitPrice Awp Cannot be zero.", false, "long");
        }  else {
            this.formGroupInvalid = true;
        }
    }
    visiblePatientNotes() {
        this.drugNotes = true;
    }

    routeBackToPrevScreen() {
        this.canDeactivateRoute = true;
        if (this.isFromErxToAdd) {
            this.IsAddNewDrugCompleted.emit();
        } else {
            this._rxUtils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        }
        // this._rxUtils.routeBackFromDrug(this.routeFrom, this.focusOnDU, "save");
    }

    CancelPopTaggle() {
        if (this.Drug.dirty) {
            this.sharedCancel = true;
        } else {
            this.routeBackToPrevScreen();
        }
    }

    CancelPopClose(val) {
        if (val) {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        } else {
            this.sharedCancel = false;
        }
    }

    setNumberFormat(ControlName, number) {
        if (this.Drug.controls[ControlName].dirty) {
            this.Drug.controls[ControlName].patchValue((parseFloat(this.Drug.controls[ControlName].value)).toFixed(number));
        }
    }

    openCompound() {
        let modelRef = this._modalService.open(
            CompIngredListComponent,
            {
                size: "lg",
                backdrop: "static",
                keyboard: false,
                windowClass: "md-x-lg"       
            }
        );
        modelRef.componentInstance.DrugData = this.compoundDrugData;
        modelRef.componentInstance.DrugId = this.drugId;
        modelRef.componentInstance.IsFromDrug = true;
        modelRef.componentInstance.ErxPrescribedDrugs = this.isFromErxToAdd && this.erxDrugValues ? this.erxDrugValues : null;
        modelRef.componentInstance.EmitDrugData.subscribe(drugData => {
            this.compoundDrugData = drugData;
            this.confirmToChangeCompValues();
            modelRef.close();
        });
    }

    confirmToChangeCompValues() {
        let newUnitAwp = (this.compoundDrugData.totalAwp / this.compoundDrugData.totalCount).toFixed(3);
        let oldUnitAwp = this.Drug.value.UnitPriceAWP;
        let newUnitCost = (this.compoundDrugData.totalCost / this.compoundDrugData.totalCount).toFixed(3);
        let oldUnitCost = this.Drug.value.UnitPriceCost;
        if (
            (oldUnitAwp && parseFloat(newUnitAwp) != parseFloat(oldUnitAwp) &&
                (Math.abs(parseFloat(newUnitAwp) - parseFloat(oldUnitAwp)) > 0.05))
            ||
            (oldUnitCost && parseFloat(newUnitCost) != parseFloat(oldUnitCost) &&
                (Math.abs(parseFloat(newUnitCost) - parseFloat(oldUnitCost)) > 0.05))
        ) {
            this.modalRef = this._modalService.open(this.COMPDRUGPATCH);
        }
    }

    patchCompValuesToDrug() {
        this.Drug.patchValue({
            QtyPack: this.compoundDrugData.totalCount.toFixed(3),
            AWPPack: this.compoundDrugData.totalAwp.toFixed(2),
            CostPack: this.compoundDrugData.totalCost.toFixed(2),
            UnitPriceAWP: (this.compoundDrugData.totalAwp / this.compoundDrugData.totalCount).toFixed(4),
            UnitPriceAWP3Digits : (this.compoundDrugData.totalAwp / this.compoundDrugData.totalCount).toFixed(3),
            UnitPriceCost: (this.compoundDrugData.totalCost / this.compoundDrugData.totalCount).toFixed(3)
        });
        this.modalRef.close();
        this.modalRef = null;
    }

    selectDrugType(inputVal) {
        if (inputVal.Id === 1) {
            this.strenthReq = true;
        } else {
            this.strenthReq = false;
            this.drugUnitAndUnitOfMeasureValidationCheck();
        }
        if (inputVal.Id === 2) {
            this.isCompDrug = true;
            if (!this.drugId) {
                this._drugSvc.getDrugCompoundNDC()
                    .subscribe(resp => {
                        if (resp) {
                            this.Drug.controls.DrugCode.patchValue(resp);
                            this.compoundDrugData = null;
                            this.openCompound();
                        }
                    });
            }
        } else {
            this.isCompDrug = false;
        }
    }

    saveCompoundDrug(drugId) {
        let dataToPost = this.compoundDrugData;
        dataToPost["CompDrugId"] = drugId;
            dataToPost.list.map(elem => {
            elem.CompDrugId = drugId;
            return elem;
        });
        this._drugSvc.AddIngredientDrug(dataToPost).subscribe(resp => {
            if (resp) {
                this._auditUtils.getChangedValues(
                    this.originalData,
                    this.compoundDrugData.list,
                    "Add Compound Ingredients",
                    "Drug",
                    drugId
                );
                this.drugId = drugId;
                if (this.isFromErxToAdd) {
                    this.IsAddNewDrugCompleted.emit(drugId);
                } else {
                    this._modalService.open(this.COMPDRUGMISC);
                }
            } else {
                this._alertSer.error(MsgConfig.WRONG_MSG);
            }
        });
    }

    redirectToMisc(drugId) {
        this.canDeactivateRoute = true;
        this._route.navigate(
            ["/eprx/drug/" + drugId + "/miscInformation"],
            { queryParams: { fm: this.routeFrom, focus: "compound" } }
        );
    }
}
