import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService, AlertService } from "../services";
import { AccCategoryE, eRxE, FileMaintenanceE, Rx1E, RxE, SMSE, ToolsE } from "../models";
import { BasePrivGetter } from "./access-privs.guard";


@Injectable()
export class RxDurEntryGuard extends BasePrivGetter implements CanActivate {
    constructor(
        _userServ: UserService,
        _router: Router,
        _alertServ: AlertService
    ) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(
            AccCategoryE.FileMaintenance,
            FileMaintenanceE.SigFile,
            true
        );
        return reslt;
    }
}

@Injectable()
export class BrowseRxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx, RxE.BrowseRx, true);
        return reslt;
    }
}

@Injectable()
export class PatientHisGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.BrowseRx, RxE.BrowseRx, true);
        return reslt;
    }
}

@Injectable()
export class RxTransferGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx, RxE.RxTransfer, true);
        return reslt;
    }
}

@Injectable()
export class RefillQueueGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.RefillQueue, true);
        return reslt;
    }
}

@Injectable()
export class TransmissionHistoryGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.TransmissionHistory, true);
        return reslt;
    }
}

@Injectable()
export class FiledQueueGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.FiledQueue, true);
        return reslt;
    }
}

@Injectable()
export class ScheduleGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Tools, ToolsE.ScheduledTasks, true);
        return reslt;
    }

}

@Injectable()
export class RphDashboardGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.RphDashboard, true);
        return reslt;
    }
}

@Injectable()
export class ReturnToStockGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.ReturnToStock, true);
        return reslt;
    }
}

@Injectable()
export class InsuExcpReportGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.InsuranceExceptionReport, true);
        return reslt;
    }
}

@Injectable()
export class LabelQueueGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.LabelQueue, true);
        return reslt;
    }
}

@Injectable()
export class ReviewRxsTaggedForFollowUpGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.ReviewRxsTaggedForFollowUp, true);
        return reslt;
    }
}

@Injectable()
export class RxVerificationGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.RxVerification, true);
        return reslt;
    }
}

@Injectable()
export class ErxActionListGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.eRx, eRxE.eRxActionList, true);
        return reslt;
    }
}

@Injectable()
export class ErxReprotGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.eRx, eRxE.eRxExceptionReport, true);
        return reslt;
    }
}

@Injectable()
export class RphVerificationGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Rx1, Rx1E.PharmacistVerification, true);
        return reslt;
    }
}

@Injectable()
export class MessageLogGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.SMS, SMSE.MessageLog, true);
        return reslt;
    }
}

@Injectable()
export class MessageQueueGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.SMS, SMSE.MessageQueue, true);
        return reslt;
    }
}

