import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class CreateNPatchFG {

    constructor(
        private _fb: FormBuilder
      ) { }

    createAndPatchFGInfo(data) {
        return this.createMainForm(data);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                for (const control1 of control.controls) {
                    if (control1 instanceof FormControl) {
                        control1.markAsTouched({
                            onlySelf: true
                        });
                    } else if (control1 instanceof FormGroup) {
                        this.validateAllFormFields(control1);
                    }
                }
                // control.markAsTouched();
            }
        });
    }

    createMainForm(fgData) {
        let mainFormGroup = null;
        if (Array.isArray(fgData)) {
          return this.generateFA(fgData);
        } else if (typeof fgData === "object" && fgData !== null) {
          mainFormGroup = this._fb.group(fgData);
          Object.keys(fgData).filter(d => {
            if (Array.isArray(fgData[d]) || (typeof fgData[d] === "object" && fgData[d] !== null)) {
              mainFormGroup.setControl(d, this.createMainForm(fgData[d]))
            }
          });
        }
        return mainFormGroup;
      }

      generateFA(datFA) {
          const gFA = datFA.map(val => this.createMainForm(val));
          return this._fb.array(gFA);
      }
}
