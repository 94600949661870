import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter,
    AfterContentChecked, ChangeDetectorRef } from "@angular/core";
import { TransmissionUtils, NRxUtils, RxBillingUtil } from "src/app/utils";
import { RxStore } from "src/app/store";
import { Observable, Subject, forkJoin } from "rxjs";
import { TransmissionService, RxService, CommonService, AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonPrintService } from "src/app/services/common-print.service";
import { RxTransmisParams } from "src/app/models";
import { LabelQueueService } from "src/app/services/label-queue.service";
import * as moment from "moment";
import { CommonWarnorconfirmPopupComponent } from "../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-tr-rx-only",
    templateUrl: "./tr-rx-only.component.html"
})
export class TRRxOnlyComponent implements OnInit, OnChanges, AfterContentChecked {
    @Input() rxInfo: any;
    @Input() transmissionType: any;
    @Input() systemData: any;
    @Input() insrncToRevrs: any;
    @Input() transmitParams: any;
    @Input() insuPriorToRevrs: any;
    @Input() frmPatHis: any;
    @Input() gTBatchId: any;
    @Input() transreverseInfo: any;
    @Input() rxFG: any;
    @Input() FromReverseClaimScreen:any;
    @Input() FromUnbilledRx:any;
    @Input() rejectedTransRevsInfo: any;
    switchErr: any;
    labelSubscri: any;
    reverseClaimTranmissionDetails: any;
    type:string = '';
    isFrmTrPat: boolean;
    switchDownRxList: any;
    isTransmsnPaidRej: boolean;
    @Input()
    set TransmissionData(data) {
        if (data) {
            this.transmissionDataFromActions = data;
        }
    }

    @Output()
    IsTransmissionCompleted = new EventEmitter<any>();

    @ViewChild("TRANSRESULT", { static: true })
    TRANSRESULT: any;

    @ViewChild("SWITCHERROR", { static: true })
    SWITCHERROR: any;
    
    @Output() transmissionRejectRxs = new EventEmitter<any>();
    @Output() transSwitchDownRxs = new EventEmitter<any>();
    @Output() transmissionBothRejectSwitchDownRxs = new EventEmitter<any>();
    transmissionData$: Observable<any>;
    rxInfo$: Observable<any>;
    transcRespStatus: any;
    modelRef: any;
    isTransmsnPaid: boolean;
    isRjctActnEnbld: boolean;
    errorMessage: string;
    confirmModel: any;
    confirmModalName: string;
    bulkTransInfo: any;
    patientIndex = 0;
    transmResp: any;
    rxCost: number;
    grpTransFailRxs: any;
    grpTransRxs: any;
    payRecs: any;
    rxFG$: Observable<any>;
    transmissionDataFromActions: any;
    unsubscribe$: Subject<void> = new Subject();
    telephoneRxOriginId: number;

    constructor(
        private _transUtils: TransmissionUtils,
        private _rxStore: RxStore,
        private _transServ: TransmissionService,
        private _modalService: NgbModal,
        private _rxService: RxService,
        private _cdr: ChangeDetectorRef,
        private _cmnPrintServ: CommonPrintService,
        private _nrxUtls: NRxUtils,
        private _cmnService: CommonService,
        private _rxBillng: RxBillingUtil,
        private _alertService: AlertService,
        private _labelQueueService: LabelQueueService
    ) {
        this.transmissionData$ = this._rxStore.transmissionData$;
        this.rxInfo$ = this._rxStore.rxInfo$;
        this.rxFG$ = this._rxStore._rxFGBS$;
    }

    ngOnInit() {
        if (this.rejectedTransRevsInfo) {
            this.transmissionType = "TransReverse";
            console.log(this.rxFG)
            this.rxInfo = this.rxInfo ? this.rxInfo : this._rxStore.rxInfo$["source"]["value"];
            this.initiateTransmission();
        } else {
            if (this.transmissionType !== "TransMessage" && this.transmissionType !== "GroupTransmission"
            && this.transmissionType !== "ViewTranmsn" ) {
                this.initiateTransmission();
            } else if (this.transmissionType === "TransMessage" || this.transmissionType === "ViewTranmsn") {
                this.getAndShowTransmData();
            } else if (this.transmissionType === "GroupTransmission") {
                this.ShowTransDataForCompletedRx();
            }
        }     
        if (!this.systemData) {
            this.systemData = this._cmnService.systemData$["source"]["value"];
        }
        this._cmnService.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.RxOrigin && resp.RxOrigin.length) {
                this.telephoneRxOriginId = resp.RxOrigin.find(elm => elm.Name === 'Telephone').Id;
            }
        });
    }

    ngOnChanges(changes?: any) {
        if (changes && changes.transmResp && changes.transmResp.rxInfo) {
            // this.transmResp = changes.transmResp.currentValue;
            // if ((this.transmissionType === "TransMessage" || this.transmissionType === "GroupTransmission")
            //     && this.transmResp.transmissionDetails) {
            //     this.displayTransmissionInfo();
            // }
        }
    }

    updateModifyTime() {
        if (this.rxInfo$ && this.rxInfo$["source"]["value"]) {
            this.rxInfo$["source"]["value"]["PrescReFill"]["ModifiedDtTm"] = new Date();
            this._rxStore.storeRxInfo(this.rxInfo$["source"]["value"]);
        }
    }
    initiateNextTransforSelectedInsu(val: any) {
        if(val) {
            this.insrncToRevrs = val.Ins_Code ? val.Ins_Code : val.InsurerCode;
            this.insuPriorToRevrs = val.CoverageCD ? val.CoverageCD : (val.insuPriId - 1);
        }
    }
    async initiateTransmission() {
        if (this.transmissionType === "ReTransmission") {
            this.payRecs = await this._transServ.getPayRecord((this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.Id)
            ? this.rxInfo.PrescReFill.Id : 0).toPromise();
        }
        const transmissionParams = this._transUtils.createTransmissionParams(
            this.rxInfo,
            this.transmissionType,
            this.insrncToRevrs,
            this.insuPriorToRevrs,
            this.payRecs
        );
        if (this.transmissionType === "TrnsmtPatent" && this.transmissionType !== "ReTransmission" && transmissionParams["BillAs"] !== "S" && transmissionParams["BillAs"] !== "T" ) {
            this.initateTransmitPatientOnly();
        } else if(this.transmissionType === "ReTransmission") {
            this.initiateRxTransmission(transmissionParams);
        } else {
            this.initiateRxTransmission(transmissionParams);
            // this._transUtils.storeAndShowPatTransData(this.bulkTransInfo, 1);
        }
    }

    async initateTransmitPatientOnly() {
        let message: any;
        this.bulkTransInfo = await this._rxService.transmitPatientOnlyNw(this.rxInfo.Prescription.PatientId).toPromise();
        if (this.bulkTransInfo) {
            this.switchDownRxList = this.bulkTransInfo?.SwitchDownError?.length ? this.bulkTransInfo.SwitchDownError : null;
            if (this.bulkTransInfo && this.bulkTransInfo.SwitchDownError && this.bulkTransInfo.SwitchDownError.length && this.bulkTransInfo.MenuGroupTransmission && !this.bulkTransInfo.MenuGroupTransmission.length) {
                message = "All the transmission service switches are currently down, so the following Rx(s) cannot be transmitted."+ "<br>";
                this.bulkTransInfo.SwitchDownError.map(resp=>{
                    const textmsg = "<div>"+ resp.PrescNum + "-" + resp.ReFillNum + (this._cmnService.checkIsPartialFill({PartialFillNo : resp.PartialFillNo,
                        PartialIndStatus : resp.PartialIndStatus}) ? "-" + resp.PartialFillNo : "") + "</div>"  
                message = message + textmsg;
                })
                const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                    backdrop: false,
                    keyboard: false, windowClass: "large--content",
                    centered : true
                });
                modelRef.componentInstance.IsHeaderText = "Transmission Switch is down";
                modelRef.componentInstance.warnMsg = message;
                modelRef.componentInstance.okButton = true;
                modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp:any)=> {
                        modelRef.close();
                        this.emitCloseTransmitCmpltd(true);
                })
            } else {
                this.isFrmTrPat = true;
                this.getTransmittedRxs();
                const transData = this.bulkTransInfo[this.patientIndex];
                if (transData) {
                    const data: any =  new RxTransmisParams();
                    data["Ins_Code"] = transData["Insu_Code"];
                    data["PrescRefillId"] = transData["PrescrefillId"];
                    data["TransType"] = "B1";
                    data["rxId"] = transData["PrescriptionId"];
                    data["patientId"] = transData["PatientId"];
                    data["PrescNum"] = transData["RxNo"];
                    this.transmResp = await this._transServ.getTransmissionInfo(transData["RxTransmissionLogId"], data, transData["ClaimId"]);
                    if (this.transmResp  && this.transmResp.transmissionDetails && this.transmResp.transmissionDetails.VersionInfo) {
                        this.transmResp.transmissionDetails.VersionInfo = typeof this.transmResp.transmissionDetails.VersionInfo === "string"? this.transmResp.transmissionDetails.VersionInfo.split(":"):this.transmResp.transmissionDetails.VersionInfo;
                    }
                    this._rxStore.storeTransmissionData(this.transmResp);
                    await this.getRxNdDisplayTransInfo(true);
                    this.displayTransmissionInfo();
                    this.rxCost = this._transUtils.calculateRxCost(this.transmResp);
                } else {
                    this.emitCloseTransmitCmpltd(true);
                }
            }
        } else {
            this.emitCloseTransmitCmpltd(true);
        }
    }

    async getAndShowTransmData() {
        const Id = this.transmitParams["ItemId"];
        const claimID = this.transmitParams["claimID"];
        delete this.transmitParams["ItemId"];
        delete this.transmitParams["claimID"];
        const resp = await this._transServ.getTransmissionInfo(Id, this.transmitParams, claimID);
        if (resp) {
            this.showTransmsResp(resp);
        } else {
            this.emitAsTransmissionCompleted(true, true);
           // this.showTransmissionError();
        }
    }

    async ShowTransDataForCompletedRx() {
        this.bulkTransInfo = await this._transServ.getGroupTransmissionStatus(this.frmPatHis ? this.frmPatHis : 0,
             true, this.gTBatchId ? this.gTBatchId : 0).toPromise();
            this.getTransmittedRxs();
            const transData = this.bulkTransInfo[this.patientIndex];
            if (transData) {
                const data: any =  new RxTransmisParams();
                data["Ins_Code"] = transData["Insu_Code"];
                data["PrescRefillId"] = transData["PrescrefillId"];
                data["TransType"] = "B1";
                data["rxId"] = transData["PrescriptionId"];
                data["patientId"] = transData["PatientId"];
                data["PrescNum"] = transData["RxNo"];
                this.transmResp = await this._transServ.getTransmissionInfo(transData["RxTransmissionLogId"], data, transData["ClaimId"]);
                if (this.transmResp  && this.transmResp.transmissionDetails && this.transmResp.transmissionDetails.VersionInfo ) {
                    this.transmResp.transmissionDetails.VersionInfo =typeof this.transmResp.transmissionDetails.VersionInfo === "string"? this.transmResp.transmissionDetails.VersionInfo.split(":"):this.transmResp.transmissionDetails.VersionInfo;
                }
                this._rxStore.storeTransmissionData(this.transmResp);
                await this.getRxNdDisplayTransInfo(true);
                this.displayTransmissionInfo();
            } else {
                this.showTransmsResp(this.transmResp);
            }
        
    }

    getTransmittedRxs() {
        if (this.bulkTransInfo?.TransError?.length && this.bulkTransInfo?.SwitchDownError?.length) {
            this.transmissionBothRejectSwitchDownRxs.emit(true);
        } else if(this.bulkTransInfo && this.bulkTransInfo.TransError && this.bulkTransInfo.TransError.length > 0){
            this.transmissionRejectRxs.emit(true);
        } else if (this.bulkTransInfo && this.bulkTransInfo.SwitchDownError && this.bulkTransInfo.SwitchDownError.length > 0 && !this.isFrmTrPat) {
            this.transSwitchDownRxs.emit(true);
        }
        this.bulkTransInfo = (this.FromUnbilledRx || this.frmPatHis || this.isFrmTrPat) ? this.bulkTransInfo.MenuGroupTransmission : this.bulkTransInfo;
        if (this.bulkTransInfo && this.bulkTransInfo.length > 0) {
            this.grpTransRxs = this.bulkTransInfo.map(x => {
                    return (x.RxNo + "-" + x.RefillNumber + (x.FillNo ? ("-" + x.FillNo) : ""));
            });
        }
    }

    async getRxNdDisplayTransInfo(allDetailsNotNeeded: boolean = false) {
        this.rxInfo = await this._nrxUtls.getandStoreRxInfo(
            +this.transmResp.transmissionDetails.RXNo, this.transmResp.transmissionDetails.PrescRefillId, null, null, null, null, null, allDetailsNotNeeded);
    }

    async initiateRxTransmission(parms: any) {
        const resp = this.rejectedTransRevsInfo ? this.rejectedTransRevsInfo :( this.transmissionDataFromActions ? this.transmissionDataFromActions : await this._transServ.initiateRxTransmissionAsObsev(parms).toPromise());
        this.reverseClaimTranmissionDetails = resp;
        if (resp) {
            if(resp.IsTransmissionInitiated){
                if(parms.TransType == "B2" || this.rejectedTransRevsInfo){
                    let errorMessage = "The Selected Rx#:" + (this.rejectedTransRevsInfo ? this.rejectedTransRevsInfo?.transmissionDetails?.RXNo : parms.PrescNum) + " is being reversed by a different user on the same pharmacy.";
                    this._alertService.error(errorMessage);
                } else {
                    let errorMessage = "The Selected Rx#:" + parms.PrescNum + " is being transmitted by a different user on the same pharmacy.";
                    this._alertService.error(errorMessage);
                }
                this.emitCloseTransmitCmpltd();
                return;
            }
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
            this.showTransmsResp(resp);
        } else {
            this.emitAsTransmissionCompleted(true, true);
           // this.showTransmissionError();
        }
        this.calculateRxCost();
    }

    showTransmsResp(resp) {
        this.grpTransFailRxs = null;
        if (resp && !resp.ErrorMessage && resp.transmissionDetails) {
            if (this.transmissionType === "ReversReTrans" && (resp && resp.nCPDPRecv && resp.nCPDPRecv.ResponseStatus !== "R")) {
                this.transmResp = resp;
                if(this.transmResp  && this.transmResp.transmissionDetails && this.transmResp.transmissionDetails.VersionInfo) {
                    this.transmResp.transmissionDetails.VersionInfo = typeof this.transmResp.transmissionDetails.VersionInfo === "string"? this.transmResp.transmissionDetails.VersionInfo.split(":"):this.transmResp.transmissionDetails.VersionInfo;
                }
                this.updateNdCloseTransmsn();
            } else {
                this.transmResp = resp;
                if(this.transmResp  && this.transmResp.transmissionDetails && this.transmResp.transmissionDetails.VersionInfo) {
                    this.transmResp.transmissionDetails.VersionInfo = typeof this.transmResp.transmissionDetails.VersionInfo === "string"? this.transmResp.transmissionDetails.VersionInfo.split(":"):this.transmResp.transmissionDetails.VersionInfo;
                }
                this.rxCost = this._transUtils.calculateRxCost(this.transmResp);
                this.displayTransmissionInfo();
                this._rxStore.storeTransmissionData(resp);
            }
        } else {
            if (resp && resp.nCPDPRecv && resp.nCPDPRecv.ErrorMessage && resp.nCPDPRecv.ErrorMessage[0]) {
                this.errorMessage = resp.nCPDPRecv.ErrorMessage[0];
            } else if (resp && resp.ErrorMessage && resp.ClaimProcess) {
                this.type = "SWITCHERROR"
                this.errorMessage = resp.ErrorMessage;
            }
            else if(resp && !resp.ClaimProcess && resp.ErrorMessage){
                this.type = "BINERROR"
                this.errorMessage = resp.ErrorMessage
            }
            if (this.transmissionType === "GroupTransmission" && resp && resp["ErrorList"]
                && resp["ErrorList"]["length"] > 0) {
                    this.grpTransFailRxs = resp["ErrorList"];
            }
            if (this.errorMessage) {
                const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                    backdrop: false,
                    keyboard: false, windowClass: "large--content",
                    centered : true
                });
                modelRef.componentInstance.IsHeaderText = (this.type === "SWITCHERROR" && resp.ErrorMessage !== "BIN Cannot be Blank") ? "Transmission Switch is down" : "Warning!!";
                modelRef.componentInstance.warnMsg = this.errorMessage;
                modelRef.componentInstance.okButton = true;
                modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp:any)=>{
                    if(resp && this.type === "SWITCHERROR"){
                        modelRef.close();
                        this.emitAsTransmissionCompleted(true, true);
                    }
                    else if(resp && this.type === "BINERROR"){
                        modelRef.close();
                        this.emitAsTransmissionCompleted(true, true);
                    }
                })
            } else {
                this.emitAsTransmissionCompleted(true, true);
            }
        }
    }


    displayTransmissionInfo() {
        this.transcRespStatus = this._transUtils.getTransmissionResponse(
            this.transmResp.transmissionDetails.ResponseStatus
        );
        this.modelRef = this._modalService.open(this.TRANSRESULT, {
            backdrop: "static",
            size: "lg",
            windowClass: "md-x2-lg",
            keyboard: false,
            centered: this.transmissionType !== "GroupTransmission" ? true : false
        });
        const ele = document.getElementsByClassName("refill-prog--li inProgress--refill");
        if (ele && ele[0] &&
            (this.transmissionType === "GroupTransmission" || this.transmissionType === "TrnsmtPatent")) {
            const target: any = ele[0];
            target.parentNode.parentNode.scrollTop = target.offsetTop;
        }
    }

    splitEmittedEvent(event) {
        if (event && typeof event === "object") {
            this.emitAsTransmissionCompleted(event.isNoLabel, event.switchError, event.rxInfoAfterTransmission);
        } else {
            this.emitAsTransmissionCompleted(event);
        }
        // this.emitAsTransmissionCompleted(event.isNoLabel, event.switchError, event.rxInfoAfterTransmission);
    }
    async emitAsTransmissionCompleted(isNoLabel?: any , switchError?: any, rxInfoAfterTransmission?) {
        this.switchErr = switchError;
        if (this.modelRef) {
            this.modelRef.close();
            this.confirmModalName = null;
        }
        if (!this.rxInfo) {
            this.rxInfo = this._rxStore.rxInfo$["source"]["value"];
        }

        // if (this.transmissionType !== "TransMessage" && this.transmissionType !== "TransReverse"
        //     && this.isTransmsnPaid && !isNoLabel && this.rxInfo) {
        //     this._cmnPrintServ.printLabelToRx(this.rxInfo, true);
        //     this._cmnPrintServ.printMedGuide(this.rxInfo.Drug.id);
        // }
        // await this.printAfterTransmission(switchError);

        // new implementation for label
        if ( this.transmResp &&  this.transmResp.transmissionDetails &&
            this.transmResp.transmissionDetails.PrescRefillId !== this.rxInfo["PrescReFill"]["Id"]) {
                if (!rxInfoAfterTransmission) {
                    const rxInfoList = await this._cmnService.getRxDetailsByPrescNos([this.transmResp.transmissionDetails.RXNo],
                         +this.transmResp.transmissionDetails.PrescRefillId, null, null, null, true).toPromise();
                    this.rxInfo = rxInfoList[0];
                } else {
                    this.rxInfo = rxInfoAfterTransmission;
                }
        }
        if (this.rxInfo) {
            const isCash = await this._rxBillng.checkIsCahsRx(this.rxInfo, this.rxInfo.Patient, this.systemData);
            if (this.transmissionType !== "TransMessage" && this.transmissionType !== "TransReverse" &&
            (this.isTransmsnPaid || (isCash && (this.transmissionType === "TrnsmtPatent" ||
             this.transmissionType === "GroupTransmission"))) && !isNoLabel && this.rxInfo) {
                let showLabelPopup = this._cmnService.getSetttingValue("LabelSettings", "SHOW_LABEL_PRINT_POPUP_WHILE_FILLING_RX") === "1" ?
                 true : false;
                 if((this.telephoneRxOriginId !== this.rxInfo.PrescReFill.RxOriginId) || (this.telephoneRxOriginId === this.rxInfo.PrescReFill.RxOriginId  && showLabelPopup)){
                    this._cmnPrintServ.printLabelToRx(this.rxInfo, !showLabelPopup, false, false, null, "transmitRx");
                 }
                // this._cmnPrintServ.printMedGuide(this.rxInfo.Drug.id);
                if (showLabelPopup) {
                    this.labelSubscri = this._cmnPrintServ.isLabelPrintCompleted().subscribe(async res => {
                        if (res && (res.PrescNum == this.transmResp.transmissionDetails.RXNo) && (res.PrescReFill == this.transmResp.transmissionDetails.PrescRefillId)) {
                            if (res && (!res.type || res.type === "SaveAsPdf") && res.fromScreen === "transmitRx") {
                                this._labelQueueService.IsExistInLabelQueue(res.PrescReFill).subscribe(async resp => {
                                    if (resp) {
                                        this.removeFromLabelQueue(res);
                                    } else {
                                        await this.printAfterTransmission(switchError);
                                        this.labelSubscri.unsubscribe();
                                    }
                                });
                            } else if (res && (res.type === "Cancel" || res.type === "Queue")) {
                                await this.printAfterTransmission(switchError);
                                this.labelSubscri.unsubscribe();
                            } else {
                                await this.printAfterTransmission(switchError);
                                this.labelSubscri.unsubscribe();
                            }
                        }
                    });
                } else {
                    await this.printAfterTransmission(switchError);
                }
            } else {
                await this.printAfterTransmission(switchError);
            }
        } else {
            await this.printAfterTransmission(switchError);
        }
    }

    async removeFromLabelQueue(data) {
        if (data.type === "SaveAsPdf") {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content",
            centered : true
            });
            modelRef.componentInstance.warnMsg = " Do you want to remove the selected label from the label queue?";
            modelRef.componentInstance.IsHeaderText = "Confirmation";
            modelRef.componentInstance.yesButton = true;
            modelRef.componentInstance.noButton = true;
            modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(async (response: any) => {
                modelRef.close();
                if (response) {
                    this._labelQueueService.deleteRxsFromLabelQueue([{PrescRefillId : data.PrescReFill }]).toPromise();
                    this.getLabelCount();
                    await this.printAfterTransmission(this.switchErr);
                    if (this.labelSubscri) {
                        this.labelSubscri.unsubscribe();
                    }
                } else {
                    await this.printAfterTransmission(this.switchErr);
                    if (this.labelSubscri) {
                        this.labelSubscri.unsubscribe();
                    }
                }
            });
        } else if (!data.type) {
            this._labelQueueService.deleteRxsFromLabelQueue([{PrescRefillId : data.PrescReFill }]).toPromise();
            this.getLabelCount();
            await this.printAfterTransmission(this.switchErr);
            if (this.labelSubscri) {
                this.labelSubscri.unsubscribe();
            }
        }
    }

    getLabelCount() {
        const dateParams = {
            StartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
            EndDate: moment().subtract(0, "days").format("YYYY-MM-DD")
        };
        this._cmnService.getSideNavCounts(dateParams);
    }

    async printAfterTransmission(switchError) {
        if (this.transmissionType === "TrnsmtPatent" || this.transmissionType === "GroupTransmission") {
            if (this.grpTransFailRxs) {
                await this.saveGrpTrans(this.grpTransFailRxs);
            }
            this.checkHasNextPatTransData(switchError ? "switchError" : null);
        } else {
            this.emitCloseTransmitCmpltd(switchError ? "switchError" : null);
        }
    }

    async saveGrpTrans(failedBatchIDs) {
        await this._transServ.groupTransmStatusofFailedRx({"ErrorList": failedBatchIDs}).toPromise();
        // await this._transServ.updateUserReviewStatus(batchID, "R").toPromise();
    }

    async updateNdCloseTransmsn() {
        await this.reviewTransmissionResult();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
        }
        if (this.transmResp && this.transmResp.transmissionDetails && this.transmResp.transmissionDetails.ResponseStatus === "A") {
            this.emitAsTransmissionCompleted(true);
        } else {
            this.emitAsTransmissionCompleted();
        }
    }

    async reviewTransmissionResult() {
        const resp = this.transmResp !== "R" ? "P" : "R";
        return await this._transServ
            .updateUserReviewStatus(this.transmResp.transmissionDetails.BatchId, resp)
            .toPromise();
    }

    async checkHasNextPatTransData(switchError?) {
        let message: any;
        this.patientIndex += 1;
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.bulkTransInfo && this.bulkTransInfo[this.patientIndex]) {
            const transData = this.bulkTransInfo[this.patientIndex];
            const data: any =  new RxTransmisParams();
            data["Ins_Code"] = transData["Insu_Code"];
            data["PrescRefillId"] = transData["PrescrefillId"];
            data["TransType"] = "B1";
            data["rxId"] = transData["PrescriptionId"];
            data["patientId"] = transData["PatientId"];
            data["PrescNum"] = transData["RxNo"];
            this.transmResp = await this._transServ.getTransmissionInfo(transData["RxTransmissionLogId"], data, transData["ClaimId"]);
            if(this.transmResp  && this.transmResp.transmissionDetails && this.transmResp.transmissionDetails.VersionInfo ) {
                this.transmResp.transmissionDetails.VersionInfo = typeof this.transmResp.transmissionDetails.VersionInfo === "string"? this.transmResp.transmissionDetails.VersionInfo.split(":"):this.transmResp.transmissionDetails.VersionInfo;
            }
            if (this.transmResp && this.transmResp.transmissionDetails) {
                this._rxStore.storeTransmissionData(this.transmResp);
                await this.getRxNdDisplayTransInfo(true);
                this.displayTransmissionInfo();
            } else {
                this.showTransmsResp(this.transmResp);
            }
        } else {
            if (this.switchDownRxList && this.switchDownRxList.length) {
                message = "All the transmission service switches are currently down, so the following Rx(s) cannot be transmitted."+ "<br>";
                this.switchDownRxList.map(resp=>{
                    const textmsg = "<div>"+ resp.PrescNum + "-" + resp.ReFillNum + (this._cmnService.checkIsPartialFill({PartialFillNo: resp.PartialFillNo,
                        PartialIndStatus: resp.PartialIndStatus}) ? "-" + resp.PartialFillNo : "") + "</div>"  
                message = message + textmsg;
                })
                const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                    backdrop: false,
                    keyboard: false, windowClass: "large--content",
                    centered : true
                });
                modelRef.componentInstance.IsHeaderText = "Transmission Switch is down";
                modelRef.componentInstance.warnMsg = message;
                modelRef.componentInstance.okButton = true;
                modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp:any)=> {
                        modelRef.close();
                        this.emitCloseTransmitCmpltd(true);
                })
            } else {
                this.emitCloseTransmitCmpltd(switchError);
            }    
        }
        this.rxCost = this._transUtils.calculateRxCost(this.transmResp);
    }

    emitCloseTransmitCmpltd(error?: any) {
        const evnt = {
            error:  error ? "switchError" : "completed",
            reverseClaimTranmission :  this.reverseClaimTranmissionDetails
        }
        this.IsTransmissionCompleted.emit(this.FromReverseClaimScreen ?  evnt : (error ? "switchError" : "completed"));
        this._rxStore.resetTransmissionData();
    }

    patprescDrugInsModalType(type: string) {
        // window.alert("PAt pre drug pop");
    }

    ngAfterContentChecked() {
        if (this.transmResp && this.transmResp.transmissionDetails) {
            this.isTransmsnPaid = this._transUtils.isTransmsnPaid(this.transmResp);
            this.isTransmsnPaidRej = this._transUtils.isTransmsnPaidRej(this.transmResp);
            this.isRjctActnEnbld = this._transUtils.isTrnsRjctActnEnbld(this.transmResp, this.transmissionType);
        }
    }

    showTransmissionError() {
        this.errorMessage = "Transmission service is currently not " +
        "available. Message not received for " + this.rxInfo.Prescription.PrescNum + " batch";
        this.modelRef = this._modalService.open(
            this.SWITCHERROR, { centered: true, backdrop: false, windowClass:"large--content"});
        this.confirmModalName = "SWITCHERROR";
    }

    initiateNextTrans(val) {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        if (val) {
            this.rxInfo = val;
            this.transmissionDataFromActions = null;
            this.initiateTransmission();
        } else {
             // if (this.isTransmsnPaid) {
            //     this._cmnPrintServ.printLabelToRx(this.rxInfo, true);
            //     this._cmnPrintServ.printMedGuide(this.rxInfo.Drug.id);
            // }
            // this.closeTransmsnModalRef();

            //new  label implementation based on setting
            if (this.isTransmsnPaid) {
                let showLabelPopup = this._cmnService.getSetttingValue("LabelSettings", "SHOW_LABEL_PRINT_POPUP_WHILE_FILLING_RX") === "1" ? true : false;
                this._cmnPrintServ.printLabelToRx(this.rxInfo, !showLabelPopup);
                // this._cmnPrintServ.printMedGuide(this.rxInfo.Drug.id);
                if (showLabelPopup) {
                    let labelSubscri = this._cmnPrintServ.isLabelPrintCompleted().subscribe(resp => {
                        if (resp && resp.PrescNum == this.rxInfo.Prescription.PrescNum && resp.PrescReFill == this.rxInfo.PrescReFill.Id) {
                            this.closeTransmsnModalRef();
                            labelSubscri.unsubscribe();
                        }
                    });
                } else {
                    this.closeTransmsnModalRef();
                }
            } else {
                this.closeTransmsnModalRef();
            }
        }
    }

    async isRxDeleted(isNoLabel?) {
        if (this.modelRef) {
            this.modelRef.close();
            this.confirmModalName = null;
        }
        if (!this.rxInfo) {
            this.rxInfo = this._rxStore.rxInfo$["source"]["value"];
        }
        // if (this.transmissionType !== "TransMessage" && this.transmissionType !== "TransReverse"
        //     && this.isTransmsnPaid && !isNoLabel && this.rxInfo) {
        //     this._cmnPrintServ.printLabelToRx(this.rxInfo, true);
        //     this._cmnPrintServ.printMedGuide(this.rxInfo.Drug.id);
        // }
        // await this.printAfterDeleteCheck();


        // new implementation for label
        if (this.transmissionType !== "TransMessage" && this.transmissionType !== "TransReverse" && this.isTransmsnPaid && !isNoLabel && this.rxInfo) {
            let showLabelPopup = this._cmnService.getSetttingValue("LabelSettings", "SHOW_LABEL_PRINT_POPUP_WHILE_FILLING_RX") === "1" ? true : false;
            this._cmnPrintServ.printLabelToRx(this.rxInfo, !showLabelPopup);
            // this._cmnPrintServ.printMedGuide(this.rxInfo.Drug.id);
            if (showLabelPopup) {
                let labelSubscri = this._cmnPrintServ.isLabelPrintCompleted().subscribe(async resp => {
                    if (resp && resp.PrescNum == this.rxInfo.Prescription.PrescNum && resp.PrescReFill == this.rxInfo.PrescReFill.Id) {
                        await this.printAfterDeleteCheck();
                        labelSubscri.unsubscribe();
                    }
                });
            } else {
                await this.printAfterDeleteCheck();
            }
        } else {
            await this.printAfterDeleteCheck();
        }
    }

    async printAfterDeleteCheck() {
        if (this.transmissionType === "TrnsmtPatent" || this.transmissionType === "GroupTransmission") {
            if (this.grpTransFailRxs) {
                await this.saveGrpTrans(this.grpTransFailRxs);
            }
            this.checkHasNextPatTransData();
        } else {
            this.IsTransmissionCompleted.emit("DeleteCompleted");
            this._rxStore.resetTransmissionData();
        }
    }


    closeTransmsnModalRef() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.confirmModalName = null;
    }

    async closeAndRevwTransm() {
        await this.reviewTransmissionResult();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
        }
        this.closeTransmsnModalRef();
        this.emitCloseTransmitCmpltd();
    }

    calculateRxCost() {
        if (
            this.rxInfo &&
            this.rxInfo.Drug &&
            this.rxInfo.PrescReFill.DispQty
        ) {
            const cost = +this.rxInfo.Drug.unitpricecost ? this.rxInfo.Drug.unitpricecost :
             +this.rxInfo.Drug.unitpriceawp ? this.rxInfo.Drug.unitpriceawp : 0;
            this.rxCost =
                parseFloat(cost) *
                parseFloat(this.rxInfo.PrescReFill.DispQty);
        } else {
            this.rxCost = 0;
        }

    }

    retranmitRejectedScreen(value){
        this.closeTransmsnModalRef();
        if(value && value.isRejectedRetransmission){
            this.rxInfo = value.rxInfo;
                this.initiateTransmission();
        }
        
    }

    ngOnDestory(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
