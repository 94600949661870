import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { SystemData } from 'src/app/models';
import { VaccineImmunization } from 'src/app/models/rx.model';
import { AlertService, CommonService, InsuranceService, PatientService, RxService, UserService } from 'src/app/services';
import { RxStore } from 'src/app/store';
import { RxUtils } from 'src/app/utils';
import * as _ from "lodash";
import * as moment from 'moment';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
  selector: 'app-vaccine-immunization',
  templateUrl: './vaccine-immunization.component.html',
  styleUrls: ['./vaccine-immunization.component.scss']
})
export class VaccineImmunizationComponent implements OnInit, OnDestroy {
  vaccImmuneFG: FormGroup;
  modelRef: any;
  unsubscribe$: Subject<void> = new Subject();
  systemData: SystemData;
  @ViewChild("VaccineForm", {static: true})
  VaccineForm: any;
  @Input() rxFG: any;
  @Input() rxInfo: any;
  @Input() frmVerf: boolean;
  @Input() miscInfo: any;
  @Input() frmButton: boolean = false;
  @Input() frmQuickRef: boolean = false;
  @Output()IsPopUpClosed = new EventEmitter();
  vaccineResp: any;
  vaccDrpDwnInfo: any;
  morePatInfo: any;
  appUsrData: any;
  npi: any;
  drugInfo: any;
  costBasisDrpDown: any;
  durData= [];
  disableMinorcsnt: boolean;
  loggedUserId: any;
  formGrpInvld: boolean;

  constructor(private _fb: FormBuilder, private _mdlService: NgbModal, private _cmnServ: CommonService, private _patientService: PatientService, private _rxStore: RxStore, private _insuSvc: InsuranceService, private _alrtSvc: AlertService, private _rxUtils: RxUtils, private _rxServ: RxService, private _userService: UserService, public _formatsUtil:FormatsUtil) {
    this.createFG();
  }

  ngOnInit(): void {
    this.systemData = this._cmnServ.systemData$["source"]["value"];
    this.appUsrData = this._cmnServ.appUserData$["source"]["value"];
    const userId = this._userService.getToken("UserId");
    const userIdInt = parseInt(userId);
    this.loggedUserId = this.appUsrData.find((item: any) => item.AppUserId === userIdInt);
    this.drugInfo = this._rxStore.edtDrugInfo$["source"]["value"];
    this.costBasisDrpDown = this._insuSvc.dropdownData$["source"]["value"];
    this.durData = (this.rxInfo.rxType !== "er" && !this.frmVerf) ? ((this.rxInfo.rxType === "rf" || this.frmQuickRef) ? this.getDurValues() : this._rxStore.addDur$["source"]["value"]) : this._rxStore.storeDurNewRxFlow(null);;
    this.npi = this._cmnServ.getSetttingValue("TenantSettings", "TNT_NPI_NO") ?
    (this._cmnServ.getSetttingValue("TenantSettings", "TNT_NPI_NO")).trim() : "";
    this.getVaccineInfo();
  }

  getDurValues() {
    const PrescriptionId = this.rxFG && this.rxFG.controls['Prescription'] && this.rxFG.controls['Prescription'].value['Id'] ? this.rxFG.controls['Prescription'].value['Id'] : null;
    this._rxServ.getDurValues(PrescriptionId,null)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((resp)=>{
                        const  durDataNewRxFlow = [];
                    if (resp && resp.length) {
                        resp.map(item => {
                            if (item && !item.IsRefillSpecific) {
                            durDataNewRxFlow.push(item);
                            }
                        });  
                        if (durDataNewRxFlow && durDataNewRxFlow.length) {
                            this._rxStore.storeDurNewRxFlow(durDataNewRxFlow);
                            this.durData = this._rxStore.addDur$["source"]["value"];
                        }
                    }
                  });
  }

  async getVaccineInfo() {
    this.vaccImmuneFG.controls["UserId"].patchValue(this.loggedUserId?.AppUserId);
    const length = this.durData && this.durData.length > 0 ? this.durData.length : 0;
    const moreInfoData = await this._patientService.getMorepatientInfo(this.rxInfo["Patient"]["patientid"]).toPromise();
    this.morePatInfo = moreInfoData?.MorePatientInfo ? moreInfoData.MorePatientInfo : null;
    const dob = moment(this.rxInfo["Patient"]["dob"]);
    const years = moment().diff(dob, "year");
    this.disableMinorcsnt = years > 18 ? true : false;
    this.vaccImmuneFG.controls["VFCEligibilityId"].patchValue(years > 19 ? 1 : null)
    if (this.morePatInfo)
        this.patchPatientDetails();
    this.patchBillingInfo();
    const data = {
      PrescRefillId: (this.rxInfo.rxType === "er" || this.frmButton || this.frmVerf) ? this.rxInfo["PrescReFill"]["Id"] : null,
      RxId: (this.rxInfo.rxType === "er" || this.frmButton || this.frmVerf) ? this.rxInfo["Prescription"]["Id"] : null,
      PatientId: this.rxInfo["Patient"]["patientid"],
      DrugNdc: this.rxInfo["Drug"]["ndc"]
    }
    this._cmnServ.getVaccineImmunizationInfo(data).pipe(takeUntil(this.unsubscribe$)).subscribe(async (resp: any) => {
      if (resp) {
        this.vaccineResp = resp;
        this.vaccDrpDwnInfo = this.vaccineResp.VaccineDropDown;
        this.vaccImmuneFG.controls["AdminProvSuffixId"].patchValue(6);
        if (this.vaccineResp?.DURDetails?.length) {
          if (this.rxInfo.rxType === "er" || this.frmVerf) {
            this.durData = [];
            this.filterDurValues();
          } else {
            if (length) {
              this.filterDurValues();
            } else {
              this.durData = [this.vaccineResp.DURDetails[0]];
              this.durData =  _.uniqBy(this.durData, "Id");
              this._rxStore.storeDurNewRxFlow(this.durData);
            }
          }
        }
        if (this.rxInfo.rxType == "er" || this.frmVerf) {
          this.vaccImmuneFG.patchValue(this.vaccineResp?.VaccineImmunization);
          if (this.miscInfo && this.miscInfo.rxExtra) {
            const subClarfId = this.miscInfo.rxExtra.SubClarifId1 ? this.miscInfo.rxExtra.SubClarifId1 : (this.miscInfo.rxExtra.SubClarifId2 ? this.miscInfo.rxExtra.SubClarifId2 : this.miscInfo.rxExtra.SubClarifId3);
            this.vaccImmuneFG.controls["SubClarifId"].patchValue(subClarfId);
          }
        }
        this.modelRef = this._mdlService.open(this.VaccineForm, {centered: true, backdrop:false, keyboard: false, size:"lg"})
      }
    });
    const oldIncValue = this.vaccImmuneFG.controls["Incentive"].value;
    this.vaccImmuneFG.controls["Incentive_Old"].patchValue(oldIncValue);
  }

  filterDurValues() {
    if (this.vaccineResp.DURDetails?.length > 1) {
      this.vaccineResp.DURDetails.map((item: any) => this.durData.push(item));
    } else {
      this.durData.push(this.vaccineResp.DURDetails[0]);
    }
    this.durData =  _.uniqBy(this.durData, "Id");
    this._rxStore.storeDurNewRxFlow(this.durData);
  }

  createFG() {
    this.vaccImmuneFG = this._fb.group(new VaccineImmunization());
  }

  async patchBillingInfo() {
    if (this.rxInfo) {
      this.vaccImmuneFG.controls["Incentive"].patchValue(this.rxInfo["RxOtherAmount"]["Incentive"])
      this.vaccImmuneFG.controls["CostBasisId"].patchValue(this.rxInfo["RxOtherAmount"]["CostBasisCatId"]);
      if (this.rxInfo.rxType === "rf" || this.frmQuickRef) {
        const resp = await this._rxServ.getRxExtraDefaultValues(this.rxFG.value["PrescReFill"]["Id"] ?
            this.rxFG.value["PrescReFill"]["Id"] : 0).toPromise();
        if (resp && resp.rxExtra) {
          const subClarfId = resp.rxExtra.SubClarifId1 ? resp.rxExtra.SubClarifId1 : null;
          this.vaccImmuneFG.controls["SubClarifId"].patchValue(subClarfId);
        }
      } else {
        this.vaccImmuneFG.controls["SubClarifId"].patchValue(this.rxInfo["SubClarifIdComb"]);
      }
      
    }
  }
  patchPatientDetails() {
    this.vaccImmuneFG.patchValue({
      GuardienLastName: this.morePatInfo.GuardienLastName,
      GuardienFirstName: this.morePatInfo.GuardienFirstName,
      MaidenMiddleName: this.morePatInfo.MaidenMiddleName,
      PatientRaceId: this.morePatInfo.PatientRaceId,
      PatientEthnicityId: this.morePatInfo.PatientEthnicityId,
      PatientComorbidityStatusId: this.morePatInfo.PatientComorbidityStatusId,
      PatientRelationId: this.morePatInfo.PatientRelationId
    })
  }

  closeModal(type?: any) {
    if (this.modelRef) {
      this.modelRef.close();
      this.modelRef = null;
      if (type === 'cancel')
        this._rxStore.storeVaccineImmuneDetails(null);
      this.IsPopUpClosed.emit(null);
    }
  }

  changeToNumber(val: string, prop: string) {
    this.vaccImmuneFG.controls[prop].setValue(val ? (parseFloat(val)).toFixed(2) : "0.00");
  }

  saveImmunizationForm(){
    if (this.vaccImmuneFG.valid) {
      this.formGrpInvld = false;
      const immuneDetails = this.vaccImmuneFG.value;
      this._rxStore.storeVaccineImmuneDetails(immuneDetails);
      this.rxFG.controls["SubClarifIdComb"].patchValue(this.vaccImmuneFG.value.SubClarifId);
      const fg = this.rxFG.controls["RxOtherAmount"] as FormGroup;
      fg.controls["CostBasisCatId"].patchValue(this.vaccImmuneFG.value.CostBasisId);
      fg.controls["Incentive"].patchValue(this.vaccImmuneFG.value.Incentive);
      if (this.rxInfo["rxType"] === "er" || this.frmVerf) {
        const savedDet = this._rxStore.vacnImmune$["source"]["value"];
        savedDet.PatientId = this.rxInfo["Patient"]["patientid"];
        savedDet.PrescRefillId = this.rxInfo["PrescReFill"]["Id"];
        savedDet.PrescNum =  this.rxInfo["Prescription"]["PrescNum"];
        savedDet.RefillNum = this.rxInfo["PrescReFill"]["ReFillNum"];
        savedDet.FillNo = this.rxInfo["PrescReFill"]["PartialFillNo"];
        const payLoad = {
          VaccineImmunization: savedDet
        }
          this._cmnServ.updateVaccineImmunization(payLoad).subscribe((resp: any) => {
            if (resp) {
              this._rxStore.storeDurNewRxFlow(null);
              this._alrtSvc.success("Vaccine Immunization Details saved successfully.");
            }
          });
      }
      this.closeModal();
    } else {
      this.formGrpInvld = true;
      this._alrtSvc.error(MsgConfig.ENTER_REQ_FIELD);
    } 
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}

focusOnSave(event) {
  if (document.getElementById("SaveBtn")) {
    event.preventDefault();
    document.getElementById("SaveBtn").focus();
}
}
}
