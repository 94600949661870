import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    HostListener,
    Input
} from "@angular/core";

@Component({
    selector: "app-confirm-navigate",
    templateUrl: "./confirm-navigate.component.html"
})
export class ConfirmNavigateComponent implements OnInit {
    @Input() isFromDrug: boolean;
    @Output() EmitNavigateConfirm = new EventEmitter<any>();
    @Output() SaveBeforeNavigate = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27) {
            // esc
            this.Cancel();
            // this.modalRef = null;
            event.preventDefault();
        }
    }

    Confirm() {
        this.EmitNavigateConfirm.emit(true);
    }

    saveBeforeNavigate() {
        this.SaveBeforeNavigate.emit(false);
    }

    Cancel() {
        this.EmitNavigateConfirm.emit(false);
    }
}
