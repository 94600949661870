export const MaskConstant = {
    NUMBER: "0*",
    DIGIT: "00",
    CURRENCYS: "0*.00",
    FLOAT: "0*.00",
    NDC: "AAAA0-0000-00",
    TIME: "00:00:00",
    ZIP: "00000",
    ZIPEXT: "0*",
    PHONE: "(000) 000-0000",
    ALPHANUMERIC: "A*",
    STRING: "S*",
    IP: "0*.0*.0*.0*",
    NPI: "0000000000",
    SPI:"0000000000000",
    NUMSPC: "0*-09-09"
};
