import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { TransmissionRoutingModule } from "./transmission-routing.module";
import { SharedModule } from "../shared/shared.module";
import {
    TrRxPpdiRouteComponent,
    TrRxPayDetlsComponent,
    PrintAcptRejComponent,
    CommonModalComponent,
    TrRxActionsComponent,
    TrRxHeaderInfoComponent,
    TRRxOnlyComponent
} from ".";

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [
        TRRxOnlyComponent,
        TrRxPpdiRouteComponent,
        TrRxPayDetlsComponent,
        PrintAcptRejComponent,
        CommonModalComponent,
        TrRxActionsComponent,
        TrRxHeaderInfoComponent
    ],
    exports: [TRRxOnlyComponent],
    entryComponents: [TRRxOnlyComponent]
})
export class TransmissionModule {}
