import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService, RxService } from 'src/app/services';
import { CommonStore, RxStore } from 'src/app/store';
import * as _ from "lodash";
import { NRxUtils, PatchRXFGUtil } from 'src/app/utils';
import { StaticVaribles } from 'src/app/utils/static-varaibles.utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-rx-total-qty',
  templateUrl: './rx-total-qty.component.html'
})
export class RxTotalQtyComponent implements OnInit {
 @Input() RxNumber: any;
 @Input() AuthNumber: any;
 @Input() FromScreen: any;
 @Input() IsFromRefill: boolean;
 @Input() RefNumber: any;
 @Input() RxStatusObj: any;
 @Input()  IsQuickRefill: boolean;
 totalRxs: any = [];
 @Output() CloseRxTotalQtyComponentPop = new EventEmitter<any>();
 @Output() CloseRxTotalQtyForQuickRefill = new EventEmitter<any>();
 slctdRxObj: any = {};
 selectedRxs: any = [];
 totalRefillsForRx: any = [];
  isSelected: boolean;
 rxExpiredClasses:any =[];
 unsubscribe$: Subject<void> = new Subject();
  constructor(private _rxService: RxService, public _commonSer: CommonService,    private _rxStore: RxStore,
    private _commonStore: CommonStore,  private _nrxUtils: NRxUtils,  private _patchRx: PatchRXFGUtil,
    private static_Varibles:StaticVaribles) { }

  ngOnInit() {
    this.rxExpiredClasses = this.static_Varibles.getRxExpiredClasses();
    this.getRxData();
  }
  getRxData() {
    this.isSelected = false;
      this._rxService.getRxData(this.RxNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: any) => {
          if(resp && resp.rxData && resp.rxData.length) {
            this.totalRefillsForRx =  resp.rxData;
          resp.rxData.map((item: any, index: any) => {
           const qtyRemForPartialFill = (item.PrescReFill.PartialIndStatus === 'C') ?  "" : (item.islatestpartialrefill ?
            ((item && item.partialCummulativeDetails && item.TotalRemainingQty) ? item.TotalRemainingQty : 0).toFixed(3) : "");
            const itemRx = {
              totalRxObj:item,
              PrescNum : item.Prescription.PrescNum ,
              ReFillNum : item.Prescription.ReFillNum ,
              index:  index,
              patientId:  item["Patient"]["patientid"],
              PrescReFillId: item.PrescReFill.Id,
              selected: false,
              islatestpartialrefill : item.islatestpartialrefill,
              PrescReFill : item.PrescReFill.ReFillNum ,
              fillNumber : this._commonSer.checkIsPartialFill(item.PrescReFill, item.Prescription)
              ? (item.PrescReFill.PartialFillNo) : "" ,
              qtyRem : (qtyRemForPartialFill && qtyRemForPartialFill!== "0.000" && !item.PrescReFill.IsOverFillRx) ? qtyRemForPartialFill :
              (item.PrescReFill.IsOverFillRx ? resp["TotalRemainingQty"].toFixed(3) : ""),
              qtyOrdered : (item.PrescReFill && item.PrescReFill.OrdQty) ? item.PrescReFill.OrdQty : "",
              IsDisabled: this.rxExpiredClasses.includes(this.RxStatusObj.RxColorCoding),
              titleHoverOver : this.RxStatusObj.RxHoverOver
            };
            this.totalRxs.push(itemRx);
          });
          }
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}
  optedRx(itemEvent, index){
      if (itemEvent.target.checked) {
        this.isSelected = true;
        this.slctdRxObj[index] = this.totalRxs[index];
      } else {
        delete this.slctdRxObj[index];
        this.isSelected = false;
      }
      const convrtdArray = Object.keys(this.slctdRxObj).map(
        i => this.slctdRxObj[i]
     );
     if(this.selectedRxs && this.selectedRxs.length) {
      this.selectedRxs = this.selectedRxs.filter((item: any) => {  return item["index"] != index  });
     }
     this.selectedRxs =  [...this.selectedRxs, ...convrtdArray];
     this.selectedRxs =  _.uniqBy(this.selectedRxs, "PrescReFillId");
  }
  closeModal(isfromQuickRefill?) {
    if(!this.IsQuickRefill)
       this.CloseRxTotalQtyComponentPop.emit(true);
      else if(!isfromQuickRefill)
       this.CloseRxTotalQtyForQuickRefill.emit(null);
      
  }
  async goToRefillForSelectedRx() {
    this.CloseRxTotalQtyComponentPop.emit(false);
    if (this.selectedRxs && this.selectedRxs.length) {
      const prescNum = this.selectedRxs.map(val => {
        return val["PrescNum"];
      });
      const refillNumbers = this.selectedRxs.map(val => {
        return val["PrescReFill"];
      });
      
      const patientIds = this.selectedRxs.map(val => {
        return val["patientId"];
      });
      const patchObj = {
        Documents: [],
        patCompl: "Completed",
        patPopCompl: true,
        PrescReFill: {
        }
      };
      if (patientIds && patientIds.length) {
        patchObj["Patient"] = await this._commonSer.getRxPatientDetailsById(patientIds[0]).toPromise();
      }

      let rxFG = await this._nrxUtils.createPatchStoreRxFG();

      rxFG = this._patchRx.patchRxInfo(patchObj, rxFG);
      this._rxStore.StoreRxFG(rxFG);
      const data = {
        selectedRxs: prescNum, frm: this.FromScreen, completeData: prescNum, ReFillNum: refillNumbers,
        IsFromRefill : this.IsFromRefill,
        patHistData : (this.FromScreen  === "RxHistForTotalQuantityRem" || this.FromScreen  === "RefQueue")  ? rxFG.value : null , IsForPartialFill: true
      };
      this._commonStore.storeRefInfo(data);
      this._commonStore.storeRfStage("Start");
    } else {
      this.selectedRxs = [];
      this.closeModal();
    }

  }
  quickRefillProcess() {
    const processRefills: any = [];
    if(this.selectedRxs && this.selectedRxs.length) {
      this.selectedRxs.map((item: any)=> {
        processRefills.push(item['totalRxObj'])
      });
      this.CloseRxTotalQtyForQuickRefill.emit(processRefills);
    } else {
      this.CloseRxTotalQtyForQuickRefill.emit(null);
    }
     this.selectedRxs = [];
      this.closeModal('notEMit');
  }
}
