import { PipeTransform, Pipe } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: "tokenSeparator" })
export class TokenSeparatorPipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            const splStrng = value.split(":");
            if (splStrng && splStrng.length > 0) {
                return {"txt": splStrng[0], "token": splStrng[1]};
            } else {
                return {"txt": splStrng[0], "token": ""};
            }
        }
        return null;
    }
}
