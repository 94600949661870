import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: "[onReturn]"
})
export class OnReturnDirective {
    private el: ElementRef;
    @Input() onReturn: string;
    constructor(private _el: ElementRef) {
        this.el = this._el;
    }
    @HostListener("keydown", ["$event"]) onKeyDown(e) {
        if (e.which === 13 || e.keyCode === 13) {
            e.preventDefault();
            if (e.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.tagName === "EPRX-SELECT") {
                let srcEleData = e.srcElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
                if (srcEleData.nextElementSibling && srcEleData.nextElementSibling.className === "col") {
                    let data = srcEleData.querySelectorAll("input");
                    data[0].focus();
                }
            }
            return;
        }
    }
}
