import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DrugPickVerificationComponent } from ".";
import { ReviewDrugPickComponent } from "./review-drug-pick/review-drug-pick.component";

const routes: Routes = [
    {
        path: "",
        component: DrugPickVerificationComponent
    },
    {
        path: "review",
        component: ReviewDrugPickComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class DrugPickVerificationRoutingModule {}
