import { Injectable } from "@angular/core";
import { BehaviorSubject ,  Observable } from "rxjs";
import { RxModel, DrugData } from "../models";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class RxStore {

    _transmissionDataBS$: BehaviorSubject<any> = new BehaviorSubject(null);
    transmissionData$ = this._transmissionDataBS$.asObservable();

    _transmtPatientBS$: BehaviorSubject<any> = new BehaviorSubject(null);
    transmtPatientBS$ = this._transmtPatientBS$.asObservable();

    _rxInfoBS$: BehaviorSubject<RxModel> = new BehaviorSubject(null);
    rxInfo$ = this._rxInfoBS$.asObservable();

    _rxFGBS$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
    rxFG$ = this._rxFGBS$.asObservable();

    _rxFGBSLatestInfo$: BehaviorSubject<RxModel> = new BehaviorSubject(null);
    rxFGLatestInfo$ = this._rxFGBSLatestInfo$.asObservable();

    _edrxFGBS$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
    edrxFGBS$ = this._edrxFGBS$.asObservable();

    _marInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
    marInfo$ = this._marInfo$.asObservable();

    _rxHistry$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    rxHistry$ = this._rxHistry$.asObservable();

    _patSpecfcPrescbr$: BehaviorSubject<any> = new BehaviorSubject(null);
    patSpecfcPrescbr$ = this._patSpecfcPrescbr$.asObservable();

    _defltValByDrug$: BehaviorSubject<any> = new BehaviorSubject(null);
    defltValByDrug$ = this._defltValByDrug$.asObservable();

    _edtDrugInfo$: BehaviorSubject<DrugData> = new BehaviorSubject(null);
    edtDrugInfo$ = this._edtDrugInfo$.asObservable();

    _drugInsRestr$: BehaviorSubject<any> = new BehaviorSubject(null);
    drugInsRestr$ = this._drugInsRestr$.asObservable();

    _drugInsPopDat$: BehaviorSubject<any> = new BehaviorSubject(null);
    drugInsPopDat$ = this._drugInsPopDat$.asObservable();

    _patDemoGrph$: BehaviorSubject<any> = new BehaviorSubject(null);
    patDemoGrph$ = this._patDemoGrph$.asObservable();

    _drugInterctn$: BehaviorSubject<any> = new BehaviorSubject(null);
    drugInterctn$ = this._drugInterctn$.asObservable();

    _simlrFildRxs$: BehaviorSubject<any> = new BehaviorSubject(null);
    simlrFildRxs$ = this._simlrFildRxs$.asObservable();

    _morePatntInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
    morePatntInfo$ = this._morePatntInfo$.asObservable();

    _compoundData$: BehaviorSubject<any> = new BehaviorSubject(null);
    compoundData$ = this._compoundData$.asObservable();

    _isCashRx$: BehaviorSubject<any> = new BehaviorSubject(null);
    isCashRx$ = this._isCashRx$.asObservable();

    _authRefl$: BehaviorSubject<any> = new BehaviorSubject(null);
    authRefl$ = this._authRefl$.asObservable();

    _rxMiscInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxMiscInfo$ = this._rxMiscInfo$.asObservable();

    _rxDURInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxDURInfo$ = this._rxDURInfo$.asObservable();

    _WCOMInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
    WCOMInfo$ = this._WCOMInfo$.asObservable();

    _rxDME$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxDME$ = this._rxDME$.asObservable();

    _rxFollupTag$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxFollupTag$ = this._rxFollupTag$.asObservable();

    _rxTransfrIn$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxTransfrIn$ = this._rxTransfrIn$.asObservable();

    _addDur$: BehaviorSubject<any> = new BehaviorSubject(null);
    addDur$ = this._addDur$.asObservable();

    _genEquavlntDrugs$: BehaviorSubject<any> = new BehaviorSubject(null);
    genEquavlntDrugs$ = this._genEquavlntDrugs$.asObservable();

    _ACBScore$: BehaviorSubject<any> = new BehaviorSubject(null);
    ACBScore$ = this._ACBScore$.asObservable();

    _rxBucket$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxBucket$ = this._rxBucket$.asObservable();

    _refreshDocs$: BehaviorSubject<any> = new BehaviorSubject(null);
    refreshDocs$ = this._rxBucket$.asObservable();


    _calculations$: BehaviorSubject<any> = new BehaviorSubject(null);
    calculations$ = this._calculations$.asObservable();

    _rxRefDet$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxRefDet$ = this._rxRefDet$.asObservable();

    _vacnImmune$: BehaviorSubject<any> = new BehaviorSubject(null);
    vacnImmune$ = this._vacnImmune$.asObservable();

    _rxDetFrVerf$: BehaviorSubject<any> = new BehaviorSubject(null);
    rxDetFrVerf$ = this._rxDetFrVerf$.asObservable();

    constructor() {}

    storeRxInfo(data: RxModel): void {
        this._rxInfoBS$.next(data);
    }
    storeLatestRxInfo(data: RxModel): void {
        this._rxFGBSLatestInfo$.next(data);
    }
    storeTransmissionData(data): void {
        this._transmissionDataBS$.next(data);
    }

    resetTransmissionData(): void {
        this._transmissionDataBS$.next(null);
    }

    storeTransmtPatientData(data): void {
        this._transmtPatientBS$.next(data);
    }

    resetTransmtPatientData(): void {
        this._transmtPatientBS$.next(null);
    }

    StoreRxFG(data: FormGroup): void {
        this._rxFGBS$.next(data);
    }

    StoreEditRxFG(data: FormGroup): void {
        this._edrxFGBS$.next(data);
    }

    storeMarDetails(data) {
        this._marInfo$.next(data);
    }

    storeRxHistory(data) {
        this._rxHistry$.next(data);
    }

    storePatSpecPrescDels(data) {
        this._patSpecfcPrescbr$.next(data);
    }

    storeDrugSpecDefVal(data) {
        this._defltValByDrug$.next(data);
    }

    storeEditDrugInfo(data) {
        this._edtDrugInfo$.next(data);
    }

    storeDrugInsuRstrctn(data) {
        this._drugInsRestr$.next(data);
    }

    storeDrugInsuPopData(data) {
        this._drugInsPopDat$.next(data);
    }

    storePatntDemgphData(data) {
        this._patDemoGrph$.next(data);
    }

    storeDrugIntrctns(data) {
        this._drugInterctn$.next(data);
    }

    storeSimilarFiledRxs(data) {
        this._simlrFildRxs$.next(data);
    }

    storeMorePatientInfo(data) {
        this._morePatntInfo$.next(data);
    }

    storeCompoundData(data) {
        this._compoundData$.next(data);
    }

    storeIsCashRx(data) {
        this._isCashRx$.next(data);
    }

    storeAuthRefills(data) {
        this._authRefl$.next(data);
    }

    storeRxMiscInfo(data) {
        this._rxMiscInfo$.next(data);
    }

    storeDURInfo(data) {
        this._rxDURInfo$.next(data);
    }

    storeWCOMInfo(data) {
        this._WCOMInfo$.next(data);
    }

    storeDMEInfo(data) {
        this._rxDME$.next(data);
    }

    storeFollupTaInfo(data) {
        this._rxFollupTag$.next(data);
    }

    storeTransfrInInfo(data) {
        this._rxTransfrIn$.next(data);
    }
    storeDurNewRxFlow(data) {
        this._addDur$.next(data);
    }

    storeGenrcEquvlntDrugs(data) {
        this._genEquavlntDrugs$.next(data);
    }

    storeACBScore(data) {
        this._ACBScore$.next(data);
    }

    storeRxBuckList(data) {
        this._rxBucket$.next(data);
    }

    refreshDocsSection() {
        this._refreshDocs$.next(null);
    }

    storeRxBucketId(data) {
        if (this._rxFGBS$ && this._rxFGBS$["value"]) {
            const rxFG = this._rxFGBS$["value"];
            if(rxFG && rxFG.value && rxFG.value["PrescReFill"]) {
                rxFG.value["PrescReFill"]["BucketId"] = data;
            }
        }
    }

    priceCalculationsForSetting(data) {
        this._calculations$.next(data);
    }

    storeRefillRxDetails(data) {
        this._rxRefDet$.next(data);
    }

    storeVaccineImmuneDetails(data) {
        this._vacnImmune$.next(data);
    }

    storeRxDetailsFrVerf(data) {
        this._rxDetFrVerf$.next(data);
    }
}
