import { AuditLogUtils } from "./../../../utils/audit-log.util";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    OnChanges,
    Output,
    EventEmitter,
    HostListener
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonUtil, RxBillingUtil, RxUtils } from "src/app/utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxService, AlertService, ControlFileService, CommonService } from "src/app/services";
import { RecallDeleteRxService } from "src/app/services/recall-delete-rx.service";
import { RxOriginEnum } from "src/app/models";
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";

@Component({
    selector: "app-delete-rx",
    templateUrl: "./delete-rx.component.html"
})
export class DeleteRxComponent implements OnInit, OnChanges {
    confrim: boolean;
    erxPreNuEx: boolean;
    unbillRx: boolean;
    hasControl: boolean;
    controlRx: boolean;
    submittedRx: boolean;
    modalRef: any;
    controlRxMessgae: string;
    iocdrug = false;
    unsubscribe$: Subject<void> = new Subject();
    @Input() systemData: any;
    deletemsg: string;
    rxNum: any;
    refillNum: any;
    fillNum: any;
    partialIndStatus: any;
    checkIsCashRx: boolean;
    subscriptions = new Subscription();

    @Input()
    set RxInfo(ri: any) {
        this.rxInfo = ri;
        if (this.rxInfo && this.rxInfo.Drug && this.rxInfo.Drug.id) {
            if (this.rxInfo["Drug"]["drugclass"] >= 2 && this.rxInfo["Drug"]["drugclass"] <= 5) {
                this.controlRx = true;
            } else {
                this.controlRx = false;
            }
        }
    }

    @Input() openFrom:any;


    @Output()
    refreshRxHistory = new EventEmitter<null>();

    @Output()
    emitBrowseRx = new EventEmitter();

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    IsPopUpCancelled = new EventEmitter<any>();

    @Output()
    ReloadRxHistoryData = new EventEmitter<any>();

    @ViewChild("DeleteRxConfirmation", { static: true })
    DeleteRxConfirmation: any;

    @ViewChild("UnbilledRxError", { static: true })
    UnbilledRxError: any;

    @ViewChild("eRxDelete", { static: true })
    eRxDelete: any;

    @ViewChild("controlRxDelete", { static: true })
    controlRxDelete: any;

    @ViewChild("submittedDEA", { static: true })
    submittedDEA: any;

    @ViewChild("RxDeleteWarning", { static: true })
    RxDeleteWarning: any;

    rxInfo: any;
    modalRefSM: any;
    Erx: boolean;

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) {
            if (this.modalRef) {
                this.deleteErx(false);
                event.preventDefault();
            } else  if (this.modalRefSM) {
                this.closeModalSM();
                event.preventDefault();
            }
        }
    }

    constructor(
        private _router: Router,
        private _rxUtils: RxUtils,
        private _modalService: NgbModal,
        private _rxSvc: RxService,
        private _alertSvc: AlertService,
        private _auditUtils: AuditLogUtils,
        private _controlFileSvc: ControlFileService,
        public _commonServ: CommonService,
        private _recallSer: RecallDeleteRxService,
        private _rxBillng: RxBillingUtil, private _commUtil: CommonUtil
    ) { }

    ngOnInit() { 
        if(this.rxInfo){
            this.checkRxStatus();
        }
        
    }

    ngOnChanges() {}

    checkRxStatus() {
        this.rxNum = this.rxInfo.Prescription.PrescNum;
        this.refillNum = this.rxInfo.PrescReFill.ReFillNum;
        this.fillNum = this.rxInfo.PrescReFill.PartialFillNo;
        this.partialIndStatus = this.rxInfo.PrescReFill.PartialIndStatus;
        this._commonServ
            .getRxDetailsByPrescNos(
                [this.rxInfo.Prescription.PrescNum],
                this.rxInfo.PrescReFill.Id
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp2 => {
                if (resp2) {
                    this.rxInfo = resp2[0];
                    if (this.rxInfo) {
                        this.checkIsCashRx = await this._rxBillng.checkIsCahsRx(this.rxInfo, this.rxInfo.Patient, this.systemData);
                        if (this.checkIsCashRx && this.rxInfo.RxBill.StatusId === 2) {
                           this.openDeleteModal();
                        } else {
                            this._rxSvc.getIslastRefill(this.rxInfo.PrescReFill.Id, this.rxInfo.Prescription.PrescNum)
                            .pipe(takeUntil(this.unsubscribe$))
                                .subscribe(isLast => {
                                    if(isLast.RxPayCount > 0) {
                                        this.openDeleteModal();
                                    }
                                    else if (isLast.IsLastRefill === true) {
                                        this.deleteRxConfirmation();
                                    } else if (isLast.IsLastRefill === false) {
                                        this.deletemsg = "can't  be deleted. Please delete the latest refill first.";
                                        this.modalRefSM = this._modalService.open(this.RxDeleteWarning, {
                                            centered: true,
                                            backdrop: false,
                                            keyboard: false,
                                            windowClass: "large--content"
                                        });
                                    }
                                });
                        }
                    } else {
                        this.deletemsg = " is deleted by another user.";
                        this.modalRefSM = this._modalService.open(this.RxDeleteWarning, {centered: true,
                            backdrop: false,
                            keyboard: false,
                            windowClass: "large--content"
                        });
                    }

                }
            });
    }

    openDeleteModal() {
        this.modalRefSM = this._modalService.open(this.UnbilledRxError, {centered: true,
            backdrop: false,
            keyboard: false,
            windowClass: "large--content"
        });
        this.confrim = false;
        this.unbillRx = true;
        this.hasControl = false;
        this.erxPreNuEx = false;
    }

    closeModal() {
        if (this.modalRefSM) {
            this.modalRefSM.close();
        }
        this.IsPopUpClosed.emit(null);
    }

    closeModalSM() {
        if (this.modalRefSM) {
            this.modalRefSM.close();
        }
        this.emitBrowseRx.emit();
        this.IsPopUpCancelled.emit(null);
       // this.IsPopUpClosed.emit(null);
        this.ReloadRxHistoryData.emit(true);
    }

    deleteRxConfirmation() {
        this.modalRefSM = this._modalService.open(this.DeleteRxConfirmation, {centered: true,
            backdrop: false,
            keyboard: false,
            windowClass: "large--content"
        });
        this.confrim = true;
        this.unbillRx = false;
        this.hasControl = false;
        this.erxPreNuEx = false;
    }

    async deleteRx() {
        if (+this.rxInfo.Drug.drugclass >= 2 && +this.rxInfo.Drug.drugclass <= 5) {
            // DrugClass > 2 implies Control Drug means Control Rx
            if (this.modalRefSM) {
                this.modalRefSM.close();
            }
                this.controlRxMessgae = "Once submitted to the state and Rx is deleted, then the control Rx cannot be recalled.";
                this.modalRefSM = this._modalService.open(this.controlRxDelete, {centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content"
                });
                this.confrim = false;
                this.unbillRx = false;
                this.hasControl = true;
                this.erxPreNuEx = false;
                this.submittedRx = false;
        } else if (this.rxInfo.Patient.state !== "" &&
        (await this._recallSer.CheckIOCDrugorNot(this.rxInfo.Drug.ndc, this.rxInfo.Patient.state).toPromise())) {
            if (this.modalRefSM) {
                this.modalRefSM.close();
            }
            this.controlRxMessgae = "Once deleted, item of concern Rx can`t be recalled.";
            this.modalRefSM = this._modalService.open(this.controlRxDelete, {centered: true,
                backdrop: false,
                keyboard: false,
                windowClass: "large--content"
            });
            this.iocdrug = true;
            this.confrim = false;
            this.unbillRx = false;
            this.hasControl = true;
            this.erxPreNuEx = false;
            this.submittedRx = false;
        } else {
            this.checkForERx();
        }
    }

    checkForERx() {
        if (this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.RxOriginId ===  RxOriginEnum.Electronic && this.rxInfo.PrescReFill.ReFillNum === 0 && this.rxInfo.PrescReFill.PartialFillNo === 1) {
            if (this.modalRefSM) {
                this.modalRefSM.close();
            }
            this.modalRefSM = this._modalService.open(this.eRxDelete, {centered: true,
                backdrop: false,
                keyboard: false,
                windowClass: "large--content"
            });
            this.confrim = false;
            this.unbillRx = false;
            this.hasControl = false;
            this.erxPreNuEx = true;
            this.submittedRx = false;
        } else if ((+this.rxInfo.Drug.drugclass >= 2 && +this.rxInfo.Drug.drugclass <= 5) || this.iocdrug) {
            if (this.rxInfo.PrescReFill.IsControlSubmitted || this.rxInfo.PrescReFill.IsControlSubmittedToPh) {
                if (this.modalRefSM) {
                    this.modalRefSM.close();
                }
                this.deleteErx(false)
                // this.modalRefSM = this._modalService.open(this.submittedDEA, {
                //     backdrop: false,
                //     keyboard: false, windowClass: "large--content"});
                this.confrim = false;
                this.unbillRx = false;
                this.hasControl = false;
                this.erxPreNuEx = false;
                this.submittedRx = true;
            } else {
                this._rxSvc
                    .deleteRx(this.rxInfo.PrescReFill.Id, 1)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        if (resp === 1) {
                            this._commUtil.updateDashbordStatus();
                            this._auditUtils.deletedValues(
                                this.rxInfo.PrescReFill.Id,
                                "Delete Rx",
                                "New Rx",
                                this.rxInfo.PrescReFill.Id
                            );
                            this._alertSvc.success("Rx deleted successfully.");
                            this.closeModal();
                            this.ReloadRxHistoryData.emit(true);
                            this.emitBrowseRx.emit();
                          //  this.IsPopUpClosed.emit(null);
                            // this.LoadRxHistory(); refresh rx history
                            // this.refreshRxHistory.emit(null);
                        }   else if (resp === 2) { // delete failed
                            this._alertSvc.error(
                                "Rx delete failed."
                            );
                            this.closeModal();
                            this.ReloadRxHistoryData.emit(false);
                        } else if (resp === 3) { // already deleted.
                            this._alertSvc.error(
                                "Rx is already deleted."
                            );
                            this.closeModal();
                            this.ReloadRxHistoryData.emit(true);
                        } else {
                            //  this.refreshRxHistory.emit(false);
                            this.closeModal();
                            this.ReloadRxHistoryData.emit(false);
                        }
                    });
            }
        } else {
            this._rxSvc
                .deleteRx(
                    this.rxInfo.PrescReFill.Id,
                    this.rxInfo.PrescReFill.RxOriginId
                ).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === 1) {
                        this._auditUtils.deletedValues(
                            this.rxInfo.PrescReFill.Id,
                            "Delete Rx",
                            "New Rx",
                            this.rxInfo.PrescReFill.Id
                        );
                        this._alertSvc.success("Rx deleted successfully.");
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(true);
                        this.emitBrowseRx.emit();
                       // this.IsPopUpClosed.emit(null);
                    }  else if (resp === 2) { // delete failed
                        this._alertSvc.error(
                            "Rx delete failed."
                        );
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(false);
                    } else if (resp === 3) { // already deleted.
                        this._alertSvc.error(
                            "Rx is already deleted."
                        );
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(true);
                    } else {
                        //  this.refreshRxHistory.emit(false);
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(false);
                    }
                });
        }
    }

    checkForOtherCntns(updateStatus: boolean) {
        if ((+this.rxInfo.Drug.drugclass >= 2 && +this.rxInfo.Drug.drugclass <= 5) || this.iocdrug) {
            if (this.rxInfo && this.rxInfo.PrescReFill && (this.rxInfo.PrescReFill.IsControlSubmitted ||this.rxInfo.PrescReFill.IsControlSubmittedToPh)) {
                if (this.modalRefSM) {
                    this.modalRefSM.close();
                }
                this.Erx = true;
                this.deleteErx(false)
                // this.modalRefSM = this._modalService.open(this.submittedDEA, {
                //     backdrop: false,
                //     keyboard: false,
                //     windowClass: "large--content"});
                this.confrim = false;
                this.unbillRx = false;
                this.hasControl = false;
                this.erxPreNuEx = false;
                this.submittedRx = true;
            } else {
                this.Erx = true;
                this.deleteErx(updateStatus);
            }
        } else {
            this.deleteErx(updateStatus);
        }
    }

   async deleteErx(updateStatus: boolean) {
        if (updateStatus) {
         await   this._rxSvc
                .deleteRx(
                    this.rxInfo.PrescReFill.Id,
                    this.rxInfo.PrescReFill.RxOriginId
                ).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === 1) {
                        this._auditUtils.deletedValues(
                            this.rxInfo.PrescReFill.Id,
                            "Delete Rx",
                            "New Rx",
                            this.rxInfo.PrescReFill.Id
                        );
                        // this.LoadRxHistory(); refresh rx history
                        // this.refreshRxHistory.emit(null);
                        if (this.Erx) {
                            this._alertSvc.success(
                                "Rx deleted successfully and eRx updated to 'Ready To Process'."
                            );
                        } else {
                            this._alertSvc.success(
                                "Rx deleted successfully."
                            );
                        }
                        this.closeModal();
                        if(!this.openFrom || this.openFrom !== 'patHis'){
                            this.IsPopUpCancelled.emit(null);
                        }
                        this.ReloadRxHistoryData.emit(true);
                    //  this.IsPopUpClosed.emit(null);
                        this.emitBrowseRx.emit();
                    }  else if (resp === 2) { // delete failed
                        this._alertSvc.error(
                            "Rx delete failed."
                        );
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(false);
                    } else if (resp === 3) { // already deleted.
                        this._alertSvc.error(
                            "Rx is already deleted."
                        );
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(true);
                    } else {
                        //  this.refreshRxHistory.emit(false);
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(false);
                    }
                });
        } else if (!updateStatus) {
          await  this._rxSvc
                .deleteRx(this.rxInfo.PrescReFill.Id, 1)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === 1) {
                        if (this.submittedRx) {
                            this.updateDeaSubmission();
                        }
                        this._auditUtils.deletedValues(
                            this.rxInfo.PrescReFill.Id,
                            "Delete Rx",
                            "New Rx",
                            this.rxInfo.PrescReFill.Id
                        );
                        // this.LoadRxHistory(); refresh rx history
                        // this.refreshRxHistory.emit(null);
                        this._alertSvc.success("Rx deleted successfully.");
                        this.closeModal();
                        if(!this.openFrom || this.openFrom !== 'patHis'){
                            this.IsPopUpCancelled.emit(null);
                        }
                        //this.IsPopUpCancelled.emit(null);
                        this.ReloadRxHistoryData.emit(true);
                      //  this.IsPopUpClosed.emit(null);
                        this.emitBrowseRx.emit();
                    } else if (resp === 2) { // delete failed
                        this._alertSvc.error(
                            "Rx delete failed."
                        );
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(false);
                    } else if (resp === 3) { // already deleted.
                        this._alertSvc.error(
                            "Rx is already deleted."
                        );
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(true);
                    } else {
                        //  this.refreshRxHistory.emit(false);
                        this.closeModal();
                        this.ReloadRxHistoryData.emit(false);
                    }
                });
        }
    }

    updateDeaSubmission() {
        let dataToPost = {
            PrescRefillId: this.rxInfo.PrescReFill.Id,
            ReportingStatus: '02',
            IsActive: 1,
            State: (this.rxInfo && this.rxInfo.Patient && this.rxInfo.Patient.state) ?  this.rxInfo.Patient.state  : '',
        };
        this.subscriptions.add(this._controlFileSvc.updateStatus(dataToPost)
            .subscribe(resp => {
                if (resp) {
                    if (this.subscriptions) {
                        this.subscriptions.unsubscribe()
                        this.subscriptions = new Subscription();
                    }
                }
            }));
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
       }

}
