import { Component, OnInit, Input, AfterViewInit, ViewChild, EventEmitter, Output, ChangeDetectorRef } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService, CommonService, DMSService, FaxService } from "src/app/services";
import { DomSanitizer } from "@angular/platform-browser";
import { PrintService } from "src/app/services/print.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

@Component({
    selector: "app-pdf-viewer",
    templateUrl: "./pdf-viewer.component.html"
})
export class PdfViewerComponent implements OnInit,AfterViewInit {

    totalPages = 0;
    src = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    numbersArray: any[] = [];
    currentPagenumber: any = 1;
    timer: any;
    preventSimpleClick: any;
    zoomSize = 1;
    pdfData: any;
    displayAreaHeight: string;

    @ViewChild("docSplitTemp")
    docSplitTemp: any;

    @ViewChild("removeFromQTemp")
    removeFromQTemp: any;

    @ViewChild("PDFPreviewTemp", { static: true })
    PDFPreviewTemp: any;

    splitDocFG: any;
    catDropdowns: any[];
    subCatDropdowns: any[];
    selectedCategory: any;
    patientSearchValue = "";
    prescSearchValue = "";
    drugSearchValue = "";
    modelRef: any;
    docName: any;
    faxDocument: any;
    insuranceData: any;
    pagesToSplit: any[];
    showSplitModal: boolean;
    pdfBlob: any;
    pdfContent: any;
    isfrom: any;

    @Input()
    set PdfData(data: any) {
        if (data) {
            this.pdfData = data;
        }
    }

    @Input()
    set PdfBlob(data: any) {
        if (data) {
            this.pdfBlob = data;
            const urlCreator = window.URL;
            this.pdfData = urlCreator.createObjectURL(data);
        }
    }

    @Input()
    set DocName(data: any) {
        if (data) {
            this.docName = data;
        }
    }

    @Input()
    set FaxDoc(data: any) {
        if (data) {
            this.faxDocument = data;
        }
    }

    @Input()
    set isFrom(data: any) {
        if (data) {
            this.isfrom = data;
        }
    }

    @Output() RefreshParent = new EventEmitter<any>();

    constructor(
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _alertSer: AlertService,
        private _commonServ: CommonService,
        private _dmsSer: DMSService,
        private _faxSer: FaxService,
        private _cdr: ChangeDetectorRef,
        private _sanitizer: DomSanitizer,
        private _printServ: PrintService,
        private routerActive: ActivatedRoute,
        private _router: Router
    ) { }

    ngOnInit() { }


    ngAfterViewInit() {
        // const element = document.getElementById("pdfBlock");
        // if (element) {
        //     this.displayAreaHeight =
        //         window.innerHeight -
        //         element.getBoundingClientRect().top +
        //         window.scrollY -
        //         100 +
        //         "px";
        // }
    }


    afterLoadComplete(pdf): void {
        this.numbersArray = [];
        this.totalPages = pdf.numPages;
        for (let i = 0; i < this.totalPages; i++){
            this.numbersArray.push({ Index: i, IsSelected: false });
        }
    }

    splitDoc() {
        if (!(this.numbersArray.some(elm => elm.IsSelected))) {
            this._alertSer.error((this.isfrom && this.isfrom === "fax") ? "Please select atleast one Document." : "Please select atleast one Page.");
            return;
        }
        if (this.numbersArray.some(elm => elm.IsSelected)) {
            this.pagesToSplit = this.numbersArray.filter(elm => elm.IsSelected).map(elm => elm.Index);
            this.showSplitModal = false;
            this._cdr.detectChanges();
            setTimeout(() => {
                this.showSplitModal = true;
            }, 100);
        }
    }

    closeSplitModal(refresh?) {
        this.showSplitModal = false;
        if (refresh) {
            this.RefreshParent.emit(true);
        }
    }

    changePageNumber(pageNo, eve) {
        this.currentPagenumber = pageNo;
    }

    selectPage(item) {
        item.IsSelected = !item.IsSelected;
    }

    simpleClickFunction(pageNo, eve): void {
        this.timer = null;
        this.preventSimpleClick = false;
        let delay = 200;
        this.timer = setTimeout(() => {
            if (!this.preventSimpleClick) {
                this.currentPagenumber = pageNo;
            }
        }, delay);

    }

    doubleClickFunction(item, eve): void {
        this.preventSimpleClick = true;
        clearTimeout(this.timer);
        item.IsSelected = !item.IsSelected;
    }

    goToDocQueue() {
        this._router.navigate(["/eprx/dms/documentQueue"]);
    }

    previewPdf() {
        const urlCreator = window.URL;
        this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(urlCreator.createObjectURL(this.pdfBlob));
        this.modalService.open(this.PDFPreviewTemp, {
            size: "lg",
            centered: true,
            keyboard: false,
        });
    }

    printPdf() {
        const newBlob = new Blob([this.pdfBlob], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }

    refreshParent(refresh) {
        this.RefreshParent.emit(refresh);
    }
}
