<div class="editpatient--body newrx--body edit--drug insurance--body erx--body">
    <div class="row exprx--common-block height_50rem">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15 flex--one">
            <div class="box">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class=" col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    Documents Information
                                </div>
                                <div class=" text-right pull-right col-lg-3 col-md-5 col-sm-5 col-xs-5 padding_left_2rem">
                                    Subcategory
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding--left__7 margin_5_0_0_0px padding_right_20px">
                                    <eprx-select [LabelText]="''" [ControlName]="'docSubCatId'" [FormGroupName]="postDocFG" [HasMultiple]="false" [HasBindLabel2]="false"
                                        [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="subCatDropdowns"
                                        (TriggerSelectValue)="selectSubCategory($event)" [RxSelectId]="'docSubCatId'" [IsFirstElem]="true">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-0 col-md-0 col-sm-0 col-xs-0 padding_left_3rem">
                                    <button class="hotkey_primary hotkey_primary--Override" title="Add" (click)="opendocModal(docData)" (keydown.tab)="focusOutFmAdd($event)" appShortcutKey [CtrlKey]="'a'"><span>A</span>ADD</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col">
                            <div class="exprx--common-block" *ngIf="showGrid">
                                <wj-flex-grid #documentInfo class="grid" [headersVisibility]="'Column'" [itemsSource]="documentsList" [isReadOnly]="true"
                                    [selectionMode]="'Row'" (keydown)="addEventonWijimo(docData, $event)" (keydown.enter)="editEventOnWijimo(docData, $event, documentInfo)" (keydown.tab)="focusOnCancel($event)" [allowSorting]=true  [columnPicker]="''">
                                    <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders; let i = index"
                                        title={{actvHeader}} [width]="'*'">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <div class="text-center" *ngIf="actvHeader === 'Actions'">
                                                <i class="far  fa-edit  actions " title="Edit" (click)="editDocPopup(docData , item)"></i>
                                                <i class="far fa-trash-alt  actions danger-actve" title="Delete" (click)="deleteDocPopup(deleteInfo , item)"></i>
                                                <i class="far fa-file-download  actions " title="Download" (click)="downloadSelectedFile(item)"></i>
                                                <i class="far fa-tv  actions " title="Preview" (click)="previewSelectedFile(item)"></i>
                                            </div>
                                            <div *ngIf="!(actvHeader === 'Actions')">
                                                {{item[actvHeader]}}
                                            </div>
                                        </ng-template>
                                    </wj-flex-grid-column>
                                </wj-flex-grid>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!showGrid" class="eprx--block__footer">
                    <div>
                        <span></span>
                        <i class="fa fa-file"></i><br/>
                        No Data Found!
                    </div>
                </div>

            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15 flex--one">
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Document preview
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col">
                            <div class="exprx--common-block imagePreview">
                                <lib-ngx-image-zoom *ngIf="fileTypeToShow == 'image' && imageToShow" [thumbImage]=imageToShow [fullImage]=imageToShow [magnification]="1"
                                    [enableScrollZoom]="true" [enableLens]="true"  [lensHeight]="100" [lensWidth]="200"></lib-ngx-image-zoom>
                              <!--   <img *ngIf="fileTypeToShow == 'image'" [src]="imageToShow" height="100%" width="100%"> -->
                                <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="imageToShow" class="text-center width_100per border_3px_solid_black" toolbar="0" width="100%" height="100%" type="application/pdf"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #docData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!editMode">Attach Document</h4>
        <h4 class="modal-title" *ngIf="editMode">Edit Document</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close-button" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient insurance--body">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <div class="row col-sm-12">
                            <div [ngClass]=" editMode ?'col-md-12':'col-md-6'">
                                <div class="row">
                                    <div class="col-md-6">
                                        <eprx-input *ngIf="!editMode" [AutoFocus]="true" [LabelText]="'Document ID'" [PlaceHolder]="''" [ControlName]="'Id'"
                                            [FormGroupName]="documentFG" [MaxLength]="10" [ReadOnly]="true">
                                        </eprx-input>
                                        <eprx-input *ngIf="editMode" [LabelText]="'Document ID'" [PlaceHolder]="''" [ControlName]="'Id'"
                                            [FormGroupName]="documentFG" [MaxLength]="10" [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                    <div class="col-md-6">
                                        <eprx-input *ngIf="editMode" [AutoFocus]="true" [LabelText]="'Title'" [PlaceHolder]="''" [ControlName]="'Name'"
                                            [FormGroupName]="documentFG" [MaxLength]="10" [IsRequired]="true" [InputErrors]="Name?.errors"
                                            [ErrorDefs]="{required: 'Required'}" [InputType]="'TEXT'" (TriggerChangeValue)="changeName($event,'title')">
                                        </eprx-input>
                                        <eprx-input *ngIf="!editMode" [LabelText]="'Title'" [PlaceHolder]="''" [ControlName]="'Name'"
                                            [FormGroupName]="documentFG" [MaxLength]="10" [IsRequired]="true" [InputErrors]="Name?.errors"
                                            [ErrorDefs]="{required: 'Required'}" [InputType]="'TEXT'" (TriggerChangeValue)="changeName($event,'title')">
                                        </eprx-input>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="control-label form-group custom--input col-sm-12 padding-0">
                                            <span> Date </span>
                                            <input type="text" name="searchBoxCityType" class="form-control search--input__box"
                                                placeholder="To Start Search..." value="{{today}}" [readOnly]="true">
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="control-label form-group custom--input col-sm-12 padding-0">
                                            <span> Category </span>
                                            <input type="text" name="searchBoxCityType" class="form-control search--input__box" value="{{functionScreen}}"
                                                [readOnly]="true">
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <eprx-select [LabelText]="'Subcategory'" [ControlName]="'DocSubCatId'" [FormGroupName]="documentFG"
                                            [HasMultiple]="false" [HasBindLabel2]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'"
                                            [List]="subCatList ? subCatList?.DocSubCat : ''" (TriggerSelectValue)="changeName($event,'subcategory')">
                                        </eprx-select>
                                    </div>
                                    <div class="col-md-6" [formGroup]="documentFG">
                                        <label class="control-label full--width">
                                            <span>Comments</span>
                                            <textarea rows="2" cols="50" formControlName="Remarks" class="custom--text__area"
                                                (change)="changeName($event.target.value,'comments')" placeholder="Comments"></textarea>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <app-upload class="doc-align-top" (UploadedFile)="fileUploaded($event)" ></app-upload>
                                    </div>
                                </div>
                                </div>
                            <div class="col-md-6" *ngIf="!editMode">
                                <div class="row">
                                    <ngx-image-zoom *ngIf="fileTypeToShow == 'image' && src" [thumbImage]=src [fullImage]=src [magnification]="1"
                                    [enableScrollZoom]="true" [enableLens]="true" [lensWidth]="200" [circularLens]="true"
                                    [lensHeight]="100" [lensWidth]="200" ></ngx-image-zoom>
                                    <!-- <img *ngIf="fileTypeToShow == 'image'" [src]="src" height="100%" width="100%"> -->
                                    <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="src"  class="text-center width_100per border_3px_solid_black" toolbar="0" width="100%" height="300px"
                                        type="application/pdf"></iframe>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="saveDocuments()" *ngIf="!editMode" appShortcutKey [AltKey]="'s'"><span>S</span>SAVE</button>
        <button type="button" class="hotkey_success" (click)="editImage()" *ngIf="!editMode && uploadedFile" appShortcutKey [AltKey]="'e'"><span>E</span>Edit Image</button>
        <button type="button" class="hotkey_success" (click)="updateDocuments()" *ngIf="editMode" appShortcutKey [AltKey]="'u'"><span>U</span>Update</button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'"><span>C</span>CANCEL</button>
    </div>
</ng-template>
<ng-template #deleteInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button"  [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>Are you sure you want to delete the selected document?</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')" (click)="deleteDoc()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b>NO</button>
    </div>
</ng-template>
