
import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[appInputRestriction]'
})
export class InputRestrictionDirective {

  regexStr = '^[^\s]+(\s+[^\s]+)*$';
  pattern = /^[^\s]+(\s+[^\s]+)*$/;
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef) { }


  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) { 

   if ( this.el.nativeElement.selectionStart === 0 && event.key === ' ' ) {
     event.preventDefault();
   }
   else if(! this.el.nativeElement.selectionStart && event.keyCode == 32) {
    const ipVal = (event.target as HTMLInputElement).value;
        let isSpace = ipVal.replace(/\s/g, '').length;
        if(isSpace == 0){
            //contains only white spaces
            event.preventDefault();
        }

   }
    if(event.key === ' '){
      const ipVal = (event.target as HTMLInputElement).value;
      let isValid = this.pattern.test(ipVal);
      if(!isValid){
        event.preventDefault();
      }
    }
  }

  /* not in use **/

  // validateFields(event) {
  //   setTimeout(() => {

  //     this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
  //     event.preventDefault();

  //   }, 100)
  // }

}