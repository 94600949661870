<ng-template #rxSerialPopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="close()">
        <h4 header class="text-center"> Enter / Select Rx Serial Number </h4>
        <span body>
            <div class="row">
                <div class="col-sm-3" >
                    <span class="font-weight-bold" id="'rxSerialNo'">Rx Serial#:
                    </span>
                </div>
                <div class="col-md-6">
                    <eprx-input
                    [RxSelectId]="'rxSerial'"
                    [PlaceHolder]="'Please enter Rx Serial Number'"
                    [AutoFocus]="true"
                    [ErrorDefs]="(isRequired && !rxSerialFG?.controls?.rxSerial?.value) ? {required: 'Required'} :
                     (frmQuickRef && rxSerialFG?.controls?.rxSerial?.value?.length < repeatNumber) ? {minlength: 'Rx Serial# length should be >= ' + repeatNumber} : ''"
                    [ControlName]="'rxSerial'"
                    [FormGroupName]="rxSerialFG"
                    [HasControl]="true"
                    [FormGroupInvalid]="isRequired && !rxSerialFG?.controls?.rxSerial"
                    [IsRequired]="isRequired"
                    [IsInputElemHidden]="false"
                    [MarkAsTouched]="rxSerialFG.get('rxSerial').touched"
                    [MinLength]="frmQuickRef ? repeatNumber : ''">
                    </eprx-input>
                </div>
            </div>
            <hr>
            <div class="eprx--block__content erx--body">
                <div class="col-xs-12 col-md-12 padding-0">
                    <div class="row">
            <div class=" col-md-12 align-button text-center transmit--btns">
                <button type="submit" *ngIf="!frmeRx" class="hotkey_success--black align--btn__width--small" (click)="selectValDisplay('H')"
                    appShortcutKey [AltKey]="'h'"> <span>H</span>
                    Hospital Rx - H
                </button>
                <button *ngIf="frmeRx" title="For electronic Rx's, only the 'eRx' option can be selected." class="hotkey_disable--black align--btn__width--small">
                    <span>H</span>
                    Hospital Rx - H
                </button>
                <button type="submit" *ngIf="!frmeRx" class="hotkey_success--black align--btn__width--small" (click)="selectValDisplay('9')"
                    appShortcutKey [AltKey]="'o'">
                    <span>O</span> Oral Rx (Phone) - 9
                </button>
                <button *ngIf="frmeRx" title="For electronic Rx's, only the 'eRx' option can be selected." class="hotkey_disable--black align--btn__width--small">
                    <span>O</span> Oral Rx (Phone) - 9
                </button>
                <button type="submit" class="hotkey_success--black align--btn__width--small" (click)="selectValDisplay('eRx')"
                    appShortcutKey [AltKey]="'e'">
                    <span>E</span> eRx - E
                </button>
                <button type="submit" *ngIf="!frmeRx" class="hotkey_success--black align--btn__width--small" (click)="selectValDisplay('Z')"
                    appShortcutKey [AltKey]="'p'">
                    <span>P</span> Out of State Rx - Z
                </button>
                <button *ngIf="frmeRx" title="For electronic Rx's, only the 'eRx' option can be selected." class="hotkey_disable--black align--btn__width--small">
                    <span>P</span> Out of State Rx - Z
                </button>
                <button type="submit" *ngIf="!frmeRx" class="hotkey_success--black align--btn__width--small" (click)="selectValDisplay('S')"
                    appShortcutKey [AltKey]="'s'">
                    <span>S</span> Supplies - S
                </button>
                <button *ngIf="frmeRx" title="For electronic Rx's, only the 'eRx' option can be selected." class="hotkey_disable--black align--btn__width--small">
                    <span>S</span> Supplies - S
                </button>
                <!-- <button type="submit" class="hotkey_success--black align--btn__width--small" (click)="selectValDisplay('E')"
                    appShortcutKey [AltKey]="'f'">
                    <span>F</span> eRx (Fax) - E
                </button> -->
                <button type="submit" *ngIf="!frmeRx" class="hotkey_success--black align--btn__width--small" (click)="selectValDisplay('T')"
                    appShortcutKey [AltKey]="'t'">
                    <span>T</span> Transfer in Rx - T
                </button>
                <button *ngIf="frmeRx" title="For electronic Rx's, only the 'eRx' option can be selected." class="hotkey_disable--black align--btn__width--small">
                    <span>T</span> Transfer in Rx - T
                </button>
            </div>
            </div>
            </div>
            </div>
        </span>
        <button footer ngbAutofocus id="rxSerialPopupssave" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'s'" (click)="patchRxSerial()"> <span>S</span> Save
    </button>
    <button footer id="rxSerialPopupcancel" type="button" class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="closeModal()"> <span>C</span> Cancel
    </button>
    </app-custom-modal>
</ng-template>

<!-- <ng-template #SavePopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Warning!! </h4>
        <span body class="font_size_1_4rem">
            You have not enetered a value, Are you sure you want to leave it Blank?
        </span>
        <button footer autofocus class="hotkey_primary" (click)="saveRxSerial()" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer autofocus class="hotkey_primary" (click)="focustoRxSerial()" appShortcutKey InputKey="n"><b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template> -->
<!-- <ng-template #CancelPopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Warning!! </h4>
        <span body class="font_size_1_4rem">
            Are you sure you would like to discard the changes?
        </span>
        <button footer autofocus class="hotkey_primary" (click)="discardYes()" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer autofocus class="hotkey_primary" (click)="discardNo()" appShortcutKey InputKey="n"><b>N</b> NO
        </button>
    </app-custom-modal> -->
<!-- </ng-template> -->
