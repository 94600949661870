<div class="col">
    <div class="row rx--his__actions mt-2">
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Check DUR">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'a'" (click)="openActionModal('checkDur')">
                <span class="hotkey--buttons text-center">A</span>
                <span class="save--buttons">CHECK DUR</span>
            </a>
        </span>
        <!-- <span class="secondary-actn-btn" placement="top" ngbTooltip="Benefit Check">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'B'" (click)="openActionModal('BefitCheck')">
                <span class="hotkey--buttons text-center">B</span>
                <span class="save--buttons">Benefit Check</span>
            </a>
        </span> -->
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Delete Rx">
            <a class="enable--hotkey__buttons small_HotKeybuttons" *ngIf="!hasDeleteRxPrivs" appShortcutKey [AltKey]="'d'"
                (click)="openOveride('EditRx', 'DeleteRx')">
                <span class="hotkey--buttons text-center">D</span>
                <span class="save--buttons">DELETE</span>
            </a>
            <a class="enable--hotkey__buttons small_HotKeybuttons" *ngIf="hasDeleteRxPrivs" appShortcutKey [AltKey]="'d'"
                (click)="openActionModal('DeleteRx')">
                <span class="hotkey--buttons text-center">D</span>
                <span class="save--buttons">DELETE</span>
            </a>
        </span>
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Edit Rx">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'e'" (click)="routeToEditRx()">
                <span class="hotkey--buttons text-center">E</span>
                <span class="save--buttons">EDIT</span>
            </a>
        </span>
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Defer Rx(s)">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'f'" (click)="checkIsRxFilable()">
                <span class="hotkey--buttons text-center">F</span>
                <span class="save--buttons">Defer</span>
            </a>
        </span>
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Refresh History">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'h'" (click)="loadRxHistory()">
                <span class="hotkey--buttons text-center">H</span>
                <span class="save--buttons">REFRESH</span>
            </a>
        </span>
        <span class="d-inline-flex">
            <a ngbTooltip="Send Refill Request"  class="enable--hotkey__buttons  padding_bottom_3px mr-0" appShortcutKey
            placement="top-left" [AltKey]="'s'" (click)="openRefillReqModal(true)">
                <span class="hotkey--buttons">S</span>
                <span class="save--buttons">SEND REF. R</span>
            </a>
            <span ngbDropdown class="view--Trans__icon padding_bottom_7px background-White">
                <button class="btn view-trans-arrow" id="dropdownBasic2" ngbDropdownToggle
                    ngbTooltip="Refill Request Status"></button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <a class="enable--hotkey__buttons " appShortcutKey [AltKey]="'v'" (click)="navigateToFaxRefill()">
                        <span class="save--buttons">
                            REFILL REQ. STATUS
                        </span>
                    </a>
                    <!--  Based on EP-4385 we are commenting these 2 buttons.
                    <a class="enable--hotkey__buttons " (click)="openActionModal('PrintRxForm')">
                        <span class="save--buttons">
                            Print Rx
                        </span>
                    </a>
                    <a class="enable--hotkey__buttons " (click)="openActionModal('PrintRefillReqForm')">
                        <span class="save--buttons">
                            Print Refill Request
                        </span>
                    </a> -->
                </div>
            </span>
        </span>
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Transmit Rx" >
            <a class="enable--hotkey__buttons " appShortcutKey [AltKey]="'t'" (click)="openActionModal('TR-NOW')">
                <span class="hotkey--buttons text-center">T</span>
                <span class="save--buttons">TR-NOW</span>
            </a>
        </span>
       
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Quick Refill">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'k'" (click)="openOveride('Rx', 'QuickRefill')"
                *ngIf="!hasQuickRxPrivs">
                <span class="hotkey--buttons text-center">K</span>
                <span class="save--buttons">QUICK REF.</span>
            </a>
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'k'" (click)="openActionModal('QuickRefills')"
                *ngIf="hasQuickRxPrivs">
                <span class="hotkey--buttons text-center">K</span>
                <span class="save--buttons">QUICK REF.</span>
            </a>
        </span>
        <div class="secondary-actn-btn" placement="top" ngbTooltip="Quick Label">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'l'" (click)="openActionModal('QuickLabel')">
                <span class="hotkey--buttons text-center">L</span>
                <span class="save--buttons">QUICK LABEL</span>
            </a>
        </div>
        <span class="secondary-actn-btn"
            *ngIf="!(openModalFr === 'ERW' || openModalFr === 'In' || openModalFr === 'Out' || openModalFr === 'RFQ')"
            placement="top" ngbTooltip="Create New Rx">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'n'" (click)="routeToRx()">
                <span class="hotkey--buttons text-center">N</span>
                <span class="save--buttons">CREATE NEW</span>
            </a>
        </span>
        <span class="d-inline-flex">
            <a ngbTooltip="NOTES " class="enable--hotkey__buttons small_HotKeybuttons padding_bottom_3px mr-0"
            container="body">
                <span class="save--buttons">NOTES </span>
            </a>
            <span ngbDropdown class="view--Trans__icon padding_bottom_7px background-White">
                <button class="btn view-trans-arrow" id="dropdownBasic3" ngbDropdownToggle
                    ngbTooltip="PAT. NOTES" container="body"> </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                    <a class="enable--hotkey__buttons " appShortcutKey [AltKey]="'o'" (click)="openActionModal('PatientNotes')" placement="top" ngbTooltip="Patient Notes">
                        <span class="hotkey--buttons text-center">O</span>
                        <span class="save--buttons">PAT. NOTES </span>
                    </a>
                    <a class="enable--hotkey__buttons " appShortcutKey [AltKey]="'g'" (click)="openRxNotes()" placement="top" ngbTooltip="RX Notes">
                        <span class="hotkey--buttons text-center">G</span>
                        <span class="save--buttons">RX NOTES </span>
                    </a>
                </div>
            </span>
           
        </span>
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Print">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'p'" (click)="openActionModal('PrintRxReport')">
                <span class="hotkey--buttons text-center">P</span>
                <span class="save--buttons">PRINT</span>
            </a>
        </span>
        <span class="secondary-actn-btn" *ngIf="openModalFr !== 'RFQ'" placement="top" ngbTooltip="Add to Queue">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'q'" (click)="AddToQueue()">
                <span class="hotkey--buttons text-center">Q</span>
                <span class="save--buttons">ADD TO REF. Q</span>
            </a>
        </span>
        
<span class="d-inline-flex">
    <a ngbTooltip="Transfer" class="enable--hotkey__buttons small_HotKeybuttons padding_bottom_3px mr-0">
        <span class="save--buttons">Transfer</span>
    </a>
    <span ngbDropdown class="view--Trans__icon padding_bottom_7px background-White">
        <button class="btn view-trans-arrow" id="dropdownBasic5" ngbDropdownToggle
            ngbTooltip="Transfer"></button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic5">
          
            <a class="enable--hotkey__buttons " *ngIf="openModalFr !== 'In'" placement="top" ngbTooltip="Transfer In"  appShortcutKey [AltKey]="'i'" (click)="openActionModal('XFRIN')">
                <span class="hotkey--buttons text-center">I</span>
                <span class="save--buttons">XFRIN</span>
            </a>
            <a class="enable--hotkey__buttons "  *ngIf="openModalFr !== 'Out'" placement="top" ngbTooltip="Transfer Out" appShortcutKey [AltKey]="'u'" (click)="openActionModal('XFROUT')">
                <span class="hotkey--buttons text-center">U</span>
                <span class="save--buttons">XFROUT</span>
            </a>

        </div>
    </span>
   
</span>
        <span class="secondary-actn-btn" placement="top" ngbTooltip="Deferred Rx's Can be Filled, and non Deferred Rx's can be Refilled.">
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'r'" (click)="openOveride('Rx', 'RefillRx')"
                *ngIf="!hasRefRxPrivs">
                <span class="hotkey--buttons text-center">R</span>
                <span class="save--buttons">Refill/Fill</span>
            </a>
            <a class="enable--hotkey__buttons small_HotKeybuttons" appShortcutKey [AltKey]="'r'" (click)="openActionModal('Refill')"
                *ngIf="hasRefRxPrivs" >
                <span class="hotkey--buttons text-center">R</span>
                <span class="save--buttons">Refill/Fill</span>
            </a>
        </span>
        
       
        <span class="secondary-actn-btn" *ngIf="(openModalFr && openModalFr !== 'rph') || isFromNewRxRequest"
            placement="top" ngbTooltip="Pick Rx(s)" container="body">
            <a class="enable--hotkey__buttons " appShortcutKey [AltKey]="'y'" (click)="emitPickRx()">
                <span class="hotkey--buttons text-center">Y</span>
                <span class="save--buttons">PICK</span>
            </a>
        </span>
        <span class="d-inline-flex">
            <a ngbTooltip="Verifications" container="body"  class="enable--hotkey__buttons small_HotKeybuttons padding_bottom_3px mr-0">
                <span class="save--buttons">Verifications</span>
            </a>
            <span ngbDropdown class="view--Trans__icon padding_bottom_7px background-White">
                <button class="btn view-trans-arrow" id="dropdownBasic22" ngbDropdownToggle
                    ngbTooltip="Verifications" container="body"></button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic22">
                    <a *ngIf="showMarkAsVerif" class="enable--hotkey__buttons" appShortcutKey
                    [AltKey]="'b'"
                        (click)="openActionModal('MarkAsVerf')">
                        <span class="hotkey--buttons">b</span>
                        <span class="save--buttons">MARK AS Rph VERIFIED</span>
                    </a>
                    <a *ngIf="!showMarkAsVerif" class="disable--hotkey__buttons" appShortcutKey>
                        <span class="save--buttons">
                            MARK AS Rph VERIFIED
                        </span>
                    </a>
                    <a ngbTooltip="Rph Vrf" class="enable--hotkey__buttons  padding_bottom_3px mr-0" appShortcutKey
                    [AltKey]="'w'" (click)="openActionModal('RphVerification')">
                    <span class="hotkey--buttons">W</span>
                    <span class="save--buttons">Rph Vrf</span>
                    </a>
                    <a class="enable--hotkey__buttons " appShortcutKey [AltKey]="'v'"
                        (click)="openActionModal('DrugPickVerification')" placement="top" ngbTooltip="Drug Pick Vrf">
                        <span class="hotkey--buttons text-center">V</span>
                        <span class="save--buttons">Drug Pick Vrf</span>
                    </a>
                   
                    <a class="enable--hotkey__buttons " ngbTooltip="Rx Verification" *ngIf="reqRxVerfStng"
                        appShortcutKey [AltKey]="'M'" (click)="openActionModal('RxVerification')">
                        <span class="hotkey--buttons text-center">M</span>
                        <span class="save--buttons">Rx Vrf</span>
                    </a>
                    <a class="disable--hotkey__buttons" placement="top" tooltipClass="button-tooltip" 
                        ngbTooltip="Disabled, as the Require Rx Verification setting is Turned OFF on Account settings."
                        *ngIf="(!reqRxVerfStng)">
                        <span class="hotkey--buttons text-center">M</span>
                        <span class="save--buttons">Rx Vrf</span>
                    </a>
                </div>
            </span>
        </span> 
    </div>
</div>
<app-erx-change-req *ngIf="activeModal === 'ERxChange'" [RxInfo]="selectedRxForBenefitCheck"
    (IsPopUpClosed)="openActionModal('BefitCheck')" [SelectedBenefitCheckType]=selectedBenefitCheckType
    [PrescRefillId]="selectedRxForBenefitCheck?.PrescReFill?.Id"
    [DrugId]="selectedRxForBenefitCheck?.Drug?.id"
></app-erx-change-req>
<app-drug-missing-form (closeDrugMissingValues)="drugMissingValuesClosingPop($event)" *ngIf="isShowDrugValuesPop" [drugInfo]="drugInfo" ></app-drug-missing-form>
<app-quick-refill  *ngIf="activeModal === 'QuickRefills'" [SearchParams]="SearchParams" [RxInfo]="TotalRxInfo" [FrmScrn]="'RxHist'" (IsPopUpClosed)="openActionModal($event)"
(RefreshRxHistory)="reloadRxHistory($event)"></app-quick-refill>
<app-delete-rx *ngIf="activeModal === 'DeleteRx'" [systemData]="systemInfo$ | async" [RxInfo]="rxInfo" [openFrom]="openFrm" (IsPopUpCancelled)="closeDeleteRx(null)" (IsPopUpClosed)="closeDeleteRx(null)"
></app-delete-rx>
<app-notes-log *ngIf="activeModal === 'PatientNotes'"  (CloseNotesValue)="openActionModal($event);reloadRxHistory($event)"
    [PatientInfo]="patientInfo" [OpenAsModal]="true">
</app-notes-log>
<ng-template #Pharmaverfication let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Would you like to set Pharmacist Verification for the Rx#
            <b>{{rxInfo.Prescription.PrescNum}}</b> as the VERIFIED?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="setPvrf()" appShortcutKey InputKey="y"><b>Y</b>
            YES</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </div>
</ng-template>

<app-rph-mark-verfied *ngIf="activeModal === 'MarkAsVerf'"  [RxInfo]="TotalRxInfo" (IsPopUpClosed)="openActionModal($event)"
(RefreshParent)="reloadRxHistory($event)"></app-rph-mark-verfied>
<app-check-dur *ngIf="activeModal === 'checkDur'" [PatientID]="patientID" (IsPopUpClosed)="openActionModal($event)" (RefreshRxHistory)="reloadRxHistory($event)"></app-check-dur>
<app-rx-delivery-info *ngIf="show" [RxInfo]="rxInfo" (IsPopUpClosed)="openActionModal($event)"></app-rx-delivery-info>
<!-- <app-quick-label *ngIf="activeModal === 'QuickLabel'" [RxInfo]="rxInfo" (IsPopUpClosed)="openActionModal($event)"></app-quick-label> -->
<!-- <app-print-rx-label *ngIf="activeModal === 'QuickLabel'" [SkipTemplSelct]="'false'" [RxInfo]="rxInfo" (IsPopUpClosed)="openActionModal($event)" [IsPrintLabelEmitCompleted]="true"></app-print-rx-label> -->
<app-print-patient-record *ngIf="activeModal === 'PrintRxReport'" [PatientInfo]="patientInfo" [RxList]="TotalRxInfo"
    [PatientID]="patientID" (IsPopUpClosed)="openActionModal($event)"></app-print-patient-record>
<app-print-rx-form *ngIf="activeModal === 'PrintRxForm' || activeModal === 'PrintRefillReqForm'" [RxInfo]="TotalRxInfo"
    [ModalType]="activeModal === 'PrintRefillReqForm'"
    (IsPopUpClosed)="closeActiveModalAndReLoad()"></app-print-rx-form>

<!-- Warning for Verfied Status for Pharmacist Verified -->
<ng-template #warnPharamVerified let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            This Rx# <b>{{ rxInfo.Prescription.PrescNum }}</b> has been
            VERIFIED.
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </div>
</ng-template>

<ng-template #CopayPaidStatusModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Would you like to mark selected Rx#
            <b>{{ rxInfo.Prescription.PrescNum }}</b> as Copay
            {{ copayPaid }}?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="setCopayStatus()" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n">
            <b>N</b>NO
        </button>
    </div>
</ng-template>

<app-refill-request *ngIf="isRefillReq" [rxInfo]="rxInfo" [selectedRxs]="TotalRxInfo"
    [rxOriginList]="(systemInfo$ | async)?.RxOrigin" [refillHeader]="'Send/Print/Fax Refill Request'"
    (IsPopUpClosed)="closeActiveModalAndReLoad('SendRefillReq')" [SearchParams]="SearchParams"></app-refill-request>

<!-- Warning for Billed Rx's-->
<ng-template #warnBilledRx let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            This Rx# <b>{{ rxInfo.Prescription.PrescNum }}</b> has been already Billed.
        </p>
    </div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" class="hotkey_primary" (click)="d('Cross click')" appShortcutKey
            InputKey="o">
            <b>O</b> OK
        </button>
    </div>
</ng-template>

<ng-template #QueuC let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">{{TotalRxInfo?.length}} Rx's are Selected. Are you sure you want to add to queue?</div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" focus (click)="d('Cross click')" (click)="addToQueue()" appShortcutKey
                    InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="n"><b>N</b>
                    NO</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deceased let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">Selected Patient <b>{{warningMsgDeceased}}</b> is deceased. Rx(s) of this patient cannot be
        added to Refill Queue.</div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" class="hotkey_primary" (click)="d('Cross click')" appShortcutKey
            InputKey="o">
            <b>O</b> OK
        </button>
    </div>
</ng-template>

<app-override-log *ngIf="openOverride" [OverrideEndpoint]="[overrideEndpoint]" [PrivMask]="privType"
    [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

    <app-notes-log
    *ngIf="activeModal === 'RxNotes'"
    (CloseNotesValue)="openActionModal($event);reloadRxHistory($event)"
    [RxInfo]="rxInfo"
    [OpenAsModal]="true"
>
</app-notes-log>
<app-login-verify *ngIf="activeModal == 'DrugPickVerification'" [LoginTab]="'PatientDrugPickVerification'"
    [FromPage]="'PatientDrugPickVerification'"  [HeaderName]="activeModal"
    (setPopUpValue)="afterVerifyDrugPickVerificationp($event)"></app-login-verify>

<app-login-verify *ngIf="activeModal == 'RphVerification' && openPopUp" [LoginTab]="activeModal"
    (setPopUpValue)="afterVerifyRphVerification($event)" [HeaderName]="activeModal"  [FromRxAndPatient]="true"></app-login-verify>


<app-login-verify *ngIf="activeModal == 'RxVerification' && openPopUp" [LoginTab]="activeModal"
    (setPopUpValue)="afterVerifyRxVerification($event)" [HeaderName]="activeModal"  [FromRxAndPatient]="true"></app-login-verify>

<app-file-due-date *ngIf="isFiledpopupOpen" [selectedRxs]="TotalRxInfo"
    (CloseFileDueDate)="closeMultipleFileRx($event)"></app-file-due-date>


<ng-template #RxNOTES let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeRxNotes()">
        <h4 header> Rx Notes </h4>
        <span body>
            <div class="eprx--block__content notes--block">
                <div class="row">
                    <div class="col">
                        <h5 class="notes--block--head">PATIENT: {{rxInfo?.Patient?.lastname }}
                            <span *ngIf="rxInfo?.Patient?.lastname && rxInfo?.Patient?.firstname">,&nbsp;</span>
                            {{rxInfo?.Patient?.firstname }}

                        </h5>
                        <h5 class="notes--block--head">DRUG: {{rxInfo?.Drug?.drugname}} {{rxInfo?.Drug?.strength}}
                            {{rxInfo?.Drug?.drugformname}}
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <textarea ngbAutofocus class="label--text__area col-md-12" [value]="notes"
                            (keyup)="changeNotes($event?.target?.value)" [readOnly]="!hasRxNoteChangPriv"></textarea>
                    </div>
                </div>
            </div>
        </span>
        <button ngbAutofocus footer class="hotkey_primary" *ngIf="hasRxNoteChangPriv" (click)="saveNotesWithRx()"
            appShortcutKey [CtrlKey]="'o'"><span>O</span> OK</button>
        <button ngbAutofocus footer class="hotkey_primary" (click)="closeRxNotes()" appShortcutKey [CtrlKey]="'c'">
            <span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>

<ng-template #WARNINGMSGS let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeConfirmModal()">
        <h4 header>Warning!!</h4>
        <span body> {{ warningMsg }} </span>
        <button id="btnOK" autofocus footer type="submit" class="hotkey_primary" (click)="closeConfirmModal()"
            appShortcutKey InputKey="o">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #TRANSWARNMSGS let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeConfirmModal()">
        <h4 header>Warning!!</h4>
        <span body>
            <p [innerHTML]="warningMsg"></p>
        </span>
        <button id="btnOK" autofocus footer type="submit" class="hotkey_primary" (click)="closeModalAndNext()"
            appShortcutKey InputKey="o">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #RXINMODAL let-c="close" let-d="dismiss" class="el--popup">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                <h4>Rx Transfer</h4>
            </h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="checkTrOut(false); d('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-dropdown-overflow">
            <div class="eprx--block__content">
                <app-rx-transfer-in [frmPatHis]="transfData" [GrpTransOutList]="grpTransOutRxs" [patchTransferInfo]="patchTransferInfo"  [typeResp]="holdRespType" [holdRxFrmPatHist]="holdRxData" [clearRxData]="clearData"
                [currentTrOutRxIndex]="currentIndex" (IsPopUpClosed)="checkTrOut(false);d('Cross click')"
                (IsNewRxSaved)="checkTrOut(true);d('Cross click')">
            </app-rx-transfer-in>
        </div>
    </div>
</ng-template>

<ng-template #invalidRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="drugRphRxVerification()">
        <h4 header>Warning!!</h4>
        <span body>
            <span [innerHTML]="warningInfo"></span>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="drugRphRxVerification()" appShortcutKey
            [InputKey]="'o'"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>
<ng-template #alreadyVerified let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="processDrugPickVerification()">
        <h4 header>Confirmation</h4>
        <span body>
            Rx#: <span class="font-weight-bold">{{rxInfo?.Prescription?.PrescNum}}</span> Refill#:
            <span class="font-weight-bold">{{rxInfo?.PrescReFill?.ReFillNum}}</span> Fill#:
            <span
                class="font-weight-bold">{{_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)
                ? rxInfo?.PrescReFill?.PartialFillNo : " --"}}</span><div><br></div>
            This Rx has been DPV verified by <span class="font-weight-bold">
                {{(rxInfo?.drugPickVerif) ? ((rxInfo?.drugPickVerif[0]?.UserName) | uppercase) : " " }}</span> on <span
                class="font-weight-bold">
                {{(rxInfo?.drugPickVerif) ? (rxInfo?.drugPickVerif[0] ?.VerifDtTm | localTime | date : "MM/dd/yyyy
                hh:mm:ss a") : ""}}</span>. Would you wish to re-verify?
        </span>
        <!-- <span body>
            Rx#: <span class="font-weight-bold"> {{ rxInfo?.Prescription?.PrescNum  &nbsp;}}</span> Refill#:
            <span class="font-weight-bold">{{ rxInfo?.PrescReFill?.ReFillNum }}</span>
            Fill#:
            <span class="font-weight-bold"> {{ _commonServ.checkIsPartialFill(rxInfo?.PrescReFill?.PartialFillNo,rxInfo?.PrescReFill?.PartialIndStatus) ?
                rxInfo?.PrescReFill?.PartialFillNo : " --"}}</span> Already verified on
                 {{ (rxInfo?.drugPickVerif)[0] ?.VerifDtTm | localTime | date : "MM/dd/yyyy hh:mm:ss a" }}  by
                 {{ (rxInfo?.drugPickVerif)[0] ?.UserName ? ((rxInfo?.drugPickVerif)[0] ?.UserName |  uppercase) : " " }}.  <br>
                 <span class="mt-1">Do you want to verify again?</span>
        </span> -->
        <button footer autofocus class="hotkey_primary" (click)="processDrugPickVerification('continue')" appShortcutKey
            InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="processDrugPickVerification()" appShortcutKey
            InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #AlreadyVerif let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="rxVerf ? processRxVerfication() : closeDrugPickVerf()">
        <h4 header> Confirmation </h4>
        <span body>
            Rx#: <span class="font-weight-bold">{{rxInfo?.Prescription?.PrescNum}}</span> Refill#:
            <span class="font-weight-bold">{{rxInfo?.PrescReFill?.ReFillNum}}</span> Fill#:
            <span
                class="font-weight-bold">{{_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)
                ? rxInfo?.PrescReFill?.PartialFillNo : " --"}}</span><br>
            This Rx has been {{rxVerf ? 'Rx' : 'Rph'}} verified by <span class="font-weight-bold">
                {{verifiedAlready?.UserName ? (verifiedAlready?.UserName | uppercase) : '--' }}</span> on <span
                class="font-weight-bold">
                {{verifiedAlready.VerifDtTm ? (verifiedAlready.VerifDtTm | localTime | date:'MM/dd/yyyy'):'--'}}</span>.
            Would you wish to re-verify?
        </span>
        <!-- <span body>
            Rx#: <span class="font-weight-bold">{{rxInfo?.Prescription?.PrescNum}}</span> Refill#:
            <span class="font-weight-bold">{{rxInfo?.PrescReFill?.ReFillNum}}</span>
            Fill#:
            <span class="font-weight-bold">{{ _commonServ.checkIsPartialFill(rxInfo?.PrescReFill?.PartialFillNo,rxInfo?.PrescReFill?.PartialIndStatus) ?
                 rxInfo?.PrescReFill?.PartialFillNo : " --"}}</span> Already verified on
            {{verifiedAlready.VerifDtTm ? (verifiedAlready.VerifDtTm | localTime | date:'MM/dd/yyyy'):'--'}} by
            {{verifiedAlready?.UserName ?
            (verifiedAlready?.UserName | uppercase) : '--' }}. <br/> Do you want to verify again?
        </span> -->
        <button ngbAutofocus footer class="hotkey_primary" (click)="c('Close click')" (click)="checkIsDiscontinue()"
            appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button *ngIf="rxVerf" footer class="hotkey_primary" (click)="processRxVerfication()" appShortcutKey
            InputKey="n"><b>N</b> NO</button>
        <button *ngIf="rph" footer class="hotkey_primary" (click)="closeDrugPickVerf()" appShortcutKey
            InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>
<ng-template #warnTransRx let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');getUbilledRxData()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="issueRxNos">

        </p>
    </div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" class="hotkey_primary" (click)="d('Cross click');getUbilledRxData()"
            appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </div>
</ng-template>

<ng-template #warnBatchEdit let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');intiateEditAfterChecking()"></button>
    </div>
    <div class="modal-body">
        Billed and Transferred Rxs cannot be edited in batch mode.
    </div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" class="hotkey_primary" (click)="d('Cross click');intiateEditAfterChecking()"
            appShortcutKey InputKey="o">
            <b>O</b> OK </button>
    </div>
</ng-template>

<ng-template #GRPTRANSMODAL let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal [hideCross]="true">
        <h4 header>Transmission Status</h4>
        <span body>
            <div class="max-height_80vh overflow_scroll">
                <table class="table table-bordered unbilled-queue--tabl">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Rx#</th>
                            <th scope="col">Batch#</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trans of transCompltedList; let i = index" class="inprogress--GPT">
                            <th scope="row">{{i+1}}</th>
                            <td>{{trans?.RxNo + " - " + trans?.RefillNumber + (trans?.FillNo ? (" - " + trans.FillNo) :
                                "")}}</td>
                            <td>{{trans?.BatchId}}</td>
                            <td>{{(trans?.TransmissionStatus === "4")? "Completed":
                                (trans?.TransmissionStatus === "2" ? "Pending":
                                (trans?.TransmissionStatus === "3") ? "Transmitting":
                                (trans?.TransmissionStatus === "5") ? "Reviewed": "")}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </span>
    </app-custom-modal>
</ng-template>

<app-batch-edit *ngIf="enableBatch" [selectedList]="TotalRxInfo" (IsBatchEditClosed)="closeBatch()"></app-batch-edit>
<ng-template #duplicateRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click'); afterAddQueue()">
        <h4 header> Warning!! </h4>
        <span body>
            {{warnDuplicate}}
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="d('Cross click'); afterAddQueue()">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #rxVerification let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeRxVerf()">
        <h4 header> Warning!! </h4>
        <span body>
            <label> Rx Verification is Required before Transmission for the following Rx's:</label>
            <div class="word-break font-weight-bold">{{unRxVerifiedRecords.join(",") }}</div>
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeRxVerf()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #RxDiscontinued let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span>Warning!!</span>
    </div>
    <div class="modal-body">

        <p> <span *ngIf="disContinueOpenFr !== 'MultiTrans'"> Selected Rx#: <span *ngIf="!dataList">
                    <b>{{rxInfo?.Prescription?.PrescNum}}-{{rxInfo?.PrescReFill?.ReFillNum}}{{(_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription) ?'-'+rxInfo?.PrescReFill?.PartialFillNo :
                        '')}}</b></span>
                <span *ngIf="dataList">
                    <b>{{dataList?.Prescription?.PrescNum}}-{{dataList?.PrescReFill?.ReFillNum}}{{(_commonServ.checkIsPartialFill(dataList?.PrescReFill, dataList?.Prescription) ?'-'+dataList?.PrescReFill?.PartialFillNo : '')}}</b>
                </span>
                is Discontinued. </span>
            <span *ngIf="disContinueOpenFr === 'MultiTrans'"> Following Rx(s) are Discontinued:
                <span *ngFor="let dis of discontinuedList; let i=index;">
                    <br /> <b> {{dis?.prescNum}}-{{dis?.RefNo}}-{{dis?.fillNo}}</b>
                </span>
                <br />
            </span>
            If you wish to continue, selected Rx(s) will no more be marked as discontinued.
            <br /> Would you like to proceed?
        </p>

    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
            (click)="setOrderStatus(); c('Close click')"><b>Y</b>YES</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
            (click)="closeDiscontinuePopup(); c('Close click')"><b>N</b>NO</button>
    </div>
</ng-template>
<ng-template #multipleSameRxTrOut let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Warning!! </h4>
        <span body>
            Multiple refills of the same Rx are selected for Transfer Out Rx, only the latest fill of the Rx will be
            considered to Transfer Out the Rx.
        </span>
        <button footer id="mulitpleref" ngbAutofocus class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="openActionModal('XFROUT')"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #CommonWarning let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Warning!! </h4>
        <span body>
            Multiple refills of the same Rx are selected to perform Refill, only the latest fill of the Rx will be
            considered to Refill.
        </span>
        <button footer id="mulitpleRxs" ngbAutofocus class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="processRefill(false)"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #WARNINGINFO let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="holdRx ? unHoldRx('hold') : clearRxdata()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <span [innerHTML]="warningMessage"></span>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button id="myInput" type="button" autofocus class="hotkey_primary" (keydown.enter)="unHoldRx('hold')"
            (click)="unHoldRx('hold')" *ngIf="holdRx" appShortcutKey InputKey="u"> <b>U</b>UnHold Rx </button>
        <button id="myInput" type="button" autofocus class="hotkey_primary" (keydown.enter)="clearRxdata()"
            (click)="clearRxdata()" *ngIf="!holdRx" appShortcutKey InputKey="o"> <b>o</b>OK</button>
        <button id="myInput" type="button" class="hotkey_primary" (keydown.enter)="clearRxdata()"
            (click)="clearRxdata()" *ngIf="holdRx" appShortcutKey InputKey="c"> <b>C</b> Cancel</button>
    </div>
</ng-template>

<ng-template #DiscontinuedRx let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span>Warning!!</span>
    </div>
    <div class="modal-body">
        <p>Rx#: {{transfData["rxNo"]}} is Discontinued. If you wish to continue the Rx will no more be marked as
            discontinued.
            <br /> Would you like to proceed?
        </p>
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
            (click)="setOrderStatusTrInOut(); c('Close click')"><b>y</b>Yes</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
            (click)="clearRxdata(); c('Close click')"><b>n</b>No</button>
    </div>
</ng-template>
<ng-template #inActiveInsurance let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="openSelectBillToPopup(); c('Close click')">
        <h4 header>Warning!!</h4>
        <span body>
            The selected insurance <span class="font-weight-bold">{{rxDetails[0]?.insurancename.toUpperCase()}}</span>
            is inactive. Would you like to proceed?
        </span>
        <span footer>
            <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
                (click)="continueTransmit()"><b>Y</b>YES</button>
            <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
                (click)="openSelectBillToPopup(); c('Close click')"><b>N</b>NO</button>
        </span>
    </app-custom-modal>
</ng-template>