import {
    NgbDateParserFormatter,
    NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split("-");
            if (dateParts.length === 1 && dateParts[0]) {
                return {
                    day: +dateParts[0],
                    month: null,
                    year: null
                };
            } else if (dateParts.length === 2 && dateParts[0] && dateParts[1]) {
                return {
                    day: +dateParts[0],
                    month: +dateParts[1],
                    year: null
                };
            } else if (
                dateParts.length === 3 &&
                dateParts[0] &&
                dateParts[1] &&
                dateParts[2]
            ) {
                return {
                    day: +dateParts[0],
                    month: +dateParts[1],
                    year: +dateParts[2]
                };
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        return date
            ? `${date.month ? this.padNumber(date.month) : ""}/${date.day ? this.padNumber(date.day) : ""}/${
                  date.year
              }`
            : "";
    }

    padNumber(d) {
        return d < 10 ? "0" + d.toString() : d.toString();
    }
}
