import { EditPrescriberService } from './../../../services/edit-prescriber.service';

import { AlertService, PatientService } from "src/app/services";
import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { PrivMaskGuard } from "src/app/guards";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CollectionView } from "@grapecity/wijmo";
import { InsuranceService } from "../../../services";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import * as _ from "lodash";
import { SearchInsurance, AccCategoryE, InsuranceFileE, PatientFileE, PrescriberFileE, PaginationModel } from "../../../models";
import { MsgConfig } from "../../..";
import { CommonUtil } from 'src/app/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
    selector: "app-prescriber-search",
    templateUrl: "./prescriber-search.component.html"
})
export class PrescSearchComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    searchPrescList: CollectionView;
    actvHeaders = [ "Actions",
    "Last Name",
    "First Name",
    "Quick Code",
    "License#",
    "DEA#",
    "NPI#",
    "Address",
    "Mobile #",
    "Telephone #",
    "Work #",];
    totalCount: number;
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    PrescSearchFG: FormGroup;
    hideFilterForm = false;
    currentItem: any;
    accessPrivCate: any;
    categoryId: string;
    privType: any;
    openOverride: boolean;
    savedValue: any;
    overrideFor: any;
    deletePop: any;
    modelRef: any;
    isDataExists = true;

    @ViewChild("SearchPresc")
    flex: wjcGrid.FlexGrid;
    startIndex: number;
    maxDate: { year: any; month: any; day: any; };
    prevPrivs: typeof PrescriberFileE;
    overrideEndpoint: any;
    hasAddPrescFilePrivs: boolean;
    hasChangPrescFilePrivs: boolean;
    hasDeltPrescFilePrivs: boolean;

    isPrescriberSelectable: boolean = false;
    unsubscribe$ : Subject<void> = new Subject();

    @Input()
    set IsPrescriberSelectable(inp: boolean) {
        this.isPrescriberSelectable = inp;
    }
    @Input() IsFromDocuementQueue: any;

    @Output()
    SelectedPrescriber = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }

    }

    constructor(
        private _fb: FormBuilder,
        private _editPresc: EditPrescriberService,
        private _router: Router,
        private modalService: NgbModal,
        private _alertServ: AlertService,
        private _commonUtils: CommonUtil,
        private _privMaskGuard: PrivMaskGuard,
        private _formatsUtil: FormatsUtil
    ) {
        this.PrescSearchFG = this._fb.group({
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize,
            prescriberfirstname: null,
            prescriberlastname : null,
            prescriberquickcode: null,
            prescriberlicensenumber: null,
            prescriberdeanum: null,
            prescribernpinum: null,
            mobile: null,
            worknumber: null,
            telephone: null,
            includeActive: false
        });
        this.accessPrivCate = AccCategoryE;
        this.prevPrivs = PrescriberFileE;
        this.maxDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date()
        };
    }

    ngOnInit() {
        this.getPrescriberData();

        if (this.accessPrivCate) {
            this.hasAddPrescFilePrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PrescriberFile, this.prevPrivs.Add);
            this.hasChangPrescFilePrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PrescriberFile, this.prevPrivs.Edit);
            this.hasDeltPrescFilePrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PrescriberFile, this.prevPrivs.Delete);
        }
    }

    searchPrescriberData() {
        this.setPage(1);
    }

    getPrescriberData() {
        this._editPresc.getPrescriberSearch(this.PrescSearchFG.value).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            this.SelectedPrescriber.emit(null);
            if (resp && resp["TotalDocuments"]) {
                this.generateList(resp);
                this.isDataExists = true;
            } else {
                this.totalCount = 0;
                this.isDataExists = false;
                this.searchPrescList = new CollectionView(null)
            }
        });
    }


    init(flex: wjcGrid.FlexGrid) {
        flex.columns[0].align = "center";
    }

    generateList(data: any) {
        this.totalCount = data.TotalDocuments;
        this.searchPrescList = new CollectionView(
            data.PrescriberDetails.map((presc, i) => {
                const j = {};
                j["Id"] = presc.prescriberid;
                j["Last Name"] = presc.prescriberlastname;
                j["First Name"] = presc.prescriberfirstname;
                j["Quick Code"] =  (presc.prescriberquickcode && presc.prescriberquickcode !== "###") ?
                                    presc.prescriberquickcode : "";
                j["License Number"] =  j["License#"] = (presc.prescriberlicensenumber && presc.prescriberlicensenumber !== "###")
                                    ? presc.prescriberlicensenumber : "";
                j["DEA#"] = (presc.prescriberdeanum && presc.prescriberdeanum !== "###")
                                        ? this._formatsUtil.getDeaFormat(presc.prescriberdeanum) : "";
                j["NPI#"] = (presc.prescribernpinum && presc.prescribernpinum !== "###")
                                    ? presc.prescribernpinum : "";
                 if (presc.PrescriberAddresses && presc.PrescriberAddresses.length > 0) {
                const primaryAddress = _.filter(presc.PrescriberAddresses, {
                    SPINum: +(presc.prescriberspinum)
                })[0];
                j["Address"] = primaryAddress ? (primaryAddress.AddressLine1 + " " +
                                ((primaryAddress.AddressLine2 && primaryAddress.AddressLine2 !== "###")
                                ? primaryAddress.AddressLine2 : "")) : "";
                } else {
                    j["Address"] = "";
                }
                j["Mobile#"] = (presc.mobile && presc.mobile !== "###") ? presc.mobile : "";
                j["Telephone#"] = (presc.telephone && presc.telephone !== "###") ? presc.telephone : "";
                j["Work#"] = (presc.worknumber && presc.worknumber !== "###") ?  presc.worknumber : "";
                return j;
            })
        );
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.startIndex = (this.paginationModel.pageSize * (this.pageNumber - 1));
        this.PrescSearchFG.controls["PageNumber"].patchValue(this.startIndex);
        this.getPrescriberData();
    }

    setSize(size: any) {
        this.pageNumber = this.paginationModel.pageNumber;
        this.pageSize = size ;
        this.PrescSearchFG.controls["PageSize"].patchValue(this.pageSize);
        this.setPage(1);
      //  this.getPatientData();
    }

    addPresc() {
        this._router.navigate(["eprx/prescriber/prescriberinfo"], {
            queryParams: { fm: "SearchPrescriber" }
        });
    }

    editPresc(value) {
        this._router.navigate(
            [
                "eprx/prescriber/" +
                value +
                "/prescriberinfo"
            ],
            { queryParams: { fm: "SearchPrescriber" } }
        );
    }

    openPopUpModal(content, val: any) {
        this.currentItem = val;
        this.modelRef = this.modalService.open(content, { centered: true, keyboard:false});
    }

    clear() {
        this.PrescSearchFG.reset();
        this.PrescSearchFG.controls["includeActive"].patchValue(false);
        this.setSize(this.paginationModel.pageSize);
    }

    checkHasPrivsToAccess(category: number, privType: number) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "Edit") {
                this.editPresc(this.savedValue);
            } else if (this.overrideFor === "Add") {
                this.addPresc();
            } else if (this.overrideFor === "Delete") {
                this.openPopUpModal(this.deletePop, this.savedValue);
            }
        }
    }

    openOveride(content, value, type,endpoint?) {
        this.deletePop = content;
        this.categoryId = "PrescriberFile";
        this.privType = type;
        this.openOverride = true;
        this.savedValue = value;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    prescriberSelected(checked, data, index) {
        this.SelectedPrescriber.emit(data);
    }

    deletePresc() {
        this._editPresc.deletePrescriber(this.currentItem["Id"]).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp === 1) {
                this.getPrescriberData();
                this._alertServ.success(MsgConfig.DELETE_SUCCESS);
            }  else if (resp === 2) {
                this._alertServ.error(MsgConfig.PRESCRIBER_ALREADY_IN_USE, false, "long");
            } else {
                this._alertServ.error(MsgConfig.DELETE_FAIL);
            }
        });
    }

    focusToFirst(event) {
        if (document.getElementById("prescAddBut")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("prescAddBut").focus();
            }, 10);

        }
    }

    focusOutFmCan(event) {
        if (!this.totalCount || this.totalCount === 0) {
            this.focusToFirst(event);
        }
    }

    addEventOnWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                if (this.hasAddPrescFilePrivs) {
                    this.addPresc();
                } else if (!this.hasAddPrescFilePrivs) {
                    this.openOveride(null, null, "Add", "POST_Prescriber");
                }
            }
        }
    }

    editEventOnWijimo(event, grid) {
        if (grid["selectedItems"][0]) {
        event.preventDefault();
        if (this.hasChangPrescFilePrivs) {
            this.editPresc(grid["selectedItems"][0]["Id"]);
        } else if (!this.hasChangPrescFilePrivs) {
            this.openOveride(null, grid["selectedItems"][0]["Id"], "Edit", "PUT_Prescriber");
        }
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
