import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { AdditionalInsurancesCommonComponent } from '../../shared/additional-insurances-common/additional-insurances-common.component';
import { AdditionalInsuranceService } from "src/app/services/additional-insurance.service";
import { AlertService, PatientService, PatPreDrugModalService } from 'src/app/services';
import {  SearchInsurance } from 'src/app/models';
import { CommonWarnorconfirmPopupComponent } from '../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-new-benefit-insurance',
  templateUrl: './new-benefit-insurance.component.html',
  styleUrls: ['./new-benefit-insurance.component.scss']
})
export class NewBenefitInsuranceComponent implements OnInit {
  @Input() CurrentDetails: any;
  @Input() selectedInsuranceDetails: any;
  patientInsurances: any = [];
  selectedInsDetails: any = [];
  selecteRow :any = [];
  insuranceData: any;
  selectedButtonType: string = null;
  oldPatientData: any;
  unsubscribe$: Subject<void> = new Subject();
  @Output() ClosePopup = new EventEmitter<any>();
  constructor(public _commonServ: CommonService,     
    private _patientService: PatientService,
    private _ppdims: PatPreDrugModalService,
    private _alrtsvc: AlertService,  
     private _additionalInsu: AdditionalInsuranceService, 
    private _modalSvc: NgbModal) { }

  ngOnInit(): void {
    this.getRxPatientDetailsById();
    this._commonServ.insuranceData$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.insuranceData = res;
    });
   
  }
  closePopUp() {
      this.ClosePopup.emit(true);
  }
  async getRxPatientDetailsById(isFromAfterAddInsu?) {
    const patId = this.CurrentDetails ? this.CurrentDetails.PatientId : 0;
    const response = await this._commonServ
    .getRxPatientDetailsById(patId)
    .toPromise();
    if(response && response.insurance.length) 
         this.patientInsurances = response.insurance;
         this.selectedInsuranceDetailsView();
         const oldPrimaryIn = this.oldPatientData && this.oldPatientData.length && this.oldPatientData.find((item: any) => {
              return item["insuPriId"] === 1;
          });
          const newPrimaryIn = this.patientInsurances && this.patientInsurances.length &&  this.patientInsurances.find((item: any) => {
            return item["insuPriId"] === 1;
          });
         if(isFromAfterAddInsu && (oldPrimaryIn && newPrimaryIn) &&
          (oldPrimaryIn['insuplanId'] !== newPrimaryIn['insuplanId'])) {
          const modelRef = this._modalSvc.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
          });
          modelRef.componentInstance.IsHeaderText  = "Patient Insurance Changed ";
          modelRef.componentInstance.warnMsg = "Patient's Primary Insurance has been changed from<b> " + 
          (oldPrimaryIn? (oldPrimaryIn['insurancename'].toUpperCase()) : "") + "</b> to <b>" + (newPrimaryIn? newPrimaryIn['insurancename'].toUpperCase() : "")   +"</b>.";
          modelRef.componentInstance.okButton = true;
          modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
              modelRef.close();
          });
         }
       
  }
  selectedInsuranceDetailsView() {
    this.selectedInsuranceDetails.map((res: any)  => {
      const aItem  = this.insuranceData.filter((item) => {
        return (((item['BinNum'] && res['BINLocationNumber']) && item['BinNum'] == res['BINLocationNumber']) &&
         ((item['ProcessorNum'] || res['ProcessorIdentificationNumber']) && item['ProcessorNum'] == res['ProcessorIdentificationNumber'])) });
      res['InsName'] = aItem && aItem.length ? aItem[0]['InsuranceName'] : null;
      res['InsId'] = aItem && aItem.length ? aItem[0]['InsurerCode'] : null;
      res['checked'] = false;
      res['InsuCarrierId'] = aItem && aItem.length ? aItem[0]['InsuCarrierId'] : null;
      if(this.patientInsurances && this.patientInsurances.length) {
        const isInsu = this.patientInsurances.find((item: any) => { return item['insurerid'] ==  res['InsuCarrierId']});
        res['isSameInsurance'] =  isInsu ?  true : false;
      }
    }); 
    this.selectedInsuranceDetails =   this.selectedInsuranceDetails.sort((a: any,b: any) => {
      return Number(a.isSameInsurance) - Number(b.isSameInsurance);
    });
    this.selectedInsuranceDetails.reverse();
    this.selectedPatientDetailsView();
  }
  selectedPatientDetailsView() {
    if (this.patientInsurances && this.patientInsurances.length) {
      this.patientInsurances.map((res: any,index) => {
       const isInsu = this.selectedInsuranceDetails.find((item: any) => { return item['InsuCarrierId'] ==  res['insurerid']});
          res['isSameInsurance'] =  isInsu ?  true : false;
      });
      this.patientInsurances =   this.patientInsurances.sort((a: any,b: any) => {
        return Number(a.isSameInsurance) - Number(b.isSameInsurance);
      });
      this.patientInsurances.reverse();
   }
  }
  addAdditionalInsu(isInsType) {
    this.selectedButtonType = isInsType;
    if (this.selecteRow && !this.selecteRow.length) {
      this._alrtsvc.error("Please select atleast one Record.");
    } else if(this.selecteRow && this.selecteRow.length) {
      this.afterInsuranceAddorCancel(true);
    }
 
  }
  additionalInsurances(isInsType , item?) {
    const modalRef1 = this._modalSvc.open(AdditionalInsurancesCommonComponent,
      { size: "lg", centered: true, backdrop: false, keyboard: false , 
      windowClass:"erx_extra_infoPopup"}
    );
    modalRef1.componentInstance.patientId = this.CurrentDetails.PatientId;
    modalRef1.componentInstance.selectedRow = this.selecteRow[0] ;
    modalRef1.componentInstance.IsPrimaryInsu = isInsType;
    modalRef1.componentInstance.selectedInsuranceDetails = this.selectedInsuranceDetails;
    modalRef1.componentInstance.closePopUp.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        if(res == false && modalRef1) {
          modalRef1.close();
        } else if(res && modalRef1) {
          modalRef1.close();
        }
        this.oldPatientData = this.patientInsurances;
        this.afterInsuranceAddorCancel();
    }); 
  }
  afterInsuranceAddorCancel(isFromIntial: boolean = false) {
    if(!isFromIntial)
         this.selecteRow.shift();
    if(this.selecteRow && this.selecteRow.length) {
      let item =   this.selecteRow[0];
        this.additionalInsurances(this.selectedButtonType, item);
    } else {
      this.selecteRow = [];
      this.getRxPatientDetailsById(true);
    }
  }
  checkAll(isChecked,item) {
    if (isChecked) {
        this.selectedInsDetails.push(item);
    } else {
        this.selectedInsDetails.map((val, i) => {
            if(val["PAYER IDENTIFICATION"] ==  item["PAYER IDENTIFICATION"])
                        this.selectedInsDetails.splice(i, 1);
        });
    }
}
selectData(item, isChecked?) {
    if (isChecked) {
      this.selecteRow.push(item);
  } else {
      this.selecteRow.map((val, i) => {
          if(val["PAYER IDENTIFICATION"] ==  item["PAYER IDENTIFICATION"])
                      this.selecteRow.splice(i, 1);
      });
  }
}
routeToAddInsurance(item) {
  this._ppdims._patPreDrugModal$.next("insurance");
  const modalRef = this._ppdims.modalInstanceRef;
  modalRef.componentInstance.insuId = null;
  modalRef.componentInstance.selectedRow = item;
  modalRef.componentInstance.SkipUpdateBS = true;
  modalRef.componentInstance.CloseInsuModal.pipe(this.unsubscribe$).subscribe(async resp => {
      modalRef.close();
      if (resp && resp["Type"] !== "Cancel") {
          const search = new SearchInsurance();
          const data = await this._commonServ.getInsuData(search).toPromise();
          this._commonServ._insuranceData$.next(data["Records"]);
          this.getRxPatientDetailsById();
      }
      this._ppdims._patPreDrugModal$.next(null);
  });
}
ngOnDestroy(){
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
