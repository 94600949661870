import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SystemData } from 'src/app/models';
import { CommonService, InsuranceService } from 'src/app/services';
import { CommonLogicRxInfoUtil } from 'src/app/utils/commonLogic-rxInfo.utils';

@Component({
    selector: "app-rx-serial-req-pop-display",
    templateUrl: "./rx-serial-req-pop-display.component.html"
})
export class RxSerialReqPopDisplayComponent implements OnInit {
    @ViewChild("rxSerialPopup", { static: true })
    rxSerialPopup: any;
    @ViewChild("SavePopup", {static: true})
    SavePopup: any;
    @ViewChild("CancelPopup", {static: true})
    CancelPopup: any;

    @Input() rxSerialFG: FormGroup;
    @Input() rxFG: FormGroup;
    @Input() frmIconClick: boolean;
    @Input() systemData: SystemData;
    @Input() selectedRxOriginId: any;
    @Input() frmQuickRef: boolean;
    @Input() frmeRx: boolean;
    @Output()
    IsPopUpClosed = new EventEmitter<boolean>();
    cntrlFileStngs$: Observable<any>;

    modelRef: any;
    forceRxSerStng: any;
    oldRxSerVal: any;
    modelRefCancel: NgbModalRef;
    modelRefSave: NgbModalRef;
    repeatNumber: any;
    defaulteRxSerialNum: any;
    CntrlRefillStng: any;
    isRequired: boolean;
    selectedVal: any;
    constructor(
        private _modalService: NgbModal,
        private _commonServ: CommonService, private _insServ: InsuranceService, private _cmnLogicRxUtil: CommonLogicRxInfoUtil
    ) {}

    ngOnInit() {
        this.rxSerialFG.controls["rxSerial"].patchValue(this.frmIconClick && this.rxFG.controls["Prescription"].value["PrescSN"] ?
         this.rxFG.controls["Prescription"].value["PrescSN"] : "");
        this.rxFG.controls["Prescription"].patchValue({PrescSN :
             this.rxFG.controls["Prescription"].value["PrescSN"] ?
         this.rxFG.controls["Prescription"].value["PrescSN"] : ""});
        let RxSerialReq = false;
        const drug: FormGroup = this.rxFG.controls["Drug"] as FormGroup;
        // const CntrlRxSerReq = this._commonServ.getSetttingValue("ControlFileSettings", "Control_Rx_Serial_Required");
        // this.forceRxSerStng = this._commonServ.getSetttingValue("RxSettings", "Force_Rx_Serial_entry");
        let CntrlRxSerReqList = this._commonServ.getSetttingValue("ControlFileSettings", "Control_Rx_Serial_Required");
        this.defaulteRxSerialNum = this._commonServ.getSetttingValue(
            "RxSettings",
            "Erx_default_serial_number"
        );
        this.cntrlFileStngs$ =
            this._commonServ.controlFileSettings$["source"]["value"];
        const controlRefillOptions = this.cntrlFileStngs$ && this.cntrlFileStngs$["ControlRefillOptions"] ? 
            this.cntrlFileStngs$["ControlRefillOptions"] : null;
        this.CntrlRefillStng = controlRefillOptions && controlRefillOptions.length &&
            controlRefillOptions.find(
                (item: any) =>
                    +item["DrugClass"] === +this.rxFG.value["Drug"]["drugclass"]
            );
        let CntrlRxSerReq = [];
        if(typeof(CntrlRxSerReqList) == "string" && CntrlRxSerReqList.includes(",")){
            CntrlRxSerReqList = CntrlRxSerReqList.replace(/ /g, "");
            CntrlRxSerReq = CntrlRxSerReqList.split(",");
        }else{
            CntrlRxSerReq.push(CntrlRxSerReqList);
        }
        // const insuSetngs = this._insServ.insuSettings$["source"]["value"];
            // if (insuSetngs && insuSetngs.InsuPlanOther && insuSetngs.InsuPlanOther.IsRxSNReqd) {
            //     RxSerialReq = true;
            // } else
             if (CntrlRxSerReq[0] === "C"  && (drug.value["drugclass"] >= 2 && drug.value["drugclass"] <= 5)) {
                RxSerialReq = true;
            } else if (CntrlRxSerReq.includes(this.rxFG.value["Drug"]["drugclass"].toString())) {
                RxSerialReq = true;
            } else {
                RxSerialReq = false;
            }
        // }
        // this.repeatNumber = this._commonServ.getSetttingValue(
        //     "ControlFileSettings",
        //     "SELECT_RXSERIAL_LENGTH"
        // );
        this.isRequired = RxSerialReq;
        const pharmacyState = this._commonServ.getSetttingValue("TenantSettings", "TNT_STATE");
        // const RxOrginData = this.systemData.RxOrigin.find((item: any) => item.Id === this.rxFG.controls["PrescReFill"].value["RxOriginId"]);
        this._cmnLogicRxUtil.patchRxSerialCmnLogic(this.rxFG, this.frmeRx, this.systemData,this.CntrlRefillStng)
       
        //new code
           /* if (this.frmeRx) {
                this.rxSerialFG.controls["rxSerial"].patchValue(
                    this.CntrlRefillStng && this.CntrlRefillStng["ERxSerialNo"]
                        ? this.CntrlRefillStng["ERxSerialNo"]
                        : this.defaulteRxSerialNum
                        ? this.defaulteRxSerialNum
                        : null
                );
                this.rxFG.controls["Prescription"].patchValue({PrescSN:
                     this.CntrlRefillStng && this.CntrlRefillStng["ERxSerialNo"]
                     ? this.CntrlRefillStng["ERxSerialNo"]
                     : this.defaulteRxSerialNum
                     ? this.defaulteRxSerialNum
                     : null
                    });
                // if (this.selectedRxOriginId) {
                //     if (this.selectedRxOriginId === "Written" || this.selectedRxOriginId === "Telephone") {
                //     this.rxFG.controls["Prescription"].patchValue({PrescSN: Array(+this.repeatNumber + 1).join("9")});
                //     } else if (this.selectedRxOriginId === "Facsimile") {
                //     this.rxFG.controls["Prescription"].patchValue({PrescSN: Array(+this.repeatNumber + 1).join("E")});
                //     } else if (this.selectedRxOriginId === "Not Known" || this.selectedRxOriginId === "Pharmacy") {
                //     this.rxFG.controls["Prescription"].patchValue({PrescSN: ""});
                //     }
                // }
            } else {
                if ((RxOrginData && RxOrginData.Name === "Electronic") || (this.selectedRxOriginId && this.selectedRxOriginId === "Electronic")) {
                    this.rxFG.controls["Prescription"].patchValue({PrescSN:
                     this.CntrlRefillStng && this.CntrlRefillStng["ERxSerialNo"]
                     ? this.CntrlRefillStng["ERxSerialNo"]
                     : this.defaulteRxSerialNum
                     ? this.defaulteRxSerialNum
                     : Array(+this.repeatNumber + 1).join("E")
                    });
                } else if ((RxOrginData && RxOrginData.Name === "Written") || (RxOrginData && RxOrginData.Name === "Telephone") ||
                (this.selectedRxOriginId && (this.selectedRxOriginId === "Written" || this.selectedRxOriginId === "Telephone"))) {
                    this.rxFG.controls["Prescription"].patchValue({PrescSN: Array(+this.repeatNumber + 1).join("9")});
                } else if ((RxOrginData && RxOrginData.Name === "Facsimile") || (this.selectedRxOriginId && this.selectedRxOriginId === "Facsimile")) {
                    this.rxFG.controls["Prescription"].patchValue({PrescSN: Array(+this.repeatNumber + 1).join("E")});
                } else if ((RxOrginData && RxOrginData.Name === "Not Known") || (RxOrginData && RxOrginData.Name === "Pharmacy")
                || (this.selectedRxOriginId && (this.selectedRxOriginId === "Not Known" || this.selectedRxOriginId === "Pharmacy"))) {
                    this.rxFG.controls["Prescription"].patchValue({PrescSN: ""});
                }
            }*/
        
        this.IsPopUpClosed.emit();
    }

    selectValDisplay(val) {
        /*this.selectedVal = val;
        this.rxSerialFG.controls["rxSerial"].markAsUntouched();
        if (val === "eRx") {
            this.rxSerialFG.controls["rxSerial"].patchValue(
                this.CntrlRefillStng && this.CntrlRefillStng["ERxSerialNo"]
                    ? this.CntrlRefillStng["ERxSerialNo"]
                    : this.defaulteRxSerialNum
                    ? this.defaulteRxSerialNum
                    : null
            );
        } else {
            this.rxSerialFG.controls["rxSerial"].patchValue(
                null);
        }*/
    }

    patchRxSerial() {
        /*if (!this.rxSerialFG.controls["rxSerial"].value) {
            if (this.forceRxSerStng === "1" || this.isRequired || this.frmQuickRef) {
                this.rxSerialFG.controls["rxSerial"].markAsTouched();
                this.rxSerialFG.controls["rxSerial"].setErrors({ required: true });
                document.getElementById("rxSerial").focus();
            } else {
                this.modelRefSave = this._modalService.open(this.SavePopup, { centered: true, backdrop: false,
                    keyboard: false,  windowClass: "medium-sm-modal-30"});
            }
        } else {
            if (this.frmQuickRef && this.rxSerialFG.controls["rxSerial"].value && this.rxSerialFG.controls["rxSerial"].value.length < this.repeatNumber) {
                this.rxSerialFG.controls["rxSerial"].markAsTouched();
                this.rxSerialFG.controls["rxSerial"].setErrors({
                     minlength: "Rx Serial# length should be >= " + this.repeatNumber });
                document.getElementById("rxSerial").focus();
            } else {
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
            const prescFG: any = this.rxFG.controls["Prescription"];
             prescFG.controls["PrescSN"].patchValue(this.rxSerialFG.value["rxSerial"]);
            this.oldRxSerVal = this.rxFG.controls["Prescription"].value["PrescSN"];
            this.IsPopUpClosed.emit(this.selectedVal);
        }
        }*/
    }

    saveRxSerial() {
        /*const prescFG: any = this.rxFG.controls["Prescription"];
        prescFG.controls["PrescSN"].patchValue(this.rxSerialFG.value["rxSerial"]);
        if (this.modelRefSave) {
            this.modelRefSave.close();
            this.modelRefSave = null;
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.IsPopUpClosed.emit();*/
    }

    focustoRxSerial() {
       /* if (this.modelRefSave) {
            this.modelRefSave.close();
            this.modelRefSave = null;
        }
        document.getElementById("rxSerial").focus();*/
    }

    closeModal() {
        // if (this.rxSerialFG && !this.rxSerialFG.controls["rxSerial"].value) {
          /*  if ((this.forceRxSerStng === "1" || this.isRequired || this.frmQuickRef)  && this.rxSerialFG && !this.rxSerialFG.controls["rxSerial"].value) {
                    this.rxSerialFG.controls["rxSerial"].markAsTouched();
                    this.rxSerialFG.controls["rxSerial"].setErrors({ required: true });
                    document.getElementById("rxSerial").focus();
            } else if (this.rxSerialFG) {
                if (!this.rxSerialFG.controls["rxSerial"].value) {
                    if (this.rxFG.controls["Prescription"].value["PrescSN"]) {
                        this.modelRefCancel = this._modalService.open(this.CancelPopup, { centered: true, backdrop: false,
                            keyboard: false, windowClass: "medium-sm-modal-30"});
                    } else {
                        if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        }
                        this.IsPopUpClosed.emit();
                    }
                } else {
                    if (this.rxSerialFG.controls["rxSerial"].value === this.rxFG.controls["Prescription"].value["PrescSN"]) {
                        if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        }
                        this.IsPopUpClosed.emit();
                    } else {
                        if ((this.oldRxSerVal && this.oldRxSerVal !== this.rxSerialFG.value["rxSerial"]) || !this.frmeRx) {
                            this.modelRefCancel = this._modalService.open(this.CancelPopup, { centered: true, backdrop: false,
                                keyboard: false, windowClass: "medium-sm-modal-30"});
                        } else {
                            if (this.modelRef) {
                                this.modelRef.close();
                                this.modelRef = null;
                            }
                        }

                    }
                }
            } else {
                if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        }
                this.IsPopUpClosed.emit();
            }*/
    }

    /*discardYes() {
        if (this.modelRefCancel) {
            this.modelRefCancel.close();
            this.modelRefCancel = null;
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
        const prescFG: any = this.rxFG.controls["Prescription"];
        prescFG.controls["PrescSN"].patchValue(this.rxFG.controls["Prescription"].value["PrescSN"] ?
         this.rxFG.controls["Prescription"].value["PrescSN"] : "");
        this.IsPopUpClosed.emit();
    }

    discardNo() {
        if (this.modelRefCancel) {
            this.modelRefCancel.close();
            this.modelRefCancel = null;
        }
    }*/

    close() {
       /* if ((this.frmQuickRef)  && this.rxSerialFG && !this.rxSerialFG.controls["rxSerial"].value) {
            this.rxSerialFG.controls["rxSerial"].markAsTouched();
            this.rxSerialFG.controls["rxSerial"].setErrors({ required: true });
            document.getElementById("rxSerial").focus();
        } else {
            this.rxSerialFG.controls["rxSerial"].markAsUntouched();
            this.IsPopUpClosed.emit();
        }
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }*/
    }
}
