import { Component, OnInit, Input } from "@angular/core";
import { RxModel } from "src/app/models";
import { UserService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";

@Component({
    selector: "app-print-acpt-rej",
    templateUrl: "./print-acpt-rej.component.html"
})
export class PrintAcptRejComponent implements OnInit {
    @Input() transmResp: any;
    @Input() rxInfo: RxModel;
    @Input() transcRespStatus: any;
    @Input() transmissionType: any;
    @Input() transmitParams: any;

    currentDate: Date;
    pharmName: string;
    pharmAddress: string;
    pharmFax: string;
    pharmPhone: string;
    actvHeaders = [
        "Reason",
        "Significance",
        "Other.PH",
        "Prv.Fill",
        "Fill Qty",
        "DB",
        "Other Pres.",
        "Comment",
    ];
    DURWJ: CollectionView;
    durData: any;

    constructor(private _userService: UserService) {}

    ngOnInit() {
        this.currentDate = new Date();
        this.pharmName = this._userService.getToken("PharmacyName");
        this.pharmAddress = this._userService.getToken("PharmacyAddress");
        this.pharmFax = this._userService.getToken("PharmacyFax");
        this.pharmPhone = this._userService.getToken("PharmacyPhone");
        if (this.transmResp && this.transmResp["transmissionDetails"]
            && this.transmResp["transmissionDetails"]["lstDUR"]) {
            this.generateWJ(this.transmResp["transmissionDetails"]["lstDUR"]);
        }
    }

    generateWJ(data) {
        if (data && data.length > 0) {
            this.durData = data.map(d => {
                const j = {};
                j["Reason"] = d.REASON;
                j["Significance"] = d.SIGNIFICANCE;
                j["Other.PH"] = d.OTHERPH;
                j["Prv.Fill"] = d.PREVFILL;
                j["Fill Qty"] = d.QTY;
                j["DB"] = d.DBI;
                j["Other Pres."] = d.OTHERPRES;
                j["Comment"] = d.DURTEXT;
                return j;
            });
            this.DURWJ = new CollectionView(
                data.map(d => {
                    const j = {};
                    j["Reason"] = d.REASON;
                    j["Significance"] = d.SIGNIFICANCE;
                    j["Other.PH"] = d.OTHERPH;
                    j["Prv.Fill"] = d.PREVFILL;
                    j["Fill Qty"] = d.QTY;
                    j["DB"] = d.DBI;
                    j["Other Pres."] = d.OTHERPRES;
                    j["Comment"] = d.DURTEXT;
                    return j;
                })
            );
        }
    }
}
