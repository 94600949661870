import { SystemData } from './../models/system-data.model';
import { CommonService, AlertService } from 'src/app/services';
import { Injectable } from "@angular/core";
import { RxService } from "../services";
import { Subscription } from "rxjs/internal/Subscription";
import * as _ from "lodash";

@Injectable({
    providedIn: "root"
})
export class MiscUtils {
    subscriptions = new Subscription();
    systemData: SystemData;

    constructor(
        private _rxService: RxService,
        private _commonSvc: CommonService,
        private _alertService: AlertService
    ) {
        this._commonSvc.systemData$.subscribe(resp => {
            if (resp) {
                this.systemData = resp;
            }
        });
    }

    convertToCash(rxfg?: any, rxInfo?: any) {
        if (rxfg) {
            const primaryInsu = _.filter(rxfg.value.Patient.insurance, {
                insurerid: this.systemData["DefaultValuese"][0]["CashInsurerId"]
            })[0];
            const refillInsu: any = rxfg.controls.RefillInsu;
            refillInsu.controls.InsuId.setValue(primaryInsu["insuranceid"]);
            return rxfg;
        } else {
            const primaryInsu = _.filter(rxInfo.Patient.insurance, {
                insurerid: this.systemData["DefaultValuese"][0]["CashInsurerId"]
            })[0];
            rxInfo.RefillInsu["InsuId"] = primaryInsu["insuranceid"];
            return rxInfo;
        }
    }

    updateRxInfo(rxInfo) {
        this.subscriptions.add(
            this._rxService.updateRxInfo(rxInfo).subscribe(resp => {
                return resp;
            }));
    }

    saveRxTag(fg?: any, rxFollowInfo?: any): any {
        const refillVal = fg.controls["PrescReFill"].value;
        const rxVal = fg.controls["Prescription"].value;
        rxFollowInfo["PrescNum"] = rxVal.PrescNum;
        rxFollowInfo["PrescRefillId"] = refillVal.Id;
        rxFollowInfo["RefillNum"] = refillVal.ReFillNum;
        rxFollowInfo["PartialfillNum"] = refillVal.PartialFillNo;
        if (rxFollowInfo.PrescRefill_RxTags.length > 0) {
            rxFollowInfo.PrescRefill_RxTags.map(val => {
                val.PrescRefillId = refillVal.Id;
            });
        }

        this.subscriptions.add(
            this._rxService
                .saveRxFollowUpValues(rxFollowInfo)
                .subscribe(resp => {
                    if (resp) {
                        // this._alertService.success("Rx follow-up tag saved successfully.");
                    } else {
                        this._alertService.error("Rx follow-up tag save unsucessful.");
                    }
                    return resp;
                })
        );
    }
}
