export class SearchInsurance {
    PlanCodeSearchText: string = null;
    InsNameSearchText: string = null;
    PageNumber: number = null;
    BinNum:any = null;
    PageSize: number = null;
}

export class InsuranceInfo {
    Phone = null;
    Address = null;
    Organization = null;
    OrgAdds: OrgAdds = null;
    OrgPh: OrgPh = null;
    InsuCarrier = null;
    InsuPlan = null;
    InsuTransmission: InsuTransmission = null;
    SpecialProgram: SpecialProgram = null;
}

export class OrgAdds {
    Id: number = null;
    BranchName: string = null;
    OrgId: number = null;
    PrimePhoneId: number = null;
    PrimeEmailId: number = null;
}

export class OrgPh {
    Id: number = null;
    OrgAddsId: number = null;
}

export class InsuTransmission {
    Id: number = null;
    InsuPlanId: number = null;
    SwitchId: number = null;
    ModemPrim: string = null;
    ModemSec: string = null;
    NCPDPVer: string = null;
    InsuXmitSubCatId: number = null;
    CommModeId: number = null;
    SubClarifId: number = null;
    SubclarificationName: string = null;
}

export class SpecialProgram {
    Id: number = null;
    TenantId: number = null;
    InsuPlanId: number = null;
    SplProgramId: number = null;
    IsActive: boolean = null;
}

export class RefreshData {
    IC_CODE: string = null;
    IC_NAME: string = null;
    ADDRESS: string = null;
    PHONE: string = null;
    PHARM_NO: string = null;
    MAG_CODE: string = null;
    BIN_NO: string = null;
    COMMENT: string = null;
    CITY: string = null;
    STATE: string = null;
    ZIP: string = null;
    PHONE_V: string = null;
    CONTACT: string = null;
    NCPDP_VER: string = null;
    SWITCHCD: string = null;
    COMMMODE: string = null;
    MDSUPPLY: number = null;
    MAXQTY: number = null;
    MAXMIN: number = null;
    MDOTOF: number = null;
    MREFILL: number = null;
    MDREFILL: number = null;
    WORKMAN_C: string = null;
    TBATCHQTY: number = null;
    PART_FILL: string = null;
    QPAYORID: string = null;
    PAYORID: string = null;
    RECV_ID: string = null;
    DEFOTHCOVCODE: string = null;
    OTHPAYERCOVCODE: string = null;
    FORCECOBFORSECBILL: string = null;
    SET51ID: string = null;
}

export class InsuranceSettings {
    InsuPlanPrice: InsuPlanPrice = null;
    InsuPlanOther: InsuPlanOther = null;
}

export class InsuPlanPrice {
    Id: number = null;
    TenantId: number = null;
    InsuPlanId: number = null;
    CostBasisId: number = null;
    CoPayCatId: number = null;
    CoPayBrandAmnt: number = null;
    CoPayGenAmnt: number = null;
    ProFee: number = null;
    IsPFForOTC: boolean = null;
    BrandPriceSchId: number = null;
    GenPriceSchId: number = null;
    IsUNCSame: boolean = null;
    BrandPricePer: number = null;
    GenPricePer: number = null;
    IsProfWatch: boolean = null;
    MaxCoPayAmnt: number = null;
    IsZeroBillAllowed: boolean = null;
    IsZeroUNC: boolean = null;
    IsAssigntAccepted: boolean = null;
    TaxRatePer: number = null;
    SalesTaxBasisId: number = null;
    IsTaxOnRx: boolean = null;
    IsTaxOnOTC: boolean = null;
    FlatSalesTaxAmt: number = null;
    IsPartialFillAllowed: boolean = null;
}

export class InsuPlanOther {
    Id: number = null;
    TenantId: number = null;
    InsuPlanId: number = null;
    PrescIdCatId: number = null;
    PrimePrescIdCatId: number = null;
    MaxDaysSupply: number = null;
    MaxQty: number = null;
    OrderToFillDays: number = null;
    MaxRefills: number = null;
    OrderToLastRefill: number = null;
    IsRefillRepriced: boolean = null;
    IsSortByRx: boolean = null;
    IsWorkmanComp: boolean = null;
    // IsRxSNReqd: boolean = null;
    RPhIdCatId: number = null;
    CompoundIngrcandiffer: boolean = null;
    RefillDueConsumptionThreshold: number = null;
    DgCodeDotReq: string = null;
}

export class InsuTransOther {
    Id: number = null;
    TenantId: number = null;
    InsuPlanId: number = null;
    BatchQty: number = null;
    IsPASupport: boolean = null;
    IsRebillSupport: boolean = null;
    IsPartialFill: boolean = null;
    IsPatPaidAsCopay: boolean = null;
    VendCert: string = null;
    PayorIdQualifId: number = null;
    PayorNum: string = null;
    ReceiverNum: string = null;
    COBHCReqQualif: string = null;
    DefOthrCovCode: string = null;
    OthrPayorCovCode: string = null;
    ForceCoBSeg: string = null;
    NCDPId: number = null;
    COBMethodId: number = null;
    DefServPlace: string = null;
    IsOPPRAFoced: boolean = null;
    IsQualifForced: boolean = null;
    IsOthConvCodeSent: boolean = null;
    IsSubClarifCodeSent: boolean = null;
    IsLeadingZeroSent: boolean = null;
}

export class InsuNcpdp {
    Ncpdp: Ncpdp = null;
    NcpdpSeg: NcpdpSeg = null;
}

export class Ncpdp {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    NCPDPCatId: number = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class NcpdpSeg {
    Id: number = null;
    NCPDPId: number = null;
    IsPatientIncl: boolean = null;
    PatientStr: string = null;
    IsInsuIncl: boolean = null;
    InsuStr: string = null;
    IsClaimsIncl: boolean = null;
    ClaimsStr: string = null;
    IsPharmProvIncl: boolean = null;
    PharmProvStr: string = null;
    IsPrescIncl: boolean = null;
    PrescStr: string = null;
    IsCOBIncl: boolean = null;
    COBStr: string = null;
    IsWCompIncl: boolean = null;
    WCompStr: string = null;
    IsDURIncl: boolean = null;
    DURStr: string = null;
    IsPricingIncl: boolean = null;
    PricingStr: string = null;
    IsCouponIncl: boolean = null;
    CouponStr: string = null;
    IsCompoundIncl: boolean = null;
    CompoundStr: string = null;
    IsPriorApprIncl: boolean = null;
    PriorApprStr: string = null;
    IsClinicalIncl: boolean = null;
    ClinicalStr: string = null;
    IsAddDocIncl: boolean = null;
    AddDocStr: string = null;
    IsFacilityIncl: boolean = null;
    FacilityStr: string = null;
    IsNarrativeIncl: boolean = null;
    NarrativeStr: string = null;
}

export class OtherInsPayer {
    InsuPlanId: number = null;
    PageNumber: number = null;
    PageSize: number = null;
}

export class OtherPayor {
    Id: number = null;
    TenantId: number = null;
    InsuCarrierId: number = null;
    InsuPlanId: number = null;
    PayerCode: string = null;
    PayerQualifId: number = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    OtherInsuId: any = null;
}

export class InsuranceData {
    Id: number = null;
    InsuCarrierId: number = null;
    PlanCode: string = null;
    InsuranceName: string = null;
    Address: string = null;
    CityName: string = null;
    StateName: string = null;
    PhoneNumber: string = null;
    PharmacyNum: string = null;
    ProcessorNum: string = null;
    BinNum: string = null;
    NcpdpVer: string = null;
    CommunicationMode: string = null;
    Remarks: string = null;
    TotalCount: number = null;
}

export class InsuBillFileSetting {
    Id: number = null;
    TenantId: number = null;
    InsuPlanId: number = null;
    SegSep: string = null;
    ElementSep: string = null;
    FieldSep: string = null;
    VersionCode: string = null;
    SendorCode: string = null;
    SendorQualif: string = null;
    AuthInfo: string = null;
    AuthInfoQualif: string = null;
    ReceiverCode: string = null;
    ReceiverQualif: string = null;
    SecurityInfo: string = null;
    SecurityInfoQualif: string = null;
    InterContCode: string = null;
    InterContVersion: string = null;
    RecCode: string = null;
    EntName: string = null;
    VersionCode2: string = null;
}

export class PayorData {
    PhoneId: number = null;
    PhoneNum: string  = null;
    FaxId: number = null;
    FaxNum: string = null;
    AddressId: number = null;
    Address1: string = null;
    ZipCode: string = null;
    ZipExtension: string = null;
    OrganizationId: number = null;
    PayorId: number = null;
    PayorInsId: string = null;
    PayorName: string = null;
    PayorNotes: string = null;
    ContactName: string = null;
    OrgAddsId: number = null;
    OrgPhId: number = null;
    CityId: number = null;
    CityName: string = null;
    StateId: number = null;
    StateName: string = null;
    PrCreatedDtTm: any = null;
    OgCreatedDtTm: any = null;
    AdCreatedDtTm: any = null;
    FaxCreatedDtTm: any = null;
    PhCreatedDtTm: any = null;
    TotalRecords: number = null;
}

export class PayorInsuranceInputParams {
    payorId: number = null;
    insuranceId: any[] = [];
    // IsFromEditInsurance = false;
}
