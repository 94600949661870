import { FormBuilder, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";
import { RxModel, ERxDoseSchs, RxAuditLog, RxExtra, PrescInfo, CouponInfo, DiagCode, ProcModifier, ErxVersions, RxBillingCalculationInput } from "../models";
import * as moment from "moment";
import * as _ from "lodash";
import { RxService, RxTransferService, CommonService, DocumentsService, DrugService, DMSService, ErxService, InsuranceService } from "../services";
import { NewAuditUtils } from "./new-audit.util";
import { forkJoin, Subscription } from "rxjs";
import { RxStore } from "../store";
import { RecallDeleteRxService } from "../services/recall-delete-rx.service";
import { PrintService } from "../services/print.service";
import { NRxUtils } from "./nRx.util";
import { RxBillingUtil } from "./rx-billing.util";
import { CommonUtil } from "./common.util";
import * as Sentry from "@sentry/browser";

@Injectable({
    providedIn: "root"
})
export class NRxSaveUtils {
    count = 0;
    constructor(private _rxServ: RxService, private _nwAudtUtl: NewAuditUtils, private _nrxUtls: NRxUtils,
            private _recallRxSer: RecallDeleteRxService, private _rxStore: RxStore, private _rxTrnsfrServ: RxTransferService,
            private _cmmnServ: CommonService, private _docService: DocumentsService, private _drugServ: DrugService,
            private _rxbilUitl: RxBillingUtil, private _dmsSer: DMSService, private _fb: FormBuilder,
            private _printser: PrintService, private erxService: ErxService, private _commUtil: CommonUtil, private _insuServ: InsuranceService) {}

    setValuesBfrSave(rxFG, rxType, drugIntrctn, isCash, systemData?: any) {
        const rxInfo: RxModel = rxFG.getRawValue();
        const prescReFill = rxFG.controls["PrescReFill"] as FormGroup;
        const Prescription = rxFG.controls["Prescription"] as FormGroup;

        // TODO: Check bill status is passing as 1 for new Rx
        // TODO: Check rxstatusid 1 in prescription
        // TODO: check PrescRefill statusID as 1 for new Rx
        if (!Prescription.value.PrescSN) {
            Prescription.controls["PrescSN"].patchValue("");
        }

        Prescription.controls["PatientId"].setValue(rxInfo.Patient.patientid);
        Prescription.controls["PrescriberId"].setValue(rxInfo.Prescriber.prescriberid);
        if (rxType !== "rf" || (rxType === "rf" && !prescReFill.value["IsNewPrescRefill"])) {
            Prescription.controls["DrugId"].setValue(rxInfo.Drug.id);
        }
        prescReFill.controls["DrugId"].setValue(rxInfo.Drug.id);

        delete rxInfo.SigCodes["AltDescription"];

        if (!(prescReFill.value.StatusId === 2 || Prescription.value.RxStatusId === 2)) {
            rxInfo["RxFile"] = null;
        }

        if (rxInfo && rxInfo["RxFile"] && !rxInfo["RxFile"]["DeferredReason"]) {
            rxInfo["RxFile"]["DeferredReason"] = null;
        }

        if (!(rxType === "rf")) {
            rxInfo.Prescription.PatientId = rxInfo.Patient.patientid;
            rxInfo.Prescription.PrescriberId = rxInfo.Prescriber.prescriberid;
            rxInfo.Prescription.DrugId = rxInfo.Drug.id;
            rxInfo.Prescription.Days = rxInfo.PrescReFill.SupplyDays;
            rxInfo.Prescription.Qty = rxInfo.PrescReFill.DispQty;
            rxInfo.Prescription.IsActive = true;
            rxInfo.Prescription.RxReceiptDtTm = new Date();
            rxInfo.RxBill.DrugId = rxInfo.Drug.id;
            rxInfo.PrescReFill.DrugId = rxInfo.Drug.id;
        }
        rxInfo.RxBill.Qty = rxInfo.PrescReFill.DispQty;
        rxInfo.PrescReFill.SalesPerId = localStorage.getItem("UserId") ? +this._cmmnServ.DecryptData(localStorage.getItem("UserId")) : 1;
        rxInfo.PrescReFill.FillDtTm = !rxInfo.PrescReFill.FillDtTm ? new Date() : rxInfo.PrescReFill.FillDtTm;
        rxInfo.PrescReFill.NextFillDt = moment(rxInfo.PrescReFill.FillDtTm).add("days",
            rxInfo.PrescReFill.SupplyDays ?
                rxInfo.PrescReFill.SupplyDays : 0).toDate();
           // rxInfo.PrescReFill.ExpDt = moment(rxInfo.PrescReFill.OrderDtTm).toDate();

        //rxInfo.RxBill.DosageInstId = 1;
        rxInfo.PrescReFill.BucketId = rxInfo.PrescReFill.BucketId ? rxInfo.PrescReFill.BucketId
            : this._cmmnServ.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET") ?
            +this._cmmnServ.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET") : null;
        if(rxType === "nr" || rxType === "er") {
            rxInfo.PrescReFill.ReferencePrescReFillId = (sessionStorage.getItem("ReferencePrescReFillId") && rxFG?.controls?.isNewFrmExisting.value == true ) ?  +this._cmmnServ.DecryptData(sessionStorage.getItem("ReferencePrescReFillId")) :  rxInfo?.PrescReFill?.ReferencePrescReFillId;
        }
        if (rxType !== "er") {
            const RxAdmDosSchFilt = JSON.parse(
                sessionStorage.getItem("RxAdmDosSchFilt")
            );
            const RxAdmDosSchInf: any[] = JSON.parse(
                sessionStorage.getItem("RxAdmDosSchInf")
            );

            rxInfo.RxAdminDoseSchedule.RxAdmin = JSON.parse(
                sessionStorage.getItem("RxAdmDosSchFilt")
            );
            rxInfo.RxAdminDoseSchedule.RxDoseSchs = [];
            if (RxAdmDosSchInf) {
                RxAdmDosSchInf.map(ds => {
                    const admnDosSchInfo = new ERxDoseSchs();
                    admnDosSchInfo.DoseSeqNum = ds.DoseNum;
                    admnDosSchInfo.DoseQty = ds.DoseQty;
                    admnDosSchInfo.DoseTimeCatId = 1;
                    admnDosSchInfo.DoseSlot = ds.DoseSlot;
                    admnDosSchInfo.DoseTime = ds.DoseTime;
                    rxInfo.RxAdminDoseSchedule.RxDoseSchs.push(admnDosSchInfo);
                });
            }
        }

        rxInfo.prescRefill_Observtions.PrescRefillId = rxInfo.PrescReFill.Id;
        if (drugIntrctn && rxFG.value["rxType"] !== "er") {
            let morepatientinfo = this._rxStore.morePatntInfo$["source"]["value"];
            rxInfo.prescRefill_Observtions.IsDrugDisContraInd =
                    drugIntrctn.DiseaseContradication && drugIntrctn.DiseaseContradication.IsShowDDC ? true : false;
            rxInfo.prescRefill_Observtions.IsDrugToDrugIntra =
                    drugIntrctn.DrugToDrugInteraction && drugIntrctn.DrugToDrugInteraction.length > 0 ? true : false;
            rxInfo.prescRefill_Observtions.IsDupDrugTherapy =
            (drugIntrctn.DuplicateDrugs && drugIntrctn.DuplicateDrugs.length > 0) ? true : false;
            rxInfo.prescRefill_Observtions.IsDrugAllergy =
                    drugIntrctn.Allergies ? drugIntrctn.Allergies.IsAllergy : null;
            rxInfo.prescRefill_Observtions.IsPregLact =
            drugIntrctn &&
            drugIntrctn.PregnancyLactationInfo &&
            rxInfo.Patient.gender === "Female" &&
            this._nrxUtls.isSafeForLabrDelivrySet() && +morepatientinfo && +morepatientinfo.MorePatientInfo &&
            +morepatientinfo.MorePatientInfo.PregnancyCdId === 3 &&
            drugIntrctn.PregnancyLactationInfo.IssfeforLabourandDelivery === "No" ? true : false;
            rxInfo.prescRefill_Observtions.IsAdvrDrugReaction =
                drugIntrctn.AdverseReaction && drugIntrctn.AdverseReaction.length > 0 ? true : false;
            rxInfo.prescRefill_Observtions.IsDrugLifeStyle =
            drugIntrctn.LifeStyle && drugIntrctn.LifeStyle.length > 0 ? true : false;
        }
        rxInfo.prescRefill_Observtions.CreatedDtTm = rxInfo.prescRefill_Observtions.CreatedDtTm ? rxInfo.prescRefill_Observtions.CreatedDtTm : new Date();
        if (isCash) {
            rxInfo["PrescReFill"]["IsRxHold"] = false;
        }
        if (rxInfo.RxOtherAmount && rxInfo.RxOtherAmount.OtherAmntMisc &&
            rxInfo.RxOtherAmount.OtherAmntMisc.length !== 0) {
            rxInfo.RxOtherAmount.OtherAmntMisc.map((value, i) => {
                if (!value["OtherAmntCatId"]) {
                    value["OtherAmntCatId"] = 1;
                }
                if (!value["Amount"]) {
                    value["Amount"] = 0;
                }
            });
        }
        if (systemData && systemData.DeliveryMode) {
            const oldData = this._rxStore.rxInfo$["source"]["value"];
            if(oldData && oldData["rxRelatedCodeInfo"] && oldData["rxRelatedCodeInfo"]["DeliveryModeCode"]){
                const codeUpdate = systemData.DeliveryMode.find((item: any) => item.Code === oldData["rxRelatedCodeInfo"]["DeliveryModeCode"]);
                oldData["rxRelatedCodeInfo"]["DeliveryModeCode"] = codeUpdate ? codeUpdate.Name : "";
            }

            this._rxStore.storeRxInfo(oldData);
            const deliverList = systemData.DeliveryMode.find((item: any) => item.Id === rxInfo.PrescReFill.DeliverModeId);
            if(rxInfo && rxInfo.rxRelatedCodeInfo && rxInfo.rxRelatedCodeInfo.DeliveryModeCode){
            rxInfo.rxRelatedCodeInfo.DeliveryModeCode = deliverList ? deliverList.Name : "";
            }
        }
        if (rxType === "nr") {
            rxInfo.RefillInsu.InsuPriId = 1;
        }
        return rxInfo;
    }

    async updateRxInfo(rxInfo: any, oldInfo?: any, forTransmit?: boolean,transmissionDetails: any = null,drugChanged?:boolean, isFrmFollowUpBilling?: any) {
        const oldData = oldInfo ? oldInfo : this._rxStore.rxInfo$["source"]["value"];
        let result = null;
        if(oldData && drugChanged && rxInfo["Prescription"]["PrescNum"] !== oldData["Prescription"]["PrescNum"] && (rxInfo["PrescReFill"]["ReFillNum"] === 0 &&  rxInfo["islatestrefill"] && rxInfo["isOriginalRx"] && (rxInfo["PrescReFill"]["PartialFillNo"] === 1 && rxInfo["islatestpartialrefill"]))){
            //const isEmpty = ( rxInfo && rxInfo["RxAdminDoseSchedule"]["RxAdmin"]["Id"]) ? () : 
            if ((rxInfo && rxInfo["RxAdminDoseSchedule"] && rxInfo["RxAdminDoseSchedule"]["RxAdmin"] && rxInfo["RxAdminDoseSchedule"]["RxAdmin"]["Id"]) && ((rxInfo["RxAdminDoseSchedule"]["RxAdmin"]["Id"] === 0) || (!rxInfo["RxAdminDoseSchedule"]["RxAdmin"]["Id"]))) {
                rxInfo["RxAdminDoseSchedule"]["RxAdmin"] = null;
            }
            rxInfo["rphverifDtTm"] = null;
            rxInfo["Prescription"]["OldPrescNum"]  = oldData["Prescription"]["PrescNum"];
            result = !forTransmit ? await this._rxServ.updateRxInfoWithDrugChange(rxInfo).toPromise() : rxInfo;
        
            if (result) {
                rxInfo["PrescReFill"]["Id"] = result["PrescRefillId"] ? result["PrescRefillId"] : rxInfo["PrescReFill"]["Id"];
                rxInfo["Prescription"]["Id"] = result["ESRxViewModel"] ? result["ESRxViewModel"]["Prescription"]["Id"] : rxInfo["Prescription"]["Id"];
                // if updateRxInfoWithDrugChange will be call , the PrescReFillId and PresNumber should be changes so storeRxinfo  should be updated.
                this._rxStore.storeLatestRxInfo(rxInfo);
            }

        }else{
            result = !forTransmit ? await this._rxServ.updateRxInfo(rxInfo).toPromise() : rxInfo;
        }
        this._cmmnServ.storePrescNum(null);
        if (oldData && oldData["PrescReFill"] && oldData["PrescReFill"]["FillDtTm"]) {
            oldData["PrescReFill"]["FillDtTm"] =  (oldData["PrescReFill"] && oldData["PrescReFill"]["FillDtTmCopy"] ) ?
                oldData["PrescReFill"]["FillDtTmCopy"] : oldData["PrescReFill"]["FillDtTm"];
        }
        if (oldData && oldData["PrescReFill"] && oldData["PrescReFill"]["OrderDtTm"]) {
            oldData["PrescReFill"]["OrderDtTm"] =  (oldData["PrescReFill"] && oldData["PrescReFill"]["OrderDtTmCopy"] ) ?
            oldData["PrescReFill"]["OrderDtTmCopy"] : oldData["PrescReFill"]["OrderDtTm"];
		}
        if ((!forTransmit && result && !isNaN(+result.RxNum) && +result.RxNum > 0 && oldData) || (forTransmit && oldData)) {
            this._commUtil.updateDashbordStatus();
            if (rxInfo && !rxInfo["RxFile"] && this._rxStore.rxInfo$["source"]["value"] && this.count >= 2) {
                this._rxStore.rxInfo$["source"]["value"]["RxFile"] = null;
            }
                if (false) {
                    let rxInfoResp: any;
                    rxInfoResp = await this._nrxUtls.getandStoreRxInfo(+rxInfo.Prescription.PrescNum,
                     rxInfo.PrescReFill.Id, false, null, null, null, true);
                    await this.saveRxAuditLog(oldData, rxInfoResp,isFrmFollowUpBilling);
                } else {
                    let rxInfoResp = rxInfo;
                    await this.saveRxAuditLog(oldData, rxInfoResp,isFrmFollowUpBilling);
                }
            this.count++;
        }
        if (!forTransmit) {
            return result;
        }
    }
    async saveRxAuditLog(oldRx, newRx,isFrmFollowUpBilling?: any) {
        if (oldRx && newRx && oldRx["PrescReFill"] && newRx["PrescReFill"]) {
            if (oldRx["PrescReFill"]["OrderDtTm"] && oldRx["PrescReFill"]["FillDtTm"] &&
            newRx["PrescReFill"]["OrderDtTmCopy"] && newRx["PrescReFill"]["FillDtTmCopy"]) {
                newRx["PrescReFill"]["FillDtTm"] = newRx["PrescReFill"]["FillDtTmCopy"];
                newRx["PrescReFill"]["OrderDtTm"] = newRx["PrescReFill"]["OrderDtTmCopy"];
                if (moment(oldRx["PrescReFill"]["OrderDtTm"]).format("MM/DD/YYYY") ===
                moment(newRx["PrescReFill"]["OrderDtTmCopy"]).format("MM/DD/YYYY")) {
                    oldRx["PrescReFill"]["OrderDtTm"] = newRx["PrescReFill"]["OrderDtTmCopy"];
                } else if (oldRx["PrescReFill"]["OrderDtTm"] !== newRx["PrescReFill"]["OrderDtTmCopy"]) {
                    oldRx["PrescReFill"]["OrderDtTm"] = oldRx["PrescReFill"]["OrderDtTm"] ? oldRx["PrescReFill"]["OrderDtTm"] : null;
                    newRx["PrescReFill"]["OrderDtTmCopy"] = newRx["PrescReFill"]["OrderDtTmCopy"] ?
                    moment(newRx["PrescReFill"]["OrderDtTmCopy"]).format("MM/DD/YYYY") : null;
                }
                if (moment(oldRx["PrescReFill"]["FillDtTm"]).format("MM/DD/YYYY") ===
                moment(newRx["PrescReFill"]["FillDtTmCopy"]).format("MM/DD/YYYY")) {
                    oldRx["PrescReFill"]["FillDtTm"] = newRx["PrescReFill"]["FillDtTmCopy"];
                } else if (oldRx["PrescReFill"]["FillDtTm"] !== newRx["PrescReFill"]["FillDtTmCopy"]) {
                    oldRx["PrescReFill"]["FillDtTm"] = oldRx["PrescReFill"]["FillDtTm"] ? oldRx["PrescReFill"]["FillDtTm"] : null;
                    newRx["PrescReFill"]["FillDtTmCopy"] = newRx["PrescReFill"]["FillDtTmCopy"] ?
                    moment(newRx["PrescReFill"]["FillDtTmCopy"]).format("MM/DD/YYYY") : null;
                }
            } else {
                if (moment(oldRx["PrescReFill"]["OrderDtTm"]).format("MM/DD/YYYY") ===
                moment(newRx["PrescReFill"]["OrderDtTm"]).format("MM/DD/YYYY")) {
                oldRx["PrescReFill"]["OrderDtTm"] = newRx["PrescReFill"]["OrderDtTm"];
            } else if (oldRx["PrescReFill"]["OrderDtTm"] !== newRx["PrescReFill"]["OrderDtTm"]) {
                oldRx["PrescReFill"]["OrderDtTm"] = oldRx["PrescReFill"]["OrderDtTm"] ? oldRx["PrescReFill"]["OrderDtTm"] : null;
                newRx["PrescReFill"]["OrderDtTm"] = newRx["PrescReFill"]["OrderDtTm"] ?
                moment(newRx["PrescReFill"]["OrderDtTm"]).format("MM/DD/YYYY") : null;
            }
            if (moment(oldRx["PrescReFill"]["FillDtTm"]).format("MM/DD/YYYY") ===
            moment(newRx["PrescReFill"]["FillDtTm"]).format("MM/DD/YYYY")) {
                oldRx["PrescReFill"]["FillDtTm"] = newRx["PrescReFill"]["FillDtTm"];
            } else if (oldRx["PrescReFill"]["FillDtTm"] !== newRx["PrescReFill"]["FillDtTm"]) {
                oldRx["PrescReFill"]["FillDtTm"] = oldRx["PrescReFill"]["FillDtTm"] ? oldRx["PrescReFill"]["FillDtTm"] : null;
                newRx["PrescReFill"]["FillDtTm"] = newRx["PrescReFill"]["FillDtTm"] ?
                moment(newRx["PrescReFill"]["FillDtTm"]).format("MM/DD/YYYY") : null;
                }
            }
            await this.updateAuditLog(oldRx, newRx,isFrmFollowUpBilling);
        }
    }

    async saveRxNdOtherInfo(rxInfo: RxModel, rxFG: FormGroup,type?,dmeClosed?) {
        const fileDetails = {
            "IsFromFile" : rxFG.value["IsFromFile"],
            DueDateTime  : (rxFG && rxFG.value && rxFG.value["RxFile"]) ? rxFG.value["RxFile"]["DueDtTm"] : "",
            Description : (rxFG && rxFG.value && rxFG.value["RxFile"]) ? rxFG.value["RxFile"]["DeferredReasonName"] : "",
            deferredReason: (rxFG && rxFG.value && rxFG.value["RxFile"]) ? rxFG.value["RxFile"]["DeferredReason"] : ""
        }
                 rxInfo["HOADetails"] = rxInfo["HOADetails"]["StartDate"] ? rxInfo["HOADetails"] : rxFG.value["HOADetails"];
        const resp: any = rxFG.value["isDetOldRx"] ?
                            await this._rxServ.deleteNewFile(rxFG.value["isDetOldRx"], rxFG.value["isDetOldRxRf"],
                             rxFG.value["PrescReFill"]["Id"], rxFG.value["isDetOldRxRfFill"], fileDetails).toPromise()
                            : await this._rxServ.saveRxInfo(rxInfo).toPromise();

        const intialCheck = (!rxFG.value["isDetOldRx"]) ? (resp && resp.Message !== "Refill Already Created" && resp &&  !isNaN(+resp.RxNum) && +resp.RxNum > 0) : (resp && resp !== "Refill Already Created" && resp &&  !isNaN(+resp.PrescNum) && +resp.PrescNum > 0);
        
        if (intialCheck) {
            if (rxInfo["erxDetailsInRx"]["erxId"] && rxInfo["rxType"] === "nr") {
                const erxObject = {"ErxId": rxInfo["erxDetailsInRx"]["erxId"],
                "ErxOpTransactionId": rxInfo["erxDetailsInRx"]["erxOpTransactionId"],
                "PatientId": rxInfo["Patient"]["patientid"],
                "PrescriberId": rxInfo["Prescriber"]["prescriberid"] ,
                 "DrugId": rxInfo["Drug"]["id"], "RxNum": rxInfo["Prescription"]["PrescNum"],
                "ErxOperationId": rxInfo["erxDetailsInRx"]["erxOperationId"]};
                let eRxVersion: any;
                const versionsetting = this._cmmnServ.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
                        if (versionsetting == 2) {
                            eRxVersion = ErxVersions.NewerVersion;
                        } else {
                            eRxVersion = ErxVersions.OldVersion;
                        }
                this.erxService.getProcessERxMessageLink(erxObject, eRxVersion).subscribe((res: any) => {});
            }
            // const resp2: any = await this._rxServ.getRefillInfo( rxFG.value["isDetOldRx"] ? resp.PrescNum : resp.RxNum).toPromise();
            if (rxInfo["rxType"] === "rnr") {
                if ( rxInfo.FaxRefillId && rxInfo.FaxRefillId > 0) {
                    await this._recallRxSer.updateFaxRefillStatus({ Id: rxInfo.FaxRefillId }).toPromise();
                } else {
                    const reclRx = {
                        PrescRefillId: (rxInfo && rxInfo.PrescReFill && rxInfo.PrescReFill.Id) ? rxInfo.PrescReFill.Id : null,
                        PrescNum: (rxInfo && rxInfo.Prescription && rxInfo.Prescription.Id) ? rxInfo.Prescription.Id : null,
                        ReFillNum: (rxInfo && rxInfo.PrescReFill && rxInfo.PrescReFill.ReFillNum) ?  rxInfo.PrescReFill.ReFillNum : null,
                        IsPrescnumExists: (rxInfo && rxInfo.IsPrescnumExists) ? true : false,
                        RxOriginId: rxInfo.PrescReFill.RxOriginId,
                        NewPrescNum:resp
                    };
                    await this._recallRxSer.deleteRxOnControlRx(reclRx).toPromise();
                }
            }
            const oldDataRefillData = this._rxStore.rxRefDet$ && this._rxStore.rxRefDet$["source"] ? this._rxStore.rxRefDet$["source"]["value"] : null;
            if(rxInfo["rxType"]=== "rf" && oldDataRefillData && oldDataRefillData["Refdetail"]  && oldDataRefillData["Refdetail"]["Refilldetails"]) {
                const edtRxAudtMdl = new RxAuditLog().rxAuditModelForRefill;
                this._nwAudtUtl.saveAuditChange(
                    oldDataRefillData["Refdetail"]["Refilldetails"][0],
                    rxInfo,
                    "EditRx",
                    "Rx",
                    resp.PrescRefillId,
                    edtRxAudtMdl
                );
            }
            rxFG.controls.Prescription.patchValue({
                PrescNum: rxFG.value["isDetOldRx"] ? resp.PrescNum : resp.RxNum,
                Id: (resp && resp.rxId) ? resp.rxId : null,
                PatientId: (resp && resp.patientId) ? resp.patientId : null
            });
            rxFG.controls.PrescReFill.patchValue({ Id: resp.PrescRefillId });
            rxFG.controls.Patient.patchValue({ PatientId: resp.patientId });
            rxInfo = rxFG.getRawValue();
            await this.saveRxOtherTabInfo(rxInfo,type,dmeClosed,resp);
            return rxFG;
        } else {
            return resp;
        }
    }

    async saveRxOtherTabInfo(rxInfo: RxModel,type,dmeClosed?, saveRxData?) {
        let alreadyRxDME = false;
        const resp =  (saveRxData && saveRxData.ESRxViewModel) ? saveRxData.ESRxViewModel : await this._nrxUtls.getandStoreRxInfo(+rxInfo.Prescription.PrescNum, rxInfo.PrescReFill.Id) ;
        if(saveRxData && saveRxData.ESRxViewModel)
              this._rxStore.storeRxInfo(saveRxData.ESRxViewModel);
        if(resp){
            if(resp && resp["Drug"] && resp["Drug"].drugtypeid && resp["Drug"].drugtypeid == 5 && rxInfo["rxType"]== "nr" && type !== "TrnsmtPatent" && !dmeClosed){
                const rxDME = this._rxStore.rxDME$["source"]["value"];
                alreadyRxDME = true;
                this._rxServ.saveDMEdata(rxDME, rxInfo["Prescription"]["Id"]).subscribe(resp => {
                });
            } else {
                alreadyRxDME = false;
            }
        }
        // this.mapRxTempProprAndPatch(rxInfo, resp);
        const saveRxEnd = [];
        let saveRxEXtraValues: any;
        const wcom = this._rxStore.WCOMInfo$["source"]["value"];
        const rxDME = this._rxStore.rxDME$["source"]["value"];
        const rxDur = this._rxStore.rxDURInfo$["source"]["value"];
        const miscInfo = this._rxStore.rxMiscInfo$["source"]["value"];
        const rxFolupTag = this._rxStore.rxFollupTag$["source"]["value"];
        const rxTransfrI = this._rxStore.rxTransfrIn$["source"]["value"];
        const compIng = this._rxStore.compoundData$["source"]["value"];
        const durDataNewRxFlow = this._rxStore.addDur$["source"]["value"];
        const vacnImmuneDet = this._rxStore.vacnImmune$["source"]["value"];
        const documents = rxInfo.Documents;
        let xRfInfoP = null;

        if (wcom) {
            if (wcom["rxExtra"]) {
                wcom["rxExtra"]["rxOriginId"] = rxInfo.PrescReFill.RxOriginId;
            }
            wcom["WorkmanComp"]["RxId"] = rxInfo.Prescription.Id;
            saveRxEnd.push(this._rxServ.saveWCOMinfo(wcom));
        }

        if (rxDME && !alreadyRxDME) {
            rxDME["RxId"] = rxInfo.Prescription.Id;
            saveRxEnd.push(this._rxServ.saveDMEdata(rxDME, rxInfo.Prescription.Id));
        }
        if (rxDur) {
            saveRxEnd.push(this._rxServ.addDURValues(rxDur));
        }
        if (vacnImmuneDet) {
            vacnImmuneDet.PatientId = rxInfo.Patient.patientid;
            vacnImmuneDet.PrescRefillId = rxInfo["PrescReFill"]["Id"];
            vacnImmuneDet.PrescNum =  rxInfo["Prescription"]["PrescNum"];
            vacnImmuneDet.RefillNum = rxInfo["PrescReFill"]["ReFillNum"];
            vacnImmuneDet.FillNo = rxInfo["PrescReFill"]["PartialFillNo"];
            const payLoad = {
                VaccineImmunization: vacnImmuneDet
              }
            saveRxEnd.push(this._cmmnServ.updateVaccineImmunization(payLoad));
        }
        if (miscInfo) {
            miscInfo.rxExtra.PrescRefillId = rxInfo.PrescReFill.Id;
            miscInfo.prescInfo.PrescRefillId = rxInfo.PrescReFill.Id;
            miscInfo.couponInfo.PrescRefillId = rxInfo.PrescReFill.Id;
            miscInfo.diagCode.map((dcode) => {
                dcode.PrescRefillId = rxInfo.PrescReFill.Id;
            });
            miscInfo.diagCode.map((dcode) => {
                dcode.PrescRefillId = rxInfo.PrescReFill.Id;
            });
            miscInfo.procModifier.map((procM) => {
                procM.PrescRefillId = rxInfo.PrescReFill.Id;
            });
            miscInfo.rxExtra.Id = null;
            miscInfo.PrescriptionId = rxInfo.Prescription.Id;
         //   saveRxEnd.push(this._rxServ.SaveRxExtraValues(rxInfo.PrescReFill.Id, miscInfo));
        } else {
            const otherValFG = this._fb.group({
                rxExtra: this._fb.group(new RxExtra()),
                prescInfo: null,
                couponInfo: null,
                diagCode: [],
                procModifier: [],
                PrescriptionId: null
            });
            otherValFG.controls["rxExtra"].patchValue({PrescRefillId: rxInfo.PrescReFill.Id,
                 rxOriginId: rxInfo.PrescReFill.RxOriginId, IsPatAssignInd: saveRxData["isPatIndicator"],
                  TreatmentTypeId: saveRxData.TreatmentTypeId});
            }

        if (rxFolupTag) {
            rxFolupTag["PrescNum"] = rxInfo.Prescription.PrescNum;
            rxFolupTag["PrescRefillId"] = rxInfo.PrescReFill.Id;
            rxFolupTag["RefillNum"] = rxInfo.PrescReFill.ReFillNum;
            rxFolupTag["PartialfillNum"] = rxInfo.PrescReFill.PartialFillNo;
            if (rxFolupTag.PrescRefill_RxTags.length > 0) {
                rxFolupTag.PrescRefill_RxTags.map(val => {
                    val.PrescRefillId = rxInfo.PrescReFill.Id;
                });
            }
            saveRxEnd.push(this._rxServ.saveRxFollowUpValues(rxFolupTag));
        }
        if (rxTransfrI) {
            if (rxInfo.PrescReFill.StatusId === 2 || rxInfo.Prescription.RxStatusId === 2) {
                rxTransfrI["IsFiledRx"] = true;
            }
            rxTransfrI["prescnumid"] = rxInfo.Prescription.PrescNum;
            if (rxTransfrI["IsFmPrint"]) {
                xRfInfoP = {FromPrescRefillId: rxInfo.PrescReFill.Id,
                transferType: "TransferIn", RxNum: rxInfo.Prescription.PrescNum};
            }
            rxTransfrI.PrescRefillId = rxInfo.PrescReFill.Id;
            saveRxEnd.push(this._rxTrnsfrServ.postRxTransferInOut(rxTransfrI));
        }

        if (durDataNewRxFlow) {
                const  payload = {
                    prescriptionNo : rxInfo.Prescription.Id,
                    prescrefillId : rxInfo.PrescReFill.Id,
                    durInfoList : durDataNewRxFlow
                }
            saveRxEnd.push(this._rxServ.addDURValues(payload));
            this._rxStore.storeDurNewRxFlow(null);
        }
        if (documents && documents.length > 0) {
            let docQueuedoc = [];
            let normalDocs = [];
            documents.forEach(elm => {
                if (elm['UniqueDocumentId']) {
                    elm["FillRx"] = +rxInfo.Prescription.PrescNum;
                    docQueuedoc.push(elm);
                } else {
                    normalDocs.push(elm);
                }
            });
            if (normalDocs && normalDocs.length) {
                let dataToPost = {
                    prescNum: rxInfo.Prescription.PrescNum,
                    documentIds: normalDocs.map((a) => a.Id),
                };
                saveRxEnd.push( this._docService.attachDocToRx(dataToPost) );
            }
            if (docQueuedoc && docQueuedoc.length) {
                saveRxEnd.push(this._dmsSer.attachDocToRxFromDocQueue(docQueuedoc));
                if (sessionStorage.getItem("DocQueuDocs")) {
                    let docQueuSessionDocs = JSON.parse(sessionStorage.getItem("DocQueuDocs"));
                    if (docQueuSessionDocs && docQueuSessionDocs.length) {
                        docQueuedoc.map(docElm => {
                            let foundIndex = docQueuSessionDocs.findIndex(sessnDoc => sessnDoc.Id === docElm.Id);
                            if (foundIndex > -1) {
                                docQueuSessionDocs[foundIndex]["docUsed"] = true;
                            }
                        });
                        sessionStorage.setItem("DocQueuDocs", JSON.stringify(docQueuSessionDocs));
                    }
                }
            }
        }

        if (compIng) {
            compIng["list"].map(val => val = Object.assign(val, {PrescReFillId: rxInfo.PrescReFill.Id, Id: null}));
            const dataToPost = {
                list: compIng["list"],
                note: compIng["note"],
                EffortLvlId: compIng["EffortLvlId"],
                DispQty: compIng["DispQty"],
                BeyondUseDays: compIng["BeyondUseDays"],
                TimeToMake: compIng["TimeToMake"],
                PrescReFillId: rxInfo.PrescReFill.Id,
                RemoveDrugIngredientsIds: "",
                IsForRx: true,
                CompDrugId: rxInfo.Drug.id
            };
            saveRxEnd.push(this._drugServ.AddIngredientDrug(dataToPost));
            this._rxStore.storeCompoundData(null);
        }

        if (saveRxEnd && saveRxEnd.length > 0) {
            await forkJoin(saveRxEnd).toPromise();
            if (xRfInfoP) {
                const presp = await this._rxTrnsfrServ.printReport(xRfInfoP).toPromise();
                if (presp && presp.size > 0 && presp.type === "application/pdf") {
                    await this.printReport(presp);
                }
            }
        }
    }

    printReport(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printser.printPdf(base64data);
        }.bind(this);
    }


    saveAuditLog(rxType, rxInfo: RxModel, PrescReFillId) {
        const rxModel = new RxAuditLog().rxAuditModel;
        this._nwAudtUtl.saveAuditOnAdd(
            rxInfo,
            (rxType === "nr" || rxType === "nrf") ? "New Rx" : "Rx",
            (rxType === "nr" || rxType === "nrf") ? "Rx" : "Refill Rx",
            PrescReFillId,
            rxModel
        );
    }

    updateAuditLog(oldRxInfo, rxInfo: RxModel,isFrmFollowUpBilling?: any) {
        const edtRxAudtMdl = new RxAuditLog().rxAuditModel;
        this._nwAudtUtl.saveAuditChange(
            oldRxInfo,
            rxInfo,
            "EditRx",
            "Rx",
            rxInfo.PrescReFill.Id,
            edtRxAudtMdl,
            null,
            null,
            null,
            isFrmFollowUpBilling
        );

    }

    isFiledBilledPending(rxFG) {
        if (rxFG) {
            const rxInfo = rxFG.getRawValue();
            return (!rxFG.valid || rxInfo.RxBill.StatusId === 2 || rxInfo.PrescReFill.StatusId === 4 ||
                rxInfo.Prescription.RxStatusId === 4 || (
                rxInfo.PrescReFill.GroupTransStatus === "Pending" ||
                    rxInfo.PrescReFill.GroupTransStatus === "Completed" ||
                    rxInfo.PrescReFill.GroupTransStatus === "Transmitting"));
        }
    }

    isFiledPending(rxFG) {
        const rxInfo = rxFG.getRawValue();
        return (!rxFG.valid || rxInfo.PrescReFill.StatusId === 4 ||
            rxInfo.Prescription.RxStatusId === 4 || (
            rxInfo.PrescReFill.GroupTransStatus === "Pending" ||
                rxInfo.PrescReFill.GroupTransStatus === "Completed" ||
                rxInfo.PrescReFill.GroupTransStatus === "Transmitting"));
    }

    isNtValdRxNdPending(rxFG) {
        const rxInfo = rxFG.getRawValue();
        return !rxFG.valid ||
            rxInfo.PrescReFill.GroupTransStatus === "Pending" ||
            rxInfo.PrescReFill.GroupTransStatus === "Completed" ||
            rxInfo.PrescReFill.GroupTransStatus === "Transmitting";
    }

    isFillNtNwFiledPend(rxFG) {
        const rxInfo = rxFG.getRawValue();
        return !rxFG.valid || rxInfo.RxBill.StatusId === 2 ||
            rxInfo.PrescReFill.GroupTransStatus === "Pending" ||
            rxInfo.PrescReFill.GroupTransStatus === "Completed" ||
            rxInfo.PrescReFill.GroupTransStatus === "Transmitting" ||
            rxInfo.isNewRxFile ||
            (rxInfo.rxType === "rf" && !(rxInfo.islatestrefill && rxInfo.islatestpartialrefill)) ||
            (rxInfo.rxType === "er" && !(rxInfo.islatestrefill && rxInfo.islatestpartialrefill)) || rxInfo.fileToFill;
    }

    mapRxTempProprAndPatch(oldRx, newRx: RxModel) {
        ["rxType", "routeFrom", "isPatHisEnbl", "patPopCompl", "prescPopCompl",
        "drugPopCompl", "dailyDose", "rxActnType", "isQuickVerf", "isNewRxFile"].map(x =>{
            newRx[x] = oldRx[x];
        });
        delete newRx.Patient["Diagnosis"];
        this._nrxUtls.createPatchStoreRxFG(newRx);
    }

    clearAllRxRelatedInfo() {
        this._rxStore._transmissionDataBS$.next(null);
        this._rxStore._rxInfoBS$.next(null);
        this._rxStore._marInfo$.next(null);
        this._rxStore._patSpecfcPrescbr$.next(null);
        this._rxStore._defltValByDrug$.next(null);
        this._rxStore._edtDrugInfo$.next(null);
        this._rxStore._drugInsRestr$.next(null);
        this._rxStore._drugInsPopDat$.next(null);
        this._rxStore._patDemoGrph$.next(null);
        this._rxStore._drugInterctn$.next(null);
        this._rxStore._simlrFildRxs$.next(null);
        this._rxStore._morePatntInfo$.next(null);
        this._rxStore._compoundData$.next(null);
        this._rxStore._isCashRx$.next(null);
        this._rxStore._authRefl$.next(null);
        this._rxStore._rxMiscInfo$.next(null);
        this._rxStore._rxDURInfo$.next(null);
        this._rxStore._WCOMInfo$.next(null);
        this._rxStore._rxDME$.next(null);
        this._rxStore._rxFollupTag$.next(null);
        this._rxStore._rxTransfrIn$.next(null);
        this._rxStore.storeGenrcEquvlntDrugs(null);
        this._rxStore._ACBScore$.next(null);
        this._rxStore._vacnImmune$.next(null);
        // this._rxStore._rxBucket$.next(null);
    }

    async clerAllNwRxInfo(rxInfo: RxModel, systemData?: any) {
        const defRxStart = this._nrxUtls.getSettingValue("RxSettings", "START_NEW_RX_FROM");
        await this.clearAllRxRelatedInfo();
        const rxFG = await this._nrxUtls.createPatchStoreRxFG();
        this._rxStore.StoreRxFG(rxFG);
        if (+defRxStart !== 2) {
            const patData = await this._cmmnServ.getRxPatientDetailsById(rxInfo.Patient.patientid).toPromise();
            if (patData) {
                rxFG.controls["Patient"].patchValue(patData);
            }
            rxFG.controls["patPopCompl"].setValue("Completed");
            rxFG.controls["patCompl"].setValue(null);
            rxFG.controls["check340BP"].patchValue(true);
            let insData = null;
            if (systemData && patData) {
                const insureList = await this._nrxUtls.getSortedInusuDtls(patData.insurance);
                insData = await this._nrxUtls.getDefaultInsuranceInfo(patData, insureList, systemData);
                if (insData && insData.insuranceid) {
                    const refilInsu = rxFG.controls["RefillInsu"] as FormGroup;
                    refilInsu.controls["InsuId"].setValue(+insData.insuranceid);
                    let insuStngresp: any;
                    const insuSetObservableData = this._insuServ.insuSettings$["source"]["value"];
                    if (insuSetObservableData && insuSetObservableData.InsuPlanOther &&
                        insuSetObservableData.InsuPlanOther.InsuPlanId && insuSetObservableData.InsuPlanOther.InsuPlanId
                         === +insData.insuplanId) {
                            insuStngresp =  insuSetObservableData;
                    } else {
                        insuStngresp =  await this._nrxUtls.getInsuSettbyPlanId(+insData.insuplanId);
                    }
                    await this._rxbilUitl.getInsuranceManualCalc(rxFG, insuStngresp);
                }
                const isCsh = this._rxbilUitl.checkIsCahsRx(rxFG.getRawValue(), patData, systemData);
                this._rxStore.storeIsCashRx(isCsh);
            }
        }
        const rxType = rxFG.get("rxType").value;
        const isERx = this._nrxUtls.isFromErx(rxFG.getRawValue());
        if (rxType === "nr" && !isERx) {
            this._nrxUtls.patchDefaultValues(rxFG);
            this._nrxUtls.makeRequirdFildsBasedOnSettings(rxFG, this._cmmnServ.systemSettings$["source"]["value"]);
        }
        if (+defRxStart === 1) {
            rxFG.controls["Prescriber"].patchValue(rxInfo.Prescriber);
            rxFG.controls["Prescription"].patchValue({PrescriberAddressId: rxInfo.Prescription.PrescriberAddressId});
            rxFG.controls["prescPopCompl"].setValue("Completed");
            rxFG.controls["prescCompl"].setValue(null);
            rxFG.controls["isPatHisEnbl"].setValue(true);
            rxFG.controls["check340BPr"].patchValue(true);
            if (this._nrxUtls.getSetngKeyVal("RxSettings", "DEF_PAT_LAST_PRESCRIBER")) {
                const resp = await this._cmmnServ.getLatestPrescriber(rxInfo.Patient.patientid).toPromise();
                this._rxStore.storePatSpecPrescDels(resp);
            }
        }
        this._rxStore.StoreRxFG(rxFG);
    }

    async clearNewRxDataFrEdit(value) {
        const rxFG = this._rxStore.rxFG$["source"]["value"];
        if (rxFG && rxFG.controls) {
            rxFG.controls["closeBillDet"].patchValue(value);
        }
    }

    clearAllEdtRxInfo() {
        this.clearAllRxRelatedInfo();
        this._rxStore._rxFGBS$.next(null);
    }
}
