<div class="row">
    <div class="icon padding-0">
        <img src="assets/dist/Icons/Icon_rx2.png" />
    </div>
    <div class="col-md-6 padding-0">
        <div class="content--heading__title">
            {{ headingData }}
            <span class="content--name" *ngIf="drugId">
                | &nbsp; {{ drugInfo?.Name | uppercase}}
                           {{ drugInfo?.Strength ? drugInfo?.Strength : "" | uppercase  }}
                           {{ drugInfo?.MeasureUnitName ? drugInfo?.MeasureUnitName : "" | uppercase }}
                           {{ drugForm ? drugForm : "" | uppercase}} </span>
            <span class="content--name" *ngIf="!drugId"> | &nbsp; Add New Drug </span>
        </div>
    </div>
    <div class="col-md-5 edit-patient--group--icon padding-0">
        <div class="modal-icon">
            <div class="insurance--group--icon padding-0">
                <ul class="drug--icons">
                    <span class="icons--padding">
                        <i class="far fa-pen-square" title="Summary" aria-hidden="true" (click)="openPopupModal('sales')" *ngIf="drugId"></i>
                    </span>
                    <span class="icons--padding">
                        <i class="far fa-copy" title="Copy Info From Another Drug" aria-hidden="true" (click)="copyPopUp(COPY)"></i>
                    </span>
                    <span class="icons--padding">
                        <i class="far fa-sync-alt" title="Refresh Data from MMS" (click)="drugInfoData(MMS)"></i>
                    </span>
                    <span class="icons--padding">
                        <i class="far fa-search" title="Search All NDC OCC" (click)="openPopupModal('NDCOOC')" *ngIf="drugId"></i>
                    </span>
                    <span class="icons--padding headerImage" *ngIf="drugId">
                        <img src="assets/dist/Icons/icon_pills_white.svg" title="Equivalent Drug" class="headerimg" (click)="getEquivalentDrugInfo(equiDrugContent)"
                        />
                    </span>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #COPY let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Search</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <div class="exprx--common-block">
            <div>
                <div class="seconday-search--grid">
                    <div class="row">
                        <div class="col-4 color_cyan">
                            Search Drug</div>
                        <div class="col-8">
                            <div class="text-right">
                                <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for Equv ;
                                    </span><b class="color_orange">Shift + <i class="far fa-lg fa-long-arrow-alt-right color_orange"
                                           ></i></b><span> for Gen Equv</span></div>
                            </div>
                        </div>
                    </div>
                    <app-searchbox-global #drugSearch [IsFocused]="isDrugSearchFocused"
                        (FocusedOutofSearch)="isFocusedOutofSearch($event)" [SearchFor]="'Drug'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedDrug)="copyDrugAlert(alert, $event)">
                    </app-searchbox-global>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" class="hotkey_success" (click)="d('Cross click')"
            (keydown.enter)="d('Cross click')" appShortcutKey [AltKey]="'c'">
               <span>C</span> Cancel
            </button>
        </div>
    </div>
</ng-template>

<ng-template #MMS let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Search MMS Data</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="closeModal();closeModalFocus()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <div class="exprx--common-block">
            <div class="eprx--block__header">
                <div class="row">
                    <div class="col">
                        <span class="eprx--header__heading">
                            Drug MMS Data
                        </span>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content">
                    <form [formGroup]="drugSearchFG">
                    <div class="col-sm-12 padding-0 erx--body">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <eprx-input [AutoFocus]="true" [LabelText]="'Drug Name'" [PlaceHolder]="''" [ControlName]="'DrugName'" [FormGroupName]="drugSearchFG"
                                    (TriggerOnEnterValue)="searchMMS(MMS)">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <eprx-input [LabelText]="'NDC'" [PlaceHolder]="''" [ControlName]="'NDCCode'" [FormGroupName]="drugSearchFG"
                                    (TriggerOnEnterValue)="searchMMS(MMS)">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-button allign-bottom text-right">
                                    <button type="submit" class="hotkey_success pull-right"  (click)="show()" (click)="searchMMS(MMS)" appShortcutKey [AltKey]="'f'">
                                        <span>F</span>Search
                                    </button>
                                    <button type="button" class="hotkey_success pull-right" (keydown.tab)="focusOndrugMMSGrid($event)" (click)="clearSearchData()" appShortcutKey [AltKey]="'l'">
                                        <span>L</span>Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
            </div>
            <div class="eprx--block__content" *ngIf="hasMMSData">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <wj-flex-grid #DrugMMS [headersVisibility]="'Column'"  [itemsSource]="mmsDataWJ" [isReadOnly]="true" [columnPicker]="''"
                        (updatedView)="init(DrugMMS)" (keydown.enter)="keyEventOnMMSWijimo(MMSData, Warning, $event, DrugMMS)" (keydown)="cancelEventOnMMSGrid($event, 'MMS')"
                        [selectionMode]="'Row'" class="max-height_35rem" >
                            <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="
                                    let actvHeader of actvHeaders;
                                    let i = index
                                ">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div *ngIf="!(actvHeader === 'Select')" [title] ="item[actvHeader] |  uppercase">
                                        {{ item[actvHeader] }}
                                    </div>
                                    <div class="text-center" *ngIf="actvHeader === 'Select'" [title] ="item[actvHeader] |  uppercase">
                                        <span class="refresh--button">
                                            <i class="far fa-file-import" title="Select" (click)="
                                                    selectMMSData(item.Select)
                                                " (click)="
                                                    generateNewData(MMSData, Warning, 1)
                                                "></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="inActvHeader" [binding]="inActvHeader" [visible]="false" *ngFor="
                                    let inActvHeader of inactvHeader;
                                    let i = index
                                " [format]="'d'">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                </div>
                <app-eprx-pagination [TotalCount]="selectedDrugMMs.length" [PageSize]="Size" [WijmoName]="DrugMMS" [GridName]="'DrugMMS'" (OnPageSelected)="setPage($event)"
                (OnSizeSelected)="setSize($event)" (OnFocusOut)="focusOnMMSCancel($event)" *ngIf="SearchMMSLength > 0" [FromModal]="true"></app-eprx-pagination>
        </div>
        <div *ngIf="SearchMMSLength === 0" class="eprx--block__footer">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" id="mmscancelbu" class="hotkey_success" tabindex="5" (click)="closeModal(); closeModalFocus()"
            (keydown.enter)="closeModal(); closeModalFocus()" appShortcutKey [AltKey]="'c'">
                <span>C</span>Cancel
            </button>
        </div>
    </div>
</ng-template>

<ng-template #MMSData let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Update Drug From MMS Data</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug editpatient--body newrx--body">
        <div class="exprx--common-block editpatient">
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class=" content--heading">
                            <span class="content--name">DRUG INFORMATION</span>
                        </div>
                        <wj-flex-grid #DrugInfoMMS [headersVisibility]="'Column'"(updatedView)="refreshInit(DrugInfoMMS)"
                        [selectionMode]="'Row'" (keydown)="cancelEventOnMMSGrid($event, 'REFRESH')" [itemsSource]="refreshListToModify" [isReadOnly]="true"
                        (keydown.space)="spaceEventOnWijimo($event, 1, DrugInfoMMS)"
                            *ngIf="refreshListToModify" [selectionMode]="'None'" class="max_height_70vh">
                            <wj-flex-grid-column [header]="'HEADING'" [width]="200" [binding]="'head'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'CURRENT VALUE'" [width]="350" [binding]="'currentvalue'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'MMS DATA VALUE'" [width]="350" [binding]="'MMsvalue'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'USE THIS'" [width]="300" [binding]="'link'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <span class="text-center">
                                        <input class="form-check-input" type="checkbox" *ngIf="item?.link" [id]="item.link" name="" [(ngModel)]="item.IsSelected"
                                            (change)="
                                                refreshMMSData(
                                                    item,
                                                    1,
                                                    $event.target.checked
                                                )
                                            " [checked]="item.IsSelected" />
                                        <label class="form-check-label" *ngIf="item?.link" [for]="item.link">
                                        </label>
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                        <div class=" content--heading">
                            <span class="content--name">DRUG COUNSELLING</span>
                        </div>
                        <wj-flex-grid #MiscInfo (keydown)="cancelEventOnMMSGrid($event, 'REFRESH')" [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="refreshDrugCoun" [isReadOnly]="true"
                            *ngIf="refreshDrugCoun" (keydown.space)="spaceEventOnWijimo($event, 2, MiscInfo)">
                            <wj-flex-grid-column [header]="'HEADING'" [width]="200" [binding]="'head'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'CURRENT VALUE'" [width]="350" [binding]="'currentvalue'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'MMS DATA VALUE'" [width]="350" [binding]="'MMsvalue'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'USE THIS'" [width]="300" [binding]="'link'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <span class="text-center">
                                        <input class="form-check-input" type="checkbox" *ngIf="item?.link" [id]="item.link" name="" [(ngModel)]="item.IsSelected"
                                            (change)="
                                                refreshMMSData(
                                                    item,
                                                    2,
                                                    $event.target.checked
                                                )
                                            " [checked]="item.IsSelected" />
                                        <label class="form-check-label" *ngIf="item?.link" [for]="item.link">
                                        </label>
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                        <div class=" content--heading">
                            <span class="content--name">MISCELLANOUS INFORMATION</span>
                        </div>
                        <wj-flex-grid #MMSdatas (keydown)="cancelEventOnMMSGrid($event, 'REFRESH')"  [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="refreshDrugMisc" [isReadOnly]="true"
                            *ngIf="refreshDrugMisc" (keydown.space)="spaceEventOnWijimo($event, 3, MMSdatas)">
                            <wj-flex-grid-column [header]="'HEADING'" [width]="200" [binding]="'head'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'CURRENT VALUE'" [width]="350" [binding]="'currentvalue'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'MMS DATA VALUE'" [width]="350" [binding]="'MMsvalue'">
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'USE THIS'" [width]="300" [binding]="'link'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <span class="text-center">
                                        <input class="form-check-input" type="checkbox" *ngIf="item?.link" [id]="item.link" name="" [(ngModel)]="item.IsSelected"
                                            (change)="
                                                refreshMMSData(
                                                    item,
                                                    3,
                                                    $event.target.checked
                                                )
                                            " [checked]="item.IsSelected" />
                                        <label class="form-check-label" *ngIf="item?.link" [for]="item.link">
                                        </label>
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" class="hotkey_success"
            (click)="d('Cross click')" (click)="updatePopUp(1, warning)"
            (keydown.enter)="updatePopUp(1, warning); d('Cross click')" appShortcutKey [AltKey]="'u'">
               <span>U</span> Update
            </button>
            <button type="button" class="hotkey_success"
            (click)="d('Cross click');" (keydown.tab)="focusOnRefreshMMSGrid($event)" (keydown.enter)="d('Cross click');" appShortcutKey [AltKey]="'c'">
              <span>C</span>  Cancel
            </button>
        </div>
    </div>
</ng-template>

<ng-template #equiDrugContent class="el--popup" let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Equivalent Drugs</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <wj-flex-grid #equiDrugs [isReadOnly]="true" [itemsSource]="equiWJ" [allowSorting]="false" [headersVisibility]="'Column'"
            (initialized)="init(equiDrugs)" [selectionMode]="'None'">
            <wj-flex-grid-column [header]="'Drug Name'" [width]="'5*'" [binding]="'drugname'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Strength'" [width]="'5*'" [binding]="'strength'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'NDC'" [width]="'5*'" [binding]="'drugcode'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'AWP'" [width]="'5*'" [binding]="'unitpriceawp'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Cost'" [width]="'5*'" [binding]="'unitpricecost'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Dg. Mfg'" [width]="'5*'" [binding]="'manufacturername'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Pkg. Size'" [width]="'5*'" [binding]="'qtypack'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Qty-Pack'" [width]="'5*'" [binding]="'qtypack'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Preferred'" [width]="'5*'" [binding]="'ispreferred'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    {{ item["ispreferred"] }}
                </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
    </div>
</ng-template>

<ng-template #warning let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">Are you sure you want to update?</div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button autofocus type="button" class="hotkey_primary"
            (click)="d('Cross click')" (click)="updateDrug()"
            (keydown.enter)="updateDrug(); c('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES
            </button>
            <button type="button" class="hotkey_primary" (click)="d('Cross click');"
            (keydown.enter)="c('Cross click');" appShortcutKey InputKey="n">
              <b>N</b> NO
            </button>
        </div>
    </div>
</ng-template>

<ng-template #Warning let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">Are you sure you want to add information from MMS?</div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" autofocus class="hotkey_primary"
            (click)="c('Cross click')" (click)="addNewDrugFrmMMS()"
            (keydown.enter)="addNewDrugFrmMMS(); c('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES
            </button>
            <button type="button" class="hotkey_primary" (click)="c('Cross click')"
            (keydown.enter)="c('Cross click')" appShortcutKey InputKey="n">
               <b>N</b> NO
            </button>
        </div>
    </div>
</ng-template>

<ng-template #alert let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="drugId">
        Are you sure you want to copy {{ copiedDrugName }} to {{ drugName }} ?
    </div>
    <div class="modal-body" *ngIf="!drugId">
        Are you sure you want to copy {{ copiedDrugName }} information to new Drug ?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" autofocus class="hotkey_primary" (click)="copyDrugInfo()"
            (click)="d('Cross click')" (keydown.enter)="c('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES
            </button>
            <button type="button" class="hotkey_primary" (click)="d('Cross click')"
            (keydown.enter)="c('Cross click')" appShortcutKey InputKey="n">
               <b>N</b> NO
            </button>
        </div>
    </div>
</ng-template>
<app-drug-ndcocc *ngIf="(activeModal === 'NDCOOC')"  [DrugId]="drugId" [DrugCode]="drugInfo.DrugCode" (IsPopUpClosed)="openPopupModal($event)"></app-drug-ndcocc>
<app-drug-sales-summary *ngIf="(activeModal === 'sales')" [DrugId]="drugId" [DrugCode]="drugInfo.DrugCode" [DrugName]="drugInfo.Name" (IsPopUpClosed)="openPopupModal($event)"></app-drug-sales-summary>


