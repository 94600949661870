import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, NgZone, ChangeDetectionStrategy } from "@angular/core";
import { RxStore } from "src/app/store";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { PopupsCommonLogicUtil } from "src/app/utils/popups-common-logic.util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonWarnorconfirmPopupComponent } from "../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";

@Component({
  selector: "app-ed-rx",
  templateUrl: "./ed-rx.component.html"
})
export class EdRxComponent implements OnInit {
    edrxFGBS$: Observable<FormGroup>;
    @Input() FiledRx: any;
    @Input() SelectedValue: boolean;
    drugInfoFromRecall: any;
    @Input()
    set drugInfoFrmRecall(val) {
        if (val) {
            this.drugInfoFromRecall = val;
        }
    }
    @Input() errorEditInfo:any;
    @Input() rxFG:any;
    @Input() isFromRecall: boolean;
    @Input() isFromCntrlFileErr: boolean;
    @Input() isFromLargeValues: boolean;
    @Input() largerValuesForDisplay: any;
    @Input() PatientId:any;
    @Input() IsFromRxHistory:any
  
    @Input() OpenReverse: any;
    @Input() openFrom:any;
    @Input() rejectedTransRevsInfo: any;
    
    @Output() CloseEditMode = new EventEmitter<string>();
    @Output() CloseAndReopen = new EventEmitter<any>();
    modalRef: any;
    constructor(private _rxStore: RxStore, 
        private _mdlServ: NgbModal,
        public zone: NgZone,
        private _cdr: ChangeDetectorRef,
        private _popupLogics: PopupsCommonLogicUtil) {
        this.edrxFGBS$ = this._rxStore.edrxFGBS$;
    }

    async ngOnInit() {
        this._rxStore.priceCalculationsForSetting(false);
    }

    CloseEitRxPopUp(val, type?: any) {
       if (type) {
            this.SelectedValue = val.isNPPatientValue;
            this.CloseAndReopen.emit(val);
        } else if (val === "cancel") {
            this.CloseEditMode.emit("cancel");
        } else {
            this.CloseEditMode.emit("Refresh");
        }
    }

}
