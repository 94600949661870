export const LabelPageSetup = [
    {
        Name: "Zebra Thermal(4.00 Inch * 8.50 Inch)",
        Id: 0,
        Width: 1200,
        Height: 2550,
        PrintWidth: 4.00,
        PrintHeight: 8.50,
        IsLandscape: false
    },
    {
        Name: "Zebra Thermal(3.80 Inch * 8.50 Inch)",
        Id: 1,
        Width: 1140,
        Height: 2550,
        PrintWidth: 3.80,
        PrintHeight: 8.50,
        IsLandscape: false
    },
    {
        Name: "Zebra Thermal(8.50 Inch * 4.00 Inch)",
        Id: 2,
        Width: 2550,
        Height: 1200,
        PrintWidth: 8.50,
        PrintHeight: 4.00,
        IsLandscape: true
    },
    {
        Name: "Zebra Laser(5.25 Inch * 8.25 Inch)",
        Id: 3,
        Width: 1575,
        Height: 2475,
        PrintWidth: 5.25,
        PrintHeight: 8.25,
        IsLandscape: false
    },
    {
        Name: "US Letter Laser(8.50 Inch * 11 Inch)",
        Id: 4,
        Width: 2550,
        Height: 3300,
        PrintWidth: 8.50,
        PrintHeight: 11,
        IsLandscape: false
    },
    {
        Name: "US Legal Laser(8.50 Inch * 14 Inch)",
        Id: 5,
        Width: 2550,
        Height: 4200,
        PrintWidth: 8.50,
        PrintHeight: 14,
        IsLandscape: false
    },
    {
        Name: "US Ledger Laser(11 Inch * 17 Inch)",
        Id: 6,
        Width: 3300,
        Height: 5100,
        PrintWidth: 11,
        PrintHeight: 17,
        IsLandscape: false
    }
];

export enum CounsTypeEnum {
    YES = "1",
    NO = "2",
    REPORT = "3"
}
