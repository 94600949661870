export class LifeStyle{
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    LifestyleId: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm:Date = null;
    IsDeleted: boolean = null;
  }

  export class rowDataLifeStyle {
    Description: string = null;
    Code: string = null;
    IsDeleted: boolean = null;
    PatientId: number = null;
    LifestyleId: number = null;
}

export class LifeStyleList {
    LifeStyles: LifeStyleData[] = [];
    TotalCount: number = null;
}

export class LifeStyleData {
    ConceptDescription: string = null;
    ConceptID: string = null;
    ConceptName: string = null;
}