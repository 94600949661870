import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonStore } from "src/app/store";

@Component({
    selector: "app-refill-progress-stats",
    templateUrl: "./refill-progress-stats.component.html"
})
export class RefillProgressStatsComponent implements OnInit {
    expand: boolean = false;
    rFData: any;
    refInQueueList: any;
    refSelctList: any;

    constructor(private _commonStore: CommonStore) {
        this.rFData = this._commonStore.rxSelectedForRf$["source"]["value"];
        if (this.rFData) {
            this.refInQueueList = this.rFData["selectedRxs"];
            this.refSelctList = this.rFData["completeData"];
        }
    }

    ngOnInit() {}
    showExpand() {
            this.expand = ! this.expand;
    }
}
