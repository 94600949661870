import { CommonService, AlertService } from "src/app/services";
import {
    Component,
    OnInit,
    EventEmitter,
    ViewChild,
    Output,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    Input,
    HostListener
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import {
    CloneRx,
    RxPatientModel,
    RxModel,
    SystemSetting,
    AccCategoryE,
    RefillRxE,
    RxE,
    ControlRxProcessingE,
    Rx1E
} from "../../../models";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxUtils } from "../../../utils";
import { RxService } from "../../../services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import * as _ from "lodash";
import { PrivMaskGuard } from "src/app/guards";
import { RxFileComponent } from "../rx-file/rx-file.component";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-refill-rx-info",
    templateUrl: "./refill-rx-info.component.html"
})
export class RefillRxInfoComponent implements OnInit, AfterViewInit, OnDestroy {
    hisrf: any;
    isFromERW = false;
    DisRefi = false;
    actvModal: string;
    categoryId: any;
    openOverride: boolean;
    privType: any;
    overrideFor: any;
    fileFG: FormGroup;
    isFiledNew: any;
    allowControlRx: boolean;
    overRideChecked: boolean;
    controlRxPrivs: any;
    Rx1Priv: any;
    overrideEndpoint: any;
    filePopUp: boolean;
    hasRefillRxPrivs: boolean;
    hasCreateRxPrivs: boolean;
    patHisWJ: CollectionView;
    pdactvHeaders: string[];
    subscriptions = new Subscription();
    rxInfoToFill: any;
    filedRx: any;
    hasFileRx: boolean;
    modelRef2: any;
    isFiledRxSelected = false;
    selectedFiledRx: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxNum(rn: number) {
        this.hisrf = rn;
    }

    @Input()
    set IsFromERW(ip: boolean) {
        this.isFromERW = ip;
    }
    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    CloseParent = new EventEmitter<null>();

    @Output()
    CloseParentPopup = new EventEmitter<null>();

    @Output()
    refillPopClose = new EventEmitter<boolean>();

    @Output()
    SendRefillRequest = new EventEmitter<null>();

    @Output()
    EmitRxInfo = new EventEmitter<any>();

    @ViewChild("Refill", { static: true })
    Refill: any;

    @ViewChild("FILE", { static: true })
    FILE: any;

    modelRef: any;
    rxNuFG: FormGroup;
    rxDetailsIDs: CloneRx;
    rxPatientModel: RxPatientModel;
    prescriberInfo: any;
    drugInfo: any;
    rxDetails: any;
    secondpop = false;
    rxno: any;
    popupsize = "sm";
    warn = 0;
    filledWJ: CollectionView;
    actvHeaders: string[];
    refilldis = false;
    createdis = false;
    moredays: number;
    rxnotFound = false;
    systemSetting: SystemSetting;
    cookieValue: any;
    accessPrivCate: any;
    refillRxPrivs: any;
    RxEPrivs: any;

    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27) {
            if (this.actvModal === "first") {
                this.closeModal("Cancel");
            } else if (this.actvModal === "RxNotFound") {
                this.goTofirstPopUp();
            } else if (this.actvModal === "Pick Up") {
                this.goTofirstPopUp();
            } else if (this.actvModal === "Warn") {
                this.closeModal("Cancel");
            }
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _rxUtils: RxUtils,
        private _rxsrv: RxService,
        private _commonServ: CommonService,
        private _cdr: ChangeDetectorRef,
        private _alertSvc: AlertService,
        private _privMaskGuard: PrivMaskGuard
    ) {
        this.rxNuFG = this._fb.group({
            rxNum: []
        });
        this.accessPrivCate = AccCategoryE;
        this.refillRxPrivs = RefillRxE;
        this.Rx1Priv = Rx1E;
        this.controlRxPrivs = ControlRxProcessingE;
        this.RxEPrivs = RxE;
        this.fileFG = this._fb.group({
            dueDateTime: [],
            description: [],
            parentPage: [],
            prescId: [],
            prescRefillId: []
        });

        this.fileFG.controls["dueDateTime"].setValue(
            moment(new Date()).format("MM/DD/YYYY")
        );
    }

    ngOnInit() {
        if (this.hisrf) {
            this.rxNuFG.controls["rxNum"].setValue(this.hisrf);
            this.getRxDet(this.hisrf);
            this.secondpop = true;
            // this.modelRef = this._modalService.open(this.Refill, {
            //     centered: true,
            //     backdrop: false
            // });
            // this.actvModal = "refill";
            // document.getElementById("myInput").focus();
        } else {
            this.modelRef = this._modalService.open(this.Refill, {
                centered: true,
                backdrop: false,
                keyboard: false
            });
            sessionStorage.setItem("BackToRefRxPop", "" + true);
            this.actvModal = "first";
            //  document.getElementById("myInput").focus();
        }

        this._commonServ.systemSettings$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemSetting = resp;
        });

        if (this.accessPrivCate) {
            this.hasRefillRxPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.RxEPrivs.RefillRx);
            this.hasCreateRxPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.RefillRx, this.refillRxPrivs.CreateNew);
        }
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    get dueDateTime(): FormControl {
        return this.fileFG.get("dueDateTime") as FormControl;
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closeModal(type) {
        if (this.filePopUp) {
            this.filePopUp = false;
        }
        if (type === "Cancel") {
            sessionStorage.setItem("BackToRefRxPop", "" + false);
        }
        if (this.isFromERW && type === "Cancel") {
            if (this.modelRef) {
                sessionStorage.setItem("BackToRefRxPop", "" + false);
                this.modelRef.close();
            }
            // this.IsPopUpClosed.emit(null);
            this.refillPopClose.emit(true);
            return;
        }
        this.IsPopUpClosed.emit(null);
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.modelRef2) {
            this.modelRef2.close();
        }
    }
    getRxDet(rxId: number) {
        const cloneRx = new CloneRx();
        cloneRx.RxId = rxId;

        this._commonServ
            .getRxDetailsByPrescNos([rxId], null)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp: RxModel[]) => {
                if (
                    resp &&
                    resp.length > 0 &&
                    resp[0].Patient &&
                    resp[0].Prescriber &&
                    resp[0].Drug
                ) {
                    const rxDetlsID = {
                        PatientId: resp[0].Patient.patientid,
                        PrescriberId: resp[0].Prescriber.prescriberid,
                        DrugId: resp[0].Drug.id,
                        PrescNum: rxId
                    };
                    this.getRxRefillDetails(rxDetlsID);
                } else {
                    this.rxnotFound = true;
                    // this.closeModal();
                    if (this.modelRef) {
                        this.modelRef.close();
                    }
                    this.modelRef = this._modalService.open(this.Refill, {
                        centered: true,
                        backdrop: false,
                        keyboard: false
                    });
                    this.actvModal = "RxNotFound";
                }
                this.EmitRxInfo.emit(resp[0]);
            });
    }

    getRxRefillDetails(rxDetailsIDs) {
        this._rxsrv.refillRxDetails(rxDetailsIDs)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resps => {
            if (resps) {
                this.rxDetails = resps;
                if (
                    this.rxDetails.Refdetail.Refilldetails[0].Drug.drugclass >=
                    2
                ) {
                    if (
                        !this.checkHasPrivsToAccess(
                            this.accessPrivCate.Rx1,
                            this.Rx1Priv.ControlRxProcessing
                        )
                    ) {
                        this.openOveride(
                            "Rx1",
                            "ControlRxProcessing",
                            "POST_Rx"
                        );
                    } else {
                    if (
                        !this.checkHasPrivsToAccess(
                            this.accessPrivCate.ControlRxProcessing,
                            this.controlRxPrivs.AllowControlRxProcessing
                        )
                    ) {
                        this.openOveride(
                            "RefillRx",
                            "AllowControlRxProcessing",
                            "POST_Rx"
                        );
                    } else {
                        if (
                            !this.checkHasPrivsToAccess(
                                this.accessPrivCate.RefillRx,
                                this.refillRxPrivs.AllowControlRxProcessing
                            )
                        ) {
                            this.openOveride(
                                "RefillRx",
                                "AllowControlRxProcessing",
                                "POST_Rx"
                            );
                        } else {
                            this.processRefill();
                        }
                    }
                }
                } else {
                    this.processRefill();
                }
            } else {
                this.rxnotFound = true;
                // this.closeModal();
                if (this.modelRef) {
                    this.modelRef.close();
                }
                this.modelRef = this._modalService.open(this.Refill, {
                    centered: true,
                    backdrop: false,
                    keyboard: false
                });
                this.actvModal = "RxNotFound";
            }
        });
    }

    processRefill() {
        this.rxno = this.rxNuFG.controls["rxNum"].value;
        if (!this.rxDetails.IsPickedUp && !(this.rxDetails.Refdetail.Refilldetails[0].PrescReFill.StatusId ===
            2 ||
            this.rxDetails.Refdetail.Refilldetails[0].Prescription
                .RxStatusId === 2)) {
            this.rxnotFound = false;
            this.secondpop = true;
            // this.closeModal();
            if (this.modelRef) {
                this.modelRef.close();
            }
            this.modelRef = this._modalService.open(this.Refill, {
                centered: true,
                backdrop: false,
                keyboard: false
            });
            this.actvModal = "Pick Up";
        } else {
            this.secondpop = true;
            this.rxnotFound = false;
            this.warningpopup();
        }
    }

    goTofirstPopUp() {
        sessionStorage.setItem("BackToRefRxPop", "" + false);
        // this.closeModal();
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.isFromERW) {
            this.refillPopClose.emit(true);
            return;
        }
        this.secondpop = false;
        this.rxnotFound = false;
        this.rxNuFG.controls["rxNum"].setValue(null);
        this.modelRef = this._modalService.open(this.Refill, {
            centered: true,
            backdrop: false,
            keyboard: false
        });
        this.actvModal = "first";
    }
    getRxNum() {
        if (this.rxNuFG.controls["rxNum"].value) {
            this.getRxDet(this.rxNuFG.controls["rxNum"].value);
        }
    }

    unHoldRx() {
            this.rxDetails.Refdetail.Refilldetails[0]["PrescReFill"]["IsRxHold"] = false;
            this._rxsrv.updateRxInfo(this.rxDetails.Refdetail.Refilldetails[0])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resps => {
                if (resps && !isNaN(+resps) && +resps > 0) {
                    if (
                        this.warn &&
                        this.checkHasPrivsToAccess(
                            this.accessPrivCate.Rx,
                            this.RxEPrivs.RefillRx
                        )
                    ) {
                        this.cloneRxInfo(this.rxno);
                    } else if (
                        this.warn &&
                        this.checkHasPrivsToAccess(
                            this.accessPrivCate.Rx,
                            this.RxEPrivs.RefillRx
                        )
                    ) {
                        this.openOveride("Rx", "RefillRx", "POST_Rx");
                    }
                } else if (isNaN(+resps)) {
                    this._alertSvc.error(resps);
                } else {
                    this._alertSvc.error("Save Unsuccessfull");
                }
            });
    }

    cloneRxInfo(rxNo: number) {
        sessionStorage.setItem("PatientComplete", "" + false);
        sessionStorage.setItem("PrescriberComplete", "" + false);
        sessionStorage.setItem("DrugComplete", "" + false);
        sessionStorage.removeItem("ErxType");
        this.CloseParent.emit(null);
        this.closeModal("Refill");
        const rxInfo = this.rxDetails.Refdetail.Refilldetails[0];
        if (rxInfo && rxInfo.RxPay && rxInfo.RxPay.length > 0) {
            let rxPay = null;
            let insurence = null;
            if (rxInfo.RxPay && rxInfo.RxPay.length > 0) {
                rxPay = rxInfo.RxPay.filter(pay => (+pay.CoverageCD) === 0 && pay.RecType !== "R")[0];
            }
            if (rxPay) {
                insurence = rxInfo.Patient.insurance.filter((item: any) => {
                    return (item.insuType === rxPay.Ins_Code.trim() && !item.IsCash);
                })[0];
                if (insurence) {
                    rxInfo.RefillInsu.InsuId = +insurence.insuranceid;
                }
            }
        }
        if (+rxInfo.PrescReFill.ReFillNum !== 0) {
            rxInfo.PrescReFill.ReFillNum = rxInfo.PrescReFill.ReFillNum + 1;
        } else {
            rxInfo.PrescReFill.ReFillNum = 1;
        }

        this.rxDetails.Refdetail.Refilldetails[0].RxPay = null;
            this._rxUtils.storeERXInfo(
                this.rxDetails.Refdetail.Refilldetails[0],
                rxNo,
                "rf"
            );
    }

    cloneNewRxInfo(rxNo: number) {
        sessionStorage.removeItem("ErxType");
        this.CloseParent.emit(null);
        this.rxDetails.Refdetail.Refilldetails[0].RxPay = null;
        this.closeModal("NewRx");
        this._rxUtils.storeERXInfo(
            this.rxDetails.Refdetail.Refilldetails[0],
            rxNo,
            "nrf"
        );
    }

    warningpopup() {
        // this.closeModal();
        if (this.modelRef) {
            this.modelRef.close();
        }
        const orderDate = moment(this.rxDetails.Refdetail.Refilldetails[0].PrescReFill.OrderDtTm);
        const today = moment();
        const daysBeforeCreated = today.diff(orderDate, "days");
        if (this.rxDetails.MaxOrderToLastrefilldays === null) {
            this.rxDetails.MaxOrderToLastrefilldays = 365;
        }
        if (this.rxDetails["MAxRefillAllowed"] === null) {
            this.rxDetails["MAxRefillAllowed"] = 99;
        }
        if (daysBeforeCreated < this.rxDetails.MaxOrderToLastrefilldays &&
            this.rxDetails.FinalExpdate > -this.rxDetails.MaxOrderToLastrefilldays &&
            this.rxDetails.FinalExpdate < 0) {
            this.warn = 1;
            this.moredays = -this.rxDetails.FinalExpdate;
            this.createdis = false;
            this.refilldis = false;
        }
        if (this.rxDetails.FinalExpdate > 0 && daysBeforeCreated < this.rxDetails.MaxOrderToLastrefilldays) {
            this.warn = 2;
            this.createdis = true;
            this.refilldis = false;
        }
        if (this.rxDetails.SimiliarRx.RecordCount) {
            this.warn = 6;
            this.generateWJ(this.rxDetails.SimiliarRx.Refilldetails);
            this.createdis = false;
            this.refilldis = false;
        }
        if (this.rxDetails.Refdetail.Refilldetails[0].PrescReFill.IsRxHold) {
            this.warn = 9;
            this.createdis = true;
            this.refilldis = true;
        }
        if (
            this.rxDetails.Refdetail.Refilldetails[0].PrescReFill.StatusId ===
            2 ||
            this.rxDetails.Refdetail.Refilldetails[0].Prescription
                .RxStatusId === 2
        ) {
            this.warn = 4;
            this.createdis = false;
            this.refilldis = true;
        }
        if (
            this.rxDetails.Refdetail.Refilldetails[0].PrescReFill.StatusId ===
            4 ||
            this.rxDetails.Refdetail.Refilldetails[0].Prescription
                .RxStatusId === 4
        ) {
            if (this.getDefaultValue("ALLOW_REFILL_TRANS_OUT") === "1") {
                this.warn = 5;
                this.createdis = false;
                this.refilldis = true;
            }
        }
        if (this.rxDetails.Refdetail.Refilldetails[0].PrescReFill.ReFillNum
            >= this.rxDetails["MAxRefillAllowed"]) {
                this.warn = 10;
                this.createdis = false;
                this.refilldis = true;
        }
        if (
            this.rxDetails.Refdetail.Refilldetails[0].Prescription
                .IsDiscontinued
        ) {
            this.warn = 3;
            this.createdis = true;
            this.refilldis = true;
        }
        if (
            this.rxDetails.Refdetail.Refilldetails[0].Prescription.ReFillNum <=
            this.rxDetails.Refdetail.Refilldetails[0].PrescReFill.ReFillNum
        ) {
            this.warn = 7;
            this.createdis = false;
            this.refilldis = true;
            this.getPatientDrugDisHistory(this.rxDetails.Refdetail.Refilldetails[0].Prescription.PatientId,
                this.rxDetails.Refdetail.Refilldetails[0].Prescription.DrugId);
        }
        if (daysBeforeCreated > this.rxDetails.MaxOrderToLastrefilldays ||
            this.rxDetails.FinalExpdate < -this.rxDetails.MaxOrderToLastrefilldays) {
            this.warn = 8;
            this.createdis = false;
            this.refilldis = true;
        }

        if (this.warn !== 4 && this.warn) {
            this.modelRef = this._modalService.open(this.Refill, {
                centered: true,
                size: "lg",
                backdrop: false,
                keyboard: false
            });
            this.actvModal = "Warn";
        } else if (this.warn === 4) {
            this.filePopUp = true;
            this.modelRef2 = this._modalService.open(this.FILE, {
                centered: true,
                backdrop: false,
                keyboard: false
            });
        } else {
            // this.CloseParentPopup.emit(null);
            this.closeModal("no errors");
            this.cloneRxInfo(this.rxNuFG.controls["rxNum"].value);
        }
    }

    saveFileToInfo() {
        this.filePopUp = false;
        const rxInfo = this.rxDetails.Refdetail.Refilldetails[0];

        // this.fileFG.controls["prescId"].setValue(rxInfo.Prescription.Id);
        // this.fileFG.controls["prescRefillId"].setValue(rxInfo.PrescReFill.Id);

        if (!this.isFiledNew) {
            rxInfo.Prescription.RxStatusId = 1;
            rxInfo.PrescReFill.StatusId = 1;
            // rxInfo.PrescReFill.IsNewPrescRefill = true;
            this._rxsrv.updateRxInfo(rxInfo)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && !isNaN(+resp) && +resp > 0) {
                    if (this.modelRef2) {
                        this.modelRef2.close();
                    }
                    this.cloneRxInfo(this.rxNuFG.controls["rxNum"].value);
                } else if (isNaN(+resp)) {
                    this._alertSvc.error(resp);
                } else {
                    this._alertSvc.error(
                        "Rx Filling Unsuccessfull.",
                        false,
                        "long"
                    );
                }
            });
        } else {
            if (this.modelRef2) {
                this.modelRef2.close();
            }
            sessionStorage.setItem("CreatingNewFile", "" + rxInfo.Prescription.PrescNum);
            this.cloneNewRxInfo(this.rxno);
        }
    }

    createNewFiledRx(val) {
        this.isFiledNew = val;
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    generateWJ(info: any) {
        this.filledWJ = new CollectionView(
            info.map((Rx, i) => {
                const j = {};
                j["Rx#"] = Rx.Prescription.PrescNum;
                j["Order Date"] = moment.utc(Rx.PrescReFill.OrderDtTm).local().format("L");
                j["Due Date"] = moment.utc(Rx.PrescReFill.NextFillDt).local().format("L");
                j["Drug Name"] = Rx.Drug.drugname;
                j["Qty"] = Rx.PrescReFill.OrdQty;
                j["Days"] = Rx.PrescReFill.OrderDays;
                return j;
            })
        );
        this.actvHeaders = [
            "Rx#",
            "Order Date",
            "Due Date",
            "Drug Name",
            "Qty",
            "Days"
        ];
    }

    getDefaultValue(defKey: string) {
        const defObj: any = _.filter(this.systemSetting.PharmacySettings, {
            Key: defKey
        });
        if (defObj.length > 0) {
            return defObj[0].Value;
        } else {
            return null;
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "CreateNew") {
                this.cloneNewRxInfo(this.rxno);
            } else if (this.overrideFor === "RefillRx") {
                this.cloneRxInfo(this.rxno);
            } else if (this.overrideFor === "AllowControlRxProcessing" || this.overrideFor === "ControlRxProcessing") {
                this.processRefill();
            }
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    sendRefillRequest() {
        this.SendRefillRequest.emit();
    }

    getPatientDrugDisHistory(patientID, drugId) {
        this._rxsrv
            .getPatientDrugDispHist(patientID, drugId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp: any) => {
                if (resp) {
                    this.generatePatDisWJ(resp.dispensedHistory);
                }
            });
    }

    generatePatDisWJ(info: any) {
        if (info && info.length > 0) {
            info.map(d => {
                if (d.Status === "Filed") {
                    this.hasFileRx = true;
                    this.rxInfoToFill = d;
                }
            });
            this.patHisWJ = new CollectionView(
                info.map((d, i) => {

                    const j = {};
                    j["Rx#"] = d.PrescNum;
                    j["Rf"] = d.ReFillNum;
                    j["Date Filled"] =(d.RxReceiptDtTm) ? moment.utc(d.RxReceiptDtTm).local().format(
                        "MM/DD/YY"
                    ) : "";
                    j["Status"] = d.Status;
                    j["Drug Name"] = d.DrugName;
                    j["QtyDisp"] = d.QtyDisp;
                    j["Days"] = d.Days;
                    j["Prescriber Name"] = d.PrescriberName;
                    j["Ins.Type"] = d.Name;
                    j["Pat.Name"] = d.PatientName;
                    j["PreName"] = d.PrescriberName;
                    return j;
                })
            );
            this.pdactvHeaders = [
                "Rx#",
                "Rf#",
                "Date Filled",
                "Status",
                "Drug Name",
                "QtyDisp",
                "Days",
                "Prescriber Name"
            ];
        }
    }

    filleRx() {
        this.rxInfoToFill = null;
        let selectedrxNo = null;
        let prescRefID = null;

        if (this.selectedFiledRx) {
            selectedrxNo = this.selectedFiledRx["Rx#"];
            prescRefID = this.selectedFiledRx["PrescRefillId"];
        }
        this.subscriptions.add(
        this._commonServ
        .getRxDetailsByPrescNos(
            [selectedrxNo],
            prescRefID,
            false
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp2 => {
            if (resp2) {
                this.rxInfoToFill = resp2[0];
                const modalRef = this._modalService.open(RxFileComponent, {
                    backdrop: false,
                    size: "lg",
                    windowClass: "md-x-lg",
                    keyboard: false
                });
                modalRef.componentInstance.RxInfo = this.rxInfoToFill;
                this.subscriptions.add(
                    modalRef.componentInstance.IsPopUpClosed
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        modalRef.close();
                        // this.emitPopUpClosed("R");
                        this.cloneRxInfo(+this.rxInfoToFill.Prescription.PrescNum);
                }));

                this.subscriptions.add(
                    modalRef.componentInstance.CancelPopUpClosed
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        modalRef.close();
                }));
            }
        }));
    }

    gridClickHandler(e: MouseEvent, grid: wjcGrid.FlexGrid) {
        const htInfo = grid.hitTest(e);
        this.isFiledRxSelected = false;
        this.selectedFiledRx = null;
        if (htInfo.grid) {
            const selectdItem= htInfo.grid.selectedItems[0];
            /* rowIndex is the clicked data row index */
            if (selectdItem["Status"] === "Filed") {
                this.isFiledRxSelected = true;
                this.selectedFiledRx = selectdItem;
            }
        }
    }

}
