import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    SimpleChanges,
    OnChanges,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core";
import { RxPatientModel, SystemData, CloneRx } from "src/app/models";
import {
    CommonService,
    KeyboardShortcuts,
    EditPrescriberService,
    EditDrugService,
    RxService
} from "../../../services";
import * as moment from "moment";
import { Router } from "@angular/router";
import { RxHistoryComponent } from "../../shared";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxUtils } from "src/app/utils";
import { MaskConstant } from "src/app/app.mask-patterns";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-pat-history",
    templateUrl: "./pat-history.component.html"
})
export class PatHistoryComponent implements OnInit, AfterViewInit {
    @Input()
    set PatientID(pi: number) {
        this.patientID = pi;
        if (this.patientID) {
            this.showRxHistory = true;
            this.getPatientInfo(this.patientID);
        }
    }

    @Output()
    EmitPatientInfo = new EventEmitter<{ PatientInfo: RxPatientModel }>();

    @Output()
    actionButtonsHandler = new EventEmitter();

    @Output()
    emitRxInfo = new EventEmitter();

    @ViewChild("PatSearch")
    patSearch: ElementRef;

    @ViewChild(RxHistoryComponent)
    rxHistoryComponent: RxHistoryComponent;

    loadRxHistory: boolean;
    patientID: number;
    rxPatientModel: RxPatientModel;
    systemData: SystemData;
    age: string;
    showRxHistory = false;
    expiredDateRange = 0;
    rxDetailsIDs: CloneRx;
    prescriberInfo: any;
    drugInfo: any;
    patId: number;
    router: string;
    showPatientCard = false;
    isInputFocused = false;
    unsubscribe$: Subject<void> = new Subject();

    constructor(
        private _commonServ: CommonService,
        private _cdr: ChangeDetectorRef
    ) {
        this.router = window.location.href;
        // if (sessionStorage.getItem("backFmXFO") === "true") {
        //     sessionStorage.setItem("backFmXFO", "" + false);
        //     const PatInfo: any = JSON.parse(sessionStorage.getItem("patInfoInHis"));
        //     this.selectedPatientInfo(PatInfo);
        // }
     }

    ngOnInit() {
        if (this.router.includes("/PatientHistory")) {
            this.showPatientCard = true;
        }
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
            resp => (this.systemData = resp)
        );
    }
    ngAfterViewInit() {
        this.isInputFocused = true;
    }
    setAge(pi) {
        if (pi && pi.PatientInfo) {
            const dob = moment(pi.PatientInfo.Dob);
            const years = moment().diff(dob, "year");
            dob.add(years, "years");
            const months = moment().diff(dob, "months");
            dob.add(months, "months");
            this.age = years + " years " + months + " Months";
        }
    }

    selectedPatientInfo(patientInfo: any) {
        this.isInputFocused = false;
        this.rxPatientModel = patientInfo;
        this.patientID = patientInfo.patientid;
        if (this.patientID) {
        const storePatientInfo = JSON.stringify(patientInfo);
        sessionStorage.setItem("patInfoInHis", storePatientInfo);
            this.showRxHistory = true;
            this.getPatientInfo(this.patientID);
        }
        this.EmitPatientInfo.emit({ PatientInfo: this.rxPatientModel });
    }

    getPatientInfo(patientID: number): void {
        this._commonServ
            .getPatientInformationForRx(patientID)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.isInputFocused = false;
                    this.rxPatientModel = resp;
                    this.setAge(this.rxPatientModel);
                }
            });
    }

    setExpiryDateRange(val) {
        this.expiredDateRange = val;
    }

    hasRX(val: boolean) {
        this.actionButtonsHandler.emit(val);
    }

    emitRx(val) {
        this.emitRxInfo.emit(val);
    }

    LoadRxHistory() {
        this.loadRxHistory = true;
       // this.rxHistoryComponent.getPatientHistory();
    }

    resetPatientInfo() {
        this.isInputFocused = false;
        this.actionButtonsHandler.emit(false);
        this.rxPatientModel = null;
        this.showRxHistory = false;
        this._cdr.detectChanges();
        this.isInputFocused = true;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
