<ng-template #PatientResubmit let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            <div>Rx's that have been submitted for the Patient <span class="font-weight-bold">{{patientName}} </span> earlier today, will be re-submitted to the State with this change.
            Do you wish to add to the Re-submission list?</div>
        </span>
        <button
        footer
        autofocus
        type="submit"
        class="hotkey_primary"
        appShortcutKey
        (click)="reSubmitToStatesForCntrlFile()"
        InputKey="y"><b>Y</b> YES
    </button>
    <button
        footer
        type="submit"
        class="hotkey_primary"
        appShortcutKey
        InputKey="n"
        (click)="closeModal()"
    >
        <b>N</b> NO
    </button>
    </app-custom-modal>
</ng-template>

<ng-template #PrescriberResubmit let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            <div>Rx's that have been submitted for the Prescriber <span class="font-weight-bold">{{prescriberName}} </span> earlier today, will be re-submitted to the State with this change.
            Do you wish to add to the Re-submission list?</div>
        </span>
        <button
        footer
        autofocus
        type="submit"
        class="hotkey_primary"
        appShortcutKey
        (click)="reSubmitToStatesForCntrlFile()"
        InputKey="y"><b>Y</b> YES
    </button>
    <button
        footer
        type="submit"
        class="hotkey_primary"
        appShortcutKey
        InputKey="n"
        (click)="closeModal()"
    >
        <b>N</b> NO
    </button>
    </app-custom-modal>
</ng-template>
