<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Insurance Restrictions'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                <button class="hotkey_success" id="SaveButton" (click)="routeBackToPrevScreen()"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header large_header">
            <div class="row">
                <div class="col">
                    <span class="eprx--header__heading">
                    </span>
                </div>
                <div class="col pull-right text-right">
                    <button class="hotkey_primary  hotkey_primary--Override" (keydown.tab)="focusOutFromAdd($event)"
                    autofocus
                    (click)="openPopUpModal(popup)" appShortcutKey [CtrlKey]="'a'"><span>A</span>ADD</button>
                </div>
            </div>
        </div>
        <div class="eprx--block__content" *ngIf="totalCount">
            <wj-flex-grid #insuResList [headersVisibility]="'Column'" [itemsSource]="dispHisWJ" [isReadOnly]="true" [columnPicker]="''"
                [selectionMode]="'Row'" (updatedView)="init(insuResList)" (keydown)="addEventOnWijimo($event, popup)" (keydown.enter)="keyEventOnWijimo(popup, insuResList)">
                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders  ; let i = index"
                    [width]="(actvHeader === 'NDC Qualifier')  ? 300 : '*'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <div *ngIf="actvHeader === 'Actions'"  class="text-center">
                            <i class="far fa-edit fa-sm  actions" title="edit" (click)="editPopModal(popup, item)"></i>
                            <i class="far fa-trash-alt fa-sm actions danger-actve" title="delete" (click)="deletePopUpModal(delete, item)"></i>
                        </div>
                        <div *ngIf="!(actvHeader === 'Actions')">
                            {{item[actvHeader]}}
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
            </wj-flex-grid>
            <app-eprx-pagination [TotalCount]="totalCount" [PageSize]="Size" [WijmoName]="inventorylist" [GridName]="'InsuranceRestriction'"
                (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize(Size)" (OnFocusOut)="focusToFirst($event)"></app-eprx-pagination>
        </div>
        <div class="eprx--block__content eprx--block__footer" *ngIf="(totalCount === 0)">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
    </div>
</div>
<ng-template #popup let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Drug/Insurance Restrictions Settings</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <label class="col-md-12 padding-0"> Apply To All Insurances? </label>
                <eprx-radio [LabelText]="''" [ControlName]="'IsAllInsu'" [FormGroupName]="drugEditFG" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                  [AutoFocus]="true"  [IDForList]="['yes', 'no']" (TriggerSelectValue)="applytoAll()">
                </eprx-radio>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-select [LabelText]="'Insurances'" [PlaceHolder]="''" [ControlName]="'InsurId'" [FormGroupName]="drugEditFG" [BindLabel]="'InsurerCode'"
                    [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="dropDownData" [IsDisabled]="HasInsuFilter"
                    [IsRequired]="!HasInsuFilter" [InputErrors]="InsurId?.errors" [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid"
                    [LabelTitle2]="'Name'" [LabelTitle1]="'Insurer Code'">
                </eprx-select>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <label class="col-md-12 padding-0">Allowed By Insurance? </label>
                <eprx-radio [LabelText]="''" [ControlName]="'IsAllowed'" [FormGroupName]="drugEditFG" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                    [IDForList]="['y', 'n']">
                </eprx-radio>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Billing NDC'" [PlaceHolder]="''" [ControlName]="'BillingNDC'" [FormGroupName]="drugEditFG" [MaskPattern]="'00000-0000-00'"
                    [MaxLength]="'13'">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-select [LabelText]="'NDC Qualifier'" [PlaceHolder]="''" [ControlName]="'NDCQualiId'" [FormGroupName]="drugEditFG" [BindLabel]="'Name'"
                    [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="systemData?.NdcQualifier">
                </eprx-select>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <label class="col-md-12 padding-0"> Prior Approval Required? </label>
                <eprx-radio [LabelText]="''" [ControlName]="'IsPriorApprReqd'" [FormGroupName]="drugEditFG" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                    [IDForList]="['ye', 'No']">
                </eprx-radio>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-input [LabelText]="'Maximum Refills Allowed'" [PlaceHolder]="''" [ControlName]="'MaxRefillsAllowed'" [FormGroupName]="drugEditFG"
                    [MaskPattern]="'00'">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Qty Conversion Calculation'" [PlaceHolder]="''" [ControlName]="'QtyConCal'" [FormGroupName]="drugEditFG"
                [BeforeDecimal]="10"  [DecimalValues]="3"  [InputType]="'NUMBER'" [MaskPattern]="'0999999.000'">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-input [LabelText]="'Max Qty Allowed'" [PlaceHolder]="''" [DecimalValues]="3" [ControlName]="'MaxQtyAllowed'" [InputType]="'NUMBER'"
                [MaskPattern]="'099999.000'" [DropSpclChar]="false" [FormGroupName]="drugEditFG" (TriggerSearchValue)="setNumberFormat('MaxQtyAllowed')">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Result'" [PlaceHolder]="''" [ControlName]="'Result'" [FormGroupName]="drugEditFG">
                </eprx-input>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" *ngIf="!editMode" (click)="addInsuRest()" appShortcutKey [AltKey]="'s'"><span>S</span>Save</button>
                <button class="hotkey_success" *ngIf="editMode" (click)="editInsuRest()" appShortcutKey [AltKey]="'u'"><span>U</span>Update</button>
                <button class="hotkey_success" (keydown.enter)="d('Cross click')" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure, you want to delete?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteInsuRest()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c"><b>C</b>CANCEL</button>
            </div>
        </div>
    </div>
</ng-template>
