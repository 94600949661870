import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener } from "@angular/core";
import * as _ from "lodash";

@Component({
    selector: "app-rx-delivery-info",
    templateUrl: "./rx-delivery-info.component.html"
})
export class RxDeliveryInfoComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        
    }

}
