import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, CommonService, RphVerificationService } from 'src/app/services';
import * as moment from "moment";
import { AuditLogUtils } from 'src/app/utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rph-mark-verfied',
  templateUrl: './rph-mark-verfied.component.html'
})
export class RphMarkVerfiedComponent implements OnInit {
    selectedRxs: any[];
    currentRx: any;
    erroMsg: string;
    verifiedAlready: any;
    refreshFrid=false;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxInfo(data: any[]) {
        if (data) {
            this.selectedRxs = data;
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    RefreshParent = new EventEmitter<any>();

    @ViewChild("ErrorTemp", { static: true })
    ErrorTemp: any;

    @ViewChild("AlreadyVerif", { static: true })
    AlreadyVerif: any;

    @ViewChild("VerifSuccess", { static: true })
    VerifSuccess: any;


    constructor(public _commonSrv:CommonService, private _alertService:AlertService,
        private _auditUtils: AuditLogUtils,
        private _modalServ:NgbModal,private _rphService:RphVerificationService) { }

    ngOnInit() {
        if (this.selectedRxs && this.selectedRxs.length) {
            this.processRecord();
        }
    }

    processRecord() {
        this.erroMsg = "";
        if (this.selectedRxs && this.selectedRxs.length) {
            this.currentRx = this.selectedRxs.shift();
            this.checkVerifications();
        } else {
            this.RefreshParent.emit(true);
            this.IsPopUpClosed.emit(null);
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    checkVerifications() {
       if ( this.currentRx && this.currentRx["PrescReFill"] &&
                  (this.currentRx["PrescReFill"]["StatusId"] === 2 || this.currentRx["Prescription"]["RxStatusId"] === 2)) {
            this.openErrorModal("Selected Rx is Filed, Cannot be verified.");
        } else if (this.currentRx && this.currentRx["PrescReFill"] &&
                   (this.currentRx["PrescReFill"]["StatusId"] === 4 || this.currentRx["Prescription"]["RxStatusId"] === 4)) {
            this.openErrorModal("Selected Rx is Transfered, Cannot be verified.");
        } else {
            // this.checkPharmacyVerf();
            this.checkDrugPickVerif();
        }
    }

    checkPharmacyVerf() {
        this._rphService.IsPharmacyDrugPickVerified()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.checkDrugPickVerif();
            } else {
                this.openErrorModal("Pharmacy verification unsuccessful.");
            }
        });
    }

    checkDrugPickVerif() {
        if (this._commonSrv.getSetttingValue("WorkFlowSettings", "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF") === "1") {
            this._rphService
                .IsDrugPickVerified(this.currentRx.Prescription.PrescNum,
                    this.currentRx.PrescReFill.ReFillNum, this.currentRx.PrescReFill.PartialFillNo)
                    .pipe(takeUntil(this.unsubscribe$))
                .subscribe(drugVerified => {
                    if (drugVerified) {
                        this.checkIfVerifiedAlready();
                    } else {
                        this.openErrorModal("Drug pick verification is required before pharmacist verification.");
                    }
                });
        } else {
            this.checkIfVerifiedAlready();
        }
    }

    checkIfVerifiedAlready() {
        this._rphService.IsRphVerifiedAlready(this.currentRx.Prescription.Id, this.currentRx.PrescReFill.Id, false)
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(verfiRe => {
                if (verfiRe && verfiRe["Status"] && verfiRe["Status"].trim() === "V") {
                    this.verifiedAlready = verfiRe;
                    this._modalServ.open(this.AlreadyVerif, { keyboard: false, backdrop: "static", centered: true, windowClass:"large--content" });
                } else {
                    this.verifyRph();
                }
            });
    }

    verifyRph() {
        const DatatoPost = {
            PrescId: this.currentRx.Prescription.Id,
            PrescRefillId: this.currentRx.PrescReFill.Id,
            PartialfillNum: this.currentRx["PrescReFill"]["PartialFillNo"],
            VerifDtTm: moment(Date.now()).format("MM-DD-YYYY"),
            Status: "V",
            RefillNum: this.currentRx.PrescReFill.ReFillNum,
            PrescNum: this.currentRx.Prescription.PrescNum,
            SendReadyForPickupAlerts:  this._commonSrv.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
            SendReadyForPickupAlertsWhen: this._commonSrv.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When")
        };
        this._rphService.PostRphVerify(DatatoPost)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.auditLogChanges();
                this._modalServ.open(this.VerifSuccess, { keyboard: false, backdrop: "static", centered: true, windowClass:"large--content" });
            }
        });
    }

    openErrorModal(erMsg) {
        this.erroMsg = erMsg;
        this._modalServ.open(this.ErrorTemp, { keyboard: false, backdrop: "static", centered: true, windowClass:"large--content" });
    }

    auditLogChanges() {
                    this._auditUtils.getChangedValues(null, { "Rph Verified": "Verified" }, "Rph Verification",
                    "Rx", this.currentRx.PrescReFill.Id);
    }

}
