import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeUtcPipe'
})
export class DateTimeUtcPipePipe implements PipeTransform {

  transform(value: any): any {
    let dateVal = null;
    if (value) {
        dateVal = moment.utc(value).local().format("MM/DD/YYYY");
        if (moment(dateVal, "MM/DD/YYYY", true).isValid()) {
            return dateVal;
        } else {
            return null;
        }
    }
    return null;
  }

}
