<app-custom-modal (oncrossBtnClick)="cancelSaveCashRx()">
    <h4 header>Confirm Cash Rx Price</h4>
    <span body>
        <div class="col-xs-12 col-md-12 col-lg-12">
            <div class="row">
                <div class="col-xs-6 col-md-6 col-lg-6">
                    <eprx-select
                        [RxSelectId]="'cnfmrxPriceSchId'"
                        [LabelText]="'Price CD'"
                        [PlaceHolder]="''"
                        [TabIndex]="38"
                        [List]="priceScheduleList"
                        [HasMultiple]="false"
                        [BindLabel]="'PriceCode'"
                        [BindLabel2]="'Name'"
                        [BindValue]="'Id'"
                        [LabelForId]="'Name'"
                        (TriggerSelectValue)="triggerPriceSchdlValue($event)"
                        [IsInputElemHidden]="false"
                        (TriggerOnEnterValue)="keytab($event, 'PriceSchId')"
                        [AutoFocus]="true"
                        [HasControl]="false"
                        [InputValue]="rxInfo?.PrescReFill?.PriceSchId"
                        (TriggerOnTabbing)="keytab($event, 'PriceSchId')"
                    >
                    </eprx-select>
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6">
                    <eprx-input
                        [RxSelectId]="'cnfmrxUnitPriceAWP'"
                        [LabelText]="'Awp'"
                        [PlaceHolder]="''"
                        [ReadOnly]="true"
                        [TabIndex]="-1"
                        [InputType]="'CURRENCY'"
                        (TriggerSearchValue)="setNumberFormat('UnitPriceAWP', $event)"
                        [IsDisabled]="true"
                        (TriggerOnEnterValue)="keytab($event, 'UnitPriceAWP')"
                        (TriggerOnTabbing)="keytab($event, 'UnitPriceAWP')"
                        [HasControl]="false"
                        [InputValue]="
                            (rxInfo?.PrescReFill?.UnitPriceAWP
                                ? (+rxInfo?.PrescReFill?.UnitPriceAWP).toFixed(2)
                                : '0.00'
                            )
                        "
                    >
                    </eprx-input>
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6">
                    <eprx-input
                        [RxSelectId]="'cnfmIngredient Cost'"
                        [LabelText]="'Ingredient Cost'"
                        [PlaceHolder]="''"
                        [TabIndex]="39"
                        [InputType]="'CURRENCY'"
                        (TriggerSearchValue)="setNumberFormat('Price', $event)"
                        (TriggerOnBlur)="initiateManualCal()"
                        (TriggerOnEnterValue)="keytab($event, 'Price')"
                        (TriggerOnTabbing)="keytab($event, 'Price')"
                        [HasControl]="false"
                        [InputValue]="
                            (rxInfo?.PrescReFill?.Price
                                ? (+rxInfo?.PrescReFill?.Price).toFixed(2)
                                : '0.00'
                            )
                        "
                    >
                    </eprx-input>
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6">
                    <eprx-input
                        [RxSelectId]="'cnfmrxOtherAmnt'"
                        [LabelText]="'Rx Cost'"
                        [PlaceHolder]="''"
                        [InputValue]="
                            (+(
                                (+this.rxInfo?.PrescReFill?.DispQty
                                    ? (+this.rxInfo?.PrescReFill?.DispQty)
                                    : 0) *
                                (this.rxInfo?.Drug?.unitpricecost
                                    ? (+this.rxInfo?.Drug?.unitpricecost)
                                    : 0)
                            )).toFixed(2)
                        "
                        [TabIndex]="0"
                        [IsDisabled]="true"
                        [InputType]="'CURRENCY'"
                        (TriggerSearchValue)="setNumberFormat('OtherAmnt', $event)"
                        [HasControl]="false"
                        (TriggerOnEnterValue)="keytab($event)"
                        (TriggerOnTabbing)="keytab($event)"
                        [HasControl]="false"
                    ></eprx-input>
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6">
                    <eprx-input
                        [RxSelectId]="'cnfmrxProfFee'"
                        [LabelText]="'P.Fee'"
                        [PlaceHolder]="''"
                        [TabIndex]="40"
                        [InputType]="'CURRENCY'"
                        (TriggerSearchValue)="setNumberFormat('ProfFee', $event)"
                        (TriggerOnBlur)="initiateManualCal()"
                        (TriggerOnEnterValue)="keytab($event, 'ProfFee')"
                        (TriggerOnTabbing)="keytab($event, 'ProfFee')"
                        [HasControl]="false"
                        [InputValue]="
                            (+this.rxInfo?.PrescReFill?.ProfFee
                                ? (+this.rxInfo?.PrescReFill?.ProfFee).toFixed(2)
                                : '0.00'
                            )
                        "
                    >
                    </eprx-input>
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6">
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6">
                    <eprx-input
                        [RxSelectId]="'cnfmrxTotalAmt'"
                        [LabelText]="'Total Price'"
                        [PlaceHolder]="''"
                        [TabIndex]="41"
                        [InputType]="'CURRENCY'"
                        [InputValue]="
                            (+((this?.rxInfo?.PrescReFill?.Price
                                ? (+this?.rxInfo?.PrescReFill?.Price)
                                : 0.00) +
                            (this?.rxInfo?.PrescReFill?.ProfFee
                                ? +this?.rxInfo?.PrescReFill?.ProfFee
                                : 0.00))).toFixed(2)
                        "
                        (TriggerOnBlur)="
                            crossCheckIngredientCost($event?.target?.value)
                        "
                        (TriggerOnTabbing)="crossCheckIngredientCost($event?.target?.value)"
                        [HasControl]="false"
                        (TriggerOnEnterValue)="focusOnSaveButton($event)"
                        (TriggerSearchValue)="setNumberFormat('ProfFee', $event)"
                    >
                    </eprx-input>
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6">
                </div>
            </div>
        </div>
    </span>
    <button
        footer
        id="saveCnfmBillCash"
        ngbAutofocus
        tabindex="42"
        class="hotkey_success"
        (click)="saveCashInfo()"
        appShortcutKey
        [AltKey]="'s'"
        *ngIf="!(routeFrom === 'nr' || routeFrom === 'nrf' || routeFrom === 'rnr' || routeFrom === 'rf') || isFrmTransHistory || isChangeToCash"
    >
        <span>S</span> Save
    </button>

    <button
        footer
        id="saveCnfmBillCash"
        ngbAutofocus
        tabindex="42"
        class="hotkey_success"
        (click)="saveCashInfoForNewRx()"
        appShortcutKey
        [AltKey]="'s'"
        *ngIf="(routeFrom === 'nr' || routeFrom === 'nrf' || routeFrom === 'rnr' || routeFrom === 'rf') && !isFrmTransHistory && !isChangeToCash"
    >
        <span>S</span> Save
    </button>
    <button
        footer
        id="cancelCnfmBillCash"
        ngbAutofocus
        tabindex="43"
        class="hotkey_success"
        (click)="cancelSaveCashRx()"
        (blur)="focusOnPriceCodeForCashCnfm($event)"
        appShortcutKey
        [AltKey]="'c'"
    >
        <span>C</span> Cancel
    </button>
</app-custom-modal>
