import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';

@Component({
  selector: 'app-image-editor-crop',
  templateUrl: './image-editor-crop.component.html',
  styleUrls: ['./image-editor-crop.component.scss']
})
export class ImageEditorCropComponent implements OnInit {
    inputImage: any;
    cropMode: any;
    isRotate: any = 0;

    @Input()
    set InputImage(data: any) {
        if (data) {
            this.inputImage = data;
        }
    }

    @Input()
    set IsCropMode(data: any) {
        this.cropMode = data;
        if (this.cropMode && this.angularCropper.cropper) {
            this.angularCropper.cropper.crop();
        }
    }

    @Output()
    CloseImageCropEditor = new EventEmitter<any>();

    config = {
        dragMode: "move",
        background: true,
        movable: true,
        rotatable: true,
        scalable: true,
        zoomable: true,
        viewMode: 1,
        checkImageOrigin: true,
        // cropmove: this.cropMoved.bind(this),
        checkCrossOrigin: false
    };

    @ViewChild("angularCropper", { static: true })
    angularCropper: CropperComponent;
    croppedImage: any;

    constructor() { }

    ngOnInit() {
    }

    zoomin() {
        if (this.angularCropper && this.angularCropper.cropper) {
            this.angularCropper.cropper.clear();
            this.angularCropper.cropper.zoom(0.2);
        }
    }

    zoomOut() {
        this.angularCropper.cropper.clear();
        this.angularCropper.cropper.zoom(-0.2);
    }

    rotateImage(isRotateRight) {
        this.angularCropper.cropper.clear();
         if (isRotateRight) {
           this.angularCropper.cropper.rotate(180);
        } else {
            this.angularCropper.cropper.rotate(-180);
        }
        this.cropFromCropMode();
    }

    cropFromCropMode() {
        // this.croppedImage = this.angularCropper.cropper.crop();
        if (this.angularCropper && this.angularCropper.cropper) {
            this.croppedImage = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
        this.CloseImageCropEditor.emit(this.croppedImage);
        }
    }

    cancelFromCropMode() {
        this.CloseImageCropEditor.emit(null);
    }
}
