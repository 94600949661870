import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation, HostListener } from "@angular/core";
import { AlertService, CommonService } from "src/app/services";
import { userGuideLoginPage } from 'src/app/models/userGuide-login-enum';
@Component({
  selector: 'app-userGuide-popup',
  templateUrl: './user-guide-popup.component.html',
  styleUrls: ['./user-guide-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserGuidePopupComponent implements OnInit {
  isMaximized = false;
  idList = [];
  showUserGuide: boolean = false;
  userGuideData: any;
  HeaderText: string;
  isLinkClicked: boolean;
  respForSSO : boolean = false;
  @HostListener("window:keydown", ["$event"])
  keyEventAlt(event: KeyboardEvent) {
      if (event.which === 27) {
          event.preventDefault();
          this.close();
      }
  }

  constructor(private _alertService: AlertService, private _commonSvc: CommonService) { }

  ngOnInit() {
    this._alertService.getUserGuide().subscribe(resp => {
      if (resp) {
        this.respForSSO = false;
        this.HeaderText = resp.heading;
        this.idList = [];
        this.isMaximized = false;
          this.showUserGuide = !this.showUserGuide;
          if (resp.key == 'Login') {
            this.respForSSO = false;
            this.userGuideData = userGuideLoginPage.Login;
          } else {
            this._commonSvc.getUserGuideDataWithKey(resp.key).subscribe(res => {
              if (res.response && res.Id) {
                this.userGuideData = res.response;
                this.respForSSO = false;
                this.idList.push(res.Id)
              } else {
                this.userGuideData = `<div class="userGuide-error">
                                      <p><i class="far fa-exclamation-triangle"></i></p>
                                      Service Unavailable at the moment. Please re-try in sometime.
                                    </div>`
              }
            })
          }
      } else {
          this.showUserGuide = false;
      }
    })  
  }
  clickOnUserGuideLinks() {
    this.isLinkClicked = true;
    document.addEventListener('click', (event) => {
        if ((event.target as HTMLElement).tagName === 'A' && this.isLinkClicked) {
          event.preventDefault()
          const url = (event.target as HTMLAnchorElement).href;
          let id;
          let urlArray = url.split('/');
          urlArray.map((data, i) => {
            if (data == 'pages') {
              this.isLinkClicked = false;
              id = urlArray[i + 1]
            }
          });
          if (id == 190251033) {
            this.idList =[];
            this.userGuideData = userGuideLoginPage.SSOData;
            this.idList.push(id);
            this.respForSSO = true;
          }
          else if (id) {
            this._commonSvc.getUserGuideDataWithId(id).subscribe(res => {
            if (res.response == "error") {
              this.userGuideData = `<div class="userGuide-error">
                                      <p><i class="far fa-exclamation-triangle"></i></p>
                                      Service Unavailable at the moment. Please re-try in sometime.
                                    </div>`
            } else {
              this.userGuideData = res.response;
              this.idList.push(id)
            }
            this.isLinkClicked = false;
          })
        } 
      
      }
        
    });
  }

  navigateToBackScreen() {
    if(this.idList[0] == 190251033){
      this.idList.pop();
      this.respForSSO = false;
      this.userGuideData = userGuideLoginPage.Login;
    } else{
        this._commonSvc.getUserGuideDataWithId(this.idList[this.idList.length-2]).subscribe(res => {
          this.userGuideData = res.response;
        })
        this.idList.pop();
      }
}

  maximizeScreen() {
    this.isMaximized = false;
  }

  minimizeScreen() {
    this.isMaximized = true;
  }

  close() {
    this.isLinkClicked = false;
    this.showUserGuide = false;
    this.userGuideData = null;
  }

}
