<ng-template #IOUADD let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">IOU - Rx Actual Dispensed History</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="exprx--common-block">
            <div class="box-default mb-2">
                <div class="eprx--block__content border_AllRadius transmit--block insurance--body">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 trasmit--rx">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx">
                                    <label> Rx# </label>
                                        <div class="label--data font-weight-normal">
                                               {{rxInfo?.Prescription?.PrescNum}}-{{rxInfo?.PrescReFill?.ReFillNum}}{{_cmnSvc.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription) ? '-'+rxInfo?.PrescReFill?.PartialFillNo : ''}}
                                        </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 trasmit--rx">
                                    <label> Patient Name</label>
                                    <div class="label--data font-weight-normal">
                                        <!-- usunamnasdwdjwd, &nbsp;adkdkwdkwdmcslcmla -->
                                           {{rxInfo?.Patient?.fullname.replace('_', ', ') | uppercase}}
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx">
                                    <label> Fill Date </label>
                                        <div class="label--data font-weight-normal">
                                            {{ rxInfo?.PrescReFill?.FillDtTm | localTime | date : "MM/dd/yyyy" : "--" }}
                                        </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 trasmit--rx">
                                    <label> Prescriber Name</label>
                                    <div class="label--data font-weight-normal">
                                        <!-- usunamnasdwdjwd, &nbsp;adkdkwdkwdmcslcmla -->
                                        {{rxInfo?.Prescriber?.fullname.replace('_', ', ') | uppercase}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 dash--border green_dashborder background-color-lightgreen" style="height:14vh">
                                        <i class="far fa-receipt" style="position:absolute;top:32px;margin-left: 54px;font-size: large;"></i>
                                        <label style="position:absolute;top:51px;margin-left:23px"> Billed Quantity</label>
                                        <div class="label--data font-weight-bold" style="position:absolute;top:73px;margin-left:43px">
                                            {{rxInfo?.PrescReFill?.DispQty | number : '1.3-3'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 dash--border grey_dashborder background-color_alice--blue" style="margin-top:5px;padding:0px;height: 61px;">
                                        <i class="far fa-prescription-bottle-alt" style="position: absolute;top: 14px;margin-left: 7px;"></i>
                                        <label class="margin_left_30px"> Quantity Dispensed</label>
                                        <div class="label--data font-weight-bold" style="margin-left:61px">
                                            {{ActDispQty ? (ActDispQty | number : '1.3-3') : '0.000' }}
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 dash--border orange_dashborder background-color-lightOrange " style="margin-top: 18px;
                                    height: 61px;">
                                        <i class="far fa-pills" style="position: absolute;top: 10px;margin-left: -3px;"></i>
                                        <label style="margin-left:18px"> Quantity Owed</label>
                                        <div class="label--data font-weight-bold" style="margin-left:50px">
                                            {{OwedQuantity ? (OwedQuantity | number : '1.3-3') : '0.000' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 dash--border grey_dashBorder background-color_alice--blue">
                                    <i class="far fa-dollar-sign"></i>
                                    <label class="margin_left_30px"> Billed Qty.</label>
                                    <div class="label--data font-weight-bold margin_left_37px">
                                        {{rxInfo?.PrescReFill?.DispQty | number : '1.3-3'}}
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top_20px dash--border grey_dashBorder background-color_alice--blue">
                                    <i class="far fa-prescription-bottle-alt"></i>
                                    <label class="margin_left_30px"> Dispensed Qty.</label>
                                    <div class="label--data font-weight-bold margin_left_37px">
                                        {{(ActDispQty) | number : '1.3-3'}}
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top_20px dash--border orange_dashborder background-color-lightOrange">
                                    <i class="far fa-pills"></i>
                                    <label class="margin_left_30px"> Quantity Owed</label>
                                    <div class="label--data font-weight-bold margin_left_37px">
                                        {{OwedQuantity | number : '1.3-3'}}
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx">
                            <label> Rx# </label>
                                <div class="label--data font-weight-bold">
                                       10120
                                </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 trasmit--rx">
                            <label> Patient Name</label>
                            <div class="label--data font-weight-bold">
                                   Bergamel Soloman
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 dash--border grey_dashBorder color_alice--blue">
                            <label> Dispensed Qty.</label>
                            <div class="label--data font-weight-bold">
                                10.000
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx">
                            <label> Rx# </label>
                                <div class="label--data font-weight-bold">
                                       10120
                                </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 trasmit--rx">
                            <label> Patient Name</label>
                            <div class="label--data font-weight-bold">
                                   Bergamel Soloman    
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 dash--border orange_dashborder background-color-lightOrange">
                            <label> Quantity Owed</label>
                            <div class="label--data font-weight-bold">
                                10.000
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="eprx--block__content erx--body">
                    <wj-flex-grid #iouflex [headersVisibility]="'Column'" [itemsSource]="IOUListWJ" [selectionMode]="'Row'"
                    [isReadOnly]="true" [columnPicker]="'iouWjList'" [allowSorting]="true" (initialized)="init(iouflex)"
                    [ngClass]="!isDataExists ? 'no-data wjm-grd-custom-height-42':'wjm-grd-custom-height-42'">
                    <!--<wj-flex-grid-column [header]="''" [width]="41">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                             // <i aria-hidden="true" class="far fa-angle-right actions actions--Highlightfocus--icon add--icon" *ngIf="!additionalItems" (click)="showExpand(item)"></i>
                            <i aria-hidden="true" class="far fa-angle-down actions actions--Highlightfocus--icon add--icon" *ngIf="additionalItems" (click)="showExpand()"></i> 
                            <i aria-hidden="true" class="far fa-angle-right actions actions--Highlightfocus--icon add--icon" *ngIf="item.isClicked" (click)="item.isClicked = false;showExpand(item)"></i>
                            <i aria-hidden="true" class="far fa-angle-down actions actions--Highlightfocus--icon add--icon" *ngIf="!item.isClicked" (click)="item.isClicked = true;showExpand()"></i> -->
                            <!-- <i *ngIf="item.isClicked" (click)="item.isClicked = false; showExpand(item);"
                            class="far fa-plus-circle actions "></i>
                            <i *ngIf="!item.isClicked" (click)="item.isClicked = true; showExpand();"
                            class="far fa-minus-circle actions"></i> //
                            <i *ngIf="!dp.isDetailVisible(row)"  (click)="dp.showDetail(row,true)"
                            class="far fa-plus-circle actions actions--Highlightfocus--icon"></i>
                        <i *ngIf="dp.isDetailVisible(row)" (click)="dp.hideDetail(row);"
                            class="far fa-minus-circle actions actions--Highlightfocus--icon"></i>
                        </ng-template>
                    </wj-flex-grid-column>-->
                    <wj-flex-grid-column [header]="'Action'" [width]="55">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <i class="far fa-print actions" title="Print Label" (click)="printLabel(item?.RowId)"></i>
                            <!-- <i class="far fa-edit actions actions--Highlightfocus--icon" title="Edit" (click)="openAddEditIOUPopup('Edit', item)"></i>
                            <i class="far fa-trash-alt actions danger-actve" title="Delete" (click)="openDeletePopup(item)"></i> -->
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                         [width]="rxheader['width']"   *ngFor="let rxheader of wjHeaders; let i = index">
                         <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div *ngIf="rxheader['hName'] === 'NDC#'">
                                {{ item[rxheader['hName']]}}
                                <span [ngbTooltip]=patToolTipCont placement="right" container="body" tooltipClass="DPVVerf-tooltip" container="body" triggers="mouseover:mouseleave" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <4)?'top':'left'" class="d-inline ml-0 margin_left_0_5rem padding_0_0_5_rem font-weight-bold color_white height_23px width_23px text-center font_size_1_2rem background_orange border_radius_50per">𝒊</span>
    
                                    <ng-template #patToolTipCont>
                                        <div>
                                            <div class="row text-left" *ngIf="item['IsRefrigeratorItem']">
                                                <div class="col-sm-12 water_mark_storageItem" [ngStyle]="{'color':StatusId?.bgColor}">
                                                Refrigerated
                                                </div>
                                            </div>
                                            <div class="row text-left">
                                                <!-- <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Patient Info</label>
                                                </div> -->
                                                <div class="col-lg-9 col-md-9">
                                                    <div class="row">
                                                        <div class="col-lg-5 col-md-5">
                                                            <label>Verfn. Source :&nbsp;&nbsp;</label>
                                                            <span class="label--data">{{item.Source ? (item.Source | titlecase) : '--'}}</span>
                                                        </div>
                                                        <div class="col-lg-7 col-md-7">
                                                            <label>User :&nbsp;&nbsp;</label>
                                                            <span class="label--data">{{item.UserId ? (item.UserId | titlecase) : '--'}}</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-lg-5 col-md-5">
                                                            <label>Drug Lot# :&nbsp;&nbsp;</label>
                                                            <span class="label--data">{{item.LotNum ? item.LotNum : '--'}}</span>
                                                        </div>
                                                        <div class="col-lg-7 col-md-7">
                                                            <label>Expiration Date :&nbsp;&nbsp;</label>
                                                            <span class="label--data">{{item.LotExpiryDtTm ? item.LotExpiryDtTm : '--'}}</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-lg-5 col-md-5">
                                                            <label>Serial# :&nbsp;&nbsp;</label>
                                                            <span class="label--data">{{item.SerialNo ? item.SerialNo : '--'}}</span>
                                                        </div>
                                                        <div class="col-lg-7 col-md-7">
                                                            <label>GTIN# :&nbsp;&nbsp;</label>
                                                            <span class="label--data">{{item.GTIN ? item.GTIN : '--'}}</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6">
                                                            <label> DVrf? :&nbsp;&nbsp;</label>
                                                            <span class="label--data">
                                                                {{item?.DPV}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3">
                                                    <div class="text-center">
                                                        <label>Drug Image</label>
                                                    </div>
                                                    
                                                    <div class="col-sm-12 padding-0 edit--drug">
                                                    <img class="Drug_tablate--preview" src="{{ item.ImgUrl }}"
                                                     />
                                                    </div>
                                                </div>    
                                            </div>
                                            <!-- <div class="col-sm-12"> -->
                                                <br>
                                                <div class="row text-left">
                                                    <div class="col-sm-12">
                                                        <label class="text-decoration_underline">Drug Description</label>
                                                        <div><br></div>
                                                        <div class="eprx--block__content border_AllRadius insurance--body border_color_blue" >
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Shape :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                           
                                                                            {{item?.DrugDescription?.Shape ? item?.DrugDescription?.Shape : '--'}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Color Front :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                            
                                                                            {{item?.DrugDescription?.Color_Frnt ? item?.DrugDescription?.Color_Frnt : '--'}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Color Back :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                           
                                                                            {{item?.DrugDescription?.Color_Back ? item?.DrugDescription?.Color_Back : '--'}}
                                                                        </span>
                                                                    </div>
                                                                </div><br>
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Side1 :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                            
                                                                            {{item?.DrugDescription?.ID_FRONT ? item?.DrugDescription?.ID_FRONT : '--'}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Side2 :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                            
                                                                            {{item?.DrugDescription?.ID_BACK ? item?.DrugDescription?.ID_BACK : '--'}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Flavours :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                       
                                                                            {{item?.DrugDescription?.FlavorName ? item?.DrugDescription?.FlavorName : '--'}}
                                                                        </span>
                                                                    </div>
                                                                </div><br>
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Scoring :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                            {{item?.DrugDescription?.ScoringName ? item?.DrugDescription?.ScoringName : '--'}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <label>Coating :&nbsp;&nbsp;</label>
                                                                        <span class="label--data">
                                                                            {{item?.DrugDescription?.CoatingName ? item?.DrugDescription?.CoatingName : '--'}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <!-- </div> -->
                                        </div>
                                    </ng-template>
                            </div>
                            <div *ngIf="!(rxheader['hName'] === 'NDC#')">
                                {{item[rxheader['hName']]}}
                            </div>
                         </ng-template>
                    </wj-flex-grid-column>
                    <!-- <ng-template wjFlexGridDetail [maxHeight]="200"  #dp="wjFlexGridDetail" let-item="item">
                        <div class="eprx--block__content transmit--block">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6 trasmit--rx text-display-notes">
                                            <label> GTIN#</label>
                                            <div class="label--data font-weight-bold">
                                                {{item?.GTIN ? item?.GTIN : '--'}}
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6 trasmit--rx text-display-notes">
                                            <label> Serial#</label>
                                            <div class="label--data font-weight-bold">
                                                {{item?.SerialNo ? item?.SerialNo : '--'}}
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6 trasmit--rx text-display-notes">
                                            <label> Lot#</label>
                                            <div class="label--data font-weight-bold">
                                                {{item?.LotNum ? item?.LotNum : '--'}}
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6 trasmit--rx text-display-notes">
                                            <label>Expiration Date</label>
                                            <div class="label--data font-weight-bold">
                                                {{item?.LotExpiryDtTm ? item?.LotExpiryDtTm : '--'}}
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6 trasmit--rx text-display-notes">
                                            <label> DVrf?</label>
                                            <div class="label--data font-weight-bold">
                                                {{item?.DPV}}
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6 trasmit--rx text-display-notes">
                                            <label> User</label>
                                            <div class="label--data font-weight-bold">
                                                {{item?.UserId}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 sol-xs-6 edit--drug text-center" *ngIf="item.ImgUrl">
                                    <span class="d-inline ml-0 drug_description_tooltip_OnImg"
                                                    [ngbTooltip]="instructToolTip" tooltipClass="search-tooltip_DrugImg" triggers="mouseover:mouseleave" [autoClose]="true"
                                                    placement="left">
                                                    <img [alt]="'Drug Image'" [src]="item.ImgUrl" class="rx-tablate--preview" />
                                                </span>
                                                <ng-template #instructToolTip>
                                                    <div>
                                                        <div class="row-text-left"
                                                            *ngIf="!(item.DrugDescription?.Descrip)">
                                                            <div class="col-md-12">
                                                                <label class="margin_bottom_1rem text-decoration_underline">Drug
                                                                    Description:</label><br>
                                                            </div>
                                                            <div class="col-md-6">
                                                                No Description available
                                                            </div>
                                                        </div>
                                                        <div class="row text-left"
                                                            *ngIf="(item.DrugDescription?.Descrip)">
                                                            <div class="col-md-12">
                                                                <label class="margin_bottom_1rem text-decoration_underline">Drug
                                                                    Description:</label>
                                                            </div>
                                                            <div><br></div>
                                                            <div class="col-md-6">
                                                                <label>Shape :</label>
                                                                <span>
                                                                    {{item.DrugDescription?.Shape ? item.DrugDescription?.Shape : "--"}}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Coating :</label><span>
                                                                    {{item.DrugDescription?.CoatingName? item.DrugDescription?.CoatingName : "--"}}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Color Front :</label><span>
                                                                    {{item.DrugDescription?.Color_Frnt? item.DrugDescription?.Color_Frnt : "--"}}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Color Back :</label><span>
                                                                    {{item.DrugDescription?.Color_Back? item.DrugDescription?.Color_Back : "--"}}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Side 1 :</label><span>
                                                                    {{item.DrugDescription?.ID_FRONT? item.DrugDescription?.ID_FRONT : "--"}}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Side 2 :</label><span>
                                                                    {{item.DrugDescription?.ID_BACK?item.DrugDescription?.ID_BACK : "--"}}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Scoring :</label><span>
                                                                    {{item.DrugDescription?.ScoringName? item.DrugDescription?.ScoringName : "--"}}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Flavours :</label><span>
                                                                    {{item.DrugDescription?.FlavorName?item.DrugDescription?.FlavorName : "--"}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="!item.ImgUrl">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-display-notes"><label class="text-decoration_underline">Drug Description:</label></div><div><br></div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-display-notes">
                                            <label>Shape</label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.Shape ? item.DrugDescription?.Shape : "--"}}</div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-display-notes">
                                            <label>Color Front</label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.Color_Frnt? item.DrugDescription?.Color_Frnt : "--"}}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-display-notes">
                                            <label>Color Back </label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.Color_Back? item.DrugDescription?.Color_Back : "--"}}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-display-notes">
                                            <label>Side 1</label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.ID_FRONT? item.DrugDescription?.ID_FRONT : "--"}}</div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-display-notes">
                                            <label>Side 2</label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.ID_BACK?item.DrugDescription?.ID_BACK : "--"}}</div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-display-notes">
                                            <label>Flavours</label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.FlavorName? item.DrugDescription?.FlavorName : "--"}}
                                            </div>
                                        </div>
        
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-display-notes">
                                            <label>Scoring</label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.ScoringName? item.DrugDescription?.ScoringName : "--"}}
                                            </div>
                                        </div>
        
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-display-notes">
                                            <label>Coating</label>
                                            <div class="label--data">
                                                {{item.DrugDescription?.CoatingName? item.DrugDescription?.CoatingName : "--"}}
                                            </div>
                                        </div>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    </ng-template> -->
                    
                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                </wj-flex-grid>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <!-- <button class="hotkey_success" (click)="openAddEditIOUPopup('AddPartial')" appShortcutKey
                    [AltKey]="'a'"><span>A</span>Add Partial Dispensing Record
                </button>
                <button class="hotkey_success" (click)="openAddEditIOUPopup('AddComplete')" appShortcutKey [AltKey]="'c'"><span>C</span>Complete Dispensing
                </button> -->
                <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span>Close
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #DELETEIOU let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure, you want to delete?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteIOU()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="n"> <b>N</b>No</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #IOUAddEdit let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!isEdit">Add Dispensed Quantity</h4>
        <h4 class="modal-title" *ngIf="isEdit">Edit Dispensed Quantity</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal('cancel', null)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 erx--body bottom_0_3rem">
                <div class="seconday-search--grid">
                    <div class="row">
                        <div class="col-4 color_cyan">
                            Drug</div>
                        <div class="col-8">
                            <div class="text-right">
                                <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for Equv ;
                                    </span><b class="color_orange">Shift + <i
                                            class="far fa-lg fa-long-arrow-alt-right color_orange"></i></b><span> for Gen
                                        Equv</span></div>
                            </div>
                        </div>
                    </div>
                    <app-searchbox-global id="iouDrug" [RxSelectId]="'DrugPres'" class="common_global_search_max_content_display" [fromIOU]="true"
                        [SearchFor]="'Drug'" [IsAddNew]="false" [DisableSerchFil]="true" [IsHideDrugFilters]="true" [InputValue]="drugInputValue"
                        [DisableRadioButtons]="true" (SelectedDrug)="selectDrug($event)" [ClearInputValue]="drugInputValue">
                    </app-searchbox-global>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 padding--right__7">
                <eprx-input [LabelText]="'Drug NDC Code'" [PlaceHolder]="'_____-____-__'"
                    [ControlName]="'drugNDC'" [FormGroupName]="IOUAddEditFG" [MaxLength]="13" [MinLength]="10"
                    [MaskPattern]="'AAAA0-0000-00'" [ReadOnly]="true"
                    [RxSelectId]="'DrugCode'" [DropSpclChar]="true">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <eprx-input [LabelText]="'Act Disp Qty'" [PlaceHolder]="'Act Disp Qty'"
                            [ControlName]="'actDispQty'" [FormGroupName]="IOUAddEditFG" [InputType]="'NUMBER'" [DecimalValues]="3"
                            [MaskPattern]="'099999.000'" [RxSelectId]="'actDispQuantity'" [DropSpclChar]="false">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                        <eprx-input [LabelText]="'Owed Qty'" [PlaceHolder]="'Owed Qty'"
                            [ControlName]="'owedQty'" [FormGroupName]="IOUAddEditFG" [InputType]="'NUMBER'" [DecimalValues]="3"
                            [MaskPattern]="'099999.000'" [RxSelectId]="'owedQuantity'" [DropSpclChar]="false">
                        </eprx-input>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--right__7">
                <eprx-text-area [RxSelectId] ="'iouNote'" [LabelText]="'Notes/Comments'" [ControlName]="'comments'" [FormGroupName]="IOUAddEditFG"
                [PlaceHolder]="'Notes / Comments'" [Rows]="2">
              </eprx-text-area>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 padding--right__7">
                <eprx-input [LabelText]="'GTIN#'" [PlaceHolder]="'GTIN#'"
                            [ControlName]="'gtin'" [FormGroupName]="IOUAddEditFG"
                            [RxSelectId]="'gtinId'" [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 padding--right__7">
                <eprx-input [LabelText]="'Serial#'" [PlaceHolder]="'Serial#'"
                            [ControlName]="'serial'" [FormGroupName]="IOUAddEditFG"
                            [RxSelectId]="'serialId'" [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 padding--right__7" style="padding-left:2.2rem">
                <div class="row">
                    <div class="col-md-7 task-top pr-0 pl-0">
                        <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'startdate'" [PlaceHolder]="''"
                            [ControlName]="'startdate'" [LabelText]="'Start Date/Time'" [FormGroupName]="IOUAddEditFG" [MinDate]=""
                            [ErrorDefs]="{required: 'Required', inValid:'Invalid Date', pattern: 'Invalid Date'}"
                            [MarkAsTouched]="IOUAddEditFG?.controls?.startdate?.touched" [IsRequired]="true">
                        </eprx-date-picker>
                    </div>
                    <div class="col-md-5 btn-time-pick align-time btn-meridian pl-0 pr-0">
                        <ngb-timepicker [(ngModel)]="time" [required]="true" (ngModelChange)="timeValue($event)">
                        </ngb-timepicker>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="hotkey_success" (click)="cancelAddEditIOU()" appShortcutKey [AltKey]="'l'"><span>L</span>Cancel
        </button>
    </div>
</ng-template>
