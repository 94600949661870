import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-edit-drug",
    templateUrl: "./edit-drug.component.html"
})
export class EditDrugComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
