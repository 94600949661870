import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject,  Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
  selector: "[appChangeDebounce]"
})
export class ChangeDebounceDirective implements OnInit, OnDestroy {

    @Input() debounceTime = 300;
    @Output() debounceChange = new EventEmitter();
    private clicks = new Subject();
    private subscription: Subscription;

    constructor() { }

    ngOnInit() {
      this.subscription = this.clicks.pipe(
        debounceTime(this.debounceTime)
      ).subscribe(e => this.debounceChange.emit(e));
    }

    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

    @HostListener("keyup", ["$event"])
    clickEvent(event) {
      event.preventDefault();
      event.stopPropagation();
      this.clicks.next(event);
    }

}
