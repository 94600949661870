import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "settingskeys" })
export class SettingKeysPipe implements PipeTransform {
    transform(value, searchKey): any {
        const datas: any = [];

        if (value) {
            for (const rxkey in searchKey) {
                if (value[searchKey[rxkey]]) {
                    for (const key in value[searchKey[rxkey]]) {
                        if (key) {
                            datas.push(
                                value[searchKey[rxkey]][key]
                            );
                        }
                    }
                }
            }
        }
        return datas;
    }
}
