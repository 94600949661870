<span *ngIf="(dscsaInfo || DscsaList?.length) && barcodeValidation" [ngbTooltip]="dscsaInfo_tooltip" container="body" class="d-inline"  triggers="mouseover:mouseleave" [autoClose]="true" [tooltipClass]="barcodeValidation && barcodeValidation == 1 ? 'dscsa-check-tooltip' : 'dscsa-uncheck-tooltip'" placement="right">
    <i *ngIf="barcodeValidation" class="fa-kit fa-dscsa-1 mt-1" [ngClass]="(FromScreen == 'Captured from Rx Entry' || FromScreen == 'Captured from Edit Rx') ? 'font_size_1_8rem' : 'font_size_1_4rem'" [ngStyle]="{color: barcodeValidation == 1 ? '#51a351' : '#f7941d'}"></i> 
<ng-template #dscsaInfo_tooltip>
    <div class="row text-left" *ngIf="DscsaList?.length">
            <ul class="" *ngFor="let list of DscsaList; let i=index;">
                <li><span class="font-weight-bold "> Title : </span>{{list?.UIScreen ? list?.UIScreen : FromScreen ? FromScreen  : '--'}} </li>
                <li><span class="font-weight-bold "> Run Date Time : </span>{{list?.SerializedOn ? (getSerializedOnDate(list?.SerializedOn) | uppercase) : '--'}} </li>
                <li><span class="font-weight-bold "> Drug Lot# : </span>{{list?.LotNumber ? list?.LotNumber : '--'}} </li>
                <li><span class="font-weight-bold "> Lot Expiration Date : </span> {{list?.LotExpDate ? (list?.LotExpDate | date:'MM/dd/yyyy') : '--'}} </li>
                <li><span class="font-weight-bold "> GTIN : </span> {{list?.Gtin ? list?.Gtin : '--'}} </li>
                <li><span class="font-weight-bold "> Serial# : </span>{{list?.SerialNumber ? list?.SerialNumber :'--'}} </li>
            </ul>
    </div>
    <div class="row text-left" *ngIf="dscsaInfo">
        <ul class="">
            <li><span class="font-weight-bold "> Title : </span>{{dscsaInfo?.UIScreen ? dscsaInfo?.UIScreen : FromScreen ? FromScreen  : '--'}} </li>
            <li><span class="font-weight-bold "> Run Date Time : </span>{{SerializedOn ? (SerializedOn | uppercase) : '--'}} </li>
            <li><span class="font-weight-bold "> Drug Lot# : </span>{{dscsaInfo?.LotNumber ? dscsaInfo?.LotNumber : '--'}} </li>
            <li><span class="font-weight-bold "> Lot Expiration Date : </span> {{dscsaInfo?.LotExpDate ? (dscsaInfo?.LotExpDate | date:'MM/dd/yyyy') : '--'}} </li>
            <li><span class="font-weight-bold "> GTIN : </span> {{dscsaInfo?.Gtin ? dscsaInfo?.Gtin : '--'}} </li>
            <li><span class="font-weight-bold "> Serial# : </span>{{dscsaInfo?.SerialNumber ? dscsaInfo?.SerialNumber :'--'}} </li>
        </ul>
</div>
</ng-template>
</span>