import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import { RxService, AlertService, CommonService } from "src/app/services";
import { DUR, RxAuditLog, SystemData, RxModel } from "src/app/models";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MsgConfig } from "src/app";
import { NewAuditUtils, ValidateFormFieldsUtils } from "src/app/utils";
import { RxStore } from "src/app/store";
import { Observable } from "rxjs";
@Component({
    selector: "app-trans-dur",
    templateUrl: "./trans-dur.component.html"
})
export class TransDurComponent implements OnInit {

    @Input() transDetails: any;
    @Input() rxno: any;
    @Input() refId: any;
    @Input() systemData: SystemData;
    @Input() openRxDur: boolean;
    @Input() rxType: string;
    @ViewChild("info", { static: true }) info: any;
    @ViewChild("DURADD", { static: true }) DURADD: any;
    @ViewChild("openDURPopup", { static: true })
    openDURPopup: any;
    @Output() IsPopupClosed = new EventEmitter();
    @Input() rxFG$: Observable<FormGroup>;
    rxInfo:any;
    rxInfo$: Observable<RxModel>;
    transActnType: string | null = null;
    actvHeaders = [
        "Reason",
        "Significance",
        "Other.PH",
        "Prv.Fill",
        "Fill Qty",
        "DB",
        "Other Pres.",
        "Comment",
    ];
    DURWJ: CollectionView = new CollectionView([]);
    durData: any;
    editMode: boolean;
    originalData: any;
    DurFG: any;
    modalRef: any;
    formGroupInvalid: boolean;
    modelRef: any;
    PrescrirefillId : any;
    constructor(private _rxService: RxService, private _fb: FormBuilder, private _comnSvc: CommonService,private _nwAudtUtl: NewAuditUtils,
         private _modalService: NgbModal, private _alrtServ: AlertService, private _validUtils: ValidateFormFieldsUtils, private _rxStore: RxStore, private _cdr: ChangeDetectorRef) {
            this.rxInfo$ = this._rxStore.rxInfo$;
          }

    ngOnInit() {
        if (this.transDetails && this.transDetails["transmissionDetails"]
            && this.transDetails["transmissionDetails"]["lstDUR"] && !this.openRxDur) {
            this.generateWJ(this.transDetails["transmissionDetails"]["lstDUR"]);
        } else if (this.openRxDur) {
            this.systemData = this._comnSvc.systemData$["source"]["value"];
            this.getDURData();
        }
    }

    generateWJ(data) {
        if (data && data.length > 0) {
            this.DURWJ = new CollectionView(
                data.map(d => {
                    const j = {};
                    j["Reason"] = d.REASON;
                    j["Significance"] = d.SIGNIFICANCE;
                    j["Other.PH"] = d.OTHERPH;
                    j["Prv.Fill"] = d.PREVFILL;
                    j["Fill Qty"] = d.QTY;
                    j["DB"] = d.DBI;
                    j["Other Pres."] = d.OTHERPRES;
                    j["Comment"] = d.DURTEXT;
                    return j;
                })
            );
        }
    }

    async getDURData() {
        this.durData = await this._rxService.getDurValues(this.rxno,this.refId).toPromise();
        if (this.durData && this.durData.length > 0) {
            this.commonForDurOpen();
            //this.openDURPopUp("Edit");
        } else {
            this.openDURPopUp("add");
        }
    }

    validateCoAgent() {
        if (this.DurFG.controls["CoAgentNum"].value) {
            this.DurFG.controls["CoAgentIdType"].setValidators(Validators.required);
            this.DurFG.controls["CoAgentIdType"].markAsTouched();
        } else  {
            this.DurFG.controls["CoAgentIdType"].setValidators(Validators.nullValidator);
            this.DurFG.controls["CoAgentIdType"].markAsTouched();
        }
    }

    openDURPopUp(type) {
        this.DurFG = this._fb.group(new DUR());
        if (type === "add") {
            this.editMode = false;
        } else {
            this.editMode = true;
            this.DurFG.patchValue(this.durData[0]);
            this.originalData = Object.assign({}, this.DurFG.getRawValue());
        }
        this.modalRef = this._modalService.open(this.DURADD, {centered: true, size: "lg", keyboard: false, backdrop: false});
    }

    checkDurFgHasValue() {
        const cntrlData = ["ServReasonId", "ProfServId", "ServResultId", "EffortLvlId", "ClinicalSignfId", "CoAgentNum", "CoAgentIdType"];
        let hasValue = false;
        cntrlData.map(cntrl => {
            if (this.DurFG.value[cntrl]) {
                hasValue = true;
            }
        });
        return hasValue;
    }

    enterKeyDownEv(event, type) {
        if (event) {
            event.preventDefault();
            if (type === "addDUR") {
                this.addDUR();
            } else if (type === "Info" && this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
        }
    }

    async addDUR() {

        this.DurFG.controls["IsActive"].setValue(true);
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                 this.modelRef = this._modalService.open(this.info , {centered: true, keyboard: false, backdrop : false, windowClass:"large--content"});
            } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
                this.formGroupInvalid = true;
                this._validUtils.validateAllFormFields(this.DurFG);
            } else {
                if (this.modalRef) {
                this.modalRef.close();
                }
                this.closeModal();
                this.DurFG.controls["RxId"].patchValue(this.rxno);
                this.formGroupInvalid = false;
                let resp;
                const data: any[] = [];
                const durData = this.DurFG.getRawValue();
                durData.RxId = this.rxno;
                data.push(durData);
                this.PrescrirefillId = this.DurFG.controls.IsRefillSpecific && this.DurFG.controls.IsRefillSpecific.value ? this.refId : null;
                const  payload = {
                    prescriptionNo : this.rxno,
                    prescrefillId : this.PrescrirefillId,
                    durInfoList : data
                }
                if (this.editMode) {
                    this.DurFG.controls.PrescrefillId.patchValue(this.DurFG.controls.IsRefillSpecific && this.DurFG.controls.IsRefillSpecific.value ? this.refId : null)
                     resp = await this._rxService.editDURValues([this.DurFG.getRawValue()], this.durData[0]["Id"], this.PrescrirefillId).toPromise();
                     this.saveAuditChange(resp, this.originalData, this.DurFG.getRawValue());
                } else {
                    resp = await this._rxService.addDURValues(payload).toPromise();
                        this.saveAuditChange(resp, null, this.DurFG.getRawValue());
                }
                if (resp) {
                    this._alrtServ.success(MsgConfig.SAVE_SUCCESS);
                } else {
                    this._alrtServ.error("DUR Not Saved.");
                }
            }
        } else {
            this.formGroupInvalid = true;
        }
    }
    commonForDurOpen() {
        this.modelRef = this._modalService.open(this.openDURPopup, {
            size: "lg",
            windowClass: "max-modal-78",
            backdrop: false,
            keyboard: false,
            centered: true
        });
    }
    closeDURModal() {
        this.transActnType = null;
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopupClosed.emit(null);
        this._cdr.detectChanges();
    }
    saveAuditChange(res, orginal, changed) {
        if (res) {
        const durAudtMdl = new RxAuditLog().durAuditModel;
        this._nwAudtUtl.saveAuditChange(
            orginal,
            changed,
            "DUR",
            "Rx",
            this.refId,
            durAudtMdl
          );
        }
    }
    closeModal() {
        this.IsPopupClosed.emit(null);
    }

}
