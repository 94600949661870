import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";
import { DrugBucket } from "../models";
@Injectable()
export class BucketService {
    constructor(private _http: HttpClient) {}

    getBucketsInfo(searchData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Inventory_BucketSearch,
            searchData
        );
    }
    // getBucketsInform(): Observable<any> {
    //     return this._http.get<any>(
    //         constant.GET_Inventory_Buckets
    //     );
    // }

    getBucketDropdowns(): Observable<any> {
        return this._http.get<any>(constant.GET_Inventory_BucketDropDowns);
    }

    getBucketsForDropdowns(drugId?): Observable<any> {
        return this._http.get<any>(constant.GET_Inventory_Buckets.replace("{drugId}", "" + drugId));
    }

    CompoundDrugBucketsInfo(inpData) {
        return this._http.post<any>(
            constant.POST_Inventory_CompoundDrugBucketsInfo,
            inpData
        );
    }

    managBucketInv(payload): Observable<any> {
        return this._http.put<any>(constant.PUT_BUCK_INV_MANAGE , payload);
    }

    createBucket(inpData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Inventory_CreateBucket,
            inpData
        );
    }

    updateBucket(inpData: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Inventory_UpdateBucket,
            inpData
        );
    }

    deleteBucket(bucketId: any): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Inventory_DeleteBucket.replace(
                "{bucketId}",
                "" + bucketId
            ),
            {}
        );
    }

    getBucketById(buckId: string): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_BucketbyId.replace("{bucketId}", "" + buckId)
        );
    }

    getBucketHistbyId(data: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Inventory_BucketHistbyId,
            data
        );
    }

    getBucketLogs(searchData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Inventory_BucketLogsSearch,
            searchData
        );
    }

    getBucketDruglist(searchText: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_BucketDrugList.replace(
                "{drugName}",
                "" + searchText
            )
        );
    }

    getDrugQTYbyId(drugId: string): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_DrugQTYbyId.replace("{drugId}", "" + drugId)
        );
    }

    getDrugQTYinHand(drugId: string, bucketId: string): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_DrugQTYInHand.replace(
                "{drugId}",
                "" + drugId
            ).replace("{bucketId}", "" + bucketId)
        );
    }

    bucketTransfer(inpData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Inventory_TransferBucket,
            inpData
        );
    }

    getBucketCodeSuggest(value: any) {
        return this._http.get<any>(
            constant.GET_Inventory_BucketCodeSuggest.replace(
                "{searchText}",
                "" + value
            )
        );
    }

    getBucketNameSuggest(value: any) {
        return this._http.get<any>(
            constant.GET_Inventory_BucketNameSuggest.replace(
                "{searchText}",
                "" + value
            )
        );
    }

    getSpecificTyepSuggest(value: any) {
        return this._http.get<any>(
            constant.GET_Inventory_SpecificTypeSuggest.replace(
                "{searchText}",
                "" + value
            )
        );
    }

    getDrugNameSuggest(value: any) {
        return this._http.get<any>(
            constant.GET_Inventory_DrugNameSuggest.replace(
                "{searchText}",
                "" + value
            )
        );
    }

    isBucketAlreadyExist(BUcketCode: string): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Inventory_IsBucketCodeExists.replace(
                "{BucketCode}",
                "" + BUcketCode
            )
        );
    }

    isBucketNameAlreadyExist(Bucket: any): Observable<boolean> {
        return this._http.post<boolean>(
            constant.POST_Inventory_IsBucketNameExists,
            Bucket
        );
    }

    getBucketByDrugId(drugId: any, buckId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Drug_Bucket_BucketByDrugid.replace(
                "{drugId}",
                "" + drugId
            ).replace("{bucketId}", "" + buckId)
        );
    }

    getInventoryPreferredVendorId(payLoad: any): Observable<any> {
        return this._http.post<any>(constant.POST_Inventory_PreferredVendorID, payLoad);
    }

    putInventoryPreferredVendorPrice(data): Observable<any> {
        return this._http.put<any>(constant.PUT_Inventory_PreferredVendorPrice , data);
    }

    getInventoryLatestDrugLotExpiryDate(data): Observable<any> {
        return this._http.post<any>(constant.POST_INVENTORY_LatestDrugLot , data);
    }
}
