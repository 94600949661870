export class DocumentScanDetails
{
    Prescnumber: number;
    Refillnumber: number;
    PatientId: number;
    PatientName: string;
    IsVerified: boolean;
    IsPickedUp: boolean;
    DocumentId: string;
    ReferenceNo: string[];
    ScanDate: Date;
    DocTypeExtn: string;
    PrintWithLabel: boolean;
    BarcodeInfo: string;
    BarcodeDoc: string;
    DocumentName: any;
    DrugPi: any;
    IsBarcodePresent: boolean;
}

export class DocumentScanInputParams {
    BarcodeDoc: string = null;
    DocTypeExtn: string = null;
    SubCategoryId: number = null;
}

export class DocumentQueue {
    Id: number = null;
    TenantId: number = null;
    DocumentId: number = null;
    FaxDocumentId: string = null;
    PatientId: number = null;
    CategoryId: number = null;
    SubCateogryId: number = null;
    DeliveryPriorityId: number = null;
    FillRx: string = null;
    ScanDate: Date = null;
    MarkedBy: String = null;
    Title: string = null;
    Comments: string = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    DocData: string = null;
    DocTypeExtn: string = null;
    IsSelected: boolean = false;
    PatientName: any;
    UniqueDocumentId: string;
    FileName: string;
    UserName: string;
    ClassStyle: string;
    EnableCheckBox: boolean;
}
