<ng-template #openPopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <span header class="height:4rem;">
            <div class="row">
                        <h4 class="padding_left_14px margin_top_5px">Send Refill Due Message</h4>
                        <button   *ngIf = "!groupView && reportData.RxData.length" (click)="openModal(true)"
                        class="hotkey_primary ml-77 ">
                            Group By Patient
                        </button>
                        <button   *ngIf = "!groupView && !reportData.RxData.length"
                        class="inactive ml-77">
                            Group By Patient
                        </button>
                        <button  *ngIf = "groupView"  (click)="openModal(false)"
                        class="hotkey_primary ml-77">
                           Individual
                        </button>
            </div>
        </span>

        <span body>
            <div class="eprx--block__content min-height_29rem" *ngIf= "reportData.RxData.length && groupView">
                <wj-flex-grid #flex [columnPicker]="'refillDueMsgOuterWj'" [itemsSource]="refilDueDatawj" [isReadOnly]="true"
                [selectionMode]="'Row'" [headersVisibility]="'Column'"
                >
                <wj-flex-grid-column [header]="''" [width]="40">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-item="item">
                            <div>
                                <i *ngIf="!dp.isDetailVisible(row)" (click)="dp.showDetail(row,true);"
                                class="far fa-plus-circle actions p-1"></i>
                            <i  *ngIf="dp.isDetailVisible(row)" (click)="dp.hideDetail(row); "
                                class="far fa-minus-circle actions p-1"></i>
                            </div>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [width]="35" [header]="''">
                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
                        <span class="left_0_5rem">
                            <input class="form-check-input" type="checkbox" id="i" name="" [(ngModel)]="allSelect"
                                (click)="selectAllRx($event?.target?.checked, dp, 'groupView')" [checked]="allSelect" />
                            <label class="form-check-label" for="i"></label>
                        </span>
                    </ng-template>
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                        <span>
                            <input class="form-check-input m-1" type="checkbox"
                        [(ngModel)]="item.IsSelected" [checked]="item.IsSelected" [id]="item.RowId"
                        (change)="selectedRow($event?.target?.checked, item['patientNo'],'OuterGrid',dp)" />
                    <label class="form-check-label" [for]="item.RowId"></label>
                        </span>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [class]="wj-cell-height"
                    [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                    *ngFor="let rxheader of wjHeaders; let i = index">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div *ngIf="(rxheader['hName'] !=='Is Not Pickup Remainder' && rxheader['hName'] !== 'Email')"  title = {{item[rxheader.hName]}}>
                        {{ item[rxheader['hName']] }}
                    </div>
                    <div class="no-text-Transform" [title]="rxheader['hName']" *ngIf="rxheader['hName'] === 'Email'">
                        {{item[rxheader['hName']]}}
                    </div>
                    <div *ngIf="rxheader['hName'] ==='Is Not Pickup Remainder'" title = {{item[rxheader.hName]}} class="text-center">
                        <span *ngIf="item['Is Not Pickup Remainder']">
                            <i class="far fa-check color_green"  title="Yes"></i>
                        </span>
                        <span *ngIf="!item['Is Not Pickup Remainder']">
                            <i class="far fa-times  fa-uncheck color_red" title="No"></i>
                        </span>
                    </div>
                    </ng-template>
                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                        <div title={{rxheader.hName}}>
                            {{ rxheader.hName }}
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
                <ng-template wjFlexGridDetail #dp="wjFlexGridDetail" let-item="item">
                    <wj-flex-grid #insflex [columnPicker]="'refillDueMsgInnerWj'" [itemsSource]="showData(item)"
                        [isReadOnly]="true" class="wjm-grd-custom-height-58"
                        [headersVisibility]="'Column'" class="text_transform"
                        >
                        <wj-flex-grid-column [header]="''" [width]="41">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-item="item">
                                <div class="left_8px">
                                    <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                                        [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                                        (click)="selectedRow($event?.target?.checked, item['PrescrefillId'],'InnerGrid', dp)" />
                                    <label class="form-check-label" [for]="item.RowId"></label>
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                            [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders2; let i = index"
                            [width]="rxheader['width']">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div *ngIf="!(rxheader['hName'] ==='IsActive?')" title = {{item[rxheader.hName]}}>
                                {{ item[rxheader['hName']] }}
                            </div>
                            <div *ngIf="rxheader['hName'] ==='IsActive?'" title = {{item[rxheader.hName]}} class="text=center">
                                <span *ngIf="item.IsActive">
                                    <i class="far fa-check color_green"  title="Active"></i>
                                </span>
                                <span *ngIf="!item.IsActive">
                                    <i class="far fa-times  fa-uncheck color_red" title="Inactive"></i>
                                </span>
                            </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                </ng-template>
                </wj-flex-grid>
            </div>
            <div class="eprx--block__content min-height_29rem" *ngIf= "reportData.RxData.length && !groupView">
            <wj-flex-grid #flex [columnPicker]="'refillDueMsgIndividualWj'" [itemsSource]="refilDueDataIndividualView"
            [isReadOnly]="true" class="wjm-grd-custom-height-58"
            [headersVisibility]="'Column'" class="text_transform"
            >
            <wj-flex-grid-column [header]="''" [width]="41">
                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
                    <span class="left_0_5rem">
                        <input class="form-check-input" type="checkbox" id="i" name="" [(ngModel)]="SelectAllRxs"
                            (click)="selectAllRx($event?.target?.checked, flex, 'Individual')" [checked]="SelectAllRxs" />
                        <label class="form-check-label" for="i"></label>
                    </span>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div class="margin_left_3px">
                        <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                            (click)="selectedRow($event?.target?.checked, item['PrescrefillId'],'singleGrid', flex)" />
                        <label class="form-check-label" [for]="item.RowId"></label>
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders3; let i = index"
                [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <div *ngIf="!(rxheader['hName'] ==='IsActive?')" title = {{item[rxheader.hName]}}>
                    {{ item[rxheader['hName']] }}
                </div>
                <div *ngIf="rxheader['hName'] ==='IsActive?'" title = {{item[rxheader.hName]}} class="text-center">
                    <span *ngIf="item.IsActive">
                        <i class="far fa-check color_green" title="Active"></i>
                    </span>
                    <span *ngIf="!item.IsActive">
                        <i class="far fa-times  fa-uncheck color_red" title="Inactive"></i>
                    </span>
                </div>
                </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
        </div>
            <div class="eprx--block__content min-height_29rem eprx--block__footer" *ngIf="!reportData.RxData.length">
                <div>
                    <span></span>
                    <i class="fa fa-file"></i><br/>
                    No Data Found!
                </div>
            </div>
        </span>
        <div footer class="col-12">
        <div  class="row">
            <div class="col-11">
                <div class="col-12">
                    <label><b>Total Rx Count :</b> </label>
                    <span class="font-weight-bold"> {{reportData?.TotalRxCount ? reportData.TotalRxCount : 0}}</span>
                </div>
                <div class="col-12 margin_top_0_3rem">
                    <label><b>Total Patients&nbsp;:</b> </label>
                    <span class="font-weight-bold"> {{reportData?.TotalPatientCount ? reportData.TotalPatientCount : 0}}</span>
                </div>
            </div>
            <div class="col-2">
            <div class="row">
            <div class=""  >
                <button *ngIf="reportData.RxData.length"  ngbAutofocus  appShortcutKey [AltKey]="'s'" (click)="CheckforMobileNumber()"
                (keydown.enter)="CheckforMobileNumber()" (keydown.tab)="CheckforMobileNumber()" class="hotkey_success"
                > <span>S</span>
                    Send
                </button>
                <button  ngbAutofocus  *ngIf="!reportData.RxData.length" class="p-0"  appShortcutKey [CtrlKey]="'s'" class="inactive"
                > <span>S</span> Send</button> 
            <button  class="hotkey_success" appShortcutKey [AltKey]="'c'"
            (click)="closeModal()" [ngClass] = "reportData.RxData.length ? 'cancel-left-align-25' : 'cancel-left-align-7'"
            (keydown.enter)="closeModal()" (keydown.tab)="closeModal()">
                <span>C</span> Cancel
            </button>
            </div>
            </div>
        </div>
        </div>
        </div>
    </app-custom-modal>
</ng-template>
<ng-template #exceptionPopup let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Message Exception</h4>
        <button type="button"  [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col font-size-14" [innerHTML]="messageToDisplay">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button footer   class="hotkey_primary" (click)="sendRefilDueMessage();closeModal()" appShortcutKey InputKey="o"> <b>O</b> OK </button>
    </div>
</ng-template>
