import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services';
import { AuditLogUtils, NRxUtils } from 'src/app/utils';

@Component({
  selector: 'app-verification-checks',
  templateUrl: './verification-checks.component.html',
  styleUrls: ['./verification-checks.component.scss']
})
export class VerificationChecksComponent implements OnInit {
  verfData: any;
  verfMsg: any;
  @ViewChild("VERFCONFIRMATION", { static: true })
  VERFCONFIRMATION: any;
  @Input() rxFG: any;
  @Input() flow: any;
  @Output()IsPopUpClosed = new EventEmitter();

  constructor(private _modalService: NgbModal,  private _nrxUtils: NRxUtils, private _auditUtils: AuditLogUtils) { }

  ngOnInit() {
    this.checkVerifications()
  }

  checkVerifications () {
    this.verfData = "";
    this.verfMsg = "";
    if(this.flow !== "ExcludeDPVANDRPH") {
      if (this.rxFG.value["drugPickVerificationStatus"] && (this.rxFG.value["drugPickVerificationStatus"]).toLowerCase() === "p") {
        this.verfData = this.verfData + "D";
        this.verfMsg = this.verfMsg + "Drug Pick verification ";
      }
      if (this.rxFG.value["rph2VerificationStatus"] && (this.rxFG.value["rph2VerificationStatus"]).toLowerCase() === "v") {
        this.verfData = this.verfData + "R";
        this.verfMsg = this.verfMsg + (this.verfMsg && this.verfMsg.length > 0 ?
          " and Pharmacist Verification " : " Pharmacist Verification " );
      }
    }
    if (this.rxFG.value["rph1VerificationStatus"] && (this.rxFG.value["rph1VerificationStatus"]).toLowerCase() === "r") {
      this.verfData = this.verfData + "X";
      this.verfMsg = this.verfMsg + (this.verfMsg && this.verfMsg.length > 0 ?
        " and Rx Verification  " : " Rx Verification " );
    }
    this._modalService.open(this.VERFCONFIRMATION, {centered: true, backdrop: false, windowClass: "large--content"});    
  }

  async removeVerfication(removeVerf) {
    if (removeVerf) {
      await this._nrxUtils.updateVerfStatus(this.rxFG.getRawValue(), this.verfData, this.rxFG, true);
      if (this.verfMsg.includes("Drug Pick verification")) {
          this._auditUtils.getChangedValues(
              null,
              {"DP Verification Status": "Re-Verify"},
              "DP Verification",
              "Rx",
              this.rxFG.value.PrescReFill.Id
          );
      }
      if (this.verfMsg.includes("Pharmacist Verification")) {
          this._auditUtils.getChangedValues(
              null,
              {"Rph Verification Status": "Re-Verify"},
              "Rph Verification",
              "Rx",
              this.rxFG.value.PrescReFill.Id
          );
      }
      if (this.verfMsg.includes("Rx Verification")) {
          this._auditUtils.getChangedValues(
              null,
              {"Rx Verification Status": "Re-Verify"},
              "Rx Verification",
              "Rx",
              this.rxFG.value.PrescReFill.Id
          );
      }
      this.IsPopUpClosed.emit(null);
    } else {
      this.IsPopUpClosed.emit(null);
    }
  }

}
