import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'threefixed'
})
export class ThreefixedPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value ? value.toFixed(3) : "0.000";
  }

}
