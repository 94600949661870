import { CommonService } from 'src/app/services';
import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from "@angular/core";
import { EPatient, SystemData, EPrescriber, PatientInfo } from "../../../models";
import * as moment from "moment";
import * as _ from "lodash";
import { PatientStore } from 'src/app/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DrugBucketUtil } from 'src/app/utils/drug-bucket.util';

@Component({
    selector: "app-rx-patient-card",
    templateUrl: "./rx-patient-card.component.html"
})
export class RxPatientCardComponent implements OnInit, OnChanges {
    refreshInsuId: any;
    systemData: SystemData;
    rxPatientModel: EPatient;
    age: any;
    primaryInsu: any;
    mobile: any;
    telephone: any;
    contactType: any[] = [];
    hasPatientHistory: boolean;
    Is340BStatus:boolean;
    PatientInfo: PatientInfo;
    drugInfo: any;
    isFromErx: any;
    unsubscribe$: Subject<void> = new Subject();
    @Input() rxFG: any;


    primaryInsuFromErxtoRequest: any;
    EditPatientDetails: any;
    isOnChanges = false;
    patientEditData: any;
    primaryInsuFromErxtoRequestForCash: any;
    @Input()
    set primaryInsuFromErxtoRequestObejct(pi: any) {
        if (pi) {
            this.primaryInsuFromErxtoRequest = pi;
        } else {
            this.primaryInsuFromErxtoRequest = {};
        }
    }
    @Input()
    set primaryInsuFromErxtoRequestForCashObejct(cashInfo: any) {
        if (cashInfo) {
            this.primaryInsuFromErxtoRequestForCash = cashInfo;
        } else {
            this.primaryInsuFromErxtoRequestForCash = {}
        }
    }
    @Input()
    set RxPatientInfo(pi: EPatient) {
        if (pi) {
            this.rxPatientModel = pi;
            this.setPackagingPatient();
            if (pi && pi.dob) {
                const dob = moment(pi.dob);
                const years = moment().diff(dob, "year");
                dob.add(years, "years");
                const months = moment().diff(dob, "months");
                dob.add(months, "months");
                this.age = years + " Yrs, " + months + " M";
            } else {
                this.age = null;
            }
            this.primaryInsu = _.filter(this.rxPatientModel.insurance, {
                insuranceid: +this.rxPatientModel.insuranceid
            })[0];
            if (!this.primaryInsu) {
                this.primaryInsu = _.filter(this.rxPatientModel.insurance, {
                    IsCash: true
                })[0];
            }
            if (!this.primaryInsu) {
                this.primaryInsu = _.filter(this.rxPatientModel.insurance, {
                    insurerid: this.systemData["DefaultValuese"][0]["CashInsurerId"]
                })[0];
            }
            if (!this.primaryInsu) {
                this.primaryInsu = _.filter(this.rxPatientModel.insurance, {
                    insuranceid: 10189
                })[0];
            }
        }
    }

    @Input()
    set HasPatientHistoryVisb(ph: boolean) {
        this.hasPatientHistory = ph;
    }

    @Input()
    set DrungInfo(di: boolean) {
        this.drugInfo = di;
    }

    @Input() IsFromErxRequest: any;

    @Input()
    set EditPatientInfo(data: any) {
        if (data) {
            this.updateContactType(data);
        }
    }

    @Input()
    set RefreshInsuId(InsuRef: any) {
        this.refreshInsuId = InsuRef;
        this.refreshInsuranceId();
    }

    @Input()
    set IsFromERx(ifr: any) {
        this.isFromErx = ifr;
    }

    @Input() prescriber: EPrescriber;

    constructor(private _commonServ: CommonService, private _patientStore: PatientStore, private _cdr: ChangeDetectorRef,private _commServ: CommonService) {
        this._commonServ.systemData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.systemData = resp;
                }
            });
        this._patientStore.patientEditInfo$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.patientEditData = resp;
            });
    }

    ngOnInit() {
        if ((!this.PatientInfo || this.IsFromErxRequest) && !this.isOnChanges) {
            this.getEditPatDetails();
            this.isOnChanges = true;
        }
    }
    setPackagingPatient() {
        // To display the Packaging Patient
        if(this.systemData['Interfaces']?.length) {
            let packagingPatient = this.systemData['Interfaces'] && this.systemData['Interfaces'].length && this.systemData['Interfaces'].find((item: any) => {return (item['Id'] === this.rxPatientModel['InterfaceId']) } );
            this.rxPatientModel['packagingPatient'] = null;
            this._cdr.detectChanges();
            this.rxPatientModel['packagingPatient'] = packagingPatient ?   packagingPatient['Name'] : "";
        }
        const settings = this._commServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        this.Is340BStatus = (this.rxPatientModel?.Is340B && settings)? true : false;
    }
    async getEditPatDetails() {
        if (this.rxPatientModel && this.rxPatientModel["patientid"]) {
            // const data = await this._commonServ.getEditPatientInformation(this.rxPatientModel["patientid"]).toPromise();
            this.updateContactType(this.patientEditData);
        }
    }

    refreshInsuranceId() {
        if (this.refreshInsuId) {
            this.primaryInsu = _.filter(this.rxPatientModel.insurance, {
                insuranceid: +this.refreshInsuId
            })[0];
        }
    }
    updateContactType(data) {
        this.contactType = [];
        this.mobile = null;
        this.telephone = null;
        this.PatientInfo = data;
        if (this.PatientInfo && this.PatientInfo["PerPrefContact"] && this.PatientInfo["PerPrefContact"].length) {
            this.PatientInfo["PerPrefContact"].map(val => {
                if (val.IsPreferred) {
                    this.mobile = val.ContactNumber;
                } else {
                    this.contactType.push(val);
                }
            });
        }
        if (this.contactType && this.contactType.length) {
            this.contactType.map(val => {
                if (val.ContactType === "Mobile" && val.ContactNumber) {
                    this.telephone = val.ContactNumber;
                    return;
                } else if (val.ContactType === "Telephone") {
                    this.telephone = val.ContactNumber;
                }
            });
        }
    }

    ngOnChanges(changes?: any) {
        if ((!this.PatientInfo || this.IsFromErxRequest) && !this.isOnChanges) {
            this.isOnChanges = true;
            this.getEditPatDetails();
        }
        if (changes && changes.primaryInsuFromErxtoRequestObejct && changes.primaryInsuFromErxtoRequestObejct.currentValue) {
            /*   this.primaryInsuFromErxtoRequest  = {};
               setTimeout(() => {
                   this.primaryInsuFromErxtoRequest  = changes.primaryInsuFromErxtoRequestObejct.currentValue;
               }, 100);*/
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
