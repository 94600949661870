import { RxUtils } from "src/app/utils/rx.util";
import { Drug } from "./../../../models/patient-Info.model";
import { EditDrugService } from "./../../../services/edit-drug.service";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import { CommonService } from "../../../services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccCategoryE, PaginationModel, RxE } from "src/app/models";
import { PrivMaskGuard } from "src/app/guards";
import { Subscription } from "rxjs";

@Component({
    selector: "app-disp-history",
    templateUrl: "./disp-history.component.html"
})
export class DispHistoryComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    drugEditFG: FormGroup;
    dispHisWJ: CollectionView;
    actvHeaders: string[];
    inActvHeaders: string[];
    Size = 15;
    pageNu = this.paginationModel.pageNumber;
    totalCount: number;
    drugId: number;
    drugInfo: Drug;
    drugName: string;
    startIndex = 0;
    therapeutic: any;
    therapeuticCode: any;
    routeFrom: any;
    focusOnDU: any;
    subscriptions = new Subscription();


    @ViewChild("inventorylist")
    flex: wjcGrid.FlexGrid;
    @ViewChild("Redirect", { static: true })
    Redirect: any;
    accessPrivCate: any;
    rxPrivs: any;
    openOverride: boolean;
    routeToRxInfo: any;
    privType: any;
    categoryId: any;
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.routeBackToPrevScreen();
            }
        }
    }
    constructor(
        private _fb: FormBuilder,
        private _drgSvc: EditDrugService,
        private _actvRoute: ActivatedRoute,
        private _route: Router,
        private _rxutils: RxUtils,
        private _privMaskGuard: PrivMaskGuard,
        private _modalService: NgbModal,
        private _commonSvc: CommonService

    ) {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (params && params.fm) {
                    this.routeFrom = params.fm;
                }
                if (params && params.focus) {
                    if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                        this.focusOnDU = params.focus;
                    }
                }
            }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugId = +params["pid"])
        ));
        this.drugEditFG = this._fb.group({
            drugId: this.drugId,
            pagenumber: this.paginationModel.pageNumber,
            pagesize: this.paginationModel.pageSize,
            Therapeutic: [""],
            TherapeuticCode: [""],
            includeEquivalentDrugs: false
        });
    }

    ngOnInit() {
        this.getData();
    }
    getData() {
        if (this.drugEditFG.value["includeEquivalentDrugs"]) {
            if (this.drugEditFG.value["Therapeutic"] && this.drugEditFG.value["TherapeuticCode"]) {
                this._drgSvc.getDispnsHistory(this.drugEditFG.value).subscribe(resp => {
                    if (resp["TotalCount"] !== 0) {
                        this.generateWJ(resp);
                        this.totalCount = resp["TotalCount"];
                    } else {
                        this.totalCount = 0;
                    }
                });
            } else {
                this.totalCount = 0;
            }
        } else {
            this._drgSvc.getDispnsHistory(this.drugEditFG.value).subscribe(resp => {
                if (resp["TotalCount"] !== 0) {
                    this.generateWJ(resp);
                    this.totalCount = resp["TotalCount"];
                } else {
                    this.totalCount = 0;
                }
            });
        }
    }
    setPage(page: number) {
        this.pageNu = page;
        //  this.startIndex = this.Size * (this.pageNu - 1);
        this.drugEditFG.controls["pagenumber"].setValue(this.pageNu);
        this.drugEditFG.controls["pagesize"].setValue(this.Size);
        //  this.startIndex = 0;
        this.getData();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        // this.startIndex = this.Size * (this.pageNu - 1);
        this.drugEditFG.controls["pagenumber"].setValue(1);
        this.drugEditFG.controls["pagesize"].setValue(this.Size);
        // this.startIndex = 0;
        this.getData();
    }
    setEqui() {
        if (this.therapeutic && this.therapeuticCode) {
            if (this.drugEditFG.value["includeEquivalentDrugs"]) {
                this.drugEditFG.controls["Therapeutic"].setValue(this.therapeutic);
                this.drugEditFG.controls["TherapeuticCode"].setValue(this.therapeuticCode);
            } else {
                this.drugEditFG.controls["Therapeutic"].setValue(null);
                this.drugEditFG.controls["TherapeuticCode"].setValue(null);
            }
            this.getData();
        } else {
            this._drgSvc.getDrugInfo(this.drugId).subscribe(resp => {
                if (resp) {
                    this._commonSvc._drugInfo$.next(resp);
                    this.therapeutic = resp["Drug"]["Therapeutic"];
                    this.therapeuticCode = resp["Drug"]["TherapeuticCode"];
                    if (this.drugEditFG.value["includeEquivalentDrugs"]) {
                        this.drugEditFG.controls["Therapeutic"].setValue(this.therapeutic);
                        this.drugEditFG.controls["TherapeuticCode"].setValue(this.therapeuticCode);
                    } else {
                        this.drugEditFG.controls["Therapeutic"].setValue(null);
                        this.drugEditFG.controls["TherapeuticCode"].setValue(null);
                    }
                }
                this.getData();
            });
        }


    }
    exportExcel() {
        wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
            this.flex,
            {formatItem: (args) =>  args.xlsxCell.formula = null},
            "dispHistory.xlsx"
        );
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    generateWJ(info: any) {
        if (info) {
            this.dispHisWJ = new CollectionView(
                info.DispensedHistory.map((drug, i) => {
                    this.therapeutic = drug.Drug.therapeutic;
                    this.therapeuticCode = drug.Drug.therapeuticcode;
                    const j = {};
                    j["Date Filled"] = moment.utc(drug.PrescReFill.FillDtTm).local().format(
                        "MM/DD/YY"
                    );
                    j["PrescRefillId"] = drug.PrescReFill.Id;
                    j["Days"] = drug.Prescription.Days;
                    if (drug.insuranceInfo) {
                        if (drug.insuranceInfo.organization) {
                            j["Ins.Type"] =
                                drug.insuranceInfo.organization.Name;
                            j["TotalAmount"] = drug.insuranceInfo.organization.Name === "CASH" ?
                                drug.PrescReFill.TotalAmt :
                                (drug.PrescReFill.TotalAmt != null ? drug.PrescReFill.TotalAmt : 0) +
                                (drug.PrescReFill.CoPay != null ? drug.PrescReFill.CoPay : 0);
                        } else {
                            j["Ins.Type"] = " ";
                            j["TotalAmount"] = " ";
                        }
                    } else {
                        j["Ins.Type"] = " ";
                        j["TotalAmount"] = " ";
                    }
                    j["Patient Name"] =
                        drug.Patient.lastname + ", " + drug.Patient.firstname;
                    j["Rx#"] = drug.Prescription.PrescNum;
                    j["Fill#"] = this._commonSvc.checkIsPartialFill(drug.PrescReFill, drug.Prescription) ?
                    drug["partialCummulativeDetails"]["TotFills"] : "";
                    j["Prescriber Name"] =
                        drug.Prescriber.prescriberlastname + ", " + drug.Prescriber.prescriberfirstname;
                    j["QtyDisp"] = drug.PrescReFill.DispQty ? (drug.PrescReFill.DispQty).toFixed(3) : "0.000";
                    j["Rf"] = drug.Prescription.ReFillNum;
                    j["Copay"] = drug.PrescReFill.CoPay != null ? drug.PrescReFill.CoPay : 0;
                    j["Status"] = drug.PrescReFill.StatusId;
                    j["Status"] = (drug.PrescReFill.StatusId === 4 || drug.Prescription.RxStatusId === 4) ? "T" :
                        (drug.PrescReFill.StatusId === 2 || drug.Prescription.RxStatusId === 2 || drug.RxBill.StatusId === 6) ? "D" :
                            (drug.PrescReFill.StatusId === 3 || drug.Prescription.RxStatusId === 3) &&
                                drug.RxBill.StatusId === 2 ? "BI" :
                                (drug.RxBill.StatusId === 2 && drug.PrescReFill.StatusId !== 4 && drug.Prescription.RxStatusId !== 2
                                    && drug.Prescription.RxStatusId !== 4 && drug.PrescReFill.StatusId !== 2) ? "B" :
                                    ((drug.PrescReFill.StatusId === 3 || drug.Prescription.RxStatusId === 3) &&
                                        drug.RxBill.StatusId === 1) ? "UI" : "U";

                    return j;
                })
            );
            this.actvHeaders = [
                "Patient Name",
                "Date Filled",
                "Rx#",
                "Rf",
                "Fill#",
                "Status",
                "TotalAmount",
                "QtyDisp",
                "Days",
                "Copay",
                "Ins.Type",
                "Prescriber Name"
            ];
        }
    }
    routeBackToPrevScreen() {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        //  this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    routeToEditRx(item: any) {
        this.routeToRxInfo = item;
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.Rx,
            this.rxPrivs.EditRx)) {
            this.routeToRx();
        } else {
            this.openOveride("EditRx");
        }
    }

    keyEventOnWijimo(grid) {
        if (this.totalCount) {
            event.preventDefault();
            this.routeToEditRx(grid.selectedItems[0]);
        }
    }

    routeToRx(skipCheck?) {
        if ((this.routeFrom === "er" || this.routeFrom === "rf") && !skipCheck) {
            this._modalService.open(this.Redirect);
        } else {
            if (this.focusOnDU && this.routeFrom) {
                sessionStorage.setItem("toEdit", "" + "DispHisF:" + this.drugId + "." + this.routeFrom);
            } else if (this.routeFrom && !this.focusOnDU) {
                sessionStorage.setItem("toEdit", "" + "DispHis:" + this.drugId + "." + this.routeFrom);
            } else {
                sessionStorage.setItem("toEdit", "" + "DispHis:" + this.drugId);
            }
            this._rxutils.getRxDetailsByPrescNumber(+this.routeToRxInfo["Rx#"], "er", this.routeToRxInfo["PrescRefillId"]);
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
        }
    }

    openOveride(content) {
        this.privType = content;
        this.categoryId = "Rx";
        this.openOverride = true;
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    focusOutFromRadio(event) {
        if (this.totalCount && this.totalCount > 0) {
            event.preventDefault();
            this.flex.focus();
        } else {
            this.focusToFirst(event);
        }
    }

    focusOutFmCan(event) {
        if (document.getElementById("Comp")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("Comp").focus();
            }, 10);
        }
    }
}
