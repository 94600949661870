import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { SystemData, PrescMMsFilters, AccCategoryE, PrescriberFileE, PaginationModel } from "src/app/models";
import { EditPrescriberService, AlertService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CommonUtil, PrescriberUtil, ValidateFormFieldsUtils, WijimoUtil } from "src/app/utils";
import { MMSDataHeaders } from "../prescriber.constants";
import { PrescriberStore } from "src/app/store";
import { MsgConfig } from "src/app";
import { PrivMaskGuard } from "src/app/guards";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from "../../../services";

@Component({
    selector: "app-prescriber-mms",
    templateUrl: "./prescriber-mms.component.html"
})
export class PrescriberMMSComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();

    @Input() mmsType: string;
    @Input() prescriberFG: FormGroup;
    @Input() prescriberInfo: any;
    @Input() openMMS: boolean;
    @Input() prescriberId: number;
    @Input() isNPI: boolean;
    @Input() TextNPI : String;
    @Input() TextSPI : String;
    @Output() addMMSData = new EventEmitter<any>();
    @Input() IsFromGlobalSearch:any;
    refreshFG: FormGroup;
    hideFilters = true;
    completeMMSData: any;
    selectedMMSValues: any;
    hasMMSValues: boolean;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    selectedSizeMMSData: any;
    actvHeaders: string[];
    searchedMMSList: CollectionView;
    model: any;
    modaltype: string;
    mmsDataGrid: any;
    refreshMMSModel: any;
    stateChange: boolean;
    cityChange: boolean;
    zipChange: boolean;
    isDataExists = true;
    wjHeaders: {hName: string, isVisible: boolean, width:number}[];
    mmsStateAndCityIds = { StateId: null, CityId: null, OldStateId: null, OldCityId: null };
    refreshDataGrid: wjcGrid.FlexGrid;
    @ViewChild("searchData", { static: true })
    searchData: any;
    selectedRow: any;
    formGroupInvalid: boolean;
    unsubscribe$ : Subject<void> = new Subject();
    constructor(private _fb: FormBuilder, private _editPresbSvc: EditPrescriberService,
        private _modalSvc: NgbModal, private _presbUtil: PrescriberUtil, private _alertSvc: AlertService,
        private _privMask:PrivMaskGuard, private _commonUtil: CommonUtil, private _commonServ: CommonService, 
        private _presStore: PrescriberStore, private _formUtils: ValidateFormFieldsUtils,private _wijimoUtils: WijimoUtil) {
        this.refreshFG = this._fb.group(new PrescMMsFilters());
    }

    ngOnInit() {
        if (this.openMMS) {
            this.prescriberSearchPopUp(this.searchData);
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "prescriberSearchWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("prescriberSearchWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("prescriberSearchWJ", storedWJ);
    }

  
    async getPrescriberMMS() {
        if (((this.refreshFG.controls['PrescLastName'].value && this.refreshFG.controls['PrescLastName'].value.length < 3)
        || ((this.refreshFG.controls['PrescFirstName'].value && this.refreshFG.controls['PrescFirstName'].value.length < 3))  ||
          ((this.refreshFG.controls['Dea'].value && this.refreshFG.controls['Dea'].value.length < 3)) ||
          ((this.refreshFG.controls['Phone'].value && this.refreshFG.controls['Phone'].value.length < 3)) ||
          ((this.refreshFG.controls['License'].value && this.refreshFG.controls['License'].value.length < 3)) ||
          ((this.refreshFG.controls['PrescNpi'].value && this.refreshFG.controls['PrescNpi'].value.length < 3)) ||
          ((this.refreshFG.controls['Zip'].value && this.refreshFG.controls['Zip'].value.length < 3)))) {
                setTimeout(() => {
                    this.formGroupInvalid = true;
                  }, 10);
    } else {
        if (this.refreshFG.valid) {
            this.hideFilters = false;
            this.completeMMSData = await this._presbUtil.getPrescriberMMSInfo(this.refreshFG.value);
            if (this.completeMMSData && this.completeMMSData.length > 0) {
                this.selectedMMSValues = this.completeMMSData[0];
                this.isDataExists = true;
                this.mmsPagination();
                this.hasMMSValues = true;
            } else {
                this.hasMMSValues = false;
                this.isDataExists = false;
                this.searchedMMSList = new CollectionView(null);
            }
        }
    }


    }

    mmsPagination() {
        const startIndex = (this.pageNu * this.Size) - this.Size;
        const endIndex = (startIndex + this.Size);
        this.selectedSizeMMSData = this.completeMMSData.slice(startIndex, endIndex);
        if (this.selectedSizeMMSData.length) {
            this.hasMMSValues = true;
            this.searchedMMSList = this._presbUtil.generateMMSWIJIMO(this.selectedSizeMMSData);
            this.actvHeaders = MMSDataHeaders;
        } else {
            this.hasMMSValues = false;
            this.searchedMMSList = new CollectionView(null)
        }
    }

    async prescriberSearchPopUp(searchData) {
        this.refreshFG.markAsTouched();
        if (this.mmsType === "NPI" && !this._privMask.canActivate(AccCategoryE.PrescriberFile,PrescriberFileE.CanUpdatePerscriberNPI)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else{
            this.patchValue();
            await this.getPrescriberMMS();
                this.hideFilters = false;
                this.formGroupInvalid = false
                this.model = this._modalSvc.open(searchData, {keyboard: false, backdrop: false,windowClass:"max-modal-1300", centered : true});
                this.modaltype = "MMSSearchType";
        }
    }

    patchValue() {
        if (!this.prescriberId) {
            this.refreshFG.patchValue({
                PrescLastName: this.prescriberFG.value.Person.LastName,
                PrescFirstName: this.prescriberFG.value.Person.FirstName,
               // Zip: this.isNPI ? null : this.prescriberFG.value.Address.ZipCode, // based on RanaRegan feedback
                PrescNpi: this.prescriberFG.value.Person.NPINum
               // License: this.prescriberFG.value.Prescriber.PrescbNum,
               // Dea: this.prescriberFG.value.Prescriber.DEANum
            });
        } else {
            this.refreshFG.patchValue({
                PrescLastName: this.prescriberFG.value.Person.LastName,
                PrescFirstName: this.prescriberFG.value.Person.FirstName
            });
        }
    }

    clearFilters(val?: any) {
        this.refreshFG.reset();
        if (!val) {
            this.getPrescriberMMS();
        }
        this.refreshFG.markAsTouched();
        setTimeout(() => {
            this.refreshFG.markAsUntouched();
        }, 20);
    }

    closeModal(val?: any) {
        this.clearFilters(val ? val : "");
        if (this.model) {
            this.model.close();
        }
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = this.paginationModel.pageSize;
        this.prescriberFG.markAsTouched();
        setTimeout(() => {
            this.prescriberFG.markAsUntouched();
            this.prescriberFG.updateValueAndValidity();
        }, 100);
        if (document.getElementById("SaveButton")) {
            document.getElementById("SaveButton").focus();
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.focus();
        this.mmsDataGrid = flex;
    }

    focusToMMsData(event) {
        if (this.hasMMSValues && this.mmsDataGrid) {
            event.preventDefault();
            this.mmsDataGrid.focus();
        }
    }

    cancelEventOnWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 67) {
                event.preventDefault();
                if (this.hideFilters) {
                    //   this.closeMMSData();
                } else if (!this.hideFilters) {
                    this.closeModal();
                }
            }
        }
    }

    setPage(page: number) {
        this.pageNu = page;
        this.mmsPagination();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        this.mmsPagination();
    }

    selectedMMSByEnter(grid, event) {
        let ht: any;
        if (event.which === 13) {
            event.preventDefault();
        } else if (event.which !== 13) {
            ht = grid.hitTest(event);
        }
        if (this._modalSvc.hasOpenModals() && this.modaltype === "MMSSearchType" && (!ht || ht.cellType === 1)) {
            event.preventDefault();
            this.selectMMSData(grid.selectedItems[0]?.["row"]);
        }
    }

    async selectMMSData(val: any) {
        if (!this._privMask.canActivate(AccCategoryE.PrescriberFile,PrescriberFileE.CanUpdatePerscriberNPI)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else
        {
this.selectedMMSValues = this.selectedSizeMMSData[val];
        const stateAndCityData = await this._presbUtil.getStateAndCityId(this.selectedMMSValues.ADDRZP,
            this.selectedMMSValues.ADDRST, this.selectedMMSValues.ADDRCT);
        this.mmsStateAndCityIds = {
            StateId: stateAndCityData ? stateAndCityData["stateId"] : null, CityId: stateAndCityData ? stateAndCityData["cityId"] : null,
            OldCityId: this.prescriberFG.value["Address"] ? this.prescriberFG.value["Address"]["CityId"] : null,
            OldStateId: this.prescriberFG.value["Address"] ? this.prescriberFG.value["Address"]["StateId"] : null
        };
        this.mmsFunction();
        }

    }

    mmsFunction() {
        if (!this.prescriberId) {
            if (this.mmsType && (this.mmsType === "SPI" || this.mmsType === "NPI")) {
                this.updateNIPOrSPI();
            } else {
                this.refreshMMSModel = this._presbUtil.generateMMSModelForAdd(this.selectedMMSValues, this.prescriberFG.value);
                this.refreshMMSModel.map(value => {
                    this.refreshMMSData(value, true);
                });
            }
            if (this.model) {
                this.modaltype = null;
                this.model.close();
            }
            this.addMMSData.emit();
            if (document.getElementById("SaveButton")) {
                document.getElementById("SaveButton").focus();
            }
        } else {
            this.openMMSValuesForEdit();
        }
    }

    openMMSValuesForEdit() {
        this.refreshMMSModel = this._presbUtil.generateMMSModelForUpdate(this.selectedMMSValues, this.prescriberFG.value);
        this.hideFilters = true;
    }

    async refreshMMSData(val: any, checked: any) {
        const fg: any = this.prescriberFG.controls[val["keyName"]];
        fg.controls[val["ControlName"]].patchValue(
            checked ? val["MMsvalue"] :  val["currentvalue"]
        );
        if (val.head === "STATE") {
            this.stateChange = checked ? true : false;
            this.prescriberFG.controls["Address"].patchValue({
                StateId: checked ? this.mmsStateAndCityIds["StateId"] :
                this.mmsStateAndCityIds["OldStateId"]
            });
        } else if (val.head === "CITY") {
            this.cityChange = checked ? true : false;
            this.prescriberFG.controls["Address"].patchValue({
                CityId: checked ? this.mmsStateAndCityIds["CityId"] :
                this.mmsStateAndCityIds["OldCityId"]
            });
        }
        else if (val.head === "ZIP") {
            this.zipChange = checked ? true : false;
            //need to update the city and state as well as per the zip code
            this._commonServ.getStateCityDataByZip(checked ? val["MMsvalue"] :  val["currentvalue"])
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(async zipMaster => {
                    this.prescriberFG.controls["Address"].patchValue({
                        StateName: zipMaster.StateName
                    });
                    this.prescriberFG.controls["Address"].patchValue({
                        CityName: zipMaster.CityName
                    });
                    const resp = await this._commonUtil.patchStateAndCity(this.prescriberFG.controls["Address"].value, this.prescriberFG,null);
                        this._presStore.storePrescriberState(resp["state"]);
                        this._presStore.storePrescriberCity(resp["city"]);
                });
        }
        const resp = await this._commonUtil.patchStateAndCity(this.prescriberFG.controls["Address"].value, this.prescriberFG,null);
        this._presStore.storePrescriberState(resp["state"]);
        this._presStore.storePrescriberCity(resp["city"]);
    }

    spaceEventOnWijimo(event, grid) {
        event.preventDefault();
        if (grid["selectedItems"][0]["display"]) {
            let checked = document.getElementById(grid["selectedItems"][0]["link"])["checked"];
            checked = !checked;
            this.refreshMMSData(grid["selectedItems"][0], checked);
        }
    }

    tabEventOnWijimo(event) {
        if (document.getElementById("updateMMSButton")) {
            event.preventDefault();
            document.getElementById("updateMMSButton").focus();
        }
    }

    updateMMSInit(flex: wjcGrid.FlexGrid) {
        flex.focus();
        this.refreshDataGrid = flex;
    }
    onclickRefreshMMS(grid){
        if( grid.selectedRows && grid.selectedRows.length && grid.selectedRows[0].dataItem){
            this.selectedRow = grid.selectedRows[0].dataItem;
        }
    }

    updateNIPOrSPI(grid?: any) {
        if (!this.selectedMMSValues && grid) {
            this.selectedMMSValues = this.selectedSizeMMSData[grid.selectedItems[0]["row"]];
        }
        if (this.mmsType === "NPI") {
            this.prescriberFG.controls["Person"].patchValue({
                NPINum: this.selectedRow ? this.selectedRow["NPI#"] : "",
            });
        } else if (this.mmsType === "SPI") {
            this.prescriberFG.controls["PrescAdds"].patchValue({
                SPINum: this.selectedRow ? this.selectedRow["SPI#"] : ""
            });
        }
    }

    clearRefreshData(val) {
        if( this.refreshMMSModel &&  this.refreshMMSModel.length) {
            this.refreshMMSModel.map(value => {
                this.refreshMMSData(value, false);
            });
        }
        this.closeModal(val);
    }

    async updateMMSData() {
        const response  = await this._presbUtil.updatePrescriber(this.prescriberInfo);
        if (response) {
            this._presbUtil.patchPrescInfo(this.prescriberInfo, this.prescriberFG);
            this._presStore.storePrescriberData(this.prescriberInfo);
            this._alertSvc.success(MsgConfig.UPDATE_SUCCESS);
        } else {
            this._alertSvc.error(MsgConfig.UPDATE_FAIL);
        }
    }

    focusOnRefreshGrid(event) {
        if (this.refreshDataGrid) {
            event.preventDefault();
            this.refreshDataGrid.focus();
        }
    }

    closeMMSData() {
        this.hideFilters = false;
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
