import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";

@Injectable({
    providedIn: "root"
})
export class BussineceIntelligenceService {

    constructor(private _http: HttpClient) { }

    getPatientBI(data) {
        return this._http.post(constant.POST_GET_Patient_BI, data);
    }
    getPrescriberBI(data) {
        return this._http.post(constant.POST_Prescriber_BI, data);
    }
    getInsuranceBI(data) {
        return this._http.post(constant.POST_Insurance_BI, data);
    }

    getMyPatientData(data) {
        return this._http.post(constant.POST_GET_My_Patients, data);
    }

    getDrugData(data) {
        return this._http.post(constant.POST_Drug_BI, data);
    }

  getMypatientAmount(data) {
      return this._http.post<any>(constant.POST_GET_My_Patinets_Amount, data);
  }
}
