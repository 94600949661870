import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "eprx-check-box",
    templateUrl: "./check-box.component.html"
})
export class CheckBoxComponent implements OnInit, OnChanges {
    labelText: string;
    inputFormGroup: FormGroup;
    custContrlName: string;
    isDisabled = false;
    autoFocus: boolean;
    IsChecked = false;

    @Input()
    set AutoFocus(at: boolean) {
        this.autoFocus = at;
    }

    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }
    @Input()
    set IsDisabled(ir: boolean) {
        this.isDisabled = ir;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input() rxSelectId: any;

    @Input() IsFrmTrans: boolean;
    @Input()
    set isChecked(ir: boolean) {
        this.IsChecked = ir;
    }
    @Output()
    TriggerChangeValue = new EventEmitter();

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
     }

    ngOnChanges(changes: SimpleChanges) {
        if (this.IsFrmTrans) {
            if (this.inputFormGroup.controls[this.custContrlName].value === "0" || this.inputFormGroup.controls[this.custContrlName].value === "1") {
            const data = parseInt(this.inputFormGroup.controls[this.custContrlName].value, 0);
            this.inputFormGroup.controls[this.custContrlName].patchValue(data ? true : false);
            }
        }
    }

    triggerChange($event) {
        this.TriggerChangeValue.emit({ value: $event.target.checked });
    }

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab"){
            this.TriggerOnShiftTabbing.emit(event);
        }
    }
}
