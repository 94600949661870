import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";

@Injectable()
export class DrugPriceCheckService {
    constructor(private _http: HttpClient) {}

    getBucketDrugNameSuggest(searchText: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Drug_Suggest.replace("{drugId}", "" + searchText)
        );
    }

    getDrugPriceHistory(drugId: any, pagesize: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_DRUG_DrugPriceHistory.replace(
                "{drugId}",
                "" + drugId
            ).replace("{pageSize}", "" + pagesize)
        );
    }

    getDrugData(drugId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_DRUG_DrugData.replace("{drugId}", "" + drugId)
        );
    }

    getQtyInHandEquivalents(drugId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Drug_QtyInHandEquivalent.replace("{Drugid}", "" + drugId)
                .replace("{ndc}", "" + null)
        );
    }

    getDrugPriceSchedule(): Observable<any> {
        return this._http.get<any>(constant.GET_DRUG_DrugPriceSchedule);
    }

    getDrugPriceCheck(drugData: any): Observable<any> {
        return this._http.post<any[]>(
            constant.POST_DRUG_DrugPriceCheck,
            drugData
        );
    }

    getEqDrugPriceChck(value) {
        return this._http.post(constant.POST_EQDRUG_PriceCheck, value);
    }

    getDrugBuckPrice(drugId, buckId) {
        return this._http.get(constant.GET_DRUG_BUCKET_PRICE.replace("{drugId}/{bucketId}", "" + drugId + "/" + buckId));
    }
}
