import {
    Directive,
    HostListener,
    ElementRef,
    Input,
    SimpleChanges,
    OnChanges,
} from "@angular/core";
import { DateFormatPipe } from "../pipes";

@Directive({
    selector: "[appDateFormat]",
})
export class DateFormatDirective {
    datePicker = new DateFormatPipe();

    @Input() InputValue: any;

    constructor(private elementRef: ElementRef) {}

    @HostListener("focus", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        this.elementRef.nativeElement.value = this.datePicker.transform(
            this.InputValue
        );
    }
}
