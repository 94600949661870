<app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)">
</app-cancel-pop-up>
<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Information'" [DrugData]="drugEditFG"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                <button *ngIf="isCompDrug" class="hotkey_success" title="Alt+G" (click)="openCompound()" >
                        <span>G</span> Comp. Drug </button>
                <button id="SaveButton" class="hotkey_success" title="Alt+S" (click)="updateDrug()" *ngIf="drugId">
                    <span>S</span> Save </button>
                <button id="SaveButton" class="hotkey_success" title="Alt+S" (click)="addNewDrug()" *ngIf="!drugId">
                    <span>S</span> Save </button>
                <button class="hotkey_success mr-0" title="Alt+C"  (keydown.tab)="focusOutFmCan($event)" (click)="CancelPopTaggle()">
                    <span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="row exprx--common-block">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 padding-sm-15">
            <div class="box  box-solid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Drug Information
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="drugId">
                            <div class="col-sm-12 padding-0">
                                <img src="{{imgURL}}" class="drug-img">
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 padding--left__7" *ngIf="drugId">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [AutoFocus]="true" [LabelText]="'Drug Quick Code'" [PlaceHolder]="'Drug Quick Code'" [ControlName]="'QuickCode'"
                                    [InputErrors]="QuickCode?.errors" [ErrorDefs]="{invalid: 'Already Exist'}" [FormGroupName]="Drug" [MaxLength]="10" [RxSelectId]="'QuickCode'"
                                     [MarkAsTouched]="QuickCode?.touched">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Drug Name'" [PlaceHolder]="'Drug Name'" [ControlName]="'Name'" [FormGroupName]="Drug" [InputErrors]="Name?.errors"
                                    [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [MaxLength]="35" [FormGroupInvalid]="formGroupInvalid"
                                    [RxSelectId]="'Name'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Drug Label Name'" [PlaceHolder]="'Drug Label Name'" [ControlName]="'DrugLabelName'" [FormGroupName]="Drug"
                                   [MaxLength]="30" [FormGroupInvalid]="formGroupInvalid" [RxSelectId]="'LabelName'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Drug Full Name'" [PlaceHolder]="'Drug Full Name'" [ControlName]="'DrugFullName'" [FormGroupName]="Drug"  [MaxLength]="200" [FormGroupInvalid]="formGroupInvalid"
                                    [RxSelectId]="'FullName'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-select [LabelText]="'Drug Manufacturer'" [PlaceHolder]="'Drug Manufacturer'" [ControlName]="'ManufactId'" [FormGroupName]="Drug"
                                    [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="defVal?.Organization"
                                    [RxSelectId]="'ManufactId'" [InputErrors]="ManufactId?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                    [FormGroupInvalid]="formGroupInvalid">
                                </eprx-select>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="!drugId">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [AutoFocus]="true" [LabelText]="'Drug Quick Code'" [PlaceHolder]="'Drug Quick Code'" [ControlName]="'QuickCode'"
                                [MarkAsTouched]="QuickCode?.touched"
                                [ErrorDefs]="{invalid: 'Already Exist'}"   [InputErrors]="QuickCode?.errors" [RxSelectId]="'QuickCode'" [FormGroupName]="Drug" [MaxLength]="10" >
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Drug Name'" [PlaceHolder]="'Drug Name'" [ControlName]="'Name'" [FormGroupName]="Drug" [InputErrors]="Name?.errors"
                                    [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [MaxLength]="35" [FormGroupInvalid]="formGroupInvalid"
                                    [RxSelectId]="'Name'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Drug Label Name'" [PlaceHolder]="'Drug Label Name'" [ControlName]="'DrugLabelName'" [FormGroupName]="Drug"
                                   [MaxLength]="30" [FormGroupInvalid]="formGroupInvalid" [RxSelectId]="'LabelName'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Drug Full Name'" [PlaceHolder]="'Drug Full Name'" [ControlName]="'DrugFullName'" [FormGroupName]="Drug"  [MaxLength]="200" [FormGroupInvalid]="formGroupInvalid"
                                    [RxSelectId]="'FullName'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-select [LabelText]="'Drug Manufacturer'" [PlaceHolder]="'Drug Manufacturer'" [ControlName]="'ManufactId'" [FormGroupName]="Drug"
                                    [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="defVal?.Organization"
                                    [RxSelectId]="'ManufactId'" [InputErrors]="ManufactId?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                    [FormGroupInvalid]="formGroupInvalid">
                                </eprx-select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-select [LabelText]="'Drug Type'" [PlaceHolder]="'Drug Type'" [ControlName]="'DrugTypeId'" [FormGroupName]="Drug" [HasMultiple]="false"
                                [RxSelectId]="'DrugTypeId'" [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                [List]="systemData?.DrugType" [InputErrors]="DrugTypeId?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                [FormGroupInvalid]="formGroupInvalid" (TriggerSelectValue)="selectDrugType($event?.value)"
                                [IsDisabled]="(drugId && drugInfo?.Drug?.DrugTypeId === 2)? true : false">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-select [LabelText]="'Drug Form'" [PlaceHolder]="'Drug Form'" [ControlName]="'DrugFormId'" [FormGroupName]="Drug" [HasMultiple]="false"
                                [RxSelectId]="'DrugFormId'" [BindLabel]="'FormName'" [BindValue]="'FDAFormID'" [LabelForId]="'Name'"
                                [List]="defVal?.FDA_Form" [InputErrors]="DrugFormId?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                [FormGroupInvalid]="formGroupInvalid">
                            </eprx-select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <eprx-input [LabelText]="'Strength'" [RxSelectId]="'Strength'" [PlaceHolder]="'Strength'" [ControlName]="'Strength'" [FormGroupName]="Drug"
                                [InputErrors]="Strength?.errors" [IsRequired]="strenthReq" [ErrorDefs]="{required: 'Required', maxlength: 'length exceeding than 15'}" [AutoFocus]="focusOn === 'Strength'"
                                [FormGroupInvalid]="formGroupInvalid" [MaxLength]="15" [MarkAsTouched]="Strength?.touched">
                            </eprx-input>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 margin_top_20px">
                            <eprx-select [LabelText]="''" [PlaceHolder]="''" [ControlName]="'MeasureUnitId'" [FormGroupName]="Drug" [HasMultiple]="false"
                                [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'" [IsRequired]="strenthReq" [List]="systemData?.DrugUnit" [AutoFocus]="focusOn === 'StrengthUnit'"
                                [RxSelectId]="'strengthUnit'" [InputErrors]="MeasureUnitId?.errors" [ErrorDefs]="{required: 'Required'}"
                                [FormGroupInvalid]="formGroupInvalid" [MarkAsTouched]="MeasureUnitId?.touched">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-select [LabelText]="'Unit of Measure'" [PlaceHolder]="'Unit of Measure'" [ControlName]="'DrugUnitId'" [FormGroupName]="Drug" [HasMultiple]="false"
                            [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="systemData?.DrugUnit" [AutoFocus]="focusOn === 'DrugUnit'"
                                [RxSelectId]="'MeasureUnitId'" [InputErrors]="DrugUnitId?.errors" [IsRequired]="strenthReq" [ErrorDefs]="{required: 'Required'}"
                                [FormGroupInvalid]="formGroupInvalid" [MarkAsTouched]="DrugUnitId?.touched">
                            </eprx-select>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-12 padding-0"> Brand </label>
                            <eprx-radio [ControlName]="'IsBranded'" [FormGroupName]="Drug"  [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                [IDForList]="['yes', 'no']" [LabelForId]="">
                            </eprx-radio>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-input [LabelText]="'Brand Name'" [PlaceHolder]="'Brand Name'" [ControlName]="'BrandName'" [FormGroupName]="Drug" [RxSelectId]="'BrandName'"
                                [MaxLength]="30">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-input [LabelText]="'Generic Name'" [PlaceHolder]="'Generic Name'" [ControlName]="'GenericName'" [FormGroupName]="Drug"
                                [RxSelectId]="'GenericName'" [MaxLength]="30">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-input [LabelText]="(isBranded === true) ? 'Brand NDC' : 'Generic NDC'" [PlaceHolder]="'_____-____-__'" [ControlName]="'BrandOrGenNDC'"
                                [FormGroupName]="Drug" [MaxLength]="13" [MaskPattern]="'AAAA0-0000-00'" [RxSelectId]="'BrandOrGenNDC'"
                                [DropSpclChar]="true">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-input [LabelText]="'Drug NDC Code'" [PlaceHolder]="'_____-____-__'" [ControlName]="'DrugCode'" [FormGroupName]="Drug"
                                [MaxLength]="13" [MaskPattern]="'AAAA0-0000-00'" [IsRequired]="true" [ErrorDefs]="{required: 'Required', pattern: 'please enter 10 digits'}"
                                [RxSelectId]="'DrugCode'" [IsDisabled]="drugId ? true : isCompDrug ? true : false" [FormGroupInvalid]="formGroupInvalid"
                                [DropSpclChar]="true">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-select [LabelText]="'NDC Qualifier'" [PlaceHolder]="'NDC Qualifier'" [ControlName]="'NDCQualiId'" [FormGroupName]="Drug" [HasMultiple]="false"
                                [BindLabel]="'Name'" [RxSelectId]="'NDCQualiId'" [BindLabel2]="'Remarks'" [BindValue]="'Id'"
                                [LabelForId]="'Id'" [List]="systemData?.NdcQualifier" [Show2Values]="true" [show2ValInInputhidden]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-input [LabelText]="'Replacement NDC'" [PlaceHolder]="'_____-____-__'" [ControlName]="'ReplaceNDC'" [FormGroupName]="Drug"
                                [MaxLength]="13" [MaskPattern]="'00000-0000-00'" [DropSpclChar]="true" [RxSelectId]="'ReplaceNDC'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-input [LabelText]="'Old NDC'" [PlaceHolder]="'_____-____-__'" [ControlName]="'OldNDC'" [FormGroupName]="Drug" [MaxLength]="13"
                                [MaskPattern]="'00000-0000-00'" [DropSpclChar]="true" [RxSelectId]="'OldNDC'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-input [LabelText]="'Billing NDC Code'" [PlaceHolder]="'_____-____-__'" [ControlName]="'BillingNDC'" [FormGroupName]="Drug"
                                [MaxLength]="13" [MaskPattern]="'00000-0000-00'" [DropSpclChar]="true" [RxSelectId]="'BillingNDC'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-input [LabelText]="'Universal Product Code'" [PlaceHolder]="'Universal Product Code'" [ControlName]="'UPC'" [FormGroupName]="Drug" [MaxLength]="20" [RxSelectId]="'UPC'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-select [LabelText]="'Unit Dose Indicator'" [PlaceHolder]="'Unit Dose Indicator'" [ControlName]="'UnitDoseIndId'" [FormGroupName]="Drug"
                                [HasMultiple]="false" [Show2Values]="true" [BindLabel2]="'Remarks'" [BindLabel]="'Name'" [BindValue]="'Id'"
                                [LabelForId]="''" [List]="systemData?.UnitDoseIndication" [RxSelectId]="'UnitDoseIndId'">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7" *ngIf="drugId">
                            <eprx-input [LabelText]="'Quantity in Pack'" [PlaceHolder]="'Quantity in Pack'" [ControlName]="'QtyPack'" [FormGroupName]="Drug"
                                [DropSpclChar]="false" [DecimalValues]="3" [InputType]="'NUMBER'" [MaskPattern]="'099999.000'"
                                [RxSelectId]="'QtyPack'" (TriggerSearchValue)="convertByMouseDown('QtyPack')">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7" *ngIf="!drugId">
                            <eprx-input [LabelText]="'Quantity in Pack'" [PlaceHolder]="'Quantity in Pack'" [ControlName]="'QtyPack'" [FormGroupName]="Drug"
                                [DecimalValues]="3" [InputType]="'NUMBER'" [DropSpclChar]="false" [MaskPattern]="'099999.000'"
                                [RxSelectId]="'QtyPack'" (TriggerSearchValue)="convertByMouseDown('QtyPack')">
                            </eprx-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8 col-lg-8 drug--price__info">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 padding-sm-15 flex--one padding-0">
                    <div class="box box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Drug Price Information
                            </div>
                        </div>
                        <div class="box-body min-height_35rem">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="drugId">
                                    <eprx-input [LabelText]="'Pack AWP Price'" [PlaceHolder]="'Pack AWP Price'" [ControlName]="'AWPPack'" [FormGroupName]="Drug"
									[DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'"
                                    [RxSelectId]="'AWPPack'" (TriggerSearchValue)="convertByMouseDown('AWPPack')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="!drugId">
                                    <eprx-input [LabelText]="'Pack AWP Price'" [PlaceHolder]="'Pack AWP Price'" [ControlName]="'AWPPack'" [FormGroupName]="Drug"
									[DecimalValues]="2" [InputType]="'CURRENCY'" [DropSpclChar]="false" [MaskPattern]="'099999.00'"
                                        [RxSelectId]="'AWPPack'" (TriggerSearchValue)="convertByMouseDown('AWPPack')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7" *ngIf="drugId">
                                    <eprx-input [LabelText]="'Pack Cost'" [InputType]="'CURRENCY'" [PlaceHolder]="'Pack Cost'" [ControlName]="'CostPack'" [FormGroupName]="Drug"
									[DropSpclChar]="false" [DecimalValues]="2" [InputType]="'NUMBER'" [MaskPattern]="'099999.00'"
                                        [RxSelectId]="'CostPack'" (TriggerSearchValue)="convertByMouseDown('CostPack')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7" *ngIf="!drugId">
                                    <eprx-input [LabelText]="'Pack Cost'" [InputType]="'CURRENCY'" [PlaceHolder]="'Pack Cost'" [ControlName]="'CostPack'" [FormGroupName]="Drug"
									[DecimalValues]="2" [InputType]="'NUMBER'" [DropSpclChar]="false" [MaskPattern]="'099999.00'"
                                        [RxSelectId]="'CostPack'" (TriggerSearchValue)="convertByMouseDown('CostPack')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="drugId">
                                    <eprx-input [LabelText]="'Unit AWP Price'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit AWP Price'" [ControlName]="'UnitPriceAWP3Digits'" [FormGroupName]="Drug"
									[DropSpclChar]="false" [DecimalValues]="3" [InputType]="'NUMBER'" [MaskPattern]="'09999.000'"
                                        [RxSelectId]="'UnitPriceAWP'" (TriggerSearchValue)="convertByMouseDown('UnitPriceAWP')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="!drugId">
                                    <eprx-input [LabelText]="'Unit AWP Price'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit AWP Price'" [ControlName]="'UnitPriceAWP3Digits'" [FormGroupName]="Drug"
									[DecimalValues]="3" [InputType]="'NUMBER'" [DropSpclChar]="false" [MaskPattern]="'09999.000'"
                                        [RxSelectId]="'UnitPriceAWP'" (TriggerSearchValue)="convertByMouseDown('UnitPriceAWP')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7" *ngIf="drugId">
                                    <eprx-input [LabelText]="'Unit Cost'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit Cost'" [ControlName]="'UnitPriceCost'" [FormGroupName]="Drug"
									[DropSpclChar]="false" [DecimalValues]="3" [InputType]="'NUMBER'" [MaskPattern]="'09999.000'" [AutoFocus]="focusOnDU === 'drugUnit'"
                                        [RxSelectId]="'UnitPriceCost'" (TriggerSearchValue)="convertByMouseDown('UnitPriceCost')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7" *ngIf="!drugId">
                                    <eprx-input [LabelText]="'Unit Cost'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit Cost'" [ControlName]="'UnitPriceCost'" [FormGroupName]="Drug"
									[DecimalValues]="3" [InputType]="'NUMBER'" [DropSpclChar]="false" [MaskPattern]="'09999.000'"
                                        [RxSelectId]="'UnitPriceCost'" (TriggerSearchValue)="convertByMouseDown('UnitPriceCost')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="drugId">
                                    <eprx-input [LabelText]="'Unit Price Direct'" [InputType]="'CURRENCY'" [DropSpclChar]="false" [PlaceHolder]="'Unit Price Direct'" [ControlName]="'DirectUnitPrice'" [FormGroupName]="Drug"
                                        [MaxLength]="10" (TriggerSearchValue)="setNumberFormat('DirectUnitPrice', 3)" [MaskPattern]="'09999.000'" [InputType]="'NUMBER'" [DecimalValues]="3" [RxSelectId]="'DirectUnitPrice'">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="!drugId">
                                    <eprx-input [LabelText]="'Unit Price Direct'" [InputType]="'CURRENCY'" [DropSpclChar]="false" [PlaceHolder]="'Unit Price Direct'" [ControlName]="'DirectUnitPrice'" [FormGroupName]="Drug"
                                        [MaxLength]="10" [RxSelectId]="'DirectUnitPrice'" (TriggerSearchValue)="setNumberFormat('DirectUnitPrice', 3)" [MaskPattern]="'09999.000'"
                                        [InputType]="'NUMBER'" [DecimalValues]="3">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                        <eprx-date-picker [LabelText]="'Last Update'" [PlaceHolder]="'Last Update'" [ControlName]="'LastUpdateDt'" [FormGroupName]="Drug"
                                        [MinDate]="" [MaxDate]="" [RxSelectId]="'LastUpdateDt'">
                                    </eprx-date-picker>
                                </div>
                                <div class="col-md-6">
                                    <label class="col-md-12 padding-0"> Exclude From Data Update </label>
                                    <eprx-radio [ControlName]="'IsExclFrmUpdate'" [FormGroupName]="Drug"  [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                        [IDForList]="['4', '5']" [LabelForId]="">
                                    </eprx-radio>
                                </div>
                                <div class="col-md-6">
                                    <label class="col-md-12 padding-0"> Price Update </label>
                                    <eprx-radio [ControlName]="'IsPriceUpdAuto'" [FormGroupName]="Drug"  [List]="['Auto', 'Manual']" [ValueList]="[true, false]"
                                        [IDForList]="['6', '7']" [LabelForId]="">
                                    </eprx-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 padding-sm-15 flex--one padding-0">
                    <div class="box box-default box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Other Info/ Default
                            </div>
                        </div>
                        <div class="box-body min-height_35rem">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                    <eprx-select [LabelText]="'Drug Class'" [PlaceHolder]="'Drug Class'" [ControlName]="'DrugClass'" [FormGroupName]="Drug" [HasMultiple]="false"
                                        [BindLabel]="'Name'" [BindValue]="'Name'" [LabelForId]="'Name'" [List]="[0,1,2,3,4,5]"
                                        [RxSelectId]="'DrugClass'" [InputErrors]="DrugClass?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                        [FormGroupInvalid]="formGroupInvalid">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                    <eprx-select [LabelText]="'Drug Form Sig Code'" [PlaceHolder]="'Drug Form Sig Code'" [ControlName]="'DrugFormSigCodeId'"
                                        [FormGroupName]="Drug" [HasMultiple]="false" [BindLabel]="'SigCode'" [BindValue]="'Id'"
                                        [LabelForId]="'Name'" [List]="defVal?.Sig" [RxSelectId]="'DrugFormSigCodeId'">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-3 col-xs-3 padding--right__7">
                                    <eprx-select [LabelText]="'Default Use Sig Code'" [PlaceHolder]="'Default Use Sig Code'" [ControlName]="'DrugUseSigCodeId'"
                                        [FormGroupName]="Drug" [HasMultiple]="false" [BindLabel]="'SigCode'" [BindValue]="'Id'"
                                        [LabelForId]="'Name'" [List]="defVal?.Sig" [RxSelectId]="'DrugUseSigCodeId'">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                    <eprx-select [LabelText]="'Default Sig Code'" [PlaceHolder]="'Default Sig Code'" [ControlName]="'DefaultSigCode'" [FormGroupName]="Drug"
                                        [HasMultiple]="false" [BindLabel]="'SigCode'" [BindValue]="'SigCode'" [LabelForId]="'Name'"
                                        [List]="defVal?.Sig" [RxSelectId]="'DefaultSigCode'">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="drugId">
                                    <eprx-input [LabelText]="'Default Quantity'" [PlaceHolder]="'Default Quantity'" [ControlName]="'DefaultQty'" [FormGroupName]="Drug"
                                        [DecimalValues]="3" [InputType]="'NUMBER'" [DropSpclChar]="false" [MaskPattern]="'099999.000'"
                                        [RxSelectId]="'DefaultQty'" (TriggerSearchValue)="setNumberFormat('DefaultQty', 3)">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7" *ngIf="!drugId">
                                    <eprx-input [LabelText]="'Default Quantity'" [PlaceHolder]="'Default Quantity'" [ControlName]="'DefaultQty'" [FormGroupName]="Drug"
                                        [DecimalValues]="3" [InputType]="'NUMBER'" [RxSelectId]="'DefaultQty'" (TriggerSearchValue)="setNumberFormat('DefaultQty', 3)">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                    <eprx-input [LabelText]="'Default Days'" [PlaceHolder]="'Default Days'" [ControlName]="'DefaultDays'" [FormGroupName]="Drug"
                                        [MaxLength]="3" [RxSelectId]="'DefaultDays'">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                    <label class="col-md-12 padding-0"> Is Active? </label>
                                    <eprx-radio [ControlName]="'IsActive'" [FormGroupName]="Drug"  [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                        [IDForList]="['8', '9']" [LabelForId]="">
                                    </eprx-radio>
                                    <!-- <eprx-select [LabelText]="'Status(A/I)'" [PlaceHolder]="'Status(A/I)'" [ControlName]="'IsActive'" [FormGroupName]="Drug"
                                        [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [RxSelectId]="'IsActive'"
                                        [List]="[{'Id': true, 'Name': 'Active'}, {'Id': false, 'Name': 'InActive'}]">
                                    </eprx-select> -->
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                    <eprx-date-picker [LabelText]="'Discontinue Date'" [PlaceHolder]="'Discontinue Date'" [ControlName]="'DiscontDt'" [FormGroupName]="Drug"
                                        [MinDate]="minDate" [MaxDate]="" [RxSelectId]="'DiscontDt'">
                                    </eprx-date-picker>
                                </div>
                                <div class="col-md-6">
                                    <label class="col-md-12 padding-0"> Is Preferred? </label>
                                    <fieldset [disabled]="drugInfo?.PreferredDrugsCount ? (drugInfo?.PreferredDrugsCount != 0 ? true : false) : false">
                                        <eprx-radio [ControlName]="'IsPreferred'" [FormGroupName]="Drug"  [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                            [IDForList]="['8', '9']" [LabelForId]="">
                                        </eprx-radio>
                                    </fieldset>
                                </div>
                                <div class="col-md-6">
                                    <label class="col-md-12 padding-0" title="Include in Refill Reminder"> Include in Ref Reminder </label>
                                    <eprx-radio [ControlName]="'IsIncInRefilRem'" [FormGroupName]="Drug"  [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                        [IDForList]="['t', 'f']" [LabelForId]=""
                                        >
                                    </eprx-radio>
                                </div>
                                <div class="col-md-6">
                                    <label class="col-md-12 padding-0"> FDA Approved? </label>
                                    <eprx-radio [ControlName]="'IsFDAApprovedNULL'" [FormGroupName]="Drug"  [List]="[true, false, 'Unknown']"
                                        [ValueList]="[1, 0, 2]" [IDForList]="['1', '2', 'Unkn']" [LabelForId]=""
                                        (keydown.tab)="focusToFirst($event)">
                                    </eprx-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 exprx--common-block">
                    <div class="box box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Drug Description
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <label>Shape</label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.Shape ? drugInfo?.DrugDesc?.Shape : "--"}}</div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                    <label>Color Front</label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.Color_Frnt? drugInfo?.DrugDesc?.Color_Frnt : "--"}}</div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                    <label>Color Back </label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.Color_Back? drugInfo?.DrugDesc?.Color_Back : "--"}}</div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                    <label>Side 1</label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.ID_FRONT? drugInfo?.DrugDesc?.ID_FRONT : "--"}}</div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                    <label>Side 2</label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.ID_BACK?drugInfo?.DrugDesc?.ID_BACK : "--"}}</div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                    <label>Flavours</label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.FlavorName? drugInfo?.DrugDesc?.FlavorName : "--"}}</div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                    <label>Scoring</label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.ScoringName? drugInfo?.DrugDesc?.ScoringName : "--"}}</div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                    <label>Coating</label>
                                    <div class="label--data">{{drugInfo?.DrugDesc?.CoatingName? drugInfo?.DrugDesc?.CoatingName : "--"}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #COMPDRUGPATCH let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef.close();modalRef=null">
        <h4 header>Confirmation</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    This Compound's Unit AWP or Unit Cost Price has changed.
                </div>
                <div class="col-md-6">
                    Current AWP : ${{Drug?.value?.UnitPriceAWP}}
                </div>
                <div class="col-md-6">
                    New AWP : ${{(compoundDrugData?.totalAwp / compoundDrugData?.totalCount).toFixed(3)}}
                </div>
                <div class="col-md-6">
                    Current Cost : ${{Drug?.value?.UnitPriceCost}}
                </div>
                <div class="col-md-6">
                    New Cost : ${{(compoundDrugData?.totalCost / compoundDrugData?.totalCount)?.toFixed(3)}}
                </div>
                <div class="col-md-12">
                    Do you wish to update the prices?
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="patchCompValuesToDrug()" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="modalRef.close();modalRef=null" appShortcutKey InputKey="n">
          <b>N</b>  NO
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #COMPDRUGMISC let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="redirectToMisc(drugId);c('close')">
        <h4 header>Warning!!</h4>
        <span body>
            Please enter the compound related values for the compound drug.
        </span>
        <button footer autofocus class="hotkey_primary" (click)="redirectToMisc(drugId);c('close')" appShortcutKey InputKey="o"><b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>
