<div class="prescriber--tabing navTabStyles d-flex flex-row">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs col-11" 
        (tabChange)="beforeChange($event?.nextId)" #prescTabs >
        <li [ngbNavItem]="'Patient1'"  (click)="beforeChange('Patient1')">
            <a ngbNavLink class="nav-link" id="Patient1">
                <div class="txt">
                    <div>Prescriber <br />Info</div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient2'"  (click)="beforeChange('Patient2')">
            <a ngbNavLink class="nav-link" [ngClass]="{
                'avail-rec': prescriberInfo?.IsDocumentExists
            }">
                <div class="txt" >
                    <div >Documents </div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient3'"  (click)="beforeChange('Patient3')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div>  Clinic <br />
                        Addresses </div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient4'"  (click)="beforeChange('Patient4')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div> Prescriber <br />
                        History </div>
                </div>
            </a>
        </li>
        
        <li [ngbNavItem]="'Patient5'"  (click)="beforeChange('Patient5')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>Notes </div>
                </div>
            </a>
        </li>
        
    </ul>
    <ul class="col-1 height_2_9rem border_bottom_0_21_rem_blue">
        <li class="text-right">
            <app-user-guide-icon [ActiveTabId]="presbId ? 'EditPrescriber' : 'AddPrescriber'"></app-user-guide-icon>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #warning let-c="close" let-d="dismiss">
    <app-custom-modal (oncrossBtnClick)="c('Close click');closeWarning()">
            <h4 header>Prescriber</h4>
            <span body>Please save Prescriber record first.
            </span>
            <button footer autofocus  type="button" class="hotkey_primary" (click)="c('Close click');" (click)="addPrescriber()" appShortcutKey InputKey="y"> <b>Y</b>YES</button>
            <button footer type="button" class="hotkey_primary" (click)="c('Close click');closeWarning()" appShortcutKey InputKey="n"> <b>N</b> NO</button>
        </app-custom-modal>

</ng-template>
