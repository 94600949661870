import { RxUtils } from './../../../utils/rx.util';
import { Subscription } from 'rxjs';
import { PreviousRouteService } from 'src/app/services';
import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { SystemSetting } from "./../../../models/system-settings.model";
import { SystemData, DrugData } from "src/app/models";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { DrugService } from "../../../services/drug.services";
import { ActivatedRoute, Router } from "@angular/router";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    CommonService,
    EditDrugService,
    RxService,
    AlertService
} from "../../../services";
import { RegEx } from "../../../app.regex";
import { BaseUtils } from "../../../utils/base.utils";
import { PrintService } from "src/app/services/print.service";
import { MsgConfig } from "../../../app.messages";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { diff } from "deep-diff";
import * as _ from "lodash";

@Component({
    selector: "app-drug-counselling",
    templateUrl: "./drug-counselling.component.html"
})
export class DrugCounsellingComponent implements OnInit {
    @ViewChild("MedGuide", { static: true })
    MedGuide: any;

    drugID: number;
    drugInfo: DrugData;
    drugName: string;
    dosageInfoWJ: CollectionView;
    indicationWJ: CollectionView;
    cautionWJ: CollectionView;
    dosingWJ: CollectionView;
    warningWJ: CollectionView;
    dietaryWJ: CollectionView;
    RAWJ: CollectionView;
    lIWJ: CollectionView;
    WJ2: CollectionView;
    WJ3: CollectionView;
    WJ4: CollectionView;
    WJ5: CollectionView;
    WJ6: CollectionView;
    WJ7: CollectionView;
    WJ8: CollectionView;
    drugDescription: any;
    drugactvHeader: string[];
    indactvHeader: string[];
    warHeader: string[];
    dosHeader: string[];
    diteraryHeader: string[];
    cautionHeader: string[];
    header: string[];
    drugCounsellingDate: any;
    drugCounselFG: FormGroup;
    drugCodeFG: FormGroup;
    modalReference: any;
    systemData: SystemData;
    codeInfo: any;
    drugPregInfo: any;
    regex: any;
    lan = true;
    hasCodes = false;
    hasIndi = false;
    hasCaution = false;
    hasWar = false;
    hasdite = false;
    hasDose = false;
    hasWJ2 = false;
    i = 0;
    formGroupInvalid: boolean;
    systemStng: SystemSetting;
    pdfContent: SafeResourceUrl;
    medGuide: any;
    originalData: Object;
    hasRout = false;
    hasCaut = false;
    hasGenr = false;
    hasDoseFre = false;
    hasRAdm = false;
    hasDCau = false;
    HasGen = false;
    hasDosFrq = false;
    hasDriAdm = false;
    hasDrilStr = false;
    driGenInf = false;
    driPrice = false;
    driLeg = false;
    sharedCancel = false;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    canDeactivateRoute = true;
    hasWarning: boolean;
    modalReferenceSM: any;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                event.preventDefault();
                this.update();
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.CancelPopTaggle();
            }
        } else if (event.which === 27 && this.modalService.hasOpenModals()) {
            if (this.modalReferenceSM) {
                this.modalReferenceSM.close();
                event.preventDefault();
        } else if (this.modalReference) {
            this.closeModal();
            event.preventDefault();
        }
    }
}

    constructor(
        private _drugService: DrugService,
        private _actvRoute: ActivatedRoute,
        private modalService: NgbModal,
        private _fb: FormBuilder,
        private _commonServ: CommonService,
        private _drgSvc: EditDrugService,
        private _rxService: RxService,
        private _alertSer: AlertService,
        private _sanitizer: DomSanitizer,
        private _auditUtils: AuditLogUtils,
        private _route: Router,
        private _rxutils: RxUtils,
        private _printServ: PrintService
    ) {
        this.regex = RegEx;
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (params && params.fm) {
                    this.routeFrom = params.fm;
                }
                if (params && params.focus) {
                    if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                        this.focusOnDU = params.focus;
                    }
                }
            }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugID = +params["pid"])
        ));
    }

    ngOnInit() {
        this._commonServ.systemData$.subscribe(resp => {
            this.systemData = resp;
        });
        this._drgSvc.getDrugInfo(this.drugID).subscribe(resp => {
            this.drugName = resp["Drug"].Name;
            this._commonServ._drugInfo$.next(resp);
        });
        this.createDrugCodeFG();
        this.getDrugIndication();
        this.getDosageInfo();
        this.createDrugCounsellingFG();
        this.getDrugCounsellingData();
    }
    get Therapeutic(): FormControl {
        return this.drugCodeFG.get(
            "Therapeutic"
        ) as FormControl;
    }

    get TherapeuticCode(): FormControl {
        return this.drugCodeFG.get(
            "TherapeuticCode"
        ) as FormControl;
    }
    getDosageInfo() {
        this._drugService.getDosageInfo(this.drugID).subscribe(resp => {
            if (resp) {
                this.genratedosageInfoWJ(resp);
            }
        });
    }
    openLg(content, modalName) {
        if (modalName === "drugCounselling") {
            this.getDrugCounsellingData();
        }
        if (modalName === "drugIndication") {
            this.getDrugIndication();
        }
        this.modalReference = this.modalService.open(content, {
            size: "lg",
            centered: true,
            keyboard:false
        });
    }
    createDrugCounsellingFG() {
        this.drugCounselFG = this._fb.group({
            format: [true],
            language: this._commonServ.getSetttingValue(
                "PatientSettings",
                "PAT_LANGUAGE"
            )
        });
    }
    createDrugCodeFG() {
        this.drugCodeFG = this._fb.group({
            Therapeutic: ["0"],
            TherapeuticCode: ["0"],
            WarningCode: [""],
            IsWarningCodeChkd: true,
            Codedescription: [""],
            drugId: this.drugID,
            DrugCode: [""]
        });
    }
    getDrugCounsellingData() {
        const drugCounsOpts: any = this.drugCounselFG.value;
        this._drugService
            .getDrugCounsellingInfo(
                this.drugID,
                drugCounsOpts.format,
                drugCounsOpts.language
            )
            .subscribe(resp => {
                const respData: any = resp;
                if (respData.DrugDescription) {
                    this.drugDescription = respData.DrugDescription;
                }
                this.drugCounsellingDate = resp;
            });
    }
    genratedosageInfoWJ(dosageInfoList: any) {
        if (dosageInfoList) {
            this.dosageInfoWJ = new CollectionView(
                dosageInfoList.map((patient, i) => {
                    const j = {};
                    j["Age Category"] = patient.AGEBANDNAME;
                    j["From Age Days"] = patient.FromAgeDays
                        ? patient.FromAgeDays
                        : "";
                    j["To Age Days"] = patient.ToAgeDays
                        ? patient.ToAgeDays
                        : "";
                    j["Min Daily Units"] = patient.MINDAILYUNITS;
                    j["Max Daily Units"] = patient.MAXDAILYUNITS;
                    j["Usual Daily Units"] = patient.USUALDAILYUNITS;
                    j["Ingredient Name"] = patient.INGREDIENTNAME;
                    j["Min Daily Dose"] = patient.MINDAILYDOSE;
                    j["Max Daily Dose"] = patient.MAXDAILYDOSE;
                    j["Usual Daily Dose"] = patient.USUALDAILYDOSE;
                    j["Frequency"] = patient.FREQUENCY;
                    j["Frequency Days"] = patient.FREQUENCYDAYS;
                    j["LifeTime Max"] = patient.LIFETIMEMAX;
                    j["Patient Experience"] = patient.PATIENTEXPERIENCE;
                    j["Dosing Type"] = patient.DOSINGTYPE;
                    j[
                        "Hepatic Impairement May Require Dose Adjustment"
                    ] = patient.HEPATICIMPAIRMENTMAYREQUIREDOSEADJUSTMENT
                            ? "True"
                            : "False";
                    j[
                        "Renal Impairement May Require Dose Adjustment"
                    ] = patient.RENALIMPAIRMENTMAYREQUIREDOSEADJUSTMENT
                            ? "True"
                            : "False";
                    return j;
                })
            );

            this.drugactvHeader = [
                "Age Category",
                "From Age Days",
                "To Age Days",
                "Min Daily Units",
                "Max Daily Units",
                "Usual Daily Units",
                "Ingredient Name",
                "Min Daily Dose",
                "Max Daily Dose",
                "Usual Daily Dose",
                "Frequency",
                "Frequency Days",
                "LifeTime Max",
                "Patient Experience",
                "Dosing Type",
                "Hepatic Impairement May Require Dose Adjustment",
                "Renal Impairement May Require Dose Adjustment"
            ];
        }
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        for (let i = 0; i < flex.columns.length; i++) {
            flex.columns[i].align = "center";
        }
    }
    getDrugIndication() {
        this._drgSvc.getDrugCodes(this.drugID, this.lan).subscribe(resp => {
            if (resp) {
                this.codeInfo = resp;
                this.patchValue();
                const data = this.drugCodeFG.value;
                delete data["Codedescription"];
                delete data["WarningCode"];
                this.originalData = data;
                if (Object.keys(resp.DrillDownData).length !== 0) {
                    this.generateWJ(resp);
                    this.hasWarning = true;
                } else {
                    this.hasWarning = false;
                }
            } else {
                this.drugCodeFG.controls["Therapeutic"].patchValue("0");
                this.drugCodeFG.controls["TherapeuticCode"].patchValue("0");
            }
        });
    }
    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.codeInfo) {
            if (
                [
                    "Therapeutic",
                    "TherapeuticCode",
                    "WarningCode",
                    "Codedescription"
                ].indexOf(key) > -1 &&
                this.codeInfo[key]
            ) {
                this.drugCodeFG.controls[key].patchValue(
                    this.codeInfo[key].toString()
                );
            } else if (["IsWarningCodeChkd"].indexOf(key) > -1) {
                this.hasCodes = this.codeInfo[key];
                this.drugCodeFG.controls[key].patchValue(this.codeInfo[key]);
            } else if (["DrugCode"].indexOf(key) > -1) {
                this.drugCodeFG.controls[key].setValue(this.codeInfo[key]);
            }
        }
        this.drugCodeFG.markAsPristine();
        this.drugCodeFG.valueChanges.subscribe(value => {
            if (this.drugCodeFG.dirty) {
                this.canDeactivateRoute = false;
            } else {
                this.canDeactivateRoute = true;
            }
        });
    }
    update() {
        const data = this.drugCodeFG.value;
        delete data["Codedescription"];
        delete data["WarningCode"];
        if (this.drugCodeFG.valid) {
            this._drgSvc.updateDrugCoun(data).subscribe(resp => {
                if (resp) {
                    this._auditUtils.getChangedValues(
                        this.originalData,
                        data,
                        "Drug Counselling",
                        "Drug",
                        this.drugID
                    );
                    this._alertSer.success("Drug counselling updated successfully.");
                    this.routeBackToPrevScreen("update");
                } else {
                    this._alertSer.error("Drug counselling update unsuccessful.");
                }
            });
        } else {
            this.formGroupInvalid = true;
        }
    }
    updateLan(val: any) {
        this.lan = val;
        this.getDrugIndication();
    }
    displayCodes(val: any) {
        this.hasCodes = !this.hasCodes;
    }
    generateWJ(indiInfo: any) {
        if (indiInfo.Indications) {
            this.indicationWJ = new CollectionView(
                indiInfo.Indications.map((patient, i) => {
                    const j = {};
                    j["Description"] = patient.IndDescription;
                    return j;
                })
            );
            this.indactvHeader = ["Description"];
        } else {
            this.hasIndi = false;
        }
        if (indiInfo.Caution) {
            this.cautionWJ = new CollectionView(
                indiInfo.Caution.map((drug, i) => {
                    const j = {};
                    j["CAUTION"] = drug.AVOIDCAUTION.split(".");
                    j["EVIDENCE QUALITY"] = drug.QUALITYOFEVIDENCETEXT;
                    j["RECOMMENDATION"] = drug.STRENGTHOFRECOMMENDATIONTEXT;
                    return j;
                })
            );
            this.cautionHeader = [
                "CAUTION",
                "EVIDENCE QUALITY",
                "RECOMMENDATION"
            ];
        } else {
            this.hasCaution = false;
        }
        if (indiInfo && indiInfo.DrillDownData && indiInfo.DrillDownData.Dietary && indiInfo.DrillDownData.Dietary.length > 0) {
            this.hasdite = true;
            this.dietaryWJ = new CollectionView(
                indiInfo.DrillDownData.Dietary.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.diteraryHeader = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasdite = false;
        }
        if (indiInfo && indiInfo.DrillDownData && indiInfo.DrillDownData.Dosing && indiInfo.DrillDownData.Dosing.length > 0) {
            this.hasDose = true;
            this.dosingWJ = new CollectionView(
                indiInfo.DrillDownData.Dosing.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.dosHeader = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasDose = false;
        }
        if (indiInfo && indiInfo.DrillDownData && indiInfo.DrillDownData.Warning && indiInfo.DrillDownData.Warning.length > 0) {
            this.hasWar = true;
            this.warningWJ = new CollectionView(
                indiInfo.DrillDownData.Warning.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.warHeader = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasWar = false;
        }
        if ( indiInfo && indiInfo.DrillDownData
            && indiInfo.DrillDownData["Route of Administration"] && indiInfo.DrillDownData["Route of Administration"].length > 0) {
            this.hasRAdm = true;
            const dataInfo = indiInfo.DrillDownData["Route of Administration"];
            this.RAWJ = new CollectionView(
                dataInfo.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasRAdm = false;
        }
        if (indiInfo && indiInfo.DrillDownData && indiInfo.DrillDownData.Caution && indiInfo.DrillDownData.Caution.length > 0) {
            this.hasDCau = true;
            this.WJ2 = new CollectionView(
                indiInfo.DrillDownData.Caution.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasDCau = false;
        }
        if (indiInfo && indiInfo.DrillDownData && indiInfo.DrillDownData.Generic && indiInfo.DrillDownData.Generic.length > 0) {
            this.HasGen = true;
            this.WJ3 = new CollectionView(
                indiInfo.DrillDownData.Generic.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.HasGen = false;
        }
        if ( indiInfo && indiInfo.DrillDownData &&
            indiInfo.DrillDownData["Dosing Frequency"] &&
            indiInfo.DrillDownData["Dosing Frequency"].length > 0) {
            this.hasDosFrq = true;
            const dataInfo = indiInfo.DrillDownData["Dosing Frequency"];
            this.WJ4 = new CollectionView(
                dataInfo.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasDosFrq = false;
        }
        if (indiInfo && indiInfo.DrillDownData
            && indiInfo.DrillDownData.Administration
            &&  indiInfo.DrillDownData.Administration.length > 0) {
            this.hasDriAdm = true;
            this.WJ5 = new CollectionView(
                indiInfo.DrillDownData.Administration.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasDriAdm = false;
        }
        if ( indiInfo && indiInfo.DrillDownData && indiInfo.DrillDownData.Storage &&  indiInfo.DrillDownData.Storage.length > 0) {
            this.hasDrilStr = true;
            this.WJ6 = new CollectionView(
                indiInfo.DrillDownData.Storage.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.hasDrilStr = false;
        }
        if (indiInfo && indiInfo.DrillDownData
            && indiInfo.DrillDownData["General Information"]
            && indiInfo.DrillDownData["General Information"].length > 0) {
            this.driGenInf = true;
            const dataInfo = indiInfo.DrillDownData["General Information"];
            this.WJ7 = new CollectionView(
                dataInfo.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.driGenInf = false;
        }
        if (indiInfo && indiInfo.DrillDownData && indiInfo.DrillDownData.Pricing &&  indiInfo.DrillDownData.Pricing.length > 0) {
            this.driPrice = true;
            this.WJ8 = new CollectionView(
                indiInfo.DrillDownData.Pricing.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.driPrice = false;
        }
        if (indiInfo && indiInfo.DrillDownData
            && indiInfo.DrillDownData["Legal information"] && indiInfo.DrillDownData["Legal information"].length > 0) {
            this.driLeg = true;
            const dataInfo = indiInfo.DrillDownData["Legal information"];
            this.lIWJ = new CollectionView(
                dataInfo.map((drug, i) => {
                    const j = {};
                    j["WARNING"] = drug.WARNING;
                    j["GENDER"] = drug.GENDER;
                    j["AGE START"] = drug["AGE START"];
                    j["AGE END"] = drug["AGE END"];
                    return j;
                })
            );
            this.header = ["WARNING", "GENDER", "AGE START", "AGE END"];
        } else {
            this.driLeg = false;
        }
    }
    getDrugPregnancyInfo(content: any) {
        this._drugService
            .getDrugPregnancyInfo(this.drugID, "RxEntry")
            .subscribe(resp => {
                if (resp) {
                    this.drugPregInfo = resp;
                    this.modalReference = this.modalService.open(content, {
                        size: "lg",
                        keyboard:false
                    });
                }
            });
    }
    closeModal() {
        this.modalReference.close();
    }

    printMedGuide() {
        this._drugService.getDrugMedguide(this.drugID).subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.medGuide = resp;
                let urlCreator = window.URL;
                this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                    urlCreator.createObjectURL(resp)
                );
                this.openLg(this.MedGuide, "MedGuide");
            } else {
                this._alertSer.error(MsgConfig.MEDGUIDE_NOT_AVAILABLE);
            }
        });
    }

    print() {
        const newBlob = new Blob([this.medGuide], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
            this.closeModal();
        }.bind(this);
    }
    routeBackToPrevScreen(type?: any) {
        this.canDeactivateRoute = true;
        if (type && type === "update") {
            this._rxutils.updateLatestRxWithEditDrugInfo(this.drugID, this.routeFrom, null, "update", this.focusOnDU);
        } else {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugID, this.routeFrom, null, "cancel", this.focusOnDU);
        }
        // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    CancelPopTaggle() {
        if (this.drugCodeFG.dirty) {
        this.sharedCancel = true;
        } else {
        this.routeBackToPrevScreen();
        this.sharedCancel = false;
        }
    }

    CancelPopClose(val) {
        if (val) {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        } else {
            this.sharedCancel = false;
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    focusOutFmRem(event) {
        if (!(this.hasWarning && this.hasCodes)) {
            this.focusToFirst(event);
        }
    }
}
