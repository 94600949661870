<ng-template #insuPopUpToLink let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Please Select Insurance</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');addInsCodes()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [AutoFocus]="true" [LabelText]="'Filter'" [PlaceHolder]="'Search Insurance'"
                    (TriggerChangeValue)="fiterInsu($event)" [HasControl]="false">
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid #InsuList [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="insuWJ"
            [isReadOnly]="true">
            <wj-flex-grid-column header="Select" [width]="57">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span class="text-center">
                        <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected" (click)="selectCode(item,$event)"/>
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true"
            *ngFor="let actvHeader of activeHeadersIns; let i = index" [width]="
            actvHeader === 'Ins.Name' ? 220 : '*'">
            </wj-flex-grid-column>
        </wj-flex-grid>
        <div class="ml-2 mt-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 row">
            <label>Selected Insurances : </label>
            <div class="label--data">
                {{optedInsList.length}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="custmAdd" class="hotkey_success" (click)="addInsCodes('addcodes')" appShortcutKey
            [AltKey]="'o'"><span>O</span>Ok</button>
        <button type="button" class="hotkey_success" (click)="c('Close click'); addInsCodes()" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>