import { RxUtils } from './../../../utils/rx.util';
import { Subscription } from 'rxjs';
import { PreviousRouteService } from './../../../services/previous-route.service';
import { Drug } from "./../../../models/patient-Info.model";
import { CollectionView } from "@grapecity/wijmo";
import { DrugMisc, MiscInfo } from "./../../../models/edit-drug.model";
import { EditDrugService } from "./../../../services/edit-drug.service";
import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    HostListener,
    ViewChild
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { AlertService, CommonService } from "../../../services";
import * as _ from "lodash";
import {diff} from "deep-diff";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import { MsgConfig } from "../../../app.messages";

@Component({
    selector: "app-misc-information",
    templateUrl: "./misc-information.component.html"
})
export class MiscInformationComponent
    implements OnInit, AfterViewInit, OnDestroy {
    drugEditFG: FormGroup;
    drugId: number;
    miscInfo: MiscInfo;
    actvHeaders: string[];
    lotInfoWJ: CollectionView;
    editMode: boolean;
    pkg = [];
    drugInfo: Drug;
    drugName: string;
    minDate: any;
    modalReference: any;
    hasPkg = true;
    pkgIsActive = true;
    deleteLotData: any;
    deletePKDData: any;
    originalData: MiscInfo;
    hasLot: boolean;
    formGroupInvalid: boolean;
    sharedCancel: boolean;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    haspkgValue: boolean;
    activeModal: string;
    canDeactivateRoute = true;

    @ViewChild("lotList")
    flex: wjcGrid.FlexGrid;
    isFromCompound = false;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                event.preventDefault();
                this.updateDrug("U", null, null);
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.CancelPopTaggle();
            }
        } else if (event.which === 27 && (this.modalReference || this.modalReference)) {
            if (this.modalReference) {
                this.modalReference.close();
            } else if (this.modalReference) {
                this.modalReference.close();
            }
            event.preventDefault();
        }
    }

    constructor(
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _drgSvc: EditDrugService,
        private _actvRoute: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _alertSer: AlertService,
        private _commonServ: CommonService,
        private _auditUtils: AuditLogUtils,
        private _route: Router,
        private _rxutils: RxUtils
    ) {
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
             if (params && params.fm) {
                 this.routeFrom = params.fm;
                }
                if (params && params.focus) {
                    if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                        this.focusOnDU = params.focus;
                    }  else if (params.focus == "compound") {
                        this.isFromCompound = true;
                    }
                }
         }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugId = +params["pid"])
        ));


        this._commonServ.getDrugInfo(this.drugId).subscribe(resp => {
            this._commonServ._drugInfo$.next(resp);
            this.drugInfo = resp["Drug"];
            this.drugName = this.drugInfo.Name;
        });
        this.createFG();
    }

    ngOnInit() {
        this.getInfo();
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }
    ngAfterViewInit() {
        this._cdr.detectChanges();
        if (!this.isFromCompound) {
        const data: any = document.getElementById("Automated").getElementsByTagName("input")[0];
        data.focus();
        } else {
            const data: any = document.getElementById("DoseFrmDesc").getElementsByTagName("input")[0];
        data.focus();
        }
    }

    ngOnDestroy() {
        this._cdr.detach();
    }
    createFG() {
        this.drugEditFG = this._fb.group({
            DrugMisc: this._fb.group(new DrugMisc()),
            PkgMc: this._fb.array([]),
            DrugLot: this._fb.array([]),
            Druglot: this._fb.group({
                Action: [],
                DrugId: this.drugId,
                DrugTransId: [],
                Id: [],
                LotNum: [" "],
                ExpDt: [],
                TenantId: -7101307679,
                IsActive: []
            }),
            Pkgmc: this._fb.group({
                Action: "A",
                Id: null,
                IsActive: true,
                Name: [""],
                Remarks: null,
                TenantId: -7101307679
            })
        });
    }
    getInfo() {
        this._drgSvc.getMiscInfo(this.drugId).subscribe(resp => {
            if (resp) {
                const data = resp;
                this.miscInfo = resp;

                this.patchValue();
                this.originalData = data;
            }
        });
    }

    get DrugMisc(): FormGroup {
        return this.drugEditFG.get("DrugMisc") as FormGroup;
    }
    get Druglot(): FormGroup {
        return this.drugEditFG.get("Druglot") as FormGroup;
    }
    get Pkgmc(): FormGroup {
        return this.drugEditFG.get("Pkgmc") as FormGroup;
    }
    get PkgMc(): FormArray {
        return this.drugEditFG.get("PkgMc") as FormArray;
    }
    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (const key in this.miscInfo) {
            if (["DrugMisc"].indexOf(key) > -1) {
                if (this.miscInfo[key]) {
                this.drugEditFG.controls[key].patchValue(this.miscInfo[key]);
                } else {
                    const data: any = this.drugEditFG.controls["DrugMisc"];
                    data.controls["IsMedGuide"].patchValue(false);
                    data.controls["IsUnitDrug"].patchValue(false);
                }
            } else if (["DrugLot"].indexOf(key) > -1) {
                if (this.miscInfo[key]) {
                    this.generateWJ(this.miscInfo[key]);
                    this.hasLot = true;
                    this.generateFA(key, this.miscInfo[key]);
                } else {
                    this.hasLot = false;
                }
            } else if (["PkgMc"].indexOf(key) > -1) {
                if (this.miscInfo[key]) {
                this.generateFA(key, this.miscInfo[key]);
                this.haspkgValue = true;
                } else {
                    this.haspkgValue = false;
                }
            }  else {
                this.generateFA(key, this.miscInfo[key]);
            }
        }
        this.drugEditFG.markAsPristine();

        this.drugEditFG.valueChanges.subscribe(value => {
            if (this.drugEditFG.dirty) {
                this.canDeactivateRoute = false;
            } else {
                this.canDeactivateRoute = true;
            }
        });
    }

    getclass(drug) {
    }
    openPopUpModal(content) {
        this.drugEditFG.reset();
        this.editMode = false;
        this.modalReference = this.modalService.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Add";
    }
    closeModal() {
        this.modalReference.close();
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    generateWJ(info: any) {
        if (info) {
            this.lotInfoWJ = new CollectionView(
                info.map((drug, i) => {
                    const j = {};
                    j["Expiration Date"] = moment(drug.ExpDt).format(
                        "MM/DD/YYYY"
                    );
                    j["LotNumber"] = drug.LotNum;
                    j["Actions"] = "";
                    j["drugId"] = drug.Id;
                    j["IsActive"] = drug.IsActive;
                    j["Id"] = drug.Id;
                    j["IsDeleted"]=drug.IsDeleted;
                    return j;
                })
            );
            this.actvHeaders = ["Actions", "LotNumber", "Expiration Date", "IsActive"];
        }
    }
    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.drugEditFG.setControl(key, extraCostFormArray);
        }
    }
    updateDrug(flag, updateFrom?: string , deleteId?: any) {
        const dataInfo = this.drugEditFG.value;
        dataInfo.DrugMisc.DrugId = this.drugId;
        this._drgSvc.updateMisc(dataInfo).subscribe(resp => {
            if (resp) {
                if (updateFrom === "Add Lot") {
                    const newLotVal = (dataInfo.DrugLot.length > 0) ? dataInfo.DrugLot[dataInfo.DrugLot.length - 1] : null;
                    this._auditUtils.getChangedValues(
                        null,
                        newLotVal,
                        "Add Drug Lot",
                        "Drug",
                        this.drugId
                    );
                }  else if (deleteId) {
                    if (resp === 1) {
                    this._auditUtils.deletedValues(
                        deleteId,
                        updateFrom,
                        "Drug",
                        this.drugId
                    );
                }
                } else if ( updateFrom === "Edit Lot") {
                    const changedLot = _.filter(dataInfo.DrugLot, {Action : "U"});
                    const originalLot =  _.filter(this.originalData.DrugLot , { Id: changedLot[0].Id});
                    this._auditUtils.getChangedValues(
                        originalLot[0],
                        changedLot[0],
                        "Edit Drug Lot",
                        "Drug",
                        this.drugId
                    );
                } else {
                    this._auditUtils.getChangedValues(
                        this.originalData,
                        dataInfo,
                        "Misc Info",
                        "Drug",
                        this.drugId
                    );
                    if (this.isFromCompound) {
                        this._route.navigate(
                            ["/eprx/drug/" + this.drugId + "/druginfo"],
                            { queryParams: { fm: this.routeFrom } }
                        );
                    } else {
                        this.routeBackToPrevScreen("update");
                    }
                }

                this.getInfo();
                if (flag === "D") {
                    if (resp === 1 && updateFrom === "Delete Drug Lot") {
                        this._alertSer.success(MsgConfig.UPDATE_SUCCESS);
                    } else if (resp === 2 && updateFrom === "Delete Drug Lot") {
                        this._alertSer.error("Drug Lot cannot be deleted as it is already in use by other Rx's.");
                        this.getInfo();
                    } else {
                    this._alertSer.success(MsgConfig.DELETE_SUCCESS);
                    }
                } else if (flag === "U") {
                    this._alertSer.success(MsgConfig.UPDATE_SUCCESS);
                } else {
                    this._alertSer.success(MsgConfig.SAVE_SUCCESS);
                }
            } else {
                if (flag === "D") {
                this._alertSer.error(MsgConfig.DELETE_FAIL);
                } else if (flag === "U") {
                    this._alertSer.error(MsgConfig.UPDATE_FAIL);
                } else {
                    this._alertSer.error(MsgConfig.SAVE_FAIL);
                }
            }
        });
    }
    routeToRxScreen(rx: any) {
        this._commonServ.storePatientIdinBS(rx.value.Patient.patientid);
        this._commonServ.storeRxIdinBS(rx.RxID);
        localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
        // localStorage.setItem("RxInfo", JSON.stringify(rx.value));
        this.canDeactivateRoute = true;
        this._route.navigate(["eprx/rx"], {
            queryParams: {
                fm: rx.type
            }
        });
    }
    editLot() {
        const dataInfo = this.drugEditFG.value;
        for (const key in dataInfo.DrugLot) {
            if (key) {
                if (dataInfo.DrugLot[key].Id === dataInfo.Druglot.Id) {
                    dataInfo.DrugLot[key].Action = "U";
                    dataInfo.DrugLot[key].LotNum = dataInfo.Druglot.LotNum;
                    dataInfo.DrugLot[key].ExpDt = dataInfo.Druglot.ExpDt;
                }
            }
        }
        if (!(dataInfo.Druglot.LotNum)) {
            this.formGroupInvalid = true;
        }
        if (!(dataInfo.Druglot.ExpDt)) {
            this.formGroupInvalid = true;
        }
        if (dataInfo.Druglot.LotNum && dataInfo.Druglot.ExpDt) {
            this.updateDrug("U", "Edit Lot");
            this.modalReference.close();
            this.drugEditFG.controls["Druglot"].reset();
            this.drugEditFG.controls["Druglot"].markAsUntouched();
        } else {
            this.formGroupInvalid = true;
            this._alertSer.warning(MsgConfig.ENTER_REQ_FIELD);
        }
    }
    addLot() {
        const dataInfo = this.drugEditFG.value;
        dataInfo.Druglot.Action = "A";
        dataInfo.Druglot.DrugId = this.drugId;
        dataInfo.Druglot.IsActive = true;

        dataInfo.DrugLot.push(dataInfo["Druglot"]);
        if (dataInfo.Druglot.LotNum && dataInfo.Druglot.ExpDt) {
            this.updateDrug("U", "Add Lot", null);
            this.drugEditFG.controls["Druglot"].reset();
            this.drugEditFG.controls["Druglot"].markAsUntouched();
            this.modalReference.close();
        } else {
            this.formGroupInvalid = true;
            this._alertSer.warning(MsgConfig.ENTER_REQ_FIELD);
        }
    }
    deleteWarPopUp(ActiveValue, item) {
        // this.modalService.open(content, {
        //     centered: true
        // });
        item["IsActive"] = ActiveValue;
        this.deleteLotData = item;
        this.deleteLotdata();
    }
    deleteLotdata() {
        const data = this.deleteLotData;
        const dataInfo = this.drugEditFG.value;
        for (const key in dataInfo.DrugLot) {
            if (dataInfo.DrugLot[key].Id === data.drugId) {
                if (data["IsActive"]) {
                    dataInfo.DrugLot[key].Action = "U";
                } else {
                    dataInfo.DrugLot[key].Action = "D";
                }
                dataInfo.DrugLot[key].IsActive = data["IsActive"];
            }
        }
        this.updateDrug("D", "Delete Drug Lot", data.drugId);
    }
    editLotPopUpModal(content, val: any) {
        this.editMode = true;
        this.modalReference = this.modalService.open(content, { centered: true, keyboard:false, backdrop: false });
        this.activeModal = "Update";
        const dataInfo = this.drugEditFG.value;
        dataInfo.Druglot.LotNum = val.LotNumber;
        dataInfo.Druglot.ExpDt = val["Expiration Date"];
        dataInfo.Druglot.Id = val.drugId;
        this.drugEditFG.controls["Druglot"].patchValue(dataInfo.Druglot);
    }
    getPKD() {
        const dataInfo = this.drugEditFG.value;

        dataInfo.Pkgmc.Action = "A";
        dataInfo.Pkgmc.IsActive = true;
        for (const key in dataInfo.PkgMc) {
            if (dataInfo.PkgMc[key].Name === dataInfo.Pkgmc.Name) {
                this.hasPkg = false;
            }
        }
        if (this.hasPkg) {
            //this.miscInfo.PkgMc.push(dataInfo.Pkgmc);
            dataInfo.PkgMc.push(dataInfo.Pkgmc);
           // this.miscInfo.PkgMc = dataInfo.Pkgmc;
        }
        this.patchValue();
        this.hasPkg = true;
        delete dataInfo["Druglot"];
        const data: any = this.drugEditFG.controls["Pkgmc"];
        data.controls["Name"].patchValue(null);
        delete dataInfo["Pkgmc"];
        this._drgSvc.updateMisc(dataInfo).subscribe(resp => {
            if (resp) {
                this._auditUtils.getChangedValues(
                    null,
                    dataInfo.PkgMc[dataInfo.PkgMc.length - 1],
                    "Add Packaging Machine Id",
                    "Drug",
                    this.drugId
                );
                this.getInfo();
            } else {
                this._alertSer.error(MsgConfig.WRONG_MSG);
            }
        });
        // this.updateDrug();

    }
    deletePKDWarn(content, val: any) {
        this.modalReference= this.modalService.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Delete";
        this.deletePKDData = val;
    }
    deletePKDdata() {
        const vals = this.deletePKDData;
        const dataInfo = this.drugEditFG.value;
        dataInfo.PkgMc[vals].Action = "U";
        this._drgSvc.updateMisc(dataInfo).subscribe(resp => {
            if (resp) {
                this.getInfo();
            } else {
                this._alertSer.error(MsgConfig.WRONG_MSG);
            }
        });
        // this.updateDrug("D", "Delete Packaging Id", dataInfo.PkgMc[vals].Id);
    }
    routeBackToPrevScreen(type?: any) {
        this.canDeactivateRoute = true;
        if (type && type === "update") {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "update", this.focusOnDU);
        } else {
            this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        }
        // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    CancelPopTaggle() {
        if (this.drugEditFG.dirty) {
            this.sharedCancel = true;
        } else {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        }
    }

    CancelPopClose(val) {
        if (val) {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        } else {
            this.sharedCancel = false;
        }
    }

    focusOutFmCan(event) {
        if (document.getElementById("addLot")) {
            event.preventDefault();
            setTimeout(() => {
            const data: any = document.getElementById("addLot").getElementsByTagName("input")[0];
            data.focus();
            }, 10);
        }
    }

    focusToWijimo(event) {
        if (this.hasLot) {
            event.preventDefault();
            this.flex.focus();
        } else {
            this.focusToFirst(event);
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
}
