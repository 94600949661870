import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    HostListener
} from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import { DrugInfo } from "../../../models";
import * as moment from "moment";
import { DrugService } from "../../../services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-drug-dosage-info",
    templateUrl: "./drug-dosage-info.component.html",
    styles: []
})
export class DrugDosageInfoComponent implements OnInit {
    therapeuticCode: number;
    unsubscribe$: Subject<void> = new Subject();
    @Input()
    set DrugID(di: number) {
        if (di) {
            this.drugID = di;
        }
    }

    @Input()
    set DrugTherapCode(di: number) {
        if (di) {
            this.therapeuticCode = di;
            this.getDosageInfo();
        }
    }

    @Output()
    CloseDosageInfoPopUp = new EventEmitter<{}>();

    @ViewChild("DOSAGEINFO", { static: true })
    DOSAGEINFO: any;

    drugID: number;
    dosageInfoWJ: CollectionView;
    drugactvHeader: string[];
    modelRef: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }
    }


    constructor(
        private _drugService: DrugService,
        private _modalService: NgbModal
    ) {}

    ngOnInit() {}

    getDosageInfo() {
        this._drugService.getDosageInfo(this.drugID)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.genratedosageInfoWJ(resp);
            }
        });
    }

    genratedosageInfoWJ(dosageInfoList: DrugInfo[]) {
        this.dosageInfoWJ = new CollectionView(
            dosageInfoList.map((patient, i) => {
                const j = {};
                j["Age Category"] = patient.AGEBANDNAME;
                j["From Age Days"] = patient.FromAgeDays
                    ? moment(patient.FromAgeDays).format("L")
                    : "";
                j["To Age Days"] = patient.FromAgeDays
                    ? moment(patient.ToAgeDays).format("L")
                    : "";
                j["Min Daily Units"] = patient.MINDAILYUNITS;
                j["Max Daily Units"] = patient.MAXDAILYUNITS;
                j["Usual Daily Units"] = patient.USUALDAILYUNITS;
                j["Ingredient Name"] = patient.INGREDIENTNAME;
                j["Min Daily Dose"] = patient.MINDAILYDOSE;
                j["Max Daily Dose"] = patient.MAXDAILYDOSE;
                j["Usual Daily Dose"] = patient.USUALDAILYDOSE;
                j["Frequency"] = patient.FREQUENCY;
                j["Frequency Days"] = patient.FREQUENCYDAYS;
                j["LifeTime Max"] = patient.LIFETIMEMAX;
                j["Patient Experience"] = patient.PATIENTEXPERIENCE;
                j["Dosing Type"] = patient.DOSINGTYPE;
                j[
                    "Hepatic Impairement May Require Dose Adjustment"
                ] = patient.HEPATICIMPAIRMENTMAYREQUIREDOSEADJUSTMENT
                    ? "True"
                    : "False";
                j[
                    "Renal Impairement May Require Dose Adjustment"
                ] = patient.RENALIMPAIRMENTMAYREQUIREDOSEADJUSTMENT
                    ? "True"
                    : "False";
                return j;
            })
        );

        this.drugactvHeader = [
            "Age Category",
            "From Age Days",
            "To Age Days",
            "Min Daily Units",
            "Max Daily Units",
            "Usual Daily Units",
            "Ingredient Name",
            "Min Daily Dose",
            "Max Daily Dose",
            "Usual Daily Dose",
            "Frequency",
            "Frequency Days",
            "LifeTime Max",
            "Patient Experience",
            "Dosing Type",
            "Hepatic Impairement May Require Dose Adjustment",
            "Renal Impairement May Require Dose Adjustment"
        ];
        this.modelRef = this._modalService.open(this.DOSAGEINFO, {size: "lg", keyboard: false, centered: true});
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].height = 70;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    closeDosageInfoPopUp() {
        if (this.modelRef) {
        this.modelRef.close();
        }
        this.CloseDosageInfoPopUp.emit();
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
       }
}
