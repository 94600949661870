export enum datePickerenum {
    fillFrom = "From Fill Date",
    fillTo = "To Fill Date",
    orderFrom = "From Order Date",
    orderTo = "To Order Date",
    // deferFrom = "From Defer Date",
    // deferTo = "To Defer Date",
    dueDtFrom = "From Due Date",
    dueDtTo = "To Due Date",
    dueDtThrshldFrm = "From Due Date By Thershold",
    dueDtThrshldTo = "To Due Date By Thershold",
    dueDtPckUpFrm = "From Due Date Based on PickUp Date",
    dueDtPckUpTo = "To Due Date Based on PickUp Date",
    pickUpDtFrm = "From PickedUp Date",
    pickUpDtTo = "To PickedUp Date",
    accDtFrm = "From Date of Accident",
    accDtTo = "To Date of Accident",
    fillFrmDate = "FromDateFill",
    fillToDate = "ToDateFill",
    ordFrmDate = "FromDateOrder",
    ordToDate = "ToDateOrder",
    dueFrmDate = "FromDueDate",
    dueToDate = "ToDueDate",
    dueByThrshldFrmDate = "FromDueDateByThreshold",
    dueByThrshldToDate = "ToDueDateByThreshold",
    dueByPckUpFrmDate = "FromDueDateBasedPickupDate",
    dueByPckUpToDate = "ToDueDateBasedPickupDate",
    pickFrmDate = "FromDatePick",
    pickToDate = "ToDatePick",
    accFrmDate = "FromAccDate",
    accToDate = "ToAccDate"
}

export enum dropDownenum {
    insuranceNm = "Insurances",
    facilityNm = "Facility",
    patCatNm = "Patient Category",
    drugTypNm = "Drug Type",
    drugClassNm = "Drug Class",
    drugBrndNm = "Drug Brand",
    payorNm = "Payor",
    dawNm = "DAW Values",
    rxOrgnNm = "Rx Origin Code",
    pickUpStatNm = "Pickup Status",
    transfStatNm = "Rx Transfer Status",
    pharmacistNm = "Pharmacist",
    diagCodeNm = "Diagnosis Code(s)",
    rxStatNm = "Rx Status",
    invBuckNm = "Inventory Bucket Name",
    excldInsNm = "Exclude Insurances",
    insCode = "InsCode",
    facCode = "FacCode",
    patCatCode = "PatCatCode",
    drugTypId = "DrugTypeId",
    drugClasId = "DrugClassId",
    drugBrndId = "DrugBrandId",
    payorCd = "PayorCode",
    dawId = "DAWId",
    rxOrgId = "RxOriginId",
    pickUpStId = "PickUpStatusId",
    transfStatus = "TransferStatus",
    pharmacistId = "PharmacistId",
    rxStatusId = "RxStatusId",
    invBuckId = "InvBucketId",
    excludeIns= "ExcludeInsCode",
    diagCode = "DiagCode"
}
export enum checkBoxesenum {
    isActvPatient = "IsActivePatient",
    isOrginalRx = "IsOriginalRxs",
    isRefillsOnly = "IsRefillsOnly",
    isFacPatOnly = "IsFacilityPatOnly",
    isExcldFacPat = "IsExcludeFacPatients",
    isCashRxOnly = "IsCashRxsOnly",
    isInsPaidRxOnly = "IsInsPaidRxsOnly",
    incldUnbilled = "IncludeUnbilled",
    incldDefered = "IncludeDeferred",
    isCompoundRxOnly = "IsCompundRxOnly",
    is340BDrug = "Is340BDrug",
    isAuthRefMoreThanZero = "IsAuthRefMoreThanZero",
    isMissingSerialNumOnly = "IsMissingSNOnly",
    ActvPatientOnly = "ActivePatient Only",
    OriginalRxOnly = "OriginalRxs Only",
    RefillsOnly = "Refills Only",
    FacPatientsOnly = "Facility Patients Only",
    ExcldFacPatients = "Exclude Facility Patients",
    CashRxOnly = "Cash Rxs Only",
    InsPaidRxOnly = "Ins Paid Rxs Only",
    IncldUnbilled = "Include Unbilled",
    IncldDeferred = "Include Deferred",
    CompoundRxOnly = "Compound Rxs Only",
    Incld340BDrug = "Include 340B Drugs",
    AuthRefGrtZero = "Auth Refill Greater than Zero",
    MissingSerialNumOnly = "Missing Serial Numbers Only"
}