import { Component, Input, OnInit, ViewChild, Output, EventEmitter, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtil} from "src/app/utils";
import { MsgConfig } from '../../../app.messages';
import { MessageSettingEnum } from "../../../models/messaging.enum";
import { AlertService, CommonService, SettingsDataService } from '../../../services';
import { TitleCasePipe } from "@angular/common";
import { CommonWarnorconfirmPopupComponent } from '../common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html'
})
export class SendSmsComponent implements OnInit {
    mobileNumber: any;
    @Input()
    set PatientData(data) {
        this.patientInfo = data;
    }
    @Output() ClosePatientModal = new EventEmitter<any>();
    @ViewChild("openPopup", { static: true })
    openPopup: any;
    sendSmsFg: FormGroup;
    modelRef: any;
    patientInfo: any;
    patientGeneralSettings: any;
    msgFormatList: any;
    messageTypeId: any;
    dropDownData: any[] = [];
    messageDropdown : any = [];
    unsubscribe$: Subject<void> = new Subject();
    subscriptionMessage: any;
    formSubmitted: boolean;
  constructor(private _fb: FormBuilder,
    private _modalServ: NgbModal,
    private _comnUtil: CommonUtil,
    private _stngSvc: SettingsDataService,
    private _alertService: AlertService,
    private _commonServ: CommonService,
    public _titleCasePipe: TitleCasePipe) {
        this.messageTypeId = MessageSettingEnum.General;
       
     
   }

  ngOnInit() {
    this.subscriptionMessage = this._commonServ.getSetttingValue("MessageSettings", "Send_Subscription_Message_Alerts") === "1";
    this.patientGeneralSettings = this.patientInfo['PatientMessageSettings']['SendGeneralAlerts'];
    var messageCatId;
    if((this.patientGeneralSettings && this.subscriptionMessage) || this.patientGeneralSettings )
             messageCatId = MessageSettingEnum.General;
    else if(this.subscriptionMessage)
            messageCatId = MessageSettingEnum.Subscription;
    this.dropDownData = [{
        Id: null,
        Message: null,
        MessageCatId:  messageCatId,
        MessageTitle: "None",
        MessageSubject: "None",
        }];
    this.createFG();
    this.getSmsEmailTemplateList();
    if(this.patientGeneralSettings && this.subscriptionMessage)
             this.messageDropdown = [{id:MessageSettingEnum.General,name:'General' } , {id: MessageSettingEnum.Subscription, name: 'Subscription'}];
    else if(this.subscriptionMessage)
        this.messageDropdown = [ {id: MessageSettingEnum.Subscription, name: 'Subscription'}];
    else if(this.patientGeneralSettings)
        this.messageDropdown = [ {id: MessageSettingEnum.General, name: 'General'}];
      this.openModal();
      this.patchDefaultValues();
      
  }
  createFG() {
    this.sendSmsFg = this._fb.group({
        PatientName: null,
        ToNumber: null,
        Subject: null,
        Content: null,
        EntityId: null,
        MessageCatId: (this.patientInfo['PatientMessageSettings']['SendGeneralAlerts']) ?  this.messageTypeId : MessageSettingEnum.Subscription,
    });
  }
  openModal() {
    this.modelRef = this._modalServ.open(this.openPopup, { size: "lg" , centered: true, backdrop: false, keyboard: false });
  }
  patchDefaultValues() {
     this.patientInfo.Phones.map(phn => {
         if (phn.PhoneCatId === 2) {
            this.mobileNumber = phn.xPhone;
         }
      });
      this.sendSmsFg.controls["PatientName"].patchValue(this.patientInfo.Person.FirstName + " " + this.patientInfo.Person.LastName); 
      this.sendSmsFg.controls["ToNumber"].patchValue(this.mobileNumber ? this._comnUtil.getPhoneFormat(this.mobileNumber.trim()) : "");
      this.sendSmsFg.controls["EntityId"].patchValue(this.patientInfo.Patient.Id);
  }
    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
            this.ClosePatientModal.emit(false);
        }
    }

    sendSMSData() {

        const patName = this._titleCasePipe.transform(this.sendSmsFg.controls["PatientName"].value);
        if (this.sendSmsFg.valid) {
            this.formSubmitted = false;
            const smsFormGrpData = this.sendSmsFg.value;
            if (this.sendSmsFg.controls["Content"].value && (this.sendSmsFg.controls["Content"].value).includes("??PatientNm??")) {
                smsFormGrpData.Content = (this.sendSmsFg.controls["Content"].value).replaceAll("??PatientNm??", patName);
              }
              smsFormGrpData.ToNumber = this.mobileNumber;
              smsFormGrpData.PatientName = patName;
              if(this.sendSmsFg.value["MessageCatId"] === MessageSettingEnum.General) {
                this._stngSvc.sendSMSData(smsFormGrpData)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._alertService.success(
                            MsgConfig.MSG_SENT_SUCCESS
                        );
                        this.closeModal();
                    }
                });
              } else {
                if(this.patientInfo['isResendSubscription'] ) {
                    const modelRef = this._modalServ.open(CommonWarnorconfirmPopupComponent, {
                        backdrop: false,
                        keyboard: false, windowClass: "large--content"
                    });
                    modelRef.componentInstance.IsHeaderText = "Confirmation";
                    modelRef.componentInstance.warnMsg = "Patient already received Subscirption message. Do you want to resend the message?";
                    modelRef.componentInstance.yesButton = true;
                    modelRef.componentInstance.noButton = true;
                    modelRef.componentInstance.IsPopUpClosed
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((resp: any) => {
                        modelRef.close(); 
                        if(resp)
                             this.sendSMSDataForSub();          
                    });
                } else {
                    this.sendSMSDataForSub();
                }
              }
        } else {
            this.formSubmitted = true;
        }

    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    patchMsgContent(event, name) {
        if (name && (name == 'Format') && event && event.value) { 
            this.sendSmsFg.controls["Content"].patchValue(event.value.Message ? event.value.Message : null);
        }
         else  {
            this.sendSmsFg.controls["MessageCatId"].patchValue(event?.value?.id ? event.value.id : this.messageTypeId);
            this.getSmsEmailTemplateList();
         }
          
    }
    getSmsEmailTemplateList() {
        this._stngSvc.getSmsEmailTemplateList(this.sendSmsFg.value['MessageCatId'])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
              this.msgFormatList = resp;
              this.msgFormatList = this.dropDownData.concat(this.msgFormatList);
              if (this.msgFormatList && this.msgFormatList[0]) {
                  this.sendSmsFg.controls["Subject"].patchValue(this.msgFormatList[0].MessageTitle);
                  this.sendSmsFg.controls["Content"].patchValue(this.msgFormatList[0].Message);
                }
            }
        });
    }
    sendSMSDataForSub() {
        this.patientInfo['SubscriptionMessage'] = this.sendSmsFg.value['Content'];
        this.patientInfo['isResendSubscription']  = true;
        this.patientInfo['IsSendSMS']  = true;
        this.patientInfo['SubscriptionTempId']  = this.sendSmsFg.value['Subject'];
        this._stngSvc.sendSMSDataForSub(this.patientInfo)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this._alertService.success(
                    MsgConfig.MSG_SENT_SUCCESS
                );
                this.closeModal();
            }
        });
    }
}
