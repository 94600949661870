export class searchTrackPatientCriteria {
    SearchText: string = null;
    SearchBy: string = null;
    OrderByColumn: string = null;
    SortDirection: string = null;
    PageNumber: number = null;
    PageSize: number = null;
    PrescrefillId: null;
}

export class SearchTrackPatient {
    PageNumber: number = null;
    PageSize: number = null;
    Document: any = null;
    Function: any = null;
    FromLogDate: any = null;
    ToLogDate: any = null;
    Initail: any = null;
    OverrideInitial: any = null;
    entityId: any = null;
}
