<div class="editpatient--tabing navTabStyles  d-flex flex-row">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs col-11" #PATIENTTABS
    (tabChange)="beforeChange($event?.nextId)">
    <li [ngbNavItem]="'Patient1'"  (click)="beforeChange('Patient1')">
        <a ngbNavLink class="nav-link" id="Patient1">
            <div class="txt">
                <div>Patient Info</div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient2'" (click)="beforeChange('Patient2')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Additional Insurance </div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient3'" (click)="beforeChange('Patient3')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>More Patient Info </div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient4'" (click)="beforeChange('Patient4')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Facility Info </div>
            </div>
        </a>
    </li>
    
    <li [ngbNavItem]="'Patient5'" (click)="beforeChange('Patient5')">
        <a ngbNavLink class="nav-link">
            <div class="txt" >
                <div>Diagnosis Details </div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient6'" (click)="beforeChange('Patient6')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Delivery Address </div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient7'" (click)="beforeChange('Patient7')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Notes </div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient8'" (click)="beforeChange('Patient8')" >
        <a ngbNavLink class="nav-link" [ngClass]="{ 'avail-rec': patientInfo?.IsDocumentExists }">
            <div class="txt"  >
                <div>Documents</div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient9'" (click)="beforeChange('Patient9')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Clinical Info</div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient10'" (click)="beforeChange('Patient10')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Patient Category</div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient11'" (click)="beforeChange('Patient11')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Life Style Concept</div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient12'" (click)="beforeChange('Patient12')" >
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Messaging</div>
            </div>
        </a>
    </li>
    <li [ngbNavItem]="'Patient13'" (click)="beforeChange('Patient13')">
        <a ngbNavLink class="nav-link">
            <div class="txt">
                <div>Track Patient</div>
            </div>
        </a>
    </li>
       
     </ul>
    <ul class="col-1 height_2_9rem border_bottom_0_21_rem_blue">
        <li class="text-right">
            <app-user-guide-icon [ActiveTabId]="patientID ? 'EditPatient' : 'AddPatient'"></app-user-guide-icon>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div> 