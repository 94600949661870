<!-- <ng-template #openTabs let-c="close" let-d="dismiss" class="el--popup"> -->
    <div class="col-md-12 editpathistory--tabing mb-0 navTabStyles">
        <div class="row">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" (tabChange)="beforeChangetab($event?.nextId)" class="nav-tabs col-12">
          <li [ngbNavItem]="'rxVerfTab'" (click)="!disableRxTab ? beforeChangetab('rxVerfTab') : ''" class="text-decoration-none" [disabled]="disableRxTab" [title]="noPrivsRx ? 'You do not have privilege to perform this action.': (disableRxTab ? 'Require Rx Verification setting is set to No in Account Settings.' : '')">
            <a ngbNavLink class="nav-link text-decoration-none" id="rxVerfTab">
              <div class="txt">
                  <div>Rx Verification</div>
              </div>
            </a>
            <ng-template ngbNavContent>
              <app-rph-verify-modal *ngIf="rxActv" [IsRxVerification]="IsRxVerification" [totalRecordsCount]="totalRecordsCount" [RxInfo]="rxInfoList" [RxID]="RxId"  [RefilID]="RefillId" [FillID]="FillId" [IsPatientHistory]="isPatientHistory" (CloseRphModal)="closeModal('rxVerf')" (emitRxInfo)="storeRxInfo($event)" (emitRxDet)="storeRxDetals($event)"></app-rph-verify-modal>
            </ng-template>
          </li>
          <li [ngbNavItem]="'dpvVerfTab'" (click)="!disableDpvTab ? beforeChangetab('dpvVerfTab') : ''" class="text-decoration-none" [disabled]="disableDpvTab" [title]="noPrivsDPV ? 'You do not have privilege to perform this action.': (disableDpvTab ? 'Need to Complete Rx Verification before DrugPick Verification.' : '')">
            <a ngbNavLink class="nav-link text-decoration-none" id="dpvVerfTab">
              <div class="txt">
                  <div>DrugPick Verification</div>
              </div>
            </a>
             <ng-template ngbNavContent>
              <app-verify-info *ngIf="dpvActv" [RxInfo]="rxInfo" [IsPatientHistory]="isPatientHistory" (EnableRphTabAfterTab)="disableRphTab = false" (TriggerChangeTab)="changeTab($event)" (emitRxDet)="storeRxDetals($event)"></app-verify-info>
            </ng-template> 
          </li>
          <li [ngbNavItem]="'rphVerfTab'" (click)="!disableRphTab ? beforeChangetab('rphVerfTab') : ''" class="text-decoration-none" [disabled]="disableRphTab" [title]="noPrivsRph ? 'You do not have privilege to perform this action.': (disableRphTab ? 'Need to Complete DrugPick Verification before Rph Verification.' : '')">
              <a ngbNavLink class="nav-link text-decoration-none" id="rphVerfTab"> <div class="txt">
                  <div>Pharmacist Verification</div>
              </div>
               </a>
               <ng-template ngbNavContent>
                <app-rph-verify-modal *ngIf="rphActv" [IsRxVerification]="IsRxVerification" [totalRecordsCount]="totalRecordsCount" [RxInfo]="rxInfoList" [RxID]="RxId"  [RefilID]="RefillId" [FillID]="FillId" [IsPatientHistory]="isPatientHistory" (CloseRphModal)="closeModal()" (emitRxInfo)="storeRxInfo($event)" (emitRxDet)="storeRxDetals($event)"></app-rph-verify-modal>
              </ng-template>
            </li>
            <div class="col text-right mr-n3 pr-0">
              <button (click)="closeModal()" class="hotkey_success  hotkey_primary--Override"
                 appShortcutKey [AltKey]="'c'"><span>C</span>Close</button>
          </div>
        </ul>
        </div>
        
        <div [ngbNavOutlet]="nav"></div>
      </div>

<app-login-verify
      *ngIf="(activeModal === 'DrugPickVerification') && openPopUp"
      [LoginTab]="activeModal" [FromVerftabPage]="true" [HeaderName]="activeModal"
      (setPopUpValue)="routeToScreen('dpvVerfTab');"
></app-login-verify>
<app-login-verify
      *ngIf="((activeModal === 'RphVerification') || ( activeModal=== 'RxVerification')) && openPopUp" [HeaderName]="activeModal"
      [LoginTab]="activeModal" [FromVerftabPage]="true" (setPopUpValue)="routeToScreen((activeModal === 'RphVerification' ? 'rphVerfTab' : 'rxVerfTab'), (frmDPVToRph ? eventRph : null))"
></app-login-verify>
      <!-- </ng-template> -->
      