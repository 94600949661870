import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";
import { RxInputParams } from "../models";

@Injectable()
export class QuickRefillService {
    constructor(private _http: HttpClient) {}

    postRefillData(rxInputParams: RxInputParams): Observable<any> {
        return this._http.post<any>(constant.POST_Rx_QuickRefill, rxInputParams);
    }

    getRxDeliveryInfo(rxId, prescRefillId?): Observable<any> {
        return this._http.get<any>(constant.GET_Rx_DeliveryInfo.replace("{rxId}", "" + rxId)
        .replace("{prescRefillId}", "" + prescRefillId));
    }


    putRxDeliveryInfo(deliveryAdrsInfo): Observable<any> {
        return this._http.put<any>(constant.PUT_Rx_DeliveryInfo, deliveryAdrsInfo);
    }

    saveQuickRefill(data): Observable<any> {
        return this._http.put<any>(constant.PUT_Rx_QuickRefill, data);
    }

    putRxExpiryDate(data): Observable<boolean> {
        return this._http.put<boolean>(constant.PUT_Rx_DrugExpiry, data);
    }
}
