import { Component, Input, OnInit } from '@angular/core';
import { MsgConfig } from 'src/app/app.messages';

@Component({
  selector: 'app-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss']
})
export class NoDataFoundComponent implements OnInit {
@Input() TextDisplay: string; 
@Input() IsPageFrm: string; 

  constructor() { }

  ngOnInit(): void {
    if(!this.TextDisplay) 
      this.TextDisplay =  MsgConfig.DEFAULT_NO_DATA_FOUND_WARN
  }

}
