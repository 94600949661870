import { BaseUtils } from "./../../../utils/base.utils";
import { AlertService, CommonService, DocumentsService, UserService } from "src/app/services";
import { FormGroup, FormControl } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    HostListener,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RphVerificationService, RxService } from "../../../services";
import * as moment from "moment";
import {
    DrugInteraction,
    DrugToDrugIntractn,
    DiseaseContradication,
    PregnancyLactationInfo,
    RxModel,
    DocumentInputParams,
    SharedDocuments,
    DrugInteractionData,
} from "../../../models";
import * as _ from "lodash";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { NRxUtils, ValidateFormFieldsUtils } from "src/app/utils";
import { Subject, Subscription } from "rxjs";
import * as Sentry from "@sentry/browser";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-quick-verf",
    templateUrl: "./quick-verf.component.html",
})
export class QuickVerfComponent implements OnInit {
    imgURL = "empty.jpg";
    quickFG: FormGroup;
    formGroupInvalid = false;
    rxNumber: number;
    rxInfo: any;
    today: any;
    minDate: any;
    time: any;
    modelRef: any;
    subscriptions = new Subscription();
    drugToDrugInteractions: DrugToDrugIntractn;
    drugDeseContrd: DiseaseContradication;
    drugPregInfo: PregnancyLactationInfo;
    postData: any;
    QuickMod: any;
    InvalidPassword3 = false;
    InvalidPassword2 = false;
    InvalidPassword1 = false;
    getDocParams = new DocumentInputParams();
    rxInfoData: any;
    patientInfo: any;
    billToInsu: any;   // primaryInsu
    actvModal: string;
    actvModal2: string;
    currentDrugPopIndex = 0;
    AllergyData: string;
    pharmacistDet = [];
    drugToDrugInteractions1: DrugToDrugIntractn;
    drugUserIsVerified = true;
    rphUserIsVerified =  true;
    drugAllergyData: string;
    currentLifeStyleIndex = 0;
    adverseDataMonograph: string;
    drugLifeStyle: any;
    isRxRequired: any = false;
    isRxRequiredForOrginal: any = false;
    isOrginalRx: any = false;
    isDpvRequired: any = false;
    isRPhRequired: any = false;
    isRxHtmlRequired: any = false;
    isDpvHtmlRequired: any = false;
    isRPhhtmlRequired: any = false;
    isTransimissonrequired: any = false;
    totalDocs: any = [];
    showErxDocs = false;
    unsubscribe$: Subject<void> = new Subject();
    isCancelClicked = false;
    @Input()
    set RxNumber(rxNo: number) {
        this.rxNumber = rxNo;
    }

    @Input() IsQuickForRxVer: boolean;
    @Input() drugInteractions: DrugInteraction;
    @Input() appUserData: any;
    @Input() directVerf: boolean;

    @Input()
    set RxInfo(rxinfo: any) {
        this.rxInfoData = rxinfo;
        this.billToInsu = _.filter(this.rxInfoData.Patient.insurance, {
            insuranceid: +this.rxInfoData.RefillInsu.InsuId,
        })[0];
    }
    @Input() rxFG: FormGroup;

    @Output()
    QuickVerfRes = new EventEmitter<any>();

    @ViewChild("QuickVer", { static: true })
    QuickVer: any;

    @ViewChild("DRUGALLERGIES", { static: true })
    DRUGALLERGIES: any;

    @ViewChild("DRUGDISEASECONTRIND", { static: true })
    DRUGDISEASECONTRIND: any;

    @ViewChild("DRUGTODRUGINTERACTION", { static: true })
    DRUGTODRUGINTERACTION: any;

    @ViewChild("DUPLICATEDRUG", { static: true })
    DUPLICATEDRUG: any;

    @ViewChild("DRUGPREGNENCY", { static: true })
    DRUGPREGNENCY: any;

    @ViewChild("ADVERSEDRUG", { static: true })
    ADVERSEDRUG: any;

    @ViewChild("LIFESTYLE", { static: true })
    LIFESTYLE: any;

    @ViewChild("CANCEL", { static: true })
    CANCEL: any;

    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (
            event.which === 27 &&
            (this.modelRef || this.QuickMod) &&
            this._modalService.hasOpenModals()
        ) {
            if (this.actvModal === "Cancel verf") {
                this.modelRef.close();
                this.cancelVerf();
                this.actvModal = "";
            } else if (this.actvModal === "Quick Verf") {
                this.openCancelPopUP();
            } else {
                this.modelRef.close();
            }
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _modalService: NgbModal,
        private _rphService: RphVerificationService,
        private _rxService: RxService,
        private _docService: DocumentsService,
        private _alertSrv: AlertService,
        private _commonServ: CommonService,
        private _userService: UserService,
        private _baseUtils: BaseUtils,
        private _formUtils: ValidateFormFieldsUtils,
        public _formatsUtil:FormatsUtil,
        private _nrxUtls: NRxUtils

    ) {
        this.quickFG = this._fb.group({
            recvBy: [],
            recvTm: [this.rxInfoData && this.rxInfoData.eRxDetailsViewModel && this.rxInfoData.eRxDetailsViewModel.ErxRecievedDate ?
                  moment(this.rxInfoData.eRxDetailsViewModel.ErxRecievedDate).format("hh:mm") : moment().format("hh:mm A")],
            recvDt: this.rxInfoData && this.rxInfoData.eRxDetailsViewModel && this.rxInfoData.eRxDetailsViewModel.ErxRecievedDate ?
                moment(this.rxInfoData.eRxDetailsViewModel.ErxRecievedDate).format("MM/DD/YYYY") : [],
            recvPsw: [],
            drugBy: [],
            drugTm: [moment().format("hh:mm A")],
            drugDt: [],
            drugPsw: [],
            rphBy: [],
            rphTm: [moment().format("hh:mm A")],
            rphDt: [],
            rphPsw: [],
        });
        this.postData = [
            {
                UserName: "",
                Pasword: "",
                VerificationType: "drug",
            },
            {
                UserName: "",
                Pasword: "",
                VerificationType: "rph",
            },
        ];
    }

    ngOnInit() {
        if(this.rxInfoData["Drug"]["drugtypeid"] == 2 && +this.rxInfoData.PrescReFill.DispQty < +this.rxInfoData.PrescReFill.OrdQty) {
            return;
        }
        // if (!(this.IsQuickForRxVer || this.directVerf)) {
        //     this.checkSetngValueAndPatch();
        // }
        if (!this.drugInteractions) {
            this.getDrugInteractionInfo();
        } else {
            this.patchDrugInteractions();
        }
        if (this.appUserData) {
            this.appUserData.map((val) => {
                if (val.Persona.includes("1") || val.Persona.includes("2")) {
                    this.pharmacistDet.push(val);
                }
            });
        }
        this.getDocumentsBeforeOpenModal();
    }
    async  getDocumentsBeforeOpenModal() {
        this.getDocParams.DocCatId = SharedDocuments.Prescription;
        if (this.rxInfoData && this.rxInfoData["Prescription"]) {
            this.getDocParams.PrescNum = this.rxInfoData["Prescription"]["PrescNum"]
        }
        if (this.rxInfoData && this.rxInfoData["PrescReFill"]) {
            this.getDocParams.PrescNum = this.rxInfoData["Prescription"]["PrescNum"];
            this.getDocParams.RefillNum = this.rxInfoData["PrescReFill"]["ReFillNum"];
            this.getDocParams.FillNum=this.rxInfoData["PrescReFill"]["PartialFillNo"];
        }
        if (this.rxInfoData && (this.rxInfoData["rxType"] !== "nr")) {
          await  this.getDocuments();
        }
        if (!this.QuickMod) {
            this.openModal();
        }
    }
   async getDocuments() {
        const resp1 = await this._docService.getDocList(this.getDocParams).toPromise();
        if (resp1 && resp1.Documents) {
            this.totalDocs = resp1.Documents;
        }
    }

    // checkSetngValueAndPatch() {
    //     this.isRxRequired  = parseFloat(this._commonServ.getSetttingValue("WorkFlowSettings", "Rx_Verification"));
    //     this.isDpvRequired = parseFloat(this._commonServ.getSetttingValue("WorkFlowSettings", "Drug_Pick_Verification"));
    //     this.isRPhRequired = parseFloat(this._commonServ.getSetttingValue("WorkFlowSettings", "RPH_Verification"));
    // }

    openModal() {
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date(),
        };
        this.quickFG.controls["recvDt"].patchValue(this.today);
        // this.quickFG.controls["drugDt"].patchValue(this.today);
        // this.quickFG.controls["rphDt"].patchValue(this.today);
        if (this.rxFG && this.rxFG.value) {
            this.showErxDocs = this._nrxUtls.isShowErxDocument(this.rxFG.getRawValue() , this.rxInfoData["rxType"]);
        }
        this.QuickMod = this._modalService.open(this.QuickVer, {
            size: "lg",
            backdrop: false,
            keyboard: false,
            windowClass: "max-modal-94"
        });
        this.actvModal = "Quick Verf";
    }

    saveQuickVerf() {
        this._formUtils.blurAndDelay();
        if (!this.quickFG.valid) {
            this.isCancelClicked = false;
            this._alertSrv.error(this.IsQuickForRxVer ? "Rx Verification Required before Transmission." : "Enter required fields.");
            return;
        }
         // if (this.IsQuickForRxVer || this.drugUserIsVerified || this.rphUserIsVerified) {
        if (this.isCancelClicked) {
            this.cancelVerf();
        } else {
        if (this.IsQuickForRxVer) {
            this.closeQuickVerfPopupOnSave();
        } else {
            this.checkingUserValid();
        }
    }
    }

    async checkingUserValid() {
        // let hasDphVal = false;
        // let hasRphVal = false;
        // if ((!this.drugUserIsVerified && this.quickFG.value["drugBy"] && this.quickFG.value["drugPsw"])) {
        //     this.postData[0].UserName = this.quickFG.value["drugBy"];
        //     this.postData[0].Pasword = this.quickFG.value["drugPsw"];
        //     hasDphVal = true;
        // } else {
        //     this.postData[0].UserName = "";
        //     this.postData[0].Pasword = "";
        //     hasDphVal = false;
        // }
        // if ((!this.rphUserIsVerified && this.quickFG.value["rphBy"] && this.quickFG.value["rphPsw"])) {
        //    this.postData[1].UserName = this.quickFG.value["rphBy"];
        //    this.postData[1].Pasword = this.quickFG.value["rphPsw"];
        //     hasRphVal = true;
        // } else {
        //     this.postData[1].UserName = "";
        //     this.postData[1].Pasword = "";
        //     hasRphVal = false;
        // }
        // if (hasRphVal || hasDphVal) {
        //     const resp: any = await  this._rxService.checkAppUser(this.postData).toPromise();
        //     if (resp.drug && resp.rph) {
        //         this.closeQuickVerfPopupOnSave();
        //     } else {
        //         this.formGroupInvalid = true;
        //         this.InvalidPassword3 = hasRphVal ? resp["rph"] ? false : true : false;
        //         this.InvalidPassword2 = hasDphVal ? resp["drug"] ? false : true : false;
        //         const errorMsg = this.InvalidPassword3 && this.InvalidPassword2 ?
        //         "Password for Drug Pick verification and RPH Verification is not correct." :
        //         this.InvalidPassword3 ? "Password for RPH Verification is not correct." :
        //         this.InvalidPassword2 ? "Password for Drug Pick verification is not correct." : null;
        //         if (errorMsg) {
        //             this._alertSrv.error(errorMsg);
        //         }
        //     }
        // } else {
            this.closeQuickVerfPopupOnSave();
        // }
    }
 gerLoginUserDetails() {
        const techname = this._userService.getToken("TechUserName");
        const isTechnician = techname && techname.length ? true : false;
        const adminName = this._userService.getToken("PhUserName").toLowerCase();
        const checkVal = isTechnician ? techname.toLowerCase() : adminName;
        return checkVal;

    }
    closeQuickVerfPopupOnSave() {
        if (this.QuickMod) {
            this.QuickMod.close();
        }
        // const checkVal = this.gerLoginUserDetails();
        // if (this.quickFG.value["drugBy"] && (this.quickFG.value["drugBy"].toLowerCase() !== checkVal) && !this.quickFG.value["drugPsw"]) {
        //     this.quickFG.controls["drugBy"].patchValue("");
        // }
        // if (this.quickFG.value["rphBy"] && this.quickFG.value["rphBy"].toLowerCase() !== checkVal && !this.quickFG.value["rphPsw"]) {
        //     this.quickFG.controls["rphBy"].patchValue("");
        // }
        this.QuickVerfRes.emit(this.quickFG.getRawValue());
        this.formGroupInvalid = false;
    }

    async openCancelPopUP() {
        if ((this.IsQuickForRxVer) && !this.directVerf) {
            this.isCancelClicked = true;
            await this.saveQuickVerf();
            return;
        }
        this.formGroupInvalid = false;
        if (this.QuickMod) {
            this.QuickMod.close();
        }
        if (this.directVerf) {
            this.cancelVerf();
        } else {
            this.modelRef = this._modalService.open(this.CANCEL, {
                centered: true,
                keyboard: false,
                windowClass:"large--content"
            });
            this.actvModal = "Cancel verf";
        }
    }

    backTOVerf() {
        if (this.modelRef) {
        this.modelRef.close();
        }
        this.openModal();
    }

    cancelVerf() {
        this.isCancelClicked = false;
        if (this.QuickMod) {
            this.QuickMod.close();
        }
        this.QuickVerfRes.emit(false);
        this.actvModal = "";
    }
    checkUserIsLogin(type) {
        this.formGroupInvalid = false;
        const techname = this._userService.getToken("TechUserName");
        const isTechnician = techname && techname.length ? true : false;
        const adminName = this._userService.getToken("PhUserName").toLowerCase();
        this.quickFG.controls[type === "rxRec" ? "recvDt" : ""].patchValue(this.today);
        // if (type !== "rxRec") {
        //     this.quickFG.controls[type === "drug" ? "drugPsw" : "rphPsw"].patchValue(null);
        // }
        if (type === "rxRec") {
            this.recvDt.markAsUntouched();
        }
        // else if (type === "drug") {
        //     this.drugDt.markAsUntouched();
        //     this.InvalidPassword2 = false;
        //     const checkVal = isTechnician ? techname.toLowerCase() : adminName;
        //     if (this.quickFG.value["drugBy"] &&
        //     this.quickFG.value["drugBy"].toLowerCase() === checkVal) {
        //         this.drugUserIsVerified = true;
        //         this.drugPsw.clearValidators();
        //         this.drugPsw.updateValueAndValidity();
        //     } else {
        //         this.drugUserIsVerified = false;
        //     }
        // } else {
        //     this.rphDt.markAsUntouched();
        //     this.InvalidPassword3 = false;
        //     if (this.quickFG.value["rphBy"] &&
        //      this.quickFG.value["rphBy"].toLowerCase() === adminName) {
        //         this.rphUserIsVerified = true;
        //         this.rphPsw.clearValidators();
        //         this.rphPsw.updateValueAndValidity();
        //     } else {
        //         this.rphUserIsVerified = false;
        //     }
        // }
    }


    getSetttingValue() {
        // this.isRxRequired  = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Rx_Verification"), 0);
        this.isRxRequiredForOrginal  = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Rx_Verification_Required_Before_Transmission"), 0);
        // this.isDpvRequired = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Drug_Pick_Verification"), 0);
        // this.isRPhRequired = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "RPH_Verification"), 0);
        this.isOrginalRx = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        let actionType = this.rxInfoData["rxActnType"];
            if ((!this.isRxRequiredForOrginal &&
                this.rxInfoData && ((actionType === "TransmitNow" || actionType === "HoldRx" || actionType === "TrnsmtPatent") ))) {
                this.recvBy.clearValidators();
                this.recvPsw.clearValidators();
                this.recvBy.updateValueAndValidity();
                this.recvPsw.updateValueAndValidity();
            }
            // if (!this.isDpvRequired) {
            //     this.drugPsw.clearValidators();
            //     this.drugBy.clearValidators();
            //     this.drugPsw.updateValueAndValidity();
            //     this.drugBy.updateValueAndValidity();
            // }
            // if (!(this.isRPhRequired)) {
            //     this.rphPsw.clearValidators();
            //     this.rphPsw.updateValueAndValidity();
            //     this.rphBy.clearValidators();
            //     this.rphBy.updateValueAndValidity();
            // }
    }

    checkVerf() {
        this._formUtils.blurAndDelay();
        if (!this.quickFG.valid) {
            this.formGroupInvalid = true;
            // if (this._commonServ.getSetttingValue("WorkFlowSettings", "ALLOW_QUICK_VERIFICATION") === "0" || (!this.isRxRequired)) {
            //     if (this.isOrginalRx && this.isRxRequiredForOrginal && (this.rxInfoData["PrescReFill"]["ReFillNum"]) === 0) {
            //         this._alertSrv.error("Rx Verification Required before Transmission.");
            //     }  if (!(this.isOrginalRx) && this.isRxRequiredForOrginal) {
            //         this._alertSrv.error("Rx Verification Required before Transmission.");
            //     }
            // }   else {
            //     this._alertSrv.error("Enter required fields.");
            // }
            if (this.isOrginalRx && this.isRxRequiredForOrginal && (this.rxInfoData["PrescReFill"]["ReFillNum"]) === 0) {
                this._alertSrv.error("Rx Verification Required before Transmission.");
            }  else if (!(this.isOrginalRx) && this.isRxRequiredForOrginal) {
                this._alertSrv.error("Rx Verification Required before Transmission.");
            } else {
                this._alertSrv.error("Enter required fields.");
            }

            return;
        }

        if (this.drugUserIsVerified && this.rphUserIsVerified) {
            if (this.QuickMod) {
            this.QuickMod.close();
            }
            this.QuickVerfRes.emit(this.quickFG.value);
            this.formGroupInvalid = false;
        }
        //  else if (!this.drugUserIsVerified && !this.rphUserIsVerified) {
        //     if (
        //         this.quickFG.value["drugBy"] &&
        //         this.quickFG.value["drugPsw"] &&
        //         this.quickFG.value["rphBy"] &&
        //         this.quickFG.value["rphPsw"]
        //     ) {
        //         this.postData[0].UserName = this.quickFG.value["drugBy"];
        //         this.postData[0].Pasword = this.quickFG.value["drugPsw"];
        //         this.postData[1].VerificationType = "drug";
        //         this.postData[1].UserName = this.quickFG.value["rphBy"];
        //         this.postData[1].Pasword = this.quickFG.value["rphPsw"];
        //         this.postData[1].VerificationType = "rph";
        //         this._rxService
        //             .checkAppUser(this.postData)
        //             .subscribe((resp) => {
        //                 if (resp["drug"] && resp["rph"]) {
        //                     this.QuickMod.close();
        //                     this.QuickVerfRes.emit(this.quickFG.value);
        //                     this.formGroupInvalid = false;
        //                 } else if (!resp["rph"] && !resp["drug"]) {
        //                     this.formGroupInvalid = true;
        //                     this.InvalidPassword3 = true;
        //                     this.InvalidPassword2 = true;
        //                     this.InvalidPassword1 = false;
        //                     this._alertSrv.error(
        //                         "Password for Drug Pick verification and RPH Verification is not correct."
        //                     );
        //                 } else if (!resp["drug"]) {
        //                     this.formGroupInvalid = true;
        //                     this.InvalidPassword3 = false;
        //                     this.InvalidPassword2 = true;
        //                     this.InvalidPassword1 = false;
        //                     this._alertSrv.error(
        //                         "Password for Drug Pick verification is not correct."
        //                     );
        //                 } else if (!resp["rph"]) {
        //                     this.formGroupInvalid = true;
        //                     this.InvalidPassword3 = true;
        //                     this.InvalidPassword2 = false;
        //                     this.InvalidPassword1 = false;
        //                     this._alertSrv.error(
        //                         "Password for RPH Verification is not correct."
        //                     );
        //                 }
        //             });
        //     } else {
        //         if (!(this.isDpvRequired) || !(this.isRPhRequired) || !(this.isRxRequired)) {
        //             this.QuickMod.close();
        //             this.QuickVerfRes.emit(this.quickFG.value);
        //             this.formGroupInvalid = false;
        //         } else {
        //             this.formGroupInvalid = true;
        //             this._alertSrv.error("Enter required fields");
        //         }
        //     }
        // } else if (!this.drugUserIsVerified && this.rphUserIsVerified) {
        //     if (this.quickFG.value["drugBy"] && this.quickFG.value["drugPsw"]) {
        //         this.postData[0].UserName = this.quickFG.value["drugBy"];
        //         this.postData[0].Pasword = this.quickFG.value["drugPsw"];
        //         this.postData[0].VerificationType = "drug";
        //         this._rxService
        //             .checkAppUser(this.postData)
        //             .subscribe((resp) => {
        //                 if (resp["drug"]) {
        //                     this.QuickMod.close();
        //                     this.QuickVerfRes.emit(this.quickFG.value);
        //                     this.formGroupInvalid = false;
        //                 } else if (!resp["drug"]) {
        //                     this.formGroupInvalid = true;
        //                     this.InvalidPassword3 = false;
        //                     this.InvalidPassword2 = true;
        //                     this.InvalidPassword1 = false;
        //                     this._alertSrv.error(
        //                         "Password for Drug Pick verification is not correct."
        //                     );
        //                 }
        //             });
        //     } else {
        //         if (!(this.isDpvRequired) || !(this.isRPhRequired) || !(this.isRxRequired)) {
        //             this.QuickMod.close();
        //             this.QuickVerfRes.emit(this.quickFG.value);
        //             this.formGroupInvalid = false;
        //         } else {
        //             this.formGroupInvalid = true;
        //             this._alertSrv.error("Enter required fields");
        //         }
        //     }
        // } else if (this.drugUserIsVerified && !this.rphUserIsVerified) {
        //     if (this.quickFG.value["rphBy"] && this.quickFG.value["rphPsw"]) {
        //         this.postData[0].UserName = this.quickFG.value["rphBy"];
        //         this.postData[0].Pasword = this.quickFG.value["rphPsw"];
        //         this.postData[0].VerificationType = "rph";
        //         this._rxService
        //             .checkAppUser(this.postData)
        //             .subscribe((resp) => {
        //                 if (resp["rph"]) {
        //                     this.QuickMod.close();
        //                     this.QuickVerfRes.emit(this.quickFG.value);
        //                     this.formGroupInvalid = false;
        //                 } else if (!resp["rph"]) {
        //                     this.formGroupInvalid = true;
        //                     this.InvalidPassword3 = true;
        //                     this.InvalidPassword2 = false;
        //                     this.InvalidPassword1 = false;
        //                     this._alertSrv.error(
        //                         "Password for RPH Verification is not correct."
        //                     );
        //                 }
        //             });
        //     } else {
        //         if (!(this.isDpvRequired) || !(this.isRPhRequired) || !(this.isRxRequired)) {
        //             this.QuickMod.close();
        //             this.QuickVerfRes.emit(this.quickFG.value);
        //             this.formGroupInvalid = false;
        //         } else {
        //             this.formGroupInvalid = true;
        //             this._alertSrv.error("Enter required fields");
        //         };
        //     }
        // }
    }


    getDrugInteractionInfo() {
        const dob = moment(this.rxInfoData?.Patient?.dob ? this.rxInfoData.Patient.dob : null);
        const days = moment().diff(dob, "days");
        const drugIntData: DrugInteractionData = {
            drugId: this.rxInfoData.Drug.id,
            patientId: this.rxInfoData.Patient.patientid,
            gender: this.rxInfoData.Patient.gender,
            islong: true,
            languageCulture: "es-US",
            calledFrom: "RxEntry",
            patLanguage: this.rxInfoData.Patient.langname,
            isFromEditRx: this.rxInfoData.rxType == "er" ? true : false,
            isForCount: true,
            ageInDays: days,
            therapeuticCode: this.rxInfoData.Drug.therapeuticcode,
            drugClass: this.rxInfoData.Drug.drugclass,
            CHECK_DRU_AND_DELIVERY: this._nrxUtls.isSafeForLabrDelivrySet(),
            CHECK_DRU_BEARING_AGE: this._nrxUtls.isChildBearingAgeSet(),
            USE_ACB_SCORING: this._nrxUtls.getSetngKeyVal("PatientSettings", "USE_ACB_SCORING"),
            drugNdc: this.rxInfoData.Drug.drugcode,
            diagnosisList: this.rxInfoData.Patient.Diagnosis,
            ddiScanDays: this._nrxUtls.getSettingValue("PatientSettings", "DDI_SCAN_DAYS"),
            INCIDENCE: this._nrxUtls.getSettingValue("PatientSettings", "INCIDENCE"),
            ON_SET: this._nrxUtls.getSettingValue("PatientSettings", "ON_SET"),
            SEVERITY_LEVEL: this._nrxUtls.getSettingValue("PatientSettings", "SEVERITY_LEVEL"),
            USE_ADV_DRUG_REACTION: this._nrxUtls.getSettingValue("PatientSettings", "USE_ADV_DRUG_REACTION"),
            DRUGS_WIT_WARN_ONLY: this._nrxUtls.getSettingValue("PatientSettings", "DRUGS_WIT_WARN_ONLY")           
        }
        this._rphService
            .getDrugInteractions(
                drugIntData
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp) => {
                if (resp) {
                    this.drugInteractions = resp;
                    this.patchDrugInteractions();
                }
            });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    patchDrugInteractions() {
        if (
            this.drugInteractions.DiseaseContradication &&
            this.drugInteractions.DiseaseContradicationCount
        ) {
            this.drugDeseContrd = this.drugInteractions.DiseaseContradication;
        }
        if (
            this.drugInteractions.DrugToDrugInteraction &&
            this.drugInteractions.DrugToDrugInteraction.length > 0
        ) {
            this.drugToDrugInteractions1 = this.drugInteractions.DrugToDrugInteraction[0];
        }
        if (
            this.drugInteractions.PregnancyLactationInfo &&
            this.rxInfoData.Patient.Gender === "Female"
        ) {
            this.drugPregInfo = this.drugInteractions.PregnancyLactationInfo;
        }
        if (this.drugInteractions &&
            this.drugInteractions.Allergies &&
            this.drugInteractions.AllergiesCount) {
            const res = this.drugInteractions.Allergies.AllergyInfo.replace(/.<br\s*[\/]?>/gi, "");
            this.drugAllergyData = res.replace(/Patient is allergic to drug/gi, "<br/>Patient is allergic to drug");
            // this.drugAllergyData = this.drugAllergyData.substring(6, this.drugAllergyData.length);
        }
    }

    getDrugAllergiesStatus() {
        if (
            this.drugInteractions.Allergies != null &&
            this.drugInteractions.AllergiesCount
        ) {
            this.modelRef = this._modalService.open(this.DRUGALLERGIES, { windowClass: "large--content"});
            this.actvModal2 = "drug allergies";
        }
    }

    getDrugDeceaseContradictionInfo() {
        if (
            this.drugInteractions.DiseaseContradication &&
            this.drugInteractions.DiseaseContradicationCount
        ) {
            this.drugDeseContrd = this.drugInteractions.DiseaseContradication;
            this.modelRef = this._modalService.open(this.DRUGDISEASECONTRIND, {
                size: "lg",
            });
            this.actvModal2 = "DRUGDISEASECONTRIND";
        }
    }

    checkAllDDIPopClosed() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.currentDrugPopIndex += 1;
        if (
            this.drugInteractions.DrugToDrugInteraction.length ===
            this.currentDrugPopIndex
        ) {
            this.currentDrugPopIndex = 0;
            // this.getDuplicateDrugTherapyInfo();
        } else {
            this.getDrugToDrugInterationInfo();
        }
    }

    getDrugToDrugInterationInfo() {
        if (
            this.drugInteractions.DrugToDrugInteraction &&
            this.drugInteractions.DrugToDrugInteraction.length > 0
        ) {
            this.drugToDrugInteractions = this.drugInteractions.DrugToDrugInteraction[
                this.currentDrugPopIndex
            ];
            this.modelRef = this._modalService.open(
                this.DRUGTODRUGINTERACTION,
                {
                    size: "lg",
                    windowClass: "modal-center-to-page"
                }
            );
            this.actvModal2 = "DRUGTODRUGINTERACTION";
        }
    }

    getDuplicateDrugTherapyInfo() {
        if (this.drugInteractions.DuplicateDrugs.length > 0) {
            this.modelRef = this._modalService.open(this.DUPLICATEDRUG, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content medium-sm-modal duplicate-drug-modal" });
            this.actvModal2 = "DUPLICATEDRUG";
        }
    }

    getDrugPregnancyInfo() {
        if (
            this.drugInteractions.PregnancyLactationInfo &&
            this.rxInfoData.Refdetail.Refilldetails[0].Patient.Gender === "Female"
        ) {
            this.drugPregInfo = this.drugInteractions.PregnancyLactationInfo;
            this.modelRef = this._modalService.open(this.DRUGPREGNENCY, {
                size: "lg",
            });
            this.actvModal2 = "DRUGPREGNENCY";
        }
    }

    getAdverseReaction() {
        if (this.drugInteractions &&
            this.drugInteractions.AdverseReaction &&
            this.drugInteractions.AdverseReaction.length > 0) {
            this.adverseDataMonograph = this.drugInteractions.AdverseReaction[0]["ADVERSEREACTIONS"];
            this.modelRef = this._modalService.open(this.ADVERSEDRUG, {
                size: "lg", keyboard: false, backdrop: false,
                windowClass: "modal-center-to-page"
            });
            this.actvModal2 = "ADVERSEDRUG";
        }
    }

    checkLifeStyleComplete() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.currentLifeStyleIndex += 1;
        if (this.drugInteractions.LifeStyle.length !== this.currentLifeStyleIndex) {
            this.getLifeStyle();
        } else {
            this.currentLifeStyleIndex = 0;
        }
    }

    getLifeStyle() {
        if (this.drugInteractions &&
            this.drugInteractions.LifeStyle &&
            this.drugInteractions.LifeStyle.length > 0) {
            this.drugLifeStyle = this.drugInteractions.LifeStyle[this.currentLifeStyleIndex];
            // Sentry.captureException([
            //     {name: this.drugLifeStyle},
            //     {name: this.drugInteractions.LifeStyle.length}
            // ]);
            this.modelRef = this._modalService.open(this.LIFESTYLE, {
                size: "lg", keyboard: false, backdrop: false, centered : true
            });
            this.actvModal2 = "LIFESTYLE";
        }
    }

    get recvBy(): FormControl {
        return this.quickFG.get("recvBy") as FormControl;
    }
    get recvTm(): FormControl {
        return this.quickFG.get("recvTm") as FormControl;
    }
    get recvDt(): FormControl {
        return this.quickFG.get("recvDt") as FormControl;
    }
    get recvPsw(): FormControl {
        return this.quickFG.get("recvPsw") as FormControl;
    }
    get drugBy(): FormControl {
        return this.quickFG.get("drugBy") as FormControl;
    }
    get drugTm(): FormControl {
        return this.quickFG.get("drugTm") as FormControl;
    }
    get drugDt(): FormControl {
        return this.quickFG.get("drugDt") as FormControl;
    }
    get drugPsw(): FormControl {
        return this.quickFG.get("drugPsw") as FormControl;
    }
    get rphBy(): FormControl {
        return this.quickFG.get("rphBy") as FormControl;
    }
    get rphTm(): FormControl {
        return this.quickFG.get("rphTm") as FormControl;
    }
    get rphDt(): FormControl {
        return this.quickFG.get("rphDt") as FormControl;
    }
    get rphPsw(): FormControl {
        return this.quickFG.get("rphPsw") as FormControl;
    }

    focustonext(event, type: any) {
        if (document.getElementById(type)) {
            document.getElementById(type).focus();
            event.preventDefault();
        }
    }
}
