import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import {
    PatientInfo,
    SystemData,
    EligibilityTransmit,
    PatientInsurances,
    Insurances,
    InsuCards,
    InsuGroups,
    InsuCarrier,
    SearchInsurance,
    eligibilityEnum
} from "../../../models";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import * as _ from "lodash";
import { PatientService, AlertService, PatPreDrugModalService, CommonService } from "src/app/services";
import { RegEx } from "src/app/app.regex";
import { CollectionView } from "@grapecity/wijmo";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientInfoComponent } from "../../edit-patient/patient-info";
import { PatientStore } from "src/app/store";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { CommonWarnorconfirmPopupComponent } from "../common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { MsgConfig } from "src/app/app.messages";
@Component({
    selector: "app-eligibility",
    templateUrl: "./eligibility.component.html"
})
export class EligibilityComponent implements OnInit {
    patientFilteredInsus: PatientInsurances[];
    eligibilityInurances: PatientInsurances[];
    insurancesToProcess: any;
    confirmModal: any;
    insuToProcess: any;
    InsuPopUpdata: any;
    unsubscribe$: Subject<void> = new Subject();
    insuList: any[]=[];
    MsgConfig: any
    @Input()
    set PatientInfoFG(fg: any) {
        this.patientInfoFG = fg;
    }

    @Input()
    set SystemData(sd: any) {
        this.systemData = sd;
    }

    @Input()
    set PatientInfo(pi: PatientInfo) {
        this.patientInfo = pi;
        if (this.patientInfo && this.patientInfo["PatientInsurances"] && this.patientInfo["PatientInsurances"].length && this.patientInfo["Patient"]) {
            this.patientId = this.patientInfo["Patient"].Id;
            this.patientFilteredInsus = this.patientInfo["PatientInsurances"].filter(elm => !elm["IsCash"]);
        }
    }
    @Input() Insurances: any;

    @Output()
    GetPatientInfo = new EventEmitter<{}>();

    @ViewChild("insuranceNotExists", { static: true })
    insuranceNotExists: any;

    @ViewChild("insuAddedToPatient", { static: true })
    insuAddedToPatient: any;

    insuTransmitFG: FormGroup;
    systemData: SystemData;
    patientInfoFG: FormGroup;
    patientInfo: PatientInfo;
    patientId: number;
    showResults = false;
    regex: any;
    gender: string;
    insuranceSelected = null;
    insurerId: number;
    transmitResult: any;
    acceptResult: any;
    status: string = null;
    hasMessage = false;
    msgF4Info: string;
    resultDate: string;
    enableUpdate = false;
    hasErrorMessage: boolean;
    hasResponse: boolean;
    resultAcceptWJ: CollectionView;
    hasData: boolean;
    modalRef: any;
    primaryPolicyNum: string;
    troopStatus: any;
    CommercialElgStatus: any;
    cashInsurance: boolean = false;

    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27) { // yes
            this.closePopUp();
            event.preventDefault();
        }
    }

    constructor(
        private modalService: NgbModal,
        private _ppdiModalSer: PatPreDrugModalService,
        private _fb: FormBuilder,
        private _patUtils: PatientInfoUtil,
        private _editPatService: PatientService,
        private _alertService: AlertService,
        public activeModal: NgbActiveModal,
        private _commonServ: CommonService,
        private _formUtils:ValidateFormFieldsUtils
    ) {
        this.regex = RegEx;
        this.insuTransmitFG = this._fb.group(new EligibilityTransmit());
    }

    ngOnInit() {
        this._editPatService.getEligibilityDefaultInus()
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.length > 0) {
                    this.eligibilityInurances = resp;
                    this.automateEligibilityCheckProcess();
                } else {
                    this.eligibilityInurances = [];
                }
            });
        this.checkisAllInputsArrived();
        this._commonServ.insuranceData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.length) {
                this.insuList = resp;
            }
        });
        this.MsgConfig = MsgConfig;
    }

    checkisAllInputsArrived() {
        if (this.patientInfoFG && this.patientInfo && this.systemData) {
            this.getGender();
            this.checkPrimaryInsuranceExist();
            this.insuTransmitFG.controls["dateOfService"].setValue(moment().format("MM/DD/YYYY"));
            this.populateInsuranceName();
            this.status = null;
            this.hasErrorMessage = null;
            this.hasMessage = null;
        }
    }

    getGender() {
        const id = this.patientInfoFG.value.Person.GenderId;
        switch (id) {
            case 1:
                this.gender = "MALE";
                break;
            case 2:
                this.gender = "FEMALE";
                break;
            case 3:
                this.gender = "UNKNOWN";
                break;
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    checkPrimaryInsuranceExist() {
        this.insurerId = this.patientInfoFG.value.Insurances.InsurerId;
        if (this.insurerId && this.patientInfo && this.patientInfo["PatientInsurances"] && this.patientInfo["PatientInsurances"].length) {
            const policyNumber =   this.patientInfo.PatientInsurances.filter(value=>{
                return value["Id"] === this.insurerId
            })
            this.cashInsurance = null;
            if (policyNumber && policyNumber.length) {
                if (this.insuTransmitFG && this.insuTransmitFG.controls["policyNum"]) {
                    this.cashInsurance  = policyNumber[0]["IsCash"];
                    this.insuTransmitFG.controls["policyNum"].setValue(policyNumber[0]["PolicyNum"]);
                }
            }
           
          
        }
    }

    populateInsuranceName() {
        if (this.insurerId && this.patientInfo && this.patientInfo["PatientInsurances"] && this.patientInfo["PatientInsurances"].length) {
            const primaryInsu = this.patientInfo.PatientInsurances.filter(val => val["Id"] === this.insurerId)[0];
            this.insuranceSelected = new PatientInsurances();
            if(primaryInsu){
                this.insuranceSelected.Id = primaryInsu["InsuranceId"];
                this.insuranceSelected.insurancename = primaryInsu["insurancename"];
            }
            // this.insuTransmitFG.controls["insuranceId"].setValue(
            //     primaryInsu["insurerid"]
            // );
        }
    }

    updateTransDetails(type?, isCheck: boolean = false) {
        if (this.insuTransmitFG.valid) {
            this.insuTransmitFG.controls.patientId.setValue(this.patientId);
            this.insuTransmitFG.controls.IsFrmManualCheck.setValue(isCheck);
            this._editPatService
                .getEligibilityTransmissionResults(this.insuTransmitFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === null) {
                        this.hasData = false;
                        this.hasErrorMessage = null;
                        this.hasResponse = null;
                        this.hasMessage = null;
                        this._alertService.error("Transmission results fetch unsuccessful.");
                    } else {
                        this.hasData = true;
                        this.transmitResult = resp;
                        this.setResults();
                        if(this.insuTransmitFG.value['IsTroop']){
                            this.troopStatus = resp;
                        }else{
                            this.CommercialElgStatus = resp;
                        }
                        if(resp.Result == "R" && !type && !isCheck){
                            this.automateEligibilityCheckProcess('transRejected')
                        }else if(resp.Result == "A" && !type && !isCheck){
                            this._alertService.success(
                                "Insurance Information is updated."
                            );
                        }
                    }
                });
        } else {
            this._formUtils.validateAllFormFields(this.insuTransmitFG);
        }
    }

    setResults() {
        this.resultDate = moment(Date.now()).format("MM/DD/YYYY");
        if (this.transmitResult.ErrorMessage !== null) {
            this._alertService.error(this.transmitResult.ErrorMessage);
            this.hasData = false;
        } else if (this.transmitResult.ErrorMessage === null) {
            this.hasErrorMessage = false;
            if (this.transmitResult.Result === "A") {
                this.status = "ACCEPTED";
                this.hasResponse = true;
                this.enableUpdate = true;
                this.generateSuccessWJ(this.transmitResult);
            } else if (this.transmitResult.Result === "R") {
                this.status = "REJECTED";
                this.hasResponse = true;
                this.enableUpdate = false;
            }
        }
        if (this.transmitResult.MessageF4 !== null) {
            this.hasMessage = true;
            // this.enableUpdate = true;
            this.msgF4Info = this.transmitResult.MessageF4;
        }
    }

    generateSuccessWJ(successResult) {
        this.resultAcceptWJ = new CollectionView(
            successResult.lstInsuOtherInfo.map((result, i) => {
                let k = {};
                k["SRNO"] = i+1;
                k["BN"] = result.BN;
                k["EFFECTIVEDATE"] = result.EFECTIVEDATE;
                k["GP"] = result.GP;
                k["ID"] = result.ID;
                k["INSORD"] = result.INSORD;
                k["PAYIDQ"] = result.PAYIDQ;
                k["PC"] = result.PC;
                k["PH"] = result.PH;
                k["BN"] = result.BN;
                k["PN"] = result.PN;
                k["RELATIONCD"] = result.RELATIONCD;
                let filteredInsuList=this.insuList.filter(elm => {return  (elm.BinNum == result.BN && elm.ProcessorNum == result.PN) });
                let insu = filteredInsuList.sort((el1, el2) => {
                    if (el1.InsurerCode < el2.InsurerCode) { return -1 }
                    else if (el1.InsurerCode > el2.InsurerCode) { return 1; }
                    else { return 0; }
                });
                k["INSUCODE"] = (insu && insu[0]) ? insu[0].InsurerCode : "";
                return k;
            })
        );
    }

    selectedInsType(val) {
        if(val && val.Id) {
            this.insurerId = val.Id;
        }
        // this.insuTransmitFG.controls.policyNum.patchValue(val.PolicyNum);
    }

    changeInsurance(pat) {
        this.insuTransmitFG.controls["policyNum"].setValue(
            pat.PolicyNum ? pat.PolicyNum : null
        );
    } 

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(
                term =>
                    term.length < 1
                        ? []
                        : this.patientInfo.PatientInsurances.filter(v => {
                            var valExists = false;
                            Object.keys(v).forEach(function (key) {
                                const val = v[key] ? v[key] : "";
                                valExists = valExists || val.toString().toLowerCase().startsWith(term.toLocaleLowerCase());
                            });
                            return valExists;
                        }).splice(0, 20)
            )
        );

    formatter = (x: any) => {
        return x.Name;
    };

    async updatePatInfo() {
        if (this.insuTransmitFG && this.insuTransmitFG.valid) {
            const resp = await this._editPatService.transmitPatientInfo(
                this.transmitResult, this.insurerId, this.insuTransmitFG.value.policyNum, this.patientId
            ).toPromise();
            if ( resp && resp["length"] === 0) {
                this.cancelAddInsu();
            } else if (resp && resp["length"] > 0) {
                this.insurancesToProcess = resp;
                this.processInsurances();
            }
        } else {
            this._formUtils.validateAllFormFields(this.insuTransmitFG);
        }
    }

    closePopUp() {
        if (this.activeModal) {
            this.activeModal.close();
        }
        const search = new SearchInsurance();
        this._commonServ.getInsuranceData(search);
    }

    processInsurances() {
        this.InsuPopUpdata = this.insurancesToProcess[0].split("*");
        this.confirmModal = this.modalService.open(this.insuranceNotExists, {centered: true, keyboard: false, backdrop: false, windowClass:"large--content"});
    }

    processNextInsurance() {
        if (this.confirmModal) {
            this.confirmModal.close();
        }
        if (this.insurancesToProcess.length > 0) {
            this.insuToProcess = this.insurancesToProcess.shift().split("*");
            this._ppdiModalSer._patPreDrugModal$.next("insurance");
            const modalRef = this._ppdiModalSer.modalInstanceRef;
            modalRef.componentInstance.insuId = 0;
            modalRef.componentInstance.insuDataFrmEligibilty = this.insuToProcess;
            modalRef.componentInstance.CloseInsuModal
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                modalRef.close();
                if (resp && resp["insuId"]) {
                    this.addInsuranceToPatient(resp["insuId"]);
                } else {
                    this.cancelAddInsu();
                }
                this._ppdiModalSer._patPreDrugModal$.next(null);
            });
        } else {
            this.cancelAddInsu();
        }
    }

    async cancelAddInsu() {
        const patInfo = await this._editPatService.getEditPatientInformation(this.patientId).toPromise();
        this.patientInfoFG.patchValue({
            Insurances: patInfo.Insurances ? patInfo.Insurances : this.patientInfoFG.value["Insurances"],
            InsuCarrier: patInfo.InsuCarrier ? patInfo.InsuCarrier : this.patientInfoFG.value["InsuCarrier"],
            InsuCards: patInfo.InsuCards ? patInfo.InsuCards : this.patientInfoFG.value["InsuCards"],
            InsuGroups: patInfo.InsuGroups ? patInfo.InsuGroups : this.patientInfoFG.value["InsuGroups"]
        });
        await this._patUtils.getPatientInsurances(this.patientId, this.patientInfo, patInfo);
        this.closePopUp();
        this.GetPatientInfo.emit(true);
    }

    async addInsuranceToPatient(insuranceId) {
        const insuranceData = {
            patientid: this.patientId,
            Insurance: new Insurances(),
            InsuCard: new InsuCards(),
            Insgroup: new InsuGroups(),
            InsuCarrier: new InsuCarrier()
        };
        insuranceData.Insurance.InsuPriId = +this.insuToProcess[5];
        insuranceData.Insurance.PerNum = this.insuToProcess[4];
        insuranceData.Insurance.RelCatId = this.insuToProcess.length > 6 ? +this.insuToProcess[6] : null;
        insuranceData.InsuCard.PolicyNum = this.insuToProcess[1];
        insuranceData.InsuCard.ExpiryDt = "12/31/2099";
        insuranceData.Insgroup.Name = this.insuToProcess[3];
        await this._editPatService.addInsuranceToPatient(insuranceData, insuranceId).toPromise();
        if (this.insurancesToProcess.length > 0) {
            this.InsuPopUpdata = this.insurancesToProcess[0].split("*");
        }
        this.confirmModal = this.modalService.open(this.insuAddedToPatient, {centered: true, keyboard: false, backdrop: false, windowClass:"large--content"});
    }

    automateEligibilityCheckProcess(value?){
            if(this.eligibilityInurances && this.eligibilityInurances.length && this.insuTransmitFG && this.insuTransmitFG.controls){
                if(this.insuTransmitFG.controls['policyNum'] && this.insuTransmitFG.controls['policyNum'].value && !value && this.eligibilityInurances[0].IsTroop){
                    this.insuTransmitFG.controls['insuranceId'].patchValue(this.eligibilityInurances[0].Id);
                    this.insuTransmitFG.controls['IsTroop'].patchValue(true);
                    this.updateTransDetails(); 
                }else{
                    this.insuTransmitFG.controls['insuranceId'].patchValue(this.eligibilityInurances.length == 2 ? this.eligibilityInurances[1].Id : this.eligibilityInurances[0].Id);
                    this.insuTransmitFG.controls['IsTroop'].patchValue(false);
                    if(!this.insuTransmitFG.value['policyNum'])
                        this.insuTransmitFG.controls['policyNum'].patchValue(eligibilityEnum.CommercialPolicyNum);
                    this.updateTransDetails('Commercial eligibility'); 
                }
            }
    }
}

