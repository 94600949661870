<ng-template #ErrorTemp let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header>Warning!! </h4>
        <span body>
            <div class="row">
                <div class="col-12">
                    Rx#: <label>{{currentRx.Prescription.PrescNum}}</label>, Refill#: <label>{{currentRx.PrescReFill.ReFillNum}}</label>
                    <span *ngIf="(_commonSrv.checkIsPartialFill(currentRx.PrescReFill, currentRx.Prescription))">, Fill#:
                    <label>{{currentRx.PrescReFill.PartialFillNo}}</label></span>
                </div>
                <div class="col-12">
                    {{erroMsg}}
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="c('Close click');processRecord()" appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>

<ng-template #AlreadyVerif let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Confirmation </h4>
        <span body>
            Rx#: {{currentRx.Prescription.PrescNum}} Refill#: {{currentRx.PrescReFill.ReFillNum}}
            <span *ngIf="(_commonSrv.checkIsPartialFill(currentRx.PrescReFills, currentRx.Prescription))"> Fill#:
                {{currentRx.PrescReFill.PartialFillNo}}</span>  Already verified on
            {{verifiedAlready.VerifDtTm ? (verifiedAlready.VerifDtTm | localTime | date:'MM/dd/yyyy'):'--'}} by
            {{verifiedAlready?.UserName ?
            (verifiedAlready?.UserName | uppercase) : '--' }}. Do you want to verify again?
        </span>
        <button ngbAutofocus footer class="hotkey_primary" (click)="c('Close click')" (click)="verifyRph()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="c('Close click');processRecord()" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #VerifSuccess let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Success </h4>
        <span body>
            <div class="row">
                <div class="col-12">
                    Rx#: <label>{{currentRx.Prescription.PrescNum}}</label>, Refill#: <label>{{currentRx.PrescReFill.ReFillNum}}</label>
                    <span *ngIf="(_commonSrv.checkIsPartialFill(currentRx.PrescReFill, currentRx.Prescription))"> Fill#:
                        <label>{{currentRx.PrescReFill.PartialFillNo}}</label></span>
                </div>
                <div class="col-12">
                    Rph verification completed successfully.
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="c('Close click');processRecord()" appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>

