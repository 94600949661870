import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AlertService, CommonService, ErxService } from 'src/app/services';
import { CommonUtil, WijimoUtil } from 'src/app/utils';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ErxStatusTitles } from '../erx.constan';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
  selector: 'app-benfit-check',
  templateUrl: './benfit-check.component.html'
})
export class BenfitCheckComponent implements OnInit {
  @Input() BenefitCheckDetails: any;
  @Input() RxDetails: any;
  @Input() PrescReFill: any;
  @Input() IsBenfitCheckfromRxHistory: any;
  @Input() RxDays: any;
  @Input() IsBenefitCheckProcess: boolean;
  @Input() SelectedRxDetails: boolean;
  @Input() FromIntakeQueue: boolean;
  
  
  @ViewChild("benfitCheckSupllyDays", { static: true })
  benfitCheckSupllyDays: any;
  @ViewChild("benfitCheckModal", { static: true })
  benfitCheckModal: any;

  @Output()
  CloseBenfitCheckModal = new EventEmitter<any>();

  benfitCheckModel: any;
  erxStatus = ErxStatusTitles;
  gridViewObj: CollectionView;
  benfitCheckFlex: any;
  befitCheckData: any;
  supplyDaysList: any;
  supplyDaysFG: FormGroup;
  today: any;
  defaultHeaders: any = ["Action"];
  wjHeaders: any =  [
    "Drug Type",
    "Drug Name"
  ];
  warningModelRef: any;
  befitDetails: any;
  eRxVersion: any;
  closeSupplyDaysPop: any;
  insuranceDetails: any;
  age: any;
  isFormCoverageRefresh: boolean = false;
  isSendChangeRequest: boolean ;
  isPaRequest: boolean ;
  coverageInfoLength: number;
  isSelectedType: string;
  systemData:any;
  dawValue: any;
  unsubscribe$: Subject<void> = new Subject();
  constructor(private _fb: FormBuilder,
    private _modalService: NgbModal, private _commonSvc: CommonService, private _wijimoUtils: WijimoUtil,
    private _erxService: ErxService, private commonUtil: CommonUtil, private _alertSer: AlertService, private _cdr: ChangeDetectorRef,
    private _formatsUtil:FormatsUtil) {
    this.createFG();
  }

  ngOnInit() {
    this._commonSvc.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
      this.systemData = resp;
  });
    this.eRxVersion = this.commonUtil.getErxVersion();
    this.today = moment(new Date()).format("MM/DD/YYYY hh:mm:ss");
    this.checkBenefitCheckDetails();
  }
  checkBenefitCheckDetails(fromCoverageRefresh?) {
    let erxObject;
    if (this.IsBenfitCheckfromRxHistory) {
       erxObject = { eRxDetailsViewModel: null, SupplyDays: null, prescRefillId: this.PrescReFill };
    } else {
      erxObject =  { eRxDetailsViewModel: this.BenefitCheckDetails , SupplyDays: this.RxDays };
    }
    this.getReadyBenfitCheck(erxObject, this.eRxVersion, fromCoverageRefresh);
  }
  refreshCoverageInfo() {
    this.supplyDaysList = [];
    this.wjHeaders =[];
    this.wjHeaders =  [
      "Drug Type",
      "Drug Name"
    ];
    this.today = moment(new Date()).format("MM/DD/YYYY hh:mm:ss");
    this.checkBenefitCheckDetails(true);
  }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("BenefitCheckCoverageInfoQueueWj");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("BenefitCheckCoverageInfoQueueWj", storedWJ);
  }
  createFG() {
    this.supplyDaysFG = this._fb.group({
      supplyDays: null,
      erxSig: null
    });
  }
  openRxDaysPOp() {
    this.closeSupplyDaysPop = this._modalService.open(
      this.benfitCheckSupllyDays,
      {
        centered: true,
        keyboard: false,
        size: "sm",
        windowClass: "medium-sm-modal-30",
      }
    );
  }
  closeModalForOpenPops() {
    if (this.closeSupplyDaysPop) {
      this.closeSupplyDaysPop.close();
      this.closeSupplyDaysPop = null;
    }
    if (this.warningModelRef) {
      this.warningModelRef.close();
      this.warningModelRef = null;
    }
  }
  openBenfitModal(resp, rxDays) {
    if (!rxDays) {
      rxDays = parseInt(this.supplyDaysFG.value["supplyDays"], 0);
    }
    this.getReadyBenfitCheck({ eRxDetailsViewModel: resp, SupplyDays: rxDays }, this.eRxVersion);
    this.warningModelRef = this._modalService.open(
      this.benfitCheckModal,
      {
        keyboard: false,
        size: "lg",
        backdrop: false,
        windowClass: "medium-sm-modal-73",
        centered: true
      }
    );
  }
  init(flex: wjcGrid.FlexGrid) {
    flex.focus();
  }
  openModal() {
    this.warningModelRef = this._modalService.open(
      this.benfitCheckModal,
      {
        keyboard: false,
        size: "lg",
        backdrop: false,
        windowClass: "medium-sm-modal-73",
        centered: true
      });
  }
  goToProcessErx(isSelectedType) {
    if (this.closeSupplyDaysPop) {
      this.closeSupplyDaysPop.close();
      this.closeSupplyDaysPop = null;
    }
    if (this.warningModelRef) {
      this.warningModelRef.close();
      this.warningModelRef = null;
    }
    this.isSelectedType = !this.FromIntakeQueue ? isSelectedType : "";
    this.CloseBenfitCheckModal.emit(isSelectedType);

  }
  getReadyBenfitCheck(obj, version, fromCoverageRefresh?) {
    this._erxService.getReadyBenfitCheck(obj, version) .toPromise()
    .then(resp1 => {
      if (resp1.ErrorsList) {
        this.closeModal("cancel");
        this._alertSer.error(resp1.ErrorsList, false, "long");
      }  else if (resp1) {
        this.befitCheckData = resp1;
        if (resp1 && resp1.eRxDetailsViewModel) {
          this.benfitCheckModel = resp1.eRxDetailsViewModel;
          this.dawValue =  (!obj.eRxDetailsViewModel) ?  (this.systemData.DAW.filter(item=>{
            return item.Id === (+this.benfitCheckModel.RxDaw)})) : this.benfitCheckModel.RxDaw;
            this.dawValue = this.dawValue[0].Code ? this.dawValue[0].Code : this.dawValue;
          if(resp1.SupplyDaysList) {
          this.supplyDaysList =  resp1.SupplyDaysList.concat(["30","90"]);
         this.supplyDaysList.sort();
         this.supplyDaysList.map((day: any) => {
              this.wjHeaders.push(day + " Days");
            });
          }
          this.wjHeaders.push("PA Req?");
          this.insuranceDetails = (this.benfitCheckModel.insuranceDetails && this.benfitCheckModel.insuranceDetails.length) ?
           this.benfitCheckModel.insuranceDetails[0] : null;
          if (this.benfitCheckModel && this.benfitCheckModel.DrugNdc) {
            this.benfitCheckModel.DrugNdc = this._formatsUtil.getNDCFormat(this.benfitCheckModel.DrugNdc);
          } if (this.benfitCheckModel && this.benfitCheckModel.PatBirthDt) {
            this.age = this.commonUtil.getPatientAge(this.benfitCheckModel.PatBirthDt);
          } if (resp1 && resp1.MedicationRequestedInfo) {
            resp1.AlternateDrugs.unshift(resp1.MedicationRequestedInfo);
            this.isPaRequest = ( resp1.AlternateDrugs && resp1.AlternateDrugs[0] && resp1.AlternateDrugs[0]["PAreq"]) ? false :  true;
            this.isSendChangeRequest = true;
            this.generateRxWJ(resp1.AlternateDrugs);
          }
          if (!fromCoverageRefresh) {
            this.openModal();
          }

        } else {
          this.benfitCheckModel = null;
          if (this.IsBenfitCheckfromRxHistory) {
            this.CloseBenfitCheckModal.emit("");
          }
        }
      }
    })
    .catch(error => {
      if (this.warningModelRef) {
        this.warningModelRef.close();
        this.warningModelRef = null;
      }
      this.CloseBenfitCheckModal.emit("");
    });



  }
  focusOutForWijmo() {
      setTimeout(()=>{
        this.isPaRequest  = true;
        this.isSendChangeRequest  = true;
      }, 200);
  }
  onRowDblclicked(s: any, e: any) {
        if (s.selection && (s.selection.row || (s.selection.row === 0))) {
            this.isPaRequest = (s.selectedRows && s.selectedRows.length && s.selectedRows[0] && (s.selectedRows[0]["dataItem"]["PA Req?"] === "NO")) ? true :  false;
            this.isSendChangeRequest = (s.selection.row === 0 ) ? true : false;
        }
 }
  async closeModal(isClosedType?) {
    await this.closeModalForOpenPops();
    this.CloseBenfitCheckModal.emit(isClosedType);
  }
  itemFormatter = (panel, r, c, cell) => {
    const row = panel.rows[r];
    if (row.dataItem) {
      if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].header === "30 Days") {
        if (!(row.dataItem["30 Days"] && row.dataItem["30 Days"]["Copay"])) {
          cell.style.backgroundColor = "red";
        }
      } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].header === "90 Days") {
        if (!(row.dataItem["90 Days"] && row.dataItem["90 Days"]["Copay"])) {
         cell.style.backgroundColor = "red";
        }
      } else {
        cell.style.backgroundColor = "";
      }
    }
  }
  generateRxWJ(alternateDrugs) {
    this.coverageInfoLength = alternateDrugs.length;
    this.gridViewObj = new CollectionView(
      alternateDrugs.map((item, i) => {
        const k = {};
        k["RowId"] = i;
        k["Is30Selected"] = false;
        k["Is45Selected"] = false;
        k["Is90Selected"] = false;
        k["Quantity"] = item.Quantity ? item.Quantity : null;
        k["Drug Type"] = item.DrugType ? item.DrugType : null;
        const otherPricingInfo = item.otherPricingInfo ? item.otherPricingInfo : [];
            if(item.ThirtyDaysPricingInfo) {
              otherPricingInfo.push(item.ThirtyDaysPricingInfo);
            }
            if(item.FourtyFiveDaysPricingInfo) {
              otherPricingInfo.push(item.FourtyFiveDaysPricingInfo);
            }
            if(item.NinentyDaysPricingInfo) {
              otherPricingInfo.push(item.NinentyDaysPricingInfo);
            }
            k["isSelected"] = false;
        k["otherPricingInfo"] =  otherPricingInfo;
        otherPricingInfo.map((item: any) => {
          item["Days"] = (!item["Days"]) ? 30 : item["Days"];
          k[item["Days"] + " Days"] =   item;
        });
        k["NinentyDaysPricingInfoDetail"] = item.NinentyDaysPricingInfo;
        k["NinentyDaysPricingInfo"] =
          (item.NinentyDaysPricingInfo && item.NinentyDaysPricingInfo.coverageAlerts &&
            item.NinentyDaysPricingInfo.coverageAlerts.length) ? item.NinentyDaysPricingInfo.coverageAlerts : [];
        k["FourtyFiveDaysPricingInfo"] =
          (item.FourtyFiveDaysPricingInfo && item.FourtyFiveDaysPricingInfo.coverageAlerts &&
            item.FourtyFiveDaysPricingInfo.coverageAlerts.length) ? item.FourtyFiveDaysPricingInfo.coverageAlerts : [];
        k["FourtyFiveDaysPricingInfoDetail"] = item.FourtyFiveDaysPricingInfo;
        k["ThirtyDaysPricingInfo"] =
          (item.ThirtyDaysPricingInfo && item.ThirtyDaysPricingInfo.coverageAlerts &&
            item.ThirtyDaysPricingInfo.coverageAlerts.length) ? item.ThirtyDaysPricingInfo.coverageAlerts : [];
        k["ThirtyDaysPricingInfoDetail"] = item.ThirtyDaysPricingInfo;
        k["NinentyDaysAWP"] = item.NinentyDaysAWP ? item.NinentyDaysAWP : null;
        k["ThirtyDaysAWP"] = item.ThirtyDaysAWP ? item.ThirtyDaysAWP : null;
        k["NinentyDaysCost"] = item.NinentyDaysCost;
        k["ThirtyDaysCost"] = item.ThirtyDaysCost;
        k["EquivalentDrugsStockQty"] = item.EquivalentDrugsStockQty;
        k["StockQty"] = item.StockQty;
        k["Drug Name"] = item.DrugDescription ? item.DrugDescription : null;
        k["PA Req?"] = item.PAreq ? "YES" : "NO";
        return k;
      })
    );

  }
  selectedConverageInfo(isChecked , item,isType) {
     if(isType=== "30days" && isChecked) {
              item["Is90Selected"] = false;
              item["Is45Selected"] = false;
     }  else if(isType=== "90days" && isChecked ) {
            item["Is30Selected"] = false;
              item["Is45Selected"] = false;
     }  else if(isType=== "45days" && isChecked ) {
                item["Is30Selected"] = false;
              item["Is90Selected"] = false;
     }
  }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
