import { SystemSetting } from './../../../models/system-settings.model';
import { AlertService, CommonService, PreviousRouteService, ReportsService } from 'src/app/services';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnChanges,
    HostListener
} from "@angular/core";
import { DrugService } from "../../../services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { DrugInfo, SystemData } from "../../../models";
import { DomSanitizer } from '@angular/platform-browser';
import { PrintService } from 'src/app/services/print.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
const drugImage = environment.DrugImage;
@Component({
    selector: "app-drug-counselling",
    templateUrl: "./drug-counselling.component.html",
    styles: []
})
export class DrugCounsellingComponent implements OnInit, OnChanges {
    systemStng: SystemSetting;
    imgURL: any;
    @Input()
    set SystemData(sd: SystemData) {
        this.systemData = sd;
    }

    @Input() drugId: any;
    @Output()
    CloseDrugCounselPopUp = new EventEmitter<{}>();
    @Input()
    drugFG:any
    @Input() IsFromNdrug;
    @ViewChild("DRUGCONSELLING", { static: true })
    DRUGCONSELLING: any;
    @ViewChild("drugcousellingprint", { static: true })
    drugcousellingprint :any;
    drugCounsellFG:FormGroup;
    drugInfo: any;
    modelRef: any;
    drugCounselFG: FormGroup;
    drugCounsellingData: any;
    systemData: SystemData;
    editDrugInfo: any;
    isprint: boolean;
    DrugCounExpLog: any;
    showPreview = false;
    pdfContent: any;
    unsubscribe$: Subject<void> = new Subject();
    defRange :any;
    modalRef: any;
    activeModal: any;
    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            event.preventDefault();
            this.closeDrugCounselPopUp();
        }
    }

    constructor(
        private _drugService: DrugService,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _commonSrv: CommonService,
        private _prevSer: PreviousRouteService,
        private _alertSer: AlertService,
        private _sanitizer: DomSanitizer,
        private _printServ:PrintService,
        private _modalSvc: NgbModal
    ) {
    }

    ngOnInit() {
        if (this.systemData && this.drugId) {
            this.createDrugCounsellingFG();
        }
        if(this.IsFromNdrug) this.getDrugImageURL();
        else this.imgURL = this.drugFG?.value['imgURL']
    }

    ngOnChanges() {
    }

    createDrugCounsellingFG() {
        this.drugCounselFG = this._fb.group({
            format: [true],
            language: this._commonSrv.getSetttingValue(
                        "PatientSettings", "PAT_LANGUAGE")
        });
        this.getDrugCounsellingData();
        this.modelRef = this._modalService.open(this.DRUGCONSELLING, {
            size: "lg",
            backdrop: false,
            centered: true
        });
    }
    get format(): FormControl {
        return this.drugCounselFG.get("format") as FormControl;
    }

    get language(): FormControl {
        return this.drugCounselFG.get("language") as FormControl;
    }
    async previewDrugCounLog(val: boolean) {
        this.isprint = val;
            const payLoad = {
                DrugId: this.drugId,
                isShortMonogramSelected: !this.drugCounselFG.controls["format"].value,
                languageCulture :this.drugCounselFG.controls["language"].value
                }
                this._drugService.getDrugCounsellingReport(payLoad)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp && resp.size > 0 && resp.type === "application/pdf") {
                        this.DrugCounExpLog = resp;
                        if (this.isprint) {
                            this.printDrugCounExp();
                        }
                        else {
                            this.showPreview = true;
                            const urlCreator = window.URL;
                        this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                            urlCreator.createObjectURL(resp) + "#toolbar=0&zoom=115"
                        );
                        this.openModal();
                        }
                    } else {
                        this._alertSer.error("Drug Counselling report is unavailable.");
                    }
                });
    }
    routeBack() {
        this._prevSer.routeBackToPrevScreen();
        // this._router.navigate(["eprx/dashboard"]);
    }
    openModal() {
    this.modalRef = this._modalSvc.open(this.drugcousellingprint, {
            size: "lg",
            keyboard: false,
            centered : true
            }); 
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
       }
    closeModel() {
        if (this.modalRef) {
        this.modalRef.close();
        }
    }
    printDrugCounExp() {
        const newBlob = new Blob([this.DrugCounExpLog], {
            type: "application/pdf"
        });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function() {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }
    closeDrugCounselPopUp() {
        if (this.modelRef) {
            this.modelRef.close();
            }
        this.CloseDrugCounselPopUp.emit();
    }
    async getDrugCounsellingData() {
        const drugCounclngFiltrs: any = this.drugCounselFG.value;
        this.drugCounsellingData = await this._drugService.getDrugCounsellingInfo(
            this.drugId,
            drugCounclngFiltrs.format,
            drugCounclngFiltrs.language
        ).toPromise();
    }

    async getDrugImageURL() {
        if (this.drugFG && this.drugFG.ImageName) {
            const drugImageFileSplit = this.drugFG.ImageName.split(".");
            let isExist = await this._commonSrv
                .isFileExist(drugImageFileSplit[0], drugImageFileSplit[1]).toPromise();
            if (isExist) {
                this.imgURL = drugImage.replace("{imagefile}", "" +
                        drugImageFileSplit[0] + "." + drugImageFileSplit[1]
                );
            } else {
                this.imgURL = drugImage.replace("{imagefile}",
                "empty.jpg");
            }
        }
        else {
            this.imgURL = drugImage.replace("{imagefile}", "empty.jpg");
        }
    }
}



