<a (click)="selectedPatient(inputData)" *ngIf="!isAllToShow && !showPatName">
    <div class="card patient-card" *ngIf="inputData">
        <div class="drug-row-no">{{index+1}}</div>
        <div class="col-12">
            <div class="row">
                <div class="col-9">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "result-card.Patient Name" | translate }}
                        </div>
                        <div class="card__subTit--title" >
                            <span appHighlight [searchedWords]="searchTerm.split(',')[0]" [text]='inputData?.lastname | uppercase'></span>,
                            <span appHighlight [searchedWords]="searchTerm.split(',')[1] ? searchTerm.split(',')[1].trim() : searchTerm" [text]=' inputData?.firstname | uppercase'></span>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                        <div class="row">
                            <div class="card__subTit--head">
                                {{ "result-card.DOB" | translate }}
                            </div>
                            <div class="card__subTit--title">
                                <span appHighlight [searchedWords]="searchTerm" [text]='inputData?.dob | date: "MM/dd/yyyy"'></span>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="row">
                <div class="col-9">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "result-card.Address" | translate }}
                        </div>
                        <div class="card__subTit--title word_Space_Pre_Line">
                            {{
                                inputData?.address1 ? inputData?.address1 + "," : ""
                            }}
                            {{
                                inputData?.address2 ? inputData?.address2 + "," : ""
                            }}
                            {{ inputData?.city ? inputData?.city + "," : "" }}
                            {{ inputData?.state ? inputData?.state + "," : "" }}
                            {{ inputData?.zipcode ? inputData?.zipcode : "" }}
                        </div>
                    </div>
                </div>
                <div class="col-3">
                        <div class="row">
                            <div class="card__subTit--head">
                                {{ "result-card.Phone" | translate }}
                            </div>
                            <div class="card__subTit--title space_overflow">
                                <span appHighlight [fromPhone]="true" [searchedWords]="searchTerm ? (searchTerm | mask : '[(]000[)]000-000000000' ) : ''"
                                [searchWithText] = "inputData?.telephone && inputData?.telephone !== '###' ? inputData?.telephone : ''" [searchWord2]="searchTerm"
                                [text]='inputData?.telephone && inputData?.telephone !== "###" ? (inputData?.telephone | mask : "(000)000-0000" ) : " "'></span>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "result-card.Ins Id" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            <span appHighlight [searchedWords]="searchTerm" [text]='primaryInsu?.policynum ? primaryInsu?.policynum : "--"'></span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "result-card.Ins Type" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{ primaryInsu?.insuType ? primaryInsu?.insuType : "--" }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "result-card.Chart No" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            <span appHighlight [searchedWords]="searchTerm" [text]='inputData?.chartnum === "###" ? " " : inputData?.chartnum'></span>
                        </div>
                    </div>
                </div>
                <div class="col">
                        <div class="row">
                            <div class="card__subTit--head">
                                {{ "result-card.Facility" | translate }}
                            </div>
                            <div class="card__subTit--title">
                                    {{ inputData?.PatientFacilityInfo?.Organization?.Name ?
                                        (inputData?.PatientFacilityInfo?.Organization?.Name | uppercase  ): ""  }}
                            </div>
                        </div>
                    </div>
            </div>
        </div>

    </div>
</a>

<a (click)="selectedPatient(inputData)" *ngIf="showPatName">
    <div class="card patient-card" *ngIf="inputData">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="card__subTit--title">
                        {{ inputData?.firstname + " " + inputData?.lastname }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>
