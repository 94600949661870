<div >
    <div *ngFor="let msg of toasterMsg; let i = index">
        <div
            id="toast-container"
            aria-live="polite"
            role="alert"
            *ngIf="msg?.type"
        >
            <div class="toasterGrid" >
                <div class="alert-toaster--success alert" *ngIf="msg?.type === 'success'">
                    <div class="row toast-message--success">
                        <div class="col-3">
                            <i class="far fa-check-circle"></i>
                        </div>
                        <div class="col-8 toast-size">Success:
                            {{msg?.text}}
                        </div>
                        <div class="ta toast-close">
                            <i class="far fa-times" (click)="removeFromArray(i, msg?.type)"></i>
                        </div>
                    </div>
                    <div class="row float-right" *ngIf="msg?.type === 'update'">
                        <button class="alert_update" (click)="hardRefreshBrowser();"> Update</button>
                    </div>
                </div>
            </div>

            <div class="toasterGridmsg" *ngIf="msg?.type === 'warning'">
                <div class="alert-toaster alert" *ngIf="msg?.type === 'warning'">
                    <div class="toast-message--warning">
                        <div>
                            <i class="far fa-exclamation-triangle"></i>
                        </div>
                        <div class="toast-size">Warning:
                            {{msg?.text}}
                        </div>
                        <div class="ta toast-close--warning">
                            <i class="far fa-times cursor-pointer padding_5px" (click)="removeFromArrayByForce(i)"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="toasterGrid">
                <div class="alert-toaster--warning alert" *ngIf="msg?.type === 'update'">
                    <div class="row float-right" *ngIf="msg?.type === 'update'">
                        <button class="alert_update" (click)="hardRefreshBrowser();"> Update</button>
                    </div>
                </div>
            </div>

            <div  class = "w-100 h-100 alert_css">
                <div class="toasterGridmsg">
                    <div class="alert-toaster alert" *ngIf="msg?.type === 'error'">
                        <div class="toast-message--error">
                            <div>
                                <button autofocus="true" (keydown.enter)="removeFromArrayByForce(i)" (keydown.tab)="removeFromArrayByForce(i)" (keydown.space)="removeFromArrayByForce(i)"><i class="far fa-times-circle cursor-pointer" (click)="removeFromArrayByForce(i)"></i></button>
                            </div>
                            <div class="toast-size">Error:
                                <span>{{(msg?.text | tokenSeparator)?.txt}}</span>
                                <span class="tooltiptext" id="myTooltip"></span>
                                <span (click)="myFunction()" id="tokenText" *ngIf="(msg?.text | tokenSeparator)?.token">: {{(msg?.text | tokenSeparator)?.token}}
                                </span>
                            </div>
                            <i class="far fa-times"  (click)="removeFromArrayByForce(i)"></i>
                        </div>
                        <div class="row float-right" *ngIf="msg?.type === 'update'">
                            <button class="alert_update" (click)="hardRefreshBrowser();"> Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="toasterGrid" >
                <div class="alert-toaster alert" *ngIf="msg?.type === 'unsuccess'">
                    <div class="row toast-message--unsuccess">
                        <div class="col-3">
                            <i class="far fa-times-circle cursor-pointer" (click)="removeFromArrayByForce(i)"></i>
                        </div>
                        <div class="col-8 toast-size">Failed:
                            {{msg?.text}}
                        </div>
                            <i class="far fa-times" (click)="removeFromArray(i, msg?.type)"></i>
                    </div>
                    <div class="row float-right" *ngIf="msg?.type === 'update'">
                        <button class="alert_update" (click)="hardRefreshBrowser();"> Update</button>
                    </div>
                </div>
            </div>
            <!-- <div class="toasterGridmsg" *ngIf="msg?.type === 'error'">
                <div style="position: relative; top: 33%;">
                    <div class="alert-toaster alert" *ngIf="msg?.type === 'error'">
                        <div class="toast-message--error">
                            <div>
                                <i class="far fa-times-circle" (click)="removeFromArrayByForce(i)" style="cursor: pointer"></i>
                            </div>
                            <div class="toast-size">Error:
                                <span>{{(msg?.text | tokenSeparator)?.txt}}</span>
                                <span class="tooltiptext" id="myTooltip"></span>
                                <span (click)="myFunction()" id="tokenText" *ngIf="(msg?.text | tokenSeparator)?.token">
                                    : {{(msg?.text | tokenSeparator)?.token}}
                                </span>
                            </div>
                        </div>
                        <div class="row" *ngIf="msg?.type === 'update'" style="float: right;">
                            <button class="alert_update" (click)="hardRefreshBrowser();"> Update</button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

