import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { ControlRxFilterParams, DrugClass } from 'src/app/models/control-file.model';
import { CommonService, ControlFileService, ReportsService } from 'src/app/services';
import { CommonUtil, WijimoUtil } from 'src/app/utils';
import { format } from 'url';
import { CollectionView } from '@grapecity/wijmo';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-automatic-submission-cntrl-file',
  templateUrl: './automatic-submission-cntrl-file.component.html',
})
export class AutomaticSubmissionCntrlFileComponent implements OnInit {
  autoSubmissionFG : FormGroup;
  drugFG:FormGroup;
  patchDataFG: FormGroup;
  controlFile: any;
  today: any;
  automatSubWJ: CollectionView;
  wjHeaders: any;
  pickFrmTimeForDisplay: string = "AM";
  pickToTimeForDisplay: string = "PM";
  statesDataFromStngs: any;
  allSelect: any;
  totalRxs: any;
  queueData: any;
  slctdRxObj: any = {};
  selectedRxs:any = [];
  allStateRxs:any = [];
  DrugClass:any = [];
  singleRxsStates:any = [];
  isPickFrmTimeValid: any = false;
  isPickToTimeValid: any = false;
  singleStatePatching:any;
  updatedStates:any;
  fromDueDateList: any[] = [];
  @Output() controlFileSchedule = new EventEmitter<any>();
  @Output() cntrlfileDetails = new EventEmitter();
  @Output() rowDetails = new EventEmitter();
  @Input() InputValue:any;
  @Input() editMODE:any;
  @Input() editTaskData : any;
  ControlRefillOptions:any;
  maxDate: { year: number; month: number; day: number; };
  slctdRx:any = [];
  selectedStates:any = [];
  timeFormatted:any;
 time: { hour: number; minute: number; };
 time2: { hour: number; minute: number; };
 stateArray:any = [];
 unsubscribe$: Subject<void> = new Subject();
  constructor( private _modalSvc: NgbModal, private _fb: FormBuilder,private _commonServ: CommonService, private _wijimoUtils: WijimoUtil, private _ctrlFileSvc: ControlFileService,private _commonUtils: CommonUtil, private _reportsServ : ReportsService) {
   }

  ngOnInit() {
    this.maxDate = {
      year: moment(this.today).year(),
      month: moment(this.today).month() + 1,
      day: moment(this.today).date()
  };
  this.patchDefaultValHeaders();
  this._wijimoUtils.wjSavedData$
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(w => {
      if (w && w["WijmoKey"] && w["WijmoKey"] === "automatSubWj") {
          this.patchDefaultValHeaders();
      }
  });
  this.time = { hour: parseFloat("00"), minute: parseFloat("00") };
    this.time2 = { hour: parseFloat("23"), minute: parseFloat("59") };

  this.today = moment().format("MM/DD/YYYY");
  this.createFG();
  this._commonUtils.setDefaultValues(this.autoSubmissionFG,"autoSubmissionFG");
  this.setDefaultValues();
  this._ctrlFileSvc.getControlFileSettings()
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(resp=>{
    if(resp){
    this.statesDataFromStngs = resp.ControlSetting;
    let stateVal = this._commonServ.getSetttingValue("TenantSettings", "TNT_STATE");
    this.generateStatesgrid(this.statesDataFromStngs,stateVal);
    }
   })
   if (this.autoSubmissionFG.controls["CntrlFromDate"].value === "d") {
    this.autoSubmissionFG.controls["FromDt"].patchValue(this.today);
    } else {
      this.autoSubmissionFG.controls["FromDt"].patchValue(moment(this.autoSubmissionFG.value.FromDt).format("MM/DD/YY"));
    }
    if (this.autoSubmissionFG.controls["CntrlToDate"].value === "d") {
    this.autoSubmissionFG.controls["ToDt"].patchValue(this.today);
    } else {
      this.autoSubmissionFG.controls["ToDt"].patchValue(moment(this.autoSubmissionFG.value.ToDt).format("MM/DD/YY"));
    }
    if (this.InputValue) {
      // this.autoSubmissionFG.patchValue(this.InputValue);
      if(this.InputValue.CntrlFromDate === "d-"){
        this.autoSubmissionFG.controls["CntrlFromDate"].patchValue(this.InputValue.CntrlFromDate);
        this.autoSubmissionFG.controls["CntrlFromDueDateDays"].patchValue(this.InputValue.CntrlFromDueDateDays);
      }else if(this.InputValue.CntrlFromDate === "d"){
         this.autoSubmissionFG.controls["CntrlFromDate"].patchValue(this.InputValue.CntrlFromDate);
        this.autoSubmissionFG.controls["FromDt"].patchValue(this.today);
      }else if(this.InputValue.CntrlFromDate === "f"){
        this.autoSubmissionFG.controls["CntrlFromDate"].patchValue(this.InputValue.CntrlFromDate);
        this.autoSubmissionFG.controls["FromDt"].patchValue(this.InputValue.FromDt);
      }
      if(this.InputValue.CntrlToDate === "d-"){
        this.autoSubmissionFG.controls["CntrlToDate"].patchValue(this.InputValue.CntrlToDate);
        this.autoSubmissionFG.controls["CntrlToDueDateDays"].patchValue(this.InputValue.CntrlToDueDateDays);
      }else if(this.InputValue.CntrlToDate === "d"){
         this.autoSubmissionFG.controls["CntrlToDate"].patchValue(this.InputValue.CntrlToDate);
        this.autoSubmissionFG.controls["ToDt"].patchValue(this.today);
      }else if(this.InputValue.CntrlToDate === "f"){
        this.autoSubmissionFG.controls["CntrlToDate"].patchValue(this.InputValue.CntrlToDate);
        this.autoSubmissionFG.controls["ToDt"].patchValue(this.InputValue.ToDt);
      }
      this.autoSubmissionFG.controls['IncRxNotSubmitted'].patchValue(this.InputValue.IncRxNotSubmitted);
      this.autoSubmissionFG.controls['IncRxResubmission'].patchValue(this.InputValue.IncRxResubmission);
      this.InputValue.State.map(val => this.singleRxsStates.push(val));
      let fromDateTimeSplit = this.InputValue.FrmTime.split("T");
      let fromTimeSplit = this.InputValue.FrmTime.includes("T") ?  fromDateTimeSplit[1].split(":") : this.InputValue.FrmTime.split(":") ;
      this.time["hour"] = parseFloat(fromTimeSplit[0]);
      this.time["minute"] = parseFloat(fromTimeSplit[1]);
      let toDateTimeSplit = this.InputValue.ToTime.split("T");
      let toTimeSplit = this.InputValue.ToTime.includes("T") ?  toDateTimeSplit[1].split(":") : this.InputValue.ToTime.split(":");
      this.time2["hour"]  = parseFloat(toTimeSplit[0]);
      this.time2["minute"] =parseFloat(toTimeSplit[1]);

    }
  }
  setDefaultValues() {
  this.fromDueDateList = [
    { Name: "Today's Date", Value: "d" },
    // { Name: "Today's Date +", Value: "d+" },
    { Name: "Today's Date -", Value: "d-" },
    { Name: "Fixed Date", Value: "f" }
  ];
}
  createFG() {
    this.autoSubmissionFG = this._fb.group( new ControlRxFilterParams());
    this.drugFG =this._fb.group(new DrugClass() )
   
  }
  getPickFrmTimeForDisplay() {
    this.pickFrmTimeForDisplay = (this.pickFrmTimeForDisplay === "AM") ? "PM" : "AM";
}
getPickToTimeForDisplay() {
    this.pickToTimeForDisplay = (this.pickToTimeForDisplay === "AM") ? "PM" : "AM";
}
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("automatSubWj");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("automatSubWj", storedWJ);
}
  selectedRx(isChecked, id, flexGrid) {
    if (isChecked) {
      flexGrid.grid.collectionView.items.map(val => {
        if (val && val["StateId"] === id) {
          val["IsSelected"] = isChecked ? true : false;
        }
      })
      const newArr: any = [];
      flexGrid.grid.collectionView.items.map(ord => {
        if (ord && ord["IsSelected"]) {
          newArr.push(ord.State);
        }
      }
      )
      this.selectedStates = newArr;
      this.autoSubmissionFG.controls["State"].patchValue(this.selectedStates);
    } else {
      flexGrid.grid.collectionView.items.map(val => {
        if (val && val["StateId"] === id) {
          val["IsSelected"] = isChecked ? true : false;
        }
      })
      const newArr: any = [];
      flexGrid.grid.collectionView.items.map(ord => {
        if (ord && ord["IsSelected"]) {
          newArr.push(ord.State);
        }
      }
      )
      this.selectedStates = newArr;
      this.autoSubmissionFG.controls["State"].patchValue(this.selectedStates);
    }
  }
  checkAll(isChecked: boolean, flexGrid) {
    this.allSelect = isChecked;
    // const stateID = this.ControlRefillOptions[0] && this.ControlRefillOptions[0].StateId ? this.ControlRefillOptions[0].StateId : "";
    if (this.allSelect) {
      flexGrid.grid.collectionView.items.filter(val => {
        // if (val&&val["StateId"] !== stateID) {
          val["IsSelected"] = isChecked ? true : false;
        // };
      })
      const newArr: any = [];
      flexGrid.grid.collectionView.items.map(ord => {
        if (ord && ord["IsSelected"]) {
          newArr.push(ord.State);
        }
      }
      )
      this.selectedStates = newArr;
      this.autoSubmissionFG.controls["State"].patchValue(this.selectedStates);
    } else {
      flexGrid.grid.collectionView.items.map(val => {
        // if (val && val["StateId"] !== stateID) {
          val["IsSelected"] = false;
        }

      // }
      )
      const newArr: any = [];
      flexGrid.grid.collectionView.items.map(ord => {
        if (ord && ord["IsSelected"]) {
          newArr.push(ord.State);
        }
      }
      )
      this.selectedStates = newArr;
      this.autoSubmissionFG.controls["State"].patchValue(this.selectedStates);
    }

  }
  generateStatesgrid(statesDataFromStngs,state?) {
    this.automatSubWJ = new CollectionView(
      statesDataFromStngs.map((value: any, i) => {
            const k = {};
            k["RowId"]=i;
            k["State"] =  value.State ?value.State : "";
            k["File WebSite"] =  value.WebsiteLink ?  value.WebsiteLink : "";
            k["Upload file to FTP/SFTP?"] = value && value.UploadFileToFTP ? "Y" : "N" ;
            // k["Send Email?"] =  "N";
            k["IsSelected"] = ((state === k["State"] && this.selectedStates.includes(state)) || (state === k["State"] && !this.editMODE)) ? true :((this.InputValue && this.InputValue.State) ? this.InputValue.State.some(elm => elm === k["State"]):false)
              if(k["IsSelected"]){
                this.stateArray.push(k["State"]);
              }
              this.selectedStates = this.stateArray;
              this.autoSubmissionFG.controls["State"].patchValue(this.selectedStates);
            k["StateId"]=value.StateId;
            k["IsActive"] = value.IsActive;
            return k;
        }),  
    );
}
saveParameterDtls(){
  // if (this.autoSubmissionFG.controls['CntrlFromDate'] && this.autoSubmissionFG.controls['CntrlFromDate'].value === 'd+') {
  //   this.autoSubmissionFG.controls["FromDt"].patchValue(moment().add(this.autoSubmissionFG.value.CntrlFromDueDateDays, "days").format("MM/DD/YY"));
  // }

  if (this.autoSubmissionFG.controls['CntrlFromDate'] && this.autoSubmissionFG.controls['CntrlFromDate'].value === 'd-') {
    this.autoSubmissionFG.controls["FromDt"].patchValue(moment().subtract(this.autoSubmissionFG.value.CntrlFromDueDateDays, "days").format("MM/DD/YY"));
  }
  // if (this.autoSubmissionFG.controls['CntrlToDate'] && this.autoSubmissionFG.controls['CntrlToDate'].value === 'd+') {
  //   this.autoSubmissionFG.controls["ToDt"].patchValue(moment().add(this.autoSubmissionFG.value.CntrlToDueDateDays, "days").format("MM/DD/YY"));
  // }

  if (this.autoSubmissionFG.controls['CntrlToDate'] && this.autoSubmissionFG.controls['CntrlToDate'].value === 'd-') {
    this.autoSubmissionFG.controls["ToDt"].patchValue(moment().subtract(this.autoSubmissionFG.value.CntrlToDueDateDays, "days").format("MM/DD/YY"));
  }
  this.DrugClass.push(
     this.drugFG.controls['Class2'].value ? 2:null,
     this.drugFG.controls['Class3'].value ? 3:null,
     this.drugFG.controls['Class4'].value ? 4:null,
     this.drugFG.controls['Class5'].value ? 5:null,
     this.drugFG.controls['Class6'].value ? 6:null
  )
  if(this.DrugClass){
   let sltcdDrugClass = this.DrugClass.filter(val=>{
      return val !== null;
    })
    this.autoSubmissionFG.controls["DrugClass"].patchValue(sltcdDrugClass)
    
  }
 if(this.autoSubmissionFG.valid){
  this.autoSubmissionFG.controls["FrmTime"].patchValue(this.time["hour"] +":" + this.time["minute"]);
  this.autoSubmissionFG.controls["ToTime"].patchValue(this.time2["hour"] +":"+ this.time2["minute"]);
  if(!this.selectedStates.length){
    this.autoSubmissionFG.controls["State"].patchValue(['NY']);
  }
  if (this.editMODE) {
    const dataToPost = {
      Id : this.editMODE ? this.editTaskData.Id : 0,
      taskId: this.editTaskData?.TaskId,
      taskName: this.editTaskData?.TaskName,
      taskDescription : this.editTaskData?.TaskDescription,
      IsEnabled : this.editTaskData?.IsEnabled,
      startTime : this.editTaskData?.StartTime,
      HangFireJobId : this.editMODE ? this.editTaskData?.HangFireJobId : "",
      cronExpression: this.editTaskData?.CronExpression,
      ParametersString : JSON.stringify(this.autoSubmissionFG.value),
      LastExecutedDtTm: this.editMODE ? this.editTaskData?.LastExecutedDtTm : null,
      EmailAddress: null,
      IsFileGenerated: null,
      RecurIn : this.editTaskData?.RecurIn,
      isEmailEnabled: false,
      emailAddress: null,
      TimeZone: null,
      IsActive: true,
      IsDeleted: false,
      IsDefault: this.editMODE ? this.editTaskData?.IsDefault : false,
      Action: this.editMODE ? "u": "a",
      CreatedDtTm: this.editMODE ? this.editTaskData?.CreatedDtTm :null,
      ModifiedDtTm: this.editMODE ? this.editTaskData?.ModifiedDtTm: null,
      taskTypeId : this.editTaskData?.TaskTypeId
    }
      this._reportsServ.addTaskToScheduler(dataToPost).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
        this.cntrlfileDetails.emit(this.autoSubmissionFG.value);  
      });
  } else {
      this.cntrlfileDetails.emit(this.autoSubmissionFG.value);  
  }
 }
}
close() {
  this.controlFileSchedule.emit();
}
setForFromDate() {
  if (this.autoSubmissionFG.controls["CntrlFromDate"] && this.autoSubmissionFG.controls["CntrlFromDate"].value === "d") {
      this.autoSubmissionFG.controls["FromDt"].patchValue(this.today);
    }
}

setForDueDate(eve) {
  if (this.autoSubmissionFG.controls["CntrlToDate"] && this.autoSubmissionFG.controls["CntrlToDate"].value === "d") {
      this.autoSubmissionFG.controls["ToDt"].patchValue(this.today);
    }
}
navUpForFromDays() {
  let fromDueDays = parseInt(this.autoSubmissionFG.value.CntrlFromDueDateDays)
  // if (this.autoSubmissionFG.controls["CntrlFromDate"] && this.autoSubmissionFG.controls["CntrlFromDate"].value === 'd+' && parseInt(this.autoSubmissionFG.controls["CntrlFromDueDateDays"] && this.autoSubmissionFG.controls["CntrlFromDueDateDays"].value) >=0) {
  //   if (fromDueDays <= 999) {
  //     this.autoSubmissionFG.controls["CntrlFromDueDateDays"].patchValue(++fromDueDays);
  //   }
  //  }

   if (this.autoSubmissionFG.controls["CntrlFromDate"] && this.autoSubmissionFG.controls["CntrlFromDate"].value === 'd-' && parseInt(this.autoSubmissionFG.controls["CntrlFromDueDateDays"] && this.autoSubmissionFG.controls["CntrlFromDueDateDays"].value) >=0) {
    if (fromDueDays <= 999) {
      this.autoSubmissionFG.controls["CntrlFromDueDateDays"].patchValue(++fromDueDays);
    }
   }
}

navDownForFromDays() {
  let fromDueDays = parseInt(this.autoSubmissionFG.value.CntrlFromDueDateDays)
  // if (this.autoSubmissionFG.controls["CntrlFromDate"] && this.autoSubmissionFG.controls["CntrlFromDate"].value === 'd+' && parseInt(this.autoSubmissionFG.controls["CntrlFromDueDateDays"] && this.autoSubmissionFG.controls["CntrlFromDueDateDays"].value) >0) {
  //   if (fromDueDays > 0) {
  //     this.autoSubmissionFG.controls["CntrlFromDueDateDays"].patchValue(--fromDueDays);
  //   }
  //  }

   if (this.autoSubmissionFG.controls["CntrlFromDate"] && this.autoSubmissionFG.controls["CntrlFromDate"].value === 'd-' && parseInt(this.autoSubmissionFG.controls["CntrlFromDueDateDays"] && this.autoSubmissionFG.controls["CntrlFromDueDateDays"].value) >=0) {
    if (fromDueDays > 0) {
      this.autoSubmissionFG.controls["CntrlFromDueDateDays"].patchValue(--fromDueDays);
    }
   }
}

navUpForToDueDays() {
  let toDueDays = parseInt(this.autoSubmissionFG.value.CntrlToDueDateDays);
  if (this.autoSubmissionFG.controls["CntrlToDate"] && this.autoSubmissionFG.controls["CntrlToDate"].value === 'd+' &&  parseInt(this.autoSubmissionFG.controls["CntrlToDueDateDays"] && this.autoSubmissionFG.controls["CntrlToDueDateDays"].value) >=0) {
    if (toDueDays <= 999) {
      this.autoSubmissionFG.controls["CntrlToDueDateDays"].patchValue(++toDueDays);
    }
  }

  if (this.autoSubmissionFG.controls["CntrlToDate"] && this.autoSubmissionFG.controls["CntrlToDate"].value === 'd-' &&  parseInt(this.autoSubmissionFG.controls["CntrlToDueDateDays"]&&this.autoSubmissionFG.controls["CntrlToDueDateDays"].value) >=0) {
    if (toDueDays <=999) {
      this.autoSubmissionFG.controls["CntrlToDueDateDays"].patchValue(++toDueDays);
    }
  }
}

navDownForToDueDays() {
  let toDueDays = parseInt(this.autoSubmissionFG.value.CntrlToDueDateDays);
  if (this.autoSubmissionFG.controls["CntrlToDate"] && this.autoSubmissionFG.controls["CntrlToDate"].value === 'd+' &&  parseInt(this.autoSubmissionFG.controls["CntrlToDueDateDays"] && this.autoSubmissionFG.controls["CntrlToDueDateDays"].value) >0) {
    if (toDueDays > 0) {
      this.autoSubmissionFG.controls["CntrlToDueDateDays"].patchValue(--toDueDays);
    }
   }
  if (this.autoSubmissionFG.controls["CntrlToDate"]&&this.autoSubmissionFG.controls["CntrlToDate"].value === 'd-' && parseInt(this.autoSubmissionFG.controls["CntrlToDueDateDays"] && this.autoSubmissionFG.controls["CntrlToDueDateDays"].value) >=0) {
    if (toDueDays > 0) {
      this.autoSubmissionFG.controls["CntrlToDueDateDays"].patchValue(--toDueDays);
    }
  }
}
timeValueChange(eve, type) {
  if (eve) {
      if (type === "FromTime") {
        this.autoSubmissionFG.patchValue({FrmTime :eve["hour"]+":"+eve["minute"]} );
      } else if (type === "ToTime") {
        this.autoSubmissionFG.patchValue({ToTime :eve["hour"]+":"+eve["minute"]});
      }
  } else {
      if (type === "FromTime") {
    this.autoSubmissionFG.patchValue({
      FrmTime :eve["hour"]+":"+eve["minute"]
    });
  } else if (type === "ToTime") {
      this.autoSubmissionFG.patchValue({
        ToTime :eve["hour"]+":"+eve["minute"]
        });
  }
}
}
}
