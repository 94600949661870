import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { RxModel, SystemData } from "src/app/models";
import { CommonService } from "src/app/services";

@Component({
    selector: 'app-prior-approval-result',
    templateUrl: './prior-approval-result.component.html'
})
export class PriorApprovalResultComponent implements OnInit {
    @Input() rxData: RxModel;
    @Input() priorResp: any;
    @Input() postData: any;

    modalRef: any;
    showPatient = false;

    @Output() IsPopUpClosed = new EventEmitter<any>();
    messageType: string;
    insuList: any[] = [];
    constructor(private _modalService: NgbModal) {

    }

    ngOnInit() {
        if (this.priorResp && this.priorResp.message) {
            if (this.priorResp.message.startsWith("Rejected")) {
                this.messageType = "Rejected";
            } else if (this.priorResp.message.startsWith("Accepted")) {
                this.messageType = "Accepted";
            }
            let insu;
            if (this.postData.ins_code) {
                insu=this.rxData.Patient.insurance.find(insuElm=> insuElm.InsurerCode == this.postData.ins_code);
            } else if (this.postData.InsuId) {
                insu=this.rxData.Patient.insurance.find(insuElm=> insuElm.insuranceid == this.postData.InsuId);
            }
            if (insu) {
                this.rxData.Patient.insuranceid= insu.insuranceid;
            }
            this.showPatient = true;
        }
    }

    closeModal() {
        this.IsPopUpClosed.emit(null);
    }
}
