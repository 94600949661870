import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import * as Dynamsoft from "dwt";
import { FormBuilder, FormGroup } from "@angular/forms";
import { environment } from "src/environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageEditorComponent } from "../image-editor/image-editor.component";
import * as moment from "moment";
import { AlertService, CommonService, UserService } from "src/app/services";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, EditRxE, RefillRxE, RxEntryE } from "src/app/models";
import { MsgConfig } from "src/app/app.messages";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-scanner-util",
    templateUrl: "./scanner-util.component.html",
})
export class ScannerUtilComponent implements OnInit {
    DWObject: WebTwain;
    imageOutput: any;
    imgURL: string | ArrayBuffer;
    scannedFiles = [];
    respArray = [];
    ScannerFG: FormGroup;
    imageTypeDropdowns: any[];
    scannerSources: any[];
    DWTLicenseKey: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set ScanMode(mode: any) {
        this.scanMode = mode;
    }

    @Input()
    set ShowUi(data: any) {
        this.showUi = data;
    }
    @Input() rxType: any;
    @Input() isFromLogin: any;

    @Output()
    ScannedFile = new EventEmitter();
    showImageEditor: boolean;
    imageFile: File;

    @Output()
    ScannedFileStream = new EventEmitter();

    scanMode: any;
    showUi = true;

    constructor(private _fb: FormBuilder, private _modalService: NgbModal,private _commonServ:CommonService,
        private _userservice: UserService,private _privMask: PrivMaskGuard,private _alertSvc: AlertService) {
    }

    ngOnInit() {
        this.createFG();
        if (this.isFromLogin) {
            this.DynamsoftInstallationCheck()
        } else {
            this.DWObject = this._commonServ.Dynamsoft$["source"]["value"];
            if (this.DWObject) {
                this.Dynamsoft_OnReady()
            }
        }
    }

    DynamsoftInstallationCheck() {
        if (!localStorage.getItem("DWTLicensekey")) {
            this._userservice.getWijimoAndDWTDWTLicenseKey()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.DWTLicenseKey = resp.find(obj => obj.KeyName === "DWTLicenseKey");
                    // Dynamsoft.WebTwainEnv.ProductKey = this.DWTLicenseKey.KeyValue;
                    if (this.DWTLicenseKey && this.DWTLicenseKey.KeyValue) {
                        localStorage.setItem("DWTLicensekey", this.DWTLicenseKey.KeyValue);
                    }
                    Dynamsoft.WebTwainEnv.ProductKey = this._commonServ.DecryptData(localStorage.getItem("DWTLicensekey"));
                }
            });
        } else {
            Dynamsoft.WebTwainEnv.ProductKey = this._commonServ.DecryptData(localStorage.getItem("DWTLicensekey"));
        }
        Dynamsoft.WebTwainEnv.ResourcesPath = environment.DWTInstallFolder;
        Dynamsoft.WebTwainEnv.AutoLoad = false;
        Dynamsoft.WebTwainEnv.Trial = false;
        Dynamsoft.WebTwainEnv.Containers = [
            {
                ContainerId: "dwtcontrolContainer",
                Width: "100px",
                Height: "100px",
            },
        ];
        Dynamsoft.WebTwainEnv.RegisterEvent("OnWebTwainReady", () => {
            this.Dynamsoft_OnReady();
        });
        Dynamsoft.WebTwainEnv.Load();
        this.pageonload();
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createFG() {
        let scannResolutionSetting = this._commonServ.getSetttingValue("DmsSettings", "Scanned_Document_Resolution");
        let imageType = this._commonServ.getSetttingValue("DmsSettings", "Default_Scanning_Image_Type");
        this.ScannerFG = this._fb.group({
            scannerSource: [0],
            pixelType: [imageType == "1" ?  EnumDWT_PixelType.TWPT_GRAY : EnumDWT_PixelType.TWPT_RGB],
            resolution: [scannResolutionSetting ? +scannResolutionSetting : null],
            duplex: [false]
        });
    }

    startScan() {
        let fgValue = this.ScannerFG.value;
        if (this.rxType == "nr" && !this._privMask.canActivate(AccCategoryE.RxEntry, RxEntryE.ScanADocument)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        } else if (this.rxType == "er" && !this._privMask.canActivate(AccCategoryE.EditRx, EditRxE.ScanADocument)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        } else if (this.rxType == "rf" && !this._privMask.canActivate(AccCategoryE.RefillRx, RefillRxE.ScanADocument)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        } else {
            if (this.DWObject) {
                this.DWObject.SelectSourceByIndex(this.ScannerFG.value.scannerSource);
                this.DWObject.CloseSource();
                this.DWObject.OpenSource();
                this.DWObject.IfShowProgressBar = false;
                this.DWObject.IfShowCancelDialogWhenImageTransfer = false;
                this.DWObject.IfShowFileDialog = false;
                this.DWObject.IfShowIndicator = false;
                this.DWObject.IfShowPrintUI = false;
                this.DWObject.IfShowUI = false;
                this.DWObject.PixelType = this.ScannerFG.value.pixelType ? this.ScannerFG.value.pixelType : EnumDWT_PixelType.TWPT_RGB;
                this.DWObject.Resolution = this.ScannerFG.value.resolution;
                this.DWObject.IfFeederEnabled = true;
                this.DWObject.IfDuplexEnabled = fgValue.duplex ? true : false;
                this.DWObject.IfDisableSourceAfterAcquire = true;
                this.DWObject.AcquireImage(function () { }.bind(this), function () { });
                this.DWObject.RegisterEvent(
                    "OnPostTransfer",
                    function () {
                        // this.processSingleImage();
                    }.bind(this)
                );
    
                this.DWObject.RegisterEvent(
                    "OnPostTransferAsync",
                    function () {
                        this.processSingleImage();
                    }.bind(this)
                );
                this.DWObject.RegisterEvent(
                    "OnPostAllTransfers",
                    function () {
                        this.DWObject.CloseSource();
                    }.bind(this)
                );
            } else {
                this.DynamsoftInstallationCheck()
            }
        }
       
    }

    processSingleImage() {
        this.DWObject.SelectedImagesCount = 1;
        this.DWObject.SetSelectedImageIndex(
            this.DWObject.HowManyImagesInBuffer - 1,
            this.DWObject.HowManyImagesInBuffer - 1
        );
        this.DWObject.GetSelectedImagesSize(EnumDWT_ImageType.IT_PNG);
        this.imageOutput = this.DWObject.SaveSelectedImagesToBase64Binary();
        // this.docSer.saveDocument([this.imageOutput])
        //     .subscribe(resp => {
        //         if (resp) {
        //             this.respArray.push(resp);
        //         }
        //     });
        const timeStamp = moment().format("YYYYMMDD-hhmmss");
        this.ScannedFileStream.emit({
            byteStream: "data:image/png;base64," + this.imageOutput,
            filename: timeStamp + ".png",
            extsn: "png",
        });
        this.urltoFile(
            "data:image/png;base64," + this.imageOutput,
            timeStamp + ".png",
            "image/png"
        ).then((file) => {
            this.imageFile = file;
            this.ScannedFile.emit(file);
            this.scannedFiles.push(file);
        });
    }

    clearImages() {
        this.DWObject.RemoveAllImages();
        this.scannedFiles = [];
    }

    printImage2() {
        this.urltoFile(
            "data:image/png;base64," + this.imageOutput,
            "today.png",
            "image/png"
        ).then((file) => {
            this.showTransferedImage(file);
        });
    }

    showTransferedImage(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
            this.imgURL = reader.result;
        };
    }

    urltoFile(url, filename, mimeType) {
        return fetch(url)
            .then((res) => res.arrayBuffer())
            .then((buf) => new File([buf], filename, { type: mimeType }));
    }

    printImage() {
        this.DWObject.SelectedImagesCount = 1;
        this.DWObject.SetSelectedImageIndex(0, 0);
        this.DWObject.GetSelectedImagesSize(EnumDWT_ImageType.IT_PNG);
        this.imageOutput = this.DWObject.SaveSelectedImagesToBase64Binary();
    }

    pageonload() {}

    Dynamsoft_OnReady() {
        const liNoScanner = document.getElementById("pNoScanner");
        if (this.isFromLogin) {
            this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain(
                "dwtcontrolContainer"
            );
            this._commonServ._Dynamsoft$.next(this.DWObject)
        } else {
            if (this.DWObject) {
                // If the ErrorCode is 0, it means everything is fine for the control. It is fully loaded.
                if (this.DWObject.ErrorCode == 0) {
                    this.DWObject.LogLevel = 0;
                    this.DWObject.IfAllowLocalCache = true;
                    this.DWObject.ImageCaptureDriverType = 3;
                }
            }
            const vCount = this.DWObject.SourceCount;
            this.scannerSources = [];
            for (let i = 0; i < vCount; i++) {
                this.scannerSources.push({
                    Id: i,
                    Name: this.DWObject.GetSourceNameItems(i),
                });
            }
            this.imageTypeDropdowns = [
                // { Id: EnumDWT_PixelType.TWPT_BW, Name: "B&W" },
                { Id: EnumDWT_PixelType.TWPT_GRAY, Name: "Gray" },
                { Id: EnumDWT_PixelType.TWPT_RGB, Name: "RGB" },
            ];
    
            this.DWObject.RegisterEvent(
                "OnPostTransfer",
                this.Dynamsoft_OnPostTransfer
            );
            this.DWObject.RegisterEvent(
                "OnPostLoad",
                this.Dynamsoft_OnPostLoadfunction
            );
            this.DWObject.RegisterEvent(
                "OnPostAllTransfers",
                this.Dynamsoft_OnPostAllTransfers
            );
        }
    }

    Dynamsoft_OnPostTransfer = () => {};

    Dynamsoft_OnPostLoadfunction = (path, name, type) => {};
    Dynamsoft_OnPostAllTransfers = () => {
        // this.DWObject.CloseSource();
    }

    editImage() {
        const modelRef = this._modalService.open(ImageEditorComponent, {
            size: "lg",
            keyboard: false,
            backdrop: "static",
            centered : true,
            windowClass: "max-modal-74"
        });
        modelRef.componentInstance.IsFromDocumentQueue = true;
        modelRef.componentInstance.ImageData =
            "data:image/png;base64," + this.imageOutput;
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((base64) => {
            modelRef.close();
        });
    }
}
