import { constant } from "src/app/app.constants";

export const DrugTabEnum = {
    Patient1: "DRUGINFO",
    Patient2: "PRICING",
    Patient3: "INVENTORY",
    Patient4: "DISPHISTORY",
    Patient5: "MISCINFO",
    Patient6: "COUNSELLING",
    Patient7: "NOTES",
    Patient8: "INSUREST",
    Patient9: "DOCUMENTS",
    Patient10: "PRICEUPDATE",
    Patient11: "DRUGCATEGORY",
    Patient12: "NOTESLOG"
   // Patient12: "BUCKETPRICING",

};

export const DrugTabTitles = {
    DRUGINFO: "Patient1",
    PRICING: "Patient2",
    INVENTORY: "Patient3",
    DISPHISTORY: "Patient4",
    MISCINFO: "Patient5",
    COUNSELLING: "Patient6",
    NOTES: "Patient7",
    INSUREST: "Patient8",
    DOCUMENTS: "Patient9",
    PRICEUPDATE: "Patient10",
    DRUGCATEGORY: "Patient11",
   // BUCKETPRICING: "Patient12",
    NOTESLOG: "Patient12"
};

export const DrugHeaderTitles = {
    DRUGINFO: "Drug Information",
    PRICING: "Drug Pricing",
    INVENTORY: "Inventory",
    DISPHISTORY: "Dispensed History",
    MISCINFO: "Drug Miscellaneous",
    COUNSELLING: "Drug Counselling",
    NOTES: "Drug Notes",
    INSUREST: "Insurance Preferences",
    DOCUMENTS: "Drug Documents",
    PRICEUPDATE: "Drug Price Update",
    DRUGCATEGORY: "Drug Category",
    NOTESLOG: "Notes",
   // BUCKETPRICING: "Bucket Pricing",
};

export const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
];

export const NdcOccHeaders = [
    "NDC",
    "Drug Name",
    "Compound Name",
    "Drug Type",
    "Compound NDC",
    "Ingredient",
    "Billing NDC",
    "Generic NDC",
    "Replacement NDC"
];

export const drugWarningHeader = [
    "WARNING",
    "GENDER",
    "AGE START",
    "AGE END",
    "WARN_DESC1",
    "WARN_DESC2"
];

export const mmsDataModel = {
    Name: "DRUG NAME",
    DrugTypeId: "DRUG TYPE",
    IsBranded: "BRAND",
    DrugFormId: "FORM",
    Strength: "STRENGTH",
    DrugUnitId: "UNIT OF MEASURE",
    UnitDoseIndId: "UNIT DOSE IND",
    ManufactId: "MADE BY",
    QtyPack: "QUANTITY-PACK",
    BrandName: "BRAND NAME",
    GenericName: "GENERIC NAME",
    UPC: "UPC",
    UnitPriceCost: "UNIT PRICE-PACK",
    UnitPriceAWP: "UNIT PRICE-AWP",
    DirectUnitPrice: "DIRECT UNIT PRICE",
    DrugClass: "DRUG CLASS",
    DiscontDt: "DISCONTINUE DATE",
    Therapeutic: "Therapeutic",
    TherapeuticCode: "Therapeutic Code",
    WarningCode: "WARNING CODE",
    DrugFullName: "DrugNameLong",
    DrugLabelName: "DrugNameShort",
    // IsTriplicate: "IsTriplicate"
};

export const drugMMSHeader = [
    "Select",
    "Drug Name",
    "Drug NDC",
    "Bill NDC",
    "Brand Name",
    "Generic Name",
    "Made By",
    "Drug Type",
    "Strength",
    "Drug Form",
    "Units",
    "Brand",
    "Size",
    "AWP Price",
    "Dir Price",
    "Cost Price",
];





