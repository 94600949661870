import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    HostListener
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PrintErxService } from "src/app/services/print-erx.service";
import html2canvas from "html2canvas";
import { PrintService } from "src/app/services/print.service";
import * as _ from "lodash";
import { UserService } from "src/app/services";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-print-erx-req",
    templateUrl: "./print-erx-req.component.html"
})
export class PrintErxReqComponent implements OnInit {
    rxInfo: any[];

    // IsMultiple = true;
    currentDate: Date;
    groupedArray: any[];
    pharmName: string;
    pharmAddress: string;
    pharmFax: string;
    pharmPhone: string;

    @Input()
    set RxInfo(data: any[]) {
        if (data) {
            this.rxInfo = data;
            this.differData();
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("CheckDur", { static: true })
    CheckDur: any;
    modelRef: NgbModalRef;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef) { // esc
            this.closeModal();
            event.preventDefault();
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _erxServ: PrintErxService,
        private _printSer: PrintService,
        private _userService: UserService,
        public _formatsUtil: FormatsUtil
    ) {}

    ngOnInit() {
        this.modelRef = this._modalService.open(this.CheckDur, {
            size: "lg",
            keyboard: false
        });
        this.currentDate = new Date();
        this.pharmName = this._userService.getToken("PharmacyName");
        this.pharmAddress = this._userService.getToken("PharmacyAddress");
        this.pharmFax = this._userService.getToken("PharmacyFax");
        this.pharmPhone = this._userService.getToken("PharmacyPhone");
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    print() {
        const data = document.getElementById("contentToConvert").children;
        for (let i = 0; i < data.length; i++) {
            html2canvas(data[i]).then(canvas => {
                // Few necessary setting options
                const imgWidth = canvas.width;
                const pageHeight = canvas.height;
                const imgHeight = canvas.height;
                const heightLeft = imgHeight;
                const contentDataURL = canvas.toDataURL("image/jpeg");
                this._printSer.print(contentDataURL);
            });
        }
    }

    differData() {
        // let tempData = this.rxInfo;
        const tempData = _.groupBy(this.rxInfo, sing => sing.Prescriber.prescriberid);
        this.groupedArray = Object.keys(tempData).map(i => tempData[i]);
    }
}
