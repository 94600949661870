import { Injectable } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import * as moment from "moment";
import { delay } from "rxjs/operators";
@Injectable({
    providedIn: "root"
})
export class ValidateFormFieldsUtils {
    errorMessage: string;

    

    validateFormFields(
        formGroup?: FormGroup,
        formCntrl?: string,
        errorDefs?: any
    ) {
        this.errorMessage = "";
        if (formGroup && formGroup.controls) {
            Object.keys(formGroup.controls).forEach(field => {
                if (formCntrl === field) {
                    const control = formGroup.get(field);
                    if ((field === "DispQty" && (formGroup.value.UnitPriceUNC === "0.00" || formGroup.value.UnitPriceUNC > "0.00") && (formGroup.value.DispQty <= "0.000")) || (field === "OrdQty" && (formGroup.value.OrdQty <= "0.000"))) {
                        control.setErrors({ invalid: true });
                    }
                    if (control && control.value === "") {
                        control.setValue(null);
                    }
                    if (control instanceof FormControl && (control.invalid)) {
                        if (errorDefs && Object.keys(errorDefs).length) {
                            Object.keys(errorDefs).some(key => {
                                if (control.errors && control.errors[key]) {
                                    this.errorMessage = errorDefs[key];
                                    return true;
                                }
                            });
                            if (!this.errorMessage && Object.keys(control.errors).length) {
                                this.errorMessage = Object.keys(control.errors)[0];
                            }
                        } else if(Object.keys(control.errors).length) {
                            this.errorMessage = Object.keys(control.errors)[0];
                        }
                    } else if (control instanceof FormGroup) {
                        this.validateFormFields(control, formCntrl);
                    }
                }
            });
            return this.errorMessage;
        }
        return null;
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                setTimeout(() => {
                    control.markAsTouched({ onlySelf: true });
                }, 10);
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                for (const control1 of control.controls) {
                    if (control1 instanceof FormControl) {
                        setTimeout(() => {
                            control1.markAsTouched({
                                onlySelf: true
                            });
                        }, 10);
                    } else if (control1 instanceof FormGroup) {
                        this.validateAllFormFields(control1);
                    }
                }
            }
        });

    }

    markFormFieldTouched(formGroup: FormGroup, formControl: string) {
        const control = formGroup.get(formControl);
        control.markAsTouched({ onlySelf: true });
    }
    markFormFieldTouchedFormFromgroup(formGroup: FormArray, formControl: string) {
        const control = formGroup.get(formControl);
        control.markAsTouched({ onlySelf: true });
    }

    checkDateErrors(FG: FormGroup, FromD: string, ToD: string, defRange: number = 0, isSubtractType: any = "days"): string {
        if (FG.controls[FromD].valid && FG.controls[ToD].valid) {
            if (!FG.get(FromD).value && !FG.get(ToD).value) {
                this.invalidDatePatchDef(FG, FromD, ToD, moment().subtract(defRange, isSubtractType).format("MM/DD/YYYY"),
                    moment().format("MM/DD/YYYY"));
                return "VALID";
            } else if ((!FG.get(FromD).value && FG.get(ToD).value) || (FG.get(FromD).value && !FG.get(ToD).value)) {
                if (!FG.get(FromD).value) {
                    this.invalidDatePatchDef(FG, FromD, ToD,
                        moment(FG.get(ToD).value, "MM/DD/YYYY").subtract(defRange, isSubtractType).format("MM/DD/YYYY"), FG.get(ToD).value);
                } else {
                    let TD = moment(FG.get(FromD).value, "MM/DD/YYYY").add(defRange, isSubtractType).format("MM/DD/YYYY");
                    TD = moment(TD, "MM/DD/YYYY").isAfter(moment()) ? moment().format("MM/DD/YYYY") : TD;
                    this.invalidDatePatchDef(FG, FromD, ToD, FG.get(FromD).value, TD);
                }
                return "VALID";
            } else if (moment(FG.get(FromD).value, "MM/DD/YYYY") > moment(FG.get(ToD).value, "MM/DD/YYYY")) {
                return "DATEISSUE";
            } else {
                return "VALID";
            }
        } else {
            return "INVALID";
        }
    }

    checkDateErrors2(FG: FormGroup, FromD: string, ToD: string, defRange: number = 0, isSubtractType: any = "days"): string {
        if (FG.controls[FromD].valid && FG.controls[ToD].valid) {
            if (!FG.get(FromD).value && !FG.get(ToD).value) {
                this.invalidDatePatchDef(FG, FromD, ToD,
                    moment().format("MM/DD/YYYY"), moment().add(defRange, isSubtractType).format("MM/DD/YYYY"));
                return "VALID";
            } else if ((!FG.get(FromD).value && FG.get(ToD).value) || (FG.get(FromD).value && !FG.get(ToD).value)) {
                if (!FG.get(FromD).value) {
                    this.invalidDatePatchDef(FG, FromD, ToD,
                        moment(FG.get(ToD).value, "MM/DD/YYYY").subtract(defRange, isSubtractType).format("MM/DD/YYYY"), FG.get(ToD).value);
                } else {
                    const TD = moment(FG.get(FromD).value, "MM/DD/YYYY").add(defRange, isSubtractType).format("MM/DD/YYYY");
                    this.invalidDatePatchDef(FG, FromD, ToD, FG.get(FromD).value, TD);
                }
                return "VALID";
            } else if (moment(FG.get(FromD).value, "MM/DD/YYYY") > moment(FG.get(ToD).value, "MM/DD/YYYY") && (FG.controls[FromD].value.length >= 10 && FG.controls[ToD].value.length >= 10)) {
                return "DATEISSUE";
            } else if (FG.controls[FromD].value.length >= 10 && FG.controls[ToD].value.length >= 10) {
                    return "VALID";
            } else {
                    return "INVALID";
            }
        } else {
            return "INVALID";
        }

    }

    invalidDatePatchDef(FG: FormGroup, FromD: string, ToD: string, FD: any, TD: any) {
        FG.controls[FromD].patchValue(FD);
        FG.controls[ToD].patchValue(TD);
    }
    async blurAndDelay() {
        const elm: any = document.activeElement;
        if (elm && elm.parentElement && elm.parentElement.parentElement &&
            elm.parentElement.parentElement.parentElement &&
            elm.parentElement.parentElement.parentElement.className === "ng-select-container ng-has-value") {
            return delay(200);
        }
        elm.blur();
        await delay(200);
        elm.focus();
        return delay(200);
    }
}
