<ng-template #DOSAGEINFO let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeDosageInfoPopUp()">
        <h4 header> Dosage Information </h4>
        <span body>
            <wj-flex-grid *ngIf="dosageInfoWJ?.items?.length > 0" #dosageInfo [isReadOnly]="true" [headersVisibility]="'Column'"
                [itemsSource]="dosageInfoWJ" (updatedView)="init(dosageInfo)" [columnPicker]="''">
                <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of drugactvHeader; let i = index"
                    [width]="(['Age Category', 'Min Daily Units', 'Max Daily Units', 'Ingredient Name', 'Min Daily Dose', 'Max Daily Dose',
                    'Dosing Type'].indexOf(drugactvHeader) > -1)? 90 : 50">
                </wj-flex-grid-column>
            </wj-flex-grid>
            <div *ngIf="dosageInfoWJ?.items?.length === 0" class="eprx--block__footer">
                <div>
                    <span></span>
                    <i class="fa fa-file"></i><br/>
                    No Data Found!
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeDosageInfoPopUp()"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>
