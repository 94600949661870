import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { constant } from "..";
import { HttpClient } from "@angular/common/http";
import { ErxVersions } from "../models";

@Injectable()
export class PrintErxService {
    constructor(private _http: HttpClient) {}

    checkErxRequest(rxId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERxReq_CheckErxRequest.replace("{rxId}", "" + rxId)
        );
    }

    chekcCompundDrug(drugId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERxReq_IsCompundDrug.replace("{drugId}", "" + drugId)
        );
    }
    checkDaw(prescId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERx_CheckDAW.replace("{prescRefillId}", "" + prescId)
        );
    }

    checkPrescriber(prescriberID: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERx_CheckPrescriber.replace(
                "{prescriberId}",
                "" + prescriberID
            )
        );
    }

    checkDrugValues(drugId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERx_CheckDrugValues.replace("{drugId}", "" + drugId)
        );
    }

    getErxDetails(prescId: any, drugId: any, reqType: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERX_GetERxDetails.replace(
                "{prescRefillId}",
                "" + prescId
            ).replace("{drugId}", "" + drugId).replace("{requestType}", "" + reqType)
        );
    }

    getErxDetailsFrErx(data, Version: any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion+"/" + constant.POST_Get_ErxChange_Details;
        } else {
            url =  constant.POST_Get_ErxChange_Details;
        }
        return this._http.post<any>(url, data);
    }

    getChangeResponseStatus(erxOpId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_changeResponseStatus.replace(
                "{erxOpId}",
                "" + erxOpId)
        );
    }

    getErxDropdowns(): Observable<any> {
        return this._http.get<any>(constant.GET_ERX_GetERxDropdowns);
    }

    saveMissingFields(mfData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_ERX_SaveMissingFields,
            mfData
        );
    }

    saveSPINumber(spiData: any): Observable<any> {
        return this._http.put<any>(constant.PUT_ERX_AddSPINumber, spiData);
    }

    xmlCreation(xmlData: any, Version: any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion+"/" + constant.POST_ERX_XMLCreation;
        } else {
            url =  constant.POST_ERX_XMLCreation;
        }
        return this._http.post<any>(url, xmlData);
    }

    refreshSpi(npinumber, prescriberId, Version: any) {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.GET_Spi_Refresh.replace("{npinumber}", "" + npinumber)
            .replace("{prescriberId}", "" + prescriberId);
        } else {
            url = constant.GET_Spi_Refresh.replace("{npinumber}", "" + npinumber)
            .replace("{prescriberId}", "" + prescriberId);
        }
        return this._http.get<any>(url);
    }

    SyncPrescriberSPI(npinumber,SPI, prescriberId, Version: any) {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.GET_Sync_Prescriber_SPI.replace("{npinumber}", "" + npinumber).replace("{SPI}", "" + SPI)
            .replace("{prescriberId}", "" + prescriberId);
        } else {
            url = constant.GET_Sync_Prescriber_SPI.replace("{npinumber}", "" + npinumber).replace("{SPI}", "" + SPI)
            .replace("{prescriberId}", "" + prescriberId);
        }
        return this._http.get<any>(url);
    }

    changeRequest(data: any, Version: any) {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.POST_ERX_ChangeReq;
        } else {
            url =  constant.POST_ERX_ChangeReq;
        }
        return this._http.post<any>(url, data);
    }

    checkCompoundDrugValues(drugId: any, prescRefillId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERx_CheckCompoundDrugValues.replace("{drugId}", "" + drugId).replace("{prescRefillId}", "" + prescRefillId)
        );
    }
}
