import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  getLocalStorage(key) {
        return localStorage.getItem(key);
  }
  removedLocalStorage(key) {
    sessionStorage.removeItem(key);
  }
  setSessionStorage(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  getSessionStorage(key) {
    return sessionStorage.getItem(key);
  }
  removedSessionStorage(key) {
    sessionStorage.removeItem(key);
  }
}
