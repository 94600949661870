import { AuditLogUtils } from './../../../utils/audit-log.util';
import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    HostListener,
    Output,
    EventEmitter,
    Input,
    ViewChild
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormControl,
    FormArray,
    Form
} from "@angular/forms";
import {
  SystemSetting,
  SystemData,
  KeyCodes,
  EPatient,
} from "src/app/models";
import { CommonService, AlertService, RxService, LoaderService } from "../../../services";
import * as moment from "moment";
import {
    NgbModal,
    NgbTimepickerConfig,
    NgbAccordionConfig,
    NgbPanelChangeEvent,
    NgbNavChangeEvent,
    NgbActiveModal
} from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { MsgConfig } from "../../../app.messages";
import { RxUtils } from 'src/app/utils';
import { RegEx } from 'src/app/app.regex';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { RxStore } from 'src/app/store';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
  selector: 'app-hoa',
  templateUrl: './hoa.component.html',
  styleUrls: ['./hoa.component.scss']
})
export class HoaComponent implements OnInit, OnDestroy {
  
  @ViewChild("HOAPopUp", { static : true })
  HOAPopUp : any;

  doseBase: string[] = ["Daily Fraction","Specific Time","Custom"];
  rxPatientModel: EPatient;
  rxFG: FormGroup;
  drugInfo: any
  InsuFiltersFrmGrp: FormGroup;
  isDataVisible: boolean = false;
  systemData: SystemData;
  openFrom: string;
  rxtype: string;
  rxInfo: any;
  hasRxData = false;
  reptDays: number[];
  prescRefillIds = [];
  dosagPerDay: number[];
  rxAdmin: any;
  Count = 0;
  today: any;
  arr: any = [];
  rptDays = 0;
  dosePe = 0;
  list: any = [];
  doseCols: any = [];
  dataArr: any = [];
  timeFractions: any = [];
  TotalRxInfo: any[] = [];
  specificTimeArr: any[] = [];
  specificTimeObj: any;
  dayFractnObj: any;
  dayFractnObjPreview: any;
  currentPrescNum:any;
  currentRefillNum:any;
  dates: any= [];
  formSubmitted = false;
  displayDtPckr = false;
  dosageSlot: any[] = [];
  mrngDosageSlot: any[] = [];
  noonDosageSlot: any[] = [];
  evngDosageSlot: any[] = [];
  nightDosageSlot: any[] = [];
  IsEditMode:boolean;
  isPrn:boolean;
  isChanged:boolean = false;
  fromCust:boolean = false;
  saveHoaArr: any[] = [];
  selectedRxList: any[]= [];
  hoaList: any[] = [];
  prescId:any;
  CreatedDtTm: Date = null;
  Id:number = 0;
  oldDose:number = 0;
  formatType:string = "daily"
  unsubscribe$: Subject<void> = new Subject<void>();
  utcMoment:any;
  hoaBatchEditList: any[] = [];
  prescObj:any = {};
  isFirstRec:boolean = true;
  endDateChanged:boolean = false;
  modelRef : any;
  subscriptions = new Subscription();
  specificTimeArrLsthan5: any[]=[]
    @Input()
    set rxfg(pi: any) {
        this.rxFG = pi;
    }

    @Input()
    set OpenFrom(of: string) {
        this.openFrom = of;
    }

    @Input()
    set rxType(ty: string) {
        this.rxtype = ty;
    }

    @Input()
    set IsPrn(ty: boolean) {
        this.isPrn = ty;
    }
    @Input() isFromPkg: boolean;
    @Input() IsFromPkgPreview: boolean;
    @Input() updatedSupDays: any;
    @Input() oldSupDays: any;
    @Input() oldSupDaysOthr : any;

    @Input()
    set RxInfo(ri: any) {
        if (ri && ri.length > 0) {
            this.TotalRxInfo = ri;
            this.rxInfo = ri[0];
        } else {
            this.TotalRxInfo = [];
            this.rxInfo = ri ? ri : [];
        }
    }
    @Output()
    CloseAdminRxPopUp = new EventEmitter<{}>();
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this.activeModal) { // esc
            this.resetFG();
            event.preventDefault();
        }
    }
  constructor( 
    public activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private _rxServc: RxService,
    private _modalService: NgbModal,
    public _commonServ: CommonService,
    private _cdr: ChangeDetectorRef,
    private _alertService: AlertService,
    ngbTimePickerconfig: NgbTimepickerConfig,
    ngbAccconfig: NgbAccordionConfig,
    private _route: ActivatedRoute,
    private _auditUtils: AuditLogUtils,
    private _router: Router,
    private _rxUtils: RxUtils,
    public loaderService: LoaderService,
    public _formatsUtil:FormatsUtil,
    private _rxStore:RxStore) { 
    this.today = new Date();
    this.utcMoment = moment.utc();
    this.createFG();
  }

  ngOnInit() {
    if(!this.IsFromPkgPreview)
        this.modelRef  = this._modalService.open(this.HOAPopUp, {keyboard : false, backdrop: false, centered : true , windowClass: !this.isFromPkg ? "max-modal-1450" : "max-modal-1485"})
    this.isDataVisible =  false;
    this.loaderService.display(true);
    const hoaChangePopup =  this.rxFG && this.rxFG.controls["HoaFrmBilling"] && this.rxFG.controls["HoaFrmBilling"].value ? this.rxFG.controls["HoaFrmBilling"].value : null;
    this.hoaBatchEditList = [];
    this.hasRxData = true;
    this.rxPatientModel = this.rxFG ? this.rxFG.get("Patient").value : null;
    this.drugInfo = this.rxFG? this.rxFG.get("Drug").value : null;
    this.generateTimeLines();
    if ((this.rxtype === "er" && !hoaChangePopup) || this.IsFromPkgPreview || this.openFrom === 'pfg' || this.openFrom === "rph") {
        this.rxInfo = (!this.isFromPkg && this.rxFG) ? this.rxFG.getRawValue() : this.rxInfo;
        this.IsEditMode = true;
        this.rxInfo.PrescReFill.SupplyDays = this.oldSupDaysOthr ? this.oldSupDaysOthr : this.rxInfo.PrescReFill.SupplyDays;
        this.getDoseSchedule(this.rxInfo);
    } else if(((this.rxFG && this.rxtype !== "er" || this.openFrom === "qrf" || (this.oldSupDays && this.updatedSupDays && this.oldSupDays !== this.updatedSupDays)) || hoaChangePopup)){
        this.rxInfo = this.rxFG.getRawValue();
        this.defaultPatchings();
        this.formatType = this.DosePerDay.value === 4 ? "daily" : "specific";
        const DosePerDay = this.rxFG.value?.IsHOAPreviewed ? this.rxFG.value['HOADetails'].DosePerDay : (this.DosePerDay.value ? this.DosePerDay.value : 4);
        this.InsuFiltersFrmGrp.controls["DosePerDay"].patchValue(DosePerDay);
        this.generateDoseCols(this.DosePerDay.value ? this.DosePerDay.value : 4);
        this.generateInitData((this.DosePerDay.value ? this.DosePerDay.value : 4), ((this.DosePerDay.value === 4) ?'hoa1' : ''), false);
        this.optedRepeatDays(this.RepeatDays.value);
        if(this.rxFG.value?.IsHOAPreviewed) {
          this.InsuFiltersFrmGrp.patchValue(this.rxFG?.value?.HOADetails);
        }
    } else {
        this.getDoseSchedule(this.rxInfo);
        this.IsEditMode = true;
    }
    this._commonServ.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      resp => (this.systemData = resp)
    );
  }
  get DosePerDay() {
    return this.InsuFiltersFrmGrp.get("DosePerDay") as FormControl;
  };
  get RepeatDays() {
    return this.InsuFiltersFrmGrp.get("RepeatDays") as FormControl;
  };
  get FormatId() {
    return this.InsuFiltersFrmGrp.get("FormatId") as FormControl;
  };
  get StartDate() {
    return this.InsuFiltersFrmGrp.get("StartDate") as FormControl;
  };
  get EndDate() {
    return this.InsuFiltersFrmGrp.get("EndDate") as FormControl;
  };
  get HOAAJSONModel() {
    return this.InsuFiltersFrmGrp.get("HOAAJSONModel") as FormArray;
  };
  getDoseDetails(form) {
    return form.get('doseDetails').controls;
  }
  getDate(form) {
    return form.get('date').value;
  }
  getDoseDetailsValue(form) {
    return form.get('doseDetails').value;
  }
  // get PrescReFill(): FormGroup {
  //   return this.rxFG.get("PrescReFill") as FormGroup;
  // }

  onDateSelect(val,type){
    let supDays =
    this.rxInfo.PrescReFill.SupplyDays &&
      parseInt(this.rxInfo.PrescReFill.SupplyDays, 0) !== 0
      ? this.rxInfo.PrescReFill.SupplyDays - 1
      : 0;
    this.rptDays = this.RepeatDays.value;
    this.dosePe = this.DosePerDay.value;
    if(type === "startdate"){
      if(this.rptDays){
        let stDate = this.StartDate.value ? moment(this.StartDate.value).format():moment().format();
        let endDate = moment(stDate).add('days', (this.rptDays* supDays)).format();
        //let utcendDate = moment.utc(endDate);
        this.InsuFiltersFrmGrp.patchValue({
          StartDate:stDate,
          EndDate: endDate,
        })
        this.dates = this.getDatesBetween(stDate, endDate, this.rptDays);
        if (this.HOAAJSONModel.value && this.HOAAJSONModel.value.length > 0 && (this.HOAAJSONModel.value.length === this.dates.length)) {
          this.changeDates();
        }
        else if (this.dataArr && this.dataArr.length > 0 && (this.dosePe > 0 || this.DosePerDay.value > 0)) {
          this.generateGridData();
        } else {
          this.generateDataArray();
          this.Count = 0;
          this.HOAAJSONModel.clear();
          this.generateGridData();
        }
      }
    } else if(type === "enddate"){
      this.endDateChanged = true;
      let stDate = this.StartDate.value ? moment(this.StartDate.value).format():moment().format();
      let endDate = this.EndDate.value ? moment(this.EndDate.value).format().split('T')[0]+'T'+moment().format().split('T')[1] : moment().format();
      this.InsuFiltersFrmGrp.patchValue({
        StartDate:stDate,
        EndDate: endDate,
      });
      //this.getDateDiff(stDate,endDate)
       this.dates = this.getDatesBetween(stDate, endDate, this.rptDays);
      if (this.dataArr && this.dataArr.length > 0 && (this.dosePe > 0 || this.DosePerDay.value > 0)) {
        this.Count = 0;
        this.HOAAJSONModel.clear();
        this.generateGridData();
      }
      else{
        this.generateDataArray();
        this.Count = 0;
        this.HOAAJSONModel.clear();
        this.generateGridData();
      }

    }

  }
  closeHoaPopUp() {
    if(this.modelRef) {
      this.modelRef.close();
      this.modelRef = null;
    }
    if(this.isFromPkg)
        this.CloseAdminRxPopUp.emit();
  }
  onCustDateSelect(data,date){
    data.patchValue({
      date : moment(date).format()
    })
  }
  optedDosePerDay(value) {
    this.dosePe = this.FormatId.value === 1 ?  parseInt("4", 10) :  parseInt(value, 10);
    this.generateDoseCols(this.dosePe);
    this.generateDataArray();
    if (this.FormatId.value !== 1) {
      if(this.IsEditMode && (this.dosePe >= this.oldDose)){
        this.isChanged = false;
        let toAdd = Math.abs(this.dosePe - this.oldDose);
        this.oldDose = this.DosePerDay.value;
       // this.generateInitData(this.dosePe, false);
       let doseLength = this.HOAAJSONModel?.value?.[0]?.['doseDetails']?.length;
       let hoaarr =  this.HOAAJSONModel.value;
       this.HOAAJSONModel.clear();
        hoaarr.forEach(x => {
          if (this.dosePe >= 5)
            x.doseDetails = []
          this.addDoseSchedule(x,toAdd,doseLength);
          this.addDoseScheduleToArray(x);
        })
      } else if(this.IsEditMode && (this.dosePe < this.oldDose)){
        this.isChanged = false;
        let toDel = Math.abs(this.dosePe - this.oldDose);
        this.oldDose = this.DosePerDay.value;
        let hoaarr =  this.HOAAJSONModel.value;
        this.HOAAJSONModel.clear();
        hoaarr.forEach(x => {
          if (this.dosePe < 5) {
            let doseLength = this.HOAAJSONModel?.value?.[0]?.['doseDetails']?.length;
            this.addDoseSchedule(x,toDel,doseLength);
          } else {
            this.removeDoseSchedule(x,toDel);
          }
          
          this.addDoseScheduleToArray(x);
        })
      }
      else{
        this.Count = 0;
        this.HOAAJSONModel.clear();
        this.generateInitData(this.dosePe, false);
        this.generateGridData();
      }
    }
    else if (this.FormatId.value === 1 && this.dataArr && this.dataArr.length > 0 && this.HOAAJSONModel.value.length == 0) {
      this.Count = 0;
      this.generateGridData();
    }
  }
  optedRepeatDays(value){
    this.generateDates(value)
    if(this.HOAAJSONModel.value && this.HOAAJSONModel.value.length >0 && !this.endDateChanged){
      this.changeDates();
    }
    else if(this.dataArr && this.dataArr.length>0 && (this.dosePe > 0 || this.DosePerDay.value > 0)){
        this.HOAAJSONModel.clear();
        this.generateGridData();
    }
}

generateDates(value){
  let supDays =
  this.rxInfo.PrescReFill.SupplyDays &&
    parseInt(this.rxInfo.PrescReFill.SupplyDays, 0) !== 0
    ? this.rxInfo.PrescReFill.SupplyDays - 1
    : 0;
  this.Count = 0;
  //this.HOAAJSONModel.clear();
  this.rptDays = parseInt(value, 10);
  let stDate = this.StartDate.value ?moment(this.StartDate.value).format():moment().format();
  //let utcStDate = moment.utc(stDate);
  let endDate = this.endDateChanged ?this.EndDate.value :moment(stDate).add('days', (this.rptDays* supDays)).format();
  //let utcendDate = moment.utc(endDate);
  this.InsuFiltersFrmGrp.patchValue({
    StartDate:stDate,
    EndDate: endDate,
  });
  this.dates = this.getDatesBetween(stDate, endDate, this.rptDays);
}
  getDoseBase(val){
    this._cdr.detectChanges();
    this.fromCust = false;
    this.oldDose = this.DosePerDay.value ? this.DosePerDay.value : 0;
    if(val === "hoa1"){
      this.formatType = "daily"
      //populate day fraction data
      this.InsuFiltersFrmGrp.patchValue({
        DosePerDay: 4,
      });
      this.HOAAJSONModel.clear();
      this.generateDoseCols(4);
      this.generateInitData(4, val, true);
    }
    else{
      this.formatType = val === "hoa2"?"specific": "custom";
      if(this.formatType === "custom"){
        this.displayDtPckr = true;
      } else if(this.formatType === "specific"){
        let supDays =
          this.rxInfo.PrescReFill.SupplyDays &&
            parseInt(this.rxInfo.PrescReFill.SupplyDays, 0) !== 0
            ? this.rxInfo.PrescReFill.SupplyDays
            : 0;
        const dispQty =
          this.rxInfo.PrescReFill.DispQty &&
            (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 1)
            ? this.rxInfo.PrescReFill.DispQty
            : (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 0.5 ? 0.5 : 0);
        const dosePerDay = Math.ceil( Math.ceil(dispQty) / supDays);
        this.oldDose = dosePerDay;
        this.InsuFiltersFrmGrp.patchValue({
          DosePerDay: dosePerDay
        }) ; 
      }
      //populate specific time data and custom data
      this.generateDoseCols(this.InsuFiltersFrmGrp.controls.DosePerDay.value);
      this.generateInitData(this.InsuFiltersFrmGrp.controls.DosePerDay.value,val,true);
    } 
  }
  createFG(){
    this.InsuFiltersFrmGrp = this._fb.group({
      DosePerDay: null,
      RepeatDays:null,
      FormatId:null,
      StartDate:null,
      EndDate:null,
      HOAAJSONModel: this._fb.array([]),
  });
  }
  defaultPatchings(){
    let supDays =
      this.rxInfo.PrescReFill.SupplyDays &&
        parseInt(this.rxInfo.PrescReFill.SupplyDays, 0) !== 0
        ? this.rxInfo.PrescReFill.SupplyDays
        : 0;
    const dispQty =
      this.rxInfo.PrescReFill.DispQty && 
        (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 1)
        ? this.rxInfo.PrescReFill.DispQty
        : (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 0.5 ? 0.5 : 0);  
    const dosePerDay = Math.ceil( Math.ceil(dispQty) / supDays);
    const endDate = this.addDays(this.today,((supDays-1)*1));
    let utcendDate = moment.utc(endDate);
    this.InsuFiltersFrmGrp = this._fb.group({
      DosePerDay: dosePerDay,
      RepeatDays: 1,
      FormatId: (dosePerDay === 4) ? 1 :2,
      StartDate: this.utcMoment.local().format(),
      EndDate:  utcendDate.local().format(),
      HOAAJSONModel: this._fb.array([]),
  });
    
   
  }
  generateDoseCols(val,frmtId?){
    this.doseCols = [];
    if (this.FormatId.value && this.FormatId.value !== 1) {
      for (let i = 0; i < val; i++) {
        this.doseCols.push({ Id: i, name: "Time & Dosage" + (i + 1).toString() });
      }
    }
    else {
      this.doseCols.push({ Id: 0, name: "Time & Dosage 1", fraction: "Morning" }, { Id: 1, name: "Time & Dosage 2", fraction: "Noon" }, { Id: 2, name: "Time & Dosage 3", fraction: "Evening" }
        , { Id: 1, name: "Time & Dosage 4", fraction: "Bed Time" });
    }
}
generateInitData(dosePe,frac?,isSwitched?){
  if(this.IsEditMode){
    this.generateDates(this.RepeatDays.value)
  }
  if(dosePe && frac === "hoa1"){
      this.dataArr = [];
      for(let i=0; i<this.dayFractnObj.length;i++){
          this.dataArr.push(this.dayFractnObj[i]);
      }
  }
  else{
      this.dataArr = [];
      let specificTimeObj;
      for (let i = 0; i < dosePe; i++) {
        if (dosePe < 5) {
          this.dataArr = [];
          for (let j = 0; j < this.specificTimeArrLsthan5[i].length; j++) {
            specificTimeObj = {
              "doseTime": this.specificTimeArrLsthan5[i][j],
              "doseQty": "1.000"
            };
            this.dataArr.push(specificTimeObj);
          }
        } else {
          specificTimeObj = {
            "doseTime": this.specificTimeArr[i],
            "doseQty": "1.000"
          };
          this.dataArr.push(specificTimeObj);
        }
        
      }
        // for(let i=0; i<dosePe; i++){
        //   const specificTimeObj = {
        //     "doseTime": this.specificTimeArr[i],
        //     "doseQty": "1.000"
        //   };
        //   this.dataArr.push(specificTimeObj);
        // }
  }
  if(isSwitched){
      this.Count = 0;
      this.HOAAJSONModel.clear();
      this.generateGridData();
  }
}
  generateGridData() {
    this.isChanged = true;
    this.arr = [];
    if (this.dates.length > this.Count && (this.FormatId.value === 1 || this.FormatId.value === 2)) {
      while (this.dates.length > this.Count) {
        this.addDoseScheduleToArray();
      }
      this.hoaBatchEditList.push({...this.InsuFiltersFrmGrp.value, ...this.prescObj});
    }
    else{
      this.addDoseScheduleToArray();
    }
  }
  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  getDatesBetween(startDate, endDate, repeatDays) {
    let currentDate = startDate;
    const dates = [];
    while (moment(currentDate).isValid() && moment(endDate).isValid() && currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = moment(currentDate).add(repeatDays,'days').format();
    }
    return dates;
  }
  addDoseScheduleToArray(data?) {
    this.Count++;
    if(this.dates.length == this.Count) {
      this.loaderService.display(false);
      this. isDataVisible = true;
    }
    if(this.IsEditMode && this.fromCust){
      let doseDched = this._fb.group({
        date: moment(data?.date).add("days",1).format(),
        doseDetails: this._fb.array(this.buildArrayControl(data?.doseDetails ? data.doseDetails : null))
      })
      this.arr.push(doseDched);
      this.HOAAJSONModel.push(doseDched);
    }
    else if(this.IsEditMode && !this.isChanged){
      let doseDched = this._fb.group({
        date: data?.date,
        doseDetails: this._fb.array(this.buildArrayControl(data?.doseDetails ? data.doseDetails : null))
      })
      this.arr.push(doseDched);
      this.HOAAJSONModel.push(doseDched);
    }
    else{
      let doseDched = this._fb.group({
        date: this.dates[this.Count - 1],
        doseDetails: this._fb.array(this.buildArrayControl(data ? data.doseDetails : (this.dataArr ? this.dataArr : null)))
      })
      this.arr.push(doseDched);
      this.HOAAJSONModel.push(doseDched);
    }
    // this.hoaBatchEditList.push(this.HOAAJSONModel)

  }
  buildArrayControl(data): FormGroup[] {
    return data ?
      data.map(x => {
        return this._fb.group({
          doseTime: [x.doseTime ? x.doseTime : null],
          doseQty: [x.doseQty ? x.doseQty : null],
          doseTimeForPrev: [x.doseTimeForPrev ? x.doseTimeForPrev : null]
        })
      }) :
      [this._fb.group({
        doseTime: [null],
        doseQty: [null]
      })]

  }
  onCustomDateSelect(value,fg?){
    if(value){
      this.displayDtPckr = false;
      let doseDched = this._fb.group({
        date: null,
        doseDetails: this._fb.array(this.buildArrayControl(this.dataArr ? this.dataArr : null))
      })
      this.HOAAJSONModel.push(doseDched);
    }
  }
  changeCustomDate(){
    this.displayDtPckr = true;
  }
  resetFG(){
    this.InsuFiltersFrmGrp.reset();
    if(!this.IsEditMode){
      this.rxFG.patchValue({
        HOADetails:null,
      })
    }
    if (this.activeModal) {
      this.activeModal.close();
    }
    this.CloseAdminRxPopUp.emit();
    
  }
  closeRxSchdModal(){
    if(this.isFromPkg){
      let ind = this.hoaBatchEditList.findIndex(x=> {return x.PrescRefillId ===  this.rxInfo.PrescReFill.Id});
      if(ind>=0){
        this.hoaBatchEditList[ind] = {...this.InsuFiltersFrmGrp.value, ...this.prescObj}
      }
      this.hoaBatchEditList.forEach(x=> {
        if(x.HOAAJSONModel && x.HOAAJSONModel.length){
          let sum = this.calculateTotalSum(x.HOAAJSONModel);
          if (sum > 0) {
            this.saveHoaArr.push({ ...x });
          }
        }
      })
      this.subscriptions.add(this._rxServc.saveHoaDeatils(this.saveHoaArr && this.saveHoaArr.length>0 ? this.saveHoaArr : [])
      .subscribe(resp=> {
        if(resp){
          //this.CloseAdminRxPopUp.emit();
          this.closeModal(true);
          if (this.subscriptions) {
            this.subscriptions.unsubscribe()
            this.subscriptions = new Subscription();
          }
        }
      }))
    }
    else if(this.IsEditMode && (this.rxtype === "er" || this.openFrom === "rph" || this.openFrom === "pfg")){
      let prescObj = {
        "PrescRefillId": this.prescId? this.prescId : this.rxInfo.PrescReFill.Id,
        "CreatedDtTm": this.CreatedDtTm ? this.CreatedDtTm : null,
        "Id": this.Id ? this.Id : 0,
        "PrescNum": (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum) ? this.rxInfo.Prescription.PrescNum : null,
        "RefillNum":  (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.ReFillNum : null,
        "FillNo": (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.PartialFillNo : null
      }
      this.saveHoaArr.push({...this.InsuFiltersFrmGrp.value, ...prescObj });
      if(this.InsuFiltersFrmGrp.value && this.InsuFiltersFrmGrp.value.HOAAJSONModel.length){
        let sum = this.calculateTotalSum(this.InsuFiltersFrmGrp.value.HOAAJSONModel);
       if(sum >0 ){
        this._rxServc.saveHoaDeatils(this.saveHoaArr && this.saveHoaArr.length>0 ? this.saveHoaArr : []).subscribe(resp=> {
          if(resp){
            this.closeModal(true);
          }
        })
       } else {
        this.closeModal();
       }
      } else {
        this.closeModal();
      }
    }
    else if(this.rxtype === "rf" || this.rxtype === "nr" || this.rxType === "nrf"){
      if(this.InsuFiltersFrmGrp.value && this.InsuFiltersFrmGrp.value.HOAAJSONModel.length){
        let sum = this.calculateTotalSum(this.InsuFiltersFrmGrp.value.HOAAJSONModel);
      //   let sum = this.InsuFiltersFrmGrp.value.HOAAJSONModel.reduce((acc,cart)=>{
      //     return acc + cart.doseDetails.reduce((acc, doseDetails) => {
      //     return acc + parseFloat(doseDetails.doseQty)
      //  },0)
      //  },0)
       if(sum > 0) {
        this.rxFG.patchValue({
          HOADetails: this.InsuFiltersFrmGrp.value,
        })
        this.closeModal(true);
       } else {
        this.closeModal(true);
       }
      } else {
        this.closeModal(true);
      }
      this.hoaBatchEditList = [];
    }
    else {
      this.closeModal(this.InsuFiltersFrmGrp.value);
    }
  }

  timelineLabels(desiredStartTime, interval, period,type) {
    if(type === "all"){
      this.dosageSlot = [];
      const periodsInADay = moment.duration(1, "day").as(period);
      let startTimeMoment = moment(desiredStartTime, "hh:mm");
      for (let i = 0; i < periodsInADay; i += parseInt(interval, 0)) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        this.dosageSlot.push({ "doseSlotTime": startTimeMoment.format("hh:mm A"), "Id": startTimeMoment.format("HH:mm:ss") });
    }
    }
    else if(type === "m"){
      this.mrngDosageSlot = [];
      const periodsInADay = moment.duration(0.28, "day").as(period);
      let startTimeMoment = moment("05:00", "hh:mm");
      for (let i = 0; i < periodsInADay; i += parseInt(interval, 0)) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        this.mrngDosageSlot.push({ "doseSlotTime": startTimeMoment.format("hh:mm A"), "Id": startTimeMoment.format("HH:mm:ss") });
    }
    }
    else if(type === "n"){
      this.noonDosageSlot = [];
      const periodsInADay = moment.duration(0.2, "day").as(period);
      let startTimeMoment = moment("12:00", "hh:mm");
      for (let i = 0; i < periodsInADay; i += parseInt(interval, 0)) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        this.noonDosageSlot.push({ "doseSlotTime": startTimeMoment.format("hh:mm A"), "Id": startTimeMoment.format("HH:mm:ss") });
    }
    }
    else if(type === "e"){
      this.evngDosageSlot = [];
      const periodsInADay = moment.duration(0.15, "day").as(period);
      let startTimeMoment = moment("17:00", "hh:mm");
      for (let i = 0; i < periodsInADay; i += parseInt(interval, 0)) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        this.evngDosageSlot.push({ "doseSlotTime": startTimeMoment.format("hh:mm A"), "Id": startTimeMoment.format("HH:mm:ss") });
    }
    }
    else{
      this.nightDosageSlot = [];
      const periodsInADay = moment.duration(0.12, "day").as(period);
      let startTimeMoment = moment("21:00", "hh:mm");
      for (let i = 0; i < periodsInADay; i += parseInt(interval, 0)) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        this.nightDosageSlot.push({ "doseSlotTime": startTimeMoment.format("hh:mm A"), "Id": startTimeMoment.format("HH:mm:ss") });
    }
    }

}


removeDoseSchedule(sched:any,todel){
  for(let i=0; i<todel; i++){
    sched.doseDetails.pop();
  }
}

addDoseSchedule(sched:any,toAdd,idx?){
  this.dataArr = [];
  let specificTimeObj;
  for (let i = 0; i < this.dosePe; i++) {
      if (this.dosePe < 5) {
        sched.doseDetails = [];
        for (let j = 0; j < this.specificTimeArrLsthan5[i].length; j++) {
          specificTimeObj = {
            "doseTime": this.specificTimeArrLsthan5[i][j],
            "doseQty": "1.000"
          };
          sched.doseDetails.push(specificTimeObj);
        }
      } else {
        specificTimeObj = {
          "doseTime": this.specificTimeArr[i],
          "doseQty": "1.000"
        };
        sched.doseDetails.push(specificTimeObj);
      }
        
    }
  // for(let i=0;i<toAdd;i++){
  //   const specificTimeObj = {
  //     "doseTime": this.specificTimeArr[idx + i],
  //     "doseQty": "1.000"
  //   };
  //   sched.doseDetails.push(specificTimeObj);
  // }
}

deleteDoseSched(ind){
  if (this.HOAAJSONModel.length > 1)
    this.HOAAJSONModel.removeAt(ind);
  else 
    this._alertService.warning("Unable to delete all records. Please maintain at least one.");
}

changeDates(){
  for(let i=0; i<this.dates.length; i++){
    this.HOAAJSONModel?.at(i).patchValue({date: this.dates[i]});
  }
}

addCustomSched(){
  let hoaarr =  this.HOAAJSONModel.value;
  if(hoaarr && hoaarr.length){
    this.isChanged = false;
    this.fromCust = true;
    this.addDoseScheduleToArray(hoaarr[hoaarr.length - 1]);
  }else{
    this.Count = 0;
    this.isChanged = true;
    this.addDoseScheduleToArray()
  }
}
addDoseScheduleToArraybyDetails(sched:any){
  this.Count++;
  this.dates.push(sched.date);
  let doseDched = this._fb.group({
    date: sched.date,
    doseDetails: this._fb.array(this.buildArrayControl(sched.doseDetails ? sched.doseDetails : null))
  })
  this.HOAAJSONModel.push(doseDched);
}
keytab(fg?: any, fc?: string, index: number = 0, subIndex: number = 0){
  if (fc === "doseQty") {
    this.setNumberFormat(fg,"doseQty",index, subIndex);
}
}
setNumberFormat(fg,ControlName,index, subIndex) {
  if(!index) {
      this.HOAAJSONModel.value.map(x => {
        x.doseDetails[subIndex].doseQty = parseFloat(fg.controls["doseQty"].value).toFixed(3)
      })
  }
  let hoaarr =  this.HOAAJSONModel.value;
  this.HOAAJSONModel.clear();
  this.Count = 0;
  this.isChanged = false;
   hoaarr.forEach(x => {
     //this.addDoseSchedule(x,toAdd,doseLength);
     this.addDoseScheduleToArray(x);
   })
  fg.controls[ControlName].patchValue(
      (parseFloat(fg.controls["doseQty"].value)).toFixed(3)
  );
  // await this.doPriceCalculation();
}

setTimeToAllRows(fg,ControlName,selectedIndex, subIndex){
  if(!selectedIndex) {
    this.HOAAJSONModel.value.map((x,index) => {
      x.doseDetails[subIndex].doseTime  = fg.controls["doseTime"].value
    })
  }
  let hoaarr =  this.HOAAJSONModel.value;
  this.HOAAJSONModel.clear();
  this.Count = 0;
  this.isChanged = false;
   hoaarr.forEach(x => {
     this.addDoseScheduleToArray(x);
   })
}

getDoseSchedule(rxInfo){
  //Make api call to fetch existing dose schedule
  if(this.isFromPkg){
    if(this.TotalRxInfo && this.TotalRxInfo.length>0){
      this.TotalRxInfo.forEach(x => {
        this.prescRefillIds.push(x.PrescReFill.Id);
        this.selectedRxList.push(x.Prescription.PrescNum);
      })
    }
  }
  else if(rxInfo && rxInfo.PrescReFill && rxInfo.PrescReFill.Id){
    this.prescRefillIds.push(rxInfo.PrescReFill.Id);
  }
  this.subscriptions.add(this._rxServc.getHoaDetailsInfo(this.prescRefillIds).subscribe(resp=>{
  if(resp && resp.length == 0){
    this.generateDailyFracData();
  }
  else if(resp) {
    this.hoaList = resp;
    let data = this.hoaList && this.hoaList.length && this.hoaList.filter(x=> {
      return x.PrescRefillId === this.prescRefillIds[0]
    })
    data && data.length>0 ? this.generateHoaData(data[0]):this.generateDailyFracData()
    
  } else {
    this.hoaList = [];
  }
  if (this.subscriptions) {
    this.subscriptions.unsubscribe()
    this.subscriptions = new Subscription();
  }
 }))

}
generateDailyFracData(){
  let supDays =
    this.rxInfo.PrescReFill.SupplyDays &&
      parseInt(this.rxInfo.PrescReFill.SupplyDays, 0) !== 0
      ? this.rxInfo.PrescReFill.SupplyDays
      : 0;
  const dispQty =
    this.rxInfo.PrescReFill.DispQty && 
      (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 1)
      ? this.rxInfo.PrescReFill.DispQty
      : (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 0.5 ? 0.5 : 0);
  const dosePerDay = Math.ceil( Math.ceil(dispQty) / supDays);
  this.oldDose = dosePerDay;
  this.formatType = dosePerDay === 4 ? "daily" : "specific";
  this.isChanged = true;
  let today = moment().format();
  //this.defaultPatchings();
  if(this.isFromPkg){
    this.rxInfo = this.TotalRxInfo[0];
    this.currentPrescNum = (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum) ? this.rxInfo.Prescription.PrescNum : null
    this.currentRefillNum = (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.ReFillNum : null,
    this.prescObj = {
      "PrescRefillId": this.rxInfo.PrescReFill.Id? this.rxInfo.PrescReFill.Id : null,
      "CreatedDtTm": this.CreatedDtTm ? this.CreatedDtTm : null,
      "Id": this.Id ? this.Id : 0,
      "PrescNum": (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum) ? this.rxInfo.Prescription.PrescNum : null,
      "RefillNum":  (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.ReFillNum : null,
      "FillNo": (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.PartialFillNo : null
    }
  }
  this.InsuFiltersFrmGrp.patchValue({
    DosePerDay: dosePerDay,
    RepeatDays: 1,
    StartDate: today,
    FormatId: (dosePerDay === 4) ? 1 :2
});
  this.generateDoseCols(dosePerDay);
  this.generateInitData(dosePerDay, ((dosePerDay === 4) ?'hoa1' : ''), false);
  this.optedRepeatDays(this.RepeatDays.value);
  //this.loaderService.display(false)
}
generateHoaData(value){
  this.formatType = (value.FormatId === 2) ? "specific" : (value.FormatId === 1 ? "daily" : "custom");
  this.prescId =value.PrescRefillId;
  this.CreatedDtTm = value.CreatedDtTm;
  this.Id = value.Id;
  this.oldDose = value.DosePerDay;
  let data = value.HOAAJSONModel;
  this.InsuFiltersFrmGrp.patchValue({
    DosePerDay: value.DosePerDay,
    EndDate: value.EndDate,
    RepeatDays: value.RepeatDays,
    StartDate:value.StartDate,
    FormatId: value.FormatId
});
this.prescObj = {
  "PrescRefillId": this.prescId? this.prescId : this.rxInfo.PrescReFill.Id,
  "CreatedDtTm": this.CreatedDtTm ? this.CreatedDtTm : null,
  "Id": this.Id ? this.Id : 0,
  "PrescNum": (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum) ? this.rxInfo.Prescription.PrescNum : null,
  "RefillNum":  (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.ReFillNum : null,
  "FillNo": (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.PartialFillNo : null
}
this.generateDoseCols(value.DosePerDay);
if(data && data.length > 0){
  data.forEach(x => this.addDoseScheduleToArraybyDetails(x))
}
if(this.hoaBatchEditList.findIndex(x=> {return x.PrescRefillId ===  this.rxInfo.PrescReFill.Id}) < 0){
  this.hoaBatchEditList.push({...this.InsuFiltersFrmGrp.value, ...this.prescObj})
}
}

dispBatchEdit(item,idx){
  this.Id = 0;
  idx>0 ? (this.isFirstRec = false) : (this.isFirstRec)
  this.currentPrescNum = item.Prescription.PrescNum;
  this.currentRefillNum = item.PrescReFill.ReFillNum;

  if(this.hoaBatchEditList && this.hoaBatchEditList.length > 0){
    let prevInd = this.hoaBatchEditList.findIndex(x=> {return x.PrescRefillId ===  this.rxInfo.PrescReFill.Id});
    if(prevInd >= 0){
      this.hoaBatchEditList[prevInd] = {...this.InsuFiltersFrmGrp.value, ...this.prescObj};
      //this.generateHoaData(this.hoaBatchEditList[idx])
    }
      let resp = this.hoaList.filter(x =>{
        return x.PrescRefillId === item.PrescReFill.Id
      });
      let currInd = this.hoaBatchEditList.findIndex(x=> {return x.PrescRefillId ===  item.PrescReFill.Id});
      this.rxInfo =  this.TotalRxInfo[idx];
      this.HOAAJSONModel.clear();
      if(resp && resp.length == 0 && (currInd <0)){
        let supDays =
          this.rxInfo.PrescReFill.SupplyDays &&
            parseInt(this.rxInfo.PrescReFill.SupplyDays, 0) !== 0
            ? this.rxInfo.PrescReFill.SupplyDays
            : 0;
        const dispQty =
          this.rxInfo.PrescReFill.DispQty && 
          (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 1)
            ? this.rxInfo.PrescReFill.DispQty
            : (parseFloat(this.rxInfo.PrescReFill.DispQty) >= 0.5 ? 0.5 : 0);
        const dosePerDay = Math.ceil( Math.ceil(dispQty) / supDays);
        this.formatType = dosePerDay === 4 ? "daily" : "specific";
        this.isChanged = true;
        let today = moment().format();
        //this.defaultPatchings();
        this.prescObj = {
          "PrescRefillId": this.rxInfo.PrescReFill.Id? this.rxInfo.PrescReFill.Id : null,
          "CreatedDtTm": this.CreatedDtTm ? this.CreatedDtTm : null,
          "Id": this.Id ? this.Id : 0,
          "PrescNum": (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum) ? this.rxInfo.Prescription.PrescNum : null,
          "RefillNum":  (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.ReFillNum : null,
          "FillNo": (this.rxInfo && this.rxInfo.PrescReFill) ? this.rxInfo.PrescReFill.PartialFillNo : null
        }
        this.InsuFiltersFrmGrp.patchValue({
          DosePerDay: dosePerDay,
          RepeatDays: 1,
          StartDate: today,
          FormatId: (dosePerDay === 4) ? 1 :2
      });
        this.generateDoseCols(dosePerDay);
        this.generateInitData(dosePerDay,((dosePerDay === 4) ?'hoa1' : ''), false);
        this.optedRepeatDays(this.RepeatDays.value);
      }
      else if (resp && resp.length > 0 && (currInd <0)){
        this.generateHoaData(resp[0]);
      }
      else{
        this.generateHoaData(this.hoaBatchEditList[currInd])
      }
    
  }
}

generateTimeLines(){
  this.specificTimeObj = {
    "doseTime": "09:00:00",
    "doseQty": "1.000"
  };
  this.specificTimeArrLsthan5 = [["08:00:00"], [ "08:00:00", "18:00:00"], [ "08:00:00", "13:00:00", "18:00:00" ],[ "08:00:00", "13:00:00", "18:00:00", "21:00:00" ]]
  this.specificTimeArr = ["08:00:00","10:00:00","12:00:00","14:00:00","16:00:00","18:00:00","20:00:00","22:00:00","00:00:00","02:00:00"]
  this.dayFractnObjPreview = ["08:00AM", "1:00PM", "6:00PM", "9:00PM"];
  this.dayFractnObj = [{
    "doseTime": "08:00:00",
    "doseTimeForPrev": "08:00:00AM",
    "doseQty": "1.000"
  }, {
    "doseTime": "13:00:00",
    "doseTimeForPrev": "1:00:00PM",
    "doseQty": "1.000"
  }, {
    "doseTime": "18:00:00",
    "doseTimeForPrev": "6:00:00PM",
    "doseQty": "1.000"
  }, {
    "doseTime": "21:00:00",
    "doseTimeForPrev": "9:00:00PM",
    "doseQty": "1.000"
  }];
  this.reptDays = Array.from(Array(32).keys());
  this.reptDays.shift();
  this.dosagPerDay = Array.from(Array(11).keys());
  this.dosagPerDay.shift();
  this.timelineLabels("00:00", "30", "m","all");
  this.timelineLabels("05:00", "30", "m","m");
  this.timelineLabels("12:00", "30", "m","n");
  this.timelineLabels("17:00", "30", "m","e");
  this.timelineLabels("21:00", "30", "m","b");
}

editHoa(rxInfo){
  this.generateTimeLines();
  this.IsEditMode = true;
  this.getDoseSchedule(rxInfo);
}
generateDataArray(){
  if (this.dosePe && this.FormatId.value === 1) {
    this.dataArr = [];
    for (let i = 0; i < this.dayFractnObj.length; i++) {
      this.dataArr.push(this.dayFractnObj[i]);
    }
  }
  else {
    let specificTimeObj;
    this.dataArr = [];
    for (let i = 0; i < this.dosePe; i++) {
      if (this.dosePe < 5) {
        this.dataArr = [];
        for (let j = 0; j < this.specificTimeArrLsthan5[i].length; j++) {
          specificTimeObj = {
            "doseTime": this.specificTimeArrLsthan5[i][j],
            "doseQty": "1.000"
          };
          this.dataArr.push(specificTimeObj);
        }
      } else {
        specificTimeObj = {
          "doseTime": this.specificTimeArr[i],
          "doseQty": "1.000"
        };
        this.dataArr.push(specificTimeObj);
      }
      
    }
  }
}

calculateTotalSum(arr){
  return arr.reduce((acc,cart)=>{
    return acc + cart.doseDetails.reduce((acc, doseDetails) => {
    return acc + parseFloat(doseDetails.doseQty)
 },0)
 },0)
}

closeModal(val?){
  if (this.activeModal) {
    this.activeModal.close();
  }
  this.CloseAdminRxPopUp.emit(val);
}
ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}

}
