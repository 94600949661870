import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    HostListener
} from "@angular/core";
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
    FormArray
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
    SystemSetting,
    SystemData,
    DosageSchedule,
    DosageSlotTime,
    ERxAdmin,
    EPatient,
    ERxDoseSchs
} from "src/app/models";
import { CommonService, RxService, AlertService } from "src/app/services";
import * as moment from "moment";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-admin-rx",
    templateUrl: "./admin-rx.component.html",
    styles: []
})
export class AdminRxComponent implements OnInit, OnChanges {
    rxFG: FormGroup;
    rxPatientModel: EPatient;
    drugInfo: any;
    systemSetting: SystemSetting;
    systemData: SystemData;
    reptDays: number[];
    dosagPerDay: number[];
    dosageSlot: any[];
    doseTimeDesc: string;
    openFrom: string;
    rxInfo: any;
    hasRxData = false;
    rxAdmin: any;
    unsubscribe$: Subject<void> = new Subject();

    // @Input()
    // set RxFormGroup(fg: any) {
    //     this.rxFG = fg;
    // }

    @Input()
    set RxPatientInfo(pi: EPatient) {
        this.rxPatientModel = pi;
    }

    @Input()
    set DrugInfo(di: any) {
        this.drugInfo = di;
    }

    @Input()
    set OpenFrom(of: string) {
        this.openFrom = of;
    }

    @Input()
    set RxInfo(ri: any) {
        this.rxInfo = ri;
        this.hasRxData = true;
    }

    @Output()
    CloseAdminRxPopUp = new EventEmitter<{}>();



    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27) { // esc
            event.preventDefault();
            this.resetFG();
        } else if (event.ctrlKey) {
            if (event.which === 89) { // y
                if (this.RxAdminDoseSchedule.valid) {
                    event.preventDefault();
                    this.closeRxSchdModal();
                }
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.resetFG();
            }
        }
    }


    constructor(
        public activeModal: NgbActiveModal,
        private _commonServ: CommonService,
        private _rxService: RxService,
        private _fb: FormBuilder,
        private _alertSvc: AlertService
    ) {
        this.rxFG = this._fb.group({
            RxAdminDoseSchedule: this._fb.group({
                RxAdmin: this._fb.group(new ERxAdmin()),
                RxDoseSchs: this._fb.array([])
            })
        });
    }

    get RxAdminDoseSchedule(): FormGroup {
        return this.rxFG.get("RxAdminDoseSchedule") as FormGroup;
    }

    get RxAdmin(): FormGroup {
        return this.rxFG.controls.RxAdminDoseSchedule.get(
            "RxAdmin"
        ) as FormGroup;
    }

    get RxDoseSchs(): FormArray {
        return this.rxFG.controls.RxAdminDoseSchedule.get(
            "RxDoseSchs"
        ) as FormArray;
    }

    ngOnInit() {
        this.reptDays = Array.from(Array(30).keys());
        this.dosagPerDay = Array.from(Array(12).keys());
        const timeInter = this._commonServ.getSetttingValue(
            "RxSettings",
            "DEF_DOSE_TIME_INTERVAL"
        );

        this.timelineLabels("00:00", timeInter, "m");

        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
            resp => (this.systemData = resp)
        );

        this._commonServ.systemSettings$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.RxSettings) {
                this.systemSetting = resp;
            }
        });
        if (this.rxInfo) {
            this.patchAdminRxDefaultValues();
        }
    }

    timelineLabels(desiredStartTime, interval, period) {
        const periodsInADay = moment.duration(1, "day").as(period);

        const startTimeMoment = moment(desiredStartTime, "hh:mm");
        this.dosageSlot = [];
        for (let i = 0; i < periodsInADay; i += parseInt(interval, 0)) {
            startTimeMoment.add(i === 0 ? 0 : interval, period);
            this.dosageSlot.push({ "doseSlotTime": startTimeMoment.format("hh:mm A") });
        }
    }

    ngOnChanges() {
        if (this.rxInfo) {
            this.patchAdminRxDefaultValues();
        }
    }

    patchAdminRxDefaultValues() {
        this.doseTimeDesc = null;
        let rxAdminDoseSchedule: any = null;
        if (this.openFrom === "RxHistory") {
            // const RxAdminDoseSchedule: any = this.rxInfo.RxAdminDoseSchedule
        }
        rxAdminDoseSchedule = this.rxFG.controls.RxAdminDoseSchedule;
        if (this.rxInfo.RxAdminDoseSchedule && this.rxInfo.RxAdminDoseSchedule.RxAdmin) {
            rxAdminDoseSchedule.controls["RxAdmin"].patchValue(this.rxInfo.RxAdminDoseSchedule.RxAdmin);
        }

        if (this.rxInfo.RxAdminDoseSchedule && this.rxInfo.RxAdminDoseSchedule.RxDoseSchs.length > 0) {
            const extraCostFGs = this.rxInfo.RxAdminDoseSchedule.RxDoseSchs.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            rxAdminDoseSchedule.setControl("RxDoseSchs", extraCostFormArray);
        }

        if ((this.openFrom === "RphVerification" || this.openFrom === "NewRx") && sessionStorage.getItem("OldAdminData") === "true") {
            sessionStorage.setItem("OldAdminData", "" + false);
            if (sessionStorage.getItem("RxAdmDosSchFilt")) {
                rxAdminDoseSchedule.controls.RxAdmin.patchValue(
                    JSON.parse(sessionStorage.getItem("RxAdmDosSchFilt"))
                );
            }
            if (sessionStorage.getItem("RxAdmDosSchInf")) {
                const extraCostFGs = JSON.parse(sessionStorage.getItem("RxAdmDosSchInf")).map(val => this._fb.group(val));
                const extraCostFormArray = this._fb.array(extraCostFGs);
                rxAdminDoseSchedule.setControl("RxDoseSchs", extraCostFormArray);
            }
        }

        let strtDate = null;
        if (
            rxAdminDoseSchedule.value.RxAdmin &&
            rxAdminDoseSchedule.value.RxAdmin.StartDt
        ) {
            strtDate = rxAdminDoseSchedule.value.RxAdmin.StartDt;
        } else {
            strtDate = this.rxInfo.PrescReFill.FillDtTm;
        }

        const supDays =
            this.rxInfo.PrescReFill.SupplyDays &&
                parseInt(this.rxInfo.PrescReFill.SupplyDays, 0) !== 0
                ? this.rxInfo.PrescReFill.SupplyDays - 1
                : 0;
        const endDate = moment(strtDate)
            .add(supDays, "days")
            .format("MM/DD/YYYY");

        rxAdminDoseSchedule.controls.RxAdmin.controls["StartDt"].setValue(
            strtDate
        );
        rxAdminDoseSchedule.controls.RxAdmin.controls["EndDt"].setValue(
            endDate
        );
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    onDateSelect(val: any) {
        const RxAdminDoseSchedule: any = this.rxFG.controls.RxAdminDoseSchedule;
        if (
            this.rxInfo["PrescReFill"] &&
            this.rxInfo.PrescReFill.SupplyDays
        ) {
            const startDate = moment(
                new Date(val.year + "-" + val.month + "-" + val.day)
            )
                .add(this.rxFG.value.PrescReFill.SupplyDays, "days")
                .format("MM/DD/YYYY");

            RxAdminDoseSchedule.controls.RxAdmin.controls["EndDt"].setValue(
                startDate
            );
        }

        const adminObj: any = _.filter(this.systemSetting.RxSettings, {
            Key: "MAKE_MAX_DAILY_DOSE_CDRUG_MNDT"
        });

        if (
            adminObj &&
            adminObj.Value === "1" &&
            this.drugInfo &&
            this.drugInfo.drugclass === 5
        ) {
            RxAdminDoseSchedule.controls.RxAdmin.setValidators([
                Validators.required
            ]);
        }

        if (!this.drugInfo) {
            if (
                adminObj &&
                adminObj.Value === "1" &&
                this.rxInfo.Drug &&
                this.rxInfo.Drug.drugclass === 5
            ) {
                RxAdminDoseSchedule.controls.RxAdmin.setValidators([
                    Validators.required
                ]);
            }
        }
    }

    optedDoseSchedValue(val: any) {
        let optedVal: number;
        if (val) {
            optedVal = val.Id;
        }
        this._rxService
            .getDosageScheduleInfo(optedVal)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => this.patchDosageSchInfo(resp));
    }

    patchDosageSchInfo(dosgSch: DosageSchedule[]) {
        dosgSch.map((dos: any) => {
            if (dos.DoseTimeDesc === "Night") {
                dos["DoseTimeCatId"] = 4;
            } else if (dos.DoseTimeDesc === "Evening") {
                dos["DoseTimeCatId"] = 3;
            } else if (dos.DoseTimeDesc === "Afternoon") {
                dos["DoseTimeCatId"] = 2;
            } else {
                dos["DoseTimeCatId"] = 1;
            }
        });

        if (dosgSch && dosgSch.length) {
            const rxAdminDoseSchedule: any = this.rxFG.controls
                .RxAdminDoseSchedule;

            rxAdminDoseSchedule.controls.RxAdmin.controls[
                "dosePerDay"
            ].setValue(dosgSch.length);
            const dosgFGs = dosgSch.map(val => this._fb.group(val));
            const dosgFormArray = this._fb.array(dosgFGs);
            rxAdminDoseSchedule.setControl("RxDoseSchs", dosgFormArray);
        }
    }

    optedDoseSlotValue(val: any, index: number) {
        const rxAdminDoseSchedule: any = this.rxFG.controls.RxAdminDoseSchedule;
        const time = val.split(" ");
        time[0] = time[0].replace(":", ".");
        // if ((+time[0] > 5.00 && val === "AM") && (+time[0] <= 12.00 && val === "PM")) {
        //     const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
        //     this.doseTimeDesc = "Morning";
        //     FG.controls[0].controls["DoseTimeCatId"].setValue(1);
        // } else if (val > "12:00 PM" && val <= "05:00 PM") {
        //     const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
        //     this.doseTimeDesc = "Afternoon";
        //     FG.controls[0].controls["DoseTimeCatId"].setValue(1);
        // } else if (val > "05:00 PM" && val <= "10:00 PM") {
        //     const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
        //     this.doseTimeDesc = "Evening";
        //     FG.controls[0].controls["DoseTimeCatId"].setValue(1);
        // } else {
        //     const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
        //     this.doseTimeDesc = "Night";
        //     FG.controls[0].controls["DoseTimeCatId"].setValue(1);
        // }

        if (time[1] === "PM" && time[0] !== "12.00") {
            time[0] = +time[0] + 12;
        }

        if ((+time[0] >= 5.00 && time[1] === "AM") && (+time[0] < 12.00)) {
            const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
            this.doseTimeDesc = "Morning";
            FG.controls[index].controls["DoseTimeCatId"].setValue(1);
        } else if ((+time[0] >= 12.00 && time[1] === "PM") && (+time[0] < 17.00)) {
            const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
            this.doseTimeDesc = "Afternoon";
            FG.controls[index].controls["DoseTimeCatId"].setValue(2);
        } else if ((+time[0] >= 17.00 && time[1] === "PM") && (+time[0] < 22.00)) {
            const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
            this.doseTimeDesc = "Evening";
            FG.controls[index].controls["DoseTimeCatId"].setValue(3);
        } else {
            const FG: any = rxAdminDoseSchedule.controls.RxDoseSchs;
            this.doseTimeDesc = "Night";
            FG.controls[index].controls["DoseTimeCatId"].setValue(4);
        }
    }

    resetFG() {
        const rxAdminDoseSchedule: any = this.rxFG.controls.RxAdminDoseSchedule;
        rxAdminDoseSchedule.controls.RxAdmin.setValidators([
            Validators.nullValidator
        ]);
        if (this.activeModal) {
        this.activeModal.close();
        }
        this.CloseAdminRxPopUp.emit();
    }

    closeRxSchdModal() {
        if (this.RxAdmin.valid) {
            const rxAdminDoseSchedule: any = this.rxFG.controls
                .RxAdminDoseSchedule;
            const dosgSchdInfo = JSON.stringify(
                rxAdminDoseSchedule.controls.RxDoseSchs.value
            );
            const admDosSchFilt = JSON.stringify(
                rxAdminDoseSchedule.controls.RxAdmin.value
            );
            if (this.openFrom === "RphVerification" || this.openFrom === "NewRx") {
                sessionStorage.setItem("RxAdmDosSchInf", dosgSchdInfo);
                sessionStorage.setItem("RxAdmDosSchFilt", admDosSchFilt);
                sessionStorage.setItem("OldAdminData", "" + true);
            }
            // if (
            //     this.openFrom === "RphVerification" || this.openFrom === "NewRx"
            // ) {

            // }

            this.rxAdmin = {
                RxAdmin: JSON.parse(
                    sessionStorage.getItem("RxAdmDosSchFilt")
                ),
                RxDoseSchs: JSON.parse(
                    sessionStorage.getItem("RxAdmDosSchInf")
                ),
                PrescriptionId: this.rxInfo.Prescription.Id
            };
            this._rxService.postRxAdmin(this.rxAdmin)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._alertSvc.success("Rx Admin data saved successfully.");
                }
            });
            if (this.activeModal) {
                this.activeModal.close();
                }
            this.CloseAdminRxPopUp.emit();
        } else {
            this.validateAllFormFields(this.RxAdmin);
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    optedDosePerDay(val) {
        const rxAdminDoseSchedule: any = this.rxFG.controls.RxAdminDoseSchedule;
        let erxDosSchObj = [];
        if (this.rxFG.value.RxAdminDoseSchedule.RxDoseSchs) {
            erxDosSchObj = this.rxFG.value.RxAdminDoseSchedule.RxDoseSchs;
        }
        if (val > this.rxFG.value.RxAdminDoseSchedule.RxDoseSchs.length) {
            for (
                let i = this.rxFG.value.RxAdminDoseSchedule.RxDoseSchs.length;
                i < val;
                i++
            ) {
                const nErxDos = new ERxDoseSchs();
                nErxDos.DoseSlot = i + 1;
                erxDosSchObj.push(nErxDos);
            }
        } else {
            for (
                let i = this.rxFG.value.RxAdminDoseSchedule.RxDoseSchs.length;
                i > val;
                i--
            ) {
                erxDosSchObj.pop();
            }
        }
        const dosgFGs = erxDosSchObj.map(obj => this._fb.group(obj));
        const dosgFormArray = this._fb.array(dosgFGs);
        rxAdminDoseSchedule.setControl("RxDoseSchs", dosgFormArray);
    }
}
