import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";

@Injectable()
export class FaxService {

    constructor(private _http: HttpClient) {}

    getRefillData(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_FAX_FOLLOUP_LIST, payload);
    }

    changeMarkStatus(payload: any): Observable<any> {
        return this._http.put<any>(constant.PUT_FAX_MARK_RECORDS, payload);
    }

    addComment(payload: any): Observable<any> {
        return this._http.put<any>(constant.PUT_FAX_UPDATE_COMMENT, payload);
    }

    deleteRefills(payload: any): Observable<any> {
        return this._http.put<any>(constant.PUT_FAX_DELETE, payload);
    }

    faxRxGeneration(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_FAX_RX_GENERATION, payload);
    }

    printFaxRefillReport(payload: any) {
        return this._http.post(constant.POST_FAX_REPORT, payload, { responseType: "blob" });
    }

    getFaxLogData(payload: any) {
        return this._http.post(constant.POST_FAXLOG , payload);
    }

    cancelFax(payload: any) {
        return this._http.post<any>(constant.POST_FAX_CANCEL , payload);
    }

    resendFaxes(payload: any) {
        return this._http.post<any>(constant.POST_FAX_RESEND , payload);
    }

    incomingFaxList(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_INCOMING_FAX_LIST, payload);
    }

    markAsReadFax(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_INCOMING_FAX_MARK, payload);
    }

    removeOrRecallFaxes(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_INCOMING_FAX_REMOVE, payload);
    }


    updateFaxNoToPresc(params: any): Observable<any> {
        return this._http.put<any>(constant.PUT_UPDATEFAXNO_TOPRESC, params);
    }

}
