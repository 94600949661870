import {
    Component,
    OnInit,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ValidateFormFieldsUtils } from "src/app/utils";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "eprx-radio",
    templateUrl: "./radio.component.html"
})
export class RadioComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    autoFocus: boolean;
    openFrom:string;
    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }

    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }

    @Input()
    set IsDisabled(ir: boolean) {
        this.isDisabled = ir;
    }

    @Input()
    set List(ls: any[]) {
        this.radioList = ls;
    }

    @Input()
    set BindLabel(hm: string) {
        this.bindLabel = hm;
    }

    @Input()
    set BindValue(hm: string) {
        this.bindValue = hm;
    }

    @Input()
    set LabelForId(hm: string) {
        this.labelForId = hm;
    }

    @Input()
    set Name(nm: string) {
        this.name = nm;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
    }

    @Input()
    set IDForList(idf: string[]) {
        this.idForList = idf;
    }

    @Input()
    set ValueList(vl: string[]) {
        this.valueList = vl;
    }

    @Input()
    set HasControl(hc: boolean) {
        this.hasControl = hc;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set AutoFocus(ti: boolean) {
        this.autoFocus = ti;
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }
    @Input()
    set OpenFrom(op:string){
        if(op){
            this.openFrom = op;
        }
    }

    @Output()
    TriggerSelectValue = new EventEmitter();

    @Output()
    TriggerOnEnterValue = new EventEmitter();

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();

    labelText: string;
    inputErrors: any;
    errorDefs: any;
    errorMessage: string;
    inputFormGroup: FormGroup;
    custContrlName: string;
    isDisabled = false;
    radioList: any[];
    bindLabel: string;
    bindValue: string;
    labelForId: string;
    name: string;
    isRequired: boolean;
    idForList: string[];
    valueList: string[];
    hasControl = true;
    tabIndex: number;

    constructor(private _cdr: ChangeDetectorRef, private _validFormFieldsUtils: ValidateFormFieldsUtils) {}

    ngOnInit() {}

    ngOnChanges(changes: any): void {
        if (changes && changes.InputErrors) {
            const errors: any = changes.InputErrors.currentValue;
            this.errorMessage = "";
            if (errors) {
                Object.keys(this.errorDefs).some(key => {
                    if (errors[key]) {
                        this.errorMessage = this.errorDefs[key];
                        return true;
                    }
                });
            }
        }
    }

    ngAfterViewInit() {
        // this._cdr.detectChanges();
    }
    ngAfterViewChecked() {
        if (this.isDisabled && this.openFrom === "PatHis" && document.getElementById("NextPrevRadioBtn2")) {
            !document.getElementById("NextPrevRadioBtn2").hasAttribute('disabled') ? document.getElementById("NextPrevRadioBtn2").setAttribute('disabled', '') : '';
            // document.getElementById("NextPrevRadioBtn2").setAttribute('disabled', '');
          //  document.getElementById("NextPrevRadioBtn1").removeAttribute('disabled');
        }
      }
    

    ngOnDestroy() {
        // this._cdr.detach();
    }

    changeValue(val) {
        this.TriggerSelectValue.emit(val);
    }
    onEnter(value?: any): void {
        this.TriggerOnEnterValue.emit(value);
    }
    validateAllFormFields(
        formGroup?: FormGroup,
        formCntrl?: string
    ) {
        this.errorMessage = this._validFormFieldsUtils.validateFormFields(formGroup, formCntrl, this.errorDefs );
    }

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab"){
            this.TriggerOnShiftTabbing.emit(event);
        }
    }
}
