<div class="modal-header">
    <h4 class="modal-title" >Attach Document</h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close-button" (click)="closeModal()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body ">
    <!-- <div class="edit-patient insurance--body"> -->
        <div class="row">
            <div class="col-4 p-4">
                <app-upload class="doc-align-top" [showScanner]="true" (UploadedFile)="fileUploaded($event)" [NameRequired]="true" [NameFG]="docUploadFG" [NameControlName]="'Name'">
                </app-upload>
            </div>
            <div class="col-8">
                <div class="exprx--common-block erx--body insurance--body">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Document Search</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                            <div class="row">
                                <div class="col-4">
                                    <eprx-date-picker [RxSelectId]="'ScannedFrom'" [LabelText]="'Scanned From'"
                                        [PlaceHolder]="'Scanned From'" [ControlName]="'ScannedFrom'"
                                        [MarkAsTouched]="docQueueFG?.controls?.ScannedFrom?.touched" [FormGroupName]="docQueueFG"
                                        [MinDate]="" [MaxDate]="today">
                                    </eprx-date-picker>
                                </div>
                                <div class="col-4">
                                    <eprx-date-picker [RxSelectId]="'ScannedTo'" [LabelText]="'Scanned To'" [PlaceHolder]="'Scanned To'"
                                        [ControlName]="'ScannedTo'" [MarkAsTouched]="docQueueFG?.controls?.ScannedTo?.touched"
                                        [FormGroupName]="docQueueFG" [MinDate]="" [MaxDate]="today">
                                    </eprx-date-picker>
                                </div>
                                <div class="col-4 align-button text-right">
                                    <button title="Search / Refresh" type="button" class="hotkey_success" (click)="getDocs()" (keydown.enter)="getDocs()"
                                        appShortcutKey [AltKey]="'f'"><span>F</span>
                                        Search</button>
                                    <!-- <button type="button" class="hotkey_success" (click)="clearSearchInputs()"
                                        (keydown.enter)="clearSearchInputs()" appShortcutKey [AltKey]="'l'"><span>L</span> Clear
                                    </button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content"  *ngIf="totalDocs && totalDocs.length">
                        <div class="col-12">
                                <eprx-input [LabelText]="'Filter'" [PlaceHolder]="'Search Docs'" [HasControl]="false" [InputValue]="searchString" (TriggerChangeValue)="searchDocs($event)" ></eprx-input>
                        </div>
                        <div class="col-12">
                            <span  class="color_orange"> Double click or Enter to select </span>
                            <wj-flex-grid  #docsTemp [headersVisibility]="'Column'" [itemsSource]="docWJ"
                                [isReadOnly]="true" [selectionMode]="'Row'" [columnPicker]="''" (keydown)="addEventOnWijimo($event)"
                                (keydown.enter)="OnDoubleClickOnWijimo(docsTemp, $event)" (dblclick)="OnDoubleClickOnWijimo(docsTemp, $event)">
                                <wj-flex-grid-column header="View" [width]="58">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                        <span class="text-center">
                                            <i class="far fa-eye actions" (click)="previewSelectedFile(item.Data)"></i>
                                        </span>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="actvHeader | uppercase" [binding]="actvHeader" [visible]="true"
                                    *ngFor="let actvHeader of actvHeaders; let i = index" [width]=" actvHeader === 'Action' ? 80 : actvHeader === 'Website'
                                                                        ? 150 :actvHeader === 'Send Vendor Cost Price' ? 150: 150 ">
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>
                    </div>
                </div>
            </div>
             <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <lib-ngx-image-zoom *ngIf="addFileType == 'image'" [thumbImage]=imageSrc [fullImage]=imageSrc [magnification]="1"
                            [enableScrollZoom]="true" [enableLens]="true" [lensWidth]="200" [circularLens]="true" [lensHeight]="200"
                            [lensWidth]="300">
                        </lib-ngx-image-zoom>
                        <div  *ngIf="addFileType == 'pdf'" class="pdf-container view-sect max-height_700px overflow-auto">
                            <pdf-viewer [src]="pdfSrc" class="d-block" [fit-to-page]="true" [original-size]="false"
                             [stick-to-page]="true" [show-borders]="true" [autoresize]="true"
                                >
                            </pdf-viewer>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    <!-- </div> -->
</div>
<div class="modal-footer">
    <button type="button" class="hotkey_success" (click)="saveDocument()"  appShortcutKey [AltKey]="'o'"><span>O</span>OK</button>
    <button type="button" class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span>CANCEL</button>
</div>

<ng-template #PreviewDocument let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click')">
        <h4 header>Preview Label</h4>
        <span body>
            <div class="row">
                <div class="col-12">
                    <lib-ngx-image-zoom *ngIf="addFileType == 'image' && imageSrc" [thumbImage]=imageSrc [fullImage]=imageSrc
                        [magnification]="1" [enableScrollZoom]="true" [enableLens]="true" [lensWidth]="200"
                        [circularLens]="true" [lensHeight]="100" [lensWidth]="200">
                    </lib-ngx-image-zoom>
                    <iframe *ngIf="addFileType == 'pdf'" [src]="pdfSrc" class="text-center width_100per border_3px_solid_black" toolbar="0" width="100%"
                                height="500px" type="application/pdf"></iframe>
                    <!-- <div *ngIf="addFileType == 'pdf'" class="pdf-container view-sect"
                        style="max-height: 700px;overflow: auto;">
                        <pdf-viewer [src]="pdfSrc" [fit-to-page]="true" [original-size]="false" [stick-to-page]="true"
                            [show-borders]="true" [autoresize]="true" style="display: block;">
                        </pdf-viewer>
                    </div> -->
                </div>
            </div>
        </span>
        <button footer ngbAutofocus type="submit" class="hotkey_primary" (click)="c('close')" appShortcutKey
            InputKey="o">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
