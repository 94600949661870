export class AccessRoles {
    Id: number = null;
    TenantId: number = null;
    PharmacyId: number = null;
    Persona: number = null;
    Name: string = null;
    Description: string = null;
    IsActive: boolean = null;
    IsDeleted: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

// export class RoleAccessPrivilege {
//     Id: number = null;
//     AccessRoleId: number = null;
//     CategoryId: number = null;
//     PrivilegeMask: number = null;
//     IsActive: boolean = null;
//     IsDeleted: boolean = null;
//     CreatedDtTm: Date = null;
//     ModifiedDtTm: Date = null;
// }
export class RoleAccessPrivilege {
    AccessRoleId: number = null;
    CategoryId: number = null;
    PrivilegeMask: number = null;
    PrivilegeValue: number = null;
}

export class Users {
    Id: number = null;
    TenantId: number = null;
    PharmacyId: number = null;
    PersonId: number = null;
    CreatedById: number = null;
    UserName: string = null;
    PassWord: string = null;
    IsActive: boolean = true;
    IsDeleted: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    EnforceIpRestriction: boolean = false;
}
export class UserRoles {
    Id: number = null;
    PersonId: number = null;
    AccessRoleId: number = null;
    IsActive: boolean = null;
    IsDeleted: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
 }
// export  class Person
// {
//      long Id { get; set; }
//      long? TenantId { get; set; }
//      string FirstName { get; set; }
//      string MiddleName { get; set; }
//      string LastName { get; set; }
//      string Initials { get; set; }
//      DateTime? BirthDt { get; set; }
//      int? GenderId { get; set; }
//      int? MaritalStatusId { get; set; }
//      string ProfPicExtn { get; set; }
//      long? PrimeAddsId { get; set; }
//      long? PrimePhId { get; set; }
//      long? PrimeEmailId { get; set; }
//      decimal? NPINum { get; set; }
//      string IdProofCode { get; set; }
//      int? IdProofId { get; set; }
//      string Remarks { get; set; }
//      int ActiveStatusId { get; set; }
//      DateTime CreatedDtTm { get; set; }
//      DateTime ModifiedDtTm { get; set; }
// }
export class searchItem {
    item: any;
    path: string;
}
