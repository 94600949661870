<div id="loader-wrapper" *ngIf="_loader">
    <div class="spinner">
        <div class="disable-select height_100px">
            <img src="assets/img/edited.png" class="loader-img">
        </div>
        <div class="disable-select">
            <!-- <h5 class="text-white">Please wait ... </h5> -->
            <h3 class="text-white" >{{message ? message + " ..." : 'Loading data ...'}}</h3>
        </div>
        <!-- <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="length" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg>
        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg>
        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg>
        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
        </svg> -->
    </div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
</div>
