import {
    Component,
    OnInit,
    ChangeDetectorRef,
    AfterViewInit,
    Output,
    EventEmitter,
    Input
} from "@angular/core";

@Component({
    selector: "app-custom-modal",
    templateUrl: "./custom-modal.component.html"
})
export class CustomModalComponent implements OnInit, AfterViewInit {
    @Input() hideCross = false;
    @Input() hideFooter = false;
    @Input() applyCustmCSS: any;

    constructor(private cd: ChangeDetectorRef) {}

    @Output()
    oncrossBtnClick = new EventEmitter<any>();

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    ngOnInit() {}

    closeModal() {
        this.oncrossBtnClick.emit(true);
    }
}
