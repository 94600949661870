<div class="col">
    <div class="row rx--his__actions margin_top_1rem">
            <div class="secondary-actn-btn" placement="top" ngbTooltip="Verify">
                <a class="enable--hotkey__buttons" (click)="checkVerifications('RxVerify')">
                    <span class="hotkey--buttons text-center">V</span>
                    <span class="save--buttons">Verify</span>
                </a>
            </div>
            <span *ngIf="showMarkAsVerif" class="secondary-actn-btn" placement="top" ngbTooltip="Mark as Verified">
                <a class="enable--hotkey__buttons" (click)="checkVerifications('MarkAsVerif')">
                    <span class="hotkey--buttons text-center">M</span>
                    <span class="save--buttons">Mark as Verified</span>
                </a>
            </span>
            <span class="secondary-actn-btn" placement="top" *ngIf="!isRxVerification" ngbTooltip="Mark as Follow Up">
                <a class="enable--hotkey__buttons" (click)="checkVerifications('FollowUp')">
                    <span class="hotkey--buttons text-center">U</span>
                    <span class="save--buttons">Mark as Follow-up</span>
                </a>
            </span>
            <!-- <span class="secondary-actn-btn" placement="top" ngbTooltip="Edit Rx">
                <a class="enable--hotkey__buttons" (click)="routeEditRx()">
                    <span class="hotkey--buttons text-center">E</span>
                    <span class="save--buttons">Edit Rx</span>
                </a>
            </span>
            <span class="secondary-actn-btn" placement="top" ngbTooltip="Patient History">
                <a class="enable--hotkey__buttons" (click)="routeToPatientHistory()">
                    <span class="hotkey--buttons text-center">T</span>
                    <span class="save--buttons">Pat History</span>
                </a>
            </span> -->
            <span class="secondary-actn-btn" placement="top" ngbTooltip="Verify History">
                <a class="enable--hotkey__buttons" (click)="openHistoryPopUp()">
                    <span class="hotkey--buttons text-center">H</span>
                    <span class="save--buttons">Ver Hist</span>
                </a>
            </span>
            <span class="secondary-actn-btn" placement="top" [ngbTooltip]="hasPatOvrRidPrv ? 'Edit Patient' : 'User has No privilege'">
                <a *ngIf="hasPatOvrRidPrv" class="enable--hotkey__buttons" (click)="openActionModal('EditPatient')">
                    <span class="hotkey--buttons text-center">P</span>
                    <span class="save--buttons">Edit Patient</span>
                </a>
                <a *ngIf="!hasPatOvrRidPrv" class="enable--hotkey__buttons" (click)="openOveride('PatientFile', 'Edit', 'EditPatient', 'PUT_Patient')">
                    <span class="hotkey--buttons text-center">P</span>
                    <span class="save--buttons">Edit Patient</span>
                </a>
            </span>
            <span class="secondary-actn-btn" placement="top" [ngbTooltip]="hasPrescOvrRidPrv ? 'Edit Prescriber' : 'User has No privilege'">
                <a *ngIf="hasPrescOvrRidPrv"class="enable--hotkey__buttons" (click)="openActionModal('EditPrescriber')">
                    <span class="hotkey--buttons text-center">R</span>
                    <span class="save--buttons">Edit Prescriber</span>
                </a>
                <a *ngIf="!hasPrescOvrRidPrv"class="enable--hotkey__buttons" (click)="openOveride('PrescriberFile', 'Edit', 'EditPrescriber', 'PUT_Prescriber')">
                    <span class="hotkey--buttons text-center">R</span>
                    <span class="save--buttons">Edit Prescriber</span>
                </a>
            </span>
            <!-- <span class="secondary-actn-btn" placement="top" ngbTooltip="Notes">
                <a class="enable--hotkey__buttons" (click)="openActionModal('Notes')">
                    <span class="hotkey--buttons text-center">N</span>
                    <span class="save--buttons">Notes</span>
                </a>
            </span> -->
            <span class="secondary-actn-btn" *ngIf="!isRxVerification" placement="top" ngbTooltip="Insurance Transmission Result">
                <a class="enable--hotkey__buttons" (click)="openActionModal('TransResult')">
                    <span class="hotkey--buttons text-center">I</span>
                    <span class="save--buttons">Trans Result</span>
                </a>
            </span>
    </div>
</div>

<ng-template #AlreadyVerif let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Confirmation </h4>
        <span body>
            Rx#: <span class="font-weight-bold">{{rxInfo.Prescription.PrescNum}}</span> Refill#:
             <span class="font-weight-bold">{{rxInfo.PrescReFill.ReFillNum}}</span> Fill#:
             <span class="font-weight-bold">
                 {{(_commonSrv.checkIsPartialFill(rxInfo.PrescReFill, rxInfo?.Prescription)) ? rxInfo.PrescReFill.PartialFillNo : ' --'}}</span>
                 <div><br></div>
                 This Rx has been {{isRxVerification ? 'Rx' : 'Rph'}} verified  by <span class="font-weight-bold">
                 {{verifiedAlready?.UserName ? (verifiedAlready?.UserName | uppercase) : '--' }}</span> on <span class="font-weight-bold">
            {{verifiedAlready.VerifDtTm ? (verifiedAlready.VerifDtTm | localTime | date:'MM/dd/yyyy'):'--'}}</span>. Would you wish to re-verify?
        </span>
        <button ngbAutofocus footer class="hotkey_primary" (click)="c('Close click')" (click)="checkIsDiscontinue()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #AlreadyVerifFollow let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header> Confirmation </h4>
        <span body>
            Are you sure you want to mark the Verified Rx for Follow-up?
        </span>
        <button ngbAutofocus footer class="hotkey_primary" (click)="c('Close click')" (click)="openFollowModal()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #RxDiscontinued let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span >Warning!!</span>
    </div>
    <div class="modal-body">
        <p>Rx is Discontinued. If you wish to continue the Rx will no more be marked as discontinued. Would you like to proceed?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey InputKey="'y'"
        (click)="setOrderStatus(); c('Close click')"><b>Y</b>YES</button>
        <button class="hotkey_primary" appShortcutKey InputKey="'n'"
        (click)="c('Close click')"><b>N</b>NO</button>
    </div>
</ng-template>

<app-rx-notes *ngIf="activeModal === 'Notes'" [RxInfo]="rxInfo" (IsPopUpClosed)="openActionModal($event)"></app-rx-notes>
<app-ins-trans-result *ngIf="activeModal === 'TransResult'" [RxInfo]="rxInfo" (IsPopUpClosed)="openActionModal($event)"></app-ins-trans-result>
<app-rph-verify-hist [IsRxVerification]="isRxVerification" *ngIf="IsHistory" [RxInfo]="rxInfo" (RefreshGrid)="emitRefreshGrid()" (IsPopUpClosed)="closeHist($event)"></app-rph-verify-hist>
<app-rph-followup *ngIf="IsFollowup" [RxInfo]="rxInfo" (IsPopUpClosed)="closeFollowupModal($event)" (RefreshGrid)="emitGridRefresh($event)"> </app-rph-followup>
<app-override-log
    *ngIf="openOverride"
    [PrivMask]="privType"
    [CatId]="categoryId"
    [OverrideEndpoint]="overrideEndpoint"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>
