import { Injectable } from "@angular/core";
import { BehaviorSubject ,  Observable } from "rxjs";
import { PatientInfo } from "src/app/models";

@Injectable({
    providedIn: "root"
})
export class PatientStore {

    _patientInfo$: BehaviorSubject<PatientInfo> = new BehaviorSubject(null) as BehaviorSubject<PatientInfo>;
    patientInfo$: Observable<PatientInfo> = this._patientInfo$.asObservable();

    _patientInsuList$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    patientInsuList$: Observable<any> = this._patientInsuList$.asObservable();

    _isModalTriggered$: BehaviorSubject<string> = new BehaviorSubject(null) as BehaviorSubject<string>;
    isModalTriggered$: Observable<string> = this._isModalTriggered$.asObservable();

    _notesLog$: BehaviorSubject<string> = new BehaviorSubject(null) as BehaviorSubject<string>;
    notesLog$: Observable<string> = this._notesLog$.asObservable();

    _patCity$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    patCity$: Observable<any> = this._patCity$.asObservable();

    _patState$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    patState$: Observable<any> = this._patState$.asObservable();

    _diagList$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    diagList$: Observable<any> = this._diagList$.asObservable();

    _patFacility$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    patFacility$: Observable<any> = this._patFacility$.asObservable();

    _patGroups$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    patGroups$: Observable<any> = this._patGroups$.asObservable();

    _primaryInsuChange$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    primaryInsuChange$: Observable<any> = this._primaryInsuChange$.asObservable();


    _patientEditInfo$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    patientEditInfo$: Observable<any> = this._patientEditInfo$.asObservable();

    constructor() {}

    storePatientInfo(data) {
        this._patientInfo$.next(data);
    }

    storePatientInsuranceList(data) {
        this._patientInsuList$.next(data);
    }

    storeAndTriggerModalTypeToShow(type: string) {
        this._isModalTriggered$.next(type);
    }

    clearTriggerModalTypeToShow() {
        this._isModalTriggered$.next(null);
    }

    storeNotesLog(data) {
        this._notesLog$.next(data);
    }

    storePatCity(data) {
        this._patCity$.next(data);
    }

    storePatState(data) {
        this._patState$.next(data);
    }

    storeDiagList(data) {
        this._diagList$.next(data);
    }

    storeFacilty(data) {
        this._patFacility$.next(data);
    }

    storePatGroups(data) {
        this._patGroups$.next(data);
    }
    trrigerPatInsuChange(data) {
        this._primaryInsuChange$.next(data);
    }
}
