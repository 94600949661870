export class RphFilters {
    FromDate: Date = null;
    ToDate: Date = null;
    VerifyStage: any = null;
    VerifyStatus: any = null;
    PharmacyFilter: any = null;
    Rxonly: boolean = null;
    DDIOnly: boolean = null;
    PatientId: number = null;
    UserId: any = null;
    PageNumber: number = null;
    PageSize: number = null;
    selectedIndex: number = null;
    pageNu: number = null;
    alreadyInfoChckd: boolean = null;
}
