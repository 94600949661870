export class NotVerifyFilters {
    FromDate: Date = null;
    ToDate: Date = null;
    VerifyStatus: string = null;
    UserName: string = null;
    IsCurrentUser: boolean = null;
    ShowPendingRx: boolean = null;
    StartIndex: number = null;
    MaxRecords: number = null;
}

export class DrugPickVerify {
    PrescId: number = null;
    PrescNum: string = null;
    PrescRefillId: number = null;
    BottleNdc: string = null;
    RefillNum: number = null;
    Result: string = null;
    VerifiedById: number = null;
    UserName : string = null;
    PartialfillNum: number = null;
}

export class DrugVerifiedHistory {
    Rx: number = null;
    Pharmacist: string = null;
    Ref: number = null;
    Tech: string = null;
    DrugNDC: number = null;
    DateVerified: any = null;
    Result: string = null;
    FinalResult: string = null;
    Drug: any = null;
    Qtyinstock: number = null;
    QtyOrd: number = null;
    IsPicked: boolean = null;
}
