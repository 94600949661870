import { ReportsService } from "src/app/services/reports.service";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from "@angular/core";
import { RxStore } from "src/app/store";
import { Observable } from "rxjs/observable";
import { Subscription ,  forkJoin } from "rxjs";

import { RxModel, SystemData } from "src/app/models";
import {
    InsuranceService,
    TransmissionService,
    AlertService,
    RxService,
    CommonService,
    UserService
} from "src/app/services";
import html2canvas from "html2canvas";
import { PrintService } from "src/app/services/print.service";
import { NRxUtils, TransmissionUtils, PatchRXFGUtil, RxBillingUtil, NewAuditUtils, CommonUtil, RxPricingUtil } from "src/app/utils";
import { CommonPrintService } from "src/app/services/common-print.service";
import { ViewTransMsgComponent } from "../../shared/view-trans-msg/view-trans-msg.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BillToCashComponent } from "../../shared";
import { EdRxComponent } from "../../ed-rx/ed-rx.component";
import { NRxSaveUtils } from "src/app/utils/nRx-save.util";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-tr-rx-actions",
    templateUrl: "./tr-rx-actions.component.html",
    styles: []
})
export class TrRxActionsComponent implements OnInit {
    @Input() transmResp: any;
    @Input() transmissionType: any;
    @Input() rxInfo: RxModel;
    @Input() isRjctActnEnbld: boolean;
    @Input() isTransmsnPaid: boolean;
    @Input() isTransmsnPaidRej: boolean;
    @Input() systemData: SystemData;
    @Input() transmitParams: any;
    @Input() transReverseInfo: any;
    @Input() rxFG: any;

    @Output()
    IsTransmsnCmpltd = new EventEmitter<any>();

    @Output()
    IsRxDeleted = new EventEmitter<any>();

    @Output()
    InitiateNextTrans = new EventEmitter<any>();

    @Output()
    InitiateNextTransForSelectedIns = new EventEmitter<any>();

    @Output()
    rejectedReTransmission = new EventEmitter<any>();
    transActnType: string;
    subscriptions = new Subscription();

    rxInfo$: Observable<RxModel>;
    transActionType: string;
    secondModalName: string;
    rxCost = 0;
    commonModalName: string;
    folwUpList$: Observable<any>;
    nxtInsuLst: any;
    nxtInsuToBill: any;
    payRecords: any;
    noLabel: boolean;
    enablNoLabl: boolean;
    sendFaxFromTrans: boolean;
    cmsSelected = false;
    @ViewChild("TRANSREJPOPUP", { static: true })
    TRANSREJPOPUP: any;
    @ViewChild("PREVIEW", { static: true })
    PREVIEW: any;
    hasFaxNumber: boolean;
    BIN = "";
    PCN = "";
    pharmNo: any;
    certCode: any;
    selectedRx: any;
    isCashDisable:boolean = false;
    oldAuth: any;
    insuSettings: any;
    insuSetngs: any;
    insuList: any;
    pricSchdlLst$: Observable<any>;
    rxInfoData: any;
    restrictRejectTrans: boolean;
    oldRxInfo: any;
    pdfContent: any;
    constructor(
        private _rxStore: RxStore,
        private _transmsnServ: TransmissionService,
        private _insurService: InsuranceService,
        private _printSer: PrintService,
        private _alertServ: AlertService,
        private _nrxUtil: NRxUtils,
        private _saveRxUtls: NRxSaveUtils,
        private _transUtils: TransmissionUtils,
        private _rxServ: RxService,
        private _cmnPrintService: CommonPrintService,
        private _reportServ: ReportsService,
        private _mdlServ: NgbModal,
        private _billUtls: RxBillingUtil,
        private _cmnService: CommonService,
        private _nwAudtUtl: NewAuditUtils,
        private _userService: UserService,
        public _cmnUtils: CommonUtil,
        private _cdr: ChangeDetectorRef,
        private _rxPricingUtil: RxPricingUtil,
        private _patchRx: PatchRXFGUtil,
        private _modalService: NgbModal,
        private _sanitizer: DomSanitizer
    ) {
        this.rxInfo$ = this._rxStore.rxInfo$;
        this.folwUpList$ = this._rxServ.followUpList$;
        this.pricSchdlLst$ = this._cmnService.priceSchedule$;
    }

    ngOnInit() {
        this.restrictRejectTrans = (this.transmissionType === "ReversReTrans" && (this.transmResp && this.transmResp.nCPDPRecv && this.transmResp.nCPDPRecv.ResponseStatus === "R")) ? true : false
        this.noLabel = false;
        this.rxCost = this._transUtils.calculateRxCost(this.transmResp);
        this.enablNoLabl = this._nrxUtil.getSetngKeyVal("LabelSettings", "SHOW_NO_LBL_OPT");
        let faxNumberSetting = this._cmnService.getSetttingValue("FaxAndReportsSettings", "Fax_Number");
        if (faxNumberSetting && faxNumberSetting.length) {
            this.hasFaxNumber = true;
        } else {
            this.hasFaxNumber = false;
        }
        this.oldRxInfo = this.rxInfo$["source"]["value"];
       const rxInfo = this.rxInfo$["source"]["value"]? this.rxInfo$["source"]["value"] : null;
        if(rxInfo && rxInfo.RxPay && rxInfo.RxPay.length) {
                this.isCashDisable = this._cmnUtils.checkPayRecExistOrNotForSelcRx(rxInfo.RxPay);
        }
    }

    updateModifyTime() {
        if (this.rxInfo$ && this.rxInfo$["source"]["value"]) {
            this.rxInfo$["source"]["value"]["PrescReFill"]["ModifiedDtTm"] = new Date();
            this._rxStore.storeRxInfo(this.rxInfo$["source"]["value"]);
        }
    }

    async showActionModal(actn?: string) {
        await this.reviewTransmissionResult();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
        }
        if (actn === "FileRx") {
            const data = await this._cmnService.getRxDetailsByPrescNos(this.rxInfo.Prescription.PrescNum,
                this.rxInfo.PrescReFill.Id).toPromise();
                this.rxInfoData = Object.assign({}, data[0]);
        }
        this.transActnType = actn;
        this._cdr.detectChanges();
        if (actn === "RejectPriorApp") {
            this.secondaryPopUpModal(actn);
        }
    }

    clearActionModalType() {
        this.transActnType = null;
    }

    async convertToCash(isFromTransmissionRejectionScreen = false) {
        // this.transActnType = "BillToCash";
        this.rxInfo = await this._nrxUtil.getandStoreRxInfo(+this.rxInfo.Prescription.PrescNum, this.rxInfo.PrescReFill.Id);
        let primaryInsu = null;
        primaryInsu = this.rxInfo?.Patient?.insurance.filter(
            x =>
                x.insurerid ===
                this.systemData["DefaultValuese"][0]["CashInsurerId"]
        )[0];
        if (primaryInsu && primaryInsu["insuranceid"]) {
            this.rxInfo.RefillInsu["InsuId"] = primaryInsu["insuranceid"];
            this._insurService.getInsSettingsInfoForRx(primaryInsu.insuplanId);
        }
        if (isFromTransmissionRejectionScreen) {
            this.rxInfo["IsFromTransmissionRejectScreen"] = true;
        }
        await this.PriceCal(primaryInsu ? primaryInsu["insuranceid"] : null);
        this.rxFG.controls["rxType"].patchValue(this.rxInfo["rxType"]);
        const modalRefCash = this._mdlServ.open(BillToCashComponent, {
            keyboard: false,
            backdrop: false,
            centered : true
        });
        modalRefCash.componentInstance.rxInfo = this.rxFG.value;
        modalRefCash.componentInstance.IsChangeToCash = "true";
        modalRefCash.componentInstance.IsBillToCashPopUpClosed.subscribe(r => {
            modalRefCash.close();
            this.rxInfo["RxBill"]["StatusId"] = 2;
            const isCashRx = this._billUtls.checkIsCahsRx(this.rxInfo, this.rxInfo.Patient, this.systemData);
            this._rxStore.storeIsCashRx(isCashRx);
            this.reviewRejectedTransmModal(null, isCashRx);
        });
        modalRefCash.componentInstance.CancelCashPopUp.subscribe(r => {
            modalRefCash.close();
        });

    }

    printScreen() {
        const data = document.getElementById("contentToPrint");
        html2canvas(data).then(canvas => {
            this._alertServ.success("Screen printing initiated");
            const contentDataURL = canvas.toDataURL("image/jpeg");
            this._printSer.print(contentDataURL);
        });
    }

    printAcceptRej() {
        const data = document.getElementById("printAcceptRej");
        html2canvas(data).then(canvas => {
            this._alertServ.success("Print has been initiate for Transmission Accept/Rejected screen.");
            const contentDataURL = canvas.toDataURL("image/jpeg");
            this._printSer.print(contentDataURL);
        });
        this.checkReset();
    }

    async reviewRejectedTransmModal(event?, isCashOrDef?: any) {
        // TODO: add this before routing
        // await this.updateErxStatus();
        if (event && typeof(event) === "boolean") {
            isCashOrDef = true;
        }
        this.BIN = this.transmResp.ClaimProcess.objPatient.objBillToIns.BIN_NO;
        this.PCN = this.transmResp.ClaimProcess.objPatient.objBillToIns.MAG_CODE;
        this.pharmNo = this.transmResp.ClaimProcess.objPatient.objBillToIns.PHARM_NO;
        this.certCode = this.transmResp.ClaimProcess.objPatient.objBillToIns.SVENDOR_CC;
        this.payRecords = [];
        /*     if ((this.transmResp && this.transmResp.nCPDPRecv) && this.transmResp.nCPDPRecv.RejCodes === "70")
            && this.BIN === "007250" && (this.PCN.toLowerCase() === "testvd04" ||  this.PCN.toLowerCase() === "testvd05"
             || this.PCN.toLowerCase() === "testvd06" || this.PCN.toLowerCase() === "testvd32" || this.PCN.toLowerCase() === "testvd33")
             && this.pharmNo === "0000123531" && this.certCode.toUpperCase() === "VD0MLC"))
             */
        let hasPayRecords: any;
        if (this.payRecords && this.payRecords.length > 0) {
            hasPayRecords = this.payRecords.filter(pay => { return (pay.RecType === "P") });
        }
          const restrictRejectedTransmission = !this.transmissionType || (this.transmissionType != "ReversReTrans" && this.transmResp && this.transmResp.nCPDPRecv && this.transmResp.nCPDPRecv.ResponseStatus !== 'R') ? true : false;
        if ((this.transmissionType != "TransReverse" && this.transmissionType != "TransMessage") && this.transActnType !== "DeleteRx" &&
            (this.transmResp && this.transmResp.nCPDPRecv) && !isCashOrDef  && restrictRejectedTransmission) {
                const resp = await forkJoin([this.reviewTransmissionResult(),
                this._transmsnServ.getPayRecord((this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.Id) ? this.rxInfo.PrescReFill.Id : 0)]).toPromise();
                if (resp && resp["1"]) {
                    this.payRecords = resp["1"];
                    this._transmsnServ._payRecords$.next(resp["1"]);
                }
                this.checkForSecondryTrans();
                return;
            } else {
                await this.reviewTransmissionResult();
            }
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
        }
        if (this.transActnType === "DeleteRx") {
            this.transActnType = null;
            this.IsRxDeleted.emit(this.noLabel);
        } else {
            this.emitTransComplted();
        }
    }

    async updateErxStatus() {
        if (
            this.rxInfo &&
            this.rxInfo.eRxDetailsViewModel &&
            this.rxInfo.eRxDetailsViewModel.ErxId
        ) {
            await this._nrxUtil.updateErxStatus(this.rxInfo);
        }
    }

    async reviewTransmissionResult() {
        const resp = (this.transmResp && this.transmResp.transmissionDetails &&
                this.transmResp.transmissionDetails.ResponseStatus !== "R") ? "P" : "R";
        const batchId = this.transmResp && this.transmResp.transmissionDetails &&
        this.transmResp.transmissionDetails.BatchId ? this.transmResp.transmissionDetails.BatchId : 0;
        if (this.transmResp && !this.transmResp.IsTransmissionStatusAPINotrequired) {
            const result =  await this._transmsnServ
                .updateUserReviewStatus(batchId, resp)
                .toPromise();
            if (this.transmissionType === "TransReverse") {
                this.getSideNavCounts();
            }
            return result;
        }
        return true;
    }

    secondaryPopUpModal(name: string) {
        this.secondModalName = name;
    }

    async emitAsTransmissionCompleted() {
        await this._transmsnServ.updateUserReviewStatus(
            this.transmResp.transmissionDetails.BatchId, "R").toPromise();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
        }
        this.emitTransComplted();
    }

    emitTransComplted() {
        this.transActnType = null;
        this.IsTransmsnCmpltd.emit(this.noLabel);
    }

    revNoLablndContinueTransm() {
        // this._cmnPrintService.printMedGuide(this.rxInfo.Drug.id);
        this.noLabel = true;
        this.reviewAndContinueTransm();
    }

    async reviewAndContinueTransm() {
        // const isLess = this._transUtils.checkIsAmountPaidLess(this.rxInfo, this.transmResp);
        // if (isLess) {
        //     this.commonModalName = "TRANSMISSIONCONF";
        // } else {
            if (this.transmissionType === "GroupTransmission" || this.transmissionType === "TrnsmtPatent") {
                await this.auditChangesAfterbilled();
            } else if (this.rxInfo &&  this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.Id  &&  this.transmissionType === "TransReverse" && this.transReverseInfo && this.transReverseInfo.RxPay && this.transReverseInfo.RxPay.length > 0 )  {
                const oldValue = [{name: "Authorization", value: this.transReverseInfo.RxPay[0].Auth_No}];
                const chngValue = [{name: "Authorization", value: this.transmResp.transmissionDetails.AuthNo}];
                this._nwAudtUtl.SaveAuditOnDelete(
                    oldValue,
                    chngValue,
                    "EditRx",
                    "Rx",
                    this.rxInfo.PrescReFill.Id
                );
            } else {
                if (this.rxInfo &&  this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.Id  && this.transmissionType &&  this.transmissionType !== "TransMessage") {
                    this.oldAuth = this.transmResp.transmissionDetails.AuthNo;
                    const oldValue = [{name: "Authorization", value: ""}];
                    const chngValue = [{name: "Authorization", value: this.transmResp.transmissionDetails.AuthNo}];
                    this._nwAudtUtl.SaveAuditOnDelete(
                        oldValue,
                        chngValue,
                        "EditRx",
                        "Rx",
                        this.rxInfo.PrescReFill.Id
                    );
                }
            }
            if (!(this.transmissionType === "GroupTransmission" || this.transmissionType === "TrnsmtPatent" || this.transmissionType === "TransReverse")) {
                const rxInfoList = await this._cmnService.getRxDetailsByPrescNos([this.transmResp.transmissionDetails.RXNo],
                    +this.transmResp.transmissionDetails.PrescRefillId).toPromise();
                await this._saveRxUtls.updateRxInfo((rxInfoList ? rxInfoList[0]: null), null, true,this.transmResp.transmissionDetails);
            }
            this.saveTransmitClaims();
        // }
    }

    async auditChangesAfterbilled() {
        if (this.rxInfo &&  this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.Id) {
            this._nwAudtUtl.SaveAuditOnDelete(
                [{ name: "Status", value: "U" }],
                [{ name: "Status", value: "B" }],
                "EditRx",
                "Rx",
                this.rxInfo.PrescReFill.Id);
        }
    }

    async saveTransmitClaims() {
        if (this.transmissionType !== "TransMessage") {
            const resp = await forkJoin([this.reviewTransmissionResult(),
                this._transmsnServ.getPayRecord((this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.Id) ? this.rxInfo.PrescReFill.Id : 0)]).toPromise();
            if (resp && resp["1"]) {
                this.payRecords = resp["1"];
                this._transmsnServ._payRecords$.next(resp["1"]);
            }
            if (resp && resp["0"]) {
                if (this.rxInfo && this.rxInfo["PrescReFill"]) {
                    this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
                    this.updateModifyTime();
                }
            }
            let hasPayRecords: any;
            if (this.payRecords && this.payRecords.length > 0) {
                hasPayRecords = this.payRecords.filter(pay => { return (pay.RecType === "P") });
            }
            if (this.transmissionType !== "TransReverse" && this.transmissionType !== "ReversReTrans" &&
                this.transmissionType !== "TransAdjst" ) {
                this.checkForSecondryTrans();
            } else {
                this.IsTransmsnCmpltd.emit(this.noLabel);
            }
        } else {
            await this.reviewTransmissionResult();
            if (this.rxInfo && this.rxInfo["PrescReFill"]) {
                this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
                this.updateModifyTime();
            }
            this.IsTransmsnCmpltd.emit(this.noLabel);
        }
    }

    async initiateFollowupTag() {
        await this.reviewTransmissionResult();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
        }
        this.transActnType = "FWUPTAG";
    }

    closeActionModals() {
        this.transActnType = null;
    }

    async checkForSecondryTrans() {
        if (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum) {
        this.rxInfo = await this._nrxUtil.getandStoreRxInfo(+this.rxInfo.Prescription.PrescNum, this.rxInfo.PrescReFill.Id);
        var ReferencePrescReFillId = null;
            if(this.rxFG?.value?.rxType === "nr" || this.rxFG?.value?.rxType === "er") {
                ReferencePrescReFillId = sessionStorage.getItem("ReferencePrescReFillId") ? +this._cmnService.DecryptData(sessionStorage.getItem("ReferencePrescReFillId")) : this.rxInfo?.PrescReFill?.ReferencePrescReFillId;
            }
            if(this.rxInfo?.PrescReFill?.CarryFwdBillSequence === true) {
                let req = { PrescNum : this.rxInfo?.Prescription?.PrescNum, PrescId : this.rxInfo?.Prescription?.Id, PartialFillNo : this.rxInfo?.PrescReFill?.PartialFillNo, RefillNum : this.rxInfo?.PrescReFill?.ReFillNum, PrescReFillId : this.rxInfo?.PrescReFill?.Id, PatientId:this.rxInfo?.Patient?.patientid, PrevNext:'P', isNewFrmExisting: this.rxFG?.controls?.isNewFrmExisting?.value, ReferencePrescReFillId: ReferencePrescReFillId }
                await this._rxServ.getRefillInsuList(req).subscribe(resp => {
                    if(resp?.length) {
                        this.rxInfo.RefillInsuList = resp;
                        this.getRxInsInfo();
                    } else {
                        this.getRxInsInfo();
                    }
                });
            } else {
                this.getRxInsInfo();
            }
    }
    }

    getRxInsInfo() {
        if (this.rxInfo) {           
            this.nxtInsuLst = this._transUtils.getRemainingInsuranceToBill(this.rxInfo, this.payRecords, this.systemData);            
            this.nxtInsuToBill = this._transUtils.checkHasNextInsurncs(this.rxInfo, this.payRecords, this.nxtInsuLst);
            if (this.nxtInsuToBill && this.nxtInsuLst && this.nxtInsuLst.length && +this.rxInfo.RefillInsu.InsuPriId < 3) {
                // this.rxInfo.RefillInsu.InsuPriId = this.rxInfo.RefillInsu.InsuPriId + 1;
                // this.rxInfo.RefillInsu["InsuId"] = this.nxtInsuToBill["insuranceid"];
                this.transActnType = "NxtInsuBillTo";
            } else {
                const event = {
                    isNoLabel: this.noLabel,
                    switchError: null,
                    rxInfoAfterTransmission: this.rxInfo
                };
                this.IsTransmsnCmpltd.emit(event);
            }
        }
    }
    checkAndInitiateNextForInsuarnce(insList) {
        if(insList)
           this.InitiateNextTransForSelectedIns.emit(insList);
    }
    checkAndInitiateNextTrans(val) {
        this.transActnType = null;
        this.InitiateNextTrans.emit(val);
    }

    showConnectionString() {
        const modalRef = this._mdlServ.open(ViewTransMsgComponent, {
            backdrop: false,
            size: "lg",
            windowClass: "md-x-lg_77",
            keyboard: false, centered : true
        });
        modalRef.componentInstance.RxInfo = this.rxInfo;
        modalRef.componentInstance.ViewTransString = this.transmResp ? this.transmResp.TransStrObject : null;
        modalRef.componentInstance.IsNotFromTrans = "false";
        modalRef.componentInstance.IsPopUpClosed.subscribe(resp => {
            modalRef.close();
            this.clearActionModalType();
        });
    }

    async redirectToEditRx() {
        await this.reviewTransmissionResult();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this.updateModifyTime();
        }
        await this._saveRxUtls.clearNewRxDataFrEdit(true);
        let rxInfo: any = await this._nrxUtil.getandStoreRxInfo(+this.rxInfo.Prescription.PrescNum, this.rxInfo.PrescReFill.Id, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtil.editRxPatchValues(rxInfo);
            await this._nrxUtil.createPatchStoreRxFG(rxInfo);
            this._nrxUtil.getCommonEndPoints(rxInfo);
        }
        const modalRef = this._mdlServ.open(
            EdRxComponent,
            {
                backdrop: false,
                size: "lg",
                windowClass: "md-xx-lg comp--modal",
                keyboard: false
            }
        );
        modalRef.componentInstance.CloseEditMode
            .subscribe(async x => {
                modalRef.close();
                await this._saveRxUtls.clearNewRxDataFrEdit(false);
                this.IsTransmsnCmpltd.emit(this.noLabel);
            });
    }

    accRejPopUp() {
        if (this.transmResp && this.transmResp.transmissionDetails &&
            (this.transmResp.transmissionDetails.ResponseStatus === "R" ||
             this.transmResp.transmissionDetails.ResponseStatus === "TRANSMISSION ERROR"
             ) ) {
            this._mdlServ.open(this.TRANSREJPOPUP, { centered: true, backdrop: false });
        } else {
            this.printAcceptRej();
        }
    }

    patchMessage() {
        let result = "";
        result = this.transmResp && this.transmResp.nCPDPRecv && this.transmResp.nCPDPRecv.MessageFQ ?
             this.transmResp.nCPDPRecv.MessageFQ.toString().replace(".", "")
            : !(this.transmResp.transmissionDetails.ResponseStatus === "R" ||
        this.transmResp.transmissionDetails.ResponseStatus === "TRANSMISSION ERROR") ?
        this.transmResp.ClaimProcess.objncpdRecv.MessageFQ ?
        this.transmResp.ClaimProcess.objncpdRecv.MessageFQ.toString().replace(".", "") : "" : "";
        if (this.transmissionType === "TransReverse" ||
         (this.transmitParams && this.transmitParams["TransType"] === "B2")) {
        result = result + (this.transmResp && this.transmResp.nCPDPRecv && this.transmResp.nCPDPRecv.MessageF4) ?
             this.transmResp.nCPDPRecv.MessageF4.toString().replace(".", "")
            : !(this.transmResp.transmissionDetails.ResponseStatus === "R" ||
        this.transmResp.transmissionDetails.ResponseStatus === "TRANSMISSION ERROR") ?
        this.transmResp.ClaimProcess.objncpdRecv.MessageF4 ?
        this.transmResp.ClaimProcess.objncpdRecv.MessageF4.toString().replace(".", "") : "" : "";
        }
        return result;
    }

   async printRejTrans(isPreview?) {
        const data = {
            prescRefillId: this.transmResp ? this.transmResp.transmissionDetails.PrescRefillId : null,
            IsCMSFormIncluded: this.cmsSelected,
            message: this.transmResp ? this.transmResp.transmissionDetails.RejectInfo : "",
            lstDUR: this.transmResp.transmissionDetails ? this.transmResp.transmissionDetails.lstDUR : null,
            transmissionmessage: this.patchMessage()
        };
        const resp = await this._reportServ.getPrintReportForRejTrans(data).toPromise();
        if (isPreview && resp && resp.size > 0 && resp.type === "application/pdf") {
            const urlCreator = window.URL;
            const urlCre = urlCreator.createObjectURL(resp);
            this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(urlCre + "#toolbar=0&zoom=115");
            this._modalService.open(this.PREVIEW, { size: "lg", backdrop: false });
        }
        else if ( resp && resp.size > 0 && resp.type === "application/pdf") {
            this._alertServ.success("Print has been initiate for Transmission Accept/Rejected screen.");
            const newBlob = new Blob([resp], { type: "application/pdf" });
            const reader = new FileReader();
            reader.readAsDataURL(newBlob);
            reader.onloadend = function() {
                const base64data = reader.result;
                this._printSer.printPdf(base64data);
            }.bind(this);
        } else {
            this._alertServ.error("unable to print");
        }
        this.checkReset();
    }
    sendFax() {
        // this.sendFaxFromTrans = true;
        this.selectedRx = this.rxInfo$["source"]["value"];
        const faxData = {
            PrescNum: this.selectedRx &&  this.selectedRx["Prescription"] && this.selectedRx["Prescription"]["PrescNum"] ?
            this.selectedRx["Prescription"]["PrescNum"] : null,
            DrugId: this.selectedRx["Drug"]["id"],
            PrescriberId: this.selectedRx["Prescriber"]["prescriberid"],
            DrugClass: this.selectedRx["Drug"]["drugclass"],
            Faxnumber: this.selectedRx["Prescriber"]["faxnumber"],
            Notes: "",
            RefillNum: this.selectedRx["PrescReFill"]["ReFillNum"],
            PartialFillNo: this.selectedRx["PrescReFill"]["PartialFillNo"],
            Subject: this._cmnService.getSetttingValue("FaxAndReportsSettings", "Fax_Subject"),
            PatientName: this.selectedRx["Patient"]["lastname"] + ", " + this.selectedRx["Patient"]["firstname"],
            PatientId: this.selectedRx["Patient"]["patientid"],
            PrescriberName: this.selectedRx["Prescriber"]["prescriberlastname"] + ", " + this.selectedRx["Prescriber"]["prescriberfirstname"],
            IsControlRx: this.selectedRx["Drug"]["drugclass"] >= 2 && this.selectedRx["Drug"]["drugclass"] <= 5 ? true : false
        };

        const data = {
            prescRefillId: this.transmResp ? this.transmResp.transmissionDetails.PrescRefillId : null,
            IsCMSFormIncluded: this.cmsSelected,
            message: this.transmResp ? this.transmResp.transmissionDetails.RejectInfo : "",
            lstDUR: this.transmResp && this.transmResp.ClaimProcess ? this.transmResp.ClaimProcess.lstRxDur : null
        };

        const MultiSingleRxPg = this._cmnService.getSetttingValue("FaxAndReportsSettings", "Refill_Request_Fax_Print_Format");
        const RefillRequestFaxInputParams = {
            Faxes: [faxData],
            IncludeCoverPage: false,
            FaxFormat: MultiSingleRxPg === "1" ? 2 : 1,
            IsBatchFax: false,
            FromFaxNumber: "18447783045",
            FaxPrefix: this._cmnService.getSetttingValue("FaxAndReportsSettings", "Fax_Prefix"),
            IsTransmissionRejectedFax: true,
            rejectedTransmissionReportParams: data
        };
        this._userService.sendSfaxRequest(RefillRequestFaxInputParams).subscribe((resp) => {
                // this.closeModal();
                if (resp) {
                    this._alertServ.success("Fax sent Successful.");
                } else {
                    this._alertServ.error("Fax sent Unsuccessful.");
                }
        });
    }
    closeSFax(value) {
        this.sendFaxFromTrans = false;
        this.checkReset();
    }
    checkReset() {
        this.cmsSelected = false;
    }

    async PriceCal(insuId) {
        if (this.rxInfo["rxType"] && this.rxInfo["rxType"] !== "er") {
            let rxFG = await this._nrxUtil.createPatchStoreRxFG();
            rxFG = this._patchRx.patchRxInfo(this.rxInfo, rxFG);
            this.rxFG = rxFG;
        } else {
            const rxFG: FormGroup = this._patchRx.createNewRxFG();
            rxFG.patchValue(this.rxInfo);
            this.rxFG = rxFG;
        }
        const patient = this.rxFG.get("Patient").value;
        let insuList = null;
        if (patient && patient.insurance && patient.insurance.length > 0) {
            insuList = patient.insurance.filter(insu => insu.insuranceid === +insuId)[0];
        }
        if (!insuList) {
            insuList = patient.insurance[0];
        }
        this.insuSetngs = this._insurService.insuSettings$["source"]["value"]? this._insurService.insuSettings$["source"]["value"] : null;
        if (insuList && insuList.insuplanId) {
            this.insuSetngs  = await this._insurService.getInsSettingsInfoForRx(insuList.insuplanId);
        }
        await this._billUtls.getInsuranceManualCalc(this.rxFG, this.insuSetngs);
        const priceSchedule = this.pricSchdlLst$["source"]["value"];
        const result = +(+this.rxFG.controls["PrescReFill"].value["TotalAmt"] - +this.rxFG.controls["PrescReFill"].value["ProfFee"]);
        this.rxFG.controls["PrescReFill"].value["Price"] = result > 0 ? result : 0;
        this.rxFG.controls["RefillInsu"].patchValue({InsuId: insuId});
        await this._billUtls.getPriceDetlsNdCalc(null, this.rxFG, priceSchedule, this.insuSetngs, this.rxFG.value.rxType);
        this.insuList = insuList;
    }
    getSideNavCounts() {
        const dateParams = {
            StartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
            EndDate: moment().subtract(0, "days").format("YYYY-MM-DD")
        };
        this._cmnService.getSideNavCounts(dateParams);
        }

        reTransmission(){
            let event = {
              rxInfo:  this.rxInfo,
              isRejectedRetransmission: true
            }
            this.rejectedReTransmission.emit(event);
        }
}
