<div class="row mx-0" [ngClass]="(IsFromRx) ? 'mr-5':'mx-0'">
    <div class="col-1 px-0 mt-2">
        <ul class="p-0">
            <li class="submit" [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'"
                (click)="modeSelected('pointer')" [ngbTooltip]="'Pointer'">
                <i class="far fa-mouse-pointer"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'"
                (click)="modeSelected('pencil')" [ngbTooltip]="'Pencil'">
                <i class="far fa-pencil"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'" (click)="modeSelected('brush')"
                [ngbTooltip]="'Brush'">
                <i class="far fa-paint-brush-alt"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'" (click)="modeSelected('text')"
                [ngbTooltip]="'Text'">
                <i class="far fa-text"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'" (click)="modeSelected('crop')"
                [ngbTooltip]="'Crop'">
                <i class="far fa-crop">
                </i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'"
                (click)="modeSelected('zoomin')" [ngbTooltip]="'Zoom In'">
                <i class="far fa-search-plus"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'"
                (click)="modeSelected('zoomout')" [ngbTooltip]="'Zoom Out'">
                <i class="far fa-search-minus"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'" (click)="insertShape('line')"
                [ngbTooltip]="'Line'">
                <i class="far fa-horizontal-rule"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'" (click)="insertShape('circle')"
                [ngbTooltip]="'Circle'">
                <i class="far fa-circle"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'" (click)="insertShape('rect')"
                [ngbTooltip]="'Rectangle'">
                <i class="far fa-rectangle-landscape"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'"
                (click)="modeSelected('rotateRight')" [ngbTooltip]="'Rotate Image'">
                <i class="far fa-redo"></i>
            </li>
            <li class="submit " [ngClass]="IsFromRx ? 'btn-sm-width2' : 'btn-sm-width1'" (click)="modeSelected('undo')"
                [ngbTooltip]="'Undo'">
                <i class="far fa-undo"></i>
            </li>
            <li class="pl-2" [ngbTooltip]="'Color'">
                <input class="form-control width_3_2rem" [(colorPicker)]="color"
                 [style.background]="color" [ngClass]="IsFromRx ? 'margin_left_57' : 'margin-left_14'"
                    (colorPickerChange)="colorChange($event)" title="Font Color" />
            </li>
        </ul>
    </div>
    <div class="col-11 px-0" [ngClass]="(IsFromRx) ? '':'common_scroll'">
        <div class="exprx--common-block erx--body insurance--body">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    <span>Edit Image Options</span>
                    <ul class="pull-right eprx--header__icons" *ngIf="(!cropMode)">
                        <li>
                            <a (click)="saveImage()">
                                <button class="hotkey_success--Override hotkey_primary--Override">SAVE</button>
                            </a>
                        </li>
                        <li>
                            <a (click)="closeModal()">
                                <button class="hotkey_success--Override hotkey_primary--Override">CANCEL</button>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div [hidden]="cropMode" class="container-canvas image-edit width_100per float-left" id="canvasDf"
                            >
                            <canvas id="canvasArea" class="canvasArea width_100per"></canvas>
                        </div>
                        <app-image-editor-crop *ngIf="cropMode" [IsCropMode]="currentMode=='crop'"
                            [InputImage]="cropInputImage" (CloseImageCropEditor)="closeCrop($event)">
                        </app-image-editor-crop>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
           <!--<div style="width: 3rem;position: absolute;left: -30px;top:0px;">
                           <button class="submit btn-sm-width1" (click)="modeSelected('pointer')" [ngbTooltip]="'Pointer'">
                                <i class="far fa-mouse-pointer"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="modeSelected('pencil')" [ngbTooltip]="'Pencil'">
                                <i class="far fa-pencil"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="modeSelected('brush')" [ngbTooltip]="'Brush'">
                                <i class="far fa-paint-brush-alt"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="modeSelected('text')" [ngbTooltip]="'Text'">
                                <i class="far fa-text"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="modeSelected('crop')" [ngbTooltip]="'Crop'">
                                <i class="far fa-crop">
                                </i>
                            </button>-->
                            <!-- <button class="submit btn-sm-width1" (click)="modeSelected('shapes')" [ngbTooltip]="'Shapes'">
                                <i class="far fa-shapes"></i>
                            </button> -->
                                                       <!--   <button class="submit btn-sm-width1" (click)="modeSelected('zoomin')" [ngbTooltip]="'Zoom In'">
                                <i class="far fa-search-plus"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="modeSelected('zoomout')" [ngbTooltip]="'Zoom Out'">
                                <i class="far fa-search-minus"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="insertShape('line')" [ngbTooltip]="'Line'">
                                <i class="far fa-horizontal-rule"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="insertShape('circle')" [ngbTooltip]="'Circle'">
                                <i class="far fa-circle"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="insertShape('rect')" [ngbTooltip]="'Rectangle'">
                                <i class="far fa-rectangle-landscape"></i>
                            </button>
                            <button class="submit btn-sm-width1" (click)="modeSelected('rotateRight')" [ngbTooltip]="'Rotate Image'">
                                <i class="far fa-redo"></i>
                            </button>-->
                           <!-- <button class="submit btn-sm-width1" (click)="modeSelected('rotateLeft')" [ngbTooltip]="'Rotate Image'">
                                <i class="far fa-undo"></i>
                            </button>-->
                                                      <!--   <button class="submit btn-sm-width1" (click)="modeSelected('undo')" [ngbTooltip]="'Undo'">
                                <i class="far fa-undo"></i>
                            </button>-->
                            <!-- <button class="submit btn-sm-width1" (click)="insertShape('triangle')" [ngbTooltip]="'Triangle'">
                                <i class="far fa-triangle"></i>
                            </button> -->
                                                      <!--   <div class="pl-2" [ngbTooltip]="'Color'">
                                <input class="form-control" [(colorPicker)]="color" [style.background]="color"
                                (colorPickerChange)="colorChange($event)" title="Font Color" />
                            </div>
                        </div>-->
    <!-- <div class="col-2">
        <div class="exprx--common-block scanner--options">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    <div class="col-sm-12 padding-0">
                        <div class="row">
                            <div class="col-md-10">
                                <span>Tools</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content scanner--options--body">
                <div class="row">
                    <div class="col-6">
                        <button class="hotkey_primary" (click)="modeSelected('pointer')" [ngbTooltip]="'Pointer'">
                            <i class="far fa-mouse-pointer"></i>
                        </button>
                        <button class="hotkey_primary" (click)="modeSelected('pencil')" [ngbTooltip]="'Pencil'">
                            <i class="far fa-pencil"></i>
                        </button>
                        <button class="hotkey_primary" (click)="modeSelected('text')" [ngbTooltip]="'Text'">
                            <i class="far fa-text"></i>
                        </button>
                        <button class="hotkey_primary" (click)="modeSelected('zoomin')" [ngbTooltip]="'Zoom In'">
                            <i class="far fa-search-plus"></i>
                        </button>
                    </div>
                    <div class="col-6 padding-0">
                        <button class="hotkey_primary" (click)="modeSelected('crop')" [ngbTooltip]="'Crop'">
                            <i class="far fa-crop"></i>
                        </button>
                        <button class="hotkey_primary" (click)="modeSelected('brush')" [ngbTooltip]="'Brush'">
                            <i class="far fa-paint-brush-alt"></i>
                        </button>
                        <button class="hotkey_primary" (click)="modeSelected('shapes')" [ngbTooltip]="'Shapes'">
                            <i class="far fa-shapes"></i>
                        </button>
                        <button class="hotkey_primary" (click)="modeSelected('zoomout')" [ngbTooltip]="'Zoom Out'">
                            <i class="far fa-search-minus"></i>
                        </button>
                    </div>
                    <div class="col-12">
                        <input class="form-control" [(colorPicker)]="color" [style.background]="color"
                            (colorPickerChange)="colorChange($event)" title="Font Color" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-10" *ngIf="cropMode">
        <app-image-editor-crop [InputImage]="cropInputImage" (CloseImageCropEditor)="closeCrop($event)">
        </app-image-editor-crop>
    </div>
    <div class="col-10" [hidden]="cropMode">
        <div class="exprx--common-block erx--body insurance--body">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    <div class="col-sm-12 padding-0">
                        <div class="row">
                            <div class="col-md-10">
                                <span>Toolbar</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content scanner--options">
                <div class="row scanner--options--body" *ngIf="currentMode === 'shapes'">
                    <div class="col-6">
                        <button class="hotkey_primary" (click)="insertShape('line')" [ngbTooltip]="'Line'">
                            <i class="far fa-horizontal-rule"></i>
                        </button>
                        <button class="hotkey_primary" (click)="insertShape('circle')" [ngbTooltip]="'Circle'">
                            <i class="far fa-circle"></i>
                        </button>
                        <button class="hotkey_primary" (click)="insertShape('rect')" [ngbTooltip]="'Rectangle'">
                            <i class="far fa-rectangle-landscape"></i>
                        </button>
                        <button class="hotkey_primary" (click)="insertShape('triangle')" [ngbTooltip]="'Triangle'">
                            <i class="far fa-triangle"></i>
                        </button>
                    </div>
                    <div class="col-2">
                        <button class="hotkey_primary" [(colorPicker)]="shapesFillColor"
                            (colorPickerChange)="fillColor($event)" [ngbTooltip]="'Color'">
                            <i class="far fa-fill-drip" [style.color]="shapesFillColor"></i>
                        </button>
                    </div>
                    <div class="col-3">
                        <label>Opacity</label>
                        <input type="range" id="opacity" min="0.1" max="1" step="0.1" value="1"
                            (change)="changeOpacity($event?.target?.value)">
                    </div>
                </div>
            </div>
        </div>
    </div> -->