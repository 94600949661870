<ng-template #DRUGINDICATION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeDrugIndicationPopUp()">
        <h4 header>Drug Indication</h4>
        <span body>
            <div class="eprx--block__content">
                <h3>Indications</h3>
                <wj-flex-grid
                    #indiInfo
                    [isReadOnly]="true"
                    [headersVisibility]="'Column'"
                    [itemsSource]="indicationWJ"
                    (initialized)="init(indiInfo)"
                    [selectionMode]="'None'"
                >
                    <wj-flex-grid-column
                        [header]="drugactvHeader"
                        [binding]="drugactvHeader"
                        [visible]="true"
                        *ngFor="
                            let drugactvHeader of ['Description'];
                            let i = index
                        "
                        [width]="'*'"
                    >
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isIndictnExists"></app-no-data-found>
                </wj-flex-grid>
                <h3>Beer Information</h3>
                <wj-flex-grid
                    #cautionInfo
                    [isReadOnly]="true"
                    [headersVisibility]="'Column'"
                    [itemsSource]="cautionWJ"
                    (initialized)="init(cautionInfo)"
                    [selectionMode]="'None'"
                >
                    <wj-flex-grid-column
                        [header]="drugactvHeader"
                        [binding]="drugactvHeader"
                        [visible]="true"
                        *ngFor="
                            let drugactvHeader of cautionHeader;
                            let i = index
                        "
                        [width]="drugactvHeader === 'CAUTION' ? 700 : '*'"
                        [wordWrap]="true"
                    >
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isCautionExists"></app-no-data-found>
                </wj-flex-grid>
            </div>
        </span>
        <button
            footer autofocus
            class="hotkey_primary"
            (click)="closeDrugIndicationPopUp()"
            appShortcutKey InputKey="o"
        >
          <b>O</b>  OK
        </button>
    </app-custom-modal>
</ng-template>
