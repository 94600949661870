import { Size } from "@grapecity/wijmo";
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, OnChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { DateFormatPipe } from "src/app/pipes";
import { NgbDateStruct, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { PrivMaskGuard } from "src/app/guards";
import { Console } from "console";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "app-date-picker-ref",
    templateUrl: "./date-picker.component.html"
})
export class DatePickerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    hasFGInvalid: boolean;
    autoFocus: boolean;
    isFirstElem = false;
    datePicker = new DateFormatPipe();
    showMask = false;
    maskPattern: any;
    formatType: string;
    selected: NgbDateStruct;
    buttonTabIndex: number = -1;
    isNumberType = false;
    // date: {year: number, month: number};
    inputType = "text";
    dropSpclChar = true;
    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set PlaceHolder(ph: string) {
        this.placeHolder = ph;
    }

    @Input()
    set AutoFocus(ti: boolean) {
        this.autoFocus = ti;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }

    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }

    @Input()
    set MinDate(mn: any) {
        if (mn) {
            this.minDate = mn;
        }
    }

    @Input()
    set MaxDate(md: any) {
        if (md) {
            this.maxDate = {
                year: +md.year,
                month: +md.month,
                day: +md.day
            };
            let todayDate = {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate()
            };

            if (moment(moment(this.maxDate).format("YYYY-MM-DD")).isAfter(todayDate)) {
                this.maxDate = todayDate;
            }
        }
    }

    @Input()
    set IsDisabled(id: boolean) {
        this.isDisabled = id;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
        if (ir) {
            this.inputFormGroup.controls[this.custContrlName].setValidators([Validators.required]);
        }
    }

    @Input()
    set ReadOnly(mp: boolean) {
        this.isReadOnly = mp;
    }

    @Input()
    set HasControl(hc: boolean) {
        this.hasControl = hc;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set ButtonTabIndex(ti: number) {
        this.buttonTabIndex = ti;
    }

    @Input()
    set FormGroupInvalid(hc: boolean) {
        this.hasFGInvalid = hc;
        if (this.hasFGInvalid) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }

    @Input()
    set IsFirstElem(value: any) {
        this.isFirstElem = value;
    }

    @Output()
    TriggerOnDateSelect = new EventEmitter();

    @Output()
    TriggerOnEnterValue = new EventEmitter<{ value: string }>();

    @Output()
    TriggerOnTabbing = new EventEmitter<any>();

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();

    labelText: string;
    placeHolder: string;
    inputErrors: any;
    errorDefs: any;
    errorMessage: string;
    inputFormGroup: FormGroup;
    custContrlName: string;
    maxDate: any;
    minDate: any;
    isDisabled: boolean;
    isRequired: boolean;
    isReadOnly: boolean;
    hasControl = true;
    tabIndex: number;
    rxID: any;
    isEmitted = false;
    format: any = "";
    a: any = "";
    b: any = "";

    constructor(private _validFormFieldsUtils: ValidateFormFieldsUtils, private _cdr: ChangeDetectorRef, private _element: ElementRef) {
        const date = moment(new Date());
        const defaultDate: any = {
            year: date.year() - 125,
            month: date.month() + 1,
            day: date.date()
        };
        this.minDate = defaultDate;
    }

    ngOnInit() {
        // $(":input").inputmask();
     }

    ngOnChanges(changes?: any) {
        if (changes && this.inputFormGroup && this.custContrlName) {
            let value = this.inputFormGroup.value[this.custContrlName];
            value = this.datePicker.transform(value);
            if (value) {
                let date;
                if (value.includes("/")) {
                    date = value.split("/");
                    this.selected = { year: +date[2], month: +date[0], day: +date[1] };
                } else if (value.includes("-")) {
                    // date = value.split("T")[0].split("-");
                    this.selected = { year: +date[0], month: +date[1], day: +date[2] };
                }
            }
        }
    }

    changeValue(val: { year; month; day }) {
        this.selected = val;
        let value = "" + val.month + "/" + val.day + "/" + val.year;
        value = moment(value).format("MM/DD/YYYY");

        if (document.getElementById(this.rxID)) {
            document.getElementById(this.rxID).focus();
        }
        if (this.inputFormGroup.value[this.custContrlName] !== value) {
            // if (!this.isEmitted) {
            this.TriggerOnDateSelect.emit(value);
            //     this.isEmitted = !this.isEmitted;
            // }

            if (this.hasControl) {
                this.inputFormGroup.controls[this.custContrlName].setValue(value);
                this.inputFormGroup.controls[this.custContrlName].markAsDirty();
            }
        } else {
            this.inputFormGroup.controls[this.custContrlName].patchValue(this.datePicker.transform(value));
            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
        }
    }

    onDatePickerOpen(event) {
        if (this.inputFormGroup.controls[this.custContrlName].value) {
           this.selected = {
               year: moment(this.inputFormGroup.controls[this.custContrlName].value).year(),
            month: moment(this.inputFormGroup.controls[this.custContrlName].value).month() + 1,
            day: moment(this.inputFormGroup.controls[this.custContrlName].value).date()
           };          
            event.toggle();
        } else {
            event.toggle();
        }
    }

    changeNullValue(val) {
        if (val) {
            this.inputFormGroup.controls[this.custContrlName].setValue(val);
        } else {
            this.inputFormGroup.controls[this.custContrlName].setValue(null);
        }
    }

    validateAllFormFields(formGroup?: FormGroup, formCntrl?: string) {
        this.errorMessage = this._validFormFieldsUtils.validateFormFields(formGroup, formCntrl, this.errorDefs);
    }

    onEnter(e: any, datePick: any): void {
        this.onTabbingEvent(e, datePick);
    }

    emitOnKeyDown(e?: any) {
        if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105) ) {
            let inputVal = e.target.value;
            if (inputVal) {
                let inputValArr = inputVal.replace(/[^0-9]/g, "").match(/.{1,2}/g);
                if (inputValArr.length > 3) {
                    inputVal = inputValArr[0] + "/" + inputValArr[1] + "/" + inputValArr[2] + inputValArr[3];
                } else {
                    inputVal = inputValArr.join("/");
                }
                e.target.value = inputVal;
            }
        }


        // if (/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/.test(e.target.value)) {

            // let charCode = (e.which) ? e.which : e.keyCode;
            // if (e.target.value && charCode > 31 && (charCode > 47 || charCode < 58)) {
            // if (e.target.value.length > 1) {
            //         if (e.target.value.length > 7 && e.target.value.length < 11) {
            //             e.target.value = e.target.value.substring(0, 2) + "/" + e.target.value.substring(2, 4) + "/"
            //                              + e.target.value.substring(4, 10);
            //             e.target.value = moment(e.target.value).format("MM/DD/YYYY");
            //         }

            //     }
            //  }

    }

    // To manually trigger change detection for the current component
    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
    }

    onTabbingEvent(e, datePick: any) {
        if (/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/.test(e.target.value)) {
            const dateVal = moment(e.target.value).format("MM/DD/YYYY");
            // const dateVal = this.format + "/" + e.target.value.substr(6, 10);
            if (moment(dateVal, "MM/DD/YYYY", true).isValid()) {
                const value = e.target.value;
                let data = null;
                let min = null;
                if (this.maxDate) {
                    data = moment(moment(this.maxDate.year + "-" + this.maxDate.month + "-" + this.maxDate.day).format("YYYY-MM-DD"));
                }

                if (this.minDate) {
                    min = moment(moment(this.minDate.year + "-" + this.minDate.month + "-" + this.minDate.day).format("YYYY-MM-DD"));
                }

                if (min || data) {
                    if (data && value && !moment(moment(dateVal).format("YYYY-MM-DD")).isAfter(data)) {
                        if (value && !moment(moment(min).format("YYYY-MM-DD")).isAfter(dateVal)) {
                            this.inputFormGroup.controls[this.custContrlName].setValue(
                                this.inputFormGroup.value[this.custContrlName] ?  this.inputFormGroup.value[this.custContrlName] : dateVal);
                        } else {
                            e.preventDefault();
                            // this.inputFormGroup.controls[this.custContrlName].setValue(null);
                            this.inputFormGroup.controls[this.custContrlName].setErrors({ "minDate": true });
                            // this.inputFormGroup.controls[this.custContrlName].updateValueAndValidity();
                            this._cdr.detectChanges();
                        }
                    } else {
                        if (min && value && !moment(moment(min).format("YYYY-MM-DD")).isAfter(dateVal) &&
                            !moment(moment(dateVal).format("YYYY-MM-DD")).isAfter(data)) {
                                this.inputFormGroup.controls[this.custContrlName].setValue(
                                this.inputFormGroup.value[this.custContrlName] ?  this.inputFormGroup.value[this.custContrlName] : dateVal);
                        } else {
                            e.preventDefault();
                            // this.inputFormGroup.controls[this.custContrlName].setValue(null);
                            this.inputFormGroup.controls[this.custContrlName].setErrors({ "maxDate": true });
                           // this.inputFormGroup.controls[this.custContrlName].updateValueAndValidity();
                            this._cdr.detectChanges();
                        }
                    }
                } else {
                    this.inputFormGroup.controls[this.custContrlName].setValue(
                        this.inputFormGroup.value[this.custContrlName] ?  this.inputFormGroup.value[this.custContrlName] : dateVal);
                }
            } else {
                e.preventDefault();
                // this.inputFormGroup.controls[this.custContrlName].setValue(null);
                this.inputFormGroup.controls[this.custContrlName].setErrors({ inValid: true });
               // this.inputFormGroup.controls[this.custContrlName].updateValueAndValidity();
                this._cdr.detectChanges();
            }
        } else if (e.target.value || e.target.value === "") {
            // this.inputFormGroup.controls[this.custContrlName].setValue(null);
            if (this.isRequired) {
                e.preventDefault();
                this.inputFormGroup.controls[this.custContrlName].setErrors({ Required: true });
                this.inputFormGroup.controls[this.custContrlName].setErrors({ inValid: true });
            }
           // this.inputFormGroup.controls[this.custContrlName].updateValueAndValidity();
            this._cdr.detectChanges();
        }
        this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
        if (datePick) {
            datePick.close();
        }
    }

    async emitAfterComplt(e, datePicker: any) {
        await this.onTabbingEvent(e, datePicker);
        if (this.inputFormGroup && this.inputFormGroup.controls[this.custContrlName].valid) {
            this.TriggerOnTabbing.emit(e);
        } else {
            if (this.hasControl) {
                if (this.inputFormGroup.controls[this.custContrlName].invalid) {
                    e.preventDefault();
                    let input = this._element.nativeElement.getElementsByTagName("input");
                    this.b = input[0].value;
                    input[0].focus();
                    this.inputFormGroup.controls[this.custContrlName].updateValueAndValidity();
                }
            }
        }


    }

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab") {
            this.TriggerOnShiftTabbing.emit(event);
        }
    }

    onNavigate() {
        this.selected = { year: 1789, month: 7, day: 14 };
    }

    foucsDatePicker() {
        // this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
        this.inputFormGroup.controls[this.custContrlName].markAsTouched();
    }
}
