<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Miscellaneous'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                <button class="hotkey_success" id="SaveButton" (click)="updateDrug('U', null, null)" appShortcutKey [AltKey]="'s'"><span>S</span> Save </button>
                <button class="hotkey_success" (keydown.tab)="focusOutFmCan($event)" (click)="CancelPopTaggle()" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="row exprx--common-block">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15 flex--one">
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <div class="row">
                        <div class="col">
                            <span class="eprx--header__heading"> Miscellaneous Information</span>
                        </div>
                        <div class="col pull-right text-right">
                            <button aria-hidden="true" class="hotkey_primary hotkey_primary--Override" title="Add Lot" (click)="openPopUpModal(vendoritem)" appShortcutKey [CtrlKey]="'a'"><span>A</span>ADD</button>
                        </div>
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col" *ngIf="hasLot">
                            <wj-flex-grid #lotList [headersVisibility]="'Column'" [itemsSource]="lotInfoWJ" [isReadOnly]="true" [columnPicker]="''"
                                [selectionMode]="'Row'" (updatedView)="init(lotList)" class="grid">
                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders  ; let i = index"
                                    [width]="(actvHeader === 'IsActive') ? 100 : 238">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="actvHeader === 'Actions'" class="text-center">
                                            <i class="far fa-edit fa-sm  actions" title="edit" (click)="editLotPopUpModal(vendoritem,item)"></i>
                                            <!-- <i class="far fa-trash fa-sm actions " title="delete" (click)="deleteWarPopUp(deleteLot, item)"></i> -->
                                        </div>
                                        <div *ngIf="actvHeader === 'IsActive'" class="text-center">
                                                <input class="form-check-input" type="checkbox" [id]="item.Id" name="" [(ngModel)]="item.IsActive"
                                                (change)="deleteWarPopUp($event?.target?.checked, item)" [checked]="item.IsActive" />
                                            <label class="form-check-label" [for]="item.Id"> </label>
                                        </div>
                                        <div *ngIf="!((actvHeader === 'Actions') || (actvHeader === 'IsActive'))">
                                            {{item[actvHeader]}}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <eprx-select [RxSelectId]="'Automated'" [LabelText]="'Automated Machine ID'" [PlaceHolder]="'Automated Machine ID'" [ControlName]="'AutoMcId'" [FormGroupName]="DrugMisc"
                                    [IsFirstElem]="true && !isFromCompound" [HasMultiple]="false" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.AutoMc"
                                        [BindLabel2]="'Name'" [ShowLabel2]="true">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <eprx-select [LabelText]="'Inventory Location'" [PlaceHolder]="'Inventory Location'" [ControlName]="'InvLocId'" [FormGroupName]="DrugMisc"
                                        [HasMultiple]="false" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.InvLoc"
                                        [BindLabel2]="'Name'" [ShowLabel2]="true">
                                    </eprx-select>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label class=" col-12 padding--left__7">Unit Dose Drug?</label>
                                    <eprx-radio [ControlName]="'IsUnitDrug'" [FormGroupName]="DrugMisc" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['yes', 'no']">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label class=" col-12 padding--left__7" title="Medication Guide Available?">Med Guide?</label>
                                    <eprx-radio [ControlName]="'IsMedGuide'" [FormGroupName]="DrugMisc" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['1', '0']">
                                    </eprx-radio>
                                </div>
                                <div class="col-sm-12 col-sm-12 col-sm-12 col-sm-12">
                                    <div class="row">
                                        <div class="col-xl-4">
                                            <eprx-input [LabelText]="'Packaging Machine Id'" [PlaceHolder]="'Add Packaging ID'" [ControlName]="'Name'" [FormGroupName]="Pkgmc"
                                                [MaxLength]="1" (TriggerOnEnterValue)="getPKD()">
                                            </eprx-input>
                                        </div>
                                        <div class="col-xl-8 margin_top_20px">
                                            <ul>
                                                <div class="row"  [formGroup]="drugEditFG" *ngIf="haspkgValue">
                                                    <div class="col-sm-2 input-group" *ngFor="let drug of PkgMc?.controls; let i=index" formArrayName="PkgMc">
                                                        <li>{{miscInfo?.PkgMc[i]?.Name}}</li>
                                                        <i class="input-group-btn far fa-trash-alt actions" (click)="deletePKDWarn(deletePKD, i)"></i>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15 flex--one">
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Compound Related Values
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <!-- <eprx-select [LabelText]="'NDC-Q'" [PlaceHolder]="'NDC-Q'" [ControlName]="'NDCQualiId'" [FormGroupName]="Drug" [HasMultiple]="false"
                                [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="systemData?.NdcQualifier">
                            </eprx-select> -->
                            <eprx-select [RxSelectId]="'DoseFrmDesc'" [LabelText]="'Dosage Form Description'" [PlaceHolder]="'Dosage Form Description'" [ControlName]="'DoseFrmDescId'"
                                [FormGroupName]="DrugMisc" [HasMultiple]="false" [BindLabel]="'Id'" [BindLabel2]="'Name'" [BindValue]="'Id'"
                                [LabelForId]="'Id'" [List]="miscInfo?.DoseFrmDesc" [ShowLabel2]="true" [IsFirstElem]="isFromCompound">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-select [LabelText]="'Dispensing Unit Form'" [PlaceHolder]="'Dispensing Unit Form'" [ControlName]="'DispUnitFormId'"
                                [FormGroupName]="DrugMisc" [HasMultiple]="false" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                [List]="miscInfo?.DispUnitForm" [BindLabel2]="'Name'" [ShowLabel2]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-3 col-xs-3 padding--right__7">
                            <eprx-select [LabelText]="'Route of Administration'" [PlaceHolder]="'Route of Administration'" [ControlName]="'AdmnstrnRouteId'"
                                [FormGroupName]="DrugMisc" [HasMultiple]="false" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                [List]="miscInfo?.AdmnstrnRoute" [BindLabel2]="'Name'" [ShowLabel2]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-select [LabelText]="'Compound Type'" [PlaceHolder]="'Compound Type'" [ControlName]="'CompoundTypId'" [FormGroupName]="DrugMisc"
                                [HasMultiple]="false" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.CompoundTyp"
                                [BindLabel2]="'Name'" [ShowLabel2]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-select [LabelText]="'Route of Administration(SNOMED)'" [PlaceHolder]="'Route of Administration(SNOMED)'" [ControlName]="'AdmnstrnRoute2Id'"
                                [FormGroupName]="DrugMisc" [HasMultiple]="false" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                [List]="miscInfo?.AdmnstrnRoute2" [BindLabel2]="'Name'" [ShowLabel2]="true">
                            </eprx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 flex--one">
                    <div class="box box-default box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Rx Related Values
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                    <eprx-select [LabelText]="'Dosage Form Code'" [PlaceHolder]="'Dosage Form Code'" [ControlName]="'DoseFrmCodeId'" [FormGroupName]="DrugMisc"
                                        [HasMultiple]="false" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.DoseFrmCode"
                                        [BindLabel2]="'Name'" [ShowLabel2]="true">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                    <eprx-select [LabelText]="'Drug Strength Qualifier'" [PlaceHolder]="'Drug Strength Qualifier'" [ControlName]="'DrugStrQualId'"
                                        [FormGroupName]="DrugMisc" [HasMultiple]="false" [BindLabel]="'Code'" [BindValue]="'Id'"
                                        [LabelForId]="'Name'" [List]="miscInfo?.DrugStrQual" [BindLabel2]="'Name'" [ShowLabel2]="true">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-3 col-xs-3 padding--right__7">
                                    <eprx-select [LabelText]="'Potency Unit Code'" [PlaceHolder]="'Potency Unit Code'" [ControlName]="'PotencyUnitCodeId'" [FormGroupName]="DrugMisc"
                                        [HasMultiple]="false" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.PotencyUnitCode"
                                        [BindLabel2]="'Name'" [ShowLabel2]="true">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                    <eprx-select [LabelText]="'Quantity Qualifier'" [PlaceHolder]="'Quantity Qualifier'" [ControlName]="'QtyQualId'" [FormGroupName]="DrugMisc"
                                        [HasMultiple]="false" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.QtyQual"
                                     (keydown.tab)="focusToFirst($event)"   [BindLabel2]="'Name'" [ShowLabel2]="true">
                                    </eprx-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #vendoritem let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Drug Lot</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-input [LabelText]="'Lot Number :'" [PlaceHolder]="'Lot Number'" [IsRequired]="true" [ControlName]="'LotNum'" [FormGroupName]="Druglot"
                [InputType]="'ALPHANUMERIC'" [MaxLength]="10" [FormGroupInvalid]="formGroupInvalid" [ErrorDefs]="{required: 'Required'}" [AutoFocus]="true">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-date-picker [LabelText]="'Expiration Date :'" [PlaceHolder]="'Expiration Date'" [ControlName]="'ExpDt'" [FormGroupName]="Druglot"
                    [MinDate]="minDate" [MaxDate]="" [ErrorDefs]="{required: 'Required'}" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid">
                </eprx-date-picker>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="addLot()" *ngIf="!editMode" appShortcutKey [AltKey]="'s'"><span>S</span>Save</button>
                <button class="hotkey_success" (click)="editLot()" *ngIf="editMode" appShortcutKey [AltKey]="'u'"><span>U</span>Update</button>
                <button class="hotkey_success" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #deletePKD let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{warningText?.deleteConfrmMsg}}
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deletePKDdata()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c"><b>C</b>CANCEL</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #deleteLot let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to InActive druglot?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteLotdata()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c"><b>C</b>CANCEL</button>
            </div>
        </div>
    </div>
</ng-template>
<app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)"></app-cancel-pop-up>
