<div class="modal-header border-top-right-radius_10">
    <h4 class="modal-title">Select Insurance from eRx to update on Patient’s Profile </h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="closePopUp()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body exprx--common-block erx--body edit--drug newrx--body">
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <a class="nav-link3 text-white border-top-left-radius_10">
                    <span class="font_size_16">
                        Patient’s existing Insurance
                    </span>
                </a>
                <a class="nav-link4  bg-white text-white mt-0">
                    <span class="ttext">
                        <span  class="color_lightSkyBlue">INSURANCE</span>: (BIN#; PCN;
                        GROUP)
                    </span>
                </a>
                <a class="nav-link4 text-white" *ngFor="let item of patientInsurances"
                [ngClass]="(item.isSameInsurance)?'active_bar ':'bg-white'">
                    <span class="ttext">
                        <span class="color_lightSkyBlue"> {{item?.insurancename | uppercase}}:
                        </span>
                        <span> ({{(item?.binnum) ? (item?.binnum + '' | uppercase): '  -'}};  </span>
                        <span>{{(item?.ProcessorNum) ? (item?.ProcessorNum+'' | uppercase) : '  -'}};  </span>
                        <span> {{(item?.groupname) ? (item?.groupname | uppercase): '  - '}})</span>
                    </span>
                </a>
            </div>
            <div class="col-sm ml-1">
                <a class="nav-link3 text-white border-top-right-radius_10">
                    <span class="font_size_16">
                        eRx Benefits Co-ordination
                    </span>
                </a>
                <a class="nav-link4 bg-white text-white mt-0">


                    <span class="ttext">
                        <span class="color_lightSkyBlue">INSURANCE</span>: (BIN#; PCN;
                        GROUP)
                    </span>
                </a>
                <a class="nav-link4  text-white"  *ngFor="let item of selectedInsuranceDetails"
                 [ngClass]="(item.isSameInsurance)?'active_bar ':
                 ((!item['InsName']) ? 'light_orange_forBg' : 'bg-white')">


                    <span class="ttext2"  [title]="item.isSameInsurance ? 'Already added to patient' : ''">
                        <input *ngIf="item['InsName']" type="checkbox" [disabled]="item.isSameInsurance"

                        (click)="selectData(item,$event?.target?.checked)"
                        [checked]="item.checked"  [(ngModel)]="item.checked"
                          id="i" name="" class="form-check-input common_checkBox_size">    &nbsp;
                        <span *ngIf="item['InsName']"
                                 class="color_lightSkyBlue padding_left_23px">
                                {{item['InsName']  ? (item['InsName'] + '' | uppercase) : '  -'}}:
                         </span>
                         <span>
                            <i *ngIf="(!item['InsName'] && item['ProcessorIdentificationNumber'] && item['BINLocationNumber'])"
                            class="far fa-plus-circle plus_icon_withoutHover"
                            title="Insurance does not exist on the pharmacy file. Please add as new insurance."
                            (click)="routeToAddInsurance(item)"
                            ></i>
                         </span>
                         <span *ngIf="(!item['InsName'] && ((item['ProcessorIdentificationNumber']
                         && !item['BINLocationNumber']) ||  (!item['ProcessorIdentificationNumber']
                         && item['BINLocationNumber'])))">
                            --
                         </span>
                                &nbsp;
                                <span [title]="item['BINLocationNumber']" >
                                     ({{item['BINLocationNumber']  ?
                                    (item['BINLocationNumber'] +'' | uppercase ): '  -'}}</span>

                                <span  [title]="item['ProcessorIdentificationNumber']"
                                [ngClass]="(item['ProcessorIdentificationNumber']?.length>17) ? 'text_overflow_eRx_ExtraPopUp':''">;     {{(item['ProcessorIdentificationNumber']) ? item['ProcessorIdentificationNumber'] + '' : '-'}}</span>

                                <span [ngClass]="(item['GROUP NAME']?.length>17) ? 'text_overflow_eRx_ExtraPopUp':''">;     {{item['GROUP NAME'] ? (item['GROUP NAME'] + '' | uppercase) : '     -   '}}  </span>)

                    </span>

                </a>

            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button"  *ngIf="(selecteRow && selecteRow.length);else other_content1"   class="hotkey_success" (click)="addAdditionalInsu('additional')" appShortcutKey
        [AltKey]="'a'"><span>A</span> Add as Additional Insu</button>
        <ng-template #other_content1>
            <button type="button"   class="inactive" ><span>A</span>  Add as Additional Insu</button>
        </ng-template>
        <button type="button" class="hotkey_success" (click)="closePopUp()" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>



