<div class="eprx--block__content presc-Sml"   *ngIf="((rxPatientModel && prescriber && drugInfo?.id) || isFromErx==='true') && (!rxFG?.value['frmInprog'] || rxFG?.value['afterDrugInactv'])">
    <div class="row">
        <div class="col pcard--sleek__grid">
            <label>DOB</label>
            <div class="label--data">
                {{ rxPatientModel?.dob | date: "MM/dd/yyyy" }}
            </div>
        </div>
        <div class="col pcard--sleek__grid">
            <label>Gender</label>
            <div class="label--data">
                {{ rxPatientModel?.gender ? rxPatientModel?.gender  : "--" }}
            </div>
        </div>
        <div class="col pcard--sleek__grid">
            <label>Age</label>
            <div class="label--data">{{ age ? age : "--" }}</div>
        </div>
        <div class="col-2 position-relative" *ngIf="Is340BStatus"> 
            <span class="position-340">
                340B 
            </span> 
        </div>
        <div class="col-8 pcard--sleek__grid sml-box semi-sml">
            <label>Address</label>
            <div class="label--data">
                <span *ngIf="rxPatientModel?.address1 && rxPatientModel?.address1 !== '###'">
                    {{rxPatientModel?.address1}},
                </span>
                <span *ngIf="rxPatientModel?.address2 && rxPatientModel?.address2 !== '###'">
                    {{rxPatientModel?.address2}},
                </span>
                <span *ngIf="rxPatientModel?.city && rxPatientModel?.city !== '###'">
                    {{rxPatientModel?.city}},
                </span>
                <span *ngIf="rxPatientModel?.state && rxPatientModel?.city !== '###'">
                        {{rxPatientModel?.state}},
                </span>
                <span *ngIf="rxPatientModel?.zipcode && rxPatientModel?.zipcode !== '###'">
                    {{rxPatientModel?.zipcode}}
                </span>
            </div>
        </div>
        <div class="col-4 pcard--sleek__grid">
            <label>Phone#</label>
            <div class="label--data">
                <span>{{rxPatientModel?.mobile ? (rxPatientModel?.mobile | mask : "(000)000-0000"):"--"}}</span>,
                <span>{{rxPatientModel?.telephone ? (rxPatientModel?.telephone | mask : "(000)000-0000"):"--"}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pcard--sleek__grid">
            <label>Insurance Type</label>
            <div class="label--data">
                {{ primaryInsu?.insuType ? primaryInsu?.insuType : "--" }}
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pcard--sleek__grid">
            <label>Ins ID/Group#</label>
            <div class="label--data">
                {{ primaryInsu?.policynum ? primaryInsu?.policynum : "--" }}/{{ primaryInsu?.groupname ? primaryInsu?.groupname : "--" }}
            </div>

        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pcard--sleek__grid">
            <label>Facility</label>
            <div class="label--data">
                {{ rxPatientModel?.PatientFacilityInfo?.Organization?.Name ?
                    rxPatientModel?.PatientFacilityInfo?.Organization?.Name : "--"  }}
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pcard--sleek__grid">
            <label>Packaging</label>
           <div class="cursor_pointer">
            <span class="label--data"  triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient" container="body" 
            [ngbTooltip]="(rxPatientModel?.isPkgAllowed) ? rxPatientModel?.packagingPatient : ''" >
                {{(rxPatientModel?.isPkgAllowed) ? "Y" : "N"}}
              </span>
           </div>
        </div>
       
      
        <div class="col-8 pcard--sleek__grid sml-box semi-sml">
            <label>Allergies</label>
            <div class="label--data" *ngIf="rxPatientModel?.patallergies?.length > 0">
                <span *ngFor="let allergy of rxPatientModel?.patallergies; let i=index">
                   <span [ngClass]="allergy?.Name === 'No Known Allergies' ? '' : 'allergy-color'"> {{ allergy?.Name }}</span> <span *ngIf="i !== (rxPatientModel?.patallergies?.length - 1)"> , </span>
                </span>
            </div>
            <div class="label--data" *ngIf="rxPatientModel?.patallergies?.length === 0">
                <span> -- </span>
            </div>
        </div>
    
       
        <!-- <div class="col-4 pcard--sleek__grid sml-box semi-smll">
            <label>Remarks </label><br>
            <span class="label--data text-display-notes ellpise" triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient" container="body" 
            [ngbTooltip]="(rxPatientModel?.notes) ? rxPatientModel?.notes : ''">
                {{ rxPatientModel?.notes ? rxPatientModel?.notes : "--" }}
            </span>
        </div> -->
    </div>
</div>
<div class="eprx--block__content presc-Sml"   [ngClass]="IsFromErxRequest ? 'height_7rem' : ''"  *ngIf="(rxPatientModel && (!prescriber || !drugInfo?.id) && isFromErx !=='true') || (rxFG?.value['frmInprog']) && 
    !rxFG?.value['afterDrugInactv']">
    <div class="row">
        <div class="pcard--sleek__grid" [ngClass]="IsFromErxRequest ? 'col-lg-2' : 'col-lg-1 col-md-3 col-sm-3 col-xs-12'" >
            <label>DOB</label>
            <div class="label--data">
                {{ rxPatientModel?.dob | date: "MM/dd/yyyy" }}
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 pcard--sleek__grid">
            <label>Gender</label>
            <div class="label--data" [title]="rxPatientModel?.gender">
                {{ rxPatientModel?.gender ? rxPatientModel?.gender  : "--" }}
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 pcard--sleek__grid">
            <label>Age</label>
            <div class="label--data">{{ age ? age : "--" }}</div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 pcard--sleek__grid">
            <label>Insurance Type </label>
            <div class="label--data" *ngIf="!IsFromErxRequest">
                {{ primaryInsu?.insuType ? primaryInsu?.insuType : "--" }}
            </div>
            <div class="label--data" *ngIf="IsFromErxRequest">
                {{ primaryInsuFromErxtoRequest?.insuType ? primaryInsuFromErxtoRequest?.insuType : (primaryInsuFromErxtoRequestForCash?.insuType ? primaryInsuFromErxtoRequestForCash?.insuType : '--') }}
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 pcard--sleek__grid">
            <label>Ins ID/Group# </label>
            <div class="label--data" *ngIf="!IsFromErxRequest">
                {{ primaryInsu?.policynum ? primaryInsu?.policynum : "--" }}/{{ primaryInsu?.groupname ? primaryInsu?.groupname : "--" }}
            </div>
            <div class="label--data" *ngIf="IsFromErxRequest">
                {{ primaryInsuFromErxtoRequest?.policynum ? primaryInsuFromErxtoRequest?.policynum : "--" }}/{{ primaryInsuFromErxtoRequest?.groupname ? primaryInsuFromErxtoRequest?.groupname : "--" }}
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 pcard--sleek__grid">
            <label>Facility</label>
            <div class="label--data">
                {{ rxPatientModel?.PatientFacilityInfo?.Organization?.Name ?
                    rxPatientModel?.PatientFacilityInfo?.Organization?.Name : "--"  }}
            </div>
        </div> 
        <div class="col-1 position-relative" *ngIf="Is340BStatus"> 
            <span class="position-340">
                340B 
            </span> 
        </div>
        <div class="col-lg-3 col-md-9 col-sm-9 col-xs-12 pcard--sleek__grid sml-box">
            <label class="d-block">Address</label>
            <div class="label--data">
                <span *ngIf="rxPatientModel?.address1 && rxPatientModel?.address1 !== '###'">
                    {{rxPatientModel?.address1}},
                </span>
                <span *ngIf="rxPatientModel?.address2 && rxPatientModel?.address2 !== '###'">
                    {{rxPatientModel?.address2}},
                </span>
                <span *ngIf="rxPatientModel?.city && rxPatientModel?.city !== '###'">
                    {{rxPatientModel?.city}},
                </span>
                <span *ngIf="rxPatientModel?.state && rxPatientModel?.city !== '###'">
                        {{rxPatientModel?.state}},
                </span>
                <span *ngIf="rxPatientModel?.zipcode && rxPatientModel?.zipcode !== '###'">
                    {{rxPatientModel?.zipcode}}
                </span>
            </div>
        </div>
        <div class="col-lg-2 col-md-9 col-sm-9 col-xs-12 pcard--sleek__grid sml-box">
            <label>Phone#</label>
            <div class="label--data">
                <span>{{rxPatientModel?.mobile ? (rxPatientModel?.mobile | mask : "(000)000-0000"):"--"}}</span>,
                <span>{{rxPatientModel?.telephone ? (rxPatientModel?.telephone | mask : "(000)000-0000"):"--"}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 pcard--sleek__grid sml-box">
            <label>Allergies</label>
            <div *ngIf="rxPatientModel?.patallergies?.length > 0" [ngClass]="IsFromErxRequest? '' : 'label--data'">
                <span *ngFor="let allergy of rxPatientModel?.patallergies; let i=index">
                    <span [ngClass]="allergy?.Name === 'No Known Allergies' ? '' : 'allergy-color'"> {{ allergy?.Name }}</span> <span *ngIf="i !== (rxPatientModel?.patallergies?.length - 1)">, </span>
                </span>
            </div>
            <div class="label--data" *ngIf="rxPatientModel?.patallergies?.length === 0">
                <span> -- </span>
            </div>
        </div>
        <div class="col-lg-1 col-md-9 col-sm-9 col-xs-12 pcard--sleek__grid sml-box">
            <label>Packaging</label>
            <div class="cursor_pointer">
                <span class="label--data"  triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient" container="body" 
                [ngbTooltip]="(rxPatientModel?.isPkgAllowed) ? rxPatientModel?.packagingPatient : ''">
                    {{(rxPatientModel?.isPkgAllowed) ? "Y" : "N"}}
                </span>
            </div>
        </div>
        <!-- <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12 pcard--sleek__grid sml-box">
            <label>Remarks </label> <br>
            <span class="label--data text-display-notes ellpise" triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient" container="body" 
            [ngbTooltip]="(rxPatientModel?.notes) ? rxPatientModel?.notes : ''">
                {{ rxPatientModel?.notes ? rxPatientModel?.notes : "--" }}
            </span>
        </div> -->
    </div>
</div>
<div class="eprx--block__content" *ngIf="!rxPatientModel">
    Please select patient.
</div>

