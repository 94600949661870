import { Component, OnInit, Input, Output, EventEmitter, HostListener } from "@angular/core";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GridLabels} from 'src/app/models/wijmo-export.enum';
import { auditMappings } from 'src/app/models/auditMappings';
import { AuditLogUtils, CommonUtil } from "src/app/utils";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, PaginationModel, PatientFileE } from "src/app/models";
import { MsgConfig } from "src/app/app.messages";
import { EditDrugService } from "src/app/services/edit-drug.service";
import { AlertService, ReportsService } from "src/app/services";
import { DomSanitizer } from "@angular/platform-browser";
import { PrintService } from "src/app/services/print.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
@Component({
    selector: "app-eprx-pagination",
    templateUrl: "./eprx-pagination.component.html"
})
export class EprxPaginationComponent implements OnInit {
    @Input() rxId: any;
    totalCount: number;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    totalPages = 1;
    showExport = true;
    flex: wjcGrid.FlexGrid;
    gridName = "export.xlsx";
    excludeColumns: string[] = ["Actions", "Select", "Action"];
    incPageSize: number;
    fromModal = false;
    gridlabelName:  any;
    billToStatus: any;
    isSelectedType: any;
    unsubscribe$: Subject<void> = new Subject();

    @Output()
    OnPageSelected = new EventEmitter<any>();

    @Output()
    OnSizeSelected = new EventEmitter<any>();

    @Output()
    OnFocusOut = new EventEmitter<any>();

    hasExport: boolean;
    excludeSize = false;
    secondName: string;
    hasSecondCount: boolean;
    thirdName: string;
    hasThirdCount: boolean;
    secondCount: number;
    thirdCount: number;
    @Input() IsShortCutKey: string;
    @Input() AltShortCutKey : string;
    @Input() TotalCopayForSelectedRxs : string;

    isPrint: boolean;
    invAuditPayload: any;
    InvAuditTrails: any;
    isprint: any;
    pdfContent: any;
    fromRph : boolean = false;



    @Input()
    set SecondName(tc: string) {
        this.secondName = tc;
        if (tc) {
        this.hasSecondCount = true;
        }
    }

    @Input()
    set ThirdName(tc: string) {
        this.thirdName = tc;
        if (tc) {
        this.hasThirdCount = true;
        }
    }

    @Input()
    set SecondCount(tc: number) {
        this.secondCount = tc;
    }

    @Input()
    set ThirdCount(tc: number) {
        this.thirdCount = tc;
    }

    @Input()
    set TotalCount(tc: number) {
        this.totalCount = tc;
        this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    }

    @Input()
    set BillToStatus(tc: any) {
        this.billToStatus = tc;
    }

    @Input()
    set IsSelectedType(tc: any) {
        this.isSelectedType = tc;
    }

    @Input()
    set IncPageSize(ip: any) {
        if (ip) {
            this.incPageSize = +ip;
        }
    }

    @Input()
    set PageSize(ps: number) {
        if (ps) {
            this.pageSize = +ps;
            this.pageSize = +ps;
            this.pageNumber = 1;
            this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        }
    }

    @Input()
    set PageNumber(ps: number) {
        if (ps) {
            this.pageNumber = ps;
        }
    }

    @Input()
    set HasExport(ps: boolean) {
        if (ps) {
            this.hasExport = ps;
        }
    }
    @Input() 
    set HasPrint(pp:boolean){
        if(pp){
            this.isPrint = pp;
        }
    }
    @Input()
    set WijmoName(ps: wjcGrid.FlexGrid) {
        this.flex = ps;;
    }

    @Input() TotalFiledRxs: any;
    
  @Input() screenTotalNVRecords: any;

    @Input() screenTotalVerfRecords: any;

    @Input()
    set GridName(ps: any) {
        if (ps) {
            this.gridName = ps + ".xlsx";
            this.gridlabelName = ps;
        }
    }

    @Input()
    set ExcludeColumns(exAr: string[]) {
        if (exAr) {
            this.excludeColumns = exAr;
        }
    }

    @Input()
    set ExcludeSize(ps: any) {
        if (ps) {
            this.excludeSize = true;
        } else {
            this.excludeSize = false;
        }
    }

    @Input()
    set FromModal(tc: boolean) {
        if (tc) {
            this.fromModal = tc;
        }
    }
    @Input()
    set InvAuditPayload(Ip:any){
        if(Ip){
            this.invAuditPayload = Ip
        }
    }
    @Input()
    set FromRphModal(bl: boolean) {
        if (bl) {
            this.fromRph = bl;
        }
    }
    constructor(private _alertSer: AlertService,private _modalService: NgbModal,private _sanitizer: DomSanitizer, private _comnUtil: CommonUtil,private _editDrugSvc: EditDrugService, private _reportsServ: ReportsService, private _printServ: PrintService,private _privMask:PrivMaskGuard, private _auditUtils: AuditLogUtils) {}

    ngOnInit() {
    }

    setPage(pn: number) {
        this.pageNumber = pn;
        this.OnPageSelected.emit(pn);
    }

    setSize(ps: number) {
        this.pageSize = +ps;
        this.pageNumber = 1;
        this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        this.OnSizeSelected.emit(this.pageSize);
    }

    exportExcel() {
        if (this.gridlabelName === "PatientSearchGrid" && !this._privMask.canActivate(AccCategoryE.PatientFile, PatientFileE.Export)) {
            this._alertSer.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else{
            wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                this.flex,
                {
                    includeColumns: function(column) {
                        return (column.binding && column.isVisible && !(this.excludeColumns || this.excludeColumns.map((x: any) =>
                        x.toUpperCase())).includes(column.binding));
                    }.bind(this),
                    formatItem: this.gridName !== "Vendors.xlsx" ? this._comnUtil.exportFormatItem : this._comnUtil.exportItem
                },
                this.gridName
            );
            const auditDetails = auditMappings[this.gridlabelName as GridLabels];
            if (auditDetails) {
                this._auditUtils.getChangedValues(
                    null,
                    { [auditDetails.fileName]: "Exported" },
                    "Export",
                    auditDetails.entityName,
                    null
                );
            }
        }
    }

    next() {
        if (this.pageNumber < this.totalPages) {
            this.pageNumber = this.pageNumber + 1;
            this.OnPageSelected.emit(this.pageNumber);
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    last() {
        if (this.pageNumber < this.totalPages) {
            this.pageNumber = this.totalPages;
            this.OnPageSelected.emit(this.pageNumber);
        }
    }

    previous() {
        if (this.pageNumber > 1) {
            this.pageNumber = this.pageNumber - 1;
            this.OnPageSelected.emit(this.pageNumber);
        }
    }

    first() {
        if (this.pageNumber > 1) {
            this.pageNumber = 1;
            this.OnPageSelected.emit(this.pageNumber);
        }
    }

    focusOut(event) {
        if (event) {
            this.OnFocusOut.emit(event);
        }
    }
    printReport(val){
        this.isprint = val
        this.invAuditPayload.ManageInvPrint =   (this.gridlabelName == "manageInvAudit") ? true : false;
        this.invAuditPayload.PhysicalInvPrint =  (this.gridlabelName == "physicalInvAudit") ? true : false
        this.invAuditPayload.RecvInvPrint =  (this.gridlabelName == "receiveInvAudit") ? true : false
        this.invAuditPayload.DispInvPrint = (this.gridlabelName == "dispensedInvAudit") ? true : false
        this._reportsServ.getInventoryTransactionAuditReport(this.invAuditPayload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp=>{
            if (resp && resp.type === "application/pdf") {
                this.InvAuditTrails = resp;
                if (this.isprint) {
                    this.printInventoryAuditReport();
                } else {
                    const urlCreator = window.URL;
                    this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                        urlCreator.createObjectURL(resp) + "#toolbar=0&zoom=115"
                    );
                }
            } else {
                this._alertSer.error("Daily Log report is unavailable.");
            }
        })
    }
    printInventoryAuditReport() {
        const newBlob = new Blob([this.InvAuditTrails], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function() {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }

}
