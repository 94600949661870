export class Vendorss {
    VendorId: number = null;
    Id: number = null;
    CityId: number = null;
    Name: string = null;
    VendorCode: string = null;
    Code: string = null;
    City: string;
    WebUrl: string;
    State: string;
    Zip: string;
    Phone: string;
    Fax: string;
    ContactPerson: string;
    Status: boolean;
    TimeToOrder: string = null;
    IsAutoClose: boolean;
    LastorderDate: string = null;
    LastReceiveDate: string = null;
    IsSupportEDI: boolean;
    IsSendVendorCostPrice: boolean;
    ISUSeFor832: boolean;
    EdiVendorCode: string;
    IsTotalQtyFor810: boolean;
    PriceQualifier: string;
    IsUpdatePrice: boolean;
    CostQualifier: string;
    IsUpdateCost: boolean;
    Comments: string;
    DefaultBucketId: number = null;
    BucketName: string = null;
    IsActive = false;
    Address: string = null;
    StateId: number = null;
    Email: string = null;
    PhoneId: string;
    EmailId: string;
    FaxId: string;
    AddressId: string;
    VendorEdiId: string;
    IS340B: boolean = null;
}
export class VendorEDI {
    Id: number = null;
    TenantId: number = null;
    VendorId: number = null;
    IsEDISupported: boolean = false;
    EDICode: string = null;
    PriceQualifId: number = null;
    IsPriceUpdtdByPriceQualif: boolean = false;
    CostQualifId: number = null;
    IsPriceUpdtdByCostQualif: boolean = false;
    IsSendCostPriceNeeded: boolean = false;
    Is832PktPrice: boolean = false;
    Is810PktPrice: boolean = false;
    Remarks: string = null;
    IsActive: boolean = false;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}
export class VendorContracts {
    Id: number = null;
    TenantId: number = null;
    VendorId: number = null;
    ContractName: string = null;
    DefaultBucketId: number = null;
    LastOrdered: string = null;
    LastRecieved: string = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}





