<ng-template #DMEPOPUP let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePopup()">
        <h4 header>Durable Medical Equipment (DME)</h4>
        <span body>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col padding--right__7">
                        <div class="col-xs-12 padding--right__7 mt-2">
                            <eprx-input [RxSelectId]="'locatorCodeDME'"  [TabIndex]="'41'" [AutoFocus]="true" [LabelText]="'Locator Code'" [PlaceHolder]="''"
                                [ControlName]="'LocatorCode'" [FormGroupName]="dmeFG"  [MaxLength]="2">
                            </eprx-input>
                        </div>
                        <div class="col-xs-12 padding--right__7 mt-2">
                            <eprx-input [LabelText]="'Category Of Services'" [PlaceHolder]="''" [TabIndex]="'43'" [ControlName]="'ServiceCat'"
                                [FormGroupName]="dmeFG"  [MaxLength]="4">
                            </eprx-input>
                        </div>
                    </div>
                    <div class="col padding--left__7">
                        <div class="col-xs-12 padding--left__7 mt-2">
                            <eprx-input [LabelText]="'Clinic Speciality'" [PlaceHolder]="''" [TabIndex]="'42'" [ControlName]="'ClinicSp'"
                                [FormGroupName]="dmeFG" [MaxLength]="3">
                            </eprx-input>
                        </div>
                        <div class="col-xs-12 padding--left__7 mt-2">
                            <eprx-input [LabelText]="'Accident Code'" [PlaceHolder]="''" [TabIndex]="'44'" [ControlName]="'AccidentCode'"
                                [FormGroupName]="dmeFG"  [MaxLength]="1">
                            </eprx-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col padding--right__7">
                        <div class="col-xs-12 padding--right__7 mt-2">
                            <eprx-radio [LabelText]="'Handicap Child Pg'" [TabIndex]="'45'" [ControlName]="'IsHandiChildPg'" [FormGroupName]="dmeFG"
                                [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                [IDForList]="['IsHandiChildPg1', 'IsHandiChildPg2']" >
                            </eprx-radio>
                        </div>
                        <div class="col-xs-12 padding--right__7 mt-2">
                            <eprx-radio [LabelText]="'Family Planning'" [TabIndex]="'47'" [ControlName]="'IsFamilyPlan'" [FormGroupName]="dmeFG"
                                [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsFamilyPlan1', 'IsFamilyPlan2']"
                               >
                            </eprx-radio>
                        </div>
                    </div>
                    <div class="col padding--right__7">
                        <div class="col-xs-12 padding--right__7 mt-2">
                            <eprx-radio [LabelText]="'Possible Disability'" [TabIndex]="'46'"  [ControlName]="'IsDisability'" [FormGroupName]="dmeFG"
                                [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsDisability1', 'IsDisability2']"
                                >
                            </eprx-radio>
                        </div>
                        <div class="col-xs-12 padding--right__7 mt-2">
                            <eprx-radio [LabelText]="'Rental'" [ControlName]="'IsRental'" [TabIndex]="'48'" [FormGroupName]="dmeFG"
                                [List]="['Yes', 'No']"  [ValueList]="[true, false]"
                                [IDForList]="['IsRental1', 'IsRental2']" >
                            </eprx-radio>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer  class="hotkey_success" ngbAutofocus appShortcutKey 
            (keydown.enter)="saveDMEpopup('close');closePopup()" [tabindex]="49" (click)="saveDMEpopup('close')"
            [AltKey]="'s'"><span>S</span> Save
        </button>
        <button footer class="hotkey_success" appShortcutKey 
            (keydown.enter)="closePopup()" (click)="closePopup()" [tabindex]="50" [AltKey]="'c'"><span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template> 
