import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, CommonService, UserService } from 'src/app/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Subject, Subscription, from } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  encapsulation :ViewEncapsulation.None
})
export class ReleaseNotesComponent implements OnInit {
data = [];
@ViewChild("ReleaseNotes",{static: true})
ReleaseNotes : any;
@ViewChild("searchBoxReleaseType", { static: true }) 
searchBoxReleaseType: ElementRef;
@Output() CloseReleaseModal = new EventEmitter<any>();
@Output() CountForRns = new EventEmitter<any>();
ReleasemodelRef: any;
array: any = [];
filteredOptions: any[] = [];
searchTerm = '';
ids = [];
dataforChecked = [];
duplicateArrayRn = [];
searchSelectedData = [];
searchSelectedData1 = [];
inputFg : FormGroup;
hasRnData : boolean;
currentDate: Date;
currentMonth: number;
monthNames = [];
currentYear: any;
months = [];
formattedMonth: string;
shortYear: string;
requiredFormat: string;
defaultValue: any;
isFocused : boolean;
userId: string;
userIdInt: number;
unsubscribe$: Subject<void> = new Subject();
jVersion: any;
keyToCheckRelaseNotes : boolean;
releaseBuildVersion: { UserId: number; BuildVersion: string; ShowReleasesAfterLogin : boolean};
featureSearchFg: FormGroup;
selectedValue: null;
isConfluneceServiceNotAvailable : boolean;
subscription = new Subscription();
  rnDataToDisplay: any;
  idList = [];
  isLinkedClicked: boolean;
  isFrmSearch : boolean = false;
  @HostListener("window:keydown", ["$event"])
  keyEventAlt(event: KeyboardEvent) {
      if (event.which === 27) {
          event.preventDefault();
          this.releaseNotesClose();
      }
  }
  constructor(private _userService:UserService,private _modalService:NgbModal, private _fb:FormBuilder,private _alertSrv :AlertService,private calendar:NgbCalendar,
    private _cmmnServ :CommonService) {
    this.inputFg = this._fb.group({
      inputText : [],
      ShowReleasesAfterLogin : this.keyToCheckRelaseNotes
    });
    this.featureSearchFg = this._fb.group({
      searchControl : []
    })
  }
  ngOnInit(): void {
    this.idList = [];
  this.getReleaseNotesPopup();
  this.getDate();
  this.getPagesForReleaseNotes("New Releases",true);
  }
getDate(){
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    this.currentYear = currentDate.getFullYear();
    for (let i = 0; i <= 12; i++) {
      const date = new Date(this.currentYear, currentMonth - i, 1);
      const monthName = date.toLocaleString('default', { month: 'short' });
      this.shortYear = date.getFullYear().toString();
      this.shortYear = this.shortYear.slice(-2);
      this.requiredFormat = `${monthName} '${this.shortYear}`;
      this.months.push(this.requiredFormat);
      this.defaultValue = this.months[0];
  }
}
  getReleaseNotesPopup(){
    this.ReleasemodelRef = this._modalService.open(this.ReleaseNotes,
        { backdrop: false, centered: true, windowClass: "large--content_62"});
  }
highlightElement(element) {
    element.classList.toggle('highlight');
  }
  releaseNotesClose(){
    this.idList = [];
    this.userId = this._userService.getToken("UserId");
    this.userIdInt = parseInt(this.userId)
    this.subscription.add(this._cmmnServ.getAppVersion().subscribe(resp => {
    if (resp && resp.version) {
        this.jVersion = resp.version;
        this.releaseBuildVersion = {
        UserId : this.userIdInt,
        BuildVersion : this.jVersion,
        ShowReleasesAfterLogin : this.inputFg.controls["ShowReleasesAfterLogin"].value
      }
        this._userService.postReleaseNotesBuildVersion(this.releaseBuildVersion)
        .subscribe((res)=>{
        if(res){    
           this.inputFg.controls["ShowReleasesAfterLogin"].patchValue(this.keyToCheckRelaseNotes);
           if(this.subscription){
              this.subscription.unsubscribe();
              this.subscription = new Subscription();
           }
        }});
    }}));
    if(this.ReleasemodelRef){
      this.ReleasemodelRef.close();
      this.ReleasemodelRef = null;
    }
    this.CloseReleaseModal.emit(true);
    this.CountForRns.emit(true);
  }

checkedReleaseCheckbox(isChecked,Id,isMultiple){
  if(this.ids && !this.ids.includes(Id)){
    this.ids.push(Id)
  }
  if(isChecked && this.ids && this.ids.length > 0){
    this.duplicateArrayRn.forEach(x => {
      if(x.Id === Id && isMultiple){
        this.dataforChecked.push(x);
      }
      else if(x && x.Id === Id && !isMultiple){
        this.dataforChecked = [];
        this.dataforChecked.push(x);
      }
    })
    this.dataforChecked.map((item)=>{
      if(item && item.response){
        this.array = [...this.dataforChecked] 
      }
    })
  } else if(!isChecked && this.ids && this.ids.length > 1){
    this.dataforChecked = this.dataforChecked.filter(x => x.Id !== Id);
    this.ids = this.ids.filter(id => id !== Id);
    this.array = [...this.dataforChecked];
  } else{
    if(this.ids.length === 1){
      this.ids = [];
    }
      this.array =[...this.duplicateArrayRn];
      this.dataforChecked = [];
  }
}
searchMenuforReleaseData(event){
  if(event && event.value && event.value['Title']){
    this.isFrmSearch = true;
    this.searchTerm = event.value['Title'];
    this.filteredOptions = this.searchSelectedData['releaseNotesPages'].filter(option => option.Title.toLowerCase().includes(this.searchTerm.toLowerCase()));
    this.filteredOptions.map((item)=>{
      if(item && this.searchTerm !== "" && item.Title.toLowerCase().startsWith(this.searchTerm.toLowerCase())){
        item.isChecked = true;
          this.checkedReleaseCheckbox(item.isChecked, item.Id,false);
          this.isFocused = true;
      } else{
        item.isChecked = false;
      }
    });
  } else {
    this.ids = [];
    this.array =[...this.duplicateArrayRn];
  }
  this.filteredOptions = this.searchSelectedData['releaseNotesPages'];
}
getPagesForReleaseNotes(event,frmOnint? : boolean){
  if(event){
    this.selectedValue = null;
  }
    this.isLinkedClicked = frmOnint ? true : false;
  this.isFrmSearch = false;
  const Year = this.currentYear.toString();
  const FullYear = event.replace("'", Year.slice(0,2));
  const payload = {
    date : FullYear
  }
this._userService.getReleaseNotesPages(payload ? payload : null)
.pipe(takeUntil(this.unsubscribe$))
.subscribe((resp)=>{
  if(resp){
    if(resp.releaseNotesPages && resp.releaseNotesPages.length){
      this.hasRnData = true;
      this.isConfluneceServiceNotAvailable = false;
    }else if(!resp.IsConfluneceServiceAvailable && !resp.releaseNotesPages){
      this.isConfluneceServiceNotAvailable = true;
    } else{
      this.hasRnData = false;
      this.isConfluneceServiceNotAvailable = false;
    }
    this.searchSelectedData = resp;
    this.filteredOptions = resp.releaseNotesPages;
    this.keyToCheckRelaseNotes = resp.ShowReleasesAfterLogin;
    this.duplicateArrayRn = [];
    this.idList =[];
    if(resp.releaseNotesPages){
      this.array = [];
      this.rnDataToDisplay = [];
      this.idList.push(resp.releaseNotesPages[0].Id);
      if(resp.releaseNotesPages[0].Title === "New Releases"){
        resp.releaseNotesPages.splice(0,1);
      }
      from(resp.releaseNotesPages).pipe(
        concatMap((res: any) => 
          this._userService.getReleaseNotes(res.Id).pipe(
            takeUntil(this.unsubscribe$)))).subscribe(result => {
          if (result) {
            this.array.push(result);
            this.rnDataToDisplay = this.array[0].response;
            this.duplicateArrayRn = this.array;
            this.inputFg.controls["ShowReleasesAfterLogin"].patchValue(this.keyToCheckRelaseNotes);
          }
        },
      );
    }
    this.featureSearchFg.controls['searchControl'].patchValue(null);
    this.array = this.duplicateArrayRn;
  } });
}
clickOnUserGuideLinks() {
  this.isFrmSearch = false;
  this.isLinkedClicked = true;
  document.addEventListener('click', (event) => {
      if ((event.target as HTMLElement).tagName === 'A') {
        event.preventDefault()
        const url = (event.target as HTMLAnchorElement).href;
        let id;
        let urlArray = url.split('/');
        urlArray.map((data, i) => {
          if (data == 'pages') {
            id = urlArray[i + 1]
          }
        });
        if (id) {
          this._cmmnServ.getUserGuideDataWithId(id).subscribe(res => {
          if (res.response == "error") {
            this.rnDataToDisplay = `<div class="userGuide-error">
                                    <p><i class="far fa-exclamation-triangle"></i></p>
                                    Service Unavailable at the moment. Please re-try in sometime.
                                  </div>`
          } else {
            this.rnDataToDisplay = res.response;
            if(this.idList.indexOf(id) === -1){
              this.idList.push(id);
            }
          }
        })
      } 
    
    }
      
  });
}
navigateToBackScreen(){
  this._cmmnServ.getUserGuideDataWithId(this.idList[this.idList.length-2]).subscribe(res => {
    this.rnDataToDisplay = res.response;
  });
  this.idList.pop();
  this.isLinkedClicked = false;
}
ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
