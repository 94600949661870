export const InsuTabEnum = {
    Patient1: "PLANINFO",
    Patient2: "SETTINGS",
    Patient3: "TRANSSTNGS",
    Patient4: "OTHERINSPAYERID",
    Patient5: "NOTES",
    Patient6: "NOTESLOG",
    Patient7: "DOCUMENTS",
    Patient8: "INSUBILLSTNGS"
};

export const InsuHeaderTitles = {
    PLANINFO: "Plan Information",
    SETTINGS: "Settings",
    TRANSSTNGS: "Transmission Settings",
    OTHERINSPAYERID: "Other Ins Payer ID",
    NOTES: "Notes",
    NOTESLOG: "Notes",
    DOCUMENTS: "Documents",
    INSUBILLSTNGS: "Ins Billing File Settings"
};

export const InsuTabTitles = {
    PLANINFO: "Patient1",
    SETTINGS: "Patient2",
    TRANSSTNGS: "Patient3",
    OTHERINSPAYERID: "Patient4",
    NOTES: "Patient5",
    NOTESLOG : "Patient6",
    DOCUMENTS : "Patient7",
    INSUBILLSTNGS : "Patient8"
};

export const otherInsuSearch = {
    InsuPlanId: null,
    PageNumber: 1,
    PageSize: 30
};

export const InsuPlanPriceDef = {
    Id: null,
    TenantId: null,
    InsuPlanId: null,
    CostBasisId: null,
    CoPayCatId: null,
    CoPayBrandAmnt: null,
    CoPayGenAmnt: null,
    ProFee: null,
    IsPFForOTC: null,
    BrandPriceSchId: null,
    GenPriceSchId: null,
    IsUNCSame: false,
    BrandPricePer: null,
    GenPricePer: null,
    IsProfWatch: false,
    MaxCoPayAmnt: null,
    IsZeroBillAllowed: false,
    IsZeroUNC: false,
    IsAssigntAccepted: false,
    TaxRatePer: null,
    SalesTaxBasisId: null,
    IsTaxOnRx: true,
    IsTaxOnOTC: true,
    FlatSalesTaxAmt: null,
    IsPartialFillAllowed: false
};

export const InsuPlanOtherDef  = {
    Id: null,
    TenantId: null,
    InsuPlanId: null,
    PrescIdCatId: 5,
    PrimePrescIdCatId: 5,
    MaxDaysSupply: 30,
    MaxQty: 0,
    OrderToFillDays: 180,
    MaxRefills: 5,
    OrderToLastRefill: 180,
    IsRefillRepriced: false,
    IsSortByRx: true,
    IsWorkmanComp: false,
    // IsRxSNReqd: false,
    RPhIdCatId: 1,
    CompoundIngrcandiffer: false,
    RefillDueConsumptionThreshold: 0,
    DgCodeDotReq: "Y"
};

export const otherInsuHeaders = [
    "Actions",
    "Other Insurance",
    "Payer Id",
    "Qualifier"
];

export const MMSDataHeaders = [
    "Select",
    "Ins.Code",
    "Ins.Name",
    "Address",
    "Phone#",
    "Pharamacy Id#",
    "Processor Ctrl",
    "Bin#",
    "Ver",
    "Comm.Method",
    "Notes",
];

export const cntrlsNotToPatch = [
    "Id",
    "OrgId",
    "PrimePhoneId",
    "PrimeAddsId",
    "InsurerCode",
    "InsuPlanId",
    "InsuCarrierId",
    "PlanCode"
];

