import { Injectable, OnInit } from "@angular/core";
import { RxBillingCalculationInput } from "../models";
import { Subscription } from "rxjs";
import { CommonService, InsuranceService, RxService } from "../services";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class RxPricingUtil {

    subscriptions = new Subscription();
    rxInfo: any;
    priceScheduleList: any[];
    insuSettings: any;
    totalPriceForCash: number;

    constructor(private _commonServ: CommonService, private _insurService: InsuranceService, private _rxService: RxService) {
        this.subscriptions.add(
            this._commonServ.priceSchedule$.subscribe(resp =>
                resp && resp.PriceScheduleList
                    ? (this.priceScheduleList = resp.PriceScheduleList)
                    : (this.priceScheduleList = [])
            )
        );

        this.subscriptions.add(
        this._insurService.insuSettings$.subscribe(resp => {
            if (resp && resp.InsuPlanPrice) {
                this.insuSettings = resp;
            }
        }));
    }

    async triggerPriceSchedule(type: string, rxInfo: any, pricSchdlLst: any, insuSetngs: any, rxType: string) {
        this.rxInfo = rxInfo;

        if (this.rxInfo.PrescReFill.DispQty && +this.rxInfo.PrescReFill.DispQty !== 0) {
            const inputData = new RxBillingCalculationInput();
            inputData.Discount = rxInfo.PrescReFill.DiscSchId;
            inputData.DrugId = this.rxInfo.Drug.id;
            inputData.OtherAmount = rxInfo.PrescReFill.OtherAmnt;
            inputData.PriceScheduleId = this.rxInfo.PrescReFill.PriceSchId;
            inputData.QuantityDispense = +this.rxInfo.PrescReFill.DispQty;
            inputData.BucketId = +this.rxInfo.PrescReFill.BucketId;

            if (type === "PriceSch") {
                inputData.TypeOfCalculation = "PriceScheduleId";
            } else {
                inputData.TypeOfCalculation = "Drug";
            }
            inputData.PatientId = this.rxInfo.Patient.patientid;
            inputData.InsuranceId = this.rxInfo.RefillInsu.InsuId;
            if (inputData.QuantityDispense) {
                const resp = await this._rxService.getPricingDetails(inputData).toPromise();

                if (resp) {
                    this.rxInfo["pricingValues"] = resp;
                    if (type !== "PriceSch") {
                        this.rxInfo.PrescReFill.PriceSchId = +resp.PriceSchId;
                    }
                    this.rxInfo.PrescReFill.Price = resp.Price ? resp.Price : "0.00";

                    this.rxInfo.PrescReFill.BalDue =
                        resp.BalDue ? resp.BalDue : "0.00";

                    this.rxInfo.PrescReFill.ProfFee =
                        resp.ProFee ? resp.ProFee : (0);

                    this.rxInfo.PrescReFill.TotalAmt =
                        resp.TotalAwp
                            ? resp.TotalAwp
                            : "0.00";

                    let AWP = null;
                    let rxCost = null;
                    if (this.rxInfo.PrescReFill.DispQty) {
                        AWP =
                            +this.rxInfo.PrescReFill.DispQty *
                            this.rxInfo.Drug.unitpriceawp;
                        this.rxInfo.PrescReFill[
                            "UnitPriceAWP"
                        ] = AWP ? AWP : "0.00";
                        Object.assign(this.rxInfo["pricingValues"], {Awp: parseFloat(this.rxInfo.Drug.unitpriceawp)});
                        if (this.rxInfo.Drug && this.rxInfo.Drug.manageinventory && this.rxInfo.Drug.manageinventory.length) {
                            const isBuckets = this.rxInfo.Drug.manageinventory.filter(buck =>
                                buck.BucketId === (this.rxInfo["PrescReFill"] &&  this.rxInfo["PrescReFill"]['BucketId']))
                                || [];
                            const rxCost = isBuckets && isBuckets.length ? (+this.rxInfo.PrescReFill.DispQty * isBuckets[0].UnitPriceCost).toFixed(2) : null;
                            this.rxInfo.PrescReFill["TotalRxCost"] = rxCost ? rxCost : "0.00";
                        }

                    } else {
                        this.rxInfo.PrescReFill["TotalRxCost"] = "0.00";
                    }
                    this.rxInfo.PrescReFill.UnitPriceUNC =
                        resp ? resp.UAndC : "0.00";
                        const origProFee =  (resp && resp.OrigProFee ? (rxInfo["rxType"] === 'er' ? rxInfo['PrescReFill']['OrigProfFee'] : resp.OrigProFee) : 0.0);
                    this.rxInfo.OrigProFee = origProFee;
                    this.rxInfo.PrescReFill["OrigProfFee"] = origProFee;
                    // sessionStorage.setItem("RxForQuick", JSON.stringify(this.rxFG.getRawValue()));
                    return this.initiateManualCal(this.rxInfo, insuSetngs, rxType, resp);
                } else {
                    this.rxInfo["pricingValues"] = null;
                    if (type !== "PriceSch") {
                        if (
                            this.priceScheduleList &&
                            this.priceScheduleList.length > 0
                        ) {
                            this.rxInfo.PrescReFill.PriceSchId =
                                this.priceScheduleList[0].Id;
                        }
                    }
                    return this.makeAllFieldsToDefaultValue();
                }
            }
        } else {
            this.rxInfo["pricingValues"] = null;
            this.rxInfo.PrescReFill["TotalRxCost"] = "0.00";
            return this.makeAllFieldsToDefaultValue();
        }
    }

    makeAllFieldsToDefaultValue() {
        this.rxInfo.PrescReFill.Price = "0.00";
        this.rxInfo.PrescReFill.BalDue = "0.00";
        this.rxInfo.PrescReFill.ProfFee = "0.00";
        this.rxInfo.PrescReFill.TotalAmt = "0.00";
        this.rxInfo.PrescReFill.UnitPriceAWP = "0.00";
        this.rxInfo.PrescReFill.UnitPriceUNC = "0.00";
        this.rxInfo.PrescReFill.OtherAmnt = "0.00";
        return this.rxInfo;
    }


    initiateManualCal(rxInfo, insuSetngs, rxType, resp?: any) {
        rxInfo = this.getInsuranceManualCalc(rxInfo, insuSetngs, resp);
        rxInfo.TaxAmt = rxInfo["RxOtherAmount"] ? (rxInfo["RxOtherAmount"]["FlatSalesTax"] +
            rxInfo["RxOtherAmount"]["SalesTax"]) : 0;
        this.rxInfo = this.caculateBalDue(rxInfo, rxType);
        return this.rxInfo;
    }

    getInsuranceManualCalc(rxInfo: any, insurancSettings: any, resp?: any) {
        if (rxInfo.RxOtherAmount) {
            if (insurancSettings && insurancSettings.InsuPlanPrice) {
                rxInfo.RxOtherAmount["SalesTaxPer"] = (
                    insurancSettings.InsuPlanPrice.TaxRatePer
                        ? +insurancSettings.InsuPlanPrice.TaxRatePer
                        : 0.0
                );

                rxInfo.RxOtherAmount["SalesTaxBasis"] = (
                    insurancSettings.InsuPlanPrice.SalesTaxBasisId
                );

                if ((rxInfo.Drug.drugtypeid === 4 && insurancSettings.InsuPlanPrice.IsTaxOnOTC) ||
                    (rxInfo.Drug.drugtypeid === 1 && insurancSettings.InsuPlanPrice.IsTaxOnRx)) {
                    if (insurancSettings.InsuPlanPrice.SalesTaxBasisId === 2) {
                        rxInfo.RxOtherAmount["SalesTax"] = (
                            (
                                ((rxInfo.PrescReFill.Price
                                    ? +rxInfo.PrescReFill.Price
                                    : 0.0) *
                                    (rxInfo.RxOtherAmount.SalesTaxPer
                                        ? +rxInfo.RxOtherAmount.SalesTaxPer
                                        : 0.0)) /
                                100
                            ).toFixed(2)
                        );
                    } else if (insurancSettings.InsuPlanPrice.SalesTaxBasisId === 1 ||
                        insurancSettings.InsuPlanPrice.SalesTaxBasisId === 3) {
                        const origProFee =  (resp && resp.OrigProFee ? (rxInfo["rxType"] === 'er' ? rxInfo['PrescReFill']['OrigProfFee'] : resp.OrigProFee) : 0.0);
                        rxInfo.RxOtherAmount["SalesTax"] = (
                            (
                                (((rxInfo.PrescReFill.Price
                                    ? +rxInfo.PrescReFill.Price
                                    : 0.0) + (rxInfo.Drug.drugtypeid === 4 ? (origProFee) :
                                     (rxInfo.PrescReFill.ProfFee ? +rxInfo.PrescReFill.ProfFee : 0.0))) *
                                    (rxInfo.RxOtherAmount.SalesTaxPer
                                        ? +rxInfo.RxOtherAmount.SalesTaxPer
                                        : 0.0)) /
                                100
                            ).toFixed(2)
                        );
                    }
                } else {
                    rxInfo.RxOtherAmount["SalesTax"] = 0.0;
                }
            }
        }

        return rxInfo;
    }

    caculateBalDue(rxInfo: any, type: string) {
        let rxOthAmnt = 0;
        let rxOtherFee = 0;
        let otherAmount = 0;
        let tax = 0;
        let fakeTax = 0;
        const rxOtherAmnt = rxInfo.RxOtherAmount;

        rxOtherFee = (rxOtherAmnt && rxOtherAmnt.ProfFee ? +rxOtherAmnt.ProfFee : 0) +
        (rxOtherAmnt && rxOtherAmnt.Incentive ? +rxOtherAmnt.Incentive : 0);
        rxInfo["OtherFee"] = rxOtherFee;

        if (rxOtherAmnt && rxOtherAmnt.OtherAmntMisc && rxOtherAmnt.OtherAmntMisc.length > 0) {
            otherAmount = (rxOtherAmnt.OtherAmntMisc[0] ? +rxOtherAmnt.OtherAmntMisc[0].Amount : 0)
            + (rxOtherAmnt.OtherAmntMisc[1] ? +rxOtherAmnt.OtherAmntMisc[1].Amount : 0) +
            (rxOtherAmnt.OtherAmntMisc[2] ? +rxOtherAmnt.OtherAmntMisc[2].Amount : 0);
            rxInfo["PrescReFill"]["OtherAmnt"] = (otherAmount ? (otherAmount).toFixed(2) : "0.00");
        } else {
            rxInfo["PrescReFill"]["OtherAmnt"] = "0.00";
        }
        rxOthAmnt = rxOtherAmnt ?
            (rxOtherAmnt.ProfFee ? +rxOtherAmnt.ProfFee : 0)
            + (rxOtherAmnt.Incentive ? +rxOtherAmnt.Incentive : 0)
            + (rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0)
            + (rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0)
            + (otherAmount ? +otherAmount : 0) : 0;

        if (type === "er") {
            fakeTax = (rxOtherAmnt && rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0) +
                        (rxOtherAmnt && rxOtherAmnt.FaxeSalesTax ? +rxOtherAmnt.FaxeSalesTax : 0);
        } else {
            rxInfo["OtherAmount"] = (rxOthAmnt ? (rxOthAmnt).toFixed(2) : "0.00");
            fakeTax = (rxOtherAmnt && rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0) +
             (rxOtherAmnt && rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0);
        }

        tax = (rxOtherAmnt && rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0) +
         (rxOtherAmnt && rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0);
        rxInfo["TaxAmt"] = ( tax > 0 ? tax.toFixed(2) : "0.00");

        rxInfo["PrescReFill"]["TotalAmt"] = ((((rxOthAmnt ? +rxOthAmnt : 0) -
            ((rxOtherAmnt && rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0))) +
            (rxInfo["PrescReFill"].Price ? +rxInfo["PrescReFill"].Price : 0) +
            (rxInfo["PrescReFill"].ProfFee ? +rxInfo["PrescReFill"].ProfFee : 0)).toFixed(2));
        //  + (rxOtherAmnt && rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0)

        const balDue = ((rxInfo["PrescReFill"].Price ? +rxInfo["PrescReFill"].Price : 0) +
                        (rxInfo["PrescReFill"].ProfFee ? +rxInfo["PrescReFill"].ProfFee : 0) +
                        (rxInfo.OtherFee ? +rxInfo.OtherFee : 0) +
                        (rxInfo["PrescReFill"].OtherAmnt ? +rxInfo["PrescReFill"].OtherAmnt : 0) +
                        (fakeTax ? +fakeTax : (tax ? +tax : 0)) ) -
                        (rxInfo["PrescReFill"].Discount ? +rxInfo["PrescReFill"].Discount : 0);

        if (rxInfo["PrescReFill"]["IsManuaBalanceDue"]) {
        } else {
            rxInfo["PrescReFill"]["BalDue"] = (balDue > 0 ? balDue.toFixed(2) : "0.00");
        }
        return rxInfo;
    }






    // calculateSalesTax() {
    //     const otherAmount: any = this.rxInfo.RxOtherAmount;
    //     if (otherAmount && otherAmount.value && !(+otherAmount.value.SalesTax)) {
    //         if (this.insuSettings && this.insuSettings.InsuPlanPrice.IsTaxOnRx && this.insuSettings.InsuPlanPrice.SalesTaxBasisId === 2) {
    //             otherAmount.controls["SalesTax"].setValue(
    //                 (
    //                     ((this.rxInfo.PrescReFill.Price
    //                         ? +this.rxInfo.PrescReFill.Price
    //                         : 0.0) *
    //                         (this.insuSettings.InsuPlanPrice.TaxRatePer
    //                             ? +this.insuSettings.InsuPlanPrice.TaxRatePer
    //                             : 0.0)) /
    //                     100
    //                 )
    //             );
    //         } else if (this.insuSettings && this.insuSettings.InsuPlanPrice.IsTaxOnRx &&
    //             (this.insuSettings.InsuPlanPrice.SalesTaxBasisId === 1 ||
    //             this.insuSettings.InsuPlanPrice.SalesTaxBasisId === 3)) {
    //             otherAmount.controls["SalesTax"].setValue(
    //                 (
    //                     (((this.rxInfo.PrescReFill.Price
    //                         ? +this.rxInfo.PrescReFill.Price
    //                         : 0.0) + (this.rxInfo.PrescReFill.ProfFee
    //                             ? +this.rxInfo.PrescReFill.ProfFee
    //                             : 0.0)) *
    //                         (this.insuSettings.InsuPlanPrice.TaxRatePer
    //                             ? +this.insuSettings.InsuPlanPrice.TaxRatePer
    //                             : 0.0)) /
    //                     100
    //                 )
    //             );
    //         } else {
    //             otherAmount.controls["SalesTax"].setValue("0.00");
    //         }
    //     }
    //     return this.initiateManualCal();
    // }

    // calOtherAmount() {
    //     return this.initiateManualCal();
    // }

    // initiateManualCal(rxInfo?: any) {
    //     if (rxInfo) {
    //         this.rxInfo = rxInfo;
    //     }
    //     let rxOthAmnt = 0;
    //     // const PrescRefillFG: any = this.rxFG.controls["PrescReFill"];

    //     const rxOtherAmnt = this.rxInfo.RxOtherAmount;
    //     if (this.rxInfo.RxOtherAmount) {
    //         rxOthAmnt =
    //             (rxOtherAmnt.ProfFee ? +rxOtherAmnt.ProfFee : 0)
    //             + (rxOtherAmnt.Incentive ? +rxOtherAmnt.Incentive : 0)
    //             + (rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0)
    //             + (rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0);
    //         if (rxOtherAmnt.OtherAmntMisc && rxOtherAmnt.OtherAmntMisc.length > 0) {
    //             rxOtherAmnt.OtherAmntMisc.map(misc => {
    //                 if (misc && misc.Amount) {
    //                     rxOthAmnt = rxOthAmnt + (+misc.Amount);
    //                 }
    //             });
    //         }
    //     }

    //     this.rxInfo.PrescReFill.OtherAmnt = rxOthAmnt;
    //     this.rxInfo.PrescReFill.TotalAmt = (+((+rxOthAmnt -
    //         (this.rxInfo.RxOtherAmount.FlatSalesTax ? +this.rxInfo.RxOtherAmount.FlatSalesTax : 0)) +
    //         (this.rxInfo.PrescReFill.Price ? +this.rxInfo.PrescReFill.Price : 0) +
    //         (this.rxInfo.PrescReFill.ProfFee ? +this.rxInfo.PrescReFill.ProfFee : 0)));

    //     const totalAmountForPopup = (this.rxInfo.PrescReFill.ProfFee ? +this.rxInfo.PrescReFill.ProfFee : 0)
    //         + (this.rxInfo.PrescReFill.Price ? +this.rxInfo.PrescReFill.Price : 0);
    //     this.totalPriceForCash = +(+totalAmountForPopup);

    //     const balDue = (this.rxInfo.PrescReFill.TotalAmt ? +this.rxInfo.PrescReFill.TotalAmt : 0.00) -
    //         (this.rxInfo.PrescReFill.Discount ? +this.rxInfo.PrescReFill.Discount : 0);

    //     this.rxInfo.PrescReFill.BalDue = balDue > 0 ? balDue : 0.00;
    //     // sessionStorage.setItem("RxForQuick", JSON.stringify(this.rxInfo));
    //     // this.calculationComplete.emit(true);
    //     return this.rxInfo;
    // }
}
