import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as wjcGrid from "@grapecity/wijmo.grid";
import { CollectionView } from "@grapecity/wijmo";
import { ColorCode, CommonUtil, WijimoUtil } from 'src/app/utils';
import { AlertService, CommonService, RxService } from 'src/app/services';
import { FormatsUtil } from 'src/app/utils/formats.util';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CurrentViewModel, SystemData } from 'src/app/models';
import * as moment from 'moment';
import { IntegerFloatPipe } from 'src/app/pipes/integer-float-format.pipe';
@Component({
  selector: 'app-current-view',
  templateUrl: './current-view.component.html',
  styleUrls: ['./current-view.component.scss']
})
export class CurrentViewComponent implements OnInit {
  @ViewChild("CurrentView", {static: true})
  CurrentView: any;
  @Input() RxInfo: any;
  modelRef: any;
  gridData: CollectionView;
  totalCount: number = 0;
  RfOrRx: any;
  isDataExists = true;
  systemData: SystemData;
  totalRxResp: any;
  unsubscribe$: Subject<void> = new Subject();
  @Output() IsPopUpClosed = new EventEmitter<any>();
  prescriptionQuantities: any[] = [];
  wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
  integerFloatFormat = new IntegerFloatPipe();
  constructor(private _modalService: NgbModal,   
              private _colorCode: ColorCode, 
              private _wijimoUtils: WijimoUtil,
              private _formatUtils: FormatsUtil,
              private _commonSer: CommonService,
              private _formatsUtil: FormatsUtil,
              private _comnUtil: CommonUtil,
              private _alertServ: AlertService,
              private _rxService: RxService) { }

  ngOnInit(): void {
    this.RfOrRx = this._commonSer.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
    this._commonSer.systemData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp) {
            this.systemData = resp;
        }
    });
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$.subscribe(w => {
        if (w && w["WijmoKey"] && w["WijmoKey"] === "RxHistoryCurrentView") {
            this.patchDefaultValHeaders();
        }
    });
     this.modelRef = this._modalService.open(this.CurrentView,{keyboard : false, backdrop : false, centered : true , windowClass:"max-modal-87"});
     this.getCurrentViewRxDetails();
  }
  closeModal() {
    if (this.modelRef) {
    this.modelRef.close();
    this.modelRef = null;
    }
    this.IsPopUpClosed.emit(false);
  }
  getCurrentViewRxDetails() {
     const payload: any  = new CurrentViewModel();
     payload.patientId =  (this.RxInfo?.Patient) ?  this.RxInfo?.Patient?.patientid :  this.RxInfo;
    this._rxService
    .getRxCurrentViewDetails(payload)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp: any) => {
        if(resp) {
          this.totalRxResp = resp;
          this.prescriptionQuantities = resp.prescriptionQuantities ? resp.prescriptionQuantities : [];
          this.generateList(resp);
        }
    });
  }
    itemFormatter = (panel, r, c, cell) => {
      const row = panel.rows[r];
      if (row && row.dataItem) {
          cell.style.background = "";
           if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
            const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
            if(colors && colors.bg && colors.color) {
                cell.style.background = colors.bg;
                cell.style.color = colors.color;
            } else {
                cell.style.background = "#FFF";
                cell.style.color = "#000";
            }
            cell.style.fontWeight = "bold";
            cell.style.paddingLeft = "1rem";
        } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
            const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
            if(colors && colors.bg && colors.color) {
                cell.style.background = colors.bg;
                cell.style.color = colors.color;
            } else {
                cell.style.background = "#FFF";
                cell.style.color = "#000";
            }
            cell.style.fontWeight = "bold";
            cell.style.paddingLeft = "1rem";
        } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rf and Def Due Date") {
          const colors = this._colorCode.showRfDueDtColorRefactoring(row.dataItem);
          if(colors && colors.bg && colors.color) {
              cell.style.background = colors.bg;
              cell.style.color = colors.color;
          } else {
              cell.style.background = "#FFF";
              cell.style.color = "#000";
          }
      } else {
              cell.style.backgroundColor = "#FFF";
              cell.style.color = "#000";
              cell.style.fontWeight = "normal";
              cell.style.textAlign = "initial";
          }
      }
  };
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("RxHistoryCurrentView");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("RxHistoryCurrentView", storedWJ);
}
  generateList(data: any) {
    this.totalCount = data?.length;
    this.gridData = new CollectionView(
        data.rxHistory.map((patRx, i) => {
            const k = {};
            k["Id"] = patRx.Id;
            k["Rx#"] = this._formatUtils.checkRefill(
              patRx.Prescription.PrescNum,
              patRx.Prescription.ReFillNum,
              patRx.PrescReFill.ReFillNum
          );
          k["Auth#"] = patRx.Prescription.ReFillNum;
          k["IsBilled"] = this._comnUtil.checkIsGreenBilled(patRx, this.systemData);
          k["IsPicked"] = patRx.pickupRx ? patRx.pickupRx.IsPickedUp === true ? true : false : false;
          k["DrugClass"] = patRx?.Drug?.drugclass ? patRx?.Drug?.drugclass : 0;
          k["Patient Name"] =
          (patRx.Patient.lastname + ", " + patRx.Patient.firstname).toUpperCase();
          k["Fill#"] = this._commonSer.checkIsPartialFill(patRx.PrescReFill, patRx.Prescription)
          ? patRx.PrescReFill.PartialFillNo : "";
          k["fillNo"] = patRx.PrescReFill.PartialFillNo ? patRx.PrescReFill.PartialFillNo : 1;
          k["Supply Days"] = k["Days Supply"] = Math.round(patRx.PrescReFill.SupplyDays);
          k["Ref"] = k["Ref#"] = patRx.PrescReFill.ReFillNum;
          k["Fill Date"] = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
            "MM/DD/YYYY hh:mm:ss a"
        );
          k["Status"] =
          (patRx.PrescReFill.StatusId === 4 || patRx.Prescription.RxStatusId === 4) ? "T" :
              (patRx.PrescReFill.StatusId === 2 || patRx.Prescription.RxStatusId === 2 || patRx.RxBill.StatusId === 6) ? "D" :
                  (patRx.PrescReFill.StatusId === 3
                      || patRx.Prescription.RxStatusId === 3) && patRx.RxBill.StatusId === 2 ? "BI" :
                      (patRx.RxBill.StatusId === 2 && patRx.PrescReFill.StatusId !== 4
                          && patRx.Prescription.RxStatusId !== 2) ? "B" :
                          ((patRx.PrescReFill.StatusId === 3 || patRx.Prescription.RxStatusId === 3) &&
                              patRx.RxBill.StatusId === 1) ? "UI" : "U";
              let tqtyDisp = "0.000";
              let dispQty = 0;
              let totalDispQty = 0;
              let OrdQty = 0;
              let filledRefillsQty = 0;
              k["Insurance"] = this._commonSer.rxInsurance(patRx);
              k["Prescriber Name"] = patRx.Prescriber ? 
                patRx.Prescriber.fullname.replace("_",", ") : null;
              if (this.prescriptionQuantities && this.prescriptionQuantities.length) {
                const rxc = this.prescriptionQuantities.find(v => v["PrescNum"] === patRx.Prescription.PrescNum);
                tqtyDisp = rxc && rxc["Qty"] ? (rxc["Qty"]).toFixed(3) : "0.000";
                dispQty = parseFloat(tqtyDisp);
                totalDispQty = parseFloat(rxc && rxc["TotalDispensedQty"] ? (rxc["TotalDispensedQty"]).toFixed(3) : "0.000");
                OrdQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * (k["Auth#"] + 1)) : 0;
                filledRefillsQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * rxc["RefillsCount"]) : 0;
            }
            k["TQty Disp"] = tqtyDisp;
            k["Qty Disp"] = patRx.PrescReFill.DispQty ? (patRx.PrescReFill.DispQty).toFixed(3) : "0.000";
            k["Rf and Def Due Date"] = (k["Status"] === "D" && patRx.RxFile && patRx.RxFile.DueDtTm) ? moment.utc(patRx.RxFile.DueDtTm).local().format("MM/DD/YYYY") : ((!(k["Refills Left"]) &&  ((k["Qty Rem"] === " ") || (k["Qty Rem"] === "0.000") )) ? "" :  moment.utc(patRx.PrescReFill.NextFillDt).local().format(
              "MM/DD/YYYY"
          )) ;
            k["Drug Name"] = (patRx?.Drug?.drugname?.trim() + " " + patRx?.Drug?.strength?.trim() + " " + patRx?.Drug?.drugformname?.trim()).toUpperCase();
            k["Rx Qty Rem"] = (OrdQty && ((!patRx.isRxTransferred && (+this.RfOrRx)) ||
             !(+this.RfOrRx)) ? (OrdQty - dispQty).toFixed(3) : "0.000");
             console.log(k["Rx Qty Rem"], "RxQtyRem", k["Rx#"], "Rx#", patRx.PrescReFill.OrdQty, "patRx.PrescReFill.OrdQty")
            k["Refills Left"] =!patRx.isRxTransferred? (patRx.PrescReFill.OrdQty ? this.integerFloatFormat.transform((k["Rx Qty Rem"]/patRx.PrescReFill.OrdQty)):0) : 0;
            k["NDC"] = (patRx && patRx.Drug && patRx.Drug.ndc) ? this._formatsUtil.getNDCFormat(patRx.Drug.ndc): "";
            k["T.Rx Qty Rem"]  = (patRx.PrescReFill.IsOverFillRx) ? ( (k["islatestrefill"]  ?
            k["Rx Qty Rem"] : "0.000")) : k["Rx Qty Rem"];
            k["Refills Left"] =!patRx.isRxTransferred? (patRx.PrescReFill.OrdQty ? this.integerFloatFormat.transform((k["Rx Qty Rem"]/patRx.PrescReFill.OrdQty)) : 0) : 0;
            return k;
        })
    );
}
toggleColorLegend() {
  this._alertServ.showHelpText({ RxStatus: false, DrugName: true, Status: true });
}
}
