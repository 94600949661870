<div class="exprx--common-block edit--drug mt-0 mb-0">
    <div class="eprx--block__header h-75">
        <div class="eprx--header__heading">
            <div class="col-sm-12 padding-0">
                <div class="row pt-3">
                    <div class="col-md-10">
                        <span><h2>Add Drug to Bucket</h2></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content">
        <span class="ml-2">You are adding this Drug to the selected Bucket for the first time. Please confirm the drug pricing
            information.</span>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 pl-0 pr-0">
            <div class="box  box-solid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col">
                                    Drug Information
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <label>Bucket Name:</label>
                            <div class="label--data font-weight-bold">{{DrugBucketInfo ? (DrugBucketInfo.Name | uppercase) : "--"}}</div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <label>Drug Name:</label>
                            <div class="label--data font-weight-bold">{{((DrugInfo?.Drug?.Name ? DrugInfo?.Drug?.Name : "") +
                                (DrugInfo?.Drug?.Strength ? " "+DrugInfo?.Drug?.Strength.trim() : "") +
                                (DrugInfo?.Drug?.DrugFormName ? " "+DrugInfo?.Drug?.DrugFormName.trim() : "")) | uppercase}}</div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <label>Stock In Hand:</label>
                            <div class="label--data font-weight-bold">{{DrugBucketInfo?.Qty ? (DrugBucketInfo?.Qty).toFixed(3) : "0.000"}}</div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <label>Drug NDC:</label>
                            <div class="label--data font-weight-bold">{{DrugFG?.get('Drug').value.DrugCode ?
                                (this._formatsUtil.getNDCFormat(DrugFG?.get('Drug').value.DrugCode)) : "--"}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row equal--height">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 exprx--common-block equal--height-grid">
                <div class="box  box-solid">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col">
                                        Drug Pricing Information
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <eprx-input [LabelText]="'Pack AWP Price'" [PlaceHolder]="'Pack AWP Price'"
                                    [DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'" [ControlName]="'AWPPack'"
                                    [MaskPattern]="'099999.00'" [RxSelectId]="'AWPPack'" [IsDisabled]="true" [FormGroupName]="DrugFG?.get('Drug')">
                                </eprx-input>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <eprx-input [LabelText]="'Unit AWP Price'" [PlaceHolder]="'Unit AWP Price'"
                                    [DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'"  [ControlName]="'UnitPriceAWP3Digits'"
                                    [MaskPattern]="'099999.00'" [RxSelectId]="'UnitPriceAwp'" [IsDisabled]="true" [FormGroupName]="DrugFG?.get('Drug')">
                                </eprx-input>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <eprx-input [LabelText]="'Unit Price Direct'" [PlaceHolder]="'Unit Price Direct'"
                                    [DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'"  [ControlName]="'DirectUnitPrice'"
                                    [MaskPattern]="'099999.00'" [RxSelectId]="'UnitPriceDir'" [IsDisabled]="true" [FormGroupName]="DrugFG?.get('Drug')">
                                </eprx-input>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <eprx-input [LabelText]="'Pack Cost'" [PlaceHolder]="'Pack Cost'" [RxSelectId]="'costPackId'" [ControlName]="'CostPack'"
                                    [DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'"  [AutoFocus]="true" (TriggerSearchValue)="convertCostPackNUnitPrice('COSTPACK')"
                                    [MaskPattern]="'099999.00'" [FormGroupName]="ManageInventory">
                                </eprx-input>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <eprx-input [LabelText]="'Unit Cost'" [PlaceHolder]="'Unit Cost'" [ControlName]="'UnitPriceCost'"
                                    [DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'" (TriggerSearchValue)="convertCostPackNUnitPrice('UNITPRICECOST')"
                                    [MaskPattern]="'09999.000'" [RxSelectId]="'UnitPriceCost'" [FormGroupName]="ManageInventory">
                                </eprx-input>
                            </div>
                           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Quantity in Pack'" [PlaceHolder]="'Quantity in Pack'" [ControlName]="'QtyPack'" [IsDisabled]="true"
                                    [DropSpclChar]="false" [DecimalValues]="2"  [FormGroupName]="ManageInventory" [MaskPattern]="'099999.000'"
                                    [RxSelectId]="'QtyPack'">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 exprx--common-block equal--height-grid">
                <div class="box  box-solid">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col">
                                        Inventory Information
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-radio [LabelText]="'Manage Inventory'"  [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                [IsDisabled]="!DrugBuckFG.value.Id" [ControlName]="'IsManaged'" [FormGroupName]="ManageInventory" [List]="['Yes','No']"
                                [ValueList]="[true,false]" [IDForList]="['ActiveAddDrug','InActiveAddDrug']" (TriggerSelectValue)="checkIsBuckPhyInv()" [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Reorder level'" [PlaceHolder]="''"  [ControlName]="'ReorderQty'" [MaskPattern]="'099999.000'"
                                    [InputType]="'NUMBER'" [MaxLength]="6" [DropSpclChar]="false" [FormGroupName]="DrugPO">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Target Qty'" [PlaceHolder]="''"  [MaskPattern]="'099999.000'"
                                    [DropSpclChar]="false" [DecimalValues]="3" [InputType]="'NUMBER'" [FormGroupName]="DrugPO" [ControlName]="'TargetQty'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-select [LabelText]="'Preferred Vendor'" [PlaceHolder]="'Preferred Vendor'" [List]="prefVendorList"
                                 [RxSelectId]="'PreferrVednId'" [LabelForId]="'vendorpref'" [FormGroupName]="DrugPO" [ControlName]="'ContractId'" [BindLabel]="'ContractName'"
                                [BindValue]="'ContractId'" [BindLabel2]="'VendorName'" [Show2Values]="true" (TriggerSelectValue)="drugPreferredVendor($event)" [show2ValInInputhidden]="true" [LabelTitle2]="'Vendor Name'" [LabelTitle1]="'Account'" [HasMultiple]="false">
                               </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Minimum Order Qty'" [PlaceHolder]="'Minimum Order Qty'" [ControlName]="'MinOrderQty'"
                                    [DropSpclChar]="false" [DecimalValues]="2"  [FormGroupName]="DrugPO" [MaskPattern]="'099999.000'"
                                    [RxSelectId]="'MinimumOrderQty'">
                                </eprx-input>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 align-button text-right mt-3">
            <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'s'" id="search"
            (click)="addDrugToBuck($event, IsFrmRx)" (keydown.enter)="addDrugToBuck($event, IsFrmRx)"><span>S</span> Save
            </button>
            <button id="closeAddDrgBuck" (click)="closePopUp('NOTADDED')" class="hotkey_success" (keydown.enter)="closePopUp('NOTADDED')"
                appShortcutKey [AltKey]="'c'"><span>C</span>Close</button>
        </div>
    </div>
</div>


<ng-template #PRIVANDMANG let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="c('Close click');inActvMangInv($event,false)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span [innerHTML]="messageFor"></span>
    </div>
    <div class="modal-footer">
        <button footer autofocus class="hotkey_primary" (click)="c('Close click');inActvMangInv($event,true)" appShortcutKey
            InputKey="y"><b>Y</b>YES
        </button>
        <button footer autofocus class="hotkey_primary" (click)="c('Close click');inActvMangInv($event,false)" appShortcutKey
            InputKey="n"><b>N</b>NO
        </button>
    </div>
</ng-template>

<ng-template #UpdateCost let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click');">
        <h4 header>Drug Preferred Vendor</h4>
        <span body>
            You have selected <span class="font-weight-bold">'{{(vendorDetails ? vendorDetails?.VendorName : vendorName) | uppercase }}'</span> as your preferred vendor. Would you like to update
            the drug cost from this vendor?
            <br><br>
            Current Unit Cost:    <span class="font-weight-bold">{{ManageInventory?.value?.UnitPriceCost  | currency
                : "$"
                : "symbol"
                : "1.3-3"}}</span>
            <br>
            <span class="font-weight-bold">{{(vendorDetails ? vendorDetails?.VendorName : vendorName) | uppercase }}</span> Unit Cost:   
            <span [ngClass]="(vendorDetails && vendorDetails?.UnitCost)? 'font-weight-bold':''"> {{ vendorDetails ? (vendorDetails?.UnitCost  |  currency
                : "$"
                : "symbol"
                : "1.3-3") : "NOT in system, would you like to Add?"}}</span>
        </span>
        <button *ngIf="!hasVendorDetails" footer autofocus (click)="openVendorItemModal()" class="hotkey_primary" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button *ngIf="hasVendorDetails" footer autofocus (click)="patchToDrugUnitCost();closeUpdateModal()" class="hotkey_primary" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="closeUpdateModal()"appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #vendorItemAdd let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Vendor - Drug Definition</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeVendoeItemModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" [formGroup]="vendorItemFG">
        <div class="row">
            <div class="col-md-6">
                <label class="col-md-12 padding-0">Drug Name</label>
                <div class="label--data">
                    {{((DrugInfo?.Drug?.Name ? DrugInfo?.Drug?.Name : "") +
                                (DrugInfo?.Drug?.Strength ? " "+DrugInfo?.Drug?.Strength.trim() : "") +
                                (DrugInfo?.Drug?.DrugFormName ? " "+DrugInfo?.Drug?.DrugFormName.trim() : "")) | uppercase}}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 padding-0">NDC </label>
                <div class="label--data">
                    {{this._formatsUtil.getNDCFormat(DrugFG?.get('Drug').value.DrugCode)}}
                </div>
            </div>
            <div class="col-md-4">
                <label class="col-md-12 padding-0"> Strength </label>
                <div class="label--data">
                    {{DrugFG?.value?.Drug?.Strength}}
                </div>
            </div>
            <div class="col-md-4">
                <label class="col-md-12 padding-0">Form</label>
                <div class="label--data">
                    {{DrugFG?.value?.Drug?.DrugFormName}}
                </div>
            </div>
            <div class="col-md-4">
                <label class="col-md-12 padding-0">Quantity in Pack</label>
                <div class="label--data">
                    {{ManageInventory?.value?.QtyPack}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <eprx-input [AutoFocus]="true" [LabelText]="'Vendor Item Code'"
                    [PlaceHolder]="'only numerical values are accepted'" [ControlName]="'ItemCode'"
                    [FormGroupName]="vendorItemFG" [IsRequired]="true" [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="vendorFgInvalid" [InputType]="'NUMBER'">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-select [LabelText]="'Vendor Name'" [PlaceHolder]="'Vendor Name'" [ControlName]="'ContractId'"
                    [FormGroupName]="vendorItemFG" [List]="dropDownValues?.Vendor" [LabelTitle2]="'Vendor Name'" [LabelTitle1]="'Account'"
                    [RxSelectId]="'vendorList'" [HasMultiple]="false" [BindLabel]="'ContractName'" [BindValue]="'ContractId'" [BindLabel2]="'VendorName'" [Show2Values]="true"
                    [LabelForId]="'VendorName'" [IsRequired]="true" [ErrorDefs]="{ required: 'Required' }" [IsDisabled]="true"
                    >
                </eprx-select>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Unit Price - AWP'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit Price - AWP'"
                    [ControlName]="'UnitAWP'" [FormGroupName]="vendorItemFG"
                    (TriggerSearchValue)="convertNumberFormat('UnitAWP', 3)" [MaskPattern]="'099999.000'"
                    [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'AWP Pack'" [InputType]="'CURRENCY'" [PlaceHolder]="'AWP Pack'" [ControlName]="'AWP'"
                    [FormGroupName]="vendorItemFG" (TriggerSearchValue)="convertNumberFormat('AWP', 3)"
                    [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Unit Price - Cost'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit Price - Cost'"
                    [ControlName]="'UnitCost'" [FormGroupName]="vendorItemFG" [DecimalValues]="3"
                    (TriggerSearchValue)="convertNumberFormat('UnitCost', 3)" [MaskPattern]="'099999.000'"
                    [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Cost Pack'" [InputType]="'CURRENCY'" [PlaceHolder]="'Cost Pack'" [ControlName]="'PackCost'"
                    [FormGroupName]="vendorItemFG" (TriggerSearchValue)="convertNumberFormat('PackCost', 3)"
                    [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="saveVendorItems()" appShortcutKey [AltKey]="'s'">
                    <span>S</span>Save
                </button>
                <button class="hotkey_success" appShortcutKey [AltKey]="'c'"  (click)="closeVendoeItemModal();"
                    >
                    <span>C</span>Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>
