import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CollectionView } from '@grapecity/wijmo';
import * as moment from "moment";
import { CommonUtil, WijimoUtil } from 'src/app/utils';
import { MessageService } from 'src/app/services/message.service';
import { AlertService } from 'src/app/services';
import { MsgConfig } from 'src/app/app.messages';
import { TitleCasePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-send-refill-due',
  templateUrl: './send-refill-due.component.html'
})
export class SendRefillDueComponent implements OnInit {
    refilDueDataInnerGrid: CollectionView;
    refilDueDatawj: CollectionView;
    refilDueDataIndividualView: CollectionView;
    wjHeaders: any;
    wjHeaders2: any;
    wjHeaders3: any;
    allSelect: boolean;
    flexGrid: any;
    groupView = false;
    individualdata: any = [];
    slctdRxObj: any = {};
    SelectAllRxs: boolean;
    NotValidRxs: any = [];
    messageToDisplay: any;
    unsubscribe$: Subject<void> = new Subject();
    modelRef2: any;
    @Input()
    set DataFromReport(data) {
        if (data) {
            this.reportData = data;
        }
    }
    @Output() CloseRefillDueModal = new EventEmitter<any>();
    @ViewChild("openPopup", { static: true })
    openPopup: any;
    @ViewChild("exceptionPopup", { static: true })
    exceptionPopup: any;
    modelRef: any;
    reportData: any;
  constructor(private _modelSer: NgbModal,
    private _comnUtil: CommonUtil,
    private _wijimoUtils: WijimoUtil,
    private _msgServ: MessageService,
    private _alertService: AlertService,
    public _titleCasePipe: TitleCasePipe) { }

  ngOnInit() {
    this.openPopUpModal();
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(w => {
        if (w && w["WijmoKey"] && (w["WijmoKey"] === "refillDueMsgOuterWj" ||
         w["WijmoKey"] === "refillDueMsgInnerWj"  || w["WijmoKey"] === "refillDueMsgIndividualWj")) {
            this.patchDefaultValHeaders();
        }
    });
  }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("refillDueMsgOuterWj");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("refillDueMsgOuterWj", storedWJ);
    const storedWJ2 = await this._wijimoUtils.getWJSavedData("refillDueMsgInnerWj");
    this.wjHeaders2 = this._wijimoUtils.patchDefHeader("refillDueMsgInnerWj", storedWJ2);
    const storedWJ3 = await this._wijimoUtils.getWJSavedData("refillDueMsgIndividualWj");
    this.wjHeaders3 = this._wijimoUtils.patchDefHeader("refillDueMsgIndividualWj", storedWJ3);
}
  openPopUpModal() {
    this.modelRef = this._modelSer.open(this.openPopup, { size: "lg",
    windowClass: "max-width-80", centered: true, backdrop: false, keyboard: false });
    this.reportData.RxData.map(item => {
        item.refillsDueReportData.map(rx => {
            this.individualdata.push(rx);
        });
    });
    this.gridIndiviudal(this.individualdata);
  }
  generateWj() {
    this.refilDueDatawj = new CollectionView(
        this.reportData.RxData.map((Rx, i) => {
            const k = {};
            k["RowId"] = i;
            k["patientNo"] = Rx.PatientId;
            k["Patient Name"] = Rx.PatientName;
            k["Language"] = Rx.Language;
            k["Email"] = Rx.Email;
            k["Date of Birth"] = Rx.BirthDt && Rx.BirthDt !== "0001-01-01T00:00:00" ?
            moment(Rx.BirthDt).format("MM/DD/YYYY") : "";
            // k["Phone#"] = Rx.Mobile;
            k["Mobile#"] = Rx.Mobile ? this._comnUtil.getPhoneFormat(Rx.Mobile.trim()) : "";
            k["Patient Address"] = Rx.PatAddress;
            k["Gender"] = Rx.Gender;
            k["Is Not Pickup Remainder"] = Rx.SendReadyForPickupAlerts ? true : false;
            k["Insurance"] = Rx.PrimaryInsurance;
            k["IsSelected"] = false;
            k["RxCount"] = Rx.RxCount;
            k["Pickup Due Date"] = Rx.PickupDueDate && Rx.PickupDueDate !== "0001-01-01T00:00:00" ?
            moment(Rx.PickupDueDate).format("MM/DD/YYYY") : "";
            // k["Is Not Pickup SMS"] = Rx.SendSubscriptionMessageAlerts;
            // k["Is Not Pickup SMS"] = Rx.SendSubscriptionMessageAlerts;
            k["items"] = this.gridOnGrid(Rx.refillsDueReportData, Rx.Mobile);
            return k;
    })
    );
}
ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}
  gridOnGrid(data, mobile) {
    const insideArr: any = [];
    this.refilDueDataInnerGrid = new CollectionView(
        data.map((Rx, i) => {
            const k = {};
            k["RowId"] = i;
            k["PatientId"] = Rx.PatientId;
            k["Rx#"] = Rx.RxNo;
            k["Ref#"] = Rx.Lastref;
            k["Fill#"] = Rx.partialFillNo;
            k["Date Filled"] = Rx.LastfillDate;
            k["Quantity"] = Rx.Qty ?  (Rx.Qty).toFixed(3) :  "0.000";
            k["Auth#"] = Rx.AuthRefs;
            k["Drug Name"] = Rx.DrugName;
            k["Patient Name"] = Rx.PatientName;
            k["IsActive"] = !Rx.isDeceased ? true : false;
            k["Prescriber Name"] = Rx.PresName;
            k["Primary Insurance"] = Rx.Ins;
            k["Total Cost"] = Rx.RxCost ? "$" + (Rx.RxCost).toFixed(2) : "$0.00";
            k["Total Rx Amount"] = Rx.RxAmount ? "$" + (Rx.RxAmount).toFixed(2) : "$0.00";
            k["Rx Amount"] = Rx.RxAmount;
            k["Profit"] = Rx.ProfitAmnt ? "$" + (Rx.ProfitAmnt).toFixed(2) : "$0.00";
            k["IsSelected"] = false;
            k["PatientSMSName"] = Rx.PatientSMSName;
            k["Rx Type"] = Rx.RxType;
            k["Delivery Method"] = Rx.DeliveryMethod;
            k["PrescrefillId"] = Rx.PrescrefillId;
            k["MobileNumber"] = mobile;
            insideArr.push(k);
            return k;
        })
    );
    return insideArr;
  }

  gridIndiviudal(data) {
        this.refilDueDataIndividualView = new CollectionView(
            data.map((Rx, i) => {
                const k = {};
                k["RowId"] = i;
                k["PatientId"] = Rx.PatientId;
                k["Rx#"] = Rx.RxNo;
                k["Ref#"] = Rx.Lastref;
                k["Fill#"] = Rx.partialFillNo;
                k["Date Filled"] = Rx.LastfillDate;
                k["Quantity"] = Rx.Qty ?  (Rx.Qty).toFixed(3) :  "0.000";
                k["Auth#"] = Rx.AuthRefs;
                k["Drug Name"] = Rx.DrugName;
                k["Patient Name"] = Rx.PatientName;
                k["IsActive"] = !Rx.isDeceased ? true : false;
                k["Prescriber Name"] = Rx.PresName;
                k["Primary Insurance"] = Rx.Ins;
                k["Total Cost"] = Rx.RxCost ? "$" + (Rx.RxCost).toFixed(2) : "$0.00";
                k["Total Rx Amount"] = Rx.RxAmount ? "$" + (Rx.RxAmount).toFixed(2) : "$0.00";
                k["Rx Amount"] = Rx.RxAmount;
                k["Profit"] = Rx.ProfitAmnt ? "$" + (Rx.ProfitAmnt).toFixed(2) : "$0.00";
                k["IsSelected"] = false;
                k["PatientSMSName"] = Rx.PatientSMSName;
                k["Rx Type"] = Rx.RxType;
                k["Delivery Method"] = Rx.DeliveryMethod;
                let mobile: any;
                let dob: any;
                this.reportData.RxData.filter(item => {
                    if (item["PatientId"] === Rx.PatientId) {
                        mobile = item["Mobile"];
                        dob = item["BirthDt"];
                    }
                });
                k["Mobile#"] = mobile  ? this._comnUtil.getPhoneFormat(mobile.trim()) : "";
                k["Date of Birth"] = dob && dob !== "0001-01-01T00:00:00" ?
            moment(dob).format("MM/DD/YYYY") : "";
                k["PrescrefillId"] = Rx.PrescrefillId;
                k["MobileNumber"] = mobile;
                return k;
            })
        );

  }
  showData(item) {
    return item.items;
}
selectedRow(isChecked, patId, type, flexGrid) {
    let count = 0;
        if (type === "OuterGrid") {
            flexGrid.grid.collectionView.items.map(item => {
                if (item["patientNo"] === patId) {
                    item["IsSelected"] = isChecked;
                    item.items.map(rx => {
                        rx["IsSelected"] = isChecked;
                    });
                }
            });
        } else if (type === "InnerGrid") {
            flexGrid.grid.collectionView.items.map(item => {
                item.items.map(rx => {
                    if (rx["PrescrefillId"] === patId) {
                        rx["IsSelected"] = isChecked ? true : false;
                    }
                    if (rx["IsSelected"]) {
                        count++;
                        item["IsSelected"] = ((rx["PatientId"] === item["patientNo"]) && (count === item["RxCount"])) ? true : false;
                    }
                });
                count = 0;
            });
        } else {
            flexGrid._rows[0].collectionView._src.map(item => {
                if (item["PrescrefillId"] === patId) {
                item["IsSelected"] = isChecked;
                }
            });
        }
        if (type !== "singleGrid") {
            flexGrid.grid.collectionView.items.map(item => {
                if (item["IsSelected"]) {
                    count++;
                }
            });
            this.allSelect = (count === this.reportData.RxData.length) ? true : false;
            count = 0;
            this.flexGrid = flexGrid.grid.collectionView.items;
        } else {
            flexGrid._rows[0].collectionView._src.map(item => {
                if (item["IsSelected"]) {
                    count++;
                }
            });
            this.SelectAllRxs = (count === this.individualdata.length) ? true : false;
            count = 0;
            this.flexGrid =  flexGrid._rows[0].collectionView._src;
            flexGrid.refresh();
        }
    }
selectAllRx(isChecked: boolean, flexGrid, type) {
    if (type === "groupView") {
        this.allSelect = isChecked;
        flexGrid.grid.collectionView.items.map(item => {
            item["IsSelected"] = isChecked;
            item.items.map(rx => {
                rx["IsSelected"] = isChecked;
            });
        });
        this.flexGrid = flexGrid.grid.collectionView.items;
    } else {
        this.SelectAllRxs = isChecked;
        flexGrid._rows[0].collectionView._src.map(item => {
            item["IsSelected"] = isChecked;
        });
        this.flexGrid = flexGrid._rows[0].collectionView._src;
    }
}

sendRefilDueMessage() {
    const data: any = [];
    if (this.groupView) {
        this.flexGrid.map(item => {
            item.items.map(rx => {
                if (rx["IsSelected"] && rx["MobileNumber"]) {
                    data.push({
                        RxNo: rx["Rx#"],
                        LastRef: rx["Ref#"],
                        PatientId: rx["PatientId"],
                        PatientName: rx["Patient Name"],
                        DrugName: rx["Drug Name"],
                        RxAmount: rx["Rx Amount"],
                        ToNumber: rx["MobileNumber"],
                        PatientSMSName: rx["PatientSMSName"],
                    });
                }
            });
        });
    } else {
        this.flexGrid.map(rx => {
            if (rx["IsSelected"] && rx["MobileNumber"]) {
                    data.push({
                        RxNo: rx["Rx#"],
                        LastRef: rx["Ref#"],
                        PatientId: rx["PatientId"],
                        PatientName: rx["Patient Name"],
                        DrugName: rx["Drug Name"],
                        RxAmount: rx["Rx Amount"],
                        ToNumber: rx["MobileNumber"],
                        PatientSMSName: rx["PatientSMSName"],
                    });
                }
        });
    }


    if (data.length) {
        const payLoad = {
            MessageCatId : 2,
            refillsDueData: data
        };
        this._msgServ.SendRefillDueData(payLoad)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this._alertService.success(
                    MsgConfig.REFILL_DUE_MSG_SUCCESS
                );
                this.closeModal();
            }
        });
    }

}
closeModal() {
    if (this.modelRef) {
        this.modelRef.close();
        this.modelRef = null;
        this.CloseRefillDueModal.emit(false);
    } if (this.modelRef2) {
        this.modelRef2.close();
        this.modelRef2 = null;
    }
}
openModal(val) {
    this.groupView = val;
    if (!this.groupView) {
        this.allSelect = false;
       this.gridIndiviudal(this.individualdata);
    } else {
        this.SelectAllRxs = false;
        this.generateWj();
    }
}
CheckforMobileNumber() {
    const NotValidRxsList: any = [];
    if (this.groupView) {
    this.flexGrid.map(item => {
        item.items.map(rx => {
            if (rx["IsSelected"] && !rx["MobileNumber"]) {
                 if (NotValidRxsList.length) {
                    NotValidRxsList.push(this._titleCasePipe.transform(rx["Patient Name"]));
                 } else {
                    NotValidRxsList.push(this._titleCasePipe.transform(rx["Patient Name"]));
                 }
            }
        });
    });
} else {
    this.flexGrid.map(rx => {
        if (rx["IsSelected"] && !rx["MobileNumber"]) {
            if (NotValidRxsList.length) {
                NotValidRxsList.push(this._titleCasePipe.transform(rx["Patient Name"]));
            } else {
                NotValidRxsList.push(this._titleCasePipe.transform(rx["Patient Name"]));
            }
       }
        });
    }
    this.NotValidRxs = [...new Set(NotValidRxsList)];
    if (this.NotValidRxs.length) {
        this.messageToDisplay =
        "Cannot send message because the following Patient(s) have Wrong/Missing Phone Number:<br> <b>" +
        this.NotValidRxs.join(",", ", ") + "</b>.";
        this.modelRef2 = this._modelSer.open(this.exceptionPopup, {
            size: "sm",
            centered: true,
            windowClass: "max-modal-44",
            keyboard: false,
            backdrop: "static"
        });
    } else {
        this.sendRefilDueMessage();
    }
}
}
