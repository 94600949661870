<ng-template #NoteHistory let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePopup()">
        <h4 header> Notes For: {{SelectedItemForNoteHistory['Patient Name'] | titlecase}}&nbsp;&nbsp; Rx#:
            {{SelectedItemForNoteHistory['Rx#']}} - {{SelectedItemForNoteHistory['Ref#']}}
            <span *ngIf="SelectedItemForNoteHistory['Fill#']">- {{SelectedItemForNoteHistory['Fill#']}}</span>
        </h4>
        <span body>
            <div class="row">
                <div class="col-7">
                    <div class="row">
                        <div class="col-12">
                            <label>Notes History</label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3">
                            <label>Prescriber Name</label>
                            <div class="label--data ellpise" [title]="(SelectedItemForNoteHistory && SelectedItemForNoteHistory['Prescriber Name']) ? SelectedItemForNoteHistory['Prescriber Name'] : ''">
                                {{(SelectedItemForNoteHistory && SelectedItemForNoteHistory['Prescriber Name']) ? SelectedItemForNoteHistory['Prescriber Name'] : "--"}}</div>
                        </div>
                        <div class="col-3">
                            <label >Drug Name</label>
                            <div class="label--data ellpise" [title]="(SelectedItemForNoteHistory && SelectedItemForNoteHistory['Drug Name']) ? SelectedItemForNoteHistory['Drug Name'] : ''">{{(SelectedItemForNoteHistory && SelectedItemForNoteHistory['Drug Name']) ? SelectedItemForNoteHistory['Drug Name'] : ''}}</div>
                        </div>
                        <div class="col-2">
                            <label >Drug Class</label>
                            <div class="label--data" >{{(SelectedItemForNoteHistory && SelectedItemForNoteHistory['DrugClass']) ? 
                                SelectedItemForNoteHistory['DrugClass'] : ''}}</div>
                        </div>
                        <div class="col-4">
                            <button class="hotkey_success" (click)="printNotes()" appShortcutKey  [AltKey]="'p'">
                                <span>P</span>Print</button>
                                <button class="hotkey_success ml-3" (click)="newNotesOpen()" appShortcutKey  [AltKey]="'a'">
                                    <span>A</span>Add</button>
                        </div>
                        
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <wj-flex-grid #flex 
                            id="theGridTallRows"
                                [headersVisibility]="'Column'" [itemsSource]="notesHistory" [isReadOnly]="true"
                                 [selectionMode]="'Row'" [allowSorting]="true"  (click)="onRowDblclicked(flex, $event)"
                                class="wjm-grd-custom-height-22"> 
                                <wj-flex-grid-column [header]="'Action'" [width]="63">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item"
                                        let-row="row">
                                        <span class="row text-center">
                                                <span class="text-center ml-3">
                                                    <input class="form-check-input ml-2" type="checkbox" [id]="item.RowId" name=""
                                                        [(ngModel)]="item.IsSelected"  [checked]="item.IsSelected"  
                                                        (click)="selecteNotes($event?.target?.checked, item)"/>
                                                    <label class="form-check-label" [for]="item.RowId">
                                                    </label>
                                                </span>
                                        </span> 
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                    [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                                    *ngFor="let rxheader of wjHeaders; let i = index">
                                    <div>
                                        {{rxheader['hName']}} 
                                    </div>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>

                    </div>
                </div>
                <div class="col-5">
                    <div class="row">
                        <div class="col-12">
                            <label>Notes Details </label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="card existing-patient-family-wijmo border_25">
                                <div class="card-header notes_History">
                                        <div class="row">
                                            <div class="col-6 marging_top14">
                                                <label >Created Date & Time</label>
                                                <div class="label--data">   {{selectedItem['Created Date & Time'] ?selectedItem['Created Date & Time']  : '--' }}</div>
                                            </div>
                                            <div class="col-6 marging_top14">
                                                <label >Created By</label>
                                                <div class="label--data">   {{selectedItem['Created By'] ? selectedItem['Created By'] : '--'}}</div>
                                            </div>
                                        </div>
                                </div>
                                <div class="card-body">
                                    <div class="row margin_11">
                                        <div class="col-12"  *ngIf="isAdd">
                                            <eprx-text-area [IsRequired]="true" 
                                            [LabelText]="'Annotation'" [FormGroupName]="noteHistoryFormGroup" [ControlName]="'annotationNotes'" 
                                            [PlaceHolder]="'Annotation'" [AutoFocus]="true"  [Rows]="8" [cols]="12"></eprx-text-area>
                                        </div>
                                        <div class="col-12" *ngIf="!isAdd">
                                            {{selectedItem['Notes'] ? selectedItem['Notes'] : '--'}}
                                        </div>
                                    </div>
                                  
                                </div>
                                <div class="card-footer bg-white" *ngIf="isAdd">
                                    <div class="row float-right">
                                        <div class="ml-1"  >
                                            <button footer ngbAutofocus class="hotkey_success " (click)="saveNotes()" appShortcutKey
                                            [AltKey]="'s'" ><span>S</span>Save</button>
                                        </div>
                                        <div >
                                            <button footer ngbAutofocus class="hotkey_success" (click)="isAdd = false" appShortcutKey
                                            [AltKey]="'l'"><span>L</span>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                              </div>
                        </div>
                    </div>

                </div>

            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_success" (click)="closePopup()" appShortcutKey
        [AltKey]="'c'"><span>C</span>Close</button>


    </app-custom-modal>
</ng-template>