<ng-template #OTHERAMOUNT let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal(true)">
        <h4 header> Other Amount </h4>
        <span body>
            <div class="row">
                <div class="col-6">
                    <div class="col-md-12 padding--right__7">
                        <eprx-input ngbAutofocus [LabelText]="'Profession Service Fee'" [PlaceHolder]="''" [ControlName]="'ProfFee'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                            [FormGroupName]="rxFG?.controls?.RxOtherAmount" (TriggerSearchValue)="changeToNumber($event?.value, 'ProfFee')" [AutoFocus]="true" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'ProfessionServiceFee'">
                        </eprx-input>
                    </div>
                    <div class="col-md-12 padding--right__7">
                        <eprx-input [LabelText]="'Incentive Amount'" [PlaceHolder]="''" [ControlName]="'Incentive'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                            [FormGroupName]="rxFG?.controls?.RxOtherAmount" (TriggerSearchValue)="changeToNumber($event?.value, 'Incentive')" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'IncentiveAmount'">
                        </eprx-input>
                    </div>
                    <div class="col-md-12 padding--right__7">
                        <eprx-input [LabelText]="'Flat Sales Tax Amount'" [PlaceHolder]="''" [ControlName]="'FlatSalesTax'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                            [FormGroupName]="rxFG?.controls?.RxOtherAmount" (TriggerSearchValue)="changeToNumber($event?.value, 'FlatSalesTax')" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'FlatSalesTaxAmount'">
                        </eprx-input>
                    </div>
                    <div class="col-md-12 padding--right__7">
                        <eprx-input [LabelText]="'Sales Tax Percentage'" [PlaceHolder]="''" [ControlName]="'SalesTaxPer'" [InputType]="'NUMBER'" [DecimalValues]="2" [MaxLength]="5"
                            [FormGroupName]="rxFG?.controls?.RxOtherAmount" [MaskPattern]="'099.00'" [DropSpclChar]="false" (TriggerSearchValue)="changeInTaxValue()" [RxSelectId]="'SalesTaxPercentage'">
                        </eprx-input>
                    </div>
                    <div class="col-md-12 padding--right__7">
                        <eprx-input [LabelText]="'Percentage Sales Tax Amount'" [PlaceHolder]="''" [ControlName]="'SalesTax'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                            [FormGroupName]="rxFG?.controls?.RxOtherAmount" (TriggerSearchValue)="checkAsManul($event?.value, 'SalesTax');" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                            [IsDisabled]="!(insuSetng?.InsuPlanPrice?.IsTaxOnOTC || insuSetng?.InsuPlanPrice?.IsTaxOnRx)"
                            [Title]="!(insuSetng?.InsuPlanPrice?.IsTaxOnOTC || insuSetng?.InsuPlanPrice?.IsTaxOnRx) ? 'Sales Tax amount is not calculated for this Rx' : 'Sales Tax amount'" [RxSelectId]="'PercentageSalesTaxAmount'">
                        </eprx-input>
                    </div>
                    <div class="col-md-12 padding--right__7">
                        <eprx-select [LabelText]="'Percent. Sales Tax Basis'" [PlaceHolder]="'Sales Tax Basis'"
                            [ControlName]="'SalesTaxBasis'" [FormGroupName]="rxFG?.controls?.RxOtherAmount"
                            [secondCntrlN]="'SalesTaxBasisName'" [bindScnCntrlV]="'Name'"
                            [HasMultiple]="false" [Title]="'Percent. Sales Tax Basis'" [HasBindLabel2]="true"
                            [BindLabel]="'Remarks'" [ShowLabel2]="true" [BindLabel2]="'Name'"
                            [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="insuDrpLst ? insuDrpLst?.SalesTaxBasis : ''" (TriggerSelectValue)="changeInTaxValue()" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                <div class="col-6">

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <eprx-input [LabelText]="'Patient Paid Amount'" [PlaceHolder]="''" [ControlName]="'PaidAmnt'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                            [FormGroupName]="rxFG?.controls?.RxOtherAmount" (TriggerSearchValue)="changeToNumber($event?.value, 'PaidAmnt')" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'PatientPaidAmount'">
                        </eprx-input>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <eprx-select [LabelText]="'Basis of Cost'" [RxSelectId]="'BasCost'" [PlaceHolder]="''" [List]="insuDrpLst ? insuDrpLst?.CostBasis : ''"
                         [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Name'" [ControlName]="'CostBasisCatId'"
                            [FormGroupName]="rxFG?.controls?.RxOtherAmount" [bindScnCntrlV]="'Remarks'" [secondCntrlN]="'CostBasisCatName'" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <eprx-input [LabelText]="'Usual & Customary'" [PlaceHolder]="''" [ControlName]="'UnitPriceUNC'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                            [FormGroupName]="rxFG?.controls?.PrescReFill" (TriggerSearchValue)="changedUsualAndCustomary($event)" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'UsualNdCustomary'">
                        </eprx-input>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [formGroup]="rxFG?.controls?.RxOtherAmount">
                        <label>Other Amount</label>
                        <div class="row" formArrayName="OtherAmntMisc">
                            <div class="col">
                                <div class="row" *ngFor="let otherAmntDetls of OtherAmntMisc?.controls; let i=index">
                                    <div class="col-lg-6 col-md-6 col-xl-6">
                                        <eprx-input [LabelText]="''" [PlaceHolder]="''" [ControlName]="'Amount'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                                            [FormGroupName]="otherAmntDetls" (TriggerSearchValue)="changeMiscInfoToNumber($event?.value, i,  'Amount')" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'othAmount' + i">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-xl-6">
                                        <eprx-select [LabelText]="''" [PlaceHolder]="''" [List]="systemData?.OtherAmntCat"
                                        [secondCntrlN]="'OtherAmntCatName'" [bindScnCntrlV]="'Name'"
                                            [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'" [ControlName]="'OtherAmntCatId'"
                                            [FormGroupName]="otherAmntDetls" [HasBindLabel2]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [Show2Values]="true">
                                        </eprx-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="saveOtherAmount()" appShortcutKey [AltKey]="'s'"><span>S</span>  Save </button>
        <button footer class="hotkey_success" (click)="closeModal(true)" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>
