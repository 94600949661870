import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmNavigateComponent } from "../components/confirm-navigate/confirm-navigate.component";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class CanDeactivateGuard {
    _closePopup$: BehaviorSubject<any> = new BehaviorSubject(null);
    closePopup$ = this._closePopup$.asObservable();
    constructor(private _modalService: NgbModal) { }
    canDeactivate(component) {
        if (component.canDeactivateRoute && !(component.canDeactivateRoute === "OrdDt" || component.canDeactivateRoute === "FillDt")) {
            return true;
        } else {
            if (component.canDeactivateRoute === "OrdDt" || component.canDeactivateRoute === "FillDt") {
                return true;
            } else {
                const canNavigate = new Subject<boolean>();
                const modelRef = this._modalService.open(ConfirmNavigateComponent, {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content"
                });
                modelRef.componentInstance.EmitNavigateConfirm
                    .subscribe(resp => {
                        modelRef.close();
                        canNavigate.next(resp);
                        if (sessionStorage.getItem("VerfScrn") === "true") {
                            this._closePopup$.next(resp);
                        } else {
                            this._closePopup$.next("false");
                        }
                    });
                return canNavigate.asObservable();
            }
        }
    }
}


@Injectable()
export class CanDeactivateRxGuard {
    constructor(private _modalService: NgbModal) { }
    canDeactivate(component) {
        if (component.routeFrom !== "nr" && component.routeFrom !== "rnr") {
            if (component.canDeactivateRoute) {
                return true;
            } else {
                const canNavigate = new Subject<boolean>();
                const modelRef = this._modalService.open(ConfirmNavigateComponent, {
                    centered: true,
                    backdrop: false,
                    keyboard: false
                });
                modelRef.componentInstance.EmitNavigateConfirm
                    .subscribe(resp => {
                        modelRef.close();
                        canNavigate.next(resp);
                    });
                return canNavigate.asObservable();
            }
        } else {
            return true;
        }
    }
}
