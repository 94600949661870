<eprx-select *ngIf="type === 'PriceCode'" [LabelText]="'Price Code'" [PlaceHolder]="''" [ControlName]="controlName"
    [FormGroupName]="priceListFG" [HasMultiple]="false" [BindLabel]="'PriceCode'" [BindLabel2]="'Name'"
    [BindValue]="'Id'" [LabelForId]="'Name'" [List]="priceScheduleList?.PriceScheduleList" [IsFirstElem]="autoFoucsFeild"
    [InputErrors]="priceListFG?.controls['PriceSchId']?.errors" [ErrorDefs]="{ required: 'Required' }"
    [IsRequired]="true" #PriceList  [RxSelectId]="'PriceList'" [FormGroupInvalid]="formInvalid"
    (TriggerSelectValue)="selectedPrice($event)">
</eprx-select>

<app-searchbox-global class="common_global_search_max_content_display_for_reports_price_list"
*ngIf="type === 'Drug'" id="drugSearchR" #drugSearchR [SearchFor]="'Drug'" [ClearInputValue]="drugSearchInputValue"
    [RxSelectId]="'drugSearchR'" [AllowToAddNewDrug]="false" [DisableRadioButtons]="true" [IsFocused]="isInputFocused" [IsAddNew]="false"
    (SelectedDrug)="optedDrugtoAdd($event)"></app-searchbox-global>
