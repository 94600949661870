<ng-template #RxVerifHist let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header *ngIf="!isRxVerification"> Pharmacist Verification History</h4>
        <h4 header *ngIf="isRxVerification"> Rx Verification History</h4>
        <span body>
            <div class="eprx--block__content">
                <div *ngIf="!verifyHist && !(isForRphVerf || isForRxVerf)" class="text-center">
                    <h5>No Verification History available for this Rx.</h5>
                </div>
                <div *ngIf="verifyHistory?.length === 0 && !isForRxVerf" class="text-center">
                    <h5>No Rx Verification History available for this Rx.</h5>
                </div>
                <div *ngIf="verifyHistory?.length === 0 && !isForRphVerf" class="text-center">
                    <h5>No Rph Verification History available for this Rx.</h5>
                </div>
                <span *ngIf="verifyHistory?.length > 0">
                    <wj-flex-grid #VerifHist  class="min-height_2rem" [headersVisibility]="'Column'" [itemsSource]="verifyHistModified"
                        [isReadOnly]="true" [selectionMode]="'None'" [columnPicker]="'rphVeryHistListWJ'">
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                         [width]="rxheader['width']"   *ngFor="let rxheader of wjHeaders; let i = index">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div *ngIf="(rxheader['hName'] === 'Date Verified')">
                                    {{item[rxheader['hName']] | localTime | date:'MM/dd/yyyy hh:mm:ss a'}}
                                </div>
                                <div *ngIf="(rxheader['hName'] === 'Status')">
                                    <span *ngIf="(['R','R  ', 'r', 'R ', 'r '].indexOf(item[rxheader['hName']]) > -1 && isRxVerification)"> {{item['IsReverified'] ? "Re-Verified" : "Verified"}}</span>
                                    <span *ngIf="(['V', 'v', 'V ', 'v '].indexOf(item[rxheader['hName']]) > -1 && !isRxVerification)"> {{item['IsReverified'] ? "Re-Verified" : "Verified"}}</span>
                                    <span *ngIf="['F', 'f', 'F ', 'f '].indexOf(item[rxheader['hName']]) > -1"> Followup</span>
                                    <span *ngIf="['RV', 'rv', 'RV ', 'rv '].indexOf(item[rxheader['hName']]) > -1"> Needs ReVerification</span>
                                </div>
                                <div *ngIf="!(rxheader['hName'] === 'Date Verified' || rxheader['hName'] === 'Status')">
                                    {{item[rxheader['hName']]}}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>


                        <!-- <wj-flex-grid-column header="RX No." [width]="200">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                <span>{{rxInfo?.Prescription.PrescNum}}</span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column header="REFILL No." [width]="100">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                <span>{{rxInfo?.PrescReFill.ReFillNum}}</span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column header="Fill#" [width]="100">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                <span>{{rxInfo?.PrescReFill.PartialFillNo}}</span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'Verified By'" [width]="300" [binding]="'UserName'">
                        </wj-flex-grid-column>
                         <wj-flex-grid-column [header]="'Date Verified'" [width]="'*'" [binding]="'VerifDtTm'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-column header="Date Verified" [width]="200">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                <span>{{item.VerifDtTm | localTime | date:'MM/dd/yyyy hh:mm a'}}</span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'Status'" [width]="'*'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <span *ngIf="['R', 'r', 'R ', 'r '].indexOf(item.Status) > -1"> Rx Verification</span>
                                    <span *ngIf="['V', 'v', 'V ', 'v '].indexOf(item.Status) > -1"> Pharmacist Verification</span>
                                    <span *ngIf="['F', 'f', 'F ', 'f '].indexOf(item.Status) > -1"> Followup</span>
                                    <span *ngIf="['RV', 'rv', 'RV ', 'rv '].indexOf(item.Status) > -1"> Needs Reverification</span>
                                    </ng-template>
                        </wj-flex-grid-column> -->
                </span>
            </div>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>
