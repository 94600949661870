<eprx-select #subPriceList
[RxSelectId]="'subPriceList'"
[LabelText]="'Reason for Service'" [PlaceHolder]="''"
[ControlName]="'ServReasonId'" [FormGroupName]="durFG"
[IsFirstElem]="true"
[HasMultiple]="false" [BindLabel]="'Name'"
[AutoFocus]="true"
[BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
[secondCntrlN]="'ServReasonName'" [bindScnCntrlV]="'Name'"
[List]="defaultval"
[Show2Values]="true"
[InputErrors]="InputErrorsName" [ErrorDefs]="{ required: 'Required' }"

[FormGroupInvalid]="formGroupInvalid">
</eprx-select>
