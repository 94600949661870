import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { UserService } from './user.service';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class LogicoyService {
  tenantZipCode: any;
  tenantState: any;
  tenantCity: any;
  tenantStreet: any;
  DEA: any;
  constructor(private _commonSer: CommonService, private _userService: UserService) { }

  PMPNow(rxFG) {
    this._commonSer.getLogiCoyPDMPReport().subscribe(resp => {
      if (resp) {
          let DataToPost = this.logicoy(rxFG,resp)
          this._commonSer.getLogicoyUrl(resp['UserName'],resp['Password'],resp['InterfaceURL'],DataToPost).subscribe(res => {
              if (res) {
                  let logicoyLink = res['Report']['ReportRequestURLs']['ViewableReport'].Value
                  window.open(logicoyLink, "_blank");
              }
          })
      }
  });
  }

  logicoy(patient,logicoyResp) {
    this._commonSer._newTenatInfo$.subscribe(resp => {
      if (resp && resp["PharmacyDetails"]) {
          this.tenantZipCode = resp["PharmacyDetails"]["ZipCode"];
          this.tenantState = resp["PharmacyDetails"]["StateName"];
          this.tenantCity = resp["PharmacyDetails"]["CityName"],
          this.tenantStreet = resp["PharmacyDetails"]["AddressLine1"],
          this.DEA = resp["PharmacyDetails"]["DEARegistration"]
      }
    });
    const logicoyData = {
      Requester: {
        LicenseeRequestIdField: logicoyResp['LicenseeRequestId'],
        Location: {
            Address: {
                City: this.tenantCity,
                StateCode: this.tenantState,
                Street: [this.tenantStreet],
                ZipCode: this.tenantZipCode
            },
            DEANumber: this.DEA,
            Name: logicoyResp['PharmacyName'],
            npi: this._commonSer.DecryptData(localStorage.getItem("UniquePharmacyId"))
        },
        Provider: {
            DEANumber: this.DEA,
            FirstName: logicoyResp["providerFirstName"],
            LastName: logicoyResp["providerLastName"],
            NPINumber: this._commonSer.DecryptData(localStorage.getItem("UniquePharmacyId")),
            ProfessionalLicenseNumber: null,
            Role: this._userService.getToken("TechUserName") ? 'TECHNICIAN' : 'ADMIN'
        }
      },
      PrescriptionRequest: {
          Patient: {
              Address: {
                  City: patient.city,
                  StateCode: patient.state,
                  Street: [patient.address1],
                  ZipCode: patient.zipcode
              },
              Birthdate: patient.dob,
              Name: {
                  First: patient.firstname,
                  Last: patient.lastname
              },
              Phone: patient.mobile,
              SexCode: patient.gender
          }
      },
  }
  return logicoyData;
  }
}
