export class DrugInfo {
    AGEBANDNAME: String = null;
    FromAgeDays: Date = null;
    ToAgeDays: Date = null;
    MINDAILYUNITS: number = null;
    MAXDAILYUNITS: number = null;
    USUALDAILYUNITS: number = null;
    INGREDIENTNAME: String = null;
    MINDAILYDOSE: any = null;
    MAXDAILYDOSE: any = null;
    USUALDAILYDOSE: number = null;
    FREQUENCY: number = null;
    FREQUENCYDAYS: number = null;
    LIFETIMEMAX: number = null;
    PATIENTEXPERIENCE: number = null;
    DOSINGTYPE: string = null;
    HEPATICIMPAIRMENTMAYREQUIREDOSEADJUSTMENT: String = null;
    RENALIMPAIRMENTMAYREQUIREDOSEADJUSTMENT: String = null;
}
