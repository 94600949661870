import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsgConfig } from 'src/app/app.messages';
import { DUR, RxAuditLog, SystemData } from 'src/app/models';
import { AlertService, RxService } from 'src/app/services';
import { RxStore } from 'src/app/store';
import { NewAuditUtils, ValidateFormFieldsUtils } from 'src/app/utils';

@Component({
  selector: 'app-add-dur',
  templateUrl: './add-dur.component.html',
  styleUrls: ['./add-dur.component.scss']
})
export class AddDurComponent implements OnInit {
    @Input() systemData: SystemData;
    @Output() IsPopUpClosed = new EventEmitter<any>();
    @ViewChild("DURADD", { static: true })
    DURADD: any;
    @ViewChild("info", { static: true })
    info: any;

    modelRef: any;
    DurFG: FormGroup;
    modal: any;
    formGroupInvalid: boolean;
    rxInfo: any;

  constructor(private _modalService: NgbModal,
    private _fb: FormBuilder,
    private _validUtils: ValidateFormFieldsUtils,
    private _alrtServ: AlertService,
    private _rxStore: RxStore
    ) {
    this.createFG();
   }

  ngOnInit(): void {
    this.openDURPopup();
  }

  createFG() {
    this.DurFG = this._fb.group(new DUR());
  }

  openDURPopup() {
    this.modal = this._modalService.open(this.DURADD, {
        centered: true,
        size: "lg"
    });
  }

  checkDurFgHasValue() {
    const cntrlData = ["ServReasonId", "ProfServId", "ServResultId", "EffortLvlId", "ClinicalSignfId", "CoAgentNum", "CoAgentIdType"];
    let hasValue = false;
    cntrlData.map(cntrl => {
        if (this.DurFG.value[cntrl]) {
            hasValue = true;
        }
    });
    return hasValue;
}

  async addDUR() {
    this.DurFG.controls["IsActive"].setValue(true);
    if (this.DurFG.valid) {
        if (!this.checkDurFgHasValue()) {
            this.modelRef = this._modalService.open(this.info, {
                centered: true,
                backdrop: false,
                windowClass: "large--content"
            });
        } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
            this.formGroupInvalid = true;
            this._validUtils.validateAllFormFields(this.DurFG);
        } else {
            this.formGroupInvalid = false;
            const durDataNewRxFlow = this._rxStore.addDur$["source"]["value"];
            if (durDataNewRxFlow && durDataNewRxFlow.length) {
            durDataNewRxFlow.push(this.DurFG.value);
            this._rxStore.storeDurNewRxFlow(durDataNewRxFlow);
            } else {
                this._rxStore.storeDurNewRxFlow([this.DurFG.value]);
            }
            this.closeModal();
            this._alrtServ.success(MsgConfig.SAVE_SUCCESS);
        }
    } else {
        this.formGroupInvalid = true;
        this._alrtServ.error(MsgConfig.MANDATORY_FIELDS);
    }
}
    closeModal() {
        if (this.modal) {
            this.modal.close();
            this.modal = null;
        }
        this.IsPopUpClosed.emit(null);
    }
}
