import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    HostListener
} from "@angular/core";
import { LoaderService } from "../../services";

@Component({
    selector: "app-loader",
    templateUrl: "./loader.component.html"
})
export class LoaderComponent implements OnInit, AfterViewInit, OnDestroy {
    _loader: any;
    message: any = "";


    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (this._loader) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
        }
    }

    constructor(
        private loaderService: LoaderService,
        private _cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loaderService
            .getMessage()
            .subscribe(loader =>{
                    this._loader = loader;
                    this._cdr.detectChanges();
                });
        this.loaderService
            .getLoaderMessage()
            .subscribe(message =>
                {
                    this.message = message
                }
                );
    }
    ngAfterViewInit() {
        this._cdr.markForCheck();
    }
    ngOnChanges() {
        this._cdr.detectChanges();
    }
    ngOnDestroy() {
        this._cdr.detach();
    }
   
}
