
import { RxFollowTag, RxModel } from "./../../../models";
import { AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    Component,
    HostListener
} from "@angular/core";
import { RxService } from "../../../services";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { RxStore } from "src/app/store";
import { NRxSaveUtils, PatchRXFGUtil } from "src/app/utils";
import { ErxExceptionReportService } from "src/app/services/erx-exception-report.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-rx-follow-tag",
    templateUrl: "./rx-follow-tag.component.html"
})
export class RxFollowTagComponent implements OnInit {
    @Input() rxInfo: RxModel;
    @Input() rxFwTgInfo: any;
    @Input() savedFlwInfo: any;
    @Input() folwUpList: any;
    @Input() rxFG: any;
    @Input() frmTrans: boolean;

    RxFwUpFG: FormGroup;
    modalRef: any;
    showTag = true;
    prescNumber: number;
    FollowFG: FormGroup;
    rxData: any;
    RxNewList: RxFollowTag[];
    originalData = [];
    routeFrom: any;
    prescRefillId: any;
    unsubscribe$: Subject<void> = new Subject();

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    CancelFolwUpTag = new EventEmitter<any>();

    @ViewChild("RXFWTG", { static: true })
    RXFWTG: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modalRef && this._modalService.hasOpenModals()) { // esc
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _rxService: RxService,
        private _fb: FormBuilder,
        private _alertService: AlertService,
        private _rxStore: RxStore,
        private _pRxFGUtls: PatchRXFGUtil,
        private _erxEcptnServ: ErxExceptionReportService,
        private _nrxSaveUtils: NRxSaveUtils
    ) {
        this.FollowFG = this._fb.group({
            PrescNum: null,
            PrescRefillId: null,
            RefillNum: null,
            PartialfillNum: null,
            PrescRefill_RxTags: this._fb.array([])
        });
        this.RxFwUpFG = this._fb.group({
            RxTag: null,
            RxTagId: this._fb.array([])
        });
    }

    ngOnInit() {
        this.RxNewList = new Array<RxFollowTag>();
        this.openFWTAG();
        if (!this.rxFG) {
            const rxFG = this._pRxFGUtls.createNewRxFG();
            this.rxFG = this._pRxFGUtls.patchRxInfo(this.rxInfo, rxFG);
        }

        if (!this.folwUpList || (this.folwUpList && this.folwUpList.length === 0)) {
            this._erxEcptnServ.getRxFollowUpDropDown()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp["length"]) {
                    this.folwUpList = resp;
                    this.folwUpList = this.folwUpList.filter(val =>val["IsActive"])
                } else {
                    this.folwUpList = [];
                }
            })
        }

        // if (!this.rxFwTgInfo || (this.rxFwTgInfo && this.rxFwTgInfo.length === 0)) {
        //     this.rxFwTgInfo
        // }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    get RxTagId(): FormArray {
        return this.RxFwUpFG.get("RxTagId") as FormArray;
    }

    deleteRxTag(val: any) {
        const dataInfo = this.RxFwUpFG.value;
        dataInfo.RxTagId[val].IsDeleted = true;
        dataInfo.RxTagId[val].Action = "U";
        this.rxFwTgInfo = dataInfo;
        this.patchValue();
    }

    openFWTAG() {
        this.modalRef = this._modalService.open(this.RXFWTG, {
            centered: true,
            keyboard: false
        });
        if (document.getElementById("followUpTagIDpop")) {
            const data: any = document.getElementById("followUpTagIDpop").getElementsByTagName("input")[0];
            data.focus();
        }
    }

    async saveRxTag() {
        const refillVal = this.rxInfo.PrescReFill;
        const rxVal = this.rxInfo.Prescription;
        this.FollowFG.controls["PrescNum"].setValue(rxVal.PrescNum);
        this.FollowFG.controls["PrescRefillId"].setValue(refillVal.Id);
        this.FollowFG.controls["RefillNum"].setValue(refillVal.ReFillNum);
        this.FollowFG.controls["PartialfillNum"].setValue(refillVal.PartialFillNo);
        const rxfolowInfo = this.FollowFG.value;
        if (this.rxFwTgInfo && this.rxFwTgInfo.RxTagId) {
            rxfolowInfo.PrescRefill_RxTags = this.rxFwTgInfo.RxTagId[0];
        }
        const resp = await this._rxService.saveRxFollowUpValues(rxfolowInfo).toPromise();
        if (resp) {
            if (this.rxFG) {
                const oldInfo = Object.assign({}, this.rxFG.getRawValue());
                this.rxFG.controls["PrescRefill_RxTags"].patchValue(rxfolowInfo.PrescRefill_RxTags);
                this._nrxSaveUtils.updateAuditLog(oldInfo, this.rxFG.getRawValue());
            }
            this.closeModal();
        } else {
            this._alertService.error("Rx FollowUp Tag Save unsuccessful.", false, "long");
        }
    }

    saveFrNewRx() {
        const rxfolowInfo = this.FollowFG.value;
        if (this.rxFwTgInfo && this.rxFwTgInfo.RxTagId) {
            rxfolowInfo.PrescRefill_RxTags = this.rxFwTgInfo.RxTagId;
            this.rxFG.controls["PrescRefill_RxTags"].patchValue(rxfolowInfo.PrescRefill_RxTags[0]);
        }
        this.closeModal();
    }

    get PrescRefill_RxTags(): FormGroup {
        return this.rxFG.controls["PrescRefill_RxTags"] as FormGroup;
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.modalRef = null;
        this.IsPopUpClosed.emit(null);
    }

    cancelFollowupTag() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.modalRef = null;
        this.CancelFolwUpTag.emit();
    }

    patchValue() {
        for (const key in this.rxFwTgInfo) {
            if (["RxTagId"].indexOf(key) > -1 && this.rxFwTgInfo[key]) {
                this.generateFA(key, this.rxFwTgInfo[key]);
            }
        }
        this.RxFwUpFG.controls["RxTag"].setValue(this.rxFwTgInfo.RxTag);
    }

    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.RxFwUpFG.setControl(key, extraCostFormArray);
        }
    }

    selectedRxTag(val: any) {
        this.showTag = true;
        const dataInfo = this.rxFwTgInfo;
        this.RxNewList =  (dataInfo && dataInfo.RxTagId && dataInfo.RxTagId.length > 0) ? dataInfo.RxTagId : [];
        if (dataInfo) {
            for (const key in dataInfo.RxTagId) {
                if (val && val.value && (val.value.Name === dataInfo.RxTagId[key].Name)) {
                    if (dataInfo.RxTagId[key].Action === "A") {
                        this.showTag = false;
                    } else {
                        this.showTag = true;
                    }
                } else {
                    this.showTag = true;
                }
            }
        }
        if (this.showTag && val && val.value) {
            const rxArray = new RxFollowTag();
            const refillVal = this.rxInfo.PrescReFill;
            this.rxData = this.RxFwUpFG.value;
            rxArray.Name = val.value.Name;
            rxArray.Action = "A";
            rxArray.Id = val.value.Id;
            rxArray.TenantId = val.value.TenantId;
            rxArray.PrescRefillId = refillVal.Id;
            rxArray.RxTagId = val.value.Id;
            rxArray.IsDeleted = false;
            this.RxNewList[0] = rxArray;
            this.RxFwUpFG.controls["RxTag"].setValue(val.value.Id);
        }
        this.rxFwTgInfo = this.RxFwUpFG.value;
        this.rxFwTgInfo.RxTagId = this.RxNewList;
        this.patchValue();
    }
}
