import { Injectable } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { RxService } from "src/app/services/rx.service";
import "rxjs/add/operator/delay";
import "rxjs/add/operator/toPromise";
import { RxStore } from "src/app/store/rx.store";
import { PatchRXFGUtil } from "src/app/utils/rx-patch-FG.util";
import { CreateNPatchFG } from "src/app/utils/create-Patch-FG.util";
import { RxModel, SystemData, AccCategoryE, PatientFileE, PrescriberFileE,
        DrugFileE, ControlRxProcessingE, EDrug, RxE, RefillRxE, RxControlsModal, RxEntryE, EditRxE, SystemSetting, RxNotesE, RecallDeletedRxE, RxAuditLog, DrugInteractionData, InsuranceFileE, RxTransferE, PharmacistVerificationE, 
        PaginationModel} from "../models";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { InsuranceService } from "src/app/services/insurance.service";
import { PrivMaskGuard } from "../guards";
import { NotesLogService, EditDrugService, DrugService, MorePatientInfoService, UserService, TransmissionService, DrugPickVerificationService, RphVerificationService } from "../services";
import * as moment from "moment";
import { CommonStore, PatientStore } from "../store";
import { BaseUtils } from "./base.utils";
import { AuditLogUtils } from "./audit-log.util";
import { RxUtils } from "./rx.util";
import { DrugBucketUtil } from "./drug-bucket.util";
import * as _ from "lodash";
import { NewAuditUtils } from "./new-audit.util";
import * as Sentry from "@sentry/browser";
import { CommonUtil } from "./common.util";

@Injectable({
    providedIn: "root"
})
export class NRxUtils {
    paginationModel: PaginationModel = new PaginationModel();
    accPrvCat = AccCategoryE;
    patPrivs = PatientFileE;
    presbrPrivs = PrescriberFileE;
    drugPrivs = DrugFileE;
    cntrlRxPrivs = ControlRxProcessingE;
    rxPrivs = RxE;
    refillRxPrivs = RefillRxE;
    rxEntryPrvs = RxEntryE;
    edtRxPrvs = EditRxE;
    rxNotsPrvs = RxNotesE;
    recallNewRxPrivs = RecallDeletedRxE;
    insurancePrivs = InsuranceFileE;
    transferPrivs = RxTransferE;
    searchParams = {
        SearchText: null,
        SearchBy: null,
        OrderByColumn: null,
        SortDirection: null,
        PageNumber: this.paginationModel.pageNumber,
        PageSize: this.paginationModel.pageSize,
        PrescrefillId: null,
        PrescNum: null,
        RxType: null

    };

    constructor(private _rxService: RxService, private _commonServ: CommonService, private _insuSvc: InsuranceService,
        private _rxStore: RxStore, private _pRxFGUtls: PatchRXFGUtil, private _cnpFG: CreateNPatchFG, private _prvMskGrd: PrivMaskGuard,
        private _notesLog: NotesLogService, private _patStore: PatientStore, private edtDrgServ: EditDrugService, private _nwAudtUtl: NewAuditUtils,
        private _drgServ: DrugService, private _morPatInfSvc: MorePatientInfoService, private _baseUtils: BaseUtils,
        private _audtUtls: AuditLogUtils, private _usrServ: UserService, private _trnsmsnServ: TransmissionService, private _commonStore:CommonStore,
        private _drugPickVerf: DrugPickVerificationService, private _rphVerf: RphVerificationService, private _rxUtils: RxUtils,
        private _drugBuckUtil: DrugBucketUtil, private _cmnUtil: CommonUtil) {}

    async updateErxStatus(rxInfo: RxModel) {
        const rxFgVal = rxInfo;
        const dataToPost = {
            ErxId: rxFgVal.eRxDetailsViewModel.ErxId,
            ErxOpTransactionId: rxFgVal.eRxDetailsViewModel.ErxOpTransactionId,
            Id: rxFgVal.eRxDetailsViewModel.ProcessErxId,
            RxNum: rxInfo.Prescription.PrescNum,
            ErxOperationId: rxFgVal.eRxDetailsViewModel.ErxOperationId,
            MaxDailyDose: rxFgVal.eRxDetailsViewModel.MaxDailyDose
        };
        await this._rxService
            .updateErxStatus(dataToPost)
            .toPromise();
    }

    async updateVerfStatus(rxInfo, verfData, rxFG?: any, isFrmFile?: any, isFrmDfr?: boolean) {
        let result: any;
        const pharName = this._usrServ.getToken("PhUserName");
        const techName = this._usrServ.getToken("TechUserName");
        if (verfData && verfData.indexOf("D") !== -1) {
            let datalist = [];
            const data = {BottleNdc: rxInfo["Drug"]["drugcode"], PrescId: rxInfo["Prescription"]["Id"],
            PrescNum: rxInfo["Prescription"]["PrescNum"], PrescRefillId: rxInfo["PrescReFill"]["Id"],
            PartialfillNum: rxInfo["PrescReFill"]["PartialFillNo"], IsForRxVerification: false,
            RefillNum: rxInfo["PrescReFill"]["ReFillNum"], Result: "RV", UserName: techName ? techName : pharName,
            VerifiedById: rxInfo["PrescReFill"]["UpdatedById"], Status: "RV",
            SendReadyForPickupAlerts:  this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
            SendReadyForPickupAlertsWhen: this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When"), VerifiedQty: rxInfo["PrescReFill"]["DispQty"]}
            datalist.push(data);
            result = await this._drugPickVerf.SendDrugReVerifyDetails(datalist).toPromise();
            if (verfData === "D" && isFrmDfr) {
                return result;
            }
        }
        const data = {BottleNdc: rxInfo["Drug"]["drugcode"], PrescId: rxInfo["Prescription"]["Id"],
        PrescNum: rxInfo["Prescription"]["PrescNum"], PrescRefillId: rxInfo["PrescReFill"]["Id"],
        PartialfillNum: rxInfo["PrescReFill"]["PartialFillNo"], IsForRxVerification: false,
            RefillNum: rxInfo["PrescReFill"]["ReFillNum"], Result: "RV", UserName: techName ? techName : pharName,
            VerifiedById: rxInfo["PrescReFill"]["UpdatedById"], Status: "RV",
            SendReadyForPickupAlerts:  this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
            SendReadyForPickupAlertsWhen: this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When")};
        if (verfData && verfData.indexOf("R") !== -1) {
            result = await this._rphVerf.PostRphVerify(data).toPromise();
            const msgList = ["DR", "R"];
            if ((msgList.includes(verfData)) && isFrmDfr) {
                return result;
            }
        }
        if (verfData && verfData.indexOf("X") !== -1) {
            data["IsForRxVerification"] = true;
            result = await this._rphVerf.PostRphVerify(data).toPromise();
            const msgList = ["DXR", "DX", "XR", "X"];
            if (isFrmDfr && (msgList.includes(verfData))) {
                return result;
            }
        }
        if (rxFG) {
            const rxInfoD = await this.getandStoreRxInfo(+rxInfo.Prescription.PrescNum, rxInfo.PrescReFill.Id);
            this._pRxFGUtls.patchVerifications(rxFG, rxInfoD, "both");
        }
    }

    async getandStoreRxInfo(rxNo: number, prescID?: number, isRecall?: boolean, rfNo?: any, refillData?: any, frmActions?: boolean, isFromEditRx?: boolean, allDetailsNotNeeded?: boolean,isRecallDrugLotDeleted?,prfNo?:any) {
        const rxInfo = await this._commonServ.getRxDetailsByPrescNos([rxNo], prescID, isRecall, rfNo, isFromEditRx, allDetailsNotNeeded,isRecallDrugLotDeleted,false,prfNo).toPromise();
        if (rxInfo && rxInfo.length) {
            this._rxStore.storeRxInfo(rxInfo[0]);
            if (frmActions) {
                rxInfo[0].RefillInsu = refillData;
            }
            // commented audit for view edit Rx as it is added from backend
            // if (isFromEditRx) {
            //     this._audtUtls.getChangedValues(
            //         { "View Edit Rx": rxInfo[0].Prescription.UserName },
            //         { "View Edit Rx": _.toNumber(this._usrServ.getToken("UserId")) },
            //         "View Edit Rx",
            //         "Rx",
            //         prescID
            //     );
            // }
            return rxInfo[0];
        } else {
            return null;
        }
    }

    editRxPatchValues(rxInfo: RxModel) {
        if (rxInfo) {
        rxInfo["rxType"] = "er";
        rxInfo["routeFrom"] = "eprx/browserx";
        rxInfo["isPatHisEnbl"] = false;
        rxInfo["patPopCompl"] = "Completed";
        rxInfo["prescPopCompl"] = "Completed";
        rxInfo["drugPopCompl"] = "Completed";
        rxInfo["patCompl"] = "Completed";
        rxInfo["prescCompl"] = "Completed";
        rxInfo["drugCompl"] = "Completed";
        rxInfo["dailyDose"] = null;
        rxInfo["rxActnType"] = null;
        rxInfo["isQuickVerf"] = null;
        rxInfo["isNewRxFile"] = null;
        delete rxInfo.Patient["Diagnosis"];
        return rxInfo;
        }
    }

    async createPatchStoreRxFG(rxInfo?: RxModel, type?: any) {
        let rxFG = this._pRxFGUtls.createNewRxFG();
        if (rxInfo) {
            rxFG = this._pRxFGUtls.patchRxInfo(rxInfo, rxFG);
            this.calculateQtyRem(rxFG);
            if (type) {
                this._rxStore.StoreRxFG(rxFG);
            } else {
                this._rxStore.StoreEditRxFG(rxFG);
            }
        } else {
            await this.getNEwPrescNum(rxFG);
            rxFG.controls["rxType"].setValue("nr");
           // rxFG.controls["routeFrom"].setValue("eprx/dashboard");
            this._rxStore.StoreRxFG(rxFG);
        }
        return rxFG;
    }

    calculateQtyRem(rxFG) {
        let QtyRem = 0;
        const PRESCREFILL = rxFG.controls["PrescReFill"];
        rxFG.controls["PrescReFill"].patchValue({
            QtyRemRef: rxFG.value["rxType"] === "er" && parseFloat(
                rxFG.value["partialCummulativeDetails"] ? rxFG.value["partialCummulativeDetails"]["TotRemQty"] : 0) ?
                (parseFloat(rxFG.value["partialCummulativeDetails"]["TotRemQty"])).toFixed(3) : "0.000",
            erQtyRem: parseFloat(rxFG.value.PrescReFill.DispQty) +
           (rxFG.value["partialCummulativeDetails"] ? parseFloat(rxFG.value["partialCummulativeDetails"]["TotRemQty"]) : 0)
        });
        if (rxFG.value["PrescReFill"]["PartialFillNo"] === 1 && rxFG.value["rxType"] !== "er") {
            QtyRem = (+PRESCREFILL.value["OrdQty"] ? parseFloat(PRESCREFILL.value["OrdQty"]) : 0) -
            (+PRESCREFILL.value["DispQty"] ? parseFloat(PRESCREFILL.value["DispQty"]) : 0);
        } else {
            QtyRem = PRESCREFILL.value["erQtyRem"] -
            (+PRESCREFILL.value["DispQty"] ? parseFloat(PRESCREFILL.value["DispQty"]) : 0);
        }
        rxFG.controls["PrescReFill"].patchValue({
            QtyRemRef: +QtyRem ? (QtyRem).toFixed(3) : "0.000"
        });
    }

    async getNEwPrescNum(rxFG) {
        const resp = await this._rxService.getPrescriptionId(0).toPromise();
        this.setPrescNoNdNotManulaRx(+resp, rxFG);
    }

    async getCommonEndPoints(rxInfo: any,rxType?) {
        // console.log("rxInfo:",rxInfo,rxInfo.Drug.id, rxInfo.Patient.patientid,
        // rxInfo.Patient.gender,rxInfo.Drug.drugclass,rxInfo.Drug.therapeuticcode,this.isSafeForLabrDelivrySet(),this.isChildBearingAgeSet(),this.getSetngKeyVal("PatientSettings", "USE_ACB_SCORING"));
        // this.drugIntData.drugId = rxInfo.Drug.id;
        // this.drugIntData.patientId = rxInfo.Patient.patientid;
        const dob = moment(rxInfo?.Patient?.dob ? rxInfo.Patient.dob : null);
        const days = moment().diff(dob, "days");
        const drugIntData: DrugInteractionData = {
            drugId : rxInfo && rxInfo.Drug && rxInfo.Drug.id ? rxInfo.Drug.id : null,
            patientId: rxInfo.Patient.patientid,
            gender: rxInfo.Patient.gender,
            islong: true,
            languageCulture: "es-US",
            calledFrom: "RxEntry",
            patLanguage: rxInfo.Patient.langname,
            isFromEditRx:  rxInfo.rxType == "er" ? true : false,
            isForCount: true,
            ageInDays: days,
            therapeuticCode: rxInfo.Drug.therapeuticcode,
            drugClass: rxInfo.Drug.drugclass,
            CHECK_DRU_AND_DELIVERY: this.isSafeForLabrDelivrySet(),
            CHECK_DRU_BEARING_AGE: this.isChildBearingAgeSet(),
            USE_ACB_SCORING: this.getSetngKeyVal("PatientSettings", "USE_ACB_SCORING"),
            drugNdc: rxInfo.Drug.drugcode,
            diagnosisList: rxInfo.Patient.Diagnosis,
            ddiScanDays: this.getSettingValue("PatientSettings", "DDI_SCAN_DAYS"),
            INCIDENCE: this.getSettingValue("PatientSettings", "INCIDENCE"),
            ON_SET: this.getSettingValue("PatientSettings", "ON_SET"),
            SEVERITY_LEVEL: this.getSettingValue("PatientSettings", "SEVERITY_LEVEL"),
            USE_ADV_DRUG_REACTION: this.getSettingValue("PatientSettings", "USE_ADV_DRUG_REACTION"),
            DRUGS_WIT_WARN_ONLY: this.getSettingValue("PatientSettings", "DRUGS_WIT_WARN_ONLY")
        }

        if (rxInfo && rxInfo.Patient && rxInfo.Patient.patientid && rxInfo.Drug && rxInfo.Drug.id) {
            const endpoints  = (rxType == "rf") ?  
            [
                this.getInsuranceSettings(rxInfo), // 0
                this._drgServ.getDrugInteractions(drugIntData)
            ] : 
            [
                        this.getInsuranceSettings(rxInfo), // 0
                        this._drgServ.getDrugInteractions(drugIntData), // 1
                        this.getPayRecords(rxInfo) 
            ]
            let  resp = await forkJoin(endpoints).toPromise();
            if (resp) {
                // this._rxStore.storeMarDetails(resp[0]);
                this._rxStore.storeDrugIntrctns(resp[1]);
                if(rxInfo.rxType !== "rf")
                      this._trnsmsnServ.storePayRecords(resp[2]);
            }
        }
    }

    async getInsuranceSettings(rxInfo: RxModel) {
        if( rxInfo && rxInfo.Patient && rxInfo.Patient.insurance && rxInfo.Patient.insurance.length > 0){
            const insu = rxInfo.Patient.insurance.find(x => (+x.insuranceid) === (+rxInfo.RefillInsu.InsuId));
            if (insu && insu.insuplanId) {
                return await this.getInsuSettbyPlanId(insu.insuplanId);
            }
        }

    }

    async getInsuSettbyPlanId(insPlnID: number) {
        const data = await this._insuSvc.getInsSettingsInfo(insPlnID).toPromise();
        this._insuSvc._insuSettings$.next(data);
        return data;
    }

    // getMarDetails(patID: number) {
    //     return this._rxService.getMarDetails(patID);
    // }

    getPayRecords(rxInfo: RxModel) {
        return this._trnsmsnServ.getPayRecord(rxInfo.PrescReFill.Id);
    }

    getPatientOverRidePriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.PatientFile, this.patPrivs.Edit);
    }

    getPrscrbrOverRidePriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.PrescriberFile, this.presbrPrivs.Edit);
    }
    getInsOverRidePriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.InsuranceFile, this.insurancePrivs.Edit);
    }

    getNDCChangeOverRidePriv() {
        return this._prvMskGrd.canActivate(AccCategoryE.PharmacistVerification, PharmacistVerificationE.AllowRphVerificationIfDrugChange);
    }

    getAlwCntrlRxProc(type) {
        let result = true;
        if (type === "rf") {
            result = this.getAlwRfCntrlRxProc();
        } else {
            result = this._prvMskGrd.canActivate(this.accPrvCat.ControlRxProcessing, this.cntrlRxPrivs.AllowControlRxProcessing);
        }
        return result;
    }

    checkCntrlProcPriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.ControlRxProcessing, this.cntrlRxPrivs.AllowControlRxProcessing);
    }

    getAlwElctrCntrlRxProc() {
        return this._prvMskGrd.canActivate(this.accPrvCat.ControlRxProcessing, this.cntrlRxPrivs.AllowElectronicControlRxProcessing);
    }

    getAlwRfCntrlRxProc() {
        return this._prvMskGrd.canActivate(this.accPrvCat.ControlRxProcessing, this.cntrlRxPrivs.AllowControlRxProcessing) &&
             this._prvMskGrd.canActivate(this.accPrvCat.RefillRx, RefillRxE["AllowControlRxProcessing"]);
    }

    getChangAuthRefPriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RefillRx, this.refillRxPrivs.ChangeAuthorizedRefills);
    }

    hasDrgAlgryOverRdPriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.Rx, this.rxPrivs.OverridePatientDrugAllergyWarning);
    }

    hasPriceChangePriv(type) {
        const rxPrv = this._prvMskGrd.canActivate(this.accPrvCat.RxEntry, this.rxEntryPrvs.ChangePrice);
        const erxPv = this._prvMskGrd.canActivate(this.accPrvCat.EditRx, this.edtRxPrvs.ChangePrice);
        return ((type === "nr" && !rxPrv) || (type === "er" && !erxPv));
    }

    hasDiscChangePriv(type) {
        const erxPv = this._prvMskGrd.canActivate(this.accPrvCat.EditRx, this.edtRxPrvs.OrderStatus);
        return (type === "er" && !erxPv);
    }

    hasChngRxStatsBildUnBilPrv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.EditRx, this.edtRxPrvs.ChangeRxStatusBilledUnBilled);
    }

    hasEditRxTransmissnPrv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.EditRx, this.edtRxPrvs.Transmit);
    }

    hasEditRxRevTrnsmsnPrv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.EditRx, this.edtRxPrvs.ReverseTransmission);
    }

    hasEditRxPrintLabelPriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.EditRx, this.edtRxPrvs.PrintLabelForUnbilledRx);
    }

    hasDrugChangePrv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.DrugFile, this.drugPrivs.Edit);
    }

    // hasDrugChangePrvInRef() {
    //     return this._prvMskGrd.canActivate(this.accPrvCat.Rx, this.rxPrivs.AllowDrugChangeInRefillAndEdit);
    // }

    hasDrugChangePrvForEditRx() {
        return this._prvMskGrd.canActivate(this.accPrvCat.EditRx, this.edtRxPrvs.AllowDrugChangeOnEditRx);
    }

    hasDrgChngPrivForRflRx() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RefillRx, this.refillRxPrivs.AllowDrugChangeOnRefill);
    }

    hasDrgChngPrivForeRx() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RxEntry, this.rxEntryPrvs.AllowDrugChangeoneRxToNewRx);
    }

    
    hasDrgChngPrivForRecallNewRx() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RecallDeletedRx, this.recallNewRxPrivs.AllowDrugChangeOnRecallDeletedRx);
    }

    hasReflRxTransmissnPrv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RefillRx, this.refillRxPrivs.Transmit);
    }

    hasEntryRxTransmissnPrv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RxEntry, this.rxEntryPrvs.Transmit);
    }

    hasNotesChangPrv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RxNotes, this.rxNotsPrvs.Edit);
    }

    hasTransferInPriv() {
        return this._prvMskGrd.canActivate(this.accPrvCat.RxTransfer, this.transferPrivs.AllowRxTransferIn)
    }

    getSetngKeyVal(type: string, defKey: string) {
        return this._commonServ.getSetttingValue(type, defKey) === "1";
    }

    getSettingValue(type: string, defKey: string) {
        const val = this._commonServ.getSetttingValue(type, defKey);
        return val ? val : null;
    }

    authRequiFrDrugChange() {
        return this._commonServ.getSetttingValue("RxSettings", "AUTH_REQ_DRUG_CHANGED") === "1";
    }

    hasDrgAlgryOverRdSetg() {
        return this._commonServ.getSetttingValue("PatientSettings", "ALLERGY_OVERRIDE_REQ") === "1";
    }

    is340BPharmcy() {
        return this._commonServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
    }

    isSafeForLabrDelivrySet() {
        return this._commonServ.getSetttingValue("PatientSettings", "CHECK_DRU_AND_DELIVERY") === "1";
    }

    isChildBearingAgeSet() {
        return this._commonServ.getSetttingValue("PatientSettings", "CHECK_DRU_BEARING_AGE") === "1";
    }

    warnFrBlnkUnitCostSet() {
        return this._commonServ.getSetttingValue("RxSettings", "WARN_BLANK_UNIT_COST") === "1";
    }

    lactationRating() {
        return this._commonServ.getSetttingValue("PatientSettings", "LACTATION_RATING");
    }

    pregnencyRating() {
        return this._commonServ.getSetttingValue("PatientSettings", "PREGNANCY_RATING");
    }

    async getNotesLog(type, Id, rxObject?) {
        const date = moment(Date.now()).format("YYYY-MM-DD");
        if(rxObject?.PrescReFill) {
            this.searchParams['PrescrefillId'] = rxObject.PrescReFill;
            this.searchParams['PrescNum'] = rxObject.PrescNum;
        } 
        if(rxObject?.RxType)
        this.searchParams['RxType'] = rxObject.RxType;
        const resp = await this._notesLog.getNotesLog(type, Id, "1973-01-01", date, this.searchParams).toPromise();
        this._patStore.storeNotesLog(resp);
        return resp;
    }

    getSortedInusuDtls(insuList) {
        let insureList = [];
        let patInsList: any;
        if (insuList) {
        patInsList = insuList.filter(ins => ins.insuPriId);
        patInsList = patInsList.sort((a: any, b: any) =>
            a.insuPriId > b.insuPriId ? 1 : b.insuPriId > a.insuPriId ? -1 : 0);
        const patNoInsPriList = insuList.filter((ins: any) => !ins.insuPriId);
        patNoInsPriList.map((ins: any) => patInsList.push(ins));

        //For removing inactive insurances
        patInsList = patInsList.filter(x => {
           return (x.ActiveStatus ? x.ActiveStatus === 1 : x);
        });
        }

        if (patInsList) {
            insureList = patInsList;
        }
        return insureList;
    }

    // This is for bill to popup... here refillInsu wont be there.. so patient has to be use to match
    checkDefaultInsuranceInfo(patientData, insureList, systemData) {
        const insuCode = insureList.filter(x => x.insuranceid === +patientData.insuranceid);
        const primaryInsu = insureList.filter(x => x.insuranceid === +patientData.insuranceid)[0];

        const primaryCash = insureList.filter(x =>
            x.insurerid === +systemData["DefaultValuese"][0]["CashInsurerId"])[0];

        if (insuCode.length === 0) {
            if (primaryInsu) {
                patientData.insuranceid = primaryInsu.insuranceid;
            } else if (primaryCash)  {
                patientData.insuranceid = primaryCash.insuranceid;
            } else if (insureList && insureList.length > 0) {
                patientData.insuranceid = insureList[0]["insuranceid"];
            }
        }
        return patientData;
    }

    getDefaultInsuranceInfo(patientData, insureList, systemData) {
        const insuCode = insureList.filter(x => x.insuranceid === +patientData.insuranceid);
        const primaryCash = insureList.filter(x => x.insurerid === +systemData["DefaultValuese"][0]["CashInsurerId"]);
        return (insuCode && insuCode.length) ? insuCode[0] : (primaryCash && primaryCash.length) ? primaryCash : null;
    }

    resetRxFieldsOnDrugReset(rxFG: FormGroup) {
        const PrescRefillFG: any = rxFG.controls["PrescReFill"];
        const PrescFG: any = rxFG.controls["Prescription"];
        if (rxFG.value["rxType"] !== "er") {
            rxFG.controls["OtherAmount"].patchValue("0.000");
            rxFG.controls["TotalRefillsQty"].patchValue("0.000");
            rxFG.controls["TotalRemainingQty"].patchValue("0.000");
            PrescFG.controls["ReFillNum"].setValue(0);
            PrescRefillFG.controls["DispQty"].setValue("0.000");
            PrescRefillFG.controls["QtyRemRef"].setValue("0.00");
            PrescRefillFG.controls["OrdQty"].setValue("0.000");
            PrescRefillFG.controls["Price"].setValue("0.00");
            PrescRefillFG.controls["BalDue"].setValue("0.00");
            PrescRefillFG.controls["ProfFee"].setValue("0.00");
            PrescRefillFG.controls["TotalAmt"].setValue("0.00");
            PrescRefillFG.controls["UnitPriceAWP"].setValue("0.00");
            PrescRefillFG.controls["TotalRxCost"].setValue("0.00");
            PrescRefillFG.controls["UnitPriceUNC"].setValue("0.00");
            PrescRefillFG.controls["PriceSchId"].setValue(null);
            PrescRefillFG.controls["SupplyDays"].setValue(0);
            PrescRefillFG.controls["OrderDays"].setValue(0);
            const sigCodes = rxFG.controls["SigCodes"] as FormGroup;
            sigCodes.controls["SigCode"].setValue(null);
            sigCodes.controls["SigCode"].markAsUntouched();
            setTimeout(() => {
                PrescRefillFG.controls["TechInitials"].markAsUntouched();
            }, 50);
            PrescRefillFG.controls["TechInitials"].setErrors(null);
            PrescRefillFG.controls["TechInitials"].setValidators([Validators.nullValidator]);
            PrescRefillFG.controls["TechInitials"].reset();
            PrescRefillFG.controls["SupplyDays"].setErrors(null);
            PrescRefillFG.controls["OrderDays"].setErrors(null);
            PrescRefillFG.controls["SupplyDays"].markAsUntouched();
            PrescRefillFG.controls["OrderDays"].markAsUntouched();
        }
        PrescRefillFG.controls["LotNum"].setValue(null);
        PrescRefillFG.controls["LotNum"].setErrors(null);
        PrescRefillFG.controls["LotNum"].markAsUntouched();
        PrescRefillFG.controls["LotExpiryDate"].setValue(null);
        this._commonStore.storeDrugLotDataForBarCode(null);
        PrescRefillFG.controls["LotExpiryDate"].setErrors(null);
        PrescRefillFG.controls["LotExpiryDate"].markAsUntouched();
        if (!rxFG.value["Is340NotByDrug"]) {
            PrescRefillFG.controls["Is340B"].setValue(false);
        }
        this.updateSigValidity(rxFG);
    }
    updateSigValidity(rxFG) {
        const sigCodes: any = rxFG.controls.SigCodes;
        if(sigCodes) {
            sigCodes.controls["SigCode"].setValue(null);
            sigCodes.controls["Description"].setValue(null);
            sigCodes.controls["AlternateLangDescription"].setValue(null);
            sigCodes.controls["SigCode"].setValidators([Validators.required]);
        }
    }
    isFromErx(rx) {
        if (rx && rx.eRxDetailsViewModel && (rx.eRxDetailsViewModel.ProcessErxId || rx.eRxDetailsViewModel.ErxId)) {
            return true;
        } else {
            return false;
        }
    }

    isShowErx(rx, rxType?) {
        if ((rx && rx.erxDetailsInRx && (rx.erxDetailsInRx.erxOperationId || rx.erxDetailsInRx.erxId) && rxType === "er") ||
        ((rx && rx.eRxDetailsViewModel  && (rx.eRxDetailsViewModel.ProcessErxId || rx.eRxDetailsViewModel.ErxId)))) {
            const displayErxDetails = +this._commonServ.getSetttingValue("ERxSettings", "SHOW_ERX_FORM_IN_RX_ENTRY");
            if (displayErxDetails === 1 || rx.rxType !== "nr") {
                return true;
            }
        }
        return false;
    }

    isAnnotationForRx(rxFG) {
        if (rxFG['rxType'] =='er' &&  (rxFG['Drug'].drugclass === 2 &&  this._commonServ.checkIsPartialFill
        (rxFG['PrescReFill'], rxFG['Prescription'])
        ? rxFG['PrescReFill'].PartialFillNo : '')) {
                return true;
            }
        return false;

    }

    isShowErxDocument(rx, rxType?) {
        if ((rx && rx.erxDetailsInRx && (rx.erxDetailsInRx.erxOperationId || rx.erxDetailsInRx.erxId)
         && (rxType === "nr" || rxType === "er")) ||
        ((rx && rx.eRxDetailsViewModel  && (rx.eRxDetailsViewModel.ProcessErxId || rx.eRxDetailsViewModel.ErxId)))) {
                return true;
        }
        return false;
    }

    async getAndStoreEditDrugInfo(drugInfo) {
        if (drugInfo && drugInfo.id) {
            const resp = await this.edtDrgServ.getDrugInfo(drugInfo.id).toPromise();
            this._rxStore.storeEditDrugInfo(resp);
        }
    }

    async getDrugInfoAfterSelected(type: string, drugInfo: EDrug, rxFG?: FormGroup, isErx?: any) {
        const rxInfo = rxFG.getRawValue();
        const dob = moment(rxInfo?.Patient?.dob ? rxInfo.Patient.dob : null);
        const days = moment().diff(dob, "days");
        const drugIntData: DrugInteractionData = {
            drugId : drugInfo.id,
            patientId: rxInfo.Patient.patientid,
            gender: rxInfo.Patient.gender,
            islong: true,
            languageCulture: "es-US",
            calledFrom: "RxEntry",
            patLanguage: rxInfo.Patient.langname,
            isFromEditRx: false,
            isForCount: true,
            ageInDays: days,
            therapeuticCode: rxInfo.Drug.therapeuticcode,
            drugClass: rxInfo.Drug.drugclass,
            CHECK_DRU_AND_DELIVERY: this.isSafeForLabrDelivrySet(),
            CHECK_DRU_BEARING_AGE: this.isChildBearingAgeSet(),
            USE_ACB_SCORING: this.getSetngKeyVal("PatientSettings", "USE_ACB_SCORING"),
            drugNdc: rxInfo.Drug.drugcode,
            diagnosisList: rxInfo.Patient.Diagnosis,
            ddiScanDays: this.getSettingValue("PatientSettings", "DDI_SCAN_DAYS"),
            INCIDENCE: this.getSettingValue("PatientSettings", "INCIDENCE"),
            ON_SET: this.getSettingValue("PatientSettings", "ON_SET"),
            SEVERITY_LEVEL: this.getSettingValue("PatientSettings", "SEVERITY_LEVEL"),
            USE_ADV_DRUG_REACTION: this.getSettingValue("PatientSettings", "USE_ADV_DRUG_REACTION"),
            DRUGS_WIT_WARN_ONLY: this.getSettingValue("PatientSettings", "DRUGS_WIT_WARN_ONLY")
        }
        const serviceStack = [];
        serviceStack.push(this._rxService.getPrescNoByDrugClass(drugInfo.drugclass)); //0
        serviceStack.push(this._rxService.getRxDefaultValues(drugInfo.id)); //1
        serviceStack.push(this.edtDrgServ.getDrugInfo(drugInfo.id)); //2
        serviceStack.push(this._drgServ.getDrugInteractions(drugIntData)); //3

        if (rxFG.get("drugPopCompl").value !== "Completed") {
            serviceStack.push(this.edtDrgServ.getInsuranceRestrictionWithoutBS(drugInfo.id, 1, 10)); //4
            serviceStack.push(this.getNotesLog("Drug", drugInfo.id)); //5
            serviceStack.push(this._rxService.checkSimilarRxs(rxInfo.Patient.patientid,
                rxInfo.Prescriber.prescriberid, rxInfo.Drug.id)); //6
            if(!isErx)
                 serviceStack.push(this._morPatInfSvc.getMorePatientInfo(rxInfo.Patient.patientid)); //7

            if (rxInfo && rxInfo.RefillInsu && rxInfo.RefillInsu.InsuId) {
                serviceStack.push(this.edtDrgServ.getDrugInsurancePopups(rxInfo.RefillInsu.InsuId)); //8
            }
            if (drugInfo.drugclass >= 2 && drugInfo.drugclass <= 5) {
                serviceStack.push(this._rxService.getDemoGraphDetails(rxInfo.Patient.patientid)); //9
            }
            serviceStack.push(this._rxService.getACBScore(rxInfo.Patient.patientid, drugInfo.id, drugInfo.therapeuticcode));
        }

        const resp:any = await forkJoin(serviceStack).toPromise();
        if (!(type === "rf"|| type === "er")) {
            if(resp) {
                this.setPrescNoNdNotManulaRx(resp[0].nextPrescNum, rxFG);
            }
        }
        if(type === "er"){
            if(resp){
                this._commonServ.storePrescNum(resp[0].nextPrescNum);
            }
        }

        if (!(type === "rf" || type === "er" || type === "nrf" || type === "rnr" || isErx || rxFG.value["isNewFrmExisting"]) && !rxFG.value.frmInprog) {
            if (resp) {
                this.setDefaultValByDrugPresc(resp[1], rxFG);
            }
        }
        if (resp) {
            this._rxStore.storeEditDrugInfo(resp[2]);
            this._rxStore.storeDrugIntrctns(resp[3]);
        }

        if (resp && rxFG.get("drugPopCompl").value !== "Completed") {
            this._rxStore.storeDrugInsuRstrctn(resp[4]);
            // NOTE: response 5 is for notes info

            this._rxStore.storeSimilarFiledRxs(resp[6]);
            if(!isErx)
                 this._rxStore.storeMorePatientInfo(resp[7]);
            if (rxInfo && rxInfo.RefillInsu && rxInfo.RefillInsu.InsuId) {
                this._rxStore.storeDrugInsuPopData(isErx ? resp[7] : resp[8]);
                if (drugInfo.drugclass >= 2 && drugInfo.drugclass <= 5) {
                    this._rxStore.storePatntDemgphData(isErx ? resp[8] : resp[9]);
                    this._rxStore.storeACBScore(isErx ? resp[9] : resp[10]);
                } else {
                    this._rxStore.storeACBScore(isErx ? resp[8] : resp[9]);
                }
            } else if (drugInfo.drugclass >= 2 && drugInfo.drugclass <= 5) {
                this._rxStore.storePatntDemgphData(isErx ? resp[7] : resp[8]);
                this._rxStore.storeACBScore(isErx ? resp[8] : resp[9]);
            }

            if (resp && resp[2] && resp[2].Drug) {
                rxFG.controls["Drug"].patchValue({
                    DispUnitFormId: resp[2].Drug.DispUnitFormId
                });

            }
            this._cmnUtil.storedefaultDrugInsPref(rxFG);
        }
    }    

    setPrescNoNdNotManulaRx(prescNumber, rxFG) {
        const prescFG = rxFG.controls.Prescription as FormGroup;
        const prescReFill = rxFG.controls["PrescReFill"] as FormGroup;
        prescFG.controls["PrescNum"].setValue(prescNumber);
        prescReFill.controls["PrescNum"].setValue(prescNumber);
        if (prescFG.controls["IsManualRx"]) {
            prescFG.controls["IsManualRx"].setValue(false);
        }
    }

    setDefaultValByDrugPresc(resp, rxFG: FormGroup) {
        this._rxStore.storeDrugSpecDefVal(resp);
        if (resp && resp.length > 0) {
            const prescReFill: any = rxFG.controls.PrescReFill;
            const sigCodes: any = rxFG.controls.SigCodes;
            const refillInsu: any = rxFG.controls.RefillInsu;
            const prescrip: any = rxFG.controls.Prescription;

            sigCodes.controls.SigCode.setValue(resp[0].Sig);
            sigCodes.controls.Description.setValue(resp[0].SigDesc);
            sigCodes.controls["AlternateLangDescription"].setValue(
                null
            );
            if (rxFG.value["scldQty"] && parseFloat(rxFG.value["scldQty"])) {
                prescReFill.controls.DispQty.patchValue(
                    (parseFloat(rxFG.value["scldQty"])).toFixed(3)
                );
                prescReFill.controls.OrdQty.patchValue(
                    (parseFloat(rxFG.value["scldQty"])).toFixed(3)
                );
                rxFG.controls["scldQty"].patchValue(0);
            } else {
                if (resp[0].Qty) {
                    prescReFill.controls.DispQty.patchValue(
                        (+resp[0].Qty).toFixed(3)
                    );
                    prescReFill.controls.OrdQty.patchValue(
                        (+resp[0].Qty).toFixed(3)
                    );
                } else {
                    prescReFill.controls.DispQty.patchValue("0.000");
                    prescReFill.controls.OrdQty.patchValue("0.000");
                }
            }

            if (+resp[0].OrderDays) {
                prescReFill.controls.SupplyDays.setValue(
                    +resp[0].OrderDays
                );
                prescReFill.controls.OrderDays.setValue(
                    +resp[0].OrderDays
                );
            } else if (
                +resp[0].DailyDose &&
                +prescReFill.value.DispQty
            ) {
                const dailyDose = +resp[0].DailyDose
                    ? +resp[0].DailyDose
                    : null;
                const supplydays = Math.round(
                    prescReFill.value.DispQty / dailyDose
                );
                prescReFill.controls["SupplyDays"].patchValue(
                    supplydays
                );
                prescReFill.controls["OrderDays"].patchValue(
                    supplydays
                );
            }
        }
    }

    focusOnInputField(rxFG) {
        const htmlElmId = (rxFG.value['rxType'] === "er" || (rxFG.value['IsElectornicRx'] && rxFG.valid)) ? "actionInputer" : "actionInput";
        setTimeout(() => {
            const htmlElm = document.getElementById(htmlElmId);
            if (htmlElm) {
                setTimeout(() => {
                    if (document.getElementById(htmlElmId)) {
                        document.getElementById(htmlElmId).focus();
                    }
                }, 0);
            }
        }, 0)
    }

    rxInfoKeytab(event: any, fg?: any, fc?: string, rxFG?:FormGroup) {
        if (rxFG && rxFG.valid && rxFG.value['IsElectornicRx']) {
            this.focusOnInputField(rxFG);
        } else {
            sessionStorage.setItem("ActiveId", (event.target && event.target.id) ? event.target.id : null);
            let isFcValid = true;
            if (fg && fg.controls[fc]) {
                if (["DispQty", "OrdQty"].indexOf(fc) > -1) {
                    if (+fg.value[fc] !== 0) {
                        isFcValid = fg.controls[fc].valid;
                    } else {
                        isFcValid = false;
                    }
                } else {
                    isFcValid = fg.controls[fc].valid;
                }
            }
            this._baseUtils.keytab(event, isFcValid);
        }
     
    }

    rxInfoUndokeytab(event: any, fg?: any, fc?: string) {
        sessionStorage.setItem("ActiveId", event.target.id);
        let isFcValid = true;
        if (fg && fg.controls[fc]) {
            if (["DispQty", "OrdQty"].indexOf(fc) > -1) {
                if (+fg.value[fc] !== 0) {
                    isFcValid = fg.controls[fc].valid;
                } else {
                    isFcValid = false;
                }
            } else {
                isFcValid = fg.controls[fc].valid;
            }
        }
        this._baseUtils.Undokeytab(event, isFcValid);
    }

    rxBilngkeytab(event: any, fg?: any, fc?: string, rxFG?: FormGroup) {
        if (rxFG && rxFG.valid && rxFG.value['IsElectornicRx']) {
            this.focusOnInputField(rxFG);
        }
        else {
            let isFcValid = true;
            if (fg && fg.controls[fc]) {
                if (["SupplyDays", "OrderDays"].indexOf(fc) > -1) {
                    if (+fg.value[fc] !== 0) {
                        isFcValid = fg.controls[fc].valid;
                    } else {
                        isFcValid = false;
                    }
                } else {
                    isFcValid = fg.controls[fc].valid;
                }
            }
            this._baseUtils.keytab(event, isFcValid, fc);
        }
    }
    rxBilngUndokeytab(event: any, fg?: any, fc?: string) {
        sessionStorage.setItem("ActiveId", event.target.id);
        let isFcValid = true;
        if (fg && fg.controls[fc]) {
            if (["DispQty", "OrdQty"].indexOf(fc) > -1) {
                if (+fg.value[fc] !== 0) {
                    isFcValid = fg.controls[fc].valid;
                } else {
                    isFcValid = false;
                }
            } else {
                isFcValid = fg.controls[fc].valid;
            }
        }
        this._baseUtils.Undokeytab(event, isFcValid);
    }

    checkAuthRefills(rxFG, cntrlRefllOptns, insuSetngs) {
        let authrif = [];
        const rxInfo = rxFG.getRawValue();
        if (+rxInfo.Drug.drugclass >= 2 && +rxInfo.Drug.drugclass <= 5) {
             authrif = [];
            if (cntrlRefllOptns && cntrlRefllOptns.length > 0) {
                const cntrlStng = cntrlRefllOptns.find(val => Number(val.DrugClass) === +rxInfo.Drug.drugclass);
                if (cntrlStng) {
                    for (let i = 0; i <= cntrlStng["MaxRefillAllow"]; i++) {
                        authrif.push({ Id: i, Name: i.toString() });
                    }
                } else {
                    authrif = this.getMaxRefills();
                }
            } else {
                authrif = this.getMaxRefills();
            }
        } else {
            if (
                insuSetngs &&
                insuSetngs.InsuPlanOther &&
                insuSetngs.InsuPlanOther.MaxRefills !== null
            ) {
                for (
                    let i = 0;
                    i <= insuSetngs.InsuPlanOther["MaxRefills"];
                    i++
                ) {
                    authrif.push({ Id: i, Name: i.toString() });
                }
            } else {
                authrif = this.getMaxRefills();
            }
        }

        this._rxStore.storeAuthRefills(authrif);
    }

    getMaxRefills() {
        const authrif = [];
        for (let i = 0; i <= 99; i++) {
            authrif.push({ Id: i, Name: i.toString() });
        }
        return authrif;
    }

    async checkHasPayyerSet(insuList) {
        if (insuList && insuList.insurerid) {
            return await this._rxService.getBInInsuPayerSetCheck(insuList.insurerid).toPromise();
        }
    }

    async updateRxInfo(rxInfo) {
        return await this._rxService.updateRxInfo(rxInfo).toPromise();
    }

    async changeBillTypeBilled(rxInfo, rxPay: any) {
        return await this._rxService.updateRxStatus(rxInfo.PrescReFill.RxBillId,
                (rxPay && rxPay.RecType === "P") ? 2 : 1, rxInfo.PrescReFill.Id).toPromise();
    }

    async changeBillTypeUnbilled(rxInfo) {
        return await this._rxService.updateRxStatus(
            rxInfo.PrescReFill.RxBillId, 1, rxInfo.PrescReFill.Id).toPromise();
    }

    getPriceCodeList() {
        let priceSchdlList = [];
        const resp = this._commonServ.priceSchedule$["source"]["value"];
        if (resp && resp.PriceScheduleList && resp.PriceScheduleList.length > 0) {
            priceSchdlList = resp.PriceScheduleList;
        }
        return priceSchdlList;
    }

    getDisctCodeList() {
        let discntCodeList = [];
        const resp = this._commonServ.discountSchedule$["source"]["value"];
        if (resp && resp.length > 0) {
            discntCodeList = resp;
        }
        return discntCodeList;
    }

    isNeedToReSubmitForCntrlFile(oldRxInfo, rxInfo, frmPatPrescFile?: boolean, editedRxPrescReFillId?: any) {
        const resubData = {
            PatientId: null,
            PrescriberId: null,
            DrugId: null,
            PrescRefillId: null,
            StateName: null
        };
        if (oldRxInfo && rxInfo) {
            let isNeedToReSubmit = false;
            const fieldsToResubmit = [];
            const isRxValid = true;
            if (!frmPatPrescFile) {
            [
                "lastname",
                "firstname",
                "address1",
                "city",
                "state",
                "zipcode",
                "dob",
                "gender"
            ].map(prop => {
                if ((oldRxInfo && rxInfo && oldRxInfo.Patient && rxInfo.Patient) && (oldRxInfo.Patient[prop] != rxInfo.Patient[prop])) {
                    isNeedToReSubmit = true;
                    if (prop === "lastname") {
                        fieldsToResubmit.push("Patient's Last Name");
                    } else if (prop === "firstname") {
                        fieldsToResubmit.push("Patient's First Name");
                    } else if (prop === "zipcode") {
                        fieldsToResubmit.push("Patient's Zip Code");
                    } else if (prop === "dob") {
                        fieldsToResubmit.push("Patient's DOB");
                    } else if (prop === "gender") {
                        fieldsToResubmit.push("Patient's Gender");
                    } else if (prop === "address1") {
                        fieldsToResubmit.push("Patient's Address 1");
                    } else if (prop === "city") {
                        fieldsToResubmit.push("Patient's City");
                    } else if (prop === "state") {
                        fieldsToResubmit.push("Patient's State");
                    } else {
                        fieldsToResubmit.push(prop);
                    }
                }
                if (isNeedToReSubmit) {
                    resubData.PatientId = rxInfo.Prescription.PatientId;
                    if (prop === "state" && (oldRxInfo && rxInfo && oldRxInfo.Patient && rxInfo.Patient && oldRxInfo.Patient[prop] != rxInfo.Patient[prop])) {
                        resubData.StateName = rxInfo.Patient.state;
                    }
                }
            });
            } else {
                [
                    "LastName",
                    "FirstName",
                    "AddressLine1",
                    "CityId",
                    "StateId",
                    "ZipCode",
                    "BirthDt",
                    "GenderId"
                ].map(prop => {
                    if ((oldRxInfo && rxInfo && oldRxInfo.Patient && rxInfo.Patient)
                    && (oldRxInfo.Person[prop] != rxInfo.Person[prop] || oldRxInfo.Addresses[prop] != rxInfo.Addresses[prop])) {
                        isNeedToReSubmit = true;
                        if (prop === "LastName") {
                            fieldsToResubmit.push("Patient's Last Name");
                        } else if (prop === "FirstName") {
                            fieldsToResubmit.push("Patient's First Name");
                        } else if (prop === "ZipCode") {
                            fieldsToResubmit.push("Patient's Zip Code");
                        } else if (prop === "BirthDt") {
                            fieldsToResubmit.push("Patient's DOB");
                        } else if (prop === "GenderId") {
                            fieldsToResubmit.push("Patient's Gender");
                        } else if (prop === "AddressLine1") {
                            fieldsToResubmit.push("Patient's Address 1");
                        } else if (prop === "CityId") {
                            fieldsToResubmit.push("Patient's City");
                        } else if (prop === "StateId") {
                            fieldsToResubmit.push("Patient's State");
                        } else {
                            fieldsToResubmit.push(prop);
                        }
                    }
                    if (isNeedToReSubmit) {
                        resubData.PatientId = rxInfo.Person.Id;
                        resubData.PrescRefillId = editedRxPrescReFillId ? editedRxPrescReFillId : null;
                        if (prop === "StateId" && (oldRxInfo.Addresses.StateName != rxInfo.Addresses.StateName)) {
                            resubData.PatientId = rxInfo.Person.Id;
                            resubData.StateName = rxInfo.Addresses.StateName;
                        }
                    }
                });
            }
            if (!frmPatPrescFile) {
                if (oldRxInfo && rxInfo && oldRxInfo.Prescriber && rxInfo.Prescriber &&
                    (oldRxInfo.Prescriber["prescriberdeanum"] != rxInfo.Prescriber["prescriberdeanum"])) {
                    isNeedToReSubmit = true;
                    fieldsToResubmit.push("Prescriber DEA Number");
                    if (isNeedToReSubmit) {
                        resubData.PrescriberId = rxInfo.Prescription.PrescriberId;
                    }
                }

                ["PrescNum", "ReFillNum", "PrescSN"].map(prop => {
                    if (oldRxInfo.Prescription && rxInfo.Prescription && oldRxInfo.Prescription[prop] != rxInfo.Prescription[prop]) {
                        isNeedToReSubmit = true;
                        if (prop === "PrescNum") {
                            fieldsToResubmit.push("Prescription Number");
                        } else if (prop === "ReFillNum") {
                            fieldsToResubmit.push("Refill Number");
                        } else if (prop === "PrescSN") {
                            fieldsToResubmit.push("Rx Serial Number");
                        } else {
                            fieldsToResubmit.push(prop);
                        }
                        if (isNeedToReSubmit) {
                            resubData.PrescRefillId = rxInfo.PrescReFill.Id;
                        }
                    }
                });
                const ordDateChanged = this.checkDatesEqualOrNot(oldRxInfo.PrescReFill["OrderDtTm"], rxInfo.PrescReFill["OrderDtTm"]);
                const fillDateChanged = this.checkDatesEqualOrNot(oldRxInfo.PrescReFill["FillDtTm"], rxInfo.PrescReFill["FillDtTm"]);
                ["ReFillNum", "DispQty", "SupplyDays", "RxOriginId", "OrderDtTm", "FillDtTm"].map(prop => {
                    if ((oldRxInfo.PrescReFill && rxInfo.PrescReFill) && (((prop !== "OrderDtTm" && prop !== "FillDtTm") && oldRxInfo.PrescReFill[prop] != rxInfo.PrescReFill[prop]) || (prop === "OrderDtTm" &&  ordDateChanged) || (prop === "FillDtTm" && fillDateChanged))) {
                        isNeedToReSubmit = true;
                        if (prop === "ReFillNum") {
                            fieldsToResubmit.push("ReFill Number");
                        } else if (prop === "DispQty") {
                            fieldsToResubmit.push("Dispense Quantity");
                        } else if (prop === "SupplyDays") {
                            fieldsToResubmit.push("Supply Days");
                        } else if (prop === "RxOriginId") {
                            fieldsToResubmit.push("Rx Origin Id");
                        } else if (prop === "OrderDtTm") {
                            fieldsToResubmit.push("Order Date");
                        } else if (prop === "FillDtTm") {
                            fieldsToResubmit.push("Fill Date");
                        } else {
                            fieldsToResubmit.push(prop);
                        }
                        if (isNeedToReSubmit) {
                            resubData.PrescRefillId = rxInfo.PrescReFill.Id;
                        }
                    }
                });

                ["ndc"].map(prop => {
                    if ((oldRxInfo.Drug && rxInfo.Drug) &&
                        oldRxInfo.Drug[prop] != rxInfo.Drug[prop]) {
                        isNeedToReSubmit = true;
                        fieldsToResubmit.push("Drug NDC");
                        if (isNeedToReSubmit) {
                            resubData.DrugId = rxInfo.Prescription.DrugId;
                        }
                    }
                });
                if (isNeedToReSubmit) {
                    resubData.PrescRefillId = rxInfo.PrescReFill.Id;
                }
            } else {
                if (oldRxInfo && rxInfo && oldRxInfo["Prescriber"] && rxInfo["Prescriber"]
                && (oldRxInfo["Prescriber"]["DEANum"] != rxInfo["Prescriber"]["DEANum"])) {
                    isNeedToReSubmit = true;
                    fieldsToResubmit.push("Prescriber DEA Number");
                    if (isNeedToReSubmit) {
                        resubData.PrescriberId = rxInfo.Prescriber.Id;
                        resubData.PrescRefillId = editedRxPrescReFillId ? editedRxPrescReFillId : null;
                    }
                }
            }
            return {"isNeedToReSubmit": isNeedToReSubmit, "fields": fieldsToResubmit, "resubData": resubData};
        } else {
            return null;
        }

    }

    checkDatesEqualOrNot(date1, date2) {
        const oldDate = new Date(moment(date1).format("MM/DD/YYYY")).getTime();
        const newDate = new Date(moment(date2).format("MM/DD/YYYY")).getTime();
        return (oldDate !== newDate) ? true : false;
    }

    checkIsValidRxForCntrlFile(rxFG: FormGroup): any {
        const rxInfo = rxFG.getRawValue();
        const reqFields = [];
        const patientInfo =  rxInfo && rxInfo.Patient ? rxInfo.Patient : null;
        let isRxValid = true;
        [
            "lastname",
            "firstname",
            "address1",
            "city",
            "state",
            "zipcode",
            "dob",
            "gender"
        ].map(prop => {
            if (rxInfo && rxInfo.Patient && (
                !rxInfo.Patient[prop] ||
                rxInfo.Patient[prop] === "" ||
                rxInfo.Patient[prop] === "###")
            ) {
                isRxValid = false;
                if (prop === "lastname") {
                    reqFields.push("Patient's Last Name");
                } else if (prop === "firstname") {
                    reqFields.push("Patient's First Name");
                } else if (prop === "zipcode" && patientInfo.state === "NJ") {
                    reqFields.push("Patient's Zip Code");
                } else if (prop === "dob" && patientInfo.state === "NJ") {
                    reqFields.push("Patient's DOB");
                } else if (prop === "gender" && (patientInfo.state === "CA")) {
                    reqFields.push("Patient's Gender");
                } else if (prop === "address1" && (patientInfo.state === "CT" || patientInfo.state === "NM" || patientInfo.state === "PR" || patientInfo.state === "IL" || patientInfo.state === "NJ" )) {
                    reqFields.push("Patient's Address Line 1");
                } else if (prop === "city" && patientInfo.state === "NJ") {
                    reqFields.push("Patient's City");
                } else if (prop === "state" && patientInfo.state === "NJ") {
                    reqFields.push("Patient's State");
                } else {
                    reqFields.push(prop);
                }
            }
        });

        ["prescribernpinum", "prescriberdeanum"].map(prop => {
            if (rxInfo && rxInfo.Prescriber &&
                (rxInfo.Prescriber[prop] === null ||
                rxInfo.Prescriber[prop] === "" ||
                rxInfo.Prescriber[prop] === "###")
            ) {
                isRxValid = false;
                if (prop === "prescriberdeanum") {
                    reqFields.push("Prescriber DEA Number");
                } else if (prop === "prescribernpinum" && (patientInfo.state === "DC" || patientInfo.state === "NJ" || patientInfo.state === "NM" || patientInfo.state === "PA")) {
                    reqFields.push("Prescriber NPI Number");
                }
            }
        });

        ["PrescNum", "ReFillNum"].map(prop => {
            if (rxInfo && rxInfo.Prescription &&
                (rxInfo.Prescription[prop] === null ||
                rxInfo.Prescription[prop] === "" ||
                rxInfo.Prescription[prop] === "###")
            ) {
                isRxValid = false;
                if (prop === "PrescNum") {
                    reqFields.push("Prescription Number");
                } else if (prop === "ReFillNum") {
                    reqFields.push("Auth Refill Number");
                } else {
                    reqFields.push(prop);
                }
            }
        });

        ["ReFillNum", "DispQty", "SupplyDays", "RxOriginId", "OrderDtTm", "FillDtTm", "OrdQty"].map(prop => {
            if (rxInfo &&  rxInfo.PrescReFill &&
                (rxInfo.PrescReFill[prop] === null ||
                rxInfo.PrescReFill[prop] === "" ||
                rxInfo.PrescReFill[prop] === "###")
            ) {
                isRxValid = false;
                if (prop === "ReFillNum") {
                    reqFields.push("ReFill Number");
                } else if (prop === "DispQty" && (patientInfo.state === "CA" || patientInfo.state === "CO" || patientInfo.state === "OH" || patientInfo.state === "")) {
                    reqFields.push("Dispensed Quantity");
                } else if (prop === "SupplyDays") {
                    reqFields.push("Supply Days");
                } else if (prop === "RxOriginId") {
                    reqFields.push("Rx Origin ID");
                }  else if (prop === "OrderDtTm") {
                    reqFields.push("Order Date");
                } else if (prop === "FillDtTm" &&
                (patientInfo.state === "FL" || patientInfo.state === "CO" || patientInfo.state === "IL" || patientInfo.state === "NJ" || patientInfo.state === "PA" ||  patientInfo.state === "VA")) {
                    reqFields.push("Fill Date");
                } else if (prop === "OrdQty" && (patientInfo.state === "AZ" || patientInfo.state === "CA")) {
                    reqFields.push("Ordered Quantity");
                } else {
                    reqFields.push(prop);
                }
            }
        });

        ["ndc"].map(prop => {
            if (rxInfo && rxInfo.Drug &&
                (!rxInfo.Drug[prop] ||
                rxInfo.Drug[prop] === "" ||
                rxInfo.Drug[prop] === "###") && (patientInfo.state === "CA" || patientInfo.state === "CT" || patientInfo.state === "FL" || patientInfo.state === "NJ" ||
                patientInfo.state === "OH" || patientInfo.state === "PR")
            ) {
                isRxValid = false;
                reqFields.push("Drug NDC");
            }
        });
        const pharmContactName = this._commonServ.getSetttingValue("TenantSettings",  "TNT_CONTACT_NAME");
        const pharmRegNo = this._commonServ.getSetttingValue("TenantSettings",  "TNT_REGI");
        const pharmState = this._commonServ.getSetttingValue("TenantSettings",  "TNT_STATE");
        if (!pharmContactName && patientInfo.state === "CA") {
            isRxValid = false;
            reqFields.push("Pharmacy's Contact Name");
        }
        if (!pharmRegNo && (patientInfo.state === "FL" || patientInfo.state === "CT")) {
            isRxValid = false;
            reqFields.push("Pharmacy's License Number");
        }
        if (!pharmState && patientInfo.state === "WV") {
            isRxValid = false;
            reqFields.push("Pharmacy State"); //Id Qualifier of pat identifier
        }
        if (rxInfo && rxInfo.Patient && (!rxInfo.Patient.telephone || rxInfo.Patient.telephone === "" || rxInfo.Patient.telephone === "###") &&
        (!rxInfo.Patient.mobile || rxInfo.Patient.mobile === "" || rxInfo.Patient.mobile === "###") && (patientInfo.state === "FL" ||
         patientInfo.state === "NC" || patientInfo.state === "OK" || patientInfo.state === "PA" || patientInfo.state === "PR")) {
            isRxValid = false;
            reqFields.push("Patient's Phone Number");
        }
        if (rxInfo && rxInfo.Prescriber && (!rxInfo.Prescriber.telephone || rxInfo.Prescriber.telephone === "" || rxInfo.Prescriber.telephone === "###") &&
        (patientInfo.state === "AZ")) {
            isRxValid = false;
            reqFields.push("Prescriber's Phone Number");
        }
        if (rxInfo && rxInfo.Patient && rxInfo.Patient.PatientInfo && !rxInfo.Patient.PatientInfo.ServicePlaceId && patientInfo && patientInfo.state === "MA") {
            isRxValid = false;
            reqFields.push("Patient Location Code");
        }
        if (rxInfo && rxInfo.Drug && patientInfo.state === "OH") {
            if (rxInfo.Drug.drugtypeid && rxInfo.Drug.drugtypeid === 2 && !rxInfo.Drug.DispUnitFormId) {
                isRxValid = false;
                reqFields.push("Compound Drug Dosage Units Code");
            } else if (rxInfo.Drug.drugtypeid && rxInfo.Drug.drugtypeid !== 2  && !rxInfo.Drug.drugunitid ) {
                isRxValid = false;
                reqFields.push("Drug Dosage Units Code");
            }
        }
        return {"isValid": isRxValid, "fields": reqFields};
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    setNewPrescRefStat(rxFG, rxType ) {
        const prescReFill = rxFG.controls["PrescReFill"] as FormGroup;
        if (rxType === "nr" || !rxType || rxType === "nrf" ||
            rxType === "rnr" || rxType === "er" || rxType === "brx") {
            prescReFill.controls["IsNewPrescRefill"].setValue(false);
        }
    }

    async setQuickVerfStatus(val, rxFG, rxType) {
        const prescReFill = rxFG.controls["PrescReFill"] as FormGroup;
        if (val !== false && val) {
            this._audtUtls.getChangedValues(null, { "Rph Verified": "Verified" }, "Rph Verification",
                "Rx", rxFG.value.PrescReFill.Id);
            this._audtUtls.getChangedValues(null, { "Received By": val, "Received DateTm": moment().format("MM/DD/YYYY hh:mm:ss a") },
                "Rx Received", "Rx", rxFG.value.PrescReFill.Id);
            this._audtUtls.getChangedValues(null, { "DP Verified": "Verified" },
                "Drug Pick Verification", "Rx", rxFG.value.PrescReFill.Id);
            if (rxType === "er") {
              await this.getandStoreRxInfo(+rxFG.value.Prescription.PrescNum, rxFG.value.PrescReFill.Id);
            }
        }
    }

    async saveQuikcVerfication(rxInfo, quickVerfData, rxType, rxFG?: any) {
        let data = null;
        if (rxInfo.PrescReFill.isVerified && quickVerfData) {
            data = {
                RefillNum: rxInfo.PrescReFill["ReFillNum"], PrescNum: rxInfo.Prescription["PrescNum"], IsVerified: true,
                RphAppUserName: quickVerfData["rphBy"], DphAppUserName: quickVerfData["drugBy"], RxAppUserName: quickVerfData["recvBy"],
                PrescrefillId: rxInfo.PrescReFill["Id"], PrescriptionId: rxInfo.Prescription["Id"], DrugNdc: rxInfo.Drug["drugcode"],
                IsFromEditRx: (rxType && rxType === "er") ? true : false, RxReceivedDate: quickVerfData["recvDt"],
                DPHverifiedDate: quickVerfData["drugDt"], RPHverifiedDate: quickVerfData["rphDt"],
                PartialFill: rxInfo.PrescReFill["PartialFillNo"]
            };
            if (quickVerfData["recvBy"]) {
                this._audtUtls.getChangedValues(
                    null,
                    {"Received By": quickVerfData["recvBy"], "Received DateTm": quickVerfData["recvDt"] + " " + quickVerfData["recvTm"]},
                    "Rx Received",
                    "Rx",
                    rxInfo.PrescReFill.Id
                );
            }
            if (quickVerfData["rphBy"]) {
                this._audtUtls.getChangedValues(
                    null,
                    {"RPH Verified By": quickVerfData["rphBy"], "Verified DateTm": quickVerfData["rphDt"] + " " + quickVerfData["rphTm"]},
                    "Rph Verification",
                    "Rx",
                    rxInfo.PrescReFill.Id
                );
            }
            if (quickVerfData["drugBy"]) {
                this._audtUtls.getChangedValues(
                    null,
                    {"Drug Pick Verified By": quickVerfData["drugBy"], "Verified DateTm": quickVerfData["drugDt"] + " " + quickVerfData["drugTm"]},
                    "Drug Pick Verification",
                    "Rx",
                    rxInfo.PrescReFill.Id
                );
            }

        } else if (rxInfo && rxInfo.PrescReFill["Id"]) {
            const pharName = this._usrServ.getToken("PhUserName");
            const techName = this._usrServ.getToken("TechUserName");
            data = {
                RefillNum: rxInfo.PrescReFill["ReFillNum"], PrescNum: rxInfo.Prescription["PrescNum"], IsVerified: false,
                RphAppUserName: null, DphAppUserName: null, RxAppUserName: techName && (techName).trim() !== "" ? techName : pharName,
                PrescrefillId: rxInfo.PrescReFill["Id"], PrescriptionId: rxInfo.Prescription["Id"], DrugNdc: rxInfo.Drug["drugcode"],
                IsFromEditRx: (rxType && rxType === "er") ? true : false,
                RxReceivedDate: quickVerfData ? quickVerfData["recvDt"] : new Date(),
                DPHverifiedDate: null, RPHverifiedDate: null, PartialFill: rxInfo.PrescReFill["PartialFillNo"]
            };
        }
        if (rxInfo && rxInfo.PrescReFill["Id"] && quickVerfData) {
            await this._rxService.saveVerification(data).toPromise();
            if (rxType && rxType === "er") {
                const rxInfoD = await this.getandStoreRxInfo(+rxInfo.Prescription.PrescNum, rxInfo.PrescReFill.Id);
                if (rxFG) {
                    const oldDataRefillData = this._rxStore.rxRefDet$["source"]["value"];
                    if(rxType === "rf" && oldDataRefillData && oldDataRefillData["Refdetail"]  && oldDataRefillData["Refdetail"]["Refilldetails"]) {
                        const edtRxAudtMdl = new RxAuditLog().rxAuditModel;
                        this._nwAudtUtl.saveAuditChange(
                            oldDataRefillData["Refdetail"]["Refilldetails"][0],
                            rxInfo,
                            "EditRx",
                            "Rx",
                            rxInfoD.PrescReFill.Id,
                            edtRxAudtMdl
                        );
                    }
                    this._pRxFGUtls.patchVerifications(rxFG, rxInfoD, "both");
                }
            }
        }
    }

    makeRequirdFildsBasedOnSettings(rxFG: FormGroup, systemSetting: SystemSetting) {
        const logedIntechName = this._usrServ.getToken("TechUserName");
        const rxControlModal = new RxControlsModal();
        if ((systemSetting) && (systemSetting.RxSettings) && (systemSetting.RxSettings.length > 0)) {
            systemSetting.RxSettings.map(set => {
                if (set.Key !== "MAKE_TECH_INITIALS_MNDT") {
                    const rxControls = rxControlModal[set.Key];
                    if (rxControls && rxControls.length === 2 && set.Value === "1") {
                        const rxControlFG: any = rxFG.controls[rxControls[0]];
                        rxControlFG.controls[rxControls[1]].setValidators([
                            Validators.required
                        ]);
                    } else if (rxControls && set.Value === "1") {
                        const rxControlFG: any = rxFG.controls[rxControls[0]];
                        const subRxControlFG: any = rxControlFG.controls[rxControls[1]];
                        subRxControlFG.controls[rxControls[2]].setValidators([
                            Validators.required
                        ]);
                    }
                } else if (set.Key === "MAKE_TECH_INITIALS_MNDT" && logedIntechName &&
                    this._commonServ.getSetttingValue("RxSettings", "MAKE_TECH_INITIALS_MNDT") === "1") {
                    const rxControls = rxControlModal[set.Key];
                    if (rxControls && rxControls.length === 2 && set.Value === "1") {
                        const rxControlFG: any = rxFG.controls[rxControls[0]];
                        rxControlFG.controls[rxControls[1]].setValidators([
                            Validators.required
                        ]);
                    } else if (rxControls && set.Value === "1") {
                        const rxControlFG: any = rxFG.controls[rxControls[0]];
                        const subRxControlFG: any = rxControlFG.controls[rxControls[1]];
                        subRxControlFG.controls[rxControls[2]].setValidators([
                            Validators.required
                        ]);
                    }
                }
            });
        }
        const prescReFillFG: any = rxFG.controls["PrescReFill"];
        prescReFillFG.controls["OrdQty"].setValidators([Validators.required]);
        prescReFillFG.controls["DispQty"].setValidators([Validators.required]);

        const sigCodes: any = rxFG.controls["SigCodes"];
        sigCodes.controls["SigCode"].setValidators([Validators.required]);
    }

    patchDefaultValues(rxFG: FormGroup) {
        const prescReFill: any = rxFG.controls.PrescReFill;
        const prescrip: any = rxFG.controls.Prescription;
        const techUsrName = this._usrServ.getToken("TechUserName");
        const phUserName = this._usrServ.getToken("PhUserName");

        const today = moment(new Date());
        prescReFill.controls["ReFillNum"].setValue(0);
        if (rxFG && rxFG.controls && !rxFG.controls["isNewFrmExisting"].value) {
            prescrip.controls["ReFillNum"].setValue(0);
            prescReFill.controls.BucketId.setValue(
                +this.getSettingValue("RxSettings", "DEF_GENERAL_BUCKET"));
            if (rxFG && rxFG.controls?.Documents && rxFG.controls?.Documents?.value?.length && rxFG.controls?.Documents?.value[0]?.FaxDocumentId) {
                prescReFill.controls.RxOriginId.setValue(5);
            } else {
                prescReFill.controls.RxOriginId.setValue(
                    +this.getSettingValue("RxSettings", "DEF_RX_ORIG_CODE"));
            }
            if (!rxFG.value["isFromDocuementQueue"] && !prescReFill.controls.DeliverModeId.value) {
                    prescReFill.controls.DeliverModeId.setValue(
                        +this.getSettingValue("RxSettings", "DEF_DELIVERY_METHOD") ?
                        +this.getSettingValue("RxSettings", "DEF_DELIVERY_METHOD") : this._rxUtils.getDelModeDefVal());
            }
            prescReFill.controls["NoOfLabels"].setValue(
                +this._commonServ.getSetttingValue("RxSettings", "Default_Label_Quantity"));
                const labelQty = +this._commonServ.getSetttingValue("RxSettings", "Default_Label_Quantity");
                if(labelQty && labelQty > 100) {
                    Sentry.captureException([
                        {Label_Quantity:   +this._commonServ.getSetttingValue("RxSettings", "Default_Label_Quantity")}
                    ]);
                }
        }
        prescReFill.controls.IsCopayPaid.setValue(
            +this.getSettingValue("RxSettings", "DEF_COPAY_PAID_STATUS"));

        if (techUsrName && techUsrName !== " ") {
            prescReFill.controls.TechInitials.setValue(techUsrName);
        } else {
            if (!sessionStorage.getItem("ErxId")) {
                prescReFill.controls["TechInitials"].reset();
                prescReFill.controls["TechInitials"].markAsUntouched();
            }
        }
        if ( phUserName &&  phUserName !== " ") {
            prescReFill.controls["RegPharmacist"].setValue(phUserName);
        } else {
            prescReFill.controls["RegPharmacist"].setValue(null);
        }

        // DEF_DOSE_TIME_INTERVAL
        //  Based on this card commented these changes EP-6860: UI: DAW: Remove and Update DAW Settings for Rx Entry
        // if (!prescReFill.value.DAWId) {
        //     const dawStng = +this.getSettingValue("RxSettings", "DEF_DAW");
        //     prescReFill.controls.DAWId.setValue(dawStng ? dawStng : null);
        // }

        prescReFill.controls["FillDtTm"].setValue(today);
        prescReFill.controls["OrderDtTm"].setValue(
            this._commonServ.getSetttingValue("RxSettings", "MAKE_DT_ORDER_MNDT") === "0" ? today : null);
        // prescReFill.controls["NoOfLabels"].setValue(1);
    }

    focusInvalidFieldForErx(inputFormGroup: FormGroup) {
        const prescRefillFG = inputFormGroup.controls.PrescReFill as FormGroup;
        const sigCodes: any = inputFormGroup.controls.SigCodes as FormGroup;
        const patInfo: any = inputFormGroup.controls.Patient.value as FormGroup;
        if (prescRefillFG.controls.DAWId.invalid) {
            this.focusToInputFieldById("rxdaw");
        } else if (prescRefillFG.controls.LotNum.invalid) {
            this.focusToInputFieldById("drugLotID");
        } else if (prescRefillFG.controls.LotExpiryDate.invalid || (prescRefillFG.value.LotNum && !prescRefillFG.value.LotExpiryDate)
        || new Date(moment().format("MM/DD/YYYY")) > new Date(moment(prescRefillFG.value.LotExpiryDate).format("MM/DD/YYYY"))) {
            this.focusToInputFieldById("rxExpires");
        } else if (prescRefillFG.controls.OrdQty.invalid || +prescRefillFG.value.OrdQty === 0) {
            this.focusToInputFieldById("rxOrdrQty");
        } else if (prescRefillFG.controls.DispQty.invalid || +prescRefillFG.value.DispQty === 0) {
            this.focusToInputFieldById("rxDispQty");
        } else if (!sigCodes.controls.Description.value && sigCodes.controls.Description.value !== "") {
            this.focusToInputFieldById("rxSigCode");
        } else if (inputFormGroup.controls.TreatmentTypeId.invalid || !inputFormGroup.controls.TreatmentTypeId) {
            this.focusToInputFieldById("treatmenttypeId");
        }  else if (prescRefillFG.controls.OrderDtTm.invalid || !prescRefillFG.controls.OrderDtTm) {
            this.focusToInputFieldById("rxOrderDtTm");
        }  else if (prescRefillFG.controls.FillDtTm.invalid || !prescRefillFG.controls.FillDtTm) {
            this.focusToInputFieldById("rxFillDtTm");
        } else if (prescRefillFG.controls.SupplyDays.invalid || +prescRefillFG.value.SupplyDays === 0) {
            this.focusToInputFieldById("rxSupplyDays");
        } else if ((<FormGroup>inputFormGroup.controls.Prescription).controls.ReFillNum.invalid) {
            this.focusToSelectById("rxReFillNum");
        } else if (prescRefillFG.controls.TechInitials.invalid) {
            this.focusToInputFieldById("rxTechInitials");
        } else if (prescRefillFG.controls.RegPharmacist.invalid) {
            this.focusToInputFieldById("rxRegPharmacist");
        } else if (prescRefillFG.controls.RxOriginId.invalid) {
            this.focusToSelectById("rxRxOriginId");
        } else if (prescRefillFG.controls.DeliverModeId.invalid || !prescRefillFG.value.DeliverModeId) {
            this.focusToInputFieldById("rxDeliverModeId");
        } else if (prescRefillFG.controls.Price.invalid) {
            this.focusToInputFieldById("rxPrice");
        } else if (inputFormGroup?.controls?.isSupplyDaysChanged?.value) {
            this.focusToInputFieldById("rxSupplyDays");
            return true;
        } else {
            if(inputFormGroup.value['IsElectornicRx'] && inputFormGroup.value.rxType === 'nr')
                 this.focusOnInputField(inputFormGroup);
            else 
            this.focusToInputFieldById((inputFormGroup.value.rxType === "er" && patInfo && patInfo.patallergies?.length) ? "actionInputer" : "actionInput");
            return true;
        }
        return false;
    }

    focusToInputFieldById(fieldToFocus) {
        if (document.getElementById(fieldToFocus)) {
            setTimeout(()=>{
                let elm;
            elm = document.getElementById(fieldToFocus);
            if (elm) {
                elm.focus();
                // elm.select();
            }
            }, 100);
        }
    }

    focusToSelectById(fieldToFocus) {
        const element=document.getElementById(fieldToFocus);
        if (element && element.tagName === "NG-SELECT") {
            const ngSelectElem: any = document.getElementsByClassName("ng-input");
            const len = ngSelectElem.length;
            for (let i = 0; i < len; i++) {
                if (
                    ngSelectElem[i] &&
                    ngSelectElem[i]["parentElement"]["parentElement"]["parentElement"]["id"] === fieldToFocus
                ) {
                    ngSelectElem[i]["id"] = fieldToFocus;
                    ngSelectElem[i]["children"][0].focus();
                }
            }
            (<HTMLElement>element.firstElementChild.firstElementChild.children[1]).focus();
        }
    }

    focusOnPatientPrescDrug(rxFG: any) {

    }

    focusOnSearchBox() {
        if (document.getElementById("iSearchBox")) {
            let elm;
            elm = document.getElementById("iSearchBox")[1];
            if (elm) {
                elm.focus();
                // elm.select();
            }
        }
    }

    getAndCalDiscount(rxFG: any) {
        this._rxService
        .getRxDiscount(rxFG.value.Patient["DiscSchCd"])
        .subscribe((result: any) => {
            const prescReFill: FormGroup = rxFG.controls
                .PrescReFill as FormGroup;
            const rxBill: FormGroup = rxFG.controls.RxBill as FormGroup;
            let TotPrice = prescReFill.value.TotalAmt;

            if (TotPrice !== undefined && TotPrice !== null && TotPrice !== "") {
                TotPrice = parseFloat(TotPrice);
            }
            if (result !== null && result !== false) {
                let DiscountAmount = 0;
                let Copay = 0;
                let BalDue = 0;
                let DiscountAmtTemp = 0;
                DiscountAmount = result.DiscountAmount;
                Copay = rxBill.value.CoPayChrg;

                if (
                    DiscountAmount === undefined ||
                    DiscountAmount === null ||
                    isNaN(DiscountAmount)
                ) {
                    DiscountAmount = 1;
                }

                if (!Copay) {
                    Copay = 0;
                }

                if (result.DiscountType === "P") {
                    if (DiscountAmount !== 0) {
                        DiscountAmount =
                            parseFloat(TotPrice) * (DiscountAmount / 100);
                    }

                    prescReFill.controls["Discount"].setValue(
                        DiscountAmount.toFixed(2)
                    );
                    DiscountAmtTemp = DiscountAmount + Copay;

                    if (TotPrice > DiscountAmtTemp) {
                        BalDue = TotPrice - DiscountAmtTemp;
                        BalDue = +BalDue > 0 ? +BalDue : 0;
                        prescReFill.controls["BalDue"].setValue(BalDue.toFixed(2));
                    } else {
                        BalDue = DiscountAmtTemp - TotPrice;
                        BalDue = +BalDue > 0 ? +BalDue : 0;
                        prescReFill.controls["BalDue"].setValue(0);
                    }
                } else {
                    DiscountAmount = Math.ceil(DiscountAmount * 100) / 100;
                    prescReFill.controls["Discount"].setValue(
                        DiscountAmount.toFixed(2)
                    );
                    DiscountAmtTemp = DiscountAmount + Copay;

                    if (DiscountAmtTemp) {
                        if (TotPrice > DiscountAmtTemp) {
                            BalDue = TotPrice - DiscountAmtTemp;
                            BalDue = +BalDue > 0 ? +BalDue : 0;
                            prescReFill.controls["BalDue"].setValue(BalDue.toFixed(2));
                        } else {
                            BalDue = DiscountAmtTemp - TotPrice;
                            BalDue = +BalDue > 0 ? +BalDue : 0;
                            prescReFill.controls["BalDue"].setValue(
                                BalDue.toFixed(2)
                            );
                        }
                    }
                }
            } else {
                if (TotPrice) {
                    prescReFill.controls["BalDue"].setValue(TotPrice.toFixed(2));
                } else {
                    prescReFill.controls["BalDue"].setValue(0.0);
                }
            }
        });
    }

    setFocusOnSearchBox(rxFG, type) {
        if (type === "Patient") {
            if (rxFG && rxFG.value["Prescriber"] && rxFG.value["Prescriber"]["prescriberid"]) {
                this.checkRxFGHasDrug(rxFG);
            } else {
                this.setValueForFocusOnSearch(rxFG, "Prescriber");
            }
        } else if (type === "Prescriber") {
            if (rxFG && rxFG.value && rxFG.value["Patient"] && rxFG.value["Patient"]["patientid"]) {
                this.checkRxFGHasDrug(rxFG);
            } else {
                this.setValueForFocusOnSearch(rxFG, "Patient");
            }
        } else if (type === "Drug") {
            if (rxFG && rxFG.value && rxFG.value["Patient"] && rxFG.value["Patient"]["patientid"]) {
                if (rxFG.value["Prescriber"] && rxFG.value["Prescriber"]["prescriberid"]) {
                    rxFG.controls["focusOnSearch"].patchValue(null);
                    if (rxFG.value["rxType"] === "nr") {
                        if (document.getElementById("rxdaw")) {
                            document.getElementById("rxdaw").focus();
                        }
                    } else {
                        this.focusInvalidFieldForErx(rxFG);
                    }
                } else {
                    this.setValueForFocusOnSearch(rxFG, "Prescriber");
                }
            } else {
                this.setValueForFocusOnSearch(rxFG, "Patient");
            }
        }
    }

    checkRxFGHasDrug(rxFG) {
        if (rxFG.value["Drug"] && rxFG.value["Drug"]["id"]) {
            rxFG.controls["focusOnSearch"].patchValue(null);
            const isERx = this.isFromErx(rxFG.value);
            if (rxFG.value["rxType"] === "nr" && !isERx) {
                if (document.getElementById("rxdaw")) {
                    document.getElementById("rxdaw").focus();
                }
            } else {
                this.focusInvalidFieldForErx(rxFG);
            }
        } else {
            this.setValueForFocusOnSearch(rxFG, "Drug");
        }
    }

    setValueForFocusOnSearch(rxFG, type) {
        rxFG.controls["focusOnSearch"].patchValue(null);
        setTimeout(() => {
            rxFG.controls["focusOnSearch"].patchValue(type);
        }, 50);
    }

    async getDrugsLot(rxType, drugId, bucketId?) {
        if (!bucketId) {
            bucketId = this._drugBuckUtil.getDefaultBucketId();
        }
        const data = {
            term: null,
            drugId: drugId,
            bucketId: bucketId,
            fetchInActiveRecords: rxType === "er" ? true: false
        }
        const drugLotResp = await this._commonServ.getDrugLots(data).toPromise();
        let finalLotList = [];
        if (drugLotResp && drugLotResp.length > 0) {
            finalLotList = drugLotResp.sort((a, b) => {
                return <any>new Date(a.ExpDt) - <any>new Date(b.ExpDt);
            });
            finalLotList = finalLotList.filter(val => val.IsActive &&
                new Date(moment(val.ExpDt).format("MM/DD/YYYY")) >= new Date(moment().format("MM/DD/YYYY")));
        }
        return finalLotList;
    }

    async patchDefDrugLot(rxFG, drugId, bucketId?, finalLotListData?) {
        const finalLotList = finalLotListData ? finalLotListData : await this.getDrugsLot(rxFG.value["rxType"], drugId, bucketId);
        const PRESCREFILL = rxFG.controls["PrescReFill"] as FormGroup;
        if (finalLotList && finalLotList.length > 0) {
                const drugLotID = rxFG && rxFG.value.PrescReFill && rxFG.value.PrescReFill.DrugLotId ? rxFG.value.PrescReFill.DrugLotId : null;
                const lotDetails = drugLotID ?  (finalLotList.filter(lotdetails => {
                   return  lotdetails.Id === drugLotID ;
                })) : null;
                PRESCREFILL.controls["LotNum"].patchValue(rxFG.value.rxType === "nr" ? finalLotList[0]["LotNum"] : (lotDetails && lotDetails.length ? lotDetails[0].LotNum : finalLotList[0]["LotNum"]));
                PRESCREFILL.controls["LotExpiryDate"].patchValue(rxFG.value.rxType === "nr" ? (finalLotList[0]["ExpDt"] ? moment.utc(finalLotList[0]["ExpDt"]).local().format("MM/DD/YYYY") : finalLotList[0]["ExpDt"]): (
                lotDetails && lotDetails.length ? lotDetails[0].ExpDt : finalLotList[0]["ExpDt"]));
                PRESCREFILL.controls["isLotExpDisabled"].patchValue(false);
        } else {
            if(!finalLotList?.length) {
                    PRESCREFILL.controls["LotNum"].patchValue(null);
                    PRESCREFILL.controls["LotExpiryDate"].patchValue(null);
                    PRESCREFILL.controls["DrugLotId"].patchValue(null);
                    PRESCREFILL.controls["LotExpiryDate"].setErrors(null);
                    PRESCREFILL.controls["LotExpiryDate"].markAsUntouched();
                    PRESCREFILL.controls["isLotExpDisabled"].patchValue(true);
                    PRESCREFILL.controls["LotExpiryDate"].setValidators([Validators.nullValidator]);
            }
        }
    }
    async patchDrugLot(rxType, AddOrUpdateValue, drugId, bucketId?, finalLotListData?) {
    const finalLotList = finalLotListData ? finalLotListData : await this.getDrugsLot(rxType, drugId, bucketId);
        if (finalLotList && finalLotList.length > 0) {
        AddOrUpdateValue.controls['LotNum'].patchValue(finalLotList[0]["LotNum"]);
        AddOrUpdateValue.controls['ExpDt'].patchValue(finalLotList[0]["ExpDt"] ?
        moment.utc(finalLotList[0]["ExpDt"]).local().format("MM/DD/YYYY") : finalLotList[0]["ExpDt"]);
        }
    }
    async patchAndUpdateOrderStatus(rxFG) {
        await this._rxService.putRxDiscontinuedOrderStatus(rxFG.value.Prescription.Id).toPromise();
        rxFG.controls["PrescReFill"].patchValue({OrderStatus : null});
        rxFG.controls["Prescription"].patchValue({IsDiscontinued : false});
    }
    getPrimaryAddress(data,primeAdd) {
        let primeList: any;
        if (data) {
        primeList = data.filter(res => (res.Id == primeAdd));
        const patNoInsPriList = data.filter(val => (val.Id !== primeAdd));
        if(patNoInsPriList){
            primeList = primeList.concat(patNoInsPriList)
        }
        return primeList;
    }}
}
