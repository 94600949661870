import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxStore } from "src/app/store";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { AlertService, CommonService, DrugService } from "src/app/services";
import * as moment from "moment";
import { CommonUtil, NRxUtils, WijimoUtil } from "src/app/utils";
import { FormBuilder, FormGroup } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormatsUtil } from "src/app/utils/formats.util";


@Component({
    selector: "app-equivalent-drugs",
    templateUrl: "./equivalent-drugs.component.html",
    styles: []
})
export class EquivalentDrugsComponent implements OnInit {
    checkedGenDrug: any;
    checkedBrandDrug: any;
    selectedDrugItem: string;

    @Input() set EquivalentDrugWJ(ed: any) {
        this.equivalentDrugWJ = ed;
    }
    @Input() rxFG: any;
    @Input() rxFGFormGroup: FormGroup;
    @Input()
    set EqulDrugPopType(ed: any) {
        this.equlDrugPopType = ed;
    }

    @Input()
    set IsFrom(ed: any) {
        this.isFrom = ed;
    }

    @Input()
    set DiscDrugInfo(dd: any) {
        this.discDrugInfo = dd;
    }
    @Input() isGenrcEql: boolean;
    @Input() isFromRecall: boolean;
    @Input() BucketId: number;
    @Input() DAWBsdDrugSug : boolean;
    @Output() OptedDrugInfo = new EventEmitter();
    @Output() emitValuesForeRx = new EventEmitter();

    @Output()
    CloseAdminRxPopUp = new EventEmitter<{}>();
    @Output() closeDiscForDrugReset = new EventEmitter<any>();

    @ViewChild("MarkAsPrefDrug", { static: true })
    MarkAsPrefDrug: any;

    equivalentDrugWJ: CollectionView;
    equivalentBrandDrugWJ: CollectionView;
    equivalentGenDrugWJ: CollectionView;
    equlDrugPopType: any;
    discDrugInfo: any;
    rxHeaders: any[] = [];
    selectedRow: any;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    wjHeadersForGen: { hName: string; isVisible: boolean; width: number; }[];
    selectedItem: any;
    typeofDrug: any;
    brandedDrugList: any;
    brandedDrugListCopy: any;
    genricDrugList: any;
    genricDrugListCopy: any;
    payLoad: any;
    PrefDrugStng: any;
    DrugList = [];
    modelRef: any;
    isFrom: string;
    prefDrugSetting: any;
    isHideButton: boolean = true;
    isErxRx: boolean = false;
    selectedDrugInfo: any = null;
    refreshFG: FormGroup;
    genericGrid: wjcGrid.FlexGrid;
    branchGrid: wjcGrid.FlexGrid;
    unsubscribe$: Subject<void> = new Subject();
    isPaidPayRecAvail : boolean;

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 || event.which === 9) {
            // event.preventDefault();
            //  this.closepopmodal();
        }
    }

    constructor(public activeModal: NgbActiveModal, private _rxStore: RxStore,
        private _alertService: AlertService, private _wijimoUtils: WijimoUtil,
        private _drugBuckUtil: DrugBucketUtil, private _drugServc: DrugService,
        private _nrxUtls: NRxUtils, private _fb: FormBuilder, public _cmnUtils : CommonUtil,
          private _commonServ: CommonService, private _modalServ: NgbModal,
        public _formatsUtil:FormatsUtil) {
                this.createFG();
          }

    ngOnInit() {
        if (this.equlDrugPopType && this.rxFGFormGroup)
            this.rxFGFormGroup.controls['equilantSelectTypeForPrefDrug'].patchValue(this.equlDrugPopType);
        this.prefDrugSetting = +this._commonServ.getSetttingValue("RxSettings", "PRF_DRUG_SLCT_ON_RX_FILL");
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "equivalentBrandDrugs" || w["WijmoKey"] === "equivalentGenDrugs")) {
                this.patchDefaultValHeaders();
            }
        });
        this.filterData();
        if ((this.prefDrugSetting == 2 && (this.equlDrugPopType == 'notPref' || this.equlDrugPopType == 'eRx'))) {
            this.isHideButton = false;
        } else {
            this.isHideButton = true;
        }
    }
    filterData() {
        const Branded = [], Genric = [];
        if (this.equivalentDrugWJ && this.equivalentDrugWJ.items.length > 0) {
         if(this.equlDrugPopType && this.rxFGFormGroup)
             this.rxFGFormGroup.controls['equilantSelectTypeForPrefDrug'].patchValue(this.equlDrugPopType);

         this.prefDrugSetting =  +this._commonServ.getSetttingValue("RxSettings", "PRF_DRUG_SLCT_ON_RX_FILL");
        const Branded = [], Genric = [];
        if (this.equivalentDrugWJ && this.equivalentDrugWJ.items.length > 0 ) {
            this.equivalentDrugWJ.items.forEach(item => {
                item["isbranded"] ? Branded.push(item) : Genric.push(item);
            });

            if (this.equlDrugPopType && this.equlDrugPopType === 'rxScreen') {
                Branded.sort((a, b) => (a.ispreferred > b.ispreferred) ? -1 : 1);
                Genric.sort((a, b) => (a.ispreferred > b.ispreferred) ? -1 : 1);
            }
            this.brandedDrugList = Branded;
            this.brandedDrugListCopy = Branded;
            this.genricDrugList = Genric;
            this.genricDrugListCopy = Genric;
            this.generateWJBrand(Branded);
            this.generateWJGeneric(Genric);

        }
        if(this.rxFGFormGroup && this.rxFGFormGroup.value && this.rxFGFormGroup.value.RxPay && this.rxFGFormGroup.value.insuranceInfo){
            this.isPaidPayRecAvail = (this._cmnUtils.checkPayRecExistOrNotForSelcRx(this.rxFGFormGroup.value.RxPay) && !this.rxFGFormGroup.value.insuranceInfo.insurance.IsCash);
        }
        
    }
}
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("equivalentBrandDrugs");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("equivalentBrandDrugs", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("equivalentGenDrugs");
        this.wjHeadersForGen = this._wijimoUtils.patchDefHeader("equivalentGenDrugs", storedWJ1);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    generateWJBrand(BrandedOrGenric) {
        if (BrandedOrGenric && BrandedOrGenric.length) {
        this.equivalentBrandDrugWJ = new CollectionView(
            BrandedOrGenric.map((Rx, i) => {
                const k = {};
                k["Drug Name"] = (Rx.drugname + " " + Rx.strength + " " + Rx.drugformname)
                k["NDC"] = this._formatsUtil.getNDCFormat(Rx.ndc);
                k["Mfg"] = Rx.manufacturername;
                k["Pref"] = Rx.ispreferred ? "Y" : "N";
                k["Is Pref?"] = Rx.ispreferred ? "Y" : "N";
                k["item"] = Rx;
                k["itemFromEquil"] = true;
                k["Ispreferred"] = Rx.ispreferred;
                k["Isdiscontdate"] = Rx.isdiscontdate;
                k["isactive"] = Rx.isactive;
                k["id"] = Rx.id;
                k["drugcode"] = (Rx.drugcode) ? Rx.drugcode : null;
                k["manageinventory"] = Rx.manageinventory;
                k["LastDispensedDate"] = Rx.lastDispensedDate ? Rx.lastDispensedDate : null;
                k["Size"] = Rx.qtypack.toFixed(3);
                k["AWP"] = Rx.unitpriceawp ? "$" + Rx.unitpriceawp.toFixed(3) : "0.000";
                k["Stock Qty"] = this._drugBuckUtil.getQtyInHand(Rx.manageinventory, this.BucketId);
                k["Cost"] = Rx.unitpricecost ? "$" + Rx.unitpricecost.toFixed(3) : "0.000";
                k["Last Dispensed"] = Rx.dispensedDate ? moment(Rx.dispensedDate).format("MM/DD/YYYY") : "";
                k["Inv?"] = Rx.isinvmangd ? "Y" : "N";
                k["Is 340B"] = Rx.Is340B ? "Y" : "N";
                return k;
            })
        );
        }

    }
    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c]._hdr === "Is Pref?") {
                let key = panel.columns[c]._hdr;
                if (row.dataItem[key] === "Y") {
                    cell.style.backgroundColor = "#34e534";
                    cell.style.color = "#fff";
                } else {
                    cell.style.backgroundColor = "";
                    cell.style.color = "";
                }
            }
        }
    }
    generateWJGeneric(BrandedOrGenric) {
        if (BrandedOrGenric && BrandedOrGenric.length) {
        this.equivalentGenDrugWJ = new CollectionView(
            BrandedOrGenric.map((Rx, i) => {
                const k = {};
                k["rowId"] = i;
                k["item"] = Rx;
                k["Drug Name"] = (Rx.drugname + " " + Rx.strength + " " + Rx.drugformname)
                k["NDC"] = this._formatsUtil.getNDCFormat(Rx.ndc);
                k["Manufacturer"] = Rx.manufacturername;
                k["Is Pref?"] = Rx.ispreferred ? "Y" : "N";
                k["Ispreferred"] = Rx.ispreferred;
                k["Isdiscontdate"] = Rx.isdiscontdate;
                k["LastDispensedDate"] = Rx.lastDispensedDate ? Rx.lastDispensedDate : null;
                k["Size"] = Rx.qtypack.toFixed(3);
                k["id"] = Rx.id;
                k["itemFromEquil"] = true;
                k["drugcode"] = (Rx.drugcode) ? Rx.drugcode : null;
                k["AWP"] = Rx.unitpriceawp ? "$" + Rx.unitpriceawp.toFixed(3) : "$0.000";
                k["Stock Qty"] = this._drugBuckUtil.getQtyInHand(Rx.manageinventory, this.BucketId);
                k["isactive"] = Rx.isactive;
                k["Cost"] = Rx.unitpricecost ? "$" + Rx.unitpricecost.toFixed(4) : "$0.0000";
                k["Last Dispensed"] = Rx.dispensedDate ? moment(Rx.dispensedDate).format("MM/DD/YYYY") : "";
                k["Inv?"] = Rx.isinvmangd ? "Y" : "N";
                k["Is 340B?"] = Rx.Is340B ? "Y" : "N";
                k["manageinventory"] = Rx.manageinventory;
                k["drugname"] = (Rx.drugname + " " + (Rx.strength ? Rx.strength : '') + " " + (Rx.drugformname ? Rx.drugformname : ''));
                k["ndc"] = this._formatsUtil.getNDCFormat(Rx.ndc);
                k["Is340B"] = Rx.Is340B ? "Y" : "N";
                k["unitpricecost"] = Rx.unitpricecost ? "$" + Rx.unitpricecost.toFixed(3) : "$0.000";
                k["awppack"] = Rx.unitpriceawp ? "$" + Rx.unitpriceawp.toFixed(3) : "$0.000";
                k["manufacturername"] = Rx.manufacturername;

                return k;
            })
        );
        }
        this.rxHeaders = [
            "Drug Name",
            "NDC",
            "Manufacturer",
            "Size",
            "AWP",
            "Stock Qty",
            "Is Pref?",
            "Cost",
            "Last Dispensed",
            "Inv?",
            "Is 340B?"
        ]

    }

    onRowDblclicked(s: any, e: any) {
        if(!this.isPaidPayRecAvail){
            const drugInfo = s.selectedRows[0].dataItem;
                const is340B =  this._commonServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
                if (drugInfo && drugInfo.Isdiscontdate === "YES" && this.equlDrugPopType && (this.equlDrugPopType === 'Disc' || this.equlDrugPopType === 'inactive' || this.equlDrugPopType === 'QuickInactive' || this.equlDrugPopType === 'notPref')) {
                    this._alertService.error("Discontinued Drug cannot be selected.");
                    return;
                }
                if (this.rxFG && this.rxFG.PrescReFill && this.rxFG.PrescReFill.Is340B && this._drugBuckUtil.getInvSetForPatPrescDrg("Force_340B_NDC_for_340B_Rx") && drugInfo && !drugInfo.Is340B && is340B) {
                    this._alertService.error("Non-340B Drug cannot be selected for 340B Rx.");
                    return;
                }
                if (this.activeModal) {
                    this.activeModal.close();
                }
                     this.OptedDrugInfo.emit(drugInfo);
                this._rxStore.storeGenrcEquvlntDrugs(null);
        }
    }


    init(flex: wjcGrid.FlexGrid) {
        flex.refresh();
        flex.columnHeaders.rows[0].height = 42;
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.focus();
        this.genericGrid = flex;
    }
   
    closepopmodal(type?: any) {
        if (this.activeModal) {
            this.activeModal.close();
        }
        if(this.equlDrugPopType && this.equlDrugPopType === 'Disc' && this.equivalentDrugWJ && this.equivalentDrugWJ.items && this.equivalentDrugWJ.items.length == 0){
            this.emitValuesForeRx.emit(type);  
        } else{
            this.CloseAdminRxPopUp.emit(type);
        }
    }

    closeEquavalentDrgsPoup() {
        this._rxStore.storeGenrcEquvlntDrugs(null);
        this.equivalentDrugWJ = null;
        this.CloseAdminRxPopUp.emit(null);
    }

    drugFieldFocus() {
        setTimeout(() => {
            if (document.getElementById("DrugNameMMS"))
               document.getElementById("DrugNameMMS").focus();
          }, 0);
    }

    getQtyInHand(manageInventory: any): any {
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId === +this._drugBuckUtil.getDefaultBucketId()) || null;
            return isExist ? isExist["QtyInHand"].toFixed(3) : "0.000";
        } else {
            return "0.000";
        }
    }

    selectedRxGen(isChecked: boolean, checkedVal: any, type: any) {
        if (isChecked) {
            this.typeofDrug = type;
            this.selectedItem = checkedVal;
            this.checkedBrandDrug = this.checkedBrandDrug ? null : this.checkedBrandDrug;
            this.checkedGenDrug =
                (checkedVal && checkedVal["id"]) ? checkedVal["id"] : null;
        }
    }

    selectedRxBrand(isChecked: boolean, checkedVal: any, type: any) {
        if (isChecked) {
            this.typeofDrug = type;
            this.selectedItem = checkedVal;
            this.checkedGenDrug = this.checkedGenDrug ? null : this.checkedGenDrug;
            this.checkedBrandDrug = (checkedVal && checkedVal["id"]) ? checkedVal["id"] : null;
        }
    }
    markAsPref() {
        this.DrugList = [];
        this.PrefDrugStng = this._commonServ.getSetttingValue(
            "DrugSettings", "ONE_PREF_DRUG_PER_CLASS");
        if (this.selectedItem) {
            if (!this.selectedItem.Ispreferred) {
                if (this.typeofDrug === "gen") {
                    this.genricDrugList.map((item: any) => {
                        if (item.id === this.selectedItem.id) {
                            this.payLoad = item;
                        }
                    });
                } else {
                    this.brandedDrugList.map((item: any) => {
                        if (item.id === this.selectedItem.id) {
                            this.payLoad = item;
                        }
                    });
                }
                this.equivalentDrugWJ.items.map((item) => {
                    if (item && item.ispreferred) {
                        const name =
                            (item.drugname
                                ? item.drugname.trim().toUpperCase()
                                : "") +
                            " " +
                            (item.strength
                                ? item.strength.trim().toUpperCase()
                                : "") +
                            " " +
                            (item.drugformname
                                ? item.drugformname.trim().toUpperCase()
                                : "") +
                            " - " +
                            this._formatsUtil.getNDCFormat(item?.ndc);
                        this.DrugList.push(name);
                    }
                });
                this.selectedDrugItem =
                (this.payLoad &&
                    (this.payLoad.drugname
                        ? this.payLoad.drugname.trim().toUpperCase()
                        : "") +
                    " " +
                    (this.payLoad.strength
                        ? this.payLoad.strength.trim().toUpperCase()
                        : "") +
                    " " +
                    (this.payLoad.drugformname
                        ? this.payLoad.drugformname.trim().toUpperCase()
                        : "") +
                    " - " +
                    (this.payLoad.ndc
                        ? this._formatsUtil.getNDCFormat(this.payLoad.ndc).toUpperCase(): null));
                    // (this.payLoad.ndc
                    //     ? this.payLoad.ndc.substr(0, 5) +
                    //       "-" +
                    //       this.payLoad.ndc.substr(5, 4) +
                    //       "-" +
                    //       this.payLoad.ndc.substr(9, 11)
                    //     : null));
                this.modelRef = this._modalServ.open(this.MarkAsPrefDrug, {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content",
                });
            } else {
                this._alertService.error(
                    "Selected Drug is already marked as Preferred."
                );
            }
        } else {
            this._alertService.error("Please select atleast one Drug.");
        }
    }

    continueMarkPref() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        const Branded = [], Genric = [];
        this._drugServc.getUpdatePrefDrug(this.payLoad)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp) => {
            if (resp && resp.length > 0) {
                this.equivalentDrugWJ.items.map((item: any) => {
                    resp.map((data: any) => {
                        if (item.id === data.DrugId) {
                            item.ispreferred = data.IsPreferred;
                        }
                    });
                    if (item["isbranded"] === true) {
                        Branded.push(item);
                    } else {
                        Genric.push(item);
                    }
                });
                this.generateWJBrand(Branded);
                this.generateWJGeneric(Genric);
                this.selectedItem = null;
                this.checkedGenDrug = null;
                this.checkedBrandDrug = null;
            }
        });
    }

    cancelMarkPref() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }
    closepopmodalForeRx(type) {
        if(type === 'IGNORE' || type === 'Cancel') {
                this.cancelMarkPref();
               this.emitValuesForeRx.emit(type);
        } else  if(["QuickInactive","Disc"].includes(this.equlDrugPopType)) {
            if (this.activeModal) {
                this.activeModal.close();
            }
            this._rxStore.storeGenrcEquvlntDrugs(null);
            this.OptedDrugInfo.emit(this.selectedDrugInfo);
        } else {
            this.emitValuesForeRx.emit(this.selectedItem["item"]);
        }
    }
    onRowDblclicked1(s: any, e: any) {
        if(!this.isPaidPayRecAvail){
            const drugInfo = s.selectedRows[0].dataItem;
        const is340B =  this._commonServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        if (drugInfo && drugInfo.Isdiscontdate === "YES" && this.equlDrugPopType && (this.equlDrugPopType === 'Disc' || this.equlDrugPopType === 'inactive' || this.equlDrugPopType === 'QuickInactive' || this.equlDrugPopType === 'notPref')) {
            this._alertService.error("Discontinued Drug cannot be selected.");
            return;
        }
        if (this.rxFG && this.rxFG.PrescReFill && this.rxFG.PrescReFill.Is340B && this._drugBuckUtil.getInvSetForPatPrescDrg("Force_340B_NDC_for_340B_Rx") && drugInfo && !drugInfo.Is340B && is340B) {
            this._alertService.error("Non-340B Drug cannot be selected for 340B Rx.");
            return;
        }
        this.selectedDrugInfo = drugInfo;
        }
    }
    createFG() {
        this.refreshFG = this._fb.group({ DrugName: null, NDCCode: null, QuickCode: null });
    }
    initBrand(flex: wjcGrid.FlexGrid) {
        this.genericGrid = flex;
    }
    async getDrugMMS() {
        let drugName = this.refreshFG.value['DrugName'];
        if (drugName) {
            drugName = drugName ? ((drugName + '').toLowerCase()) : "";
            this.brandedDrugList = this.brandedDrugListCopy.filter((item: any) => {
                item["strength"] = item["strength"] ? ((item["strength"] + '').toLowerCase()) : "";
                item["drugformname"] = item["drugformname"] ? ((item["drugformname"] + '').toLowerCase()) : "";
                item["drugname"] = item["drugname"] ? ((item["drugname"] + '').toLowerCase()) : "";
                const orginalDrugName = item["drugname"] + " " + item["strength"] + " " + item["drugformname"];
                return (item["drugcode"].includes(drugName) || item["strength"].includes(drugName) || item["drugformname"].includes(drugName) ||
                    item["drugname"].includes(drugName) || orginalDrugName.includes(drugName));
            });

            this.genricDrugList = this.genricDrugListCopy.filter((item: any) => {
                item["strength"] = item["strength"] ? ((item["strength"] + '').toLowerCase()) : "";
                item["drugformname"] = item["drugformname"] ? ((item["drugformname"] + '').toLowerCase()) : "";
                item["drugname"] = item["drugname"] ? ((item["drugname"] + '').toLowerCase()) : "";
                const orginalDrugName = item["drugname"] + " " + item["strength"] + " " + item["drugformname"];
                return (item["drugcode"].includes(drugName) || item["strength"].includes(drugName) || item["drugformname"].includes(drugName) ||
                    item["drugname"].includes(drugName) || orginalDrugName.includes(drugName));

            });
            this.generateWJBrand(this.brandedDrugList);
            this.generateWJGeneric(this.genricDrugList);

        } else
            this.clearFilters();

    }
    
    focusToMMsData(event) {
        if (this.brandedDrugListCopy && this.brandedDrugListCopy.length) {
            event.preventDefault();
            this.branchGrid.focus();
        } else {
            event.preventDefault();
            this.genericGrid.focus();
        }
    }
    clearFilters() {
        this.refreshFG.reset();
        this.createFG();
        this.generateWJBrand(this.brandedDrugListCopy);
        this.generateWJGeneric(this.genricDrugListCopy)
    }

    getFocusVal(){
        return (this.selectedItem) && ((this.equlDrugPopType && (this.equlDrugPopType === 'eRx') || (this.isFrom == 'nDrug')) && (this.equlDrugPopType !== 'popup')) || (this.equlDrugPopType == 'QuickInactive' && this.selectedDrugInfo)
    }
    toggleColorLegend() {
        this._alertService.showHelpText({RxStatus: false, DrugName: false, Status: false, DrugForDrugFile: true});
    }

}
