
<div class="row">
    <div class="col-6 pb-2 offset-3" *ngIf="nameRequired">
        <eprx-input [LabelText]="'Title'" [PlaceHolder]="'Title'" [ControlName]="nameControlName" [FormGroupName]="nameFG"
            [IsRequired]="true" [ErrorDefs] [MarkAsTouched]="nameFG.get(nameControlName).touched"
            [InputErrors]="nameFG.get(nameControlName).errors" [ErrorDefs]="{required: 'Required'}" [TabIndex]="-1">
        </eprx-input>
    </div>
    <div class="col-md-11" *ngIf="showScanner && enableScanner">
        <app-scanner-util (ScannedFile)="handleFileInput([$event])"></app-scanner-util>
    </div>
    <div class="col-md-12 text-center" *ngIf="showScanner">
        <label>or</label>
    </div>
    <div class="col-md-12" [ngClass]="!alignStart ? 'text-center' : ''">
        <div class="upload-btn-wrapper">
            <button class="btn" (click)="!disableClick ? checkForTitle(uploadDocFile) : ''" [disabled]="disableClick"
            [title]="disableClick ? 'Click on edit icon to perform action' : ''">Upload a file
            <div class="warn-text">Max Size: 10 MB</div>
            </button>
            <input type="file" [tabindex]="-1" #uploadDocFile name="myfile" (click)="$event.target.value = null"
                hidden="true" (change)="handleFileInput($event.target.files)"
                accept="image/png, image/jpeg,image/gif, application/pdf" />
        </div>
    </div>
</div>


