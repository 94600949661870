import { PipeTransform, Pipe } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: "dateFormat" })
export class DateFormatPipe implements PipeTransform {
    transform(value: any): any {
        let dateVal = null;
        if (value) {
            dateVal = moment(value).format("MM/DD/YYYY");
            if (moment(dateVal, "MM/DD/YYYY", true).isValid()) {
                return dateVal;
            } else {
                return null;
            }
        }
        return null;
    }
}
