import { Component, Input, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DME } from 'src/app/models';
import { RxStore } from 'src/app/store';

@Component({
  selector: 'app-dme-popup',
  templateUrl: './dme-popup.component.html'
})
export class DMEPopupComponent implements OnInit {
  dmeFG:FormGroup;
  @Input() rxFG:any;
  @Input() Type:any;
  @Output()
  SaveDMEPopUp = new EventEmitter<any>();
  @Output()
  closepopupModal = new EventEmitter<any>();
  constructor(private _rxStore: RxStore, private _fb: FormBuilder, private _mdlServ: NgbModal) { }
  @ViewChild("DMEPOPUP", { static: true })
    DMEPOPUP: any;
    modalRef:any;
  ngOnInit(): void {
    this.createFG();
    this.displayingDMEpopup()
  }

 createFG(){
     this.dmeFG = this._fb.group(new DME()) as FormGroup;
    }
    displayingDMEpopup(type?){
        this.defaultValuePatching();
        if(this.rxFG && this.rxFG.controls["Drug"]&& this.rxFG.controls["Drug"].value && this.rxFG.controls["Drug"].value.drugtypeid && this.rxFG.controls["Drug"].value.drugtypeid == 5 && this.rxFG.value.rxType && this.rxFG.value.rxType == "nr"){
            this.modalRef = this._mdlServ.open(this.DMEPOPUP, {centered: true,
                keyboard: false, backdrop: false, windowClass: "mode_width_37"
            });
        }
       
    }
    defaultValuePatching(){
       this.dmeFG.patchValue({
        LocatorCode:12,
        ServiceCat:4444,
        ClinicSp:20,
        AccidentCode:2,
        IsHandiChildPg:false,
        IsFamilyPlan:false,
        IsDisability:false,
        IsRental:false
       }) 
    }
    saveDMEpopup(){
      this._rxStore._rxDME$.next(this.dmeFG.value);
      this.SaveDMEPopUp.emit(this.Type);
      if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
      }

    }
    closePopup(){
      if (this.modalRef) {
        this.modalRef.close();
        this.modalRef = null;
        this.closepopupModal.emit(this.Type)
    }
    }
}
