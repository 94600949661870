import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageEditorCropComponent } from '../image-editor-crop/image-editor-crop.component';
import { fabric } from "fabric";
import { DomSanitizer } from "@angular/platform-browser";
import { DocumentsService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-image-editor-ref',
  templateUrl: './image-editor-ref.component.html',
  styleUrls: ['./image-editor-ref.component.scss']
})
export class ImageEditorRefComponent implements OnInit {
    inputImage: any;
    canvasArea: any;
    unsubscribe$: Subject<void> = new Subject();

    @ViewChild(ImageEditorCropComponent)
    imageCropEditor: ImageEditorCropComponent;
    isRotate: boolean = false;

    modelRef: any;
    color = "#000000";
    bgImage: any;
    cropMode = false;
    croppedImage: any;
    ZOOM_FACTOR = 0.5;
    currentMode: any;
    shapesFillColor: any;
    originalImage: any;
    cropInputImage: any;
    isRx: any;
    canvasWidth: any;
    canvasHeight: any;
    canvasImage: any;

    @Input() IsFromRx: any;
    @Input() selectedDocuement: any;
    
    @Input()
    set ImageData(data: any) {
        if (data) {
            this.originalImage = data;
            this.inputImage = data;
            this.cropInputImage = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        }
    }

    @Input()
    set ImageFile(data: any) {
        if (data) {
            this.readsUrl(data);
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    IsRotateDegrees = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer, private _cdr: ChangeDetectorRef,
    private _docService: DocumentsService) { }

    ngOnInit() {
        this.canvasArea = new fabric.Canvas("canvasArea", { selection: false });
        fabric.Object.prototype.set({
            transparentCorners: false,
            borderColor: "#0000c5",
            cornerColor: "#ff0000",
            cornerStyle: "circle",

        });
        this.setBackgroundImageToCanvas(this.inputImage);
    }
    readsUrl(event: any) {
        if (event) {
            let reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                let base64string = (<FileReader>event.target).result.toString();
                this.originalImage = base64string;
                this.inputImage = this.originalImage;
                this.setBackgroundImageToCanvas(this.inputImage);
            };
            reader.readAsDataURL(event);
        }
    }

    setBackgroundImageToCanvas(image) {
        if (image) {
            fabric.Image.fromURL(image, function (myImg) {
                let canvasParent = document.getElementById("canvasDf");
                let canvasWidth = canvasParent.offsetWidth;
                let img1 = myImg.set({ left: 0, top: 0 });
                img1.scaleToHeight((myImg.height * (canvasWidth / myImg.width)));
                this.canvasWidth = myImg.height;
                this.canvasWidth = myImg.width;
                img1.scaleToWidth(canvasWidth);
                this.bgImage = img1;
                this.canvasImage = this.bgImage;
                this.canvasArea.setWidth(canvasWidth);
                this.canvasArea.setHeight((myImg.height * (canvasWidth / myImg.width)));
                this.canvasArea.renderAll();
                this.addBackgroundImage();
            }.bind(this));
        }
    }

    addBackgroundImage() {
        this.canvasArea.setBackgroundImage(
            this.bgImage,
            this.canvasArea.renderAll.bind(this.canvasArea));
    }

    ngAfterViewInit() {
    }

    modeSelected(mode) {
        this.currentMode = mode;
        switch (mode) {
            case "pointer":
                {
                    this.exitCropMode();
                    this.canvasArea.isDrawingMode = false;
                    break;
                }
            case "crop":
                {
                    this.enterCropMode();
                    break;
                }
            case "pencil":
                {
                    this.drawPencil();
                    break;
                }
            case "brush":
                {
                    this.drawBrush();
                    break;
                }
            case "text":
                {
                    this.addText();
                    break;
                }
            case "undo":
                {
                    this.undoForSlectedCanvasObject();
                    this.canvasArea.isDrawingMode = false;
                    break;
                }
            case "shapes":
                {
                    this.exitCropMode();
                    this.canvasArea.isDrawingMode = false;
                    break;
                }
            case "zoomin":
                {
                    this.zoomin();
                    break;
                }
            case "zoomout":
                {
                    this.zoomOut();
                    break;
                }
            case "rotateRight":
                {
                    this.rotateImage(true);
                    break;
            }
            case "rotateLeft":
            {
                    this.rotateImage(false);
                break;
            }
            default:
                {
                    this.canvasArea.isDrawingMode = false;
                    break;
                }
        }

    }
    undoForSlectedCanvasObject() {
        this.canvasArea.getActiveObjects().forEach((obj) => {
            this.canvasArea.remove(obj);
          });
          this.canvasArea.discardActiveObject().renderAll();
    }
    addText() {
        this.exitCropMode();
        this.canvasArea.isDrawingMode = false;
        const text = new fabric.Textbox("Text Here...", {
            fontSize: 24,
            fontWeight: "normal",
            editable: true,
            lockScalingY: false,
            lockUniScaling: false,
            fontFamily: "Arial",
            fill: this.color,
            left: 50,
            top: 50,
            width: 200
        });
        this.canvasArea.add(text);
        this.canvasArea.setActiveObject(text);
        text.enterEditing();
        text.selectAll();
    }


    drawPencil() {
        this.exitCropMode();
        this.canvasArea.isDrawingMode = 1;
        this.canvasArea.freeDrawingBrush.color = this.color;
        this.canvasArea.freeDrawingBrush.width = 3;
        this.canvasArea.renderAll();
    }

    drawBrush() {
        this.exitCropMode();
        this.canvasArea.isDrawingMode = 1;
        this.canvasArea.freeDrawingBrush.color = this.color;
        this.canvasArea.freeDrawingBrush.width = 10;
        this.canvasArea.renderAll();
    }

    closeCrop(croppedImage) {
        if (croppedImage) {
            this.inputImage = croppedImage;
            this.setBackgroundImageToCanvas(this.inputImage);
            this.exitCropMode();
        } else {
            this.exitCropMode();
        }
    }

    enterCropMode() {
        this.cropInputImage = this.canvasArea.toDataURL();
        this.cropMode = true;
    }

    exitCropMode() {
        this.cropMode = false;
    }

    zoomin() {
        this.enterCropMode();
        setTimeout(() => {
            if (this.imageCropEditor) {
                this.imageCropEditor.zoomin();
            }
        }, 50);
    }

    zoomOut() {
        this.enterCropMode();
        setTimeout(() => {
            if (this.imageCropEditor) {
                this.imageCropEditor.zoomOut();
            }
        }, 50);
    }

    rotateImage(isRotateRight) {
        this.isRotate = !this.isRotate;
        this._cdr.detectChanges();
        let inputImage: any = this.inputImage;
        if (inputImage && inputImage.split(",") && inputImage.split(",").length > 1) {
            this.inputImage =
                this._docService.rotateDocuments({
                    base64String: inputImage.split(",")[1],
                    degree: this.isRotate ? -180 : 180
                })
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                    this.inputImage = "data:application/png;base64," + res;
                    this.cropInputImage = this.canvasArea.toDataURL();
                    this.setBackgroundImageToCanvas(this.inputImage);
                });
        } 

    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    colorChange(color) {
        this.color = color;
        let activeObject = this.canvasArea.getActiveObject();
        if (color && activeObject != null) {
            if (this.currentMode === "shapes") {
                activeObject.set("stroke", color);
            } else if (this.currentMode === "pencil" || this.currentMode === "brush") {
                this.canvasArea.freeDrawingBrush.color = this.color;
            } else {
                activeObject.set("fill", color);
            }
            this.canvasArea.renderAll();
        }
    }

    fillColor(color) {
        let activeObject = this.canvasArea.getActiveObject();
        this.shapesFillColor = color;
        if (this.shapesFillColor && activeObject != null) {
            activeObject.set("fill", this.shapesFillColor);
            this.canvasArea.renderAll();
        }
    }

    changeOpacity(value) {
        let activeObject = this.canvasArea.getActiveObject();
        if (value && activeObject != null) {
            activeObject.set("opacity", value);
            this.canvasArea.renderAll();
        }
    }

    insertShape(type) {
        this.exitCropMode();
        if (type === "line") {
            const horizantal = new fabric.Line([1, 1, 100, 1], {
                stroke: this.color,
                left: 50,
                top: 50
            });
            this.canvasArea.add(horizantal);
            this.canvasArea.setActiveObject(horizantal);
        } else if (type === "circle") {
            const circle = new fabric.Circle({
                radius: 50,
                // fill: this.color,
                fill:"",
                stroke: this.color,
                strokeWidth: 3,
                left: 70,
                top: 70
            });
            this.canvasArea.add(circle);
            this.canvasArea.setActiveObject(circle);
        } else if (type === "rect") {
            const rect = new fabric.Rect({
                width: 100,
                height: 100,
                // fill: this.color
                fill:"",
                stroke: this.color,
                strokeWidth: 3,
                left: 20,
                top: 100
            });
            this.canvasArea.add(rect);
            this.canvasArea.setActiveObject(rect);
        } else if (type === "triangle") {
            const triangle = new fabric.Triangle({
                width: 100,
                height: 100,
                // fill: this.color
                fill:"",
                stroke: this.color,
                strokeWidth: 3,
                left: 60,
                top: 80
              });
            this.canvasArea.add(triangle);
            this.canvasArea.setActiveObject(triangle);
        }
    }

    saveImage() {
        
          let editedImage = this.canvasArea.toDataURL();
         this.IsPopUpClosed.emit(editedImage);
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }


}
