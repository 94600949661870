import { BaseUtils } from "src/app/utils/base.utils";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    HostListener
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    AlertService,
    RphVerificationService,
    CommonService,
    PatPreDrugModalService,
    RxService
} from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxPatientModel, SystemSetting, AccCategoryE, RxE } from "src/app/models";
import { RxUtils, NRxUtils } from "src/app/utils";
import * as moment from "moment";
import { PrivMaskGuard } from "src/app/guards";
import { CommonStore, RxStore } from "src/app/store";
import { AuditLogUtils } from "src/app/utils/audit-log.util";
import { VerificationTabsListComponent } from "../../verification-tabs/verification-tabs-list/verification-tabs-list.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-action-buttons",
    templateUrl: "./action-buttons.component.html"
})
export class ActionButtonsComponent implements OnInit {
    rxInfo: any;
    IsFollowup = false;
    rphFilters: any;
    IsHistory = false;
    verifiedAlready: any;
    buttonType: string = null;
    systemStng: SystemSetting;
    modalRef: any;
    accessPrivCate: any;
    rxPrivs: any;
    privType: any;
    categoryId: any;
    isRxVerification: boolean ;
    openOverride: boolean;
    showMarkAsVerif=false;
    model: any;
    beforeRph: number;
    // orginal: number;
    reqRxVerfStng: number;
    hasPrescOvrRidPrv: any;
    hasPatOvrRidPrv: any;
    overrideFor: any;
    overrideEndpoint: any;
    savedValue: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
    }

    @Input()
    set IsRxVerification(data: any) {
        this.isRxVerification = data;
    }

    @Input()
    set RphFilters(data: any) {
        this.rphFilters = data;
    }

    @Input()
    set VerifyRxNumber(rxNo: any) {
        if (rxNo != null) {
            this.getRxDetails(rxNo);
        }
    }

    @Input() totalRecordsCount: any;
    @Output()
    RefreshGrid = new EventEmitter<Boolean>();

    @ViewChild("AlreadyVerif", { static: true })
    AlreadyVerif: any;

    @ViewChild("AlreadyVerifFollow", { static: true })
    AlreadyVerifFollow: any;

    @ViewChild("RxDiscontinued", { static: true})
    RxDiscontinued: any;

    activeModal: string;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 86) { // v
                event.preventDefault();
                this.checkVerifications("RxVerify");
            } else if (event.which === 77) { // m
                event.preventDefault();
                this.checkVerifications("MarkAsVerif");
            } else if (event.which === 85) { // u
                event.preventDefault();
                this.checkVerifications("FollowUp");
            // } else if (event.which === 69) { // e
            //     event.preventDefault();
            //     this.routeEditRx();
            // } else if (event.which === 84) { // t
            //     event.preventDefault();
            //     this.routeToPatientHistory();
            } else if (event.which === 72) { // h
                event.preventDefault();
                this.openHistoryPopUp();
            } else if (event.which === 80) { // p
                event.preventDefault();
                this.openActionModal("EditPatient");
            } else if (event.which === 82) { // r
                event.preventDefault();
                this.openActionModal("EditPrescriber");
            } else if (event.which === 78) { // n
                event.preventDefault();
                this.openActionModal("Notes");
            } else if (event.which === 73) { // i
                event.preventDefault();
                this.openActionModal("TransResult");
            }
        } else if (event.which === 27 && this.modalRef && this._modalService.hasOpenModals()) {
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }
    }

    constructor(
        private _actvRoute: ActivatedRoute,
        private _alertService: AlertService,
        private _modalService: NgbModal,
        private _rxUtils: RxUtils,
        private _privMaskGuard: PrivMaskGuard,
        private _router: Router,
        private _rphService: RphVerificationService,
        public _commonSrv: CommonService,
        private _patPreDrugService: PatPreDrugModalService,
        private _nrxUtils: NRxUtils,
        private _cmnStore: CommonStore,
        private _rxService:RxService,
        private _rxStore: RxStore,
        private _auditUtils: AuditLogUtils,
    ) {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
    }

    ngOnInit() {
        this.hasPrescOvrRidPrv  = this._nrxUtils.getPrscrbrOverRidePriv();
        this.hasPatOvrRidPrv  = this._nrxUtils.getPatientOverRidePriv();
        this._commonSrv.systemSettings$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemStng = resp;
        });
        this.showMarkAsVerif=this._commonSrv.getSetttingValue("WorkFlowSettings", "Mark_Verify_by_the_Pharmacist") === "1" ? true : false;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openActionModal(modalType: string) {
        this.activeModal = modalType;
        this._rxStore.storeRxInfo(this.rxInfo);
        if (this.activeModal === "EditPrescriber") {
            this._patPreDrugService._patPreDrugModal$.next("prescriber");
                const modalRef = this._patPreDrugService.modalInstanceRef;
                modalRef.componentInstance.prescriberID = this.rxInfo.Prescriber.prescriberid;
                modalRef.componentInstance.ClosePrescriberModal
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    modalRef.close();
                    this._patPreDrugService._patPreDrugModal$.next(null);
                    this.emitGridRefresh();
                });
        } else if (this.activeModal === "EditPatient") {
            this._patPreDrugService._patPreDrugModal$.next("patient");
            const modalRef = this._patPreDrugService.modalInstanceRef;
            modalRef.componentInstance.patientID = +this.rxInfo.Patient.patientid;
            modalRef.componentInstance.ClosePatientModal
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                modalRef.close();
                this._patPreDrugService._patPreDrugModal$.next(null);
                this.emitGridRefresh();
            });
        }
    }
    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "Rx") {
                this.routeToRx();
            } else if (this.overrideFor === "PatientFile") {
                this.openActionModal(this.savedValue);
            } else if (this.overrideFor === "PrescriberFile") {
                this.openActionModal(this.savedValue);
            }
            
        }
    }

    openOveride(content, type, item?, endpoint?) {
        this.privType = type;
        this.categoryId = content;
        this.openOverride = true;
        this.overrideFor = content;
        this.savedValue = item;
        this.overrideEndpoint = endpoint;
    }

    routeEditRx() {
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.Rx,
            this.rxPrivs.EditRx)) {
                this.routeToRx();
            } else {
                this.openOveride("Rx", "EditRx", null, 'PUT_Rx');
            }
    }

    async routeToRx(rxNo?: any, rfId?: any) {
        const selectedrxNo = rxNo ? rxNo : +this.rxInfo.Prescription.PrescNum;
        const selectedrxId = rfId ? rfId : this.rxInfo.PrescReFill.Id;
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            this._patPreDrugService._patPreDrugModal$.next("Rx");
            const modalRef = await this._patPreDrugService.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(x => {
                    modalRef.close();
                    this._patPreDrugService._patPreDrugModal$.next(null);
                    this.emitGridRefresh();
                });
            modalRef.componentInstance.CloseAndReopen
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(d => {
                    modalRef.close();
                    this._patPreDrugService._patPreDrugModal$.next(null);
                    if (d) {
                        this.routeToRx(d["rxNo"], d["rfId"]);
                    } else {
                        this.emitGridRefresh();
                    }
                });
        }
    }

    routeToPatientHistory() {
        this._patPreDrugService._patPreDrugModal$.next("patHist");
        const modalRef = this._patPreDrugService.modalInstanceRef;
        modalRef.componentInstance.patientID = this.rxInfo && this.rxInfo.Patient ? this.rxInfo.Patient.patientid : null;
        modalRef.componentInstance.popUpOpenFr = "rph";
        modalRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(closed => {
            modalRef.close();
            this._patPreDrugService._patPreDrugModal$.next(null);
            this.emitGridRefresh();
        });
    }

    checkVerifications(btnType: string) {
        this.buttonType = btnType;
        // this.orginal = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        // this.beforeRph = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings",
        //  "Rx_Verification_Required_Before_RPh_Verification"), 0);
        this.reqRxVerfStng = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        // const workFlwPatStng = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings","Require_Rx_Verification_for_workflow_patient_only"), 0);
        if (this.rxInfo && (this.rxInfo["PrescReFill"]["StatusId"] === 2 || this.rxInfo["Prescription"]["RxStatusId"] === 2)) {
            this._alertService.error("Selected Rx is Deferred, Cannot be verified");
        } else if (this.rxInfo && (this.rxInfo["PrescReFill"]["StatusId"] === 4 || this.rxInfo["Prescription"]["RxStatusId"] === 4)) {
            this._alertService.error("Selected Rx is Transfered, Cannot be verified");
        //  else if (this.reqRxVerfStng && !this.isRxVerification) {
        //     if (workFlwPatStng) {
        //         if (this.rxInfo.Patient.IsWorkflowPatient && this.orginal && this.beforeRph &&
        //             !(this.rxInfo.rph1VerificationStatus && this.rxInfo.rph1VerificationStatus.toLowerCase() === "r") &&
        //             (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
        //                 this._alertService.error("Rx  verification is required before Rph  verification.");
        //         } else if (this.rxInfo.Patient.IsWorkflowPatient && !(this.orginal) &&
        //             this.beforeRph && !(this.rxInfo.rph1VerificationStatus &&
        //             this.rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
        //                 this._alertService.error("Rx  verification is required before Rph  verification.");
        //         } else {
        //             this.IspharmacyVerified();
        //         }
        //     } else if (this.orginal && this.beforeRph &&
        //         !(this.rxInfo.rph1VerificationStatus && this.rxInfo.rph1VerificationStatus.toLowerCase() === "r") &&
        //         (this.rxInfo && (this.rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
        //         this._alertService.error("Rx  verification is required before Rph  verification.");
        //     } else if (!(this.orginal) && this.beforeRph && !(this.rxInfo.rph1VerificationStatus &&
        //         this.rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
        //             this._alertService.error("Rx  verification is required before Rph  verification.");
        //     }
        }else if (!this.isRxVerification && this.rxInfo && this.rxInfo["RxBill"]["StatusId"] === 1 && (this._commonSrv.getSetttingValue("WorkFlowSettings","ALLOW_RPH_VERF_UNB_RX" ) === "0")) {
            this._alertService.error("Selected Rx is Unbilled, Cannot be verified.");
        } else {
            if (this.isRxVerification) {
                this.IsVerifiedAlready();
            } else {
                this.IspharmacyVerified();
            }
        }
    }

    IspharmacyVerified() {
        this._rphService.IsPharmacyDrugPickVerified()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                    if (this.buttonType === "FollowUp" ) {
                        this.IsVerifiedAlready();
                    } else {
                        this.IsDrugPickVerified();
                    }
            } else {
                this._alertService.error("Pharmacy verification unsuccessful.");
            }
        });
    }

    IsDrugPickVerified() {
        if (
            this._commonSrv.getSetttingValue(
                "WorkFlowSettings",
                "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
            ) === "1"
        ) {
        this._rphService
            .IsDrugPickVerified(this.rxInfo.Prescription.PrescNum ,
                this.rxInfo.PrescReFill.ReFillNum, this.rxInfo.PrescReFill.PartialFillNo)
                .pipe(takeUntil(this.unsubscribe$))
            .subscribe(drugVerified => {
                if (drugVerified) {
                    this.IsVerifiedAlready();
                } else {
                    this._alertService.error(
                        "Drug pick verification is required before pharmacist verification."
                    );
                }
            });
        } else {
            this.IsVerifiedAlready();
        }
    }

    IsVerifiedAlready() {
        this._rphService
            .IsRphVerifiedAlready(
                this.rxInfo.Prescription.Id,
                this.rxInfo.PrescReFill.Id,
                this.isRxVerification
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(verfiRe => {
                if (verfiRe && verfiRe["Status"] && (verfiRe["Status"].trim() === "V" || verfiRe["Status"].trim() === "R")) {
                    if (this.buttonType === "FollowUp") {
                        this.modalRef = this._modalService.open(this.AlreadyVerifFollow, {keyboard: false, backdrop: false,
                             windowClass: "large--content" ,centered : true});
                    } else {
                        this.verifiedAlready = verfiRe;
                        this.modalRef = this._modalService.open(this.AlreadyVerif, { keyboard: false, backdrop: false,
                             windowClass: "large--content" , centered : true });
                    }
                } else {
                    if (this.buttonType === "RxVerify") {
                        if (this.rxInfo && this.rxInfo.Prescription.IsDiscontinued) {
                            this.model = this._modalService.open(this.RxDiscontinued, { centered: true, backdrop: false,
                                 keyboard: false, windowClass: "large--content" });
                        } else {
                            this.checkIsDiscontinue();
                        }
                    } else if (this.buttonType === "MarkAsVerif") {
                        this.verifyRph();
                    } else if (this.buttonType === "FollowUp") {
                        this.openFollowModal();
                    }
                }
            });
    }

    checkIsDiscontinue() {
        if (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.IsDiscontinued) {
            this.model = this._modalService.open(this.RxDiscontinued, { centered: true, backdrop: false, keyboard: false, windowClass:"large--content"});
        } else {
            if (this.buttonType === "RxVerify") {
                this.RxVerifyNavigate();
            } else if (this.buttonType === "MarkAsVerif") {
                this.verifyRph();
            }
        }
    }

    async setOrderStatus() {
        await this._rxService.putRxDiscontinuedOrderStatus(this.rxInfo.Prescription.Id).toPromise();
        if (this.buttonType === "RxVerify") {
            this.RxVerifyNavigate();
        } else if (this.buttonType === "MarkAsVerif") {
            this.verifyRph();
        }
    }

    RxVerifyNavigate() {
        this.rphFilters.alreadyInfoChckd = true;
        this._cmnStore.storeRphFilters(this.rphFilters);
        const type = this.isRxVerification ? "RxVerification" : "RphVerification";
        const modalRefVerfTab = this._modalService.open(VerificationTabsListComponent, {size: "lg",  windowClass: "md-xx-screenWidth comp--modal modal-large modal-center-to-page", backdrop: false});
        modalRefVerfTab.componentInstance.IsRxVerification = this.isRxVerification;
        modalRefVerfTab.componentInstance.RxInfo = this.rxInfo;
        modalRefVerfTab.componentInstance.RxID = this.rxInfo.Prescription.PrescNum ;
        modalRefVerfTab.componentInstance.RefilID = this.rxInfo.PrescReFill.ReFillNum ;
        modalRefVerfTab.componentInstance.FillID = this.rxInfo.PrescReFill.PartialFillNo;
        modalRefVerfTab.componentInstance.RefillNumId = this.rxInfo.PrescReFill.Id;
        modalRefVerfTab.componentInstance.totalRecordsCount = this.totalRecordsCount;
        modalRefVerfTab.componentInstance.activeTabId = this.isRxVerification ? "rxVerfTab" : "rphVerfTab";
        modalRefVerfTab.componentInstance.closeTabs
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((x: any) => {
            modalRefVerfTab.close();
            sessionStorage.removeItem("RphFmDPV");
            this.emitRefreshGrid();
        });
        // this._router.navigate([
        //     "/eprx/" + type + "/" +
        //         this.rxInfo.Prescription.PrescNum +
        //         "/" +
        //         this.rxInfo.PrescReFill.ReFillNum +
        //         "/" +
        //         this.rxInfo.PrescReFill.PartialFillNo +
        //         "/" +
        //         this.totalRecordsCount +
        //         "/verifyRx"
        // ]);
    }

    openFollowModal() {
        this.IsFollowup = true;
    }

    verifyRph() {

        const DatatoPost = {
            PrescId: this.rxInfo.Prescription.Id,
            PrescRefillId: this.rxInfo.PrescReFill.Id,
            VerifDtTm: moment(Date.now()).format("MM-DD-YYYY"),
            Status: this.isRxVerification? "R" : "V",
            RefillNum: this.rxInfo.PrescReFill.ReFillNum,
            PartialfillNum: this.rxInfo["PrescReFill"]["PartialFillNo"],
            PrescNum: this.rxInfo.Prescription.PrescNum,
            SendReadyForPickupAlerts:  this._commonSrv.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
            SendReadyForPickupAlertsWhen: this._commonSrv.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When")
        };
        this._rphService.PostRphVerify(DatatoPost)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.emitRefreshGrid();
                const Verification = this.isRxVerification ? "Rx" : "Rph";
                this._auditUtils.getChangedValues(
                    null,
                    this.isRxVerification ? {"Rx Verified": "Verified"} : {"Rph Verified": "Verified"},
                    (this.isRxVerification) ? "Rx" : "Rph" + " Verification",
                    "Rx",
                    this.rxInfo.PrescReFill.Id
                );
                this._auditUtils.getChangedValues(
                    null,
                    this.isRxVerification ? {"Rx Verification Status": "Pass"} : {"RPH Verification Status": "Pass"},
                    "Rx Verification",
                    "Rx",
                    this.rxInfo.PrescReFill.Id
                );
                this._alertService.success(((this.isRxVerification) ? "Rx" : "Rph") + " verification completed successfully.");
            }
        });
    }

    openHistoryPopUp() {
        this.IsHistory = true;
    }
    closeHist() {
        this.IsHistory = false;
    }
    closeFollowupModal() {
        this.IsFollowup = false;
    }

    emitRefreshGrid() {
        this.RefreshGrid.emit(null);
    }

    getRxDetails(rxNo: any) {
        if (rxNo) {
            let rxNoarr=rxNo.split("-");
            this._rphService
            .getRphVerifyDetails({
                PageNumber: 1,
                PageSize: 1,
                RxId: rxNoarr[0] ? rxNoarr[0] : null,
                RefillNum: rxNoarr[1] ? rxNoarr[1] : null,
                PartialfillNum: rxNoarr[2] ? rxNoarr[2] : null
            }, this.isRxVerification)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.rxInfo = resp.data[0];
                    this.checkVerifications("RxVerify");
                }
            });
        }
    }

    emitGridRefresh(val?: any) {
        this.RefreshGrid.emit(true);
    }
}
