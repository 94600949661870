import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { WijimoUtil } from 'src/app/utils/wijimo.utils';
import * as wjcCore from "@grapecity/wijmo";
import * as moment from "moment";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html'
})
export class InventoryDetailsComponent implements OnInit {
  wjHeaders: any[];
  InventoryListWJ:any;
  @Input() QtyInfo:any;
  totalList = { general: 0, bucket340b: 0 };
  @Output() IsPopupClosed = new EventEmitter<any>();
  totalSum: number;
  unsubscribe$: Subject<void> = new Subject();
  constructor(private _wijimoUtils: WijimoUtil) { 

  }

  ngOnInit() {
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(w => {
      if (w && w["WijmoKey"] && w["WijmoKey"] === "inventoryListwj") {
          this.patchDefaultValHeaders();
      }
  });
  this.checkQtyDetails(this.QtyInfo);
  this.calculateTotalGeneralAnd340bRecords(this.QtyInfo);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("inventoryListwj");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("inventoryListwj", storedWJ);
}


checkQtyDetails(QtyInfo) {
  this.InventoryListWJ = new wjcCore.CollectionView(
    QtyInfo.map((Bucket, i) => {
          const j = {};
          j["Bucket Name"] = Bucket.BucketName ? Bucket.BucketName : "";
          j["Qty"] = Bucket.QtyInHand ? Bucket.QtyInHand.toFixed(3) : "0.000";
          j["Bucket Type"] = Bucket.BucketType ? Bucket.BucketType : "";
          j["Last Vendor"] = Bucket.ContextName ?  Bucket.ContextName :"";
          j["Cost"] =  Bucket.UnitPriceCost ? "$" + ((Bucket.UnitPriceCost).toFixed(3)): "$0.000";
          j["Last Received Date"] = Bucket.ReceiptDtTm ?  moment.utc(Bucket.ReceiptDtTm).local().format(
            "MM/DD/YY hh:mm:ss a"
        ) : "";
          j["Qty On Order"] = Bucket.OrderQty ? Bucket.OrderQty.toFixed(3) : "0.000";
          return j;
      })
  );
}
closeModal(){
  this.IsPopupClosed.emit();
}

calculateTotalGeneralAnd340bRecords(resp) {
  if(resp){
    resp.map((item: any) => {
      if (item.BucketType === "General Bucket") {
          this.totalList.general = (+this.totalList.general) + (+item.QtyInHand);
      }
      if (item.BucketType === "340B Bucket") {
          this.totalList.bucket340b = (+this.totalList.bucket340b) + (+item.QtyInHand);
      }
     });
     this.totalSum = this.totalList.general + this.totalList.bucket340b;
  }
  }
}
