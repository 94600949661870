<ng-template #FILECONFIRMATION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Defer Confirmation </h4>
        <span body>
            <span> Please confirm if you want to Defer this Rx. </span>
        </span>
        <button footer class="hotkey_primary"
        ngbAutofocus (click)="confirmToFile()" (keydown.enter)="confirmToFile($event)" appShortcutKey InputKey="y"><b>Y</b>YES</button>
        <button footer class="hotkey_primary"
        (click)="closeModal()" (keydown.enter)="closeModal($event)" appShortcutKey InputKey="n"><b>N</b>NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #FILE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header *ngIf="(rxInfo?.PrescReFill?.StatusId != 2
        && rxInfo?.Prescription?.RxStatusId != 2)"> Please Enter Defer Due Date </h4>
        <h4 header *ngIf="(rxInfo?.PrescReFill?.StatusId == 2
        || rxInfo?.Prescription?.RxStatusId == 2)"> Fill A Deferred Rx </h4>
        <span body class="overflow_unset">
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Defer Due Date'" [PlaceHolder]="''" [ControlName]="'DueDtTm'"
                        [FormGroupName]="fileFG" [MaxDate]="" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                        [InputErrors]="DueDtTm?.errors" [FormGroupInvalid]="formGrpInvld" [MarkAsTouched]="fileFG?.get('DueDtTm')?.touched"
                        *ngIf="(rxInfo?.PrescReFill?.StatusId != 2
                        && rxInfo?.Prescription?.RxStatusId != 2)">
                    </eprx-date-picker>
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Fill date'" [PlaceHolder]="''" [ControlName]="'DueDtTm'"
                        [FormGroupName]="fileFG" [MaxDate]="" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                        [InputErrors]="DueDtTm?.errors"
                        *ngIf="(rxInfo?.PrescReFill?.StatusId == 2
                        || rxInfo?.Prescription?.RxStatusId == 2)">
                    </eprx-date-picker>
                </div>
            </div>
            <div class="row"  *ngIf="(rxInfo?.PrescReFill?.StatusId != 2
            && rxInfo?.Prescription?.RxStatusId != 2)">
                <div class="col padding--right__7">
                    <eprx-select
                    [LabelText]="'Deferred Reason'"
                    [PlaceHolder]="'Deferred Reason'"
                    [Title]="'Deferred Reason'"
                    [HasMultiple]="false"
                    [HasBindLabel2]="false"
                    [BindLabel]="'Name'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Name'"
                    [FormGroupName]="fileFG"
                    [ControlName]="'DeferredReason'"
                    [secondCntrlN]="'DeferredReasonName'"
                    [bindScnCntrlV]="'Name'"
                    [List]="deferredReasonList">
                    </eprx-select>
                </div>
            </div>
            <div *ngIf="(rxInfo?.PrescReFill?.StatusId == 2
            || rxInfo?.Prescription?.RxStatusId == 2) && rxInfo?.PrescReFill?.ReFillNum === 0">
                Assign New
                <form class="form-group custom--input was-validated">
                    <span class="inline-flex">
                        <div class="custom-control custom-radio">
                            <input type="radio"
                            class="custom-control-input"
                            id="s1"
                            name="newOrOld"
                            (change)="createNewFiledRx(true)"
                            >
                            <label class="custom-control-label" for="s1">
                                    Yes
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input type="radio"
                            class="custom-control-input"
                            id="n1"
                            name="newOrOld"
                            (change)="createNewFiledRx(false)"
                            checked
                            >
                            <label class="custom-control-label" for="n1">
                                    No
                            </label>
                        </div>
                    </span>
                </form>
            </div>
        </span>
        <button footer class="inactive" ngbAutofocus *ngIf="!fileFG?.valid">   <span>S</span> Save </button>
        <button footer class="hotkey_success" ngbAutofocus (keydown.enter)="checkForReverification()"
        (click)="checkForReverification()" *ngIf="fileFG?.valid" appShortcutKey [AltKey]="'s'">
            <span>S</span> Save </button>
        <button footer class="hotkey_success" (click)="closeModal()"
        (keydown.enter)="closeModal($event)" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>

<ng-template #UnbilledRxError let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>Rx#: {{rxInfo?.Prescription?.PrescNum }}-{{ rxInfo?.PrescReFill?.ReFillNum }}{{_commonService.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription) ? '-' + rxInfo?.PrescReFill?.PartialFillNo : ""}}</div>
        <p>Billed Rx(s) or Rx(s) with paid pay records cannot be Deferred.</p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="o"
            (click)="closeModalSM()"
            (keydown.enter)="closeModalSM($event)"
        >
            <b>O</b> OK
        </button>
    </div>
</ng-template>
