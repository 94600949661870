import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    HostListener
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxService, RxTransferService, TransmissionService, AlertService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";
import { TransmitSearch } from "src/app/models";
import { TRRxOnlyComponent } from "src/app/modules/transmission";
import { WijimoUtil } from "src/app/utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { LocalTimePipe } from "src/app/pipes";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-ins-trans-result",
    templateUrl: "./ins-trans-result.component.html"
})
export class InsTransResultComponent implements OnInit {

    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("transLogs", { static: true })
    transLogs: any;

    modelRef: any;
    rxInfo: any;
    transLogsInfo: any;
    rxTransmissionWJ: any;
    hasLogs = false;
    transmitParams = new TransmitSearch();
    transmitResponse: any;
    isTransmission: boolean;
    activeModal: string;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    rxTrLogWijmo: wjcGrid.FlexGrid;
    unsubscribe$: Subject<void> = new Subject();

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (
            event.which === 27 &&
            this.modelRef &&
            this._modalService.hasOpenModals()
        ) {
            // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }
    }

    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _rxTrnsfrSvc: RxTransferService,
        private _alertService: AlertService,
        private _transmissionServ: TransmissionService,
        private _wijimoUtils: WijimoUtil,
        private _formatsUtil:FormatsUtil,
        private localTimePipe: LocalTimePipe
    ) {
        config.backdrop = "static";
    }

    ngOnInit() {
        this.getTransmissionLogs();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "rxTransmissionLogWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    getTransmissionLogs() {
        this._rxTrnsfrSvc
            .getRXTransmissionLog(this.rxInfo.PrescReFill.Id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.length > 0) {
                    this.hasLogs = true;
                    this.transLogsInfo = resp;
                    if (this.transLogsInfo.length > 1) {
                        this.generateLogsWJ(resp);
                        this.modelRef = this._modalService.open(this.transLogs, {
                            size: "lg",
                            centered: true,
                            backdrop: false,
                            windowClass: "transmsg--modal",
                            keyboard: false
                        });
                        this.activeModal = "TRANSLOG";
                    } else {
                        this.showTransmitLog(this.transLogsInfo[0]);
                    }
                } else {
                    this._alertService.error("There is no Transmission Message to be displayed for this Rx.");
                    this.IsPopUpClosed.emit(null);
                }
            });
    }

    generateLogsWJ(rxLogs) {
        this.rxTransmissionWJ = new CollectionView(
            rxLogs.map((val: any, i) =>{
                const k = {};
                k['Id'] = val.Id;
                k["Rx#"] = val.PrescNum ? val.PrescNum : "";
                k["Ref#"] = val.RefillNum;
                k["Fill#"] = (val.FillNo && val.FillNo > 0) ? val.FillNo : " ";
                k["Result"] = val.Trans_Result ? val.Trans_Result : "";
                k["Transm. Date"] = val.BilledDate ? moment(val.BilledDate).format("MM/DD/YYYY") : "";
                k["Ins. Code"] = val.Ins_Code ? val.Ins_Code : "";
                k["Trans."] = val.TransactionType ? val.TransactionType : "";
                k["User"] = val.UserName ? val.UserName : "";
                // k["Drug NDC"] = (val && val.DrugNDC) ? (val.DrugNDC.substr(0, 5)) + "-" +
                // (val.DrugNDC.substr(5, 4)) + "-" + (val.DrugNDC.substr(9, 11)) : "";
                k["Drug NDC"] = this._formatsUtil.getNDCFormat(val?.DrugNDC);
                k["Ins_Code"] = val.Ins_Code ? val.Ins_Code : "";
                k["PrescRefillId"] = val.PrescRefillId ? val.PrescRefillId : "";
                k["TransactionType"] = val.TransactionType ? val.TransactionType : "";
                k["RxId"] = val.RxId ? val.RxId : "";
                k["Trans_Result"] = val.Trans_Result ? val.Trans_Result : "";
                k["ClaimId"] = val.ClaimId ? val.ClaimId : 0;
                return k;
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    
    async showTransmitLog(item) {
        this.transmitParams.Ins_Code = item.Ins_Code;
        this.transmitParams.PrescRefillId = item.PrescRefillId;
        this.transmitParams.TransType = item.TransactionType;
        this.transmitParams.rxId = item.RxId;
        this.transmitParams.patientId = this.rxInfo.Patient.patientid;
        this.transmitParams["claimID"] = (item && item.ClaimId) ? item.ClaimId : 0;
        this.transmitParams["ItemId"] = item.Id;
        this.isTransmission = true;
    }

    completeTranmsns() {
        this.isTransmission = false;
        this.closeModal();
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }

    init(flex: wjcGrid.FlexGrid) {
        this.rxTrLogWijmo = flex;
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("rxTransmissionLogWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("rxTransmissionLogWJ", storedWJ);
    }
}
