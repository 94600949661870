export const TabEnum = {
    Patient1: "RX",
    Patient2: "MISCINFO",
    Patient3: "NOTES",
    Patient4: "RXFILL",
    Patient5: "RXPAY",
    Patient6: "PICKUP",
    Patient7: "DUR",
    Patient8: "WCOM",
    Patient9: "DME",
    Patient10: "TRACKRX",
    Patient11: "CLINICAL",
    Patient12: "MAR",
    Patient13:"DOCUMENTS"
};

export const TabTitles = {
    RX: "Patient1",
    MISCINFO: "Patient2",
    NOTES: "Patient3",
    RXFILL: "Patient4",
    RXPAY: "Patient5",
    PICKUP: "Patient6",
    DUR: "Patient7",
    WCOM: "Patient8",
    DME: "Patient9",
    TRACKRX: "Patient10",
    CLINICAL: "Patient11",
    MAR: "Patient12",
    DOCUMENTS:"Patient13"
};

export const CredentialsHeaderNames = {
    RphVerification:"Rph Verification",
    DrugPickVerification:"Drug Pick Verification",
    RxVerification:"Rx Verification",
    ReviewRph:"Review Rph History",
    ReviewDrugPick:"Review DPV History"
};


// export const TabNames = {}
