import { AlertService } from "./../../../services/alert.service";
import { FileMaintenanceE, RxE } from "./../../../models/access-privs.enum";
import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    HostListener,
    AfterContentChecked
} from "@angular/core";
import {
    KeyboardShortcuts,
    Unlisten,
    CommonService,
    RxService,
    UserService,
    RxObservableService,
    PatPreDrugModalService
} from "../../../services";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Observable  } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { Subscription } from "rxjs/Subscription";

import {
    debounceTime,
    distinctUntilChanged,
    map,
    tap,
    switchMap,
    share,
    takeUntil
} from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    AccCategoryE,
    PatientFileE,
    PrescriberFileE,
    DrugFileE
} from "../../../models";
import { PrivMaskGuard } from "../../../guards";
import { MsgConfig } from "../../..";
import { RxUtils, PrescriberUtil, DrugUtils, NRxUtils } from "src/app/utils";
import { TypeaheadPopupDirective } from "src/app/directives";
import * as moment from "moment";
import "rxjs/add/observable/empty";
import { RxStore, CommonStore } from "src/app/store";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";

@Component({
    selector: 'app-searchbox-global-ref',
    templateUrl: './search-global-ref.component.html'
  })
export class SearchGlobalRefComponent implements OnInit, OnDestroy, OnChanges {
    // keyboardShortcuts: KeyboardShortcuts;
    // unlisten: Unlisten;
    SuggestData: Observable<any[]>;
    option: any;
    selectedValue: any = "Patient";
    listItems = ["Patient", "Prescriber", "Drug", "Rx", "Refill"];
    erxItems = ["Patient", "Prescriber", "Drug"];
    searching = false;
    searchFailed = false;
    hasFilters = true;
    inputDisable: boolean;
    selectDisable: boolean;
    patRouterURL: string;
    presRouterURL: string;
    drugRouterURL: string;
    erxSearch: boolean;
    elements: any;
    value = null;
    debounceTime = 1000;
    modelRef: any;
    selecInfo: string;
    selectConfig = false;
    isAddnew = true;
    placholderText = "Patient";
    inputValue = "";
    searchValue: string;
    isByEnter = false;
    enableBarcode = false;

    @ViewChild("searchBox")
    searchBox: ElementRef;

    @ViewChild("searchIcon")
    searchIcon: ElementRef;

    @ViewChild("instance")
    instance: NgbTypeahead;

    @ViewChild("addPatient", { static: true })
    addPatient: any;

    @ViewChild("addPrescriber", { static: true })
    addPrescriber: any;

    @ViewChild("addDrug", { static: true })
    addDrug: any;

    @ViewChild("addRx", { static: true })
    addRx: any;

    @ViewChild("DefaultActionp", { static: true })
    DefaultActionp: any;

    @ViewChild("DefaultActionpr", { static: true })
    DefaultActionpr: any;

    @ViewChild("DefaultActiond", { static: true })
    DefaultActiond: any;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    disableSerchFil: boolean;
    disableRadioButtons: boolean;
    isElementFocused: boolean;
    showPatName: boolean;
    accessPrivCate: any;
    patientPrivs: any;
    drugPrivs: any;
    prescriberPrivs: any;
    filePrivs: any;
    showPrescName: boolean;
    showDrugName: boolean;
    defualtPrescId: number;
    defualtDrugId: number;
    tabIndex: number;
    rxID: any;
    actModal = "";
    openOverride: boolean;
    categoryId: string;
    privType: any;
    overrideFor: any;
    isFrmErx = false;
    isFrmRx: any;
    EqDrugs: any[];
    todayDate: any;
    showEqDrugs: boolean;
    selecteEqDrugRowId = 0;
    rxPrivs: any;
    selectedPatInfo: any;
    overrideEndpoint: any;
    pageName: any;
    hasAddPatPrivs: boolean;
    hasAddprescPrivs: boolean;
    hasAddDrugPrivs: boolean;
    showlgList: any;
    allowToAddNewDrug = true;
    notToClearSession: boolean;
    searchFrmPage: string;
    eqDrugHeading: string;
    addModalSubscription: any;
    searchTextValue= "";
    @Input() restricSearch: any;
    isFetchingBarcode: boolean;
    suggestList: any[]=[];
    showSuggest: boolean;
    selectedSuggestRowId: number;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set HasFilters(hf: boolean) {
        this.hasFilters = hf;
    }
    @Input()
    set selectdisabled(dis: boolean) {
        this.selectDisable = dis;
    }

    @Input()
    set inputdisabled(dis: boolean) {
        this.inputDisable = dis;
    }

    @Input()
    set SearchFor(sf: string) {
        this.selectedValue = sf;
        this.option = sf;
        this.placholderText = sf;
    }

    @Input()
    set SearchFrm(sf: string) {
        this.searchFrmPage = sf;
    }

    @Input()
    set PatRouterURL(ru: string) {
        this.patRouterURL = ru;
    }

    @Input()
    set PreRouterURL(ru: string) {
        this.presRouterURL = ru;
    }

    @Input()
    set NotToClearSesion(data: boolean) {
        if (data) {
            this.notToClearSession = true;
        } else {
            this.notToClearSession = false;
        }
    }

    @Input()
    set DrugRouterURL(ru: string) {
        this.drugRouterURL = ru;
    }

    @Input()
    set ErxSearch(erx: boolean) {
        this.erxSearch = erx;
    }

    @Input()
    set DisableSerchFil(dp: boolean) {
        this.disableSerchFil = dp;
    }

    @Input()
    set DisableRadioButtons(ds: boolean) {
        this.disableRadioButtons = ds;
    }

    @Input()
    set DebounceTime(dp: number) {
        this.debounceTime = dp;
    }

    @Input()
    set IsAddNew(iad: boolean) {
        this.isAddnew = iad;
    }

    @Input()
    set IsFocused(isfoc: boolean) {
        this.isElementFocused = isfoc;
    }

    @Input()
    set InputValue(iv: string) {
        if (iv) {
            this.inputValue = iv;
        }
    }

    @Input()
    set ClearInputValue(iv: any) {
        if (iv === true) {
            this.inputValue = null;
        } else if (iv !== false) {
            this.inputValue = iv;
        }
    }

    @Input()
    set ShowLgList(data: any) {
        if (data) {
            this.showlgList = data;
        } else {
            this.showlgList = false;
        }
    }

    @Input()
    set ShowOnlyPatName(patName: boolean) {
        this.showPatName = patName;
    }

    @Input()
    set ShowOnlyPrescName(prescName: boolean) {
        this.showPrescName = prescName;
    }

    @Input()
    set ShowOnlyDrugName(drugName: boolean) {
        this.showDrugName = drugName;
    }

    @Input()
    set DefualtPrescId(prescId: number) {
        this.defualtPrescId = +prescId;
    }

    @Input()
    set DefualtDrugId(drugId: number) {
        this.defualtDrugId = +drugId;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }

    @Input()
    set IsFromERX(ie: any) {
        this.isFrmErx = ie;
    }

    @Input()
    set IsFromRx(data: any) {
        this.isFrmRx = data;
    }

    @Input()
    set ParentPage(data: any) {
        this.pageName = data;
    }

    @Input()
    set AllowToAddNewDrug(data: any) {
        this.allowToAddNewDrug = data;
    }

    @Input() emitIfNotFound: boolean;
    @Input() searchID: boolean;

    
    @Input()
    set EnableBarcode(hf: boolean) {
        this.enableBarcode = true;
    }

    @Output()
    SelectedPatient = new EventEmitter();

    @Output()
    SelectedPrescriber = new EventEmitter();

    @Output()
    SelectedDrug = new EventEmitter();

    @Output()
    OptedType = new EventEmitter();

    @Output()
    OnEnter = new EventEmitter();

    @Output()
    FocusedOutofSearch = new EventEmitter();

    @Output()
    OptedFormAddNew = new EventEmitter();

    @Output()
    clearedInput = new EventEmitter();

    @Output() emitRestrictionSucess = new EventEmitter();

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            if (
                this.actModal === "addPatient" ||
                this.actModal === "addPrescriber" ||
                this.actModal === "addDrug" ||
                this.actModal === "addRx"
            ) {
                event.preventDefault();
                this.closeModal();
                this.actModal = null;
            }
        } else if (event.keyCode === 13) {
            if (this.showEqDrugs && this.EqDrugs && this.EqDrugs.length) {
                this.selectedEqDrug(this.EqDrugs[this.selecteEqDrugRowId]);
            } else if (this.suggestList && this.suggestList.length > 0) {
                this.formatter(this.suggestList[this.selectedSuggestRowId]);
            }
        } else if (event.which === 39 && this.selectedValue == "Drug") {
            // right
            if (this.searchBox.nativeElement === document.activeElement) {
                this.showEqDrugs = true;
            }
            this.EqDrugs = [];
        } else if (event.which === 37 && this.showEqDrugs) {
            if (this.EqDrugs.length > 0) {
                this.showEqDrugs = false;
                // this.searchBox.nativeElement.focus();
                event.preventDefault();
            }
        } else if (event.which === 38) {
            if (this.showEqDrugs) {
                // this.searchBox.nativeElement.blur();
                event.preventDefault();
                if (this.selecteEqDrugRowId > 0) {
                    this.selecteEqDrugRowId--;
                    const ele = document.getElementsByClassName(
                        "dropdown-item active"
                    );
                    if (ele && ele[1]) {
                        ele[1].scrollIntoView({ block: "center" });
                    }
                }
            } else if(this.suggestList && this.suggestList.length > 0) {
                event.preventDefault();
                if (this.selectedSuggestRowId > 0) {
                    this.selectedSuggestRowId--;
                    const ele = document.getElementsByClassName(
                        "dropdown-item active"
                    );
                    // if (ele && ele[1]) {
                    //     ele[1].scrollIntoView({ block: "center" });
                    // }
                }
            }
            // this.showEqDrugs = false;
        } else if (event.which === 40) {
            if (this.showEqDrugs) {
                // this.searchBox.nativeElement.blur();
                event.preventDefault();
                if (
                    this.EqDrugs.length > 0 &&
                    this.selecteEqDrugRowId < this.EqDrugs.length - 1
                ) {
                    this.selecteEqDrugRowId++;
                    const ele = document.getElementsByClassName(
                        "dropdown-item active"
                    );
                    if (ele && ele[1]) {
                        ele[1].scrollIntoView({ block: "center" });
                    }
                }
            } else if ( this.suggestList.length > 0 && this.selectedSuggestRowId < this.suggestList.length - 1 ) {
                this.selectedSuggestRowId++;
                const ele = document.getElementsByClassName(
                    "dropdown-item active"
                );
                // if (ele && ele[1]) {
                //     ele[1].scrollIntoView({ block: "center" });
                // }
            }
            // this.showEqDrugs = false;
        }
    }

    @HostListener("document:keydown.control.y", ["$event"])
    keyEventY(event: KeyboardEvent) {
        if (
            (this.selectedValue === "Patient" ||
            this.selectedValue === "Prescriber" ||
            this.selectedValue === "Drug" ||
            this.selectedValue === "Rx") &&
            !this._modalService.hasOpenModals()
        ) {
            event.preventDefault();
            this.selectedValue = "Prescriber";
            this.addNewPatientPrescDrug();
        }
    }

    @HostListener("document:keydown.control.x", ["$event"])
    keyEventX(event: KeyboardEvent) {
        if (
            (this.selectedValue === "Patient" ||
            this.selectedValue === "Prescriber" ||
            this.selectedValue === "Drug" ||
            this.selectedValue === "Rx") &&
            !this._modalService.hasOpenModals()
        ) {
            event.preventDefault();
            this.selectedValue = "Patient";
            this.addNewPatientPrescDrug();
        }
    }

    @HostListener("document:keydown.control.z", ["$event"])
    keyEventZ(event: KeyboardEvent) {
        if (
            (this.selectedValue === "Patient" ||
            this.selectedValue === "Prescriber" ||
            this.selectedValue === "Drug" ||
            this.selectedValue === "Rx") &&
            !this._modalService.hasOpenModals()
        ) {
            event.preventDefault();
            this.selectedValue = "Drug";
            this.addNewPatientPrescDrug();
        }
    }

    constructor(
        private _commonServ: CommonService,
        // keyboardShortcuts: KeyboardShortcuts,
        private _router: Router,
        private _modalService: NgbModal,
        private _privMaskGuard: PrivMaskGuard,
        private _alertServ: AlertService,
        private _utils: RxUtils,
        private _typehead: TypeaheadPopupDirective,
        private _actvRoute: ActivatedRoute,
        private _rxObserSer: RxObservableService,
        private _prescriberUtil: PrescriberUtil,
        private _drugUtil: DrugUtils,
        private _nrxUtils: NRxUtils,
        private _rxStore: RxStore,
        private _patUtil: PatientInfoUtil,
        private _commonStore: CommonStore,
        private _patPreDrugModalServ: PatPreDrugModalService) {
        this.accessPrivCate = AccCategoryE;
        this.patientPrivs = PatientFileE;
        this.drugPrivs = PrescriberFileE;
        this.prescriberPrivs = DrugFileE;
        this.filePrivs = FileMaintenanceE;
        this.rxPrivs = RxE;
        // this.keyboardShortcuts = keyboardShortcuts;
        // this.unlisten = null;
        this.option = this.listItems[0];
        this.todayDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date(),
        };
    }

    ngOnInit() {
        if (this.accessPrivCate) {
            this.hasAddPatPrivs = this.checkHasPrivsToAccess(
                this.accessPrivCate.PatientFile,
                this.patientPrivs.Add
            );
            this.hasAddprescPrivs = this.checkHasPrivsToAccess(
                this.accessPrivCate.PrescriberFile,
                this.prescriberPrivs.Add
            );
            this.hasAddDrugPrivs = this.checkHasPrivsToAccess(
                this.accessPrivCate.DrugFile,
                this.drugPrivs.Add
            );
        }
    }

    ngOnChanges(): void {
        // tslint:disable-next-line:no-unused-expression
        if (this.isElementFocused && this.searchBox) {
            this.searchBox.nativeElement.focus();
        }
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line:no-unused-expression
        // this.unlisten && this.unlisten();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getValueFromDropdown(value) {
        if (value) {
            this.selectedValue = value;
            this.OptedType.emit(this.selectedValue);
            this.value = null;
            this.placholderText = value;
            setTimeout(() => {
                this.searchBox.nativeElement.focus();
            }, 100);
            // document.getElementById("iSearchBox").focus();
        }
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(this.debounceTime),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap((term) => {
                if (this.restricSearch) {
                    this.emitRestrictionSucess.emit(null);
                } else if (term) {
                    return this.searchByKeyWord(term);
                } else {
                    if (this.selectedValue === "Patient") {
                        this.SelectedPatient.emit(null);
                    } else if (this.selectedValue === "Prescriber") {
                        this.SelectedPrescriber.emit(null);
                    } else if (this.selectedValue === "Drug") {
                        this.SelectedDrug.emit(null);
                    }
                    this.SuggestData = this._commonServ.getEmptyObservable();
                    return this._commonServ.getEmptyObservable();
                    // return Observable.empty();
                }
            }),
            tap(() => (this.searching = false))
        );

    checkIsNullValue(val) {
        if (val === null || val === undefined || val === "") {
            if (this.selectedValue === "Patient") {
                this.SelectedPatient.emit(null);
            } else if (this.selectedValue === "Prescriber") {
                this.SelectedPrescriber.emit(null);
            } else if (this.selectedValue === "Drug") {
                this.SelectedDrug.emit(null);
            }
        }
    }

    searchByKeyWord(value: any) {
        if (typeof value === "string") {
            value = value.trim();
            if (value === "" || value === " ") {
                value = null;
            }
            this.inputValue = value;
        }
        let newSuggestData;
        this.SuggestData = this.assignEmptyList();
        this.selecInfo = null;
        let type = null;
        this.searchValue = value;
        this._typehead.isCompleted = false;
        if (!this.isByEnter) {
            if (value && value.length > 0) {
                if (value.indexOf(":") >= 0) {
                    type = value.split(":")[0];
                    type = type.replace(/[^A-Z0-9]/gi, "");
                    const typeVal = type.toLowerCase();
                    if (
                        typeVal === "pr" ||
                        typeVal === "d" ||
                        typeVal === "p" ||
                        typeVal === "rx" ||
                        typeVal === "rf"
                        ) {
                        value = value.split(":")[1];
                        this.searchTextValue = value;
                    }
                    this.inputValue = value;
                    if (value) {
                        if (type.toLowerCase().indexOf("pr") >= 0) {
                            this.placholderText = "Prescriber";
                            this.option = this.listItems[1];
                            this.selectedValue = this.listItems[1];
                            const prescName = value.split("_");
                            let prescVal = null;
                            if (prescName[1]) {
                                prescVal =
                                    prescName[0].trim() +
                                    "_" +
                                    prescName[1].trim();
                            } else {
                                prescVal = prescName[0].trim();
                            }
                            this.SuggestData = this._commonServ.getPrescriberSuggest(
                                prescVal
                            ).pipe(map(resp => this.assignSuggestList(resp)));
                            this.openAddNewPrescriberModal(this.SuggestData);
                        } else if (type.toLowerCase().indexOf("d") >= 0) {
                            this.placholderText = "Drug";
                            this.option = this.listItems[2];
                            this.selectedValue = this.listItems[2];
                            this.SuggestData = this._commonServ.getGlobalSearchDrugSuggest(
                                value
                            ).pipe(map(resp => this.assignSuggestList(resp)));
                            this.openAddNewDrugModal(this.SuggestData);
                        } else if (type.toLowerCase().indexOf("rx") >= 0) {
                            this.placholderText = "Rx";
                            this.option = this.listItems[3];
                            this.selectedValue = this.listItems[3];
                            this.routeToEditRx(+value);
                            setTimeout(() => {
                                document.getElementById("iSearchBox").focus();
                            }, 300);
                        } else if (type.toLowerCase().indexOf("refill") >= 0) {
                            this.placholderText = "Refill";
                            this.option = this.listItems[4];
                            this.selectedValue = this.listItems[4];
                            this._commonServ
                                .getRxDetailsByPrescNos([value], null, false)
                                .pipe(takeUntil(this.unsubscribe$))
                                .subscribe((rxInfo) => {
                                    if (rxInfo && rxInfo.length > 0) {
                                        const data = {
                                            selectedRxs: [+value],
                                            frm: "SideNav",
                                            completeData: [+value],
                                        };
                                        value = null;
                                        this.inputValue = null;
                                        this.searchValue = null;
                                        this._commonStore.storeRefInfo(data);
                                        this._commonStore.storeRfStage("Start");
                                        this.SuggestData = this.assignEmptyList();;
                                    } else {
                                        this.modelRef = this._modalService.open(
                                            this.addRx,
                                            {
                                                centered: true,
                                                backdrop: false,
                                                keyboard: false,
                                            }
                                        );
                                    }
                                });
                            setTimeout(() => {
                                document.getElementById("iSearchBox").focus();
                            }, 300);
                        } else if (
                            type.toLowerCase().indexOf("p") >= 0 ||
                            this.selectedValue === "Patient"
                        ) {
                            this.placholderText = "Patient";
                            this.option = this.listItems[0];
                            this.selectedValue = this.listItems[0];
                            const patName = value.split("_");
                            let patVal = null;
                            if (patName[1]) {
                                patVal =
                                    patName[0].trim() + "_" + patName[1].trim();
                            } else {
                                patVal = patName[0].trim();
                            }
                            this.SuggestData = this._commonServ.getPatientsuggest(
                                patVal
                            ).pipe(map(resp => this.assignSuggestList(resp)));
                            // this.SuggestData = this._commonServ.getPatientsuggest(
                            //     patVal
                            // );
                            this.openAddNewPatientModal(this.SuggestData);
                        }
                    }
                } else if (value && this.erxSearch) {
                    if (this.selectedValue === "Patient") {
                        this.SelectedPatient.emit(value);
                    } else if (this.selectedValue === "Prescriber") {
                        this.SelectedPrescriber.emit(value);
                    } else {
                        this.SelectedDrug.emit(value);
                    }
                } else if (this.selectedValue) {
                    // value = value.replace(",", "_");
                    this.searchTextValue = value;
                    if (value.length > 0) {
                        if (this.selectedValue === this.listItems[0]) {
                            const patName = value.split("_");
                            let patVal = null;
                            if (patName[1]) {
                                patVal =
                                    patName[0].trim() + "_" + patName[1].trim();
                            } else {
                                patVal = patName[0].trim();
                            }
                            this.SuggestData = this._commonServ.getPatientsuggest(
                                patVal
                            ).pipe(map(resp => this.assignSuggestList(resp)));
                            this.openAddNewPatientModal(this.SuggestData);
                        } else if (this.selectedValue === this.listItems[1]) {
                            const prescName = value.split(",");
                            let prescVal = null;
                            if (prescName[1]) {
                                prescVal =
                                    prescName[0].trim() +
                                    "_" +
                                    prescName[1].trim();
                            } else {
                                prescVal = prescName[0].trim();
                            }
                            this.SuggestData = this._commonServ.getPrescriberSuggest(
                                prescVal
                            ).pipe(map(resp => this.assignSuggestList(resp)));
                            this.openAddNewPrescriberModal(this.SuggestData);
                        } else if (this.selectedValue === this.listItems[2]) {
                            this.EqDrugs = [];
                            this.showEqDrugs = false;
                            // if (value && value.length > 16) {
                            //     this.SuggestData = this._commonServ.getDrugByBarcode(value);
                            //     this.SuggestData.subscribe(barcodeDrug => {
                            //         if (barcodeDrug && barcodeDrug.length === 1) {
                            //             this.searchValue = null;
                            //             this.SelectedDrug.emit(barcodeDrug[0]);
                            //         }
                            //     });
                            // } else {
                            if (!this.isFetchingBarcode) {
                                this.SuggestData = this._commonServ.getGlobalSearchDrugSuggest(value).pipe(map(resp => this.assignSuggestList(resp)));
                                this.openAddNewDrugModal(this.SuggestData);
                            } else {
                                this._commonServ.getEmptyObservable();
                            }
                            // }
                        } else if (this.selectedValue === this.listItems[3]) {
                            this.SuggestData = this.assignEmptyList();;
                            sessionStorage.removeItem("ActiveId");
                            this.routeToEditRx(+value);
                            this.SuggestData = this.assignEmptyList();;
                        } else if (this.selectedValue === this.listItems[4]) {
                            this.SuggestData = this.assignEmptyList();;
                            sessionStorage.removeItem("ActiveId");
                            this._commonServ
                                .getRxDetailsByPrescNos([value], null, false)
                                .pipe(takeUntil(this.unsubscribe$))
                                .subscribe((rxInfo) => {
                                    if (rxInfo && rxInfo.length > 0) {
                                        const data = {
                                            selectedRxs: [+value],
                                            frm: "SideNav",
                                            completeData: [+value],
                                        };
                                        value = null;
                                        this.inputValue = null;
                                        this.searchValue = null;
                                        this._commonStore.storeRefInfo(data);
                                        this._commonStore.storeRfStage("Start");
                                        this.SuggestData = this.assignEmptyList();
                                    } else {
                                        this.modelRef = this._modalService.open(
                                            this.addRx,
                                            {
                                                centered: true,
                                                backdrop: false,
                                                keyboard: false,
                                            }
                                        );
                                    }
                                });
                        }
                    }
                } else {
                    this.SuggestData = this.assignEmptyList();
                    this.SelectedPatient.emit(null);
                }
            } else {
                if (this.selectedValue === "Patient") {
                    this.SelectedPatient.emit(value);
                } else if (this.selectedValue === "Prescriber") {
                    this.SelectedPrescriber.emit(value);
                } else {
                    this.SelectedDrug.emit(value);
                }
                this.SuggestData = this.assignEmptyList();
            }
        }
        newSuggestData = this.SuggestData;
        let isSinglData = false;
        if (newSuggestData && newSuggestData.length > 0) {
                newSuggestData.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
                    if (data && data.length > 0) {
                        if (data.length === 1) {
                            isSinglData = true;
                            this.formatter(data[0]);
                        }
                    }
                })
        }
        this.isByEnter = false;
        if (isSinglData) {
            return [];
        } else {
            // return this.SuggestData;
            return this._commonServ.getEmptyObservable();
        }
    }

    async routeToEditRx(value, rfId?: any) {
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(+value, rfId ? rfId : null, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            this._patPreDrugModalServ._patPreDrugModal$.next("Rx");
            const modalRef = await this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
                modalRef.close();
                this._patPreDrugModalServ._patPreDrugModal$.next(null);
                this.inputValue = null;
            });
            modalRef.componentInstance.CloseAndReopen
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(d => {
                modalRef.close();
                if (d) {
                    this.routeToEditRx(d["rxNo"], d["rfId"]);
                } else {
                    this._patPreDrugModalServ._patPreDrugModal$.next(null);
                    this.inputValue = null;
                }
            });
        } else {
            this.modelRef = this._modalService.open(this.addRx, {
                centered: true,
                backdrop: false,
                keyboard: false,
            });
        }
    }

    formatter = (x: any) => {
        x = Object.assign({}, x);
        this.SuggestData = this.assignEmptyList();
        let name = null;
        if (this.selectedValue === "Patient") {
            if (
                this.searchFrmPage === "header" ||
                this.searchFrmPage === "Dash"
            ) {
                this.reDirectToRx(x);
            } else {
                this.SelectedPatient.emit(x);
                name = x.lastname + " " + x.firstname;
            }
            this.inputValue = null;
            this.searchValue = null;

        } else if (this.selectedValue === "Prescriber") {
            this.SelectedPrescriber.emit(x);
            this.selecInfo = x.prescriberlastname + " " + x.prescriberfirstname;
            this.inputValue = null;
            this.searchValue = null;
            name = this.searchFrmPage === "header" ||
                this.searchFrmPage === "Dash"
                ? null
                : x.prescriberlastname + " " + x.prescriberfirstname;
        } else if (this.selectedValue === "Drug") {
            this.SelectedDrug.emit(x);
            this.selecInfo = x.drugname;
            this.inputValue = null;
            this.searchValue = null;
            name = this.searchFrmPage === "header" ||
                this.searchFrmPage === "Dash"
                ? null
                : x.drugname;
        }
        setTimeout(() => {
            this.SuggestData = this.assignEmptyList();
        }, 100);
        this.inputValue = name;
        return name;
    };

    // formatter = (x: any) => {
    //     if (this.selectedValue === "Patient") {
    //         /* Clear local storage as selecting new patient */
    //         this.selectedPatientSuggest(x);
    //         return x.lastname + " " + x.firstname;
    //     } else if (this.selectedValue === "Prescriber") {
    //         if (this.pageName === "header") {
    //             this._router.navigate([this.presRouterURL, "prescriber" , x.prescriberid]);
    //         } else if (this.presRouterURL) {
    //             this._router.navigate([
    //                 this.presRouterURL,
    //                 x.prescriberid,
    //                 "prescriberinfo"
    //             ]);
    //         }
    //         this.SelectedPrescriber.emit(x);
    //         this.selecInfo = x.prescriberlastname + " " + x.prescriberfirstname;
    //         // this.inputValue = null;
    //         return x.prescriberlastname + " " + x.prescriberfirstname;
    //     } else if (this.selectedValue === "Drug") {
    //         if (this.pageName == "header") {
    //             this._router.navigate([this.drugRouterURL,"drug" , x.id]);
    //         } else if (this.drugRouterURL) {
    //             this._router.navigate([this.drugRouterURL, x.id, "druginfo"]);
    //         }
    //         this.SelectedDrug.emit(x);
    //         this.selecInfo = x.drugname;
    //        // this.inputValue = null;
    //         return x.drugname;
    //     }
    // };

    async reDirectToRx(data?: any) {
        if (this._router.url === "/eprx/rx?fm=nr") {
            this._router.navigate(["eprx/dashboard"]);
        }
        const rxFG = await this._nrxUtils.createPatchStoreRxFG();
        rxFG.controls["Patient"].patchValue(data);
        this._rxStore.StoreRxFG(rxFG);
        this._router.navigate(["/eprx/rx"], {
            queryParams: { fm: "nr" },
        });
    }

    selectedPatientSuggest(x) {
        localStorage.removeItem("patientId");
        localStorage.removeItem("RxInfo");
        localStorage.removeItem("RxID");
        localStorage.removeItem("PatientInfo");
        sessionStorage.removeItem("ErxType");
        sessionStorage.removeItem("Insurance");
        sessionStorage.removeItem("InsuranceType");
        sessionStorage.removeItem("isTransMSG");
        sessionStorage.removeItem("isPatHistEnabled");
        sessionStorage.removeItem("toEdit");
        localStorage.removeItem("NewPatientInfo");
        if (!this.notToClearSession) {
            window.sessionStorage.clear();
        }
        this._rxObserSer.clearAllRxOtherInfoBS();

        if (this.patRouterURL) {
            if (
                this.checkHasPrivsToAccess(
                    this.accessPrivCate.Rx,
                    this.rxPrivs.RxEntry
                )
            ) {
                this.reDirectToRx(x);
            } else {
                this.openOveride("Rx", "RxEntry", "POST_Rx");
            }
        }
        this._commonServ.storePatientIdinBS(x.patientid);
        this.SelectedPatient.emit(x);
        localStorage.setItem("PatientInfo", this._commonServ.encryptData(JSON.stringify(x)));
        this.selecInfo = x.lastname + " " + x.firstname;
        // this.inputValue = null;
        sessionStorage.removeItem("ActiveId");
        sessionStorage.removeItem("CreatingNewFile");
    }

    clearFocus() {
        this.searchBox.nativeElement.blur();
    }

    clearValue() {
        this.searchBox.nativeElement.value = "";
        this.SuggestData = this.assignEmptyList();
    }

    openAddNewPatientModal(patientObserv: Observable<any>) {
        patientObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp && resp.length === 0 && !this.isByEnter) {
                if (this.inputValue === this.searchValue) {
                    this.modelRef = this._modalService.open(this.addPatient, {centered: true,
                        keyboard: false,
                    });
                    this.actModal = "addPatient";
                } else {
                    this.modelRef = this._modalService.open(this.addPatient, {centered: true,
                        keyboard: false,
                    });
                    this.actModal = "addPatient";
                }
            } else if (
                resp &&
                resp.length === 1 &&
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DISP_SINGLE_MATCH_FILE_SEARCH"
                ) === "0"
            ) {
                this.searchValue = null;
                this.SelectedPatient.emit(resp[0]);
            }
        });
    }

    openAddNewPrescriberModal(presObserv: Observable<any>) {
        presObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp && resp.length === 0 && !this.isByEnter) {
                if (this.searchValue) {
                    if (this.searchValue.substring(0, 3) === "pr:") {
                        this.searchValue = this.searchValue.substring(
                            3,
                            this.searchValue.length + 1
                        );
                    }
                }
                if (this.inputValue === this.searchValue) {
                    this.modelRef = this._modalService.open(
                        this.addPrescriber,
                        { keyboard: false, centered: true }
                    );
                    this.actModal = "addPrescriber";
                } else {
                    this.modelRef = this._modalService.open(
                        this.addPrescriber,
                        { keyboard: false, centered: true }
                    );
                    this.actModal = "addPrescriber";
                    this.isElementFocused = true;
                }
            } else if (
                resp &&
                resp.length === 1 &&
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DISP_SINGLE_MATCH_FILE_SEARCH"
                ) === "0"
            ) {
                this.searchValue = null;
                this.SelectedPrescriber.emit(resp[0]);
            }
        });
    }

    openAddNewDrugModal(drugObserv: Observable<any>) {
        drugObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp && resp.length === 0 && !this.isByEnter) {
                if (this.inputValue === this.searchValue) {
                    if (this.defualtDrugId) {
                        this.getDrugById(this.defualtDrugId);
                    } else {
                        this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                            keyboard: false,
                        });
                        this.actModal = "addDrug";
                    }
                } else {
                    if (this.searchValue) {
                        if (this.searchValue.substring(0, 2) === "d:") {
                            this.searchValue = this.searchValue.substring(
                                2,
                                this.searchValue.length + 1
                            );
                        }
                        sessionStorage.setItem("AddDrugInfo", this.searchValue);
                    }
                    this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                        keyboard: false,
                    });
                    this.actModal = "addDrug";
                }
            } else if (
                resp &&
                resp.length === 1 &&
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DISP_SINGLE_MATCH_FILE_SEARCH"
                ) === "0"
            ) {
                this.searchValue = null;
                this.SelectedDrug.emit(resp[0]);
            }
        });
    }

    closeModal() {
        sessionStorage.setItem("RouteFromSearchPrescToSearchMMS", "false");
        this.actModal = "";
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.isElementFocused = false;
        this.inputValue = "s";
        this.searchValue = "s";

        if (this.selectedValue === "Patient") {
            this.SelectedPatient.emit(null);
        } else if (this.selectedValue === "Prescriber") {
            this.SelectedPrescriber.emit(null);
        } else {
            this.SelectedDrug.emit(null);
        }
        setTimeout(() => {
            this.searchBox.nativeElement.focus();
            this.inputValue = null;
            this.searchValue = null;
        }, 50);
        // document.getElementById("iSearchBox").focus();
        this.isByEnter = false;
    }

    searchForMMSPopupRoute() {
        sessionStorage.setItem("RouteFromSearchPrescToSearchMMS", "true");
        this.addNewPatientPrescDrugRx();
    }

    routeToNewDrugMMS() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        let curntRoute = null;
        this._actvRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            if (params && params.fm) {
                curntRoute = params.fm;
            }
        });
        sessionStorage.setItem("AddDrugInfo", this.searchValue);
        if (this.searchFrmPage === "ErxChange") {
            this.OptedFormAddNew.emit("DrugMMS");
        } else {
            if (window.location.hash.includes("dashboard")) {
                this._router.navigate(["eprx/drug/druginfo"], {
                    queryParams: { openMMS: true },
                });
            } else {
                if (curntRoute === "nr") {
                    this._router.navigate(["eprx/drug/druginfo"], {
                        queryParams: { fm: "nr", openMMS: true },
                    });
                } else if (curntRoute === "er") {
                    this._router.navigate(["eprx/drug/druginfo"], {
                        queryParams: { fm: "er", openMMS: true },
                    });
                }
            }
        }
    }

    async addNewPatientPrescDrug(openMMS?: boolean) {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        if (this.emitIfNotFound) {
            this.OptedFormAddNew.emit(this.selectedValue);
        } else {
            if (this.selectedValue === "Patient") {
                if (this.isFrmErx) {
                    this.OptedFormAddNew.emit("Patient");
                } else {
                    this._patPreDrugModalServ._patPreDrugModal$.next("patient");
                    const modalRef = this._patPreDrugModalServ.modalInstanceRef;
                    let FG = this._patUtil.generatePatientInfoFG();
                    if (this.searchValue && !this.searchValue.endsWith("#")) {
                        FG = this._patUtil.patchPatFrmGlobalSearch(
                            this.searchValue,
                            FG
                        );
                    }
                    modalRef.componentInstance.patientID = null;
                    modalRef.componentInstance.addPatientModel = FG;
                    modalRef.componentInstance.ClosePatientModal
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                        async (resp) => {
                            if (resp["PatientId"]) {
                                const response = await this._commonServ
                                    .getRxPatientDetailsById(resp["PatientId"])
                                    .toPromise();
                                if (
                                    (this.searchFrmPage === "header" ||
                                    this.searchFrmPage === "Dash") && !this.isFrmRx
                                ) {
                                    this.reDirectToRx(
                                        response ? response : null
                                    );
                                } else {
                                    this.SelectedPatient.emit(
                                        response ? response : null
                                    );
                                }
                            }
                            modalRef.close();
                            this._patPreDrugModalServ._patPreDrugModal$.next(
                                null
                            );
                            this.inputValue = null;
                            this.searchValue = null;
                        }
                    );
                }
            } else if (this.selectedValue === "Prescriber") {
                if (this.isFrmErx) {
                    this.OptedFormAddNew.emit("Prescriber");
                } else {
                    let formGroupName = null;
                    this._patPreDrugModalServ._patPreDrugModal$.next(
                        "prescriber"
                    );
                    const modalRef = this._patPreDrugModalServ.modalInstanceRef;
                    if (this.searchValue) {
                        formGroupName = this._prescriberUtil.generatePrescriberFG();
                        if (/^([0-9]{10})$/.test(this.searchValue)) {
                            formGroupName.controls["Person"].patchValue({
                                NPINum: this.searchValue
                                    ? this.searchValue
                                    : null,
                            });
                        } else if (
                            /^([a-zA-Z]{2})([0-9]{7})$/.test(this.searchValue)
                        ) {
                            formGroupName.controls["Person"].patchValue({
                                DEANum: this.searchValue,
                            });
                        } else if (this.searchValue.indexOf("/") === -1) {
                            const patientNames = this.searchValue.split(",");
                            formGroupName.controls["Person"].patchValue({
                                LastName: patientNames[0]
                                    ? patientNames[0].trim()
                                    : null,
                                FirstName: patientNames[1]
                                    ? patientNames[1].trim()
                                    : null,
                            });
                        }
                    }
                    modalRef.componentInstance.prescriberID = null;
                    modalRef.componentInstance.openMMS = openMMS;
                    modalRef.componentInstance.addPrescriberFG = formGroupName;
                    modalRef.componentInstance.ClosePrescriberModal
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                        (resp) => {
                            if (
                                resp["prescriberId"] &&
                                !(
                                    (this.searchFrmPage === "header" ||
                                    this.searchFrmPage === "Dash") && !this.isFrmRx
                                )
                            ) {
                                this.getPrescriberById(resp["prescriberId"]);
                            }
                            modalRef.close();
                            this._patPreDrugModalServ._patPreDrugModal$.next(
                                null
                            );
                            this.inputValue = null;
                            this.searchValue = null;
                        }
                    );
                }
            } else if (this.selectedValue === "Drug") {
                if (this.isFrmErx) {
                    this.OptedFormAddNew.emit("Drug");
                } else {
                    this._patPreDrugModalServ._patPreDrugModal$.next("drug");
                    const modalRef = this._patPreDrugModalServ.modalInstanceRef;
                    modalRef.componentInstance.drugId = null;
                    const fG = this._drugUtil.createDrugDataModal();
                    if (this.searchValue) {
                        if (this.searchValue.substring(0, 2) === "d:") {
                            this.searchValue = this.searchValue.substring(
                                2,
                                this.searchValue.length + 1
                            );
                        }
                        if (/^([0-9]{11})$/.test(this.searchValue)) {
                            if (
                                this.searchValue != null &&
                                this.searchValue !== undefined
                            ) {
                                fG.controls["Drug"].patchValue({
                                    DrugCode: this.searchValue
                                        ? this.searchValue
                                        : null,
                                });
                            }
                        } else {
                            const drugNames = this.searchValue.split(/[\s,]+/);
                            fG.controls["Drug"].patchValue({
                                Name: drugNames[0] ? drugNames[0].trim() : null,
                                Strength: drugNames[1] ? drugNames[1] : null,
                            });
                        }
                    }
                    modalRef.componentInstance.openMMS = openMMS;
                    modalRef.componentInstance.addDrugModal = fG;
                    modalRef.componentInstance.CloseDrugModal
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                        (resp) => {
                            modalRef.close();
                            if (
                                resp["drugId"] &&
                                !(
                                    (this.searchFrmPage === "header" ||
                                    this.searchFrmPage === "Dash") && !this.isFrmRx
                                )
                            ) {
                                this.getDrugById(resp["drugId"]);
                            }
                            this._patPreDrugModalServ._patPreDrugModal$.next(
                                null
                            );
                            this.inputValue = null;
                            this.searchValue = null;
                        }
                    );
                }
            }
        }
    }

    addNewPatientPrescDrugRx() {
        sessionStorage.removeItem("ActiveId");
        let curntRoute = null;
        this._actvRoute.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((params) => {
            if (params && params.fm) {
                curntRoute = params.fm;
            }
        });
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.selectedValue === "Patient") {
            if (this.isFrmErx || this.searchFrmPage === "Family") {
                this.OptedFormAddNew.emit("Patient");
            } else if (this.pageName == "header" && !this.isFrmRx) {
                sessionStorage.setItem(
                    "AddPatientInfo",
                    this.searchValue ? this.searchValue : ""
                );
                this._router.navigate([this.patRouterURL, "patient", "false"]);
            } else {
                sessionStorage.setItem(
                    "AddPatientInfo",
                    this.searchValue ? this.searchValue : ""
                );
                if (curntRoute === "nr") {
                    this._router.navigate(["eprx/editpatient/patientinfo"], {
                        queryParams: { AddPatient: true, fm: "nr" },
                    });
                } else if (curntRoute === "er") {
                    this._router.navigate(["eprx/editpatient/patientinfo"], {
                        queryParams: { AddPatient: true, fm: "er" },
                    });
                } else {
                    this._router.navigate(["eprx/editpatient/patientinfo"], {
                        queryParams: { AddPatient: true },
                    });
                }
            }
        } else if (
            this.selectedValue === "Prescriber" ||
            this.selectedValue === "Drug"
        ) {
            if (this.isFrmErx) {
                this.OptedFormAddNew.emit(this.selectedValue);
            } else {
                this.addNewPatientPrescDrug();
            }
        }

        // else if (this.selectedValue === "Prescriber") {
        //     if (this.isFrmErx) {
        //         this.OptedFormAddNew.emit("Prescriber");
        //     } else if (this.pageName == "header") {
        //         if (this.searchValue) {
        //             sessionStorage.setItem("AddPrescriberInfo", this.searchValue ? this.searchValue : "");
        //         }
        //         this._router.navigate([this.presRouterURL, "prescriber","0"]);
        //     } else {
        //         if (this.searchValue) {
        //             sessionStorage.setItem("AddPrescriberInfo", this.searchValue ? this.searchValue : "");
        //         }
        //         if (window.location.hash.includes("dashboard")) {
        //             this._router.navigate(["eprx/prescriber/prescriberinfo"]);
        //         } else {
        //             if (curntRoute === "nr") {
        //                 this._router.navigate(["eprx/prescriber/prescriberinfo"], {
        //                     queryParams: { fm: "nr" }
        //                 });
        //             } else if (curntRoute === "er") {
        //                 this._router.navigate(["eprx/prescriber/prescriberinfo"], {
        //                     queryParams: { fm: "er" }
        //                 });
        //             }
        //         }
        //     }
        // } else if (this.selectedValue === "Drug") {
        //     sessionStorage.setItem("AddDrugInfo", this.searchValue ? this.searchValue : "");
        //     if (this.isFrmErx || this.searchFrmPage === "ErxChange") {
        //         this.OptedFormAddNew.emit("Drug");
        //     } else if (this.pageName == "header") {
        //         this._router.navigate([this.drugRouterURL, "drug", "0"]);
        //     } else {
        //         if (window.location.hash.includes("dashboard")) {
        //             this._router.navigate(["eprx/drug/druginfo"]);
        //         } else {
        //             if (curntRoute === "nr") {
        //             this._router.navigate(["eprx/drug/druginfo"], {
        //                 queryParams: { fm: "nr" }
        //             });
        //         } else if (curntRoute === "er") {
        //             this._router.navigate(["eprx/drug/druginfo"], {
        //                 queryParams: { fm: "er" }
        //             });
        //         }
        //         }
        //     }
        // } else {
        // }
    }

    onKeydown(va) {}

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val && this.privType !== "RxEntry") {
            this.addNewPatientPrescDrugRx();
        } else {
            this.reDirectToRx();
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = category;
        this.overrideEndpoint = endpoint;
    }

    focusedOutofSearch() {
        this.FocusedOutofSearch.emit();
        setTimeout(() => {
            this.SuggestData = this.assignEmptyList();
        }, 500);
    }

    searchRx = (text$: Observable<string>) =>
    text$.pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(() => (this.searching = true)),
        switchMap((term) => {
            if (term) {
                let value = term;
                if (typeof value === "string") {
                    value = value.trim();
                    if (value === "" || value === " ") {
                        value = null;
                    }
                }
                let type = null;
                if (value && value.length > 0) {
                    if (value.indexOf(":") >= 0) {
                        type = value.split(":")[0];
                        type = type.replace(/[^A-Z0-9]/gi, "");
                        const typeVal = type.toLowerCase();
                        if (typeVal === "p") {
                            this.selectedValue = this.listItems[0];
                        } else if (typeVal === "pr") {
                            this.selectedValue = this.listItems[1];
                        } else if (typeVal === "d") {
                            this.selectedValue = this.listItems[2];
                        } else if (typeVal === "rx") {
                            this.selectedValue = this.listItems[3];
                        } else if (typeVal === "rf") {
                            this.selectedValue = this.listItems[4];
                        }

                        if (
                            typeVal === "pr" ||
                            typeVal === "d" ||
                            typeVal === "p" ||
                            typeVal === "rx" ||
                            typeVal === "rf"
                        ) {
                            value = value.split(":")[1];
                        }
                        this.inputValue = value;
                    }
                }
            }
            setTimeout(() => {
                document.getElementById("iSearchBox").focus();
            }, 10);
            return Observable.empty();
        }),
        tap(() => (this.searching = false))
    );

    getPrescriberById(presId: any) {
        this._commonServ
            .getRxPrescriberDetailsById(presId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp) => {
                if (resp) {
                    this.searchValue = null;
                    this.SelectedPrescriber.emit(resp);
                } else {
                    this.modelRef = this._modalService.open(
                        this.addPrescriber,
                        { keyboard: false, centered: true }
                    );
                    this.actModal = "addPrescriber";
                    // document.getElementById("addPrescrDefActn").focus();
                }
            });
    }

    getDrugById(drugId: any) {
        this._commonServ.getRxDrugDetailsById(drugId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp) => {
            if (resp) {
                this.searchValue = null;
                this.SelectedDrug.emit(resp);
            } else {
                this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                    keyboard: false,
                });
                this.actModal = "addDrug";
                // document.getElementById("addDrugDefActn").focus();
            }
        });
    }

    keyTab($event) {
        this.OnEnter.emit($event);
    }

    searchByKeyWordOnEnter(e) {
        const data = this.searchByKeyWord(e.target.value);
        // this.search = data;
        this.keyTab(e);
    }

    checkIsDOBorPatRx(rxNo) {
        let type = null;
        if (rxNo) {
            if (rxNo.indexOf(":") >= 0) {
                type = rxNo.split(":")[0];
                type = type.replace(/[^A-Z0-9]/gi, "");
                rxNo = rxNo.split(":")[1];
                if (type.toLowerCase().indexOf("p") >= 0) {
                    this.placholderText = "Patient";
                    this.option = this.listItems[0];
                    this.selectedValue = this.listItems[0];
                }
            }

            if (
                /^([0-9]{1,12})(\/)$/.test(rxNo) &&
                this.selectedValue === "Patient"
            ) {
                this.inputValue = rxNo;
                this.isByEnter = true;
                this._commonServ
                    .getPatientsuggestByRxNo(rxNo)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((resp) => {
                        if (resp) {
                            if (this.modelRef) {
                                this.modelRef.close();
                            }
                            this.reDirectToRx(resp);
                            // this.selectedPatientSuggest(resp);
                        }
                    });
            }
        }
    }

    getDrugStatus(drugInfo: any) {
        if (
            drugInfo.discontdt &&
            moment(drugInfo.discontdt).isSameOrBefore(this.todayDate) &&
            drugInfo.IsPreferred
        ) {
            return "card drug-card-red drug-card-brown";
        } else if (drugInfo["lastdispenseddate"] && drugInfo.ispreferred) {
            return "card drug-card-green drug-card-brown";
        } else if (
            drugInfo.discontdt &&
            moment(drugInfo.discontdt).isSameOrBefore(this.todayDate)
        ) {
            return "card drug-card-red";
        } else if (drugInfo["lastdispenseddate"]) {
            return "card drug-card-green";
        } else if (drugInfo.ispreferred) {
            return "card drug-card-brown";
        } else {
            return "card";
        }
    }

    equivalentDrugs(eqDrugs: any[], isEq: boolean) {
        if (isEq) {
            this.eqDrugHeading = "Equivalent Drugs";
        } else {
            this.eqDrugHeading = "Generic Equivalent Drugs";
        }
        if (eqDrugs && eqDrugs.length > 0) {
            this.showEqDrugs = true;
            this.EqDrugs = eqDrugs;
            // this.searchBox.nativeElement.blur();
            this.selecteEqDrugRowId = 0;
        } else {
            this.EqDrugs = [];
            this.showEqDrugs = false;
        }
    }
    selectedEqDrug(drug) {
        if (this.pageName == "header") {
            this._router.navigate([this.drugRouterURL, "drug", drug.id]);
        } else if (this.drugRouterURL) {
            this._router.navigate([this.drugRouterURL, drug.id, "druginfo"]);
        }
        this.SuggestData = this.assignEmptyList();
        this.searchValue = null;
        this.SelectedDrug.emit(drug);
        this.selecInfo = drug.drugname;
        this.inputValue = null;
        this.EqDrugs = [];
        this.showEqDrugs = false;
        if (this.instance) {
            this.instance.dismissPopup();
        }
    }

    inputClearedBySearchIcon() {
        this.inputValue = "";
        this.clearedInput.emit(this.inputValue);
        if (this.selectedValue === "Patient") {
            this.SelectedPatient.emit(null);
        } else if (this.selectedValue === "Prescriber") {
            this.SelectedPrescriber.emit(null);
        } else if (this.selectedValue === "Drug") {
            this.SelectedDrug.emit(null);
        }
    }


    getBarcodeInfo(barocodeText) {
        if (barocodeText && barocodeText.length) {
            // console.log(barocodeText);
            this.isFetchingBarcode = true;
            this._commonServ.getDrugByBarcode(barocodeText)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(barcodeDrug => {
                this.isFetchingBarcode = false;
                if (barcodeDrug && barcodeDrug.length === 1) {
                    this.searchValue = null;
                    this.SelectedDrug.emit(barcodeDrug[0]);
                }
            });
        }
    }

    assignSuggestList(resp) {
        if (resp && resp.length > 0) {
            this.selectedSuggestRowId = 0;
            this.suggestList = resp;
            this.showSuggest = true;
        } else {
            this.selectedSuggestRowId = null;
            this.suggestList = [];
            this.showSuggest = false;
        }

        return resp;
    }

    assignEmptyList() {
        // this.SuggestData = this._commonServ.getEmptyObservable();
        this.suggestList = [];
        this.showSuggest = false;
        return this._commonServ.getEmptyObservable();

    }
}
