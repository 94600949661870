import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "src/app/services";
import { DocumentsService } from "./../../../services/documents.service";
import { Documents } from "./../../../models/documents.model";
import { SystemData, SharedDocuments } from "src/app/models";
import { CommonService } from "./../../../services/common.service";
import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    Input,
    HostListener
} from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import * as moment from "moment";
import { UserService } from "../../../services";
import { constant, MsgConfig } from "../../..";
import { CollectionView } from "@grapecity/wijmo";
import { DomSanitizer } from "@angular/platform-browser";
import { RegEx } from "../../../app.regex";
import * as _ from "lodash";
import { NewAuditUtils } from "src/app/utils";
import { ImageEditorComponent } from "../image-editor/image-editor.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-documents",
    templateUrl: "./documents.component.html"
})
export class DocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
    subCatList: any;
    catArray: Array<any> = [];
    docInfo: any;
    today: any;
    src: any;
    documents: any;
    editMode: boolean;
    currentDoc: any;
    imageToShow: any;
    docRowInfo: any;
    docData: any;
    showGrid: boolean;
    patientID: any;
    drugID: number;
    fileName: string;
    prescriberID: any;
    regex: {
        AlphaNumeric: string;
        Email: string;
        Dob: string;
        Numeric: string;
        IdNumeric: string;
        NotOnlySpecialChar: string;
    };
    route: string;
    modalRef: any;
    systemData: SystemData;
    url = " ";
    actvHeaders: string[];
    documentFG: FormGroup;
    documentsList: CollectionView;
    postDocFG: FormGroup;
    functionScreen: string;
    showUpload = true;
    Title: any;
    Comments: string="";
    SubCategory: any;
    DocSubCatId: any;
    DocURL: string;
    insuranceID: number;
    originalData: any;
    prescNumber: number = null;
    fileTypeToShow: string;
    uploadedFile: any;
    subCatDropdowns: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set InsuranceInfo(insId: number) {
        this.insuranceID = insId;
    }

    @Input() documentFr: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modalRef && this.modalService.hasOpenModals()) { // esc
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }
    }


    constructor(
        private modalService: NgbModal,
        private _commonServ: CommonService,
        private _fb: FormBuilder,
        private _docService: DocumentsService,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private sanitizer: DomSanitizer,
        private _actvRoute: ActivatedRoute,
        private _NewAuditUtils: NewAuditUtils,
        private _auditUtils: AuditLogUtils,
        private _userService: UserService,
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.imageToShow = "";
        this.regex = RegEx;
        this.route = window.location.href;
        this.DocURL = constant.POST_Shared_AttachDocument;
    }

    ngOnInit() {
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemData = resp;
        });
        this.createFG();
        this.getInfo();
    }

    getInfo() {
        if (this.documentFr && this.documentFr["From"] === "Prescriber") {
            this.prescriberID = this.documentFr["Id"];
            this.postDocFG.controls["prescId"].patchValue(this.prescriberID);
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Prescriber
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(5);
            this.patientID = null;
            this.drugID = null;
            this.insuranceID = null;
            this.functionScreen = SharedDocuments[2];
            this.uploadDocList();
        } else if (this.documentFr && this.documentFr["From"] === "Drug") {
            this.drugID = this.documentFr["Id"];
            this.postDocFG.controls["drugId"].patchValue(this.drugID);
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Drug
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(1);
            this.patientID = null;
            this.prescriberID = null;
            this.insuranceID = null;
            this.functionScreen = SharedDocuments[3];
            this.uploadDocList();
        }  else if (this.documentFr && this.documentFr["From"] === "Insurance") {
            this.insuranceID = this.documentFr["Id"];
            this.postDocFG.controls["insuCarrierId"].patchValue(this.insuranceID);
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Insurance
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(1);
            this.patientID = null;
            this.prescriberID = null;
            this.drugID = null;
            this.functionScreen = SharedDocuments[4];
            this.uploadDocList();
        } else if (this.documentFr && this.documentFr["From"] === "Patient") {
            this.patientID = this.documentFr["Id"];
            this.postDocFG.controls["patientId"].patchValue(this.patientID);
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Patient
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(3);
            this.prescriberID = null;
            this.drugID = null;
            this.insuranceID = null;
            this.functionScreen = SharedDocuments[1];
            this.uploadDocList();
        } else if (this.route.includes("/prescriber")) {
            this._actvRoute.parent.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                params => (this.prescriberID = +params["prescriberid"])
            );
            this.postDocFG.controls["prescId"].patchValue(this.prescriberID);
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Prescriber
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(5);
            this.patientID = null;
            this.drugID = null;
            this.insuranceID = null;
            this.functionScreen = SharedDocuments[2];
            this.uploadDocList();
        } else if (this.route.includes("/editpatient")) {
            this._actvRoute.parent.parent.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                params => (this.patientID = +params["pid"])
            );

            this.postDocFG.controls["patientId"].patchValue(this.patientID);
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Patient
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(3);
            this.prescriberID = null;
            this.drugID = null;
            this.insuranceID = null;
            this.functionScreen = SharedDocuments[1];
            this.uploadDocList();
        } else if (this.route.includes("/drug")) {
            this._actvRoute.parent.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                params => (this.drugID = +params["pid"])
            );
            this.postDocFG.controls["drugId"].patchValue(this.drugID);
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Drug
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(1);
            this.patientID = null;
            this.prescriberID = null;
            this.insuranceID = null;
            this.functionScreen = SharedDocuments[3];
            this.uploadDocList();
        } else if (this.route.includes("/Insurance")) {
            this.postDocFG.controls["insuCarrierId"].patchValue(
                this.insuranceID
            );
            this.postDocFG.controls["docCatId"].patchValue(
                SharedDocuments.Insurance
            );
            // this.postDocFG.controls["docSubCatId"].patchValue(6);
            this.patientID = null;
            this.prescriberID = null;
            this.drugID = null;
            this.functionScreen = SharedDocuments[4];
            this.uploadDocList();
        }
    }

    fileUploaded(val: any) {
        this.uploadedFile = val;
        if (val.type === "application/pdf") {
            this.fileTypeToShow = "pdf";
        } else {
            this.fileTypeToShow = "image";
        }
        this.readsUrl(val);
    }

    createFG() {
        this.documentFG = this._fb.group(new Documents());
        this.postDocFG = this._fb.group({
            patientId: [],
            docCatId: [],
            docSubCatId: [],
            drugId: [],
            prescId: [],
            insuCarrierId: []
        });
    }

    get Name(): FormControl {
        return this.documentFG.controls["Name"].get("Name") as FormControl;
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
        if (document.getElementById("docSubCatId")) {
         const data: any = document.getElementById("docSubCatId").getElementsByTagName("input")[0];
         data.focus();
        }
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
    }
    focusOutFmAdd(event) {
        if (!this.showGrid) {
            this.focusOnCancel(event);
        }
    }

    focusOnCancel(event) {
        if (document.getElementById("docCancelBut")) {
            event.preventDefault();
            document.getElementById("docCancelBut").focus();
        }
    }

    changeName(val: any, type: any) {
        if (type === "title") {
            this.Title = val;
        } else if (type === "comments") {
            this.Comments = val;
        } else {
            this.SubCategory = val.value.Id;
        }
        this.DocSubCatId = this.postDocFG.value.docCatId;
    }

    uploadDocList() {
        this.getDocSubcats();
        this._docService.getDocList(this.postDocFG.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.documents = resp.Documents;
                if (this.documents) {
                    this.showGrid = true;
                    this.docData = this.documents;
                    this.generateList(this.documents);
                } else {
                    this.showGrid = false;
                    this.generateList(null);
                    this.imageToShow = "";
                }

                this.subCatList = resp;
                delete this.subCatList["Documents"];
            }
        });
    }

    getDocSubcats() {
        this._docService.getDocumentSubCats({ CatId: this.postDocFG.value.docCatId })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.subCatDropdowns = resp;
            }
        });
    }

    generateList(data: any) {
        if (data) {
            this.documentsList = new CollectionView(
                data.map((document, i) => {
                    const j = {};
                    j["data"] = document;
                    j["row"] = i;
                    j["Id"] = document.Id;
                    j["Date"] = moment(document.CreatedDtTm).format("MM/DD/YYYY");
                    j["Document ID"] = document.Id;
                    j["Title"] = document.Name;
                    j["Category"] = SharedDocuments[document.DocCatId];
                    j["Comment"] = document.Remarks === "null" ? "" : document.Remarks;
                    j["extn"] = document.Extn;
                    j["FileName"] = document.FileName;
                    j["TenantId"] = document.TenantId;
                    j["CreatedDtTm"] = document.CreatedDtTm;
                    j["ModifiedDtTm"] = document.ModifiedDtTm;
                    return j;
                })
            );
            this.actvHeaders = [
                "Actions",
                "Date",
                "Document ID",
                "Title",
                "Category",
                "Comment"
            ];
        }
    }

    selectSubCategory(val: any) {
        this.postDocFG.controls["docSubCatId"].patchValue(val.value.Id);
        this._docService.getDocList(this.postDocFG.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.imageToShow = "";
                this.fileTypeToShow = null;
                this.documents = resp.Documents;
                if (this.documents) {
                    this.showGrid = true;
                    this.docData = this.documents;
                    this.generateList(this.documents);
                } else {
                    this.showGrid = false;
                    this.imageToShow = "";
                    this.generateList(null);
                }

                this.subCatList = resp;
                delete this.subCatList["Documents"];
            }
        });
    }



    downloadSelectedFile(val: any) {
        this._docService.getDocumentByName(val)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.setUpForDownload(resp, val["FileName"]);
                this.createImageFromBlob(resp);
            }
        });
    }

    previewSelectedFile(val: any) {
        this._docService.getDocumentByName(val)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (resp.type === "application/pdf") {
                    this.fileTypeToShow = "pdf";
                } else {
                    this.fileTypeToShow = "image";
                }
                this.createImageFromBlob(resp);
            }
        });
    }

    createImageFromBlob(image: Blob) {
        const urlCreator = window.URL;
        this.imageToShow = this.sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image)
        );
    }

    setUpForDownload(response: any, fileName: any) {
        const extensionTag = fileName.split(".");
        const extensionTagChrtrs = extensionTag.pop();
        const a: any = document.createElement("a");
       // document.body.appendChild(a);
        a.style = "display: none";
        const data: any = response;
        const url = URL.createObjectURL(data);
        a.href = url;
        a.download = fileName;
        a.click();
       // a.parentNode.removeChild(a);
    }
    addEventonWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.opendocModal(content);
            }
        }
    }

    editEventOnWijimo(content, event, grid) {
        if (grid["selectedItems"][0]) {
            event.preventDefault();
            this.editDocPopup(content, grid["selectedItems"][0]);
        }
    }

    opendocModal(docData) {
        this.fileTypeToShow = null;
        this.src = " ";
        this.editMode = false;
        this.fileName = "";
        this.showUpload = true;
        this.documentFG.patchValue({
            Name: null
        });
        this.documentFG.patchValue({
            Remarks: null
        });
        this.documentFG.patchValue({
            DocSubCatId: this.postDocFG.value.docSubCatId
        });
        this.SubCategory = this.documentFG.value.DocSubCatId;
        this.modalRef = this.modalService.open(docData, { size: "lg" , keyboard:false, backdrop:'static'});
        this.getDocDetails();
    }

    editDocPopup(docData, val: any) {
        this.fileTypeToShow = null;
        this.editMode = true;
        this.src = "";
        this.showUpload = false;
        this.imageToShow = "";
        this.modalRef = this.modalService.open(docData, {
            size: "lg",
            centered: true,
            keyboard: false,
            backdrop:'static'
        });
        const rowId = val.row;
        this.docRowInfo = this.docData[rowId];
        this.documentFG.patchValue({
            Name: val.Title,
            Remarks: val.Comment,
            DocNum: val.Id,
            DocSubCatId: this.docRowInfo.DocSubCatId,
            Id: val.Id
        });
        this.originalData = _.clone(val.data);
    }

    deleteDocPopup(deleteInfo, val: any) {
        this.modalRef = this.modalService.open(deleteInfo, { centered: true , keyboard:false});
        this.currentDoc = val;
    }

    deleteDoc() {
        // const docId = this.currentDoc["Id"];
        // const extn = this.currentDoc["extn"];
        // this._docService
        //     .deleteDocument(
        //         docId,
        //         this.patientID,
        //         this.drugID,
        //         this.prescriberID,
        //         this.insuranceID,
        //         extn,
        //         this.prescNumber
        //     )
        //     .subscribe(resp => {
        //         if (resp) {
        //             if (this.patientID) {
        //                 this._NewAuditUtils.SaveAuditOnDelete(
        //                     null,
        //                     [{name: "Id", value: docId}],
        //                     "Delete Documents",
        //                     "Patient",
        //                     this.patientID
        //                 );
        //             } else if (this.drugID) {
        //                 this._NewAuditUtils.SaveAuditOnDelete(
        //                     null,
        //                     [{name: "Id", value: docId}],
        //                     "Delete Documents",
        //                     "Drug",
        //                     this.drugID
        //                 );
        //             } else if (this.prescriberID) {
        //                 this._NewAuditUtils.SaveAuditOnDelete(
        //                     null,
        //                     [{name: "Id", value: docId}],
        //                     "Delete Documents",
        //                     "Prescriber",
        //                     this.prescriberID
        //                 );
        //             } else if (this.insuranceID) {
        //                 this._NewAuditUtils.SaveAuditOnDelete(
        //                     null,
        //                     [{name: "Id", value: docId}],
        //                     "Delete Documents",
        //                     "Insurance",
        //                     this.insuranceID
        //                 );
        //             }
        //             this._alertService.success(MsgConfig.DELETE_SUCCESS);
        //             this.uploadDocList();
        //         } else {
        //             this._alertService.error(MsgConfig.DELETE_FAIL);
        //         }
        //     });
    }

    getDocDetails() {
        this._docService
            .getDocDetails(
                this.functionScreen,
                this.patientID,
                this.drugID,
                this.prescriberID,
                this.insuranceID,
                this.prescNumber
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.docInfo = resp;
                    this.patchValue();
                }
            });
    }

    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.docInfo) {
            if (key && this.docInfo[key]) {
                if (key !== "DocSubCatId") {
                    this.documentFG.controls[key].patchValue(this.docInfo[key]);
                }
            }
        }
    }

    saveDocuments() {
        if (this.documentFG.valid) {
            this.uploadDocument();
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
        }
    }

    uploadDocument() {
        const endpoint = this.DocURL.replace(
            "{requestFrom}", "" + this.functionScreen)
            .replace("{patientId}", "" + this.patientID)
            .replace("{drugId}", "" + this.drugID)
            .replace("{prescId}", "" + this.prescriberID)
            .replace("{prescNum}", "" + this.prescNumber)
            .replace("{insuCarrierId}", "" + this.insuranceID)
            .replace("{docCatId}", "" + this.DocSubCatId)
            .replace("{docSubCatId}", "" + this.SubCategory)
            .replace("{name}", "" + this.documentFG.value.Name)
            .replace("{remarks}", this.Comments ? this.Comments : "Test");
         this._userService.postFile(endpoint, this.uploadedFile)
         .pipe(takeUntil(this.unsubscribe$))
         .subscribe(data => {
            if (this.modalRef) {
            this.modalRef.close();
            }
            this.uploadDocList();
        }, error => {
                this._alertService.error("File upload unsuccessful.");
            });
    }

    updateDocuments() {
        if (this.documentFG.valid) {
            this.docRowInfo.Name = this.documentFG.value.Name;
            this.docRowInfo.Remarks = this.documentFG.value.Remarks;
            this.docRowInfo.DocSubCatId = this.documentFG.value.DocSubCatId;
            this._docService
                .updateDocuments(this.docRowInfo)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        const documentModel = [{
                            HeaderName: "Name",
                            ControlName: "Name"
                        }, {
                            HeaderName: "Remarks",
                            ControlName: "Remarks"
                        }, {
                            HeaderName: "DocSubCatId",
                            ControlName: "DocSubCatId"
                        }];
                        if (this.patientID) {
                            this._NewAuditUtils.saveAuditChange(
                                this.originalData,
                                this.docRowInfo,
                                "Edit Documents",
                                "Patient",
                                this.patientID,
                                documentModel
                            );
                        } else if (this.prescriberID) {
                            this._NewAuditUtils.saveAuditChange(
                                this.originalData,
                                this.docRowInfo,
                                "Edit Documents",
                                "Prescriber",
                                this.prescriberID,
                                documentModel
                            );
                        } else if (this.insuranceID) {
                            this._NewAuditUtils.saveAuditChange(
                                this.originalData,
                                this.docRowInfo,
                                "Edit Documents",
                                "Insurance",
                                this.insuranceID,
                                documentModel
                            );
                        } else if (this.drugID) {
                            this._NewAuditUtils.saveAuditChange(
                                this.originalData,
                                this.docRowInfo,
                                "Edit Documents",
                                "Drug",
                                this.drugID,
                                documentModel
                            );
                        }

                        this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                        if (this.modalRef) {
                        this.modalRef.close();
                        }
                        this.uploadDocList();
                    } else {
                        this._alertService.error(MsgConfig.UPDATE_FAIL);
                    }
                });
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
        }
    }

    editImage() {
        if (this.uploadedFile) {
            let modelRef = this.modalService.open(ImageEditorComponent, { size: "lg", keyboard: false, backdrop: 'static' });
            // modelRef.componentInstance.ImageData = this.src;
            modelRef.componentInstance.ImageFile = this.uploadedFile;

            modelRef.componentInstance.IsPopUpClosed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(base64 => {
                if (base64) {
                    this.urltoFile(base64, "Edited.png", "image/png")
                    .then((file) => {
                        this.fileUploaded(file);
                    });
                }
                modelRef.close();
            });
        }
    }

    urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then((res) => res.arrayBuffer())
            .then((buf) => new File([buf], filename, { type: mimeType }))
        );
    }

    readsUrl(event: any) {
        if (event) {
            // tslint:disable-next-line:prefer-const
            let reader = new FileReader();

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event: any) => {
                this.src = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
            };
            reader.readAsDataURL(event);
        }
    }
}
