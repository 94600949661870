import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { CommonService } from "../../../services";
import { FormGroup } from "@angular/forms";
import { RegEx } from "src/app/app.regex";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-search-zip",
    templateUrl: "./search-zip.component.html"
})
export class SearchZipComponent implements OnInit {
    inputFormGroup: FormGroup;
    custContrlName: string;
    inputErrors: any;
    isRequired = false;
    zipRegex: RegExp;
    errorDefs: object;
    hasFGInvalid: boolean;
    invalidZip = false;
    tabIndex: number;
    PhnRegex: any;
    markAsTocuhed: boolean;
    oldValue: any;
    unsubscribe$: Subject<void> = new Subject();
    rxSelectId: string;

    @Input() autoFocus: boolean;
    @Input() zipExtnCntrl: string;
    @Input() isForErx: false;;
    @Input() zipExtnFG: FormGroup;
    @Input() isFromAccSettings;
    maxExtLen = 10;

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
    }
    @Input()
    set FormGroupInvalid(hc: boolean) {
        this.hasFGInvalid = hc;
    }

    @Input()
    set PatchZip(zip: any) {
        if (zip) {
            this.getCityStateFromZipCode(zip);
        }
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        this.markAsTocuhed = mt;
    }

    @Input()
    set RxSelectId(mt: string) {
        this.rxSelectId = mt;
    }

    @Output()
    OnZipSelected = new EventEmitter<any>();

    constructor(private _commonServ: CommonService) {
        this.PhnRegex = RegEx;
        this.zipRegex = new RegExp(RegEx.Zipregex);
    }

    ngOnInit() {
        if (this.inputFormGroup && this.custContrlName) {
            this.oldValue = this.inputFormGroup.controls[this.custContrlName].value;
        }
    }
    getCityStateFromZipCode(value) {
        if (this.oldValue && this.oldValue === value) {
            return;
        } else {
            this.oldValue = value;
            if (value && this.zipRegex.test(value) ) {
                this._commonServ.getStateCityDataByZip(value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(zipMaster => {
                        this.OnZipSelected.emit(zipMaster);
                });
            }
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
