export class ErxFilter {
    StatusIds: any = null;
    FromDate: Date = null;
    ToDate: Date = null;
    SearchText: String = null;
    SearchBy: String = null;
    PageNumber: number = null;
    PageSize: number = null;
    CategoryIds: any[] = [];
    OrderByColumn: string = null;
    SortDirection: string = null;
    FirstName : string = null;
    LastName : string = null;
    DOB : string = null
}

export class Data {
    patientId: number = null;
    PatientIdForDelete: number = null;
    statusId: number = null;
}

export class ErxProcess {
    eRxId: number = null;
    PatSearchString: any;
    PrescSearchString: any;
    DrugSearchString: any;
    ErxOpTransactionId: number = null;
    PatientPhone: any;
    ErxOperationId: any;
}

export enum ErxVersions {
    OldVersion = "v10.6",
    NewerVersion = "v2017071"
}

export class ErxExceptionReport {
    PatientId: number = null;
    PrescriberId: number = null;
    DrugId: number = null;
    FacilityCode: number = null;
    InsuplanCode: number = null;
    Drugclass: any[] = null;
    MisMatchedCriteria: any[] = null;
    DrugType: any = null;
    ShowRefillsWithError: number = null;
    ExcludeRxWithErxNotes: number = null;
    ExcludePharmacistVefiriedRx: number = null;
    FromDate: Date = null;
    ToDate: Date = null;
    filterText: any = null;
    pageSize: number = null;
    pageNumber : number =null
}

export class Tags {
    PrescNum: any = null;
    RefillNum: any = null;
    PrescRefillId: any = null;
    PrescRefill_RxTags: any = null;
}

export enum ErxStatusAndMessageTypeEnums {
    NewRx = 1,
    RxChangeResponse = 6,
    REFRES = 4,
    CancelRx = 5,
    ReadyToProcess = 1,
    Removed = "true"
}
