import { Subscription } from 'rxjs/internal/Subscription';
import { BucketService } from './../../../services/bucket.service';
import { MsgConfig } from "src/app/app.messages";
import { AlertService, PreviousRouteService } from "src/app/services";
import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { SystemData } from "./../../../models/system-data.model";
import { EditDrugService } from "./../../../services/edit-drug.service";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../../../services";
import { Drug, PaginationModel } from "../../../models";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import { RxUtils } from '../../../utils';
import { UdpCurrencyMaskPipe } from '../../../pipes';

@Component({
    selector: "app-drug-bucket-pricing",
    templateUrl: "./drug-bucket-pricing.component.html"
})
export class DrugBucketPricingComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    searchRxTagFG: FormGroup;
    addBuck: FormGroup;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    drugWarn: any;
    disWarnWJ: CollectionView;
    actvHeaders: string[];
    totalCount: number;
    hideFilterForm = false;
    tagName: any;
    SuggestData: any;
    editMode = false;
    tagInfo: any;
    canAdd = true;
    drugId: number;
    systemData: SystemData;
    canAddDrop = true;
    drugInfo: Drug;
    drugName: string;
    deleteData: any;
    bucketDropDownData: any[] = [];
    @ViewChild("bucket")
    flex: wjcGrid.FlexGrid;
    modal: any;
    formGroupInvalid = false;
    bucketData: any;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    numberPipe = new UdpCurrencyMaskPipe();
    activeModal: string;
    deletepop: any;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    originalData(arg0: any, arg1: any, arg2: any, arg3: any, arg4: any): any {
        throw new Error("Method not implemented.");
    }

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.routeBackToPrevScreen();
            }
        } else  if (event.which === 27 && this.modal) { // esc
            this.modal.close();
            event.preventDefault();
        }

    }


    constructor(
        private _drg: EditDrugService,
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private _auditUtils: AuditLogUtils,
        private _alertServ: AlertService,
        private _route: Router,
        private _rxutils: RxUtils,
        private _bucketSvc: BucketService
    ) {
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
             if (params && params.fm) {
                 this.routeFrom = params.fm;
             }
             if (params && params.focus) {
                if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                    this.focusOnDU = params.focus;
                }
            }
         }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugId = +params["pid"])
        ));
    }

    ngOnInit() {
        // this._commonServ.getSystemData();
        // this._commonServ.systemData$.subscribe(resp => {
        //     this.systemData = resp;
        // });
        this._bucketSvc.getBucketsForDropdowns().subscribe(resp => {
            if (resp) {
                this.bucketData = resp;
            }
        });
        this.addBuck = this._fb.group({
            Id: null,
            DrugBucketId: null,
            TenantId: -7101307679,
            UnitPrice: null,
            DrugId: this.drugId,
            IsActive: true
        });
        this.getInfo();
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    getInfo() {
        this._drg
            .getBucketPricing(this.drugId, this.pageNu, this.Size)
            .subscribe(resp => {
                this.tagInfo = resp;
                this.generateWJ(resp);
            });
    }

    get DrugBucketId(): FormControl {
        return this.addBuck.get("DrugBucketId") as FormControl;
    }
    get UnitPrice(): FormControl {
        return this.addBuck.get("UnitPrice") as FormControl;
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    generateWJ(info: any) {
        if (info.length) {
            this.totalCount = info[0].TotalCount;
            this.disWarnWJ = new CollectionView(
                info.map((drugWarn, i) => {
                    const j = {};
                    j["Bucket Name"] = drugWarn.BucketName.toUpperCase();
                    j["Unit Pricing"] = drugWarn.UnitPrice;
                    j["Actions"] = " ";
                    j["bucketId"] = drugWarn.BucketId;
                    j["Id"] = drugWarn.BucketDrugId;
                    return j;
                })
            );
            this.actvHeaders = ["Actions", "Bucket Name", "Unit Pricing"];
        } else {
            this.totalCount = 0;
        }
    }
    setPage(page: number) {
        this.pageNu = page;
        this.getInfo();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        this.getInfo();
    }
    dropDownData() {
        if (this.bucketDropDownData) {
            this.bucketDropDownData = [];
        }
        for (const key in this.bucketData) {
            if (key) {
                for (const keys in this.tagInfo) {
                    if (
                        this.tagInfo[keys].BucketId !==
                        this.bucketData[key].Id
                    ) {
                    } else {
                        this.canAddDrop = false;
                    }
                }
                if (this.canAddDrop === true) {
                    this.bucketDropDownData.push(
                        this.bucketData[key]
                    );
                }
                this.canAddDrop = true;
            }
        }
    }
    openPopUpModal(content) {
        this.addBuck.controls["DrugBucketId"].reset();
        this.addBuck.controls["DrugBucketId"].markAsUntouched();
        this.addBuck.controls["UnitPrice"].reset();
        this.addBuck.controls["UnitPrice"].markAsUntouched();
        this.editMode = false;
        this.dropDownData();
        this.addBuck.controls["DrugBucketId"].patchValue(1);
        this.modal = this.modalService.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Add";
    }

    deletePopUpModal(content, data: any) {
        this.deleteData = data;
       this.modal = this.modalService.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Delete";
    }
    closePopUp() {
        this.bucketDropDownData = [];
        this.activeModal = "";
    }
    editPopUpModal(content, val: any) {
        this.editMode = true;
        const dataInfo = this.addBuck.value;
        this.dropDownData();
        for (const key in this.bucketData) {
            if (this.bucketData[key].Id === val.bucketId) {
                this.bucketDropDownData.push(this.bucketData[key]);
            }
        }
        if (dataInfo) {
            this.addBuck.controls["DrugBucketId"].patchValue(val["bucketId"]);
            this.addBuck.controls["UnitPrice"].patchValue(
                val["Unit Pricing"].toFixed(2)
            );
            this.addBuck.controls["Id"].patchValue(val["Id"]);
        }
        this.originalData = this.addBuck.value;
        this.modal = this.modalService.open(content, { centered: true, keyboard: false,
            backdrop: false });
        this.activeModal = "Update";
    }

    addBucketPrice() {
        const dataInfo = this.addBuck.value;
        dataInfo["UnitPrice"] = Number(dataInfo["UnitPrice"]);
        if (this.addBuck.valid) {
            this.formGroupInvalid = false;
        this._drg.addBucketPricing(dataInfo).subscribe(resp => {
            if (resp) {
                this._auditUtils.getChangedValues(
                    null,
                    dataInfo,
                    "Add BucketPrice",
                    "Drug",
                    this.drugId
                );
                this.modal.close();
                this.activeModal = "";
                this.getInfo();
                this._alertServ.success(MsgConfig.SAVE_SUCCESS);
            } else {
                this._alertServ.error(MsgConfig.SAVE_FAIL);
            }
        });
        this.addBuck.controls["DrugBucketId"].reset();
        this.addBuck.controls["DrugBucketId"].markAsUntouched();
        this.addBuck.controls["UnitPrice"].reset();
        this.addBuck.controls["UnitPrice"].markAsUntouched();
        this.bucketDropDownData = [];
        } else {
            this.formGroupInvalid = true;
        }
    }
    updateBucketPrice() {
        if (this.addBuck.valid) {
            this.formGroupInvalid = false;
            const data = this.addBuck.value;
            data["UnitPrice"] = Number(data["UnitPrice"]);
           // this.addBuck.controls[ControlName].patchValue((this.addBuck.value[ControlName]).toFixed(2));
        this._drg.editBucketPricing(data).subscribe(resp => {
            if (resp) {
                this._auditUtils.getChangedValues(
                    this.originalData,
                    data,
                    "Edit BucketPrice",
                    "Drug",
                    this.drugId
                );
                this.modal.close();
                this.activeModal = "";
                this.getInfo();
                this._alertServ.success(MsgConfig.UPDATE_SUCCESS);
            } else {
                this._alertServ.error(MsgConfig.UPDATE_FAIL);
            }
        });
        this.bucketDropDownData = [];
    } else {
        this.formGroupInvalid = true;
    }
    }
    deleteBucketPrice() {
        const val = this.deleteData;
        const dataInfo = this.addBuck.value;
        dataInfo.Id = val.Id;
        dataInfo.DrugBucketId = val.bucketId;
        dataInfo.UnitPrice = val["Unit Pricing"];
        dataInfo.IsActive = false;
        this._drg.deleteBucketPricing(dataInfo).subscribe(resp => {
            if (resp) {
                this._auditUtils.deletedValues(
                    dataInfo.Id,
                    "Delete Insurance Restrictions",
                    "Drug",
                    this.drugId
                );
                this._alertServ.success(MsgConfig.DELETE_SUCCESS);
                this.getInfo();
            } else {
                this._alertServ.error(MsgConfig.DELETE_FAIL);
            }
        });
    }
    exportExcel() {
        wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
            this.flex,
            {
                includeColumns: function(column) {
                    return column.binding !== "Actions";
                },
                formatItem: (args) =>  args.xlsxCell.formula = null
            },
            "drugBucketPricing.xlsx"
        );
    }
    routeBackToPrevScreen() {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    setNumberFormat(ControlName) {
        this.addBuck.controls[ControlName].patchValue((Number(this.addBuck.value[ControlName])).toFixed(3));
    }

    openAddModalOnEnter(content) {
        setTimeout(() => {
            this.openPopUpModal(content);
        }, 100);
    }

    keyEventOnWijimo(content, grid) {
        if (this.totalCount) {
            event.preventDefault();
            this.editPopUpModal(content, grid.selectedItems[0]);
        }
    }

    focusOutFromAdd(event) {
        if (!this.totalCount) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.flex.focus();
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    addEventOnWijimo(event, content) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openPopUpModal(content);
            }
        }
    }
}
