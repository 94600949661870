
import {of as observableOf,  Observable } from 'rxjs';
import { RphFilters } from "./../models/rph-verify.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constant } from "./../app.constants";
import { DrugInteraction, DrugInteractionData, ErxVersions } from "../models";

@Injectable({
    providedIn: "root"
})
export class RphVerificationService {
    constructor(private _http: HttpClient,) {}

    sendRphVerifyFilters(filtersInfo: RphFilters): Observable<any> {
        let headers = new HttpHeaders();
        if (sessionStorage.getItem("rph-verify")) {
            headers = headers.set("rph-token", sessionStorage.getItem("rph-verify"));
        }
        return this._http.post<any>(
            constant.POST_RphVerification_Filters,
            filtersInfo, { headers }
        );
    }
    sendRxVerifyFilters(filtersInfo: RphFilters): Observable<any> {
        let headers = new HttpHeaders();
        if (sessionStorage.getItem("rx-verify")) {
            headers = headers.set("rx-token", sessionStorage.getItem("rx-verify"));
        }
        return this._http.post<any>(
            constant.POST_RxVerification_Filters,
            filtersInfo, { headers }
        );
    }
    getRphVerifyDetails(rxIdData: any,type: boolean = false): Observable<any> {
        let headers = new HttpHeaders();
        if (sessionStorage.getItem("rph-verify") && !type) {
            headers = headers.set("rph-token", sessionStorage.getItem("rph-verify"));
        } else if (sessionStorage.getItem("drug-pick") && !type) {
            headers = headers.set("rph-token", sessionStorage.getItem("drug-pick"));
        } else if (sessionStorage.getItem("rx-verify") && type) {
            headers = headers.set("rx-token", sessionStorage.getItem("rx-verify"));
        }
        let url = constant.POST_RphVerification_Filters;
        if (type) {
            url = constant.POST_RxVerification_Filters;
        }
        return this._http.post<any>(
            url,
            rxIdData, { headers }
        );
    }

    getActioButtonsCount(params: any): Observable<any> {
        return this._http.post<any>(constant.POST_GET_ACTIONBUTTONS_COUNT,params);
    }

    getRxVerifyDetails(rxId: number, Version?: any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.GET_eRx_ReadyToProcess.replace("{eRxId}", "" + rxId);
        } else {
            url =  constant.GET_eRx_ReadyToProcess.replace("{eRxId}", "" + rxId);
        }
        return this._http.get<any>(url);
    }
    getRphVerifyUsers(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_RphVerification_UserSuggest.replace(
                "{user}",
                "" + value
            )
        );
    }

    getEmptyObservable(): Observable<any> {
        return observableOf([]);
    }

    IsPharmacyDrugPickVerified(): Observable<any> {
        return this._http.get<any>(
            constant.GET_RphVerification_IsPharmacyDrugPickVerified
        );
    }

    IsDrugPickVerified(presnum: any, prescRefId: any, fillNum: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_RphVerification_IsDrugPickVerified
                .replace(
                    "{prescnum}",
                    "" + presnum
                ).replace(
                "{prescRefillId}",
                "" + prescRefId
            ).replace("{PartialfillNum}", "" + fillNum)
        );
    }

    IsRphVerifiedAlready(prescId: number, prescRefId: number, isForRxVerification: boolean): Observable<any> {
        return this._http.get<any>(
            constant.GET_RphVerification_IsAlready.replace(
                "{prescId}",
                "" + prescId
            ).replace("{presRefillId}", "" + prescRefId).replace("{isForRxVerification}", "" + isForRxVerification));
    }

    GetRxFollowUpreason(prescId: number, prescRefId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_RphVerification_RxFollowUp.replace(
                "{prescId}",
                "" + prescId
            ).replace("{prescRefillId}", "" + prescRefId)
        );
    }

    PostRphVerify(postData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_RphVerification_Verify,
            postData
        );
    }

    getDrugInteractions(
        drugData:DrugInteractionData
    ): Observable<DrugInteraction> {
        return this._http.post<DrugInteraction>(
            constant.GET_Drug_Interactions, drugData
        );
    }

    GetRxVerifyHistory(prescId: number, refilNumber: number, fillNo: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_RphVerification_History.replace(
                "{rxid}",
                "" + prescId
            ).replace("{refilno}", "" + refilNumber).replace("{partialFillNum}", "" + fillNo)
        );
    }

    GetERxIds(erxId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_ERX_ProcessIds.replace("{eRxId}", "" + erxId)
        );
    }

    getPatientDurInfo(patientId): Observable<any> {
        return this._http.get<any>( constant.GET_PATIENT_DUR_INFO.replace("{patientId}", "" + patientId));
    }

    getRphVerifHistory(payload: any): Observable<any> {
        let headers = new HttpHeaders();
        if (sessionStorage.getItem("rph-verify")) {
            headers = headers.set("rph-token", sessionStorage.getItem("rph-verify"));
        }
        return this._http.post<any>( constant.POST_PHARM_VERIF_HISTORY, payload, { headers } );
    }


   SendBatchRphVerificationDetails(data: any) {
        return this._http.post<any>(
            constant.Post_BatchRphVerification,
            data
        );
    }

}
