<eprx-select [LabelText]="''" [PlaceHolder]="''" [HasMultiple]="false"
    [HasBindLabel2]="true" [BindLabel]="'PlanCode'" [FormGroupName]="insuCopyFG" [ControlName]="'insuId'"
    [BindLabel2]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="'null'"
    (TriggerSelectValue)="selectedValue($event)" [IsFirstElem]="true">
</eprx-select>

<eprx-select [LabelText]="'Select Ins Code to Copy From'" [PlaceHolder]="''"
[ControlName]="'insuId'" [FormGroupName]="insuCopyFG" [RxSelectId]="'CopyList'"
[HasMultiple]="false" [Title]="'Select Ins Code to Copy From'" [HasBindLabel2]="true"
[BindLabel]="'InsurerCode'" [BindLabel2]="'InsuranceName'" [BindValue]="'Id'" [LabelForId]="'Id'"
[List]="insuList$ | async">
</eprx-select>
