import { Directive, Input, SimpleChanges, Renderer2, ElementRef, OnChanges } from '@angular/core';
import { CommonUtil } from './common.util';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {
    @Input() searchedWords: any;
    @Input() text: string;
    @Input() classToApply: string;
    @Input() fromPhone: boolean;
    @Input() searchWithText: string;
    @Input() searchWord2: string;

    constructor(private el: ElementRef, private renderer: Renderer2, private _cmnUtil: CommonUtil) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.searchedWords) {
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.text);
            return;
        }
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.getFormattedText());
    }

    getFormattedText() {
        if ((this.text || this.searchWithText) && (this.searchedWords || this.searchWord2)) {
            if (this.text.toLowerCase().startsWith(this.searchedWords.toLowerCase()) ||
                (this.fromPhone && this.searchWithText.toLowerCase().startsWith(this.searchWord2.toLowerCase()))) {
                this.searchedWords = !this.fromPhone ? this._cmnUtil.checkAndReplaceSpclChars(this.searchedWords) : this.searchedWords;
                const re = new RegExp(`(${this.searchedWords})`, 'i');
                return this.text.replace(re, `<span class="${this.classToApply ? this.classToApply : 'high-light'}">$1</span>`);
            } else {
                return this.text;
            }
        } else {
            return this.text;
        }
    }
}
