import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { searchTrackPatientCriteria } from 'src/app/models';
import { CommonHeaders } from 'src/app/models/labels.models';
import { AlertService } from 'src/app/services';
import { NotesLogService } from 'src/app/services/notes-log.service';

@Component({
  selector: 'app-single-notes',
  templateUrl: './single-notes.component.html',
  styleUrls: ['./single-notes.component.scss']
})
export class SingleNotesComponent implements OnInit {
  commonlabels: any;
  notesLogFG: FormGroup;
  notesLogInfo: any;
  searchParams: searchTrackPatientCriteria;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '50px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Notes',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      toolbarHiddenButtons: [
        ['undo',
        'redo',
        'underline',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'],
        [
            'fontSize',
            'textColor',
            'backgroundColor',
            'customClasses',
            'link',
            'unlink',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
            'removeFormat',
            'toggleEditorMode'
          ]
      ]
};
unsubscribe$: Subject<void> = new Subject();
@Input() PrescReFillId : any;
@Input() PrescriptionId : any;
@Input() HeaderText : any;
@Input() BodyText : any;
@Input() RxType : any;
@Input() MainCategory : any;

modalRef: any;
@Output() IsPopUpClosed = new EventEmitter<any>();
@ViewChild("SingleNotesPopup", {static: true})
SingleNotesPopup: any;

  constructor(  private _notesLogSvc: NotesLogService, private _mdlServ: NgbModal, private _alertService: AlertService) { }

  ngOnInit(): void {
    this.createNoteForm();
    this.modalRef = this._mdlServ.open(this.SingleNotesPopup, { backdrop: "static", keyboard: false, centered: true});
    this.commonlabels = new CommonHeaders();
    this.getNotesSubacatList();
  }
  createNoteForm() {
    this.notesLogFG = new FormGroup({
        Id: new FormControl(),
        TenantId : new FormControl(),
        AppUserId:  new FormControl(),
        xNote:   new FormControl(),
        NoteCatId:  new FormControl(),
        IsShownAsPopup: new FormControl(),
        IsActive:   new FormControl(),
        IsDeleted:  new FormControl(),
        CreateDtTm:  new FormControl(),
        ModifiedDtTm:  new FormControl(),
        Name:  new FormControl(),
        PopupScreenCodes:  new FormControl(),
        IsAddItem: new FormControl(),
    });
    this.setDefaultValues();
}
setDefaultValues() {
    this.notesLogFG.controls['IsActive'].patchValue(true);
    this.notesLogFG.controls['AppUserId'].patchValue(2);
    this.notesLogFG.controls['CreateDtTm'].patchValue(moment(Date.now()).format("MM/DD/YYYY"));
    this.notesLogFG.controls['ModifiedDtTm'].patchValue( moment(Date.now()).local().format(
        "MM/DD/YYYY hh:mm:ss a"
    ));
    this.notesLogFG.controls['IsDeleted'].patchValue(false);
}
closePopup() {
     if(this.modalRef) {
          this.modalRef.close();
          this.modalRef = null;
     }
}
getNotesSubacatList() {
  this.searchParams = {
      SearchText: null,
      SearchBy: null,
      OrderByColumn: null,
      SortDirection: null,
      PageNumber: 1,
      PageSize: 30,
      PrescrefillId: null
  };
  this._notesLogSvc
  .getNotesLog(
      "RxAnnotation",
    this.PrescReFillId ? this.PrescReFillId : 0,
    "1973-01-01",
    moment(Date.now()).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN),
      this.searchParams
  )
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe((resp: any) => {
      if(resp)
      {
          this.notesLogInfo = resp;
      }
  });
}
continueSavingRx() {  
  this.closePopup();
  if(this.RxType === 'er') {
    this._notesLogSvc
    .addNotesLog("RxAnnotation",this.PrescriptionId, 
        {Note: [this.notesLogFG.value]})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp)
         this._alertService.success(MsgConfig.Notes_Saved);
    });  
    this.IsPopUpClosed.emit(false);
  } else
    this.IsPopUpClosed.emit(this.notesLogFG.value);
}
}
