<ng-template #FILEDUEDATE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click');closeFileRelatedModals()">
        <h4 header>Please Enter Defer Due Date</h4>
        <span body>
            <eprx-date-picker [AutoFocus]="true" [LabelText]="'Defer Due Date'" [PlaceHolder]="''"
                [ControlName]="'dueDateTime'" [RxSelectId]="'fileFGdueDateTime'" [FormGroupName]="fileFG" [MaxDate]=""
                [IsRequired]="true" [ErrorDefs]="{required: 'Required'}">
            </eprx-date-picker>
            <eprx-select [LabelText]="'Deferred Reason'" [PlaceHolder]="'Deferred Reason'" [Title]="'Deferred Reason'"
                [HasMultiple]="false" [HasBindLabel2]="false" [BindLabel]="'Name'" [BindValue]="'Id'"
                [LabelForId]="'Name'" [FormGroupName]="fileFG" [ControlName]="'deferredReason'"
                [List]="deferredReasonList">
            </eprx-select>
        </span>
        <span footer>
            <button ngbAutofocus type="button" class="hotkey_success" (click)="saveOrCancelFile('SAVE')" appShortcutKey
                [AltKey]="'s'">
                <span>S</span> Save
            </button>
            <button ngbAutofocus type="button" class="hotkey_success" (click)="closeFileRelatedModals()" appShortcutKey
                [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #WARNFILEMULTIRX let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click');closeFileRelatedModals()">
        <h4 header>
            {{(popUpOpenedFor === 'ALLNOTUNBILLED' || popUpOpenedFor === 'NOLATESTFILLS') ?  "Warning!!" : "Confirmation" }} </h4>
        <span body [innerHTML]="warningMsg"></span>
        <span footer *ngIf="popUpOpenedFor === 'ALLNOTUNBILLED' || popUpOpenedFor === 'NOLATESTFILLS'">
            <button id="btnOK" autofocus  type="submit" class="hotkey_primary" (click)="d('Cross click');closeFileRelatedModals()" appShortcutKey
                InputKey="o">
                <b>O</b> Ok
            </button>
        </span>
        <span footer *ngIf="popUpOpenedFor === 'FEWUNBILLED' || popUpOpenedFor === 'FEWLATESTFILLS'">
            <button id="btnYES" *ngIf="popUpOpenedFor === 'FEWUNBILLED'" type="submit" class="hotkey_primary" (click)="d('Cross click');checkLatestFill()" appShortcutKey
                InputKey="y">
                <b>Y</b> YES
            </button>
            <button id="btnYES" *ngIf="popUpOpenedFor === 'FEWLATESTFILLS'" type="submit" class="hotkey_primary" (click)="d('Cross click');checkForDpvNRpvVerificationExistOrNot()" appShortcutKey
                InputKey="y">
                <b>Y</b> YES
            </button>
            <button id="btnNO"  type="submit" class="hotkey_primary" (click)="d('Cross click');closeFileRelatedModals()" appShortcutKey
                InputKey="n">
                <b>N</b> NO
            </button>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #VERFCONFIRMATION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');flagDpvNRpv(false)">
        <h4 header> Confirmation </h4>
        <span body>
            <span [innerHTML]="warningMsg"> </span>
        </span>
        <button footer class="hotkey_primary" ngbAutofocus (click)="c('Close click');flagDpvNRpv(true)"
            (keydown.enter)="c('Close click');flagDpvNRpv(true, $event)" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="c('Close click');flagDpvNRpv(false)"
            (keydown.enter)="c('Close click');flagDpvNRpv(false, $event)" appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #ResubmitConfirm let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');submitResubmission(false)">
        <h4 header> Confirmation</h4>
        <span body>
            <div class="row">
                <div class="col-12">
                    This Rx#: <b>{{DEARxInfo.Prescription.PrescNum}}-{{DEARxInfo.PrescReFill.ReFillNum}}</b>  has been already reported to DEA, After filing, you must Re-Submit as a VOID RX.
                </div>
                <div class="col-12">
                    Would you like to Add it to the Resubmission List?
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary"  ngbAutofocus (click)="c('Close click');submitResubmission(true)" (keydown.enter)="c('Close click');submitResubmission(true, $event)" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="c('Close click');submitResubmission(false)" (keydown.enter)="c('Close click');submitResubmission(false, $event)" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>
