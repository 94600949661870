import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LabelPrintService {
    constructor(private _http: HttpClient) {}

    getPrintSetData(printSetParam: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_PrintSet_PrintSetSearch,
            printSetParam
        );
    }

    getLabelNames(): Observable<any> {
        return this._http.get<any>(constant.GET_PrintSet_LabelNameDrops);
    }

    addPrintSet(printSetData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_PrintSet_AddPrintSet,
            printSetData
        );
    }

    updatePrintSet(printSetData: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_PrintSet_UpdatePrintSet,
            printSetData
        );
    }

    deletePrintSet(printSetId: string): Observable<boolean> {
        return this._http.delete<boolean>(
            constant.DELETE_PrintSet_DeletePrintSet.replace(
                "{printSetId}",
                "" + printSetId
            )
        );
    }

    IsPrintSetNameExists(setName: any) {
        return this._http.get<any>(constant.GET_PrintSet_IsSetExists.replace("{SetName}", "" + setName));
    }
}
