import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostListener, OnDestroy } from "@angular/core";
import * as moment from "moment";
import { DrugService, CommonService } from "src/app/services";
import { EquivalentDrug, KeyCodes } from "src/app/models";
import { FormGroup } from "@angular/forms";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-drug-result-card",
    templateUrl: "./drug-result-card.component.html",
    styles: []
})
export class DrugResultCardComponent implements OnInit, OnDestroy {
    showDrugName: boolean;
    showOnlyImp: boolean;
    unsubscribe$: Subject<void> = new Subject();

    @Input() IsFrmErx: boolean;
    @Input() DrugSuggestFilterFG: FormGroup;
    @Input() searchTerm: any;
    @Input()
    set DrugResultData(drugData: any) {
        this.inputData = drugData;
    }

    @Input()
    set RouterURL(ru: string) {
        this.routerURL = ru;
    }

    @Input()
    set IsAllToShow(all: boolean) {
        this.isAllToShow = all;
    }

    @Input()
    set ShowImpValues(val: boolean) {
        this.showOnlyImp = val;
    }

    @Input()
    set ShowOnlyDrugName(drugName: boolean) {
        this.showDrugName = drugName;
    }

    @Input() index: any;

    @Output()
    SelectedDrug = new EventEmitter();

    @Output()
    EmitEqDrugs = new EventEmitter();

    @Output()
    EmitGenDrugs = new EventEmitter();

    @Output()
    EmitCloseEqDrugs = new EventEmitter();

    inputData: any;
    routerURL: string;
    isAllToShow: boolean;
    todayDate: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if ( !event.shiftKey && event.which === 39) { // right
            if (this.elementRef.nativeElement.parentElement.parentElement.className === "dropdown-item active") {
                this.getEquivalentDrugs();
                event.preventDefault();
            } else {
                event.preventDefault();
            }
        } else if (event.shiftKey && event.which === 39) {
            if (this.elementRef.nativeElement.parentElement.parentElement.className === "dropdown-item active") {
                this.getGenericDrugs();
            }
        }
    }

    constructor(private elementRef: ElementRef, private _drugSer: DrugService, private _commonServ: CommonService,
        private _buckUtils: DrugBucketUtil, public _formatsUtil:FormatsUtil) {
        this.todayDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date()
        };
    }

    ngOnInit() {}

    onRightClick(ev) {
        if (this.elementRef.nativeElement.parentElement.parentElement.className === "dropdown-item active") {
            this.getEquivalentDrugs();
            ev.preventDefault();
        } else {
            ev.preventDefault();
        }
    }

    getEquivalentDrugs() {
        this._drugSer.getEquivalentDrug(this.inputData.id, this.inputData.ndc, false, null, null,
            this.DrugSuggestFilterFG ? this.DrugSuggestFilterFG.value : null
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.EmitEqDrugs.emit(resp);
                } else {
                    this.EmitEqDrugs.emit(null);
                }
            });
    }

    getGenericDrugs() {
        this._drugSer.getGenericDrug({
            drugid: this.inputData.id,
            "therapeutic": this.inputData.therapeutic,
            "therapeuticcode": this.inputData.therapeuticcode,
            "isbranded": this.inputData.isbranded
        }).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    // this.EmitEqDrugs.emit(resp);
                    this.EmitGenDrugs.emit(resp);
                } else {
                    // this.EmitEqDrugs.emit(null);
                    this.EmitGenDrugs.emit(null);
                }
            });
    }

    selectedDrug(drug: string): any {
        this.SelectedDrug.emit({ drug });
    }

    getDrugStatus(drugInfo: any) {
        const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
        const isDrugDiscontinued = drugInfo["discontdt"] && moment().startOf("day")
            .isAfter(moment.utc(drugInfo["discontdt"]).local().add(days, "days"));
        if (isDrugDiscontinued && drugInfo.ispreferred) {
            return "card drug-card-red drug-card-brown";
        } else if (drugInfo["lastDispensedDate"] && drugInfo.ispreferred) {
            return "card drug-card-green drug-card-brown";
        } else if (isDrugDiscontinued) {
            return "card drug-card-red";
        } else if (drugInfo["lastDispensedDate"]) {
            return "card drug-card-green";
        } else if (drugInfo.ispreferred) {
            return "card drug-card-brown";
        } else {
            return "card";
        }
    }

    checkIsDrugDisc(drugInfo: any) {
        const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
        const isDrugDiscontinued = drugInfo["discontdt"] && moment().startOf("day")
            .isAfter(moment.utc(drugInfo["discontdt"]).local().add(days, "days"));
        if (isDrugDiscontinued) {
            return "drug-card-red";
        }
    }

    checkIsDrugLastDisp(drugInfo: any) {
        if (drugInfo["lastDispensedDate"]) {
            return "drug-card-green";
        }
    }

    checkIsDrugPref(drugInfo: any) {
        if (drugInfo.ispreferred) {
            return "drug-card-brown";
        }
    }

    checkIsPriceExist(manageInventory: any): boolean {
        if (!this.DrugSuggestFilterFG || !this.DrugSuggestFilterFG.value.BucketId) {
            return false;
        }
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.filter(buck => buck.BucketId === +this.DrugSuggestFilterFG.value.BucketId) || [];
            return isExist.length > 0 ? false : true;
        } else {
            return true;
        }
    }

    getQtyInHand(manageInventory: any): any {
        let defBuckId = null;
        if (!(this.DrugSuggestFilterFG && this.DrugSuggestFilterFG.value.BucketId) && !this.IsFrmErx) {
            return "0.000";
        }
        if (this.IsFrmErx) {
            defBuckId = this._buckUtils.getDefaultBucketId();
            if (!defBuckId) {
                return "0.000";
            }
        }
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId ===
                 (this.IsFrmErx ? +defBuckId : +this.DrugSuggestFilterFG.value.BucketId)) || null;
            return isExist ? isExist["QtyInHand"].toFixed(3) : "0.000";
        } else {
            return "0.000";
        }
    }

    getBucketSpecificPrice(manageInventory: any): any {
        let defBuckId = null;
        if (!(this.DrugSuggestFilterFG && this.DrugSuggestFilterFG.value.BucketId) && !this.IsFrmErx) {
            return "0.000";
        }
        if (this.IsFrmErx) {
            defBuckId = this._buckUtils.getDefaultBucketId();
            if (!defBuckId) {
                return "0.000";
            }
        }
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId ===
                 (this.IsFrmErx ? +defBuckId : +this.DrugSuggestFilterFG.value.BucketId)) || null;
            return isExist && isExist["UnitPriceCost"] ? isExist["UnitPriceCost"].toFixed(3) : "0.000";
        } else {
            return "0.000";
        }
    }

    ngOnDestroy() {
        if (this.elementRef.nativeElement.parentElement.parentElement &&
            this.elementRef.nativeElement.parentElement.parentElement.className === "dropdown-item active") {
            this.EmitCloseEqDrugs.emit();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
