import { Component, OnInit, Input, OnChanges, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from "src/app/services";
import { CommonStore } from "src/app/store";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-rx-prescriber-card",
    templateUrl: "./rx-prescriber-card.component.html",
    styles: []
})
export class RxPrescriberCardComponent implements OnInit, OnDestroy {
    PrescAddress: any;
    prescriberInfo: any;
    hasPatientHistory: boolean;
    drugInfo: any;
    isFromErx: any;
    unsubscribe$: Subject<void> = new Subject();
    Is340BStatus:boolean;
    @Input() rxFG: any;
    @Input() IsFromErxRequest: any;
    @Input() SelectedAddressId:any
    @Input()
    set PrescriberInfo(pi: any) {
        this.prescriberInfo = pi;
        this.displaySelectedAddrrss(false);
    }

    @Input()
    set HasPatientHistoryVisb(ph: boolean) {
        this.hasPatientHistory = ph;
    } 
    @Input()
    set DrungInfo(di: boolean) {
        this.drugInfo = di;
    }

    @Input()
    set IsFromERx(ifr: any) {
        this.isFromErx = ifr;
    }
    get Prescriber(): FormGroup {
        return this.rxFG?.get("Prescriber") as FormGroup;
    }
    constructor(private _commonStore: CommonStore, private _drugBuckUtil:DrugBucketUtil,private _commServ: CommonService, public _formatsUtil: FormatsUtil ) {}    
    ngOnInit() {
       this._commonStore._clinicalAddress$
       .pipe(takeUntil(this.unsubscribe$))
       .subscribe(resp => {
            if (resp && this.prescriberInfo) {
                this.prescriberInfo.PrescriberAddresses = resp;
                this.displaySelectedAddrrss(true);
            }
       })
    }
    async displaySelectedAddrrss(value){
      const preSetting =  await this._drugBuckUtil.checkPrescriber340OrNot(this.Prescriber);
      const settings = await this._commServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
      this.Is340BStatus = (preSetting && settings)? true : false;
      let rxtypecheck   = (value ? (this.rxFG && this.rxFG.value && this.rxFG.value.rxType && (this.rxFG.value.rxType == "nr" || this.rxFG.value.rxType == "er" || this.rxFG.value.rxType == "rf") ? true: false) : true);
        if(rxtypecheck && this.prescriberInfo && this.prescriberInfo.PrescriberAddresses && this.prescriberInfo.PrescriberAddresses.length){
                if (this.prescriberInfo.PrescriberAddresses.length > 1) {
                    this.prescriberInfo.PrescriberAddresses.map(value => {
                        if(this.rxFG && this.rxFG.controls && this.rxFG.controls.Prescription &&  this.rxFG.controls.Prescription.value && (value["Id"] == this.rxFG.controls.Prescription.value.PrescriberAddressId)){
                            this.PrescAddress = value;
                        }
                    });
                    if(!this.PrescAddress){
                        this.prescriberInfo.PrescriberAddresses.find(value=>{
                            if(value["Id"] == this.prescriberInfo.primeAddressId){
                                this.PrescAddress = value;
                            }
                        })
                    }
                } else {
                    this.PrescAddress =  this.prescriberInfo.PrescriberAddresses["0"];
                }
            }
          
        }

        ngOnDestroy() {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();

        }
}
