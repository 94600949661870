<div  *ngIf="!zipExtnCntrl">
    <eprx-input class="line_height_1_9" [LabelText]="'Zip'" [Title]="'Please enter valid zip and click enter to populate city and state'" [PlaceHolder]="'Zip'"
    [ControlName]="custContrlName" [FormGroupName]="inputFormGroup" [InputErrors]="inputErrors" [IsRequired]="isRequired" [ErrorDefs]="{required: 'Required' , pattern: 'Invalid Zip code'}" [RxSelectId]="rxSelectId"
    (TriggerChangeValue)="getCityStateFromZipCode($event)" [AutoFocus]="autoFocus"
    [MaxLength]="10" [FormGroupInvalid]="hasFGInvalid" [MarkAsTouched]="markAsTocuhed" [InputType]="'ZIP'" [TabIndex]="tabIndex" [ValidPattern]="PhnRegex?.Zipregex" *ngIf="isRequired">
</eprx-input>
<eprx-input [LabelText]="'Zip'"  class="line_height_1_9" [Title]="'Please enter valid zip and click enter to populate city and state'" [PlaceHolder]="'Zip'"
    [ControlName]="custContrlName" [FormGroupName]="inputFormGroup" [InputErrors]="inputErrors"  [ErrorDefs]="{pattern: 'Invalid Zip code'}"
    (TriggerChangeValue)="getCityStateFromZipCode($event)" [AutoFocus]="autoFocus" [RxSelectId]="rxSelectId"
    [MaxLength]="10" [FormGroupInvalid]="hasFGInvalid" [MarkAsTouched]="markAsTocuhed" [InputType]="'ZIP'" [TabIndex]="tabIndex" [ValidPattern]="PhnRegex?.Zipregex" *ngIf="!isRequired">
</eprx-input>
</div>


<div class="row" *ngIf="zipExtnCntrl">
    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 inline-flex height_2rem displayStateCityZip">
        <eprx-input  [ngClass]="isFromAccSettings ? 'mt-1 zip_line_height' : ''" [LabelText]="'Zip'" [RxSelectId]="rxSelectId"
            [Title]="'Please enter valid zip and click enter to populate city and state'" [PlaceHolder]="'Zip'"
            [ControlName]="custContrlName" [FormGroupName]="inputFormGroup" [InputErrors]="inputErrors"
            [IsRequired]="isRequired" [ErrorDefs]="{required: 'Required' , pattern: 'Invalid Zip code'}"
            (TriggerChangeValue)="getCityStateFromZipCode($event)" [AutoFocus]="autoFocus" [MaxLength]="10"
            [FormGroupInvalid]="hasFGInvalid" [MarkAsTouched]="markAsTocuhed" [InputType]="'ZIP'" [TabIndex]="tabIndex"
            [ValidPattern]="PhnRegex?.Zipregex" *ngIf="isRequired">
        </eprx-input>
        <eprx-input [ngClass]="isFromAccSettings ? 'mt-1 zip_line_height' : ''" [LabelText]="'Zip'" [RxSelectId]="rxSelectId"
            [Title]="'Please enter valid zip and click enter to populate city and state'" [PlaceHolder]="'Zip'"
            [ControlName]="custContrlName" [FormGroupName]="inputFormGroup" [InputErrors]="inputErrors"
            [ErrorDefs]="{pattern: 'Invalid Zip code'}" (TriggerChangeValue)="getCityStateFromZipCode($event)"
            [AutoFocus]="autoFocus" [MaxLength]="10" [FormGroupInvalid]="hasFGInvalid" [MarkAsTouched]="markAsTocuhed"
            [InputType]="'ZIP'" [TabIndex]="tabIndex" [ValidPattern]="PhnRegex?.Zipregex" *ngIf="!isRequired">
        </eprx-input>
        <label class="zip-Extn">
            -
        </label>
    </div>
    <div [ngClass]="isForErx ? 'col-lg-6 col-md-6 col-sm-6 col-xs-6 height_2rem pl-0 displayStateCityZip' : 'col-lg-7 col-md-7 col-sm-7 col-xs-7 height_2rem pl-0 displayStateCityZip'">
        <label class="col-md-12 padding-0"></label>
        <eprx-input [LabelText]="''" [Title]="'Zip Extension'" [PlaceHolder]="'Zip Extension'" [RxSelectId]="rxSelectId"
            [ControlName]="zipExtnCntrl" [FormGroupName]="zipExtnFG ? zipExtnFG : inputFormGroup" [MaxLength]="maxExtLen ? maxExtLen : 10"
            [InputType]="'ZIPEXT'">
        </eprx-input>
    </div>
</div>
