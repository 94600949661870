<ng-template #sales let-c="close" let-d="dismiss" class="el--popup">
        <div class="modal-header">
            <h4 class="modal-title">Sales Summary</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePopUp()">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body md-x-lg modal-lg" *ngIf="drugId">
            <div class="exprx--common-block  newrx--body edit--drug" *ngIf="hasSaleSummary">
                <div class="box  box-solid">
                    <div class="box-body exprx--common-block">
                        <div class="row">
                            <div class="col-8 salesPadding">
                                <div class="row">
                                    <div class="col-6">
                                        <label class="align-label">
                                            DrugName
                                        </label>
                                        <div class="label--data">
                                            {{ drugName }}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label class="align-label"> DrugNDC </label>
                                        <div class="label--data">
                                            {{ drugCode }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 salesbtm"></div>
                            <div class="salesrht"></div>
                            <div class="col-md-3 inventory inventory--color2 padding--left__7 padding--right__7">
                                <div class="inv--information">
                                    <div class="inv--value padding_top_10px ">
                                        <h3>{{ avgRxCount }}</h3>
                                        <div class="inv--description">
                                            <h5>Rx Count (Average Per Month)</h5>
                                        </div>
                                        <h3>{{ RxCount }}</h3>
                                        <div class="inv--description">
                                            <h5>Total Rx Count</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 inventory inventory--color3 padding--left__7 padding--right__7">
                                <div class="inv--information">
                                    <div class="inv--value padding_top_10px ">
                                        <h3>{{ (avgQty ? avgQty : 0) | number : '1.3-3' }}</h3>
                                        <div class="inv--description">
                                            <h5>Quantity (Average Per Month)</h5>
                                        </div>
                                    </div>
                                    <div class="inv--value">
                                        <h3>{{ (qtyVal && qtyVal ? qtyVal : 0) | number : '1.3-3' }}</h3>
                                        <div class="inv--description">
                                            <h5>Total Quantity</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 inventory inventory--color1 padding--left__7 padding--right__7">
                                <div class="inv--information">
                                    <div class="inv--value padding_top_10px ">
                                        <h3>{{'$'+ (avgAmt | number : '1.2-2') }}</h3>
                                        <div class="inv--description">
                                            <h5>Amount (Average Per Month)</h5>
                                        </div>
                                    </div>
                                    <div class="inv--value">
                                        <h3>{{ '$'+ (amt| number : '1.2-2') }}</h3>
                                        <div class="inv--description">
                                            <h5>Total Amount</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1"></div>
                        </div>
                    </div>
                    <div class="box-body exprx--common-block">
                        <div class="row">
                            <div class="col-lg-1 col-xs-1 col-md-1 col-sm-1"></div>
                            <div class="col-lg-10 col-xs-10 col-md-10 col-sm-10">
                                <div class="exprx--common-block padding--left__7 padding--right__7">
                                    <wj-flex-chart #sales onload="init(chart)" [itemsSource]="data" [bindingX]="'country'" [header]="'Drug Sales Summary Report'">
                                        <wj-flex-chart-legend [position]="'Bottom'"></wj-flex-chart-legend>
                                        <wj-flex-chart-series [name]="'SalesAmount'" [binding]="'SalesAmount'"></wj-flex-chart-series>
                                        <wj-flex-chart-series [name]="'SalesDispensed'" [binding]="'SalesDispensed'"></wj-flex-chart-series>
                                        <wj-flex-chart-series [name]="'SalesReceived'" [binding]="'SalesReceived'"></wj-flex-chart-series>
                                    </wj-flex-chart>
                                </div>
                            </div>
                            <div class="col-lg-1 col-xs-1 col-md-1 col-sm-1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasSaleSummary" class="eprx--block__footer">
                <div>
                    <span></span>
                    <i class="fa fa-file"></i><br/>
                    No Data Found!
                </div>
            </div>
        </div>
    </ng-template>
