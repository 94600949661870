 <ng-template #benfitCheckModal let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal('cancel')">
        <h4 header>
            {{ IsBenfitCheckfromRxHistory ? "Prescription Benefit Check" : "Benefit Check"}}
        </h4>
        <span body class="small--erx__screen ">
            <div class="row">
                <div class="col-6">
                    <div class="row mt-3">
                        <div class="col-2">
                            Status
                        </div>
                        <div class="col-3 ml-4  text-white text-center p-2 text--ellipsis border_radius_4px"
                            [ngClass]="(befitCheckData?.ReasonCode === 'NotProcessed') ? 'background_red' : 'bg-green'">
                            <span [title]="erxStatus[befitCheckData?.ReasonCode.toUpperCase()]">
                                {{ erxStatus[befitCheckData?.ReasonCode.toUpperCase()] }}</span>
                        </div>
                        <div class="col-6">
                            <label>Transaction#: </label>
                            {{benfitCheckModel?.Transaction}}
                        </div>
                    </div>

                </div>
                <div class="col-6 mt-1">
                    <div class="row">
                        <div class="col-5 mt-1 pr-0">
                            <h4>Coverage Requested Date:</h4>
                        </div>
                        <div class="col-6 pl-2 mt-1">
                            <h4>{{today | date: "MM/dd/yyyy hh:mm:ss a" }}</h4>
                        </div>

                    </div>
                    <div class="row" *ngIf="IsBenfitCheckfromRxHistory">
                        <div class="col-1 pr-0 font-weight-bold mt-2">
                            <label> Rx#:</label>
                        </div>
                        <div class="col-1 px-0 mt-2">
                            {{RxDetails?.PrescNum}}
                        </div>
                        <div class="col-1 pr-0 font-weight-bold mt-2">
                            <label>Ref#:</label>
                        </div>
                        <div class="col-1 px-0 mt-2">
                            {{RxDetails?.Refill}}
                        </div>
                        <div class="col-1 pr-0 font-weight-bold mt-2">
                            <label>Fill#:</label>
                        </div>
                        <div class="col-1 px-0 mt-2">
                            {{(RxDetails?.FillNo) ? RxDetails?.FillNo : '-'}}
                        </div>
                        <div class="col-3 px-0" *ngIf="befitCheckData?.CoverageStatus">
                            <div class="background_red text-white p-2 rounded"> {{befitCheckData?.CoverageStatus}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row margin_top_13px">
                <div class="col-1">
                    <span> Notes </span>
                </div>
                <div class="col-8">
                    <textarea rows="2" cols="30" class="custom--text__area" placeholder="Notes"
                        readonly>  {{befitCheckData?.FreeText | uppercase}}  </textarea>
                </div>
            </div>
            <div class="row pop_searches erx_request  ">
                <div class="col-6 erx_newRequest margin_top-2">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading"> Patient &nbsp; - &nbsp; {{(benfitCheckModel ) ?
                                ((benfitCheckModel?.PatLastName + ", " +
                                benfitCheckModel?.PatFirstName | uppercase)) : ""}} </div>
                        </div>
                        <div class="eprx--block__content padding_4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="eprx--block__content    presc-Sml patient_prescriber_card">
                                        <div class="row">

                                            <div class="col-3  pcard--sleek__grid">
                                                <label>Gender</label>
                                                <div>
                                                    {{ benfitCheckModel?.PatGender ? (benfitCheckModel?.PatGender ===
                                                    'F' ? "Female": "Male") : "--" }}
                                                </div>
                                            </div>
                                            <div class="col-3  pcard--sleek__grid">
                                                <label>Age</label>
                                                <div class="label--data">{{ age ? age : "--" }}</div>
                                            </div>
                                            <div class="col-3  pcard--sleek__grid">
                                                <label>DOB</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.PatBirthDt | date: "MM/dd/yyyy" }}
                                                </div>
                                            </div>
                                            <div class="col-3  pcard--sleek__grid sml-box">
                                                <label>Phone#</label>
                                                <div class="label--data">
                                                    <span>{{benfitCheckModel?.PatPhone? (benfitCheckModel?.PatPhone |
                                                        mask : "(000)000-0000"):"--"}}</span>
                                                </div>
                                            </div>
                                            <div class="col-12 pcard--sleek__grid sml-box">
                                                <label  class="d-block">Address</label>
                                                <div class="label--data text--ellipsis">
                                                    <span
                                                        *ngIf="benfitCheckModel?.PatAddrL1 && benfitCheckModel?.PatAddrL1 !== '###'">
                                                        {{benfitCheckModel?.PatAddrL1}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PatAddrL2 && benfitCheckModel?.PatAddrL2 !== '###'">
                                                        {{benfitCheckModel?.PatAddrL2}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PatCity && rxPatientModel?.PatCity !== '###'">
                                                        {{benfitCheckModel?.PatCity}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PatState && benfitCheckModel?.PatState !== '###'">
                                                        {{benfitCheckModel?.PatState}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PatZip && benfitCheckModel?.PatZip !== '###'">
                                                        {{benfitCheckModel?.PatZip}}
                                                    </span>
                                                    <span *ngIf="!(benfitCheckModel?.PatState) || !(benfitCheckModel?.PatCity)  ||
                                                     !(benfitCheckModel?.PatAddrL1) || !(benfitCheckModel?.PatZip)">
                                                        ---
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 erx_newRequest margin_top-2">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading"> Prescriber &nbsp;  - &nbsp; {{(benfitCheckModel ) ?
                                ((benfitCheckModel?.PresLastName +
                                ", " + benfitCheckModel?.PresFirstName | uppercase)) : ""}} </div>
                        </div>
                        <div class="eprx--block__content padding_4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="eprx--block__content  presc-Sml patient_prescriber_card">
                                        <div class="row">
                                            <div class="col-3  pcard--sleek__grid">
                                                <label>NPI#</label>
                                                <div>
                                                    {{ benfitCheckModel?.PrescNpi ? benfitCheckModel?.PrescNpi : '--' }}
                                                </div>
                                            </div>
                                            <div class="col-3  pcard--sleek__grid">
                                                <label>SPI#</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.PrescSpi ? benfitCheckModel?.PrescSpi : '--' }}
                                                </div>
                                            </div>
                                            <div class="col-3  pcard--sleek__grid">
                                                <label>DEA#</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.PrescDea ? _formatsUtil.getDeaFormat(benfitCheckModel?.PrescDea) : '--' }}
                                                </div>
                                            </div>
                                            <div class="col-3  pcard--sleek__grid sml-box">
                                                <label>Phone#</label>
                                                <div class="label--data">
                                                    <span>{{benfitCheckModel?.PrescPhone? (benfitCheckModel?.PrescPhone
                                                        | mask : "(000)000-0000"):"--"}}</span>
                                                </div>
                                            </div>
                                            <div class="col-12 pcard--sleek__grid sml-box">
                                                <label  class="d-block">Address</label>
                                                <div class="label--data">
                                                    <span
                                                        *ngIf="benfitCheckModel?.PresAddrL1 && benfitCheckModel?.PresAddrL1 !== '###'">
                                                        {{benfitCheckModel?.PresAddrL1}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PresAddrL2 && benfitCheckModel?.PresAddrL2 !== '###'">
                                                        {{benfitCheckModel?.PresAddrL2}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PresCity && benfitCheckModel?.PresCity !== '###'">
                                                        {{benfitCheckModel?.PresCity}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PresState && benfitCheckModel?.PresState !== '###'">
                                                        {{benfitCheckModel?.PresState}},
                                                    </span>
                                                    <span
                                                        *ngIf="benfitCheckModel?.PresZip && benfitCheckModel?.PresZip !== '###'">
                                                        {{benfitCheckModel?.PresZip}}
                                                    </span>
                                                    <span *ngIf="!(benfitCheckModel?.PresAddrL1) || !(benfitCheckModel?.PresCity) ||
                                                     !(benfitCheckModel?.PresState) || !(benfitCheckModel?.PresZip)">
                                                        ---
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 erx_newRequest margin_top-2">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading"> Drug &nbsp; - &nbsp; {{(benfitCheckModel ) ?
                                ((benfitCheckModel?.DrugName | uppercase)) : ""}} </div>
                        </div>
                        <div class="eprx--block__content padding_4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="eprx--block__content  presc-Sml patient_prescriber_card">
                                        <div class="row">
                                            <div class="col-4  pcard--sleek__grid">
                                                <label>NDC</label>
                                                <div class="label--data">{{ benfitCheckModel?.DrugNdc ?
                                                    benfitCheckModel?.DrugNdc : "--" }}</div>
                                            </div>
                                            <div class="col-2  pcard--sleek__grid">
                                                <label>QTY</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.RxQty ? (benfitCheckModel?.RxQty | number :
                                                    '1.3-3') : "--" }}
                                                </div>
                                            </div>
                                            <div *ngIf="!benfitCheckModel?.RxDaw" class="col-2  pcard--sleek__grid">
                                                <label>Days</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.RxDays ? benfitCheckModel?.RxDays : '0' }}
                                                </div>
                                            </div>
                                            <div *ngIf="benfitCheckModel?.RxDaw" class="col-lg-2  pcard--sleek__grid">
                                                <label>Days</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.RxDays ? benfitCheckModel?.RxDays : '0' }}
                                                </div>
                                            </div>
                                            <div *ngIf="benfitCheckModel?.RxDaw" class="col-2  pcard--sleek__grid">
                                                <label>DAW</label>
                                                <div class="label--data">
                                                    {{ dawValue ? dawValue : '' }}
                                                </div>
                                            </div>
                                            <div class="col-2  pcard--sleek__grid sml-box">
                                                <label>Auth Refills</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.RefillNum ? benfitCheckModel?.RefillNum : '--'
                                                    }}
                                                </div>
                                            </div>
                                            <div class="col-12 pcard--sleek__grid sml-box">
                                                <label class="d-block">Sig</label>
                                                <div class="label--data">
                                                    {{ benfitCheckModel?.RxSig ? benfitCheckModel?.RxSig : '--' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 erx_newRequest margin_top-2">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Benefit Details &nbsp; - &nbsp;
                            BIN : <span>{{ insuranceDetails?.BINLocationNumber ?
                                insuranceDetails?.BINLocationNumber : '--' }}
                            </span>
                            PCN: <span> {{ insuranceDetails?.ProcessorIdentificationNumber ?
                                insuranceDetails?.ProcessorIdentificationNumber : '--' }}</span>
                        </div>
                        </div>
                        <div class="eprx--block__content padding_4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="eprx--block__content  presc-Sml patient_prescriber_card">
                                        <div class="row">
                                            <div class="col-4  pcard--sleek__grid">
                                                <label>Group Name</label>
                                                <div>
                                                    {{ insuranceDetails?.GroupId ? insuranceDetails?.GroupId : '--' }}
                                                </div>
                                            </div>
                                           <div class="col-4  pcard--sleek__grid">
                                                <label>Payer ID</label>
                                                <div class="label--data">
                                                    {{ insuranceDetails?.PayerId ? insuranceDetails?.PayerId : "--" }}
                                                </div>
                                            </div>
                                            <div class="col-4  pcard--sleek__grid">
                                                <label>Payer Name</label>
                                                <div>
                                                    {{ insuranceDetails?.PayerName ? insuranceDetails?.PayerName : "--"
                                                    }}
                                                </div>
                                            </div>
                                            <div class="col-4  pcard--sleek__grid">
                                                <label>Card Holder</label>
                                                <div class="word-break">
                                                    {{ (insuranceDetails?.CardHolderFirstName) ?
                                                    (insuranceDetails?.CardHolderFirstName + "," +
                                                    insuranceDetails?.CardHolderLastName | uppercase) : '--'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-6">
                    <h5 class="mb-0 mt-2">Benefit Coverage Info</h5>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-primary" (click)="refreshCoverageInfo()">Refresh Coverage Info</button>
                </div>
            </div>
            <div class="row">
                <!--
Column customization is not required on Benefit Check Coverage Info and also it is causing focus out issues due to the customization, hence commenting the Column customization for Benefit Check Coverage Code grid.
                -->
                <div class="col far_plus">
                    <wj-flex-grid #CoverageInfo [headersVisibility]="'Column'" [itemsSource]="gridViewObj"
                        [itemFormatter]="itemFormatter" [isReadOnly]="true" [allowSorting]="false"
                      [selectionMode]="'Row'" (updatedView)="init(CoverageInfo)"
                        (lostFocus)="focusOutForWijmo()" (click)="onRowDblclicked(CoverageInfo, $event)"
                         class="max-height_15rem">
                        <wj-flex-grid-column [header]="'AWP/Cost'" [width]="80">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">


                                <span class="d-inline benefit_check_instructTooltip text-center"
                                                [ngbTooltip]="instructToolTip"
                                                tooltipClass="search-tooltip_benefitcheck"
                                                container="body" triggers="mouseover:mouseleave" [autoClose]="true"
                                                placement="right">𝒊</span>

                                                <ng-template #instructToolTip>





                                                    <div class="row mx-3">
                                                        <div class="col-12  px-0">
                                                            <div class="row">
                                                                <div  class="col-3 pr-0 text-danger">
                                                                    AWP:
                                                                </div>
                                                                <div class="col-9 pl-0">
                                                                    <span *ngIf="item?.ThirtyDaysAWP">{{item?.ThirtyDaysAWP | currency}}</span>
                                                                    <span>(30Days)</span>,&nbsp;
                                                                    <span *ngIf="item?.NinentyDaysAWP">{{item?.NinentyDaysAWP | currency}}</span>
                                                                    <span>(90Days)</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 px-0">

                                                            <div class="row">
                                                                    <div class="col-3 text-danger pr-0">
                                                                        COST:
                                                                    </div>
                                                                    <div class="col-9 pl-0">

                                                                        <span *ngIf="item?.ThirtyDaysCost">{{item?.ThirtyDaysCost | currency}}</span>
                                                                        <span>(30Days)</span>,&nbsp;
                                                                        <span *ngIf="item?.NinentyDaysCost">{{item?.NinentyDaysCost | currency}}</span>
                                                                        <span>(90Days)</span>

                                                                    </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-12" >
                                                            <div class="row" *ngIf="item?.EquivalentDrugsStockQty">
                                                                <div class="col-3 pl-0 text-danger pr-0" >
                                                                    EQ.StockQty:
                                                                </div>
                                                                <div class="col-3 pl-0">
                                                                    {{item?.EquivalentDrugsStockQty}}
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="item?.StockQty">
                                                                <div class="col-3 pl-0 text-danger" >
                                                                   Stock.Q:
                                                                </div>
                                                                <div class="col-3 pl-0">
                                                                    {{item?.StockQty}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>

                            </ng-template>
                        </wj-flex-grid-column>

                        <wj-flex-grid-column [header]="rxheader" [binding]=""  [visible]="true" [width]="(rxheader === 'Drug Type' ? 220 :
                        (rxheader === 'Drug Name' ? 297 : (rxheader === 'PA Req?' ? 74  : ((supplyDaysList.length <= 3) ? '*' : 200))) ) "
                            *ngFor="let rxheader of wjHeaders; let i = idnex">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                                <div
                                    *ngIf="(rxheader === 'Drug Type' || rxheader === 'Drug Name' || rxheader === 'PA Req?')"  class="text-center">
                                    <div *ngIf="rxheader === 'PA Req?' && item[rxheader] === 'YES'"  class="background_color_00a65a"> {{item[rxheader]}} </div>
                                    <div *ngIf="rxheader !== 'PA Req?' || (rxheader === 'PA Req?' && item[rxheader] !== 'YES')"> {{item[rxheader]}} </div>
                                </div>
                                <div class="row"
                                    *ngIf="(rxheader !== 'Drug Type' &&  rxheader !== 'Drug Name' && rxheader !== 'PA Req?')">

                                   <!-- <div class="col-4">
                                        <span> <input class="form-check-input" type="checkbox" name="" [checked]="false"
                                                (click)="selectedConverageInfo($event?.target?.checked, item,'90days')" /></span>
                                    </div>-->
                                    <div class="col-4" [ngStyle]="{'background-color' : (item[rxheader] && item[rxheader]['lowcopay']) ? '#00a65a' : ''}">
                                        <span placement="top" triggers="mouseover:mouseleave" container="body"
                                            tooltipClass="search_tooltip_befitcheck_info"
                                            [ngbTooltip]="PricingInfoDetails"> {{ (item[rxheader] &&
                                            item[rxheader]["Copay"] ) ? (item[rxheader]["Copay"] | currency) : ""}}
                                        </span>
                                        <ng-template #PricingInfoDetails>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h5>Price Info</h5>
                                                </div>
                                                <div class="col-12 font-weight-bold">
                                                    Qty - {{ (!item[rxheader]["Days"]) ? 30 : item[rxheader]["Days"] }}
                                                    - Tablet
                                                </div>
                                                <div class="col-12 text-justify">
                                                    <div class="row">
                                                        <div class="col-8">Plan Pay Amount: </div>
                                                        <div class="col-4">{{(item[rxheader] &&
                                                            item[rxheader]["PayAmount"]) ? (item[rxheader]["PayAmount"]
                                                            | currency) : "-"}}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-8">OOP Applied Amount: </div>
                                                        <div class="col-4">
                                                            {{(item[rxheader] && item[rxheader]["OOPAppliedAmount"]) ?
                                                            (item[rxheader]["OOPAppliedAmount"] | currency) : "-"}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-8">OOP Remaining Amount: </div>
                                                        <div class="col-4">
                                                            {{(item[rxheader] && item[rxheader]["OOPRemainingAmount"]) ?
                                                            (item[rxheader]["OOPRemainingAmount"] | currency) : "-"}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-8">Deductible Applied Amount:</div>
                                                        <div class="col-4">
                                                            {{(item[rxheader] &&
                                                            item[rxheader]["DeductibleAppliedAmount"]) ?
                                                            (item[rxheader]["DeductibleAppliedAmount"] | currency) :
                                                            "-"}}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-8">Deductible Remaining Amount:</div>
                                                        <div class="col-4">
                                                            {{(item[rxheader] &&
                                                            item[rxheader]["DeductibleRemainingAmount"]) ?
                                                            (item[rxheader]["DeductibleRemainingAmount"] | currency) :
                                                            "-"}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="col-4" *ngIf="item[rxheader]  && item[rxheader]['coverageAlerts'] && item[rxheader]['coverageAlerts'].length">
                                        <i class="fa fa-exclamation-circle text-warning font_size_1_4rem" container="body"  placement="top"  triggers="mouseover:mouseleave"  tooltipClass="search_tooltip_benefitcheck_infoDetails"  [ngbTooltip]="pricingInfo"  aria-hidden="true"></i>
                                        <ng-template #pricingInfo>
                                            <div class="row">
                                                <div class="col-12 font-weight-bold">
                                                    {{ (item[rxheader] && item[rxheader]["drugPriceCovered"] && item[rxheader]["drugPriceCovered"] === 1) ? 'Covered' :
                                                    (item[rxheader]  && item[rxheader]["drugPriceCovered"]  && item[rxheader]["drugPriceCovered"] === 2)  ? "Covered with Restriction" : "Not Covered" }}
                                                </div>
                                                <div class="col-12 text-justify" >
                                                        <div class="row" >
                                                                <dl class="col-12 mb-0" *ngFor="let x of item[rxheader]['coverageAlerts']">
                                                                    <dt>  {{x.ReasonCode}}</dt>
                                                                    <dd class="ml-5">{{x.ReasonText}}</dd>
                                                                </dl>
                                                        </div>
                                                        </div>
                                                </div>
                                            </ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>


                        <ng-template wjFlexGridDetail #dp="wjFlexGridDetail" let-item="item">
                            <div class="row mx-3">
                                <div class="col-4  px-0">
                                    <span class="text-danger mr-1">AWP:</span>
                                    <span *ngIf="item?.ThirtyDaysAWP">{{item?.ThirtyDaysAWP | currency}}</span>
                                    <span>(30Days)</span>,&nbsp;
                                    <span *ngIf="item?.NinentyDaysAWP">{{item?.NinentyDaysAWP | currency}}</span>
                                    <span>(90Days)</span>
                                </div>
                                <div class="col-4 px-0" *ngIf="item?.EquivalentDrugsStockQty || item?.StockQty">
                                    <span *ngIf="item?.EquivalentDrugsStockQty"
                                        class="text-danger mr-1">EQ.StockQty:</span>
                                    <span *ngIf="item?.EquivalentDrugsStockQty">{{item?.EquivalentDrugsStockQty}}</span>
                                    &nbsp;
                                    <span *ngIf="item?.StockQty" class="text-danger mr-1">StockQty:</span>
                                    <span *ngIf="item?.StockQty">{{item?.StockQty}}</span>
                                </div>
                                <div class="col-4 px-0">
                                    <span class="text-danger mr-1">COST:</span>
                                    <span *ngIf="item?.ThirtyDaysCost">{{item?.ThirtyDaysCost | currency}}</span>
                                    <span>(30Days)</span>,&nbsp;
                                    <span *ngIf="item?.NinentyDaysCost">{{item?.NinentyDaysCost | currency}}</span>
                                    <span>(90Days)</span>
                                    <span></span>
                                </div>
                            </div>
                        </ng-template>
                    </wj-flex-grid>
                </div>
            </div>
            <div class="row">
                <div class="col-12 font-weight-bold font-size_1_2">
                    The Pricing/Coverage date is a point in time calculation based upon the Quantity and Days Supply
                    provided in the Request and may vary once the
                    prescription is filled at the dispensing pharmacy. Any returned alternative info is suggestive only and
                    not intended to replace clinical decisions.
                </div>
            </div>
        </span>
        <div footer class="row p-1">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button *ngIf="!isPaRequest "  class="hotkey_success" ngbAutofocus
                    (keydown.enter)="goToProcessErx('paReq')" (click)="goToProcessErx('paReq')" appShortcutKey
                    [AltKey]="'s'"><span>S</span>
                    Send PA Req</button>
                <button *ngIf="isPaRequest" class="inactive"><span>S</span>
                    Send PA Req</button>
                <button class="hotkey_success" *ngIf="!(isSendChangeRequest)"
                    (keydown.enter)="goToProcessErx('changeReq')" (click)="goToProcessErx('changeReq')" appShortcutKey
                    [AltKey]="'e'">
                    <span>E</span>
                    Send Change Req</button>
                <button class="inactive" *ngIf="isSendChangeRequest">  <span>E</span>
                    Send Change Req</button>
                <button *ngIf="!IsBenfitCheckfromRxHistory && IsBenefitCheckProcess" class="inactive"
                    ><span>P</span>Process</button>
                    <button *ngIf="!IsBenfitCheckfromRxHistory && !IsBenefitCheckProcess" class="hotkey_success"
                    (keydown.enter)="goToProcessErx('process')" (click)="goToProcessErx('process')" appShortcutKey
                    [AltKey]="'p'"><span>P</span>
                    Process</button>

                <button *ngIf="IsBenfitCheckfromRxHistory" class="hotkey_success" 
                placement="top" ngbTooltip="Deferred Rx's Can be Filled, and non Deferred Rx's can be Refilled."
                    (keydown.enter)="goToProcessErx('Refill')" (click)="goToProcessErx('Refill')" appShortcutKey
                    [AltKey]="'r'"><span>R</span>
                    Refill/Fill</button>
                <button class="hotkey_success" (click)="closeModal('cancel')" appShortcutKey [AltKey]="'c'">
                    <span>C</span> CANCEL</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>
<app-erx-change-req
    *ngIf="isSelectedType === 'paReq'"
    [RxInfo]="SelectedRxDetails"
    (IsPopUpClosed)="openActionModal('BefitCheck')"
    [SelectedBenefitCheckType]=activeModal
    [PrescRefillId]="SelectedRxDetails?.PrescReFill?.Id"
    [DrugId]="SelectedRxDetails?.PrescReFill?.id"
></app-erx-change-req>
