<div class="modal-header">
    <h4 class="modal-title">Low Inventory</h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePopup()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>

<div class="modal-body" id="lowInvGrid">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 edit--drug">

            <div class="row padding_bottom_10px">
                <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12 padding-0">
                    <label class="align-label mw-21.5" class="col-lg-3 col-md-3 col-sm-3 col-xs-3">Drug Being Dispensed   :</label>
                    <span>{{drugInfo?.drugname ? (drugInfo?.drugname | uppercase) + " " : ""}}
                        {{drugInfo?.strength ? (drugInfo?.strength | uppercase) + " " : ""}}
                        {{drugInfo?.drugformname ? (drugInfo?.drugformname | uppercase) : ""}}</span>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 padding-0">
                    <label class="align-label mw-18.5" class="col-lg-2 col-md-2 col-sm-2 col-xs-2">NDC :</label>
                    <span>{{_formatsUtil.getNDCFormat(drugInfo?.ndc)}}</span>
                </div>
            </div>
            <div class="row">

                <div class="col-md-4 col-lg-4 col-xs-4 col-sm-4 inventory  drug-inven inventory--color2">
                    <div class="inv--information">
                        <div class="inv--image--sml">
                            <img src="assets/dist/Icons/icon_Pickup.svg" title="Qty Dispensed">
                        </div>
                        <div class="inv--value">
                            <h3>{{(lowInvInfo?.qtyInHand| number:'1.3-3')}}</h3>
                            <div class="inv--description">
                                <h5>Quantity In Hand</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-4 col-sm-4 inventory  drug-inven inventory--color4">
                    <div class="inv--information">
                        <div class="inv--image--sml">
                            <img src="assets/dist/Icons/icon_pills1_white.svg" title="Qty In Hand">
                        </div>

                        <div class="inv--value">
                            <h3>{{(lowInvInfo?.ordQty | number:'1.3-3')}}</h3>
                        </div>
                        <div class="inv--description">
                            <h5>Qty Being Dispensed</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-4 col-sm-4 inventory  drug-inven inventory--color1">
                    <div class="inv--information">
                        <div class="inv--image--sml">
                            <img src="assets/dist/Icons/icon_pills_white.svg" title="Bucket">
                        </div>
                        <div class="inv--value">
                            <h3>{{buckInfo?.Name | uppercase}}</h3>
                            <div class="inv--description">
                                <h5>Bucket Name</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row margin_top_15px" *ngIf="lowInvInfo?.qtyInHand < lowInvInfo?.ordQty">
                <div class="col help--text__danger font_size_18px text-center"
                    >
                    Quantity in hand in the bucket {{buckInfo?.Name | uppercase}} for the selected drug is less
                    than the quantity
                    being
                    dispensed.
                </div>
            </div>


            <div class="row">
                <div class="col erx--body">
                    <label>Equivalent Drugs</label>
                    <div class="row">
                        <div class="col">
                            <div class="exprx--common-block">
                                <div class="eprx--block__content edit--drug insurance--body">
                                    <wj-flex-grid #equDrug [headersVisibility]="'Column'" [itemsSource]="eqDrugWJ"
                                        [isReadOnly]="true" [columnPicker]="'row'" [selectionMode]="'Row'"
                                        *ngIf="(eqDrugList && eqDrugList.length !== 0)" (updatedView)="init(equDrug)" class="max-height_16rem">
                                        <wj-flex-grid-column [header]="header" [binding]="header" [visible]="true"
                                            *ngFor="let header of equActiveH; let i=index;" [width]="'*'" (dblclick)="onRowDblclicked(equDrug, $event, 'Drug')"
                                            (keydown.enter)="keyeventOnWj('Drug', equDrug)">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="header === 'Action'">
                                                    <i class="far fa-file-import action m-0" title="Select" (click)="
                                                    optedInvDrug(item)"></i>
                                                </div>
                                                <div *ngIf="!(header === 'Action')">
                                                    {{item[header]}}
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>

                                    <h3 *ngIf="(!eqDrugList || eqDrugList.length === 0)" class="text-center">No
                                        Equivalent Drugs</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <!-- <div class="row">
                <div class="col erx--body">
                    <label>Bucket List</label>
                    <div class="row">
                        <div class="col">
                            <div class="exprx--common-block">
                                <div class="eprx--block__content edit--drug insurance--body">
                                    <wj-flex-grid #bucketListWJ [headersVisibility]="'Column'" [itemsSource]="bucketWJ"
                                        [isReadOnly]="true" [columnPicker]="'row'" [selectionMode]="'Row'"
                                        *ngIf="(BucketList && BucketList.length !== 0)" (initialized)="init(bucketListWJ)" style="max-height: 16rem;">
                                        <wj-flex-grid-column [header]="header" [binding]="header" [visible]="true"
                                            *ngFor="let header of buckActiveH; let i=index;" [width]="'*'" (dblclick)="onRowDblclicked(bucketListWJ, $event, 'Bucket')"
                                            (keydown.enter)="keyeventOnWj('Bucket', bucketListWJ)">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="header === 'Action'">
                                                    <i class="far fa-file-import action" title="Select" (click)="
                                                    optedBucket(item)" style="margin: 0px;"></i>
                                                </div>
                                                <div *ngIf="!(header === 'Action')">
                                                    {{item[header]}}
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>

                                    <h3 *ngIf="(!BucketList || BucketList.length === 0)" class="text-center">
                                        Buckets not found</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div> -->

        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="text-right float-right ">
        <button class="hotkey_primary" id="openInv" autofocus (click)="openEnterInvent()"
         appShortcutKey [InputKey]="'i'"><b>I</b> Add Inventory </button>
        <!-- <button class="hotkey_primary" id="transfBucket" autofocus (click)="openTransferPopup()"
         appShortcutKey [InputKey]="'t'"><b>T</b> Transfer qty to Bucket</button> -->
        <button class="hotkey_primary" id="lowInvCancel" autofocus (click)="closePopup()"
         appShortcutKey [InputKey]="'o'"><b>O</b> Continue with current Drug And Bucket</button>
    </div>
</div>


<ng-template #TransBucketPop let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Transfer Bucket</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row padding_bottom_10px">
            <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12 padding-0">
                <label class="align-label" class="col-lg-5 col-md-5 col-sm-5 col-xs-5">Drug</label>
                <span>:&nbsp; {{drugInfo?.drugname ? (drugInfo?.drugname | uppercase) + " " : ""}}
                    {{drugInfo?.strength ? (drugInfo?.strength | uppercase) + " " : ""}}
                    {{drugInfo?.drugformname ? (drugInfo?.drugformname | uppercase) : ""}}
                </span>
            </div>
            <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 padding-0">
                <label class="align-label" class="col-lg-2 col-md-2 col-sm-2 col-xs-2">NDC</label>
                <span>
                    : &nbsp; {{_formatsUtil.getNDCFormat(drugInfo?.ndc) }}
                </span>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            Bucket 1
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <label class="align-label">Available Qty. : </label>
                                <span>{{ (transBucketFG?.value?.Buck1Qnty | number:'1.3-3')}}</span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <eprx-select  [AutoFocus]="true" [LabelText]="'Select Bucket'" [PlaceHolder]="''"
                                    [ControlName]="'FromBucketId'" [FormGroupName]="transBucketFG" [List]="BucketList"
                                    [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'buck1drugQnty'"
                                    (TriggerSelectValue)="optedBucketFrTrans('Bucket1' ,$event?.value)" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                    (TriggerOnClear)="optedBucketFrTrans('Bucket1' ,$event?.value)" [RxSelectId]="'Bucket1T'"
                                    [FormGroupInvalid]="transFGInvalid" [MarkAsTouched]="transBucketFG?.get('FromBucketId')?.touched">
                                </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <eprx-input [LabelText]="'Transfer Quantity'" [PlaceHolder]="'Transfer Quantity'" [MaskPattern]="'0999999.000'"
                                    [DropSpclChar]="false" [BeforeDecimal]="8" [DecimalValues]="3" [ControlName]="'Qty'"
                                    [FormGroupName]="transBucketFG" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                    [MarkAsTouched]="transBucketFG?.get('Qty')?.touched"
                                    [RxSelectId]="'Buck1TransQnty'" (TriggerOnBlur)="checkQtyValues()" [FormGroupInvalid]="transFGInvalid">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 bucket--edit--buttons">
                <h1><i class="far fa fa-arrow-circle-o-right text-primary action"></i></h1>
                <label class="align-label">Transfer to</label>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            Bucket 2
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <label class="align-label">Available Qty. :</label>
                                <span>{{ (transBucketFG?.value?.Buck2Qnty | number:'1.3-3')}}</span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <eprx-select [LabelText]="'Select Bucket'" [PlaceHolder]="''" [ControlName]="'ToBucketId'"
                                    [FormGroupName]="transBucketFG" [List]="BucketList" [BindLabel]="'Name'"
                                    [BindValue]="'Id'" [LabelForId]="'buck2drugQnty'" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                    (TriggerSelectValue)="optedBucketFrTrans('Bucket2' ,$event?.value)" [FormGroupInvalid]="transFGInvalid"
                                    (TriggerOnClear)="optedBucketFrTrans('Bucket2' ,$event?.value)" [RxSelectId]="'Bucket2T'"
                                    [MarkAsTouched]="transBucketFG?.get('ToBucketId')?.touched">
                                </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <eprx-input [LabelText]="'Qty Post Transfer'" [PlaceHolder]="'Qty Post Transfer'"
                                    [ControlName]="'Buck2TransQnty'" [FormGroupName]="transBucketFG" [InputType]="'NUMBER'"
                                    [IsDisabled]="true">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="transferQtyToBuck()" appShortcutKey [AltKey]="'s'"><span>S</span>Save</button>
        <button type="button" class="hotkey_success" (click)="c('Close click');" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
    </div>
</ng-template>

<ng-template #ERRORMSG let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closeWarnPopup()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <label>{{errorMsg}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary"
        (keydown.enter)="c('Close click');closeWarnPopup()"
        (click)="c('Close click');closeWarnPopup()" appShortcutKey InputKey="o"><b>O</b>Ok</button>
    </div>
</ng-template>
