import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AlertService } from 'src/app/services';
import { PrintService } from 'src/app/services/print.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qz-tray-steps',
  templateUrl: './qz-tray-steps.component.html'
})
export class QzTrayStepsComponent implements OnInit {

    qzUrlString = "";
    javaUrlString = "";
    isSystemplatform: string = "";
    @Output() CloseModal = new EventEmitter<any>();
    isQzLatestInstalled : boolean;

    constructor(private _printService:PrintService, private _alertServ: AlertService) { }

    ngOnInit() {
        
        this.isSystemplatform   = window.navigator.platform;;
        if(this.isSystemplatform  && (this.isSystemplatform  === 'Mac68K' || this.isSystemplatform  === 'MacPPC' || this.isSystemplatform  === 'MacIntel')) {
            this.qzUrlString =  environment.QzDownloadLinkForMac;
            this.javaUrlString = environment.JavaDownloadLinkforMac;
        } else {
            this.qzUrlString =  environment.QzDownloadLink;
            this.javaUrlString = environment.JavaDownloadLink;
        };
        this.isQzLatestInstalled = this._printService.isQzLatestVersion;
     }

    clickActions(javaLink, QzLink) {
        javaLink.click();
        setTimeout(() => {
            QzLink.click();
        }, 10000);
    }
    clickQzLatestVersionActions(QzLink)
    {
        QzLink.click();
    }

    Cancel() {
        this.CloseModal.emit(null);
    }

}
