import { Injectable } from "@angular/core";
import { constant } from "./../app.constants";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class RphDashboardService {
    constructor(private _http: HttpClient) {}

    getRphTodaysRx(data) {
       return this._http.post<any>(constant.POST_GetRph_Today_Data, data);
    }

    saveDefaultFilters(data) {
        return this._http.post(constant.POST_SaveDefault_RphFilter, data);
    }

    getDefaultFilters() {
        return this._http.get(constant.GET_Default_RphFilter);
    }


    // Filed Queue end points

    getFiledQueue(data) {
        return this._http.post<any>(constant.POST_Get_FiledRx_Queue, data);
    }


    // DeferredReason Endpoints

    getDeferredReasonList(data) {
        return this._http.post<any>(constant.POST_Get_DfrdReason_List, data);
    }

    addDeferredReason(data) {
        return this._http.post(constant.POST_Add_DfrdReason, data);
    }

    updateDeferredReason(data) {
        return this._http.put(constant.PUT_Update_DfrdReason, data);
    }

    deleteDeferredReason(id) {
        return this._http.delete(constant.DELETE_DfrdReason.replace("{id}", "" + id));
    }

    getChangedDefReasonDueDate(payload) {
        return this._http.put(constant.PUT_ChangeDefReasonAndFileDueDate, payload);
    }

    getDeferredDeleteResponse(id) {
        return this._http.get(constant.GET_DfrdReason_DeleteResponse.replace(
            "{id}", "" + id
        ));
    }

    SendBatchRphVerificationDetails(data: any) {
        return this._http.post<any>(
            constant.Post_BatchRphVerification,
            data
        );
    }
}
