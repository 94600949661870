export class DrugBucketInfo {
    BucketCatId: number = null;
    BucketCategory: any = null;
    Code: any = null;
    ContextTypeId: number = null; // 1-Gen , 2-340B
    CostPack: any = null;
    CreatedDtTm: any = null;
    FacilityId: any = null;
    Id: number = null;
    InsuCarrierId: any = null;
    IsActive = false;
    IsBucketPriceUsed = false;
    IsInvManaged = false;
    IsPriceExist = false;
    ModifiedDtTm: any = null;
    Name: string = null;
    PatCustomCatId: any = null;
    PriceSchId: any = null;
    Qty: number = null;
    Remarks: string = null;
    SpecificId: any = null;
    SplProgramId: any = null;
    TenantId: any = null;
    UnitPriceCost: any = null;
    QtyInHand: number = null;
    QtyInHandEqui: number = null;
    BucketContextType: string = null;
    showHisByBucktId = false;
}

export class DrugPO {
    Id: number = null;
    TenantId: number = null;
    DrugId: number = null;
    BucketId: number = null;
    IsAutoPO: boolean = null;
    ReorderQty: number = null;
    TargetQty: number = null;
    MinOrderQty: number = null;
    OrderQty: number = null;
    PrefVendId: number = null;
    ContractId: number = null;
}

export class ManageInventory {
    Id: number = null;
    DrugId: number = null;
    BucketId: number = null;
    IsManaged = false;
    TenantId: number = null;
    CostPack: number = null;
    UnitPriceCost: number = null;
    IsActive = false;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
    QtyPack: any = null;
}

export class DrugSuggestFilter {
    OnlyDrugsHavingQtyInStock = false;
    BucketId: number = null;
    ShowNonSplittableEquiDrugs = false;
    OnlyShowDrugsAvailableForBucket = false;
    Is340B = false;
    ShowDrugsBasedOnDAWSelection  = false;
    IsFromReset = false;
}
