<div [ngClass]="(isAddnew &&
!erxSearch) ? 'mt-1 input-group' : 'mt-1 input-group has-no-add'">
    <div
        [ngClass]="
            disableSerchFil ? 'input-group-btn disabled' : 'input-group-btn'
        "
        *ngIf="!hasFilters"
    >
        <select
            *ngIf="!erxSearch"
            #searchIcon
            class="select--box"
            [value]="option"
            (change)="getValueFromDropdown($event.target.value); clearValue()"
            [disabled]="disableSerchFil"
        >
            <option *ngFor="let opt of listItems" [value]="opt">
                {{ opt }}</option
            >
        </select>
        <select
            *ngIf="erxSearch"
            #searchIcon
            class="select--box"
            [value]="option"
            (change)="getValueFromDropdown($event.target.value); clearValue()"
        >
            <option *ngFor="let opt of erxItems" [value]="opt">
                {{ opt }}</option
            >
        </select>
    </div>

    <div class="col-md-12 radiobuttons--block">
        <div class="row">
            <div class="col-8"  [hidden]="disableRadioButtons" *ngIf="!disableRadioButtons">
                    <div class="row" [hidden]="disableRadioButtons">
                            <div
                                class="custom-control custom-radio patient--wid"
                                [ngStyle]="{'margin-right': key === 'Prescriber' ? '20px' : '0' }"
                                *ngFor="let key of ['Patient', 'Prescriber', 'Drug']"
                            >
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    [id]="key"
                                    [checked]="
                                        (selectedValue ? selectedValue : 'Patient') === key
                                    "
                                    name="typesearch"

                                    [value]="key"
                                    (click)="
                                        getValueFromDropdown($event.target.value); clearValue()
                                    "
                                    *ngIf="!erxSearch"
                                />
                                <label
                                    class="custom-control-label dash--custom__label"
                                    [for]="key"
                                    *ngIf="!erxSearch"
                                >
                                    {{ key }}
                                    <!-- <div *ngIf="selectedValue===key" class="d-inline" style="font-weight: bold;color: rgb(247, 148,29);" [ngbTooltip]="instructToolTip"  tooltipClass="search-tooltip"  triggers="click" [autoClose]="true" placement="bottom-left">𝒊</div> -->
                                    <div *ngIf="selectedValue===key" class="d-inline position-absolute font-weight-bolder color_white top_n2px right_n30px height_23px width_23px text-center font_size_1_2rem background_orange border_radius_50per"  [ngbTooltip]="instructToolTip"  tooltipClass="search-tooltip"  triggers="click" [autoClose]="true" placement="bottom-left">𝒊</div>
                                    <ng-template #instructToolTip>
                                        <div>
                                            <div class="row text-left" *ngIf="selectedValue==='Patient'">
                                                <div class="col-md-12">
                                                    <label class="margin_bottom_1rem text-decoration-underline">Search Patient</label>
                                                </div>
                                                <p class="col-md-12">Enter Patient <strong> Last, First name</strong>(can be partial last and first name) to search <br>
                                                    or <br>
                                                    In order to search by patient's first name only, enter, <strong>FirstName</strong> <br>
                                                    or<br>
                                                    Enter Patient's <strong>Insurance ID Number</strong> to search<br>
                                                    or<br>
                                                    Enter Patient's <strong>DOB</strong> to search (mm/dd/yyyy)<br>
                                                    or <br>
                                                    Enter patient's <strong>Chart number</strong><br>
                                                    or<br>
                                                    Enter patient's Phone number<br>
                                                    or<br>
                                                    Enter patient's Rx number  followed by <strong>#</strong>
                                                </p>
                                            </div>
                                            <div class="row text-left" *ngIf="selectedValue==='Prescriber'">
                                                <div class="col-md-12">
                                                    <label class="margin_bottom_1rem text-decoration-underline">Search Prescriber</label>
                                                </div>
                                                <p class="col-md-12">Enter Prescriber<strong> Last name, first name </strong> <br>
                                                    or <br>
                                                    In order to search by prescriber's first name only, enter, <strong>FirstName</strong><br>
                                                    or<br>
                                                    Enter prescriber's <strong>NPI number</strong><br>
                                                    or<br>
                                                    Enter prescriber's <strong>License number</strong><br>
                                                    or<br>
                                                    Enter prescriber's <strong>number</strong><br>
                                                </p>
                                            </div>
                                            <div class="row text-left" *ngIf="selectedValue==='Drug'">
                                                <div class="col-md-12">
                                                    <label class="margin_bottom_1rem text-decoration-underline">Search Drug</label>
                                                </div>
                                                <p class="col-md-12">Enter Drug name to search <strong>(Format name, strength)</strong> <br>
                                                or<br>
                                                  Enter the <strong>11 digit NDC </strong>to search by Drug NDC <br>
                                                  or<br>
                                                  Enter the <strong>Drug quick code </strong> to search by Drug Quick code
                                                </p>
                                            </div>
                                        </div>
                                    </ng-template>
                                </label>
                            </div>
                            <div
                                class="custom-control custom-radio drug--wid"
                                *ngIf="!erxSearch"
                            >
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    id="RxSer"
                                    [checked]="selectedValue === 'Rx'"
                                    name="typesearch"
                                    value="Rx"
                                    (click)="
                                        getValueFromDropdown($event.target.value); clearValue()
                                    "
                                />
                                <label
                                    class="custom-control-label dash--custom__label"
                                    for="RxSer"
                                >
                                    Rx
                                </label>
                            </div>
                            <div
                            
                            class="custom-control custom-radio drug--wid margin_left_28px"
                            *ngIf="!erxSearch"
                        >
                            <input
                                class="custom-control-input"
                                type="radio"
                                id="RefillSer"
                                [checked]="selectedValue === 'Refill'"
                                name="typesearch"
                                value="Refill"
                                (click)="
                                    getValueFromDropdown($event.target.value); clearValue()
                                "
                            />
                            <label
                                class="custom-control-label dash--custom__label"
                                for="RefillSer"
                            >
                                Refill
                            </label>
                        </div>
                    </div>
            </div>
            <div [ngClass]="!disableRadioButtons ? 'col-4' : 'col-12'" >
                    <span class="text-right" *ngIf="selectedValue === 'Drug' && !disableRadioButtons">
                        <span >
                            <i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for Equv ;
                    </span>  <b class="color_orange">Shift + <i class="far fa-lg fa-long-arrow-alt-right color_orange"></i></b><span>for Gen Equv</span>
                </span>
            </span>
                    <span  class="d-inline help-box-information"  *ngIf="!disableRadioButtons"  [ngbTooltip]="instructshortCutHelp"  tooltipClass="help-tooltip"  triggers="click" [autoClose]="true" placement="right">Help</span>
                     <ng-template #instructshortCutHelp>
                        <div class="row text-left">
                            <div class="col-md-12">
                                <label class="margin_bottom_1rem text-decoration-underline">Short cut Help</label>
                            </div>
                                <ul class="">
                                    <li> alt + / to focus in global search </li>
                                    <li> p: to search patient </li>
                                    <li> pr: to search prescriber </li>
                                    <li> d: to search drug </li>
                                    <li> rx: to search Rx </li>
                                    <li> ctrl + x: to add new patient </li>
                                    <li> ctrl + y: to add new prescriber </li>
                                    <li> ctrl + z: to add new drug </li>
                                    <li> ctrl + r: to add new rx </li>
                                </ul>
                        </div>
                    </ng-template>
            </div>
        </div>

    </div>
    <input
    *ngIf="!(selectedValue === 'Rx' || selectedValue === 'Refill')"
        type="text"
        #searchBox
        [tabindex]="tabIndex"
        name="searchBox"
        class="form-control search--input__box z-index-0"
        placeholder="Search {{ placholderText }}"
        appChangeDebounce
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        (keydown.esc)="clearFocus()"
        KeyUpDown
        [disabled]="inputDisable"
        [value]="inputValue"
        (keyup.enter)="keyTab($event)"
        (blur)="focusedOutofSearch()"
        (change)="checkIsNullValue($event?.target?.value)"
        [id]="searchID? searchID : 'iSearchBox'"
        ngbtypeaheadpopup
        [placement]="'bottom'"
        #instance="ngbTypeahead"
        [BarcodeDetector]="selectedValue === 'Drug' && enableBarcode"
        (EmitBarcode)="getBarcodeInfo($event)"
    />
    <div  class="col-lg-12 dropdown eqv_drugs---search search-glbl" *ngIf="suggestList && suggestList.length > 0">
        <ul [ngClass]="showlgList ? 'lg-dropdown-menu show eq--list' : 'dropdown-menu show eq--list'">
            <li class="dropdown-item"  [ngClass]="i===selectedSuggestRowId ? 'active':''" *ngFor="let r of suggestList;let i = index" (click)="formatter(r)" (mouseover)="selectedSuggestRowId=i">
                <div *ngIf="selectedValue == 'Patient'" class="col-md-12 padding-0 dropdown-item">
                    <app-patient-card [ResultData]="r" [RouterURL]="patRouterURL" [IsAllToShow]="erxSearch"
                        [ShowOnlyPatName]="showPatName" [index]="r['index']" [searchTerm]="searchTextValue">
                    </app-patient-card>
                </div>
                <div *ngIf="selectedValue == 'Prescriber'" class="col-md-12 padding-0 dropdown-item">
                    <app-prescriber-result-card [PrescriberResultData]="r" [RouterURL]="presRouterURL"
                        [IsAllToShow]="erxSearch" [ShowOnlyPrescName]="showPrescName" [index]="r['index']"
                        [searchTerm]="searchTextValue"></app-prescriber-result-card>
                </div>
                <div *ngIf="selectedValue == 'Drug'" class="col-md-12 padding-0 drug--grid dropdown-item">
                    <app-drug-result-card [DrugResultData]="r" [RouterURL]="drugRouterURL" [IsAllToShow]="erxSearch"
                        [ShowOnlyDrugName]="showDrugName" (EmitEqDrugs)="equivalentDrugs($event,true)"
                        (EmitCloseEqDrugs)="showEqDrugs=false" (EmitGenDrugs)="equivalentDrugs($event,false)"
                        [index]="r['index']" [searchTerm]="searchTextValue"></app-drug-result-card>
                </div>
            </li>
        </ul>
    </div>
        <div *ngIf="showEqDrugs && selectedValue === 'Drug' && EqDrugs.length > 0" class="col-lg-12 dropdown eqv_drugs---search">
            <div class="eq_drug_header text-center"><h3>{{eqDrugHeading}}</h3></div>
            <ul [ngClass]="showlgList ? 'lg-dropdown-menu show eq--list' : 'dropdown-menu show eq--list'" >
                <li class="dropdown-item"  [ngClass]="i===selecteEqDrugRowId ? 'active':''" *ngFor="let eqDrug of EqDrugs;let i = index" (click)="selectedEqDrug(eqDrug)" (mouseover)="selecteEqDrugRowId=i">
                    <app-drug-result-card
                        [DrugResultData]="eqDrug"
                        [RouterURL]="drugRouterURL"
                        [IsAllToShow]="erxSearch"
                        [ShowOnlyDrugName]="showDrugName"
                        [index]="i"
                    ></app-drug-result-card>
                </li>
            </ul>
        </div>
    <input
        *ngIf="selectedValue === 'Rx' || selectedValue === 'Refill'"
        type="text"
        #searchBox
        [tabindex]="tabIndex"
        name="searchBox"
        class="form-control search--input__box z-index-0"
        placeholder="Search {{ placholderText }}"
        (keydown.esc)="clearFocus()"
        [disabled]="inputDisable"
        [value]="inputValue"
        (keydown.enter)="searchByKeyWord($event?.target?.value); keyTab($event)"
        (blur)="focusedOutofSearch()"
        id="iSearchBox"
        appChangeDebounce
        [ngbTypeahead]="searchRx"

    />
    <span class="" *ngIf="hasFilters">
        <div class="search--icon">
            <i class="far fa-search" (click)="inputClearedBySearchIcon()"></i>
        </div>
    </span>
    <a
        *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Patient' &&
            hasAddPatPrivs
        "
        class="add-new--icon p-0 mt-1"
        (click)="addNewPatientPrescDrug()"
    >
        <!--i class="far fa-plus-circle" title="Add Patient"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_primary hotkey_primary--add-button'" [title]="isFrmRx ? 'Alt+X' : 'Ctrl+X'" ><span>X</span>ADD</button>
    </a>
    <a
    (click)="openOveride('PatientFile', 'Add')"
        *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Patient' &&
            !hasAddPatPrivs
        "
        class="add-new--icon p-0 mt-1"
    >
        <!--i class="far fa-plus-circle" title="User has No privilege"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_primary hotkey_primary--Override-Global'" [title]="isFrmRx ? 'Alt+X' : 'Ctrl+X'" ><span>X</span>ADD</button>
    </a>
    <a
        *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Prescriber' &&
            hasAddprescPrivs
        "
        class="add-new--icon p-0 mt-1"
        (click)="addNewPatientPrescDrugRx()"
    >
        <!--i class="far fa-plus-circle" title="Add Prescriber"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_primary hotkey_primary--Override-Global'" [title]="isFrmRx ? 'Alt+Y' : 'Ctrl+Y'" ><span>Y</span>ADD</button>
    </a>
    <a
    (click)="openOveride('PrescriberFile', 'Add')"
        *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Prescriber' &&
            !hasAddprescPrivs
        "
        class="add-new--icon p-0 mt-1"
    >
        <!--i class="far fa-plus-circle" title="User has No privilege"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_primary hotkey_primary--Override-Global'" [title]="isFrmRx ? 'Alt+Y' : 'Ctrl+Y'" ><span>Y</span>ADD</button>
    </a>
    <a
        *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Drug' &&
            hasAddDrugPrivs
        "
        class="add-new--icon p-0 mt-1"
        (click)="addNewPatientPrescDrugRx()"
    >
        <!--i class="far fa-plus-circle" title="Add Drug"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_primary hotkey_primary--Override-Global'" [title]="isFrmRx ? 'Alt+Z' : 'Ctrl+Z'" ><span>Z</span>ADD</button>
    </a>
    <a
    (click)="openOveride('DrugFile', 'Add')"
        *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Drug' &&
            !hasAddDrugPrivs
        "
        class="add-new--icon p-0 mt-1"
    >
        <!--i class="far fa-plus-circle" title="User has No privilege"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_primary hotkey_primary--Override-Global'" [title]="isFrmRx ? 'Alt+Z' : 'Ctrl+Z'"><span>Z</span>ADD</button>
    </a>



    <ng-template #rt let-r="result" let-t="term">
        <div *ngIf="selectedValue == 'Patient'" class="col-md-12 padding-0">
            <app-patient-card
                [ResultData]="r"
                [RouterURL]="patRouterURL"
                [IsAllToShow]="erxSearch"
                [ShowOnlyPatName]="showPatName"
                [index]="r['index']"
                [searchTerm]="searchTextValue"
            ></app-patient-card>
        </div>
        <div *ngIf="selectedValue == 'Prescriber'" class="col-md-12 padding-0">
            <app-prescriber-result-card
                [PrescriberResultData]="r"
                [RouterURL]="presRouterURL"
                [IsAllToShow]="erxSearch"
                [ShowOnlyPrescName]="showPrescName"
                [index]="r['index']"
                [searchTerm]="searchTextValue"
            ></app-prescriber-result-card>
        </div>
        <div
            *ngIf="selectedValue == 'Drug'"
            class="col-md-12 padding-0 drug--grid"
        >
        <app-drug-result-card
                [DrugResultData]="r"
                [RouterURL]="drugRouterURL"
                [IsAllToShow]="erxSearch"
                [ShowOnlyDrugName]="showDrugName"
                (EmitEqDrugs)="equivalentDrugs($event,true)"
                (EmitCloseEqDrugs)="showEqDrugs=false"
                (EmitGenDrugs)="equivalentDrugs($event,false)"
                [index]="r['index']"
                [searchTerm]="searchTextValue"
            ></app-drug-result-card>
        </div>
    </ng-template>
</div>

<!-- Start Warning PopUps -->

<ng-template #addPatient let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal  (oncrossBtnClick)="closeModal()">
        <h4 header>Search Patient</h4>
        <div body *ngIf="searchValue && searchValue.endsWith('#')"><b>Patient not found with given Rx: </b> <span class="font-bigger text-primary word_break">{{searchValue  | uppercase}}</span>. Would you like to add?</div>
        <div body *ngIf="searchValue && !searchValue.endsWith('#')"><b>Patient: </b> <span class="font-bigger text-primary word_break">{{searchValue  | uppercase }}</span> not found. Would you like to add?</div>
        <button
            footer
            ngbAutofocus
            id="addOk"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="y"
            (click)="addNewPatientPrescDrug()"
            (keydown.enter)="addNewPatientPrescDrug()"
        >
            <b>Y</b> YES
        </button>
        <button
            footer
            ngbAutofocus
            id="addcDefActn"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="c"
            (click)="closeModal()"
            (keydown.enter)="closeModal()"
        >
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addPrescriber let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Prescriber</h4>
        <div body><b>Prescriber: </b> <span class="font-bigger text-primary word_break">{{searchValue | uppercase}}</span> not found. Would you like to add?</div>
        <button
            footer
            ngbAutofocus
            id="addOk"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="y"
            (click)="addNewPatientPrescDrug()"
            (keydown.enter)="addNewPatientPrescDrug()"
        >
            <b>Y</b> YES
        </button>
        <button
            footer
            ngbAutofocus
            id="addOk"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="m"
            (click)="addNewPatientPrescDrug(true)"
            (keydown.enter)="addNewPatientPrescDrug(true)"
        >
            <b>M</b> Search From MMS
        </button>
        <button
            footer
            id="addcDefActn"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="c"
            (click)="closeModal()"
            (keydown.enter)="closeModal()"
        >
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addDrug let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Drug</h4>
        <div body class="word-break" *ngIf="allowToAddNewDrug"><b>Drug: </b><span class="font-bigger text-primary">{{searchValue  | uppercase}}</span> not found. Would you like to add?</div>
        <div body class="word-break"  *ngIf="!allowToAddNewDrug"><b>Drug: </b><span class="font-bigger text-primary">{{searchValue  | uppercase }}</span> not found</div>
        <button
            footer
            ngbAutofocus
            id="addOk"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="y"
            (click)="addNewPatientPrescDrug()"
            (keydown.enter)="addNewPatientPrescDrug()"
            *ngIf="allowToAddNewDrug"
        >
        <b>Y</b> YES

            <!-- <span *ngIf="!isFrmErx">Y</span> Add
            <span *ngIf="isFrmErx">Y</span> Yes -->
        </button>
        <button *ngIf="!allowToAddNewDrug" footer
        type="submit"
        class="hotkey_primary"
        appShortcutKey InputKey="o"(click)="d('Cross click')"
        (keydown.enter)="d('Cross click')"><b>O</b> OK</button>
        <button
            *ngIf="!isFrmErx && allowToAddNewDrug"
            footer
            ngbAutofocus
            id="addOk"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="m"
            (click)="addNewPatientPrescDrug(true)"
            (keydown.enter)="addNewPatientPrescDrug(true)"
        >
            <b>M</b> Search From MMS
        </button>
        <button
            footer
            id="addcDefActn"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="c"
            (click)="closeModal()"
            (keydown.enter)="closeModal()"
            *ngIf="allowToAddNewDrug"
        >
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Rx</h4>
        <div body><span class="font-bigger text-primary">Rx not found.</span></div>
        <!-- <button
            footer
            ngbAutofocus
            id="addOk"
            type="submit"
            class="hotkey_primary"
            appShortcutKey InputKey="o"
            (click)="addNewPatientPrescDrugRx()"
            (keydown.enter)="addNewPatientPrescDrugRx()"
        >
            <b>O</b> Ok
        </button> -->

        <button footer id="addcDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="closeModal()" (keydown.enter)="closeModal()">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>


<app-override-log *ngIf="openOverride" [OverrideEndpoint]="overrideEndpoint"
[PrivMask]="privType" [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

<!-- Close Warning PopUps -->
