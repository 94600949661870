import { Injectable } from "@angular/core";
import { CommonService } from "../services";
import * as moment from "moment";
import { DateCheckUtil } from "./date-checks.util";
import { ColorCode } from "./his-color.util";
import { CommonUtil } from "./common.util";
import { HoverOver } from "./hover-over.util";
import { isPlatformWorkerApp } from "@angular/common";


@Injectable({
    providedIn: "root"
})
export class ColorCodeRx {
    prescCountData: any;
    constructor(private _commonServ: CommonService, private _dateCheck: DateCheckUtil,
         private _hisColorUtil: ColorCode, private _comnUtil:CommonUtil) {}
    showRxColorCodeAndHoverOver(item, rxObj, type?, hoverOver?, withHoverBack?, frmPatScrn?) {
        let aref: any;
        let ref: any;
        let RxNo: any;
        let fillNo: any;
        let remQtyPartialFill: any;
        let Status: any;
        let duedays: any;
        let daysRemaingForDue: any;
        let drugClass: any;
        let InsRefillDueConsumptionThreshold: any;
        let IsDiscountinued: any;
        let OrderStatus: any;
        let OrdDate: any;
        let FillDate: any;
        let rxExpired = false;
        let rxExpiredByInsuranceOrAccountSettings = false;
        let colorForText = ""; // "his-bg--white__text--black";
        let MaxRefillsAllowed: any;
        let OrderToFillDays: any;
        let supplyDays: any;
        let controRx: boolean;
        let hoverOverDisplay: any;
        let TotRxRemQty: any;
        let RemQtyCheck: any;
        let isDeferredRxExpired: boolean = false;
        let rxStatusforColorCoding : string;
        if (item) {
            if (rxObj === "RxObject") {
                let tqtyDisp = "0.000";
                let dispQty = 0;
                let OrdQty = 0;
                let overfillRxOrdQty = 0;
                if (frmPatScrn) {
                    const RfOrRx = this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
                    if (item.prescriptionQuantities && item.prescriptionQuantities.length) {
                        const rxc = item.prescriptionQuantities.find(v => v["PrescNum"] === item.Prescription.PrescNum);
                        tqtyDisp = rxc && rxc["Qty"] ? (rxc["Qty"]).toFixed(3) : "0.000";
                        dispQty = parseFloat(tqtyDisp);
                        OrdQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * (item.Prescription.ReFillNum + 1)) : 0;
                        overfillRxOrdQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * ((item ? item.PrescReFill.primeRxAuthRefillCount : 0) + 1)) : 0;
                    }
                    const rxqtyrem = (OrdQty && ((!item.isRxTransferred && (+RfOrRx)) || !(+RfOrRx)) ? (OrdQty - dispQty).toFixed(3) : 0);
                    TotRxRemQty  = (item.PrescReFill.IsOverFillRx) ? ( (item["islatestrefill"]  ? (overfillRxOrdQty - dispQty).toFixed(3) : 0)) : rxqtyrem;
                } else {
                    TotRxRemQty = +item.TotalRemainingQty;
                }
                aref = item.Prescription.ReFillNum;
                ref = item.PrescReFill.ReFillNum;
                RxNo = item.Prescription.PrescNum;
                fillNo = this._commonServ.checkIsPartialFill(item.PrescReFill, item.Prescription) ? item.PrescReFill.PartialFillNo : " ";
                 fillNo = (fillNo && fillNo !== " ") ? fillNo : 1;
                remQtyPartialFill = +(item.PrescReFill.PartialIndStatus === "C") ?  "" : (item.islatestpartialrefill ?
                     (item && item.partialCummulativeDetails ? (item.partialCummulativeDetails.TotRemQty ? item.partialCummulativeDetails.TotRemQty : 0) : 0).toFixed(3) : "");
                remQtyPartialFill = remQtyPartialFill ? remQtyPartialFill : 0;
                // TotRxRemQty = +item.TotalRemainingQty;
                RemQtyCheck = item.PrescReFill.IsOverFillRx ? TotRxRemQty : remQtyPartialFill;
                duedays = Math.round(item.PrescReFill.SupplyDays) -
                moment().startOf("day").diff(moment.utc(item.PrescReFill.FillDtTm).startOf("day"), "days");

                drugClass =  item.Drug && item.Drug.drugclass >= 0 ? item.Drug.drugclass : "";
                InsRefillDueConsumptionThreshold =  item.primaryInsurance && item.primaryInsurance.refillDueConsumptionThreshold ?
                 item.primaryInsurance.refillDueConsumptionThreshold : "";
                 IsDiscountinued =  item.Prescription && item.Prescription.IsDiscontinued ? item.Prescription.IsDiscontinued : false ;
                OrderStatus =  item.PrescReFill && item.PrescReFill.OrderStatus ? item.PrescReFill.OrderStatus :
                 item.OrderStatus ? item.OrderStatus : null;
                OrdDate =  new Date(moment.utc(item.PrescReFill.OrderDtTm).format("MM/DD/YYYY"));
                FillDate =  new Date(moment.utc(item.PrescReFill.FillDtTm).format("MM/DD/YYYY"));
                supplyDays = item.PrescReFill && item.PrescReFill.SupplyDays ? Math.round(item.PrescReFill.SupplyDays) : null;
                Status = this._comnUtil.getRxStatusinSignleCharacter(item);
                } else {
                    aref = item.AuthNumber >= 0 ?  item.AuthNumber : item.AuthRefills >= 0 ? item.AuthRefills :
                     item.AuthNum >= 0 ? item.AuthNum  : "";
                    ref = item.RefillNumber >= 0 ? item.RefillNumber : item.ReFillNum >= 0 ? item.ReFillNum
                     : item.Refill >= 0 ? item.Refill : item.RefillNo >= 0 ? item.RefillNo : "";
                    RxNo = item.RxNo ? item.RxNo : item.PrescNum ? item.PrescNum : item.RxNum ? item.RxNum : "";
                    fillNo =  item.FillNo ?  item.FillNo : item.FillNum ? item.FillNum : item.Fill ? item.Fill : item.PartialFillNo ? item.PartialFillNo : " ";
                    fillNo = (fillNo && fillNo !== " ") ? fillNo : 1;
                    remQtyPartialFill = item.QtyRemPartialFill ?  item.QtyRemPartialFill : 0 ;
                    TotRxRemQty = item.TotalRemainingQty;
                    RemQtyCheck = (item.isOverFillRx || item.IsOverFillRx) ? TotRxRemQty : remQtyPartialFill;
                    Status =  this._comnUtil.getRxStatus(item.PrescStatusId ? item.PrescStatusId  : item.RxStatusId , 
                        item.RefillStatusId ? item.RefillStatusId  : item.PrescriptionStatus , item.BillStatus);
                    supplyDays = item.Day >= 0 ? item.Day : item.DaysSupply >= 0 ? item.DaysSupply : item.Days >= 0 ?
                     item.Days : item.SupplyDays >= 0 ? item.SupplyDays : "";
                    const DueDtTm = item.DueDtTm  ? (item.DueDtTm) : "";
                    const FilldateTime = item.FilldateTime  ? item.FilldateTime : item.FillDtTm ? item.FillDtTm : "";
                    const OrderDateTime = item.FilldateTime  ? item.FilldateTime : item.OrderDtTm ? item.OrderDtTm : "";
                    duedays =  Math.round(supplyDays) -
                moment().startOf("day").diff(moment.utc(FilldateTime).startOf("day"), "days");

                    drugClass = item.DrugClass >= 0 ? item.DrugClass : "";
                    InsRefillDueConsumptionThreshold = item.RefillDueConsumptionThreshold ? item.RefillDueConsumptionThreshold : "";
                    IsDiscountinued = item.IsDiscontinued ? item.IsDiscontinued : false;
                    OrderStatus = item.OrderStatus ? item.OrderStatus : "";
                    OrdDate =  new Date(moment.utc(OrderDateTime).format("MM/DD/YYYY"));
                    FillDate =  new Date(moment.utc(FilldateTime).format("MM/DD/YYYY"));
                 }
                daysRemaingForDue = duedays;
                controRx =  drugClass >= 2 && drugClass <= 5 ? true : false;
                const rxExpiryDateByInsuranceOrControlSettings =   item.rxExpiryDateByInsuranceOrControlSettings ?
                item.rxExpiryDateByInsuranceOrControlSettings === "0001-01-01T00:00:00" ? null :
                item.rxExpiryDateByInsuranceOrControlSettings :  null;
                const rxExpiryDateByInsuranceAfterDateCheck = rxExpiryDateByInsuranceOrControlSettings ?
                this._dateCheck.checkExpiryDate(rxExpiryDateByInsuranceOrControlSettings)  :  false;
                const  RxExpirationDate =   item.RxExpirationDate ? item.RxExpirationDate === "0001-01-01T00:00:00" ?
                 null : moment(item.RxExpirationDate).format("MM/DD/YYYY") : null;
                const RxExpirationDateCheck = RxExpirationDate ? this._dateCheck.checkExpiryDate(RxExpirationDate)  :  false;
                const rxThresholdCheck = item.rxThreshold ? this._dateCheck.checkExpiryDate(item.rxThreshold)  :  false;
                const RxExpDateByUser = item.rxExpiryDateByUser ? item.rxExpiryDateByUser :  null;
                const RxExpirationDue = RxExpirationDate ? moment().diff(RxExpirationDate, "days") : null;
                const OrdFillDateDiff =  (FillDate.getTime() - OrdDate.getTime()) / (1000 * 3600 * 24);
                const RxExpDate = item.RxExpirationDate ? item.RxExpirationDate === "0001-01-01T00:00:00" ?
                 null : item.RxExpirationDate :  null ;
                const isRxExpireDateAfterDateCheck = RxExpDate ? this._dateCheck.checkExpiryDate(RxExpDate)  :  false;
                const cntrlRefllOptns = this._commonServ.controlFileSettings$["source"]["value"];
                if (drugClass === 0 || drugClass === 1) {
                   MaxRefillsAllowed = item.primaryInsurance && item.primaryInsurance.MaxRefills >= 0 ?
                    item.primaryInsurance.MaxRefills : item.MaxRefillsAllowed ? item.MaxRefillsAllowed : 99;
                   OrderToFillDays =  item.primaryInsurance && item.primaryInsurance.OrderToFillDays >= 0 ?
                    item.primaryInsurance.OrderToFillDays : item.OrderToFillDays ? item.OrderToFillDays : 180;
                } else {
                    if (cntrlRefllOptns && cntrlRefllOptns.ControlRefillOptions && cntrlRefllOptns.ControlRefillOptions.length > 0) {
                           const val =  cntrlRefllOptns.ControlRefillOptions.find(ele => +(ele.DrugClass) === drugClass);
                           if (val) {
                               MaxRefillsAllowed =   val.MaxRefillAllow >= 0  ? val.MaxRefillAllow : 99;
                                OrderToFillDays  =  val.OrderFillDays >= 0  ? val.OrderFillDays : 180;
                           }
                    }
                }

                let supplydaysCheck = 0;
                const drugThresholdValue = this._commonServ.getSetttingValue("RxSettings", "REFILL_DUE_CONSUMPTION_THERSHOLD");
                if (InsRefillDueConsumptionThreshold) {
                    supplydaysCheck = supplyDays ?  Math.round(+supplyDays - ((+supplyDays * (+InsRefillDueConsumptionThreshold ?
                         +InsRefillDueConsumptionThreshold : 0)) / 100)) : null;
                } else {
                    supplydaysCheck = supplyDays ? Math.round(+supplyDays -
                         ((+supplyDays * (+drugThresholdValue ? +drugThresholdValue : 0)) / 100)) :
                     null;
                }
        if (RxExpDate && isRxExpireDateAfterDateCheck) {
            rxExpired = true;
            if (RxExpDateByUser) {
                const d1 = new Date(moment(RxExpDate).format("MM/DD/YYYY"));
                const d2 = new Date(moment(RxExpDateByUser).format("MM/DD/YYYY"));
                if (rxExpiryDateByInsuranceOrControlSettings && rxExpiryDateByInsuranceAfterDateCheck) {
                    const d3 =  new Date(moment(rxExpiryDateByInsuranceOrControlSettings).format("MM/DD/YYYY"));
                    if (((d1.getTime() === d2.getTime()) && (d2.getTime() === d3.getTime())) || (d3 < d2 && !(d1 < d3))) {
                        rxExpiredByInsuranceOrAccountSettings =  (aref >= 0) ? true : false;
                    } else {
                        rxExpiredByInsuranceOrAccountSettings =  false;
                    }
                } else {
                    rxExpiredByInsuranceOrAccountSettings =  false;
                }
            } else {
                rxExpiredByInsuranceOrAccountSettings = true;
            }
        }
        
        if (
            Status !== "D" && ((IsDiscountinued === "YES" || OrderStatus === "D" || IsDiscountinued === true)) &&
           ( !rxExpired && !((RxExpirationDue && RxExpirationDue > 0)))
        ) {
            colorForText = (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx)) ? "his-bg--grey__text--white" : "his-bg--red__text--white";
            hoverOverDisplay =  RxNo + ": " + (controRx ? "Control" : "Non-Control") + (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? " Rx is Discontinued from Cancel Rx" : " Rx is Discontinued");
        } else if (Status === "D" && (!rxExpired && !(RxExpirationDue && RxExpirationDue > 0)) &&
        !(IsDiscountinued === "YES" || OrderStatus === "D" || IsDiscountinued === true)) {
            colorForText = "his-bg--blue__text--white";
            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Deferred";
        } else if (Status === "D" && (ref === 0 && fillNo === 1 && (RxExpirationDue && RxExpirationDue > 0)) &&
         !(IsDiscountinued === "YES" || OrderStatus === "D" || IsDiscountinued === true)) {
            colorForText = "his-bg--blue_peach__text--black";
            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Deferred Rx expired due to Order to Fill Days";
            isDeferredRxExpired = true;
        } else if (Status === "D" && (rxExpired || (RxExpirationDue && RxExpirationDue > 0))) {
            if (rxExpiredByInsuranceOrAccountSettings) {
                colorForText = "his-bg--blue_peach__text--black";
                hoverOverDisplay =  RxNo + ": " + (controRx ? "Control Deferred Rx expired due to Max Days for Refill " :
                 "Non-Control Deferred Rx expired due to Order To Last Refill");
                 isDeferredRxExpired = true;
            } else {
                colorForText = "his-bg--blue_white__text--black";
                hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                 " Deferred Rx expired due to Expiration date defined on Edit Rx";
                 isDeferredRxExpired = true;
            }
        } else if (Status === "D" && ( (IsDiscountinued === "YES" || OrderStatus === "D" || IsDiscountinued === true))) {
            colorForText = (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx)) ? "his-bg--blue_grey__text--white" : "his-bg--blue_red__text--white";
            hoverOverDisplay =  RxNo + ": " + (controRx ? "Control" : "Non-Control") + (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? " Deferred Rx is Discontinued from Cancel Rx" : " Deferred Rx is Discontinued");
        } else if (Status === "T" && +(this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT"))) {
            colorForText = "his-bg--white__text--black";
            hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
        } else if ((rxExpired || (RxExpirationDue && RxExpirationDue > 0))  && ( (IsDiscountinued === "YES" ||
        OrderStatus === "D" || IsDiscountinued === true))) {
            if (rxExpiredByInsuranceOrAccountSettings) {
                colorForText = (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx)) ? "his-bg--peach_grey__text--black" : "his-bg--peach_red__text--black";
                 hoverOverDisplay = RxNo + ": " + ((item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx)) ? (controRx ?"Control Rx is Discontinued from cancel Rx and Expired due to Max Days for Refill" : "Non-Control Rx is Discontinued from cancel Rx and Expired due to Order To Last Refill") :
                 (controRx ? "Control Rx is Discontinued and Expired due to Max Days for Refill" : "Non-Control Rx is Discontinued and Expired due to Order To Last Refill"));
            } else {
                colorForText = (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx)) ? "his-bg--white_grey__text--black" : "his-bg--white_red__text--black";
                hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                 " Rx is Discontinued and Expired due to Expiration date defined on Edit Rx";
            }
        }
        if ((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0))   && daysRemaingForDue > 0 && !TotRxRemQty && Status !== "D") {
            colorForText = "his-bg--white__text--black";
            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx";
        }  else if ((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0))   && daysRemaingForDue <= 0 && !TotRxRemQty && Status !== "D") {
            colorForText = "his-bg--white__text--black";
            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
        } else if ((rxExpired || (RxExpirationDue && RxExpirationDue > 0)) && !colorForText && TotRxRemQty
        ) {
            if (rxExpiredByInsuranceOrAccountSettings) {
                colorForText = "his-bg--peach__color--white";
                hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx expired due to Max Days for Refill" :
                 "Non-Control Rx expired due to Order To Last Refill");
            } else {
                colorForText = "his-bg--white__text--black";
                hoverOverDisplay =  RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                 " Rx expired due to Expiration date defined on Edit Rx";
            }
        } else if (daysRemaingForDue < supplydaysCheck && !colorForText ) {
            if (type === "Rx") {
                if (aref !== ref && aref >= ref) {
                    if (
                        (RxExpirationDue && RxExpirationDue <= 0) ||
                        (!RxExpirationDue && daysRemaingForDue < 365)
                    ) {
                        if ((ref >= MaxRefillsAllowed)  && !TotRxRemQty && !(RxExpirationDate && RxExpirationDateCheck)) {
                            colorForText = "his-bg--peach__color--white";
                            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                             " Rx expired due to Maximum refills allowed";
                        } else if (!TotRxRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                            colorForText = "his-bg--white__text--black";
                            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control")
                        } else if (item.islatestrefill || item.isLatestRefill) {
                            // if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                                if (item.islatestpartialrefill) {
                                    if (rxThresholdCheck) {
                                        colorForText = "his-bg--darkgreen__text--white";
                                        hoverOverDisplay = RxNo + ": Refill Due";
                                        rxStatusforColorCoding = "Refill Due";
                                    } else {
                                        colorForText = "his-bg--lightgreen__text--white";
                                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                                    }
                                } else {
                                    colorForText = "his-bg--white__text--black";
                                    hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                                }
                        } else {
                                colorForText = "his-bg--white__text--black";
                                hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                        }
                        // } else {
                        //     colorForText = "his-bg--darkgreen__text--white";
                        //     hoverOverDisplay = RxNo + ": Refill Due";
                        // }
                    } else {
                        colorForText = "his-bg--white__text--black";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    }
                } else if (aref !== 0  && daysRemaingForDue <= 0 && !TotRxRemQty) {
                    colorForText = "his-bg--white__text--black";
                    hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
                } else if (TotRxRemQty) {
                    if ((ref >= MaxRefillsAllowed)  && !TotRxRemQty && !(RxExpirationDate && RxExpirationDateCheck)) {
                        colorForText = "his-bg--peach__color--white";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                         " Rx expired due to Maximum refills allowed";
                    } else if (item.islatestrefill || item.isLatestRefill) {
                        // if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                            if (item.islatestpartialrefill) {
                                if ((TotRxRemQty) && rxThresholdCheck) {
                                    colorForText = "his-bg--darkgreen__text--white";
                                    hoverOverDisplay = RxNo + ": Refill Due";
                                    rxStatusforColorCoding = "Refill Due";
                                } else {
                                    colorForText = "his-bg--lightgreen__text--white";
                                    hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                                }
                            } else {
                                colorForText = "his-bg--white__text--black";
                                hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                            }
                    } else {
                        colorForText = "his-bg--white__text--black";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    }
                    // } else {
                    //     colorForText = "his-bg--darkgreen__text--white";
                    //     hoverOverDisplay = RxNo + ": Refill Due";
                    // }
                } else {
                    colorForText = "his-bg--white__text--black";
                    if (daysRemaingForDue <= 0 && !TotRxRemQty) {
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
                    } else {
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    }
                }
            } else {
                if (aref !== ref && aref >= ref) {
                    if (
                        (RxExpirationDue && RxExpirationDue <= 0) ||
                        (!RxExpirationDue && daysRemaingForDue < 365)
                    ) {
                        if ((ref >= MaxRefillsAllowed)  && !TotRxRemQty && !(RxExpirationDate && RxExpirationDateCheck)) {
                            colorForText = "his-bg--peach__color--white";
                            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                             " Rx expired due to Maximum refills allowed";
                        } else if (!TotRxRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                            colorForText = "his-bg--white__text--black";
                            hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control")
                        } else {
                            if (rxThresholdCheck) {
                                colorForText = "his-bg--darkgreen__text--white";
                                hoverOverDisplay = RxNo + ": Refill Due";
                                rxStatusforColorCoding = "Refill Due";
                            } else {
                                colorForText = "his-bg--lightgreen__text--white";
                                hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                            }
                        }
                    } else {
                        colorForText = "his-bg--white__text--black";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    }
                }  else if (rxThresholdCheck) {
                    colorForText = "his-bg--darkgreen__text--white";
                    hoverOverDisplay = RxNo + ": Refill Due";
                    rxStatusforColorCoding = "Refill Due";
                } else {
                    colorForText = "his-bg--white__text--black";
                    hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
                }
            }
        } else if (daysRemaingForDue >= supplydaysCheck && !colorForText ) {
            if (aref !== ref && aref >= ref) {
                if (type === "Rx") {
                    if ((ref >= MaxRefillsAllowed) && !TotRxRemQty && !(RxExpirationDate && RxExpirationDateCheck)) {
                        colorForText = "his-bg--peach__color--white";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                         " Rx expired due to Maximum refills allowed";
                    } else if (!TotRxRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        colorForText = "his-bg--white__text--black";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control")
                    } else if (item.islatestrefill || item.isLatestRefill) {
                        // if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                            if (item.islatestpartialrefill) {
                                if (rxThresholdCheck ) {
                                    colorForText = "his-bg--darkgreen__text--white";
                                    hoverOverDisplay = RxNo + ": Refill Due";
                                    rxStatusforColorCoding = "Refill Due";
                                } else {
                                    colorForText = "his-bg--lightgreen__text--white";
                                    hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                                }
                            } else {
                                colorForText = "his-bg--white__text--black";
                                hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                            }
                    } else {
                        colorForText = "his-bg--white__text--black";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    }
                    // else {
                    //     if (remQty !== 0 || rxThresholdCheck) {
                    //         colorForText = "his-bg--darkgreen__text--white";
                    //         hoverOverDisplay = RxNo + ": Refill Due";
                    //     } else {
                    //         colorForText = "his-bg--lightgreen__text--white";
                    //         hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                    //     }
                    // }
                } else {
                    if ((ref >= MaxRefillsAllowed) && !TotRxRemQty && !(RxExpirationDate && RxExpirationDateCheck)) {
                        colorForText = "his-bg--peach__color--white";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") +
                         " Rx expired due to Maximum refills allowed";
                    } else if (!TotRxRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        colorForText = "his-bg--white__text--black";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control")
                    } else if ( rxThresholdCheck) {
                        colorForText = "his-bg--darkgreen__text--white";
                        hoverOverDisplay = RxNo + ": Refill Due";
                        rxStatusforColorCoding = "Refill Due";
                    } else {
                        colorForText = "his-bg--lightgreen__text--white";
                        hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                    }
                }
            } else if (TotRxRemQty) {
                if ((ref >= MaxRefillsAllowed) && !TotRxRemQty && !(RxExpirationDate && RxExpirationDateCheck)) {
                    colorForText = "his-bg--peach__color--white";
                    hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
                } else if (item.islatestrefill || item.isLatestRefill) {
                    // if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                        if (item.islatestpartialrefill) {
                            if ((TotRxRemQty) && rxThresholdCheck) {
                                colorForText = "his-bg--darkgreen__text--white";
                                hoverOverDisplay = RxNo + ": Refill Due";
                                rxStatusforColorCoding = "Refill Due";
                            } else {
                                colorForText = "his-bg--lightgreen__text--white";
                                hoverOverDisplay = RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                            }
                        } else {
                            colorForText = "his-bg--white__text--black";
                            hoverOverDisplay =  RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                        }
                        
                } else {
                    colorForText = "his-bg--white__text--black";
                    hoverOverDisplay =  RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                }
                // } else {
                //     colorForText = "his-bg--darkgreen__text--white";
                //     hoverOverDisplay = RxNo + ": Refill Due";
                // }
            } else {
                colorForText = "his-bg--white__text--black";
                hoverOverDisplay =  RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
            }
        } else if (!colorForText ) {
            colorForText = "his-bg--white__text--black";
            hoverOverDisplay =  RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
        }
        if(withHoverBack === 'withHoverBack')
            return  {hoverOverDisplay: hoverOverDisplay+".", getBackgroundForClass : this._hisColorUtil.getBackgroundForClass(colorForText) , isDeferredRxExpired : isDeferredRxExpired , rxStatusforColorCoding : rxStatusforColorCoding};
        else
            return  hoverOver === "hoverOver"  ? hoverOverDisplay : this._hisColorUtil.getBackgroundForClass(colorForText);
    }
}
}


