<ng-template #RxNOTES let-c="close" let-d="dismiss">
  <app-custom-modal (oncrossBtnClick)="closeModal()">
      <h4 header> Edit Notes </h4>
      <span body>
          <div class="eprx--block__content notes--block">
              <div class="row">
                  <div class="col">
                      <h6 class="notes--block--head">Rx#: {{rxInfo.Prescription.PrescNum}}</h6>
                      <h6 class="notes--block--head">Refill#: {{rxInfo.PrescReFill.ReFillNum}}</h6>
                      <h6 class="notes--block--head"><span *ngIf="_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)">
                        Fill#: {{rxInfo.PrescReFill.PartialFillNo}}</span></h6>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <textarea ngbAutofocus class="label--text__area col-md-12" [value]="notes" (keyup)="changeNotes($event?.target?.value)"></textarea>
                  </div>
              </div>
          </div>
      </span>
      <button footer class="hotkey_success" (click)="saveNotes()" appShortcutKey [AltKey]="'o'"><span>O</span> OK</button>
      <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
  </app-custom-modal>
</ng-template>
