export class InsuranceEligibility {
    Gender: string = null;
    SerDateElig: string = null;
    DateElig: string = null;
    InsuranceCode: string = null;
    PolicyIdsList: string = null;
    Result: string = null;
    NPI: string = null;
    InsOrderLst2: string = null;
    F4PartDPlandata: string = null;
    F526FQAddInsInfo: string = null;
    MsgPartDE: string = null;
    MessageF4: string = null;
    MessageFQ: string = null;
    lstF4PartDPlanInfoClaimTransmitE: string = null;
    F4PartDPlanInfo: string = null;
    oInsuOtherInfo: string = null;
    lstInsuOtherInfo: string = null;
    lstF26PartDPlanInfoClaimTransmit: string = null;
    F26PartDPlanInfoClaimTransmit: string = null;
    Allergydrugname: string = null;
    DrgSctnDrugId: string = null;
    ErrorMessage: string = null;
    NCPDPRecv: string = null;
}

export class EligibilityTransmit {
        patientId: number = null;
        insuranceId: number = null;
        dateOfService: Date = null;
        insurerId: number = null;
        policyNum: string = null; 
        IsFrmManualCheck:boolean = false;
        IsTroop:boolean = false
}