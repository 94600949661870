export class RxDashboardStats {
    CancelRequestCount: number = null;
    Delivery: number = null;
    DrugPickVerificationCount: number = null;
    FiledRxCount: number = null;
    unbilledRxCountByDateRange: number = null;
    unbilleRxCountBySetting: number = null;
    NewRxCount: number = null;
    NotYetPicked: number = null;
    documentQueue: number = null;
    notYetTranmsitted: number = null;
    PickedUpRx: number = null;
    RefillRespCount: number = null;
    RefillsQueueCount: number = null;
    RejectedCodeCount1: number = null;
    RejectedCodeCount2: number = null;
    IncomingFaxCount: number = null;
    RejectedCodeCount3: number = null;
    RphVerificationCount: number = null;
    RxChangeRespCount: number = null;
    RxUnbilled: number = null;
    Shipping: number = null;
    RxsNotYetPicked: number = null;
    LabelQueue: number = null;
    modifiedData: any = null;
    RxVerificationCount: number = null;
}

export class RxDateInputParams {
    StartDate: string = null;
    EndDate: string = null;
}

export class RxDashboardErxStats {
    NewRxCount: number = null;
    RefillRespCount: number = null;
    RxChangeRespCount: number = null;
    CancelRequestCount: number = null;
    RejectedCount: number = null;
}
