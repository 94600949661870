export class rowData {
    ["Diagnosis Description"]: string = null;
    diagnosisId: string = null;
    isActive: boolean = null;
    patientId: number = null;
}

export class DiagnosisList {
    TempDiagnosis: diagnosis[] = [];
    TotalPages: number = null;
    TotalRecords: number = null;
}

export class diagnosis {
    DIAGCODE: string = null;
    DIAGDESCR: string = null;
    RECID: string = null;
}

export class searchCriteria {
    Code: string = null;
    Description: string = null;
    pageNum: number = null;
    pageSize: number = null;
}

export class PatDiagnosis {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    DiagnosisId: String = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    DiagDesc: String = null;
}
