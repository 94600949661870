<ng-template #HOAPopUp let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header" *ngIf="!IsFromPkgPreview">
        <h4 class="modal-title">HOA (Hours of Administration) </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close">
            <span aria-hidden="true" class="close-button" (click)="closeHoaPopUp();resetFG()">&times;</span>
        </button>
    </div>
    
    <div [ngClass]="isFromPkg ? 'modal-body row' : 'modal-body'" *ngIf="!IsFromPkgPreview">
        <div *ngIf="isFromPkg" class="col-2">
            <div class="dispill-box batch-edit batch-edit_max_height">
                <label class="bold-text m-2">Selected Rx List</label>
                <table>
                    <tr>
                        <th>Rx#</th>
                        <th>Ref#</th>
                    </tr>
                    <tr *ngFor="let item of TotalRxInfo; let i=index" (click)="dispBatchEdit(item,i)"
                        [class.highlighted]="((item.Prescription.PrescNum === currentPrescNum && item.PrescReFill.ReFillNum === currentRefillNum) || (isFirstRec && i===0))">
                        <td>{{item.Prescription.PrescNum}}</td>
                        <td>{{item.PrescReFill.ReFillNum}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div [ngClass]="isFromPkg ? 'eprx--block__content col-10' : 'eprx--block__content'" >
            <div class="row">
                <div class="col-6 ">
                    <div class="dispill-box p-3 height_140px">
                        <div class="row">
                            <div class="col-6 row">
                                <label class="col-5"> Rx# </label>
                                <div class="label--data col-7">
                                    <span>{{ rxInfo?.Prescription?.PrescNum }}</span><span>-{{
                                        rxInfo?.PrescReFill?.ReFillNum
                                        }}</span><span>{{_commonServ.checkIsPartialFill(rxInfo?.PrescReFill,
                                        rxInfo?.Prescription) ? '-' + rxInfo?.PrescReFill?.PartialFillNo : ""}}</span>
                                </div>
                            </div>
                            <div class="col-6 row">
                                <label [ngClass]="isFromPkg ? 'col-6' : 'col-5'"> Patient Name </label>
                                <div [ngClass]="isFromPkg ? 'label--data col-6 text--ellipsis' : 'label--data col-7 text--ellipsis'"
                                    title="{{ rxInfo?.Patient?.lastname | uppercase }}, {{ rxInfo?.Patient?.firstname | uppercase  }}">
                                    {{ rxInfo?.Patient?.lastname }},&nbsp;
                                    {{ rxInfo?.Patient?.firstname }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="row col-6">
                                <label class="col-5"> Drug Name</label>
                                <div class="label--data col-8" *ngIf="!hasRxData">
                                    {{ drugInfo?.drugname + " "+(drugInfo?.strength) + " "+ (drugInfo?.drugformname) }}
                                </div>
                                <div class="label--data col-7 text--ellipsis"
                                    title='{{ (rxInfo?.Drug?.drugname | uppercase )  + " "+(rxInfo?.Drug?.strength | uppercase ) + " "+ (rxInfo?.Drug?.drugformname | uppercase )}}'
                                    *ngIf="hasRxData">
                                    {{ (rxInfo?.Drug?.drugname) + " "+(rxInfo?.Drug?.strength) + " "+
                                    (rxInfo?.Drug?.drugformname)}}
                                </div>
                            </div>
                            <div class="row col-6">
                                <label class="col-5"> Drug NDC# </label>
                                <div class="label--data col-8" *ngIf="!hasRxData">
                                    {{this._formatsUtil.getNDCFormat(drugInfo?.ndc)}}
                                </div>
                                <div class="label--data col-7" *ngIf="hasRxData">
                                    {{this._formatsUtil.getNDCFormat(rxInfo?.Drug?.ndc)}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="row col-6">
                                <label [ngClass]="isFromPkg ? 'col-6' : 'col-5'">Qty Dispensed</label>
                                <div [ngClass]="isFromPkg ? 'label--data col-6' : 'label--data col-7'">
                                    {{ rxInfo?.PrescReFill ?.DispQty}}
                                </div>
                            </div>
                            <div class="row col-6">
                                <label class="col-5 pr-0">Days Supply </label>
                                <div class="label--data col-7">
                                    {{ oldSupDaysOthr ? oldSupDaysOthr : rxInfo?.PrescReFill ?.SupplyDays}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="row col-6">
                            <label class="col-5 pr-0"> Sig Description </label>
                            <div class="label--data col-7 dispill-sig-height">
                                {{ !hasRxData ? rxFG?.value?.SigCodes?.Description :  rxInfo?.SigCodes?.Description}}
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="dispill-box p-3 height_140px">
                        <div class="row" [formGroup]="InsuFiltersFrmGrp">
                            <div class="col-12">
                                <div class="row">
                                    <div [ngClass]="isFromPkg ? 'dispill-date-picker-width' : 'col-4'">
                                        <eprx-date-picker [AutoFocus]="true" [LabelText]="'Start Date'"
                                            [ControlName]="'StartDate'" [FormGroupName]="InsuFiltersFrmGrp" [IsDisabled]=""
                                            [TabIndex]="1" [RxSelectId]="'startDate'"
                                            (TriggerOnDateSelect)="onDateSelect($event,'startdate')"></eprx-date-picker>
                                    </div>
                                    <div [ngClass]="isFromPkg ? 'dispill-date-picker-width' : 'col-4 pl-0'">
                                        <eprx-date-picker [LabelText]="'End Date'" [ControlName]="'EndDate'"
                                            [FormGroupName]="InsuFiltersFrmGrp" [RxSelectId]="'endDate'" [TabIndex]="2"
                                            [IsDisabled]=""
                                            (TriggerOnDateSelect)="onDateSelect($event,'enddate')"></eprx-date-picker>
                                    </div>
                                    <div [ngClass]="isFromPkg ? 'col-3' : 'col-2 pl-0'">
                                        <eprx-select [LabelText]="'Dose Per Day'" [PlaceHolder]="''"
                                            [ControlName]="'DosePerDay'" [FormGroupName]="InsuFiltersFrmGrp"
                                            [FormGroupInvalid]="formSubmitted" [HasMultiple]="false" [BindLabel]="'Name'"
                                            [BindValue]="'Name'" [LabelForId]="'Name'"
                                            (TriggerSelectValue)="optedDosePerDay($event?.value)" [List]="dosagPerDay"
                                            [IsDisabled]="FormatId.value === 1? true:false"
                                            [TabIndex]="FormatId.value !== 1 ? '3' : '-1'"
                                            [InputErrors]="DosePerDay?.errors" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required', pattern: 'Invalid!'}">
                                        </eprx-select>
                                    </div>
                                    <div [ngClass]="isFromPkg ? 'col-3' : 'col-2 pl-0'">
                                        <eprx-select [LabelText]="'Repeat Days'" [PlaceHolder]="''"
                                            [ControlName]="'RepeatDays'" [FormGroupName]="InsuFiltersFrmGrp"
                                            [FormGroupInvalid]="formSubmitted" [HasMultiple]="false" [BindLabel]="'Name'"
                                            [BindValue]="'Name'" [TabIndex]="(FormatId.value !== 3 || !isPrn)? '4' : '-1'"
                                            [LabelForId]="'Name'" (TriggerSelectValue)="optedRepeatDays($event?.value)"
                                            [List]="reptDays" [IsDisabled]="(FormatId.value === 3 || isPrn)? true:false"
                                            [InputErrors]="DosePerDay?.errors" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required', pattern: 'Invalid!'}">
                                        </eprx-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row margin_bottom_n2_3rem">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-2 mt-1">
                                        <span class="format">Format</span>
                                    </div>
                                    <div class="col-10">
                                        <eprx-radio [LabelText]="''" [ControlName]="'FormatId'"
                                            [FormGroupName]="InsuFiltersFrmGrp" [List]="doseBase" [IsRequired]="true"
                                            [ValueList]="[1,2,3]" [IDForList]="['hoa1', 'hoa2', 'hoa3']" [LabelForId]=""
                                            (TriggerSelectValue)="getDoseBase($event?.target?.id)">
                                        </eprx-radio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dispill-table" *ngIf="formatType === 'specific'">
                <div class="tableFixHead" [formGroup]="InsuFiltersFrmGrp">
                    <table>
                        <thead class="text-center">
                            <tr class="specificTym">
                                <th *ngIf="doseCols.length>0" class="col-1">Dose Date</th>
                                <th *ngFor="let header of doseCols">
                                    {{ header.name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody formArrayName="HOAAJSONModel">
                            <tr *ngFor="let DoseSchedule of HOAAJSONModel.controls; let i=index">
                                <td class="col-1">
                                    <div class="bold-text">{{ getDate(DoseSchedule) | date : 'EEEE' }}</div>
                                    <div>{{ getDate(DoseSchedule) | date:'shortDate'}}</div>
                                </td>
                                <td *ngFor="let Dose of getDoseDetails(DoseSchedule); let j=index" class="col-1">
                                    <div style="display: flex; width: 200px;">
                                        <eprx-select [LabelText]="''" [PlaceHolder]="''" [ControlName]="'doseTime'"
                                            [FormGroupName]="Dose" [HasMultiple]="false" [BindLabel]="'doseSlotTime'"
                                            [BindValue]="'Id'" [LabelForId]="'doseSlotTime'" [List]="dosageSlot"
                                            [DropdownPosition]="(i>=0 && i<=4) ? 'bottom' : 'top'"
                                            (TriggerSelectValue)="setTimeToAllRows(Dose, 'doseTime',i,j)">
                                        </eprx-select>
                                        <eprx-input [LabelText]="''" [PlaceHolder]="'--'" [BeforeDecimal]="8"
                                            [ControlName]="'doseQty'" [FormGroupName]="Dose" [IsDisabled]="false"
                                            [DropSpclChar]="false" [DecimalValues]="3" [InputType]="'NUMBER'"
                                            [MaskPattern]="'0999999.000'"
                                            (TriggerOnTabbing)="keytab(Dose, 'doseQty',i, j)" (focusout)="keytab(Dose, 'doseQty',i, j)">
                                        </eprx-input>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="dispill-table" *ngIf="formatType === 'daily'">
                <div [formGroup]="InsuFiltersFrmGrp" class="tableFixHead">
                    <table>
                        <thead class="text-center">
                            <tr class="specificTym">
                                <th *ngIf="doseCols.length>0">Day</th>
                                <th *ngFor="let header of doseCols">
                                    {{ header.name }}
                                </th>
                            </tr>
                            <tr class="day-fraction-header">
                                <th *ngIf="doseCols.length>0"></th>
                                <th *ngFor="let header of doseCols" class="bg-grey">
                                    {{header.fraction}}
                                </th>
                            </tr>
                        </thead>
                        <tbody formArrayName="HOAAJSONModel">
                            <tr *ngFor="let DoseSchedule of HOAAJSONModel.controls; let i=index">
                                <td class="col-1">
                                    <div class="bold-text">{{ getDate(DoseSchedule) | date : 'EEEE' }}</div>
                                    <div>{{ getDate(DoseSchedule) | date:'shortDate'}}</div>
                                </td>
                                <td *ngFor="let Dose of getDoseDetails(DoseSchedule); let j=index" class="col-1">
                                    <div style="display: flex; width: 200px;">
                                        <div class="pr-1 pl-1">
                                            <eprx-select [LabelText]="''" [PlaceHolder]="''" [ControlName]="'doseTime'"
                                                [FormGroupName]="Dose" [HasMultiple]="false" [BindLabel]="'doseSlotTime'"
                                                [BindValue]="'Id'" [DropdownPosition]="(j>=0 && j<=4) ? 'bottom' : 'top'"
                                                [LabelForId]="'doseSlotTime'"
                                                [List]="j===0 ? mrngDosageSlot : (j===1 ? noonDosageSlot : (j===2 ? evngDosageSlot : nightDosageSlot))"
                                                (TriggerSelectValue)="setTimeToAllRows(Dose, 'doseTime',i, j)">
                                            </eprx-select>
                                        </div>
                                        <div class="pr-1 pl-2">
                                            <eprx-input [LabelText]="''" [PlaceHolder]="'--'" [ControlName]="'doseQty'"
                                                [FormGroupName]="Dose" [IsDisabled]="false" [DropSpclChar]="false"
                                                [DecimalValues]="3" [InputType]="'NUMBER'" [MaskPattern]="'0999999.000'"
                                                (TriggerOnTabbing)="keytab(Dose, 'doseQty',i, j)" (focusout)="keytab(Dose, 'doseQty',i, j)">
                                            </eprx-input>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="dispill-table" *ngIf="formatType === 'custom'">
                <div [formGroup]="InsuFiltersFrmGrp" class="tableFixHead">
                    <table>
                        <thead class="text-center">
                            <tr class="specificTym">
                                <th *ngIf="doseCols.length>0" class="col-1">Day</th>
                                <th *ngIf="doseCols.length>0">Actions</th>
                                <th *ngFor="let header of doseCols">
                                    {{ header.name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody formArrayName="HOAAJSONModel">
                            <tr *ngFor="let DoseSchedule of HOAAJSONModel.controls; let i=index" class="height_3rem">
                                <td>
                                    <div style="display: flex; width: 200px;">
                                        <eprx-date-picker [ControlName]="'date'" [FormGroupName]="DoseSchedule"
                                            [RxSelectId]="'date'" [TabIndex]="21" [IsDisabled]=""
                                            (TriggerOnDateSelect)="onCustDateSelect(DoseSchedule,$event)"></eprx-date-picker>
                                    </div>
                                </td>
                                <td width="1%" (click)="deleteDoseSched(i)">
                                    <i class="far fa-trash-alt actions danger-actve" style="
                                        color: red !important; text-align: center;" title="delete"></i>
                                </td>
                                <td *ngFor="let Dose of getDoseDetails(DoseSchedule); let j=index">
                                    <div style="display: flex; width: 200px;">
                                        <eprx-select [LabelText]="''" [PlaceHolder]="''" [ControlName]="'doseTime'"
                                            [FormGroupName]="Dose" [HasMultiple]="false" [BindLabel]="'doseSlotTime'"
                                            [BindValue]="'Id'" [LabelForId]="'doseSlotTime'" [List]="dosageSlot">
                                        </eprx-select>
                                        <eprx-input [LabelText]="''" [PlaceHolder]="'--'" [ControlName]="'doseQty'"
                                            [FormGroupName]="Dose" [IsDisabled]="false" [DropSpclChar]="false"
                                            [DecimalValues]="3" [InputType]="'NUMBER'" [MaskPattern]="'0999999.000'"
                                            (TriggerOnTabbing)="keytab(Dose, 'doseQty',i, j)" (focusout)="keytab(Dose, 'doseQty',i, j)">
                                        </eprx-input>
                                    </div>
                                </td>
                            </tr>
                            <button type="button" class="hotkey_success mt-1" tabindex="200" (click)="addCustomSched()"
                                appShortcutKey [AltKey]="'a'">
                                <span>A</span> Add row
                            </button>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="!IsFromPkgPreview">
        <div class="text-right float-right">
            <button type="button" class="hotkey_success" tabindex="200" (click)="closeHoaPopUp();closeRxSchdModal()" appShortcutKey
                [AltKey]="'s'">
                <span>S</span> Save
            </button>
            <button type="button" tabindex="201" class="hotkey_success" (click)="closeHoaPopUp();resetFG()" appShortcutKey [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
        </div>
    </div>
    
 
</ng-template>
   
<div class="eprx--block__header" *ngIf="IsFromPkgPreview">
    <div class="eprx--header__heading"><span>
            HOA (Hours of Administration)</span>
    </div>
</div>
<div class="eprx--block__content" *ngIf="IsFromPkgPreview">
    <div class="row">
        <div class="col-12 ">
            <div class="dispill-box p-3">
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12 row">
                                <label class="col-5"> Rx# </label>
                                <div class="label--data col-7" *ngIf="!rxInfo.Prescription?.PrescNum">
                                    {{'--'}}
                                </div>
                                <div class="label--data col-7" *ngIf="rxInfo.Prescription?.PrescNum">
                                    <span>{{ rxInfo.Prescription?.PrescNum
                                        }}</span><span>-{{rxInfo?.PrescReFill?.ReFillNum }}</span><span>{{
                                        (_commonServ.checkIsPartialFill(rxInfo?.PrescReFill) ? '-' +
                                        rxInfo?.PrescReFill?.PartialFillNo : "")}}</span>
                                </div>
                            </div>
                            <div class="col-12 row">
                                <label class=" col-5"> Patient Name </label>
                                <div class="label--data col-7" *ngIf="!rxInfo.Patient?.patientid">
                                    {{'--'}}
                                </div>
                                <div class="label--data col-7" *ngIf="rxInfo.Patient?.patientid"
                                    title="{{ rxInfo?.Patient?.lastname | uppercase }}, {{ rxInfo?.Patient?.firstname | uppercase  }}">
                                    {{ (rxInfo?.Patient?.lastname) ? (rxInfo?.Patient?.lastname) : "--" }},&nbsp;
                                    {{ (rxInfo?.Patient?.firstname) ? (rxInfo?.Patient?.firstname) : "--" }}
                                </div>
                            </div>
                            <div class="row col-12">
                                <label class="col-5"> Drug Name </label>
                                <div class="label--data col-7"
                                    title='{{ (rxInfo?.Drug?.drugname | uppercase )  + " "+(rxInfo?.Drug?.strength | uppercase ) + " "+ (rxInfo?.Drug?.drugformname | uppercase )}}'>
                                    {{ (rxInfo?.Drug?.drugname) ? ((rxInfo?.Drug?.drugname) + "
                                    "+(rxInfo?.Drug?.strength) + " "+ (rxInfo?.Drug?.drugformname)) : "--"}}
                                </div>
                            </div>
                            <div class="row col-12">
                                <label class="col-5"> Drug NDC# </label>
                                <div class="label--data col-7" title="{{this._formatsUtil.getNDCFormat(xInfo?.Drug ?.ndc)}}">
                                    {{this._formatsUtil.getNDCFormat(rxInfo?.Drug ?.ndc)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="col-12 row">
                            <label class=" col-5"> Start Date </label>
                            <div class="label--data col-7">
                                {{(hoaList && hoaList.length > 0) ? (InsuFiltersFrmGrp.value['StartDate'] | dateFormat)
                                : "--"}}
                            </div>
                        </div>
                        <div class="col-12 row">
                            <label class=" col-5"> End Date </label>
                            <div class="label--data col-7">
                                {{(hoaList && hoaList.length > 0) ? (InsuFiltersFrmGrp.value['EndDate'] | dateFormat) :
                                "--"}}
                            </div>
                        </div>
                        <div class="col-12 row">
                            <label class=" col-5 pr-0"> Repeat Days </label>
                            <div class="label--data col-7">
                                {{(hoaList && hoaList.length > 0) ? (InsuFiltersFrmGrp.value['RepeatDays']) : "--"}}
                            </div>
                        </div>
                        <div class="col-12 row">
                            <label class=" col-5 pr-0"> Dose Per Day </label>
                            <div class="label--data col-7">
                                {{(hoaList && hoaList.length > 0) ? (InsuFiltersFrmGrp.value['DosePerDay']) : "--"}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 row">
                        <label class=" col-5 pr-0"> Sig Description </label>
                        <div class="col-7 dispill-sig-height">
                            {{ (rxInfo?.SigCodes?.Description) ? (rxInfo?.SigCodes?.Description) : "--" }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="dispill-table" *ngIf="formatType === 'specific' && rxFG && hoaList.length>0">
        <div class="file_generator_prev" [formGroup]="InsuFiltersFrmGrp">
            <table>
                <thead class="text-center">
                    <tr class="specificTym">
                        <th *ngIf="doseCols.length>0" class="col-1">Dose Date</th>
                        <th *ngFor="let header of doseCols">
                            {{ header.name }}
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="HOAAJSONModel">
                    <tr *ngFor="let DoseSchedule of HOAAJSONModel.controls; let i=index">
                        <td width="80px">
                            <div class="bold-text">
                                {{ getDate(DoseSchedule) | date : 'EEEE' }}
                            </div>
                            <div>{{ getDate(DoseSchedule) | date:'shortDate'}}</div>
                        </td>
                        <td *ngFor="let Dose of getDoseDetailsValue(DoseSchedule)">
                            <div class="d-flex">
                                <div class=" pr-1 pl-1">
                                    {{Dose?.doseTime | convertTimeFormat}}
                                </div>
                                <div class="pr-1 pl-1">
                                    {{Dose?.doseQty}}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="dispill-table" *ngIf="formatType === 'daily' && rxFG && hoaList.length>0">
        <div [formGroup]="InsuFiltersFrmGrp" class="file_generator_prev">
            <table>
                <thead class="text-center">
                    <tr class="specificTym">
                        <th *ngIf="doseCols.length>0">Day</th>
                        <th *ngFor="let header of doseCols">
                            {{ header.name }}
                        </th>
                    </tr>
                    <tr class="day-fraction-header">
                        <th *ngIf="doseCols.length>0"></th>
                        <th *ngFor="let header of doseCols" class="bg-grey">
                            {{header.fraction}}
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="HOAAJSONModel">
                    <tr *ngFor="let DoseSchedule of HOAAJSONModel.controls; let i=index">
                        <td class="col-1">
                            <div class="bold-text">{{ getDate(DoseSchedule) | date : 'EEEE' }}</div>
                            <div>{{ getDate(DoseSchedule) | date:'shortDate'}}</div>
                        </td>
                        <td *ngFor="let Dose of getDoseDetailsValue(DoseSchedule); let i=index">
                            <div class="d-flex">
                                <div class=" pr-1 pl-1">
                                    {{Dose?.doseTime | convertTimeFormat}}
                                </div>
                                <div class=" pr-1 pl-2">
                                    {{Dose?.doseQty}}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="dispill-table" *ngIf="formatType === 'custom' && rxFG && hoaList.length>0">
        <div [formGroup]="InsuFiltersFrmGrp" class="file_generator_prev">
            <table>
                <thead class="text-center">
                    <tr class="specificTym">
                        <th *ngIf="doseCols.length>0" class="col-1">Day</th>
                        <th *ngFor="let header of doseCols">
                            {{ header.name }}
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="HOAAJSONModel">
                    <tr *ngFor="let DoseSchedule of HOAAJSONModel.controls; let i=index" class="height_3rem">
                        <td class="col-1">
                            {{DoseSchedule.value['date'] | dateFormat }}
                        </td>
                        <td *ngFor="let Dose of getDoseDetailsValue(DoseSchedule)" class="col-1">
                            <div class="d-flex">
                                <div class="pr-1 pl-1">
                                    {{Dose?.doseTime | convertTimeFormat}}
                                </div>
                                <div class=" pr-1 pl-1">
                                    {{Dose?.doseQty}}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
