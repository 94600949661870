import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";
import { DocSubCategory } from "../models/dms-category.model";

@Injectable({providedIn: "root"})
export class DocSubCategoryService {
    constructor(private _http: HttpClient) {}

    getDMSSubCategoryData(searchData: any): Observable<DocSubCategory[]> {
        return this._http.post<DocSubCategory[]>(
            constant.POST_DocumentSubCategory,searchData

        );
    }

    deletecategory(docIds: string, canBeDeleted: boolean): Observable<boolean> {
        return this._http.delete<boolean>(
            constant.DELETE_DMSSubCategory.replace(
                "{docSubId}",
                "" + docIds
            ).replace("{canBeDeleted}", "" + canBeDeleted)
        );
    }

    addupdateDocsubcategory(doccategory: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_UPDATE_DocumentSubCategory,
            doccategory
        );
    }
}
