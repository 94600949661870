import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AccCategoryE, ReportsE, Reports1E, DocumentE } from "../models";
import { AlertService, UserService } from "../services";
import { BasePrivGetter } from "./access-privs.guard";

@Injectable()
export class ReportDailyLogGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.DailyLog, true);
        return reslt;
    }
}

@Injectable()
export class ReportFiledRxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.FiledRxs, true);
        return reslt;
    }
}

@Injectable()
export class ReportControlRxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.ControlRxReport, true);
        return reslt;
    }
}

@Injectable()
export class ReportRefillExpirationGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.RefillExpirationReport, true);
        return reslt;
    }
}

@Injectable()
export class ReportBusinessSummaryGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.BusinessReports, true);
        return reslt;
    }
}

@Injectable()
export class ReportRxByPrescriberGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.RxsByPrescriber, true);
        return reslt;
    }
}

@Injectable()
export class ReportDrugDispensedGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.DrugDispensingReport, true);
        return reslt;
    }
}

@Injectable()
export class ReportUnbilledRxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.UnbilledRxsReport, true);
        return reslt;
    }
}

@Injectable()
export class ReportRefillDueGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.RefillsDue, true);
        return reslt;
    }
}

@Injectable()
export class ReportPatientMedicationAdherenceextendsGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports1, Reports1E.PatientMedicationAdherence, true);
        return reslt;
    }
}
@Injectable()
export class ReportAuditTrailGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.AuditTrails, true);
        return reslt;
    }
}

@Injectable()
export class ReportControlGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }
    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.ControlRxReport, true);
        return reslt;
    }
}

@Injectable()
export class ReportPharmacistGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.RxsByPharmacist, true);
        return reslt;
    }
}

@Injectable()
export class ControlRxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.ControlFile, true);
        return reslt;
    }
}

@Injectable()
export class DrugDispenEquGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.DrugDispensingEquivalencyReport, true);
        return reslt;
    }
}

@Injectable()
export class InventoryReportGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.InventoryStatusReport, true);
        return reslt;
    }
}

@Injectable()
export class RxNotPickedReportGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports, ReportsE.RxsNotPickedUpReport, true);
        return reslt;
    }
}

@Injectable()
export class TransferredRxReportGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports1, Reports1E.TransferredRxReport, true);
        return reslt;
    }
}

@Injectable()
export class RxByInsuranceGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports1, Reports1E.RxByInsurance, true);
        return reslt;
    }
}

@Injectable()
export class SalesTaxSummaryGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports1, Reports1E.SalesTaxSummary, true);
        return reslt;
    }
}

@Injectable()
export class PriceChangeGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports1, Reports1E.PriceReport, true);
        return reslt;
    }
}

@Injectable()
export class ReportPartialFillGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Reports1, Reports1E.PartialFill, true);
        return reslt;
    }
}
@Injectable()
export class ReportWriterGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Document, DocumentE.ReportWriter, true);
        return reslt;
    }
}
