import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from "rxjs/operators";
import { CommonService, AlertService } from "../../../services";
import { City } from "../../../models";
import { FormGroup, Validators } from "@angular/forms";
import { ValidateFormFieldsUtils } from "../../../utils";

@Component({
    selector: "app-search-city",
    templateUrl: "./search-city.component.html"
})
export class SearchCityComponent implements OnInit, OnChanges, AfterViewInit {
    stateId: number;
    Cities: any;
    citySelected: any;
    cityInfo: any;
    custContrlName: string;
    inputFormGroup: FormGroup;
    stateContrlName: any;
    isRequired = false;
    errorMessage: string;
    errorDefs: object;
    inputErrors: any;
    haserror: boolean;
    tabIndex: number;
    unsubscribe$: Subject<void> = new Subject();
    cityTouched = false;

    @Input() autoFocus: boolean;
    @Input() rxSelectId: any;
    @Input() zipControl: any;

    @Input()
    set IsRequired(ir: boolean) {
        if (ir) {
            this.isRequired = ir;
        }
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set StateControlName(sn: any) {
        this.stateContrlName = sn;
    }

    @Input()
    set CitySelected(cs: City) {
        this.citySelected = cs;
    }

    @Input()
    set StateID(stId: number) {
        this.stateId = stId;
    }
    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }
    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }
    @Input()
    set FormGroupInvalid(ir: boolean) {
        if (ir) {
            this.cityTouched = true;
            this.haserror = ir;
            if (this.haserror) {
                this.validateAllFormFields(
                    this.inputFormGroup,
                    this.custContrlName
                );
            }
        }
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.cityTouched = true;
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Output()
    OnCitySelected = new EventEmitter<any>();
    @Output()
    TriggerOnClear = new EventEmitter<any>();

    constructor(
        private _commonServ: CommonService,
        private _validFormFieldsUtils: ValidateFormFieldsUtils,
        private _alertService: AlertService
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        if (this.autoFocus && this.rxSelectId && document.getElementById(this.rxSelectId)) {
            document.getElementById(this.rxSelectId).focus();
        }
    }

    onKeydownValue(event?: any): void {
        if (!event.target.value && !(event.ctrlKey || event.altKey)) {
            this.inputFormGroup.controls[this.custContrlName].patchValue(null);
        }
    }

    searchCity = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(term => this.searchByCity(term))
        );

    searchByCity(value: string) {
        if (value && !(value === " ")) {
            this.inputFormGroup.controls[this.custContrlName].setValue(null);
            if (typeof this.stateContrlName === "object") {
                if (this.stateId) {
                    this.Cities = this._commonServ.getCitiesData(
                        value,
                        this.stateId
                    );
                } else {
                    this.Cities = this._commonServ.getEmptyObservable();
                    this.inputFormGroup.controls[this.custContrlName].setValue(
                        null
                    );
                }
            } else {
                this.Cities = this._commonServ.getCitiesData(
                    value,
                    this.inputFormGroup.controls[this.stateContrlName].value
                );
            }
        } else {
            this.Cities = this._commonServ.getEmptyObservable();
            this.inputFormGroup.controls[this.custContrlName].setValue(null);
        }
        this.Cities.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (!resp || resp.length === 0) {
                this.citySelected = null;
                this.inputFormGroup.controls[this.custContrlName].setValue(
                    null
                );
                this.validateAllFormFields(
                    this.inputFormGroup,
                    this.custContrlName
                );
            }
        });
        return this.Cities;
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async optedCityValue(val) {
        if (typeof this.stateContrlName === "object") {
            this.inputFormGroup.controls[this.custContrlName].patchValue(val.item.Name);
            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
        } else {
            this.inputFormGroup.controls[this.custContrlName].patchValue(val.item.Id);
            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
        }
        this.OnCitySelected.emit(val.item);
    }

    async reverseZipCheck(val) {
        const stateId = typeof this.stateContrlName === "object" ? this.stateId : this.inputFormGroup.controls[this.stateContrlName].value;
        if (stateId && val.item.Id) {
            const resp = await this._commonServ.getZipByStateACity(stateId, val.item.Id).toPromise();
            if (typeof this.zipControl === "object") {
                if (resp && this.zipControl.value["Value"] !== resp["ZipCode"]) {
                    this.zipControl.controls["Value"].setValue(resp["ZipCode"]);
                }
            } else {
                if (this.zipControl && this.inputFormGroup.controls[this.zipControl] && resp &&
                    this.inputFormGroup.controls[this.zipControl].value !== resp["ZipCode"]) {
                    this.inputFormGroup.controls[this.zipControl].patchValue(resp["ZipCode"]);
                }
            }
        }
    }

    formatter = (x: any) => {
        return x.Name;
    };

    validateAllFormFields(formGroup?: FormGroup, formCntrl?: string) {
        if (this.isRequired) {
            this.errorMessage = this._validFormFieldsUtils.validateFormFields(formGroup, formCntrl, this.errorDefs);
        }
        if (formGroup && formCntrl && !formGroup.value[formCntrl]) {
            this.TriggerOnClear.emit(null);
        }
    }

    ngOnChanges(changes?: any) {
        if (
            this.inputFormGroup &&
            this.custContrlName &&
            this.inputFormGroup.controls[this.custContrlName] && this.isRequired
        ) {
            this.inputFormGroup.controls[this.custContrlName].setValidators([
                Validators.required
            ]);
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
        if (
            this.inputFormGroup &&
            this.StateControlName &&
            this.inputFormGroup.controls[this.StateControlName] && this.isRequired
        ) {
            this.inputFormGroup.controls[this.StateControlName].setValidators([
                Validators.required
            ]);
            this.validateAllFormFields(
                this.inputFormGroup,
                this.StateControlName
            );
        }
        if (changes) {
            if (changes.autoFocus && changes.autoFocus.currentValue) {
                if (this.autoFocus && this.rxSelectId && document.getElementById(this.rxSelectId)) {
                    document.getElementById(this.rxSelectId).focus();
                }
            }
        }
    }

    checkIfNull() {
        if (!this.inputFormGroup.value[this.custContrlName]) {
            this.citySelected = null;
        }
    }
}
