import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PatPreDrugModalService {
    patPreDrugModal$: Observable<any>;
    _patPreDrugModal$: BehaviorSubject<any>;
    modalInstanceRef: any;

    constructor() {
        this._patPreDrugModal$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.patPreDrugModal$ = this._patPreDrugModal$.asObservable();
    }

    setModalRef(insRef) {
        // if (this.modalInstanceRef) {
        //     this.modalInstanceRef.close();
        //     this.modalInstanceRef = null;
        // }
        this.modalInstanceRef = insRef;
    }



    closeModal() {
        if (this.modalInstanceRef) {
            this.modalInstanceRef.close();
            this.modalInstanceRef = null;
        }
        this._patPreDrugModal$.next(null);
    }
}
