import { Vendordruginfo } from "./../models/vendor-items";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";

@Injectable()
export class VendorItemService {
    constructor(private _http: HttpClient) {}

    getDrugInformation(drugId) {
        return this._http.get(
            constant.GET_Drug_Vendors.replace("{drugId}", "" + drugId)
        );
    }
}
