<ng-template #SendEfill let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="openCancelPopUP()">
        <h4 header>Send e-Refill Request</h4>
        <span body>
            <div class="eprx--block__content">
                <div class="row ">
                    <div class="col-md-6">
                        <div class="exprx--common-block erx--body insurance--body">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Patient - {{ erxDetails?.Patient?.lastname + ", " + erxDetails?.Patient?.firstname
                                                    | uppercase }}</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'Patient ID'" [IsDisabled]="true" [PlaceHolder]="'Patient ID'" [HasControl]="false" [InputValue]="erxDetails?.Patient?.patientid ? erxDetails?.Patient?.patientid:''">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'DOB'" [PlaceHolder]="'DOB'" [HasControl]="false" [InputValue]="erxDetails?.Patient?.dob ?
                                                    (erxDetails?.Patient?.dob | date:'MM/dd/yyyy'):''"
                                                [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'Gender'" [PlaceHolder]="'Gender'" [HasControl]="false" [InputValue]="erxDetails?.Patient?.gender ? erxDetails?.Patient?.gender:'--'"
                                                [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'Address'" [PlaceHolder]="'Address'" [HasControl]="false" [InputValue]="erxDetails?.Patient?.address1 ?
                                                        erxDetails?.Patient?.address1:''" [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'State'" [PlaceHolder]="'State'" [HasControl]="false" [InputValue]="erxDetails?.Patient?.state ?
                                                        erxDetails?.Patient?.state:''" [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'City'" [PlaceHolder]="'City'" [HasControl]="false" [InputValue]="erxDetails?.Patient?.city ?
                                                        erxDetails?.Patient?.city:''" [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'Phone#'" [PlaceHolder]="'Phone#'" [HasControl]="false" [InputValue]="(erxDetails?.Patient?.telephone && erxDetails?.Patient?.telephone !== '###') ?
                                                        (erxDetails?.Patient?.telephone | mask:'(000)000-000000000'):''" [IsDisabled]="true" [ShowMask]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'Zip'" [PlaceHolder]="'Zip'" [HasControl]="false" [InputValue]="erxDetails?.Patient?.zipcode ?
                                                        (erxDetails?.Patient?.zipcode):''" [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                            <div class="exprx--common-block erx--body insurance--body">
                                <div class="eprx--block__header">
                                    <div class="eprx--header__heading">
                                        <div class="col-sm-12 padding-0">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <span>Drug - {{ (erxDetails?.Drug?.drugname + " "  + (erxDetails?.Drug?.strength ? erxDetails?.Drug?.strength : "") +
                                                         " " + erxDetails?.Drug?.drugformname)
                                                        | uppercase }}</span>
                                                </div>
                                                <div class="col-md-2 text-right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <eprx-input [LabelText]="'NDC'" [PlaceHolder]="'NDC'" [HasControl]="false" [IsDisabled]="true"
                                                [InputValue]="erxDetails?.Drug?.ndc ? (erxDetails?.Drug?.ndc | mask:'AAAA0-0000-00') :''">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <eprx-input [LabelText]="'Def.Sig CD'" [PlaceHolder]="'Def.Sig CD'" [HasControl]="false" [InputValue]="erxDetails?.Drug?.drugusesigcode ? erxDetails?.Drug?.drugusesigcode:''"
                                                    [IsDisabled]="true">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <eprx-input [LabelText]="'Potency Unit'" [PlaceHolder]="'Potency Unit'" [HasControl]="false" [InputValue]="erxDetails?.PotencyCode ? erxDetails?.PotencyCode:''"
                                                    [IsDisabled]="true">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group" [formGroup]="erxReq">
                                                <eprx-text-area [RxSelectId]="'erxReNotes'" [PlaceHolder]="'Enter text'" [TabIndex]="1" [Rows]="3" [MaxLength]="140" [LabelText]="'Pharmacist Notes'" [FormGroupName]="erxReq" [ControlName]="'PharmNotes'" [AutoFocus]="true"> </eprx-text-area>
                                                <!-- <textarea  autofocus tabindex="1" formControlName="PharmNotes" style="height: 74%; width: 100%; resize: none" [rows]="5" [cols]="15" [maxLength]="210" (blur)="trimSpaces()" ></textarea> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
                <div class="row ">

                    <div class="col-md-6 prescSearch" *ngIf="SearchPres && !erxDetails.Prescriber">
                                <app-searchbox-global [SearchFor]="'Prescriber'" class="common_global_search_max_send_refill_req" (SelectedPrescriber)="selectedPresInfo($event)" [DisableSerchFil]="true"
                                    [DisableRadioButtons]="true" [IsFocused]="isInputFocused"></app-searchbox-global>
                    </div>
                    <div class="col-md-6" *ngIf="!SearchPres && erxDetails.Prescriber">
                                <div class="exprx--common-block erx--body insurance--body">
                                    <div class="eprx--block__header">
                                        <div class="eprx--header__heading">
                                            <div class="col-sm-12 padding-0">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <span> Prescriber - {{ (erxDetails?.Prescriber?.prescriberlastname + ", " + erxDetails?.Prescriber?.prescriberfirstname)
                                                            | uppercase }}
                                                        </span>
                                                    </div>
                                                    <div class="col-md-2 text-right">
                                                        <a (click)="resetPresc()">
                                                            <i class="far fa-redo-alt fa-lg" title="Reset Patient"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="eprx--block__content">
                                        <div class="col-sm-12 padding-0">
                                            <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div class="row">
                                                            <div class="col-11 pr-0">
                                                                    <eprx-select class="RxSig" #RxSig id="'RxSig'" [AddItem]="true" [RxSelectId]="'RxSig'" [TabIndex]="2" [LabelText]="'SPI'" [HasControl]="false" [InputValue]=" resetPrescriber ? ((SPIDetails?.length && SPIDetails[0]?.SPINo) ? SPIDetails[0]?.SPINo : '') : (PrescAddress ?
                                                                    PrescAddress?.SPINo:'')" [HasMultiple]="false" [HasBindLabel2]="false"
                                                                [BindValueML]="SPIDetails" [HasBindLabelM]="true"    [BindLabel]="'SPINo'" [BindValue]="'SPINo'" [LabelForId]="'SPINo'" [List]="SPIDetails ? SPIDetails: ''"
                                                                  (blur)="resetTabIndex()" [CheckSpiServiceLevel]="checkServiceLevel(resetPrescriber ? ((SPIDetails?.length && SPIDetails[0]) ? SPIDetails[0] : '') : (PrescAddress ?
                                                                  PrescAddress:''))" [Title]="checkServiceLevel(resetPrescriber ? ((SPIDetails?.length && SPIDetails[0]) ? SPIDetails[0] : '') : (PrescAddress ?
                                                                  PrescAddress:'')) === true ? 'Service Level found' : 'Service Level Not found'" (keydown)="setTabIndex($event)"  (TriggerSelectValue)="changeSPIADD($event); setTabIndex($event)">
                                                                </eprx-select>
                                                            </div>
                                                            <div class="col-1 padding_25px_1px_14px_6px">
                                                                    <a (click)="refreshSpi()" [ngStyle]="{color: checkServiceLevel(resetPrescriber ? ((SPIDetails?.length && SPIDetails[0]) ? SPIDetails[0] : '') : (PrescAddress ?
                                                                    PrescAddress:'')) === true ? '#0fa20f' : '#f6251a'}">
                                                                            <i class="far fa-redo-alt fa-md font-weight-bold" title="Refresh SPI"></i>
                                                                    </a>
                                                            </div>
                                                        </div>

                                                        </div>
                                                <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" *ngIf="!multipleSpi">
                                                    <eprx-input [LabelText]="'SPI'" [TabIndex]="2" [PlaceHolder]="'SPI'" [HasControl]="false" [InputValue]="erxDetails?.Prescriber?.prescriberspinum ?
                                                        erxDetails?.Prescriber?.prescriberspinum:''" (TriggerChangeValue)="changeSpiNumber($event)">
                                                    </eprx-input>
                                                </div> -->
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <eprx-input [LabelText]="'DEA#'" [PlaceHolder]="'DEA#'" [HasControl]="false" [InputValue]="erxDetails?.Prescriber?.prescriberdeanum ?
                                                        erxDetails?.Prescriber?.prescriberdeanum:''" [IsDisabled]="true">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <eprx-input [LabelText]="'NPI#'" [PlaceHolder]="'NPI#'" [HasControl]="false" [InputValue]="erxDetails?.Prescriber?.prescribernpinum ?
                                                        erxDetails?.Prescriber?.prescribernpinum:''" [IsDisabled]="true">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <eprx-input [LabelText]="'Address'" [PlaceHolder]="'Address'" [HasControl]="false" [InputValue]=" resetPrescriber ? (erxDetails?.Prescriber?.prescriberaddress1 ? erxDetails?.Prescriber?.prescriberaddress1 : '') : (PrescAddress?.AddressLine1 ?
                                                        PrescAddress?.AddressLine1: PrescAddress2?.AddressLine1 ? PrescAddress2?.AddressLine1 : '')" [IsDisabled]="true">
                                                        </eprx-input>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <eprx-input [LabelText]="'State'" [PlaceHolder]="'State'" [HasControl]="false" [InputValue]=" resetPrescriber ? (erxDetails?.Prescriber?.prescriberstate ? erxDetails?.Prescriber?.prescriberstate : '') : (PrescAddress?.StateName ?
                                                            PrescAddress?.StateName: PrescAddress2?.StateName ? PrescAddress2?.StateName : '')" [IsDisabled]="true">
                                                        </eprx-input>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <eprx-input [LabelText]="'City'" [PlaceHolder]="'City'" [HasControl]="false" [InputValue]=" resetPrescriber ? (erxDetails?.Prescriber?.prescribercity ? erxDetails?.Prescriber?.prescribercity : '') : (PrescAddress?.CityName ?
                                                            PrescAddress?.CityName: PrescAddress2?.CityName ? PrescAddress2?.CityName : '')" [IsDisabled]="true">
                                                        </eprx-input>
                                                    </div>
                                                <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" *ngIf="!multipleSpi">
                                                    <eprx-input [LabelText]="'Address'" [PlaceHolder]="'Address'" [HasControl]="false" [InputValue]="erxDetails?.Prescriber?.prescriberaddress1 ?
                                                        erxDetails?.Prescriber?.prescriberaddress1:''" [IsDisabled]="true">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" *ngIf="!multipleSpi">
                                                    <eprx-input [LabelText]="'State'" [PlaceHolder]="'State'" [HasControl]="false" [InputValue]="erxDetails?.Prescriber?.prescriberstate ?
                                                        erxDetails?.Prescriber?.prescriberstate:''" [IsDisabled]="true">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" *ngIf="!multipleSpi">
                                                    <eprx-input [LabelText]="'City'" [PlaceHolder]="'City'" [HasControl]="false" [InputValue]="erxDetails?.Prescriber?.prescribercity ?
                                                        erxDetails?.Prescriber?.prescribercity:''" [IsDisabled]="true">
                                                    </eprx-input>
                                                </div> -->
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <eprx-input [LabelText]="'Phone#'" [PlaceHolder]="'Phone#'" [HasControl]="false"
                                                        [InputValue]="phone ? (phone | mask:'(000)000-000000000') : ''" [IsDisabled]="true"  [ShowMask]="true">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <eprx-input [LabelText]="'Zip'" [PlaceHolder]="'Zip'" [HasControl]="false" [InputValue]="resetPrescriber ? (erxDetails?.Prescriber?.prescriberzipcode ? erxDetails?.Prescriber?.prescriberzipcode : '') : (PrescAddress?.ZipCode ?
                                                            (PrescAddress?.ZipCode): PrescAddress2?.ZipCode ? PrescAddress2?.ZipCode : '')"
                                                            [IsDisabled]="true">
                                                        </eprx-input>
                                                    </div>
                                                <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" *ngIf="!multipleSpi">
                                                    <eprx-input [LabelText]="'Zip'" [PlaceHolder]="'Zip'" [HasControl]="false" [InputValue]="erxDetails?.Prescriber?.prescriberzipcode ?
                                                        (erxDetails?.Prescriber?.prescriberzipcode):''"
                                                        [IsDisabled]="true">
                                                    </eprx-input>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                    <div class="col-md-6">
                        <div class="exprx--common-block erx--body insurance--body">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Rx # {{erxDetails?.Prescription?.PrescNum ? erxDetails?.Prescription?.PrescNum
                                                    : "--"}}</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            <eprx-input  [IsDisabled]="true" [LabelText]="'Ref#'" [PlaceHolder]="'Ref#'" [HasControl]="false" [InputValue]="erxDetails?.PrescReFill?.ReFillNum ? erxDetails?.PrescReFill?.ReFillNum:'0'">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <!-- <eprx-input [TabIndex]="3" [LabelText]="'Auth Refill'" [PlaceHolder]="'Auth Refill'"
                                                (TriggerChangeValue)="changeAuth($event)" [ControlName]="'AuthRef'" (TriggerOnFocusValue)="resetTabIndex()"
                                                [FormGroupName]="erxReq" [MaskPattern]="'0*'">
                                                </eprx-input> -->

                                                <eprx-select
                                                    [RxSelectId]="'rxReFillNum'"
                                                    [LabelText]="'Auth Refills'"
                                                    [PlaceHolder]="''"
                                                    [ControlName]="'AuthRef'"
                                                    [FormGroupName]="erxReq"
                                                    [List]="authRefills"
                                                    [HasMultiple]="false"
                                                    [BindLabel]="'Name'"
                                                    [BindValue]="'Id'"
                                                    [LabelForId]="'Name'"
                                                    [TabIndex]="3"
                                                    [IsSearchable]="false"
                                                >
                                                </eprx-select>
                                            <!-- <eprx-input [TabIndex]="4" [LabelText]="'Auth Refill'" [PlaceHolder]="'Auth Refill'" [HasControl]="false" [InputValue]="erxDetails?.Prescription?.ReFillNum ? erxDetails?.Prescription?.ReFillNum:'0'"
                                            (TriggerChangeValue)="changeAuth($event)" [ControlName]="'Qty'"
                                            [FormGroupName]="erxReq">
                                            </eprx-input> -->
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            <eprx-input
                                            [LabelText]="'Qty'"
                                            [DecimalValues]="3"
                                            [ControlName]="'Qty'"
                                            [FormGroupName]="erxReq"
                                            (TriggerSearchValue)="setNumberFormat('Qty',3)"
                                            [InputType]="'NUMBER'"
                                            [PlaceHolder]="'Qty'"
                                            [IsRequired]="true"
                                            [MaxLength]="11"
                                            [MarkAsTouched]="erxReq?.controls?.Qty?.touched" [ErrorDefs]="{required: 'Required'}"
                                            >
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <eprx-input [LabelText]="'Days'" [PlaceHolder]="'Days'"
                                                [ControlName]="'days'"
                                                [FormGroupName]="erxReq"
                                                [InputType]="'NUMBER'"
                                                [IsRequired]="true"
                                                [MaxLength]="3"
                                                [DecimalValues]="0"
                                                [MarkAsTouched]="erxReq?.controls?.days?.touched" [ErrorDefs]="{required: 'Required'}"
                                                (TriggerSearchValue)="setNumberFormat('days',0)"
                                                >
                                                </eprx-input>
                                            <!-- <eprx-input [LabelText]="'Days'" [PlaceHolder]="'Days'"
                                            [ControlName]="'Qty'"
                                            [FormGroupName]="erxReq" [HasControl]="false" [InputValue]="erxDetails?.Prescription?.Days ? erxDetails?.Prescription?.Days:'0'"
                                            [TabIndex]="6">
                                            </eprx-input> -->
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <app-rx-select 
                                            (TriggerOnShiftTabbing)="focusOnFaxReq()"
                                            [LabelText]="'DAW'" 
                                            [PlaceHolder]="''"
                                            [ControlName]="'DAW'"
                                            [FormGroupName]="erxReq"
                                            [HasBindLabel2]="true"
                                            [HasMultiple]="false"
                                            [List]="systemData?.DAW"
                                            [BindLabel]="'Code'" 
                                            [BindLabel2]="'Name'"
                                            [BindValue]="'Id'"
                                            [bindScnCntrlV]="'Code'"
                                            [LabelForId]="'Name'"
                                            >
                                            </app-rx-select>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'Date Ord.'" [PlaceHolder]="'Date Ord.'" [HasControl]="false" [InputValue]="erxDetails?.PrescReFill?.OrderDtTm ? (erxDetails?.PrescReFill?.OrderDtTm | localTime
                                                                        | date:'MM/dd/yyyy'):''" [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [LabelText]="'Date Fill'" [PlaceHolder]="'Date Fill'" [HasControl]="false" [InputValue]="erxDetails?.PrescReFill?.FillDtTm ? (erxDetails?.PrescReFill?.FillDtTm | localTime | date :'MM/dd/yyyy'):''"
                                                [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <eprx-input [LabelText]="'Sig'" [PlaceHolder]="'Sig'" [HasControl]="false" [InputValue]="erxDetails?.SigCodes?.Description ? erxDetails?.SigCodes?.Description:''"
                                                [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button id="faxReq" *ngIf="showFaxRef && hasFaxNumber" class="hotkey_success" footer (click)="openFaxPopUp()" appShortcutKey [AltKey]="'f'"><span>F</span>Fax Ref Request</button>
        <button *ngIf="showFaxRef && !hasFaxNumber" class="inactive" footer><span>F</span>Fax Ref Request</button>
        <button class="hotkey_success" footer (click)="sendErxRequest(canDoReq)" appShortcutKey [AltKey]="'s'"><span>S</span> Send</button>
        <button class="hotkey_success" footer (click)="openCancelPopUP()" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
    </app-custom-modal>
</ng-template>

<ng-template #CancelPop let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Error Generating e-Refill Request</h4>
        <span body>Error Generating e-Refill Request <br> Cancelled by user.
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>

<ng-template #canDoReq let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click');showCanDoReq=false">
        <h4 header>SPI Does Not Support e-Refill Request</h4>
        <span body>
            <p>
                The Selected SPI for this Prescriber may not have valid service level to process 'e-Refill Request' and may get
                rejected, you can try with another SPI with refill service level if available.
            </p>
            <p>Are you sure you want to send it anyway?</p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary"
            (click)="d('Cross click');sendErxReqAfterCheck();showCanDoReq=false" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer type="button" class="hotkey_primary" (click)="d('Cross click');showCanDoReq=false" appShortcutKey InputKey="n">
              <b>N</b>  NO
        </button>
    </app-custom-modal>
</ng-template>

<app-print-fax-request
*ngIf="faxPopUp"
[FaxInfo]="faxInfo"
[RxInfo]="selectedRxs"
(IsPopUpClosed)="resetActiveModal($event)">
</app-print-fax-request>

<!-- <ng-template #CancelPop let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Error Generating ERX Request</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Error Generating ERX Request <br> Cancelled by user.
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="closeModal()">
            Ok
        </button>
    </div>
</ng-template> -->

<!-- <ng-template #canDoReq let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">SPI Does Not Support E-Request</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                The Selected SPI for this Prescriber may not have valid service  <br> level to process 'E-Request' and may get rejected, you can try
                <br> with another SPI with refill service level if available.
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="hotkey_primary" (click)="d('Cross click');sendErxReqAfterCheck()">
                Ok
            </button>
        </div>
    </ng-template> -->
