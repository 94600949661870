<ng-template #Cancel let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">ATTENTION</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="CancelEvent(false)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to close this window?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" appShortcutKey InputKey="y" (click)="CancelEvent(true)">
                    <b>Y</b> YES
                </button>
                <button class="hotkey_primary" appShortcutKey InputKey="n" (click)="CancelEvent(false)"><b>N</b> NO</button>
            </div>
        </div>
    </div>
</ng-template>
