<app-custom-modal (oncrossBtnClick)="Cancel()">
    <h4 header>Warning!!</h4>
    <span body>
         The selection will close the current window. Do you want to proceed?
    </span>
    <button footer autofocus class="hotkey_primary" appShortcutKey (click)="Confirm()"
        InputKey="y"><b>Y</b> YES
    </button>
    <button footer class="hotkey_primary" appShortcutKey InputKey="S" (click)="Confirm()" *ngIf="isFromDrug">
        <b>S</b> Save
    </button>
    <button footer class="hotkey_primary" appShortcutKey InputKey="n" (click)="Cancel()"><b>N</b> NO
    </button>
</app-custom-modal>
