<!-- <div class="exprx--common-block erx--body insurance--body">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-md-10">
                        <span>Toolbar</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="col-12 text-right">
                <button  class="hotkey_primary" (click)="cropFromCropMode()">
                    Ok
                </button>
                <button class="hotkey_primary" (click)="cancelFromCropMode()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <angular-cropper class="col-12" #angularCropper [cropperOptions]="config" [imageUrl]="inputImage"></angular-cropper>
</div> -->
<div class="row" *ngIf="cropMode">
    <div class="col-12 text-right">
        <button  class="hotkey_success--Override hotkey_primary--Override" (click)="cropFromCropMode()">
            Crop
        </button>
        <button class="hotkey_success--Override hotkey_primary--Override" (click)="cancelFromCropMode()">
            Cancel
        </button>
    </div>
</div>

<div class="row">
    <angular-cropper class="col-12" #angularCropper [cropperOptions]="config" [imageUrl]="inputImage"></angular-cropper>
</div>
