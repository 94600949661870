export class EquivalentDrug {
    DrugName: string = null;
    NDC: number = null;
    MFG: string = null;
    Size: number = null;
    AWP: number = null;
    IsActive: boolean = null;
    DisDate: Date = null;
    DrugID: number = null;
    TotalAmount: number = null;
    ispreferred: string = null;
    Strength: any = null;
    Drgform: string = null;
    LastDispensed: Date = null;
    BrandName: string = null;
    GenericName: string = null;
    QtyInHand: number = null;
    DirectPrice: number = null;
    DrugType: string = null;
}
