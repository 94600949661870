import { Injectable } from "@angular/core";
import * as moment from "moment";
import { CommonService } from "../services";
import { DateCheckUtil } from "./date-checks.util";

@Injectable({
    providedIn: "root"
})
export class HoverOver {
    constructor(private _commonServ: CommonService , private _dateCheck: DateCheckUtil) {}
getDrugNameHoverOver(item) {
    const controlRx = item.DrugClass >= 2 && item.DrugClass <= 5 ? true : false;
    // tslint:disable-next-line:max-line-length
    const drugName = item && item["Drug Name"] ? (item["Drug Name"].toUpperCase() + ": ") : item["Drug"] ? (item["Drug"].toUpperCase() + ": ") : "";
    let status = "";
    if (controlRx) {
        // tslint:disable-next-line:max-line-length
        status =  drugName + "Control" + ((item.Status === "D" || item.statusForRx === "D") ? " Deferred Rx" : (item.IsPicked ? " Rx Picked-up" : " Rx Not yet Picked-up"));
    } else {
        // tslint:disable-next-line:max-line-length
        status = drugName  + "Non-Control" + ((item.Status === "D" || item.statusForRx === "D") ? " Deferred Rx" : (item.IsPicked ? " Rx Picked-up" : " Rx Not yet Picked-up"));
    }
    return status;
}

getStatusHoverOver(item) {
    let status = "";
    const RxStatus = item.Status + "-";
    if (item.Status === "U") {
        status = "Unbilled Rx";
    } else if (item.Status === "B") {
        status = "Billed Rx";
    } else if (item.Status === "T") {
        status = "Transferred Out Rx";
    } else if (item.Status === "UI") {
        status = "Unbilled Transferred In Rx";
    } else if (item.Status === "BI") {
        status = "Billed Transferred In Rx";
    } else if (item.Status === "D") {
        status = "Deferred Rx";
    }
    return  RxStatus + status ;
}
getRxHoverOver(item, type) {
    const controRx = item["DrugClass"] >= 2 && item["DrugClass"] <= 5 ? true : false;
    const RxNo = item["Rx#"];
    const aref = item["Auth#"];
    const ref = item["Ref#"];
    const fillNo = (item["Fill#"] && item["Fill#"] !== " ") ? item["Fill#"] : 1;
    let remQty = +item["Qty Rem P.fill"];
    remQty = (remQty) ? remQty : 0;
    let totRemQty = +item["T.Rx Qty Rem"];
    totRemQty = totRemQty ? totRemQty : 0;
    const daysRemaingForDue = item["DueDays"];
    let rxExpired = false;
    let rxExpiredByInsuranceOrAccountSettings = false;
    let MaxRefillsAllowed: any;
    let OrderToFillDays: any;
    const cntrlRefllOptns = this._commonServ.controlFileSettings$["source"]["value"];
    const isRxExpireDateAfterDateCheck = item["RxExpDate"]  ? this._dateCheck.checkExpiryDate(item["RxExpDate"])  :  false;
    const rxExpiryDateByInsuranceAfterDateCheck = item["rxExpiryDateByInsuranceOrControlSettings"]  ? this._dateCheck.checkExpiryDate(item["rxExpiryDateByInsuranceOrControlSettings"])  :  false;
    const RxExpirationDateCheck = item["RxExpirationDate"]  ? this._dateCheck.checkExpiryDate(item["RxExpirationDate"])  :  false;
    const rxThresholdCheck = item["rxThreshold"]  ? this._dateCheck.checkExpiryDate(item["rxThreshold"])  :  false;
    if (item["DrugClass"] === 0 || item["DrugClass"] === 1) {
       MaxRefillsAllowed = item.primaryInsurance && item.primaryInsurance.MaxRefills >= 0 ? item.primaryInsurance.MaxRefills : "";
       OrderToFillDays =  item.primaryInsurance && item.primaryInsurance.OrderToFillDays >= 0 ? item.primaryInsurance.OrderToFillDays : null;
       if (item["MaxRefillsAllowed"]) {
        MaxRefillsAllowed = item["MaxRefillsAllowed"];
       } if (item["OrderToFillDays"]) {
        OrderToFillDays = item["OrderToFillDays"];
       }
    } else {
        if (cntrlRefllOptns && cntrlRefllOptns.ControlRefillOptions && cntrlRefllOptns.ControlRefillOptions.length > 0) {
           const val =  cntrlRefllOptns.ControlRefillOptions.find(ele => +(ele.DrugClass) === item["DrugClass"]);
           if (val) {
                   MaxRefillsAllowed = val.MaxRefillAllow >= 0  ? val.MaxRefillAllow : 99;
                    OrderToFillDays  = val.OrderFillDays >= 0  ? val.OrderFillDays : 180;
           }
                }
        }
    if (item["RxExpDate"] && isRxExpireDateAfterDateCheck) {
        rxExpired = true;
        if (item["RxExpDateByUser"]) {
            let d1 = new Date(moment(item["RxExpDate"]).format("MM/DD/YYYY"));
            let d2 = new Date(moment(item["RxExpDateByUser"]).format("MM/DD/YYYY"));
            if (item["rxExpiryDateByInsuranceOrControlSettings"] && rxExpiryDateByInsuranceAfterDateCheck) {
                let d3 =  new Date(moment(item["rxExpiryDateByInsuranceOrControlSettings"]).format("MM/DD/YYYY"));
                if (((d1.getTime() === d2.getTime()) && (d2.getTime() === d3.getTime())) || (d3 < d2 && !(d1 < d3))) {
                    rxExpiredByInsuranceOrAccountSettings =  (aref >= 0) ? true : false;
                } else {
                    rxExpiredByInsuranceOrAccountSettings =  false;
                }
            } else {
                rxExpiredByInsuranceOrAccountSettings =  false;
            }
        } else {
            rxExpiredByInsuranceOrAccountSettings = true;
        }
    }
    const latestRefCompleted = item.PrescCount;
    const drugThresholdValue = this._commonServ.getSetttingValue("RxSettings", "REFILL_DUE_CONSUMPTION_THERSHOLD");
    const InsRefillDueConsumptionThreshold = item["InsRefillDueConsumptionThreshold"] ? item["InsRefillDueConsumptionThreshold"] : 0;
    let supplydaysChecitem = 0;
    if (InsRefillDueConsumptionThreshold) {
        supplydaysChecitem = Math.round(+item["Supply Days"] - ((+item["Supply Days"] * (+InsRefillDueConsumptionThreshold ? +InsRefillDueConsumptionThreshold : 0)) / 100));
    } else {
        supplydaysChecitem = Math.round(+item["Supply Days"] - ((+item["Supply Days"] * (+drugThresholdValue ? +drugThresholdValue : 0)) / 100));
    }
    if (
        item.Status !== "D" &&(
        (item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true)) &&
       ( !rxExpired && !((item.RxExpirationDue && item.RxExpirationDue > 0)))
    ) {
        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? " Rx is Discontinued from Cancel Rx" : " Rx is Discontinued");
    } 
    else if (item.Status === "D" && (!rxExpired && !(item.RxExpirationDue && item.RxExpirationDue > 0)) && !(item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true)) {
        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Deferred";
    } else if (item.Status === "D" && (item["Ref#"] === 0 && fillNo === 1 && (item.RxExpirationDue && item.RxExpirationDue > 0)) && !(item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true)) {
        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Deferred Rx expired due to Order to Fill Days";
    } else if(item.Status === "D" && (rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0))) {
        if (rxExpiredByInsuranceOrAccountSettings) {
            return RxNo + ": " + (controRx ? "Control Deferred Rx expired due to Max Days for Refill" : "Non-Control Deferred Rx expired due to Order To Last Refill");
        } else {
            return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Deferred Rx expired due to Expiration date defined on Edit Rx";
        }
    } else if(item.Status === "D" && ( (item.IsDiscountinued === "YES" || item["Order Status"] === "D" || item["IsDiscontinued"] === true))) {
        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? " Deferred Rx is Discontinued from Cancel Rx" : " Deferred Rx is Discontinued");
    }else if(item.Status === "T" && +(this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT"))) {
        return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
    }
    else if((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0))  && ( (item.IsDiscountinued === "YES" ||
    item["Order Status"] === "D" || item["IsDiscontinued"] === true))) {
        if (rxExpiredByInsuranceOrAccountSettings) {
            return RxNo + ": " + (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx) ? (controRx ? "Control Rx is Discontinued from Cancel Rx and Expired due to Max Days for Refill" : "Non-Control Rx is Discontinued from Cancel Rx and Expired due to Order To Last Refill") : (controRx ? "Control Rx is Discontinued and Expired due to Max Days for Refill" : "Non-Control Rx is Discontinued and Expired due to Order To Last Refill"));
        } else {
            return RxNo + ": " + (controRx ? "Control" : "Non-Control") + (item.IsDisctFromErx || (item.Prescription && item.Prescription.IsDisctFromErx)) ? " Rx is Discontinued from Cancel Rx and Expired due to Expiration date defined on Edit Rx" : " Rx is Discontinued and Expired due to Expiration date defined on Edit Rx";
        }
    }
    if ( item.Status !== "D" && ((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0)) && daysRemaingForDue > 0 && !totRemQty )) {
        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx";
    } else  if ( item.Status !== "D" && ((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0)) && daysRemaingForDue <= 0 && !totRemQty )) {
        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
    }  else if (((rxExpired || (item.RxExpirationDue && item.RxExpirationDue > 0)))
    ) {
         if (rxExpiredByInsuranceOrAccountSettings ) {
            return RxNo + ": " + (controRx ? "Control Rx expired due to Max Days for Refill" : "Non-Control Rx expired due to Order To Last Refill");
        } else  {
            return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Expiration date defined on Edit Rx";
        }
    } else if (daysRemaingForDue < supplydaysChecitem) {
        if (type === "Rx") {
            if (aref !== ref && aref >= ref) {
                if (
                    (item.RxExpirationDue && item.RxExpirationDue <= 0) ||
                    (!item.RxExpirationDue && daysRemaingForDue < 365)
                ) {
                    if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
                    } else if (!totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                        return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    } else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                        if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                            if ( rxThresholdCheck) {
                                return RxNo + ": Refill Due";
                            } else {
                                return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                            }
                        } else {
                            return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                        }
                    } else {
                        if (rxThresholdCheck) {
                            return RxNo + ": Refill Due";
                        } else {
                            return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                        }
                    }
                } else {
                    return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                }
            } else if (aref !== 0 && daysRemaingForDue <= 0 && !totRemQty) {
                return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
            } else if (totRemQty !== 0) {
                if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                    return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
                }  else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                    if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                        if (totRemQty !== 0 &&  rxThresholdCheck) {
                            return RxNo + ": Refill Due";
                        } else {
                            return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                        }
                    } else {
                        return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    }
                } else {
                    if (totRemQty !== 0 &&  rxThresholdCheck) {
                        return RxNo + ": Refill Due";
                    } else {
                        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                    }
                }
            } else {
                if (daysRemaingForDue <= 0 && !totRemQty ) {
                return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
            } else {
                return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
            }
            }
        } else {
            if (aref !== ref && aref >= ref) {
                if (
                    (item.RxExpirationDue && item.RxExpirationDue <= 0) ||
                    (!item.RxExpirationDue && daysRemaingForDue < 365)
                ) {
                    if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                    return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
                }  else if (!totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                    return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                } else {
                    if ( rxThresholdCheck) {
                        return RxNo + ": Refill Due";
                    } else {
                        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                    }
                }
                } else {
                    return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                }
            }  else if ((item["Ref#"] >= MaxRefillsAllowed)  && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
            }  else if (daysRemaingForDue <= 0 && !totRemQty) {
                return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
            } else if (!totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
            }  else if ( rxThresholdCheck) {
                return RxNo + ": Refill Due";
            } else {
                if (daysRemaingForDue <= 0 && !totRemQty) {
                    return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
                } else {
                    return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                }
            }
        }
    } else if (daysRemaingForDue >= supplydaysChecitem) {
        if (aref !== ref && aref >= ref) {
            if (type === "Rx") {
                if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                    return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
                } else if (!totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                    return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                } else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                     if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                        if (rxThresholdCheck) {
                            return RxNo + ": Refill Due";
                        } else {
                            return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                        }
                    } else {
                        return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                    }
                } else {
                    if ( rxThresholdCheck) {
                        return RxNo + ": Refill Due";
                    } else {
                        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                    }
                }
            } else {
                if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                    return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
                } else  if ( rxThresholdCheck) {
                    return RxNo + ": Refill Due";
                } else {
                    return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                }
            }
        } else if (totRemQty !== 0) {
            if ((item["Ref#"] >= MaxRefillsAllowed) && !totRemQty && !(item["RxExpirationDate"] && RxExpirationDateCheck)) {
                return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Maximum refills allowed";
            } else if (latestRefCompleted && latestRefCompleted["DocCount"] > 0) {
                if (latestRefCompleted["DocCount"] - ref === 1 && latestRefCompleted["LatestfillCount"] - fillNo === 0) {
                    if (totRemQty !== 0 &&  rxThresholdCheck) {
                        return RxNo + ": Refill Due";
                    } else {
                        return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                    }
                } else {
                    return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
                }
            } else {
                if (totRemQty !== 0 &&  rxThresholdCheck) {
                    return RxNo + ": Refill Due";
                } else {
                    return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx is Early to Refill";
                }
            }
        } else {
            if (daysRemaingForDue <= 0 && !totRemQty) {
                return RxNo + ": " + (controRx ? "Control" : "Non-Control") + " Rx expired due to Quantity consumed";
            } else {
                return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
            }
        }
    } else {
        return RxNo + ": " + (controRx ? "Control Rx" : "Non-Control Rx");
    }
}
}
