import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { DocumentInputParams, SharedDocuments } from 'src/app/models';
import { DocumentsService } from 'src/app/services/documents.service';
import { CollectionView } from  '@grapecity/wijmo';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-document-display',
  templateUrl: './document-display.component.html',
  styleUrls: ['./document-display.component.scss']
})
export class DocumentDisplayComponent implements OnInit {
  currentDocIndex: any = 0;
  unsubscribe$: Subject<void> = new Subject();
  pdfToShow: any;
  imageToShow: any;
  fileTypeToShow: string = null;
  getDocParams = new DocumentInputParams();
  documentsListWJ: CollectionView;
  hasDocuments: boolean = false;
  documentAvailable: boolean;
    documentId: any;
    rxFormGroup: FormGroup;
    @Input() totalDocs: any;
    @Input() showErxDocs: boolean;
    @Input() IsFromQuickVerification: boolean;

    @Input() rxFG: FormGroup;
    totalDocsforDisplay: any;
  constructor(private _docService: DocumentsService,  private sanitizer: DomSanitizer) {
    this.getDocParams.DocCatId = SharedDocuments.Prescription;
   }

  ngOnInit() {
      if (this.totalDocs && this.totalDocs.length && !this.showErxDocs) {
        this.totalDocs.push({Base64String: null,
            Id: 22222,
            eRxdoc: true});
            this.totalDocsforDisplay = [...this.totalDocs];
        this.previewSelectedFile(this.totalDocs[0]);
      } else {
        this.totalDocs.unshift({Base64String: null,
            Id: 22222,
            eRxdoc: true});
        this.totalDocsforDisplay = [...this.totalDocs];
      }
  }
  navigateToNextImage(next?) {
    let index = 0;
    if (next) {
        if (this.currentDocIndex === (this.totalDocsforDisplay.length - 1)) {
            index = 0;
        } else {
            index = this.currentDocIndex + 1;
        }
    } else {
        if (this.currentDocIndex === 0) {
            index = this.totalDocsforDisplay.length - 1;
        } else {
            index = (this.currentDocIndex) - 1;
        }
      }
    this.previewSelectedFile(this.totalDocsforDisplay[index]);
}
async previewSelectedFile(val: any) {
    this.currentDocIndex = this.totalDocsforDisplay.findIndex(val2 => (val2["Id"] === val.Id));
        this.pdfToShow = null;
        this.imageToShow = null;
        this.documentId = val.Id;
        if (val.Id !== 22222) {
            this._docService
            .getDocumentByName(val)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (resp.type === "application/pdf") {
                        this.createPdfFromBlob(resp);
                    } else {
                        this.createImageFromBlob(resp);
                    }
                }
            });
        } else {
            this.fileTypeToShow = null;
            this.showErxDocs = this.showErxDocs ? true : false;
        }
  }
  createPdfFromBlob(image: Blob) {
    this.fileTypeToShow = "pdf";
    const urlCreator = window.URL;
    this.pdfToShow = this.sanitizer.bypassSecurityTrustResourceUrl(
        urlCreator.createObjectURL(image) + "#toolbar=0"
    );
    this.documentAvailable = true;
}

createImageFromBlob(image: Blob) {
    this.fileTypeToShow = "image";
    const urlCreator = window.URL;
    this.imageToShow = this.sanitizer.bypassSecurityTrustResourceUrl(
        urlCreator.createObjectURL(image)
    );
    this.documentAvailable = true;
}
ngOnDestroy() {
    this.unsubscribe$.next();
     this.unsubscribe$.complete();
   }


}
