<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Category'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0 commonbut">
                <button autofocus class="hotkey_success" id="saveButton" (click)="EditDrugCat()"><span>S</span> Save</button>
                <button class="hotkey_success mr-0"  (keydown.tab)="focusOutFmCan($event)" (click)="CancelPopTaggle()"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">

            </div>
        </div>
        <div class="eprx--block__content">
            <dual-list id="dual" (destinationChange)="updateCandeActivate()"
            [(destination)]="destination" [source]="source" [height]="'300px'"></dual-list>
        </div>
    </div>
</div>

<app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)"></app-cancel-pop-up>
