import { DrugFileE } from './../../../models/access-privs.enum';
import { EditPrescriberService } from "./../../../services/edit-prescriber.service";

import { AlertService, PatientService, DrugService, CommonService } from "src/app/services";
import { PrivMaskGuard } from "src/app/guards";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import * as _ from "lodash";
import { AccCategoryE, PrescriberFileE, KeyCodes, PaginationModel } from "../../../models";
import { MsgConfig } from "../../..";
import { ColorCode, WijimoUtil } from 'src/app/utils';
import { DrugComponent } from '../../drug/drug.component';
import { DrugBucketUtil } from 'src/app/utils/drug-bucket.util';
import { DecimalPipe } from '@angular/common';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
    selector: "app-drug-search",
    templateUrl: "./drug-search.component.html"
})
export class DrugSearchComponent implements OnInit {
    searchDrugList: CollectionView;
    actvHeaders: string[];
    totalCount: number;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    DrugSearchFG: FormGroup;
    hideFilterForm = false;
    currentItem: any;
    accessPrivCate: any;
    categoryId: string;
    privType: any;
    openOverride: boolean;
    savedValue: any;
    overrideFor: any;
    deletePop: any;
    modelRef: any;
    isDataExists = true;

    @ViewChild("SearchDrug")
    flex: wjcGrid.FlexGrid;
    startIndex: number;
    maxDate: { year: any; month: any; day: any; };
    drugPrivs: typeof DrugFileE;
    overrideEndpoint: any;
    showDelete: boolean;
    hasAddDrugFilePrivs: boolean;
    hasChangDrugFilePrivs: boolean;
    hasDeltDrugFilePrivs: boolean;
    isDrugSelectable: boolean = false;
    wjHeaders: any;
    Is340BPharm$: any;
    bucketId: number;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    @Input()
    set IsDrugSelectable(inp: boolean) {
        this.isDrugSelectable = inp;
    }
    @Input() IsFromDocumentQueue: boolean;

    @Output()
    SelectedDrug = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
       if (event.which === 27 && this.modelRef) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _editDrug: DrugService,
        private _router: Router,
        private modalService: NgbModal,
        private _alertServ: AlertService,
        private _privMaskGuard: PrivMaskGuard,
        private _colorCode: ColorCode,
        private _commonService: CommonService,
        private _drugBuckUtil: DrugBucketUtil,
        private _wijimoUtils: WijimoUtil,
        private _decimalPipe: DecimalPipe,
        private _formatsUtil:FormatsUtil
    ) {
        this.DrugSearchFG = this._fb.group({
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize,
            drugname: null,
            ndc: null,
            quickcode: null,
            OnlyDrugsHavingQtyInStock: false,
            Is340B: false,
            BucketId: null
        });
        this.accessPrivCate = AccCategoryE;
        this.drugPrivs = DrugFileE;
        this.maxDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date()
        };
    }

    ngOnInit() {
        this.Is340BPharm$ = this._commonService.Is340BVal$;
        this.getDrugData();

        if (this.accessPrivCate) {
            this.hasAddDrugFilePrivs = this.checkHasPrivsToAccess(this.accessPrivCate.DrugFile, this.drugPrivs.Add);
            this.hasChangDrugFilePrivs = this.checkHasPrivsToAccess(this.accessPrivCate.DrugFile, this.drugPrivs.Edit);
            this.hasDeltDrugFilePrivs = this.checkHasPrivsToAccess(this.accessPrivCate.DrugFile, this.drugPrivs.Delete);
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "drugListWJ") {
                this.patchDefaultValHeaders();
            }
        });
       this.bucketId =  this._commonService.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET")
             ? +this._commonService.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET")
            : null;
            this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
            this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    async patchDefaultValHeaders() {
        const drugListId = document.getElementById("drugListId");
        const drugWidth = drugListId ? drugListId.clientWidth : 0;
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugListWJ", storedWJ, null, drugWidth);
    }

    searchDrugData() {
        this.setPage(1);
    }

    getDrugData() {
        //  if (this.IsFromDocumentQueue) {
       this.DrugSearchFG.controls["BucketId"].patchValue(this.DrugSearchFG.controls["OnlyDrugsHavingQtyInStock"].value
       ? this.bucketId : null);
        //  }
        this._editDrug.getDrugSearch(this.DrugSearchFG.value).subscribe(resp => {
            if (resp && resp["TotalDocuments"]) {
                this.generateList(resp);
                this.isDataExists = true;
            } else {
                this.totalCount = 0;
                this.isDataExists = false;
                this.searchDrugList = new CollectionView(null)
            }
        });
    }


    init(flex: wjcGrid.FlexGrid) {
        flex.columns[0].align = "center";
       // flex.columnHeaders.rows[0].height = 55;
       // flex.columnHeaders.rows[0].wordWrap = true;
    }

    generateList(data: any) {
        this.totalCount = data.TotalDocuments;
        const mapDataSet=[];
        data.DrugDetails.map((drug, i) => {
                const j = {};
                j["Id"] = drug.id;
                j["Drug Name"] = drug.drugname ? (drug.drugname).toUpperCase() : "";
                j["Strength"] = drug.strength ? drug.strength.toUpperCase() : "";
                j["Form"] = drug.drugformname;
                j["Size"] = drug.qtypack;
                j["Drug NDC"] =  this._formatsUtil.getNDCFormat(drug.ndc); 
                j["Made By"] = drug.manufacturername;
                j["AWP Price"] = drug.unitpriceawp ? "$" + this._decimalPipe.transform(drug.unitpriceawp, "1.3-3") : "$0.000";
                j["Units"] = drug.drugunit;
                j["Last Dispensed"] = drug.lastDispensedDate ? moment.utc(drug.lastDispensedDate).local().format("MM/DD/YYYY") : null;
                j["Quick Code"] = (drug.quickcode && drug.quickcode !== "###") ? drug.quickcode.toUpperCase() : "";
                j["IsBrand"] = drug.isbranded === true ? "Y" : "N";
                j["Cost Price"] = drug.unitpricecost ? "$" + this._decimalPipe.transform(drug.unitpricecost, "1.4-4") : "$0.0000";
                j["Dir Price"] = drug.directunitprice ?  "$" + this._decimalPipe.transform(drug.unitpricecost, "1.3-3") : "$0.000";
                j["D/C Date"] = drug.discontdt ? moment.utc(drug.discontdt).local().format("MM/DD/YYYY") : null;
                j["discontdt"] = j["D/C Date"];
                j["Brand Name"] = drug.drugbandname ? (drug.drugbandname).toUpperCase() : "";
                j["Generic Name"] = drug.genericname ? (drug.genericname).toUpperCase() : "";
                j["Stock Qty"] = this.getQtyInHand(drug.manageinventory);
                j["Drug Type"] = drug.drugtype ? drug.drugtype === "Prescription" ? "P" :
                    drug.drugtype === "Compound" ? "C" :
                        drug.drugtype === "Service" ? "S" :
                            drug.drugtype === "Over The Counter(OTC)" ? "O" :
                                drug.drugtype === "Durable Medical Equipment" ? "D" : drug.drugtype
                    : null;
                // j["Expiration Date"] = drug.qtyInHand;
                // tslint:disable-next-line:max-line-length
                j["Billing NDC"] = this._formatsUtil.getNDCFormat(drug?.billingndc);
                j["ispreferred"] = drug.ispreferred;
                const days = +this._commonService.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
                const isDrugDiscontinued = drug["discontdt"] && moment().startOf("day")
                    .isAfter(moment(drug["discontdt"]).local().add(days, "days"));
                j["isdiscontdate"] = isDrugDiscontinued ? "YES" : "NO";
                j["Gen. Avail?"] = (drug.genericAvailability).toUpperCase();
                j["Exp. Date"] = drug.discontdt ? moment.utc(drug.discontdt).local().format("MM/DD/YYYY") : null;
                j["Drug Class"] = drug.drugclass;
                // return j;
                mapDataSet.push(j);
            });
                this.searchDrugList = new CollectionView(mapDataSet,
                    {
                       sortComparer: (a: any, b: any) => {
                            return moment(a).isValid() && moment(b).isValid()
                                ? moment(a).diff(moment(b)) : null;
                        }
                    });
    }

    getQtyInHand(manageInventory: any): any {
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId === +this._drugBuckUtil.getDefaultBucketId()) || null;
            return isExist && isExist["QtyInHand"] ? (isExist["QtyInHand"]).toFixed(3) : "0.000";
        } else {
            return "0.000";
        }
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.startIndex = (this.pageSize * (this.pageNumber - 1));
        this.DrugSearchFG.controls["PageNumber"].patchValue(this.startIndex);
        this.getDrugData();
    }

    setSize(size: any) {
        this.pageNumber = this.paginationModel.pageNumber;
        this.pageSize = size;
        this.DrugSearchFG.controls["PageSize"].patchValue(this.pageSize);
        this.setPage(this.paginationModel.pageNumber);
        //  this.getPatientData();
    }

    drugSelected(checked, data, index) {
        this.SelectedDrug.emit(data);
    }

    addDrug(type) {
        // this._router.navigate(["eprx/drug/druginfo"], {
        //     queryParams: { fm: "SearchDrug" }
        // });
        this.openDrugInModal(type);
    }

    editDrug(value) {
        this._router.navigate(
            ["eprx/drug/" + value + "/druginfo"],
            {
                queryParams: { fm: "SearchDrug" }
            }
        );
    }

    openPopUpModal(content, val: any) {
        this.currentItem = val;
        this._editDrug.getDrugDeleteResponse(this.currentItem["Id"]).subscribe(resp => {
            if (resp === 2) {
                this._alertServ.error(MsgConfig.DRUG_ALREADY_IN_USE, false, "long");
            } else {
                this.modelRef = this.modalService.open(content, { centered: true, keyboard: false, backdrop: false,
                     windowClass: "large--content"});
            }
        });
    }

    clear() {
        this.DrugSearchFG.reset();
        this.setSize(this.paginationModel.pageSize);
    }

    checkHasPrivsToAccess(category: number, privType: number) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "Edit") {
                this.openDrugInModal("edit", this.savedValue);
            } else if (this.overrideFor === "Add") {
                this.addDrug(this.overrideFor);
            } else if (this.overrideFor === "Delete") {
                this.openPopUpModal(this.deletePop, this.savedValue);
                this.showDelete = true;
            }
        }
    }

    openOveride(content, value, type, endpoint?) {
        this.deletePop = content;
        this.categoryId = "DrugFile";
        this.privType = type;
        this.openOverride = true;
        this.savedValue = value;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    deleteDrug() {
        this._editDrug.deleteDrug(this.currentItem["Id"]).subscribe(resp => {
            if (resp === 1) {
                this.getDrugData();
                this._alertServ.success(MsgConfig.DELETE_SUCCESS);
            } else {
                this._alertServ.error(MsgConfig.DELETE_FAIL);
            }
        });
    }

    focusToFirst(event) {
        if (document.getElementById("addDrugbut")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("addDrugbut").focus();
            }, 10);

        }
    }

    focusOutFmCan(event) {
        if (!this.totalCount) {
            this.focusToFirst(event);
        }
    }

    addEventOnWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                if (this.hasAddDrugFilePrivs) {
                    this.addDrug("Add");
                } else if (!this.hasAddDrugFilePrivs) {
                    this.openOveride(null, null, "Add", "POST_Drug_New");
                }
            }
        }
    }

    editEventOnWijimo(event, grid) {
        if (grid["selectedItems"][0]) {
            event.preventDefault();
            if (this.hasChangDrugFilePrivs) {
                this.openDrugInModal("edit", grid["selectedItems"][0]["Id"]);
            } else if (!this.hasChangDrugFilePrivs) {
                this.openOveride(null, grid["selectedItems"][0]["Id"], "Edit", "PUT_Drug");
            }
        }
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugSearchColor(row.dataItem);
                for (const i in colors) {
                    if (colors[i]) {
                        if (i === "bg-red_text-brown") {
                            cell.style.backgroundColor = "#ff7770";
                            cell.style.color = "brown";
                        } else if (i === "bg-green_text-brown") {
                            cell.style.backgroundColor = "#86e096";
                            cell.style.color = "brown";
                        } else if (i === "bg-red_text-white") {
                            cell.style.backgroundColor = "#ff7770";
                            cell.style.color = "#ECF0F5";
                        } else if (i === "bg-green_text-black") {
                            cell.style.backgroundColor = "#86e096";
                            cell.style.color = "#000";
                        } else if (i === "bg-white_color-brown") {
                            cell.style.backgroundColor = "#FFF";
                            cell.style.color = "brown";
                        } else if (i === "bg-white_text-black") {
                            cell.style.backgroundColor = "#FFF";
                            cell.style.color = "#000";
                        }
                    }
                }
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
            }
        }
    }

    toggleColorLegend() {
        this._alertServ.showHelpText({RxStatus: false, DrugName: false, Status: false, DrugForDrugFile: true});
    }

    openDrugInModal(type, value?: any) {
        const modalRef = this.modalService.open(DrugComponent, {
            backdrop: false,
            size: "lg",
            windowClass: "md-xx-lg comp--modal modal-large modal-center-to-page",
            keyboard: false   
        });
        modalRef.componentInstance.drugId = +value;
        modalRef.componentInstance.drugFlow = type;
        modalRef.componentInstance.frmAction = true;
        modalRef.componentInstance.CloseDrugModal.subscribe(resp => {
            modalRef.close();
            this.getDrugData();
        });
    }
}
