import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Pipe({
  name: "checkBillStatus"
})
export class CheckBillStatusPipe implements PipeTransform {

  transform(payrec: any, rxFG: FormGroup, billas: any): any {
    let result = false;
    if (payrec && payrec.length && rxFG) {
        result = payrec.find(pay =>
        (pay.CoverageCD + 1) === (+rxFG.value.RefillInsu.InsuPriId) && (pay.RecType.trim().toUpperCase() === "P" || pay.RecType.trim().toUpperCase() === "C" ||
        pay.RecType.trim().toUpperCase() === "D")) ? true : false;
          return result;
    }
  }

}
