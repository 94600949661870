<div class="row" *ngIf="(patientId && familyInfoFrom === 'Patient') || (FrmFamily && patFamilyData?.Id)">
    <div [ngClass]="(FrmFamily && patFamilyData?.Id) ? 'col-md-11 form-group custom--input' : 'col-md-8 form-group custom--input'">
        <label class="control-label">
            <span>Patient's Family</span>
            <input id="familyInfo" class="form-control" readonly tabindex="-1" placeholder="Family Info"
                value="{{patFamilyData?.Name}}" />
        </label>
    </div>
    <div class="col-md-4 padding-0 margin_top_1rem" *ngIf="!FrmFamily">
        <button type="submit" class="hotkey_success pull-right" (click)="getPatientFamilyDetails(familyInfo)"
            appShortcutKey [AltKey]="'f'">
            <span>F</span> Family Info
        </button>
    </div>

</div>



<a *ngIf="(patientInfo && patientInfo.PatFamily && patientInfo.PatFamily['Id'] && familyInfoFrom === 'Rx')"
    (click)="openEditPopUpModal(patFamilyData, familyAdd, 'FrmRx')"><i class="far fa-users fa-lg font_size_1_5rem" title="Family Info"
        ></i></a>

<a *ngIf="familyInfoFrom === 'Rx'" (click)="patFamilyAdd(FamilyExistPopUp, familyInfoNotExist, familyInfo)"><i
        class="far fa-users-medical fa-lg font_size_1_5rem" title="Add A Family Member"></i></a>


<!--familyInfo popUp-->
<ng-template #familyInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Patient Family</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>This Patient is already linked to a family, What would you like to do?</span>
        <br>
        <div class="row">
            <div class="col inline-flex">
                <label>Family Name: </label>
                <div class="label--data">&nbsp; {{patFamilyData?.Name}} </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Address: </label>
                <div class="label--data">&nbsp;
                    {{patientsFamilyInfo?.FamilyAddress[0]?.AddressLine1 ? (patientsFamilyInfo?.FamilyAddress[0]?.AddressLine1 + ", ") : ""}}
                    {{patientsFamilyInfo?.FamilyAddress[0]?.AddressLine2 ? (patientsFamilyInfo?.FamilyAddress[0]?.AddressLine2 + ", ") : ""}}
                    {{patientsFamilyInfo?.FamilyAddress[0]?.StateName ? (patientsFamilyInfo?.FamilyAddress[0]?.StateName + ", ") : ""}}
                    {{patientsFamilyInfo?.FamilyAddress[0]?.CityName ? (patientsFamilyInfo?.FamilyAddress[0]?.CityName + ", ") : ""}}
                    {{patientsFamilyInfo?.FamilyAddress[0]?.ZipCode}} </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary"
            (click)="d('Cross click');openEditPopUpModal(patFamilyData, familyAdd, 'Out')" appShortcutKey
            [InputKey]="'v'">
            <b>V</b> View/Edit Family
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click');openPopUpModal(searchFamilyInfo)"
            appShortcutKey [InputKey]="'l'">
            <b>L</b> Link to another family
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click');" appShortcutKey [InputKey]="'c'">
            <b>C</b> Cancel
        </button>
    </div>
</ng-template>

<!--familyInfo popUp-->
<ng-template #familyInfoNotExist let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Patient Family</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>This Patient is not yet linked to any family, What would you like to do?</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="d('Cross click');openPopUpModal(searchFamilyInfo)"
            appShortcutKey [InputKey]="'l'">
            <b>L</b> Link To existing Family
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click');openPopUpModalAdd(familyAdd, 'StartNew')"
            appShortcutKey [InputKey]="'s'">
            <b>S</b> Start New Family
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click');" appShortcutKey [InputKey]="'c'">
            <b>C</b> Cancel
        </button>
    </div>
</ng-template>

<!--patient search-->
<ng-template #patientSearch let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Search</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <div class="exprx--common-block">
            <div>
                <div class="seconday-search--grid">
                    <div class="row">
                        <div class="col-4 color_cyan">
                            Search Patient</div>
                    </div>
                    <app-searchbox-global #drugSearch [SearchFrm]="'Family'" [SearchFor]="'Patient'"
                        [IsAddNew]="false" [emitIfNotFound]="true" (OptedFormAddNew)="addPatientFromFamily($event, FamilyExistPopUp)"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true"
                        (SelectedPatient)="patientSelected($event, FamilyExistPopUp)">
                    </app-searchbox-global>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" class="hotkey_success" (click)="d('Cross click')" (keydown.enter)="d('Cross click')"
                appShortcutKey [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
        </div>
    </div>
</ng-template>

<!--search family info-->
<ng-template #searchFamilyInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <div class="col-md-8 padding-0">
            <h4 class="modal-title">Family Profile Search</h4>
        </div>
        <div class="col-md-4 text-right padding-0 insurance--body">
            <button class="hotkey_success  hotkey_primary--Override"
                (click)="openPatientSearch(patientSearch, 'addPatientData', familyAdd)" title="Add" appShortcutKey
                [AltKey]="'a'"><span>A</span>ADD</button>
        </div>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click'); popUpClosed()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="exprx--common-block erx--body insurance--body">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-input [LabelText]="'Last Name'" [PlaceHolder]="'Last Name'" [ControlName]="'Name'"
                            [FormGroupName]="searchFamilyFG" (TriggerOnEnterValue)="getFilteredData()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Zip Code'" [PlaceHolder]="'Zip Code'" [ControlName]="'ZipCode'"
                            [FormGroupName]="searchFamilyFG" (TriggerOnEnterValue)="getFilteredData()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Primary Insurance'" [PlaceHolder]="'Primary Insurance'"
                            [ControlName]="'Insurance'" [FormGroupName]="searchFamilyFG"
                            (TriggerOnEnterValue)="getFilteredData()">
                        </eprx-input>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 align-button text-right">
                        <button type="submit" class="hotkey_success pull-right" (click)="getFilteredData()"
                            appShortcutKey [AltKey]="'f'">
                            <span>S</span> Search
                        </button>
                        <button type="button" class="hotkey_success pull-right" (click)="clearSearch()" appShortcutKey
                            [AltKey]="'l'">
                            <span>L</span> clear
                        </button>
                    </div>
                </div>
            </div>
            <div class="exprx--common-block erx--body insurance--body">
                <div class="eprx--block__content" *ngIf="totalCount">
                    <wj-flex-grid #SearchFamilyInfo [headersVisibility]="'Column'" [itemsSource]="searchFamilyInfoWJ"
                        [isReadOnly]="true" [columnPicker]="''" (updatedView)="init(SearchFamilyInfo)"
                        [selectionMode]="'Row'" [allowSorting]="false"
                        (dblclick)="linkdataOnKeyPress(SearchFamilyInfo, importFamily, $event)"
                        (keydown.enter)="linkdataOnKeyPress(SearchFamilyInfo, importFamily, $event)">

                        <wj-flex-grid-column *ngFor="
                                let actvHeader of searchActiveHeaders;
                                let i = index
                            " [header]="actvHeader" [binding]="actvHeader" [visible]="true" title="{{ actvHeader }}"
                            [width]="'*'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div *ngIf="actvHeader === 'Actions'">
                                    <i class="far fa-edit actions" title="edit" (click)="
                                    openEditPopUpModal(item.data, familyAdd)
                                "></i>
                                    <i class="far fa-trash-alt fa-lg actions danger-actve" (click)="
                                    deleteFamilyInfo(
                                        searchFamilyInfoWJ.currentItem
                                    )
                                " title="delete"></i>
                                    <i class="far fa-external-link actions"
                                        *ngIf="patFamilyData && (item.data['Id'] !== patFamilyData?.Id)" title="Link"
                                        aria-hidden="true" (click)="
                            linkData(item.data, importFamily)"></i>

                                    <i class="far fa-external-link actions" *ngIf="!patFamilyData" title="Link"
                                        aria-hidden="true" (click)="
                            linkData(item.data, importFamily)"></i>
                                    <i class="far fa-external-link actions color_dimgray" title="This patient is already linked"
                                        *ngIf=" patFamilyData && (item.data['Id'] === patFamilyData?.Id)"
                                        ></i>
                                </div>
                                <div *ngIf="!(actvHeader === 'Actions')">
                                    {{ item[actvHeader] }}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                    <app-eprx-pagination [TotalCount]="totalCount" (OnPageSelected)="setPage($event)"
                        (OnSizeSelected)="setSize($event)" [GridName]="'SearchFamilyInfo'"
                        [WijmoName]="SearchFamilyInfo" [FromModal]="true"></app-eprx-pagination>
                </div>
            </div>
            <div *ngIf="totalCount === 0" class="eprx--block__footer">
                <div>
                    <span></span>
                    <i class="fa fa-file"></i><br/>
                    No Data Found!
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="d('Cross click'); popUpClosed()" appShortcutKey
            [AltKey]="'c'">
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>


<!-- add/edit family info-->
<ng-template #familyAdd let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 *ngIf="!editMode" class="modal-title">New Family Info</h4>
        <h4 *ngIf="editMode" class="modal-title">Edit Family Info</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');modelRef=null;modalName = 'Search'">
            <span aria-hidden="true"  class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div [ngClass]="(patSearchFr === 'addPatientData') ? 'col-md-5' : 'col-md-6'">
                <eprx-input [LabelText]="'Last Name'" [PlaceHolder]="'Last name'" [ControlName]="'Name'"
                    [FormGroupName]="PatFamily" [InputErrors]="Name?.errors" [IsRequired]="true"
                    [MarkAsTouched]="Name?.touched" [ErrorDefs]="{ required: 'Required', pattern: 'Invalid!' }"
                    [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="100" [TabIndex]="-1">
                </eprx-input>
            </div>
            <div class="col-md-1" *ngIf="patSearchFr === 'addPatientData'">
                <i class="far fa-search fa-lg actions margin_top_27px" title="Search Patients"
                    (click)="openPatientSearch(patientSearch, 'addPatientData')"></i>
            </div>
            <div class="col-6">
                <eprx-input [LabelText]="'Address1'" [PlaceHolder]="'Address Line 1'" [ControlName]="'AddressLine1'"
                    [FormGroupName]="FamilyAddress" [InputErrors]="AddressLine1?.errors" [IsRequired]="true"
                    [MarkAsTouched]="AddressLine1?.touched" [ErrorDefs]="{ required: 'Required' }" [MaxLength]="150"
                    [AutoFocus]="(editMode || FrmSearchFamily === 'StartNew')" [FormGroupInvalid]="formGroupInvalid">
                </eprx-input>
            </div>
            <div class="col-6">
                <eprx-input [LabelText]="'Address2'" [PlaceHolder]="'Address Line 2'" [ControlName]="'AddressLine2'"
                    [FormGroupName]="FamilyAddress"></eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                <app-search-zip [FormGroupName]="FamilyAddress" [ControlName]="'ZipCode'"
                    [InputErrors]="ZipCode?.errors" (OnZipSelected)="insZipSelected($event)" [IsRequired]="true"
                    [FormGroupInvalid]="formGroupInvalid"></app-search-zip>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <app-search-state [FormGroupName]="FamilyAddress" [ControlName]="'StateId'" [CityControlName]="'CityId'"
                    [StateSelected]="stateSelected" (OnStateSelected)="insStateSelected($event)" [IsRequired]="true"
                    [FormGroupInvalid]="formGroupInvalid"></app-search-state>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                <app-search-city [FormGroupName]="FamilyAddress" [ControlName]="'CityId'" [StateControlName]="'StateId'"
                    [CitySelected]="citySelected" [IsRequired]="true" (OnCitySelected)="insCitySelected($event)"
                    [FormGroupInvalid]="formGroupInvalid"></app-search-city>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="form-group custom--input" [ngClass]="{'has-error': InsurerId?.invalid &&
                (InsurerId?.dirty || InsurerId?.touched) &&
                InsurerId?.errors}">
                    <label class="control-label">
                        <span> Insurance Type <span class="help--text__danger">*</span></span>
                        <input type="text" #searchBoxInsType [value]="inputValue"
                            class="form-control search--input__box" placeholder="To Start Search..." appChangeDebounce
                            [ngbTypeahead]="search" [required]="true" [resultTemplate]="rt" [inputFormatter]="formatter"
                            (selectItem)="selectedInsType($event)" KeyUpDown>

                    </label>
                    <div class="help-block" *ngIf="InsurerId?.invalid &&
                    (InsurerId?.dirty || InsurerId?.touched) &&
                    InsurerId?.errors">
                        Required
                    </div>
                    <ng-template #rt let-r="result" let-t="term">

                        <div class="card patient-card secondary-patient-card">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <table class="table table-sm">
                                            <tbody>
                                                <tr *ngIf="r">
                                                    <th class="col-md-3" scope="row">{{r?.InsurerCode | uppercase}}</th>
                                                    <td class="col-md-3"> {{r?.InsuranceName | uppercase}} </td>
                                                    <td class="col-md-3"> {{r?.BinNum}} </td>
                                                    <td class="col-md-3"> {{r?.ProcessorNum}} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <eprx-input [RxSelectId]="'primInsID'" [LabelText]="'Policy# / Insurance ID'"
                    [PlaceHolder]="'Policy# / Insurance ID'" [ControlName]="'PolicyNum'"
                    [FormGroupName]="FamilyInsuCard" [InputErrors]="PolicyNum?.errors"
                    [ErrorDefs]="{ required: 'Required'}" [MaxLength]="20"
                    [FormGroupInvalid]="FamilyInsuCard?.controls?.PolicyNum?.touched"
                    [MarkAsTouched]="PolicyNum?.touched">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <eprx-select [LabelText]="'Group#'" [PlaceHolder]="'Group#'" [ControlName]="'InsuGrpId'"
                    [FormGroupName]="FamilyInsurance" [List]="filteredGroups" [BindLabel]="'Name'" [BindValue]="'Id'"
                    [LabelForId]="'Name'">
                </eprx-select>
            </div>
            <div class="col-6">
                <eprx-input [LabelText]="'Telephone#'" [PlaceHolder]="'Telephone#'" [ControlName]="'xPhone'"
                    [FormGroupName]="FamilyPhone" [MaskPattern]="'(000)000-0000'" [ShowMask]="true"
                    [DropSpclChar]="true">
                </eprx-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label> Existing Family Members </label>
                <wj-flex-grid #existingFamily [headersVisibility]="'Column'" [itemsSource]="familyMembersWJ"
                    [isReadOnly]="true" [columnPicker]="''" [selectionMode]="'Row'"
                    (keydown)="keyeventOnFamilyWijimo($event)"
                    (selectionChanged)="selectRadioOnKeyEvents(existingFamily)" class="height_30rem"
                    (initialized)="init1(existingFamily)" *ngIf="existingMembers && existingMembers.length > 0">
                    <wj-flex-grid-column [header]="rxheader" [binding]="rxheader" [visible]="true"
                        *ngFor="let rxheader of familyHeader; let i = index"
                        [width]="rxheader === 'Select' ? 65 : (rxheader === 'Pat#') ? 91 : 337">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                            <div *ngIf="rxheader === 'Select'">
                                <input type="radio" id="item['Pat#']" name="item['Pat#']" (change)="
                                selectFamilyMember(
                                        $event?.target?.checked,
                                        item
                                    )
                                " value="{{ row.index }}" [checked]="
                                existingMembers &&  existingMembers.length > 1
                                        ? (item['Pat#'] ===
                                        familymemId) : 'true'
                                " />
                            </div>
                            <div *ngIf="!(rxheader === 'Select')">
                                {{item[rxheader]}}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                </wj-flex-grid>
                <table class="table" *ngIf="!existingMembers || existingMembers.length === 0">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col"> Pat# </th>
                            <th scope="col"> Last Name </th>
                            <th scope="col"> First Name </th>
                            <th scope="col"> Phone </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"> </th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="familyInfoFrom === 'Rx' && editMode === true" class="hotkey_success"
            (click)="selectedPatFrFill(familymemId); d('Cross click');" appShortcutKey [AltKey]="'p'">
            <span>P</span> Select This Patient
        </button>
        <button type="button" class="hotkey_success" (click)="openPatientSearch(patientSearch)" appShortcutKey
            [AltKey]="'m'">
            <span>M</span> Add Member
        </button>
        <button type="button" class="hotkey_success" (click)="deleteExistingMembers(familymemId)" appShortcutKey
            [AltKey]="'r'">
            <span>R</span> Remove Member
        </button>
        <button *ngIf="editMode === false" type="button" class="hotkey_success" (click)="addFamilyInfo(importFamily)"
            appShortcutKey [AltKey]="'s'">
            <span>S</span> Save
        </button>
        <button *ngIf="editMode === true" type="button" class="hotkey_success" (click)="updateFamilyInfo(importFamily)"
            appShortcutKey [AltKey]="'u'">
            <span>U</span> Update
        </button>
        <button type="button" class="hotkey_success" (click)="d('Cross click');modelRef=null;modalName = 'Search'"
            appShortcutKey [AltKey]="'c'">
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>

<!-- importing family profile -->
<ng-template #importFamily let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Import Family Profile Information</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeImportPopup();">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div calss="col-12 margin_1rem_0_5rem">
                <h5>Do you want to update family profile info. to selected patients?</h5>
            </div>
            <div class="col-md-12">
                <label>Family Details : </label>
                <span>&nbsp; {{ FamilyLastName | uppercase}} &nbsp; {{ Address | uppercase}}</span>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-md-12 margin_bottom_0_5rem" [formGroup]="linkFG">
                <div class="row">
                    <div class="col" [formGroup]="linkFG">
                        <input class="form-check-input" type="checkbox" id="IsLastNameUpdate"
                            formControlName="IsLastNameUpdate" />
                        <label class="form-check-label link__checkboxes" for="IsLastNameUpdate">
                            Last Name
                        </label>
                        <span>&nbsp; {{ FamilyLastName | uppercase}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6" [formGroup]="linkFG">
                <div class="row">
                    <div class="col" [formGroup]="linkFG">
                        <input class="form-check-input" type="checkbox" id="gnrlInfo"
                            formControlName="IsOverrideGeneralDetails" />
                        <label class="form-check-label link__checkboxes" for="gnrlInfo">
                            General Information
                        </label>
                    </div>
                </div>
                <span>1. Address</span>
                <br/>
                <span>2. Telephone Number</span>
            </div>
            <div class="col-md-6" [formGroup]="linkFG">
                <div class="row">
                    <div class="col" [formGroup]="linkFG">
                        <input class="form-check-input" type="checkbox" id="packPat1"
                            formControlName="IsOverrideInsuranceDetails" />
                        <label class="form-check-label link__checkboxes" for="packPat1">
                            Insurance Information
                        </label>
                    </div>
                </div>
                <span> 1. Primary Insurance</span>
                <br/>
                <span> 2. Insurance Id</span>
                <br/>
                <span> 3. Group# </span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus class="hotkey_success" (click)="importFamilyProfile(); " appShortcutKey [AltKey]="'s'">
            <span>S</span> SAVE
        </button>
        <button type="button" class="hotkey_success" (click)="closeImportPopup(); " appShortcutKey [AltKey]="'c'"(click)="closeImportPopup()">
            <span>C</span> CANCEL
        </button>
    </div>
</ng-template>

<!--FamilY Exist For Patient popUp-->
<ng-template #FamilyExistPopUp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Patient Family</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');">
            <span aria-hidden="true"class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>This Patient is already linked to a family, What would you like to do?</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="addMember('Update'); d('Cross click');" appShortcutKey
            [InputKey]="'u'">
            <span>U</span> Update
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click');" appShortcutKey [InputKey]="'c'">
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>
