<ng-template #CheckDur let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Print Fax View</h4>
        <span body >
            <div class="max_height_55rem overflow_y_auto overflow_x_hidden font_size_18px">
                    <div id="contentToConvert2" class="eprx--block__content" *ngIf="false" >
                            <div class="row padding_1_428_rem" *ngFor="let rx of rxInfo">
                                <div class="col-md-12 padding_0_714_rem border_1px_solid">
                                    <div class="row text-center">
                                        <div class="col-md-4"></div>
                                        <div class="col-md-4"><b>{{pharmName ? (pharmName | uppercase):"--"}}</b></div>
                                        <div class="col-md-4"><b>{{currentDate | localTime | date:'MM/dd/yyyy , h:mm a'}}</b></div>
                                        <div class="col-md-12">
                                            <b>{{pharmAddress ? (pharmAddress | uppercase):"--"}}</b>
                                        </div>
                                        <div class="col-md-12"><b>Phone : {{pharmPhone ? (pharmPhone):"--"}} Fax : {{pharmFax ? (pharmFax):"--"}}</b></div>
                                    </div>
                                    <div class="row">
                                        <hr class="col-md-12" />
                                    </div>
                                    <div class="row padding_1_428_rem">
                                        <hr>
                                        <h4 class="col-md-12 padding-0">Refill Request</h4>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <!-- <h5 class="col-md-12" style="padding: 0px">
                                                    Prescriber
                                                    <hr />
                                                </h5> -->
                                                <div class="col-md-12 border_1px_solid padding_0_714_rem">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <span><b>Name :{{rx?.Prescriber?.prescriberlastname+", "+rx?.Prescriber?.prescriberfirstname}}</b></span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Address : </b>{{rx?.Prescriber?.prescriberaddress1 +"," +rx?.Prescriber?.prescriberaddress2}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>DEA# : </b>{{_formatsUtil.getDeaFormat(rx?.Prescriber?.prescriberdeanum)}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>NPI# : </b>{{rx?.Prescriber?.prescribernpinum}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Phone# : </b>Off : {{rx?.Prescriber?.telephone}} Fax : </span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>LIC# :</b>{{rx?.Prescriber?.prescriberlicensenumber}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row margin-top-0_714rem">
                                                <div class="col-md-12 border_1px_solid padding_0_714_rem">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <span><b>Patient : {{rx?.Patient?.lastname+", "+rx?.Patient?.firstname}}</b></span>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <span><b>Address : </b>{{rx?.Patient?.address1 +','+ rx?.Patient?.address2}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>DOB : </b>{{rx?.Patient?.dob | date:'MM/dd/yyyy'}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Gender : </b>{{rx?.Patient?.gender}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Phone# : </b>{{rx?.Patient?.telephone}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 margin-top-0_714rem margin-bottom-0_714rem"><b>{{rx?.Patient?.lastname +', '+
                                                rx?.Patient?.firstname}} needs refills on the
                                                following Rx</b></div>
                                        <div class="col-md-12 margin-top-0_714rem margin-bottom-0_714rem">Please indicate if there have been any
                                            changes of new
                                            drugs added to their regimen.</div>
                                        <div class="col-md-12 margin-top-0_714rem">
                                            <div class="row">
                                                <div class="col-md-12"><b>Please Fax this form back to the pharmacy at ---</b></div>
                                                <div class="col-md-12">
                                                    <table class="table table-striped table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Drug Name</th>
                                                                <th scope="col">RXNO</th>
                                                                <th scope="col">Last Disp. Date</th>
                                                                <th scope="col">Qty Ord</th>
                                                                <th scope="col">Quantity</th>
                                                                <th scope="col">Refills</th>
                                                                <th scope="col">DAW*</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="margin-top-0_714rem">
                                                                <td>{{rx?.Drug?.drugname ? rx?.Drug?.drugname : "" }} {{ rx?.Drug?.strength ? rx?.Drug?.strength : "" }}</td>
                                                                <td>{{rx?.Prescription?.PrescNum}}</td>
                                                                <td>{{rx?.Drug?.dispensedDate ? (rx?.Drug?.dispensedDate| date:'MM/dd/yyyy'): "--"}}</td>
                                                                <td>{{(rx?.PrescReFill?.DispQty | number :'1.3-3')}}</td>
                                                                <td>{{(rx?.PrescReFill?.DispQty | number :'1.3-3')}}</td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colspan="7">*THE PRESCRIPTIONS WILL BE FILLED GENERICALLY UNLESS PRESCRIBER WRITES "DAW" IN
                                                                    THE BOX</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 padding-0">
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-6">Signature :_________________________________________</div>
                                            <div class="col-md-6">Date :_________________________________________</div>

                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12"><b>Rx valid only if transmitted by Facsimile</b></div>
                                        <p class="col-md-12"><b>I certify that I have received the drug counseling information for the above medications.</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="contentToConvert" class="eprx--block__content" *ngIf="true">
                            <div class="row padding_1_428_rem" *ngFor="let rxInfo of groupedArray">
                                <div class="col-md-12 border_1px_solid padding_0_714_rem">
                                    <div class="row text-center">
                                        <div class="col-md-4"></div>
                                        <div class="col-md-4"><b>{{pharmName ? (pharmName | uppercase):"--"}}</b></div>
                                        <div class="col-md-4"><b>{{currentDate | localTime | date:'MM/dd/yyyy , h:mm a'}}</b></div>
                                        <div class="col-md-12">
                                            <b>{{pharmAddress ? (pharmAddress | uppercase):"--"}}</b>
                                        </div>
                                        <div class="col-md-12"><b>Phone# : {{pharmPhone ? (pharmPhone):"--"}} Fax : {{pharmFax ? (pharmFax):"--"}}</b></div>
                                    </div>
                                    <div class="row">
                                        <hr class="col-md-12" />
                                    </div>
                                    <div class="row padding_1_428_rem">
                                        <hr>
                                        <h4 class="col-md-12 padding-0">Refill Request</h4>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <!-- <h5 class="col-md-12" style="padding: 0px">
                                                        Prescriber
                                                        <hr />
                                                    </h5> -->
                                                <div class="col-md-12 border_1px_solid padding_0_714_rem">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <span><b>Name : {{rxInfo[0]?.Prescriber?.prescriberlastname+", "+rxInfo[0]?.Prescriber?.prescriberfirstname}}</b></span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Address : </b>{{rxInfo[0]?.Prescriber?.prescriberaddress1 +"," +rxInfo[0]?.Prescriber?.prescriberaddress2}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>DEA# : </b>{{_formatsUtil.getDeaFormat(rxInfo[0]?.Prescriber?.prescriberdeanum)}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>NPI# : </b>{{rxInfo[0]?.Prescriber?.prescribernpinum}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Phone# : </b>Off : {{rxInfo[0]?.Prescriber?.telephone}} Fax : </span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>LIC# : </b>{{rxInfo[0]?.Prescriber?.prescriberlicensenumber}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row margin-top-0_714rem">
                                                <div class="col-md-12 border_1px_solid padding_0_714_rem">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <span><b>Patient : {{rxInfo[0]?.Patient?.lastname+", "+rxInfo[0]?.Patient?.firstname}}</b></span>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <span><b>Address : </b>{{rxInfo[0]?.Patient?.address1 +','+ rxInfo[0]?.Patient?.address2}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>DOB : </b>{{rxInfo[0]?.Patient?.dob | date:'MM/dd/yyyy'}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Gender : </b>{{rxInfo[0]?.Patient?.gender}}</span>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <span><b>Phone# : </b>{{rxInfo[0]?.Patient?.telephone}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 margin-top-0_714rem margin-bottom-0_714rem"><b>{{rxInfo[0]?.Patient?.lastname +', '+
                                                rxInfo[0]?.Patient?.firstname}} needs refills on the
                                                following {{rxInfo.length}} Rx(s)</b></div>
                                        <div class="col-md-12 margin-top-0_714rem margin-bottom-0_714rem">Please indicate if there have been any
                                            changes of new
                                            drugs added to their regimen.</div>
                                        <div class="col-md-12  margin-top-0_714rem">
                                            <div class="row">
                                                <div class="col-md-12"><b>Please Fax this form back to the pharmacy at ---</b></div>
                                                <div class="col-md-12">
                                                    <table class="table table-striped table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Drug Name</th>
                                                                <th scope="col">RXNO</th>
                                                                <th scope="col">Last Disp. Date</th>
                                                                <th scope="col">Qty Ord</th>
                                                                <th scope="col">Quantity</th>
                                                                <th scope="col">Refills</th>
                                                                <th scope="col">DAW*</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="margin-top-0_714rem" *ngFor="let rx of rxInfo">
                                                                    <td>{{rx?.Drug?.drugname ? rx?.Drug?.drugname : "" }} {{ rx?.Drug?.strength ? rx?.Drug?.strength : "" }}</td>
                                                                    <td>{{rx?.Prescription?.PrescNum}}</td>
                                                                    <td>{{rx?.Drug?.dispensedDate ? (rx?.Drug?.dispensedDate| date:'MM/dd/yyyy'): "--"}}</td>
                                                                    <td>{{(rx?.PrescReFill?.DispQty | number :'1.3-3')}}</td>
                                                                <td>{{(rx?.PrescReFill?.DispQty | number :'1.3-3')}}</td>
                                                                <td><input type="text" /></td>
                                                                <td><input type="text" /></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colspan="7">*THE PRESCRIPTIONS WILL BE FILLED GENERICALLY UNLESS PRESCRIBER WRITES "DAW" IN
                                                                    THE BOX</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 padding-0">
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-6">Signature :_________________________________________</div>
                                            <div class="col-md-6">Date :_________________________________________</div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12"><b>Rx valid only if transmitted by Facsimile</b></div>
                                        <p class="col-md-12"><b>I certify that I have received the drug counseling information for the above medications.</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>

        </span>
        <button class="hotkey_primary" footer (click)="print()" appShortcutKey InputKey="p"><b>P</b> Print</button>
        <button class="hotkey_primary" ngbAutofocus footer (click)="closeModal()" appShortcutKey InputKey="c"><b>C</b> Close</button>
    </app-custom-modal>
</ng-template>
