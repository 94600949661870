import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  NRxUtils, RxBillingUtil } from "src/app/utils";
import { CommonService, InsuranceService, RxService } from 'src/app/services';
import { RxBillingCalculationInput } from 'src/app/models';
import { Observable, Subject, merge } from 'rxjs';
import * as _ from "lodash";
import { RxStore } from 'src/app/store/rx.store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rx-sig-qty-daw',
  templateUrl: './rx-sig-qty-daw.component.html',
  styleUrls: ['./rx-sig-qty-daw.component.scss']
})
export class RxSigQtyDawComponent implements OnInit, OnChanges {
  @Input() erxReq: FormGroup;
  unsubscribe$: Subject<void> = new Subject();
  drugInfo: any;
  @Input()
  set DrugInfo(drug: any) {
      if(drug) {
          this.drugInfo = drug;
      }
  }
  modalRef: any;
  @ViewChild("QTYORDRDDISPEN", { static: true })
  QTYORDRDDISPEN: any;
  insurancSettings: any = [];
  discountData: any;
  DAWList: { Code: string; Name: string; }[];
  priceSchdlList: any[];
  discntCodeList: any [];
  constructor(private _mdlServ: NgbModal, private _nrxUtls: NRxUtils, private _rxService: RxService, private _cdr: ChangeDetectorRef,
       private _cmmnServ: CommonService, private _rxBillng: RxBillingUtil, private _insServ: InsuranceService, private _rxStore: RxStore) {
       }

  ngOnInit() {
    this.DAWList = [{ Code: "Y", Name: "Yes" }, { Code: "N", Name: "No" }];
    this.priceSchdlList = this._nrxUtls.getPriceCodeList();
    this.discntCodeList = this._nrxUtls.getDisctCodeList();
    this.erxReq.controls["PatientId"].valueChanges.pipe(distinctUntilChanged()).subscribe(async (res: any) => {
      if (res && this.erxReq.value["DrugId"] && this.erxReq.value["Quantity"] &&  this.erxReq.value["InsuranceId"]) {
        this.erxReq.controls["PatientId"].patchValue(res);
        this.patchQtyDispAndQtyOrdered();
      }
    });
    this.erxReq.controls["DrugId"].valueChanges.pipe(distinctUntilChanged()).subscribe(async (res: any) => {
      if (res && this.erxReq.value["PatientId"] && this.erxReq.value["Quantity"] &&  this.erxReq.value["InsuranceId"]) {
        this.erxReq.controls["DrugId"].patchValue(res);
        this.patchQtyDispAndQtyOrdered();
      }
    });
    this.erxReq.controls["InsuranceId"].valueChanges.pipe(distinctUntilChanged()).subscribe(async (res: any) => {
      if (res && this.erxReq.value["PatientId"] && this.erxReq.value["Quantity"] &&  this.erxReq.value["DrugId"]) {
        this.erxReq.controls["InsuranceId"].patchValue(res);
        this.patchQtyDispAndQtyOrdered();
      }
    });
  }

setNumberFormat(ControlName) {
  if (this.erxReq.value[ControlName]) {
    this.erxReq.controls[ControlName].patchValue(
      (parseFloat(this.erxReq.value[ControlName])).toFixed(3)
  );
  }
}
triggerDiscountSchedule() {
  if (this.erxReq.value["DiscSchId"]) {
      const discode: any = _.filter(this.discntCodeList, {
          Id: +this.erxReq.value["DiscSchId"]
      })[0];
          this._rxService
              .getRxDiscount(discode.DiscCode)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(resp => {
                  this.discountData = resp;
                  this.assignToDiscountSchId(resp);
              });
  }
}
assignToDiscountSchId(result) {
  let TotPrice = this.erxReq.value["TotalAmt"];
  if (TotPrice !== undefined && TotPrice !== null && TotPrice !== "") {
      TotPrice = parseFloat(TotPrice);
  }

  if (result !== null && result !== false) {
      let DiscountAmount = 0;
      let Copay = 0;
      let BalDue = 0;
      let DiscountAmtTemp = 0;
      DiscountAmount = result.DiscountAmount;
      Copay = 0;

      if (
          DiscountAmount === undefined ||
          DiscountAmount === null ||
          isNaN(DiscountAmount)
      ) {
          DiscountAmount = 1;
      }

      if (!Copay) {
          Copay = 0;
      }

      if (result.DiscountType === "P") {
          if (DiscountAmount !== 0) {
              DiscountAmount =
                  parseFloat(TotPrice) * (DiscountAmount / 100);
          }

          this.erxReq.controls["Discount"].setValue(
              DiscountAmount.toFixed(2)
          );
          DiscountAmtTemp = DiscountAmount + Copay;

          if (TotPrice > DiscountAmtTemp) {
              BalDue = TotPrice - DiscountAmtTemp;
              BalDue = +BalDue > 0 ? +BalDue : 0;
              this.erxReq.controls["BalDue"].setValue(BalDue.toFixed(2));
          } else {
              BalDue = DiscountAmtTemp - TotPrice;
              BalDue = +BalDue > 0 ? +BalDue : 0;
              this.erxReq.controls["BalDue"].setValue(0);
          }
      } else {
          DiscountAmount = Math.ceil(DiscountAmount * 100) / 100;
          this.erxReq.controls["Discount"].setValue(
              DiscountAmount.toFixed(2)
          );
          DiscountAmtTemp = DiscountAmount + Copay;

          if (DiscountAmtTemp) {
              if (TotPrice > DiscountAmtTemp) {
                  BalDue = TotPrice - DiscountAmtTemp;
                  BalDue = +BalDue > 0 ? +BalDue : 0;
                  this.erxReq.controls["BalDue"].setValue(BalDue.toFixed(2));
              } else {
                  BalDue = DiscountAmtTemp - TotPrice;
                  BalDue = +BalDue > 0 ? +BalDue : 0;
                  this.erxReq.controls["BalDue"].setValue(
                      BalDue.toFixed(2)
                  );
              }
          }
      }
  } else {
      if (TotPrice) {
        this.erxReq.controls["BalDue"].setValue(TotPrice.toFixed(2));
      } else {
        this.erxReq.controls["BalDue"].setValue(0.0);
      }
  }
}
convertToDecimal(item, controlName) {
  if (item.target.value) {
      this.erxReq.controls[controlName].patchValue(parseFloat(item.target.value).toFixed(2));
  } else {
      this.erxReq.controls[controlName].patchValue("0.00");
  }

}

trimSigCodeOnBlur(sigC: any) {
  if (sigC) {
      const sigtrim = sigC.value.trim();
      this.erxReq.controls["SigCode"].setValue(sigtrim);
      this._cdr.detectChanges();
  }
}
getSigCodeDescOnChange(sigC: any) {
  const endSpace = /\s$/;
  if (sigC && endSpace.test(sigC)) {
      this.getSigCodeInfo(sigC);
  }
}

getSigCodeDescOnEnter(sigC: any) {
  if (sigC) {
      this.getSigCodeInfo(sigC.target.value);
  }
}

async getSigCodeInfo(val) {
  if (val) {
      const sigCodeList: string[] = (val.trim()).split(" ");
      const sigData = {
          sigcodes: sigCodeList,
          langcodes: ["enhyus"]
      };
      const resp = await this._rxService
          .getSigCodeListByLangCode(sigData).toPromise();
              if (resp) {
                  let sigCodeData = "";
                  let sigAltCodeData = "";
                  sigCodeList.map(code => {
                      for (const key in resp) {
                          if (code === key) {
                              if (resp[key]) {
                                  if (resp[key]["en-US"]) {
                                      const sigCodeDS = resp[key][
                                          "en-US"
                                      ].split("#");
                                      sigCodeData += " " + sigCodeDS[0];
                                  } else {
                                      sigCodeData += " " + key;
                                  }
                              } else {
                                  sigCodeData += " " + key;
                                  sigAltCodeData += " " + key;
                              }
                          }
                      }
                  });
                  if (sigCodeData === "" || sigCodeData === null ) {
                      this.erxReq.controls["SigDesc"].setValue(val);
                  } else {
                      this.erxReq.controls["SigDesc"].setValue(sigCodeData.substring(0, 140).trim());
                  }
              }
  }
}

calTaxValues(res) {
  const otherAmount = this.insurancSettings.InsuPlanPrice;
  if (this.insurancSettings && this.insurancSettings.InsuPlanPrice &&
    (((this.drugInfo && this.drugInfo.drugtypeid === 4) && this.insurancSettings.InsuPlanPrice.IsTaxOnOTC) ||
    ((this.drugInfo && this.drugInfo.drugtypeid === 1) && this.insurancSettings.InsuPlanPrice.IsTaxOnRx))) {
      if (otherAmount.SalesTaxBasisId === 2) {
        this.erxReq.controls["SalesTax"].setValue(
          (+res && +res.Price > 0) ?
          ((+res.Price *
              (otherAmount.TaxRatePer ? +otherAmount.TaxRatePer : 0.0)) / 100
          ).toFixed(2) : "0.00"
        );
      } else if (otherAmount.SalesTaxBasisId === 1 ||
        otherAmount.SalesTaxBasisId === 3) {
          const origProFee =  (this.erxReq && this.erxReq.value['OrigProFee'] ? (this.erxReq.value['rxType'] === 'er' ? this.erxReq.value['PrescReFill']['OrigProFee'] :
          this.erxReq.value['OrigProFee']) : 0.0);
          const profPlusPrice = (
            (res ? +res.Price : 0.0) +
            (this.drugInfo.drugtypeid === 4 ? (origProFee ? origProFee : 0.0) : (res.ProfFee ? +res.ProfFee : 0.0)));
            this.erxReq.controls["SalesTax"].setValue((profPlusPrice && +profPlusPrice > 0) ?
            ((+profPlusPrice * (otherAmount.TaxRatePer ? +otherAmount.TaxRatePer : 0.0))
                / 100).toFixed(2) : "0.00"
        );
        } else {
          this.erxReq.controls["SalesTax"].setValue("0.00");
        }
    }
}


async patchQtyDispAndQtyOrdered(type: string = "Drug") {
  const inputData = new RxBillingCalculationInput();
  inputData.Discount = null;
  inputData.DrugId = this.erxReq.value.DrugId;
  inputData.InsuranceId = this.erxReq.value.InsuranceId;
  inputData.OtherAmount = 0;
  inputData.PatientId = this.erxReq.value.PatientId;
  inputData.PriceScheduleId = this.erxReq.value.PriceSchId;
  inputData.TypeOfCalculation = type;
  inputData.prescReFillId = 0;
  inputData.QuantityDispense = +this.erxReq.value.Quantity;
 this._rxService.getPricingDetails(inputData)
 .pipe(takeUntil(this.unsubscribe$))
 .subscribe(async (resp: any) => {
    if (resp) {
      await this.patchPriceValues(resp);

    }
  });
}
  async patchPriceValues(resp) {
      this.erxReq.controls["Price"].setValue(
          resp.Price ? resp.Price.toFixed(2) : "0.00"
      );
      this.erxReq.controls["ProfFee"].setValue(
      resp.ProFee ? resp.ProFee.toFixed(2) : (0).toFixed(2)
      );
      this.erxReq.controls["UnitPriceUNC"].setValue(
      resp.UAndC ? resp.UAndC.toFixed(2) : "0.00"
      );
      this.erxReq.controls["AWPPack"].setValue(
        resp.TotalAwp ? resp.TotalAwp.toFixed(2) : "0.00"
      );
         this.erxReq.controls["Quantity"].setValue(
         this.erxReq.value["Quantity"] ?   parseInt(this.erxReq.value["Quantity"],0) : "0.000"
      );
      this.erxReq.controls["PriceSchId"].setValue(+resp["PriceSchId"]);
      await this.calTaxValues(resp);
      const BalDue = (resp.Price ? resp.Price : 0) +  (resp.ProFee ? resp.ProFee  : 0) + (this.erxReq.value["SalesTax"] +
      ((this.insurancSettings && this.insurancSettings.InsuPlanPrice &&
        this.insurancSettings.InsuPlanPrice.FlatSalesTaxAmt) ? this.insurancSettings.InsuPlanPrice.FlatSalesTaxAmt : 0));
      this.erxReq.controls["BalDue"].setValue(BalDue.toFixed(2));
      this.erxReq.controls["Copay"].patchValue(
        ((this.erxReq.value["insuranceItem"]) === "cash") ? this.erxReq.value["BalDue"] : "0.00"
      );
    this.erxReq.controls["OthAMTPaid"].setValue(
      (resp.Price ? resp.Price : 0) +  (resp.ProFee ? resp.ProFee  : 0) + (this.erxReq.value["SalesTax"] +
      ((this.insurancSettings && this.insurancSettings.InsuPlanPrice &&
        this.insurancSettings.InsuPlanPrice.FlatSalesTaxAmt) ? this.insurancSettings.InsuPlanPrice.FlatSalesTaxAmt : 0))
      );
      this.erxReq.controls["TotalAmt"].patchValue(
        ((resp.Price ? +resp.Price : 0) +  (resp.ProFee ? +resp.ProFee  : 0) + (+this.erxReq.value["OthAMTPaid"])).toFixed(2)
      );
}
ngOnChanges() {
}
ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
