import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { TabEnum, TabNames } from "./patient.constants";
import { PatientService, CommonService, MorePatientInfoService, AlertService, ControlFileService } from "src/app/services";
import { PatientStore } from "src/app/store";
import { PatientInfo, SystemData, MorePatientInfo, PatFacility, PatDiagnosis, PatAuditLog, PaginationModel } from "src/app/models";
import { Observable, Subject, forkJoin } from "rxjs";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { FormGroup, FormBuilder } from "@angular/forms";
import { CommonUtil, CreateNPatchFG, NewAuditUtils, NRxUtils } from "src/app/utils";
import { MsgConfig } from "src/app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { EligibilityComponent } from "../shared";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-patient",
    templateUrl: "./patient.component.html"
})
export class PatientComponent implements OnInit {
    @Input() patientID: any;
    @Input() FrmFamily: boolean;
    @Input() FrmErx: boolean;
    @Input() defERxPatInfo: any;
    @Input() addPatientModel: FormGroup;
    @Input() isFromDashboard : boolean;
    @Input() erxData: any;

    @Output() ClosePatientModal = new EventEmitter<any>();

    @ViewChild("UNIQUEINSURNUMBER", { static: true }) UNIQUEINSURNUMBER: any;

    @ViewChild("warning", { static: true }) warning: any;

    @ViewChild("PATALGER", { static: true }) PATALGER: any;


    patientInfo$: Observable<PatientInfo>;
    systemData$: Observable<SystemData>;
    patientInfoFG: FormGroup;
    patientInsuList$: Observable<any>;
    facilityInfo$: Observable<any>;
    insuranceData$: any;
    morePatInfo: any;
    morePatFG: FormGroup;
    patFacilityInfo: any;
    patFacilityFG: FormGroup;
    originalPatInfo: any;
    diagList: any;
    openModal: any;
    focusField: any;
    editInsuranceData: any = null;

    currTab: string = "PATIENTINFO";
    tabEnum: any;
    headerEnum: any;
    formGroupInvalid: boolean;
    popUpType: any;
    selectedTab: any;
    addFrmTab: boolean;
    originalMorePatInfo: any;
    OriginalpatFacilityInfo: any;
    resetToFirst = false;
    preferredContact: any;
    addToEditPat: boolean;
    addFromEligibility: boolean;
    resp1: any;
    originalAdmitDt: any;
    maxDate: any;
    minDate: any;
    today: any;
    isNavigateFromPatientInfoTab : boolean = false;
    isNavigateFromPatTabForRefresh : boolean = false;
    isFromEditRx: boolean;
    editedRxPrescReFillId: any;
    Phones: any;
    patientDetailsUpdates: boolean;
    unsubscribe$: Subject<void> = new Subject();
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    constructor(private _patientService: PatientService, private _patientStore: PatientStore, private _fb: FormBuilder, private _dateChecks: DateCheckUtil,
        private _commonServ: CommonService, private _patUtils: PatientInfoUtil, private _morePatSvc: MorePatientInfoService,  private _alrtServ: AlertService, private _cnpFG: CreateNPatchFG, private _modalSvc: NgbModal, private _auditUtils: NewAuditUtils,
         private _nrxUtls: NRxUtils,  private _controlFileSvc: ControlFileService , private _cdr: ChangeDetectorRef, private _cmnutil: CommonUtil) {
        this.tabEnum = TabEnum;
        this.headerEnum = TabNames;
        this.systemData$ = this._commonServ.systemData$;
        this.insuranceData$ = this._commonServ.insuranceData$;
    }


    get Person(): FormGroup {
        return this.patientInfoFG.get("Person") as FormGroup;
    }

    get InsuCards(): FormGroup {
        return this.patientInfoFG.get("InsuCards") as FormGroup;
    }

    ngOnInit() {
        this.IntialDatePatchings();
        this.patientInfoFG = this.addPatientModel ? this.addPatientModel : this._patUtils.generatePatientInfoFG();
        this.morePatFG = this._fb.group(new MorePatientInfo());
        this.patFacilityFG = this._fb.group(new PatFacility());
        this.patientInsuList$ = this._patientStore.patientInsuList$;
        this.getAndPatchPatient();
        this.patientDetailsUpdates = true;
    }
    IntialDatePatchings() {
        this.today = moment().format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
    }
    async getAndPatchPatient() {
        await this.getDiagnosis();
        await this.getPatInsurance();
        if (this.patientID) {
            this.getPatinetInfo();
        } else {
            this.getAddPatInfo();
        }
    }

    async getPatInsurance() {
        this.patientID ? await this._patUtils.getPatientInsurances(this.patientID) : this._patientStore.storePatientInsuranceList([]);
    }

    async getDiagnosis() {
        this.diagList = await this._patientService.getDiagnosisFiltered({
            Code: null, Description: null,
            pageNum: this.pageNumber, pageSize: this.pageSize
        }).toPromise();
        this._patientStore.storeDiagList(this.diagList);
    }

    async getPatinetInfo(frmTab?: any, selectTab?:boolean) {
        this.originalPatInfo = await this._patientService.getEditPatientInformation(this.patientID).toPromise();
        if (this.originalPatInfo && this.originalPatInfo["PatientDiagnosis"] && this.originalPatInfo["PatientDiagnosis"].length > 0) {
            await this.changePatDiagDetails(this.originalPatInfo["PatientDiagnosis"]);
        }
        this._patientStore.storePatientInfo(Object.assign({}, this.originalPatInfo));
        this.patientInfo$ = this._patientStore.patientInfo$;

        const patEligibility = this.patientDetailsUpdates;
        this.patientDetailsUpdates = false;
        this._cdr.detectChanges();
        this.patientDetailsUpdates = patEligibility;
        if (this.originalPatInfo && this.patientInfoFG) {
            this._patUtils.patchPatientInfo(this.originalPatInfo, this.patientInfoFG);
        }
        if (frmTab) {
            await this.getPatInsurance();
        }
        if (!frmTab) {
            this.currTab = "PATIENTINFO";
        } else if (!selectTab) {
            this.selectedPatTab(this.selectedTab);
        }
    }

    async changePatDiagDetails(values) {
        const patDiag = await this._patientService.getPatientDiagnosis(this.patientID).toPromise();
        this.originalPatInfo["PatientDiagnosis"] = values.map(val => {
            let j = new PatDiagnosis();
            const x = patDiag["TempDiagnosis"].find(v =>
                v["DIAGCODE"] === val.DiagnosisId);
            j = Object.assign({}, val, {DiagDesc: x ? x["DIAGDESCR"] : null});
            return j;
        });
    }

    getAddPatInfo() {
        if (!this.FrmErx) {
            this._patUtils.patchPatAddDefaultVal(this.patientInfoFG);
        }
        this._patientStore.storePatientInfo(this.patientInfoFG.value);
        this.patientInfo$ = this._patientStore.patientInfo$;
        this._patUtils.patchPatientInfo(this.patientInfoFG.value, this.patientInfoFG);
        this.currTab = "PATIENTINFO";
    }

    async getMorePatInfo() {
        if (!this.morePatInfo) {
            const moreInfoData = await this._patientService.getMorepatientInfo(this.patientID).toPromise();
            this.originalMorePatInfo = moreInfoData ? JSON.parse(JSON.stringify(moreInfoData)) : null;
            if (this.originalMorePatInfo && !(this.originalMorePatInfo["MorePatientInfo"])) {
                const resCatID = this.patientInfoFG && this.patientInfoFG.value.Facility &&
                this.patientInfoFG.value.Facility.PatResiCatId ?
                 this.patientInfoFG.value.Facility.PatResiCatId : null;
                const ResidenceName = moreInfoData["ResidenceCatogiries"].find(v => v["Id"] ===
                resCatID);
                const DeliveryName = moreInfoData["DeliveryModes"].find(v => v["Id"] === +(this._commonServ.getSetttingValue("RxSettings", "DEF_DELIVERY_METHOD")));

                this.originalMorePatInfo["MorePatientInfo"] = {
                    PatientId: this.patientID,
                    IsDeceased: false,
                    IsPrivacyAcked: false,
                    IsFullyInsured: true,
                    DeliveryModeId: +(this._commonServ.getSetttingValue("RxSettings", "DEF_DELIVERY_METHOD")),
                    ResidenceCatId: resCatID,
                    DeliveryMode: DeliveryName && DeliveryName.Name,
                    ResidenceCatName: ResidenceName && ResidenceName.Name ? ResidenceName.Name : null
                };
            }
            this.morePatInfo = Object.assign({}, moreInfoData);
            if (this.morePatInfo && this.morePatInfo["MorePatientInfo"]) {
                this.morePatFG.patchValue(this.morePatInfo["MorePatientInfo"]);
                this.morePatFG.markAsPristine();
            }

        }
        this.currTab = "MOREPATIENTINFO";
    }

    async getFacilityInfo() {
        if (!this.OriginalpatFacilityInfo) {
            this.OriginalpatFacilityInfo = await this._morePatSvc.getFacilityInfo(this.patientID).toPromise();
            this._patientStore.storeFacilty(Object.assign({}, this.OriginalpatFacilityInfo));
            this.facilityInfo$ = this._patientStore.patFacility$;
            this.patFacilityInfo = Object.assign({}, this.OriginalpatFacilityInfo);
            if ((this.OriginalpatFacilityInfo && this.OriginalpatFacilityInfo["PatFacility"])) {
                if (this.OriginalpatFacilityInfo && this.OriginalpatFacilityInfo["PatFacility"] && this.OriginalpatFacilityInfo["PatFacility"]["AdmitDt"]) {
                    this.originalAdmitDt = this.OriginalpatFacilityInfo &&  this.OriginalpatFacilityInfo["PatFacility"] && this.OriginalpatFacilityInfo["PatFacility"]["AdmitDt"] ? this.OriginalpatFacilityInfo["PatFacility"]["AdmitDt"] : null;
                    this.OriginalpatFacilityInfo["PatFacility"]["AdmitDt"] =
                    moment.utc(this.OriginalpatFacilityInfo["PatFacility"]["AdmitDt"]).local().format("MM/DD/YYYY");
                }
                this.patFacilityFG.patchValue(this.facilityInfo$["source"]["value"]["PatFacility"]);
                this.patFacilityFG.markAsPristine();
            } else {
                if(this.OriginalpatFacilityInfo && this.OriginalpatFacilityInfo["PatFacility"]){
                    this.OriginalpatFacilityInfo["PatFacility"] = {
                        PatientId: this.patientID,
                        IsActive: true,
                        IsCycleFillAllowed: true,
                        IsUnitDoseAllowed: false,
                        IsPrintMedSheet: false,
                        FacLocId:  0,
                        FacStationId:  0,
                    };
                }
            }
        }
        this.currTab = "FACILITYINFO";
    }

    selectedPatTab(tabName: string) {
        if (tabName === "Patient3") {
            this.getMorePatInfo();
        } else if (tabName === "Patient4") {
            this.getFacilityInfo();
        } else {
            this.currTab = this.tabEnum[tabName];
            this.editInsuranceData = null;
        }
        this.isNavigateFromPatientInfoTab = false;

    }

    closeModal(result) {
        this.ClosePatientModal.emit(result);
    }

    getpatientInsuList() {
        this._patUtils.getPatientInsurances(this.patientID);
    }

    addtnlInsurncTab(event) {
        this.editInsuranceData = event;
        this.currTab = "ADDITIONALINSURANCES";
    }

    savePreferred(value) {
        this.preferredContact = value;
    }

    addPatientFrmTab(nextId) {
        this.selectedTab = nextId;
        this.addFrmTab = true;
        this.addOrUpdatePatientInfo(true);
    }

    resetCurrTab() {
        this.selectedTab = null;
        this.addFrmTab = false;
        this.resetToFirst = false;
        setTimeout(() => {
            this.resetToFirst = true;
        }, 100);
    }

    addOrUpdatePatientInfo(frmTab?: any) {
        const patDatesFG = this._fb.group({
            BirthDt: this.Person.controls["BirthDt"].value,
            ExpiryDt: this.InsuCards.controls["ExpiryDt"].value,
            PrivacyAckDt: this.morePatFG.controls["PrivacyAckDt"].value,
            AdmitDt: this.patFacilityFG.controls["AdmitDt"].value,
            PolicyNum: this.InsuCards.controls["PolicyNum"].value,
            DriverLicExpDt : this.morePatFG.controls["DriverLicExpDt"].value
        });
        var dateChecks = [{
            control: "BirthDt",
            minDate: "",
            maxDate: this.maxDate,
            formGroup : patDatesFG,
            isRequired: true,
            fieldTab : "PATIENTINFO",
            id: "patDOB"
        },
        {
            control: "ExpiryDt",
            minDate: this.minDate,
            maxDate: "",
            formGroup : patDatesFG,
            isRequired: +(this.InsuCards.controls["submissionType"].value === 0) ? false : true,
            fieldTab : "PATIENTINFO",
            id: "primInsCardExp"
        },
        {
            control: "PrivacyAckDt",
            minDate: "",
            maxDate: this.maxDate,
            formGroup : patDatesFG,
            isRequired: this.morePatFG.value['IsPrivacyAcked']? true : false,
            fieldTab : "MOREPATIENTINFO",
            id: "PrivacyAckDtID"
        }, {
            control: "AdmitDt",
            minDate: "",
            maxDate: this.maxDate,
            formGroup : patDatesFG,
            isRequired: false,
            fieldTab : "FACILITYINFO",
            id: "AdmitDtID"
        },
        {
            control: "DriverLicExpDt",
            minDate: this.minDate,
            maxDate: "",
            formGroup : patDatesFG,
            isRequired: this.morePatFG.value['DriveLicenseNum']? true : false,
            fieldTab : "MOREPATIENTINFO",
            id: "DriverLicExpDt"
        },
        {
            control: "PolicyNum",
            minDate: "",
            maxDate: "",
            formGroup : patDatesFG,
            isRequired: ((+this.InsuCards.controls["submissionType"].value === 9) || (+this.InsuCards.controls["submissionType"].value === 0)) ? true : false,
            fieldTab : "PATIENTINFO",
            id: "primInsID"
        }, 
     ];
       /* Sentry.captureException([
            // {name: patDatesFG.value},
            {name:  this.patientInfoFG.value}
        ]);*/
         const datecCheckFields: any = this._dateChecks.checkDateValidationAtTimeofSaveAllFormGroups(dateChecks);
         if (!this.patientInfoFG.valid || !this.morePatFG.valid) {
            this.formGroupInvalid = true;
            this.Phones = this.patientInfoFG.controls["Phones"];
        if(this.Phones && this.Phones.controls.length && (this.Phones.controls[0].value.PhoneCatId == 3) && (this.Phones.controls[1].value.PhoneCatId == 2)){
            const detailsOfPhones = this.Phones.controls[0];
            this.Phones.controls[0] = this.Phones.controls[1];
            this.Phones.controls[1] = detailsOfPhones;
        }
           if (datecCheckFields) {
                this.currTab = datecCheckFields["fieldTab"];
                // this._cdr.detectChanges();
                this.focusField = this.patientInfoFG.controls['Person'].value['LastName'] ? datecCheckFields["id"] : "PatLastName";
                this._cdr.detectChanges();
            return;
           }
        } else {
            if (!this.patientInfoFG.valid|| !(this.patientInfoFG.value["InsuCarrier"]["insutypeName"] && this.patientInfoFG.value["InsuCarrier"]["insuranceIdName"])) {
                const Id =  document.getElementById("insutype");
                  if(Id){
                    Id.focus();
                }
                this.formGroupInvalid = true;
                this._cnpFG.validateAllFormFields(this.patientInfoFG);
                this.resetCurrTab();
                setTimeout(() => {
                    if (document.querySelector(".ng-invalid")) {
                        const element: any = document.querySelector(".ng-invalid");
                        (<HTMLInputElement>element).focus();
                    }
                }, 500);
                return;
            }
            this.formGroupInvalid = false;
            if (frmTab) {
                this._modalSvc.open(this.warning, { centered: true, backdrop: false, windowClass: "large--content"});
            } else {
                this.checkndAddorUpdatePatient(this.patientID ? "UPDATE" : "ADD");
            }
        }
    }
    openDiagnosis(event) {
        this.openModal = event;
    }

    async checkndAddorUpdatePatient(mode: string) {
        let isExist = null;
        this._patUtils.setDefndActvStatus(this.patientInfoFG, mode);
        this._patUtils.updateExtraPhones(this.patientInfoFG, this.preferredContact);
        let forceAllegval = +this._commonServ.getSetttingValue('PatientSettings','FORCE_ALLERGY_SELECTION');
        if(forceAllegval && !this.patientInfoFG.value["PatAllergies"]?.length && !this.patientInfoFG.value["Drugs"]?.length && !this.patientInfoFG.value["OtherAllergies"]?.length) {
            this._modalSvc.open(this.PATALGER, { centered: true, backdrop: false, windowClass: "large--content"});
        } else {
        if ((mode === "UPDATE") && !(this.originalPatInfo && this.originalPatInfo.InsuCards &&
            (this.originalPatInfo.InsuCards.PolicyNum == this.patientInfoFG.value["InsuCards"]["PolicyNum"]) &&
            this.originalPatInfo.Insurances.InsurerId == this.patientInfoFG.value["Insurances"]["InsurerId"])) {
            isExist = await this._commonServ.checkIfPolicyNoExists(this.patientInfoFG.value["InsuCards"]["PolicyNum"],
                this.patientInfoFG.value["Insurances"]["InsurerId"], this.patientInfoFG.value["InsuCards"]["Id"]).toPromise();
        } else if (mode === "ADD") {
            isExist = await this._commonServ.checkIfPolicyNoExists(this.patientInfoFG.value["InsuCards"]["PolicyNum"],
                this.patientInfoFG.value["Insurances"]["InsurerId"], this.patientInfoFG.value["InsuCards"]["Id"]).toPromise();
        }
        this.patientInfoFG.controls["Insurances"].patchValue({
            InsuGrpId: this.patientInfoFG.value["Insurances"]["InsuGrpId"] === 0 ? null
            : this.patientInfoFG.value["Insurances"]["InsuGrpId"]
        });
        if (mode === "UPDATE") {
            this.originalPatInfo = await this._patientService.getEditPatientInformation(this.patientID).toPromise();
            const newPatInfo = this.patientInfoFG.getRawValue();
            this.patientInfoFG.controls["OldInsuCardObject"].patchValue(this.originalPatInfo?.InsuCards);
            // this.resp1 = await this._patientService.updatePatientInfo(this.patientInfoFG.getRawValue()).toPromise();
            // this.afterUpdatePatient(this.resp1);
            const tabsList = ["MOREPATIENTINFO", "FACILITYINFO", "DIAGNOSISDETAILS", "NOTESLOG", "DOCUMENTS", "CLINICALINFO", "CUSTOMCATEGORY", "LIFESTYLECONCEPT"];
            
            if (!(tabsList.includes(this.currTab))) {
                this.resp1 = await this._patientService.updatePatientInfo(this.patientInfoFG.getRawValue()).toPromise();
                this.afterUpdatePatient(this.resp1);
            } else {
                await this.updateMorePatAndFacInfo();
                const patOldNewData = [this.originalPatInfo, this.patientInfoFG.getRawValue()];
                this.closeModal({ Type: "Update", PatientId: this.patientID, "PatOldNewData": patOldNewData});
            }
        } else {            
            const resp = await this._patientService.addPatientInfo(this.patientInfoFG.getRawValue()).toPromise();
            this.afterAddPatient(resp);
            if (this.FrmErx && this.erxData && this.erxData.Species === "02") {
                this.morePatFG.patchValue({
                    PatientId: resp,
                    SpeciesCdId: +this.erxData.Species,
                    PetOwnerLastName: this.erxData.PerOwnerLastName,
                    PetOwnerFirstName: this.erxData.PetOwnerFirstName,
                });
                const moreInfoData = await this._patientService.getMorepatientInfo(resp).toPromise();
                this.morePatInfo = Object.assign({}, moreInfoData);
                this.morePatInfo["MorePatientInfo"] = this.morePatFG.value;
                this._morePatSvc.updateMorePatientInfo(this.morePatInfo).toPromise();
            }
        }
      }
    }

    resetPolicyNumber() {
        this.resetCurrTab();
        const insuCardsFG: any = this.patientInfoFG.controls["InsuCards"];
        insuCardsFG.controls["PolicyNum"].setValue(null);
        setTimeout(() => {
            if (document.getElementById("primInsID")) {
                document.getElementById("primInsID").focus();
            }
        }, 500);
    }

    async afterUpdatePatient(response) {
        if (response > 0) {
            const systemData = this.systemData$["source"]["value"]; // ActiveStatusId, GenderId,  PatSettings
            const PatStatus = [{'Id': 1, 'Name': true}, {'Id': 2, 'Name': false}];
            const dropdownsData = {"Gender": systemData.Gender, "PatStatus": PatStatus}
            const prescDataModel = new PatAuditLog().patAuditModel;
            this._auditUtils.saveAuditChange(
                this.originalPatInfo,
                this.patientInfoFG.getRawValue(),
                (this.currTab === "MESSAGE" ? "MessagingInfo" : "EditPatient"),
                "Patient",
                this.patientID,
                prescDataModel,
                false,
                dropdownsData
            );
            this._alrtServ.success(MsgConfig.UPDATE_SUCCESS);
            await this.updateMorePatAndFacInfo();
            const patOldNewData = [this.originalPatInfo, this.patientInfoFG.getRawValue()];
            this.closeModal({ Type: "Update", PatientId: this.patientID, "PatOldNewData": patOldNewData});
        }
    }
    async updateMorePatAndFacInfo() {
        const updateSvc = [];
        const updateOrder = [];
        if (this.morePatFG.dirty) {
            this.morePatInfo["MorePatientInfo"] = this.morePatFG.value;
            updateSvc.push(this._morePatSvc.updateMorePatientInfo(this.morePatInfo));
            updateOrder.push("MorePat");
        }
        if (this.patFacilityFG.dirty || (this.patFacilityFG.value["AdmitDt"])) {
            this.patFacilityFG.controls["FacLocId"].patchValue(this.patFacilityFG.value["FacLocId"]
            && this.patFacilityFG.value["FacLocId"] !== 0 ? this.patFacilityFG.value["FacLocId"] : null);
            this.patFacilityFG.controls["FacStationId"].patchValue(this.patFacilityFG.value["FacStationId"]
            && this.patFacilityFG.value["FacStationId"] !== 0 ? this.patFacilityFG.value["FacStationId"] : null);
            this.patFacilityInfo["PatFacility"] = this.patFacilityFG && this.patFacilityFG.value ? this.patFacilityFG.value :null;
            updateSvc.push(this._morePatSvc.updateFacilityInfo(this.patFacilityInfo));
            updateOrder.push("Facility");
        }
        let response = null;
        if (updateSvc && updateSvc.length > 0) {
            response = await forkJoin(updateSvc).toPromise();
            await this.auditForMorePatAndFacility(updateOrder);
        }
    }

    auditForMorePatAndFacility(data) {
        data.map(val => {
            if (val === "Facility") {
                const facilityPatModel = new PatAuditLog().facilityPatModel;
                if(this.OriginalpatFacilityInfo && this.OriginalpatFacilityInfo["PatFacility"] && this.OriginalpatFacilityInfo["PatFacility"]["AdmitDt"]){
                    this.OriginalpatFacilityInfo["PatFacility"]["AdmitDt"] = this.originalAdmitDt;
                }
                this._auditUtils.saveAuditChange(
                    this.OriginalpatFacilityInfo["PatFacility"],
                    this.patFacilityInfo["PatFacility"],
                    "FacilityInfo",
                    "Patient",
                    this.patientID,
                    facilityPatModel
                );
            } else if (val === "MorePat") {
                const morePatModel = new PatAuditLog().morePatInfoModel;
                this._auditUtils.saveAuditChange(
                    this.originalMorePatInfo["MorePatientInfo"],
                    this.morePatInfo["MorePatientInfo"],
                    "MorePatientInfo",
                    "Patient",
                    this.patientID,
                    morePatModel,
                    null,
                    null,
                    null,
                    null,
                    this.morePatInfo
                );
            }
        });
    }

    async afterAddPatient(response) {
        if (response > 0) {
            const systemData = this.systemData$["source"]["value"]; // ActiveStatusId, GenderId,  PatSettings
            const PatStatus = [{'Id': 1, 'Name': true}, {'Id': 2, 'Name': false}];
            const dropdownsData = {"Gender": systemData.Gender, "PatStatus": PatStatus};
            const prescDataModel = new PatAuditLog().patAuditModel;
            this._auditUtils.saveAuditOnAdd(
                this.patientInfoFG.getRawValue(),
                "AddPatient",
                "Patient",
                response,
                prescDataModel,
                dropdownsData
            );
            this._alrtServ.success(MsgConfig.SAVE_SUCCESS);
            if (this.addFrmTab || this.addFromEligibility) {
                this.addToEditPat = true;
                this.patientID = response;
                await this.getPatinetInfo(this.addFrmTab);
                this.addFrmTab = false;
                if (this.addFromEligibility) {
                    this.openEligblPopUpModal();
                }
                this.addFromEligibility = false;
            } else {
                this.addToEditPat = false;
                this.closeModal({ Type: "Add", PatientId: response });
            }
        }
    }

    onCancel(value) {
        this.popUpType = value;
        if (value && value !== "cancel") {
            this.closeModal({ Type: this.addToEditPat ? "Update" : "Cancel", PatientId: this.patientID });
        }
    }

    checkAndOpenEligibility() {
        if (this.patientID) {
            this.openEligblPopUpModal();
        } else {
            this.addFromEligibility = true;
            this.addOrUpdatePatientInfo();
        }
    }

    openEligblPopUpModal() {
        const PersonFg: any = this.patientInfoFG && this.patientInfoFG.controls ? this.patientInfoFG.controls["Person"] : null;
        if (PersonFg && PersonFg.controls && PersonFg.controls["BirthDt"].value && !this._cmnutil.checkDateTimeFields(PersonFg.controls["BirthDt"].value)) {
            this._alrtServ.error("Please enter Valid Date of Birth(DOB)");
        } else {
            const modalRef = this._modalSvc.open(EligibilityComponent, {
                    backdrop: false,
                    size: "lg",
                    windowClass: "modal-md modal-center-to-page scroll-popup-anywhere",
                    keyboard: false,
            });
            modalRef.componentInstance.PatientInfoFG = this.patientInfoFG;
            modalRef.componentInstance.SystemData = this.systemData$["source"]["value"];
            modalRef.componentInstance.PatientInfo = this.patientInfo$["source"]["value"];
            modalRef.componentInstance.Insurances = this.insuranceData$["source"]["value"];
            modalRef.componentInstance.GetPatientInfo.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                this.getPatinetInfo();
            });
        }
    }
    closePopUpFor340BInsurance(event) {
        if(event) {
            this.isNavigateFromPatientInfoTab = true;
            this.currTab = "ADDITIONALINSURANCES";
        } else
        this.isNavigateFromPatientInfoTab = false;

    }
    exist340bInsurancePopupClose(event) {
        if(event) {
            this.isNavigateFromPatTabForRefresh = true;
         //   this.currTab = "ADDITIONALINSURANCES";
        } else
          this.isNavigateFromPatTabForRefresh = false;

    }
    
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
