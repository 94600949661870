<ng-template #PrintRxForm let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Print {{isRefReqForm ? 'Refill Request Form' : 'Rx Form'}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                Select print Option
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row" *ngIf="isRefReqForm ? selectedRx && selectedRx.length > 0 : selectedRx && selectedRx.length >= 1">
                            <div class="col-12">
                                <wj-flex-grid #printRef [headersVisibility]="'Column'" [itemsSource]="printRefWJ"
                                    class="print-ref-Wijmo" [isReadOnly]="true" [selectionMode]="'Row'"  [columnPicker]="'printRefReqWJ'" (initialized)="init(printRef)">
                                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                        [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index" [align]="'center'">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <span *ngIf="rxheader['hName'] === 'To Fax#'">
                                                {{(item[rxheader['hName']] | mask : "(000)000-00000")}}
                                            </span>
                                            <span *ngIf="!(rxheader['hName'] === 'To Fax#')">
                                                {{item[rxheader['hName']]}}
                                            </span>
                                        </ng-template>
                                    </wj-flex-grid-column>
                                </wj-flex-grid>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right" *ngIf="isRefReqForm">
                                <button type="button" class="hotkey_success" id="printreport" appShortcutKey [AltKey]="'p'" (click)="printRefReq()">
                                    <span>P</span> Print to Printer
                                </button>
                                <button type="button" class="hotkey_success" id="senderx" appShortcutKey [AltKey]="'s'" (click)="openModal('sendEfill')">
                                    <span>S</span>Send E-Refill Request
                                </button>
                                <button type="button" class="hotkey_success" id="usefax" appShortcutKey [AltKey]="'f'" (click)="openModal('faxPopUp')">
                                    <span>F</span> Use Secured Fax
                                </button>
                                <button type="button" class="hotkey_success" id="cancel" appShortcutKey [AltKey]="'c'" (click)="closeModal()">
                                    <span>C</span>  Cancel
                                </button>
                            </div>
                            <div class="col-12 text-right" *ngIf="!isRefReqForm">
                                <button type="button" class="hotkey_success" id="printreport" appShortcutKey [AltKey]="'p'" (click)="printRxForm()">
                                    <span>P</span> Print to Printer
                                </button>
                                <button type="button" class="hotkey_success" id="senderx"appShortcutKey [AltKey]="'s'" (click)="openModal('sendEfill')" >
                                    <span>S</span>Send E-Refill Request
                                </button>
                                <button type="button" class="hotkey_success" id="usefax" appShortcutKey [AltKey]="'f'" (click)="openModal('faxPopUp')" >
                                    <span>F</span> Use Secured Fax
                                </button>
                                <button type="button" class="hotkey_success" id="cancel" appShortcutKey [AltKey]="'c'" (click)="closeModal()">
                                    <span>C</span> Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                Already Batched Rxs
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <span *ngIf="faxRecords?.length > 0;else noRecords">
                            <wj-flex-grid #batchedRxss [headersVisibility]="'Column'" [itemsSource]="prepareBatchedRxsWj"
                            class="print-batch-rx-Wijmo" [isReadOnly]="true" [columnPicker]="'printbatchedRxsRefReqWJ'" (initialized)="initBatchedRxss(batchedRxss)">
                            <wj-flex-grid-column [header]="rxBatchheader['hName']" [binding]="rxBatchheader['hName']" [visible]="rxBatchheader['isVisible']"
                                        [width]="rxBatchheader['width']" *ngFor="let rxBatchheader of wjHeadersForprepareBatchedRxs; let i = index" [align]="'center'">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <span *ngIf="rxBatchheader['hName'] === 'To Fax#'">
                                                {{item[rxBatchheader['hName']] ? (item[rxBatchheader['hName']] | mask : "(000)000-00000") : ""}}
                                            </span>
                                            <span *ngIf="!(rxBatchheader['hName'] === 'To Fax#')">
                                                {{item[rxBatchheader['hName']]}}
                                            </span>
                                        </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                        </span>
                        <ng-template #noRecords>
                            <div class="eprx--block__footer">
                                <div>
                                    <span></span>
                                    <i class="fa fa-file"></i><br/>
                                    No Data Found!
                                </div>
                            </div>
                            
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button class="hotkey_primary" (click)="closeModal()" appShortcutKey [CtrlKey]="'o'">
            <span>O</span> OK
        </button>
    </div> -->
</ng-template>

<app-send-efill-request *ngIf="activeModal === 'sendEfill'" [FaxInfo]="printRefWJ" [selectedRx]="selectedRx"  [RxInfo]="rxInfo"  (IsPopUpClosed)="closeSendRefillReq()"></app-send-efill-request>
<app-print-fax-request *ngIf="activeModal === 'faxPopUp'" [FaxInfo]="printRefWJ" [RxInfo]="selectedRx" (IsPopUpClosed)="activeModal = null;closeModal()"> </app-print-fax-request>

<app-override-log
    *ngIf="openOverride"
    [OverrideEndpoint]="[overrideEndpoint]"
    [PrivMask]="privType"
    [CatId]="categoryId"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>

<ng-template #addToQueu let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close');openRefillQueuConfirm()">
        <h4 header>Follow Up</h4>
        <span body>
            <p>Rx#: <label for=""> {{currentQueConfrm.Prescription.PrescNum}} -
                    {{currentQueConfrm.PrescReFill.ReFillNum}}</label></p>
            <p>Do you want to add this Refill Request in Followup Log?</p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="c('close');addToRefillQue()"
            appShortcutKey InputKey="y"><b>Y</b> Yes</button>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="c('close');openRefillQueuConfirm()"
            appShortcutKey InputKey="n"><b>N</b>NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #ALREADYVERIFIED let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal1(true)">
        <h4 header>Confirmation</h4>
        <span body>
            The Refill request for RX# : <b>{{ rxInfo.Prescription.PrescNum }}</b> is
            already sent before. Would you like to send again anyway?
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="chekcCompundDrug()"
            appShortcutKey
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            footer
            class="hotkey_primary"
            (click)="closeModal1(true)"
            appShortcutKey
            InputKey="n"
        >
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #DAWVALID let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal1(true)">
        <h4 header>Warning!!</h4>
        <span body>
            Only Valid Values for DAW are (NO DAW(0)) or (Physician DAW(1)).<br />Please
            change DAW for this Rx# : <b>{{ rxInfo.Prescription.PrescNum }}</b>
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="closeModal1(true)"
            appShortcutKey
            InputKey="o"
        >
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #SPINOMODAL let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal1(true)">
        <h4 header>Confirmation</h4>
        <span body>
            Prescriber : {{ rxInfo.Prescriber.prescriberid }} has no SPI
            (SureScript) number assigned. Would you like to update SPI?
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="openPopUps(ADDSPI)"
            appShortcutKey
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            footer
            class="hotkey_primary"
            (click)="closeModal1(true)"
            appShortcutKey
            InputKey="n"
        >
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #ADDSPI let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal1(true)">
        <h4 header>Update SPI</h4>
        <div body class="row">
            <div class="col-md-12">
                <label> Prescriber Name</label>
                <div class="label--data">
                    {{
                        rxInfo?.Prescriber?.prescriberlastname +
                            ", " +
                            rxInfo?.Prescriber?.prescriberfirstname
                    }}
                </div>
            </div>
            <div class="col-md-12">
                <eprx-input
                    [LabelText]="'SPI Number'"
                    [PlaceHolder]="'SPI Number'"
                    [MaxLength]="13"
                    [HasControl]="false"
                    [DebounceTime]="1000"
                    [AutoFocus]="true"
                    [ValidPattern]="regex?.spiRegex"
                                [ErrorDefs]="{
                                                pattern: 'please enter 13 digits'
                                            }"
                    (TriggerChangeValue)="changeSpiNumber($event)"
                >
                </eprx-input>
            </div>
        </div>
        <button
            ngbAutofocus
            footer
            class="hotkey_success"
            appShortcutKey
            [AltKey]="'s'"
            (click)="saveSPI()"
        >
            <span>S</span> Save
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #MISSINGFIELDS let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 header>
            Please Select Missing Values
            {{
                drugMissingForm.value.DrugName
                    ? "for " + drugMissingForm.value.DrugName
                    : ""
            }}
        </h4>
        <button
            header
            type="button"
            [tabindex]="-1"
            class="close"
            aria-label="Close"
            (click)="closeModal1(true)"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="showDrugDescription">
                <eprx-select
                    [LabelText]="'Dosage Form Description'"
                    [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmDescId'"
                    [AutoFocus]="true"
                    [ShowLabel2]="true"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DoseFrmDesc"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['DoseFrmDescId']?.errors
                    "
                    [IsRequired]="false"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Dosage Form Code'"
                    [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmCodeId'"
                    [AutoFocus]="true"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DoseFrmCode"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['DoseFrmCodeId']?.errors
                    "
                    [IsRequired]="showDrugDescription"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Drug Strength Qualifier'"
                    [PlaceHolder]="'Drug Strength Qualifier'"
                    [ControlName]="'DrugStrQualId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DrugStrQual"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['DrugStrQualId']?.errors
                    "
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Potency Unit Code'"
                    [PlaceHolder]="'Potency Unit Code'"
                    [ControlName]="'PotencyUnitCodeId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.PotencyUnitCode"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['PotencyUnitCodeId']?.errors
                    "
                    [IsRequired]="true"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Quantity Qualifier'"
                    [PlaceHolder]="'Quantity Qualifier'"
                    [ControlName]="'QtyQualId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.QtyQual"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['QtyQualId']?.errors
                    "
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="hotkey_success"
            appShortcutKey
            [AltKey]="'s'"
            (click)="saveDrugFields()"
        >
            <span>S</span> Save
        </button>
        <button
            class="hotkey_success"
            appShortcutKey
            [AltKey]="'c'"
            (click)="closeModal1(true)"
        >
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>
