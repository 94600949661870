<ng-template #DURADD let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">DUR Entry</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body box-body" [formGroup]="DurFG">
        <div class="box box-default box-solid">
            <div class="eprx--block__header">
                <span class="eprx--header__heading"></span>
            </div>
            <div class="box-body">
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <app-sub-dur [DurFG]="DurFG" [Defaultval]="systemData?.servReason" [InputErrors]="ServReasonId?.errors"
                            [FormGroupInvalid]="formGroupInvalid"></app-sub-dur>
                    </div>
                    <div class="col-6">
                        <eprx-select [LabelText]="'Professional Service'" [PlaceHolder]="''" [ControlName]="'ProfServId'"
                            [FormGroupName]="DurFG" [secondCntrlN]="'ProfServName'" [bindScnCntrlV]="'Name'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.profServs" [InputErrors]="ProfServId?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <eprx-select [LabelText]="'Result Of Service'" [PlaceHolder]="''" [ControlName]="'ServResultId'"
                            [FormGroupName]="DurFG" [secondCntrlN]="'ServResult'" [bindScnCntrlV]="'Name'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.servResult" [InputErrors]="ServResultId?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-6">
                        <eprx-select [LabelText]="'Level Of Effort'" [PlaceHolder]="''" [ControlName]="'EffortLvlId'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [secondCntrlN]="'EffortLvlName'" [bindScnCntrlV]="'Name'"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.effortLvl" [InputErrors]="EffortLvlId?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <eprx-input [LabelText]="'Co-Agent ID'" [PlaceHolder]="''" [ControlName]="'CoAgentNum'" [MaxLength]="19"
                            [InputType]="'ALPHANUMERIC'" [FormGroupName]="DurFG"></eprx-input>
                    </div>
                    <div class="col-6">
                        <eprx-select [LabelText]="'Co-Agent ID Type'" [PlaceHolder]="''" [ControlName]="'CoAgentIdType'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                            [BindValue]="'Name'" [LabelForId]="'Id'" [List]="systemData?.coAgentIdCat"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid"
                            [IsRequired]="DurFG?.value['CoAgentNum'] !== null"
                            [MarkAsTouched]="DurFG?.controls['CoAgentIdType']?.touched" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <eprx-select [LabelText]="'Clinical Significance'" [PlaceHolder]="''" [ControlName]="'ClinicalSignfId'"
                            [FormGroupName]="DurFG" [secondCntrlN]="'ClinicalSignfName'" [bindScnCntrlV]="'Name'" [IsTitle]="true"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.clinicalSignf" [InputErrors]="ClinicalSignfId?.errors"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true"
                            [show2ValInInputhidden]="true">
                        </eprx-select>
                    </div>
                    <div class="col-6" [formGroup]="DurFG">
                        <label class="align-label"> Pharmacist Comment </label>
                        <textarea class="custom--text__area" rows="1" cols="73" formControlName="PhComment"
                            placeholder="Comments"></textarea>
                    </div>

                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success"
                (click)="addDUR()" (keydown.enter)="addDUR()" autofocus appShortcutKey [AltKey]="'s'">
                    <span>S</span>    Save
                </button>
                <button class="hotkey_success"(keydown.enter)="closeModal()" (click)="closeModal()" appShortcutKey [AltKey]="'c'">
                   <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #info let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Please Correct the Information</h4>
        <button type="button"  [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>Unable to save empty record, please specify value for atleast 1 field.</span>
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" (click)="d('Cross click')" (keydown.enter)="enterKeyDownEv($event, 'Info')" autofocus appShortcutKey InputKey="o"><b>O</b>OK</button>
</div>
</ng-template>
