import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotesLogService } from 'src/app/services';

@Component({
  selector: 'app-common-notes-log',
  templateUrl: './common-notes-log.component.html',
  styleUrls: ['./common-notes-log.component.scss']
})
export class CommonNotesLogComponent implements OnInit {
  @ViewChild("RxNotesLogListPopUp", { static : true })
  RxNotesLogListPopUp : any;
  modelRefRph : any;
  notesList: any;

  @Input()
    set NotesListFrmPage(list: any) {
      console.log(list , "listlist")
        this.notesList = list;
    }


  @Input() IsFromeRx: boolean;
  @Input() HeaderText: boolean;
  @Input() IsFromPage: string;
  @Input() RxType: any;
  
  
  
  @Output() IsNotesLogPopupClosed = new EventEmitter<any>();

  constructor(private _modalService: NgbModal, private _notesLog :NotesLogService) { }

  ngOnInit(): void {
    this.modelRefRph = this._modalService.open(this.RxNotesLogListPopUp, {keyboard : false, backdrop: false, centered : true , windowClass: "max-modal-1070"})
  }
  closeModal(type?){
    if(this.modelRefRph){
      this.modelRefRph.close();
      this.IsNotesLogPopupClosed.emit(type ? type : true);
    }
  }
async changeStatus(notesItem, isChecked, noteType) {
    await this._notesLog.updateNotesStatus(notesItem.Id, isChecked, noteType, this.RxType).toPromise();
}
ngOnChanges(changes?: any): void {
}
ngAfterViewInit(): void {
}
}
