<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Price Update'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                    <button id="SaveButton" class="hotkey_success" (click)="routeBackToPrevScreen()"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>

    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                <span>Drug Price Update</span>
            </div>
        </div>
        <div class="eprx--block__content">
            <!-- <div class="col-sm-12 padding-0"  *ngIf="hideFilterForm">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="align-label"> From Date </label>
                        <span> : {{fromDate | date:'MM/dd/yyyy'}}</span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="align-label">To Date</label>
                        <span> : {{toDate | date:'MM/dd/yyyy'}}</span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                        <button class="hotkey_success pull-right" (click)="show()">  Edit Filters
                        </button>
                    </div>
                </div>
            </div> -->
            <form [formGroup]="drugPriceFG" (ngSubmit)="filter()">
            <div class="col-sm-12 padding-0 erx--body">
                    <div class="row">

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <eprx-date-picker [LabelText]="'From Date'" [PlaceHolder]="''" [ControlName]="'FromDateTime'" [FormGroupName]="drugPriceFG"
                            [AutoFocus]="true"  [MinDate]="" [MaxDate]="" [IsRequired]="true">
                            </eprx-date-picker>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <eprx-date-picker [LabelText]="'To Date'" [PlaceHolder]="''" [ControlName]="'ToDateTime'" [FormGroupName]="drugPriceFG" [MinDate]=""
                                [MaxDate]="" [IsRequired]="true">
                            </eprx-date-picker>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-button text-right allign-bottom">
                            <button type="submit" class="hotkey_success pull-right"  (click)="filter()">Search</button>
                            <button type="button" class="hotkey_success pull-right" (keydown.tab)="focusToFirst($event)" (click)="show()">clear
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="eprx--block__content" *ngIf="hasContent">
            <wj-flex-grid #priceUpdateList [headersVisibility]="'Column'" [itemsSource]="drugPUWJ" [isReadOnly]="true" [columnPicker]="''"
                [selectionMode]="'None'" (updatedView)="init(priceUpdateList)">
                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders  ; let i = index"
                    [width]="'*'" [format]="(actvHeader === 'New Price') || (actvHeader === 'Old Price') ?'c2' : ''">
                </wj-flex-grid-column>
            </wj-flex-grid>
            <app-eprx-pagination [TotalCount]="totalCount" [PageSize]="Size" [WijmoName]="priceUpdateList" [GridName]="'priceUpdateList'" (OnPageSelected)="setPage($event)"
                (OnSizeSelected)="setSize(Size)"></app-eprx-pagination>
        </div>
        <div *ngIf="!hasContent" class="eprx--block__footer">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
    </div>
</div>
