<div
    id="printAcceptRej"
   class="overflow_x_hidden font_size_22_px position-absolute top_negetive_99999_px left_ngtv_9999_px"
>
    <div id="contentToConvert2" class="eprx--block__content">
        <div class="row padding_1_428_rem">
            <div class="col-md-12 padding_0_714_rem border_1px_solid">
                <div class="row text-center">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <b>{{
                            transmResp?.ClaimProcess?.objPharmacy?.sPharmcyName
                                ? (transmResp?.ClaimProcess?.objPharmacy
                                      ?.sPharmcyName | uppercase)
                                : "--"
                        }}</b>
                    </div>
                    <div class="col-md-4">
                        <b>{{ currentDate | localTime | date: "MM/dd/yyyy , h:mm:ss a" }}</b>
                    </div>
                    <div class="col-md-12">
                        <b
                            >{{
                                transmResp?.ClaimProcess?.objPharmacy?.Address
                                    ? (transmResp?.ClaimProcess?.objPharmacy
                                          ?.Address + "," | uppercase)
                                    : ""
                            }}
                            {{
                                transmResp?.ClaimProcess?.objPharmacy?.CityName
                                    ? (transmResp?.ClaimProcess?.objPharmacy
                                          ?.CityName + "," | uppercase)
                                    : ""
                            }}
                            {{
                                transmResp?.ClaimProcess?.objPharmacy?.StateName
                                    ? (transmResp?.ClaimProcess?.objPharmacy
                                          ?.StateName + "," | uppercase)
                                    : ""
                            }}
                            {{
                                transmResp?.ClaimProcess?.objPharmacy?.Zipcode
                                    ? (transmResp?.ClaimProcess?.objPharmacy
                                          ?.Zipcode + "," | uppercase)
                                    : ""
                            }}
                        </b>
                    </div>
                    <div class="col-md-12">
                        <b
                            >Phone : {{ pharmPhone ? pharmPhone : "--" }} Fax :
                            {{ pharmFax ? pharmFax : "--" }}</b
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <hr />
                    </div>
                </div>
                <div class="row padding_1_428_rem">
                    <hr />
                    <h2
                        class="col-md-12 padding-0 border_bottom_3px_solid"
                    >
                        <span
                            *ngIf="
                                transmResp?.transmissionDetails?.ResponseStatus === 'P' ||
                                transmResp?.transmissionDetails?.ResponseStatus === 'C' ||
                                transmResp?.transmissionDetails?.ResponseStatus === 'D' ||
                                transmResp?.transmissionDetails?.ResponseStatus === 'S' ||
                                transmResp?.transmissionDetails?.ResponseStatus === 'A'
                            "
                            >Transaction Accepted</span
                        >
                        <span
                            *ngIf="
                                transmResp?.transmissionDetails
                                    ?.ResponseStatus === 'R' ||
                                transmResp?.transmissionDetails
                                    ?.ResponseStatus === 'TRANSMISSION ERROR'
                            "
                            >Transaction Rejected</span
                        >
                    </h2>
                    <div class="col-md-12 border_bottom_3px_solid">
                        <div class="row">
                            <div class="col-md-2">Patient Name:</div>
                            <div class="col-md-5 font-weight-bold">
                                {{
                                    transmResp?.ClaimProcess?.objPatient
                                        ?.LastName | uppercase
                                }}
                                ,&nbsp;{{
                                    transmResp?.ClaimProcess?.objPatient
                                        ?.FirstName | uppercase
                                }}
                                , DOB :
                                {{
                                    transmResp?.ClaimProcess?.objPatient
                                        ?.DateOfBirth | date: "MM/dd/yyyy"
                                }}
                                , Phone# :
                                {{
                                    transmResp?.ClaimProcess?.objPatient?.Phone
                                }}
                            </div>
                            <div class="col-md-2">Rx#:</div>
                            <div class="col-md-3 font-weight-bold">
                                {{ transmResp?.transmissionDetails?.RXNo }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">Patient Gender:</div>
                            <div class="col-md-5 font-weight-bold">
                                {{ transmResp?.ClaimProcess?.objPatient?.Sex }}
                            </div>
                            <div class="col-md-2">Date:</div>
                            <div class="col-md-3 font-weight-bold">
                                {{ transmResp?.transmissionDetails?.FillDate }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">Patient Address:</div>
                            <div class="col-md-5">
                                {{
                                    transmResp?.transmissionDetails
                                        ?.PatientAddress | uppercase
                                }}
                            </div>
                            <div class="col-md-2">Ins. Info:</div>
                            <div class="col-md-3 font-weight-bold">
                                Ins. Id :
                                {{ transmResp?.transmissionDetails?.PolicyNum | uppercase}}
                                , Grp. No :
                                {{ transmResp?.transmissionDetails?.GrpNo | uppercase}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">Drug Name:</div>
                            <div class="col-md-5 font-weight-bold">
                                {{ transmResp?.transmissionDetails?.DrugName }},
                                NDC : {{ transmResp?.ClaimProcess?.NDC }}
                            </div>
                            <div class="col-md-2">Billed To:</div>
                            <div class="col-md-3 font-weight-bold">
                                {{ transmResp?.transmissionDetails?.InsType }}
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-5"></div>
                            <div class="col-md-2"></div>
                            <div class="col-md-3" style="font-weight: bold">
                               Bin: {{ transmResp?.transmissionDetails?.InsType }}
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-2">Prescriber:</div>
                            <div class="col-md-10">
                                {{
                                    transmResp?.transmissionDetails
                                        ?.PrescriberName |uppercase
                                }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">Prescriber Address:</div>
                            <div class="col-md-10">
                                {{
                                    transmResp?.transmissionDetails
                                        ?.PrescribeAddress
                                }}
                            </div>
                        </div>
                        <div
                            class="row margin_top_3rem"
                            *ngIf="
                                transcRespStatus === 'Paid'
                            "
                        >
                            <div class="col-md-4">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Ins</th>
                                            <th scope="col" class="text-right">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                {{
                                                    transmResp
                                                        ?.transmissionDetails
                                                        ?.InsType
                                                }}
                                            </th>
                                            <td class="text-right">
                                                {{
                                                    transmResp
                                                        ?.transmissionDetails
                                                        ?.TotalAmount
                                                        | currency
                                                            : "USD"
                                                            : "symbol-narrow"
                                                            : "1.2-2"
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                Pat.Resp.
                                            </th>
                                            <td class="text-right">
                                                {{
                                                    transmResp
                                                        ?.transmissionDetails
                                                        ?.Deductible
                                                        | currency
                                                            : "USD"
                                                            : "symbol-narrow"
                                                            : "1.2-2"
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                Total
                                            </th>
                                            <td class="text-right">
                                                {{
                                                    transmResp
                                                        ?.transmissionDetails
                                                        ?.Deductible +
                                                        transmResp
                                                            ?.transmissionDetails
                                                            ?.TotalAmount
                                                        | currency
                                                            : "USD"
                                                            : "symbol-narrow"
                                                            : "1.2-2"
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 margin_top_3rem" 
                     *ngIf="!(transmissionType === 'TransReverse' || (transmitParams && transmitParams['TransType'] === 'B2'))">
                        <h3>DUR:</h3>
                        <table class="table" *ngIf="durData && durData.length">
                            <thead>
                                <tr>
                                    <th scope="col-3">Reason</th>
                                    <th scope="col-1">Significance</th>
                                    <th scope="col-1">Other.PH</th>
                                    <th scope="col-1">Prv.Fill</th>
                                    <th scope="col-1">Fill Qty</th>
                                    <th scope="col-1">DB</th>
                                    <th scope="col-1">Other Pres.</th>
                                    <th scope="col-3">Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dur of durData; let i=index;">
                                    <td scope="col-3">{{dur?.Reason}}</td>
                                    <td scope="col-1">{{dur?.Significance}}</td>
                                    <td scope="col-1">{{dur["Other.PH"]}}</td>
                                    <td scope="col-1">{{dur["Prv.Fill"]}}</td>
                                    <td scope="col-1">{{dur["Fill Qty"]}}</td>
                                    <td scope="col-1">{{dur?.DB}}</td>
                                    <td scope="col-1">{{dur["Other Pres."]}}</td>
                                    <td scope="col-3">{{dur?.Comment}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12 margin_top_3rem">
                        <h3>Messages: </h3>
                        <span>
                            {{
                                (transmResp && transmResp?.nCPDPRecv && transmResp?.nCPDPRecv?.MessageFQ) ? transmResp?.nCPDPRecv?.MessageFQ.toString().replace(".", "")
                                : !(transmResp?.transmissionDetails?.ResponseStatus === 'R' ||
                            transmResp?.transmissionDetails?.ResponseStatus ==='TRANSMISSION ERROR') ? transmResp?.ClaimProcess?.objncpdRecv?.MessageFQ ?
                            transmResp?.ClaimProcess?.objncpdRecv?.MessageFQ.toString().replace(".", "") : "" : ""
                            }}
                            <span *ngIf="(transmissionType === 'TransReverse' || (transmitParams && transmitParams['TransType'] === 'B2'))">
                                {{
                                    (transmResp && transmResp?.nCPDPRecv && transmResp?.nCPDPRecv?.MessageF4) ? transmResp?.nCPDPRecv?.MessageF4.toString().replace(".", "")
                                    : !(transmResp?.transmissionDetails?.ResponseStatus === 'R' ||
                                transmResp?.transmissionDetails?.ResponseStatus ==='TRANSMISSION ERROR') ? transmResp?.ClaimProcess?.objncpdRecv?.MessageF4 ?
                                transmResp?.ClaimProcess?.objncpdRecv?.MessageF4.toString().replace(".", "") : "" : ""
                                }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
