<ng-template #userDefined let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeCategoryPopup(false)">
        <h4 header> Drug Categories</h4>
        <span body>
            <div class="eprx--block__content">
                <div class="col-sm-12 padding-0">
                    <div class="row">
                        <div class="col-5">
                            <eprx-input [LabelText]="'Category Name'" [PlaceHolder]="'Category Name'" [ControlName]="'categoryName'" [FormGroupName]="drugCategoryFromgroup"
                                [Title]="'Category Name'" (TriggerOnEnterValue)="searchDrugCat()"
                                >
                            </eprx-input>
                        </div>
                        <div class="col-lg-7 text-right mt-3 ">
                            <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" (click)="searchDrugCat()" appShortcutKey [AltKey]="'f'">
                                <span>F</span>
                                Search
                            </button>
                            <button type="button"  class="hotkey_success pull-right" (click)="searchDrugCat(true)" appShortcutKey [AltKey]="'l'">
                                <span>L</span>
                                clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
                <wj-flex-grid #workersGrid1 class="wjm-grd-custom-height-56"
                [itemsSource]="drugCatList"
                [headersVisibility]="'Column'"
                [autoGenerateColumns]=false>
                <wj-flex-grid-column [binding]="'Select'" [width]="60">
                    <ng-template wjFlexGridCellTemplate #dp="wjFlexGridCellTemplate" [cellType]="'Cell'" let-item="item" let-cell="cell">
                        <div  class="text-center">
                            <input class="form-check-input" type="checkbox" [id]="item.RowId" name="" [(ngModel)]="item.Select"
                                [checked]="item.Select" (click)="selectedRecords($event?.target?.checked, dp, item['Category ID'])" />
                            <label class="form-check-label" [for]="item.RowId"></label>
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [binding]="'Category ID'"  [width]="'*'" [header]="'Category ID'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                        <div>
                            {{item["Category ID"]}}
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [binding]="'Category Name'" [width]="'2*'" [header]="'Category Name'"  [allowSorting]="false"></wj-flex-grid-column>
            </wj-flex-grid>
            <app-eprx-pagination  [TotalCount]="totalCount" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'DrugCategrysGrid'"
            [WijmoName]="DrugCategories" (OnFocusOut)="focusToFirst($event)" [PageNumber]="pageNumber" [PageSize]="pageSize"></app-eprx-pagination>
    <div class="eprx--block__content text-center" *ngIf="!totalCount">
    <div class="text-center" >
        <h3> No data to display!</h3>
    </div>
    </div>
        </span>
        <button
        ngbAutofocus
        footer
        class="hotkey_success"
        appShortcutKey
        (click)="closeCategoryPopup(true)"
        appShortcutKey
        [AltKey]="'s'"
    >
        <span>S</span> Save
    </button>
        <button
            ngbAutofocus
            footer
            class="hotkey_success"
            appShortcutKey
            (click)="closeCategoryPopup(false)"
            appShortcutKey
            [AltKey]="'c'"
        >
            <span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template>
