import { constant } from "./../app.constants";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IpConfig } from "../models";

@Injectable({
    providedIn: "root"
})
export class SettingsDataService {
    constructor(private _http: HttpClient) {}

    EditPharmacyandTenantDetails(data: any) {
        return this._http.put(constant.PUT_EditPharmacyandTenantDetails, data);
    }

    createnewTenant(data: any) {
        return this._http.post(constant.POST_CreateNewTenat, data);
    }

    editNewTenant(data: any) {
        return this._http.put(constant.PUT_NewTenantInfo, data);
    }

    GetIpConfigs(): Observable<IpConfig[]> {
        return this._http.get<IpConfig[]>(constant.GET_IPConfig_GetIP);
    }

    AddIPConfig(ipData: IpConfig): Observable<any> {
        return this._http.post<IpConfig>(constant.POST_IPConfig_AddIP, ipData);
    }

    ChangeIpConfigStatus(id: any, status: boolean): Observable<any> {
        return this._http.delete<any>(
            constant.GET_IPConfig_StatusChange.replace("{Id}", "" + id).replace(
                "{status}",
                "" + status
            ),
            {}
        );
    }

    generatePrimeEsc(data: any) {
        return this._http.post(constant.POST_PrimeESCToken, data);
    }

    getPrimeEsc() {
        return this._http.get(constant.GET_PrimeESC);
    }

    updatePrimeESC(tokenID, activestatus) {
        return this._http.put(constant.PUT_Update_Status.replace("{tokenId}", "" + tokenID)
                .replace("{activestatus}", "" + activestatus), null);
    }

    getAccountSettings(): Observable<any> {
        return this._http.post<any>(constant.POST_Account_Settings, {});
    }

    getSystemSettings(params):Observable<any> {
        return this._http.post<any>(constant.GET_System_Settings,params);
    }
    getMessageCategorys() {
        return this._http.get<any>(constant.GET_MESSAGE_DATA);
    }

    AddOrEditSMSTemplate(data: any) {
        return this._http.post(constant.POST_MESSAGE_SMSTEmplate, data);
    }

    AddOrEditMessagePreference(data: any) {
        return this._http.post(constant.POST_MESSAGE_PREFERENCE, data);
    }

    getSmsEmailTemplateList(messageTypeId: any) {
        return this._http.get(constant.GET_MESSAGE_TEMPLATEDATA.replace("{messageTypeId}", "" + messageTypeId));
    }

    sendSMSData(data: any) {
        return this._http.post(constant.POST_SMS_DATA, data);
    }

    GetRefillReminderTemplateData(value: any): any {
        return this._http.get(constant.GET_MESSAGE_TEMPLATEDATA_BY_ID.replace("{messageTemplateId}", "" + value));
    }

    sendSMSDataForSub(data: any) {
        return this._http.post(constant.POST_MESSAGE_ReSendSubscription, data);
    }
}
