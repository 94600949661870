<div class="modal-header">
        <h4 class="modal-title">Search</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="row">
                    <div class="col">
                        <table class="table table-hover">
                            <tbody>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--right__7">
                                    <eprx-select  #select [RxSelectId]="'select'" [AutoFocus]="true" [LabelText]="'NCPDP Payer Set'" [PlaceHolder]="'NCPDP Payer Set'"
                                     [ControlName]="'NCDPId'" [FormGroupName]="copyInfoFG"  [InputErrors]="NCDPId?.errors"
                                     [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [IsRequired]="true"
                                        [HasMultiple]="false" [Title]="'NCPDP Payer Set'" [HasBindLabel2]="true" [BindLabel]="'Code'"
                                        [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                            transsetingsDropValues
                                                ? transsetingsDropValues.NCPDP
                                                : ''
                                        ">
                                    </eprx-select>
                                </div>
                            </tbody>
                        </table>
                    </div>
                </div>
    </div>
    <div class="modal-footer ">
        <div class="text-right float-right ">
                <button type="button" class="hotkey_success"  (click)="copy()" appShortcutKey [AltKey]="'o'">
                      <span>O</span>  OK
                    </button>
                    <button type="button" class="hotkey_success" (click)="activeModal.dismiss('Cross click')" appShortcutKey [AltKey]="'c'">
                      <span>C</span>  CANCEL
                    </button>
        </div>
    </div>
