export enum SharedDocuments {
    Patient = 1,
    Prescriber = 2,
    Drug = 3,
    Insurance = 4,
    Prescription = 5
}

export enum RxOriginEnum {
    NotKnown = 1,
    Written = 2,
    Telephone = 3,
    Electronic = 4,
    Facsimile = 5,
    Pharmacy = 6
}

export enum eligibilityEnum{
    troopInsuranceId = 449050,
    CommercialPolicyNum = 987654321,
}

export enum resolveErrorEnum {
    ALL="All",
    Unresolved = "Unresolved",
    Resolved = "Resolved"
}

export enum MultiMedPackagingTypes {
    None =  23,
    HOA =  24,
    BubblePackaging =   138

}