export class Documents {
    PatientId: number = null;
    DrugId: number = null;
    PrescId: number = null;
    InsuranceId: number = null;
    RequestFrom: string = null;
    Id: number = null;
    TenantId: number = null;
    DocCatId: number = null;
    DocSubCatId: number = null;
    DocSubCatIdForDefault: number = null;
    DocNum: number = null;
    Name: string = null;
    FileName: string = null;
    Remarks: string = null;
    Base64String: string = null;
    Extn: string = null;
    IsActive: boolean = true;
    PrescNum: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    IsBase64Required: boolean = false;
    IsControlFile: boolean = false;
    DocSourceId: number = null;
    DocFileName: string = null;
    isRotate: boolean = false;
    degree : any  = 0;
    RefillNum: any = null;
    FillNum: any = null;
    PrintWithLabel: any = false;
    PharmacyNPI : any = null;
    DocumentIds: any = [];
}

export class DocumentInputParams {
    Id: number = null;
    DocCatId: number = null;
    DocSubCatId: number = null;
    DocSubCatName: any = null;
    PatientId: number = null;
    DrugId: number = null;
    InsuranceId: number = null;
    PrescNum: number = null;
    RefillNum: number = null;
    PrescId: number = null;
    FileName: string = null;
    Remarks: string = null;
    IsBase64Required: boolean = false;
    IsControlFile: boolean = false;
    FillNum: any = null;
}
