import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Pipe({ name: "getFormName" })
export class CustmFG implements PipeTransform {
    transform(name: string, rxStngInfoFG, control: string): any {
        let data: FormGroup;
        if (rxStngInfoFG && rxStngInfoFG.controls[control]) {
            const rxFG: any = rxStngInfoFG.controls[control] as FormGroup;
            data = rxFG?.controls?.findIndex((fg: FormGroup, i) =>   fg.value.Key === name );
        }
        return data;
    }
}

