import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService, ControlFileService } from "src/app/services";

@Component({
    selector: "app-cntrl-file-resub",
    templateUrl: "./cntrl-file-resub.component.html"
})
export class CntrlFileResubComponent implements OnInit {
    modelRef: any;
    reqToResubmit: any;
    patientName: any;
    frmPatFile: boolean;
    modalRef: any;
    unsubscribe$: Subject<void> = new Subject();
    frmPrescFile: boolean;
    prescriberName: any;
    @Input() rxInfo: any;

    @Input()
    set ReqToResubmit(ri: any) {
        if (ri) {
            this.reqToResubmit = ri;
        }
    }
    @Input() patName: any;
    @Input()
    set fromPatFile(val: boolean) {
        if (val) {
            this.frmPatFile = val;
            this.patientName = this.patName;
        }
    }
    @Input() prescName: any;
    @Input()
    set fromPrescFile(val: boolean) {
        if (val) {
            this.frmPrescFile = val;
            this.prescriberName = this.prescName;
        }
    }
    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("PatientResubmit", { static: true })
    PatientResubmit: any;

    @ViewChild("PrescriberResubmit", { static: true })
    PrescriberResubmit: any;

    constructor(private _ctrlFileSvc: ControlFileService, private _modalSvc: NgbModal, public _commonServ: CommonService) {}

    ngOnInit() {
        if (this.frmPatFile) {
            this.modalRef = this._modalSvc.open(this.PatientResubmit, {
                windowClass: "large--content", backdrop: false, centered: true
            });
        }
        if (this.frmPrescFile) {
            this.modalRef = this._modalSvc.open(this.PrescriberResubmit, {
                windowClass: "large--content", backdrop: false, centered: true
            });
        }
        if(!this.frmPatFile && !this.frmPrescFile){
            this.reSubmitToStatesForCntrlFile();
        }
        
    }

    reSubmitToStatesForCntrlFile() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.closeModal(true);
        this._ctrlFileSvc
            .resubmitControlFileInfo(this.reqToResubmit)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => this.IsPopUpClosed.emit());
    }

    closeModal(popupEmitNotNeeded: boolean = false) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if(!popupEmitNotNeeded)
            this.IsPopUpClosed.emit();
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
