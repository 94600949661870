
import {of as observableOf,  Observable ,  timer } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import "rxjs/add/observable/of";
import "rxjs/add/operator/mergeMap";

@Injectable()
export class CustomPreloadingWithDelayStrategy implements PreloadingStrategy {
    preload(route: Route, load: () => Observable<any>): Observable<any> {
        if (route.data && route.data["preload"]) {
            if (route.data["delay"]) {
                return timer(150).pipe(mergeMap(() => load()));
            }
            return load();
        } else {
            return observableOf(null);
        }
    }
}
