import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";

export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    DOWN_ARROW = 40
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: "[KeyUpDown]"
})
export class KeyUpDownDirective {
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    @HostListener("document:keydown", ["$event"])
    handleClick(event: any) {
        if (this.el.nativeElement.contains(event.target)) {
            if (event.keyCode === KEY_CODE.DOWN_ARROW) {
                const ele = document.getElementsByClassName(
                    "dropdown-item active"
                );
                if (ele && ele[0]) {
                    let target: any = ele[0];
                    target.parentNode.scrollTop = target.offsetTop;
                    // ele[0].scrollIntoView({behavior: "smooth", block: "nearest"});
                }
            }

            if (event.keyCode === KEY_CODE.UP_ARROW) {
                const ele = document.getElementsByClassName(
                    "dropdown-item active"
                );
                if (ele && ele[0]) {
                    // ele[0].scrollIntoView({ behavior: "smooth", block: "nearest" });
                    let target: any = ele[0];
                    target.parentNode.scrollTop = target.offsetTop - 120;
                }
            }
        }
    }

}
