<div class="editpatient--body newrx--body edit--drug">
    <div class="row content--heading">
        <div class="col-md-12 padding-0">
            <div class="row d-flex flex-row">
                <div class="col-12">
                    <div class="row">
                        <div class="icon">
                            <i class="far fa-pills"></i>
                        </div>
                        <div class="col-10">
                            <div class="content--heading__title padding-0">
                                REVIEW PHARMACIST VERIFICATION HISTORY
                            </div>
                        </div>
                        <div class="col-1 padding_left_12rem padding_top_0_7rem">
                            <app-user-guide-icon [ActiveTabId]= "'RPHVerfHist'"></app-user-guide-icon>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="row">
                <div class="col">
                    <span class="eprx--header__heading">Review Pharmacist Verification History</span>
                </div>
            </div>
        </div>
        <div class="eprx--block__content">
            <form [formGroup]="filterFG" (ngSubmit)="getRecords()">
                <div class="col-sm-12 padding-0">
                    <div class="row">
                                <div class="col">
                                    <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'RphFromDate'"
                                        [LabelText]="'Verification From Date'" [PlaceHolder]="''"
                                        [ControlName]="'fromDate'" [FormGroupName]="filterFG" [MinDate]=""
                                        [MaxDate]="maxDate"
                                        [ErrorDefs]="{required: 'Required', inValid:'Invalid Date', pattern: 'Invalid Date', maxDate:'Invalid Date'}"
                                        [MarkAsTouched]="filterFG.controls.fromDate?.touched">
                                    </eprx-date-picker>
                                </div>
                                <div class="col ">
                                    <eprx-date-picker [RxSelectId]="'ToDate'" [LabelText]="'Verification To Date'"
                                        [PlaceHolder]="''" [ControlName]="'toDate'" [FormGroupName]="filterFG"
                                        [MinDate]="" [MaxDate]="maxDate"
                                        [ErrorDefs]="{required: 'Required', inValid:'Invalid Date', pattern: 'Invalid Date', maxDate:'Invalid Date'}"
                                        [MarkAsTouched]="filterFG.controls.toDate?.touched">
                                    </eprx-date-picker>
                                </div>
                        <div class="col pt-4 height_2_5rem"  [formGroup]="filterFG">
                            <input class="form-check-input" type="checkbox" [id]="'currentUser'" value=true
                                formControlName="isCurrentUser" />
                            <label type="label" class="form-check-label" [for]="'currentUser'">
                                Is Current User
                            </label>
                        </div>
                        <div class="col">
                            <app-rx-select [LabelText]="'Verification Status'" [PlaceHolder]="''" [ControlName]="'verifyStatus'"
                                [FormGroupName]="filterFG" [HasMultiple]="false" [HasBindLabel2]="false"
                                [LabelForId]="'Name'"
                                [List]="[{Name:'All',Value:null},{Name:'Verified/Re-Verified',Value:'v'},{Name:'Follow Up',Value:'f'},{Name:'Re-Verify',Value:'rv'},{Name:'Not Yet Verified',Value:'n'}]"
                                [BindLabel]="'Name'" [BindValue]="'Value'" [LabelForId]="'Name'"
                                (TriggerOnEnterValue)="getRecords()" [nextElement]="'VerfType'">
                            </app-rx-select>
                        </div>
                        <div class="col">
                            <app-rx-select [RxSelectId]="'VerfType'" [LabelText]="'Verification Type'" [PlaceHolder]="''" [ControlName]="'VerificationType'" [FormGroupName]="filterFG"
                                [HasMultiple]="false" [HasBindLabel2]="false" [LabelForId]="'Name'"
                                [List]="[{Name:'All',Value:0},{Name:'Pharmacist Verification',Value:1},{Name:'Rx Verification',Value:2}]" [BindLabel]="'Name'"
                                [BindValue]="'Value'" [LabelForId]="'Name'" (TriggerOnEnterValue)="getRecords()" [nextElement]="'searchBtn'">
                            </app-rx-select>
                        </div>
                        <div class="col-4 align-button text-right pt-4">
                            <button id="searchBtn" title="Search / Refresh" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'f'"><span>F</span>
                                Search</button>
                            <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'t'"
                                (click)="getReport(false)"><span>T</span>
                                Report
                            </button>
                            <!-- <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'e'"
                            (click)="getReport(true)"><span>E</span>
                           Export
                        </button> -->
                            <button type="button" class="hotkey_success" (keydown.tab)="focusToFirstIfEmpty($event)"
                                appShortcutKey [AltKey]="'l'" (click)="clearSearchInputs()"><span>L</span> Clear
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="eprx--block__content erx--body" id="grpTransHistGrid">
            <span>
                <div class="row mt-0"  *ngIf="totalVerifRecs?.length">
                    <div class="col-12 text-right">
                        <button class="submit print-med-guide background_orange"
                            (click)="toggleColorLegend()">Color Legend</button>
                    </div>
                </div>
                <wj-flex-grid #flex [headersVisibility]="'Column'" [itemsSource]="pharmVerifWJ" [isReadOnly]="true"
                    [columnPicker]="'reviewRxVerfWJ'" (dblclick)="OnDoubleClickOnWijimo(flex, $event)" (initialized)="init(flex)"
                     [selectionMode]="'Row'" [itemFormatter]="itemFormatter" 
                     [ngClass]="[!totalCount ? 'wjm-grd-custom-height-56 no-data':'wjm-grd-custom-height-56']">
                    <wj-flex-grid-column [width]="47" [header]="'Edit Rx'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div class=" text-center padding_left_10px">
                                <i class="far fa-edit actions" title="Edit Rx" (click)="routeToEditRx(item)"></i>
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnFooter'" let-cell="cell">
                            <div></div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [width]="59" [header]="'History'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div class="padding_left_10px text-center">
                                <i class="far fa-history actions" title="Verification History"
                                    (click)="openVerifHistory(item,true)"></i>
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnFooter'" let-cell="cell">
                            <div></div>
                        </ng-template>
                    </wj-flex-grid-column>

                    <wj-flex-grid-column [header]="rxheader.hName" [binding]="rxheader.hName"
                        [aggregate]="(rxheader.hName === 'Billed' || rxheader.hName === 'Paid') ? 'Sum' : 'None'"
                        [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders" [width]="rxheader['width']"
                        >
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div *ngIf="rxheader.hName === 'Filled' || rxheader.hName === 'Verified'">
                                {{ item[rxheader.hName]}}
                            </div>
                            <div *ngIf="!(rxheader.hName === 'Filled' || rxheader.hName === 'Verified' || rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Status' || rxheader['hName'] === 'Rx#')"
                                [title]="item[rxheader.hName]+'' |  uppercase">
                                {{item[rxheader.hName]}}
                            </div>
                            <div *ngIf = "(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Status' || rxheader['hName'] === 'Rx#')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : item['StatusHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 4) ? 'right' : (cell.col.index>=4 && cell.col.index <17)?'top':'left'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"  container="body">
                                    {{item[rxheader['hName']] }}
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnFooter'" let-cell="cell">
                            <div  [title]="(totalVerifData?.TotalBilled  | currency: '$':'symbol':'1.2-2')"  *ngIf="rxheader.hName === 'Billed'" placement="right"> Total Billed:
                                <span>{{totalVerifData?.TotalBilled  | currency: "$":"symbol":"1.2-2"}}</span>
                            </div>
                            <div [title]="(totalVerifData?.TotalPaid  | currency: '$':'symbol':'1.2-2')"  *ngIf="rxheader.hName === 'Paid'" placement="right"> Total Paid:
                               <span> {{totalVerifData?.TotalPaid  | currency: "$":"symbol":"1.2-2"}}</span>
                            </div>
                            <div *ngIf="!(rxheader.hName === 'Billed' || rxheader.hName === 'Paid')"></div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div [title]="(rxheader?.hName  | StringSepWithSpaceOnCamCase )">
                                {{ rxheader?.hName  | StringSepWithSpaceOnCamCase }}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                </wj-flex-grid>
                <div class="row mt-2 mb-2" *ngIf="totalVerifRecs?.length">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <app-eprx-pagination [TotalCount]="totalCount" [WijmoName]="flex" [GridName]="'ReviewPharmacistVerfGrid'" (OnPageSelected)="setPage($event)"
                            (OnSizeSelected)="setSize($event)" (keydown.tab)="focusToFirst($event)" [PageNumber]="pageNumber" [PageSize]="pageSize"></app-eprx-pagination>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>

<app-rph-verify-hist *ngIf="showVerifHist" [RxInfo]="rxInfo" (IsPopUpClosed)="openVerifHistory(null,false)">
</app-rph-verify-hist>

<app-override-log *ngIf="openOverride" [PrivMask]="privType" [CatId]="categoryId" [OverrideEndpoint]="overrideEndpoint"
    (OverrideLoginDet)="OverrideRes($event)"></app-override-log>
