import { RphVerifyGuard } from "./../../guards/file-function-privs.guards";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RphVerificationComponent } from ".";
import { RphVerifyComponent } from "./rph-verify/rph-verify.component";
import { RphVerificationGuard } from "src/app/guards";
import { ReviewPharmacistVerifComponent } from "./review-pharmacist-verif/review-pharmacist-verif.component";

const routes: Routes = [
    {
        path: "",
        component: RphVerificationComponent,
        canActivate: [RphVerificationGuard]
    },
    {
        path: ":rxid/:refilId/:fillId/verifyRx",
        component: RphVerifyComponent,
        canActivate: [RphVerificationGuard]
    },
    {
        path: ":rxid/:refilId/:fillId/:totalRecordsCount/verifyRx",
        component: RphVerifyComponent,
        canActivate: [RphVerificationGuard]
    },
    {
        path: "review",
        component: ReviewPharmacistVerifComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RphVerificationRoutingModule {}
