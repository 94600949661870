
<div class="modal-header">
    <h3 class="modal-title">Notification Display</h3>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePopUp()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="exprx--common-block mt-0">
    <div class="row check-envelope-task height_51rem">
        <div class="col-md-2 vertical--tabs envelope-position--for-menu new-menu height_51rem mt-0">
            <div class="col-md-10">
                <div>
                    <ul class="nav nav-pills flex-column nav-item-envelope"  orientation="vertical" data-widget="tree" (tabChange)="beforeTabChange($event)" >
                        <li>
                            <a (click)="beforeTabChange('ASAP');openSuccess();onSearch()" tabIndex="0" >
                                <h5 class="row-10 nav-link  col-md-12 padding-0" title="ASAP Automatic Submission Notification" [ngClass]="[(IsActiveTabName === 'ASAP')  ? 'active': 'default_active']">
                                    {{"sideMenu.ASAP Automatic Sub Notif." | translate}}
                                </h5>
                            </a>
                        </li>
                        <li class="margin_top_0_1rem">
                            <a (click)="beforeTabChange('Control');openControlFileError();searchForControlErrorDataOnload()" tabIndex="0">
                                <h5 class="row-10 nav-link  col-md-12 padding-0" title="Resolve Control File Errors"  [ngClass]="[(IsActiveTabName === 'Control') ? 'active': 'default_active']">
                                    {{"sideMenu.Control Error File" | translate}}
                                </h5>
                            </a>
                        </li>
                        <!-- <li style="margin-top: 0.1rem;">
                            <a (click)="searchNYErrors()" tabIndex="0">
                                <h5 class="row-10 nav-link active col-md-12 padding-0" title="Control Error File for NY">
                                    {{"sideMenu.Control Error File for NY" | translate}}
                                </h5>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div  *ngIf="submissionNotif"class="col-md-10">
            <div class="row">
                <div class="col-5">
                    <div class="row">
                        <div class="col-5 left_1rem ">
                            <eprx-date-picker [RxSelectId]="'fromDate'" [AutoFocus]="true" [LabelText]="'From Date'"
                                [PlaceHolder]="''" [ControlName]="'fromDate'" [FormGroupName]="notificationDisplayFG"
                                [MinDate]="" [MaxDate]="maxDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-5">
                            <eprx-date-picker [RxSelectId]="'toDate'" [LabelText]="'To Date'" [PlaceHolder]="''"
                                [ControlName]="'toDate'" [FormGroupName]="notificationDisplayFG" [MinDate]=""
                                [MaxDate]="maxDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-1 p-4 left-2">
                            <i class="fa fa-search" aria-hidden="true" (click)="onSearch()" title="Search"></i>
                        </div>
                    </div>
                    <span *ngIf="CountDetails && CountDetails.length">
                        <div class="col-md-12">
                            <wj-flex-grid [headersVisibility]="'Column'" [itemsSource]="displaySuccessCount" [isReadOnly]="true"
                                [selectionMode]="'None'" #successFile [ngClass]="'adding-wj-height'">
                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                                    *ngFor="let actvHeader of actvCtrlHeaders" [width]="
                                                                                            actvHeader === 'Action'
                                                                                                ? 139
                                                                                                :actvHeader === 'State'
                                                                                                ? 65: actvHeader === 'Notification Date Time' ? 211 :actvHeader === 'Status' && (CountDetails.length > 19) ? 116: 124
                                                                                        ">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="actvHeader === 'Action'">
                                            <i class="far fa-eye actions blue-eye-icon margin--left" (click)="preview(item)"
                                                title="Preview"></i>
                                            <i class="far fa-download actions blue-eye-icon margin-left-window" (click)="download(item)"
                                                title="Download"></i>
                                        </div>
                                        <div *ngIf="actvHeader === 'Status'">
                                            <span>
                                                <img *ngIf="item.SuccessCount" src="assets/green-check_2.PNG"
                                                    class="succes_report" title="Success Report">
                                                <img *ngIf="item.FailureCount" src="assets/red_cross_2.PNG"
                                                    class="succes_report" title="Failure Report">
                                                <img *ngIf="!item.RxCount && !item.SuccessCount && !item.FailureCount"
                                                    src="assets/img/blue-----------zero.PNG" class="zero_reprot"
                                                    title="Zero Report">
                                            </span>
                                        </div>
                                        <div *ngIf="!((actvHeader === 'Action') || (actvHeader === 'Status'))"
                                            title="{{ item[actvHeader] }}">
                                            <div *ngIf="!item.UserReviewStatus">
                                                <b>{{item[actvHeader]}}</b>
                                            </div>
                                            <div *ngIf="item.UserReviewStatus">
                                                {{item[actvHeader]}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>
                    </span>
                    <span *ngIf="CountDetails && CountDetails.length">
                    <img  src="assets/green-check_2.PNG" class="margin_left_0_5rem width_2_1rem"
                    title="Success Report">
                    Success Report </span>
                    <span *ngIf="CountDetails && CountDetails.length">
                    <img src="assets/red_cross_2.PNG" class="margin_left_0_5rem width_2_1rem"
                    title="Failure Report">
                    Failure Report </span>
                    <span *ngIf="CountDetails && CountDetails.length">
                    <img src="assets/img/blue-----------zero.PNG" class="width_1_7rem margin_left_7rem"
                    title="Zero Report"> Zero Report </span>

                    <span *ngIf="!(CountDetails && CountDetails.length)" class="eprx--block__footer">
                        <div>
                            <span></span>
                            <i class="fa fa-file"></i><br/>
                            No Data Found!
                        </div>
                    </span>
                </div>
                <div  class="row col-md-7 height_50rem" *ngIf="pdfSrc && CountDetails && CountDetails.length">
                        <iframe [src]="pdfSrc" class="text-center" toolbar="0" width="100%" height="713px"
                            type="application/pdf"></iframe>
                </div>
            </div>
        </div>
        <div *ngIf="controlError" class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-5 text---left">
                            <p>Resolve Control File Errors</p>
                        </div>
                        <div class="col-2 top_2px">
                            <eprx-select [LabelText]="'Error Status'" [PlaceHolder]="''" [ControlName]="'statusDropdwn'" [FormGroupName]="notificationDisplayFG"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'" (TriggerSelectValue)="searchForControlErrorData($event.value)" [LabelForId]="''" [List]="[{'Id': 1,'Name':'Unresolved'},{'Id': 2, 'Name':'Resolved'},{'Id': 0, 'Name':'All'}]">
                        </eprx-select>
                        </div>
                        <div class="col-2 left_1rem">
                            <eprx-date-picker [RxSelectId]="'FromDate'" [AutoFocus]="true" [LabelText]="'From Date'"
                                [PlaceHolder]="''" [ControlName]="'FromDate'" [FormGroupName]="notificationDisplayFG"
                                [MinDate]="" [MaxDate]="maxDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-2">
                            <eprx-date-picker [RxSelectId]="'ToDate'" [LabelText]="'To Date'" [PlaceHolder]="''"
                                [ControlName]="'ToDate'" [FormGroupName]="notificationDisplayFG" [MinDate]=""
                                [MaxDate]="maxDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-1 p-4 left-2">
                            <i class="fa fa-search" aria-hidden="true" (click)="searchForControlErrorData()" title="Search"></i>
                        </div>
                    </div>
                    <div class="eprx--block__content height_46rem">
                        <div class="col-12 p-1 wj-header2" *ngIf="totalRecordsLength">
                            <div class="row mt-0">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4 text-right--margin-left">
                                            <button class="submit print-med-guide background_orange"
                                                (click)="toggleColorLegend()">Color Legend</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <wj-flex-grid #cntrlErrorFile [headersVisibility]="'Column'" [itemsSource]="controlErrorWJ"
                                class="text_transform" [isReadOnly]="true" [selectionMode]="'Row'"
                                [columnPicker]="'controlFileError'" [itemFormatter]="itemFormatter"
                                (initialized)="init(cntrlErrorFile)">
                                <wj-flex-grid-column [header]="'Action'" [width]="78">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <i class="far fa-edit actions edit--icon-color" title="Edit"
                                            (click)="routeToEditRx(item)"></i>
                                        <img *ngIf="item.ErrorUpdateStatus === 2" src="assets/green-check_2.PNG"
                                            class="resolvedOrNot" title="Resolved">
                                        <img *ngIf="item.ErrorUpdateStatus === 1" src="assets/red_cross_2.PNG"
                                        class="resolvedOrNot" title="Unresolved">
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                    [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index"
                                    [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="!(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#'  || rxheader['hName'] === 'Status') "
                                            [style.color]="(rxheader['hName'] === 'Control Error' && item.ErrorUpdateStatus === 1)  ? 'red' : (rxheader['hName'] === 'Control Error' && item.ErrorUpdateStatus === 2 ? 'green' : null) "
                                            [title]="((item[rxheader.hName]+'')|uppercase)">
                                            {{item[rxheader['hName']]}}
                                        </div>
                                        <div *ngIf="(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#'  || rxheader['hName'] === 'Status')"
                                            [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : item['StatusHoverOver']"
                                            placement="top" triggers="mouseover:mouseleave" container="body"
                                            tooltipClass="tooltip-title" container="body">
                                            {{item[rxheader['hName']]}}
                                        </div>
                                    </ng-template>
                                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                        <div title={{rxheader.hName}}>
                                            {{ rxheader.hName}}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <div class="row mt-0">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2">
                                            <p >{{selectedFilter['Name'] === 'All' ? 'Total Count:' : (selectedFilter['Name'] == 'Resolved' ? 'Total Resolved Count:' : 'Total Unresolved Count:')}} {{totalRecordsLength}}</p>

                                        </div>
                                        <div class="col-2 margin_left_n8rem"  *ngIf="selectedFilter['Name'] == 'All'">
                                            <p>Resolved Count: {{resolvedCount.length}}</p>
                                        </div>
                                        <div class="col-5 margin_left_n6rem" *ngIf="selectedFilter['Name'] == 'All'">
                                            <p>Unresolved Count: {{unResolvedCount.length}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span *ngIf="!totalRecordsLength" class="eprx--block__footer">
                            <div>
                                <span></span>
                                <i class="fa fa-file"></i><br/>
                                No Data Found!
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
       <!-- <div  *ngIf="controlFileErrorNY"class="col-md-10 eprx--block__content">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-2" style="left:1rem">
                            <eprx-date-picker [RxSelectId]="'fromDate'" [AutoFocus]="true" [LabelText]="'From Date'"
                                [PlaceHolder]="''" [ControlName]="'fromDate'" [FormGroupName]="notificationDisplayFG"
                                [MinDate]="" [MaxDate]="maxDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-2">
                            <eprx-date-picker [RxSelectId]="'toDate'" [LabelText]="'To Date'" [PlaceHolder]="''"
                                [ControlName]="'toDate'" [FormGroupName]="notificationDisplayFG" [MinDate]=""
                                [MaxDate]="maxDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-1">
                        <eprx-input [LabelText]="'State'" [HasControl]="false" [ReadOnly]="true"
                        [InputValue]="rxInfo?.Patient?.dob  ? (rxInfo?.Patient?.dob | date:'MM/dd/yyyy') : '--'">
                        </eprx-input>
                     </div>
                        <div class="col-4">
                            <app-searchbox-global
                            id="Patient" [RxSelectId]="'Patient'" [SearchFor]="'Patient'"
                            [ShowOnlyPrescName]="true" [InputValue]="patientNamePatch" [IsAddNew]="false"
                            [DisableSerchFil]="true" [DisableRadioButtons]="true"
                            (SelectedPatient)="selectPatient($event,selectedDocument)">
                        </app-searchbox-global>
                        </div>

                        <div class="col-md-2">
                            <eprx-select [LabelText]="'Error Type'" [PlaceHolder]="'Error Type'"
                               [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'"
                               >
                            </eprx-select>
                        </div>
                        <div class="col-1 p-4 left-2-rem">
                            <i class="fa fa-search" aria-hidden="true" (click)="searchNYErrors()" title="Search"></i>
                        </div>
                    </div>
                    <span>
                        <div class="col-md-12">
                            <wj-flex-grid #cntrlErrorFile [headersVisibility]="'Column'"
                            [itemsSource]="showNYErrorsWJ" [isReadOnly]="true" [selectionMode]="'Row'" [columnPicker]="'controlFileErrorForNY'"
                             (dblclick)="onRowDblclicked(cntrlErrorFile, $event)">
                            <wj-flex-grid-column [header]="'Action'" [width]="58" >
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column  [header]="rxheader['hName']" [binding]="rxheader['hName']"
                            [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders2; let i = index" [width]="rxheader['width']">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div *ngIf="rxheader['hName'] !== 'NDC'" [style.color]="rxheader['hName'] === 'Control Error' ? 'red' : null" [title] ="((item[rxheader.hName]+'')|uppercase)">
                                        {{item[rxheader['hName']]}}
                                    </div>
                                    <div *ngIf="rxheader['hName'] === 'NDC'" [style.color]="rxheader['hName'] === 'Control Error' ? 'red' : null">
                                        {{item[rxheader['hName']] | mask : "AAAA0-0000-00"}}
                                    </div>
                                </ng-template>
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                    <div title={{rxheader.hName}}>
                                        {{ rxheader.hName | uppercase}}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                        </div>
                    </span>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="modal-footer">
    <div class="text-right pull-right col-md-10 padding-0">
        <button type="button" class="hotkey_primary" (click)="closePopUp()" appShortcutKey InputKey="c">
            <b>C</b> Close
        </button>
    </div>
</div>
