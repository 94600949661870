import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RxService } from 'src/app/services';
import { PrintErxService } from 'src/app/services/print-erx.service';
import { ValidateFormFieldsUtils } from 'src/app/utils';

@Component({
  selector: 'app-drug-missing-form',
  templateUrl: './drug-missing-form.component.html',
  styleUrls: ['./drug-missing-form.component.scss']
})
export class DrugMissingFormComponent implements OnInit {
    drugMissingForm: FormGroup;
  @Input() drugInfo: any;
  drugformGroupInvalid: boolean;
  processingMissingDrug: any;
  missingPopUP: boolean;
  @Input() ErxChangeReqFG: any;
  drugIngredientsList: any;
  modalRef: any;
  isInputFocused = false;
  formSubmitted = false;
  dropdownData: any;
  unsubscribe$: Subject<void> = new Subject();
  showDrugDescription: boolean;
  @Output() closeDrugMissingValues = new EventEmitter();
  @ViewChild("MISSINGFIELDS", { static: true })
  MISSINGFIELDS: any;

  constructor(private _erxService: PrintErxService, private _fb: FormBuilder, private _modalService: NgbModal,
    private _rxService: RxService, private _formUtils: ValidateFormFieldsUtils) { }

  ngOnInit() {
    this.drugMissingForm = this._fb.group({
      DoseFrmDescId: [],
      DoseFrmCodeId: [],
      DrugStrQualId: [],
      PotencyUnitCodeId: [],
      QtyQualId: [],
      DrugId: [],
      DrugName: []
  });
  this.checkDrugValues();
}
checkDrugValues() {
    if (this.drugInfo && this.drugInfo.id) {
        this._erxService.checkDrugValues(this.drugInfo.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.missingPopUP = true;
                this.getDropdownsFormissing();
                this.patchMissingFields(resp);
                if (this.drugInfo.drugtypeid === 2) {
                    this.showDrugDescription = true;
                }
                this.formSubmitted = false;
                if (this.drugMissingForm && (!this.drugMissingForm.value.PotencyUnitCodeId ||
                    (this.drugInfo.drugtypeid === 2 && !this.drugMissingForm.value.DoseFrmCodeId))) {
                    this.openModal(this.MISSINGFIELDS);
                } else if (this.drugInfo.drugtypeid === 2) {
                    this.checkCompoundDrugMissingFields();
                } else {
                    this.closeModal();
                }
            }
        });
    } else {
        this.closeModal();
    }
}
closeModal(isSelectedTYpe: string = "") {
    if (this.modalRef) {
        this.modalRef.close();
    }
    this.closeDrugMissingValues.emit(isSelectedTYpe);
}
async saveDrugFields() {
    await this._formUtils.blurAndDelay();
    this.formSubmitted = true;
    if (!this.drugMissingForm.valid) {
        return;
    }
    this.closeModal();
    const postData = this.drugMissingForm.value;
    postData["IsUnitDrug"] = false;
    postData["IsMedGuide"] = false;
    this._erxService.saveMissingFields(postData)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp) {
            this.showDrugDescription = false;
            this.formSubmitted = false;
            if (this.processingMissingDrug) {
                this.checkEachCompMissingFields();
            } else {
                this.checkCompoundDrugMissingFields();
            }
        }
    });
}
ngOnDestroy() {
    this.unsubscribe$.next();
     this.unsubscribe$.complete();
   }
checkCompoundDrugMissingFields() {
    if (this.drugInfo.drugtypeid === 2) {
        this._erxService
            .checkCompoundDrugValues(this.drugInfo.id, 0)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                if (response) {
                    if (!this.dropdownData) {
                        this.getDropdownsFormissing();
                    }
                    this.showDrugDescription = false;
                    this.drugIngredientsList = response;
                    this.checkEachCompMissingFields();
                } else {
                    this.showErxData();
                }
            });
    } else {
        this.closeModal();
    }
}
showErxData() {
    this.closeDrugMissingValues.emit(true);
}
getDropdownsFormissing() {
    this._erxService.getErxDropdowns()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp) {
            this.dropdownData = resp;
        }
    });
}
checkEachCompMissingFields() {
    if (this.drugIngredientsList.length > 0) {
        this.processingMissingDrug = this.drugIngredientsList.shift();
        this.patchMissingFields(this.processingMissingDrug);
        this.formSubmitted = false;
        if (this.drugMissingForm && !this.drugMissingForm.value.PotencyUnitCodeId) {
            this.openModal(this.MISSINGFIELDS);
        } else {
            this.checkEachCompMissingFields();
        }
    } else {
        this.showErxData();
    }
}

openModal(content: any) {
    this.modalRef = this._modalService.open(content, {
        backdrop: false,
        keyboard: false,
        centered: true
    });
}
patchMissingFields(patchValues) {
  this.drugMissingForm.reset();
  this.drugMissingForm.controls.DoseFrmCodeId.clearValidators();
  this.drugMissingForm.patchValue(patchValues);
}
}
