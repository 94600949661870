export class Vendordruginfo {
    Id: number = null;
    ContractName: string = null;
    ContractId: number = null;
    VendorName: string = null;
    VendorId: number = null;
    ItemCode: number = null;
    UnitCost: number = null;
    PackCost: number = null;
    AWP: number = null;
    UnitAWP: number = null;
    IsDiscont: boolean = null;
    CreatedDtTm: any = null;
    ModifiedDtTm: any = null;
    IsUpdateFromPrimeEDI: any = null;
}

export class Vendor {
    Id: number = null;
    DrugId: number  = null;
    VendorId: number = null;
    ItemCode: number = null;
    UnitCost: number = null;
    PackCost: number = null;
    AWP: number = null;
    UnitAWP: number = null;
    Remarks: string = null;
    IsDiscont: any = null;
    IsActive: any = null;
    CreatedDtTm: any = null;
    ModifiedDtTm: any = null;
    IsUpdateFromPrimeEDI: any = null;
    VendorName: string = null;
    ContractId: number = null;
    ContractName: any = null;
}
