export class RxBillingCalculationInput {
    QuantityDispense: number;
    DrugId: number;
    PriceScheduleId: number;
    OtherAmount: number;
    Discount: number;
    TypeOfCalculation: string;
    PatientId: any;
    InsuranceId: any;
    prescReFillId: any;
    BucketId: number;
    PrescReFillId: any;
    CompIngList: any;
}

export class RxBillingPriceModel {
    Price: number;
    ProFee: number;
    TotalAwp: number;
    BalDue: number;
    UAndC: number;
}
