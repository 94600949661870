
<div class="exprx--common-block" *ngIf="refSelctList?.length > 1 && refInQueueList?.length > 0">
    <div class="eprx--block__header back-dark-color">
            <div class="row">
                    <div class="col-11">
                        <div class="eprx--header__heading">Batch Refills</div>
                    </div>
                    <div class="col-1 pl-1">
                        <i aria-hidden="true" class="far fa-angle-double-right text-white add--icon" *ngIf="!expand" (click)="showExpand()"></i>
                        <i type="button"aria-hidden="true" class="far fa-angle-double-down text-white add--icon" *ngIf="expand" (click)="showExpand()"></i>
                    </div>
            </div>
    </div>
    <div class="eprx--block__content batchrefill" *ngIf="expand">
        <div class="col-12 batchrefill-list--block">
            <div class="row batchrefill-header">
                <ul class="refill-inProgress">
                    <li
                        *ngFor="let refRx of refSelctList; let i = index"
                        [ngClass]="i === (refSelctList.length - refInQueueList.length)
                                ? 'refill-prog--li inProgress--refill'
                                : 'refill-prog--li notinProgress--refill'"
                    >
                        <i
                            class="far fa-chevron-circle-right"
                            *ngIf="i === (refSelctList.length - refInQueueList.length)"
                        ></i>
                        {{ refRx }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
