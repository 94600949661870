import { GridLabels, FileNames, EntityNames } from '../models/wijmo-export.enum';

export const auditMappings: { [key in GridLabels]?: { fileName: FileNames,  entityName: EntityNames } } = {
    [GridLabels.BrowseRx]: {fileName: FileNames.BrowseRx, entityName: EntityNames.Rx },
    [GridLabels.RxVerification]: {fileName: FileNames.RxVerification, entityName: EntityNames.Rx },
    [GridLabels.VerifiedListGrid]: {fileName: FileNames.DrugPickVerification, entityName: EntityNames.Rx },
    [GridLabels.notVerifiedListGrid]: {fileName: FileNames.DrugPickVerificationNotVerified, entityName: EntityNames.Rx },
    [GridLabels.ReviewDrugPickVerfGrid]: {fileName: FileNames.DPVHistory, entityName: EntityNames.Rx },
    [GridLabels.ReviewPharmacistVerfGrid]: {fileName: FileNames.RPHVerificationHistory, entityName: EntityNames.Rx },
    [GridLabels.TransHistGrid]: {fileName: FileNames.TransmissionDetails, entityName: EntityNames.Rx },
    [GridLabels.IntakeQueueGrid] : {fileName: FileNames.IntakeQueueFile, entityName: EntityNames.Rx },
    [GridLabels.PatientSearchGrid]: { fileName: FileNames.PatientFile, entityName: EntityNames.Patient },
    [GridLabels.PatientCategory]: { fileName: FileNames.PatientCategories, entityName: EntityNames.Patient },
    [GridLabels.Facility]: { fileName: FileNames.FacilityFile, entityName: EntityNames.Patient },
    [GridLabels.DiagnosisListFile]: { fileName: FileNames.DiagnosisFile, entityName: EntityNames.Patient },
    [GridLabels.DiagnosisList]: { fileName: FileNames.DiagnosisList, entityName: EntityNames.Patient },
    [GridLabels.PrescriberSearchGrid]: { fileName: FileNames.PrescriberFile, entityName: EntityNames.Prescriber },
    [GridLabels.PrescriberSpecialties]: { fileName: FileNames.PrescriberSpecialties, entityName: EntityNames.Prescriber },
    [GridLabels.PrescriberTypeFile]: { fileName: FileNames.PrescriberTypeFileMaintenance, entityName: EntityNames.Prescriber },
    [GridLabels.DrugSearchGrid]: { fileName: FileNames.DrugFile, entityName: EntityNames.Drug },
    [GridLabels.DrugCategoriesGrid]: { fileName: FileNames.DrugCategories, entityName: EntityNames.Drug },
    [GridLabels.WarningCodeList]: { fileName: FileNames.DrugWarning, entityName: EntityNames.Drug },
    [GridLabels.DrugDescriptionGrid]: { fileName: FileNames.DrugDescriptionGrid, entityName: EntityNames.Drug },
    [GridLabels.SigFileGrid]: { fileName: FileNames.SigFile, entityName: EntityNames.Drug },
    [GridLabels.PriceList]: { fileName: FileNames.PriceList, entityName: EntityNames.Drug },
    [GridLabels.PriceSchedule]: { fileName: FileNames.PriceSchedule, entityName: EntityNames.Drug },
    [GridLabels.DiscountScheduleGrid]: { fileName: FileNames.DiscountSchedule, entityName: EntityNames.Drug },
    [GridLabels.DoseSchedule]: { fileName: FileNames.DoseSchedule, entityName: EntityNames.Drug },
    [GridLabels.Vendors]: { fileName: FileNames.Vendors, entityName: EntityNames.Drug },
    [GridLabels.InsuranceGrid]: { fileName: FileNames.InsuranceFile, entityName: EntityNames.Insurance },
    [GridLabels.PayorFileList]: { fileName: FileNames.PayorFileMaintenance, entityName: EntityNames.Insurance },
    [GridLabels.NCPDPGrid]: { fileName: FileNames.NCPDPPayerSet, entityName: EntityNames.Insurance },
    [GridLabels.RxFollowUpTag]: { fileName: FileNames.RxFollowUpTag, entityName: EntityNames.Rx },
    [GridLabels.DeferredList]: { fileName: FileNames.deferredList, entityName: EntityNames.Rx },
    [GridLabels.TransferPharmacy]: { fileName: FileNames.TransferPharmacy, entityName: EntityNames.Rx },
    [GridLabels.UsersList]: { fileName: FileNames.UsersList, entityName: EntityNames.Rx },
    [GridLabels.RolesListGrid]: { fileName: FileNames.RolesListGrid, entityName: EntityNames.Rx },
    [GridLabels.UsersLoginHistory]: { fileName: FileNames.UsersLoginHistory, entityName: EntityNames.Rx },
    [GridLabels.OverrideLog]: { fileName: FileNames.OverrideLog, entityName : EntityNames.Rx},
    [GridLabels.RecallDeletedRx]: { fileName: FileNames.RecallDeletedRx, entityName: EntityNames.Rx },
    [GridLabels.Buckets]: { fileName: FileNames.BucketSearch, entityName: EntityNames.Drug },
    [GridLabels.TransTypeGrid]: { fileName: FileNames.TransTypeFile, entityName: EntityNames.Drug },
    [GridLabels.DMSCategoryGrid]: { fileName: FileNames.DMSSubCategory, entityName: EntityNames.Rx }
};
