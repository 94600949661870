import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginationModel } from 'src/app/models';
import { DrugService } from 'src/app/services';

@Component({
  selector: 'app-drug-categories',
  templateUrl: './drug-categories.component.html',
  styleUrls: ['./drug-categories.component.scss']
})
export class DrugCategoriesComponent implements OnInit {
    drugCategoryFromgroup: FormGroup;
    totalCount: any;
    drugCatList: CollectionView;
    selectedCategories: any[];
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    unsubscribe$: Subject<void> = new Subject();
    modelRef: any;
    @ViewChild("userDefined", { static: true })
    userDefined: any;
    @Input() selectedDrugCategories: any;
    @Output() IsOkPopupClosed = new EventEmitter();
    @Output() IsCancelPopupClosed = new EventEmitter<any>();

  constructor(private _drugServ: DrugService, private _fb: FormBuilder, private _modalService: NgbModal) { }

  ngOnInit(): void {
    this.modelRef = this._modalService.open(this.userDefined, { centered: true,
        keyboard: false,
        backdrop: false,
        size: "lg",
        windowClass: "medium-modal-60"});
    this.createFG();
    this.getDrugCatList();
  }
  createFG() {
    this.drugCategoryFromgroup = this._fb.group({
        categoryName: null,
        PageNumber: this.paginationModel.pageNumber,
        PageSize: this.paginationModel.pageSize
      });
  }
  getDrugCatList() {
    this._drugServ.getdrugcategoriesList(this.drugCategoryFromgroup.value)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp: any) => {
      this.totalCount = resp.TotalRecords;
      if (resp && resp.Categories && resp.Categories.length) {
        this.generateList(resp.Categories);
      }
    });
  }
  generateList(data: any) {
    const mapDataSet = [];
    data.map((drug, i) => {
      const j = {};
      j["Category ID"] = j["ID"] = drug.Id;
      j["Category Name"] = j["Name"] = drug.Name ? (drug.Name).toUpperCase() : "";
      j["IsActive"] = drug.IsActive;
      j["CreatedDtTm"] = drug.CreatedDtTm;
      j["Select"] = this.selectedDrugCategories ? (this.selectedDrugCategories.some(item => item.ID === drug.Id ? true : false)) : false;
    j["isUserDefined"] = true;
      mapDataSet.push(j);
    });
    this.drugCatList = new CollectionView(mapDataSet,
      {
        sortComparer: (a: any, b: any) => {
          return moment(a).isValid() && moment(b).isValid()
            ? moment(a).diff(moment(b)) : null;
        }
      });
  }
  selectedRecords(isChecked, item, id) {
    item.grid.collectionView.items.map(rec => {
        if (rec["Category ID"] === id) {
            rec.Select = isChecked;
        }
    });
    const newArray = [];
    item.grid.collectionView.items.map(rec => {
        if (rec.Select) {
            newArray.push(rec);
        }
    });
    this.selectedCategories = newArray;
  }
  searchDrugCat(isClear: boolean = false) {
    if (isClear) {
        this.drugCategoryFromgroup.controls["categoryName"].reset();
    }
    this.getDrugCatList();
  }
  closeCategoryPopup(type) {
       if (type) {
        this.IsOkPopupClosed.emit(this.selectedCategories);
       } else {
        this.IsCancelPopupClosed.emit();
       }
       this.modelRef.close();
       this.modelRef = null;
  }
  setPage(page: number) {
    this.pageNumber = page;
    this.drugCategoryFromgroup.controls["PageNumber"].patchValue(page);
    this.getDrugCatList();
  }

  setSize(size: any) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.drugCategoryFromgroup.controls["PageSize"].patchValue(this.pageSize);
    this.setPage(1);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
     this.unsubscribe$.complete();
   }
}

