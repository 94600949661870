export class DME {
    Id: number = null;
    TenantId: number = null;
    RxId: number = null;
    DiagCode: string = null;
    ClinicSp: number = null;
    LocatorCode: number = null;
    ServiceCat: number = null;
    ServicePlace: number = null;
    AccidentCode: number = null;
    IsHandiChildPg: boolean = null;
    IsDisability: boolean = null;
    IsFamilyPlan: boolean = null;
    IsRental: boolean = null;
}

export class WCOM {
    WorkmanComp: WorkmanComp = null;
    CarrOrg: CarrOrg = null;
    CarrAdd: CarrierAddress = null;
    EmpOrg: CarrOrg = null;
    EmpAdress: CarrierAddress = null;
    EmPhn: EmPhn = null;
    PayerAddress: CarrierAddress = null;
}

export class WorkmanComp {
    AccidentDt: Date = null;
    InsuCarrierId: any = null;
    EmployerId: number = null;
    ClaimNum: number = null;
    BillEntCatId: number = null;
    PayToQId: number = null;
    PayToNum: string = null;
    PayToName: string = null;
    GenEqProdId: number = null;
    GenEqProdNum: string = null;
    BillEntCatName: string = null;
    PayToQName: string = null;
    GenEqProdName: string = null;
    PayToAddsId: number = null;
    Id: number = null;
    TenantId: number = null;
    RxId: number = null;
}

export class CarrOrg {
    Id: number = null;
    InsurerCode: string = null;
    TenantId: number = null;
    Name: string = null;
    ContPerson: string = null;
    WebURL: string = null;
    PrimeAddsId: number = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    SwitchCD: string = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class CarrierAddress {
    Id: number = null;
    TenantId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    ZipCode: string = null;
    CountryId: number = null;
    StateId: number = null;
    CityId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    CityName: string = null;
    StateName: string = null;
    Extension: string = null;
}

export class EmPhn {
    Id: number = null;
    TenantId: number = null;
    PhoneCatId: number = null;
    AreaCode: string = null;
    xPhone: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class WCOM1 {
    AccidentDate: Date = null;
    InsuCarrierId: number = null;
    CarrierName: string = null;
    InsurerCode: string = null;
    CarrierAddress: string = null;
     CarrierStateName: string = null;
     CarrierCityName: string = null;
    CarrierState: number = null;
    CarrierCity: number = null;
    CarrierZip: string = null;

    EmployerName: string = null;
    EmployerContact: string = null;
    EmployerPhone: string = null;
    EmployerAddress: string = null;
    EmployerStateName: string = null;
    EmployerCityName: string = null;
    EmployerState: number = null;
    EmployerCity: number = null;
    EmployerZip: string = null;
    EmployerClaim: number = null;
    EmployerAreaCode: string = null;

    BillEntityType: number = null;
    PayToName: string = null;
    PayToQ: number = null;
    PayToId: string = null;
    PayToAddress: string = null;
    PayToState: number = null;
    PayToCity: number = null;
     PayToStateName: string = null;
    PayToCityName: string = null;
    PayToZip: string = null;
    GenEqProdId: number = null;
    GenEqProdIdQ: string = null;

    // Prescription Num
    PrescNum: number = null;
}

export class RxExtraValues {
    rxExtra: RxExtra = null;
    prescInfo: PrescInfo = null;
    couponInfo: CouponInfo = null;
    diagCode: DiagCode[] = [];
    procModifier: ProcModifier[] = [];
}

export class RxExtra {
    PrescRefillId: number = null;
    CopayExcpCode: string = null;
    SubClarifId1: number = null;
    SubClarification1Name: string = null;
    SubClarifId2: number = null;
    SubClarification2Name: string = null;
    SubClarifId3: number = null;
    SubClarification3Name: string = null;
    OtherCovrgId: number = null;
    OtherCoverageName: string = null;
    DelayReasonId: number = null;
    DelayReasonName: string = null;
    PhServiceCatId: number = null;
    PharmServiceCatName: string = null;
    AlternateCode: string = null;
    ServiceLvlId: number = null;
    ServiceLvlName: string = null;
    InsSpVal: string = null;
    IsPatAssignInd: boolean = null;
    InterimAuthCode: string = null;
    BatchFileBillCodeId: number = null;
    BatchFileBillRef: string = null;
    IsPAUsedForAllRefills: boolean = null;
    IdRefillReminded: boolean = null;
    RxRecdById: number = null;
    RxRecdDtTm: Date = null;
    RxExpDt: Date = null;
    IsIncludedInMar: boolean = null;
    MaxDailyDose: number = null;
    BillDaysSup: number = null;
    Id: any = null;
    rxOriginId: number = null;
    InterimAuthCatId: number = null;
    IntermAuthCatName: string = null;
    BatchFileBillName: string = null;
    RxOriginName: string = null;
    TreatmentTypeId: number = null;
    TreatmentTypeName: string = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class PrescInfo {
    PrescRefillId: number = null;
    OrgPrescProdCatId: number = null;
    OrgPrescProdCatName: string = null;
    OrgPrescProd: string = null;
    OrgPrescQty: number = null;
    OrgPrescDrugName: string = null;
    Id: any = null;
}

export class CouponInfo {
    PrescRefillId: number = null;
    CouponCatId: number = null;
    CouponCatName: string = null;
    CouponNum: string = null;
    CouponVal: number = null;
    Id: any = null;
}

export class DiagCode {
    PrescRefillId: number = null;
    DiagnosisId: string = null;
    IsActive = true;
}

export class ProcModifier {
    PrescRefillId: number = null;
    CPTCode: number = null;
}

export class DUR {
    Id: number = null;
    TenantId: number = null;
    RxId: number = null;
    ServReasonId: number = null;
    ServReasonName: string = null;
    ProfServId: number = null;
    ProfServName: string = null;
    ServResultId: number = null;
    ServResult: string = null;
    EffortLvlId: number = null;
    EffortLvlName: string = null;
    CoAgentNum: any = null;
    CoAgentIdType: any = null;
    CoAgentIdCatId: number = null;
    ClinicalSignfId: number = null;
    ClinicalSignfName: string = null;
    PhComment: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    IsRefillSpecific : boolean = null;
    PrescrefillId : number = null;
}

export class RxChangeDUR {
    ServiceReasonCode: string = null;
    ProfessionalServiceCode: string = null;
    ServiceResultCode: string = null;
    LevelOfEffort: string = null;
    CoAgentQualifier: string = null;
    ClinicalSignificanceCode: string = null;
    CoAgentIdType: string = null;
    CoAgentID: number = null;
    AcknowledgementReason: string = null;
}

export class RxChangeDia {
    Qualifier: string = null;
    DiagnosisCode: string = null;
    DiagnosisDescription: string = null;
}

export class RxChangeDrug {
    DrugId: number = null;
    Days: number = null;
    Qty: number = null;
    Sig: string = null;
    Refills: number = null;
    Daw: string = null;
    DrugName: string = null;
    SigCode: String = null;
}

