import { SystemSetting } from "./../../models/system-settings.model";
import { CommonService, UserService, AlertService, RxService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { DrugVerifiedHistory } from "./../../models/drug-pick-verify.model";
import { NgbModal, NgbToast } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChild, HostListener, Output, EventEmitter, AfterViewInit, Input, ChangeDetectorRef} from "@angular/core";
import * as moment from "moment";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { DrugPickVerificationService } from "../../services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccCategoryE, NotVerifyFilters, PaginationModel, RxE } from "../../models";
import * as _ from "lodash";
import { BaseUtils } from "../../utils/base.utils";
import { ColorCode, CommonUtil, NRxUtils, ValidateFormFieldsUtils, WijimoUtil } from "../../utils";
import { MsgConfig } from "src/app";
import { CommonStore } from "src/app/store";
import { PrivMaskGuard } from "src/app/guards";
import { EdRxComponent } from "../ed-rx";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { Verificationsenum } from "src/app/models/Verifications.enum";
import { VerificationTabsListComponent } from "../verification-tabs/verification-tabs-list/verification-tabs-list.component";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";
@Component({
    selector: "app-drug-pick-verification",
    templateUrl: "./drug-pick-verification.component.html"
})
export class DrugPickVerificationComponent implements OnInit, AfterViewInit {
    @Input() rxId: any;
    hideFilterForm = true;
    verifyHistory = false;
    notVerified = true;
    showRxDetails = false;
    searchRX = true;
    paginationModel: PaginationModel = new PaginationModel();
    @ViewChild("dpverifytabs")
    dpverifytabs: NgbToast;

    @ViewChild("notVerifiedlist")
    flex: wjcGrid.FlexGrid;
    @ViewChild("VerifyHistorylist")
    Histflex: wjcGrid.FlexGrid;
    @ViewChild("invalidRx", { static: true })
    invalidRx: any;
    @ViewChild("RxDiscontinued", { static: true})
    RxDiscontinued: any;
    @ViewChild("DiscontinuedRx", { static: true})
    DiscontinuedRx: any;
    today: any;
    minDate: { year: any; month: any; day: any };
    notVerifyListWJ: any;
    totalCount: any;
    HisttotalCount: any;
    actvHeaders: string[];
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    VerifyHistListWJ: CollectionView;
    NotVerifyFG: FormGroup;
    verifyStatus: { status: string; value: string }[];
    startIndex = 0;
    nVpageNumber = this.paginationModel.pageNumber;
    nVpageSize = this.paginationModel.pageSize;
    drugVerifyFilters: NotVerifyFilters = new NotVerifyFilters();
    HiststartIndex = 0;
    rxInfo: any;
    RxNumFG: FormGroup;
    modalRef: any;
    totalRx: any;
    VerifiedRx: any;

    notVerifiedRx: any;
    verfList: any;
    notVerfList: any;
    @ViewChild("alreadyVerified", { static: true })
    alreadyVerified: any;
    showFilters = true;
    stdate: any;
    eddate: any;
    warningInfo: string;
    routeFrom: any;
    startdate: string;
    systemStng: SystemSetting;
    userName: string;
    PASSED = "p";
    ALL = "a";
    actvHeaders1: string[];
    val: any;
    groupByNames = [];
    filedQueueFlex: any;
    notyetVerified: any;
    selectedFiledWJ: wjcGrid.FlexGrid;
    selectedrxNo: any;
    selectedrxId: any;
    privType: string;
    categoryId: string;
    openOverride: boolean;
    groupFG: FormGroup;
    systemData: any;
    wjHeaders: any;
    wjHeaders1: any;
    isChecked: boolean = false;
    resp: any;
    isAfterPopup: boolean = false;
    chkVal: any;
    response: any;
    dataBrDisContinueCheck: any;
    excludeColumns: string[] = ["Actions", "Select", "Action"];
    verfHistList: any;
    activeTabId: string;
    isDataExists = true;
    isDataExists2 = true;
    groupVerfFG: FormGroup;
    groupByNamesVerf = [];
    unsubscribe$: Subject<void> = new Subject();
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this.modalService.hasOpenModals()) { // esc
            if (this.modalRef) {
                event.preventDefault();
            }
        }
    }

    constructor(
        private _drgVerifyServ: DrugPickVerificationService,
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _actvRoute: ActivatedRoute,
        public _commonService: CommonService,
        private _baseUtls: BaseUtils,
        private _userService: UserService,
        private _alertService: AlertService,
        private _colorCode: ColorCode,
        private _commonStore: CommonStore,
        private _privMaskGuard: PrivMaskGuard,
        private _nrxUtils: NRxUtils,
        private _formUtils: ValidateFormFieldsUtils,
        private _comnUtil: CommonUtil,
        private _wijimoUtils: WijimoUtil,
        private _rxService: RxService,
        private _buckUtils: DrugBucketUtil,
        private _hoverOver: HoverOver,
        private _colorCodeRx: ColorCodeRx,
        private _formatsUtil: FormatsUtil,
        private _cdr: ChangeDetectorRef
    ) {
        this.systemData = this._commonService.systemData$["source"]["value"];
    }

    ngOnInit() {
        this._commonService.systemSettings$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            this.systemStng = resp;
        });
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this._actvRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            if (params && params.from) {
                const dates = this._commonStore.dashFilters$["source"]["value"];
                if (dates) {
                  this.startdate = dates.StartDate;
                  this.today = dates.EndDate;
                }
            } else {
                this.startdate = this.today;
            }
        });
        this.verifyStatus = this.getVerifyStatusValues();
        this.createFG();
        this.show();
        this.setDefaultValues();
        this.getNVInfo();
        this.setAppliedFilters();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "drugVerfWJ1" || w["WijmoKey"] === "drugVerfWJ2")) {
                this.patchDefaultValHeaders();
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugVerfWJ1");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugVerfWJ1", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("drugVerfWJ2");
        this.wjHeaders1 = this._wijimoUtils.patchDefHeader("drugVerfWJ2", storedWJ1);
    }

    ngAfterViewInit() {
        if (this.dpverifytabs && this.routeFrom === "Dashboard") {
           // this.dpverifytabs.select("Patient1");
        }
    }

    setAppliedFilters() {
        const values: NotVerifyFilters = this.NotVerifyFG.value;
        if (values) {
            this.drugVerifyFilters.FromDate = values.FromDate;
            this.drugVerifyFilters.ToDate = values.ToDate;
            this.drugVerifyFilters.VerifyStatus = _.filter(this.verifyStatus, {
                value: values.VerifyStatus
            });
            this.drugVerifyFilters.IsCurrentUser = values.IsCurrentUser;
            this.drugVerifyFilters.ShowPendingRx = values.ShowPendingRx;
        }
    }

    setDefaultValues() {
        this.NotVerifyFG.controls["FromDate"].patchValue(this.startdate);
        this.NotVerifyFG.controls["ToDate"].patchValue(this.today);
        let verifyStatus = (this.routeFrom === "Dashboard") ? this.PASSED : this.ALL;
        this.NotVerifyFG.controls["VerifyStatus"].patchValue(verifyStatus);
        this.NotVerifyFG.controls["IsCurrentUser"].patchValue(false);
        this.NotVerifyFG.controls["ShowPendingRx"].patchValue(true);
    }

    createFG() {
        this.NotVerifyFG = this._fb.group(new NotVerifyFilters());
        this.RxNumFG = this._fb.group({
            rxnum: null
        });
        this.groupFG = this._fb.group({
            patientGrp: [],
            DrugGrp: []
        });
        this.groupVerfFG = this._fb.group({RxGrp: []});
    }

    reset() {
        this.NotVerifyFG.reset();
        this.groupFG.reset();
        this.RxNumFG.reset();
        this.groupVerfFG.reset();
        this.NotVerifyFG.markAsUntouched();
        this.setDefaultValues();
        this.WijimoGroupBy();
        this.WijimoGroupByVerf();
        this.getNVInfo();
    }

    getVerifyStatusValues() {
        return [
            {
                status: `All`,
                value: this.ALL
            },
            {
                status: `Verified/Re-Verified`,
                value: this.PASSED
            },
            {
                status: `Re-verify`,
                value: "rv"
            }
        ];
    }

    show() {
        this.hideFilterForm = !this.hideFilterForm;
    }

    beforeChange(nextId: string) {
        if (nextId) {
            this.activeTabId = nextId;
            if (nextId === "Patient1") {
                this.nVpageSize = this.paginationModel.pageSize;
                this.notVerified = true;
                this.getNVInfo();
                this.show();
                this.verifyHistory = false;
            } else if (nextId === "Patient2") {
                this.Size = this.paginationModel.pageSize;
                this.verifyHistory = true;
                this.getVerifyInfo();
                this.notVerified = false;
            }
        }
    }

    focusOutFrCancel(event) {
        if ((this.notVerified && !this.totalCount) || (this.verifyHistory && !this.HisttotalCount)) {
            this.focusOnFirst(event);
        }
    }

    focusOnFirst(event) {
        if (document.getElementById("RxNumber")) {
            event.preventDefault();
            document.getElementById("RxNumber").focus();
        }
    }

    async getVerifyInfo() {
        if (this.verifyHistory) {
            this.NotVerifyFG.controls["StartIndex"].setValue(
                this.HiststartIndex
            );
            this.NotVerifyFG.controls["MaxRecords"].setValue(this.Size);
        }
        await this._drgVerifyServ
            .sendVerifiedFilters(this.NotVerifyFG.value).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.verfList = resp?.RxCountForDrugPick && resp?.RxCountForDrugPick?.DrugPickHistoryCount ? resp.RxCountForDrugPick.DrugPickHistoryCount : 0;;
                if (resp) {
                    this.verfHistList = {...resp};
                    console.log(this.verfHistList, "Lsit11")
                    this.generateVerifyHist(this.verfHistList);
                }
            });
    }

    getRxDrugInfo() {
        this.searchRX = false;
        // this.showRxDetails = true;
        const modalRefVerfTab = this.modalService.open(VerificationTabsListComponent, {size: "lg",  windowClass: "md-xx-screenWidth comp--modal modal-large modal-center-to-page", backdrop: false});
        modalRefVerfTab.componentInstance.RxInfo = this.rxInfo;
        modalRefVerfTab.componentInstance.IsPatientHistory = true;
        modalRefVerfTab.componentInstance.activeTabId = "dpvVerfTab";
        modalRefVerfTab.componentInstance.closeTabs.pipe(takeUntil(this.unsubscribe$)).subscribe((x: any) => {
            modalRefVerfTab.close();
            this.changeTab(x);
        });
    }

    changeTab(val?: any) {
        if (val) {
            this.showFilters = true;
            this.show();
            this.searchRX = true;
            this.showRxDetails = false;
            this.RxNumFG.reset();
            this.getNVInfo();
        }
    }

    getRxDetails() {
        let num = null;
        if (this.RxNumFG.value.rxnum) {
            num = this.RxNumFG.value.rxnum.split("-");
        }
        if (num && num.length) {
            this._drgVerifyServ
                .getRxDetails(num[0], num[1], num[2] ? num[2] : 0).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    this.checkIsDrugPickReady(resp);
                });
        }
    }

    checkIsDrugPickReady(data, proceed?: any) {
        if(this.modalRef){
            this.modalRef.close();
            this.modalRef = null;
        }
        if (data && data.Prescription && data.Prescription.Id !== -1) {
            // const orginal = parseInt(this._commonService.getSetttingValue("WorkFlowSettings",
            // "Require_Rx_Verification_for_Original_Rx_Only"), 0);
           const beforeDPV = parseInt(this._commonService.getSetttingValue("WorkFlowSettings", "Rx_Verification_Required_Before_DPV"), 0);
           const reqRxVerfStng = parseInt(this._commonService.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        //    const workFlwPatStng = parseInt(this._commonService.getSetttingValue("WorkFlowSettings","Require_Rx_Verification_for_workflow_patient_only"), 0);
            if (this.RxNumFG) {
                this.RxNumFG.reset();
            }
            if (data && data.drugPickVerificationStatus && (
                data.drugPickVerificationStatus.trim().toUpperCase() === "P") && !proceed
            ) {
                this.rxInfo = data;
                this.modalRef = this.modalService.open(this.alreadyVerified, { centered: true, keyboard: false, backdrop: false,
                    windowClass: "large--content"});
            } else if (data && (data.PrescReFill.StatusId === 4 || data.Prescription.RxStatusId === 4)) {
                this._alertService.error("This is a Transfered Rx, it cannot be verified.");
            } else if (data && (data.PrescReFill.StatusId === 2 || data.Prescription.RxStatusId === 2)) {
                this._alertService.error("This is a Deferred Rx, it cannot be verified.");
            } else if (data &&
                data.RxBill.StatusId === 1 &&
                this._commonService.getSetttingValue("WorkFlowSettings", "ALLOW_DRUG_PICK_VERF_UNB_RX") === "0"
            ) {
                this._alertService.error("Rx is unbilled.");
            } else if (reqRxVerfStng) {
                // if (workFlwPatStng) {
                //     if (data.Patient.IsWorkflowPatient && orginal && beforeDPV && !(data.rph1VerificationStatus) &&
                //      (data && (data["PrescReFill"]["ReFillNum"]) === 0)) {
                //         this._alertService.error("Rx  verification is required before Drug pick verification.");
                //     } else if (data.Patient.IsWorkflowPatient && !(orginal) && beforeDPV && !(data.rph1VerificationStatus)) {
                //         this._alertService.error("Rx  verification is required before Drug pick verification.");
                //     } else {
                //         this.getRxDrugInfo();
                //         this.showFilters = false;
                //         this.rxInfo = data;
                //     }
                // } else if (orginal && beforeDPV && !(data.rph1VerificationStatus) && (data && (data["PrescReFill"]["ReFillNum"]) === 0)) {
                //     this._alertService.error("Rx  verification is required before Drug pick verification.");
                // } else
                 if (beforeDPV && !(data.rph1VerificationStatus)) {
                    this._alertService.error("Rx  verification is required before Drug pick verification.");
                } else if (data && data.Prescription && data.Prescription.IsDiscontinued) {
                    this.dataBrDisContinueCheck = data;
                    this.modalRef = this.modalService.open(this.RxDiscontinued, { centered: true, keyboard: false,
                         backdrop: false, windowClass: "large--content"});
                } else {
                    this.rxInfo = data;
                    this.getRxDrugInfo();
                    this.showFilters = false;
                    
                }
            } else if (data && data.Prescription && data.Prescription.IsDiscontinued) {
                this.dataBrDisContinueCheck = data;
                this.modalRef = this.modalService.open(this.RxDiscontinued, { centered: true, keyboard: false,
                     backdrop: false, windowClass: "large--content"});
            } else {
                this.rxInfo = data;
                this.getRxDrugInfo();
                this.showFilters = false;
            }
        } else {
            this.warningInfo = "Rx not found.";
            this.modalRef = this.modalService.open(this.invalidRx, { centered: true, backdrop: false,
                 keyboard: false, windowClass: "large--content"});
        }
    }

    async setOrderStatus() {
        await this._rxService.putRxDiscontinuedOrderStatus(this.dataBrDisContinueCheck.Prescription.Id).toPromise();
        this.rxInfo = this.dataBrDisContinueCheck;
        this.getRxDrugInfo();
        this.showFilters = false;
        
    }

    init(flex: wjcGrid.FlexGrid) {
        this.filedQueueFlex = flex;
        flex.collapseGroupsToLevel(0);
    }

    initnv(flex: wjcGrid.FlexGrid) {
        flex.columns[0].align = "center";
    }

    loadedRows(grid) {
        grid.collapseGroupsToLevel(0);
    }

    generateNotVerify(drugNotVerified: any) {
        this.totalCount = drugNotVerified.RecordCount;
        this.totalRx = drugNotVerified.RxCountForDrugPick.Total;
        this.notVerifiedRx = drugNotVerified.RxCountForDrugPick.NotVerified;
        this.VerifiedRx = drugNotVerified.RxCountForDrugPick.Verified;
        if (drugNotVerified && drugNotVerified.ESRxViewModel && drugNotVerified.ESRxViewModel.length) {
            this.isDataExists = true;
            const mapDataSet = [];
                drugNotVerified.ESRxViewModel.map((drugNV, i) => {
                    const j = {};
                    j["row"] = i;
                    j["data"] = drugNV;
                    j["Status"] =  (drugNV.PrescReFill.StatusId === 4 || drugNV.Prescription.RxStatusId === 4) ? "T" :
                    (drugNV.PrescReFill.StatusId === 2 || drugNV.Prescription.RxStatusId === 2 || drugNV.RxBill.StatusId === 6) ? "D" :
                    drugNV.PrescReFill.StatusId === 3 && drugNV.RxBill.StatusId === 2 ? "BI" :
                    (drugNV.RxBill.StatusId === 2 &&
                      drugNV.PrescReFill.StatusId !== 4 && drugNV.PrescReFill.StatusId !== 2) ? "B" :
                    (drugNV.PrescReFill.StatusId === 3 && drugNV.RxBill.StatusId === 1) ? "UI" : "U";
                    j["DrugClass"] = drugNV.Drug.drugclass;
                    const days = +this._commonService.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
                    const isDrugDiscontinued = drugNV.Drug["discontdt"] && moment().startOf("day")
                        .isAfter(moment(drugNV.Drug["discontdt"], "MM/DD/YYYY").add(days, "days"));
                    j["IsDrugDis"] = isDrugDiscontinued ? "YES" : "NO";
                    j["Rx#"] = drugNV.Prescription.PrescNum;
                    j["Ref#"] = drugNV.PrescReFill.ReFillNum;
                    j["Fill#"] = this._commonService.checkIsPartialFill(drugNV.PrescReFill, drugNV.Prescription) ? drugNV.PrescReFill.PartialFillNo : " ";
                    j["PrescRefillId"] = drugNV.PrescReFill.Id ? drugNV.PrescReFill.Id : null;
                    j["Patient"] =
                        drugNV.Patient.lastname +
                        ", " +
                        drugNV.Patient.firstname;
                    j["Prescriber"] =
                        drugNV.Prescriber.prescriberlastname +
                        ", " +
                        drugNV.Prescriber.prescriberfirstname;
                    j["Drug"] =
                        drugNV.Drug.drugname +
                        " " +
                        drugNV.Drug.strength +
                        " " +
                        drugNV.Drug.drugformname;
                        
                    j["NDC"] =  this._formatsUtil.getNDCFormat(drugNV.Drug.ndc);
                    j["Qty"] = drugNV.PrescReFill.OrdQty ? (drugNV.PrescReFill.OrdQty).toFixed(3) : "0.000";
                    j["Fill Date"] =(drugNV.PrescReFill.FillDtTm) ? moment.utc(drugNV.PrescReFill.FillDtTm).local().format(
                        "MM/DD/YY hh:mm:ss a"
                    ) : "";
                    j["IsPicked"] = (drugNV.pickupRx && drugNV.pickupRx.IsPickedUp) ? true : false;
                    j["Qty in hand"] = this._buckUtils.getQtyInHand(drugNV.Drug.manageinventory ? drugNV.Drug.manageinventory : null, drugNV.PrescReFill.BucketId);
                    j["IsBilled"] = this._comnUtil.checkIsGreenBilled(drugNV, this.systemData);
                    j["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(j);
                    j["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(j);
                    const rowId = j["row"] >= 0 ? j["row"] : "";
                    const RxDetails = rowId >= 0 ?  drugNotVerified.ESRxViewModel[rowId] : null;
                    j["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
                    // return j;
                    mapDataSet.push(j)
                }),
                this.notVerifyListWJ = new CollectionView(mapDataSet,
                    {
                        groupDescriptions: this.groupByNames,
                        sortComparer: (a: any, b: any) => {
                            return moment(a).isValid() && moment(b).isValid()
                                ? moment(a).diff(moment(b)) : null;
                        }
                    })
        } else {
            this.isDataExists = false;
            this.notVerifyListWJ = new CollectionView(null)
        }
    }

    routeToEditRx(item) {
        if (item) {
            this.selectedrxNo = item["Rx#"];
            this.selectedrxId = item["PrescRefillId"];
        }
        if (this._privMaskGuard.canActivate(AccCategoryE.Rx,RxE.EditRx)) {
            this.routeToRx();
        } else {
            this.openOveride("EditRx");
        }
    }

    async routeToRx(val?: object) {
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(+this.selectedrxNo, this.selectedrxId, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
        }
        const modalRef = this.modalService.open(EdRxComponent,
            {
                backdrop: false,
                size: "lg",
                windowClass: "md-xx-lg comp--modal",
                keyboard: false
            }
        );
        modalRef.componentInstance.SelectedValue = (val && (val["isNPPatientValue"] || val["isNPPatientValue"] === "false")) ? val["isNPPatientValue"] : "true";
        modalRef.componentInstance.CloseEditMode.pipe(takeUntil(this.unsubscribe$))
            .subscribe(x => {
                modalRef.close();
                if (x) {
                    if (this.notVerified) {
                        this.getNotVerifyData();
                    } else if (this.verifyHistory) {
                        this.getVerifyInfo();
                    }
                }
            });
        modalRef.componentInstance.CloseAndReopen.pipe(takeUntil(this.unsubscribe$))
            .subscribe(d => {
                modalRef.close();
                if (d) {
                    this.selectedrxNo = d["rxNo"];
                    this.selectedrxId = d["rfId"];
                    this.routeToRx(d);
                } else {
                    if (this.notVerified) {
                        this.getNotVerifyData();
                    } else if (this.verifyHistory) {
                        this.getVerifyInfo();
                    }
                }
            });
    }

    openOveride(content) {
        this.privType = content;
        this.categoryId = "DPV";
        this.openOverride = true;
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
        }
    }

    loadedWJRows(flex: wjcGrid.FlexGrid) {
        flex.collapseGroupsToLevel(0);
    }


  async  getNVInfo() {
        await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.NotVerifyFG, "FromDate", "ToDate", 0);
       if (resp === "DATEISSUE") {
            this._alertService.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertService.error(MsgConfig.INVALID_DATES_ERROR);
        } else {
            if (moment(this.RxNumFG.value.FromDate, "MM/DD/YYYY") > moment(this.RxNumFG.value.ToDate, "MM/DD/YYYY")) {
                this._alertService.error(MsgConfig.DATE_RANGE_ERROR);
                return;
            }
            this.show();
            this.setAppliedFilters();
            this.getNotVerifyData();
        }
    }

    getNotVerifyData() {
        const technician = this._userService.getToken("TechUserName");
        if (!technician) {
            this.userName = this._userService.getToken("PhUserName");
        } else {
            this.userName = this._userService.getToken("TechUserName");
        }
        this.NotVerifyFG.controls["UserName"].setValue(this.userName);

        if (this.notVerified) {
            this.NotVerifyFG.controls["StartIndex"].setValue(this.startIndex);
            this.NotVerifyFG.controls["MaxRecords"].setValue(this.nVpageSize);
            this._drgVerifyServ
                .sendNotVerifiedFilters(this.NotVerifyFG.value).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    this.notVerfList = resp && resp.ESRxViewModel &&  resp.ESRxViewModel.length ? resp.ESRxViewModel.length : 0;
                    if (resp) {
                        this.notyetVerified = resp;
                        this.generateNotVerify(resp);
                    }
                });
        } else if (this.verifyHistory) {
            this.NotVerifyFG.controls["StartIndex"].setValue(
                this.HiststartIndex
            );
            this.NotVerifyFG.controls["MaxRecords"].setValue(this.Size);
            this.getVerifyInfo();
        }
    }

    setNVSize(size: any) {
        this.nVpageNumber = 1;
    this.nVpageSize = size ;
    this.NotVerifyFG.controls["StartIndex"].setValue(this.nVpageNumber);
    this.NotVerifyFG.controls["MaxRecords"].setValue(this.nVpageSize);
    this.setNVPage(1);
    }

    setNVPage(page: number) {
        this.nVpageNumber = page;
        this.startIndex = (this.nVpageSize * (this.nVpageNumber - 1));
        this.NotVerifyFG.controls["StartIndex"].setValue(this.startIndex);
        this.getNotVerifyData();
    }

    setPage(event: any) {
        this.pageNu = event;
        this.HiststartIndex = this.Size * (this.pageNu - 1);
        this.NotVerifyFG.controls["StartIndex"].setValue(this.HiststartIndex);
        this.getVerifyInfo();
    }

    setSize(size: any) {
        this.Size = size;
        this.NotVerifyFG.controls["MaxRecords"].setValue(this.Size);
        this.getVerifyInfo();
    }

    generateVerifyHist(drugVerifyHist: any) {
        this.HisttotalCount = drugVerifyHist?.RxCountForDrugPick && drugVerifyHist?.RxCountForDrugPick?.DrugPickHistoryCount ? drugVerifyHist.RxCountForDrugPick.DrugPickHistoryCount : 0;
        this.totalRx = drugVerifyHist?.RxCountForDrugPick?.Total;
        this.notVerifiedRx = drugVerifyHist?.RxCountForDrugPick?.NotVerified;
        this.VerifiedRx = drugVerifyHist?.RxCountForDrugPick?.Verified;
        if(drugVerifyHist && drugVerifyHist.ESRxViewModel && drugVerifyHist.ESRxViewModel.length){
        const mapDataSet = [];
            drugVerifyHist.ESRxViewModel.map((History, i) => {
                const msgAry = new Array<DrugVerifiedHistory>();
                const j = {};
                j["row"] = i;
                j["Status"] = (History.PrescReFill.StatusId === 4 || History.Prescription.RxStatusId === 4) ? "T" :
                    (History.PrescReFill.StatusId === 2 || History.Prescription.RxStatusId === 2 || History.RxBill.StatusId === 6) ? "D" :
                        History.PrescReFill.StatusId === 3 && History.RxBill.StatusId === 2 ? "BI" :
                            (History.RxBill.StatusId === 2 &&
                            History.PrescReFill.StatusId !== 4 && History.PrescReFill.StatusId !== 2) ? "B" :
                                (History.PrescReFill.StatusId === 3 && History.RxBill.StatusId === 1) ? "UI" : "U";
                j["DrugClass"] = History.Drug.drugclass;
                const days = +this._commonService.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
                const isDrugDiscontinued = History.Drug["discontdt"] && moment().startOf("day")
                    .isAfter(moment(History.Drug["discontdt"], "MM/DD/YYYY").add(days, "days"));
                j["IsDrugDis"] = isDrugDiscontinued ? "YES" : "NO";
                j["Rx#"] = History && History.Prescription && History.Prescription.PrescNum ? History.Prescription.PrescNum.toString() : "";
                j["Pharmacist"] = History.PrescReFill.RegPharmacist ? History.PrescReFill.RegPharmacist : History.Prescription.UserName;
                j["Ref#"] = History.PrescReFill.ReFillNum;
                j["Fill#"] = this._commonService.checkIsPartialFill(History.PrescReFill, History.Prescription) ? History.PrescReFill.PartialFillNo : " ";
                j["Patient Name"]= (History.Patient.lastname + ", " + History.Patient.firstname).toUpperCase();
                j["PrescRefillId"] = History.PrescReFill.Id ? History.PrescReFill.Id : null;
                j["Tech"] = History.PrescReFill.TechInitials ? History.PrescReFill.TechInitials.toUpperCase() : null;
                j["DrugNDC"] = j["Drug NDC"] = (History && History.drugPickVerif[0].BottleNDC) ? this._formatsUtil.getNDCFormat(History.drugPickVerif[0].BottleNDC): "";
                j["Lot#"] = (History && History.drugPickVerif[0].LotNum) ? (History.drugPickVerif[0].LotNum) : "";
                j["Lot Expiration Date"] = j["LotExpiryDtTm"] = (History && History.drugPickVerif[0].LotExpiryDtTm) ? moment.utc(
                    History.drugPickVerif[0].LotExpiryDtTm
                ).local().format("MM/DD/YYYY") : "";
                j["GTIN#"] = j["gtin"] = (History && History.drugPickVerif[0].gtin) ? (History.drugPickVerif[0].gtin) : "";
                j["Serial#"] = j["serialNo"] = (History && History.drugPickVerif[0].serialNo) ? (History.drugPickVerif[0].serialNo): "";
                j["Date Time Verified"] = (History && History.drugPickVerif[0].VerifDtTm) ? moment.utc(
                    History.drugPickVerif[0].VerifDtTm
                ).local().format("MM/DD/YYYY hh:mm:ss a") : "";
                if (["P", "p", "p ", "P "].indexOf(History.drugPickVerif[0].Result) > -1) {
                    j["Result"] = History.drugPickVerif[0].IsReverified? Verificationsenum.reverified:Verificationsenum.verified;
                } else if ((History.drugPickVerif[0].Result).trim().toLowerCase() === "rv") {
                    j["Result"] = "Re-Verify";
                } else {
                    j["Result"] = "Failed";
                }
                j["Drug"] =
                    History.Drug.drugname +
                    " " +
                    History.Drug.strength +
                    " " +
                    History.Drug.drugformname;
                j["Qty In Hand"] = (History && History.drugPickVerif[0].QtyInHand) ? (History.drugPickVerif[0].QtyInHand).toFixed(3): "0.000";
                j["IsPicked"] = (History.pickupRx && History.pickupRx.IsPickedUp) ? true : false;
                j["Qty Dispensed"] = (History && History.drugPickVerif[0].VerifiedQty) ? (History.drugPickVerif[0].VerifiedQty).toFixed(3): (History.PrescReFill.DispQty ? (History.PrescReFill.DispQty).toFixed(3) :"0.000");
               /* const data = History.drugPickVerif;
                data.splice(0, 1);
                // tslint:disable-next-line:forin
                for (const key in data) {
                    const drugHistInfo = new DrugVerifiedHistory();
                    drugHistInfo.IsPicked = data[key].IsPicked;
                    drugHistInfo.Rx = History.Prescription.PrescNum;
                    drugHistInfo.Pharmacist = History.PrescReFill.RegPharmacist;
                    drugHistInfo.Ref = History.PrescReFill.ReFillNum;
                    drugHistInfo.Tech = data[key].UserName;
                    drugHistInfo.DrugNDC = data[key].BottleNDC;
                    drugHistInfo.DateVerified = (data[key].VerifDtTm) ? moment.utc(
                        data[key].VerifDtTm
                    ).local().format("MM/DD/YY hh:mm:ss a") : "";
                    if (["P", "p", "p ", "P "].indexOf(data[key].Result) > -1) {
                        drugHistInfo.Result = "Passed";
                    } else if (data[key].Result === "RV") {
                        drugHistInfo.Result = "Re-verify";
                    } else {
                        drugHistInfo.Result = "Failed";
                    }
                    drugHistInfo.Drug =
                        History.Drug.drugname +
                        " " +
                        History.Drug.strength +
                        " " +
                        History.Drug.drugformname;
                    drugHistInfo.Qtyinstock = null;
                    drugHistInfo.QtyOrd = History.PrescReFill.DispQty ? (History.PrescReFill.DispQty).toFixed(3) : "0.000";
                    msgAry.push(drugHistInfo);
                }
                j["items"] = msgAry;*/
                j["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(j);
                const rowId = j["row"] >= 0 ? j["row"] : "";
                const RxDetails = rowId >= 0 ?  drugVerifyHist.ESRxViewModel[rowId] : null;
                j["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
                // return j;
                mapDataSet.push(j)
            })
            this.VerifyHistListWJ = new CollectionView(mapDataSet,
                {
                    groupDescriptions: this.groupByNamesVerf,
                    sortComparer: (a: any, b: any) => {
                        return moment(a).isValid() && moment(b).isValid()
                            ? moment(a).diff(moment(b)) : null;
                    }
                });
                if (this.HisttotalCount) {
                    this.isDataExists2 = true;
                } else {
                    this.isDataExists2 = false;
                    this.VerifyHistListWJ = new CollectionView(null)
                }
            } else {
                this.isDataExists2 = false;
                this.VerifyHistListWJ = new CollectionView(null)
            }
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
               cell.style.fontWeight = "bold";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
               cell.style.fontWeight = "bold";
               cell.style.textAlign = "center";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const rowId = row && row.dataItem ? row.dataItem["row"] : "";
                let RxDetails: any;
                if (row.dataItem && row.dataItem.Result) {
                     RxDetails = rowId >= 0 ?  this.verfHistList.ESRxViewModel[rowId] : null;
                } else {
                    RxDetails = rowId >= 0 ?  this.notyetVerified.ESRxViewModel[rowId] : null;
                }
                const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx");
                if (colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else  {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }

    keyEventOnWijimo(grid, e) {
        e.preventDefault();
        this.checkIsDrugPickReady(grid.selectedItems[0].data);
    }

    toggleColorLegend() {
        this._alertService.showHelpText({RxStatus: true, DrugName: true, Status: true});
    }


    focusToFirst(event) {
        if (document.getElementById("RxNumber")) {
            event.preventDefault();
            this._cdr.detectChanges();
            document.getElementById("RxNumber").focus();

        }
    }

    WijimoGroupBy(flex?: wjcGrid.FlexGrid) {
        const groupByNames = [];
        const cntrlName = [{ cntrlN: "patientGrp", headN: "Patient" }, { cntrlN: "DrugGrp", headN: "Drug" }];
        cntrlName.map(val => {
            if (this.groupFG.value[val["cntrlN"]]) {
                groupByNames.push(val["headN"]);
            }
        });
        this.groupByNames = groupByNames;
        this.generateNotVerify(this.notyetVerified);
        if(this.flex)
            this.flex.collapseGroupsToLevel(0);
    }
    WijimoGroupByVerf(flex?: wjcGrid.FlexGrid) {
        const groupByNames = [];
        const cntrlName = [{ cntrlN: "RxGrp", headN: "Rx#" }];
        cntrlName.map(val => {
            if (this.groupVerfFG.value[val["cntrlN"]]) {
                groupByNames.push(val["headN"]);
            }
        });
        this.groupByNamesVerf = groupByNames;
        this.generateVerifyHist(this.verfHistList);
        if(this.Histflex)
            this.Histflex.collapseGroupsToLevel(0);
    }

    updateWJView(flex: wjcGrid.FlexGrid) {
        this.selectedFiledWJ = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
        if (this.groupByNames && this.groupByNames.length > 0) {
            flex.columns.map((val, i) => {
                if (this.groupByNames.includes(val.header)) {
                    flex.columns[i].visible = false;
                } else {
                    flex.columns[i].visible = true;
                }
            });
        } else {
            const columnHeader = ["Patient", "Drug"];
            flex.columns.map((val, i) => {
                if (columnHeader.includes(val.header) && val.isVisible) {
                    flex.columns[i].visible = true;
                }
            });
        }
    }
    updateVerfWJView(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        if (this.groupByNamesVerf && this.groupByNamesVerf.length > 0) {
            flex.columns.map((val, i) => {
                if (this.groupByNamesVerf.includes(val.header)) {
                    flex.columns[i].visible = false;
                } else {
                    flex.columns[i].visible = true;
                }
            });
        } else {
            const columnHeader = ["Rx#"];
            flex.columns.map((val, i) => {
                if (columnHeader.includes(val.header) && !val.isVisible) {
                    flex.columns[i].visible = true;
                }
            });
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
