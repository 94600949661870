    <div class="modal-header">
        <h4 class="modal-title">Control File Creation Scheduling Tasks</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body height_51rem">
            <div class="eprx--block__content">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 refill-due-report-height--top">
                         <div class="row">
                            <div class="col-md-6">
                                <app-rx-select [LabelText]="'From Date'" [PlaceHolder]="''" [ControlName]="'CntrlFromDate'"
                                    [FormGroupName]="autoSubmissionFG" [HasMultiple]="false" [HasBindLabel2]="false" [LabelForId]="'Name'"
                                    [List]="fromDueDateList" [BindLabel]="'Name'" [BindValue]="'Value'" [LabelForId]="'Name'" (TriggerSelectValue)="setForFromDate()">
                                </app-rx-select>
                            </div>
                            <div class="col-md-6" *ngIf="(autoSubmissionFG?.controls['CntrlFromDate']?.value=== 'd' ||
                                                        autoSubmissionFG?.controls['CntrlFromDate']?.value=== 'f')">
                                <eprx-date-picker [RxSelectId]="'FromDt'" [AutoFocus]="true" [LabelText]="''" [PlaceHolder]="''"
                                    [ControlName]="'FromDt'" [FormGroupName]="autoSubmissionFG" [MinDate]="" [MaxDate]=""
                                    [IsDisabled]="autoSubmissionFG?.controls['CntrlFromDate']?.value=== 'd'?true:false">
                                </eprx-date-picker>
                            </div>
                            <div class="col-md-5"
                                *ngIf="(autoSubmissionFG?.controls['CntrlFromDate']?.value=== 'd-')">
                                <!-- autoSubmissionFG?.controls['CntrlFromDate']?.value=== 'd+' || -->
                                <div class="row">
                                    <div class="col-md-10">
                                        <eprx-input class="position-relative top_n19px" [LabelText]="'Days'" [PlaceHolder]="'Days'"
                                            [ControlName]="'CntrlFromDueDateDays'" [FormGroupName]="autoSubmissionFG" [HasControl]="true"
                                            [RxSelectId]="'CntrlFromDueDateDays'" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalidDate"
                                            [ErrorDefs]="{required: 'Required'}"  [MaxLength]="4" [InputErrors]="autoSubmissionFG?.controls?.CntrlFromDueDateDays?.errors"
                                             [MarkAsTouched]="autoSubmissionFG?.controls?.CntrlFromDueDateDays?.touched">
                                        </eprx-input>
                                    </div>
                                    <div class="col-md-2 pl-0 pt-4">
                                        <i class="far fa-angle-up text-primary fa-2x refillduefar" (click)="navUpForFromDays()"></i>
                                        <i class="far fa-angle-down text-primary fa-2x refillduefar" (click)="navDownForFromDays()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row grid_custom_timePicker margin_top_1rem">
                        <div class="col-6 btn-time-pick align-time btn-meridian btn-align grid_custom_timePicker checkin-custom--input">
                            <ngb-timepicker [(ngModel)]="time" [meridian]="true" [required]="true"
                                (ngModelChange)="timeValueChange($event, 'FromTime')">
                            </ngb-timepicker>
                            </div>
                        </div>
                        <div class="col-3 margin_top_1_4rem" [formGroup]="autoSubmissionFG" >
                            <input class="form-check-input" type="checkbox" [id]="10" [checked]="true" formControlName="IncRxResubmission"/>
                            <label class="form--check--label--2" [for]="10" id="'incRx'">
                                Include Control Rx From Resubmission List
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 refill-due-report-height--top">
                            <div class="row">
                                <div class="col-md-6">
                                    <app-rx-select [LabelText]="'To Date'" [PlaceHolder]="''" [ControlName]="'CntrlToDate'"
                                        [FormGroupName]="autoSubmissionFG" [HasMultiple]="false" [HasBindLabel2]="false" [LabelForId]="'Name'"
                                        [List]="fromDueDateList" [BindLabel]="'Name'" [BindValue]="'Value'" [LabelForId]="'Name'"
                                        (TriggerSelectValue)="setForDueDate($event)">
                                    </app-rx-select>
                                </div>
                                <div class="col-md-6" *ngIf="(autoSubmissionFG?.controls['CntrlToDate']?.value=== 'd' || autoSubmissionFG?.controls['CntrlToDate']?.value=== 'f')">
                                    <eprx-date-picker [RxSelectId]="'ToDt'" [AutoFocus]="true" [LabelText]="''" [PlaceHolder]="''"
                                        [ControlName]="'ToDt'" [FormGroupName]="autoSubmissionFG" [MinDate]="" [MaxDate]=""
                                        [IsDisabled]="autoSubmissionFG?.controls['CntrlToDate']?.value=== 'd'? true: false">
                                    </eprx-date-picker>
                                </div>
                                <div class="col-md-5"
                                    *ngIf="(autoSubmissionFG?.controls['CntrlToDate']?.value=== 'd-')">
                                    <!-- autoSubmissionFG?.controls['CntrlToDate']?.value=== 'd+' || -->
                                    <div class="row">
                                        <div class="col-md-10">
                                            <eprx-input class="position-relative top_n19px" [LabelText]="'Days'" [PlaceHolder]="'Days'"
                                                [ControlName]="'CntrlToDueDateDays'" [FormGroupName]="autoSubmissionFG" [HasControl]="true"
                                                [RxSelectId]="'CntrlToDueDateDays'" [IsRequired]="true"
                                                [FormGroupInvalid]="formGroupInvalidDate" [ErrorDefs]="{required: 'Required'}" [MaxLength]="4"
                                                [InputErrors]="autoSubmissionFG?.controls?.CntrlToDueDateDays?.errors"
                                                [MarkAsTouched]="autoSubmissionFG?.controls?.CntrlToDueDateDays?.touched">
                                            </eprx-input>
                                        </div>
                                        <div class="col-md-2 pl-0 pt-4">
                                            <i class="far fa-angle-up text-primary fa-2x refillduefar" (click)="navUpForToDueDays()"></i>
                                            <i class="far fa-angle-down text-primary fa-2x refillduefar" (click)="navDownForToDueDays()"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row grid_custom_timePicker margin_top_1rem">
                            <div class="col-6 btn-time-pick align-time btn-meridian btn-align grid_custom_timePicker checkin-custom--input">
                                <ngb-timepicker [(ngModel)]="time2" [meridian]="true" [required]="true"
                                    (ngModelChange)="timeValueChange($event, 'ToTime')">
                                </ngb-timepicker>
                            </div>
                        </div>
                        <div class="col-3 margin_top_1_4rem" [formGroup]="autoSubmissionFG"  >
                            <input class="form-check-input" type="checkbox" [id]="11" formControlName="IncRxNotSubmitted"/>
                            <label class="form--check--label--2" [for]="11" id="'submitRxs'">
                                Submit Only control Rxs Not yet submitted
                            </label>
                        </div>
                    </div>
                </div><br/>
                <!-- <div class="col-12 top-rem"> -->
                    <!-- <div class="row">
                    <div class="col-6" [formGroup]="autoSubmissionFG" >
                        <input class="form-check-input" type="checkbox" [id]="10" formControlName="IncRxResubmission"/>
                        <label class="form-check-label" [for]="10" id="'incRx'">
                            Include Control Rx From Resubmission List
                        </label>
                    </div> -->
                    <!-- <div class="col-6 right-rem" [formGroup]="autoSubmissionFG" >
                        <input class="form-check-input" type="checkbox" [id]="10" formControlName="IncRxNotSubmitted"/>
                        <label class="form-check-label" [for]="10" id="'submitRxs'">
                            Submit Only control Rxs Not yet submitted
                        </label>
                    </div> -->
                <!-- </div> -->
                <!-- </div> -->


            </div>
            <div class="col-md-12">
                <wj-flex-grid #automaticSubmission [headersVisibility]="'Column'" [itemsSource]="automatSubWJ" [isReadOnly]="true" [columnPicker]="'automatSubWj'" [selectionMode]="'None'">
                    <wj-flex-grid-column [width]="85" [header]="'Select'">
                        <ng-template wjFlexGridCellTemplate #dp="wjFlexGridCellTemplate" [cellType]="'ColumnHeader'">
                            <span>
                                <input class="form-check-input" type="checkbox" id="i" name="" [(ngModel)]="allSelect"
                                    (click)="checkAll($event?.target?.checked,dp)" [checked]="allSelect" />
                                <label class="form-check-label" for="i"></label>
                            </span>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'"  #dp="wjFlexGridCellTemplate" let-item="item" let-cell="cell">
                            <span class="text-center">
                                <input class="form-check-input left_0_37_rem" type="checkbox" [id]="item.RowId" name=""
                                    [(ngModel)]="item.IsSelected" (click)="selectedRx($event?.target?.checked,item['StateId'],dp)" [disabled]="!(item.IsActive)"
                                    [checked]="item.IsSelected" />
                                <label class="form-check-label" [for]="item.RowId"></label>
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                    [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index" [width]="rxheader['width']">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div [title]="rxheader['hName']" *ngIf="rxheader['hName'] !== 'File WebSite'">
                                {{item[rxheader['hName']]}}
                            </div>
                            <div class="no-text-Transform" [title]="rxheader['hName']" *ngIf="rxheader['hName'] === 'File WebSite'">
                                {{item[rxheader['hName']]}}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
        </div>

    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="saveParameterDtls();close()" appShortcutKey
                [AltKey]="'o'">
                    <span>O</span> Ok
                </button>
                <button footer class="hotkey_success" (click)="close()" appShortcutKey [AltKey]="'c'">
                    <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>

