import { Component, OnInit, Output, EventEmitter, Input, OnChanges, AfterViewInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from "rxjs/operators";
import { CommonService, AlertService } from "../../../services";
import { State } from "../../../models";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ValidateFormFieldsUtils } from "../../../utils";

@Component({
    selector: "app-search-state",
    templateUrl: "./search-state.component.html"
})
export class SearchStateComponent implements OnInit, OnChanges, AfterViewInit {
    States: any;
    stateSelected: any;
    stateInfo: any;
    inputFormGroup: FormGroup;
    custContrlName: string;
    cityContrlName: any;
    isRequired = false;
    inputErrors: any;
    errorDefs: object;
    errorMessage: string;
    haserror: boolean;
    tabIndex: number;
    stateTouched = false;
    unsubscribe$: Subject<void> = new Subject();

    @Input() autoFocus: boolean;
    @Input() rxSelectId: any;

    @Input()
    set IsRequired(ir: boolean) {
        if (ir) {
            this.isRequired = ir;
        }
    }

    @Input()
    set FormGroupInvalid(ir: boolean) {
        if (ir) {
            this.haserror = ir;
            this.stateTouched = true;
            if (this.haserror) {
                this.validateAllFormFields(
                    this.inputFormGroup,
                    this.custContrlName
                );
            }
        }
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.stateTouched = true;
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        } else {
            this.errorMessage = null;
        }
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set CityControlName(cn: any) {
        this.cityContrlName = cn;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set StateSelected(ss: State) {
        this.stateSelected = ss;
    }
    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }
    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }
    @Output()
    OnStateSelected = new EventEmitter<any>();

    @Output()
    TriggerOnClear = new EventEmitter<any>();

    constructor(private _commonServ: CommonService,  private _validFormFieldsUtils: ValidateFormFieldsUtils,
        private _alertService: AlertService) {}

    ngOnInit() {}

    ngAfterViewInit() {
        if (this.autoFocus && this.rxSelectId && document.getElementById(this.rxSelectId)) {
            document.getElementById(this.rxSelectId).focus();
        }
    }

    onKeydownValue(event?: any): void {
        if (!event.target.value && !(event.ctrlKey || event.altKey)) {
            this.inputFormGroup.controls[this.custContrlName].patchValue(null);
        }
    }

    searchState = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(term => this.searchByState(term))
        );

    searchByState(value: string) {
        this.States = [];
        this.inputFormGroup.controls[this.custContrlName].setValue(null);
        if (value && value !== "") {
            this.States = this._commonServ.getStatesData(value);
        } else {
            this.States = this._commonServ.getEmptyObservable();
            this.inputFormGroup.controls[this.custContrlName].setValue(null);
        }
        this.States.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (!resp || resp.length === 0) {
                this.stateSelected = null;
                this.inputFormGroup.controls[this.custContrlName].setValue(null);
                this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
            }
        });
        return this.States;
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    formatter = (x: any) => {
        return x.Name;
    };

    optedStateValue(val) {
        if (typeof this.cityContrlName === "object") {
            this.inputFormGroup.controls[this.custContrlName].setValue(val.item.Name);
            this.cityContrlName.controls["Value"].setValue(null);
            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
        } else {
            this.inputFormGroup.controls[this.custContrlName].setValue(val.item.Id);
            this.inputFormGroup.controls[this.cityContrlName].setValue(null);
            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
        }
        this.OnStateSelected.emit(val.item);
    }

    async validateAllFormFields(formGroup?: FormGroup, formCntrl?: string) {
        if (this.isRequired) {
            this.errorMessage = await this._validFormFieldsUtils.validateFormFields(formGroup, formCntrl, this.errorDefs);
        }
        if (formGroup && formCntrl && !formGroup.value[formCntrl]) {
            this.TriggerOnClear.emit(null);
        }
    }

    ngOnChanges(changes?: any) {
        if (this.inputFormGroup && this.custContrlName && this.inputFormGroup.controls[this.custContrlName] && this.isRequired) {
            this.inputFormGroup.controls[this.custContrlName].setValidators([Validators.required]);
            this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
        }
        if (this.inputFormGroup && this.cityContrlName && this.inputFormGroup.controls[this.cityContrlName] && this.isRequired) {
            this.inputFormGroup.controls[this.cityContrlName].setValidators([Validators.required]);
            this.validateAllFormFields(this.inputFormGroup, this.cityContrlName);
        }
        if (changes) {
            if (changes.autoFocus && changes.autoFocus.currentValue) {
                if (this.autoFocus && this.rxSelectId && document.getElementById(this.rxSelectId)) {
                    document.getElementById(this.rxSelectId).focus();
                }
            }
        }
    }

    checkIfNull() {
        if (!this.inputFormGroup.value[this.custContrlName]) {
            this.stateSelected = null;
        }
    }
}
