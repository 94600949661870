import { ActivatedRoute } from "@angular/router";
import { CommonService, UserService } from "src/app/services";
import {
    Component,
    OnInit,
    ViewChild,
    EventEmitter,
    Output,
    Input,
    HostListener
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { RxService } from "../../../services";
import * as moment from "moment";
import * as _ from "lodash";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-iou",
    templateUrl: "./iou.component.html"
})
export class IouComponent implements OnInit {
    @ViewChild("IOUPOPUP", { static: true })
    IOUPOPUP: any;
    @ViewChild("DISPQTY", { static: true })
    DISPQTY: any;
    modalRef: any;
    @Output()
    IsPopUpClosed = new EventEmitter<boolean>();
    rxModelFG: FormGroup;
    rxDrugModel: any;
    prescNumber: number;
    TodayDate: any;
    totalQty: any;
    showIOUGrid = false;
    IOUFG: FormGroup;
    today: string;
    minDate: any;
    iouInfo: any;
    IouArray: any;
    actualDisp = 0;
    QtyOwed: number;
    editIOU = [];
    refillNum: any;
    exRxId: any;
    drugName: any;
    drugNDC: any;
    rxInfo: any;
    takenQty: number;
    disableAdd: boolean;
    presc: any;
    cQtyOwed: number;
    modalRefSM: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxDrugInfo(pi: any) {
        this.rxDrugModel = pi;
        this.drugName = this.rxDrugModel.drugname;
        this.drugNDC = this.rxDrugModel.ndc;
    }

    @Input()
    set DrugInfoFromEditDrug(pi: any) {
        if (this.rxDrugModel) {
            return;
        } else {
            this.rxDrugModel = pi;
            this.drugName = this.rxDrugModel.DrugData.DrugName;
            this.drugNDC = this.rxDrugModel.DrugDesc.ndc;
        }
    }

    @Input()
    set RxFG(pi: FormGroup) {
        this.rxModelFG = pi;
        this.totalQty = this.rxModelFG.controls["PrescReFill"].value;
        this.refillNum = this.rxModelFG.controls["PrescReFill"].value["ReFillNum"];
        this.presc = this.rxModelFG.controls["Prescription"].value["PrescNum"];
    }

    @Input()
    set RxInfo(rxInfo: any) {
        this.rxInfo = rxInfo;
        this.totalQty = rxInfo["PrescReFill"];
        this.presc = rxInfo["Prescription"]["PrescNum"];
        this.refillNum = rxInfo["PrescReFill"]["ReFillNum"];
        this.drugName = rxInfo["Drug"]["drugname"];
        this.drugNDC = rxInfo["Drug"]["drugcode"];
    }

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modalRef && this._modalService.hasOpenModals()) { // esc
            if (this.modalRef) {
                this.closeModal();
                event.preventDefault();
            } else if (this.modalRefSM) {
                this.closePopUpModal();
                event.preventDefault();
            }
            // this.modalRef.close();
            // this.modalRef = null;
            // event.preventDefault();
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _rxService: RxService,
        private _fb: FormBuilder,
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private _userService: UserService
    ) {
    }

    ngOnInit() {
        if (!this.rxInfo) {
            if (localStorage.getItem("RxInfo")) {
                const rxInfo = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("RxInfo")));
                if (rxInfo["PrescReFill"]["Id"]) {
                 this.exRxId = rxInfo["PrescReFill"]["Id"];
                 this.presc = rxInfo["Prescription"]["PrescNum"];
                } else {
                 this.exRxId = null;
                 if (!this.exRxId) {
                     this._rxService.getPrescriptionId(0)
                     .pipe(takeUntil(this.unsubscribe$))
                     .subscribe(resp => {
                         this.prescNumber = resp;
                         this.exRxId = this.prescNumber["RxID"];
                     });
                 }
             }
            }
        } else {
            this.exRxId = this.rxInfo["PrescReFill"]["Id"];
            this.presc = this.rxInfo["Prescription"]["PrescNum"];
        }

        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.openIOU();
        this.createFG();
        this.TodayDate = moment().format("MM/DD/YYYY");
    }

    // get PrescReFill(): FormGroup {
    //     return this.rxModelFG.get("PrescReFill") as FormGroup;
    // }

    openIOU() {
        this.modalRef = this._modalService.open(this.IOUPOPUP, {
            size: "lg",
            keyboard:false
        });
        this.getIOUValues();
    }

    
    ngOnDestroy() {
	    this.unsubscribe$.next();
	    this.unsubscribe$.complete();
    }

    getIOUValues() {
        this._rxService.getRxIOUValues(this.exRxId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.iouInfo = resp;
                this.IouArray = resp.Iou;
                this.IOUFG.controls["AppUser"].patchValue(this.iouInfo.AppUser);
                // tslint:disable-next-line:forin
                for (const key in this.IouArray) {
                    const IouValues = this._fb.group({
                        Id: this.IouArray[key].Id,
                        TenantId: this.IouArray[key].TenantId,
                        PrescRefillId: this.IouArray[key].PrescRefillId,
                        DispDt: this.IouArray[key].DispDt,
                        DispQty: (this.IouArray[key].DispQty).toFixed(3),
                        AppUserId: this.IouArray[key].AppUserId,
                        Comments: this.IouArray[key].Comments,
                        OWED: (this.IouArray[key].OWED).toFixed(3),
                        IsDeleted: this.IouArray[key].IsDeleted
                    });
                    this.Iou.push(IouValues);
                    this.actualDisp =
                        this.actualDisp + this.IouArray[key].DispQty;
                    this.editIOU[key] = true;
                }
                this.QtyOwed = this.totalQty.OrdQty - this.actualDisp;
                this.cQtyOwed = this.totalQty.OrdQty - this.actualDisp;
            }
        });
    }

    createFG() {
        this.IOUFG = this._fb.group({
            AppUser: null,
            Pharmacy: null,
            Iou: this._fb.array([])
        });
    }

    get Iou(): FormArray {
        return this.IOUFG.get("Iou") as FormArray;
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.IsPopUpClosed.emit(false);
    }

    closePopUpModal() {
        if (this.modalRefSM) {
            this.modalRefSM.close();
            this.modalRefSM = null;
        }
    }

    addOrdQuantity() {
        this.showIOUGrid = true;
    }

    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (const key in this.iouInfo) {
            if (["Iou"].indexOf(key) > -1 && this.iouInfo[key]) {
                this.generateFA(key, this.iouInfo[key]);
            } else {
                for (const keys in this.iouInfo) {
                    if (keys && this.iouInfo[keys]) {
                        this.IOUFG.controls[keys].patchValue(
                            this.iouInfo[keys]
                        );
                    }
                }
            }
        }
    }

    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.IOUFG.setControl(key, extraCostFormArray);
        }
    }

    updateIOUValues() {
        const dataInfo = this.IOUFG.value;
        this._rxService.updateIOUValues(dataInfo.Iou)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp === 1) {
                if (this.modalRef) {
                this.modalRef.close();
                }
                this.IsPopUpClosed.emit(true);
            }
        });
    }

    addIOUToArray() {
        let takenQty: any = 0;
        let owenedQty: any = 0;
        this.Iou.value.forEach(element => {
            takenQty = takenQty + Number(element["DispQty"]);
            owenedQty = Number(element["OWED"]);
        });
        // this.Iou.value.forEach((element, i) => {
        //    // element["OWED"] = (this.QtyOwed - this.takenQty).toFixed(3);
        // const dataInfo: any = this.IOUFG.controls;
        // const data: any = dataInfo.Iou.controls[i];
        //  data.controls["OWED"].patchValue((this.QtyOwed - takenQty).toFixed(3));
        // });


        const IouValues = this._fb.group({
            Id: null,
            TenantId: null,
            PrescRefillId: this.exRxId,
            DispDt: this.today,
            DispQty: "0.000",
            AppUserId: _.toUpper(this._userService.getToken("UserId")),
            Comments: null,
            OWED: (this.cQtyOwed).toFixed(3),
            IsDeleted: null
        });
        if (this.cQtyOwed - takenQty === 0) {
            this.disableAdd = true;
        }
        this.Iou.push(IouValues);
    }

    addComplete() {
        let takenQty: any = 0;
        let owenedQty: any = 0;
        this.Iou.value.forEach(element => {
            takenQty = takenQty + Number(element["DispQty"]);
            owenedQty = Number(element["OWED"]);
        });

        const IouValues = this._fb.group({
            Id: null,
            TenantId: null,
            PrescRefillId: this.exRxId,
            DispDt: this.today,
            DispQty: (this.cQtyOwed).toFixed(3),
            AppUserId: _.toUpper(this._userService.getToken("UserId")),
            Comments: null,
            OWED: "0.000",
            IsDeleted: null
        });

        this.disableAdd = true;
        this.Iou.push(IouValues);
    }

    checkDispensedQty(val: any, i: any) {
        let takenQty: any = 0;
        const dataInfo: any = this.IOUFG.controls;
        const data: any = dataInfo.Iou.controls[i];
        this.Iou.value.forEach(element => {
            takenQty = takenQty + Number(element["DispQty"]);
        });
        if (this.cQtyOwed - takenQty < 0) {
            this.modalRefSM = this._modalService.open(this.DISPQTY, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"
            });
        data.controls["DispQty"].patchValue(null);
        }
    }

    updateDispensedQty(j: any) {
        let owenedQty: any = 0;
        let takenQty: any = 0;
        this.Iou.value.forEach(element => {
            takenQty = takenQty + Number(element["DispQty"]);
            owenedQty = Number(element["OWED"]);
        });
        if ((this.cQtyOwed - takenQty) < 0) {
        } else {
        //     this.Iou.value.forEach((element, i) => {
        //       // element["OWED"] = (this.QtyOwed - this.takenQty).toFixed(3);


        //    });
           const dataInfo: any = this.IOUFG.controls;
           const data: any = dataInfo.Iou.controls[j];
           data.controls["OWED"].patchValue((this.cQtyOwed - takenQty).toFixed(3));
           data.controls["DispQty"].patchValue(+data.controls["DispQty"]["Value"].toFixed(3));
           if (this.cQtyOwed - takenQty === 0) {
              this.disableAdd = true;
          }
          this.cQtyOwed = this.cQtyOwed - takenQty;
        }
    }

    editIOUValues(val: any) {
        this.editIOU[val] = false;
    }

    deleteIOUValue(val: any) {
        const dataInfo = this.IOUFG.value;
        this._rxService.deleteIOUValue(dataInfo.Iou[val].Id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.getIOUValues();
            }
        });
    }
}
