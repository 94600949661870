<ng-template #ReleaseNotes let-c="close" let-d="dismiss" class="el--popup">
    <div class="releasenotes">
        <div class="modal-header">
            <h4 class="modal-title">Release Notes</h4>
            <button
                type="button"
                [tabindex]="-1" class="close"
                aria-label="Close"
                (click)="d('Cross click')"
                (click)="releaseNotesClose()">
                <i class="fa fa-times close-button" aria-hidden="true"></i>
            </button>
        </div>
        <div>
            <div class="row scrollwidth">
                <div class="menu scrollbar">
                    <div class="col-md-12">
                        <h6 class="allfeatures">Search By Features</h6>
                        <div class="form-group has-search">
                            <eprx-select [PlaceHolder]="'Ex: Rx, Refill, etc..'" [AutoFocus] = "true"
                            [ControlName]="'searchControl'" [FormGroupName]="featureSearchFg" [HasMultiple]="false"
                            [BindLabel]="'Title'" [BindValue]="'Id'" [LabelForId]="''"
                            [InputValue]="Title" [SelectOnTab]="true"
                            [List]="filteredOptions" (TriggerSelectValue)="searchMenuforReleaseData($event)">
                            </eprx-select>
                        </div>
                    </div>
                    <button class="current-releases">
                        <a (click)="getPagesForReleaseNotes($event.target?.innerText)">New Releases</a>
                    </button>
                    <h6 class=" col-md-12 pickbydate">Pick By Month/Year</h6>
                    <div  class="col-md-12 release-border">
                        <div class="row bookshelf--block--releasenotes">
                            <div class="bookshelf w-95">
                                <div class="book-tilted-release" *ngFor="let option of months" [class.highlight]="selectedValue === option"
                                (click)="getPagesForReleaseNotes($event.target?.innerText)" (click)="selectedValue = option">
                                    <a>
                                        <div class="book--release book-blue">
                                            <div class="release-months">{{option}}</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-md-12 checkboxforrelease" [formGroup]="inputFg">
                        <input class="form-check-input" type="checkbox" formControlName="ShowReleasesAfterLogin" id="ShowReleasesAfterLogin"/>
                        <label class="form-check-label">
                            Do not pop-up after login
                        </label>
                    </div>
                </div>
                <div class="maincontent scrollbar">
                    <div [ngClass]="isLinkedClicked ? '' : 'main_header'" *ngIf="!isFrmSearch">
                        <h4 class="userGuide-title">
                            <i class="fas fa-arrow-left m-2 backArrow" (click)="navigateToBackScreen()" *ngIf="(idList.length > 1)"></i>
                        </h4>
                        <div>
                            <span class="header_txt" [innerHTML]="rnDataToDisplay" *ngIf ="hasRnData && !isConfluneceServiceNotAvailable" (click)="clickOnUserGuideLinks()"></span>
                        </div>
                    </div>
                    <div *ngIf="isFrmSearch">
                        <div *ngFor=" let RNData of array">
                            <span class="header_txt" [innerHTML]="RNData.response" *ngIf ="hasRnData && !isConfluneceServiceNotAvailable" (click)="clickOnUserGuideLinks()"></span>
                        </div>
                    </div>
                    <span *ngIf="!hasRnData && !isConfluneceServiceNotAvailable">
                        <h1 class="noDataclass"><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>No updates are avaiable</h1>
                    </span>
                    <span *ngIf="isConfluneceServiceNotAvailable">
                        <h1 class="unavailable-release"><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
                            Service Unavailable at the moment. Please re-try in sometime</h1>
                    </span>
                </div>
            </div>
        </div>
    </div>
  </ng-template>
