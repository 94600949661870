<ng-template #PrinterConfig let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Default Printer Configuration</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <eprx-select [LabelText]="'Default Label Printer'" [PlaceHolder]="'Default Label Printer'"
                    [List]="printerList" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                    [InputValue]="selectedDefaultPrinter" [HasControl]="false"
                    (TriggerSelectValue)="optedPrinter($event.value)" [IsFirstElem]="true" [AutoFocus]="true">
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select [LabelText]="'Default Report Printer'" [PlaceHolder]="'Default Report Printer'"
                    [List]="printerList" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                    [InputValue]="selectedReportPrinter" [HasControl]="false"
                    (TriggerSelectValue)="optedReportPrinter($event.value)">
                </eprx-select>
            </div>
            <div class="col-md-12" *ngIf="actvList?.length">
                <eprx-select [LabelText]="'Default Receive Mode'" [PlaceHolder]="'Default Receive Mode'"
                    [List]="recvModeList" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                    [InputValue]="selectedReceiveMode" [HasControl]="false"
                    (TriggerSelectValue)="optedInterfaceMode($event.value)">
                </eprx-select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'o'">
            <span>O</span> OK
        </button>
    </div>
</ng-template>
