<ng-template #CMMPriorApproval let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Send prior approval request</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal();d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
            Do you want to send prior approval request to the prescriber through CoverMyMeds.com?
    </div>
    <div class="modal-footer">
        <button  ngbAutofocus class="hotkey_primary" (click)="submitCMM()" (click)="d('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES </button>
        <button  class="hotkey_primary" (click)="closeModal()" (click)="d('Cross click')" appShortcutKey InputKey="n"> <b>N</b>NO </button>
    </div>
</ng-template>

<ng-template #CMMWarning let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        {{cmmWarningResp}}
    </div>
    <div class="modal-footer">
        <button footer ngbAutofocus class="hotkey_primary" (click)="closeModal(); d('Cross click')" appShortcutKey InputKey="o"><b>O</b>OK</button>
    </div>
</ng-template>
