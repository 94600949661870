import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { NRxUtils } from '../utils/nRx.util';
import { PatchRXFGUtil } from '../utils';
import { Router } from '@angular/router';
import { CommonStore, RxStore } from '../store';
import * as _ from 'lodash';
import { CommonService } from './common.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InprogressService {

    private _inProgressRxs$: BehaviorSubject<any>;
    inProgressRxs$: Observable<any>;
    rxInfoToRestore: any;
    uniqueList: any;
    isEqual = false;


    constructor(
        private _nrxUtils: NRxUtils,
        private _patchRx: PatchRXFGUtil,
        private _router: Router,
        private _commonStore: CommonStore,
        private _commonSer: CommonService,
        private _rxStore: RxStore
        ) {
        this._inProgressRxs$ = <BehaviorSubject<any>>new BehaviorSubject([]);
        this.inProgressRxs$ = this._inProgressRxs$.asObservable();

    }

    async storeInProgress(rxInfo) {
        const inprgrsArray = this._inProgressRxs$.getValue();
        // inprgrsArray.unshift(rxInfo);
        const duplicate: any = (inprgrsArray && inprgrsArray.length > 0) ?
        await this.checkDuplicateInprogressRxs(rxInfo, inprgrsArray) : false;
        if (!duplicate || (duplicate && !duplicate.duplicate)) {
            inprgrsArray.push(rxInfo);
            // if (inprgrsArray && inprgrsArray.length > 1) {
            //     this.uniqueList = inprgrsArray.filter((item, i, c) =>
            //     c.findIndex((n => n.Patient.patientid === item.Patient.patientid)) === i);
            //     inprgrsArray = this.uniqueList;
            // }
            if (inprgrsArray.length > 2) {
                if (
                    inprgrsArray[2]["rxType"] === "rf" ||
                    inprgrsArray[2]["rxType"] === "nrf"
                ) {
                    this._commonStore.storeRfStage("End");
                }
                const isProgress = this._commonSer.DecryptData(localStorage.getItem("isProgress"));
                if (!isProgress) {
                    inprgrsArray.splice(0, 1);
                    localStorage.removeItem("isProgress");
                } else {
                    localStorage.removeItem("isProgress");
                }
            } else {
                localStorage.removeItem("isProgress");
            }
            this._inProgressRxs$.next(inprgrsArray);
        } else if (duplicate && duplicate.inprgrsArray) {
            this._inProgressRxs$.next(duplicate.inprgrsArray);
        }
    }

    storeProgressData(data) {
        this._inProgressRxs$.next(data);
    }

    restoreInProgress(indexToRestore) {
        this.restoreRxInfo(indexToRestore ? +indexToRestore : 1);
    }

    private async restoreRxInfo(indexToRestore) {
        const inprgrsArray = this._inProgressRxs$.getValue();
        this.rxInfoToRestore  = inprgrsArray.splice(indexToRestore - 1, 1);
        const rxFG = await this._nrxUtils.createPatchStoreRxFG();
        if (this.rxInfoToRestore  && this.rxInfoToRestore [0]) {
            this.rxInfoToRestore[0]["frmInprog"] = true;
            this.rxInfoToRestore[0]["calQtyRemFrmIn"] = true;
            this.rxInfoToRestore[0]["patPopCompl"] = null;
            this.rxInfoToRestore[0]["patCompl"] = null;
            this.rxInfoToRestore[0]["prescPopCompl"] = null;
            this.rxInfoToRestore[0]["prescCompl"] = null;
            this.rxInfoToRestore[0]["drugPopCompl"] = null;
            const pricedata = {
                BalDue: this.rxInfoToRestore[0].PrescReFill.BalDue,
                Price: this.rxInfoToRestore[0].PrescReFill.Price,
                PriceSchId: this.rxInfoToRestore[0].PrescReFill.PriceSchId,
                ProFee: this.rxInfoToRestore[0].PrescReFill.ProfFee,
                TotalAmt: this.rxInfoToRestore[0].PrescReFill.TotalAmt,
                UnitPriceAWP: this.rxInfoToRestore[0].PrescReFill.UnitPriceAWP,
                UnitPriceUNC: this.rxInfoToRestore[0].PrescReFill.UnitPriceUNC,
                OtherAmount: this.rxInfoToRestore[0].OtherAmount
            };
            this.rxInfoToRestore[0].isInprogressRx = true;
            this.rxInfoToRestore[0].inProgressRxPriceVals = (this.rxInfoToRestore[0]  && this.rxInfoToRestore[0]['Drug'])? pricedata : null;
            this._patchRx.patchRxInfo(this.rxInfoToRestore[0], rxFG, null, null, );
            if (rxFG && rxFG.controls["PrescReFill"]) {
                const prescReFill: any = rxFG.controls.PrescReFill;
                prescReFill.controls["FillDtTm"].setValue(moment(new Date()));
                console.log(rxFG.controls["PrescReFill"].value["FillDtTm"], "FillDtTM");
            }
            if (rxFG && rxFG.controls["rxType"].value === "nr" && rxFG.controls["Prescriber"].value && !rxFG.controls["Prescriber"].value["prescriberid"]) {
                if (this._nrxUtils.getSetngKeyVal("RxSettings", "DEF_PAT_LAST_PRESCRIBER")) {
                    this._commonSer.getLatestPrescriber(rxFG.controls["Patient"].value["patientid"]).subscribe((resp: any) => {
                    if (resp) {
                        this._rxStore.storePatSpecPrescDels(resp);
                    }
                    });
                }
            }
            this._inProgressRxs$.next(inprgrsArray);
            this._router.navigate(["/eprx/rx"], {
                queryParams: { fm: "nr" }
            });
        }
    }

    checkDuplicateInprogressRxs(rxInfo, inprgrsArray) {
         let duplicate = false;
        const rxInfoArray = {
            Patient: rxInfo.Patient,
            Prescriber: rxInfo.Prescriber,
            Drug: rxInfo.Drug
        };
        // tslint:disable-next-line:forin
        for (const item in inprgrsArray) {
            const inpArray = {
                Patient: inprgrsArray[item].Patient,
                Prescriber: inprgrsArray[item].Prescriber,
                Drug: inprgrsArray[item].Drug
            };
           const isEqual = _.isEqual(inpArray, rxInfoArray);
           if (isEqual) {
               inprgrsArray[item] = rxInfo;
               duplicate = true;
           }
        }
        return {duplicate: duplicate, inprgrsArray: inprgrsArray};
     }
}
