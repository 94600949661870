<ng-template #CMMPriorApproval let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal>
        <h4 header>Send prior approval request</h4>
        <span body>
            Do you want to send prior approval request to the prescriber through CoverMyMeds.com?
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o"><b>O</b>OK </button>
    </app-custom-modal>
</ng-template>


<ng-template #TRANSMISSIONCONF let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeConfirmModal()">
        <h4 header>Confirmation</h4>
        <span body>
            Amount paid is LESS than the DRUG COST!, Do you want to continue?
        </span>
        <button
            footer
            ngbAutofocus
            type="submit"
            class="hotkey_primary"
            (click)="saveTransmitClaims()"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button
            footer
            type="submit"
            class="hotkey_primary"
            (click)="closeConfirmModal()"
            appShortcutKey InputKey="n"
        >
          <b>N</b>  NO
        </button>
    </app-custom-modal>
</ng-template>
