<div class="editpatient--body newrx--body edit--drug insurance--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Notes'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                <button class="hotkey_success"id="SaveButton" (click)="update()"><span>S</span> Save</button>
                <button class="hotkey_success mr-0" (click)="CancelPopTaggle()"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="row">
                <div class="col">
                    <span class="eprx--header__heading">
                        Notes
                    </span>
                </div>
            </div>
        </div>
        <div class="notes--block">
                <div [formGroup]="drugNotesFG">
                    <div class="row">
                        <div class="col-lg-12 padding--right__7" [formGroup]="drugNotesFG">
                            <div class="form-group">
                                <textarea (keydown.tab)="focusToFirst($event)" class="label--text__area col-md-12" autofocus formControlName="Remarks" value="{{drugInfo?.Remarks}}"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>

<app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)"></app-cancel-pop-up>
