
import { RxUtils } from "src/app/utils/rx.util";
import { Subscription } from "rxjs";

import { CommonService } from "./../../../services/common.service";
import {
    Component,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    OnDestroy,
    HostListener
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    FormControl,
    AbstractControl
} from "@angular/forms";
import { SystemData } from "./../../../models/system-data.model";
import { Drug, DrugMiscPrice, PriceHis } from "../../../models";
import { RegEx } from "./../../../app.regex";
import { EditDrugService } from "./../../../services/edit-drug.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "../../../services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import { MsgConfig } from "src/app/app.messages";

@Component({
    selector: "app-edit-drug-pricing",
    templateUrl: "./edit-drug-pricing.component.html"
})
export class EditDrugPricingComponent
    implements OnInit, AfterViewInit, OnDestroy {
    drugPricingFG: any;
    drugMiscFG: any;
    systemData: SystemData;
    priceInfo: any;
    items = [];
    regex: any;
    hasDrugMisc = true;
    drugId: number;
    deleteData: any;
    originalData: any;
    priceScheduleList: any;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    defaultMiscVal: number[];
    DrugMiscPriceFG: FormGroup;
    modalRef: any;
    canDeactivateRoute = true;
    sharedCancel: boolean;
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                event.preventDefault();
                this.update();
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.CancelPopTaggle();
            }
        } else if (event.which === 27 && this.modalRef) { // esc
            this.modalRef.close();
            event.preventDefault();
        }

    }

    constructor(
        private _fb: FormBuilder,
        private _data: CommonService,
        private _drugSvc: EditDrugService,
        private _cdr: ChangeDetectorRef,
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private _alertSer: AlertService,
        private modalService: NgbModal,
        private _route: Router,
        private _auditUtils: AuditLogUtils,
        private _rxutils: RxUtils
    ) {
        this.regex = RegEx;
        this.createFG();
        this.defaultMiscVal = [0, 1, 2];
        this.DrugMiscPriceFG = this._fb.group({
            Id: [],
            TenantId: [],
            DrugId: [],
            DrugMiscPriceCatId: [],
            Price: [],
            IsActive: [],
            CreatedDtTm: [],
            ModifiedDtTm: []
        });
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
             if (params && params.fm) {
                 this.routeFrom = params.fm;
             }
             if (params && params.focus) {
                if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                    this.focusOnDU = params.focus;
                }
            }
         }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugId = +params["pid"])
        ));
    }
    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
    }

    ngOnInit() {
        // this._commonServ.getSystemData();
        this._commonServ.systemData$.subscribe(resp => {
            this.systemData = resp;
        });
        this.getInfo(this.drugId);

        this._commonServ.priceSchedule$.subscribe(
            resp => ((resp && resp.PriceScheduleList) ? this.priceScheduleList = resp.PriceScheduleList : [])
        );
    }

    createFG() {
        this.drugPricingFG = this._fb.group({
            Drug: this._fb.group(new Drug()),
            DrugMiscPrice: this._fb.array([new DrugMiscPrice()]),
            PriceHistory: this._fb.group(new PriceHis())
        });
    }
    getInfo(id: number) {
        this._data.getDrugInfo(id).subscribe(resp => {
            this._commonServ._drugInfo$.next(resp);
            this.priceInfo = resp;
            const PriceHisdata: any = this.drugPricingFG.controls[
                "PriceHistory"
            ];
            PriceHisdata.controls["OldAWPPrice"].patchValue(
                this.priceInfo.Drug.AWPPack
            );
            PriceHisdata.controls["OldCostPack"].patchValue(
                this.priceInfo.Drug.CostPack
            );
            PriceHisdata.controls["OldUnitDIR"].patchValue(
                this.priceInfo.Drug.DirectUnitPrice
            );
            this.patchValue();
            for (const key in this.priceInfo) {
                if (["Drug"].indexOf(key) > -1) {
                    for (const keys in this.priceInfo[key]) {
                        if (
                            [
                                "DirectUnitPrice",
                                "UnitPriceAWP",
                                "UnitPriceCost",
                            ].indexOf(keys) > -1
                        ) {
                            if (this.priceInfo[key][keys]) {
                                this.priceInfo[key][keys] = this.priceInfo[key][keys].toFixed(3);
                            } else {
                                this.priceInfo[key][keys] = "0.000";
                            }
                        } else if (
                            ["AWPPack", "CostPack" , "Surcharge",
                            "PerOfBrand"].indexOf(keys) > -1
                        ) {
                            if (this.priceInfo[key][keys]) {
                                this.priceInfo[key][keys] = this.priceInfo[key][keys].toFixed(2);
                            } else {
                                this.priceInfo[key][keys] = "0.00";
                            }
                        }
                    }
                } else if (["DrugMiscPrice"].indexOf(key) > -1 && this.priceInfo[key]) {
                    for (const keys in this.priceInfo[key]) {
                        if (
                            this.priceInfo[key][keys]
                        ) {
                            this.priceInfo[key][keys]["Price"] = (this.priceInfo[key][keys]["Price"]).toFixed(3);
                        } else {
                            this.priceInfo[key][keys]["Price"] = "0.000";
                        }
                    }
                }
            }
            this.patchValue();

            this.drugPricingFG.markAsPristine();
            this.drugPricingFG.valueChanges.subscribe(value => {
                if (this.drugPricingFG.dirty) {
                    this.canDeactivateRoute = false;
                } else {
                    this.canDeactivateRoute = true;
                }
            });
            this.originalData = _.clone(this.drugPricingFG.value);
            delete this.originalData.DrugMisc;
            delete this.originalData.PriceHistory;
        });
    }
    get Drug(): FormGroup {
        return this.drugPricingFG.get("Drug") as FormGroup;
    }
    get DrugMiscPrice(): FormArray {
        return this.drugPricingFG.get("DrugMiscPrice") as FormArray;
    }

    patchValue() {

        // tslint:disable-next-line:prefer-const
        for (const key in this.priceInfo) {
            if (key === "DrugMiscPrice") {
                this.generateFA(key, this.priceInfo[key]);
            } else if (key === "Drug" && this.priceInfo[key]) {
                this.drugPricingFG.controls[key].patchValue(
                    this.priceInfo[key]
                );
            }
        }
    }

    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.drugPricingFG.setControl(key, extraCostFormArray);
        } else {
            const extraCostFGs = this.defaultMiscVal.map((val, i) => {
                if (i === 0) {
                    this.DrugMiscPriceFG.controls["DrugMiscPriceCatId"].patchValue(1);
                } else if (i === 1) {
                    this.DrugMiscPriceFG.controls["DrugMiscPriceCatId"].patchValue(2);
                } else if (i === 2) {
                    this.DrugMiscPriceFG.controls["DrugMiscPriceCatId"].patchValue(3);
                }
                this.DrugMiscPriceFG.controls["IsActive"].patchValue(true);
                this.DrugMiscPriceFG.controls["DrugId"].patchValue(this.drugId);
                this.DrugMiscPriceFG.controls["Price"].patchValue("0.000");
               return this._fb.group(this.DrugMiscPriceFG.value);
            });
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.drugPricingFG.setControl(key, extraCostFormArray);
        }
    }

    updateAltCost(val, type) {
        const dataInfo: any = this.drugPricingFG.controls["DrugMiscPrice"];
        const data: any = dataInfo.controls[type];
        if (data.controls["Price"].dirty) {
        data.controls["Price"].setValue((Number(val["value"])).toFixed(3));
        }
    }

    updatePrice() {
        const dataInfo = this.drugPricingFG.value;
        for (const key in dataInfo) {
            if (key === "DrugMisc") {
                dataInfo.DrugMisc.IsActive = "true";
                dataInfo.DrugMisc.DrugId = this.drugId;
                dataInfo.DrugMisc.TenantId = "-7101307679";
            }
        }
        if (this.priceInfo["DrugMiscPrice"]) {
            this.priceInfo["DrugMiscPrice"].push(dataInfo["DrugMisc"]);
        } else {
            this.priceInfo["DrugMiscPrice"] = [];
            this.priceInfo["DrugMiscPrice"].push(dataInfo["DrugMisc"]);
        }
        if (this.priceInfo["DrugMiscPrice"].length > 0) {
            this.hasDrugMisc = true;
        } else {
            this.hasDrugMisc = true;
        }
        this.drugPricingFG.controls["DrugMisc"].reset();
        this.drugPricingFG.controls["DrugMisc"].markAsUntouched();
        this.patchValue();
    }

    deleteWarn(content, i) {
        this.modalRef=this.modalService.open(content, {
            centered: true
        });
        this.deleteData = i;
    }

    deleteMiscPrice() {
        const i = this.deleteData;
        if (this.priceInfo["DrugMiscPrice"].length === 1) {
            this.hasDrugMisc = false;
        }
        const dataInfo = this.drugPricingFG.value;
        dataInfo.DrugMiscPrice[i].IsActive = false;
        this.priceInfo["DrugMiscPrice"][i].IsActive = false;
        this.patchValue();
        this._auditUtils.deletedValues(
            this.priceInfo["DrugMiscPrice"][i].Id,
            "Delete Drug Price Cost",
            "Drug",
            this.drugId
        );
    }
    update() {
        const dataInfo = this.drugPricingFG.getRawValue();
        const PriceHisdata: any = this.drugPricingFG.controls["PriceHistory"];
        PriceHisdata.value["NewAWPPrice"] = dataInfo["Drug"]["AWPPack"];
        PriceHisdata.value["NewCostPack"] = dataInfo["Drug"]["CostPack"];
        PriceHisdata.value["NewUnitDIR"] = dataInfo["Drug"]["DirectUnitPrice"];
        this._drugSvc.updateDrugInfo(dataInfo).subscribe(resp => {
            if (resp === 0 || resp === 3 || resp === 5) {
                const newPriceInfo = _.clone(dataInfo);
                delete newPriceInfo.PriceHistory;
                this._auditUtils.getChangedValues(
                    this.originalData,
                    newPriceInfo,
                    "Pricing Info",
                    "Drug",
                    this.drugId
                );
                this._alertSer.success("Drug info updated successfully.");
               this.routeBackToPrevScreen("update");
            } else if (resp === 2) {
                this._alertSer.error("Quick code must be unique.");
            } else if (resp === 1) {
                this._alertSer.error("NDC code must be unique.");
            } else {
                this._alertSer.error(MsgConfig.WRONG_MSG);
            }
        });
    }

    convertByMouseDown(changes: any) {
        const dataInfo: any = this.drugPricingFG.controls["Drug"];
        if (changes === "UnitPriceAWP" && dataInfo.controls["UnitPriceAWP"].dirty) {
            dataInfo.controls["UnitPriceAWP"].patchValue(
                (Number(dataInfo.controls["UnitPriceAWP"].value)).toFixed(3)
            );
            dataInfo.controls["AWPPack"].patchValue(
                (
                    dataInfo.controls["UnitPriceAWP"].value *
                    dataInfo.controls["QtyPack"].value
                ).toFixed(2)
            );
        } else if (changes === "UnitPriceCost" && dataInfo.controls["UnitPriceCost"].dirty) {
            dataInfo.controls["UnitPriceCost"].patchValue(
                (Number(dataInfo.controls["UnitPriceCost"].value)).toFixed(3)
            );
            dataInfo.controls["CostPack"].patchValue(
                (
                    dataInfo.controls["UnitPriceCost"].value *
                    dataInfo.controls["QtyPack"].value
                ).toFixed(2)
            );
        } else if (changes === "AWPPack" && dataInfo.controls["AWPPack"].dirty) {
            dataInfo.controls["AWPPack"].patchValue(
                (Number(dataInfo.controls["AWPPack"].value)).toFixed(2)
            );
            if (
                ((
                    dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceAWP"].patchValue(
                    (
                        dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value
                    ).toFixed(3)
                );
            } else {
                dataInfo.controls["UnitPriceAWP"].patchValue("0.000");
            }
            dataInfo.controls["UnitPriceAWP"].markAsPristine();
        } else if (changes === "CostPack" && dataInfo.controls["CostPack"].dirty) {
            dataInfo.controls["CostPack"].patchValue(
               (Number(dataInfo.controls["CostPack"].value)).toFixed(2)
            );
            if (
                ((
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["CostPack"].value /
                        dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceCost"].patchValue(
                    (
                        dataInfo.controls["CostPack"].value /
                        dataInfo.controls["QtyPack"].value
                    ).toFixed(3)
                );
            } else {
                dataInfo.controls["UnitPriceCost"].patchValue("0.000");
            }
            dataInfo.controls["UnitPriceCost"].markAsPristine();
        } else if (changes === "QtyPack" && dataInfo.controls["QtyPack"].dirty) {
            dataInfo.controls["QtyPack"].patchValue(
                (Number(dataInfo.controls["QtyPack"].value)).toFixed(3)
            );
            if (
              ((dataInfo.controls["AWPPack"].value /
                dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceAWP"].patchValue(
                    (
                        dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value
                    ).toFixed(3)
                );
            } else {
                dataInfo.controls["UnitPriceAWP"].patchValue("0.000");
            }

            if (
                ((
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["CostPack"].value /
                        dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceCost"].patchValue(
                    (
                        dataInfo.controls["CostPack"].value /
                        dataInfo.controls["QtyPack"].value
                    ).toFixed(3)
                );
            } else {
                dataInfo.controls["UnitPriceCost"].patchValue("0.000");
            }
        }
    }

    numberFormat(control, type) {
        const dataInfo: any = this.drugPricingFG.controls["Drug"];
        if (dataInfo.controls[control].dirty) {
            if (type === 2) {
                dataInfo.controls[control].patchValue((Number(dataInfo.value[control])).toFixed(2));
            } else {
                dataInfo.controls[control].patchValue((Number(dataInfo.value[control])).toFixed(3));
            }
        }
    }

    routeBackToPrevScreen(type?: any) {
        this.canDeactivateRoute = true;
        if (type && type === "update") {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "update", this.focusOnDU);
        } else {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    focusOutFmCan(event) {
        if (document.getElementById("AWPPack")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("AWPPack").focus();
            }, 10);
        }
    }


    CancelPopTaggle() {
        if (this.Drug.dirty) {
            this.sharedCancel = false;
        } else {
            this.routeBackToPrevScreen();
        }
    }

    CancelPopClose(val) {
        if (val) {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        } else {
            this.sharedCancel = false;
        }
    }
}
