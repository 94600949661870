export class InveTransType {
    active: boolean;
    Id: number = null;
    Code: string = null;
    Name: string = null;
    TransType: string = null;
    IsReceipt: boolean = null;
    CreatedDttm: string = null;
}


export class PhysicalInventory {
    id: number = null;
    tenantId: number = null;
    oldQty: number = null;
    newQty: number = null;
    drugId: number = null;
    userId: number = null;
    isProcessed: boolean = null;
    bucketId: number = null;
    processedUserId: number = null;
    billedOffset: number = null;
    unbilleOffset: number = null;
    drugPickVerifOffset: number = null;
    createdDtTm: any = null;
    modifiedDtTm: any = null;
    comments: any=null;
    IsFromEditDrug: any=null;
    Packs: any = null;
    LooseQty: any = null;
    IsFromPhysicalInventoryScreen: boolean = null;
}
