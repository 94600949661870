import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "../../../node_modules/rxjs";
import { constant } from "../app.constants";


@Injectable({
    providedIn: "root"
})
export class AdditionalInsuranceService {
    constructor(private _http: HttpClient) {}

    addPatientInsurance(insuData: any): Observable<boolean> {
        return this._http.post<boolean>(constant.POST_Patient_Insurance, insuData);
    }

    updatePatientInsurance(data: any): Observable<number> {
        return this._http.put<number>(constant.PUT_Patient_Insurance, data);
    }

    deletePatientInsurance(patInsuId, insurerId, patId) {
        return this._http.delete(
            constant.DELETE_Patient_Insurance.replace(
                "{patientinsuranceid}",
                "" + patInsuId
            ).replace("{insuranceid}", "" + insurerId).replace("{patientId}", "" + patId)
        );
    }

    setPrimaryInsurance(patientId, insuranceId): Observable<boolean> {
        return this._http.put<boolean>(
            constant.PUT_Patient_Insurance_SetPrimary.replace(
                "{insuranceid}",
                "" + insuranceId
            ).replace("{patientid}", "" + patientId),
            null
        );
    }

    isInsuranceExistForPatient(patientId, insurerId, insuId): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Patient_InsuranceExists.replace(
                "{patientid}",
                "" + patientId
            ).replace("{insurerid}", "" + insurerId).replace("{insuranceId}", insuId)
        );
    }
    setPrimaryInsuranceForRecallRx(patientId, refillId): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Patient_Insurance_SetPrimary_ForRecall.replace(
                "{patientId}",
                "" + patientId
            ).replace("{RefillId}", "" + refillId)
        );
    }
    setPrimaryInsuranceForRecallRxDetails(patientId): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Patient_Insurance_SetPrimary_Details.replace(
                "{patientId}",
                "" + patientId
            )
        );
    }
    insuranceStatusSet(insuranceId, status): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Patient_Insurance_Status.replace(
                "{insuranceId}",
                "" + insuranceId
            ).replace("{status}", "" + status),
            null
        );
    }

    getInsuGroups(insuCarrierId): Observable<any> {
        return this._http.get<boolean>(
            constant.GET_Patient_InsuranceGroups.replace("{insurerId}", "" + insuCarrierId)
        );
    }

    // Insurance/PriorityCheck/{patientId}/{PriorityId}
    checkIfInsuPriorityExists(patientId: any, PriorityId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_Priority_Exists.replace(
                "{patientId}",
                "" + patientId
            ).replace("{PriorityId}", "" + PriorityId)
        );
    }

    isInsuranceActiveFrmInsFilePatient(insurerId): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Patient_InsuranceActiveStatus.replace("{insurerid}", "" + insurerId)
        );
    }

    getPatAddInsuranceDeleteResp(insuranceId): Observable<any> {
        return this._http.get<any>(constant.GET_Patient_InsuranceDeleteResponse.replace("{insuranceid}", "" + insuranceId));
    }

    insuranceFileStatusSet(actvInsuranceModal): Observable<boolean> {
        return this._http.post<boolean>(
            constant.POST_InsuranceFile_Status, actvInsuranceModal);
    }
}
