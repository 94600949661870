<ng-template #CurrentView let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Rx History - Current View</h4>
        <span body>
            <div class="row">
                <div class="col-12 text-right">
                    <button type="button" class="submit print-med-guide background_orange_RGB border_1px_solid_black"
                    (click)="toggleColorLegend()">Color Legend</button>
                </div>
            </div>
            <wj-flex-grid #CurrentView [headersVisibility]="'Column'" [itemsSource]="gridData" [isReadOnly]="true" [columnPicker]="'RxHistoryCurrentView'"
            [selectionMode]="'Row'"  [itemFormatter]="itemFormatter" [allowSorting]="true"  [ngClass]="['wjm-grd-custom-height-56', (!totalRxResp?.rxHistory?.length) ? 'no-data':'']" >
            <wj-flex-grid-column  [header]="rxheader['hName']" [binding]="rxheader['hName']"
            [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                *ngFor="let rxheader of wjHeaders; let i = index" title="{{ rxheader['hName'] }}">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                    <div >
                        {{ item[rxheader['hName']]}}
                    </div>

                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!totalRxResp?.rxHistory?.length"></app-no-data-found>
        </wj-flex-grid>
        <div class="row">
            <div class="col-6">
                    <label>Total Count: &nbsp;</label>{{totalRxResp?.rxHistory?.length}}
            </div>
        </div>
        </span>
        <button footer ngbAutofocus id="deletNotesBtn" type="button" class="hotkey_primary" appShortcutKey
            (click)="closeModal()" InputKey="o"><b>O</b> Ok</button>
    </app-custom-modal>
</ng-template>

