import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "udpCurrencyMask"
})
export class UdpCurrencyMaskPipe implements PipeTransform {
    amount: any;

    transform(value: any, args?: any, frmtTyp?: any, beforeVal?: any): any {
        if (!((value ===  "") || (value == null))) {
            let amount = String(value);
            const beforePoint = amount.split(".")[0];
            let integers = "";
            if (typeof beforePoint !== "undefined") {
                integers = beforePoint.replace(/(?!^-)[^0-9.]/g, "");
            }
            const afterPoint = amount.split(".")[1];
            let decimals = "";
            if (typeof afterPoint !== "undefined") {
                decimals = afterPoint.replace(/\D+/g, "");
            }
            if (decimals.length > 2) {
                decimals = decimals.slice(0, decimals.length);
            }
            amount = integers;
            if (typeof afterPoint === "string") {
                amount += ".";
            }
            if (decimals.length > 0) {
                amount += decimals;
            }
            if (!args && frmtTyp === "currency") {
                args = 2;
            }

            if (amount.indexOf(".") >= 0) {
                return (parseFloat(amount)).toFixed(args);
            } else {
                if (beforeVal) {
                    return (parseFloat(amount.substring(0, 7) + "." + ((+amount.substring(7, 10)) % 1000))).toFixed(args);
                } else if (args) {
                    return (parseFloat(amount.substring(0, 6) + "." + ((+amount.substring(6, 6 + (+args))) % 1000))).toFixed(args);
                } else {
                    return (parseFloat(amount.substring(0, 6) + "." + ((+amount.substring(6, 8)) % 1000))).toFixed(args);
                }
            }
        }
        return value;
    }
}
