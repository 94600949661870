<ng-template #RxFollowup let-c="close" let-d="dismiss">
    <app-custom-modal (oncrossBtnClick)="closeModal(true)">
        <h4 header>Follow-up Reason</h4>
        <span body>
            <div class="eprx--block__content notes--block">
                <div class="row">
                    <div class="col">
                        <h6 class="notes--block--head">Reasons for Rx No: {{rxInfo.Prescription.PrescNum}}</h6>
                        <h6 class="notes--block--head">Refill#: {{rxInfo.PrescReFill.ReFillNum}}</h6>
                        <h6 class="notes--block--head"><span *ngIf="_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)">
                        Fill No: {{rxInfo.PrescReFill.PartialFillNo}}</span></h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <textarea ngbAutofocus class="label--text__area col-md-12" [value]="followUpReas" (keyup)="changeFollowup($event?.target?.value)"></textarea>
                    </div>
                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="saveFollowUp()" appShortcutKey [AltKey]="'o'"><span>O</span> OK</button>
        <button footer class="hotkey_success" (click)="closeModal(true)" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
    </app-custom-modal>
</ng-template>
