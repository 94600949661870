import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { DUR, RxAuditLog, SystemData } from "src/app/models";
import { CommonService, InsuranceService, RxService, TransmissionService, UserService } from "src/app/services";
import { AuditLogUtils, CommonUtil, NewAuditUtils, NRxUtils, RxBillingUtil, ValidateFormFieldsUtils } from "src/app/utils";
import { CollectionView } from "@grapecity/wijmo";
import { BillToCashComponent } from "../..";
import { CommonWarnorconfirmPopupComponent } from "../../common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { MsgConfig } from "src/app/app.messages";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-batch-edit",
  templateUrl: "./batch-edit.component.html"
})
export class BatchEditComponent implements OnInit, AfterViewInit {

    @Input() selectedList: any;

    @ViewChild("DURADD")
    DURADD: any;
    @ViewChild("BATCHEDIT")
    BATCHEDIT: any;
    @ViewChild("ORDERSUPLDAYS")
    ORDERSUPLDAYS: any;
    @ViewChild("TECHPASSWORD")
    TECHPASSWORD: any;
    @ViewChild("PHARMPASSWORD")
    PHARMPASSWORD: any;
    @ViewChild("WARNING", { static: true })
    WARNING: any;

    @Output() IsBatchEditClosed: EventEmitter<any> = new EventEmitter();

    DURWJ: CollectionView = new CollectionView([]);
    _authRefl$: BehaviorSubject<any> = new BehaviorSubject(null);
    authRefl$ = this._authRefl$.asObservable();
    actvHeaders = [
        "Reason",
        "Significance",
        "Other.PH",
        "Prv.Fill",
        "Fill Qty",
        "DB",
        "Other Pres.",
        "Comment",
    ];
    activeTabId: string;
    editMode = false;
    selectedRxs: any;
    DurFG: FormGroup;
    systemData: SystemData;
    formGroupInvalid: boolean;
    newRxDur = [];
    modelRef: any;
    totalCount: number;
    editIndex: any;
    deleteIndex: number;
    modal: any;
    authRefills = [];
    batchFG: FormGroup;
    extraValueInfo: any;
    insuCntrlStngs: any;
    maxRefillsAll = 99;
    orderToFillDays = 180;
    batchModel: any;
    authRefDis = false;
    maxOrdDate: string;
    maxFillDate: string;
    warnMsg: any;
    warnModel: any;
    warnOpendF: string;
    modalRef: any;
    savedTechPass: any;
    techSelc: any;
    techName: any;
    savedPharmPass: any;
    pharmName: any;
    pharSecl: any;
    cashIndex = 0;
    hasPayRec = false;
    hasDurVal: any;
    dupSelectedList: any;
    WarnMessage: string = "";
    modalOpend: any;
    priceSchList: any;
    LevelData = [];
    MaxLevel: number;
    originalExtaVal: any;
    changedExtraVal: any;
    iscashScenerio: boolean = false;
    VerifiedList: any = [];
    captureTechDet: boolean;
    validTechCond: boolean;
    validPharmCond: boolean;
    deleteHeaderTxt: any;
    deleteConfrmMsg: any;
    isSelectedBillToAsCash = false;
    rxPayRecExist: boolean;
    rxNotPayRecList: any=[];
    unsubscribe$: Subject<void> = new Subject();
    drugClassBasedEditList = [];
    fillDateList : any = [];
    insForFillDate: any;

    constructor(private _modalService: NgbModal, private _cmmnServ: CommonService, private _newAudit: NewAuditUtils, private _transmsnServ: TransmissionService,
         private _validUtils: ValidateFormFieldsUtils, private _fb: FormBuilder, private _insurService: InsuranceService, private _billUtls: RxBillingUtil,
         private _commonServ: CommonService, private _rxService: RxService, private _usrServ: UserService, private _cmnUtils: CommonUtil, private _auditUtils: AuditLogUtils, private _nrxUtils: NRxUtils) {
            this.createDURFG();
            this.createBatchFG();
        }

    ngOnInit() {
        this.createInfoForRx();
        this.systemData = this._commonServ.systemData$["source"]["value"];
        this.selectedRxs = this.selectedList.map(v => {
            return v["Prescription"]["PrescNum"] + " - " + v["PrescReFill"]["ReFillNum"] + (this._commonServ.checkIsPartialFill(v["PrescReFill"], v["Prescription"])
            ? " - " + v["PrescReFill"]["PartialFillNo"] : "");
        });
        this.activeTabId = "Patient1";
        this.priceSchList = this._commonServ.priceSchedule$["source"]["value"];
        this.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    ngAfterViewInit() {
        this.batchModel = this._modalService.open(this.BATCHEDIT, {centered: true, windowClass: "medium-modal-68", backdrop: false});
    }

    beforeChange(nextId: string) {
        if (nextId) {
            this.activeTabId = nextId;
        }
    }

    closeBatchEditPopup(type?: any) {
        if(this.iscashScenerio){
            type = "cash";
        }
        if (!type || (type && type === "cash")) {
            this.auditLogChanges((type && type === "cash"));
        }
        if (this.batchModel) {
            this.batchModel.close();
        }
        this.IsBatchEditClosed.emit(true);
    }

    async auditLogChanges(isCash?: boolean) {
        const cntrlVal = {
            OrderDtTm: { ParentN: "PrescReFill", CntrlN: "OrderDtTm" },
            FillDtTm: { ParentN: "PrescReFill", CntrlN: "FillDtTm" },
            RPH: { ParentN: "PrescReFill", CntrlN: "RegPharmacist" },
            Tech: { ParentN: "PrescReFill", CntrlN: "TechInitials" },
            AuthRefills: {ParentN: "Prescription", CntrlN: "ReFillNum" },
            InsuranceCode: {ParentN: "rxRelatedCodeInfo", CntrlN: "BillTo" },
            OrderStatus: {ParentN: "PrescReFill", CntrlN: "OrderStatus" },
            ChangeStatusToFiled: {ParentN: "PrescReFill", CntrlN: "StatusId" }
        };
        this.formatOrdNFillDt();
        const list = JSON.parse(JSON.stringify(isCash ? this.dupSelectedList : this.selectedList));
        Object.keys(cntrlVal).map(cntrl => {
            if (this.batchFG.value[cntrl]) {
                list.map(v => {
                    if (cntrl === "InsuranceCode") {
                        let priceCode = null;
                        if (this.priceSchList && this.priceSchList["PriceScheduleList"] && this.priceSchList["PriceScheduleList"].length) {
                            priceCode = this.priceSchList["PriceScheduleList"].find(p => p["Id"] === +v?.PrescReFill?.PriceSchId);
                        }
                        v[cntrlVal[cntrl]["ParentN"]] = {
                            BillTo: this.batchFG.value[cntrl],
                            PriceCode: priceCode && priceCode.PriceCode ? priceCode.PriceCode : null
                        };
                    } else if (cntrl === "ChangeStatusToFiled") {
                        v["PrescReFill"]["StatusId"] = 2;
                        v["PrescReFill"]["RxStatusId"] = (v["PrescReFill"] && v["PrescReFill"]["RxStatusId"] && v["PrescReFill"]["RxStatusId"] === 3) ? 3 : 2;
                        v["RxFile"] = { DueDtTm: this.batchFG.value["DueDate"] };
                    } else {
                        v[cntrlVal[cntrl]["ParentN"]][cntrlVal[cntrl]["CntrlN"]] = this.batchFG.value[cntrl];
                    }
                });
            }
        });
        const edtRxAudtMdl = new RxAuditLog().rxAuditModel;
        const chgData = [];
        await ((this.rxNotPayRecList?.length && this.rxPayRecExist) ? this.rxNotPayRecList : this.selectedList).map((rx, i) => {
            const d = this._newAudit.saveAuditChange(rx, list[i], "EditRx", "Rx", rx.PrescReFill.Id, edtRxAudtMdl, true);
            if (d) {
                chgData.push(JSON.parse(JSON.stringify(d)));
            }

            if (this.batchFG.value["DurData"] && this.batchFG.value["DurData"].length) {
                const durAudtMdl = new RxAuditLog().durAuditModel;
                this.batchFG.value["DurData"].map(dur => {
                    const d1 = this._newAudit.saveAuditChange(null, dur, "DUR", "Rx", rx.PrescReFill.Id, durAudtMdl, true);
                    if (d1) {
                        chgData.push(JSON.parse(JSON.stringify(d1)));
                    }
                });
            }
        });
        if (this.originalExtaVal && this.originalExtaVal.length) {
            const miscMdl = [{HeaderName: "Rx Origin", ControlName: "RxOrgName"},
                 {HeaderName: "Subm. Clarification", ControlName: "SubCla"},
                 {HeaderName: "Other Coverage Cd", ControlName: "OtherCov"}];
            this.originalExtaVal.map(v => {
                const m1 = this._newAudit.saveAuditChange(v, this.changedExtraVal, "MiscRxInfo", "Rx", v.PrescRefillId, miscMdl, true);
                if (m1) {
                    chgData.push(JSON.parse(JSON.stringify(m1)));
                }
            });
        }
        if (chgData && chgData.length) {
            await this._newAudit.saveAuditDetails(chgData);
        }
    }
    findInsuName(isCash?: any) {
        const InsuranceData = [];
        (this.rxPayRecExist && (this.rxNotPayRecList?.length) ? this.rxNotPayRecList : this.selectedList).map(rx => {
            let insurence = null;
            let priceCode = null;
            if (rx.Patient && rx.Patient.insurance && rx.Patient.insurance.length > 0) {
                insurence = rx.Patient.insurance.find(v => v["insuranceid"] === +rx.RefillInsu.InsuId);
            }
            if (this.priceSchList && this.priceSchList["PriceScheduleList"] && this.priceSchList["PriceScheduleList"].length) {
                priceCode = this.priceSchList["PriceScheduleList"].find(p => p["Id"] === +rx.PrescReFill.PriceSchId);
            }
            rx["rxRelatedCodeInfo"] = {
                BillTo: (insurence && insurence.insuType) ? insurence.insuType : "",
                PriceCode: priceCode && priceCode.PriceCode ? priceCode.PriceCode : ""
            };
            const data = {PrescrefillId: rx.PrescReFill.Id,
                InsurerId: insurence ? insurence.Id : null,
                InsuranceName:  (insurence && insurence.insuType) ? insurence.insuType : null};
            InsuranceData.push(JSON.parse(JSON.stringify(data)));
        });
        if (InsuranceData && InsuranceData.length) {
            this.batchFG.controls["OldRxInsuranceData"].patchValue(InsuranceData);
        }
    }

    formatOrdNFillDt() {
        ((this.rxPayRecExist && (this.rxNotPayRecList?.length)) ? this.rxNotPayRecList : this.selectedList).map(rx => {
            if (rx && rx["PrescReFill"]) {
                rx["PrescReFill"]["OrderDtTm"] = rx["PrescReFill"]["OrderDtTm"] ?
                moment.utc( rx["PrescReFill"]["OrderDtTm"]).local().format("MM/DD/YYYY") : null;
                rx["PrescReFill"]["FillDtTm"] = rx["PrescReFill"]["FillDtTm"] ?
                moment.utc( rx["PrescReFill"]["FillDtTm"]).local().format("MM/DD/YYYY") : null;
            }
        });
    }

    async createInfoForRx() {
        this.authRefills = [];
        this.maxOrdDate = null;
        this.maxFillDate = null;
        for (let i = 0; i <= this.maxRefillsAll; i++) {
            this.authRefills.push({ Id: i, Name: i.toString() });
        }
        this._authRefl$.next(this.authRefills);
        const d = {
            patientId: this.selectedList[0].Patient.patientid,
            PrescreFills: this.selectedList.map(v => {
                const k = {};
                k["PrescId"] = v["Prescription"]["Id"];
                k["ReFillNum"] = v["PrescReFill"]["ReFillNum"];
                k["PartialFillNo"] = v["PrescReFill"]["PartialFillNo"];
                return k;
            })
        };
        this.insuCntrlStngs = await this._rxService.getStngsFrBatchEdit(d).toPromise();
        this.hasDurVal = this.insuCntrlStngs && this.insuCntrlStngs["DurCount"] ? this.insuCntrlStngs["DurCount"] : 0;
        this.selectedList.map(v => {
            if (v && v["RxPay"] && v["RxPay"].length && v["RxPay"].find(p => p["RecType"] === "P")) {
                this.hasPayRec = true;
            }
            if (v && v["PrescReFill"] && (v["PrescReFill"]["ReFillNum"] > 0 || v["PrescReFill"]["PartialFillNo"] > 1 ||
            !(v["PrescReFill"]["ReFillNum"] === 0 && v["PrescReFill"]["PartialFillNo"] === 1 && v["islatestrefill"]))) {
                this.authRefDis = true;
            }
            if (v && v["PrescReFill"]) {
                if (!this.maxOrdDate || (this.maxOrdDate && moment(moment(this.maxOrdDate).format("YYYY-MM-DD"))
                .isAfter(moment(v["PrescReFill"]["OrderDtTm"]).format("YYYY-MM-DD")))) {
                    this.maxOrdDate = moment(v["PrescReFill"]["OrderDtTm"]).format("MM/DD/YYYY");
                }
                if (!this.maxFillDate || (this.maxFillDate && moment(moment(this.maxFillDate).format("YYYY-MM-DD"))
                .isAfter(moment(v["PrescReFill"]["FillDtTm"]).format("YYYY-MM-DD")))) {
                    this.maxFillDate = moment(v["PrescReFill"]["FillDtTm"]).format("MM/DD/YYYY");
                }
            }
            if (v && v["Drug"] && v["Drug"]["drugclass"] >= 2 && v["Drug"]["drugclass"] < 6) {
                const data = this.insuCntrlStngs["controlRefillOption"].find(rf => +rf["DrugClass"] === v["Drug"]["drugclass"]);
                this.maxRefillsAll = data && data["MaxRefillAllow"] < this.maxRefillsAll ? +data["MaxRefillAllow"] : this.maxRefillsAll;
                this.orderToFillDays = data && data["OrderFillDays"] < this.orderToFillDays ? +data["OrderFillDays"] : this.orderToFillDays;
            } else {
                const data = this.insuCntrlStngs && this.insuCntrlStngs["insuRefillSettings"] ?
                (this.insuCntrlStngs["insuRefillSettings"].find(rf => +rf["InsuranceId"] === v["RefillInsu"]["InsuId"])) : null;
                this.maxRefillsAll = data && data["MaxRefills"] < this.maxRefillsAll ? +data["MaxRefills"] : this.maxRefillsAll;
                this.orderToFillDays = data && data["OrderToFillDays"] < this.orderToFillDays ?
                 +data["OrderToFillDays"] : this.orderToFillDays;
            }
        });
        this.authRefills = [];
        for (let i = 0; i <= this.maxRefillsAll; i++) {
            this.authRefills.push({ Id: i, Name: i.toString() });
        }
        this._authRefl$.next(this.authRefills);
        this.extraValueInfo = await this._rxService.getRxExtraDefaultValues(0).toPromise();
    }

    createBatchFG() {
        this.batchFG = this._fb.group({
            DurData: [],
            rxData: [],
            OrderDtTm: null,
            FillDtTm: null,
            RPH: null,
            PharmacistId: null,
            Tech: null,
            TechnicianId: null,
            InsuranceId: null,
            InsuranceCode: null,
            SubClarificationId: null,
            SubClarificationName: null,
            otherCoverageId: null,
            OtherCoverageName: null,
            DueDate: null,
            ChangeStatusToFiled: false,
            RxOriginId: null,
            RxOriginName: null,
            PatientId: null,
            AuthRefills: null,
            OrderStatus: null,
            copayexpCode: null,
            OldRxInsuranceData: null
        });
    }

    createDURFG() {
        this.DurFG = this._fb.group(new DUR());
    }

    getNEWDUR() {
        this.generateWJ(this.newRxDur);
    }

    generateWJ(info: any) {
        if (info) {
            this.totalCount = info.length;
            this.DURWJ = new CollectionView(
                info.map((dur, i) => {
                    const j = {};
                    j["Clinical Signif.Code"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "clinicalSignf", "Id", dur.ClinicalSignfId, "Remarks" );
                    j["Agent Id"] = dur.CoAgentIdType;
                    j["Level"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "effortLvl", "Id", dur.EffortLvlId, "Remarks" );
                    j["Professional Service"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "profServs", "Id", dur.ProfServId, "Remarks" );
                    j["Reason of Service"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "servReason", "Id", dur.ServReasonId, "Remarks" );
                    j["Result of Service"] = this._cmmnServ.getSystmDatVal(this.systemData,
                        "servResult", "Id", dur.ServResultId, "Remarks" );
                    j["Id Q"] = dur.CoAgentNum;
                    j["PH-Comment"] = dur.PhComment;
                    j["Actions"] = "";
                    j["value"] = i;
                    j["Id"] = dur.Id;
                    return j;
                })
            );
            this.actvHeaders = [
                "Actions",
                "Reason of Service",
                "Professional Service",
                "Result of Service",
                "Level",
                "Id Q",
                "Agent Id",
                "Clinical Signif.Code",
                "PH-Comment"
            ];
        }
    }

    addPopUp() {
        this.editMode = false;
        this.DurFG.reset();
        this.DurFG.controls["CoAgentIdType"].setValidators(Validators.nullValidator);
        this.DurFG.controls["CoAgentIdType"].markAsTouched();
        this.DurFG.markAsUntouched();
        this.modal = this._modalService.open(this.DURADD, {
            centered: true,
            size: "lg"
        });
    }

    editNewRxPopUpModal(content, val, nu) {
        this.editMode = true;
        this.editIndex = nu;
        for (const key in this.DurFG.getRawValue()) {
            if (key) {
                this.DurFG.controls[key].patchValue(this.newRxDur[nu][key]);
            }
        }
        this.modal = this._modalService.open(content, {
            size: "lg",
            centered: true, backdrop: false
        });
    }

    addDUR() {
        const length = this.newRxDur && this.newRxDur.length > 0 ? this.newRxDur.length : 0;
        this.DurFG.controls["IsActive"].setValue(true);
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                this.modelRef = this._modalService.open(this.DURADD, {
                    centered: true,
                    backdrop: false
                });
            } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
                this.formGroupInvalid = true;
                this._validUtils.validateAllFormFields(this.DurFG);
            } else {
                this.totalCount = length + 1;
                this.newRxDur.push(this.DurFG.getRawValue());
                if (this.modal) {
                    this.modal.close();
                }
                this.getNEWDUR();
            }
        } else {
            this.formGroupInvalid = true;
        }
    }

    editNewRxDUR() {
        const length = this.newRxDur && this.newRxDur.length > 0 ? this.newRxDur.length : 0;
        if (this.DurFG.valid) {
            if (!this.checkDurFgHasValue()) {
                this.modelRef = this._modalService.open(this.DURADD, {
                    centered: true,
                    backdrop: false
                });
            } else if (this.DurFG.value.CoAgentNum && !this.DurFG.value.CoAgentIdType) {
                this.formGroupInvalid = true;
                this._validUtils.validateAllFormFields(this.DurFG);
            } else {
                this.totalCount = length;
                this.newRxDur[this.editIndex] = this.DurFG.getRawValue();
                if (this.modal) {
                    this.modal.close();
                }
                this.getNEWDUR();
            }
        } else {
            this.formGroupInvalid = true;
        }
    }

    openNewRxDeleteModal(content, val) {
        this.deleteIndex = val;
        this.modal = this._modalService.open(content, {
            centered: true,
            backdrop: false,
            windowClass:"large--content"
        });
    }

    deleteNewRxDur() {
        this.newRxDur.splice(this.deleteIndex, 1);
        this.totalCount = this.newRxDur.length;
        this.getNEWDUR();
    }

    checkDurFgHasValue() {
        const cntrlData = ["ServReasonId", "ProfServId", "ServResultId", "EffortLvlId", "ClinicalSignfId", "CoAgentNum", "CoAgentIdType"];
        let hasValue = false;
        cntrlData.map(cntrl => {
            if (this.DurFG.value[cntrl]) {
                hasValue = true;
            }
        });
        return hasValue;
    }

    convertToCash(rxInfo) {
        let primaryInsu = null;
        primaryInsu = rxInfo.Patient.insurance.filter(
            x => x.insurerid === this.systemData["DefaultValuese"][0]["CashInsurerId"])[0];
        if (primaryInsu && primaryInsu["insuranceid"]) {
            rxInfo.RefillInsu["InsuId"] = primaryInsu["insuranceid"];
            this._insurService.getInsSettingsInfoForRx(primaryInsu.insuplanId);
        }
        const modalRefCash = this._modalService.open(BillToCashComponent, {
            keyboard: false,
            backdrop: false,
            size: "sm",
            windowClass: "md-xs-lg comp--modal",
            centered : true
        });
        modalRefCash.componentInstance.rxInfo = rxInfo;
        modalRefCash.componentInstance.frmBatchEdit = true;
        modalRefCash.componentInstance.IsChangeToCash = "true";
        modalRefCash.componentInstance.IsBillToCashPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(r => {
            modalRefCash.close();
            if(this.dupSelectedList && this.dupSelectedList[this.cashIndex] && this.dupSelectedList[this.cashIndex]["PrescReFill"]) {
                this.dupSelectedList[this.cashIndex]["PrescReFill"] = !r  ? this.dupSelectedList[this.cashIndex]["PrescReFill"] : r["PrescReFill"];
            }
            this.checkCashDisplayComplete();
        });
        modalRefCash.componentInstance.CancelCashPopUp
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(r => {
            modalRefCash.close();
            this.checkCashDisplayComplete(true);
        });

    }

    async checkCashDisplayComplete(isFromCanel: boolean = false) {
        this.cashIndex = this.cashIndex + 1;
        if (isFromCanel && ((this.rxNotPayRecList?.length && this.rxPayRecExist) ? this.rxNotPayRecList.length : this.selectedList.length)=== this.cashIndex) {
            this.rxNotPayRecList = [];
            return;
        } else if (((this.rxNotPayRecList?.length && this.rxPayRecExist) ? this.rxNotPayRecList.length : this.selectedList.length) === this.cashIndex) {
            this.formBatchEditModel("cash");
            await this.getRxExtraVal();
            const resp = await this._rxService.BatchEditSave(this.batchFG.getRawValue()).toPromise();
            if(resp && resp["rxListNotUpdated"] && resp["rxListNotUpdated"].length > 0) {
                this.iscashScenerio = true;
                this.WarnMessage = "Following selected Rx's are edited by another user, hence cannot be edited." + "</br>&nbsp;<span class='font-weight-bold'>" +  resp["rxListNotUpdated"].join(",") + "</span>";
                this.modalOpend = this._modalService.open(this.WARNING, {centered: true, backdrop: false, windowClass: "mode_width_33"});
            } else {
                this.closeBatchEditPopup("cash");
            }
        } else {
            this.convertToCash(this.dupSelectedList[this.cashIndex]);
        }
    }
    closeWarnPopupForCashRx() {
            if(this.modalOpend) {
                this.modalOpend.close();
                this.modalOpend = null;
            }
            if(this.warnModel) {
                this.warnModel.close();
                this.warnModel = null;
            }
            if (this.rxPayRecExist && this.rxNotPayRecList && this.rxNotPayRecList.length && this.isSelectedBillToAsCash) {
                this.cashIndex = 0;
                this.formatOrdNFillDt();
                this.dupSelectedList = JSON.parse(JSON.stringify(this.rxNotPayRecList));
                this.convertToCash(this.dupSelectedList[this.cashIndex]);
            } else {
                this.rxPayRecExist = false;
                this.closeBatchEditPopup();
            }
    }
    async saveBatchEdit() {
        const loginPharmName = this._usrServ.getToken("PhUserName");
        let responseVal: any;
        let orderDtTm = (this.batchFG.value["OrderDtTm"]) ? this.batchFG.value["OrderDtTm"] : this.maxOrdDate;
        orderDtTm = (!this.batchFG.value["OrderDtTm"] && !this.batchFG.value["FillDtTm"]) ? null :  orderDtTm;
        let fillDtTm = this.batchFG.value["FillDtTm"] ? this.batchFG.value["FillDtTm"] : this.maxFillDate;
        fillDtTm = (!this.batchFG.value["OrderDtTm"] && !this.batchFG.value["FillDtTm"]) ? null :  fillDtTm;
        if (this.batchFG.value["Tech"] && !this.techSelc) {
            this.validTechCond = true;
            this.validPharmCond = false;
            responseVal = await this._rxService.checkIsValidTechician(this.batchFG.value["Tech"]).toPromise();
        } else if (this.batchFG.value["RPH"] && !this.pharSecl && this.batchFG.value["RPH"] !== loginPharmName) {
            this.validTechCond = false;
            this.validPharmCond = true;
            responseVal = await this._rxService.checkIsValidPharmacist(this.batchFG.value["RPH"]).toPromise();
        } else {
            this.validTechCond = false;
            this.validPharmCond = false;
           responseVal = true;
        }
        // const resp = this.batchFG.value['Tech'] ? await this._rxService.checkIsValidTechician(this.batchFG.value['Tech']).toPromise() : true;
        if(!responseVal) {
            const Msg = "Invalid Initials.";
            this.openWarnPopup(Msg);
            this.warnOpendF = "rxBTechInitials";
            return;
        } else if (moment(moment(orderDtTm).format("YYYY-MM-DD")).isAfter(moment(fillDtTm).format("YYYY-MM-DD"))) {
            this.openWarnPopup("Ordered Date cannot be greater than Filled Date.");
            this.warnOpendF = "rxBOrderDtTm";
            return;
        } else if (moment(moment(orderDtTm).format("YYYY-MM-DD")).isAfter(moment(fillDtTm).format("YYYY-MM-DD"))) {
            this.openWarnPopup("Filled Date cannot be less than Ordered Date.");
            this.warnOpendF = "rxBFillDtTm";
            return;
        } 
        else {
            if (responseVal !== true) {
                this.captureTechDet = true;
                this.techName = this.batchFG.value["Tech"];
                this.pharmName = this.batchFG.value["RPH"];
                if (this.validTechCond) {
                    this.captureTechPassword();
                } else if (this.validPharmCond) {
                    this.capturePharmPassword();
                }
            } else {
                this.captureTechDet = false;
                this.continueSavingBatchEdit();
            }
        }
    }

    continueSavingBatchEdit() {
        let rxPayRecordsList:any = [];
        if(this.selectedList && this.selectedList.length) {
            this.selectedList.map((item:any) => {
                if(item.RxPay && item.RxPay.length) {
                  if (this._cmnUtils.checkPayRecExistOrNotForSelcRx(item.RxPay)) {
                    rxPayRecordsList.push(item.Prescription.PrescNum + "-" + item.PrescReFill.ReFillNum +
                    (this._commonServ.checkIsPartialFill(item.PrescReFill, item.Prescription) ? "-" + item.PrescReFill.PartialFillNo : " "));
                    } else {
                        this.rxNotPayRecList.push(item);
                    }
                } else {
                    this.rxNotPayRecList.push(item);
                }
             });
             const selectedInsuranceId = this.batchFG.controls["InsuranceId"].value;
             this.selectedList[0].Patient.insurance.map((item: any) => {
                    if(item["insuranceid"] === selectedInsuranceId && (item["IsCash"])) {
                        this.isSelectedBillToAsCash = true;
                    }
             });
        }
    if(rxPayRecordsList && rxPayRecordsList.length && this.isSelectedBillToAsCash)  {
        this.rxPayRecExist = true;
        this.WarnMessage = "Following selected Rx's have paid pay records, hence cannot be Billed as CASH." + "</br>&nbsp;<span class='font-weight-bold'>" +  rxPayRecordsList.join(",") + "</span>";
        this.modalOpend = this._modalService.open(this.WARNING, {centered: true, backdrop: false, windowClass: "mode_width_33"});
        return;
    } else {
        this.rxPayRecExist = false;
        let insu = null;
        this.cashIndex = 0;
        if (this.batchFG.value["InsuranceId"]) {
            insu = this.selectedList[0].Patient.insurance.find(v => v["insuranceid"] === this.batchFG.value["InsuranceId"]);
            this.insForFillDate = insu;
        }
        if (insu && insu["IsCash"]) {
            this.formatOrdNFillDt();
            this.dupSelectedList = JSON.parse(JSON.stringify(this.selectedList));
            this.convertToCash(this.dupSelectedList[this.cashIndex]);
        } else if (this.selectedList.length) {
            this.selectedList.map(item => {
                if (item["rPHVerifs"] && item["rPHVerifs"].length && item["rph1VerificationStatus"] === "R") {
                    this.VerifiedList.push(item);
                }
            });
            if (this.VerifiedList.length) {
                const rxsList: any = [];
                let rx = "";
                this.VerifiedList.map(item => {
                    if (item) {
                        rxsList.push(item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] +
                        (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " "));
                    }
                  });
                  rxsList.forEach(item => {
                    rx += "<br/><label>" + item + "</label>";
                });
                const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {backdrop: false,
                    keyboard: false, windowClass: "large--content max-modal-34"});
                modelRef.componentInstance.IsHeaderText = "Confirmation";
                modelRef.componentInstance.warnMsg =  "Following Rx(s) have Rx Verification records:"
                + rx + "<br/>Would you like to Re-Verify these records?",
                modelRef.componentInstance.yesButton = true;
                modelRef.componentInstance.noButton = true;
                modelRef.componentInstance.IsPopUpClosed
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((response: any) => {
                        modelRef.close();
                        if (response) {
                            this.VerifiedList.map(async item => {
                                // if (item["drugPickVerif"] && item["drugPickVerif"].length &&
                                //  item["drugPickVerificationStatus"].toLowerCase()  === "p") {
                                //     this._auditUtils.getChangedValues(
                                //         null,
                                //         {"DP Verification Status": "Re-verify"},
                                //         "DP Verification",
                                //         "Rx",
                                //        item.PrescReFill.Id
                                //     );
                                //    await this._nrxUtils.updateVerfStatus(item, "D");
                                // } 
                                if (item["rPHVerifs"] && item["rPHVerifs"].length) {
                                    if (item["rph1VerificationStatus"]  === "R") {
                                        this._auditUtils.getChangedValues(
                                            null,
                                            {"Rx Verification Status": "Re-verify"},
                                            "Rx Verification",
                                            "Rx",
                                            item.PrescReFill.Id
                                        );
                                       await this._nrxUtils.updateVerfStatus(item, "X");
                                    }
                                    // if (item["rph2VerificationStatus"]  === "V") {
                                    //     this._auditUtils.getChangedValues(
                                    //         null,
                                    //         {"Rph Verification Status": "Re-verify"},
                                    //         "Rph Verification",
                                    //         "Rx",
                                    //         item.PrescReFill.Id
                                    //     );
                                    //    await this._nrxUtils.updateVerfStatus(item, "R");
                                    // }
                                }
                            });
                        }
                        this.checkOrderToFirstFillDays();
                    });
            } else if(this.batchFG.value["FillDtTm"] && !this.VerifiedList.length){
                this.checkOrderToFirstFillDays();
            }
            else {
                this.batchEditSave();
            }
        } else {
            this.batchEditSave();
        }
    }
    }
    async batchEditSave() {
        this.formBatchEditModel();
        await this.getRxExtraVal();
        const resp = await this._rxService.BatchEditSave(this.batchFG.getRawValue()).toPromise();
        if(resp && resp["rxListNotUpdated"] && resp["rxListNotUpdated"].length > 0) {
            this.WarnMessage = "Following selected Rx's are edited by another user, hence cannot be edited." + "</br>&nbsp;<span class='font-weight-bold'>" +  resp["rxListNotUpdated"].join(",") + "</span>";
            this.modalOpend = this._modalService.open(this.WARNING, {centered: true, backdrop: false, windowClass: "mode_width_33"});
        } else {
            this.closeBatchEditPopup();
        }
    }
    async checkOrderToFirstFillDays(){
        this.VerifiedList = [];
        let insuStng;
        const fillDtTm = this.batchFG.value["FillDtTm"] ? this.batchFG.value["FillDtTm"] : this.maxFillDate;
        const fillDate = "<label>" + (moment(fillDtTm).format("MM/DD/YYYY")) + "</label>";
        if(this.insForFillDate?.insuplanId){
            insuStng = await this._insurService.getInsSettingsInfo(this.insForFillDate?.insuplanId).toPromise();
        }
        if(this.selectedList?.length && this.batchFG.value["FillDtTm"]){
            if (this.selectedList.length) {
                const rxFillDatesList: any = [];
                let fillDateRxs = "";
                this.selectedList.map(item => {
                    if (item) {
                        const orderDtTm = moment(item?.PrescReFill?.OrderDtTm).format("MM/DD/YYYY");
                        const fillDays = moment(fillDtTm).diff(orderDtTm, "days");
                        if(!(this.batchFG.value["InsuranceId"])){
                            if(+item.primaryInsurance.OrderToFillDays < fillDays){
                                rxFillDatesList.push(item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] +
                                    (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " "));
                            } else{
                                this.drugClassBasedEditList.push(item);
                            }
                            this.selectedList = this.drugClassBasedEditList?.length ? this.drugClassBasedEditList : [];
                        } else if((this.batchFG.value["InsuranceId"])){
                            if(!(item["Drug"]["drugclass"] >= 2 && item["Drug"]["drugclass"] < 6)){
                                if(insuStng.InsuPlanOther.OrderToFillDays < fillDays){
                                    rxFillDatesList.push(item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] +
                                    (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " "));
                                } else{
                                    this.drugClassBasedEditList.push(item);
                                }
                            } else{
                                if(+item.primaryInsurance.OrderToFillDays < fillDays){
                                    rxFillDatesList.push(item["Prescription"]["PrescNum"] + "-" + item["PrescReFill"]["ReFillNum"] +
                                    (this._commonServ.checkIsPartialFill(item["PrescReFill"], item["Prescription"]) ? "-" + item["PrescReFill"]["PartialFillNo"] : " "));
                                } else{
                                    this.drugClassBasedEditList.push(item);
                                }
                            }
                            this.selectedList = this.drugClassBasedEditList?.length ? this.drugClassBasedEditList : [];
                        }
                    }
                  });
                rxFillDatesList?.forEach(item => {
                    fillDateRxs += "Rx#: <label>" + item + "</label><br/>";
                });
                if(rxFillDatesList?.length){
                    const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {backdrop: false,
                        keyboard: false, windowClass: "large--content max-modal-34"});
                    modelRef.componentInstance.IsHeaderText = "Information";
                    modelRef.componentInstance.warnMsg =  "Selected Fill Date" + " " + fillDate + " " + "could not be applied on the following Rx's as the Order to First Fill Date is not within the selected Fill Date range based on the setting applied as per the drug class" + "<br/>" +
                    (fillDateRxs + " " + "<br/>")
                    modelRef.componentInstance.okButton = true;
                    modelRef.componentInstance.IsPopUpClosed
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((response: any) => {
                            if (response) {
                                modelRef.close();
                                this.batchEditSave();
                            }
                    });
                } else{
                    this.batchEditSave();
                }
            }
        } else{
            this.batchEditSave();
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async getRxExtraVal() {
        const batchVal = this.batchFG.value;
        if (batchVal["InsuranceId"]) {
            this.findInsuName();
        }
        if (batchVal["SubClarificationName"] || batchVal["OtherCoverageName"] || batchVal["RxOriginName"]) {
            const prescIds = (this.rxPayRecExist && (this.rxNotPayRecList?.length) ? this.rxNotPayRecList : this.selectedList).map(v => {
                return v["PrescReFill"]["Id"];
            });
            const rxExtraVal: any = await this._rxService.getMultiRxExtraVal(prescIds).toPromise();
            if (rxExtraVal && rxExtraVal.length) {
                this.originalExtaVal = rxExtraVal.map(v => {
                    const k = {};
                    k["PrescRefillId"] = v["PrescRefillId"];
                    k["RxOrgName"] = v["RxOriginName"] && this.batchFG.value["RxOriginName"] ? v["RxOriginName"].toUpperCase() : null;
                    k["OtherCov"] = v["OtherCoverageName"] && this.batchFG.value["OtherCoverageName"]
                    ? v["OtherCoverageName"].toUpperCase() : null;
                    k["SubCla"] = v["SubClarification1Name"] && this.batchFG.value["SubClarificationName"]
                    ? v["SubClarification1Name"].toUpperCase() : null;
                    return k;
                });
                this.changedExtraVal = {
                    RxOrgName: this.batchFG.value["RxOriginName"] ? this.batchFG.value["RxOriginName"].toUpperCase() : null,
                    OtherCov: this.batchFG.value["OtherCoverageName"] ? this.batchFG.value["OtherCoverageName"].toUpperCase() : null,
                    SubCla: this.batchFG.value["SubClarificationName"] ? this.batchFG.value["SubClarificationName"].toUpperCase() : null
                };
            } else {
                this.originalExtaVal = null;
            }
        } else {
            this.originalExtaVal = null;
        }
    }

    changeCheckFiled() {
        if (!this.batchFG.value["ChangeStatusToFiled"]) {
            this.batchFG.controls["DueDate"].patchValue(null);
        } else {
            if (this.insuCntrlStngs && this.insuCntrlStngs["oldRefillsCount"] && this.insuCntrlStngs["oldRefillsCount"] > 0) {
                this.openWarnPopup("Selected Rx's must be latest refills to change status to Deferred.");
                this.warnOpendF = "FFB";
                this.batchFG.controls["ChangeStatusToFiled"].patchValue(false);
                this.batchFG.controls["DueDate"].patchValue(null);
            } else if (this.hasPayRec) {
                this.openWarnPopup("Cannot Defer the Rx's having Paid PayRecords.");
                this.warnOpendF = "FFB";
                this.batchFG.controls["ChangeStatusToFiled"].patchValue(false);
                this.batchFG.controls["DueDate"].patchValue(null);
            } else {
                this.batchFG.controls["DueDate"].patchValue(moment().format("MM/DD/YYYY"));
            }
        }
    }

    formBatchEditModel(isCash?: any) {
        const list = JSON.parse(JSON.stringify(isCash ? this.dupSelectedList : this.selectedList));
        const rxData = list?.map(data => {
            const k = {};
            k["PrescId"] = data["Prescription"]["Id"];
            k["PrescNum"] = data["Prescription"]["PrescNum"];
            k["prescReFill"] = data["PrescReFill"];
            k["DrugClass"] = data["Drug"]["drugclass"];
            k["drugtypeid"] = data["Drug"]["drugtypeid"];
            k["unitpriceawp"] = data["Drug"]["unitpriceawp"];
            k["rxOtherAmountId"] = data["RxOtherAmount"] ? data["RxOtherAmount"]["Id"] : 0;
            return k;
        });
        this.batchFG.patchValue({
            rxData: rxData,
            DurData: this.newRxDur,
            PatientId: list?.[0]?.["Patient"]?.["patientid"]
        });
        this.batchFG.controls["DurData"].patchValue(this.newRxDur);
    }

    closeWarnPopup(key) {
        if (this.warnModel) {
            this.warnModel.close();
        }
        if (this.warnOpendF === "rxBOrderDtTm") {
            this.batchFG.controls["OrderDtTm"].patchValue(null);
        } else if (this.warnOpendF === "rxBFillDtTm") {
            this.batchFG.controls["FillDtTm"].patchValue(null);
        }
        if (document.getElementById(this.warnOpendF)) {
            document.getElementById(this.warnOpendF).focus();
        }
        this.warnOpendF = null;
    }

    openWarnPopup(msg) {
        this.warnMsg = msg;
        this.warnModel = this._modalService.open(this.ORDERSUPLDAYS, {centered: true, backdrop: false, windowClass:"large--content"});
    }

    checkDateValidation(key) {
        const orderDtTm = this.batchFG.value["OrderDtTm"] ? this.batchFG.value["OrderDtTm"] : this.maxOrdDate;
        const fillDtTm = this.batchFG.value["FillDtTm"] ? this.batchFG.value["FillDtTm"] : this.maxFillDate;
        if (key === "OrderDtTm") {
            if (moment(moment(orderDtTm).format("YYYY-MM-DD")).isAfter(moment(fillDtTm).format("YYYY-MM-DD"))) {
                this.openWarnPopup("Ordered Date cannot be greater than Filled Date.");
                this.warnOpendF = "rxBOrderDtTm";
            }
        } else if (key === "FillDtTm") {
            if (moment(moment(orderDtTm).format("YYYY-MM-DD")).isAfter(moment(fillDtTm).format("YYYY-MM-DD"))) {
                this.openWarnPopup("Filled Date cannot be less than Ordered Date.");
                this.warnOpendF = "rxBFillDtTm";
            }
        }
    }

    // checkDaysDiff(key) {
    //     const orderDtTm = this.batchFG.value["OrderDtTm"] ? this.batchFG.value["OrderDtTm"] : this.maxOrdDate;
    //         const fillDtTm = this.batchFG.value["FillDtTm"] ? this.batchFG.value["FillDtTm"] : this.maxFillDate;
    //     if (orderDtTm && fillDtTm) {
    //         if (moment(fillDtTm).diff(orderDtTm, "days") > +this.orderToFillDays) {
    //             const ordMsg = "Order to fill date exceeds Maximum Allowed of " + (+this.orderToFillDays) + ".";
    //             this.openWarnPopup(ordMsg);
    //             this.warnOpendF = key === "OrderDtTm" ? "rxBOrderDtTm" : "rxBFillDtTm";
    //         }
    //     }
    // }

    async techCheck(value) {
        const enteredName = value.target;
        if (enteredName.value && enteredName.value !== this.techSelc) {
            this.checkIsValidTechician(enteredName.value);
        }
    }

    captureTechPassword() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.modalRef = this._modalService.open(this.TECHPASSWORD, {centered: true, keyboard: false, backdrop: false});
    }

    closePasswordpopup(type) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (document.getElementById(type)) {
            document.getElementById(type).focus();
        }
    }

    SaveTechPass(value) {
        this.savedTechPass = value;
    }

    async validateTechPass(techPass: any) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (techPass && techPass.target.value && techPass.target.value !== " ") {
            const resp = await this._rxService.checkTechicianPassword(techPass.target.value,
                (this.techName).toLowerCase()).toPromise();
            if (resp && resp.Status === 1) {
                this.techSelc = this.techName;
                if (this.captureTechDet) {
                    // this.continueSavingBatchEdit();
                    this.saveBatchEdit();
                }
            } else {
                let Msg = "Password Incorrect.";
                if (resp && resp.Status === 2) {
                    Msg = "User Locked.";
                } else {
                    Msg = "Password Incorrect.";
                }
                this.warnOpendF = "rxBTechInitials";
                this.openWarnPopup(Msg);
            }
        } else {
            const Msg = "Password Incorrect.";
            this.warnOpendF = "rxBTechInitials";
            this.openWarnPopup(Msg);
        }
    }

    async checkIsValidPharm(value) {
        const pharmName = value.target;
        const loginPharmName = this._usrServ.getToken("PhUserName");
        if (pharmName.value && pharmName.value !== this.pharSecl && pharmName.value !== loginPharmName) {
            const resp = await this._rxService.checkIsValidPharmacist(pharmName.value).toPromise();
            if (resp) {
                this.pharmName = pharmName.value;
                this.capturePharmPassword();
            } else {
                const Msg = "Invalid Initials.";
                this.warnOpendF = "rxBPH";
                this.openWarnPopup(Msg);
            }
        }
    }

    capturePharmPassword() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        setTimeout(() => {
            this.modalRef = this._modalService.open(this.PHARMPASSWORD, {centered: true, keyboard: false, backdrop: false});
        }, 10);
    }

    SavePharmPass(value) {
        this.savedPharmPass = value;
    }

    async validatePharmPass(PharmPass: any) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (PharmPass && PharmPass.target.value && PharmPass.target.value !== " ") {
            const resp = await this._rxService.checkTechicianPassword(PharmPass.target.value,
                        (this.pharmName).toLowerCase()).toPromise();
            if (resp && resp.Status === 1) {
                this.pharSecl = this.pharmName;
                if (this.captureTechDet) {
                    this.continueSavingBatchEdit();
                }
            } else {
                let Msg = "Password Incorrect.";
                if (resp && resp.Status === 2) {
                    Msg = "User Locked.";
                } else {
                    Msg = "Password Incorrect.";
                }
                this.warnOpendF = "rxBPH";
                this.openWarnPopup(Msg);
            }
        } else {
            const Msg = "Password Incorrect.";
            this.warnOpendF = "rxBPH";
            this.openWarnPopup(Msg);
        }
    }
    async checkIsValidTechician(enteredName) {
        const resp = await this._rxService.checkIsValidTechician(enteredName).toPromise();
        if (resp) {
            this.techName = enteredName;
            this.captureTechPassword();
        } else {
            const Msg = "Invalid Initials.";
            this.warnOpendF = "rxBTechInitials";
            this.openWarnPopup(Msg);
        }
    }
}
