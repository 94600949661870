
export const ErxStatusTitles = {
    PROCESSEDALTERNATIVESAVAILABLE: "Processed Alternatives Available",
    DISCONTINUEDNDC: "Discontinued NDC",
    DRUGNOTFOUND: "Drug Not Found",
    MISSINGINVALIDDOB: "Missing Invalid DOB",
    MISSINGINVALIDDAYSSUPPLY: "Missing Invalid Days Supply",
    MISSINGINVALIDGENDERCODE: "Missing Invalid Gender Code",
    PROCESSED: "Processed",
    MISSINGINVALIDPRESCRIBERID: "Missing Invalid Prescriber ID",
    MISSINGINVALIDQUANTITY: "Missing Invalid Quantity",
    NOTPROCESSED: "Not Processed",
    PBMMEMBERIDMISSINGINVALID: "PBM Member ID Missing Invalid",
    PATIENTNOTCOVERED: "Patient Not Covered",
    PATIENTNOTFOUND: "Patient Not Found",
    PHARMACYIDMISSINGINVALID: "Pharmacy ID Missing Invalid",
    SYSTEMERROR: "System Error",
    UNMATCHEDQUANTITYUNIT: "Unmatched Quantity Unit",
    VALIDATIONERROR: "Validation Error"
};
