<ng-template #AssessmentForm let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Clinical Assessment Form</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body modal--body_height max--height_71">
        <div class="">
            <div class="box-default mb-2">
                <div class="exprx--common-block border_AllRadius transmit--block erx--body insurance--body">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <span class="header--sml__htex width_85px"> Patient Info - {{patientName}}</span>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                <label class="font_Styles--400_1--1rm">Rx#:</label>
                                <div class="label--data">
                                    {{rxNo}}
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                <label class="font_Styles--400_1--1rm">DOB</label>
                                <div class="label--data">
                                    {{ patientInfo?.dob | date: "MM/dd/yyyy" }}
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                <label class="font_Styles--400_1--1rm">Gender</label>
                                <div class="label--data">
                                    {{ patientInfo?.gender ? patientInfo?.gender  : "--" }}
                                </div>
                            </div>
                            
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                        <label class="font_Styles--400_1--1rm">Age</label>
                                        <div class="label--data">{{ age ? age : "--" }}</div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                        <label class="font_Styles--400_1--1rm">Facility</label>
                                        <div class="label--data">
                                            {{ patientInfo?.PatientFacilityInfo?.Organization?.Name ?
                                                patientInfo?.PatientFacilityInfo?.Organization?.Name : "--"  }}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="font_Styles--400_1--1rm">Drug Name</label>
                                        <div class="label--data">
                                            {{drugName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-lg-4 col-xs-4">
                                <label class="font_Styles--400_1--1rm">Address</label>
                                <div class="label--data">
                                    <span *ngIf="patientInfo?.address1 && patientInfo?.address1 !== '###'">
                                        {{patientInfo?.address1}},
                                    </span>
                                    <span *ngIf="patientInfo?.address2 && patientInfo?.address2 !== '###'">
                                        {{patientInfo?.address2}},
                                    </span>
                                    <span *ngIf="patientInfo?.city && patientInfo?.city !== '###'">
                                        {{patientInfo?.city}},
                                    </span>
                                    <span *ngIf="patientInfo?.state && patientInfo?.city !== '###'">
                                            {{patientInfo?.state}},
                                    </span>
                                    <span *ngIf="patientInfo?.zipcode && patientInfo?.zipcode !== '###'">
                                        {{patientInfo?.zipcode}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-2 col-xs-2 pr-0">
                                <label class="font_Styles--400_1--1rm">Phone#</label>
                                <div class="label--data">
                                    <span>{{patientInfo?.mobile ? (patientInfo?.mobile | mask : "(000)000-0000"):"--"}}</span>,
                                    <span>{{patientInfo?.telephone ? (patientInfo?.telephone | mask : "(000)000-0000"):"--"}}</span>
                                </div>
                            </div>
                            <div class="col-md-5 col-lg-5 col-xs-5">
                                <label class="font_Styles--400_1--1rm">Allergies</label>
                                <div class="label--data max-height_50px overflow_y_scroll" *ngIf="patientInfo?.patallergies?.length > 0">
                                    <span *ngFor="let allergy of patientInfo?.patallergies; let i=index">
                                       <span [ngClass]="allergy?.Name === 'No Known Allergies' ? '' : 'allergy-color'"> {{ allergy?.Name }}</span> <span *ngIf="i !== (patientInfo?.patallergies?.length - 1)"> , </span>
                                    </span>
                                </div>
                                <div class="label--data" *ngIf="patientInfo?.patallergies?.length === 0">
                                    <span> -- </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="((viewMode && formDetails?.form?.sections?.length) || formDetails?.sections?.length)">
                <div *ngFor="let eachSec of sectionsList">
                    <div class="exprx--common-block border_AllRadius transmit--block erx--body insurance--body">
                        <div class="eprx--block__header" >
                            <div class="eprx--header__heading">
                                <span class="header--sml__htex width_85px">{{eachSec?.sectionName}}</span>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4" *ngFor="let qsn of eachSec.questions" [ngClass]="eachSec?.displayOrder === 2 && !qsn.response?.toggleValue ? 'pt-1': ''">
                                        <div class="row">
                                            <label class="col-10 font_Styles--400_1--1rm">{{qsn.question}}</label>
                                            <div class="col-2" *ngIf="qsnTypeEnums.YesOrNo === qsn.questionTypeId">
                                                <eprx-toggle [IsChecked]="qsn.response?.toggleValue" [RxselectId]="'IsAutoSfax'"
                                                [withOutFrmGrp]="true" (TriggerSelectValue)="displayDrop($event, qsn.questionId, qsn)">
                                                </eprx-toggle>
                                            </div>
                                            <span class="col-12" *ngIf="qsn.response?.toggleValue && qsn.childQuestions?.length">{{qsn.childQuestions?.[0]?.question}}</span>
                                                <eprx-select class="col-12" *ngIf="qsn.response?.toggleValue && qsn.childQuestions?.length && qsnTypeEnums.DropDown === qsn.childQuestions[0].questionTypeId" [LabelText]="''" [RxSelectId]="'childQsn'+qsn.childQuestions[0].questionId" [PlaceHolder]="''"  [ControlName]="'childQsn'+qsn.childQuestions[0].questionId" [FormGroupName]="childQsnFG"
                                                [BindLabel]="'description'" [BindValue]="'optionId'" [List]="qsn.childQuestions?.[0]?.options?.length ? (qsn.childQuestions[0].options) : null"  [AddItem]="true" [SelectOnTab]="true"  [HasMultiple]="qsn.childQuestions[0].isMultiSelect" (TriggerSelectValue)="getChildVal($event.value, qsn.childQuestions[0].questionId, qsn.childQuestions[0])">
                                                </eprx-select>
                                                <eprx-text-area class="col-12" *ngIf="qsn.response?.toggleValue && qsn.childQuestions?.length && qsnTypeEnums.Text === qsn.childQuestions[0].questionTypeId" [LabelText]="' '" [ControlName]="'childQsn'+qsn.childQuestions[0].questionId" [FormGroupName]="childQsnFG" [PlaceHolder]="''" [MaxLength]="150" (TriggerOnTabbing)="patchTexts($event, qsn.childQuestions[0])" [Rows]="2"></eprx-text-area>
                                                <eprx-date-picker class="col-12" *ngIf="qsn.response?.toggleValue && qsn.childQuestions?.length && qsnTypeEnums.Date === qsn.childQuestions[0].questionTypeId" [LabelText]="' '" [ControlName]="'childQsn'+qsn.childQuestions[0].questionId" [FormGroupName]="childQsnFG" [PlaceHolder]="''" [MinDate]="" [MaxDate]="maxDate"  (EmitChangeValue)="patchDateFields($event, qsn.childQuestions[0])"
                                                ></eprx-date-picker>
                                        </div>
                                        <eprx-select *ngIf="qsnTypeEnums.DropDown === qsn.questionTypeId" [LabelText]="' '" [FormGroupName]="directQsnsFG" [ControlName]="'drctQsn'+qsn.questionId"
                                         [RxSelectId]="'drctQsn'+qsn.questionId" [PlaceHolder]="''"  [BindLabel]="'description'" [BindValue]="'optionId'" [List]="qsn.options?.length ? qsn.options : null" [HasMultiple]="qsn.isMultiSelect" [AutoFocus]="(eachSec.displayOrder === 1 && qsn.displayOrder === 1) ? true : false"
                                        [AddItem]="true" [SelectOnTab]="true" (TriggerSelectValue)="getSelcVal($event?.value, qsn)">
                                        </eprx-select>
                                        <div class="col-8 p-0">
                                            <eprx-date-picker *ngIf="qsnTypeEnums.Date === qsn.questionTypeId" [LabelText]="' '" [ControlName]="'dateQsn'+qsn.questionId" [FormGroupName]="dateTimeFG" [PlaceHolder]="''" [MinDate]="" [MaxDate]="maxDate"  (EmitChangeValue)="patchDateFields($event, qsn)"
                                        >
                                        </eprx-date-picker>
                                        </div>
                                        
                                        <eprx-text-area *ngIf="qsnTypeEnums.Text === qsn.questionTypeId" [LabelText]="' '" [ControlName]="'Text'+qsn.questionId" [FormGroupName]="textQsnFG" [PlaceHolder]="''" [MaxLength]="150" (TriggerOnTabbing)="patchTexts($event, qsn)"
                                        [Rows]="2" ></eprx-text-area>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div><br>
                <div *ngIf="((viewMode && !formDetails?.form?.sections?.length) || (!viewMode && !formDetails?.sections?.length))">
                    <h5 class="exprx--common-block border_AllRadius transmit--block erx--body insurance--body erx-inactive-drug">{{MsgConfig.WRONG_MSG}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div class="float-right">
                <input *ngIf="((viewMode && formDetails?.form?.sections?.length) || formDetails?.sections?.length)" class="form-check-input margin_top_0_7rem" type="checkbox" [id]="markComplt" name=""
                [(ngModel)]="markComplete" [checked]="markComplete" (change)="ChangeStatus($event?.target?.checked)" />
                <label *ngIf="((viewMode && formDetails?.form?.sections?.length) || formDetails?.sections?.length)" class="form-check-label font_Styles--400--1rm" [for]="markComplt">Mark Complete
                </label>
                <button [ngClass]="formDetails?.sections?.length ? 'hotkey_success ml-2' : 'inactive ml-2'"  *ngIf="!viewMode" id="SaveBtn" appShortcutKey [AltKey]="'s'" (click)="saveForm()"><span>S</span>Save
                </button>
                <button [ngClass]="formDetails?.form?.sections?.length ? 'hotkey_success ml-2' : 'inactive ml-2'"  *ngIf="viewMode" id="updateBtn" appShortcutKey [AltKey]="'u'" (click)="saveForm()"><span>U</span>Update
                </button>
                <button class="hotkey_success" id="CancelBtn" appShortcutKey [AltKey]="'c'" (click)="closeModal()"><span>C</span>Cancel
                </button>
            </div>
        </div>
    </div>
 </ng-template>
