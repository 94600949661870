export class DeliveryAddress {
    StateName: string = null;
    CityName: string = null;
    TotalCount: number = null;
    Id: number = null;
    TenantId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    ZipCode: string = null;
    CountryId: number = null;
    StateId: number = null;
    CityId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    Extension: string = null;
}

export class SearchParams {
    SearchText: string = null;
    SearchBy: string = null;
    OrderByColumn: string = null;
    SortDirection: string = null;
    PageNumber: number = null;
    PageSize: number = null;
}
