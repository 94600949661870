
<div class="eprx--block__content" *ngIf="!openRxDur">
    <div class="editpatient--body newrx--body edit--drug edit--drug">
        <div class="exprx--common-block">
            <div class="eprx--block__header ">
                <div class="row">
                    <div class="col">
                        <span class="eprx--header__heading">DUR Messages</span>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content">
                <wj-flex-grid #DURlist [headersVisibility]="'Column'" class="max-height_10rem" [selectionMode]="'Row'" [itemsSource]="DURWJ" [isReadOnly]="true"
                    [columnPicker]="''">
                    <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="
                                                                let actvHeader of actvHeaders;
                                                                let i = index" [width]="(actvHeader === 'Reason' || actvHeader === 'Comment') ? '*' : 100">
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
        </div>
    </div>
</div>

<ng-template #DURADD let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">DUR Entry</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal(); d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body box-body" [formGroup]="DurFG">
        <div class="box box-default box-solid">
            <div class="eprx--block__header">
                <span class="eprx--header__heading"></span>
            </div>
            <div class="box-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <eprx-select [IsFirstElem]="true" [LabelText]="'Reason For Service'" [PlaceHolder]="''"
                                [ControlName]="'ServReasonId'" [FormGroupName]="DurFG" [secondCntrlN]="'ServReasonName'"
                                [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'"
                                [LabelForId]="'Id'" [bindScnCntrlV]="'Name'" [List]="systemData?.servReason"
                                [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6">
                            <eprx-select [LabelText]="'Professional Service'" [PlaceHolder]="''" [ControlName]="'ProfServId'"
                                [FormGroupName]="DurFG" [secondCntrlN]="'ProfServName'" [HasMultiple]="false" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [bindScnCntrlV]="'Name'"
                                [List]="systemData?.profServs" [ErrorDefs]="{ required: 'Required' }"
                                [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                            </eprx-select>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <eprx-select [LabelText]="'Result Of Service'" [PlaceHolder]="''" [ControlName]="'ServResultId'"
                                [FormGroupName]="DurFG" [secondCntrlN]="'ServResult'" [HasMultiple]="false" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [bindScnCntrlV]="'Name'"
                                [List]="systemData?.servResult" [ErrorDefs]="{ required: 'Required' }"
                                [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6">
                            <eprx-select [LabelText]="'Level Of Effort'" [PlaceHolder]="''" [ControlName]="'EffortLvlId'"
                                [FormGroupName]="DurFG" [HasMultiple]="false" [secondCntrlN]="'EffortLvlName'" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="systemData?.effortLvl"
                                [bindScnCntrlV]="'Name'" [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                            </eprx-select>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <eprx-input [LabelText]="'Co-Agent ID'" [PlaceHolder]="''" [ControlName]="'CoAgentNum'" [MaxLength]="19"
                                [InputType]="'ALPHANUMERIC'" [FormGroupName]="DurFG"></eprx-input>
                        </div>
                        <div class="col-lg-6">
                            <eprx-select [LabelText]="'Co-Agent ID Type'" id="CoAgentId" [PlaceHolder]="''"
                                [ControlName]="'CoAgentIdType'" [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Name'" [LabelForId]="'Id'" [List]="systemData?.coAgentIdCat"
                                [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid"
                                [IsRequired]="DurFG?.value['CoAgentNum'] !== null"
                                [MarkAsTouched]="DurFG?.controls['CoAgentIdType']?.touched" [Show2Values]="true">
                            </eprx-select>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                    <div class="col-lg-6">
                        <eprx-select [LabelText]="'Clinical Significance'" [PlaceHolder]="''" [ControlName]="'ClinicalSignfId'" [FormGroupName]="DurFG"  [secondCntrlN]="'ClinicalSignfName'" [bindScnCntrlV]="'Name'"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.clinicalSignf" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6" [formGroup]="DurFG">
                        <label class="align-label"> Pharmacist Comment </label>
                        <textarea class="custom--text__area" rows="1" cols="73" formControlName="PhComment" placeholder="Comments"></textarea>
                    </div>
                </div>
                </div>
                <div class="col-12">
                    <input class="form-check-input" type="checkbox" formControlName="IsRefillSpecific" id="IsRefillSpecific"/>
                    <label class="form-check-label">
                        Use this for current refill only.
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" *ngIf="!editMode" (click)="addDUR()" (keydown.enter)="enterKeyDownEv($event, 'addDUR')" appShortcutKey [AltKey]="'s'">
                   <span>S</span> Save
                </button>
                <button class="hotkey_success" *ngIf="editMode" (click)="addDUR()" (keydown.enter)="enterKeyDownEv($event, 'addDUR')" appShortcutKey [AltKey]="'u'">
                   <span>U</span> Update
                </button>
                <button class="hotkey_success"(keydown.enter)="closeModal(); d('Cross click')" (click)="closeModal(); d('Cross click')" appShortcutKey [AltKey]="'c'">
                   <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #info let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Please Correct the Information</h4>
        <button type="button"  [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>Unable to save empty record, please specify value for atleast 1 field.</span>
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" (click)="d('Cross click')" (keydown.enter)="enterKeyDownEv($event, 'Info')" appShortcutKey InputKey="o"><b>O</b>OK</button>
</div>
</ng-template>

<ng-template #openDURPopup  let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeDURModal()">
    <h4 header>
        Drug Utilization Review (DUR)
    </h4>
    <span body>
        <div class="height_31rem">
        <app-dur
                (IsPopUpClosed)="closeDURModal()" 
                [rxno]="rxno"
                [refId]="refId"
                [systemData]="systemData"
                [rxType]="'er'" 
                [openRxDur]="true"
        ></app-dur>
    </div>
    </span>
    <span footer>
        <button ngbAutofocus class="hotkey_success"(keydown.enter)=closeDURModal() (click)="closeDURModal()" appShortcutKey [AltKey]="'c'">
            <span>C</span> Cancel
         </button>
    </span>
</app-custom-modal>
</ng-template>