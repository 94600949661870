import { Component, OnInit, EventEmitter, ViewChild, Output, Input, AfterViewInit } from "@angular/core";
import { FormBuilder, FormArray, FormGroup, FormControl } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AlertService, CommonService, UserService } from "src/app/services";
import * as moment from "moment";
import { PrintService } from "src/app/services/print.service";
import { WjFlexGrid } from "@grapecity/wijmo.angular2.grid";
import { Subject, forkJoin } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonUtil, WijimoUtil } from "src/app/utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { takeUntil } from "rxjs/operators";


@Component({
    selector: "app-print-fax-request",
    templateUrl: "./print-fax-request.component.html",
})
export class PrintFaxRequestComponent implements OnInit, AfterViewInit {

    @Input()
    set FaxInfo(data: any[]) {
        if (data) {
            this.printRefWJ = data;
        }
    }
    @Input("isFromTrans")
    isFromTrans = false;

    @Input("isCmsChecked")
    isCmsChecked = false;

    @Input("transmResp")
    transmResp: any;

    @Input()
    set RxInfo(data: any[]) {
        if (data) {
            this.selectedRx = data;
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    get MultiSingleRxPg(): FormGroup {
        return this.faxFG.get("MultiSingleRxPg") as FormGroup;
    }

    @ViewChild("PrintFax", { static: true })
    PrintFax: any;

    @ViewChild("MissingPop")
    MissingPop: any;

    @ViewChild("AddFaxNo")
    AddFaxNo: any;

    @ViewChild("printRef")
    printRef: WjFlexGrid;

    @ViewChild("Batchque")
    Batchque: any;

    @ViewChild("Batchque1")
    Batchque1: any;

    @ViewChild("Batchque2")
    Batchque2: any;

    printRefWJ: any[];
    fromName: any;
    updateFaxArr: any[] = [];
    disableFaxAndBatch = false;
    chkSamePrescOrNot: null;
    checkForMissingRx = false;
    prescriberNames: any;
    prepareBatchedRxss: CollectionView;
    Prescriber: any;
    ht: any;
    showPreview: boolean = false;
    selectedRx: any[];
    forkArray: any[] = [];
    forkArrayItems: any[] = [];
    pushFaxNo: any[] = [];
    duplicatePrescNames: any[] = [];
    prescNamesFA: FormArray;
    notes: any[] = [];
    fax: any;
    updateFaxData: any;
    checkFax: boolean;
    checkChangeValue = new Array();
    showPrescNames: any[] = [];
    pdfContent: any;
    updatFaxNoArray: any[] = [];
    dupliacteFaxNos: any[] = [];
    faxFG: FormGroup;
    disabled: boolean;
    glblPrintRef: any;
    printLocalyData: any;
    today: any;
    dateValid: string;
    time: any;
    controlRX: any[] = [];
    printLocaly: any[] = [];
    maxDate: { year: number; month: number; day: number; };
    pushToNames: any[] = [];
    prescNamesFG: FormGroup;
    forkResp: any;
    forkRespOfPreview: any;
    noncontrolRx: any[] = [];
    nonControlRxPreview: any[] = [];
    controlRxPreview: any[] = [];
    refReqPrtFmt: number;
    batchFaxArray: any[] = [];
    RefillRequestFaxInputParams: any;
    fax_subject: string;
    faxPrefix: number;
    batchorpatch: boolean;
    emptyBatch: string;
    afterCompletePdf = false;
    prevIndex = 0;
    followup = 0;
    pushFollowUp: any[] = [];
    faxFollowUp: any;
    both: any[] = [];
    nonarr: any[] = [];
    MultiPreviewarr: any[] = [];
    faxObject: any;
    missingFaxNoArray: any[] = [];
    notReqUpdate: boolean = true;
    hasDataFrAlrFax : boolean = false;
    hasDataCrBtcFax : boolean = false;
    modalRef: NgbModalRef;
    modalRef1: NgbModalRef;
    modalRef2: NgbModalRef;
    modalRef3: NgbModalRef;
    modalRef4: NgbModalRef;
    modalRef5: NgbModalRef;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    refWijmo: wjcGrid.FlexGrid;
    wjHeadersForprepareBatchedRxs: { hName: string; isVisible: boolean; width: number; }[];
    batchedRxssWijmo: wjcGrid.FlexGrid;
    unsubscribe$: Subject<void> = new Subject();
    urlCreator = window.URL;

    faxData = {
        PrescNum: null,
        DrugId: null,
        PrescriberId: null,
        DrugClass: null,
        Faxnumber: null,
        PrescriberName: null,
        IsControlRx: null
    };
    selectedRxFollowup: any;
    controlRxsPrint: any[] = [];

    constructor(private _modalService: NgbModal,
        private _printser: PrintService,
        private _alertSer: AlertService,
        private _commnService: CommonService,
        private _fb: FormBuilder, private _sanitizer: DomSanitizer,
        private _userService: UserService,
        private _wijimoUtils: WijimoUtil,
        private _commonUtils : CommonUtil) {
        this.prescNamesFA = new FormArray([]);
        this.prescNamesFG = this._fb.group({
            prescNames: this.prescNamesFA
        });
    }

    ngOnInit() {
        this.generateCurrentRxsWijmo();
        this.refReqPrtFmt = this._commnService.getSetttingValue("FaxAndReportsSettings", "Refill_Request_Fax_Print_Format");
        this.faxPrefix = this._commnService.getSetttingValue("FaxAndReportsSettings", "Fax_Prefix");
        this.today = moment().format("MM/DD/YYYY");
        this.time = moment().format("hh:mm:ss a");
        this.today = moment().format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.prescNamesFG = this._fb.group({
            prescNames: this.prescNamesFA
        });
        this.modalRef = this._modalService.open(this.PrintFax, {
            size: "lg",
            windowClass: "md-x-lg_77",
            keyboard: false,
            centered: true,
            backdrop:'static'
        });
        this.createFG();
        let faxNumberSetting = this._commnService.getSetttingValue("FaxAndReportsSettings", "Fax_Number");
        this.faxFG.patchValue({
            FaxSubject: this.isFromTrans ? this._commnService.getSetttingValue("FaxAndReportsSettings", "Fax_Subject") : "Refill Request",
            FromName: this.isFromTrans ? this.selectedRx["Patient"]["fullname"] : (this.printRefWJ && this.printRefWJ["items"] &&  this.printRefWJ["items"][0] && this.printRefWJ["items"][0]["PatientName"]) ? this.printRefWJ["items"][0]["PatientName"] : "",
            ToName: this.isFromTrans ? this.selectedRx["Prescriber"]["prescriberlastname"] + "_" + this.selectedRx["Prescriber"]["prescriberfirstname"] : (this.printRefWJ && this.printRefWJ["items"] &&  this.printRefWJ["items"][0] && this.printRefWJ["items"][0]["PrescriberName"]) ? this.printRefWJ["items"][0]["PrescriberName"] : "",
            FromFaxNumber: faxNumberSetting,
            ToFaxNumber: this.isFromTrans ?
                (this.selectedRx["Prescriber"]["faxnumber"] ? this.selectedRx["Prescriber"]["faxnumber"] : null) :
                (this.printRefWJ && this.printRefWJ["items"] &&  this.printRefWJ["items"][0] && this.printRefWJ["items"][0]["PrescPhone"]) ? this.printRefWJ["items"][0]["PrescPhone"] : "",
            FaxId: this.isFromTrans ? null : (this.printRefWJ && this.printRefWJ["items"] &&  this.printRefWJ["items"][0] && this.printRefWJ["items"][0]["PrescriberId"]) ? this.printRefWJ["items"][0]["PrescriberId"] : ""
        });
        this.disableFaxAndBatch = (!(this.faxFG.controls["ToFaxNumber"].value && this.faxFG.controls["ToFaxNumber"].value.length >= 10)) ? true : false;
        const payload={
            flag: 0,
            isFromRefReq : true,
            patientids : this.selectedRx.map(rx => rx.Patient.patientid) 
        }
        this._userService.getBatchedRxs(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.length) {
                this.fax = resp;
                this.prepareBatchedRxs(resp);
                this.hasDataFrAlrFax = true;
            } else{
                this.hasDataFrAlrFax = false
            }
        });
        this.faxFG.controls["Date"].patchValue(this.today);
        this.faxFG.controls["Time"].patchValue(this.time);
        this.faxFG.controls["prescOrFac"].patchValue(0);
        this.glblPrintRef = this.isFromTrans ? null : this.printRef;
        this.disabled = this.isFromTrans ? false : this.printRefWJ && this.printRefWJ["items"] && this.printRefWJ["items"][0] && this.printRefWJ["items"][0]["PrescPhone"] && this.printRefWJ["items"][0]["PrescPhone"].length > 11 ? true : false;
        if (this.isFromTrans && (!this.faxFG.controls["ToFaxNumber"].value || this.faxFG.controls["ToFaxNumber"].value.length < 10)) {
            this.disableFaxAndBatch = true;
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "faxRefReqWJ" || w["WijmoKey"] === "faxbatchedRxsRefReqWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        this.initialPreview()
    }

    initialPreview(index?) {
        this.MultiPreviewarr = []
        this.nonControlRxPreview = []
        this.forkArrayItems = []
        this.controlRxPreview = []
        const diffnoncontolRx = [];
        const uniqueRxs = [];
        this.printRefWJ["items"].forEach((curr) => {
            if(!(uniqueRxs.some((elm) => +elm.RxNo === +curr.RxNo))){
                const fprint = {
                    DrugId: curr.DrugId,
                    FromName: curr.PatientName,
                    ToName: curr.PrescriberName,
                    Subject: curr.FaxSubject,
                    Notes: curr.Notes,
                    IsControlRx: curr.IsControlRx,
                    PrescriberId: curr.PrescriberId,
                    RefillNo: curr["Ref#"],
                    RxNo: +curr.RxNo,
                    PrescRefillId: curr.PrescRefillId
                };
                uniqueRxs.push(fprint);
            }
        });
    if(uniqueRxs.length > 0){
        uniqueRxs.map((val) => {
            if (val.IsControlRx) {
                this.controlRxPreview.push(val);
            } else {
                this.nonControlRxPreview.push(val);
                this.faxObject = {
                    patname: val.FromName,
                    prescname: val.ToName,
                    IsControlRx: val.IsControlRx
                };
                diffnoncontolRx.push(JSON.stringify(this.faxObject));
            }
        });
        const arr = Array.from(new Set(diffnoncontolRx));
        arr.forEach((curr) => {
            this.MultiPreviewarr.push(JSON.parse(curr));
        });
        this.initialPreview2(index);
    }
    }

    async initialPreview2(index?) {
        let previewlocaly = []
        let previewLocalyData = {}
        this.MultiPreviewarr.map((val) => {
            this.nonControlRxPreview.forEach((curr) => {
                    if (val.patname === curr.FromName) {
                        if (val.prescname === curr.ToName) {
                            previewLocalyData = {
                            PrescNum: curr.RxNo,
                            DrugId: curr.DrugId,
                            PrescriberId: curr.PrescriberId,
                            DrugClass: null,
                            Notes: curr.Notes,
                            RefillNum: curr["Ref#"],
                            PatientName: curr.FromName,
                            Subject: curr.Subject,
                            PrescriberName: curr.ToName,
                            IsControlRx: curr.IsControlRx
                        };
                        previewlocaly.push(previewLocalyData);
                    }
                    }
            });
            const RefillRequestFaxInputParams = {
                Faxes: previewlocaly,
                IncludeCoverPage: this.faxFG.value.IncludeCoverPage,
                FaxFormat: this.faxFG.value.MultiSingleRxPg === "1" ? 2 : 1,
                IsBatchFax: true,
                PatientId: this.selectedRx[0] && this.selectedRx[0].Patient && this.selectedRx[0].Patient.patientid
            };
            this.forkArrayItems.push(this._userService.printLocally(RefillRequestFaxInputParams));

            previewlocaly = [];
        });
        
        if (this.controlRxPreview && this.controlRxPreview.length > 0) {
            this.controlRxPreview.map(async val => {
                if (val.IsControlRx) {
                    previewLocalyData = {
                        PrescNum: val.RxNo,
                        DrugId: val.DrugId,
                        PrescriberId: val.PrescriberId,
                        DrugClass: null,
                        Notes: val.Notes,
                        RefillNum: val.RefillNo,
                        PatientName: val.FromName,
                        Subject: val.Subject,
                        PrescriberName: val.ToName,
                        IsControlRx: val.IsControlRx
                    };
                    previewlocaly.push(previewLocalyData);
                }
                const RefillRequestFaxInputParams = {
                    Faxes: previewlocaly,
                    IncludeCoverPage: this.faxFG.value.IncludeCoverPage,
                    FaxFormat: this.faxFG.value.MultiSingleRxPg === "1" ? 2 : 1,
                    IsBatchFax: true,
                    PatientId: this.selectedRx[0].Patient.patientid
                };
                this.forkArrayItems.push(this._userService.printLocally(RefillRequestFaxInputParams));
                previewlocaly = [];
            });
            
        } 
        this.forkRespOfPreview = await forkJoin(this.forkArrayItems).toPromise();
        if (this.forkRespOfPreview) {
            this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                this.urlCreator.createObjectURL(index ? this.forkRespOfPreview[index] : this.forkRespOfPreview[0]) + "#toolbar=0&zoom=70"
            );
            this.showPreview = true;
        }
    }

    ngAfterViewInit() {
    }

    prepareBatchedRxs(batchedRxs) {
        this.prepareBatchedRxss = new CollectionView(
            batchedRxs.map((patRx, i) => {
                const k = {};
                k["ID"] = patRx.Id;
                k["FROM NAME"] = patRx.FromName ? patRx.FromName : "";
                k["From Name"] = patRx.FromName ? patRx.FromName : "";
                k["TO NAME"] = patRx.ToName ? patRx.ToName : "";
                k["To Name"] = patRx.ToName ? patRx.ToName : "";
                k["TO FAX NO."] = patRx.ToFaxNo ? patRx.ToFaxNo : "";
                k["To Fax No."] =  k["To Fax#"] = (patRx.ToFaxNo && patRx.ToFaxNo !== "###") ? this._commonUtils.getPhoneFormatForWrongFax(patRx.ToFaxNo) : "";
                k["RX#"] = patRx.RxNo ? patRx.RxNo.toString() : "";
                k["Rx#"] = patRx.RxNo ? patRx.RxNo.toString() : "";
                k["REF#"] = ((patRx.RefillNo) || (patRx.RefillNo === 0)) ? patRx.RefillNo : "";
                k["Ref#"] = ((patRx.RefillNo) || (patRx.RefillNo === 0)) ? patRx.RefillNo : "";
                k["FAX DATE"] = (patRx.FaxDateTime) ? moment.utc(patRx.FaxDateTime).local().format("MM/DD/YYYY") : "";
                k["Fax Date"] = (patRx.FaxDateTime) ? moment.utc(patRx.FaxDateTime).local().format("MM/DD/YYYY") : "";
                k["TIME INFO"] = (patRx.FaxDateTime)  ? moment.utc(patRx.FaxDateTime).local().format("hh:mm:ss a") : "";
                k["Time Info"] = (patRx.FaxDateTime)  ? moment.utc(patRx.FaxDateTime).local().format("hh:mm:ss a") : "";
                return k;
            })
        );
    }


    createFG() {
        this.faxFG = this._fb.group({
            FaxSubject: [""],
            FromName: [""],
            ToName: [""],
            FromFaxNumber: [""],
            ToFaxNumber: [""],
            Notes: [""],
            Date: [""],
            IncludeCoverPage: [false],
            MultiSingleRxPg: [this.refReqPrtFmt],
            Time: [""],
            FaxId: [],
            prescOrFac: [0],
        });
    }

    fromNamee(e) {
        if (!this.isFromTrans) {
            if (this.ht && this.glblPrintRef && this.glblPrintRef.itemsSource
                && this.glblPrintRef.itemsSource.items[this.ht.row]) {
                let array = this.glblPrintRef.itemsSource.items.filter(f => f["PatientName"] == this.glblPrintRef.itemsSource.items[this.ht.row]["PatientName"]);
                array.map((priSet, i) => {
                    priSet["PatientName"] = e.target.value;
                    priSet["FROM NAME"] = e.target.value;
                });
                this.glblPrintRef.refresh();
            } else {
                let array = this.glblPrintRef.itemsSource.items.filter(f => f["PatientName"] == this.glblPrintRef.itemsSource.items[0]["PatientName"]);
                array.map((priSet, i) => {
                    priSet["PatientName"] = e.target.value;
                    priSet["FROM NAME"] = e.target.value;
                });
                this.glblPrintRef.refresh();
            }
        }
    }

    toName(e) {
        if (!this.isFromTrans) {
            if (this.ht && this.glblPrintRef && this.glblPrintRef.itemsSource
                && this.glblPrintRef.itemsSource.items[this.ht.row]) {
                let array = this.glblPrintRef.itemsSource.items.filter(f => f["PrescriberName"] == this.glblPrintRef.itemsSource.items[this.ht.row]["PrescriberName"]);
                array.map((priSet, i) => {
                    priSet["PrescriberName"] = e.target.value;
                    priSet["TO NAME"] = e.target.value;
                });
                this.glblPrintRef.refresh();
            } else {
                let array = this.glblPrintRef.itemsSource.items.filter(f => f["PrescriberName"] == this.glblPrintRef.itemsSource.items[0]["PrescriberName"]);
                array.map((priSet, i) => {
                    priSet["PrescriberName"] = e.target.value;
                    priSet["TO NAME"] = e.target.value;
                });
                this.glblPrintRef.refresh();
            }
        }
    }

    faxSubject(e) {
        if (!this.isFromTrans) {
            if (this.ht && this.glblPrintRef && this.glblPrintRef.itemsSource
                && this.glblPrintRef.itemsSource.items[this.ht.row]) {
                this.glblPrintRef.itemsSource.items[this.ht.row]["FaxSubject"] = e.target.value;
                this.glblPrintRef.refresh();
            } else {
                if (this.glblPrintRef && this.glblPrintRef.itemsSource && this.glblPrintRef.itemsSource.items[0]) {
                this.glblPrintRef.itemsSource.items[0]["FaxSubject"] = e.target.value;
                this.glblPrintRef.refresh();
                }
            }
        }
    }

    notesChange(e) {
        if (!this.isFromTrans) {
            if (this.ht && this.glblPrintRef && this.glblPrintRef.itemsSource
                && this.glblPrintRef.itemsSource.items[this.ht.row]) {
                this.glblPrintRef.itemsSource.items[this.ht.row]["Notes"] = e.target.value;
                this.glblPrintRef.refresh();
            } else {
                this.glblPrintRef.itemsSource.items[0]["Notes"] = e.target.value;
                this.glblPrintRef.refresh();
            }
        }
    }

    tofaxNumber(e) {
        if (this.faxFG.controls["ToFaxNumber"].value && this.faxFG.controls["ToFaxNumber"].value.length >= 10) {
            this.disableFaxAndBatch = false;
            if (!this.isFromTrans) {
                this.pushFaxNo[this.prevIndex] = this.faxFG.value.ToFaxNumber;
                for (let i = 0; i < this.printRefWJ["items"].length; i++) {
                    if (this.glblPrintRef.itemsSource.items[i]["PrescriberId"] === this.faxFG.value.FaxId) {
                        this.pushFaxNo[i] = this.pushFaxNo[this.prevIndex];
                        this.glblPrintRef.itemsSource.items[i]["PrescPhone"] = this.pushFaxNo[i];
                        this.glblPrintRef.itemsSource.items[i]["TO FAX NO."] = this.pushFaxNo[i];
                    }
                }
                this.glblPrintRef.refresh();
            }
        } else {
            this.disableFaxAndBatch = true;
        }
    }

    toFaxNumberPasteEvent(e: ClipboardEvent) {
        this.faxFG.patchValue({ ToFaxNumber: e.clipboardData.getData('text') });
        this.tofaxNumber(e)
    }

    fromfaxNumber(e) {
        if (!this.isFromTrans) {
            if (this.ht && this.glblPrintRef && this.glblPrintRef.itemsSource
                && this.glblPrintRef.itemsSource.items[this.ht.row]) {
                this.glblPrintRef.itemsSource.items[this.ht.row]["FromFaxNumber"] = e.target.value;
                this.glblPrintRef.refresh();
            } else {
                this.glblPrintRef.itemsSource.items[0]["FromFaxNumber"] = e.target.value;
                this.glblPrintRef.refresh();
            }
        }
    }

    init(e) {
        this.glblPrintRef = e;
    }


    onlyOneValue(printRef, item) {
        this.glblPrintRef = printRef;
        // tslint:disable-next-line: prefer-const
        let i;
        this.ht = printRef.hitTest(item);
        this.disabled = (printRef && printRef.itemsSource && printRef.itemsSource.items[this.ht.row] &&
             printRef.itemsSource.items[this.ht.row]["PrescPhone"])
         ? (printRef.itemsSource.items[this.ht.row]["PrescPhone"].length > 11 ? true : false ) : false;
        this.prevIndex = this.ht.row;
        this.faxFG.patchValue({
            FromName: printRef.itemsSource.items[this.ht.row]["PatientName"],
            ToName: printRef.itemsSource.items[this.ht.row]["PrescriberName"],
            ToFaxNumber: printRef.itemsSource.items[this.ht.row]["PrescPhone"],
            Notes: printRef.itemsSource.items[this.ht.row]["Notes"],
            FromFaxNumber: printRef.itemsSource.items[this.ht.row]["FromFaxNumber"],
            FaxSubject: printRef.itemsSource.items[this.ht.row]["FaxSubject"],
            FaxId: printRef.itemsSource.items[this.ht.row]["PrescriberId"]
        });
        this.disableFaxAndBatch = !printRef.itemsSource.items[this.ht.row]["PrescPhone"] ? true : false;
        this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
            this.urlCreator.createObjectURL(this.forkRespOfPreview[this.ht.row]) + "#toolbar=0&zoom=70"
        );
    }

    batchFax(borp) {
        this.faxFG.controls["Time"].patchValue(this.time);
        if (this.faxFG.controls["Date"].valid) {
            this.faxFG.controls["Date"].patchValue(this.faxFG.controls["Date"].value);
        } else {
            this.faxFG.controls["Date"].patchValue(this.today);
        }
        this.batchorpatch = borp;
        this.checkForMissingRx = false;
        let i;
        if (!this.isFromTrans) {
            for (i = 0; i < this.selectedRx.length; i++) {
                if (this.printRefWJ && this.printRefWJ["items"] && this.printRefWJ["items"][i] && !this.printRefWJ["items"][i]["PrescPhone"]) {
                    this.checkForMissingRx = true;
                    this.prescriberNames = this.printRefWJ["items"][i]["PrescriberName"];
                    this.missingFaxNoArray.push(this.prescriberNames);
                }
                const faxData = {
                    PrescNum: this.selectedRx && this.selectedRx[i] && this.selectedRx[i].Prescription &&
                     this.selectedRx[i].Prescription.PrescNum ? this.selectedRx[i].Prescription.PrescNum : null,
                    DrugId: this.selectedRx[i]&&this.selectedRx[i].Prescription&&this.selectedRx[i].Prescription.DrugId,
                    PrescriberId: this.printRefWJ["items"]&&this.printRefWJ["items"][i]&&this.printRefWJ["items"][i]["PrescriberId"],
                    DrugClass: this.selectedRx[i]&&this.selectedRx[i].Drug&&this.selectedRx[i].Drug.drugclass,
                    Faxnumber: this.printRefWJ && this.printRefWJ["items"]&&this.printRefWJ["items"][i]&&this.printRefWJ["items"][i]["PrescPhone"],
                    Notes: this.notes[i],
                    RefillNum: this.selectedRx[i]&&this.selectedRx[i].PrescReFill&&this.selectedRx[i].PrescReFill.ReFillNum,
                    PartialFillNo: this.selectedRx[i]&&this.selectedRx[i]["PrescReFill"]&&this.selectedRx[i]["PrescReFill"]["PartialFillNo"],
                    Subject: this.printRefWJ["items"]&&this.printRefWJ["items"][i]&&this.printRefWJ["items"][i]["FaxSubject"],
                    PatientName: this.printRefWJ["items"]&&this.printRefWJ["items"][i]&&this.printRefWJ["items"][i]["PatientName"],
                    PatientId:this.selectedRx[i]&&this.selectedRx[i].Patient&&this.selectedRx[i].Patient.patientid,
                    PrescriberName: this.printRefWJ["items"]&&this.printRefWJ["items"][i]&&this.printRefWJ["items"][i]["PrescriberName"],
                    IsControlRx: this.selectedRx[i]&&this.selectedRx[i].Drug&&this.selectedRx[i].Drug.drugclass >= 2 && this.selectedRx[i]&&this.selectedRx[i].Drug&&this.selectedRx[i].Drug.drugclass <= 5 ? true : false
                };
                this.batchFaxArray.push(faxData);
            }
            const getFaxNos = new Array();
            // tslint:disable-next-line:no-shadowed-variable
            this.batchFaxArray.map((curr,  i) => {
                    this.updateFaxData = {
                        prescriberid: curr["PrescriberId"],
                        faxnumber: this.printRefWJ && this.printRefWJ["items"] && this.printRefWJ["items"][i] && this.printRefWJ["items"][i]["PrescPhone"] ? this.printRefWJ["items"][i]["PrescPhone"]  : ""
                    };
                    this.updateFaxArr.push(JSON.stringify(this.updateFaxData));
            });
            const arr = Array.from(new Set(this.updateFaxArr));
            this.updateFaxArr = [];
            arr.forEach((curr, i) => {
                this.updateFaxArr.push(JSON.parse(curr));
            });
            this.checkMissing(this.batchorpatch);
        } else {
            this.checkForMissingRx = false;
            this.updateFaxData = {
                prescriberid: this.selectedRx["Prescriber"]["prescriberid"],
                faxnumber: this.faxFG.controls.ToFaxNumber.value
            };
            this.updateFaxArr.push(this.updateFaxData);
            const faxData = {
                PrescNum: this.selectedRx &&  this.selectedRx["Prescription"] && this.selectedRx["Prescription"]["PrescNum"] ?
                this.selectedRx["Prescription"]["PrescNum"] : null,
                DrugId: this.selectedRx["Drug"]["id"],
                PrescriberId: this.selectedRx["Prescriber"]["prescriberid"],
                DrugClass: this.selectedRx["Drug"]["drugclass"],
                Faxnumber: this.faxFG.controls.ToFaxNumber.value,
                Notes: this.faxFG.controls.Notes.value,
                RefillNum: this.selectedRx["PrescReFill"]["ReFillNum"],
                PartialFillNo: this.selectedRx["PrescReFill"]["PartialFillNo"],
                Subject: this.faxFG.controls.FaxSubject.value,
                PatientName: this.faxFG.controls.FromName.value,
                PatientId: this.selectedRx["Patient"]["patientid"],
                PrescriberName: this.faxFG.controls.ToName.value,
                IsControlRx: this.selectedRx["Drug"]["drugclass"] >= 2 && this.selectedRx["Drug"]["drugclass"] <= 5 ? true : false
            };
            this.batchFaxArray.push(faxData);
            this.checkMissing(this.batchorpatch);
        }
    }

    checkMissing(borp) {
        if (this.checkForMissingRx) {
            this.showPrescNames = Array.from(new Set(this.missingFaxNoArray));
            this.modalRef1 = this._modalService.open(this.MissingPop, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"
            });
        } else {
            const data = {
                prescRefillId: this.transmResp ? this.transmResp.transmissionDetails.PrescRefillId : null,
                IsCMSFormIncluded: this.isCmsChecked,
                message: this.transmResp ? this.transmResp.transmissionDetails.RejectInfo : "",
                lstDUR: this.transmResp && this.transmResp.ClaimProcess ? this.transmResp.ClaimProcess.lstRxDur : null
            };
            const RefillRequestFaxInputParams = {
                Faxes: this.batchFaxArray,
                IncludeCoverPage: this.faxFG.value.IncludeCoverPage,
                FaxFormat: this.faxFG.value.MultiSingleRxPg === "1" ? 2 : 1,
                IsBatchFax: borp,
                FromFaxNumber: "18447783045",
                // remove this
                // PatientId: this.isFromTrans ? this.selectedRx["Patient"]["patientid"] : this.selectedRx[0].Patient.patientid,
                FaxPrefix: this.faxPrefix,
                IsTransmissionRejectedFax: this.isFromTrans,
                rejectedTransmissionReportParams: data
            };
            if (this.checkFaxNumberChanged()) {
                this._userService.sendSfaxRequest(RefillRequestFaxInputParams)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.closeModal();
                    this._alertSer.success("Fax sent Successful.");
                });
            } else {
                this._userService.updateFaxNoToPresc(this.updateFaxArr)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._userService.sendSfaxRequest(RefillRequestFaxInputParams)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(() => {
                            this.closeModal();
                            this._alertSer.success("Fax sent Successful.");
                        });
                    } else {
                        this._alertSer.error("Fax sent Unsuccessful.");
                    }
                });
            }
        }
    }

    checkFaxNumberChanged() {
        if (!this.isFromTrans) {
            this.updateFaxArr.map((val, i) => {
                if(this.notReqUpdate === true) {
                this.selectedRx.map((curr, i) => {
                    if (val.prescriberid === curr["Prescriber"]["prescriberid"]) {
                        if (val.faxnumber === curr["Prescriber"]["faxnumber"]) {
                            this.notReqUpdate = true;
                        } else {
                            this.notReqUpdate = false;
                        }
                    }
                });
            }
            });
            return this.notReqUpdate;
        } else if (this.isFromTrans) {
            if (this.updateFaxArr[0].faxnumber === this.selectedRx["Prescriber"]["faxnumber"]) {
                return true;
            } else {
                return false;
            }
        }
    }

    checkFormissingRxs() {
        this.showPrescNames = Array.from(new Set(this.missingFaxNoArray));
        for (let i = 0; i < this.showPrescNames.length; i++) {
            this.prescNamesFA.push(new FormControl());
        }
        if (this.modalRef1) {
            this.modalRef1.close();
        }
    }

    updateFaxNoToPrescriber() {
        if (this.prescNamesFG.valid) {
            this.checkFax = false;
            const prescIds = new Array(this.showPrescNames.length);
            for (let i = 0; i < this.batchFaxArray.length; i++) {
                if (!this.batchFaxArray[i].Faxnumber) {
                    const name = this.batchFaxArray[i].PrescriberName;
                    const index = this.showPrescNames.indexOf(name);
                    prescIds[index] = this.batchFaxArray[i].PrescriberId;
                    this.batchFaxArray[i].Faxnumber = this.prescNamesFA.controls[index].value;
                }
            }
            for (let i = 0; i < this.showPrescNames.length; i++) {
                const updateFaxNoData = {
                    prescriberid: prescIds[i],
                    faxnumber: this.prescNamesFA.controls[i].value
                };
                this.updatFaxNoArray.push(updateFaxNoData);
            }
            // tslint:disable-next-line: no-shadowed-variable
            for (let i = 0; i < this.updateFaxArr.length; i++) {
                if (this.updateFaxArr[i].faxnumber) {
                    this.updatFaxNoArray.push(this.updateFaxArr[i]);
                }
            }
            this._userService.updateFaxNoToPresc(this.updatFaxNoArray)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    const RefillRequestFaxInputParams = {
                        Faxes: this.batchFaxArray,
                        IncludeCoverPage: this.faxFG.value.IncludeCoverPage,
                        FaxFormat: this.faxFG.value.MultiSingleRxPg === "1" ? 2 : 1,
                        IsBatchFax: this.batchorpatch,
                        FromFaxNumber: "18447783045",
                        PatientId: this.selectedRx[0].Patient.patientid,
                        FaxPrefix: this.faxPrefix
                    };
                    this._userService.sendSfaxRequest(RefillRequestFaxInputParams)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this._alertSer.success("Fax sent successfully.");
                        this.closeModalforfax();
                        this.closeModalforMissing();
                        this.closeModal();
                    });
                }
            });
        } else {
            this.checkFax = true;
            this._alertSer.error("Please fill all the details.");
        }
    }


    printLocally() {
        this.modalRef3 = this._modalService.open(this.Batchque, {
            centered: true,
            keyboard: false,
            windowClass: "large--content"
        });
    }

    batchQue(bool) {
        this.emptyBatch = bool;
        if (this.modalRef3) {
            this.modalRef3.close();
        }
        const diffnoncontolRx = [];
        const printLocalRxs = [];
        /* if (this.fax.length === 0) {
            this.printRefWJ["items"].forEach((curr) => {
                const fprint = {
                    DrugId: curr.DrugId,
                    FromName: curr.PatientName,
                    ToName: curr.PrescriberName,
                    Subject: curr.FaxSubject,
                    Notes: curr.Notes,
                    IsControlRx: curr.IsControlRx,
                    PrescriberId: curr.PrescriberId,
                    RefillNo: curr["Ref#"],
                    RxNo: curr.RxNo,
                    PrescRefillId: curr.PrescRefillId
                };
                this.fax.push(fprint);
            });
         } else {*/
            this.printRefWJ["items"].forEach((curr) => {
                if(!(printLocalRxs.some((elm) => +elm.RxNo === +curr.RxNo))){
                    const fprint = {
                        DrugId: curr.DrugId,
                        FromName: curr.PatientName,
                        ToName: curr.PrescriberName,
                        Subject: curr.FaxSubject,
                        Notes: curr.Notes,
                        IsControlRx: curr.IsControlRx,
                        PrescriberId: curr.PrescriberId,
                        RefillNo: curr["Ref#"],
                        RxNo: +curr.RxNo,
                        PrescRefillId: curr.PrescRefillId
                    };
                    printLocalRxs.push(fprint);
                    // this.printSfaxFormat(fprint);
                }
            });
        // }
        if(printLocalRxs.length >0){
            printLocalRxs.map((val, i) => {
                if (val.IsControlRx) {
                    this.controlRX.push(val);
                } else {
                    this.noncontrolRx.push(val);
                    this.faxObject = {
                        patname: val.FromName,
                        prescname: val.ToName,
                        IsControlRx: val.IsControlRx
                    };
                    diffnoncontolRx.push(JSON.stringify(this.faxObject));
                }
            });
            const arr = Array.from(new Set(diffnoncontolRx));
            arr.forEach((curr, i) => {
                this.nonarr.push(JSON.parse(curr));
            });
            this.nonCntrlRx(true);
        }
    }

    async nonCntrlRx(isForYesOrNo) {
        this.nonarr.map((val) => {
            this.noncontrolRx.forEach((curr, i) => {
                if (!curr.IsControlRx) {
                    if (val.patname === curr.FromName) {
                        if (val.prescname === curr.ToName) {
                        this.printLocalyData = {
                            PrescNum: curr.RxNo,
                            DrugId: curr.DrugId,
                            PrescriberId: curr.PrescriberId,
                            DrugClass: null,
                            Notes: curr.Notes,
                            RefillNum: curr["Ref#"],
                            PatientName: curr.FromName,
                            Subject: curr.Subject,
                            PrescriberName: curr.ToName,
                            IsControlRx: curr.IsControlRx
                        };
                        this.printLocaly.push(this.printLocalyData);
                    }
                    }
                }
            });
            this.RefillRequestFaxInputParams = {
                Faxes: this.printLocaly,
                IncludeCoverPage: this.faxFG.value.IncludeCoverPage,
                FaxFormat: this.faxFG.value.MultiSingleRxPg === "1" ? 2 : 1,
                IsBatchFax: true,
                PatientId: this.selectedRx[0] && this.selectedRx[0].Patient && this.selectedRx[0].Patient.patientid
            };
            this.forkArray.push(this._userService.printLocally(this.RefillRequestFaxInputParams));
            this.printLocaly = [];
        });
        this.forkResp = await forkJoin(this.forkArray).toPromise();
        this.downLoad(isForYesOrNo);
    }

    downLoad(isForYesOrNo) {
        if (this.nonarr.length > 0) {
            const rxArr = [];
            this.noncontrolRx.map((elm) => {
                if (elm.FromName === this.nonarr[0].patname && elm.ToName === this.nonarr[0].prescname) {
                    rxArr.push(elm.RxNo);
                }
            });
            this.selectedRxFollowup  = rxArr.toString();
            this.printSfaxFormat(this.forkResp[0]);
            this.modalRef4 = this._modalService.open(this.Batchque1, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"
            });
        } else {
            if (this.controlRX.length > 0) {
                this.controlRx(isForYesOrNo);
            } else {
                this.faxFollowpRxs(isForYesOrNo);
            }
        }
    }

    yes() {
        if (this.nonarr && this.nonarr.length) {
            this.noncontrolRx.forEach((curr, i) => {
                if (!curr.IsControlRx) {
                    if (this.nonarr[0].patname === curr.FromName) {
                        if (this.nonarr[0].prescname === curr.ToName) {
                            this.faxFollowUp = {
                                RefillNo: curr.RefillNo,
                                RxNo: curr.RxNo,
                                PrescRefillId: curr.PrescRefillId
                            };
                            this.pushFollowUp.push(this.faxFollowUp);
                        }
                    }
                }
            });
        }
        this.nonarr.splice(0, 1);
        this.forkResp.splice(0, 1);
        if (this.modalRef4) {
            this.modalRef4.close();
        }
        setTimeout(() => {
            this.downLoad(true);
        }, 3000);
    }

    no() {
        this.nonarr.splice(0, 1);
        this.forkResp.splice(0, 1);
        if (this.modalRef4) {
            this.modalRef4.close();
        }
        setTimeout(() => {
            this.downLoad(true);
        }, 3000);
    }

    printSfaxFormat(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printser.printPdf(base64data);

            // this.closeModal();
        }.bind(this);

    }

    async controlRx(isForYesOrNo) {
        this.forkArray = [];
        if (this.controlRX && this.controlRX.length > 0) {
            this.controlRX.map(async val => {
                if (val.IsControlRx) {
                    this.printLocalyData = {
                        PrescNum: val.RxNo,
                        DrugId: val.DrugId,
                        PrescriberId: val.PrescriberId,
                        DrugClass: null,
                        Notes: val.Notes,
                        RefillNum: val.RefillNo,
                        PatientName: val.FromName,
                        Subject: val.Subject,
                        PrescriberName: val.ToName,
                        IsControlRx: val.IsControlRx
                    };
                    this.printLocaly.push(this.printLocalyData);
                }
                this.RefillRequestFaxInputParams = {
                    Faxes: this.printLocaly,
                    IncludeCoverPage: this.faxFG.value.IncludeCoverPage,
                    FaxFormat: this.faxFG.value.MultiSingleRxPg === "1" ? 2 : 1,
                    IsBatchFax: true,
                    PatientId: this.selectedRx[0].Patient.patientid
                };
                this.controlRxsPrint.push({RxNo: val.RxNo});
                this.forkArray.push(this._userService.printLocally(this.RefillRequestFaxInputParams));
                this.printLocaly = [];
            });
            this.forkResp = await forkJoin(this.forkArray).toPromise();
            if (this.forkResp && this.controlRX.length === this.forkResp.length) { this.downloadControlRx(isForYesOrNo); }
        } else {
            this.faxFollowpRxs(isForYesOrNo);
        }


    }

    downloadControlRx(isForYesOrNo) {
        if (this.controlRxsPrint.length > 0) {
            this.selectedRxFollowup = this.controlRxsPrint[0].RxNo;
            this.printSfaxFormat(this.forkResp[0]);
            this.modalRef5 = this._modalService.open(this.Batchque2, {
                centered: true,
                keyboard: false,
                windowClass: "large--content"
            });
        } else {
            this.faxFollowpRxs(isForYesOrNo);
        }
    }

    yesControlRx() {
        if (this.controlRxsPrint) {
            this.controlRX.map(val => {
                if (val.RxNo === this.controlRxsPrint[0].RxNo) {
                    this.faxFollowUp = {
                        RefillNo: val.RefillNo,
                        RxNo: val.RxNo,
                        PrescRefillId: val.PrescRefillId
                    };
                    this.pushFollowUp.push(this.faxFollowUp);
                }
            });
        }
        this.controlRxsPrint.splice(0, 1);
        this.controlRX.splice(0,1);
        this.noncontrolRx.splice(0,1);
        this.forkResp.splice(0, 1);
        if (this.modalRef5) {
            this.modalRef5.close();
        }
        setTimeout(() => {
            this.downloadControlRx(true);
        }, 3000);
    }

    noControlRx() {
        this.controlRxsPrint.splice(0, 1);
        this.forkResp.splice(0, 1);
        this.controlRX.splice(0,1);
        this.noncontrolRx.splice(0,1);
        if (this.modalRef5) {
            this.modalRef5.close();
        }
        setTimeout(() => {
            this.downloadControlRx(true);
        }, 3000);
    }

    faxFollowpRxs(isForYesOrNo) {
        if (this.emptyBatch === "yes") {
            this._userService.emptyBatch()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (this.pushFollowUp.length > 0) {
                        this._userService.followUpLog(this.pushFollowUp)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(() => {
                          if(!isForYesOrNo){
                            this.closeModal();
                          }
                        });
                    } else{
                        if(!isForYesOrNo){
                            this.closeModal();
                          }
                      }
                }
            });
        } else if (this.emptyBatch === "no") {
            if (this.pushFollowUp.length > 0) {
                this._userService.followUpLog(this.pushFollowUp)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    if(!isForYesOrNo){
                        this.closeModal();
                      }
                });
            } else {
                if(!isForYesOrNo){
                    this.closeModal();
                  }
            }
        } else{
            if(!isForYesOrNo){
                this.closeModal();
              }
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    isChecked(e) {
        this.faxFG.controls["IncludeCoverPage"].patchValue(e.value);
    }

    rxPage() {
        this.forkRespOfPreview = []
        this.initialPreview(this.ht ? this.ht.row : null)
    }

    updateFaxNo() {
        // this.closeModalforMissing();
        this.checkFormissingRxs();
        this.modalRef2 = this._modalService.open(this.AddFaxNo, {
            centered: true,
            backdrop: false,
            windowClass: "large--content"
        });
    }

     closeModal(type?: string) {
         if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
         }
        if (type === "cancel") {
        this.IsPopUpClosed.emit(false);
        } else {
            this.IsPopUpClosed.emit(true);
        }
    }

    closeModalforMissing() {
        if (this.modalRef1) {
        this.modalRef1.close();
        this.modalRef1 = null;
        }
        this.missingFaxNoArray = [];
        this.batchFaxArray = [];
        this.updateFaxArr = [];
    }

    closeModalforBatch() {
        if (this.modalRef3) {
        this.modalRef3.close();
        this.modalRef3 = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    closeModalforfax() {
        if (this.modalRef2) {
        this.modalRef2.close();
        this.modalRef2 = null;
        }
        let i;
        for (i = 0; i < this.showPrescNames.length; i++) {
            this.prescNamesFA.removeAt(0);
        }
    }

    skip() {
        this.closeModalforfax();
    }

    initBatchedRxss(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRow(0, true);
        this.batchedRxssWijmo = flex;
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("faxRefReqWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("faxRefReqWJ", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("faxbatchedRxsRefReqWJ");
        this.wjHeadersForprepareBatchedRxs = this._wijimoUtils.patchDefHeader("faxbatchedRxsRefReqWJ", storedWJ1);
    }

    generateCurrentRxsWijmo() {
        if(this.printRefWJ && this.printRefWJ["items"] && this.printRefWJ["items"].length){
            this.hasDataCrBtcFax = true;
            this.printRefWJ["items"].map((element)=> {
                element["FROM NAME"] = element["PatientName"];
                element["From Name"] = element["PatientName"];
                element["TO NAME"] = element["PrescriberName"];
                element["To Name"] = element["PrescriberName"];
                element["TO FAX NO."] = element["PrescPhone"];
                element["To Fax No."] = element["To Fax#"] = element["PrescPhone"] && element["PrescPhone"] !== "###" ? this._commonUtils.getPhoneFormat(element["PrescPhone"]) : "";
                element["Rx#"] = element["RxNo"];
                element["RX ORIGIN"] = element["RxOrigin"];
                element["Rx Origin"] = element["RxOrigin"];
                element["PrescRefillId"] = element["PrescRefillId"];
            });
        } else{
            this.hasDataCrBtcFax = false;
        }
    }
}


