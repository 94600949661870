
<app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)"></app-cancel-pop-up>


<div class="exprx--common-block edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Counselling'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 drugcoun commonbut ">
                <button class="hotkey_success" id="SaveButton" (click)="update()"><span>S</span> Save</button>
                <button class="hotkey_success" (click)="CancelPopTaggle()"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Drug Codes
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div [ngClass]="(hasCodes && hasWarning) ? 'col-xl-2' : 'col-xl-12'">
                <div [ngClass]="(hasCodes && hasWarning) ? '' : 'row'">
                        <div [ngClass]="(hasCodes && hasWarning) ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
                        : 'col-lg-2 col-md-2 col-sm-2 col-xs-2'"
                        >
                            <eprx-input [LabelText]="'Therapeutic Class ID'" [AutoFocus]="true" [PlaceHolder]="'Therapeutic Class ID'" [ControlName]="'Therapeutic'" [FormGroupName]="drugCodeFG"
                            [MaskPattern]="'0*'" [RxSelectId]="'Therapeutic'" [MaxLength]="8"  [InputErrors]="Therapeutic?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                        <div [ngClass]="(hasCodes && hasWarning) ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
                        : 'col-lg-2 col-md-2 col-sm-2 col-xs-2'">
                            <eprx-input [LabelText]="'TherapeuticCode'" [PlaceHolder]="'Therapeutic Code'" [ControlName]="'TherapeuticCode'" [FormGroupName]="drugCodeFG"
                            [MaskPattern]="'0*'" [MaxLength]="8" [InputErrors]="TherapeuticCode?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                        <div [ngClass]="(hasCodes && hasWarning) ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
                        : 'col-lg-2 col-md-2 col-sm-2 col-xs-2'">
                            <eprx-input [LabelText]="'Warning Code'" [TabIndex]="-1" [PlaceHolder]="'WarningCode'" [ControlName]="'WarningCode'" [FormGroupName]="drugCodeFG" [ReadOnly]="true">
                            </eprx-input>
                        </div>
                        <div [ngClass]="(hasCodes && hasWarning) ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
                        : 'col-lg-2 col-md-2 col-sm-2 col-xs-2'">
                                <label class=" col-12 padding--left__7">Display Warning Codes?</label>
                                <eprx-radio [ControlName]="'IsWarningCodeChkd'" [FormGroupName]="drugCodeFG" [IsRequired]="true" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['1', '0']" [LabelForId]="" (TriggerSelectValue)="displayCodes('true')">
                                </eprx-radio>
                        </div>
                        <div [ngClass]="(hasCodes && hasWarning) ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
                        : 'col-lg-4 col-md-4 col-sm-4 col-xs-4'" [formGroup]="drugCodeFG">
                        <label class="align-label padding_bottom_1px"> Code Information </label>
                        <textarea  (keydown.tab)="focusOutFmRem($event)"
                        [rows]="2" [cols]="15" class="text-area h-76 w-100" formControlName="Codedescription"></textarea>
                        </div>
                </div>
            </div>
            <div class="col-xl-10" *ngIf="(hasCodes && hasWarning)">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && hasWar">
                        <label>Warning Info</label>
                        <wj-flex-grid #warInfo [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="warningWJ" (updatedView)="init(warInfo)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of warHeader; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData?.Storage">
                        <label>Storage Info</label>
                        <wj-flex-grid #storwj [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="WJ6" (updatedView)="init(storwj)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && hasdite">
                        <label>Dietary Info</label>
                        <wj-flex-grid #diteInfo [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="dietaryWJ" (updatedView)="init(diteInfo)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of diteraryHeader; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && hasDose">
                        <label>Dosage Info</label>
                        <wj-flex-grid #dosageInfo [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="dosingWJ" (updatedView)="init(dosageInfo)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of dosHeader; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData?.Caution">
                        <label>Caution Info</label>
                        <wj-flex-grid #storwj [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="WJ2" (updatedView)="init(storwj)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData?.Generic">
                        <label>Generic Info</label>
                        <wj-flex-grid #storwj [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="WJ3" (updatedView)="init(storwj)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData['Dosing Frequency']">
                        <label>DosingFrequency Info</label>
                        <wj-flex-grid #storwj [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="WJ4" (updatedView)="init(storwj)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData?.Administration">
                        <label>Administration Info</label>
                        <wj-flex-grid #storwj [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="WJ5" (updatedView)="init(storwj)"
                            class="grid" [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData['Route of Administration']">
                        <label>Route of Administration Info</label>
                        <wj-flex-grid #RAwj [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="RAWJ" (updatedView)="init(RAwj)" class="grid"
                            [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData['General Information']">
                        <label>General Information Info</label>
                        <wj-flex-grid #RAwj [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="WJ7" (updatedView)="init(RAwj)" class="grid"
                            [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData.Pricing">
                        <label>Pricing Info</label>
                        <wj-flex-grid #prWJ [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="WJ8" (updatedView)="init(prWJ)" class="grid"
                            [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5" *ngIf="hasCodes && codeInfo?.DrillDownData['Legal information']">
                        <label>Legal information</label>
                        <wj-flex-grid #lein [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="lIWJ" (updatedView)="init(lein)" class="grid"
                            [selectionMode]="'None'" [allowDragging]="'None'">
                            <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of header; let i = index"
                                [width]="(drugactvHeader === 'WARNING') ? 250 : '*'" [wordWrap]="true">
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                </div>

            </div>
            <div class="text-right col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="(hasCodes && hasWarning)">
                <button class="hotkey_success" (click)="updateLan(true)">English</button>
                <button class="hotkey_success" (click)="updateLan(false)" (keydown.tab)="focusToFirst($event)">Spanish</button>
            </div>


        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                Dosage Info
                <ul class="pull-right eprx--header__icons">
                    <li>
                        <img (click)="openLg(drugindication, 'drugIndication')" src="assets/dist/Icons/icon_pills1_white.svg" title="Drug Indic">
                    </li>
                    <li>
                        <img (click)="openLg(drugcouncial, 'drugCounselling')" src="assets/dist/Icons/icon_pills2_white.svg" title="Drug Conns">
                    </li>
                    <li  class="padding_top_0_2rem ">
                        <i class="far fa-print text-white action pull-right font_size_1_2rem" title="Print Med Guide" (click)="printMedGuide()"></i>
                    </li>
                </ul>
            </div>
        </div>
        <div class="eprx--block__content">
            <wj-flex-grid #dosageInfo [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="dosageInfoWJ" (updatedView)="init(dosageInfo)"
                [columnPicker]="''" [selectionMode]="'None'">
                <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of drugactvHeader; let i = index">
                </wj-flex-grid-column>
            </wj-flex-grid>
        </div>
    </div>

    <!-- pop-up Drug Counselling Information-->
    <ng-template #drugcouncial let-c="close" let-d="dismiss" class="el--popup">
        <div>
            <div class="modal-header">
                <h4 class="modal-title">Drug Counselling</h4>
                <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true" class="close-button">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" [formGroup]="drugCounselFG">
                    <div class="col-md-4">
                        <label class="col-md-12 padding-0">Drug</label>
                        <div class="label--data">{{drugName}}</div>
                    </div>
                    <div class="col-md-4">
                        <eprx-radio [LabelText]="'Drug Counselling Format'" [ControlName]="'format'" [FormGroupName]="drugCounselFG" [InputErrors]="format?.errors"
                            [IsRequired]="true" [List]="['Long', 'Short']" [ValueList]="[true, false]" [IDForList]="['Long', 'Short']"
                            [LabelForId]="" (TriggerSelectValue)="getDrugCounsellingData()">
                        </eprx-radio>
                    </div>
                    <div class="col-md-4">
                        <eprx-select [LabelText]="'Language'" [PlaceHolder]="'Name'" [ControlName]="'language'" [FormGroupName]="drugCounselFG" [InputErrors]="language?.errors"
                            [List]="systemData?.Lang" [BindLabel]="'Name'" [BindValue]="'LangCode'" [IsRequired]="true" [LabelForId]="'Name'"
                            [ErrorDefs]="{required: 'Required'}" (TriggerSelectValue)="getDrugCounsellingData()">
                        </eprx-select>
                    </div>
                </div>
                <div class="col-md-12 drug--counsell margin-top">
                    <div class="row">
                        <div class="col-md-8 questions">
                            <div class="row">
                                <div class="col-md-12" *ngFor="let coouncial of drugCounsellingDate?.DrugCounsellingDescription">
                                    <div class="counsell--header">
                                        <h6>{{coouncial.HEADER}}</h6>
                                    </div>
                                    <div class="counsell--body">
                                        <p>
                                            {{coouncial.DESCRIPTION}}
                                        </p>
                                    </div>
                                    <div class="counsell--body">
                                        <p>
                                            {{coouncial.FOOTER}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="drug--discription">
                                <div class="description">
                                    <span class="drug-desc--hdr">
                                        <label>Shape</label>
                                        <span class="drug-desc--txt"> {{drugDescription?.shape}}</span>
                                    </span>
                                    <div>
                                        <span class="drug-desc--hdr">
                                            <label>Color Front</label>
                                            <span class="drug-desc--txt"> {{drugDescription?.color_frnt}}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="drug-desc--hdr">
                                            <label>Color Back</label>
                                            <span class="drug-desc--txt"> {{drugDescription?.color_back}}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="drug-desc--hdr">
                                            <label>Side 1</label>
                                            <span class="drug-desc--txt"> {{drugDescription?.id_front}}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="drug-desc--hdr">
                                            <label>Side 2</label>
                                            <span class="drug-desc--txt"> {{drugDescription?.id_back}}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="drug-desc--hdr">
                                            <label>Flavor</label>
                                            <span class="drug-desc--txt"> {{drugDescription?.flavorname}}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="drug-desc--hdr">
                                            <label>Scoring</label>
                                            <span class="drug-desc--txt"> {{drugDescription?.scoringname}}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="drug-desc--hdr">
                                            <label>Coating</label>
                                            <span class="drug-desc--txt"> {{drugDescription?.coatingname}}</span>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </ng-template>


    <!-- pop-up Drug Counselling  -->

    <!-- pop-up Drug indication Information-->
    <ng-template #drugindication let-c="close" let-d="dismiss" class="el--popup">
        <div>
            <div class="modal-header">
                <h4 class="modal-title">Drug Indication</h4>
                <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true" class="close-button">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h3>Indications</h3>
                <wj-flex-grid #indiInfo [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="indicationWJ" (updatedView)="init(indiInfo)"
                    [selectionMode]="'None'" [allowDragging]="'None'">
                    <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of indactvHeader; let i = index"
                        [width]="'*'">
                    </wj-flex-grid-column>
                </wj-flex-grid>
                <h3>Beer Information</h3>
                <wj-flex-grid #cautionInfo [isReadOnly]="true" [headersVisibility]="'Column'" [itemsSource]="cautionWJ" (updatedView)="init(cautionInfo)"
                    [selectionMode]="'None'" [allowDragging]="'None'">
                    <wj-flex-grid-column [header]="drugactvHeader" [binding]="drugactvHeader" [visible]="true" *ngFor="let drugactvHeader of cautionHeader; let i = index"
                        [width]="(drugactvHeader === 'CAUTION') ? 700 : '*' " [wordWrap]="true">
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
            <div class="modal-footer">
                <div class="text-right pull-right col-md-4 padding-0">
                    <button class="hotkey_primary" (click)="getDrugPregnancyInfo(DrugPregnency)" appShortcutKey InputKey="p"><b>P</b> Pregnancy Information</button>
                    <button class="hotkey_primary mr-0" (click)="d('Cross click')" appShortcutKey InputKey="c"><b>C</b> Cancel </button>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- pop-up Drug Indication -->
    <ng-template #DrugPregnency let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="closeModal()">
            <h4 header> Pregnency and Lactation Information </h4>
            <span body>
                <div class="col-xs-12">
                    <label> Pregnancy Information </label>
                    <div class="col-xs-12">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col"> TRIMESTER </th>
                                    <th scope="col"> PREGNANCY RATING CODE </th>
                                    <th scope="col"> RATING DESCRIPTION </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pregnancyInfo of drugPregInfo?.dtPregnancyInformation">
                                    <th scope="row"> {{pregnancyInfo?.TRIMESTER}}</th>
                                    <td>{{pregnancyInfo?.PREGNANCYRATINGCODE}}</td>
                                    <td>{{pregnancyInfo?.RATINGDESCRIPTION}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <label> LACTATION Information </label>
                    <div class="col-xs-12">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col"> IS SAFE FOR CHILD BEARING AGE </th>
                                    <th scope="col"> IS SAFE FOR LABOUR AND DELIVERY </th>
                                    <th scope="col"> LACTATION RATING </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                    <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                    <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                    <td>{{lactationInfo['LACTATION RATING']}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <label> ALTER LACTATION DRUGS ( LACTATION RATING= 'AVOID' OR LACTATION RATING="CAUTION") </label>
                    <div class="col-xs-12">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col"> ALTERNATE PRODUCTION NAME </th>
                                    <th scope="col"> ALTERNATE NDCB </th>
                                    <th scope="col"> LACTATION RATING </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                    <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                    <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                    <td>{{lactationInfo['LACTATION RATING']}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </span>
            <button footer type="submit" autofocus class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="o"><b>O</b> OK </button>
        </app-custom-modal>
    </ng-template>

    <ng-template #MedGuide let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="closeModal()">
            <h4 header> Med Guide</h4>
            <span body>
                <div class="col-md-12" >
                    <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="550" type="application/pdf"></iframe>
                </div>
            </span>
            <button footer class="hotkey_primary" (click)="print()" appShortcutKey InputKey="p"><b>P</b> Print </button>
            <button footer class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="c"><b>C</b> Close </button>
        </app-custom-modal>
    </ng-template>
