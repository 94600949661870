import { Component, Input, OnInit } from '@angular/core';
import { UserGuideHeadingEnum, UserGuideKeyEnum } from 'src/app/models/user-guide-icon.enum';
import { AlertService } from 'src/app/services';
@Component({
  selector: 'app-user-guide-icon',
  templateUrl: './user-guide-icon.component.html',
  styleUrls: ['./user-guide-icon.component.scss']
})
export class UserGuideIconComponent implements OnInit {
activeTabId : string;
@Input() 
set ActiveTabId(actId : string){
    this.activeTabId = actId;
}
  constructor(private _alertServ : AlertService) { }

  ngOnInit(): void {
  }
  userGuide(){
   if(this.activeTabId){
    this._alertServ.showUserGuide({key : UserGuideKeyEnum[this.activeTabId], heading : UserGuideHeadingEnum[this.activeTabId]});
   }
}
}
