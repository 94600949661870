<div class="editpatient--body newrx--body edit--drug">
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                <div class="row">
                    <div class="col">
                        <span>Deferred Reason </span>
                    </div>
                    <div class="col pull-right text-right">
                        <button aria-hidden="true" class="hotkey_success hotkey_primary--Override" title="Add Reason"
                        appShortcutKey [AltKey]="'a'" (click)="openModal('Add',DfrdReason)"><span>A</span>ADD REASON</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="eprx--block__content">
            <div class="col-sm-12 padding-0 erx--body">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Name'" [RxSelectId]="'DeferredName'" [AutoFocus]="true"
                        [PlaceHolder]="'Search Name'" [ControlName]="'SearchText'" [FormGroupName]="searchFG" (TriggerOnEnterValue)="setPage(1)">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-button allign-bottom text-right" *ngIf="!isPopup">
                        <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" appShortcutKey [AltKey]="'f'" (click)="setPage(1)"><span>F</span> Search</button>
                        <button type="submit" class="hotkey_success pull-right" appShortcutKey [AltKey]="'l'" (click)="clearSearchData()"><span>L</span> Clear
                        </button>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-button allign-bottom text-right" *ngIf="isPopup">
                        <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" appShortcutKey [AltKey]="'f'" (click)="setPage(1)"><span>F</span> Search</button>
                        <button type="submit" class="hotkey_success pull-right" appShortcutKey [AltKey]="'l'" (click)="clearSearchData()"><span>L</span> Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="eprx--block__content">
            <wj-flex-grid #deferredlist [headersVisibility]="'Column'" [itemsSource]="defReasonWJ" [isReadOnly]="true"
                [selectionMode]="'Row'" (updatedView)="init(deferredlist)"
                (keydown)="keydownOnWijimo($event, DfrdReason)"
                [ngClass]="[!totalCount ? 'wjm-grd-custom-height-66 no-data':'wjm-grd-custom-height-66']"
                (dblclick)="onRowDblclicked(deferredlist, $event)" (initialized)="Init(deferredlist)">
                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders  ; let i = index"
                    [width]="(actvHeader === 'Actions')? '0.3*' : '5*'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <div class="text-center" *ngIf="actvHeader === 'Actions'">
                        <i class="far fa-edit fa-sm  actions mr-2 actions--Highlightfocus--icon" title="edit" (click)="openModal('Edit',DfrdReason, item)"></i>
                        <i class="far fa-trash-alt fa-sm actions danger-actve" title="delete" (click)="deletePopUp(delete, item.Id)"></i>
                        </div>
                        <div *ngIf="!(actvHeader === 'Actions')">
                            {{item[actvHeader]}}
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
                <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
            </wj-flex-grid>
            <app-eprx-pagination *ngIf="totalCount" [TotalCount]="totalCount" (OnFocusOut)="focusToFirst($event)" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'deferredList'"
                [WijmoName]="deferredlist" [PageNumber]="pageNu" [FromModal]="isPopup" [PageSize]="Size"></app-eprx-pagination>
        </div>
    </div>
</div>

<ng-template #DfrdReason let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!editMode">New Deferred Reason</h4>
        <h4 class="modal-title" *ngIf="editMode">Edit Deferred Reason</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-input [LabelText]="'Name'" [InputErrors]="defReasonFG?.controls['Name']?.errors" [AutoFocus]="true"
                [ErrorDefs]="{required: 'Required'}" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid"
                [PlaceHolder]="''" [ControlName]="'Name'" [FormGroupName]="defReasonFG" [RxSelectId]="'DfrdReason'">
                </eprx-input>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success"  *ngIf="!editMode" (click)="addDfrdReason()"  appShortcutKey [AltKey]="'s'"><span>S</span> Save</button>
                <button class="hotkey_success"  *ngIf="editMode" (click)="updateDfrsReason()" appShortcutKey [AltKey]="'u'"><span>U</span> Update</button>
                <button class="hotkey_success" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{warningText?.deleteConfrmMsg}}
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button autofocus class="hotkey_primary" (click)="d('Cross click')" (click)="deleteDfrd()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="n"><b>N</b> NO</button>
            </div>
        </div>
    </div>
</ng-template>
