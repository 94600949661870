<app-custom-modal (oncrossBtnClick)="Cancel()" >
    <h4 header>Warning!!</h4>
    <span body>
        <div class="row qz-steps" *ngIf="isQzLatestInstalled">
            <div class="col-12">
                <div class="row">
                    <div class="col-11">
                        <h2 class="text-danger">Qz-Tray has not been installed OR is inactive on your machine.</h2>
                    </div>
                    <div class="col-1 text-right">
                        <app-user-guide-icon [ActiveTabId]= "'QzTrayInstallation'"></app-user-guide-icon>
                    </div>
                </div>
                <li>Qz-Tray requires Java for Installation.</li>
                <li> To Download and install the latest version of Java and Qz-Tray,
                    <a #QzDownloadLink class="d-none" [href]="qzUrlString">Click Here</a><a #JavaDownloadLink
                        class="d-none" [href]="javaUrlString">Click Here</a>
                    <button class="hotkey_primary" (click)="clickActions(JavaDownloadLink,QzDownloadLink)">Click
                        here</button>.
                </li>
                <!-- <li> If not installed then
                    <a #QzDownloadLink style="display: none;" [href]="qzUrlString">Click Here</a><a #JavaDownloadLink style="display: none;" [href]="javaUrlString">Click Here</a>
                    <button class="hotkey_primary" (click)="clickActions(JavaDownloadLink,QzDownloadLink)" >Click here</button>
                    to Download and Install the <strong>latest</strong> version of Java and Qz-Tray and follow as below.</li> -->
                <li>
                    Qz-Tray requires Java for Installation.So please install java first and then install Qz-Tray.
                </li>
                <!-- <li>Qz-Tray requires Java for Installation. When installing Qz-Tray, If Java is not installed on the application it would prompt to install the latest version of Java prior to Qz-Tray installation.
                    <a #JavaDownloadLink style="display: none;" [href]="javaUrlString">Click Here</a><button class="hotkey_primary" (click)="JavaDownloadLink.click()" >Click here</button>
                    to Download and Install the <strong>latest</strong> version of Java and follow as below.
                </li>
                <li><a #QzDownloadLink style="display: none;" [href]="qzUrlString">Click Here</a>
                    <button class="hotkey_primary" (click)="QzDownloadLink.click()" >Click here</button>
                    to Download and Install the <strong>latest</strong> version of Qz-Tray</li> -->
                <!-- <div class="row">
                    <ul>
                        <p class="col-12">
                            Qz-Tray requires Java for Installation. When installing Qz-Tray, If Java is not installed on the application it would prompt to install the latest version of Java prior to Qz-Tray installation. Please allow to install Java.
                        </p>
                    </ul>
                    <div class="col-12">
                        <iframe width="100%" height="400px" frameBorder="0" [src]="urlSafe" scrolling="no" frameborder="3"></iframe>
                    </div>
                </div> -->
                <li>Once Java and Qz-Tray are installed, then follow the below steps to activate Qz-Tray.</li>
                <div class="row">
                    <div class="col-8">
                        <ul>

                            <li
                                *ngIf="(this.isSystemplatform && (this.isSystemplatform !== 'MacPPC' && this.isSystemplatform != 'Mac68K' && this.isSystemplatform != 'MacIntel'))">
                                Please start Qz-Tray from your Windows start Menu.</li>

                            <li
                                *ngIf="(this.isSystemplatform && (this.isSystemplatform !== 'MacPPC' && this.isSystemplatform !== 'Mac68K' && this.isSystemplatform !== 'MacIntel'))">
                                Right click on the Qz-Tray box icon that is displayed towards the bottom right corner of
                                the Toolbar.</li>

                            <li
                                *ngIf="(this.isSystemplatform && (this.isSystemplatform == 'MacPPC' ||  this.isSystemplatform == 'Mac68K' || this.isSystemplatform == 'MacIntel'))">
                                Once Qz-Tray is Installed, then an active Qz-Tray (Green Box) appears at the bottom of
                                the screen as in the screen shot here.</li>

                            <li
                                *ngIf="(this.isSystemplatform && (this.isSystemplatform !== 'MacPPC' && this.isSystemplatform !== 'Mac68K' && this.isSystemplatform !== 'MacIntel'))">
                                Check the 'Automatically start' option.</li>

                            <li>Close all Browser windows and Re-Launch the application to reflect the Qz-Tray Installation changes.</li>

                            <li class="margin_top_10rem">Now, when printing a label for the first time after
                                installation, a prompt will be displayed to allow to print.
                                On this, Check the 'Remember Me' option and click OK, to avoid redisplay of this pop-up.
                            </li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <img *ngIf="(this.isSystemplatform && (this.isSystemplatform !== 'MacPPC' && this.isSystemplatform !== 'Mac68K' && this.isSystemplatform !== 'MacIntel'))"
                            id="activateImage" [src]="'assets/Qz_active_steps.png'">
                        <img *ngIf="(this.isSystemplatform && (this.isSystemplatform == 'MacPPC' ||  this.isSystemplatform == 'Mac68K' || this.isSystemplatform == 'MacIntel'))"
                            id="activateImage3" [src]="'assets/Mac_QZ_Tray.png'">
                        <img id="activateImage2" [src]="'assets/Qz-tray_action.png'">
                    </div>
                </div>
            </div>
        </div>
        <div class="row qz-steps" *ngIf="!isQzLatestInstalled">
            <div class="col-12">
                <div class="row">
                    <div class="col-11">
                        <h2 class="text-danger">Qz-Tray Latest Version is available.</h2>
                    </div>
                    <div class="col-1 text-right">
                        <app-user-guide-icon [ActiveTabId]= "'QzTrayInstallation'"></app-user-guide-icon>
                    </div>
                </div>
                
                <li>A new version of QZ tray is available. Click here to update now.
                    <a #QzDownloadLink class="d-none" [href]="qzUrlString">Click Here</a>
                    <button class="hotkey_primary" (click)="clickQzLatestVersionActions(QzDownloadLink)">Click
                        here</button>
                </li>
                <div class="row">
                    <div class="col-8">
                        <ul>

                            <li>Please start Qz-Tray from your Windows start Menu.</li>

                            <li
                                *ngIf="(this.isSystemplatform && (this.isSystemplatform !== 'MacPPC' && this.isSystemplatform !== 'Mac68K' && this.isSystemplatform !== 'MacIntel'))">
                                Right click on the Qz-Tray box icon that is displayed towards the bottom right corner of
                                the Toolbar.</li>

                            <li
                                *ngIf="(this.isSystemplatform && (this.isSystemplatform == 'MacPPC' ||  this.isSystemplatform == 'Mac68K' || this.isSystemplatform == 'MacIntel'))">
                                Once Qz-Tray is Installed, then an active Qz-Tray (Green Box) appears at the bottom of
                                the screen as in the screen shot here.</li>

                            <li
                                *ngIf="(this.isSystemplatform && (this.isSystemplatform !== 'MacPPC' && this.isSystemplatform !== 'Mac68K' && this.isSystemplatform !== 'MacIntel'))">
                                Check the 'Automatically start' option.</li>

                            <li>Close all Browser windows and Re-Launch the application to reflect the Qz-Tray Installation changes.</li>

                            <li class="margin_top_10rem">Now, when printing a label for the first time after
                                installation, a prompt will be displayed to allow to print.
                                On this, Check the 'Remember Me' option and click OK, to avoid redisplay of this pop-up.
                            </li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <img *ngIf="(this.isSystemplatform && (this.isSystemplatform !== 'MacPPC' && this.isSystemplatform !== 'Mac68K' && this.isSystemplatform !== 'MacIntel'))"
                            id="activateImage" [src]="'assets/Qz_active_steps.png'">
                        <img *ngIf="(this.isSystemplatform && (this.isSystemplatform == 'MacPPC' ||  this.isSystemplatform == 'Mac68K' || this.isSystemplatform == 'MacIntel'))"
                            id="activateImage3" [src]="'assets/Mac_QZ_Tray.png'">
                        <img id="activateImage2" [src]="'assets/Qz-tray_action.png'">
                    </div>
                </div>
            </div>
        </div>
    </span>
    <button footer autofocus class="hotkey_primary" appShortcutKey InputKey="c" (click)="Cancel()"><b>C</b> Close
    </button>
</app-custom-modal>