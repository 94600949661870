import { Component, OnInit, Input } from "@angular/core";
import { CommonService } from "src/app/services";
import { RxUtils } from "src/app/utils";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-tr-rx-header-info",
    templateUrl: "./tr-rx-header-info.component.html",
    styles: []
})
export class TrRxHeaderInfoComponent implements OnInit {
    @Input() transmResp: any;
    @Input() transmissionType: any;
    @Input() rxInfo: any;
    BIN = "";
    PCN = "";

    constructor(private _comnSvc: CommonService, private _rxUtils: RxUtils, public _formatsUtil:FormatsUtil) {}

    ngOnInit() {
        this.getPatchBinAndPCN();
    }

    async getPatchBinAndPCN() {
        if (this.rxInfo && this.transmResp) {
            const patData = this.rxInfo["Patient"];
            if (this.transmissionType !== "TransMessage" && patData.insurance.length > 0 ) {
                const INS = await this._rxUtils.getfindAndInsuDet(patData, this.transmResp.transmissionDetails.InsType);
                this.BIN = INS && INS.InsuCarrier && INS.InsuCarrier.BINNum ? INS.InsuCarrier.BINNum : null;
                this.PCN = INS && INS.InsuPlan && INS.InsuPlan.ProcessorNum ? INS.InsuPlan.ProcessorNum : null;
            } else if (this.transmissionType === "TransMessage" && this.rxInfo && this.rxInfo.RxPay && this.rxInfo.RxPay.length > 0
                       && this.transmResp.transmissionDetails && this.transmResp.transmissionDetails.ResponseStatus !== "A") {
                    this.rxInfo.RxPay.forEach(async (ins, i) => {
                        const insCoverage = ["P: ", "S: ", "T: "];
                        if (ins.RecType !== "R" || (ins.RecType === "R" && ins.REJCodes === "70")) {
                            const INS = patData.insurance.find(type => type.insuType.trim() === ins.Ins_Code.trim());
                            /*this.BIN += INS ? insCoverage[ins.CoverageCD] +
                                        ( i < (this.rxInfo.RxPay.length - 1) ? INS.binnum + ", " : INS.binnum) : "--";
                                        console.log( this.BIN , " this.BIN this.BIN  this.BIN");
                            this.PCN += INS ? insCoverage[ins.CoverageCD] +
                                        (i < (this.rxInfo.RxPay.length - 1) ? INS.ProcessorNum + ", " : INS.ProcessorNum) : "--"; */
                        }
                     });
            }
            if (!this.BIN && !this.PCN && this.transmResp.ClaimProcess &&
                this.transmResp.ClaimProcess.objPatient && this.transmResp.ClaimProcess.objPatient.objBillToIns) {
                this.BIN = this.transmResp.ClaimProcess.objPatient.objBillToIns.BIN_NO;
                this.PCN = this.transmResp.ClaimProcess.objPatient.objBillToIns.MAG_CODE;
            }
        }
    }
}
