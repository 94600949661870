<div class="modal-header"><h4 class="modal-title">Rx Admin</h4>
    <button header type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="resetFG()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button></div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-4">
            <eprx-date-picker
                [AutoFocus]="true"
                [LabelText]="'Start Date'"
                [ControlName]="'StartDt'"
                [FormGroupName]="RxAdmin"
                [IsDisabled]=""
                [TabIndex]="20"
                [RxSelectId]="'startDate'"
                (TriggerOnDateSelect)="onDateSelect($event)"
            ></eprx-date-picker>
            <eprx-date-picker
                [LabelText]="'End Date'"
                [ControlName]="'EndDt'"
                [FormGroupName]="RxAdmin"
                [RxSelectId]="'endDate'"
                [TabIndex]="21"
                [IsDisabled]=""
            ></eprx-date-picker>
            <eprx-select
                [RxSelectId]="'doseSchedukeID'"
                [LabelText]="'Dose Schedule'"
                [PlaceHolder]="'Dose Schedule'"
                [ControlName]="'DoseScheduleId'"
                [FormGroupName]="RxAdmin"
                [List]="systemData?.DoseSchedule"
                [BindLabel]="'Name'"
                [BindValue]="'Id'"
                [LabelForId]="'Name'"
                (TriggerSelectValue)="optedDoseSchedValue($event?.value)"
                [TabIndex]="22"
            >
            </eprx-select >
            <eprx-select
                [LabelText]="'Repeat Days'"
                [PlaceHolder]="'Repeat Days'"
                [ControlName]="'RepeatDays'"
                [FormGroupName]="RxAdmin"
                [List]="reptDays"
                [TabIndex]="23"
            >
            </eprx-select >
            <eprx-select
                [RxSelectId]="'dosePerDAyId'"
                [LabelText]="'Dose Per Day'"
                [PlaceHolder]="'Dose Per Day'"
                [ControlName]="'dosePerDay'"
                [FormGroupName]="RxAdmin"
                [List]="dosagPerDay"
                (TriggerSelectValue)="
                    optedDosePerDay($event?.value)
                "
                [TabIndex]="24"
            >
            </eprx-select >
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col">
                    <label> Patient </label>
                    <div class="label--data" *ngIf="!hasRxData">
                        {{ rxPatientModel?.fullname }}
                    </div>
                    <div class="label--data" *ngIf="hasRxData">
                        {{ rxInfo?.Patient?.fullname }}
                    </div>
                </div>
                <div class="col">
                    <label> Drug </label>
                    <div class="label--data" *ngIf="!hasRxData">
                        {{ drugInfo?.drugname }}
                    </div>
                    <div class="label--data" *ngIf="hasRxData">
                        {{ rxInfo?.Drug?.drugname }}
                    </div>
                </div>
                <div class="col">
                    <label> Rx# </label>
                    <div class="label--data" *ngIf="!hasRxData">
                        {{ rxFG?.value?.Prescription?.PrescNum }}
                    </div>
                    <div class="label--data" *ngIf="hasRxData">
                        {{ rxInfo?.Prescription?.PrescNum }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label> Refill# </label>
                    <div class="label--data" *ngIf="!hasRxData">
                        {{ rxFG?.value?.PrescReFill?.ReFillNum }}
                    </div>
                    <div class="label--data" *ngIf="hasRxData">
                        {{ rxInfo?.PrescReFill?.ReFillNum }}
                    </div>
                </div>
                <div class="col-md-8">
                    <label> Description# </label>
                    <div class="label--data" *ngIf="!hasRxData">
                        {{ rxFG?.value?.SigCodes?.Description }}
                    </div>
                    <div class="label--data" *ngIf="hasRxData">
                        {{ rxInfo?.SigCodes?.Description }}
                    </div>
                </div>
            </div>
            <div class="">
                <table
                    class="table table-bordered"
                    [formGroup]="RxAdminDoseSchedule"
                >
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">Dose Seq No</th>
                            <th scope="col">Dose Qty</th>
                            <th scope="col">Dose Time</th>
                            <th scope="col">Dose Time Desc</th>
                            <th scope="col">Dose Slot</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="RxDoseSchs">
                        <tr
                            *ngFor="
                                let schdInfo of RxDoseSchs?.controls;
                                let i = index
                            "
                        >
                            <th scope="row">{{ i + 1 }}</th>
                            <td>
                                <eprx-input
                                    [LabelText]="''"
                                    [PlaceHolder]="''"
                                    [ControlName]="'DoseQty'"
                                    [FormGroupName]="schdInfo"
                                    [TabIndex]="24+(i*4)+1"
                                >
                                </eprx-input>
                            </td>
                            <td>
                                <eprx-select
                                    [LabelText]="''"
                                    [PlaceHolder]="''"
                                    [ControlName]="'DoseTime'"
                                    [FormGroupName]="schdInfo"
                                    [List]="dosageSlot"
                                    [BindLabel]="'doseSlotTime'"
                                    [BindValue]="'doseSlotTime'"
                                    [LabelForId]="'doseSlotTime'"
                                    (TriggerSelectValue)="
                                        optedDoseSlotValue($event?.value?.doseSlotTime, i)
                                    "
                                    [TabIndex]="24+(i*4)+2"
                                    [IsRequired]="true"
                                >
                                </eprx-select >
                            </td>
                            <td>
                                <eprx-select
                                    [LabelText]="''"
                                    [PlaceHolder]="''"
                                    [ControlName]="'DoseTimeCatId'"
                                    [FormGroupName]="schdInfo"
                                    [List]="systemData?.DoseTimeCat"
                                    [BindLabel]="'Name'"
                                    [BindValue]="'Id'"
                                    [LabelForId]="'Name'"
                                    [TabIndex]="24+(i*4)+3"
                                >
                                </eprx-select >
                            </td>
                            <td>
                                <eprx-select
                                    [LabelText]="''"
                                    [PlaceHolder]="''"
                                    [ControlName]="'DoseSlot'"
                                    [FormGroupName]="schdInfo"
                                    [List]="dosagPerDay"
                                    [TabIndex]="24+(i*4)+4"
                                >
                                </eprx-select >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="inactive"
        class="hotkey_primary"
        tabindex="200"
        *ngIf="!RxAdminDoseSchedule?.valid"
        appShortcutKey [CtrlKey]="'s'"
    >
        <span>S</span> Save
    </button>
    <button
        type="button"
        class="hotkey_success"
        tabindex="200"
        (click)="closeRxSchdModal()"
        *ngIf="RxAdminDoseSchedule?.valid"
        appShortcutKey [AltKey]="'s'"
    >
         <span>S</span> Save
    </button>
    <button type="button" tabindex="201" class="hotkey_success" (click)="resetFG()" appShortcutKey [AltKey]="'c'">
        <span>C</span> Cancel
    </button>
</div>
