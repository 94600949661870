import { Observable ,  BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class RxObservableService {
    savedRxInfo$: Observable<any>;
    _savedRxInfo$: BehaviorSubject<any>;

    otherValueInfo$: Observable<any>;
    _otherValueInfo$: BehaviorSubject<any>;

    DURInfo$: Observable<any>;
    _DURInfo$: BehaviorSubject<any>;

    WCOM$: Observable<any>;
    _WCOM$: BehaviorSubject<any>;

    DME$: Observable<any>;
    _DME$: BehaviorSubject<any>;

    MAR$: Observable<any>;
    _MAR$: BehaviorSubject<any>;

    constructor() {
        this._savedRxInfo$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.savedRxInfo$ = this._savedRxInfo$.asObservable();

        this._otherValueInfo$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.otherValueInfo$ = this._otherValueInfo$.asObservable();

        this._DURInfo$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.DURInfo$ = this._DURInfo$.asObservable();

        this._WCOM$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.WCOM$ = this._WCOM$.asObservable();

        this._DME$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.DME$ = this._DME$.asObservable();

        this._MAR$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.MAR$ = this._MAR$.asObservable();
    }

    saveRxInfoInBS(oi: any) {
        this._savedRxInfo$.next(oi);
    }

    saveOtherValueInfoInBS(oi: any) {
        this._otherValueInfo$.next(oi);
    }

    saveDURInfoInBS(oi: any) {
        this._DURInfo$.next(oi);
    }

    saveWCOMInfoInBS(oi: any) {
        this._WCOM$.next(oi);
    }

    saveDMEInfoInBS(oi: any) {
        this._DME$.next(oi);
    }

    saveMARInfoInBS(oi: any) {
        this._MAR$.next(oi);
    }

    clearAllRxOtherInfoBS() {
        this._savedRxInfo$.next(null);
        this._otherValueInfo$.next(null);
        this._DURInfo$.next(null);
        this._WCOM$.next(null);
        this._DME$.next(null);
        this._MAR$.next(null);
        localStorage.removeItem("OtherRxInfo");
    }
}
