import { AuditLogUtils } from './../../../utils/audit-log.util';
import { AlertService } from "src/app/services";
import { SystemData } from "./../../../models/system-data.model";
import { CollectionView } from "@grapecity/wijmo";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import {
    Measurement,
    ClinicalData
} from "./../../../models/edit-patient.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChild, ElementRef, HostListener, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientInfo, PatAuditLog, RxAuditLog } from "../../../models";
import { CommonService, PatientService } from "../../../services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as moment from "moment";
import { MsgConfig } from "../../..";
import { NewAuditUtils } from 'src/app/utils';
import { DateCheckUtil } from 'src/app/utils/date-checks.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: "app-clinical-info-shared",
    templateUrl: "./clinical-info-shared.component.html"
})
export class ClinicalInfoSharedComponent implements OnInit {
    patClinicalList: CollectionView;
    patientID: number;
    patientInfo: PatientInfo;
    editMode: boolean;
    clinicalMeasureFG: FormGroup;
    clinicalData = new ClinicalData();
    totalCount: any;
    pageCount: number;
    pageSize = 15;
    actvHeaders: string[];
    pageNumber = 1;
    @ViewChild("Clinical")
    flex: wjcGrid.FlexGrid;
    today: any;
    time: any;
    clinicalInfo: any;
    erxRowInfo: any;
    clinicData: any;
    systemData: SystemData;
    unitValues: any;
    minDate: { year: number; month: number; day: number };
    currentTime: string;
    @ViewChild("warningInfo", { static: true })
    warningInfo: ElementRef;
    clinicalId: any;
    CreatedDtTm: any;
    currentItem: any;
    formGroupInvalid: boolean;
    modelRef: any;
    wijimoId: wjcGrid.FlexGrid;
    originalData: any;
    unsubscribe$: Subject<void> = new Subject();
    dateChecks: { control: string; minDate: string; maxDate: { year: number; month: number; day: number; }; isRequired: boolean; }[];
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    @Input()
    set PatientID(pi: number) {
        this.patientID = pi;
    }

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
             if (event.which === 67) { // c
                 event.preventDefault();
                 this.cancel();
            }
        } else if (event.which === 27 && this.modalService.hasOpenModals()) { // esc
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
                event.preventDefault();
            }
        }
    }

    constructor(
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private modalService: NgbModal,
        private _editPatient: PatientService,
        private _fb: FormBuilder,
        private _alertService: AlertService,
        private _newAuditUtil: NewAuditUtils,
        private _auditUtils: AuditLogUtils,
        private _route: Router,
        private _dateUtil: DateCheckUtil
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.currentTime = moment().format("hh:mm:ss");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
    }

    get MedTestId(): FormControl {
        return this.clinicalMeasureFG.controls["MedTestId"].get(
            "MedTestId"
        ) as FormControl;
    }

    get TestUnitId(): FormControl {
        return this.clinicalMeasureFG.controls["TestUnitId"].get(
            "TestUnitId"
        ) as FormControl;
    }

    get ObsVal(): FormControl {
        return this.clinicalMeasureFG.controls["ObsVal"].get(
            "ObsVal"
        ) as FormControl;
    }

    ngOnInit() {
        this.getInfo();
        this.createFG();
        // this.getUnit();
        this.clinicalData.PageNumber = 1;
        this.clinicalData.PageSize = 15;
        this.getClinicalData(this.clinicalData);
        this.dateChecks = [{
            control: "MeasureDt",
            minDate: "",
            maxDate: this.minDate,
            isRequired: true
        }];
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
     this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    getInfo() {
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
            resp => (this.systemData = resp)
        );
        this._commonServ
            .getEditPatientInformation(this.patientID)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.patientInfo = resp;
                this._commonServ._patientInfo$.next(resp);
            });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    getClinicalData(data: any) {
        this._editPatient
            .getClinicalInfo(this.patientID, data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.generateList(resp);
                } else {
                    this.generateList(null);
                }
            });
    }

    getUnit() {
        this._editPatient.getPatientClinUnits(this.clinicalMeasureFG.value["MedTestId"])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.unitValues = resp;
            if (resp && resp.length > 0) {
                this.clinicalMeasureFG.patchValue({
                    TestUnitId: this.unitValues[0].Id
                });
            } else {
                this.clinicalMeasureFG.patchValue({
                    TestUnitId: null
                });
            }
        });
    }

    generateList(data: any) {
        if (data.length) {
            this.patClinicalList = new CollectionView(
                data.map((clinical, i) => {
                    const j = {};
                    j["Id"] = clinical.Id;
                    j["Date and Time"] = moment(clinical.MeasureDt).format(
                        "MM/DD/YYYY hh:mm a"
                    );
                    j["Dimension"] = clinical.Dimension;
                    j["Measurement Unit"] = clinical.MeasurementUnit;
                    j["Measurement Value"] = clinical.MeasurementValue;
                    j["UnitId"] = clinical.MeasurementUnitId;
                    j["DimensionId"] = clinical.DimensionId;
                    j["CreatedDtTm"] = clinical.CreatedDtTm;
                    this.totalCount = clinical.TotalCount;
                    this.pageCount = Math.ceil(
                        clinical.TotalCount / this.pageSize
                    );
                    return j;
                })
            );
            this.actvHeaders = [
                "Actions",
                "Date and Time",
                "Dimension",
                "Measurement Unit",
                "Measurement Value"
            ];
        } else {
            this.totalCount = 0;
        }
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.clinicalData.PageNumber = this.pageNumber;
        this.getClinicalData(this.clinicalData);
    }

    setSize(size: any) {
        this.pageSize = size;
        this.pageNumber = 1;
        this.clinicalData.PageNumber = 1;
        this.clinicalData.PageSize = size;
        this.getClinicalData(this.clinicalData);
    }

    createFG() {
        this.clinicalMeasureFG = this._fb.group(new Measurement());
    }

    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openClinicalInfo(content);
            }
        }
    }

    openClinicalInfo(addclinicalInfo) {
        this.time = moment().format("hh:mm a");
        this.editMode = false;
        // this.unitValues = null;
        this.clinicalMeasureFG.reset();
        this.clinicalMeasureFG.patchValue({
            MeasureDt: this.today
        });
        this.clinicalMeasureFG.patchValue({
            MeasureTm: this.time
        });
        this.modelRef = this.modalService.open(addclinicalInfo, { centered: true });
    }

    openeditClinicalInfo(addclinicalInfo, val: any) {
        this.editMode = true;
        this.modelRef = this.modalService.open(addclinicalInfo, { centered: true });
        this.clinicalInfo = this.clinicalMeasureFG.value;
        this.clinicalInfo.MeasureDt = moment(val["Date and Time"]).format(
            "MM/DD/YYYY"
        );
        this.clinicalInfo.MeasureTm = moment(val["Date and Time"]).format(
            "hh:mm a"
        );
        this.clinicalInfo.ObsVal = val["Measurement Value"];
        this.clinicalInfo.Id = val["Id"];
        // this.getUnit(val["DimensionId"]);
        this.clinicalInfo.PatientId = this.patientID;
        this.clinicalInfo.IsActive = true;
        this.clinicalInfo.CreatedDtTm = val["CreatedDtTm"];
        this.clinicalInfo.MedTestId = val["DimensionId"];
        this.clinicalInfo.TestUnitId = val["MeasurementUnitId"];
        this.clinicalInfo.MeasurementUnit = val["Measurement Unit"];
        this.clinicalInfo.Dimension = val["Dimension"];
        this.patchValue();
        this.originalData = Object.assign({}, this.clinicalMeasureFG.getRawValue());
    }

    openDeletePopUp(content, val: any) {
        this.currentItem = val;
        this.modelRef = this.modalService.open(content, { centered: true});
    }

    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.clinicalInfo) {
            if (key) {
                this.clinicalMeasureFG.controls[key].patchValue(
                    this.clinicalInfo[key]
                );
            }
        }
    }

    deleteClinicalInfo() {
        this._editPatient
            .deleteClinicalInfo(this.currentItem.Id, this.patientID)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._newAuditUtil.SaveAuditOnDelete(
                        null,
                        [{name: "Id", value: this.patientID}],
                        "Clinical Info Delete",
                        "Patient",
                        this.patientID
                    );
                    this._alertService.success(MsgConfig.DELETE_SUCCESS);
                    this.getClinicalData(this.clinicalData);
                } else {
                    this._alertService.error(MsgConfig.DELETE_FAIL);
                }
            });
    }

    getTwentyFourHourTime(amPmString) {
        const d = new Date("1/1/2013 " + amPmString);
        return d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    }

    saveclinicalData() {
        const clinicDatesFG = this._fb.group({MeasureDt: this.clinicalMeasureFG.controls["MeasureDt"].value});
        const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(clinicDatesFG, this.dateChecks);
       if (dateCheckFields) {
           return;
       } else if (this.clinicalMeasureFG.valid) {
            this.clinicalMeasureFG.patchValue({
                PatientId: this.patientID
            });
            this.clinicalMeasureFG.patchValue({
                IsActive: true
            });
            const measureTime = this.getTwentyFourHourTime(
                this.clinicalMeasureFG.value.MeasureTm
            );
            this.clinicalMeasureFG.value.MeasureTm = measureTime;
            this._editPatient
                .addClinicalInfo(this.clinicalMeasureFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === -1) {
                        this.modelRef = this.modalService.open(this.warningInfo, {
                            centered: true
                        });
                    } else if (resp === 1) {
                        const clinicalModel = new RxAuditLog().rxClinicalAuditModel;;
                        this._newAuditUtil.saveAuditOnAdd(
                            this.clinicalMeasureFG.value,
                            "Add Clinical Info",
                            "Patient",
                            this.patientID,
                            clinicalModel
                        );
                        this._alertService.success(MsgConfig.SAVE_SUCCESS);
                        if (this.modelRef) {
                            this.modelRef.close();
                        }
                        this.getClinicalData(this.clinicalData);
                    } else {
                        this._alertService.error(MsgConfig.SAVE_FAIL);
                    }
                });
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }

    updateclinicalData() {
        const clinicDatesFG = this._fb.group({MeasureDt: this.clinicalMeasureFG.controls["MeasureDt"].value});
        const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(clinicDatesFG, this.dateChecks);
       if (dateCheckFields) {
           return;
       } else if (this.clinicalMeasureFG.valid) {
            this.clinicalMeasureFG.patchValue({
                PatientId: this.patientID
            });
            this.clinicalMeasureFG.patchValue({
                IsActive: true
            });
            const measureTime = this.getTwentyFourHourTime(
                this.clinicalMeasureFG.value.MeasureTm
            );
            this.clinicalMeasureFG.value.MeasureTm = measureTime;
            this.clinicalMeasureFG.value.Id = this.clinicalInfo.Id;
            this.clinicalMeasureFG.value.CreatedDtTm = this.clinicalInfo.CreatedDtTm;
            this._editPatient
                .updateClinicalInfo(this.clinicalMeasureFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === 1) {
                        const time  = this.getTwentyFourHourTime(this.clinicalInfo.MeasureTm);
                        this.clinicalInfo.MeasureTm = time;
                        const clinicalModel = new RxAuditLog().rxClinicalAuditModel;;
                        this._newAuditUtil.saveAuditChange(
                            this.originalData,
                            this.clinicalMeasureFG.value,
                            "Clinical Info Edit",
                            "Patient",
                            this.patientID,
                            clinicalModel
                        );
                        this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                        this.getClinicalData(this.clinicalData);
                    } else {
                        this._alertService.error(MsgConfig.UPDATE_FAIL);
                    }
                });
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }

    cancel() {
        this._actvRoute.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(params => {
            if (params && params.fm) {
                if (
                    params.fm === "nr" ||
                    params.fm === "er" ||
                    params.fm === "rf" ||
                    params.fm === "nrf" ||
                    params.fm === "brx"
                ) {
                    this._route.navigate(["eprx/rx"], {
                        queryParams: { fm: params.fm }
                    });
                }
            } else {
                this._route.navigate(["eprx/dashboard"]);
            }
        });
    }
    init(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
        flex.columnHeaders.rows[0].height = 70;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    focusOutFromAdd(event) {
        if (!this.totalCount || this.totalCount === 0) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.wijimoId.focus();
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    keyeventOnWijimo(content, grid, event) {
        if (this.totalCount) {
            event.preventDefault();
            this.openeditClinicalInfo(content, grid.selectedItems[0]);
        }
    }

}
