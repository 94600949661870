import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdditionalInsuranceService, AlertService, CommonService, InsuranceService, PatPreDrugModalService } from 'src/app/services';
import { RegEx } from "src/app/app.regex";
import { RxPricingUtil, RxBillingUtil, NRxUtils, NRxSaveUtils, ValidateFormFieldsUtils, TransmissionUtils, NewAuditUtils } from "src/app/utils";
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsuCards, InsuCarrier, InsuGroups, Insurances, PatAuditLog, SearchInsurance } from 'src/app/models';
import { CommonWarnorconfirmPopupComponent } from '../common-warnorconfirm-popup/common-warnorconfirm-popup.component';

@Component({
  selector: 'app-additional-insurances-common',
  templateUrl: './additional-insurances-common.component.html',
  styleUrls: ['./additional-insurances-common.component.scss']
})
export class AdditionalInsurancesCommonComponent implements OnInit {
  @Output()  closePopUp = new EventEmitter<any>();
  @Input() patientId: any;
  @Input() selectedRow: boolean;
  @Input() IsPrimaryInsu: string;
  @Input() selectedInsuranceDetails: any;


  @ViewChild("AddInsurance", { static: true })
  AddInsurance: any;

  model: any;
  insuranceFG: FormGroup;
  systemData: any;
  insuranceData: any;
  regex: any;
  formGroupInvalid: boolean;
  policyRequired: boolean;
  filteredGroups: any;
  insuCode: string = "";
  insuName: string = "";
  unsubscribe$: Subject<void> = new Subject();
  minDate: { year: number; month: number; day: number; };

  get INSUCARD(): FormGroup {
    return this.insuranceFG.get("InsuCard") as FormGroup;
}

get Insurance(): FormGroup {
    return this.insuranceFG.get("Insurance") as FormGroup;
}


  constructor(  private _additionalInsu: AdditionalInsuranceService,
    private _validUtils: ValidateFormFieldsUtils,
    private _insurService: InsuranceService,
    private _fb: FormBuilder,
    private _modalSvc: NgbModal,
    private _ppdims: PatPreDrugModalService, private _transUtils: TransmissionUtils,
     private _alertServ: AlertService, public _commonService: CommonService) { }

  ngOnInit(): void {
    if(this.IsPrimaryInsu) {
        const warnText = '<p>Would you like to set <b>'+ (this.selectedRow['InsName'] ? this.selectedRow['InsName'].toUpperCase() : '') +'</b> as the Primary Insurance?</p>';
        const modelRef = this._modalSvc.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg =  warnText
        modelRef.componentInstance.yesButton = true;
        modelRef.componentInstance.noButton = true;
        modelRef.componentInstance.IsHeaderText = "Confirmation";
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
            modelRef.close();
            if(resp)
                this.callSaveOrUpdate(true);
            else 
                this.closePopup();
        });
    } else {
        this.model = this._modalSvc.open(this.AddInsurance, { centered: true, backdrop: false,
            keyboard: false, size: "lg" });
    }
    this.systemData = this._commonService.systemData$["source"]["value"];
    this.insuranceData = this._commonService.insuranceData$["source"]["value"];
    this.regex = RegEx;
    this.createPatInsuAddFG();
    this.defaultPatchings();
  }
  checkFGValid() {
    if (this.insuranceFG.value["Insurance"]["InsurerId"] && (!this.policyRequired || (this.policyRequired &&
        this.insuranceFG.value["InsuCard"]["PolicyNum"] && this.insuranceFG.value["InsuCard"]["PolicyNum"].trim() !== ""))) {
        this.formGroupInvalid = false;
        this.callSaveOrUpdate();
    } else {
        this.formGroupInvalid = true;
        this._validUtils.validateAllFormFields(this.insuranceFG);
    }
}
defaultPatchings() {
    this.insuranceFG.controls["InsuCard"].patchValue({FirstName: this.selectedRow['Card Holder First Name'], LastName: this.selectedRow['Card Holder Last Name'], PolicyNum : this.selectedRow['PBMMemberID']});
    this.insuName = this.selectedRow['Ins. ID'];
    this.insuCode = this.selectedRow['InsId'] ;
    if(this.insuName && this.insuCode) {
        this.policyRequired = true;
    }
    this.insuranceFG.controls["Insurance"].patchValue({
        CPBrandVal: 0, CPGenericVal: "0", RelCatId: 2,
        CoPayCatId: 1, PerNum: "01", IsPatAssigned: true, ActiveStatusId: 1,
        InsurerId: this.selectedRow['InsuCarrierId'], InsuGrpId: null
    });
    this.insuranceFG.controls["InsuCard"].patchValue({
        ExpiryDt: "12/31/2099"
    });
    this.insuranceFG.controls["InsuCarrier"].patchValue({
        InsurerCode: this.selectedRow['InsId'], BINNum: this.selectedRow['BINLocationNumber'], ProcessorNum: this.selectedRow['ProcessorIdentificationNumber']
    });
    this.insuranceFG.controls["Insgroup"].patchValue({
        Name: this.selectedRow['Group Id']});

}
createPatInsuAddFG() {
    this.insuranceFG = this._fb.group({
        patientid: this.patientId,
        Insurance: this._fb.group(new Insurances()),
        InsuCard: this._fb.group(new InsuCards()),
        Insgroup: this._fb.group(new InsuGroups()),
        InsuCarrier: this._fb.group(new InsuCarrier()),
        isFromeRx: this.IsPrimaryInsu ? true: false
    });
    const newFG = this.insuranceFG.controls["Insurance"] as FormGroup;
    if(this.IsPrimaryInsu) {
        newFG.controls.InsuPriId.patchValue(1);
    }
}
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
  selectedPriority(selectedInsu) {
    const newFG = this.insuranceFG.controls["Insurance"] as FormGroup;
    if (selectedInsu !== 0) {
        this._additionalInsu.checkIfInsuPriorityExists(123, selectedInsu)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (!resp) {
                newFG.controls.InsuPriId.patchValue(null);
                this._alertServ.error("Insurance exists with selected priority.");
            }
        });
    } else {
        newFG.controls.InsuPriId.patchValue(null);
    }
}
focusOutFromGroup() {
  if (document.getElementById("primInsRela12")) {
  const data: any = document.getElementById("primInsRela12").getElementsByTagName("input")[0];
  setTimeout(() => {
      data.focus();
  }, 100);
  }
}
filterGroupsBasedOnInsurerId(insuCarrierId) {
  const newFG = this.insuranceFG.controls["Insurance"] as FormGroup;
  const newIG = this.insuranceFG.controls["Insgroup"] as FormGroup;
  this._additionalInsu.getInsuGroups(insuCarrierId)
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(resp => {
      if (resp && resp["length"] > 0) {
          this.filteredGroups = resp;
          if (newIG.value["Name"] && !newFG.value["InsuGrpId"] &&
          this.filteredGroups.find(val => val["Name"] === newIG.value["Name"])) {
              const matchedGroup = this.filteredGroups.find(val => val["Name"] === newIG.value["Name"]);
              newFG.controls["InsuGrpId"].patchValue(matchedGroup["Id"]);
              newIG.controls["Id"].patchValue(matchedGroup["Id"]);
          } else if (!newFG.value["InsuGrpId"]) {
              newIG.controls["Name"].patchValue(null);
              newFG.controls["InsuGrpId"].patchValue(null);
              newIG.controls["Id"].patchValue(null);
          }
      } else {
          newFG.controls["InsuGrpId"].patchValue(null);
          newIG.controls["Id"].patchValue(null);
      }
  });
}

isTypedValue(Value) {
  const newIG = this.insuranceFG.controls["Insgroup"] as FormGroup;
  if (Value && Value.value && Value.value !== "") {
      newIG.controls["Name"].patchValue(Value.value["Name"]);
      newIG.controls["InsurerId"].patchValue(this.insuranceFG.value.Insurance.InsurerId);
      newIG.controls["IsActive"].patchValue(true);
      newIG.controls["Id"].patchValue(null);
  } else {
      newIG.controls["Name"].patchValue(null);
      newIG.controls["InsurerId"].patchValue(null);
      newIG.controls["IsActive"].patchValue(false);
  }
}
search = (text$: Observable<string>) =>
text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => (term.length < 1 ? [] : this.filterByPcnBin(term)))
)

filterByPcnBin(term) {
const searchTerms = term.split(",");
const insuranceList = Object.assign([], this.insuranceData);
if (insuranceList && insuranceList.length > 0) {
    ["e1comm", "..."].map(codes => {
        const index = insuranceList.findIndex(val => (val["InsurerCode"]).toLowerCase() === codes);
        if (index > -1) {
            insuranceList.splice(index, 1);
        }
    });
}
let filteredData = insuranceList.filter(v => {
    let valExists = false;
    if (v.SubmissionType === "9") {
        ["PlanCode", "InsuranceName", "BinNum"].forEach(function (key) {
            const val = v[key] ? v[key] : "";
            valExists =
                valExists ||
                val
                    .toString()
                    .toLowerCase()
                    .startsWith(searchTerms[0].toLocaleLowerCase());
        });
    }
    return valExists;
});
if (searchTerms[1]) {
    filteredData = filteredData.filter(v => {
        let valExists = false;
        const val = v["ProcessorNum"] ? v["ProcessorNum"] : "";
        valExists =
            valExists ||
            val
                .toString()
                .toLowerCase()
                .startsWith(searchTerms[1].toLocaleLowerCase());
        return valExists;
    });
}
if ((term && term.trim().length) && (!filteredData || (filteredData && filteredData.length === 0))) {
    const modelRef = this._modalSvc.open(CommonWarnorconfirmPopupComponent, {
        backdrop: false,
        keyboard: false, windowClass: "large--content"
    });
    modelRef.componentInstance.warnMsg = "Insurance not found in the file.";
    modelRef.componentInstance.okButton = true;
    modelRef.componentInstance.IsPopUpClosed
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp: any) => {
        modelRef.close();
        this.insuCode = null;
        this.insuName = null;
    });
}
return filteredData.splice(0, 20);
}

formatter = (x: any) => {
this.insuName = x.InsuranceName;
return x.InsurerCode;
}

routeToAddInsurance() {
  this._ppdims._patPreDrugModal$.next("insurance");
  const modalRef = this._ppdims.modalInstanceRef;
  modalRef.componentInstance.insuId = null;
  modalRef.componentInstance.selectedRow = this.selectedRow;
  modalRef.componentInstance.SkipUpdateBS = true;
  modalRef.componentInstance.CloseInsuModal
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(async resp => {
      modalRef.close();
      if (resp && resp["Type"] !== "Cancel") {
          const search = new SearchInsurance();
          const data = await this._commonService.getInsuData(search).toPromise();
          this._commonService._insuranceData$.next(data["Records"]);
          this.insuranceData = this._commonService.insuranceData$["source"]["value"];
          setTimeout(() => {
              this.insuranceFG.controls["Insgroup"].patchValue({Insgroup: null});
              this.insuranceFG.controls["Insurance"].patchValue({InsuGrpId: 0});
              if (document.getElementById("searchBoxInsType")) {
                  document.getElementById("searchBoxInsType").focus();
              }
              this.patchInsuranceValue(resp["insuId"]);
          }, 1000);
      }
      this._ppdims._patPreDrugModal$.next(null);
  });
}

selectedInsType(val) {
  this.insuName = val.item.InsuranceName;
  this.insuCode = val.item.InsurerCode;
  const newFG: any = this.insuranceFG.controls["Insurance"];
  const newIC: any = this.insuranceFG.controls["InsuCarrier"];
  newFG.controls["InsurerId"].setValue(val.item.InsuCarrierId);
  newFG.controls["InsuGrpId"].setValue(null);
  newIC.controls["InsurerCode"].setValue(val.item.InsurerCode);
  this.isInsuranceExist(this.patientId, val.item.InsuCarrierId, val.item.Id, newFG.controls["Id"].value);
}
async isInsuranceExist(patientId, insurerId, insuId, insuranceId, IsNew?: boolean) {
  const isInsuExs = IsNew ? false : await this._additionalInsu.isInsuranceExistForPatient(patientId,
       insurerId, insuranceId).toPromise();
  if (isInsuExs) {
      this._alertServ.error("Patient already has the selected Insurance.");
      this.insuName = " ";
      this.insuCode = " ";
      this.insuCode = null;
      const newFG: any = this.insuranceFG.controls["Insurance"];
      const newIC: any = this.insuranceFG.controls["InsuCarrier"];
      newIC.controls["InsurerCode"].setValue(null);
      newFG.controls["InsurerId"].setValue(null);
  } else {
      const insurance = this.insuranceData.find(d => d["InsuCarrierId"] === +this.insuranceFG.value["Insurance"]["InsurerId"]);
      if (insuId) {
          const insuStng = await this._insurService.getInsSettingsInfo(insuId).toPromise();
          if (insuStng && insuStng["InsuPlanPrice"]) {
              const newFG: any = this.insuranceFG.controls["Insurance"];
              newFG.controls["IsPatAssigned"].setValue(insuStng["InsuPlanPrice"]["IsAssigntAccepted"]);
          }
      }
      if (insurance) {
          this.checkIsPolicyRequired(insurance);
      }
      this.filterGroupsBasedOnInsurerId(insurerId);
  }
}
checkIsPolicyRequired(val) {
  const insuCardsFG: any = this.insuranceFG.controls["InsuCard"];
  if (insuCardsFG.value["PolicyNum"] === "" || insuCardsFG.value["PolicyNum"] === " ") {
      insuCardsFG.controls["PolicyNum"].setValue(null);
  }
  if (val.SubmissionType === "9" || !val.SubmissionType) {
      this.policyRequired = true;
      insuCardsFG.controls["PolicyNum"].setValidators([Validators.required]);
      insuCardsFG.controls["PolicyNum"].markAsUntouched();
  } else {
      this.policyRequired = false;
      insuCardsFG.controls["PolicyNum"].setValidators([Validators.nullValidator]);
      insuCardsFG.controls["PolicyNum"].setErrors(null);
      setTimeout(() => {
          insuCardsFG.controls["PolicyNum"].markAsUntouched();
      }, 200);
  }
}
async patchInsuranceValue(Id) {
  const insurance = this.insuranceData ? this.insuranceData.find(d => d["Id"] === +Id) : null;
  if (insurance) {
      this.insuranceFG.controls["InsuCard"].patchValue({ExpiryDt: "12/31/2099"});
      this.selectedInsType({item: insurance});
  }
}
closePopup(type?) {
    if(this.model){
        this.model.close();
        this.model = null;
    }
    this.closePopUp.emit(type ? type: false);
}
callSaveOrUpdate(isFrom?) {

    if (this.insuranceFG.valid) {
        const insuranceData = this.insuranceFG.value;
        this._additionalInsu
            .addPatientInsurance(insuranceData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                if (resp) {
                    this._alertServ.success("Insurance added successfully.");
                    this.closePopup(true);
                } else {
                    this._alertServ.error("Insurance add unsuccessful.");
                }
            });
    } else {
        this._validUtils.validateAllFormFields(this.insuranceFG);
    }
}
async setPatientPrimaryInsurance() {
    this.callSaveOrUpdate('fromPrimary');
  }
}
