import { Injectable } from "@angular/core";
import { AccCategoryE, RxE } from "../models";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { RxUtils } from "./rx.util";
import { PrivMaskGuard } from "src/app/guards";

@Injectable({
    providedIn: "root"
})
export class NavRxUtils {
    accessPrivCate: any;
    rxPrivs: any;
    openOverride: boolean;
    privType: any;
    categoryId: any;
    overrideEndpoint: any;
    routeFrom: string;

    subscriptions = new Subscription();

    constructor(
        private _actvRoute: ActivatedRoute,
        private _rxUtils: RxUtils,
        private _privMaskGuard: PrivMaskGuard
    ) {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (Object.keys(params).length > 0) {
                    this.routeFrom = params.fm;
                }
            })
        );
    }

    // redirectToEditRx() {
    //     if (this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.rxPrivs.EditRx)) {
    //         this.routeToRx();
    //     } else {
    //         this.openOveride("EditRx", "PUT_Rx");
    //     }
    // }

    // OverrideRes(val) {
    //     this.openOverride = false;
    //     if (val) {
    //         this.routeToRx();
    //     }
    // }

    // openOveride(content, endpoint?) {
    //     this.privType = content;
    //     this.categoryId = content;
    //     this.openOverride = true;
    //     this.overrideEndpoint = endpoint;

    // }

    // routeToRx(prescNumber: number) {
    //     if (this.routeFrom === "rf" || this.routeFrom === "nrf" || !this.routeFrom) {
    //         setTimeout(() => {
    //             sessionStorage.setItem("toEdit", "" + "rf");
    //             this._rxUtils.getRxDetailsByPrescNumber(prescNumber, "er", null, false, true);
    //         }, 3000);
    //     } else {
    //         setTimeout(() => {
    //             if (this.isGroupTransm) {
    //                 sessionStorage.setItem("toEdit", "" + "Dash");
    //             } else {
    //                 sessionStorage.setItem("toEdit", "" + this.routeFrom);
    //             }
    //             this._rxUtils.getRxDetailsByPrescNumber(prescNumber, "er");
    //         }, 3000);
    //     }
    // }

    // checkHasPrivsToAccess(category: string, privType: string) {
    //     return this._privMaskGuard.canActivate(category, privType);
    // }
}
