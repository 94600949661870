import { RxUtils } from "src/app/utils";
import { Subscription } from "rxjs";
import { Component, OnInit, HostListener } from "@angular/core";
import { EditDrugService, CommonService } from "../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-drugs-document",
    templateUrl: "./drugs-document.component.html"
})
export class DrugsDocumentComponent implements OnInit {
    drugInfo: any;
    drugId: number;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.routeBackToPrevScreen();
            }
        }
    }

    constructor(
        private _actvRoute: ActivatedRoute,
        private _commonSvc: CommonService,
        private _route: Router,
        private _rxutils: RxUtils,
        private _modalService: NgbModal

    ) {
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (params && params.fm) {
                    this.routeFrom = params.fm;
                }
                if (params && params.focus) {
                    if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                        this.focusOnDU = params.focus;
                    }
                }
            }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe((params: any) => {
            this.drugId = +params["pid"];
        }));
    }

    ngOnInit() {
        this.getInfo(this.drugId);
    }
    getInfo(drugId: number) {
        this._commonSvc.getDrugInfo(drugId).subscribe(resp => {
            if (resp) {
                this.drugInfo = resp;
                this._commonSvc._drugInfo$.next(resp);
            }
        });
    }
    routeBackToPrevScreen() {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
      //  this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }
}
