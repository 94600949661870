import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import {
    InputComponent,
    DatePickerComponent,
    SelectComponent,
    RadioComponent,
    CheckBoxComponent,
    TextAreaComponent,
    RxSelectComponent
} from ".";
import {
    ChangeDebounceDirective,
    KeyUpDownDirective,
    TypeaheadPopupDirective,
    AutofocusDirectivee,
    OnReturnDirective
} from "../../directives";
import { InputRestrictionDirective } from "src/app/directives/input-restriction.directive";
import { NgbModule, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { PipeModule } from "src/app/pipes/pipe.module";
import {
    AutofocusDirective,
    NumberCurrencyDirective,
    DateFormatDirective,
    ShortcutKeyDirective,
    BarcodeDetectorDirective,
    DragDirective,
    DragHandleDirective
} from "src/app/utils";
import { NgbDateCustomParserFormatter } from "src/app/utils/date-custom-parser.util";
import { ShortcutTabDirective } from "src/app/utils/shortcut-tab.directive";
import { DatePickerRefComponent } from './date-picker-ref/date-picker-ref.component';
import { HighlightDirective } from "src/app/utils/text-highlight.directive";
import { ToggleComponent } from './toggle/toggle.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgxMaskModule.forRoot(),
        NgSelectModule,
        PipeModule
    ],
    declarations: [
        InputComponent,
        ChangeDebounceDirective,
        InputRestrictionDirective,
        DatePickerComponent,
        SelectComponent,
        RadioComponent,
        CheckBoxComponent,
        KeyUpDownDirective,
        TextAreaComponent,
        RxSelectComponent,
        RxSelectComponent,
        TypeaheadPopupDirective,
        AutofocusDirectivee,
        AutofocusDirective,
        NumberCurrencyDirective,
        DateFormatDirective,
        ShortcutKeyDirective,
        OnReturnDirective,
        ShortcutTabDirective,
        DatePickerRefComponent,
        HighlightDirective,
        BarcodeDetectorDirective,
        DragDirective,
        DragHandleDirective,
        ToggleComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputComponent,
        ChangeDebounceDirective,
        InputRestrictionDirective,
        DatePickerComponent,
        SelectComponent,
        RadioComponent,
        CheckBoxComponent,
        KeyUpDownDirective,
        TextAreaComponent,
        RxSelectComponent,
        PipeModule,
        TypeaheadPopupDirective,
        AutofocusDirectivee,
        AutofocusDirective,
        NumberCurrencyDirective,
        DateFormatDirective,
        ShortcutKeyDirective,
        OnReturnDirective,
        ShortcutTabDirective,
        DatePickerRefComponent,
        HighlightDirective,
        BarcodeDetectorDirective,
        DragDirective,
        DragHandleDirective,
        ToggleComponent
    ],
    providers: [
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter
        }
    ],
    schemas: [NO_ERRORS_SCHEMA , CUSTOM_ELEMENTS_SCHEMA]
})
export class CustFormElemnsModule {}
