

<div class="cust--login">
    <div class="row">
        <div class="col-md-6 padding_2rem">
            <img src="assets/img/Logo.png">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 color-white">
            <!-- [style.height.px]="((pageType=='error') ? 390 : (pageType === 'validationerror') ?  320 : 340)" -->
            <div *ngIf="pageType=='error' ">
                <div class="text-center pad-top-40">
                    <i class="fa fa-exclamation-triangle fa-10x error-icon" aria-hidden="true"></i>
                    <p class="text-center error-ssomsg">Something went wrong!</p>
                </div>
                <div class="text-center success-logoutmsg">We are unable to process your request at this time due to an unexpected
                    error.
                    Please contact Prime rx Support team <span *ngIf="!EventKey">.</span> <span *ngIf="EventKey"> with the reference number : <b>{{EventKey}}</b></span>
                </div>
                    <br/>
                    <!-- <div class="text-center success-logoutmsg">  <div [style.paddingBottom.px] ="5" > <u> Prime rx Support:</u></div>
                    <i class="fa fa-phone-alt" aria-hidden="true"></i> 1-855-501-5048<br>
                    <i class="fa fa-envelope" aria-hidden="true"></i> primerxsupport@primerx.com</div> -->
            </div>
            <div *ngIf="pageType=='validationerror' ">
                    <div class="text-center pad-top-40">
                    <i class="fa fa-exclamation-triangle fa-10x error-icon" aria-hidden="true"></i>
                </div>
                <div class="text-center success-logoutmsg padding_top_30px">{{Message}}<br/></div>
            </div>
        </div>
    </div>
</div>
