import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PrivMaskGuard } from 'src/app/guards';
import { AccCategoryE, PaginationModel, RxE, SystemData } from 'src/app/models';
import { ReportsService, CommonService, AlertService, RphVerificationService, PatPreDrugModalService } from 'src/app/services';
import { PrintService } from 'src/app/services/print.service';
import { NRxUtils, PatchRXFGUtil, ValidateFormFieldsUtils, RxUtils, ColorCode, WijimoUtil, CommonUtil } from 'src/app/utils';
import { CollectionView } from  '@grapecity/wijmo';
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as _ from "lodash";
import { saveAs } from 'file-saver';
import { HoverOver } from 'src/app/utils/hover-over.util';
import { ColorCodeRx } from 'src/app/utils/rx-color.util';
import { MsgConfig } from 'src/app/app.messages';
import { Verificationsenum } from 'src/app/models/Verifications.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-review-pharmacist-verif',
    templateUrl: './review-pharmacist-verif.component.html'
})
export class ReviewPharmacistVerifComponent implements OnInit {
    today: string;
    maxDate: { year: number; month: number; day: number; };
    filterFG: any;
    pharmVerifWJ: CollectionView;
    totalVerifRecs: any;
    rphVerifFlex: any;
    openOverride: boolean;
    privType: any;
    categoryId: any;
    overrideEndpoint: any;
    rxInfo: any;
    totalVerifData: any;
    selectedRx: any;
    showVerifHist: any;
    fromDate: string;
    systemData: SystemData;
    wjHeaders: any;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    totalCount: number;
    startIndex: number;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();

    constructor(
        private _fb: FormBuilder,
        private _reportsServ: ReportsService,
        private _modalSvc: NgbModal,
        private _nrxUtils: NRxUtils,
        private _comnSvc: CommonService,
        private _privMaskGuard: PrivMaskGuard,
        private _alertSer: AlertService,
        private _router: Router,
        private _patchRx: PatchRXFGUtil,
        private _printServ: PrintService,
        private _formUtils: ValidateFormFieldsUtils,
        private _rxUtils: RxUtils,
        private _comnUtil: CommonUtil,
        private _rphService: RphVerificationService,
        private _colorCode: ColorCode,
        private _patPreDrugService: PatPreDrugModalService,
        private _wijimoUtils: WijimoUtil,
        private _hoverOver: HoverOver,
        private _colorCodeRx: ColorCodeRx
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.fromDate = moment().subtract(1,'days').format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.createFG();
    }

    ngOnInit() {
        this._comnSvc.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemData = resp;
        });
        this.getRecords();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "reviewRxVerfWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("reviewRxVerfWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("reviewRxVerfWJ", storedWJ);
    }

    createFG() {
        this.filterFG = this._fb.group({
            fromDate: [this.fromDate],
            toDate: [this.today],
            isCurrentUser: [false],
            verifyStatus: [],
            VerificationType: 0,
            IsExport:null,
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize
        });
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.startIndex = (this.pageSize * (this.pageNumber - 1));
        this.filterFG.controls["PageNumber"].setValue(this.startIndex);
        this.getRecords();
    }

    setSize(size: any) {
        this.pageNumber = this.paginationModel.pageNumber;
        this.pageSize = size ;
        this.filterFG.controls["PageSize"].setValue(this.pageSize);
        this.setPage(this.paginationModel.pageNumber);
    }

    async getRecords() {
        await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.filterFG, "fromDate", "toDate", 0);
        if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else {
            this._rphService.getRphVerifHistory(this.filterFG.value)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.totalVerifData = resp;
                    if (resp && resp.data && resp.data.length) {
                        this.totalVerifRecs = resp.data;
                        this.totalCount = resp.TotalRecords;
                        this.isDataExists = true;
                    } else {
                        this.totalVerifRecs = [];
                        this.isDataExists = false;
                        this.pharmVerifWJ = new CollectionView(null)
                    }
                    this.generatePharmVerifWJ();
                }
    
            });
        }
    }

    generatePharmVerifWJ() {
        const mapDataSet = [];
            this.totalVerifRecs.map((patRx, i) => {
                const k = {};
                k["RowId"] = i;
                k["data"] = patRx;
                k["Rx#"] = patRx.Prescription.PrescNum;
                k["Ref#"] = patRx.PrescReFill.ReFillNum;
                k["Fill#"] = this._comnSvc.checkIsPartialFill(patRx.PrescReFill, patRx.Prescription) ? patRx.PrescReFill.PartialFillNo : " ";
                k["PrescRefillId"] = patRx.PrescReFill.Id;
                k["Patient Name"] =
                    (patRx.Patient.lastname + ", " + patRx.Patient.firstname).toUpperCase();
                k["Drug Name"] = (patRx.Drug.drugname + " " + patRx.Drug.strength + " " + patRx.Drug.drugformname).toUpperCase();
                k["Drug Class"] = patRx.Drug.drugclass;
                k["DrugClass"] = patRx.Drug.drugclass;
                k["User"] = patRx.PrescReFill.RegPharmacist ?
                patRx.PrescReFill.RegPharmacist : "";
                const primaryInsu = _.filter(patRx.Patient.insurance, {
                    insuranceid: patRx.RefillInsu.InsuId
                })[0];
                let hasPayRecrds = false;
                if (patRx && patRx.RxPay && patRx.RxPay.length > 0) {
                    hasPayRecrds = patRx.RxPay.some(pay => pay.RecType !== "R");
                }
                if (primaryInsu) {
                    if (primaryInsu["IsCash"] === true ||
                        primaryInsu["insurerid"] === this.systemData["DefaultValuese"][0]["CashInsurerId"]) {
                        k["IsBilled"] = true;
                    } else {
                        k["IsBilled"] = patRx["RxPay"] ? (patRx["RxPay"]["length"] > 0 && hasPayRecrds) ? true : false : false;
                    }
                } else if (patRx.RefillInsu.InsuId === 10189) {
                    k["IsBilled"] = true;
                } else {
                    k["IsBilled"] = patRx["RxPay"] ? (patRx["RxPay"]["length"] > 0 && hasPayRecrds) ? true : false : false;
                }
                let rphVerify = patRx["rPHVerifs"];
                if (rphVerify && rphVerify.length > 0) {
                    rphVerify = rphVerify.filter((ele: any) => {
                        return ((ele.Status + "").trim().toUpperCase() === "V" || (ele.Status + "").trim().toUpperCase() === "F" || ((ele.Status + "").trim().toUpperCase() === "RV" && (!ele.IsForRxVerification)));
                    });
                    rphVerify.sort((a, b) => {
                        return <any>new Date(b.VerifDtTm) - <any>new Date(a.VerifDtTm);
                    });

                }
                let FillDtTm;
                if (patRx && patRx.PrescReFill && patRx.PrescReFill.FillDtTm) {
                    FillDtTm = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
                        "MM/DD/YYYY"
                    );
                }
                k["Verified"] = (rphVerify && rphVerify.length > 0 ) ?
                 moment.utc(rphVerify[0].VerifDtTm).local().format("MM/DD/YYYY hh:mm:ss a") :
                 moment.utc(patRx.PrescReFill.FillDtTm).local().format("MM/DD/YYYY hh:mm:ss a");
                k["Paid"] = !patRx.PrescReFill.TotalRxAmount || patRx.PrescReFill.TotalRxAmount === 0 ? "$0.00" : "$" + (parseFloat(patRx.PrescReFill.TotalRxAmount)).toFixed(2);
                k["Billed"] = patRx.PrescReFill.TotalAmt ? "$" + (parseFloat(patRx.PrescReFill.TotalAmt)).toFixed(2) : "$0.00";
                k["Qty"] = patRx.PrescReFill.DispQty ? (patRx.PrescReFill.DispQty).toFixed(3) : "0.000";
                k["Days"] = Math.round(patRx.PrescReFill.SupplyDays);
                k["Filled"] = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
                    "MM/DD/YYYY"
                );
                k["Prescriber Name"] = patRx.Prescriber ? (
                    patRx.Prescriber.prescriberlastname + ", " + patRx.Prescriber.prescriberfirstname).toUpperCase() : null;
                k["Status"] =
                (patRx.PrescReFill.StatusId === 4 || patRx.Prescription.RxStatusId === 4) ? "T" :
                    (patRx.PrescReFill.StatusId === 2 || patRx.Prescription.RxStatusId === 2 || patRx.RxBill.StatusId === 6) ? "F" :
                        (patRx.PrescReFill.StatusId === 3
                            || patRx.Prescription.RxStatusId === 3) && patRx.RxBill.StatusId === 2 ? "BI" :
                            (patRx.RxBill.StatusId === 2 && patRx.PrescReFill.StatusId !== 4
                                && patRx.Prescription.RxStatusId !== 2) ? "B" :
                                ((patRx.PrescReFill.StatusId === 3 || patRx.Prescription.RxStatusId === 3) &&
                                    patRx.RxBill.StatusId === 1) ? "UI" : "U";
                if (
                    (patRx.FollowUpStatus === null ||
                        patRx.FollowUpStatus === "") &&
                    (patRx && patRx.rph2VerificationStatus &&
                        (patRx.rph2VerificationStatus).trim().toLowerCase() === "v")
                ) {
                    k["Pharmacist Verification Status"] = rphVerify?.length > 0 && !rphVerify[0].IsReverified ? Verificationsenum.verified : Verificationsenum.reverified;
                } else if (
                    (patRx.FollowUpStatus === null ||
                        patRx.FollowUpStatus === "") &&
                    (patRx && patRx.rph2VerificationStatus &&  ((patRx.rph2VerificationStatus).trim().toLowerCase() === "rv"))
                ) {
                    k["Pharmacist Verification Status"] = "RE-VERIFY";
                } else if (
                    patRx.FollowUpStatus === "F" &&
                    (patRx.rph2VerificationStatus === null ||
                        patRx.rph2VerificationStatus === "")
                ) {
                    k["Pharmacist Verification Status"] = "Follow up";
                } else {
                    k["Pharmacist Verification Status"] = "NOT YET VERIFIED";
                }
                const rxVerfList = patRx["rPHVerifs"] && patRx["rPHVerifs"].length>0 ? patRx["rPHVerifs"].filter((v: any)=> ((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"])) : [];
                k["Rx Verification Status"] = this._comnUtil.getRXStatus(patRx.rph1VerificationStatus, (rxVerfList?.length ? rxVerfList[rxVerfList.length - 1] : null));
                k["Tr Count"] = (patRx["rPHVerifs"] && patRx["rPHVerifs"].length > 0) ? patRx["rPHVerifs"].length : 0;
                k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
                k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                const rowId = k["RowId"] >= 0 ? k["RowId"] : "";
                const RxDetails = rowId >= 0 ?   this.totalVerifRecs[rowId] : null;
                k["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
                // return k;
                mapDataSet.push(k)
            })
            this.pharmVerifWJ = new CollectionView(mapDataSet,
                {
                    sortComparer: (a: any, b: any) => {
                        return moment(a).isValid() && moment(b).isValid()
                            ? moment(a).diff(moment(b)) : null;
                    }
                });
    }

    openVerifHistory(item, showVerif) {
        if (item && item.data) {
            this.rxInfo = item.data;
        }
        this.showVerifHist = showVerif;
    }

    OnDoubleClickOnWijimo(s: any, e: any) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem && ht.cellType === 1) {
            this.routeToEditRx(s.selectedRows[0].dataItem);
        }
    }

    routeToEditRx(item: any) {
        this.selectedRx = item;
        if (this._privMaskGuard.canActivate(AccCategoryE.Rx, RxE.EditRx)) {
                this.routeToRx();
            } else {
                this.openOveride("EditRx", "PUT_Rx");
            }
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
                this.routeToRx();
            }
    }

    openOveride(content, endpoint?) {
        this.privType = content;
        this.categoryId = content;
        this.openOverride = true;
        this.overrideEndpoint = endpoint;
    }


    async routeToRx(rxNo?: any, rfId?: any) {
        const selectedrxNo = rxNo ? rxNo : +this.selectedRx["Rx#"];
        const selectedrxId = rfId ? rfId : this.selectedRx["PrescRefillId"];
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
        }
        this._patPreDrugService._patPreDrugModal$.next("Rx");
        const modalRef = await this._patPreDrugService.modalInstanceRef;
        modalRef.componentInstance.CloseEditMode
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(x => {
            modalRef.close();
            this.selectedRx = null;
            this._patPreDrugService._patPreDrugModal$.next(null);
        this.getRecords();
        });
        modalRef.componentInstance.CloseAndReopen
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(d => {
                modalRef.close();
                this._patPreDrugService._patPreDrugModal$.next(null);
                if (d) {
                    this.routeToRx(d["rxNo"], d["rfId"]);
                } else {
                    this.selectedRx = null;
                    this.getRecords();
                }
            });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getReport(IsExport: boolean=false) {
        this.filterFG.controls["IsExport"].patchValue(IsExport);
        this._reportsServ.getRphVerifReport(this.filterFG.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                  this.printReport(resp);
            } else if(resp && resp.size > 0 && resp.type === "application/ms-excel") {
                this.totalVerifData= resp;
                       const blob = new Blob([resp], { type : 'application/ms-excel' });
                        const file = new File([blob], "Reviewpharmacistverification " + '.xlsx', { type: 'application/ms-excel' });
                       saveAs(file);

            } else {
                this._alertSer.error("Report is unavailable.");
            }
        })
    }

    printReport(report) {
        const newBlob = new Blob([report], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
          const base64data = reader.result;
          this._printServ.printPdf(base64data);
        }.bind(this);
      }

    init(flex: wjcGrid.FlexGrid) {
        this.rphVerifFlex = flex;
        flex.focus();
        flex.columnFooters.rows.push(new wjcGrid.GroupRow());
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRow(0, true);
        // this.selectRadioOnKeyEvents(flex);
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];

        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
               cell.style.fontWeight = "bold";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.textAlign = "center";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const rowId = row && row.dataItem ? row.dataItem["RowId"] : "";
                const RxDetails = rowId >= 0 ? this.totalVerifRecs[rowId] : null;
                const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx");
                if (colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }

    clearSearchInputs() {
        this.filterFG.reset();
        this.filterFG.markAsUntouched();
        this.filterFG.patchValue({
            fromDate: this.fromDate,
            toDate: this.today,
            VerificationType: 0
        });
        this.setSize(this.paginationModel.pageSize);
    }

    focusToFirst(event) {
        if (document.getElementById("RphFromDate")) {
            event.preventDefault();
            // setTimeout(() => {
                document.getElementById("RphFromDate").focus();
            // }, 10);

        }
    }

    focusToFirstIfEmpty(event) {
        this.focusToFirst(event);
    }
    toggleColorLegend() {
        this._alertSer.showHelpText({RxStatus: true, DrugName: true, Status: true});
    }
}
