import { AlertService, EditPrescriberService } from "src/app/services";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from "@angular/core";
import { PrivMaskGuard } from "../../../guards";
import { AccCategoryE, FileMaintenance1E } from "../../../models";
import { MsgConfig } from "../../..";
import { PrescTabTitles } from "../prescriber.constants";
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonUtil } from "src/app/utils";

@Component({
    selector: "app-prescriber-tabs",
    templateUrl: "./prescriber-tabs.component.html"
})
export class PrescTabsComponent implements OnInit, OnChanges {
    docAvail: any;
    accessPrivCate: any;
    preNotesPrivs: any;
    activeTabId: any;
    @Input() prescriberInfo: any;
    @Input() presbId: any;
    @Input() prescFG: FormGroup;
    @Input() resetToFirst: boolean;

    @Output()
    ActivePrescriberTab = new EventEmitter();

    @Output() addPrescriberData = new EventEmitter();

    @ViewChild("warning", { static: true })
    warning: any;
    selectedTab: string;

    @Input()
    ActiveTabId(data: any) {
        this.activeTabId = PrescTabTitles[data];
    }

    constructor(
        private _alertServ: AlertService,
        private _modalSvc: NgbModal,
        private _privMaskGuard: PrivMaskGuard,
        public _commonUtil: CommonUtil
    ) {
        this.accessPrivCate = AccCategoryE;
        this.preNotesPrivs = FileMaintenance1E;
    }

    ngOnInit() {
    }

    ngOnChanges(changes?: any) {
        if (changes && changes.resetToFirst && changes.resetToFirst.currentValue) {
            if (this.resetToFirst) {
                this.activeTabId = null;
                setTimeout(() => {
                    this.activeTabId = "Patient1";
                    this.ActivePrescriberTab.emit("Patient1");
                }, 100);
            }
        }
    }

    beforeChange(nextId: string) {
        if (nextId && this.presbId) {
             if (nextId === "Patient5") {
                if (
                    this.checkHasPrivsToAccess(
                        this.accessPrivCate.FileMaintenance1,
                        this.preNotesPrivs.PrescriberNotesLog
                    )
                ) {
                    this.ActivePrescriberTab.emit(nextId);
                } else {
                   this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
                }
            } else {
                this.ActivePrescriberTab.emit(nextId);
            }
        } else {
            if (this.prescFG.valid) {
                this.selectedTab  = nextId;
                this._modalSvc.open(this.warning, {centered: true, backdrop: false, windowClass: "large--content"});
            } else {
                this.activeTabId = null;
                setTimeout(() => {
                    this.activeTabId = "Patient1";
                }, 100);
                this.addPrescriberData.emit("Patient1");
            }
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    addPrescriber() {
        this.addPrescriberData.emit(this.selectedTab);
    }

    closeWarning() {
        this.activeTabId = null;
        setTimeout(() => {
            this.activeTabId = "Patient1";
        }, 100);
        this.ActivePrescriberTab.emit("Patient1");
    }

}
