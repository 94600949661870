export class SystemData {
    Allergies: Allergies[];
    CoPayCat: IdName[];
    Gender: IdName[];
    IdProof: IdName[];
    Insurance: Insurance[];
    InsuGroup: InsuranceGroup[];
    Lang: Language[];
    PriceBase: IdName[];
    RelationCat: IdName[];
    SplProgram: IdName[];
    ERxOpStatus: ERxOpStatus[] = [];
    ERxOpCat: ERxOpCat[] = [];
    ERxStatus: ERxOpStatus[] = [];
    DoseSchedule: DoseSchedule[] = [];
    DiscountSche: DiscountSch[] = [];
    PriceSchedule: PriceSchedule[] = [];
    DrugType: IdName[];
    DrugUnit: IdName[];
    NdcQualifier: IdName[];
    UnitDoseIndication: IdName[];
    DrugMiscPriceCat: IdName[];
    DAW: DAW[] = [];
    DrugBucket: DrugBucket[] = [];
    RxOrigin: RxOrigin[] = [];
    DeliveryMode: DeliveryMode[] = [];
    RxConfig: RxConfig;
    SystemConfig: SystemConfig;
    MedTest: MedTest[] = [];
    DefaultSysSetting: DefaultSysSetting[] = [];
    PrescriberCat: PrescriberCat[];
    DrugCat: IdName[] = [];
    clinicalSignf: IdName[] = [];
    coAgentIdCat: IdName[] = [];
    effortLvl: IdName[] = [];
    payPrefMethods: IdName[] = [];
    profServs: IdName[] = [];
    servReason: IdName[] = [];
    servResult: IdName[] = [];
    DefaultValuese: any[] = [];
    batchTransmissionStatus: GrpTransStatus[] = [];
    DoseTimeCat: DoseTimeCat[] = [];
    InsuType: IdName[] = [];
    CommMode: IdName[] = [];
    InsuXmitSubCat: IdName[] = [];
    DocCat: IdName[] = [];
    CostCat: IdName[] = [];
    OtherAmntCat: IdName[] = [];
    cardCats: IdName[] = [];
    RxBillStatus: IdName[] = [];
    SeverityLevel: IdName[] = [];
    PregnancyRating: IdName[] = [];
    LactationRating: IdName[] = [];
    Onset: IdName[] = [];
    Incidence: IdName[] = null;
    Tenant: Tenant = null;
    OtherCoverage: IdName[] = null;
    DiscontReason: IdName[] = null;
    labelPrintSetSizes: LabelPageSize[] = [];
    DroppingOff_PickingUp: IdName[] = [];
    PatientQualifier: IdName[] = [];
    bucketContextTypes: BucketContext[] = [];
    pickUpFrom: pickUpFromContext[] = [];
    treatmentTypes: TreatmentType[] = [];
    rxOriginsData: RxOrigin[] = [];
    Interfaces: InterfaceIds[] = [];
}

export class Tenant {
    Id: number = null;
    Name: string = null;
    TenantId: number = null;
    NPI: string = null;
    NCPDP: string = null;
    DBURL: string = null;
    DBCatalog: string = null;
    DBUser: string = null;
    DBPass: string = null;
    Subdomain: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class LabelPageSize {
    Name: String = null;
    Id: number = null;
    Width: number = null;
    Height: number = null;
    PrintWidth: number = null;
    PrintHeight: number = null;
    IsLandscape: boolean = null;
}

export class DoseTimeCat {
    CreatedDtTm: Date;
    Id: number;
    IsActive: boolean;
    ModifiedDtTm:Date;
    Name: String;
    Remarks: String;
}

export class DrugBucket {
    Id: number;
    Code: string;
    Name: string;
    BucketCatId: number;
    InsuCarrierId: number;
    SplProgramId: number;
    PatCustomCatId: number;
    PriceSchId: number;
    IsBucketPriceUsed: boolean;
    CreatedDtTm: Date;
    ModifiedDtTm: Date;
}

export class BucketContext {
    IsActive: boolean;
    Id: number;
    ContextName: string;
}

export class pickUpFromContext {
    Id: number;
    Name: string;
    Remarks: string;
}
export class PriceSchedule {
    Id: number;
    PriceCode: string;
    Name: string;
}
export class DiscountSch {
    Discount: number;
    Id: number;
    DiscCode: string;
    Name: string;
}

export class Allergies {
    AllergyName: string;
    ClassId: number;
    Id: number;
}

export class Insurance {
    BINNum: string;
    Id: number;
    InsurerCode: string;
    ProcessorNum: string;
    Name: string;
}

export class InsuranceGroup {
    CreatedDtTm: Date;
    Id: number;
    InsurerId: number;
    IsActive: boolean;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
}

export class Language {
    CreatedDtTm: Date;
    Id: number;
    IsActive: boolean;
    IsRightAligned: boolean;
    LangCode: string;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
}

export class InterfaceIds{
    Id: number;
    IsActive: boolean;
    Name: string;
    TenantId: number;
}
export class Cities {
    CreatedDtTm: Date;
    Id: number;
    IsActive: boolean;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
    StateId: number;
}

export class States {
    CountryId: number;
    CreatedDtTm: Date;
    Id: number;
    IsActive: boolean;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
}
export class PrescriberCat {
    Id: number = null;
    Name: string = null;
    PrescCatCode: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class ZipMaster {
    Id: number;
    ZipCode: string;
    CountryId: number;
    StateId: number;
    CityId: number;
    StateName: string;
    CityName: string;
}

export class IdName {
    CreatedDtTm: Date;
    Id: number;
    IsActive: boolean;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
    Type: string;
}

export class ERxOpStatus {
    CreatedDtTm: Date;
    Id: number;
    IsActive: boolean;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
}

export class DoseSchedule {
    CreatedDtTm: Date;
    DoseDays: number;
    DosePerDay: number;
    Id: number;
    IsActive: boolean;
    IsSpecTime: boolean;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
    TenantId: number;
}

export class ERxOpCat {
    CreatedDtTm: Date;
    Id: number;
    IsActive: boolean;
    ModifiedDtTm: Date;
    Name: string;
    Remarks: string;
}

export class DAW {
    Id: number = null;
    Code: string = null;
    Name: string = null;
}

export class RxOrigin {
    Code: string = null;
    CreatedDtTm: Date = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    Name: string = null;
    Remarks: string = null;
    TenantId: number = null;
}

export class DeliveryMode {
    Code: string = null;
    CreatedDtTm: Date = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    Name: string = null;
    Remarks: string = null;
}

export class SystemConfig {
    Id: number = null;
    TenantId: number = null;
    PharmacyId: number = null;
    Is340BPharmacy: boolean = null;
    IsEnableSMSEmailSer: boolean = null;
    IsEnableReminderSer: boolean = null;
    IsDispensingMachine: boolean = null;
    DispMatchPrior: string = null;
    RxScanMode: string = null;
    InactivityTimeout: number = null;
    IsPatnhaveDiagInfo: boolean = null;
    IsAuditRxChanges: boolean = null;
    IsAuditPatnChanges: boolean = null;
    Theme: string = null;
    ModifiedDtTm: Date = null;
    ModifiedById: number = null;
    CreatedDtTm: Date = null;
}

export class RxConfig {
    Id: number = null;
    TenantId: boolean = null;
    PharmacyId: number = null;
    IsIncDateOrder: boolean = null;
    IsIncDateFilled: boolean = null;
    IsIncAuthRefills: boolean = null;
    IsIncRefillsNum: boolean = null;
    IsIncPrice: boolean = null;
    IsIncPayType: boolean = null;
    IsIncPriceCode: boolean = null;
    IsIncCo_Pay: boolean = null;
    IsIncDrugLot: boolean = null;
    IsIncSigLines: boolean = null;
    IsIncDiscount: boolean = null;
    IsIncDeliveryMethod: boolean = null;
    IsIncRxOriginCode: boolean = null;
    IsConfirmPharmInit: boolean = null;
    IsNursingHomePrtCateg: boolean = null;
    IsConfirmTechInit: boolean = null;
    IsEnforceTechInit: boolean = null;
    IsForceAuthRefills: boolean = null;
    IsForceDrugLot: boolean = null;
    IsForceDateOrder: boolean = null;
    IsForceRxSerial: boolean = null;
    IsForceUniqueRxSerial: boolean = null;
    IsForceCheckDrugExpDt: boolean = null;
    IsForceDeliveryMethod: boolean = null;
    IsForceRxOrigin: boolean = null;
    IsForceMaxDailyDose: boolean = null;
    IsDisplayPatRxHist: boolean = null;
    IsShowSmilerRx: boolean = null;
    IsShowGenAutom: boolean = null;
    IsCheckInsCardExpDt: boolean = null;
    IsShowPrivacyAcknowWarn: boolean = null;
    IsAssignNewRx: boolean = null;
    IsAllowRefillTransOutRx: boolean = null;
    IsCheckDrugExpDt: boolean = null;
    IsHardStopExpRefill: boolean = null;
    IsUpdatePatCopay: boolean = null;
    IsDispUnpaidCopayReminder: boolean = null;
    IsSaveRxBillStatus: boolean = null;
    IsCheckPhysicianNPI: boolean = null;
    IsPrintTelephoneRx: boolean = null;
    IsWarnifblankunitCost: boolean = null;
    IsWarnifInventIsLow: boolean = null;
    IsPrintLblForFiledRx: boolean = null;
    IsBringPatLastDoc: boolean = null;
    StartNextRxFrom: string = null;
    IsPromptForRetransRejtClaims: boolean = null;
    IsLogoutTechnicianChanges: boolean = null;
    IsMaxDailyDoseReqd: boolean = null;
    IsAskForCompdFormulPrintout: boolean = null;
    IsUseIncdInMAR: boolean = null;
    IsStartwithCurrentTechTechInit: boolean = null;
    IsAskForPrefDrugRxEntry: boolean = null;
    IsOnePrefDrugPerClass: boolean = null;
    ModifiedById: number = null;
    ModifiedDtTm: Date = null;
    CreatedDtTm: Date = null;
}
export class MedTest {
    Id: number = null;
    IsActive: boolean = null;
    Name: string = null;
    Remarks: string = null;
    ModifiedDtTm: Date = null;
    CreatedDtTm: Date = null;
}

export class DefaultSysSetting {
    CompPriceCalculation: string = null;
    CompoundNameLabel: string = null;
    CreatedDtTm: Date = null;
    EPrescScriptVersionId: number = null;
    ErxPrintFormat: string = null;
    Id: number = null;
    IsAllowDeleteUsedNDC: boolean = null;
    IsAllowEmptyRefillQueue: boolean = null;
    IsAuthReqClaimsPaidLessThanCost: boolean = null;
    IsByPassAutoDispPkgPat: boolean = null;
    IsDDIModeratOverrideReq: boolean = null;
    IsDDISevereOverrideReq: boolean = null;
    IsDefCopayPaidStatus: boolean = null;
    IsDisplayDrugImage: boolean = null;
    IsFilterDrugStatus: boolean = null;
    IsForceAllergySelection: boolean = null;
    IsHighlightNotPickupRxs: boolean = null;
    IsIncludeDupNDCRefills: boolean = null;
    IsOverrideReqDrugChange: boolean = null;
    IsPrintCurrentDtERX: boolean = null;
    IsPrintDrugDescription: boolean = null;
    IsPrintInactionIngr: boolean = null;
    IsRefillsDueBasedEquiDrug: boolean = null;
    IsRxDefaultDoseTime: boolean = null;
    IsShowFutureDtBrowse: boolean = null;
    IsShowRxImageRefill: boolean = null;
    IsSortFiledRXByDueDateInPatHis: boolean = null;
    IsUniqueDrugQckCode: boolean = null;
    ModifiedById: number = null;
    ModifiedDtTm: Date = null;
    PharmacyId: number = null;
    RxAdminOptionId: number = null;
    RxSerialLength: string = null;
}


export class GrpTransStatus {
    Id: number = null;
    TransmissionStatus: string = null;
}

export class TreatmentType {
    Code: string = null;
    Description: string = null;
    Id: number = null;
    IsActive: boolean = null;
}
