import { DateTime } from "@grapecity/wijmo";

export class RxPatientModel {
    PatientInfo: RxPatientDetails;
    BillToList: BillTo[];
    PatientNotes: PatientNotes[];
    RegPharmacist: string;
}

export class BillTo {
    Id: number;
    Name: string;
}

export class PatientNotes {
    Id: number;
    Note: string;
    ModifiedDtTm: Date;
}

export class RxPatientDetails {
    TenantId: number;
    PatientName: string;
    LastName: string;
    FirstName: string;
    MiddleName: string;
    PatientId: number;
    SearchInsuranceName: string;
    SearchPhoneNo: string;
    Dob: DateTime;
    ModifiedDtTm: DateTime;
    Gender: string;
    EmailId: string;
    Address: string;
    ZipCode: string;
    InsuranceType: string;
    IsInsured: string;
    GroupNo: string;
    PersonCode: string;
    Notes: string;
    Relation: string;
    DiscScheduleId: number;
    Allergies: string;
    PhoneNumber: string;
    CustomCategory: string;
    FacilityName: string;
    FacilityCode: string;
    LanguageId: number;
    LanguageName: string;
    Insuplancode: string;
    IsDisplayInsurance: boolean;
    DeliveryModeId: number; //TODO: Pavan If this is null we need to consider system settings value.. once that is done in UI delete this todo. Refer to BindPatientData in RxController old
    PolicyNum: string;
    PrimeInsuId: number;
    BillTo: number;
    InsuranceExpiryDate: DateTime;
    InsuplanId: number;
    InsuCarrierId: number;
    PatientSingleInsuranceId: number;
    Is340B: boolean;
    IspNotes: boolean;
    PrescriberId: number;
    PrescriberName: string;
    PrescriberActive: boolean;
    IsPrivacyAcked: boolean;
    FamilyId: number;

    //Todo : Pavan There is a property called MustShowRxAdmin which is based on "RxAdminOption" system settings. Delete this  todo when implemented in UI. Refer to BindPatientData in RxController old
}
