import { CommonService } from "./../services/common.service";
import { UserService } from "./../services/user.service";
import { AuditLog, Meta, Delta } from "./../models/audit-log.model";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { diff } from "deep-diff";
import * as moment from "moment";

@Injectable({
    providedIn: "root"
})
export class AuditLogUtils {
    auditObj: AuditLog = new AuditLog();
    newArr = [];
    arr: Meta;
    delta: Delta = new Delta();
    oldArr = [];
    newRecord = false;
    constructor(
        private _userService: UserService,
        private _commonServ: CommonService
    ) {}

    async getChangedValues(original, changed, action, entity, entityid, isFromLabelQueue: boolean = false) {
        if (original === null || (original && original.length === 0)) {
            this.newArr = [];
            for (const keys in changed) {
                if (changed[keys] !== null) {
                    const data = changed[keys];
                    if (_.isPlainObject(data)) {
                        // tslint:disable-next-line:forin
                        for (const key in data) {
                            if (data[key] !== null && !_.isObject(data[key])) {
                                this.arr = new Meta();
                                this.arr.name = key;
                                this.arr.value = data[key];
                                // this.newArr.push(this.arr);
                                if (this.newArr && this.newArr.length > 0 && action === "Add Clinical Address" && key === "SPINum") {
                                    const isSPIExist = this.newArr.find((item: any) => item.name === "SPINum");
                                   if (!isSPIExist) {
                                    this.newArr.push(this.arr);
                                   }
                                } else {
                                    this.newArr.push(this.arr);
                                }
                            }
                        }
                    } else {
                        // this.arr = new Meta();
                        // this.arr.name = keys;
                        // this.arr.value = data;
                        // this.newArr.push(this.arr);
                        if (action === "Add Clinical Address" && entity === "Prescriber") {
                            if (keys !== "PrescriberId") {
                                this.arr = new Meta();
                                this.arr.name = keys;
                                this.arr.value = data;
                                this.newArr.push(this.arr);
                            }
                        } else {
                            this.arr = new Meta();
                            this.arr.name = keys;
                            this.arr.value = data;
                            this.newArr.push(this.arr);
                        }
                    }
                }
            }
            this.delta.original = null;
        } else {
            this.setDiffValues(original, changed);
        }
        if (isFromLabelQueue) {
            this.auditObj["isFromLabelQueue"] = isFromLabelQueue;
        }
        this.delta.changed = this.newArr;
        this.auditObj.delta = this.delta;
        this.auditObj.entity = entity;
        this.auditObj.action = action;
        this.auditObj.entityid = entityid;
        this.auditObj.isOverride = false;
        this.auditObj.created = moment().format("YYYY/MM/DD");
        this.auditObj.userid = _.toNumber(this._userService.getToken("UserId"));
        this.saveAuditDetails([this.auditObj]);
    }

    deletedValues(deletedId, action, entity, entityid) {
        this.oldArr = [];
        this.newArr = [];
        const old = new Meta();
        old.name = "IsActive";
        old.value = 1;
        this.oldArr.push(old);
        const new1 = new Meta();
        new1.name = "IsActive";
        new1.value = 0;
        this.newArr.push(new1);
        const id = new Meta();
        id.name = "id";
        id.value = deletedId;
        this.oldArr.push(id);
        this.newArr.push(id);
        this.delta.original = this.oldArr;
        this.delta.changed = this.newArr;
        this.auditObj.entity = entity;
        this.auditObj.action = action;
        this.auditObj.entityid = entityid;
        this.auditObj.created = moment().format("YYYY/MM/DD");
        this.auditObj.userid = _.toNumber(this._userService.getToken("UserId"));
        this.auditObj.delta = this.delta;
        this.auditObj.isOverride = false;
        this.saveAuditDetails([this.auditObj]);
    }

    async saveAuditDetails(data) {
        await this._commonServ.saveAuditChanges(data).toPromise();
    }

    setDiffValues(original: any, changed: any) {
        for (const key in original) {
            if (_.isPlainObject(original[key])) {
                const differences = diff(original[key], changed[key]);
                if (differences) {
                    this.oldArr = [];
                    this.newArr = [];
                    this.setDiffForObj(differences);
                }
            } else if (_.isArray(original[key])) {
                const c = diff(original[key], changed[key]);
                for (const keys in c) {
                    if (c[keys].kind === "A") {
                        const data = c[keys];
                        if (_.isObject(data)) {
                            // tslint:disable-next-line:forin
                            for (const keyss in data.item.rhs) {
                                if (
                                    data[keyss] !== null &&
                                    !_.isObject(data[keyss])
                                ) {
                                    this.arr = new Meta();
                                    this.arr.name = keyss;
                                    this.arr.value = data.item.rhs[keyss];
                                    this.newArr.push(this.arr);
                                }
                            }
                        }
                    }
                }
                this.delta.original = this.oldArr;
            } else {
                const differences = diff(original, changed);
                if (differences) {
                    this.oldArr = [];
                    this.newArr = [];
                    this.setDiffForObj(differences);
                }
            }
        }
    }

    setDiffForObj(differences: any) {
        const insUnwantedVals = ["PersonId", "TotalCount", "OrgCreation"];
        // tslint:disable-next-line:forin
        for (const keys in differences) {
            // this.oldArr = [];
            if (differences[keys].path && !(differences[keys].lhs == differences[keys].rhs)
                && !(!differences[keys].lhs && differences[keys].rhs === "") && !(insUnwantedVals.includes(differences[keys].path[differences[keys].path.length - 1]))) {
                const old = new Meta();
                old.name =
                    differences[keys].path[differences[keys].path.length - 1];
                old.value = differences[keys].lhs;
                this.arr = new Meta();
                this.arr.name =
                    differences[keys].path[differences[keys].path.length - 1];
                this.arr.value = differences[keys].rhs;
                this.newArr.push(this.arr);
                this.oldArr.push(old);
            }
        }
        this.delta.original = this.oldArr;
    }
}

