import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    HostListener,
    ElementRef
} from "@angular/core";
import {
    NgbModalConfig,
    NgbModal,
    NgbNavChangeEvent,
    NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
import { AlertService, CommonService, PatientHistoryPrintService } from "src/app/services";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { PrintService } from "src/app/services/print.service";
import { MsgConfig } from "src/app/app.messages";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, PatientHistoryE } from "src/app/models";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-print-patient-record",
    templateUrl: "./print-patient-record.component.html"
})
export class PrintPatientRecordComponent
    implements OnInit, AfterViewInit, OnDestroy {
    patientInfo: any;
    modelRef: NgbModalRef;
    InsuFiltersFrmGrp: FormGroup;
    maxDate: { year: number; month: number; day: number };
    today: string;
    patientID: number;
    InsuranceDrops: any[] = [];
    printConfig: any[] = [];
    RxRecordFrmGrp: FormGroup;
    RxDeliveryFrmGrp: FormGroup;
    RxReceiptFrmGrp: FormGroup;
    faxFG: FormGroup;
    ExpReportFrmGrp: FormGroup;
    CopayFrmGrp: FormGroup;
    PatientAddresses: any[] = [];
    patReport: any;
    pdfContent: any;
    report: any;
    disabled = false;
    modelRef2: NgbModalRef;
    modelRef3: NgbModalRef;
    modelRef4: NgbModalRef;
    drugSearchInputValue: any = "";
    prescSearchInputValue: any = "";
    prescriberSearchInputValue: any = "";
    prescriberNameOrFax: any = "";
    okDisabled = false;
    modifiedData: any;
    faxPrefix: number;
    dataToPost: any;
    checkPrescriberOrFax = false;
    rxList: any[] = [];
    PrescRefillIds: any[] = [];
    showErrorMsg: string;
    patientDrugs: any;
    hasFaxNumber: boolean;
    hasPriv: boolean = false;
    unsubscribe$: Subject<void> = new Subject();
    header: any;
    @Input()
    set PatientID(pi: number) {
        this.patientID = pi;
    }

    @Input()
    set PatientInfo(data: any) {
        this.patientInfo = data;
    }

    @Input()
    set RxList(pi: any) {
        if (pi && pi.length > 0) {
            this.rxList = pi.map(elm =>  elm.Prescription.PrescNum + "-" + elm.PrescReFill.ReFillNum +
            (this._commonServ.checkIsPartialFill(elm.PrescReFill, elm.Prescription) ? "-" + elm.PrescReFill.PartialFillNo : ""));
            this.PrescRefillIds = pi.map(elm =>  elm.PrescReFill.Id );
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("PatientRec", { static: true })
    PatientRec: any;

    @ViewChild("PatientRxReport", { static: true })
    PatientRxReport: any;

    @ViewChild("Faxs", { static: true })
    Faxs: any;

    @ViewChild("Missing", { static: true })
    Missing: any;

    selctdTab: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && (this.modelRef || this.modelRef2) && this._modalService.hasOpenModals) { // esc
            if (this.modelRef2) {
                this.modelRef2.close();
                this.modelRef2 = null;
            } else if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
            event.preventDefault();
        }
    }

    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private _commonServ: CommonService,
        private _fb: FormBuilder,
        private _patHistPrintServ: PatientHistoryPrintService,
        private _sanitizer: DomSanitizer,
        private _printServ: PrintService,
        private _prvMskGrd: PrivMaskGuard,private _alertSvc: AlertService
    ) {
        if (this._prvMskGrd.canActivate(AccCategoryE.PatientHistory, PatientHistoryE.PrintPatientRxRecord)) {
            this.hasPriv = true;
            this.selctdTab = "rxRecord";
            this.header = 'Print Patient Rx Record';
        } else {
            this.hasPriv = false;
            this.selctdTab = "rxDelivery";
            this.header = 'Print Rx Delivery Slip';
        }
        config.backdrop = "static";
        this.today = moment().format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.initForms();
        let faxNumberSetting = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Number");
        if (faxNumberSetting && faxNumberSetting.length) {
            this.hasFaxNumber = true;
        } else {
            this.hasFaxNumber = false;
        }
    }

    ngOnInit() {
        this.faxPrefix = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Prefix");
        this.openPrintModal();
        this.InsuFiltersFrmGrp.controls.PatientId.patchValue(this.patientID);
        this.getInsurances();
        this.getPatientAddresses();
        this.getRecordConfig();
        this.getPatientDrugs();
    }
    openPrintModal() {
        this.modelRef = this._modalService.open(this.PatientRec, {
            size: "lg",
            windowClass: "modal-md-Overflow modal-center-to-page",
            keyboard: false
        });
    }

    initForms() {
        this.InsuFiltersFrmGrp = this._fb.group({
            PatientId: [],
            DrugId: [],
            NDC: [],
            PrescriberId: [],
            InsuranceId: [],
            FromDate: [this.today],
            PrescRefillIds: [],
            ToDate: [this.today],
        });
        this.RxRecordFrmGrp = this._fb.group({
            Id: [],
            RxToPrintId: [3],
            IsRxSigPrinted: [false],
            IsRxPricePrinted: [false],
            IsPatientPayAmntPrinted: [false],
            IsPharmacistIniPrinted: [false],
            IsPrescriberAddsPrinted: [false],
            PrescriberIdToPrintId: [1],
            IsCurrentRxOnlyShown: [],
            IsFiledRxShown: [],
            IsAllFamilyMemsShown: []
        });
        this.RxReceiptFrmGrp = this._fb.group({
            Id: [],
            IsPharmacistIniPrinted: [false],
            IsAllFamilyMemsShown: [],
            IsCurrentRxOnlyShown: []
        });
        this.ExpReportFrmGrp = this._fb.group({
            Id: [],
            IsPharmacistIniPrinted: [false],
            IsAllFamilyMemsShown: [],
            IsCurrentRxOnlyShown: []
        });
        this.RxDeliveryFrmGrp = this._fb.group({
            Id: [],
            IsPharmacistIniPrinted: [false],
            IsDrugNmPrinted: [false],
            IsRxBarcodePrinted: [false],
            IsInsuInfoPrinted: [false],
            IsFacilityNmNAddsPrinted: [false],
            IsPrescriberAddsPrinted: [false],
            IsPatientPayAmntPrinted: [false],
            IsAllFamilyMemsShown: [],
            IsCurrentRxOnlyShown: [],
            IsTodaysDtPrinted: [false],
            AddressId: [],
            printRxSerialNum: [false]
        });
        this.CopayFrmGrp = this._fb.group({
            Id: [],
            IsPharmacistIniPrinted: [false],
            ReportCatId: [],
            IsSummaryRepShown: [true],
            IsAllFamilyMemsShown: []
        });
        this.faxFG = this._fb.group( {
            FaxandPrescriber: [0],
            ToFaxNumber: [],
            FaxNumber: [],
            Prescriber: [],
            PrescriberName: [""]
        });
    }

    public beforeChange($event: any,heading) {
        if(!this._prvMskGrd.canActivate(AccCategoryE.PatientHistory, PatientHistoryE.PrintPatientRxRecord)) {
            if($event == "rxRecord") {
                this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);

            }
            else {
                this.selctdTab = $event;
                this.header = heading;
            }
        } else {
            this.selctdTab = $event;
            this.header = heading;
        }
    }

    getPatientDrugs() {
        this._commonServ.getPatientDrugs(this.patientID)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.length) {
                this.patientDrugs = resp.map(elm => {
                    elm["drugFullName"] = elm.Name + (elm.Strength ? " " + elm.Strength : "") + (elm.DrugFormName ? " " + elm.DrugFormName : "");
                    return elm;
                });
            }
        });
    }

    optedDrug(slctdDrug: any) {
        if (slctdDrug && slctdDrug.value) {
            this.InsuFiltersFrmGrp.controls.NDC.patchValue(slctdDrug.value.DrugCode);
        } else {
            this.InsuFiltersFrmGrp.controls.NDC.patchValue(null);
        }
    }

    optedPrescriber(slctdPres: any) {
        if (slctdPres) {
            this.InsuFiltersFrmGrp.controls.PrescriberId.patchValue(
                slctdPres.prescriberid
            );
            // tslint:disable-next-line:max-line-length
            this.prescSearchInputValue = (slctdPres.prescriberlastname ? slctdPres.prescriberlastname : "") + (slctdPres.prescriberfirstname ? ", " + slctdPres.prescriberfirstname : "");
        }
    }

    faxPrescriber(slctdPres: any) {
       this.checkPrescriberOrFax = false;
        if (slctdPres) {
            // tslint:disable-next-line:max-line-length
            this.prescriberSearchInputValue = (slctdPres.prescriberlastname ? slctdPres.prescriberlastname : "") + (slctdPres.prescriberfirstname ? ", " + slctdPres.prescriberfirstname : "");
            this.faxFG.controls.FaxNumber.patchValue(
                slctdPres.faxnumber
            );
        } else {
            this.prescriberSearchInputValue = "";
        }
        this.faxFG.patchValue({
            PrescriberName: this.prescriberSearchInputValue
        }
        );
    }

    onSelectRadioButton(e) {
        this.checkPrescriberOrFax = false;
        this.disabled = this.faxFG.controls["FaxandPrescriber"].value === 0 ? false : true;
        this.prescriberSearchInputValue = "";
        this.faxFG.controls["ToFaxNumber"].patchValue("");
        if (this.faxFG.value.FaxandPrescriber === 0) {
            this.okDisabled = false;
        } else {
            this.okDisabled = true;
        }
    }

    faxNow() {
        if (this.faxFG.value.FaxandPrescriber === 0) {
        if (!this.faxFG.value.PrescriberName) {
            this.checkPrescriberOrFax = true;
            this.showErrorMsg = "Please Select Prescriber";
        } else {
            if (this.faxFG.value.FaxNumber) {
                this.checkPrescriberOrFax = false;
                const postFaxObj = {
                    FaxTo: this.faxFG.value.PrescriberName,
                    FaxFrom: this.patientInfo.lastname + ", " + this.patientInfo.firstname,
                    FaxNumber: this.faxFG.value.FaxNumber,
                    patientDataReportViewModel: this.report,
                    FaxPrefix: this.faxPrefix
                };
                this._patHistPrintServ.postFaxNumber(postFaxObj)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    this._alertService.success("Fax sent Successful.");
                    if (this.modelRef3) {
                        this.modelRef3.close();
                    }
                    this.prescriberSearchInputValue = "";
                    this.faxFG.reset();
                });
            } else {
                this.checkPrescriberOrFax = true;
                // this.checkPrescriberOrFax=false;
                this.showErrorMsg = "FaxNumber is Missing";
            }
        }
      } else {
          this.checkPrescriberOrFax = false;
         // this.faxFG.controls.ToFaxNumber.patchValue(this.faxFG.value.ToFaxNumber);
         this.report = (this.selctdTab === "rxRecord") ? {...this.modifiedData, ...this.dataToPost} : {...this.InsuFiltersFrmGrp.value, ...this.dataToPost};
         const postFaxObj = {
             FaxTo: this.faxFG.value.PrescriberName,
             FaxFrom: this.patientInfo.lastname + ", " + this.patientInfo.firstname,
             FaxNumber: this.faxFG.value.ToFaxNumber,
             patientDataReportViewModel: this.report,
             FaxPrefix: this.faxPrefix
         };
         this._patHistPrintServ.postFaxNumber(postFaxObj)
         .pipe(takeUntil(this.unsubscribe$))
         .subscribe(resp => {
            this._alertService.success("Fax sent Successful.");
            if (this.modelRef3) {
                this.modelRef3.close();
            }
            this.prescriberSearchInputValue = "";
            this.faxFG.reset();
         });
      }
    }

    toFaxNumber(e) {
        if (this.faxFG.controls["ToFaxNumber"].value && this.faxFG.controls["ToFaxNumber"].value.length >= 10) {
            this.okDisabled = false;
        } else {
            this.okDisabled = true;
        }
    }

    closeModalForMissing() {
        if (this.modelRef4) {
            this.modelRef4.close();
        this.modelRef4 = null;
        }
    }

    close() {
        this.checkPrescriberOrFax=false;
        this.prescriberSearchInputValue = "";
        this.faxFG.reset();
        if (this.modelRef3) {
            this.modelRef3.close();
        this.modelRef3 = null;
        }
    }

    getInsurances() {
        this._patHistPrintServ
            .getPatientInsurances(this.patientID)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.InsuranceDrops = resp;
                }
            });
    }

    getPatientAddresses() {
        this._patHistPrintServ
            .getPatientAddresses(this.patientID)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.PatientAddresses = resp;
                }
            });
    }

    getRecords(print?) {
        if (
            this.InsuFiltersFrmGrp.value.FromDate === null ||
            this.InsuFiltersFrmGrp.value.FromDate === undefined ||
            this.InsuFiltersFrmGrp.value.ToDate === null ||
            this.InsuFiltersFrmGrp.value.ToDate === undefined
        ) {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            return;
        }
        // if (this.InsuFiltersFrmGrp.invalid) {
        //     this._alertService.error(MsgConfig.MANDATORY_FIELDS);
        //     return;
        // }
        this.mData();
        if (this.selctdTab === "rxRecord") {
            this._patHistPrintServ
                .getRxReport({ ...this.modifiedData, ...this.dataToPost })
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.handleResponse(resp,print);
                    }
                });
        } else if (this.selctdTab === "rxDelivery") {
            this._patHistPrintServ
                .getRxDeliveryReport({
                   ...this.modifiedData, ...this.dataToPost
                })
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.handleResponse(resp,print);
                    }
                });
        } else if (this.selctdTab === "rxReceipt") {
            this._patHistPrintServ
                .getRxReceiptReport({
                   ...this.modifiedData, ...this.dataToPost
                })
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.handleResponse(resp,print);
                    }
                });
        } else if (this.selctdTab === "copay") {
            this._patHistPrintServ
                .getCopayReport({
                   ...this.modifiedData, ...this.dataToPost
                })
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.handleResponse(resp,print);
                    }
                });
        } else if (this.selctdTab === "expReport") {
            this._patHistPrintServ
                .getRefillExpirationReport({
                    ...this.InsuFiltersFrmGrp.value,
                    ...this.dataToPost
                })
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.handleResponse(resp,print);
                    }
                });
            // this._alertService.error("Need to implement");
        }
    }

    mData() {
        this.dataToPost = this.getFormData();
        this.modifiedData = JSON.parse(JSON.stringify(this.InsuFiltersFrmGrp.value));
        this.modifiedData.FromDate = moment(this.modifiedData.FromDate).format("MM-DD-YYYY");
        this.modifiedData.ToDate = moment(this.modifiedData.ToDate).format("MM-DD-YYYY");
        this.modifiedData["RxNumList"] = (this.rxList && this.rxList.length > 0 && this.selctdTab !== "expReport") ?
         this.rxList.join(",") : null;
        this.modifiedData["PrescRefillIds"] = (this.PrescRefillIds && this.PrescRefillIds.length > 0 && this.selctdTab !== "expReport") ?
         this.PrescRefillIds : null;
    }

    handleResponse(resp,print) {
        if (resp && resp.size > 0 && resp.type === "application/pdf") {
            this.patReport = resp;
            const urlCreator = window.URL;
            this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                urlCreator.createObjectURL(resp) +"#toolbar=0"
            );
            if (print) {
                this.print();
            } else {
                this.preview();
            }
        }
    }

    print() {
        const newBlob = new Blob([this.patReport], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function() {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
            if (this.modelRef2) {
                this.modelRef2.close();
                this.modelRef2 = null;
            }
        }.bind(this);
    }

    getRecordConfig() {
        this._patHistPrintServ.getPrintConfig()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.printConfig = resp;
                if (this.printConfig.find(x => x.ReportCatId === 1)) {
                    this.RxRecordFrmGrp.patchValue(this.printConfig.find(x => x.ReportCatId === 1));
                }
                if (this.printConfig.find(x => x.ReportCatId === 2)) {
                    this.RxReceiptFrmGrp.patchValue(this.printConfig.find(x => x.ReportCatId === 2));
                }
                if (this.printConfig.find(x => x.ReportCatId === 3)) {
                    this.ExpReportFrmGrp.patchValue(this.printConfig.find(x => x.ReportCatId === 3));
                }
                if (this.printConfig.find(x => x.ReportCatId === 4)) {
                    this.RxDeliveryFrmGrp.patchValue(this.printConfig.find(x => x.ReportCatId === 4));
                }
                if (this.printConfig.find(x => x.ReportCatId === 5)) {
                    this.CopayFrmGrp.patchValue(this.printConfig.find(x => x.ReportCatId === 5));
                }
            }
        });
    }

    getFormData(): any {
        let dataToPost: any;
        if (this.selctdTab === "rxRecord") {
            dataToPost = this.RxRecordFrmGrp.value;
            dataToPost["ReportCatId"] = 1;
        } else if (this.selctdTab === "rxDelivery") {
            dataToPost = this.RxDeliveryFrmGrp.value;
            dataToPost["ReportCatId"] = 4;
        } else if (this.selctdTab === "rxReceipt") {
            dataToPost = this.RxReceiptFrmGrp.value;
            dataToPost["ReportCatId"] = 2;
        } else if (this.selctdTab === "copay") {
            dataToPost = this.CopayFrmGrp.value;
            dataToPost["ReportCatId"] = 5;
        } else if (this.selctdTab === "expReport") {
            dataToPost = this.ExpReportFrmGrp.value;
            dataToPost["ReportCatId"] = 3;
        }
        return dataToPost;
    }

    saveConfig() {
        let dataToPost: any;
        // if (this.selctdTab === "rxRecord") {
        //     dataToPost = this.RxRecordFrmGrp.value;
        //     dataToPost["ReportCatId"] = 1;
        // } else if (this.selctdTab === "rxDelivery") {
        //     dataToPost = this.RxDeliveryFrmGrp.value;
        //     dataToPost["ReportCatId"] = 4;
        // } else if (this.selctdTab === "rxReceipt") {
        //     dataToPost = this.RxReceiptFrmGrp.value;
        //     dataToPost["ReportCatId"] = 2;
        // } else if (this.selctdTab === "copay") {
        //     dataToPost = this.CopayFrmGrp.value;
        //     dataToPost["ReportCatId"] = 5;
        // } else if (this.selctdTab === "expReport") {
        //     dataToPost = this.ExpReportFrmGrp.value;
        //     dataToPost["ReportCatId"] = 3;
        // }
        dataToPost = this.getFormData();
        this._patHistPrintServ.savePrintConfig(dataToPost)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this._alertService.success("Default settings saved successfully.");
            } else {
                this._alertService.error("Default settings save unsuccessful.");
            }
        });
    }

    preview() {
        // this.modelRef.close();
        this.modelRef2 = this._modalService.open(this.PatientRxReport, {
            size: "lg",
            keyboard: false
        });
    }

    Fax() {
        this.mData();
        this.report =  {...this.modifiedData, ...this.dataToPost};
        this.faxFG.controls["FaxandPrescriber"].patchValue(0);
        this.okDisabled = false;
        this.disabled = this.faxFG.controls["FaxandPrescriber"].value === 0 ? false : true;
        this.modelRef3 = this._modalService.open(this.Faxs, {centered: true,
            keyboard: false
        });
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    // To manually trigger change detection for the current component
    ngAfterViewInit() {
        this._cdr.detectChanges();
    }
    
    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
