import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "..";
import { Observable ,  BehaviorSubject } from "rxjs";
import { PrescriberStore } from "../store";

@Injectable({
    providedIn: "root"
})
export class EditPrescriberService {
    _prescData$: BehaviorSubject<any>;
    prescData$: Observable<any>;
    constructor(private _http: HttpClient, private _presbUtil: PrescriberStore) {
        this._prescData$ = new BehaviorSubject({}) as BehaviorSubject<any>;
        this.prescData$ = this._prescData$.asObservable();
    }

    sharePrescriberInfo(data: any) {
        this._prescData$.next(data);
    }

    sendPrescriberdata(): Observable<any> {
        return this._prescData$.asObservable();
    }

    getClinicAddressList(
        prescriberId: any
    ): Observable<any> {
        return this._http.get<any>(
            constant.GET_Prescriber_ClinicAddress.replace(
                "{prescriberId}",
                "" + prescriberId
            )
        );
    }

    saveClinicAdress(data: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Prescriber_AddClinicAddress,
            data
        );
    }

    updateClincAddress(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Prescriber_ClinicAddress, data);
    }

    getPrescriberDropDown(): Observable<any> {
        return this._http.get<any>(constant.GET_Prescriber_DropDownData);
    }

    updatePrescriberData(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Prescriber, data);
    }

    setPrimaryClinicAddress(prescId: any, addressId: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Prescriber_Clinic_PrimaryAddress.replace(
                "{prescId}",
                "" + prescId
            ).replace("{addressId}", "" + addressId),
            null
        );
    }

    DeleteClinical(prescId: any, addressId: any): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Prescriber_Clinical_Delete.replace("{addressId}", "" + addressId).replace(
                "{prescId}",
                "" + prescId
            )
        );
    }


    getPrescMMSInfo(data: any): Observable<any> {
        return this._http.post<any>(constant.POST_Prescriber_MMSData, data);
    }

    checkPrescriberExist(npiNumber: any, prescriberId: any, deanum: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Prescriber_CheckNPI.replace(
                "{npiNumber}",
                "" + npiNumber
            ).replace(
                "{deanumber}",
                "" + deanum
            ).replace("{prescriberId}", "" + prescriberId)
        );
    }

    savePrescriberInfo(data: any): Observable<any> {
        return this._http.post(constant.POST_Prescriber, data);
    }

    getPrescriberHistory(
        prescriberId: number,
        pageNumber: number,
        pageSize: number
    ): Observable<any> {
        return this._http.get<any>(
            constant.GET_Prescriber_History.replace(
                "{prescId}",
                "" + prescriberId
            )
                .replace("{startIndex}", "" + pageNumber)
                .replace("{maxRecords}", "" + pageSize)
        );
    }

    getPrescriberSearch(data: any) {
        return this._http.post(constant.GET_Prescriber_Data, data);
    }

    deletePrescriber(Id: any) {
        return this._http.delete(constant.DELETE_Prescriber.replace("{prescriberId}", "" + Id));
    }

    getPrescriberDeleteResponse(prescriberId: any) {
        return this._http.get(constant.GET_PrescriberDeleteResponse.replace("{prescriberId}", "" + prescriberId));
    }

    changePrescriberAddress(prescNum: any,addressId): Observable<any> {
        return this._http.get<any>(
            constant.GET_ChangePrescriberAddress.replace("{prescNum}", "" + prescNum)
                .replace("{addressId}", "" + addressId)
        );
    }
}
