<ng-template #openPopup let-c="close" let-d="dismiss" class="el--popup">
<div class="modal-header">
        <h4 class="modal-title">Send SMS</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-3">
                <eprx-input [LabelText]="'To'"  [ReadOnly]="true"
                [ControlName]="'PatientName'" [FormGroupName]="sendSmsFg">
                </eprx-input>
            </div>
            <div class="col-3">
                <eprx-input [LabelText]="'Mobile#'"  [ReadOnly]="true"
                [ControlName]="'ToNumber'"
                [FormGroupName]="sendSmsFg">
                </eprx-input>
            </div>
            <div class="col-3">
                <eprx-select [LabelText]="'Message Category'"
                [PlaceHolder]="'Message Category'" [ControlName]="'MessageCatId'"
                [FormGroupName]="sendSmsFg" [List]="messageDropdown"
                [BindLabel]="'name'" [BindValue]="'id'" [LabelForId]="'MessageTitle'"
                [IsRequired]="true" (TriggerSelectValue)="patchMsgContent($event ,'category')">
            </eprx-select>
            </div>
            <div class="col-3">
                <eprx-select [LabelText]="'Message Format'"
                [PlaceHolder]="'Message Format'" [ControlName]="'Subject'"
                [FormGroupName]="sendSmsFg" [List]="msgFormatList"
                [BindLabel]="'MessageTitle'" [BindValue]="'MessageTitle'" [LabelForId]="'MessageTitle'"
                [IsRequired]="true" (TriggerSelectValue)="patchMsgContent($event, 'Format')">
            </eprx-select>
            </div>
        </div>
        <div class="col-12 p-0">
            <eprx-text-area [LabelText]="'Message to be sent'" [ControlName]="'Content'" [FormGroupName]="sendSmsFg"
            [PlaceHolder]="''" [Rows]="5" [MaxLength]="900" [RxSelectId]="'sendSms'" [FormGroupInvalid]="formSubmitted" [IsRequired]="true">
        </eprx-text-area>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="sendSMSData()" appShortcutKey
            [AltKey]="'s'"><span>S</span>SEND</button>
        <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'c'"
            (click)="closeModal()"><span>C</span>CANCEL</button>
    </div>
</ng-template>
