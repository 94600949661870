import { Insurances, InsuCards } from "./patient-Info.model";

export class AdditionalInsurance {
    AddPatientInsu: AddPatientInsu;
    GetPatientInsuList: GetPatientInsuList;
    PatientInsuList: PatientInsuList[] = [];
}

export class AddPatientInsu {
    patientId: number = null;
    Insurance: Insurances = null;
    InsuCard: InsuCards = null;
}

export class GetPatientInsuList {
    patientid: number = null;
    PageNumber: number = null;
    PageSize: number = null;
    OrderByColumn: string = null;
    SortDirection: string = null;
}

export class PatientInsuList {
    InsurerCode: string = null;
    PatInsId: number = null;
    InsurerName: string = null;
    InsuranceId: number = null;
    PerNum: string = null;
    InsurancePriority: string = null;
    GroupName: string = null;
    LastName: string = null;
    FirstName: string = null;
    CardExpiry: Date = null;
    IsActive: any = null;
    BinNum: number = null;
    Remarks: string = null;
    IsPatAssigned: any = null;
    RelationName: string = null;
    insucardId: number = null;
    insGroupId: number = null;
    CoPayCatId: number = null;
    RelcatId:  number = null;
    PriorityId: number = null;
    InsurerId: number = null;
    CPBrandVal: number = null;
    CPGenericVal: number = null;
    PolicyNum: number = null;
    CreatedById: number = null;
    CreatedDtTm: Date = null;
    IdProof: any = null;
    IdproofId: any = null;
    InsuType: any = null;
    InsuplanId: number = null;
    Pcn: string = null;
    InsurerActiveStatus: any = null;
}

