import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { constant } from "..";
import { DocumentScanDetails } from "../models";

@Injectable({
  providedIn: "root"
})
export class DMSService {

    constructor(private _http: HttpClient) {}

    processSingleDocument(payload: any): Observable<DocumentScanDetails> {
        return this._http.post<DocumentScanDetails>(
            constant.POST_DMS_PROCESS_IMAGE,
            payload
        );
    }

    getSubcats(): Observable<any> {
        return this._http.get<any>(constant.GET_DMS_SUBCATS);
    }

    saveBatchDocuments(payload): Observable<any>{
        return this._http.post<any>(constant.POST_DMS_SAVE_BATCH_DOCS, payload);
    }

    getQueueDocs(payload) {
        return this._http.post<any>(constant.POST_DMS_DOC_QUEUE_SEARCH, payload);
    }

    getSubCatById(catId): Observable<any[]> {
        return this._http.get<any[]>(constant.GET_SubCat_CatId.replace("{catId}", "" + catId));
    }

    getDocumentById(docId, extn): Observable<any> {
        return this._http.get<any>(constant.GET_DOCUMENT_BY_ID
            .replace("{docId}", "" + docId)
            .replace("{extn}", "" + extn));
    }

    importDocs(payload:any): Observable<any> {
        return this._http.post<any>(constant.POST_IMPORT_DOCS_TO_QUEUE,payload);
    }

    deleteDocs(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_DELETE_DOCS_FROM_QUEUE, payload);
    }

    updateDocument(payload: any): Observable<any> {
        return this._http.put<any>(constant.PUT_UPDATE_DOCS_IN_QUEUE, payload);
    }

    attachDocToEntity(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_ATTACH_DOCS_TO_ENTITY, payload);
    }

    markDocs(payload: any): Observable<any> {
        return this._http.put<any>(constant.PUT_MARK_DOCS_TO_USER, payload);
    }

    attachDocToRxFromDocQueue(dataTOPost: any): Observable<any>  {
        return this._http.post<any>(constant.POST_ATTACH_RX_FROM_QUEUE, dataTOPost);
    }

    getSubCategSeq() : Observable<any> {
        return this._http.get<any>(constant.GET_DMS_SUBCAT_SEQ);
    }

    saveSubCategSeq(payload) : Observable<any> {
        return this._http.post<any>(constant.POST_DMS_SUBCAT_SEQ,payload);
    }

    untagList(payload) {
        return this._http.post<any>(constant.POST_DMS_UNTAGLIST,payload);
    }

}
