<ng-template #actionHistory let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{modalHeader}}</h4>
        <button type="button" [tabIndex]="-1" class="close" aria-label="Close" (click)="d('Cross click'); closePopup(false)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-0 pb-0">
        <div class="row">
            <div class="col-12">
                    <div class="eprx--block__content">
                            <div class="exprx--common-block">
                                <div class="eprx--block__header ">
                                    <div class="row">
                                        <div class="col">
                                            <span class="eprx--header__heading">Patient</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-3">
                                            <eprx-input [LabelText]="'Patient'" [PlaceHolder]="'Patient'" [HasControl]="false"
                                                [InputValue]="rxInfo?.Patient?.lastname + ', ' + rxInfo?.Patient?.firstname
                                                | uppercase" [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-3">
                                            <eprx-input [LabelText]="'DOB'" [PlaceHolder]="'DOB'" [HasControl]="false"
                                            [InputValue]="rxInfo?.Patient?.dob ?
                                            (rxInfo?.Patient?.dob | date:'MM/dd/yyyy') :'--'"
                                            [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-6">
                                            <eprx-input [LabelText]="'Address'" [PlaceHolder]="'Address'" [HasControl]="false"
                                            [InputValue]="address" [IsDisabled]="true">
                                        </eprx-input>
                                        </div>
                                        <div class="col-3">
                                            <eprx-input [LabelText]="'Insurance Id'" [PlaceHolder]="'Insurance Id'" [HasControl]="false"
                                            [InputValue]="rxInfo?.Patient?.primeinsupolicyno ?
                                            rxInfo?.Patient?.primeinsupolicyno : '--'"
                                            [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-3">
                                            <eprx-input [LabelText]="'Gender'" [PlaceHolder]="'Gender'" [HasControl]="false"
                                            [InputValue]="rxInfo?.Patient?.gender ? rxInfo?.Patient?.gender : '--'"
                                            [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-6">
                                            <eprx-input [LabelText]="'Allergies'" [PlaceHolder]="'Allergies'" [HasControl]="false"
                                            [InputValue]="patientAl" [IsDisabled]="true">
                                        </eprx-input>
                                        </div>
                                        <div class="col-3">
                                            <eprx-input [LabelText]="'Phone#'" [PlaceHolder]="'Phone#'" [HasControl]="false"
                                            [InputValue]="(rxInfo?.Patient?.telephone && rxInfo?.Patient?.telephone !== '###') ?
                                            (rxInfo?.Patient?.telephone | mask:'(000)000-000000000'):''" [IsDisabled]="true">
                                        </eprx-input>
                                        </div>
                                        <div class="col-3">
                                            <eprx-input [LabelText]="'Insurance'" [PlaceHolder]="'Insurance'" [HasControl]="false"
                                                [InputValue]="insData?.InsurerCode ? insData?.InsurerCode : '--'" [IsDisabled]="true">
                                            </eprx-input>
                                        </div>
                                        <div class="col-6">
                                            <eprx-input [LabelText]="'Notes'" [PlaceHolder]="'Notes'" [HasControl]="false"
                                            [InputValue]="rxInfo?.Patient?.notes" [IsDisabled]="true">
                                        </eprx-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <div class="grp-tran-pat-hist">
                                        <app-rx-history *ngIf="activeModal" [ReviewXDays]="ReviewXDays" [MultiCheck]="true" [ShowCheckBox]="true" [ActiveScreen]="'Rph'" [showForAll]="showForAllActns" [ShowDue]="showDue" [clearcheckbox]="clearcheckbox"
                                    [PatientId]="patientId"  [FromRphModal]="true" 
                                    (emitRxInfo)="getSelectedRxs($event)" (EmitPatientHistory)="emitPatienthistory($event)" (CloseHistModal)="closeActnScreen()"></app-rx-history>
                                    </div>
                                </div>
                            </div>
                    </div>
        </div>
    </div>
    </div>
    <div class="modal-footer pt-0">
        <div class="text-right pull-right col-md-12 padding-0" *ngIf="hasButtons">
                <button class="hotkey_success" (click)="printRefillReqAndErx('sendErx')"  appShortcutKey
                [AltKey]="'s'"><span>s</span>Send E-Refill Request</button>
                <button class="hotkey_success" (click)="printRefillReqAndErx('printRefillReq')"  appShortcutKey
                [AltKey]="'p'"><span>p</span>Print Refill Request</button>
                <button class="hotkey_success" (click)="d('Cross click'); closePopup(false)"
                (keydown.enter)="d('Cross click');" appShortcutKey
                [AltKey]="'c'"><span>C</span>Cancel</button>
        </div>
        <div class="text-right pull-right col-md-12 padding-0" *ngIf="!hasButtons">
            <button class="hotkey_success" (click)="d('Cross click'); closePopup(false)"
                (keydown.enter)="d('Cross click');" appShortcutKey
                [AltKey]="'c'"><span>C</span>Cancel</button>
        </div>
    </div>
</ng-template>

<app-send-efill-request *ngIf="openFor === 'sendErx'" [FaxInfo]="printRefWJ" [selectedRx]="selectedRxs"
[RxInfo]="selectedRxForReq"  (IsPopUpClosed)="closeSendRefillReq()"></app-send-efill-request>
<app-print-rx-form *ngIf="openFor === 'printRefillReq'"
 [RxInfo]="selectedRxs" [ModalType]="openFor === 'printRefillReq'" (IsPopUpClosed)="closePrintRefill($event)"></app-print-rx-form>
