
<div class="modal-header">
    <h4 class="modal-title">Eligibility Check</h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePopUp()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Patient Information</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-3">
                            <label>Name</label>
                            <div class="label--data">{{patientInfoFG?.value?.Person?.LastName | uppercase}},&nbsp;
                                {{patientInfoFG?.value?.Person?.FirstName | uppercase}}</div>
                        </div>
                        <div class="col-3">
                            <label>DOB</label>
                            <div class="label--data">{{patientInfoFG?.value?.Person?.BirthDt | date : 'MM/dd/yyyy'}}
                            </div>
                        </div>
                        <div class="col-3">
                            <label>Gender</label>
                            <div class="label--data">
                                {{patientInfoFG?.value?.Person?.GenderId ? ((patientInfoFG?.value?.Person?.GenderId===2) ? "MALE" : (patientInfoFG?.value?.Person?.GenderId==3) ? "FEMALE" : "UNKNOWN"):"&nbsp;"}}
                            </div>
                        </div>
                        <div class="col-3">
                            <label>Primary Insurance</label>
                            <div class="label--data">{{insuranceSelected?.insurancename | uppercase}}</div>
                        </div>
                        <div class="col-6">
                            <label>Address</label>
                            <div class="label--data">
                                {{patientInfoFG?.value?.Addresses?.AddressLine1?patientInfoFG?.value?.Addresses?.AddressLine1+',':''}}
                                {{patientInfoFG?.value?.Addresses?.AddressLine2?patientInfoFG?.value?.Addresses?.AddressLine2+',':''}}
                                {{patientInfoFG?.value?.Addresses?.CityName?patientInfoFG?.value?.Addresses?.CityName+',':''}}
                                {{patientInfoFG?.value?.Addresses?.StateName?patientInfoFG?.value?.Addresses?.StateName+',':''}} {{patientInfoFG?.value?.Addresses?.ZipCode?patientInfoFG?.value?.Addresses?.ZipCode
                                                                            : ''}}
                            </div>
                        </div>
                        <div class="col-6">
                            <label>Primary Ins. Ids on File</label>
                            <div>
                                <span class="mr-1" *ngFor="let pat of patientFilteredInsus; let i = index">
                                    <span class="label--data" (click)="changeInsurance(pat)" *ngIf="pat?.PolicyNum">
                                        {{i+1}})   {{pat?.PolicyNum}} </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Insurance To Send Eligibility</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-6">
                            <eprx-select [AutoFocus]="true" [LabelText]="'Insurance'" [ControlName]="'insuranceId'" [FormGroupName]="insuTransmitFG"
                                [PlaceHolder]="'Insurance'" [BindLabel]="'InsurerCode'" [BindLabel2]="'insurancename'" [BindLabel3]="'BINNum'" [LabelTitle1]="'CODE'"
                                [LabelTitle2]="'NAME'" [LabelTitle3]="'BIN'" [BindValue]="'insurerid'" [LabelForId]="'InsuranceId'" [List]="eligibilityInurances"
                                [hasBindLabel3]="true"  [IsFirstElem]="true" [Show2Values]="true" (TriggerSelectValue)="selectedInsType($event?.value)"
                                [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"  [MarkAsTouched]="insuTransmitFG?.get('insuranceId')?.touched"></eprx-select>
                        </div>
                        <div class="col-md-3">
                            <eprx-input [LabelText]="'Policy Number'" [PlaceHolder]="'Policy Number'"
                                [ControlName]="'policyNum'" [FormGroupName]="insuTransmitFG"
                                [ErrorDefs]="{pattern: 'Invalid!', required: 'Required'}" [ValidPattern]="regex?.AlphaNumeric"
                                [IsRequired]="true" [MarkAsTouched]="insuTransmitFG?.get('policyNum')?.touched">
                            </eprx-input>
                        </div>
                        <div class="col-md-3">
                            <eprx-date-picker [LabelText]="'Service Date'" [PlaceHolder]="'Service Date'"
                                [ControlName]="'dateOfService'" [FormGroupName]="insuTransmitFG" [RxSelectId]="'serviceData'"
                                >
                            </eprx-date-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12" *ngIf="hasData">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="row">
                        <div class="eprx--header__heading col-10">
                            Transmission Result
                        </div>
                      <div class="col-1">
                        <span class="font_size_1_7rem color_white left_8rem margin_left_9rem" triggers="mouseover:mouseleave" [ngbTooltip]="eligibilityCheckTooltip" placement="left" container="body" tooltipClass="eligibilityCheck--tooltip">
                            <i class="fal fa-poll-h  actions" aria-hidden="true"></i>
                            <ng-template #eligibilityCheckTooltip>
                                <div class="text-left">
                                    <table class="table-sm w-100 eligibility_Table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Insurance Name
                                            </th>
                                            <th>
                                                Result
                                            </th>
                                            <th>
                                                BIN#
                                            </th>
                                            <th>
                                                PCN#
                                            </th>
                                            <th>
                                                Rejected Reason
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <tr *ngIf="troopStatus && eligibilityInurances?.length > 1">
                                                <td>{{eligibilityInurances[0]?.insurancename
                                                    ? eligibilityInurances[0]?.insurancename
                                                    : '--'}}</td>
                                                <td [ngClass]="(troopStatus?.Result == 'R') ? 'color_red fw-700' : 'color_green fw-700'" >{{troopStatus?.Result == 'A' ? 'Approved' : (troopStatus?.Result == 'R' ? 'Rejected' : '')}}</td>
                                                <td>{{eligibilityInurances[0]?.BINNum ? eligibilityInurances[0]?.BINNum : ''}}</td>
                                                <td>{{eligibilityInurances[0]?.ProcessorNum ? eligibilityInurances[0]?.ProcessorNum : ''}}</td>
                                                <td>{{troopStatus?.Result == 'R' ? troopStatus?.MessageF4 : ''}}</td>
                                            </tr>
                                            <tr *ngIf="CommercialElgStatus && eligibilityInurances?.length > 1">
                                                <td>{{eligibilityInurances[1]?.insurancename
                                                    ? eligibilityInurances[1]?.insurancename
                                                    : '--'}}</td>
                                                <td [ngClass]="(CommercialElgStatus?.Result == 'R') ? 'color_red fw-700' : 'color_green fw-700'" >{{CommercialElgStatus?.Result == 'A' ? 'Approved' : (CommercialElgStatus?.Result == 'R' ? 'Rejected' : '')}}</td>
                                                <td>{{eligibilityInurances[1]?.BINNum ? eligibilityInurances[1]?.BINNum : ''}}</td>
                                                <td>{{eligibilityInurances[1]?.ProcessorNum ? eligibilityInurances[1]?.ProcessorNum : ''}}</td>
                                                <td>{{CommercialElgStatus?.Result == 'R' ? CommercialElgStatus?.MessageF4 : ''}}</td>
                                            </tr>

                                            <tr  *ngIf="eligibilityInurances?.length == 1">
                                                <td>{{eligibilityInurances[0]?.insurancename
                                                    ? eligibilityInurances[0]?.insurancename
                                                    : '--'}}</td>
                                                <td [ngClass]="(eligibilityInurances[0].IsTroop ? (troopStatus?.Result == 'R') : (CommercialElgStatus?.Result == 'R')) ? 'color_red fw-700' : 'color_green fw-700'" >{{eligibilityInurances[0].IsTroop ? (troopStatus?.Result == 'A' ? 'Approved' : (troopStatus?.Result == 'R' ? 'Rejected' : '')) : (CommercialElgStatus?.Result == 'A' ? 'Approved' : (CommercialElgStatus?.Result == 'R' ? 'Rejected' : ''))}}</td>
                                                <td>{{eligibilityInurances[0]?.BINNum ? eligibilityInurances[0]?.BINNum : ''}}</td>
                                                <td>{{eligibilityInurances[0]?.ProcessorNum ? eligibilityInurances[0]?.ProcessorNum : ''}}</td>
                                                <td>{{eligibilityInurances[0].IsTroop ? (troopStatus?.Result == 'R' ? troopStatus?.MessageF4 : '') : (CommercialElgStatus?.Result == 'R' ? CommercialElgStatus?.MessageF4 : '') }}</td>
                                            </tr>
                                    </tbody>
                                </table>
                                </div>
                            </ng-template>
                        </span>
                    </div>
                    
                    </div>
                </div>
               
                <div class="eprx--block__content">
                    <div *ngIf="hasData">
                        <div class="row" *ngIf="!hasErrorMessage">
                            <div class="col-12">
                                <h4>Transmission Result  </h4>
                            </div>
                            <div class="col-12 font-weight-bold ornage_brand">
                                {{((CommercialElgStatus?.Result == 'R' && troopStatus?.Result ==  'R') ? MsgConfig?.isRejectedEligiblity : ((CommercialElgStatus?.Result == 'A' || troopStatus?.Result ==  'A') ? MsgConfig?.isApprovedEligiblity : ((cashInsurance && !troopStatus && CommercialElgStatus?.Result == 'R') ? MsgConfig?.isCashRejectedEligiblity:MsgConfig?.isApprovedEligiblity)))}}
                            </div>
                            <div class="col-md-12" *ngIf="status === 'ACCEPTED' || (!troopStatus)">
                                <div class="row">
                                    <div class="col" [ngClass]="(CommercialElgStatus?.Result === 'R') ? 'color_red' : 'color_green'">
                                        <label>Result</label>
                                        <div class="label--data"><strong>{{ (CommercialElgStatus?.Result === 'R') ? 'REJECTED': 'APPROVED'}}</strong></div>
                                    </div>
                                  <div class="col" >
                                        <label>Date</label>
                                        <div class="label--data">{{resultDate}}</div>
                                    </div>
                                    <div class="col">
                                        <label>NPI#</label>
                                        <div class="label--data">{{transmitResult?.NPI}}</div>
                                    </div> 
                                    <div class="col px-0">
                                            <label>BIN#:</label>
                                            <div class="label--data">{{troopStatus?.Result ==  'A' ? eligibilityInurances[0]?.BINNum : eligibilityInurances[1]?.BINNum}}</div>
                                    </div>
                                    <div class="col">
                                            <label>PCN#:</label> 
                                            <div class="label--data">{{troopStatus?.Result ==  'A' ? eligibilityInurances[0]?.ProcessorNum : eligibilityInurances[1]?.ProcessorNum}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row" *ngIf="hasResponse">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col" *ngIf="status === 'REJECTED' && !(cashInsurance && !troopStatus)">
                                        <label>Date</label>
                                        <div class="label--data">{{resultDate}}</div>
                                    </div>
                                    <div class="col">
                                        <label>Eligibility Response</label>
                                        <div *ngIf="status === 'ACCEPTED'">
                                            <div class="label--data">Other Insurance Information(526 FQ)</div>
                                            <wj-flex-grid [headersVisibility]="'Column'" [itemsSource]="resultAcceptWJ"
                                                [isReadOnly]="true" [columnPicker]="''">
                                                <wj-flex-grid-column [header]="' '" [width]="30" [binding]="'SRNO'">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'INS. ORD'" [binding]="'INSORD'" [width]="80">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'PAY ID Q'" [binding]="'PAYIDQ'" [width]="70">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'BIN'" [binding]="'BN'" [width]="75">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'PROCESSOR CTRL'" [binding]="'PN'" [width]="140">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'PATIENT ID'" [binding]="'ID'" [width]="100">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'GROUP#'" [binding]="'GP'" [width]="75">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'PERSON CODE'" [binding]="'PC'" [width]="130">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'PHONE#'" [binding]="'PH'" [width]="100">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'RELATION'" [binding]="'RELATIONCD'" [width]="90">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'EFFECTIVE DATE'" [binding]="'EFFECTIVEDATE'"
                                                    [width]="140">
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="'INSU CODE'" [binding]="'INSUCODE'"
                                                    [width]="100">
                                                </wj-flex-grid-column>
                                            </wj-flex-grid>
                                        </div>
                                        <div *ngIf="status === 'REJECTED'">
                                            <div class="label--data">Patient and Part D/CommE1 Plan Info.(504-F4)</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row" 
                        *ngIf="CommercialElgStatus?.Result == 'R' && troopStatus?.Result ==  'R' && hasMessage">
                            <div class="col-6"><label>Troop Eligibility  Result: <span class="color_red">REJECTED</span></label></div>
                            <div class="col-6 mb-2"><label>Commercial Eligibility Result: <span class="color_red">REJECTED</span></label></div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col"><label>NPI#:</label> &nbsp;<span class="label--data">{{troopStatus?.NPI}}</span></div>
                                    <div class="col px-0"><label>BIN#:</label> &nbsp;<span class="label--data">{{eligibilityInurances[0]?.BINNum}}</span></div>
                                    <div class="col"><label>PCN#:</label> &nbsp;<span class="label--data">{{eligibilityInurances[0]?.ProcessorNum}}</span></div>
                                </div>
                            </div>
                            <div class="col-6 elgliblity_border">
                              <div class="row">
                                <div class="col"><label>NPI#:</label> &nbsp;<span class="label--data">{{CommercialElgStatus?.NPI}}</span></div>
                                <div class="col px-0"><label>BIN#:</label> &nbsp;<span class="label--data">{{eligibilityInurances[1]?.BINNum}}</span></div>
                                <div class="col"><label>PCN#:</label> &nbsp;<span class="label--data">{{eligibilityInurances[1]?.ProcessorNum}}</span></div>
                              </div>
                            </div>
                            <div   class="col-6" >
                                <label>Message</label>
                                <div class="label--data">MessageF4 Info</div>
                                <div class="label--data">{{troopStatus?.MessageF4}}</div>
                            </div>
                            <div class="col-6 elgliblity_border">
                                <label>Message</label>
                                <div class="label--data">MessageF4 Info</div>
                                <div class="label--data">{{CommercialElgStatus?.MessageF4}}</div>
                            </div>
                        </div>
                        <div class="row" *ngIf="((CommercialElgStatus?.Result == 'A' || troopStatus?.Result ==  'A' || (cashInsurance && !troopStatus)) || (CommercialElgStatus?.Result == 'R' && !troopStatus))">
                            <div class="col-6"  *ngIf="hasMessage" >
                                <label>Message</label>
                                <div class="label--data">MessageF4 Info</div>
                                <div class="label--data">{{msgF4Info}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="text-right pull-right col-md-12 padding-0">
            <button type="button" class="hotkey_success" (click)="updateTransDetails('',true)" appShortcutKey [AltKey]="'m'"><span>M</span> Transmit</button>
            <button *ngIf="!enableUpdate" type="button" class="inactive">UPDATE PAT INFO</button>
            <button *ngIf="enableUpdate" type="button" class="hotkey_success" (click)="updatePatInfo()" appShortcutKey [AltKey]="'u'"><span>U</span>
                UPDATE PAT INFO</button>
            <button type="button" class="hotkey_success mr-3" (click)="closePopUp()" appShortcutKey [AltKey]="'c'"><span>C</span>Close</button>
        </div>
    </div>
</div>


<ng-template #insuranceNotExists let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click');cancelAddInsu()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>The Insurance for Bin# {{ InsuPopUpdata[0] }} and processor# {{ InsuPopUpdata[2] }} was not found. Would you like to add?</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus class="hotkey_primary" (click)="c('Close click')" (click)="processNextInsurance()" appShortcutKey InputKey="y">
          <b>Y</b>  YES
        </button>
        <button type="button" autofocus class="hotkey_primary" (click)="c('Close click');cancelAddInsu()" appShortcutKey InputKey="n">
            <b>N</b>  NO
          </button>
    </div>
</ng-template>


<ng-template #insuAddedToPatient let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Success</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click');cancelAddInsu()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <label>Insurance Added to patient.</label>
                <label *ngIf="insurancesToProcess.length > 0">The Insurance for BIN {{ InsuPopUpdata[0]}} and processor# {{ InsuPopUpdata[2]}} was not found. Would you like to add?</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus class="hotkey_primary" (click)="c('Close click')" (click)="processNextInsurance()" appShortcutKey InputKey="o">
          <b>O</b>  OK
        </button>
        <button *ngIf="insurancesToProcess.length > 0" type="button" class="hotkey_primary" (click)="c('Close click')" (click)="cancelAddInsu()" appShortcutKey InputKey="n">
            <b>N</b>  NO
          </button>
    </div>
</ng-template>
