import { Address, Phone, InsuCards, InsuGroups } from "src/app/models/patient-Info.model";
import { Insurance } from "src/app/models/system-data.model";

export class PatientFamily {
    PatFamily: PatFamily[] = [];
    FamilyAddress: Address[] = [];
    FamilyPhone: Phone[] = [];
    FamilyInsuCard: InsuCards[] = [];
    FamilyInsurance: Insurance[] = [];
    FamilyInsuGrp: InsuGroups[] = [];
    FamilyInsuCarrier: FamilyInsuCarrier[] = [];
}

export class GetFamily {
    name: string = null;
    address1: string = null;
    address2: string = null;
    state: string = null;
    city: string = null;
    zipCode: string = null;
    phone: string = null;
    policyNum: string = null;
    insuranceName: string = null;
    sortColumn:string = null;
    sortOrder: string = null;
    firstRow: number = null;
    lastRow: number = null;
}

export class PatFamily {
    Id: number = null;
    TenantId: number = null;
    FamilyInsuId: number = null;
    FamilyAddsId: number = null;
    FamilyPhId: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: Date = null;
}


export class PatientMessageSettings {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
  SendGeneralAlerts: boolean = null;
  SendRefillReminderAlerts: boolean = null;
  SendBirthdayGreetingAlerts: boolean = null;
  SendReadyForPickupAlerts: boolean = null;
  SendNewRxMessageAlerts: boolean = null;
  CreatedDtTm: string = null;
  ModifiedDtTm: Date = null;
  SubscriptionResponse: any = null;
  SubscriptionResponseDtTm: any = null;
}

export class ExistngFamily {
    PatientId: number = null;
    LastName: string = null;
    FirstName: string = null;
    Phone: any = null;
}

export class FamilyInsuCarrier {
    BINNum: string = null;
    Id: number = null;
    InsurerCode: string = null;
    Remarks: string = null;
    TenantId: number = null;
}

export class LinkFamily {
    PatientId: number = null;
    FamilyId: number = null;
    IsLastNameUpdate: boolean = null;
    IsOverrideGeneralDetails: boolean = null;
    IsOverrideInsuranceDetails: boolean = null;
}

