export class DosageSchedule {
    DoseNum: number = null;
    DoseQty: number = null;
    DoseSlot: number = null;
    DoseTime: string = null;
    DoseTimeDesc: string = null;
    Id: number = null;
    CreatedDtTm: string = null;
    IsActive: boolean = true;
}

export const DosageSlotTime = [
    {
        TimeToShow: "12.00AM",
        EqlTime: "00:00:00"
    },
    {
        TimeToShow: "12.45AM",
        EqlTime: "00:00:45"
    },
    {
        TimeToShow: "1.30AM",
        EqlTime: "01:30:00"
    },
    {
        TimeToShow: "2.15AM",
        EqlTime: "02:15:00"
    },
    {
        TimeToShow: "3.00AM",
        EqlTime: "03:00:00"
    },
    {
        TimeToShow: "3.45AM",
        EqlTime: "03.45:00"
    },
    {
        TimeToShow: "4.30AM",
        EqlTime: "04.30:00"
    },
    {
        TimeToShow: "5.15AM",
        EqlTime: "05:15:00"
    },
    {
        TimeToShow: "6.00AM",
        EqlTime: "06:00:00"
    },
    {
        TimeToShow: "6.45AM",
        EqlTime: "06:45:00"
    },
    {
        TimeToShow: "7.30AM",
        EqlTime: "07:30:00"
    },
    {
        TimeToShow: "8.15AM",
        EqlTime: "08:15:00"
    },
    {
        TimeToShow: "9.00AM",
        EqlTime: "09:00:00"
    },
    {
        TimeToShow: "9.45AM",
        EqlTime: "09:45:00"
    },
    {
        TimeToShow: "10.30AM",
        EqlTime: "10:30:00"
    },
    {
        TimeToShow: "11.15AM",
        EqlTime: "11:15:00"
    },
    {
        TimeToShow: "12.00PM",
        EqlTime: "12:00:00"
    },
    {
        TimeToShow: "12.45PM",
        EqlTime: "12:45:00"
    },
    {
        TimeToShow: "1.30PM",
        EqlTime: "13:30:00"
    },
    {
        TimeToShow: "2.15PM",
        EqlTime: "14:15:00"
    },
    {
        TimeToShow: "3.00PM",
        EqlTime: "15:00:00"
    },
    {
        TimeToShow: "3.45PM",
        EqlTime: "15:45:00"
    },
    {
        TimeToShow: "4.30PM",
        EqlTime: "16:30:00"
    },
    {
        TimeToShow: "5.15PM",
        EqlTime: "17:15:00"
    },
    {
        TimeToShow: "6.00PM",
        EqlTime: "18:00:00"
    },
    {
        TimeToShow: "6.45PM",
        EqlTime: "18:45:00"
    },
    {
        TimeToShow: "7.30PM",
        EqlTime: "19:30:00"
    },
    {
        TimeToShow: "8.15PM",
        EqlTime: "20:15:00"
    },
    {
        TimeToShow: "9.00PM",
        EqlTime: "21:00:00"
    },
    {
        TimeToShow: "9.45PM",
        EqlTime: "21:45:00"
    },
    {
        TimeToShow: "10.30PM",
        EqlTime: "22:30:00"
    },
    {
        TimeToShow: "11.15PM",
        EqlTime: "23:15:00"
    }
];
