<app-prescriber-tabs *ngIf="currTab" [resetToFirst]="resetToFirst" (addPrescriberData)="savePrescriber($event)" [prescFG]="prescriberFG" [presbId]="prescriberID" [prescriberInfo]="prescbInfo$ | async" (ActivePrescriberTab)="selectedPrescrTab($event)">
</app-prescriber-tabs>

<div class="editpatient--body newrx--body edit--drug insurance--body ">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="row">
                    <div class="icon">
                        <span class="prescriber-icon">
                            <i class="far fa-user-md"></i>
                        </span>
                    </div>
                    <div class="content--heading__title" *ngIf="prescriberID">
                        {{headerData[TabIds[currTab]]}} |
                        <span class="content--name">
                            {{
                            (prescbInfo$ | async)?.Person?.LastName | uppercase
                            }},&nbsp;
                            {{ (prescbInfo$ | async)?.Person?.FirstName | uppercase }}
                        </span>
                    </div>
                    <div class="content--heading__title" *ngIf="!prescriberID">
                        {{headerData[TabIds[currTab]]}} |
                        <span class="content--name"> Add New Prescriber</span>
                    </div>
                </div>
            </div>
            <div class="col-md-2 edit-patient--group--icon padding-0">
                <div class="modal-icon">
                    <div class="insurance--group--icon padding-0">
                        <app-prescriber-mms [prescriberId]="prescriberID" [IsFromGlobalSearch]="IsFromGlobalSearch" [isNPI]="isNPI" [openMMS]="openMMS" (addMMSData)="selectedFrmRef()"
                        [prescriberInfo]="prescbInfo$ | async" [prescriberFG]="prescriberFG">
                        </app-prescriber-mms>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-2 padding-0">
                <div>
                    <button class="hotkey_success" id="SaveButton"
                        title="Alt+S" appShortcutKey [AltKey]="'s'" (click)="CheckPrescTypeNPI()">
                        <span>S</span>
                        Save
                    </button>
                    <button class="hotkey_success mr-0" title="Alt+C" appShortcutKey [AltKey]="'c'"
                         (click)="onCancel('cancel')">
                        <span>C</span> Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div [ngSwitch]="currTab">
        <div *ngSwitchCase="tabEnum?.Patient1">
            <app-prescriber-info [prescriberId]="prescriberID" (CloseModal)="closeModal($event)"
                [systemData]="systemData$ | async" [prescriberInfo]="prescbInfo$ | async" [prescriberFG]="prescriberFG"
             [focusOnDea]="FocusOnDea"   [formGroupInvalid]="fGInvalid" [PrescDropValues]="prescdropValues" [DropDownValues]="facilitiesList"></app-prescriber-info>
        </div>
        <div *ngSwitchCase="tabEnum?.Patient2">
            <app-document-ref [documentFr]="{From: 'Prescriber', Id: this.prescriberID}" (RefreshParentEntity)="getPrescriberInfo('Patient2',true)"></app-document-ref>
        </div>
        <div *ngSwitchCase="tabEnum?.Patient3">
            <app-clinic-address [PrescFG]="prescriberFG" [PrescriberID]="prescriberID" [IsPopUp]="false"></app-clinic-address>
        </div>
        <div *ngSwitchCase="tabEnum?.Patient4">
            <app-prescriber-history [prescriberId]="prescriberID" [prescriberFG]="prescriberFG"></app-prescriber-history>
        </div>
        <div *ngSwitchCase="tabEnum?.Patient5">
            <app-notes-log [PrescriberInfo]="prescbInfo$ | async" [NotesFr]="'Prescriber'"></app-notes-log>
        </div>
    </div>
</div>
<ng-template #warningInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <span>{{ warningMsg }}</span>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus class="hotkey_primary" (click)="resetPrescTabs(warnId);d('Cross click')" appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </div>
</ng-template>
<app-cancel-pop-up *ngIf="popUpType === 'cancel'" (IsPopUpClosed)="onCancel($event)"></app-cancel-pop-up>
