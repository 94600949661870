import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    HostListener
} from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import { EditDrugService } from "../../../services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-drug-indication",
    templateUrl: "./drug-indication.component.html",
    styles: []
})
export class DrugIndicationComponent implements OnInit {
    @Input()
    set DrugID(di: number) {
        if (di) {
            this.drugID = di;
            this.getDrugIndication();
        }
    }

    @Output()
    CloseDrugIndicationPopUp = new EventEmitter<{}>();

    @ViewChild("DRUGINDICATION", { static: true })
    DRUGINDICATION: any;

    drugID: number;
    modelRef: any;
    indicationWJ: CollectionView;
    cautionWJ: CollectionView;
    cautionHeader: string[];
    drugIndication: any;
    unsubscribe$: Subject<void> = new Subject();
    isIndictnExists : boolean = false;
    isCautionExists : boolean = false;
    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }
    }


    constructor(
        private _editDrugService: EditDrugService,
        private _modalService: NgbModal
    ) {}

    ngOnInit() {}

    getDrugIndication() {
        this._editDrugService
            .getDrugCodes(this.drugID, true)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.drugIndication = resp;
                    this.isIndictnExists = this.drugIndication && this.drugIndication.Indications && this.drugIndication.Indications.length ? true : false;
                    this.isCautionExists = this.drugIndication && this.drugIndication.Caution && this.drugIndication.Caution.length ? true : false;
                    this.generateWJ(resp);
                    this.modelRef = this._modalService.open(
                        this.DRUGINDICATION,
                        {
                            size: "lg",
                            keyboard:false,
                            centered : true
                        }
                    );
                }
            });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
       }

    generateWJ(indiInfo: any) {
        this.indicationWJ = new CollectionView(
            indiInfo.Indications.map((patient, i) => {
                const j = {};
                j["Description"] = patient.IndDescription;
                return j;
            })
        );
        this.cautionWJ = new CollectionView(
            indiInfo.Caution.map((drug, i) => {
                const j = {};
                j["CAUTION"] = drug.AVOIDCAUTION.split(".");
                j["EVIDENCE QUALITY"] = drug.QUALITYOFEVIDENCETEXT;
                j["RECOMMENDATION"] = drug.STRENGTHOFRECOMMENDATIONTEXT;
                return j;
            })
        );
        this.cautionHeader = ["CAUTION", "EVIDENCE QUALITY", "RECOMMENDATION"];
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].height = 35;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    closeDrugIndicationPopUp() {
        if (this.modelRef) {
            this.modelRef.close();
            }
        this.CloseDrugIndicationPopUp.emit();
    }
}
