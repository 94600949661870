import { Component, OnInit, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NRxUtils } from "src/app/utils";
import { TransmissionService, PatPreDrugModalService, CommonService, InsuranceService } from "src/app/services";
import "rxjs/add/operator/delay";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { RxModel } from "src/app/models";
import { RxStore } from "src/app/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
// import { EditPatientComponent } from "src/app/modules/edit-patient";

@Component({
    selector: "app-tr-rx-ppdi-route",
    templateUrl: "./tr-rx-ppdi-route.component.html"
})
export class TrRxPpdiRouteComponent implements OnInit {
    @Input() transmResp: any;
    @Input() transmissionType: any;
    @Input() rxInfo: RxModel;

    @Output()
    PatPrescDrugInsModalType = new EventEmitter<any>();
    categoryId: any;
    privType: any;
    openOverride: boolean;
    overrideFor: any;
    overrideEndpoint: any;
    alreadyPatPriv: any;
    alreadyPrescPriv: any;
    alreadyDrugPriv: any;
    alreadyInsPriv: any;
    unsubscribe$ : Subject<void> = new Subject();

    @HostListener("document:keydown.alt.x", ["$event"])
    keyEventaltX(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            this.editPatPrescDrgIns('Patient');
          } 
    }
    @HostListener("document:keydown.alt.y", ["$event"])
    keyEventaltY(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            this.editPatPrescDrgIns('Prescriber');
          } 
    }
    @HostListener("document:keydown.alt.z", ["$event"])
    keyEventaltZ(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            this.editPatPrescDrgIns('Drug');
          } 
    }


    constructor(
        private _nrxUtil: NRxUtils,
        private _transmsnServ: TransmissionService,
        private _ppdims: PatPreDrugModalService,
        private _commonServ: CommonService,
        private _insuServ: InsuranceService,
        private _rxStore: RxStore
    ) {}

    ngOnInit() {}

    routeToPatient() {
        this.checkUpdateErxStatusandReviewTrans("R", "PATIENT");
    }

    routeToPrescriber() {
        this.checkUpdateErxStatusandReviewTrans("R", "PRESCRIBER");
    }

    routeToDrug() {
        this.checkUpdateErxStatusandReviewTrans("R", "DRUG");
    }

    routeToInsurence() {
        this.checkUpdateErxStatusandReviewTrans("R", "INSURANCE");
    }

    async checkUpdateErxStatusandReviewTrans(trStat, Screen) {
        if (
            this.rxInfo &&
            this.rxInfo.eRxDetailsViewModel &&
            this.rxInfo.eRxDetailsViewModel.ErxId
        ) {
            await this._nrxUtil.updateErxStatus(this.rxInfo);
        }
        await this.reviewTransmissionResult();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this._rxStore.storeRxInfo(this.rxInfo);
        }
        this.loadComponentAsModal(Screen);
    }

    async reviewTransmissionResult() {
        return await this._transmsnServ
            .updateUserReviewStatus(
                this.transmResp.transmissionDetails.BatchId,
                "R"
            )
            .toPromise();
    }

    loadComponentAsModal(type: string) {
        this.PatPrescDrugInsModalType.emit(type);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "PatientFile") {
                this.editPatPrescDrgIns("Patient");
            } else if (this.overrideFor === "PrescriberFile") {
                this.editPatPrescDrgIns("Prescriber");
            } else if (this.overrideFor === "DrugFile") {
                this.editPatPrescDrgIns("Drug");
            } else if (this.overrideFor === "InsuranceFile") {
                this.editPatPrescDrgIns("Insurance");
            }
        }
    }

    openOveride(content, type,endpoint?) {
        this.categoryId = content;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = content;
        this.overrideEndpoint = endpoint;
    }
    async editPatPrescDrgIns(type?: string) {
        if (type === "Patient") {
            if (!this._nrxUtil.getPatientOverRidePriv() && !this.alreadyPatPriv) {
                this.alreadyPatPriv = true;
                this.openOveride('PatientFile', 'Edit', 'PUT_Patient');
            } else {
                this._ppdims._patPreDrugModal$.next("patient");
            const modalRef = this._ppdims.modalInstanceRef;
            const patId = this.transmResp && this.transmResp.transmissionDetails ?
            +this.transmResp.transmissionDetails.PatId : +this.rxInfo.Patient.patientid;
            modalRef.componentInstance.patientID = patId;
            modalRef.componentInstance.ClosePatientModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                modalRef.close();
                if (resp["PatientId"]) {
                    this.rxInfo = await this._nrxUtil.getandStoreRxInfo(
                        +this.transmResp.transmissionDetails.RXNo, this.transmResp.transmissionDetails.PrescRefillId);
                }
                this._ppdims._patPreDrugModal$.next(null);
            });
            }
        } else if (type === "Prescriber") {
            if (!this._nrxUtil.getPrscrbrOverRidePriv() && !this.alreadyPrescPriv) {
                this.alreadyPrescPriv = true;
                this.openOveride('PrescriberFile', 'Edit', 'PUT_Prescriber');
            } else {
                this._ppdims._patPreDrugModal$.next("prescriber");
                const modalRef = this._ppdims.modalInstanceRef;
                const prescId = this.transmResp && this.transmResp.transmissionDetails ?
                +this.transmResp.transmissionDetails.PrescriberId : +this.rxInfo.Prescriber.prescriberid;
                modalRef.componentInstance.prescriberID = prescId;
                modalRef.componentInstance.ClosePrescriberModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                    modalRef.close();
                    if (resp["prescriberId"]) {
                        // const prescData = await this._commonServ.getRxPrescriberDetailsById(+this.rxInfo.Prescriber.prescriberid).toPromise();
                        // this.rxInfo.Prescriber = prescData;
                    }
                    this._ppdims._patPreDrugModal$.next(null);
                });
            }
        } else if (type === "Drug") {
            if (!this._nrxUtil.hasDrugChangePrv() && !this.alreadyDrugPriv) {
                this.alreadyDrugPriv = true;
                this.openOveride('DrugFile', 'Edit', 'PUT_Drug');
            } else {
                this._ppdims._patPreDrugModal$.next("drug");
                const modalRef = this._ppdims.modalInstanceRef;
                const drugId = this.transmResp && this.transmResp.transmissionDetails ?
                +this.transmResp.transmissionDetails.DrugId : +this.rxInfo.Drug.id;
                modalRef.componentInstance.drugId = drugId;
                modalRef.componentInstance.frmAction = true;
                modalRef.componentInstance.CloseDrugModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                    modalRef.close();
                    if (resp["drugId"]) {
                        // const drugData = await this._commonServ.getRxDrugDetailsById(+this.rxInfo.Drug.id).toPromise();
                        // this.rxInfo.Drug = drugData;
                    }
                    this._ppdims._patPreDrugModal$.next(null);
                });
            }
        } else if (type === "Insurance") {
            if (!this._nrxUtil.getInsOverRidePriv() && !this.alreadyInsPriv) {
                this.alreadyInsPriv = true;
                this.openOveride('InsuranceFile', 'Edit', 'PUT_Insurnace');
            } else {
                this._ppdims._patPreDrugModal$.next("insurance");
                const modalRef = this._ppdims.modalInstanceRef;
                const insuId = this.transmResp && this.transmResp.transmissionDetails ?
                +this.transmResp.transmissionDetails.InsuId : +this.rxInfo.RefillInsu.InsuId;
                const insurance: any = this.rxInfo.Patient.insurance.filter(
                    a => +a.insuranceid === insuId
                )[0];
                modalRef.componentInstance.insuId = insurance ? insurance.insuplanId : this.transmResp.Insuplanid;
                modalRef.componentInstance.CloseInsuModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                    modalRef.close();
                    if (resp) {
                        // this._insuServ.getInsSettingsInfoForRx(insurance ? insurance.insuplanId : 0);
                        // await this._commonServ.getRxDrugDetailsById(+this.rxInfo.Drug.id).toPromise();
                    }
                    this._ppdims._patPreDrugModal$.next(null);
                });
            }
        }
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}


