import { PrivMaskGuard } from "./../../../guards/access-privs.guard";
import { AlertService } from "./../../../services/alert.service";
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from "@angular/core";
import { MsgConfig } from "../../..";
import { AccCategoryE, FileMaintenance1E } from "src/app/models";
import { TabTitles } from "../patient.constants";
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonUtil } from "src/app/utils";
import { HubService } from "src/app/services/Hub.service";

@Component({
    selector: "app-patient-tabs",
    templateUrl: "./patient-tabs.component.html"
})
export class PPatientTabsComponent implements OnInit, OnChanges {
    accessPrivCate: any;
    patNotesPrivs: any;

    @Input() docAvail: any;
    @Input() patientID: any;
    @Input() patientFG: FormGroup;
    @Input() currTab: any;
    @Input() resetToFirst: any;
    @Input() patientInfo: any;


    @Output() ActivePatientTab = new EventEmitter<any>();
    @Output() addPatientData = new EventEmitter();
    @ViewChild("warning")
    warning: any;
    activeTabId: any;
    selectedTab: string;
    @Input()
    ActiveTabId(data: any) {
        this.activeTabId = TabTitles[data];
    }

    constructor(
        private _alertServ: AlertService,
        private _privMaskGuard: PrivMaskGuard,
        private _modalSvc: NgbModal,
        public _commonUtil: CommonUtil,
        private hubService: HubService
    ) {
        this.accessPrivCate = AccCategoryE;
        this.patNotesPrivs = FileMaintenance1E;
        this.hubService.setPatientTabsComponent(this)
    }

    ngOnInit() {
        if (this.currTab) {
          this.activeTabId = TabTitles[this.currTab];
        }
    }

    ngOnChanges(changes?: any) {
        if (changes) {
            if (changes.resetToFirst && changes.resetToFirst.currentValue) {
                if (this.resetToFirst) {
                    this.activeTabId = null;
                    setTimeout(() => {
                        this.activeTabId = "Patient1";
                        this.ActivePatientTab.emit(this.activeTabId);
                    }, 100);
                }
            }
            if (changes.ActiveTabId && changes.ActiveTabId.currentValue) {
                    this.activeTabId = null;
                    setTimeout(() => {
                        this.activeTabId = TabTitles[changes.ActiveTabId.currentValue];
                        this.ActivePatientTab.emit(this.activeTabId);
                    }, 100);
            } 
        }
    }

    beforeChange(nextId: string) {
        if (nextId && this.patientID) {
            if (nextId !== "Patient7") {
                this.ActivePatientTab.emit(nextId);
            } else if (nextId === "Patient7") {
                if (
                    this.checkHasPrivsToAccess(
                        this.accessPrivCate.FileMaintenance1,
                        this.patNotesPrivs.PatientNotesLog
                    )
                ) {
                    this.ActivePatientTab.emit(nextId);
                } else {
                    this._alertServ.error(
                        MsgConfig.NO_PRIVS_ACCESS,
                        false,
                        "long"
                    );
                }
            }
        } else {
           this.addPatient(nextId);
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    addPatient(nextId) {
        this.addPatientData.emit(nextId);
    }

}
