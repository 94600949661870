<div
    [ngClass]="[isInputElemHidden? 'form-group custom--input custom--select custom--select__height-hide' : 'form-group custom--input custom--select default', errorMessage?'has-error':'']"
    [formGroup]="inputFormGroup"
    *ngIf="hasControl && inputFormGroup"
>
    <label class="control-label" *ngIf="labelText">
        <span
            >{{ labelText
            }}
            <span *ngIf="isRequired" class="help--text__danger">*</span>
            <span *ngIf="isInputElemHidden" (click)="setInputElemHidden()" class="help--text__icons">
                    <i class="far fa-pencil-alt"></i>
            </span>
            <span *ngIf="!isInputElemHidden && isTouched" (click)="setIsTouchedFalse()" class="help--text__icons">
                    <i class="far fa-save"></i>
            </span>
        </span
        >
    </label>
    <fieldset [id]="rxID + 'f'" [disabled]="isDisabled">
    <!-- use  to disble select dropdown -->
    <span *ngIf="bindLabel" class="custom--select__span">
        <span *ngIf="!isInputElemHidden">
            <select
                [id]="rxID"
                [formControlName]="custContrlName"
                (change)="triggerChange($event)"
                (keydown)="onEnter($event)"
                (blur)="triggerOnBlur($event)"
                [ngClass]="isDisabled? 'rx--select light_gray': (changeText ? 'rx--select noTexttransform' : 'rx--select')"
                [tabindex]="tabIndex"
                [required]="isRequired"
                [title]="titleText || ''"
                [appAutofocus]="autoFocus"
                (blur)="validateAllFormFields(inputFormGroup, custContrlName)"
                (keydown.shift.tab)="onShiftTabbingEvent($event)"
                (focus)="focusOnInput()"
                (keydown.tab)="focusonTab()"
            >
                <option
                    [ngValue]="list[bindValue]"
                    [id]="list[labelForId]"
                    *ngFor="let list of dropdownList"
                    [title]="((rxID === 'rxRxOriginId' || IsFromAccSetDisabled) && list[bindLabel]?.toUpperCase() == 'ELECTRONIC')? 
                    'As it is Manual Rx ELECTRONIC option is disabled': ''"
                    [disabled]="((rxID === 'rxRxOriginId' || IsFromAccSetDisabled) && list[bindLabel]?.toUpperCase() == 'ELECTRONIC')? true : false"
                    >  
                    <div class="row" *ngIf="hasBindLabel2" [title]="titleText">
                            <div class="col-6 custom--select__dataflow">
                                <h6>{{ list[bindLabel] | uppercase}}</h6>
                            </div>
                            <div class="col-6 custom--select__dataflow">
                                <h6 *ngIf="list[bindLabel2] ">-{{ (" " + list[bindLabel2]) | uppercase}}</h6>
                                <h6 *ngIf="list[bindLabel2] === 0">-{{ " 0"}}</h6>
                            </div>
                        </div>
                    <!-- {{ list[bindLabel] }} -->
                    <span *ngIf="!hasBindLabel2">{{changeText ? list[bindLabel] : (list[bindLabel] | uppercase)}}</span>
                </option>
            </select>
        </span>
        <span *ngIf="isInputElemHidden">
            <div class="label--data">
                <span *ngIf="inputFormGroup?.value[custContrlName]">
                    <span *ngFor="let list of dropdownList">
                        <span *ngIf="list[bindValue] == inputFormGroup?.value[custContrlName]"> {{list[bindLabel] }}
                            <span *ngIf="list[bindLabel2] ">-{{ (" " + list[bindLabel2]) | uppercase}}</span>
                            <span *ngIf="list[bindLabel2] === 0">-{{ " 0"}}</span>
                        </span>
                    </span>
                </span>
                <span *ngIf="!inputFormGroup?.value[custContrlName]">
                        --
                </span>
            </div>
        </span>
    </span>
    <span *ngIf="!bindLabel" class="custom--select__span">
        <span *ngIf="!isInputElemHidden">
            <select
                [id]="rxID"
                class="rx--select"
                [formControlName]="custContrlName"
                (change)="triggerChange($event)"
                (keydown)="onEnter($event)"
                (blur)="triggerOnBlur($event)"
                [tabindex]="tabIndex"
                [ngClass]="isDisabled? 'light_gray':''"
                [required]="isRequired"
                [title]="titleText || ''"
                [appAutofocus]="autoFocus"
                (blur)="validateAllFormFields(inputFormGroup, custContrlName)"
                (keydown.shift.tab)="onShiftTabbingEvent($event)"
                (focus)="focusOnInput()"
                (keydown.tab)="focusonTab()"
            >
                <option [value]="list" [id]="list" *ngFor="let list of dropdownList"
                    >{{ list }}
                </option>
            </select>
        </span>
        <span *ngIf="isInputElemHidden">
            <div class="label--data">
                <span *ngIf="inputFormGroup?.value[custContrlName]">
                        {{inputFormGroup?.value[custContrlName]}}
                </span>
                <span *ngIf="!inputFormGroup?.value[custContrlName]">
                        --
                </span>
            </div>
        </span>
    </span>
</fieldset>
    <span class="help-block" *ngIf="errorMessage"> {{ errorMessage }} </span>
</div>

<div
    [ngClass]="[isInputElemHidden? 'form-group custom--input custom--select custom--select__height-hide' : 'form-group custom--input custom--select default',
        errorMessage?'has-error':'']"
    *ngIf="!hasControl"
>
    <label class="control-label" *ngIf="labelText">
        <span>{{ labelText }}</span>
    </label>
    <fieldset [id]="rxID + 'f'" [disabled]="isDisabled">

    <!-- use  to disble select dropdown -->
    <span class="custom--select__span">
        <select
            [id]="rxID"
            class="rx--select"
            [ngClass]="isDisabled? 'light_gray':''"
            (change)="triggerChange($event)"
            (blur)="triggerOnBlur($event)"
            (keydown.enter)="onEnter($event)"
            [tabindex]="tabIndex"
            [required]="isRequired"
            [title]="titleText || ''"

            [appAutofocus]="autoFocus"
            (blur)="validateAllFormFields(inputFormGroup, custContrlName)"
            (keydown.shift.tab)="onShiftTabbingEvent($event)"
            (focus)="focusOnInput()"
        >
            <option
                [value]="list[bindValue]"
                [id]="list[labelForId]"
                *ngFor="let list of dropdownList"
                [selected]="list[bindValue] === inputValue"
                >{{ list[bindLabel] }}
                <span *ngIf="list[bindLabel2]"
                    >- {{ list[bindLabel2] }}</span
                ></option
            >
        </select>
    </span>
    </fieldset>
    <span class="help-block" *ngIf="errorMessage"> {{ errorMessage }} </span>
</div>
