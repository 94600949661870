<ng-template #Confirmation let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Confirmation</h4>
        <span body>
            Do you want to perform Rph Verification Individually or mark all the selected Rxs as Verified?
        </span>
        <button footer class="hotkey_primary" ngbAutofocus (keydown.enter)="c('Close click');verifyInfo('individually')"
            (click)="c('Close click');verifyInfo('individually')" appShortcutKey InputKey="v"><b>V</b>
            Verify Individually
        </button>
        <button footer class="hotkey_primary" (click)="c('Close click');verifyInfo('mark')"
            (keydown.enter)="c('Close click');verifyInfo('mark')" appShortcutKey InputKey="m"><b>M</b>
            Mark all selected Rxs as Verified
        </button>
        <button footer class="hotkey_primary" (click)="closeModal()" (keydown.enter)="closeModal()" appShortcutKey
            InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #invalidRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            <span [innerHTML]="warningInfoForDisplay"></span>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="closeModal();processRphVerificationfromInvalidRx()"
            appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>
<ng-template #VerifiedRxs let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Confirmation</h4>
        <span body>
            The following Rx(s) <label>{{&nbsp; verifiedArray.join(", ")}}</label> are successfully verified.
        </span>
        <button footer autofocus class="hotkey_primary" (click)="closeModal()" (keydown.enter)="closeModal()"
            appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>
<ng-template #AlreadyVerif let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Confirmation </h4>
        <span body>
            <b>Rx#: {{rxInfo.Prescription.PrescNum}}, Refill#:
                {{rxInfo.PrescReFill.ReFillNum}},{{(rxInfo.PrescReFill.PartialFillNo > 1) ? 'Fill#:
                '+(rxInfo.PrescReFill.PartialFillNo) : ''}}</b> was already verified on
            {{verifiedDetails.VerifDtTm ? (verifiedDetails.VerifDtTm | localTime | date:'MM/dd/yyyy'):'--'}} by
            <b> {{verifiedDetails?.UserName ?
                (verifiedDetails?.UserName | uppercase): '--' }}</b>.Do you want to re-verify?
        </span>
        <button ngbAutofocus footer class="hotkey_primary"
            (click)="c('Close click');processRhvForAlreadyVerified('yes')"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="closeModal();processRhvForAlreadyVerified('no')"
             appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>
<app-login-verify *ngIf="openPopUp === true" [FromPage]="'RphDashboard'"
    (setPopUpValue)="afterVerifyRphVerification($event)" [HeaderName]="'RphVerification'">
</app-login-verify>
