import { Injectable } from "@angular/core";
import { BehaviorSubject ,  Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PrescriberStore {
    _prescriberInfo$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    prescriberInfo$: Observable<any> = this._prescriberInfo$.asObservable();

    _prescriberCity$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    prescriberCity$: Observable<any> = this._prescriberCity$.asObservable();

    _prescriberState$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    prescriberState$: Observable<any> = this._prescriberState$.asObservable();

    constructor() {}

    storePrescriberData(data) {
        this._prescriberInfo$.next(data);
    }

    storePrescriberCity(data) {
        this._prescriberCity$.next(data);
    }

    storePrescriberState(data) {
        this._prescriberState$.next(data);
    }

}
