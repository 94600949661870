<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Bucket Pricing'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                    <button class="hotkey_success" id="SaveButton" (click)="routeBackToPrevScreen()"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header large_header">
            <div class="row">
                <div class="col">
                    <span class="eprx--header__heading">
                    </span>
                </div>
                <div class="col pull-right text-right">
                        <button class="hotkey_primary hotkey_primary--Override"
                        (keydown.tab)="focusOutFromAdd($event)" autofocus
                        (click)="openPopUpModal(BucketPrice)" appShortcutKey [CtrlKey]="'a'"><span>A</span>ADD</button>
                </div>
            </div>
        </div>
        <div class="eprx--block__content" *ngIf="totalCount">
            <wj-flex-grid #bucket [headersVisibility]="'Column'" [itemsSource]="disWarnWJ" [isReadOnly]="true" [selectionMode]="'Row'"
                (updatedView)="init(bucket)" (keydown.enter)="keyEventOnWijimo(BucketPrice, bucket)" (keydown)="addEventOnWijimo($event, BucketPrice)" [allowDragging]="'None'">
                <wj-flex-grid-column [header]="'Actions'" width="5*" [binding]="'Actions'" [visible]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <div class="text-center">
                            <i class="far fa-edit fa-sm  actions" title="edit" (click)="editPopUpModal(BucketPrice, item)"></i>
                            <i class="far fa-trash-alt fa-sm actions danger-actve" title="delete" (click)=" deletePopUpModal(delete, item)"></i>
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Bucket Name'" width="5*" [binding]="'Bucket Name'"></wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Unit Pricing'" width="5*" [binding]="'Unit Pricing'" [format]="'c2'"></wj-flex-grid-column>
            </wj-flex-grid>
            <app-eprx-pagination [TotalCount]="totalCount" [PageSize]="Size" [WijmoName]="bucket" [GridName]="'bucketPricing'" (OnPageSelected)="setPage($event)"
                (OnSizeSelected)="setSize(Size)" (OnFocusOut)="focusToFirst($event)"></app-eprx-pagination>

        </div>
    </div>
    <div class="eprx--block__content eprx--block__footer" *ngIf="(totalCount === 0)">
        <div>
            <span></span>
            <i class="fa fa-file"></i><br/>
            No Data Found!
        </div>
    </div>
</div>
<ng-template #BucketPrice let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!editMode">Add Bucket Pricing</h4>
        <h4 class="modal-title" *ngIf="editMode">Update Bucket Pricing</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closePopUp()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" [formGroup]="addBuck">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-select [LabelText]="'Bucket Name'" [PlaceHolder]="''" [ControlName]="'DrugBucketId'" [RxSelectId]="'DrugBucketId'"
                [IsFirstElem]="true" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid" [InputErrors]="DrugBucketId?.errors" [ErrorDefs]="{required: 'Required'}"
                [FormGroupName]="addBuck" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="bucketDropDownData">
                </eprx-select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Unit Price'" [PlaceHolder]="''" [ControlName]="'UnitPrice'"
                 [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid" [InputErrors]="UnitPrice?.errors" [ErrorDefs]="{required: 'Required'}"
                [FormGroupName]="addBuck"  [DropSpclChar]="false" [DecimalValues]="3"  [MaxLength]="10"
                (TriggerSearchValue)="setNumberFormat('UnitPrice')" [MaskPattern]="'099999.000'">
                </eprx-input>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary"  *ngIf="!editMode" (click)="addBucketPrice()"
                (keydown.enter)="addBucketPrice()" appShortcutKey [CtrlKey]="'s'"><span>S</span>Save</button>
                <button class="hotkey_primary"  *ngIf="editMode" (click)="updateBucketPrice()"
                (keydown.enter)="updateBucketPrice()" appShortcutKey [CtrlKey]="'u'"><span>U</span>Update</button>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="closePopUp()"
                (keydown.enter)="d('Cross click'); closePopUp()" appShortcutKey [CtrlKey]="'c'"><span>C</span>Cancel </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closePopUp()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{warningText?.deleteConfrmMsg}}
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteBucketPrice()" (click)="closePopUp()"
                    appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="closePopUp()" appShortcutKey InputKey="c"><b>C</b>CANCEL</button>
            </div>
        </div>
    </div>
</ng-template>
