<div class="checkbox-toggle" [formGroup]="inputFormGroup" *ngIf="!withOutFrmGrp" >
    <div *ngIf="LabelText">
        <label> {{LabelText}}</label>
    </div>
    <input
    type="checkbox"
    [formControl]="inputFormGroup.get(custContrlName)"
    [id] = "inputFormGroup?.get(custContrlName)?.value ? rxID : rxID+1"
    [name] = "rxID"
    title="{{titleMsg}}"
    [(ngModel)]="isChecked"
    [appAutofocus]="autoFocus"
    [attr.disabled]="isDisabled ? true : null"
    (keydown) = "onKeyDownEvent($event)"
    (keyup.shift.tab)="onShiftTabbingEvent($event)"
    (click) = "changeValue($event)"

    />
</div>
<div class="checkbox-toggle" *ngIf="withOutFrmGrp" >
    <input
    type="checkbox"
    [id] = "rxID"
    [name] = "rxID"
    title="{{titleMsg}}"
    [(ngModel)]="isChecked"
    [appAutofocus]="autoFocus"
    [attr.disabled]="isDisabled ? true : null"
    (keydown) = "onKeyDownEvent($event)"
    (keyup.shift.tab)="onShiftTabbingEvent($event)"
    (change) = "changeValue($event)"
    />
</div>
