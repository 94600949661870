<div
    class="form-group custom--input height_auto"
    [ngClass]="errorMessage ? 'has-error' : ''"
>
    <label class="control-label">
        <span *ngIf="labelText"
            >{{ labelText
            }}<span *ngIf="isRequired" class="help--text__danger">*</span></span
        >
        <fieldset [disabled]="isReadOnly">
            <span *ngIf="hasControl" [formGroup]="inputFormGroup">
                <textarea
                    [appAutofocus]="autoFocus"
                    [id]="rxID ? rxID : 'txtAreaID'"
                    [required]="isRequired"
                    [tabindex]="tabIndex"
                    autocomplete="off"
                    class="form-control custom--text__area"
                    [rows]="rows"
                    [cols]="cols"
                    [formControlName]="custContrlName"
                    [placeholder]="placeHolder"
                    [maxLength]="maxLength"
                    (blur)="triggerOnBlur($event)"
                    (keydown.tab)="triggerOnTab($event)"
                    (keyup.shift.tab)="onShiftTabbingEvent($event)"
                    (click) = "getCursorPosition($event)"
                    (keyup) = "getCursorPosition($event)"
                ></textarea>
            </span>
            <span *ngIf="!hasControl">
                <textarea
                    [appAutofocus]="autoFocus"
                    [id]="rxID ? rxID : 'txtAreaID'"
                    [required]="isRequired"
                    [tabindex]="tabIndex"
                    autocomplete="off"
                    class="form-control custom--text__area"
                    [rows]="rows"
                    [cols]="cols"
                    [value]="inputValue"
                    [placeholder]="placeHolder"
                    [maxLength]="maxLength"
                    (blur)="triggerOnBlur($event)"
                    (keydown.tab)="triggerOnTab($event)"
                    (keyup.shift.tab)="onShiftTabbingEvent($event)"
                ></textarea>
            </span>
        </fieldset>
    </label>
    <div class="help-block" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
</div>
