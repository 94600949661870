<ng-template #calChange let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePriceConfirmation(false, 'fromPopup')">
        <h4 header>Confirmation</h4>
        <span body>
            <h4>{{headerText}}</h4>
            <br/>
            <h4>Following values are different : </h4>
            <table class="table table-hover">
                <tr>
                    <th></th>
                    <th>Old Value</th>
                    <th>New Value</th>
                </tr>
                <tbody>
                    <tr *ngFor="let price of priceChangeVals; let i=index">
                        <td><b>{{price["Header"] | uppercase}}</b></td>
                        <td>{{(price["Header"] === 'Price Schedule') ? (price["OldValue"] | uppercase) : price["OldValue"] | currency: "$":"symbol":"1.2-2"}}</td>
                        <td>{{(price["Header"] === 'Price Schedule') ? (price["NewValue"] | uppercase) : price["NewValue"] | currency: "$":"symbol":"1.2-2"}}</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <h4>{{footerText}}</h4>
        </span>
        <button id="btnOKcalChange" autofocus footer type="submit" class="hotkey_primary"
         (click)="closePriceConfirmation(true, 'fromPopup')" (keydown.enter)="closePriceConfirmation(true, 'fromPopup')"
         appShortcutKey
            InputKey="y">
            <b>Y</b>YES
        </button>
        <button id="btnCancelcalChange" footer type="submit" class="hotkey_primary"
         (click)="closePriceConfirmation(false, 'fromPopup')" (keydown.enter)="closePriceConfirmation(false, 'fromPopup')"
         appShortcutKey
            InputKey="n">
            <b>N</b>NO
        </button>
    </app-custom-modal>
</ng-template>
