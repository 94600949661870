
    <app-custom-modal (oncrossBtnClick)="cancelSaveCashRx()">
        <h4 header>Confirm Cash Rx Price</h4>
        <span body>
            <div class="col-xs-12 col-md-12 col-lg-12">
                <div class="row" *ngIf="frmBatchEdit">
                    <div class="col-xs-4 col-md-4 col-lg-4">
                        <label class="align-label"> Rx# </label>
                        <div class="label--data">{{rxInfo?.Prescription?.PrescNum}}</div>
                    </div>
                <div class="col-xs-2 col-md-2 col-lg-2">
                        <label class="align-label"> Ref# </label>
                        <div class="label--data">{{rxInfo?.PrescReFill?.ReFillNum}}</div>
                    </div>
                    <div class="col-xs-2 col-md-2 col-lg-2">
                        <label class="align-label"> Fill# </label>
                        <div class="label--data">{{_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription) ?
                            rxInfo?.PrescReFill?.PartialFillNo : "--" }}</div>
                    </div>
                    <div class="col-xs-4 col-md-4 col-lg-4">
                        <label class="align-label"> Qty </label>
                        <div class="label--data">{{rxInfo?.PrescReFill?.DispQty | number : '1.3-3'}}</div>
                    </div>
                    <div class="col-xs-8 col-md-8 col-lg-8">
                        <label class="align-label"> Drug Name </label>
                        <div class="label--data">{{rxInfo?.Drug?.drugname ?  (rxInfo?.Drug?.drugname).toUpperCase() + " " : ""}}
                             {{ rxInfo?.Drug?.strength ? (rxInfo?.Drug?.strength).toUpperCase() + " " : "" }}
                             {{ rxInfo?.Drug?.drugformname ? (rxInfo?.Drug?.drugformname).toUpperCase() : ""}}</div>
                    </div>
                    <div class="col-xs-4 col-md-4 col-lg-4">
                        <label class="align-label"> Days Supply </label>
                        <div class="label--data">{{rxInfo?.PrescReFill?.SupplyDays}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-md-6 col-lg-6">
                        <eprx-select
                            [RxSelectId]="'CashrxPriceSchId'"
                            [LabelText]="'Price CD'"
                            [PlaceHolder]="''"
                            [TabIndex]="38"
                            [List]="priceScheduleList"
                            [HasMultiple]="false"
                            [BindLabel]="'PriceCode'"
                            [BindLabel2]="'Name'"
                            [BindValue]="'Id'"
                            [LabelForId]="'Name'"
                            (TriggerSelectValue)="
                                getPriceDetlsNdCalc($event)
                            "
                            [IsInputElemHidden]="false"
                            (TriggerOnEnterValue)="keytab($event, 'PriceSchId')"
                            [HasControl]="false"
                            [InputValue]="rxInfo?.PrescReFill?.PriceSchId"
                            (TriggerOnTabbing)="keytab($event, 'PriceSchId')"
                        >
                        </eprx-select>
                    </div>
                    <div class="col-xs-6 col-md-6 col-lg-6">
                        <eprx-input
                            [RxSelectId]="'CashxUnitPriceAWP'"
                            [LabelText]="'AWP'"
                            [PlaceHolder]="''"
                            [ReadOnly]="true"
                            [TabIndex]="-1"
                            [InputType]="'CURRENCY'"
                            (TriggerSearchValue)="
                                setNumberFormat('UnitPriceAWP', $event)
                            "
                            [IsDisabled]="true"
                            (TriggerOnEnterValue)="
                                keytab($event, 'UnitPriceAWP')
                            "
                            (TriggerOnTabbing)="keytab($event, 'UnitPriceAWP')"
                            [HasControl]="false"
                            [InputValue]="
                                rxInfo?.PrescReFill?.UnitPriceAWP
                                    ? (+rxInfo?.PrescReFill
                                          ?.UnitPriceAWP).toFixed(2)
                                    : '0.0'
                            "
                        >
                        </eprx-input>
                    </div>
                    <div class="col-xs-6 col-md-6 col-lg-6">
                        <eprx-input
                            [RxSelectId]="'CashIngredientCost'"
                            [LabelText]="'Ingredient Cost'"
                            [PlaceHolder]="''"
                            [TabIndex]="39"
                            [InputType]="'CURRENCY'"
                            (TriggerShortcutKeyValue)="setNumberFormat('Price', $event);afterPriceValChange($event?.target?.value, 'IC')"
                            (EmitOnChangeValue)="setNumberFormat('Price', $event);afterPriceValChange($event?.target?.value, 'IC')"
                            (TriggerOnEnterValue)="keytab($event, 'Price')"
                            (TriggerOnTabbing)="keytab($event, 'Price')"
                            [HasControl]="false"
                            [InputValue]="
                            this.rxInfo?.PrescReFill?.Price
                                    ? (+this.rxInfo?.PrescReFill?.Price).toFixed(2)
                                    : '0.0'"
                            [DecimalValues]="2"
                            (TriggerOnChange)="changePriceValues(true)"
                        >
                        </eprx-input>
                        <!--
                            (TriggerOnBlur)="afterPriceValChange($event?.target?.value, 'IC')" -->
                    </div>
                    <div class="col-xs-6 col-md-6 col-lg-6">
                        <eprx-input
                            [RxSelectId]="'CashrxOtherAmnt'"
                            [LabelText]="'Rx Cost'"
                            [PlaceHolder]="''"
                            [InputValue]="
                                (+(
                                    (+this.rxInfo?.PrescReFill?.DispQty
                                        ? +this.rxInfo?.PrescReFill?.DispQty
                                        : 0) *
                                    (this.rxInfo?.Drug?.unitpricecost
                                        ? +this.rxInfo?.Drug?.unitpricecost
                                        : 0)
                                )).toFixed(2)
                            "
                            [TabIndex]="0"
                            [IsDisabled]="true"
                            [InputType]="'CURRENCY'"
                            (TriggerSearchValue)="
                                setNumberFormat('OtherAmnt', $event)
                            "
                            [HasControl]="false"
                            (TriggerOnEnterValue)="keytab($event)"
                            (TriggerOnTabbing)="keytab($event)"
                            [HasControl]="false"
                        ></eprx-input>
                    </div>
                    <div class="col-xs-6 col-md-6 col-lg-6">
                        <eprx-input
                            [RxSelectId]="'CashrxProfFee'"
                            [LabelText]="'Professional Fee'"
                            [PlaceHolder]="''"
                            [TabIndex]="40"
                            [InputType]="'CURRENCY'"
                            (TriggerShortcutKeyValue)="setNumberFormat('ProfFee', $event);afterPriceValChange($event?.target?.value, 'profee')"
                            (EmitOnChangeValue)="setNumberFormat('ProfFee', $event);afterPriceValChange($event?.target?.value, 'profee')"
                            (TriggerOnEnterValue)="keytab($event, 'ProfFee')"
                            (TriggerOnTabbing)="keytab($event, 'ProfFee')"
                            [HasControl]="false"
                            [InputValue]="(+this.rxInfo?.PrescReFill?.ProfFee
                            ? (+this.rxInfo?.PrescReFill?.ProfFee).toFixed(2)
                            : '0.00')"
                            [DecimalValues]="2"
                            (TriggerOnChange)="changePriceValues(true)"
                        >
                        </eprx-input>
                        <!--
                            (TriggerOnBlur)="afterPriceValChange($event?.target?.value, 'profee')" -->
                    </div>
                    <div class="col-xs-6 col-md-6 col-lg-6">
                        <eprx-input
                            [RxSelectId]="'CashrxTaxAmt'"
                            [LabelText]="'Tax Applied'"
                            [PlaceHolder]="''"
                            [TabIndex]="0"
                            [IsDisabled]="true"
                            [InputType]="'CURRENCY'"
                            [HasControl]="false"
                            [InputValue]="(+(
                                (this?.rxInfo?.RxOtherAmount?.FlatSalesTax
                                    ? +this?.rxInfo?.RxOtherAmount?.FlatSalesTax
                                    : 0.0) +
                                (this?.rxInfo?.RxOtherAmount?.SalesTax
                                    ? +this?.rxInfo?.RxOtherAmount?.SalesTax
                                    : 0.0)
                            )).toFixed(2)"
                        >
                        </eprx-input>
                    </div>
                    <div class="col-xs-6 col-md-6 col-lg-6">
                        <eprx-input
                            [RxSelectId]="'CashrxTotalAmt'"
                            [LabelText]="'Total Price'"
                            [PlaceHolder]="''"
                            [TabIndex]="41"
                            [InputType]="'CURRENCY'"
                            [InputValue]="(+(
                                (this?.rxInfo?.PrescReFill?.TotalAmt
                                    ? +this?.rxInfo?.PrescReFill?.TotalAmt
                                    : 0.0)
                            )).toFixed(2)"
                            (EmitOnChangeValue)="crossCheckIngredientCost($event?.target?.value)"
                            (TriggerShortcutKeyValue)="crossCheckIngredientCost($event?.target?.value)"
                            [HasControl]="false"
                            [AutoFocus]="true"
                            [DecimalValues]="2"
                            (TriggerOnChange)="changePriceValues(true)"
                        >
                        </eprx-input>
                    </div>
                    <div class="col-xs-6 col-md-6 col-lg-6">
                    </div>
                </div>
            </div>
        </span>
        <button
            footer
            id="saveCnfmBillCash"
            ngbAutofocus
            tabindex="42"
            class="hotkey_success"
            (click)="saveCashInfo()"
            appShortcutKey
            [AltKey]="'s'"
            *ngIf="
                !(
                    routeFrom === 'nr' ||
                    routeFrom === 'nrf' ||
                    routeFrom === 'rnr' ||
                    routeFrom === 'rf'
                ) || transmissionType
            "
        >
            <span>S</span> Save
        </button>

        <button
            footer
            id="saveCnfmBillCash"
            ngbAutofocus
            tabindex="42"
            class="hotkey_success"
            (click)="saveCashInfoForNewRx()"
            appShortcutKey
            [AltKey]="'s'"
            *ngIf="
                (routeFrom === 'nr' ||
                    routeFrom === 'nrf' ||
                    routeFrom === 'rnr' ||
                    routeFrom === 'rf') &&
                !transmissionType
            "
        >
            <span>S</span> Save
        </button>
        <button
            footer
            id="cancelCnfmBillCash"
            ngbAutofocus
            tabindex="43"
            class="hotkey_success"
            (click)="cancelSaveCashRx()"
            (keydown.tab)="focusOnPriceCodeForCashCnfm($event)"
            appShortcutKey
            [AltKey]="'c'"
        >
            <span>C</span> Cancel
        </button>
    </app-custom-modal>

    <ng-template #WARNING let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="closeWarnPopup()">
            <h4 header>Warning!!</h4>
            <span body>
                {{WarnMessage}}
            </span>
            <button footer type="submit" class="hotkey_primary" ngbAutofocus appShortcutKey InputKey="o"
                (keydown.enter)="closeWarnPopup()" (click)="closeWarnPopup()">
                <b>O</b> OK
            </button>
        </app-custom-modal>
    </ng-template>
