<div class="row" *ngIf="familyInfoFrom === 'Patient'">
    <div [ngClass]="(!patientId && patFamilyInfo?.Id) ? 'col-md-11 form-group custom--input' :
     'col-md-10 form-group custom--input'" *ngIf="patientId || patFamilyInfo?.Id">
        <label class="control-label">
            <span>Patient's Family Info</span>
            <input id="familyInfo" class="form-control" readonly tabindex="-1" placeholder="Family Info"
                value="{{patFamilyInfo?.Name}}" title="{{patFamilyInfo?.Name}}"/>
        </label>
    </div>
    <div class="col-md-2 padding-0 margin_top_1rem" *ngIf="patientId">
        <button type="submit" class="hotkey_success pull-right patient-family-info-btn" (click)="getFamilyDetails(FamilyFirst)"
            appShortcutKey [AltKey]="'f'" title="Add Family Info">
            <span>F</span>
        </button>
    </div>
</div>

<a *ngIf="(patientInfo && patientInfo.PatFamily && patientInfo.PatFamily['Id'] && familyInfoFrom === 'Rx')"
    (click)="openEditPopUpModal(patientInfo.PatFamily, 'FrmRx')"><i class="far fa-users fa-lg font_size_1_5rem" title="Family Info"
        ></i></a>

<a *ngIf="familyInfoFrom === 'Rx'" (click)="addPatientFromFamily('FamilyMemAddPat')"><i
        class="far fa-users-medical fa-lg font_size_1_5rem" title="Add A Family Member(Ctrl+F)"  appShortcutKey [CtrlKey]="'f'"></i></a>

<ng-template #FamilyFirst let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Patient Family</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="!hasFamily">
        <span>This Patient is not yet linked to any family, What would you like to do?</span>
    </div>

    <div class="modal-footer" *ngIf="!hasFamily">
        <button type="button" class="hotkey_primary" (click)="d('Cross click');openFamilyListModal(searchFamilyList)"
            appShortcutKey [InputKey]="'l'">
            <b>L</b> Link To existing Family
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click');openPopUpModalAdd('StartNew')"
            appShortcutKey [InputKey]="'s'">
            <b>S</b> Start New Family
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click');" appShortcutKey [InputKey]="'c'">
            <b>C</b> Cancel
        </button>
    </div>
    <div class="modal-body" *ngIf="hasFamily">
        <span>This Patient is already linked to a family, What would you like to do?</span>
        <br>
        <div class="row">
            <div class="col inline-flex">
                <label>Family Name: </label>
                <div class="label--data">&nbsp; {{patFamilyInfo?.Name}} </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Address: </label>
                <div class="label--data">&nbsp;
                    {{familyInfo?.FamilyAddress[0]?.AddressLine1 ? (familyInfo?.FamilyAddress[0]?.AddressLine1 + ", ") : ""}}
                    {{familyInfo?.FamilyAddress[0]?.AddressLine2 ? (familyInfo?.FamilyAddress[0]?.AddressLine2 + ", ") : ""}}
                    {{familyInfo?.FamilyAddress[0]?.StateName ? (familyInfo?.FamilyAddress[0]?.StateName + ", ") : ""}}
                    {{familyInfo?.FamilyAddress[0]?.CityName ? (familyInfo?.FamilyAddress[0]?.CityName + ", ") : ""}}
                    {{familyInfo?.FamilyAddress[0]?.ZipCode}} </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="hasFamily">
        <button type="button" class="hotkey_primary"
            (click)="d('Cross click');openEditPopUpModal(patientInfo.PatFamily, 'Out')" appShortcutKey
            [InputKey]="'v'">
            <b>V</b> View/Edit Family
        </button>
        <button type="button" class="hotkey_primary" (click)="d('Cross click'); openFamilyListModal(searchFamilyList)"
            appShortcutKey [InputKey]="'l'">
            <b>L</b> Link to another family
        </button>
        <button type="button" class="hotkey_primary" (click)="closeModal('modelRef3')" appShortcutKey [InputKey]="'c'">
            <b>C</b> Cancel
        </button>
    </div>
</ng-template>


<!--search family List-->
<ng-template #searchFamilyList let-c="close" let-d="dismiss">
    <div class="modal-header">
        <div class="col-md-8 padding-0">
            <h4 class="modal-title">Family Profile Search</h4>
        </div>
        <div class="col-md-4 text-right pl-0 mr-2 insurance--body">
            <button class="hotkey_success  hotkey_primary--Override"
                (click)="openPatientSearch(patientSearch, 'addPatientData', addOrEditPopUp)" title="Add" appShortcutKey
                [AltKey]="'a'"><span>A</span>ADD</button>
        </div>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="exprx--common-block erx--body insurance--body">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-input [AutoFocus]="true" [LabelText]="'Last Name'" [PlaceHolder]="'Last Name'" [ControlName]="'Name'"
                            [FormGroupName]="searchFamilyFG" (keydown.enter)="getFamilies()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Zip Code'" [PlaceHolder]="'Zip Code'" [ControlName]="'ZipCode'"
                            [FormGroupName]="searchFamilyFG" (TriggerOnEnterValue)="getFamilies()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Primary Insurance'" [PlaceHolder]="'Primary Insurance'"
                            [ControlName]="'Insurance'" [FormGroupName]="searchFamilyFG"
                            (TriggerOnEnterValue)="getFamilies()">
                        </eprx-input>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 align-button text-right">
                        <button type="submit" title="Search / Refresh" class="hotkey_success pull-right" (click)="getFamilies()"
                            appShortcutKey [AltKey]="'f'">
                            <span>F</span> Search
                        </button>
                        <button type="button" class="hotkey_success pull-right" (click)="createFamilyListFG()" appShortcutKey
                            [AltKey]="'l'">
                            <span>L</span> clear
                        </button>
                    </div>
                </div>
            </div>
            <div class="exprx--common-block erx--body insurance--body">
                <div class="eprx--block__content">
                    <wj-flex-grid #SearchFamilyInfo [headersVisibility]="'Column'" [itemsSource]="searchFamilyInfoWJ"
                        [isReadOnly]="true" [columnPicker]="''" (updatedView)="init(SearchFamilyInfo)"
                        [selectionMode]="'Row'" [allowSorting]="false"
                        (dblclick)="linkdataOnKeyPress(SearchFamilyInfo, importFamily, $event)"
                        (keydown.enter)="linkdataOnKeyPress(SearchFamilyInfo, importFamily, $event)">

                        <wj-flex-grid-column *ngFor="
                                let actvHeader of activeHeader;
                                let i = index
                            " [header]="actvHeader" [binding]="actvHeader" [visible]="true" title="{{ actvHeader }}"
                            [width]="
                                    actvHeader==='Primary Insurance' ? 165 :
                            '*'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div *ngIf="actvHeader === 'Actions'">
                                    <i class="far fa-edit actions" title="edit" (click)="
                                    openEditPopUpModal(item.data)
                                "></i>
                                    <i class="far fa-trash-alt fa-lg actions danger-actve" (click)="
                                    deleteFamilyInfo(
                                        searchFamilyInfoWJ.currentItem
                                    )
                                " title="delete"></i>
                                    <i class="far fa-external-link actions"
                                        *ngIf="(patFamilyInfo && (item.data['Id'] !== patFamilyInfo?.Id) || !patFamilyInfo)" title="Link"
                                        aria-hidden="true" (click)="
                                        linkInfo(item.data, importFamily, true)"></i>
                                    <i class="far fa-external-link actions color_dimgray" title="This patient is already linked"
                                        *ngIf=" patFamilyInfo && (item.data['Id'] === patFamilyInfo?.Id)"
                                        ></i>
                                </div>
                                <div *ngIf="!(actvHeader === 'Actions')">
                                    {{ item[actvHeader] }}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                        <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                    </wj-flex-grid>
                    <app-eprx-pagination [TotalCount]="totalCount" (OnPageSelected)="setPage($event)"
                        (OnSizeSelected)="setSize($event)" [GridName]="'SearchFamilyInfo'" *ngIf="totalCount"
                        [WijmoName]="SearchFamilyInfo" [FromModal]="true"></app-eprx-pagination>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="closeModal('modelRef2')" appShortcutKey
            [AltKey]="'c'">
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>

<!-- add/edit family info-->
<ng-template #addOrEditPopUp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 *ngIf="!editMode" class="modal-title">New Family Info</h4>
        <h4 *ngIf="editMode" class="modal-title">Edit Family Info</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');closeImportPopup()">
            <span aria-hidden="true"  class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-5">
                <eprx-input [LabelText]="'Last Name'" [PlaceHolder]="'Last name'" [ControlName]="'Name'"
                    [FormGroupName]="familyInfoFG?.get('PatFamily')" [IsRequired]="true" [AutoFocus]="true"
                    [MarkAsTouched]="familyInfoFG?.get('PatFamily.Name')?.touched" [ErrorDefs]="{ required: 'Required', pattern: 'Invalid!' }"
                    [MaxLength]="100">
                </eprx-input>
            </div>
            <div class="col-md-1">
                <i class="far fa-search fa-lg actions margin_top_27px" title="Search Patients"
                    (click)="openPatientSearch(patientSearch, 'resetFamilyData')"></i>
            </div>
            <div class="col-6">
                <eprx-input [LabelText]="'Address1'" [PlaceHolder]="'Address Line 1'" [ControlName]="'AddressLine1'"
                [FormGroupName]="familyInfoFG?.get('FamilyAddress')" [IsRequired]="true"
                [MarkAsTouched]="familyInfoFG?.get('FamilyAddress.AddressLine1')?.touched" [ErrorDefs]="{ required: 'Required' }" [MaxLength]="150"
                [FormGroupInvalid]="formGroupInvalid">
                </eprx-input>
            </div>
            <div class="col-6">
                <eprx-input [LabelText]="'Address2'" [PlaceHolder]="'Address Line 2'" [ControlName]="'AddressLine2'"
                    [FormGroupName]="familyInfoFG?.get('FamilyAddress')"></eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                <app-search-zip [FormGroupName]="familyInfoFG?.get('FamilyAddress')" [ControlName]="'ZipCode'"
                [zipExtnCntrl]="'Extension'" (OnZipSelected)="onStateOrCityOrZipChange($event, 'Zip')" [IsRequired]="true"
                [FormGroupInvalid]="formGroupInvalid"></app-search-zip>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <app-search-state [FormGroupName]="familyInfoFG?.get('FamilyAddress')" [ControlName]="'StateId'" [CityControlName]="'CityId'"
                    [StateSelected]="stateSelected" (OnStateSelected)="onStateOrCityOrZipChange($event, 'State')" [IsRequired]="true"
                    [FormGroupInvalid]="formGroupInvalid"></app-search-state>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                <app-search-city [FormGroupName]="familyInfoFG?.get('FamilyAddress')" [ControlName]="'CityId'" [StateControlName]="'StateId'"
                    [CitySelected]="citySelected" [zipControl]="'ZipCode'" [IsRequired]="true" (OnCitySelected)="onStateOrCityOrZipChange($event, 'City')"
                    [FormGroupInvalid]="formGroupInvalid"></app-search-city>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="form-group custom--input" [ngClass]="{'has-error': familyInfoFG?.get('FamilyInsurance.InsurerId')?.invalid &&
                (familyInfoFG?.get('FamilyInsurance.InsurerId')?.dirty || familyInfoFG?.get('FamilyInsurance.InsurerId')?.touched) &&
                familyInfoFG?.get('FamilyInsurance.InsurerId')?.errors}">
                    <label class="control-label">
                        <span> Insurance Type <span class="help--text__danger">*</span></span>
                        <input type="text" #searchBoxInsType [value]="insuranceCode"
                            class="form-control search--input__box" placeholder="To Start Search..." appChangeDebounce
                            [ngbTypeahead]="searchInsurnace" [required]="true" [resultTemplate]="rt" [inputFormatter]="formatter"
                            (selectItem)="selectedInsType($event)" KeyUpDown>

                    </label>
                    <div class="help-block" *ngIf="familyInfoFG?.get('FamilyInsurance.InsurerId')?.invalid &&
                    (familyInfoFG?.get('FamilyInsurance.InsurerId')?.dirty || familyInfoFG?.get('FamilyInsurance.InsurerId')?.touched) &&
                    familyInfoFG?.get('FamilyInsurance.InsurerId')?.errors">
                        Required
                    </div>
                    <ng-template #rt let-r="result" let-t="term">
                        <div class="card patient-card secondary-patient-card" *ngIf="r">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="card__subTit--head">
                                            Insurance Code:
                                        </div>
                                        <div class="card__subTit--title">
                                            {{ r?.PlanCode }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="card__subTit--head">
                                            Insurance Name :
                                        </div>
                                        <div class="card__subTit--title">
                                            {{ r?.InsuranceName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="card__subTit--head">
                                            Bin Num :
                                        </div>
                                        <div class="card__subTit--title">
                                            {{ r?.BinNum }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="card__subTit--head">
                                            PCN Num :
                                        </div>
                                        <div class="card__subTit--title">
                                            {{ r?.ProcessorNum }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <eprx-input [RxSelectId]="'primInsID'" [LabelText]="'Policy# / Insurance ID'"
                    [PlaceHolder]="'Policy# / Insurance ID'" [ControlName]="'PolicyNum'"
                    [FormGroupName]="familyInfoFG?.get('FamilyInsuCard')"
                    [ErrorDefs]="{ required: 'Required'}" [MaxLength]="80"
                    [FormGroupInvalid]="formGroupInvalid" [IsRequired]="policyRequired"
                    [MarkAsTouched]="familyInfoFG?.get('FamilyInsuCard.PolicyNum')?.touched">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <eprx-select [LabelText]="'Group#'" [PlaceHolder]="'Group#'" [ControlName]="'InsuGrpId'"
                    [FormGroupName]="familyInfoFG?.get('FamilyInsurance')" [List]="filteredGroups" [BindLabel]="'Name'" [BindValue]="'Id'"
                    [LabelForId]="'Name'" [SelectOnTab]="true" [AddItem]="true" (TriggerOnClear)="isTypedValue($event)"
                    (TriggerSelectValue)="isTypedValue($event)" (TriggerOnTabbing)="focusOutFromGroup()"
                    [InputValue]="familyInfoFG?.get('FamilyInsuGrp')?.value['Name']" [addItemMaxLength]=35>
                </eprx-select>
            </div>
            <div class="col-6">
                <eprx-input [LabelText]="'Telephone#'" [PlaceHolder]="'Telephone#'" [ControlName]="'xPhone'"
                    [FormGroupName]="familyInfoFG?.get('FamilyPhone')" [MaskPattern]="'(000)000-0000'" [ShowMask]="true"
                    [DropSpclChar]="true" [RxSelectId]="'phoneGroup'">
                </eprx-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label> Existing Family Members </label>
                <wj-flex-grid #existingFamily [headersVisibility]="'Column'" [itemsSource]="familyMembersWJ"
                    [isReadOnly]="true" [columnPicker]="''" [selectionMode]="'Row'"
                    (keydown)="keyeventOnFamilyWijimo($event)"
                    class="existing-patient-family-wijmo" (initialized)="init1(existingFamily)"  *ngIf="existingMembers && existingMembers.length > 0">
                    <wj-flex-grid-column [header]="rxheader" [binding]="rxheader" [visible]="true"
                        *ngFor="let rxheader of familyHeader; let i = index"
                        [width]="rxheader === 'Select' ? 74 :  365">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                            <div *ngIf="rxheader === 'Select'">
                                <input type="radio" id="item['Pat#']" name="item['Pat#']"
                                (change)="selectFamilyMember($event?.target?.checked, item)"
                                value="{{ item.rowid }}" [checked]="existingMembers && existingMembers.length > 0 && item['Pat#']=== selectedMembId" />
                            </div>
                            <div *ngIf="!(rxheader === 'Select' || rxheader === 'Phone')">
                                {{item[rxheader]}}
                            </div>
                            <div *ngIf="rxheader === 'Phone'">
                                {{item[rxheader] | mask:"(000)000-000000000"}}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                </wj-flex-grid>
                <table class="table" *ngIf="!existingMembers || existingMembers.length === 0">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col"> Pat# </th>
                            <th scope="col"> Last Name </th>
                            <th scope="col"> First Name </th>
                            <th scope="col"> Phone </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"> </th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="familyInfoFrom === 'Rx' && editMode" class="hotkey_success"
            (click)="selectedPatFrFill(selectedMembId); d('Cross click');" appShortcutKey [AltKey]="'p'">
            <span>P</span> Select This Patient
        </button>
        <button type="button" class="hotkey_success" (click)="openPatientSearch(patientSearch)" appShortcutKey
            [AltKey]="'m'">
            <span>M</span> Add Member
        </button>
        <button type="button" class="hotkey_success" (click)="deleteExistingMembers(selectedMembId)" appShortcutKey
            [AltKey]="'r'">
            <span>R</span> Remove Member
        </button>
        <button *ngIf="!editMode" type="button" class="hotkey_success" (click)="addOrEditFamily()"
            appShortcutKey [AltKey]="'s'">
            <span>S</span> Save
        </button>
        <button *ngIf="editMode" type="button" class="hotkey_success" (click)="addOrEditFamily()"
            appShortcutKey [AltKey]="'u'">
            <span>U</span> Update
        </button>
        <button type="button" class="hotkey_success" (click)="d('Cross click');closeImportPopup()"
            appShortcutKey [AltKey]="'c'">
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>

<!-- importing family profile -->
<ng-template #importFamily let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Import Family Profile Information</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeImportPopup();">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div calss="col-12 margin_1rem_0_5rem">
                <h5 class="ml-3">Do you want to propagate family profile info. to all its member patient(s)?</h5>
            </div>
            <div class="col-md-12">
                <label>Family Details : </label>
                <span *ngIf="linkFromList">&nbsp; {{ linkedData?.Name ? (linkedData?.Name | uppercase) : ""}} &nbsp;
                    {{ linkedData?.Address1 ? (linkedData?.Address1 | uppercase) : null}} &nbsp;
                    {{ linkedData?.Address2 ? (linkedData?.Address2 | uppercase) : null}}</span>
                <span *ngIf="!linkFromList">&nbsp; {{ linkedData?.PatFamily?.Name ? (linkedData?.PatFamily?.Name | uppercase) : ""}} &nbsp;
                    {{ linkedData?.FamilyAddress?.AddressLine1 ? (linkedData?.FamilyAddress?.AddressLine1 | uppercase) : null}} &nbsp;
                    {{ linkedData?.FamilyAddress?.AddressLine2 ? (linkedData?.FamilyAddress?.AddressLine2 | uppercase) : null}}</span>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-md-12 margin_bottom_0_5rem" [formGroup]="linkFG">
                <div class="row">
                    <div class="col" [formGroup]="linkFG">
                        <input class="form-check-input" type="checkbox" id="IsLastNameUpdate"
                            formControlName="IsLastNameUpdate" />
                        <label class="form-check-label link__checkboxes" for="IsLastNameUpdate">
                            Last Name
                        </label>
                        <span *ngIf="linkFromList">&nbsp; {{ linkedData?.Name ? (linkedData?.Name | uppercase) : ""}}</span>
                        <span *ngIf="!linkFromList">&nbsp; {{ linkedData?.PatFamily?.Name ? (linkedData?.PatFamily?.Name | uppercase) : ""}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6" [formGroup]="linkFG">
                <div class="row">
                    <div class="col" [formGroup]="linkFG">
                        <input class="form-check-input" type="checkbox" id="gnrlInfo"
                            formControlName="IsOverrideGeneralDetails" />
                        <label class="form-check-label link__checkboxes" for="gnrlInfo">
                            General Information
                        </label>
                    </div>
                </div>
                <span>1. Address</span>
                <br/>
                <span>2. Telephone Number</span>
            </div>
            <div class="col-md-6" [formGroup]="linkFG">
                <div class="row">
                    <div class="col" [formGroup]="linkFG">
                        <input class="form-check-input" type="checkbox" id=""
                            formControlName="IsOverrideInsuranceDetails" />
                        <label class="form-check-label link__checkboxes" for="packPat1">
                            Insurance Information
                        </label>
                    </div>
                </div>
                <span> 1. Primary Insurance</span>
                <br/>
                <span> 2. Insurance Id</span>
                <br/>
                <span> 3. Group# </span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus class="hotkey_success" (click)="importFamilyProfile(); " appShortcutKey [AltKey]="'s'">
            <span>S</span> SAVE
        </button>
        <button type="button" class="hotkey_success" (click)="closeImportPopup(); " appShortcutKey [AltKey]="'c'">
            <span>C</span> CANCEL
        </button>
    </div>
</ng-template>

<!--patient search-->
<ng-template #patientSearch let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Search</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <div class="exprx--common-block">
            <div>
                <div class="seconday-search--grid">
                    <div class="row">
                        <div class="col-4 color_cyan">
                            Search Patient</div>
                    </div>
                    <app-searchbox-global #drugSearch [SearchFrm]="'Family'" [SearchFor]="'Patient'"
                        [IsAddNew]="false" [emitIfNotFound]="true" (OptedFormAddNew)="addPatientFromFamily($event, FamilyExistPopUp)"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true"
                        (SelectedPatient)="patientSelected($event)">
                    </app-searchbox-global>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" class="hotkey_success" (click)="closeModal('patmodelRef')" (keydown.enter)="d('Cross click')"
                appShortcutKey [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
        </div>
    </div>
</ng-template>

<!--FamilY Exist For Patient popUp-->
<ng-template #FamilyExistPopUp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Patient Family</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');">
            <span aria-hidden="true"class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>This Patient is already linked to a family, What would you like to do?</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="addMember('Update'); d('Cross click');" appShortcutKey
            [AltKey]="'u'">
            <span>U</span> Update
        </button>
        <button type="button" class="hotkey_success" (click)="d('Cross click');" appShortcutKey [AltKey]="'c'">
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>
<app-override-log *ngIf="openOverride" [OverrideEndpoint]="overrideEndpoint"
[PrivMask]="privType" [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>
