export enum MessageSettingEnum {
    General = 0,
    RefillDue = 1,
    BirthdayGreeting = 4,
    RxReadyPickup = 2,
    NewRxReceived = 6,
    Subscription = 7,
    MobilePayment = 12
}

export enum QuestionTypeAssessEnum {
    Text = 1,
    DropDown = 2,
    Date = 3,
    YesOrNo = 4
}

export enum IdentifierTypeEnum {
    NPI = 1,
    NABP = 2,
    PATIENT = 3,
    PRESCRIBER = 4
}
