
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";

@Injectable()
export class InventoryService {
    constructor(private _http: HttpClient) {}

    getInvDropdowns(): Observable<any> {
        return this._http.get<any>(constant.GET_Inventory_DropDownsList);
    }

    getVendorById(venId: string, contId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_VendorByID.replace("{vendorID}", "" + venId).replace("{ContractId}", "" + contId)
        );
    }

    getDrugByID(drugId: any, buckId?: any): Observable<any> {
        return this._http.get<any>(constant.GET_Inventory_DrugByID.replace("{drugID}/{bucketId}", "" + drugId + "/" + buckId));
    }

    getDrugHistByID(drugId: any, isOneYear: boolean): Observable<any> {
        return this._http.get<any>(constant.GET_Inventory_DrugHistoryByID
            .replace("{drugID}", "" + drugId)
            .replace("{isOneYear}", "" + isOneYear));
    }


    getViewInventories(invParams: any): Observable<any> {
        return this._http.post<any[]>(
            constant.POST_Inventory_InventoryList,
            invParams
        );
    }

    addInventories(invData: any): Observable<any> {
        return this._http.post<any[]>(
            constant.POST_Inventory_InventoryAdd,
            invData
        );
    }

    getBucketNameSuggest(value: any) {
        return this._http.get<any>(
            constant.GET_Inventory_BucketNameSuggest.replace("{searchText}", "" + value)
        );
    }

    getBucketDrugNameSuggest(searchText: any): Observable<any> {
        return this._http.get<any>(constant.GET_Drug_Suggest.replace("{drugId}", "" + searchText));
    }

    getDrugNDCSuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_DrugNDCSuggest.replace("{searchText}", "" + value)
        );
    }

    getMadeBySuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_MadeBySuggest.replace("{searchText}", "" + value)
        );
    }

    getVendorNameSuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Vendor_NameSuggest.replace("{searchText}", "" + value)
        );
    }

    getPONumSuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_PoNumSuggest.replace("{searchText}", "" + value)
        );
    }

    getEmptyObservable(): Observable<any> {
        return observableOf([]);
    }

    deleteInventory(data: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Inventory_UpdateInventory,data
        );
    }

    getPhyInvData(drugId?,bucketId?):  Observable<any>{
        return this._http.get<any>(
           constant.GET_Inventory_PhysclInvn.replace("{drugId}/{bucketId}" , "" + drugId + "/" + bucketId));

    }

    addPhyscialInventoryData(phyInvData: any): Observable<any> {
        return this._http.post<any[]>(
            constant.POST_Inventory_PhysclInvn,
            phyInvData
        );
    }

    updatePhyInvData(phyInvData:any): Observable<any>{
        return this._http.put<any[]>(
            constant.Update_Inventory_PhysclInvn,
            phyInvData
        );
    }
    processPhyInv(list:any): Observable<any>{
        return this._http.post<any[]>(
            constant.POST_Inventory_PhysclInvn_Process,
            list
        );
    }


    deletePhyInvData(id:any): Observable<any>{
        return this._http.delete<any>(
            constant.DELETE_Inventory_PhysclInvn.replace(
               "{inventoryId}" ,""+id
            )
        );
    }

    drugBuckManagInv(payload: any): Observable<any> {
        return this._http.put<any>(constant.POST_ADD_DRUG_TO_BUCKET_MNG_INV, payload);
    }

    getDrugPoForBucket(drugId: number, buckId: number): Observable<any> {
        return this._http.get<any>(constant.GET_BUCK_DRUGPO.replace("{drugId}/{bucketId}", "" + drugId + "/" + buckId));
    }

    getDrugBucketInvHistory(drugId: number, buckId: number, considerBucket = false): Observable<any> {
        return this._http.get<any>(constant.GET_DRUG_BUCK_INV_HIST.replace("{drugId}/{bucketId}/{considerBucket}",
            "" + drugId + "/" + buckId + "/" + considerBucket));
    }

    getDrugInventoryInfo(drugId, buckId) {
        return this._http.get(constant.GET_DRUG_BUCK_INV_INFO.replace("{drugId}/{bucketId}", "" + drugId + "/" + buckId));
    }

    getDrugQtyInDifferentBuckets(drugid) {
        return this._http.get(constant.GET_Drug_CheckQty.replace("{drugid}","" + drugid));
    }

    getLatestPriceUpdateDate(drugid) {
        return this._http.get(constant.GET_Drug_LatestPriceUpdateDate.replace("{drugid}","" + drugid));
    }
}
