    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            <h4>Cover My Meds Information</h4>
        </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-dropdown-overflow">
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <span class="header--sml__htex"> Patient - </span>
                            <span class="header--sml__text">
                                <span class="header--sml__lstName">
                                    {{ rxData?.Patient?.lastname | uppercase }}</span><span
                                    *ngIf="rxData?.Patient?.lastname && rxData?.Patient?.firstname ">,&nbsp;</span><span
                                    class="header--sml__fstName">
                                    {{ rxData?.Patient?.firstname | uppercase }}</span>
                            </span>
                        </div>
                    </div>
                    <app-rx-patient-card *ngIf="showPatient" [RxPatientInfo]="rxData?.Patient"></app-rx-patient-card>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-6">
                                <eprx-input [LabelText]="'Drug Name'" [HasControl]="false" [ReadOnly]="true" [InputValue]="( rxData?.Drug?.drugname ) + ' ' +( rxData?.Drug?.strength ) +
                                    ' ' + (rxData?.Drug?.drugformname ) | uppercase">
                                </eprx-input>
                            </div>
                            <div class="col-6">
                                <eprx-input [LabelText]="'NDC'" [HasControl]="false" [ReadOnly]="true" [InputValue]="rxData.Drug.ndc">
                                </eprx-input>
                            </div>
                            <div class="col-6">
                                <eprx-input [LabelText]="'Prescriber'" [HasControl]="false" [ReadOnly]="true"
                                    [InputValue]="rxData?.Prescriber?.prescriberlastname + ', ' + rxData?.Prescriber?.prescriberfirstname">
                                </eprx-input>
                            </div>
                            <div class="col-6">
                                <eprx-input [LabelText]="'Rx#'" [HasControl]="false" [ReadOnly]="true"
                                    [InputValue]="rxData?.Prescription?.PrescNum">
                                </eprx-input>
                            </div>
                            <div class="col-12 font-weight-bold">
                                Message Response:
                            </div>
                            <div class="col-12">
                                <span appHighlight [searchedWords]="messageType" [text]="priorResp?.message"
                                    [classToApply]="messageType=='Rejected' ? 'text-bg-red' : messageType=='Accepted' ? 'text-bg-green':''"></span>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-4" *ngIf="priorResp?.primary_action">
                                <div class="row">
                                    <div class="col-12 font-weight-bold">
                                        Primary Action(s)
                                    </div>
                                    <div class="col-12">
                                        <a class="text-decoration_underline prior-apprvl-link" target="_blank" [href]="priorResp?.primary_action?.url">{{priorResp?.primary_action?.button_label}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4" *ngIf="priorResp?.actions">
                                <div class="row">
                                    <div class="col-12 font-weight-bold">
                                        Available Action(s)
                                    </div>
                                    <div class="col-12">
                                        <span *ngIf="priorResp?.actions?.info"><a class="text-decoration_underline prior-apprvl-link" target="_blank" [href]="priorResp?.actions?.info?.url">{{priorResp?.actions?.info?.button_label}}</a></span> &nbsp;&nbsp;&nbsp;
                                        <span *ngIf="priorResp?.actions?.start"><a class="text-decoration_underline prior-apprvl-link" target="_blank" [href]="priorResp?.actions?.start?.url">{{priorResp?.actions?.start?.button_label}}</a></span> &nbsp;&nbsp;&nbsp;
                                        <span *ngIf="priorResp?.actions?.cancel"><a class="text-decoration_underline prior-apprvl-link" target="_blank" [href]="priorResp?.actions?.cancel?.url">{{priorResp?.actions?.cance?.button_label}}</a></span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span> Close</button>
    </div>
