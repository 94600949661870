<a (click)="selectedPresc(inputData)" *ngIf="!isAllToShow && !showPrescName">
    <div class="card prescriber-card">
        <div class="drug-row-no">{{index+1}}</div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-md-11">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="card__subTit--head">
                                    {{
                                        "prescriber-result-card.Prescriber_Name"
                                            | translate
                                    }}
                                </div>
                                <div class="card__subTit--title">
                                    <span appHighlight    [searchedWords]="searchTerm.split(',')[0]" [text]='inputData?.prescriberlastname | uppercase'></span>,
                                    <span appHighlight  [searchedWords]="searchTerm.split(',')[1] ? searchTerm.split(',')[1].trim() : searchTerm" [text]='inputData?.prescriberfirstname | uppercase'></span>
                                    <!-- {{
                                        inputData?.prescriberlastname +
                                            ", " +
                                            inputData?.prescriberfirstname
                                            | uppercase
                                    }} -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-9 word_Space_Pre_Line">
                            <div class="row">
                                <div class="card__subTit--head">
                                    {{
                                        "prescriber-result-card.Prescriber_Address"
                                            | translate
                                    }}
                                </div>
                                <div class="card__subTit--title">
                                    {{
                                        (PrescAddress?.AddressLine1
                                            ? PrescAddress?.AddressLine1 + ","
                                            : "") | uppercase
                                    }}
                                    {{
                                        (PrescAddress?.AddressLine2
                                            ? PrescAddress?.AddressLine2 + ","
                                            : "") | uppercase
                                    }}
                                    {{
                                        (PrescAddress?.CityName
                                            ? PrescAddress?.CityName + ","
                                            : "") | uppercase
                                    }}
                                    {{
                                        (PrescAddress?.StateName
                                            ? PrescAddress?.StateName + ","
                                            : "") | uppercase
                                    }}
                                    {{
                                        PrescAddress?.ZipCode
                                            ? PrescAddress?.ZipCode
                                            : ""
                                    }}
                                </div>
                            </div>
                        </div>

                        <div class="col-3 space_overflow">
                            <div class="row">
                                <div class="card__subTit--head">
                                    {{ "prescriber-result-card.License" | translate }}
                                </div>
                                <div class="card__subTit--title">
                                    <span appHighlight  [searchedWords]="searchTerm" [text]='inputData?.prescriberlicensenumber ? inputData?.prescriberlicensenumber : ""'></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="card__subTit--head">
                                    {{ "prescriber-result-card.DEA" | translate }}
                                </div>
                                <div class="card__subTit--title">
                                    <span appHighlight   [searchedWords]="searchTerm" [text]='(_formatsUtil.getDeaFormat(inputData?.prescriberdeanum)) | uppercase'></span>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="card__subTit--head">
                                    {{ "prescriber-result-card.NPI" | translate }}
                                </div>
                                <div class="card__subTit--title">
                                    <span appHighlight   [searchedWords]="searchTerm" [text]='inputData?.prescribernpinum'></span>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="row">
                                <div class="card__subTit--head">
                                    {{ "prescriber-result-card.Phone" | translate }}
                                </div>
                                <div class="card__subTit--title">
                                    <span appHighlight [fromPhone]="true" [searchedWords]="searchTerm ? (searchTerm | mask : '[(]000[)]000-000000000' ) : ''"
                                    [searchWithText] = "inputData?.telephone && inputData?.telephone !== '###' ? inputData?.telephone : ''" [searchWord2]="searchTerm"
                                    [text]='inputData?.telephone && inputData?.telephone !== "###" ? (inputData?.telephone | mask : "(000)000-0000" ) : " "'></span>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="card__subTit--head">
                                    {{ "prescriber-result-card.QuickCode" | translate }}
                                </div>
                                <div class="card__subTit--title">
                                    {{inputData?.prescriberquickcode ? inputData?.prescriberquickcode : ''}}
                                    <!-- <span appHighlight [searchedWords]="searchTerm" [text]='inputData?.prescriberquickcode'></span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-1 padding-0 prescriber--icon">
                    <i class="far fa-user-md doc--icon" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </div>
</a>

<a (click)="selectedPresc(inputData)" *ngIf="showPrescName">
    <div class="card prescriber-card">
        <div class="row">
            <div class="col-md-11">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="card__subTit--title">
                                {{
                                    inputData?.prescriberlastname +
                                        ", " +
                                        inputData?.prescriberfirstname
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>
