import { Injectable } from "@angular/core";
import {
    Router,
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from "@angular/router";
import { UserService, CommonService } from "../services";
import { SystemData, SystemSetting } from "../models";

import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";

// this is a example to check how resolver woks
// Do no touch this unless it is necessary

@Injectable()
export class UserPrivsResolver implements Resolve<any> {
    constructor(private _userServ: UserService, private router: Router) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        const userPrivs = this._userServ.getUserPrivsFromCookies();
        if (userPrivs) {
            return userPrivs;
        } else {
            this.router.navigate(["/login"]);
            return null;
        }
    }
}

@Injectable()
export class SystemDataResolver implements Resolve<SystemData> {
    constructor(private _commonService: CommonService) {}

    resolve(): Observable<SystemData> {
        // This will return Observable and that will be subscribed in common compoenent
        // There we are checking system data is null or has data?
        // Based on that we are redirecting to login page
        return this._commonService.getSystemDataForResolver();
    }
}

@Injectable()
export class SystemSettingsResolver implements Resolve<any> {
    settingsData: any = {
        SearchText: "",
        SearchBy: "",
        OrderByColumn: "",
        SortDirection: "",
        PageNumber: 0,
        PageSize: 0
    };

    constructor(private _commonService: CommonService) {}

    resolve(): Observable<any> {
        // This will return Observable and that will be subscribed in common compoenent
        // There we are checking system data is null or has data?
        return this._commonService
            .getSystemSettingsWithoutBS(this.settingsData);
            // .catch(() => {
            //     return Observable.of("data not available at this time");
            // });
    }
}
