<ng-template #VERFCONFIRMATION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');removeVerfication(false)">
        <h4 header> Confirmation </h4>
        <span body>
            <span>{{verfMsg}} records exists for this Rx. <br/>
                Do you want to Re-Verify?  </span>
        </span>
        <button footer class="hotkey_primary"
        ngbAutofocus (click)="c('Close click');removeVerfication(true)" (keydown.enter)="c('Close click');removeVerfication(true)" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary"
        (click)="c('Close click');removeVerfication(false)" (keydown.enter)="c('Close click');removeVerfication(false)" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>
