<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Inventory'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                <button class="hotkey_success" id="SaveButton" (click)="getVendorItem(drugId)">
                    Vendor Item Codes
                </button>
                <button class="hotkey_success mr-0" (keydown.tab)="focusOutFmCan($event)"
                    (click)="CancelPopTaggle()">
                    <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
    <!--vendor item codes start-->
    <div class="row">
        <div class="col-xl-12 col-lg-12" *ngIf="show">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="row">
                        <div class="col">
                            <span class="eprx--header__heading">Vendor Drug Information</span>
                        </div>
                        <div class="col pull-right text-right">
                            <button aria-hidden="true" autofocus class="hotkey_success  hotkey_primary--Override"
                                title="Add" id="addButton" (click)="openPopUpModal(vendoritem)" appShortcutKey
                                [AltKey]="'a'">
                                <span>A</span>ADD
                            </button>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content" *ngIf="hasVendor">
                    <wj-flex-grid #Vendor [headersVisibility]="'Column'" [itemsSource]="vendorInfoWJ"
                        [columnPicker]="''" (updatedView)="init(Vendor)" [selectionMode]="'None'">
                        <div>
                            <wj-flex-grid-column [header]="vendoractvHeader" [binding]="vendoractvHeader"
                                [isReadOnly]="true" [visible]="true" *ngFor="
                                    let vendoractvHeader of vendoractvHeaders;
                                    let i = index
                                " [width]="'*'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div *ngIf="vendoractvHeader === 'Actions'" class="text-center">
                                        <i class="far fa-edit actions" title="edit" (click)="
                                                editVendorInformation(
                                                    vendoritem,
                                                    item
                                                )
                                            "></i>

                                        <i class="far fa-trash-alt actions danger-actve" (click)="
                                                deleteWarnPopUp(
                                                    deleteVendor,
                                                    item
                                                )
                                            " title="delete"></i>
                                    </div>
                                    <div *ngIf="
                                            vendoractvHeader === 'Discontinue'
                                        "  class="text-center">
                                        <input class="form-check-input" type="checkbox" [id]="item.Id" name=""
                                            [(ngModel)]="item.Discontinue" (change)="
                                                discont(
                                                    $event?.target?.checked,
                                                    item,
                                                    warnngDis
                                                )
                                            " [checked]="item.Discontinue" />
                                        <label class="form-check-label" [for]="item.Id">
                                        </label>
                                    </div>
                                    <div *ngIf="
                                            !(
                                                vendoractvHeader ===
                                                    'Actions' ||
                                                vendoractvHeader ===
                                                    'Discontinue' ||
                                                vendoractvHeader ===
                                                    'AWP Pack' ||
                                                vendoractvHeader ===
                                                    'Cost Pack' ||
                                                vendoractvHeader ===
                                                    'Unit Price Cost' ||
                                                vendoractvHeader ===
                                                    'Unit Price AWP'
                                            )
                                        ">
                                        {{ item[vendoractvHeader] }}
                                    </div>
                                    <div *ngIf="
                                            vendoractvHeader === 'AWP Pack' ||
                                            vendoractvHeader === 'Cost Pack'
                                        ">
                                        {{
                                            item[vendoractvHeader]
                                                | currency: "$":"symbol":"1.2-2"
                                        }}
                                    </div>
                                    <div *ngIf="
                                            vendoractvHeader ===
                                                'Unit Price Cost' ||
                                            vendoractvHeader ===
                                                'Unit Price AWP'
                                        ">
                                        {{
                                            item[vendoractvHeader]
                                                | currency: "$":"symbol":"1.3-3"
                                        }}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                        </div>
                    </wj-flex-grid>
                </div>
                <div *ngIf="!hasVendor" class="eprx--block__footer">
                    <div>
                        <span></span>
                        <i class="fa fa-file"></i><br/>
                        No Data Found!
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--vendor item codes end-->

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 padding-sm-15 exprx--common-block">
            <div class="box  box-solid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Inventory Information
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-3 inventory inventory--color1">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_pills1_white.svg"
                                        title="Quantity In Hand (this NDC)" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ qtyinHand | number: "1.3-3" }}</h3>
                                    <div class="inv--description">
                                        <h5>Quantity In Hand (this NDC)</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 inventory inventory--color2">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_pills_white.svg"
                                        title="Quantity In Hand (Equivalent drug)" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ qtyEqu | number: "1.3-3" }}</h3>
                                    <div class="inv--description">
                                        <h5>
                                            Quantity In Hand (Equivalent drug)
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 inventory inventory--color3">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_History.svg" title="Last Received" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ lastReceived }}</h3>
                                </div>
                                <div class="inv--description">
                                    <h5>Last Received</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 inventory inventory--color4">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_Pickup.svg" title="Last Dispensed" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ lastDispensed }}</h3>
                                </div>
                                <div class="inv--description">
                                    <h5>Last Dispensed</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Inventory Received History
                    </div>
                </div>
                <div class="box-body">
                    <wj-flex-grid #inventorylist [headersVisibility]="'Column'" [itemsSource]="inventoryWJ"
                        [isReadOnly]="true" [columnPicker]="''" class="max-height_18_2rem" [selectionMode]="'None'"
                        (updatedView)="init(inventorylist)" *ngIf="hasTrans === true">
                        <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="
                                let actvHeader of actvHeaders;
                                let i = index
                            " [width]="'*'" [format]="actvHeader === 'Price' ? 'c2' : ''">
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                    <div *ngIf="hasTrans === false" class="eprx--block__footer">
                        <div>
                            <span></span>
                            <i class="fa fa-file"></i><br/>
                            No Data Found!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 exprx--common-block">
            <div class="row" [formGroup]="orderInformationFG">
                <div class="col-md-12 padding-sm-15">
                    <div class="box box-default box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Order Information
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                    <eprx-select [RxSelectId]="'ManageInv'" [LabelText]="'Manage Inventory'"
                                        [PlaceHolder]="''" [ControlName]="'IsInvMangd'" [FormGroupName]="Drug"
                                        [HasMultiple]="false" [IsFirstElem]="true" [BindLabel]="'Name'"
                                        [BindValue]="'Id'" [LabelForId]="'Id'" [List]="[
                                            { Id: true, Name: 'Yes' },
                                            { Id: false, Name: 'No' }
                                        ]" (TriggerSelectValue)="checkIsPhysicalInventory(PRIVSWARNING)">
                                    </eprx-select>
                                </div>
                                <div class="col-md-12">
                                    <eprx-radio [LabelText]="' Auto. Order '" [ControlName]="'IsAutoPO'"
                                        [FormGroupName]="DrugPO" [IsRequired]="true" [List]="['Yes', 'No']"
                                        [ValueList]="[true, false]" [IDForList]="['yes', 'no']" [LabelForId]="">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Reorder level'" [PlaceHolder]="''"
                                        [ControlName]="'ReorderQty'" [FormGroupName]="DrugPO" [InputType]="'NUMBER'"
                                        [MaxLength]="6" [DropSpclChar]="false">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Target Qty'" [PlaceHolder]="''"
                                        [ControlName]="'TargetQty'" [FormGroupName]="DrugPO" (keydown)="
                                            changeValue('TargetQty', 3, $event)
                                        " [MaskPattern]="'099999.000'" [DropSpclChar]="false" [DecimalValues]="3"
                                        [InputType]="'NUMBER'" (TriggerSearchValue)="
                                            setNumberFormat('TargetQty')
                                        ">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                    <eprx-select [LabelText]="'Preferred Vendor'" [PlaceHolder]="'Preferred Vendor'"
                                        [ControlName]="'PrefVendId'" [FormGroupName]="DrugPO" [HasMultiple]="false"
                                        [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Code'"
                                        [List]="defVal?.Vendor">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Last Vendor'" [PlaceHolder]="'Last Vendor'"
                                        [ControlName]="'lastVendor'" [FormGroupName]="orderInformationFG"
                                        [ReadOnly]="true">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [RxSelectId]="'MinOrderQty'" [LabelText]="'Minimum Order Qty'"
                                        [PlaceHolder]="'Minimum Order Qty'" [ControlName]="'MinOrderQty'"
                                        [FormGroupName]="DrugPO" [DecimalValues]="3" [MaxLength]="6"
                                        [InputType]="'NUMBER'">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [RxSelectId]="'OrderQty'" [LabelText]="'Qty On Order'"
                                        [PlaceHolder]="'Qty On Order'" [MaxLength]="6" [ControlName]="'OrderQty'"
                                        [FormGroupName]="DrugPO" [InputType]="'NUMBER'">
                                    </eprx-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 padding-sm-15 text-right pull-right">
                    <button class="hotkey_success" (click)="getDrugData()">
                        Inventory Trans.
                        Audit
                    </button>
                    <button class="hotkey_success" (click)="addInvenRoute()" *ngIf="Drug.value['IsInvMangd'] === true">
                        Add Inventory
                    </button>
                    <button class="inactive" [tabindex]="-1" *ngIf="Drug.value['IsInvMangd'] === false">
                        Add Inventory
                    </button>
                    <button class="hotkey_success" (click)="updateDrug()" (keydown.tab)="focusToFirst($event)">
                        <span>S</span> Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- vendor info-->
<ng-template #vendoritem let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Vendor - Drug Definition</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Close click'); resetForm()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" [formGroup]="vendorItemFG">
        <div class="row">
            <div class="col-md-6">
                <label class="col-md-12 padding-0">Drug Name</label>
                <div class="label--data">
                    {{ drugInformation?.Drug?.DrugName }}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 padding-0">NDC </label>
                <div class="label--data">
                    {{  this._formatsUtil.getNDCFormat(drugInformation?.Drug?.BillingNdc, true) }}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 padding-0"> Strength </label>
                <div class="label--data">
                    {{ drugInformation?.Drug?.Strength }}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 padding-0">Form</label>
                <div class="label--data">
                    {{ drugInformation?.Drug?.FormName }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <eprx-input [AutoFocus]="true" [LabelText]="'Vendor Item Code'"
                    [PlaceHolder]="'only numerical values are accepted'" [ControlName]="'ItemCode'"
                    [FormGroupName]="vendorItemFG">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-select [LabelText]="'Vendor Code'" [PlaceHolder]="'Vendor Code'" [ControlName]="'VendorId'"
                    [FormGroupName]="vendorItemFG" [AutoFocus]="true" [List]="drugInformation?.Vendor"
                    [HasMultiple]="false" [BindLabel]="'VendorName'" [BindValue]="'Id'" [LabelForId]="'VendorName'"
                    [InputErrors]="VendorId?.errors" [IsRequired]="true" [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formGroupInvalid">
                </eprx-select>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Unit Price - AWP'" [PlaceHolder]="'Unit Price - AWP'"
                    [ControlName]="'UnitAWP'" [FormGroupName]="vendorItemFG" [InputErrors]="UnitAWP?.errors"
                    [ErrorDefs]="{ pattern: 'Invalid!' }" [MaxLength]="10" (mouseup)="convertByMouseDown('UnitAWP')"
                    (keydown)="changeValue('UnitAWP', 2, $event)" [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'AWP Pack'" [PlaceHolder]="'AWP Pack'" [ControlName]="'AWP'"
                    [FormGroupName]="vendorItemFG" [InputErrors]="AWP?.errors" (mouseup)="convertByMouseDown('AWP')"
                    [ErrorDefs]="{ pattern: 'Invalid!' }" [MaxLength]="10" (keydown)="changeValue('AWP', 2, $event)"
                    [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Unit Price - Cost'" [PlaceHolder]="'Unit Price - Cost'"
                    [ControlName]="'UnitCost'" [FormGroupName]="vendorItemFG" [InputErrors]="UnitCost?.errors"
                    [ErrorDefs]="{ pattern: 'Invalid!' }" [MaxLength]="10" (mouseup)="convertByMouseDown('UnitCost')"
                    (keydown)="changeValue('UnitCost', 2, $event)" [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Cost Pack'" [PlaceHolder]="'Cost Pack'" [ControlName]="'PackCost'"
                    [FormGroupName]="vendorItemFG" (mouseup)="convertByMouseDown('PackCost')"
                    [InputErrors]="PackCost?.errors" [ErrorDefs]="{ pattern: 'Invalid!' }" [MaxLength]="10"
                    (keydown)="changeValue('PackCost', 2, $event)" [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="addVendorDrugInfo()" *ngIf="editMode === true" appShortcutKey
                    [AltKey]="'s'">
                    <span>S</span>Save
                </button>
                <button class="hotkey_success" (click)="updateVendorDrugInfo()" *ngIf="editMode === false"
                    appShortcutKey [AltKey]="'u'">
                    <span>U</span>Update
                </button>
                <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="resetForm()">
                    <span>C</span>Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- error pop-->
<ng-template #error let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Close click'); setFocus()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Qty On Order cannot be less than Minimum Order Qty.</p>
    </div>
    <div class="modal-footer">
        <button autofocus class="hotkey_primary" (click)="c('Close click'); setFocus()" appShortcutKey InputKey="c"
            (keydown.esc)="c('Close click'); setFocus()" (keydown.enter)="c('Close click'); setFocus()">
            <b>C</b> Close
        </button>
    </div>
</ng-template>
<ng-template #deleteVendor let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">{{warningText?.deleteConfrmMsg}}</div>
    <div class="modal-footer">
        <div class="text-right pull-right">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteVendorDrugInfo()"
                    appShortcutKey InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c">
                    <b>C</b>CANCEL
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #warnngDis let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="disContinue">
        Are you sure you want to discontinue vendor?
    </div>
    <div class="modal-body" *ngIf="!disContinue">
        Are you sure you want to activate vendor?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="vendorDisContinue()" appShortcutKey
                    InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c">
                    <b>C</b>CANCEL
                </button>
            </div>
        </div>
    </div>
</ng-template>

<app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)"></app-cancel-pop-up>


<ng-template #PRIVSWARNING let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Close click'); setFocus()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p> You must have Physical inventory privileges to enable this option.</p>
    </div>
    <div class="modal-footer">
        <button autofocus class="hotkey_primary" (click)="c('Close click'); setFocus()" appShortcutKey InputKey="c"
            (keydown.esc)="c('Close click'); setFocus()" (keydown.enter)="c('Close click'); setFocus()">
            <b>C</b> Close
        </button>
    </div>
</ng-template>

<ng-template #InventoryTrans let-c="close" let-d="dismiss">
    <div class="modal-header">Inventory Transaction Audit Report
    </div>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Close click'); setFocus()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
    <div class="modal-body overflow_x_hidden overflow_y_scroll height_50rem" id="inventory">
        <h4 class="margin_top_0_75rem margin_bottom_1_75rem text-center">Inventory Transaction Audit Report for {{Drug.value?.Name}} {{Drug.value?.Strength}} {{Drug.value?.MeasureUnitName}} {{Drug.value?.DrugFormName}} NDC: {{this._formatsUtil.getNDCFormat(Drug.value.DrugCode) }}</h4>
        <table class="table table-hover">
            <tr>
                <th>Trans. Type</th>
                <th>Transaction Date</th>
                <th>Referenece</th>
                <th>Trans Qty</th>
            </tr>
            <tbody>
                <tr *ngFor="let val of inventoryHistory; let k=index">
                    <td>{{val.TransactionType}}</td>
                    <td>{{val.TransactionDate | localTime  | date:"MM/dd/yyyy h:mm:ss a"}}</td>
                    <td>{{val.Ref}}</td>
                    <td>{{val.DispQty}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button class="hotkey_success" (click)="print()" appShortcutKey [AltKey]="'p'"><span>P</span>Print
        </button>
        <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span>Close
        </button>
    </div>
</ng-template>

<app-physical-inventory1 *ngIf="isPhysicalInventEnabl" [drugInfo]="inventoryInfo" [drugDefVal]="defVal"
    (CancelPhyscInven)="closendclearMangInv($event)"></app-physical-inventory1>
