<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-8 col-xs-6 pagination">
                <ngb-pagination
                    class="d-flex justify-content-center"
                    [collectionSize]="totalCount"
                    [(page)]="pageNumber"
                    [maxSize]="5"
                    [id]="rxId"
                    [rotate]="true"
                    [pageSize]="pageSize"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    (pageChange)="setPage($event)"
                >
                </ngb-pagination>
                <span class="margin_top_0_8rem margin_left_2rem" *ngIf="(gridlabelName !== 'RxHistory' && gridlabelName !== 'notVerifiedListGrid' && gridlabelName !== 'VerifiedListGrid')">
                    <label >Total Records: </label>
                    {{ totalCount}}
                </span>
                <span class="margin_top_0_8rem margin_left_2rem" *ngIf="gridlabelName === 'RxHistory'">
                    <div> <!--There are--> {{ totalCount }} Total Rx(s)
                        <span *ngIf="(billToStatus =='All') && !(isSelectedType == 'Current')"> and {{ TotalFiledRxs }} Deferred Rx(s)</span>
                        <span *ngIf="TotalCopayForSelectedRxs!==null" class="ml-5 font-weight-bold">Total Copay for Selected Rx(s): {{TotalCopayForSelectedRxs | currency: "$":"symbol":"1.2-2"}}</span>
                    </div>
                </span>
                <span class="margin_top_0_8rem margin_left_2rem" *ngIf="gridlabelName === 'notVerifiedListGrid'">
                    <label >Total Records: </label>
                    {{ screenTotalNVRecords}}
                </span>

                <span  class="margin_top_0_8rem margin_left_2rem" *ngIf="gridlabelName === 'VerifiedListGrid'">
                    <label >Total Records: </label>
                    {{ screenTotalVerfRecords}}
                </span>
                <span class="margin_top_0_8rem margin_left_2rem" *ngIf="hasSecondCount">
                    <label >{{secondName}}: </label>
                    {{ secondCount}}
                </span>
                <span class="margin_top_0_8rem margin_left_2rem" *ngIf="hasThirdCount">
                    <label>{{thirdName}}: </label>
                    {{ thirdCount}}
                </span>
            </div>
            <div class="col-lg-3 col-md-2 col-sm-4 col-xs-6" *ngIf="!excludeSize">
                <div class="row">
                    <div class="col-2 top_0_5rem right_4rem" *ngIf="isPrint && hasExport">
                        <button  type="submit" class="hotkey_success pull-right p-0"  id="Print" appShortcutKey (click)="printReport(true)"
                        [AltKey]="AltShortCutKey" >
                        <span>{{IsShortCutKey}}</span> Print
                        </button>
                    </div>
                    <div
                        [ngClass]="hasExport? ' col-10 float-right px-0' : 'col-10 float-right'"
                    >
                        <div class="col-sm-10 erx-pageSize-select" *ngIf="!incPageSize">
                            <select
                                id="pagination"
                                class="rx--select"
                                (change)="setSize($event?.target?.value)"
                                (keydown.tab)="focusOut($event)"
                                [(ngModel)]="pageSize"
                            >
                                <option value="15" id="pageSz15"> 15 </option>
                                <option value="30" id="pageSz30"> 30 </option>
                                <option value="45" id="pageSz45"> 45 </option>
                                <option value="60" id="pageSz60"> 60 </option>
                                <option value="100" id="pageSz100"> 100 </option>
                                <option value="200" id="pageSz200"> 200 </option>
                                <option value="500" id="pageSz500"> 500 </option>
                                <option value="1000" id="pageSz1000"> 1000 </option>
                            </select>
                        </div>
                        <div class="col-sm-10 erx-pageSize-select" *ngIf="incPageSize">
                            <select
                                id="pagination"
                                (keydown.tab)="focusOut($event)"
                                class="rx--select"
                                (change)="setSize($event?.target?.value)"
                            >
                                <option [value]="i* incPageSize" [id]="i* incPageSize" *ngFor="let i of [1,2,3,4]"> {{i* incPageSize}} </option>
                            </select>
                        </div>
                    </div>
                    <div class=" col-md-1  text-right" *ngIf="!hasExport">
                        <i class="far fa-file-export ExportIcon" title="Export" *ngIf="!fromModal && !IsShortCutKey" id="Export"
                            (click)="exportExcel()" (keydown.tab)="focusOut($event)"></i>
                        <i class="far fa-file-export ExportIcon" title="Export" *ngIf="IsShortCutKey" id="Export" (click)="exportExcel()"
                            (keydown.tab)="focusOut($event)"></i>
                        <i class="far fa-file-export ExportIcon" title="Export" *ngIf="fromModal && !IsShortCutKey" id="Export" (click)="exportExcel()" 
                            (keydown.tab)="focusOut($event)"></i>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
    
    </div>

