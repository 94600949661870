import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { fabric } from "fabric";
import { ImageEditorCropComponent } from "../image-editor-crop/image-editor-crop.component";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: "app-image-editor",
  templateUrl: "./image-editor.component.html",
  styleUrls: ["./image-editor.component.scss"]
})
export class ImageEditorComponent implements OnInit, AfterViewInit {
    inputImage: any;
    canvasArea: any;
    showEditImage: boolean = false;

    // @ViewChild("ImageEditTempp")
    // ImageEditTempp: any;

    // @ViewChild("angularCropper")
    // angularCropper: CropperComponent;

    @ViewChild(ImageEditorCropComponent)
    imageCropEditor: ImageEditorCropComponent;

    isRotate: any;
    @Output()
    IsRotateDegrees = new EventEmitter<any>();

    modelRef: any;
    color = "#000000";
    bgImage: any;
    cropMode = false;

    // config = {
    //     dragMode : "move",
    //     background : true,
    //     movable: true,
    //     rotatable : true,
    //     scalable: true,
    //     zoomable: true,
    //     viewMode: 1,
    //     checkImageOrigin : true,
    //     cropmove:this.cropMoved.bind(this),
    //     checkCrossOrigin: true
    // };

    croppedImage: any;

    ZOOM_FACTOR = 0.5;
    currentMode: any;
    shapesFillColor: any;
    originalImage: any;
    cropInputImage: any;
    @Input()  IsFromDocumentQueue: any;
    @Input()
    set ImageData(data: any) {
        if (data) {
            this.originalImage = data;
            this.inputImage = data;
            this.cropInputImage = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        }
    }

    @Input()
    set ImageFile(data: any) {
        if (data) {
            this.readsUrl(data);
        }
    }
    @Input() IsPdf: boolean;
    @Input() enableEdit : boolean;
    @Output()
    IsPopUpClosed = new EventEmitter<any>();
    constructor(private _modalService: NgbModal,private sanitizer: DomSanitizer, private _cdr: ChangeDetectorRef ) {

    }

    ngOnInit() {
        this.canvasArea = new fabric.Canvas("canvasArea", { selection: false });
        fabric.Object.prototype.set({
            transparentCorners: false,
            borderColor: "#0000c5",
            cornerColor: "#ff0000",
            cornerStyle: "circle",

        });
        this.setBackgroundImageToCanvas(this.inputImage);
    }

    readsUrl(event: any) {
        if (event) {
            // tslint:disable-next-line:prefer-const
            let reader = new FileReader();

            // tslint:disable-next-line:no-shadowed-variable
            reader.onload = (event: ProgressEvent) => {
                let base64string = (<FileReader>event.target).result.toString();
                this.originalImage = base64string;
                this.inputImage = this.originalImage;
                this.setBackgroundImageToCanvas(this.inputImage);
            };
            reader.readAsDataURL(event);
        }
    }

    setBackgroundImageToCanvas(image) {
        if (image) {
            fabric.Image.fromURL(image, function (myImg) {
                // var img1 = myImg.set({ left: 0, top: 0, width: 900, height: (myImg.height * (900 / myImg.width)) });
                let img1 = myImg.set({ left: 0, top: 0 });
                img1.scaleToHeight((myImg.height * (971 / myImg.width)));
                img1.scaleToWidth(971);
                this.bgImage = img1;
                this.canvasArea.setHeight((myImg.height * (971 / myImg.width)));
                this.canvasArea.renderAll();
                this.addBackgroundImage();
            }.bind(this));
        }
    }

    addBackgroundImage() {
        this.canvasArea.setBackgroundImage(
            this.bgImage,
            this.canvasArea.renderAll.bind(this.canvasArea));
    }

    ngAfterViewInit() {
    }

    // cropMoved(data) {
    //     this.croppedImage = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    // }


    modeSelected(mode) {
        switch (mode) {
            case "pointer":
                {
                    this.exitCropMode();
                    this.canvasArea.isDrawingMode = false;
                    break;
                }
            case "crop":
                {
                    this.enterCropMode();
                    break;
                }
            case "pencil":
                {
                    this.drawPencil();
                    break;
                }
            case "brush":
                {
                    this.drawBrush();
                    break;
                }
            case "text":
                {
                    this.addText();
                    break;
                }
            case "shapes":
                {
                    this.exitCropMode();
                    this.canvasArea.isDrawingMode = false;
                    break;
                }
            case "zoomin":
                {
                    this.zoomin();
                    break;
                }
            case "zoomout":
                {
                    this.zoomOut();
                    break;
                }
            case "rotateRight":
                {
                    this.rotateImage(true);
                    break;
            }
            case "rotateLeft":
            {
                    this.rotateImage(false);
                break;
            }
            case "undo":
                {
                    this.undoForSlectedCanvasObject();
                    break;
                }
            default:
                {
                    this.canvasArea.isDrawingMode = false;
                    break;
                }
        }
        this.currentMode = mode;
    }
    undoForSlectedCanvasObject() {
        this.canvasArea.getActiveObjects().forEach((obj) => {
            this.canvasArea.remove(obj);
          });
          this.canvasArea.discardActiveObject().renderAll();
    }

    addText() {
        this.exitCropMode();
        this.canvasArea.isDrawingMode = false;
        const text = new fabric.Textbox("Text Here...", {
            fontSize: 24,
            fontWeight: "normal",
            editable: true,
            lockScalingY: false,
            lockUniScaling: false,
            fontFamily: "Arial",
            fill: this.color,
            left: 50,
            top: 50,
            width: 200
        });
        this.canvasArea.add(text);
        this.canvasArea.setActiveObject(text);
        text.enterEditing();
        text.selectAll();
    }


    drawPencil() {
        this.exitCropMode();
        this.canvasArea.isDrawingMode = 1;
        this.canvasArea.freeDrawingBrush.color = this.color;
        this.canvasArea.freeDrawingBrush.width = 3;
        this.canvasArea.renderAll();
    }

    drawBrush() {
        this.exitCropMode();
        this.canvasArea.isDrawingMode = 1;
        this.canvasArea.freeDrawingBrush.color = this.color;
        this.canvasArea.freeDrawingBrush.width = 10;
        this.canvasArea.renderAll();
    }

    closeCrop(croppedImage) {
        if (croppedImage) {
            this.inputImage = croppedImage;
            this.setBackgroundImageToCanvas(this.inputImage);
            this.exitCropMode();
        } else {
            this.exitCropMode();
        }
    }

    enterCropMode() {
        this.cropInputImage = this.canvasArea.toDataURL();
        this.cropMode = true;
    }

    // cropFromCropMode() {
    //     this.croppedImage = this.angularCropper.cropper.crop();
    // }

    exitCropMode() {
        this.cropMode = false;
    }

    // saveFromCropMode() {
    //     this.inputImage = this.croppedImage;
    //     this.exitCropMode();
    //     this.setBackgroundImageToCanvas(this.inputImage);
    // }

    // cancelFromCropMode() {
    //     this.exitCropMode();
    // }

    zoomin() {
        this.enterCropMode();
        if (this.imageCropEditor) {
            this.imageCropEditor.zoomin();
        }
    }

    zoomOut() {
        this.enterCropMode();
        if (this.imageCropEditor) {
            this.imageCropEditor.zoomOut();
        }
    }

    rotateImage(isRotateRight) {
        this.isRotate = !this.isRotate;
         this.canvasArea.backgroundImage.rotate(this.isRotate);
         this.canvasArea.renderAll();
    }
    editImage() {
            this.showEditImage = true;
    }
    colorChange(color) {
        this.color = color;
        let activeObject = this.canvasArea.getActiveObject();
        if (color && activeObject != null) {
            if (this.currentMode === "shapes") {
                activeObject.set("stroke", color);
            } else if (this.currentMode === "pencil" || this.currentMode === "brush") {
                this.canvasArea.freeDrawingBrush.color = this.color;
            } else {
                activeObject.set("fill", color);
            }
            this.canvasArea.renderAll();
        }
    }

    fillColor(color) {
        let activeObject = this.canvasArea.getActiveObject();
        this.shapesFillColor = color;
        if (this.shapesFillColor && activeObject != null) {
            activeObject.set("fill", this.shapesFillColor);
            this.canvasArea.renderAll();
        }
    }

    changeOpacity(value) {
        let activeObject = this.canvasArea.getActiveObject();
        if (value && activeObject != null) {
            activeObject.set("opacity", value);
            this.canvasArea.renderAll();
        }
    }

    insertShape(type) {
        if (type === "line") {
            const horizantal = new fabric.Line([1, 1, 100, 1], {
                stroke: this.color,
                left: 50,
                top: 50
            });
            this.canvasArea.add(horizantal);
            // this.canvasArea.renderAll();
            this.canvasArea.setActiveObject(horizantal);
        } else if (type === "circle") {
            const circle = new fabric.Circle({
                radius: 50,
                // fill: this.color,
                fill:"",
                stroke: this.color,
                strokeWidth: 3,
                left: 70,
                top: 70
            });
            this.canvasArea.add(circle);
            // this.canvasArea.renderAll();
            this.canvasArea.setActiveObject(circle);
        } else if (type === "rect") {
            const rect = new fabric.Rect({
                width: 100,
                height: 100,
                // fill: this.color
                fill:"",
                stroke: this.color,
                strokeWidth: 3,
                left: 20,
                top: 100
            });
            this.canvasArea.add(rect);
            // this.canvasArea.renderAll();
            this.canvasArea.setActiveObject(rect);
        } else if (type === "triangle") {
            const triangle = new fabric.Triangle({
                width: 100,
                height: 100,
                // fill: this.color
                fill:"",
                stroke: this.color,
                strokeWidth: 3,
                left: 60,
                top: 80
              });
            this.canvasArea.add(triangle);
            // this.canvasArea.renderAll();
            this.canvasArea.setActiveObject(triangle);
        }
    }

    saveImage() {
        // this.inputImage = this.canvasArea.toDataURL();
        this.showEditImage = this.enableEdit ? true : this.showEditImage;
        if(!this.showEditImage) {
            this.IsPopUpClosed.emit("onlyEdit");
            return;
        } else {
            let editedImage = this.canvasArea.toDataURL();
            this.IsPopUpClosed.emit(editedImage);
        }
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }

}
