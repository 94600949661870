import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as _ from "lodash";
import { CommonService } from "src/app/services";
import { FormatsUtil } from "src/app/utils/formats.util";
@Component({
    selector: "app-rx-drug-card",
    templateUrl: "./rx-drug-card.component.html"
})
export class RxDrugCardComponent implements OnInit, OnChanges {       
    @Input()
    set DrugInfo(pi: any) {
        if (pi) {
            pi.unitpriceawp = (pi.unitpriceawp) ? this.replaceString(pi.unitpriceawp) : 0.000;
            pi.awppack = (pi.awppack) ? this.replaceString(pi.awppack) : 0.00;
            if (this.isUnitCostChnaged) {
                this.isUnitCostChnaged = null
                pi.unitpricecost = this.unitCost
                pi.costpack = this.costPack
            } else {
                pi.unitpricecost = (pi.unitpricecost) ? this.replaceString(pi.unitpricecost) : 0.000;
                pi.costpack = (pi.costpack) ? this.replaceString(pi.costpack) : 0.00;
            }
            this.drugInfo = pi;
        }
    }

    @Input()
    set DrugInfoFromEditDrug(di: any) {
        if (di) {
            this.drugInfoFromEditDrug = di;
        }
    }
    @Input() IsFromErxRequest: any;
    @Input() PrescrefillId;
    @Input() isFromErx;
    @Input() DrugFormBarcode;
    @Input() RxType;
    drugInfo: any;
    drugInfoFromEditDrug: any;
    drugForm: string;
    isUnitCostChnaged = false;
    unitCost: any;
    costPack: any;
    Is340BStatus:boolean;
    constructor(public commonserv: CommonService, public _formatsUtil:FormatsUtil) {}

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges){
        this.durgCheckis340b();
        if(changes?.DrugInfo && changes?.DrugInfo?.previousValue && (changes?.DrugInfo?.previousValue?.id != changes?.DrugInfo?.currentValue?.id)) {
            this.commonserv.deleteDscsa(changes?.DrugInfo?.previousValue?.id).toPromise();
        }
    }
    async durgCheckis340b(){
        const settings = await this.commonserv.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        this.Is340BStatus = (this.drugInfo?.Is340B && settings)? true : false;
    }
    ngAfterViewInit() {
        this.commonserv._drugUnitCostChange$.subscribe(res=>{
            if (res && this.drugInfo) {
                this.isUnitCostChnaged = true;
                this.drugInfo.unitpricecost = res.unitCost,
                this.drugInfo.costpack = res.costPack
                this.unitCost = res.unitCost,
                this.costPack = res.costPack
            }
        })
    }

    replaceString(priceVal){
        let newVal;
        newVal = (priceVal + "").replace("$", "");
        return +newVal;
    }
}
