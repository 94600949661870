<ng-template #PRINTREFILLREQUEST let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{ refillHeader }}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closeRefillRequest()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="eprx--block__content">
                    <div *ngIf="!printRefWJ" class="text-center">
                        <h5>No Verification History available for this Rx</h5>
                    </div>
                    <span *ngIf="printRefWJ">
                        <wj-flex-grid
                            #printRef
                            [headersVisibility]="'Column'"
                            [itemsSource]="printRefWJ"
                            [isReadOnly]="true"
                            [selectionMode]="'None'"
                        >
                            <wj-flex-grid-column
                                [header]="'FROM NAME'"
                                [width]="'*'"
                                [binding]="'PatientName'"
                            >
                            </wj-flex-grid-column>
                            <wj-flex-grid-column
                                [header]="'TO NAME'"
                                [width]="'*'"
                                [binding]="'PrescriberName'"
                            >
                            </wj-flex-grid-column>
                            <wj-flex-grid-column
                                [header]="'TO FAX#'"
                                [width]="'*'"
                                [binding]="'PrescPhone'"
                            >
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div>{{item['PrescPhone'] ? (item['PrescPhone'] | mask : "(000)000-00000") : ""}}</div>
                            </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column
                                [header]="'DRUG NAME'"
                                [width]="'*'"
                                [binding]="'DRUG NAME'"
                            >
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div>{{item['DRUG NAME']}}</div>
                            </ng-template>
                            </wj-flex-grid-column>
                            <!-- <wj-flex-grid-column [header]="'MESSAGING METHOD'" [width]="'*'" [binding]="'Status'">
                            </wj-flex-grid-column> -->
                            <wj-flex-grid-column
                                [header]="'Rx#'"
                                [width]="'*'"
                                [binding]="'RxNo'"
                            >
                            </wj-flex-grid-column>
                            <wj-flex-grid-column
                                [header]="'RX ORIGIN'"
                                [width]="'*'"
                                [binding]="'RxOrigin'"
                            >
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </span>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button footer class="hotkey_primary" appShortcutKey InputKey="p" (click)="openActionModal('PrintRefillReqForm')">
                                <b>P</b> Print To Printer
                            </button>
                            <button *ngIf="hasFaxNumber"
                                footer
                                class="hotkey_primary"
                                appShortcutKey
                                InputKey="f"
                                (click)="openFaxPopUp()"
                            >
                            <b>F</b> Fax Refill Request
                            </button>
                            <button *ngIf="!hasFaxNumber" footer class="hotkey_primary" >
                                Fax Refill Request
                            </button>
                            <button
                                footer
                                class="hotkey_primary"
                                (click)="assignArrayAndCheck()"
                                appShortcutKey
                                InputKey="s"
                                *ngIf="selectedRxs?.length"
                            >
                            <b>S</b> Send E-Refill Request
                            </button>
                            <button footer class="hotkey_primary" *ngIf="!selectedRxs?.length">
                                Send E-Refill Request
                            </button>
                            <button
                                footer
                                class="hotkey_primary"
                                appShortcutKey
                                InputKey="c"
                                (click)="c('Close click')"
                                (click)="closeRefillRequest()"
                            >
                            <b>C</b> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="selectedRxsForRefReq.length">
                <div class="exprx--common-block" id="eqvDeferredId">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                Equivalent Deferred Rxs
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content edit--drug">
                        <div>
                            <wj-flex-grid #eqvDeferred [columnPicker]="'deferredEquivalentsWJ'" [itemsSource]="deferredEquivalentsWJ" [isReadOnly]="true"
                                [selectionMode]="'Row'" (updatedView)="updateWJView(eqvDeferred)" [headersVisibility]="'Column'"
                                (initialized)="init(eqvDeferred)" (loadedRows)="loadedWJRows(eqvDeferred)" [itemFormatter]="itemFormatter"
                                [style.max-height]="_wijHeight">
                                <wj-flex-grid-column [header]="''" [width]="40">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row">
                                        <i *ngIf="!dp.isDetailVisible(row)" (click)="dp.showDetail(row,true); "
                                            class="far fa-plus-circle actions "></i>
                                        <i *ngIf="dp.isDetailVisible(row)" (click)="dp.hideDetail(row); "
                                            class="far fa-minus-circle actions"></i>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                    [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                                    *ngFor="let rxheader of wjHeaders; let i = index">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <ng-template wjFlexGridDetail #dp="wjFlexGridDetail" let-item="item">
                                    <wj-flex-grid #eqvDeferFlex [columnPicker]="'deferredEqvGrpWJ'" [itemsSource]="showInsideGrid(item)"
                                        [isReadOnly]="true" (updatedView)="updateWJView(eqvDeferFlex)"
                                        (initialized)="init(eqvDeferFlex)" [headersVisibility]="'None'" class="text_transform ml-1"
                                        (loadedRows)="loadedWJRows(eqvDeferFlex)" [itemFormatter]="itemFormatter"
                                        [style.max-height]="_wijHeight">
                                        <wj-flex-grid-column [header]="'Action'" [width]="40">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-item="item">
                                                <div class="text-center">
                                                    <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                                                        [(ngModel)]="item.IsSelectedPatient" [checked]="item.IsSelectedPatient"
                                                        (click)="selectedRx($event?.target?.checked, item)" />
                                                    <label class="form-check-label" [for]="item.RowId"></label>
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                            [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeadersForGrpRxs; let i = index"
                                            [width]="rxheader['width']">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                                <div>
                                                    <div *ngIf = "rxheader['hName']">
                                                        {{item[rxheader['hName']]}}
                                                </div>
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </ng-template>
                            </wj-flex-grid>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right mt-1">
                                <button footer class="hotkey_primary" appShortcutKey InputKey="d" (click)="processRefillFlowEqvDefRxs()">
                                    <b>D</b> Fill
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                Already Batched Rxs
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <span *ngIf="alreadyBatchedRxs?.length > 0;else noRecords">
                            <wj-flex-grid #batchedRxss [headersVisibility]="'Column'" [itemsSource]="prepareBatchedRxsWj"
                            class="print-batch-rx-Wijmo" [isReadOnly]="true" [columnPicker]="'printbatchedRxsRefReqWJ'" (initialized)="initBatchedRxss(batchedRxss)">
                            <wj-flex-grid-column [header]="rxBatchheader['hName']" [binding]="rxBatchheader['hName']" [visible]="rxBatchheader['isVisible']"
                                        [width]="rxBatchheader['width']" *ngFor="let rxBatchheader of wjHeadersForprepareBatchedRxs; let i = index" [align]="'center'">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <span *ngIf="rxBatchheader['hName'] === 'To Fax#'">
                                                {{item[rxBatchheader['hName']] ? (item[rxBatchheader['hName']] | mask : "(000)000-00000") : ""}}
                                            </span>
                                            <span *ngIf="!(rxBatchheader['hName'] === 'To Fax#')">
                                                {{item[rxBatchheader['hName']]}}
                                            </span>
                                        </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                        </span>
                        <ng-template #noRecords>
                            <div class="eprx--block__footer">
                                <div>
                                    <span></span>
                                    <i class="fa fa-file"></i><br/>
                                    No Data Found!
                                </div>
                            </div>
                            
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-send-efill-request *ngIf="activeModal === 'sendEfill'" [FaxInfo]="printRefWJ" [selectedRx]="selectedRxs"
  [RxInfo]="rxInfo"  (IsPopUpClosed)="closeSendRefillReq()"></app-send-efill-request>


<app-print-rx-form *ngIf="activeModal === 'PrintRefillReqForm'" [RxInfo]="selectedRxs" [frmSendRefReqScreenToPrint]="true"
[ModalType]="activeModal === 'PrintRefillReqForm'" (IsPopUpClosed)="closeRefillRequest()">
</app-print-rx-form>


<app-override-log
    *ngIf="openOverride"
    [OverrideEndpoint]="[overrideEndpoint]"
    [PrivMask]="privType"
    [CatId]="categoryId"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>

<app-print-erx-req
    *ngIf="activeModal === 'printFax'"
    [RxInfo]="selectedRxs"
    (IsPopUpClosed)="resetActiveModal()"
></app-print-erx-req>

<app-print-fax-request
    *ngIf="faxPopUp"
    [FaxInfo]="printRefWJ"
    [RxInfo]="selectedRxs"
    (IsPopUpClosed)="reset($event)"
>
</app-print-fax-request>

<!-- <app-send-efill-request             // not using this.
    *ngIf="activeModal === 'sendEfill'"
    [RxInfo]="rxInfo"
    [PrescRefillId]="rxInfo.PrescRefilId"
    [DrugId]="rxInfo.DrugId"
    [FaxInfo]="printRefWJ"
    [selectedRx]="selectedRxs"
    (IsPopUpClosed)="resetActiveModal($event)"
></app-send-efill-request> -->

<ng-template #ALREADYVERIFIED let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Confirmation</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <p>The Refill Request for Rx# : <b>{{ rxInfo.Prescription.PrescNum }}</b> is
                        already sent. Would you like to send it again?</p>
                </div>
            </div>
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="chekcCompundDrug()"
            appShortcutKey
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            footer
            class="hotkey_primary"
            (click)="closeModal()"
            appShortcutKey
            InputKey="n"
        >
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #DAWVALID let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            Only Valid Values for DAW are (NO DAW(0)) or (Physician DAW(1)).<br />Please
            change DAW for this Rx# : <b>{{ rxInfo.Prescription.PrescNum }}</b>
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="closeModal()"
            appShortcutKey
            InputKey="o">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #SPINOMODAL let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Confirmation</h4>
        <span body>
            Prescriber : {{ rxInfo.Prescriber.prescriberid }} has no SPI
            (SureScript) number assigned. Would you like to update SPI?
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="openModal(ADDSPI, 'addSPI')"
            appShortcutKey
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            footer
            class="hotkey_primary"
            (click)="closeModal()"
            appShortcutKey
            InputKey="n"
        >
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #ADDSPI let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Update SPI</h4>
        <div body class="row">
            <div class="col-md-12">
                <label> Prescriber Name</label>
                <div class="label--data">
                    {{
                        rxInfo?.Prescriber?.prescriberlastname +
                            ", " +
                            rxInfo?.Prescriber?.prescriberfirstname
                    }}
                </div>
            </div>
            <div class="col-md-12">
                <label> SPI Number</label>
                <eprx-input
                [RxSelectId]="'spiNumId'"

                    [PlaceHolder]="'SPI Number'"
                    [MaxLength]="13"
                    [HasControl]="false"
                    [DebounceTime]="1000"
                    [AutoFocus]="true"
                    [ValidPattern]="regex?.spiRegex"
                                [ErrorDefs]="{
                                                pattern: 'please enter 13 digits'
                                            }"
                    (TriggerChangeValue)="changeSpiNumber($event)"
                >
                </eprx-input>
            </div>
        </div>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            appShortcutKey
            [CtrlKey]="'s'"
            (click)="saveSPI()"
        >
            <span>S</span> Save
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #MISSINGFIELDS let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 header>
            Please Select Missing Values
            {{
                drugMissingForm.value.DrugName
                    ? "for " + drugMissingForm.value.DrugName
                    : ""
            }}
        </h4>
        <button
            header
            type="button"
            [tabindex]="-1"
            class="close"
            aria-label="Close"
            (click)="closeModal()"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="showDrugDescription">
                <eprx-select
                    [LabelText]="'Dosage Form Description'"
                    [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmDescId'"
                    [AutoFocus]="true"
                    [ShowLabel2]="true"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DoseFrmDesc"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['DoseFrmDescId']?.errors
                    "
                    [IsRequired]="false"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Dosage Form Code'"
                    [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmCodeId'"
                    [AutoFocus]="true"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DoseFrmCode"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['DoseFrmCodeId']?.errors
                    "
                    [IsRequired]="showDrugDescription"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Drug Strength Qualifier'"
                    [PlaceHolder]="'Drug Strength Qualifier'"
                    [ControlName]="'DrugStrQualId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DrugStrQual"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['DrugStrQualId']?.errors
                    "
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Potency Unit Code'"
                    [PlaceHolder]="'Potency Unit Code'"
                    [ControlName]="'PotencyUnitCodeId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.PotencyUnitCode"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['PotencyUnitCodeId']?.errors
                    "
                    [IsRequired]="true"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Quantity Qualifier'"
                    [PlaceHolder]="'Quantity Qualifier'"
                    [ControlName]="'QtyQualId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.QtyQual"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm.controls['QtyQualId']?.errors
                    "
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="hotkey_success"
            appShortcutKey
            [AltKey]="'s'"
            (click)="saveDrugFields()"
        >
            <span>S</span> Save
        </button>
        <button
            class="hotkey_success"
            appShortcutKey
            [AltKey]="'c'"
            (click)="closeModal()"
        >
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>
<ng-template #ISCONTROLRX let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <p>The Refill Request for Rx# : <b>{{ rxInfo.Prescription.PrescNum }}</b> is
                        a control Rx. You do not have privilege to perform this action.</p>
                </div>
            </div>
        </span>
        <button
            footer
            class="hotkey_primary"
            (click)="closeModal()"
            appShortcutKey
            InputKey="o"
        >
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #ISNONCONTROLRX let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <p>The Refill Request for Rx# : <b>{{ rxInfo.Prescription.PrescNum }}</b> is
                        a non-control Rx. You do not have privilege to perform this action.</p>
                </div>
            </div>
        </span>
        <button
            footer
            class="hotkey_primary"
            (click)="closeModal()"
            appShortcutKey
            InputKey="o"
        >
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>