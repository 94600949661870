import { Component, OnInit, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InsuranceService } from "../../../services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-copyncdp",
    templateUrl: "./copyncdp.component.html"
})
export class CopyncdpComponent implements OnInit, AfterViewInit {


    copyInfoFG: FormGroup;
    transsetingsDropValues: any;
    unsubscribe$: Subject<void> = new Subject();

    @Output()
    CopyValue = new EventEmitter();
    formGroupInvalid: boolean;

    constructor(
        private _insurService: InsuranceService, public activeModal: NgbActiveModal, private _fb: FormBuilder) {
        this.copyInfoFG = this._fb.group({
            NCDPId: []
        });
    }

    ngOnInit() {
        this._insurService._dropdownData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.transsetingsDropValues = resp;
            }
        });
        // document.getElementById("DateRange").getElementsByTagName("input")[0];
    }

    ngAfterViewInit() {
        if (document.getElementById("select")) {
        const data: any = document.getElementById("select").getElementsByTagName("input")[0];
        data.focus();
        }
    }

    get NCDPId(): FormControl {
        return this.copyInfoFG.get("NCDPId") as FormControl;
    }

    copy() {
        if (this.copyInfoFG.value.NCDPId) {
            this.formGroupInvalid = false;
            this.activeModal.close(this.copyInfoFG.value.NCDPId);
        } else {
            this.formGroupInvalid = true;
        }
      //  this.CopyValue.emit(this.copyInfoFG.value.NCDPId);
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
