<ng-template #QUICKREFILL let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal(); RefreshRxHistoryGrid()">
        <h4 header> Quick Refill </h4>
        <span body>
            <div class="eprx--block__content erx--body pt-0">
                <div class="row mt-0">
                    <div class="col-12 text-right">
                        <button  class="submit print-med-guide background_orange" (click)="toggleColorLegend()">Color Legend</button>
                    </div>
                </div>
                <span>
                    <wj-flex-grid #rxRefill [headersVisibility]="'Column'" [itemsSource]="rxRefillWJ" [isReadOnly]="true" [selectionMode]="'None'"  [columnPicker]="'quickRefillWJ'" (initialized)="init(rxRefill)" [itemFormatter]="itemFormatter" >
                        <wj-flex-grid-column header="" [width]="50">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                <span class="text-center" *ngIf="(['Early To Refill', 'Regular',  'No More Refills Left With P.Qty','No More Refills Left With R.Qty',
                                 'Deferred Rx', 'transferred', 'Discontinued','Deferred and Discontinued Rx'].indexOf(item['Refill Status']) > -1 && !(item['Future Order Date'])) || (item['Refill Status'] === 'Transferred' && !(item.isRxTransferred))">
                                    <input class="form-check-input" type="checkbox" [id]="'quick'+item.Rx+item.RowId" name=""
                                        [(ngModel)]="item.IsSelected" (change)="optedQuickRefill($event?.target?.checked, item)"
                                        [checked]="item.IsSelected" />
                                    <label class="form-check-label" [for]="'quick'+item.Rx+item.RowId">
                                    </label>
                                </span>
                                <span class="text-center" *ngIf="(['No Quantity', 'Refill Expired',
                                'No More Refills Left',
                                'Expired Rx', 'Expired and Discontinued Rx','Deferred and Expired Rx' , 'Expired(User) and Discontinued Rx','Deferred and Expired(User) Rx','Expired(User)'].indexOf(item['Refill Status']) > -1) || (item['Refill Status'] === 'Transferred' && (item.isRxTransferred)) || (item['Future Order Date'])">
                                    <input *ngIf="showOthrBtns" class="form-check-input" type="checkbox" name="" [id]="'quick'+item.Rx+item.RowId" name=""
                                    [(ngModel)]="item.IsSelected" (change)="optedQuickRefill($event?.target?.checked, item)"
                                    [checked]="item.IsSelected" ngbTooltip="{{(item['Future Order Date']) ? 'This Prescription has Future Order Date and cannot be processed.' : ''}}" placement = "right" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"/>
                                    <input *ngIf="!showOthrBtns" class="form-check-input" type="checkbox" name="" disabled="disabled" ngbTooltip="{{(item['Future Order Date']) ? 'This Prescription has Future Order Date and cannot be processed.' : ''}}" placement = "right" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"/>
                                    <label class="form-check-label">
                                    </label>
                                </span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                            [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index" [align]="'center'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                                <div  *ngIf="!(['Qty Rem P.fill','T.Rx Qty Rem', 'Rx#','Status','Drug Name','Status','Refill Status'].includes(rxheader['hName']))" [title]="((item[rxheader['hName']]+'') | uppercase )">
                                {{item[rxheader['hName']]}}

                             </div>

                                <div *ngIf="(rxheader['hName'] === 'Rx#' || rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Status')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : item['StatusHoverOver']" placement = "top"  triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                        {{item[rxheader['hName']] }}
                                </div>

                                <div *ngIf="rxheader['hName'] === 'T.Rx Qty Rem' || rxheader['hName'] === 'Qty Rem P.fill'">
                                    {{ (item[rxheader['hName']]) ? (item[rxheader['hName']]) : "0.000"}}
                                    <!--  As per EP-4459 Handling Overfill Scenarios   <span *ngIf="rxheader['hName'] !== 'Qty Rem P.fill'"  (click)="openRxTotalQtyComponent(item)"
                                        [ngClass]="item[rxheader['hName']] && (item[rxheader['hName']] !== '0.000') && (item['PartialQtyRemaining'] > 0) ? 'total_remaining_traingle_icon' : ''">
                                    </span> -->
                                </div>

                                <div *ngIf="rxheader['hName'] === 'Refill Status'">
                                    {{(item[rxheader['hName']]  == 'No More Refills Left With P.Qty' ) ? "No More Refills Left" : item[rxheader['hName']] }}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                </span>
            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="routeToEditRx()" *ngIf="hasData || showOthrBtns" appShortcutKey
            [InputKey]="'e'"><b>E</b>Edit</button>
        <button footer ngbAutofocus class="hotkey_primary" *ngIf="(techIniMNDT || pharmIniMNDT) && hasData && !showOthrBtns"
            (click)="openSecurity('Hold')" appShortcutKey [InputKey]="'h'">
            <b>H</b> PROCESS AND HOLD</button>
            <button footer ngbAutofocus class="hotkey_primary" *ngIf="!(techIniMNDT || pharmIniMNDT) && hasData && !showOthrBtns"
            appShortcutKey [InputKey]="'h'" (click)="initiateRefillProcess(true); setTransmissionStatus('Hold')">
            <b>H</b> PROCESS AND HOLD </button>
        <button footer ngbAutofocus class="hotkey_primary" *ngIf="(techIniMNDT || pharmIniMNDT) && hasData && !showOthrBtns"
            (click)="openSecurity('ALL')" appShortcutKey [InputKey]="'a'">
            <b>A</b> PROCESS AND TR-NOW</button>
        <button footer ngbAutofocus class="hotkey_primary" *ngIf="!(techIniMNDT || pharmIniMNDT) && hasData && !showOthrBtns"
            appShortcutKey [InputKey]="'a'" (click)="initiateRefillProcess(true); setTransmissionStatus('ALL')">
            <b>A</b> PROCESS AND TR-NOW</button>
        <button footer ngbAutofocus class="hotkey_primary" appShortcutKey InputKey ="n" *ngIf="showOthrBtns" (click)="processCreateNew()"><b>N</b>Create New Rx</button>
        <button footer ngbAutofocus class="hotkey_primary" (click)="openRefillReq('Send')" *ngIf="showOthrBtns" appShortcutKey
            [InputKey]="'s'"><b>s</b>Send E-Refill Request</button>
        <button footer ngbAutofocus class="hotkey_primary" (click)="openRefillReq('PrintRefillReqForm')" *ngIf="showOthrBtns"  appShortcutKey
            [InputKey]="'p'"><b>p</b>Print Refill Request</button>
        <button footer class="hotkey_primary mr-0"  (click)="closeModal('','close'); RefreshRxHistoryGrid()"
            appShortcutKey [InputKey]="'c'"><b>C</b> CLOSE</button>
    </app-custom-modal>
</ng-template>
<app-refill-request *ngIf="activeModal" [rxInfo]="selcRxInfo" [selectedRxs]="selectedRxs"
[rxOriginList]="(systemInfo$ | async)?.RxOrigin" [refillHeader]="'Send/Print/Fax Refill Request'"
(IsPopUpClosed)="closeRefilReq(null)" [openDirectly]="activeModal"></app-refill-request>
<span *ngIf="false">
    <button footer ngbAutofocus class="hotkey_primary" (click)="routeToEditRx()" appShortcutKey [InputKey]="'e'">
        <b>E</b>Edit</button>
    <button footer ngbAutofocus class="hotkey_primary" *ngIf="techIniMNDT || pharmIniMNDT"
        (click)="openSecurity('Hold')" appShortcutKey [InputKey]="'h'">
        <b>H</b> HOLD</button>
    <button footer ngbAutofocus class="hotkey_primary" *ngIf="techIniMNDT || pharmIniMNDT" (click)="openSecurity('ALL')"
        appShortcutKey [InputKey]="'a'">
        <b>A</b> TR-NOW </button>
    <button footer ngbAutofocus class="hotkey_primary" *ngIf="!(techIniMNDT || pharmIniMNDT)"
        (click)="initiateRefillProcess(); setTransmissionStatus('Hold')" appShortcutKey [InputKey]="'h'">
        <b>H</b> HOLD</button>
    <button footer ngbAutofocus class="hotkey_primary" *ngIf="!(techIniMNDT || pharmIniMNDT)" appShortcutKey
        [InputKey]="'a'" (click)="initiateRefillProcess(); setTransmissionStatus('ALL')">
        <b>A</b> TR-NOW</button>
</span>

<ng-template #RefillInsuInactive let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span >Warning!!</span>
    </div>
    <div class="modal-body">
        <p>The Insurance for the selected Rx is Inactive, would you like to refill with the same Insurance and activate it automatically?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
        (click)="setInsuranceActive(); c('Close click')"><b>y</b>Yes</button>

    <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
        (click)="setPatientPrimaryInsurance(); c('Close click')"><b>n</b>No</button>
    </div>
</ng-template>

<ng-template #IsPickedRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalOnDismissAndIncrementIndex()">
        <h4 header> Confirmation </h4>
        <span body>
            <div class="row">
                <div class="col inline-flex">
                    <label>Rx</label>:
                    <div class="label--data"> &nbsp; {{refillRx.Prescription.PrescNum}}-{{refillRx?.PrescReFill?.ReFillNum}}<span *ngIf="_commonServ.checkIsPartialFill(refillRx?.PrescReFill, refillRx?.Prescription)">-{{refillRx?.PrescReFill?.PartialFillNo}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col inline-flex">
                    <label>Drug</label>:
                    <div class="label--data"> &nbsp; {{(refillRx?.Drug?.drugname | uppercase)+' '+ (refillRx?.Drug?.strength ? refillRx?.Drug?.strength : ' ')+' '+ (refillRx?.Drug?.drugformname)}} </div>
                </div>

            </div>
            <div class="row">
                <div class="col inline-flex">
                    <label>Patient Name</label>:
                    <div class="label--data" class="word-break"> &nbsp;
                        <span class="word-break:break-all">{{(refillRx?.Patient?.lastname | uppercase )+', '+ (refillRx?.Patient?.firstname | uppercase)}}</span>
                    </div>
                </div>
            </div>
            The Last refill of this Rx has not yet been picked-up by patient.Do you wish to continue with this refill?
        </span>
        <button footer autofocus class="hotkey_primary" (click)="checkIsFiled()" (click)="c('Close click')" appShortcutKey
            InputKey="y"><b>Y</b> YES
        </button>
        <button footer ngbAutofocus class="hotkey_primary" (click)="closeModalOnDismissAndIncrementIndex()"
            appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #DrugExpiryDate let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalOnDismissAndIncrementIndex()">
        <h4 header> Warning!!</h4>
        <span body>
            Rx# {{refillRx.Prescription.PrescNum}} require drug expiration date and expiry date cannot be earlier than
            today's date.
            <div class="col-md-6">
                <eprx-date-picker [AutoFocus]="true" [LabelText]="'Expires'" [FormGroupName]="DateFrmGrp"
                    [ControlName]="'expDate'" [PlaceHolder]="''" [MinDate]="minDate" [MaxDate]=""
                    [IsRequired]="true"
                    [ErrorDefs]="{ required: 'Required', minDate: 'Expired Date' }"
                >
                </eprx-date-picker>
            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_success" (click)="saveExpireDate()" appShortcutKey [AltKey]="'o'">
            <span>O</span> OK
        </button>
        <button footer ngbAutofocus class="hotkey_success" (click)="c('Close click')"
            (click)="closeModalOnDismissAndIncrementIndex()" appShortcutKey [AltKey]="'c'">
            <span>C</span> CANCEL
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #DrugLotReq let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Lot Number Required!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closeModalOnDismissAndIncrementIndex()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col form-group custom--input" [ngClass]="{
                    'has-error':
                    DateFrmGrp?.controls['lotNumber']?.invalid &&
                        (DateFrmGrp?.controls['lotNumber']?.dirty || DateFrmGrp?.controls['lotNumber']?.touched) &&
                        DateFrmGrp?.controls['lotNumber']?.errors
                }">
                <label class="control-label width_100per">
                    <span>
                        Lot# :
                        <span class="help--text__danger">*</span>
                    </span>
                    <input type="text" id="drugLotID" name="searchDrugLot" class="form-control search--input__box"
                        placeholder="To Start Search..." [ngbTypeahead]="searchDrugLot" (selectItem)="optedDrugLot($event)"
                        [resultTemplate]="rtDrugLot" [inputFormatter]="drugLotFormatter"
                        [value]="DateFrmGrp?.controls['lotNumber']?.value"
                        KeyUpDown [required]="true" />
                </label>
                <ng-template #rtDrugLot let-r="result" let-t="term">
                    <a>
                        <div class="typehead--result" *ngIf="r">
                            <div class="row">
                                <div class="col-sm-12 typehead--content">
                                    {{ r?.LotNum }}
                                </div>
                            </div>
                        </div>
                    </a>
                </ng-template>
                <div *ngIf="DateFrmGrp?.controls['lotNumber']?.invalid && (DateFrmGrp?.controls['lotNumber']?.dirty || DateFrmGrp?.controls['lotNumber']?.touched)" class="help-block">
                    <div *ngIf="DateFrmGrp?.controls['lotNumber']?.errors">Required</div>
                </div>
            </div>
            <!-- <div class="col">
                <eprx-input [AutoFocus]="true" [LabelText]="'Lot Number :'" [PlaceHolder]="'Lot Number'"
                    [IsRequired]="true" [ControlName]="'lotNumber'" [FormGroupName]="DateFrmGrp" [MaxLength]="10"
                    [FormGroupInvalid]="lotfgInvalif" [ErrorDefs]="{required: 'Required'}">
                </eprx-input>
            </div> -->
            <div class="col">
                <eprx-date-picker [LabelText]="'Expiration Date :'" [PlaceHolder]="'Expiration Date'"
                    [ControlName]="'expDate'" [FormGroupName]="DateFrmGrp" [RxSelectId]="'ExpirationDateLot'"
                    [MinDate]="minDate" [MaxDate]="" [ErrorDefs]="{required: 'Required'}" [IsRequired]="true"
                    [FormGroupInvalid]="lotfgInvalif">
                </eprx-date-picker>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="saveExpireDate()" appShortcutKey
                    [AltKey]="'s'"><span>S</span>Save</button>
                <button class="hotkey_success" (click)="d('Cross click');closeModalOnDismissAndIncrementIndex()" appShortcutKey
                    [AltKey]="'c'"><span>C</span>Cancel </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #warnDrugInactive let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalOnDismissAndIncrementIndex()">
        <h4 header> Warning!! </h4>
        <span body>
            Drug is marked as Inactive. Cannot refill Rx# {{refillRx.Prescription.PrescNum}}
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="closeModalOnDismissAndIncrementIndex()"
            appShortcutKey InputKey="O">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #PHARM let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click'); resetLoginFG('Pharm')">
        <h4 header>Enter Pharmacist Credentials</h4>
        <span body>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <eprx-input [AutoFocus]="true" [LabelText]="'Pharmacist Intial'" PlaceHolder="Pharmacist Intial"
                        [ControlName]="'Intial'" [FormGroupName]="LoginVerifyFG" [Title]="'Pharmacist'"
                        [IsRequired]="true" [InputErrors]="LoginVerifyFG.controls['Intial']?.errors"
                        [ErrorDefs]="{ required: 'Required', invalidUser:'Invalid Username'}" [FormGroupInvalid]="formGroupInvalid"
                        (TriggerOnBlur)="verifyPharmIntial();" [MarkAsTouched]="LoginVerifyFG.controls['Intial']?.touched">
                    </eprx-input>
                </div>
                <div *ngIf="!disablePass" class="col-md-12 col-sm-12 col-xs-12 padding_top_0_8rem">
                    <eprx-input [LabelText]="'Password'" PlaceHolder="Password" [ControlName]="'password'"
                        [FormGroupName]="LoginVerifyFG" [InputType]="'password'" [Title]="'Password'"
                        [IsRequired]="true" [InputErrors]="LoginVerifyFG.controls['Password']?.errors"
                        [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid"
                        [IsDisabled]="disablePass">
                    </eprx-input>
                    <span class="help--text__danger" *ngIf="warnMessage">
                        {{warnMessage}}
                    </span>

                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="verifyPassword(LoginVerifyFG, 'Pharm')" appShortcutKey
            [AltKey]="'s'">
            <span>S</span> SUBMIT
        </button>
        <button footer class="hotkey_success" (click)="c('Close click'); resetLoginFG('Pharm')" appShortcutKey
            [AltKey]="'c'"><span>C</span> CANCEL</button>
    </app-custom-modal>
</ng-template>

<ng-template #TECHVERIFY let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click'); resetLoginFG('Tech')">
        <h4 header>Enter Technician Credentials</h4>
        <span body>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 ">
                    <eprx-input [AutoFocus]="true" [LabelText]="'Technician Intial'" PlaceHolder="Technician Intial"
                        [ControlName]="'Intial'" [FormGroupName]="TechVerifyFG" [Title]="'Technician'"
                        [IsRequired]="true" [InputErrors]="TechVerifyFG.controls['Intial']?.errors"       [RxSelectId]="'loginIntial'"
                        [TabIndex]="1"
                        [ErrorDefs]="{ required: 'Required', invalidUser:'Invalid Username' }" [FormGroupInvalid]="formGroupInvalid1"
                        (TriggerOnBlur)="verifyTechIntial($event);" [MarkAsTouched]="TechVerifyFG.controls['Intial']?.touched">
                    </eprx-input>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 padding_top_0_8rem">
                    <eprx-input  [LabelText]="'Password'" PlaceHolder="Password" [ControlName]="'password'"
                        [FormGroupName]="TechVerifyFG" [InputType]="'password'" [Title]="'Password'"
                        [IsRequired]="true" [RxSelectId]="'QuickRefillLoginPwd'" [InputErrors]="TechVerifyFG.controls['Password']?.errors"   [TabIndex]="2" (TriggerOnTabbing)="focusOnSave($event)"
                        [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid1">
                    </eprx-input>
                    <span class="help--text__danger" *ngIf="warnMessage">
                        {{warnMessage}}
                    </span>

                </div>
            </div>
        </span>
        <button footer id="saveButton" class="hotkey_success" (click)="verifyPassword(TechVerifyFG, 'Tech')" appShortcutKey
            [AltKey]="'s'">
            <span>S</span> SUBMIT
        </button>
        <button footer class="hotkey_success" (click)="c('Close click'); resetLoginFG('Tech')" appShortcutKey
            [AltKey]="'c'"><span>C</span>CANCEL</button>
    </app-custom-modal>
</ng-template>

<ng-template #INACTIVE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click'); closeModalOnDismissAndIncrementIndex()">
        <h4 header> Inactive {{inactiveDataType}} </h4>
        <span body *ngIf="inactiveDataType !== 'Drug'">
            {{inactiveDataType}} <label><span class="font-weight-bold">{{inactiveDataName | uppercase}}</span></label> is Inactive<br>
            Cannot Refill Rx# <label><span class="font-weight-bold">{{refillRx.Prescription.PrescNum}}</span></label>
        </span>
        <span body *ngIf="inactiveDataType === 'Drug'">
            {{inactiveDataType}} <label><span class="font-weight-bold">{{inactiveDataName | uppercase}}</span></label> is Inactive. There are no Equivalent<br>
            Cannot Refill Rx# <label><span class="font-weight-bold">{{refillRx.Prescription.PrescNum}}</span></label>
        </span>
        <button footer autofocus type="submit" class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="c('Close click'); closeModalOnDismissAndIncrementIndex()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #FILE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');closeModalOnDismissAndIncrementIndex()">
      <h4 header> Fill A filed Rx For: {{selectedRxForRef}}-{{refillRx?.PrescReFill?.ReFillNum}}<span *ngIf="_commonServ.checkIsPartialFill(refillRx?.PrescReFill, refillRx?.Prescription
            )">-{{refillRx?.PrescReFill?.PartialFillNo}}
            </span> </h4>
        <span body class="overflow_unset">
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Fill date'" [PlaceHolder]="''"
                        [ControlName]="'dueDateTime'" [FormGroupName]="fileFG" [MaxDate]="" [IsRequired]="true"
                        [ErrorDefs]="{required: 'Required'}" [InputErrors]="fileFG.controls['dueDateTime']?.errors">
                    </eprx-date-picker>
                </div>
            </div>
            <div>
                <eprx-radio [LabelText]="'Assign New'" [ControlName]="'createNewRefill'" [FormGroupName]="fileFG"
                [IsDisabled]="filedNew || !(refillRx.PrescReFill.ReFillNum === 0 && refillRx.PrescReFill.PartialFillNo === 1)"
                [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['assignNew11', 'assignNew21']">
                </eprx-radio>
            </div>
        </span>
        <button footer class="inactive" *ngIf="!fileFG?.valid"> <span>S</span> Save </button>
        <button footer class="hotkey_success" (click)="saveFileToInfo()" *ngIf="fileFG?.valid" appShortcutKey
            [AltKey]="'s'">
            <span>S</span> Save </button>
        <button footer class="hotkey_success" (click)="c('Close click'); closeModalOnDismissAndIncrementIndex()"
            appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>

<ng-template #RxDiscontinued let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span >Warning!!</span>
    </div>
    <div class="modal-body">
        <p>Rx is Discontinued. If you wish to continue the Rx will no more be marked as discontinued. Would you like to proceed?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
        (click)="setOrderStatus(); c('Close click')"><b>y</b>Yes</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
        (click)="closeModalOnDismissAndIncrementIndex()"><b>n</b>No</button>
    </div>
</ng-template>
<app-override-log *ngIf="openOverride" [PrivMask]="privType" [CatId]="categoryId" [OverrideEndpoint]="overrideEndpoint"
    (OverrideLoginDet)="OverrideRes($event)"></app-override-log>



    <ng-template #confirmParOrFull let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Confirmation</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click');afterPartialFillCheck(false)">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body edit-patient">
            <span>
            The Last Fill on this Rx was partially Filled. <br/>
            Would you like to complete the remaining partial fill or proceed with the next new refill?
            </span>
        </div>
        <div class="modal-footer">
            <button autofocus type="button" class="hotkey_primary" (click)="c('Close click');afterPartialFillCheck(true)" appShortcutKey InputKey="p"
             ><b>P</b> Last Partial Fill </button>
             <button *ngIf="!noMoreRefillsLeft" autofocus type="button" class="hotkey_primary" (click)="c('Close click');afterPartialFillCheck(false)" appShortcutKey InputKey="n"
             ><b>N</b> Next New Refill </button>
        </div>
    </ng-template>

    <ng-template #TreatmentType let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="c('Close click');closeModalOnDismissAndIncrementIndex()">
          <h4 header>Treatment Type Required!!</h4>
            <span body>
                Treatment type is mandatory on this Rx, since after the previous fill. Please select the Treatment Type from below to update the same on the selected Rx.<div><br></div>
                Rx#: &nbsp;<b><span class="font-weight-bold text-primary">{{refillRx.Prescription.PrescNum}}-{{refillRx?.PrescReFill?.ReFillNum}}
                <span *ngIf="_commonServ.checkIsPartialFill(refillRx?.PrescReFill, refillRx?.Prescription)">-{{refillRx?.PrescReFill?.PartialFillNo}}</span>
                </span></b><br>
                Patient Name: &nbsp;<b><span class="font-weight-bold text-primary">{{refillRx.Patient["fullname"].replace("_", " ") | uppercase}}</span></b><br>
                Prescriber Name: &nbsp;<b><span class="font-weight-bold text-primary">{{refillRx.Prescriber["fullname"].replace("_", " ") | uppercase}}</span></b><br>
                Drug Name: &nbsp;<b><span class="font-weight-bold text-primary">{{refillRx.Drug["drugname"] | uppercase}}</span></b><br>
                Treatment Type<span class="help--text__danger">*</span>:
                <div class="col-lg-12 col-md-12 col-sm-12 pl-0">
                    <eprx-select [RxSelectId]="'treatmenttypeId'" [LabelText]="''" [PlaceHolder]="''" [AutoFocus]="true"
                        [ControlName]="'TreatmentTypeId'" [FormGroupName]="rxFG" [secondCntrlN]="'TreatmentTypeName'"
                        [BindLabel]="'Code'" [BindLabel2]="'Description'" [List]="systemData?.treatmentTypes"
                        [bindScnCntrlV]="'Description'" [BindValue]="'Id'" [HasMultiple]="false" [ErrorDefs]="{ required: 'Required'}"
                        (TriggerSelectValue)="patchtreatmentType($event)"
                        [Show2Values]="true" [show2ValInInputhidden]="true" [IsRequired]="isReqTrtmntTpe" [MarkAsTouched]="rxFG?.controls?.TreatmentTypeId?.touched">
                    </eprx-select>
                </div>
            </span>
            <button footer class="hotkey_success" (click)="processQuick()" appShortcutKey [AltKey]="'s'"><span>S</span> SAVE
            </button>
        </app-custom-modal>
    </ng-template>
    <app-rx-serial-req-pop-display *ngIf="frmIconClick"
    [rxFG]="rxFG" [rxSerialFG]="rxSerialFG" [frmQuickRef]="true" [systemData]="systemData" [frmIconClick]="frmIconClick" (IsPopUpClosed)="alreadyRxSerPatch = true;frmIconClick=false;processQuick()"
   ></app-rx-serial-req-pop-display>
