<div class="row">
    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 trasmit--rx">
        <div class="">
            <label> Rx# </label>
            <div class="label--data">
                {{ transmResp?.transmissionDetails?.RXNo }}
            </div>
        </div>
        <div class="d-inline-flex">
            <label> Patient Name</label>
            <div class="label--data">
                {{ transmResp?.transmissionDetails?.PatientName }}
                <br/>
                <label class="min-width-0">&nbsp; DOB : </label>
                {{
                    transmResp?.transmissionDetails?.FormattedDOB
                        | date: "MM/dd/yyyy"
                }}, <label class="min-width-0"> Gender :</label>
                {{ transmResp?.transmissionDetails?.Gender }}, <label class="min-width-0"> Phone# :</label>
                {{ transmResp?.transmissionDetails?.PatPhone ?
                (transmResp?.transmissionDetails?.PatPhone | mask : "(000)000-0000") :"--"}}
            </div>
        </div>
        <div class="">
            <label> Prescriber Name </label>
            <div class="label--data">
                {{ transmResp?.transmissionDetails?.PrescriberName }}
            </div>
        </div>
        <div class="">
            <label> Drug Name</label>
            <div class="label--data">
                {{ transmResp?.transmissionDetails?.DrugName }},
                <label class="min-width-0"> NDC :</label>
                <!-- {{transmResp?.transmissionDetails?.NDC ?
                (transmResp?.transmissionDetails?.NDC | mask: "AAAA0-0000-00") : "--"}} -->
                {{ this._formatsUtil.getNDCFormat(transmResp?.transmissionDetails?.NDC) }}
            </div>
        </div>
        <div class="">
            <label> NDC </label>
            <div class="label--data">
                <!-- {{transmResp?.transmissionDetails?.NDC ?
                (transmResp?.transmissionDetails?.NDC | mask: "AAAA0-0000-00") : "--"}} -->
                 {{this._formatsUtil.getNDCFormat(transmResp?.transmissionDetails?.NDC)}}
            </div>
        </div>
        <div class="">
            <label> Txmt. By </label>
            <div class="label--data">
                {{ transmResp?.transmissionDetails?.TransmittedBy }} {{ &nbsp; transmResp?.transmissionDetails?.EnteredDate | localTime | date : 'MM/dd/yyyy hh:mm:ss a'}}
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 trasmit--rx">
        <div class="inline-flex width_100per">
            <label class="min_width_10rem"> Refill# </label>
            <div class="label--data width_100per d-inline-flex">
                <div class="row width_100per padding_left_1rem">
                    <div class="">
                        &nbsp;{{ transmResp?.transmissionDetails?.NRefill }}
                    </div>
                    <div class="padding_left_1rem">
                        <label class="min-width-0 text_transform"> &nbsp;Fill# : </label>
                        {{ transmResp?.transmissionDetails?.FillNo ? transmResp?.transmissionDetails?.FillNo : ' -- ' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="" *ngIf="(transmissionType !== 'TransMessage' || transmissionType === 'TransMessage')">
            <label class="min_width_10rem"> Ins </label>
            <div class="label--data">
                {{ transmResp?.transmissionDetails?.InsType }}
            </div>
        </div>
        <!---<div class="" *ngIf="transmissionType === 'TransMessage'">
            <label> Ins </label>
            <div
                class="label--data"
                *ngIf="false"
            >
                <span *ngFor="let pay of rxInfo?.RxPay">
                    <span
                        style="color: red; margin-left: 10px;"
                    >
                        <span>{{
                            pay?.CoverageCD === 0
                                ? "P"
                                : pay?.CoverageCD === 1
                                ? "S"
                                : pay?.CoverageCD === 2
                                ? "T"
                                : ""
                        }}</span>
                        : {{ pay?.Ins_Code }}
                    </span>

                </span>
            </div>
            <div   style="color: red; margin-left: 10px;">
                <span>
                    {{ transmResp?.transmissionDetails?.InsType }}
                </span>
            </div>
        </div>-->
        <div class="inline-flex width_100per">
            <label class="min_width_10rem"> Ins. Info </label>
            <div class="label--data width_100per d-inline-flex">
                <div class="row width_100per padding_left_1rem">
                    <div class="">
                        <label class="min-width-0"> &nbsp;INS. ID : </label>
                        {{ transmResp?.transmissionDetails?.PolicyNum }}
                    </div>
                    <div class="">
                        <label class="min-width-0"> &nbsp;GRP#: </label>
                        {{ (transmResp?.transmissionDetails?.GrpNo) ? transmResp?.transmissionDetails?.GrpNo : '--' }}
                    </div>
                    <div class="">
                        <label class="min-width-0"> &nbsp;PHONE# : </label>
                        {{ transmResp?.ClaimProcess?.objPatient?.objBillToIns?.Insphone ?
                            (transmResp?.ClaimProcess?.objPatient?.objBillToIns?.Insphone | mask : "(000)000-0000") :"--"}}
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <label class="min_width_10rem"> Fill Date </label>
            <div class="label--data">
                {{ transmResp?.transmissionDetails?.FillDate | dateFormat}}
            </div>
        </div>
        <div class="">
            <label class="min_width_10rem"> Qty Disp/Days Supp </label>
            <div class="label--data">
                {{
                    transmResp?.transmissionDetails?.Qty
                        ? transmResp?.transmissionDetails?.Qty
                        : 0
                }}
                /
                {{
                    transmResp?.transmissionDetails?.Days
                        ? transmResp?.transmissionDetails?.Days
                        : 0
                }}
            </div>
        </div>
        <div class="">
            <label class="min_width_10rem"> BIN </label>
            <div class="label--data">
                {{BIN ? BIN : "--"}}
            </div>
        </div>
        <div class="">
            <label class="min_width_10rem"> PCN </label>
            <div class="label--data">
                {{PCN ? PCN : "--"}}
            </div>
        </div>
    </div>
</div>
