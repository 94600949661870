<ng-template #PHYSICALINVEN let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Add Physcial Inventory Record</h4>
        <span body>
            <div class="eprx--block__content transmit--block">
                <div class="row">
                    <div class="col-md-12">
                        <div class="exprx--common-block">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    Drug
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div
                                        class="col-lg-6 col-md-6 col-sm-6 col-xs-12 trasmit--rx"
                                    >
                                        <div class="">
                                            <label> Drug Name </label>
                                            <div class="label--data">
                                                {{
                                                    drugInfo?.Drug?.Name
                                                        | uppercase
                                                }}
                                                {{
                                                    drugInfo?.Drug?.Strength
                                                        ? drugInfo?.Drug
                                                              ?.Strength
                                                        : ("" | uppercase)
                                                }}
                                                {{
                                                    drugInfo?.Drug?.DrugUnit
                                                        ? drugInfo?.Drug
                                                              ?.DrugUnit
                                                        : ("" | uppercase)
                                                }}
                                                {{
                                                    drugInfo?.Drug?.DrugFormName
                                                        ? drugInfo?.Drug
                                                              ?.DrugFormName
                                                        : ("" | uppercase)
                                                }}
                                            </div>
                                        </div>
                                        <div class="">
                                            <label> Drug NDC </label>
                                            <div class="label--data">
                                                {{ drugInfo?.Drug?.DrugCode }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-6 col-md-6 col-sm-6 col-xs-12 trasmit--rx"
                                    >
                                        <div class="">
                                            <label> Exp. Date </label>
                                            <div class="label--data">
                                                {{
                                                    drugLotExpiryDate
                                                        | date: "MM/dd/yyyy"
                                                }}
                                            </div>
                                        </div>
                                        <div class="">
                                            <label> Manufacturer </label>
                                            <div class="label--data">
                                                --
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">&nbsp;</div>
                        <div class="exprx--common-block">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    Quantity on Shelf
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div
                                        class="col-lg-4 col-md-4 col-xs-4 trasmit--rx"
                                    >
                                        <eprx-input
                                            [LabelText]="'Packs'"
                                            [PlaceHolder]="'Packs'"
                                            [ControlName]="'packs'"
                                            [FormGroupName]="physcInventFG"
                                            [RxSelectId]="'packs'"
                                            (EmitOnChangeValue)="calculatePhysicInventTotalQty()"
                                        >
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-xs-4 trasmit--rx">
                                        <eprx-input
                                            [LabelText]="'Loose Qty'"
                                            [PlaceHolder]="'Loose Qty'"
                                            [ControlName]="'looseQTY'"
                                            [FormGroupName]="physcInventFG"
                                            [RxSelectId]="'looseQTY'"
                                            (EmitOnChangeValue)="calculatePhysicInventTotalQty()"
                                        >
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-xs-4 trasmit--rx">
                                        <eprx-select
                                            [RxSelectId]="'bucketType'"
                                            [LabelText]="'Bucket'"
                                            [PlaceHolder]="''"
                                            [ControlName]="'bucketType'"
                                            [FormGroupName]="physcInventFG"
                                            [BindLabel]="'Name'"
                                            [BindValue]="'Id'"
                                            [LabelForId]="'Id'"
                                            [List]="bucketInfo?.length > 0 ? bucketInfo: ''"
                                            [InputValue]="''"
                                            [HasMultiple]="false"
                                            [IsDisabled]="true"
                                            [HasBindLabel2]="false"
                                            (OnKeydownValue)="getBucketListByString($event?.target?.value)"
                                        >
                                        </eprx-select>
                                    </div>
                                    <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding-0" style="padding-top: 25px;">
                                        <i class="far fa-search fa-lg actions" (click)="getSearchBucketList()"></i>
                                    </div> -->
                                </div>
                                <div class="row">
                                    <div
                                        class="col-lg-4 col-md-4 col-xs-4 trasmit--rx"
                                    >
                                        <div class="">
                                            <label> Total Count </label>
                                            <div class="label--data">
                                                {{totalQty}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">&nbsp;</div>
                                    <div class="col-12">
                                        Note: Enter 0 if you currently do not
                                        have any Qty in stock. If you are
                                        receiving additional Inventory for this
                                        Drug. Please use the Receive Inventory
                                        or 810File processing option after
                                        enabling this feature. In order to
                                        manage the inventory properly. It is
                                        crucial that you correctly update the
                                        initial count of Qty in stock before
                                        using this drug.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12"></div>
                </div>
            </div>
        </span>
        <button
            footer
            index="1"
            ngbAutofocus
            id="PHYSICALINVENOK"
            #PHYSICALINVENOK
            class="hotkey_primary"
            appShortcutKey
            InputKey="o"
            (click)="calculatePhysicalInvent()"
        >
            <b>O</b> Ok
        </button>
        <button
            footer
            index="2"
            class="hotkey_primary"
            id="PHYSICALINVENCANCL"
            #PHYSICALINVENCANCL
            clas="mr-0"
            appShortcutKey
            InputKey="c"
            (click)="closeCanclPhysicalInven(true)"
        >
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>
