export class BasicInfo {
    Patient: {
        Id: number;
        PharmacyId: number;
        MergedId: number;
        PrimeInsuId: number;
        LanguageId: number;
        DiscountSchId: number;
        Remarks: string;
    };

    Person: {
        Id: number;
        TenantId: number;
        FirstName: string;
        MiddleName: string;
        LastName: string;
        Initials: string;
        BirthDt: Date;
        GenderId: number;
        MaritalStatusId: string;
        ProfPicExtn: any;
        PrimeAddsId: number;
        PrimePhId: number;
        PrimeEmailId: number;
        NPINum: string;
        IdProofCode: number;
        IdProofId: number;
        Remarks: string;
        ActiveStatusId: number;
    };

    Genders: {
        Id: number;
        Name: string;
        Remarks: string;
        IsActive: boolean;
    };

    Emails: {
        Id: number;
        TenantId: number;
        eMailCatId: number;
        xeMail: string;
        IsActive: boolean;
    };

    PerEmails: {
        Id: number;
        PersonId: number;
    };

    Phones: {
        Id: number;
        TenantId: number;
        PhoneCatId: number;
        AreaCode: string;
        xPhone: string;
        IsActive: boolean;
    };

    PerPhone: number;
    Addresses: {
        Id: number;
        TenantId: number;
        AddressLine1: string;
        AddressLine2: string;
        ZipCode: number;
        CountryId: number;
        StateId: number;
        CityId: number;
        IsActive: boolean;
    };

    States: {
        Id: number;
        CountryId: number;
        Name: string;
        Remarks: string;
        IsActive: boolean;
    };

    Citites: {
        Id: number;
        StateId: number;
        Name: string;
        Remarks: string;
        IsActive: boolean;
    };

    Languages: {
        Id: number;
        Name: string;
        LangCode: string;
        Remarks: string;
        IsRightAligned: boolean;
        IsActive: boolean;
    };

    Patient_ChartNums: any;
    Patient_SplProgs: any;
    SplPrograms: any;
    PatSettings: any;
}

export class PatientPickAlert {
    CreatedDtTm: Date = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    PatMsgAlertId: number = null;
    PickAlertCatId: number = null;
    TenantId: number = null;
}
export class PatientMsgAlert {
    CreatedDtTm: Date = null;
    Id: number = null;
    IsActive: boolean = null;
    IsEmailAllowed: boolean = null;
    IsPhoneAllowed: boolean = null;
    IsSMSAllowed: boolean = null;
    ModifiedDtTm: Date = null;
    MsgAlertCatId: number = null;
    PatientId: number = null;
    TenantId: number = null;
}
export class Measurement {
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    PatientId: number = null;
    MedTestId: number = null;
    ObsVal: string = null;
    TestUnitId: number = null;
    RefillNum: number = null;
    IsActive: boolean = null;
    MeasureDt: Date = null;
    MeasureTm: Date = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    MeasurementUnit: string = null;
    Dimension: string = null;
}
export class ClinicalData {
    SearchText: string = null;
    SearchBy: string = null;
    OrderByColumn: string = null;
    SortDirection: string = null;
    PageNumber: number = null;
    PageSize: number = null;
}
