import { SharedModule } from "src/app/modules/shared/shared.module";
import { CustFormElemnsModule } from "./../cust-form-elemns/cust-form-elemns.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DrugPickVerificationRoutingModule } from "./drug-pick-verification-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DrugPickVerificationComponent, VerifyInfoComponent } from ".";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { WjCoreModule } from "@grapecity/wijmo.angular2.core";
import { WjInputModule } from "@grapecity/wijmo.angular2.input";
import { RphVerificationModule } from "../rph-verification/rph-verification.module";
import { ReviewDrugPickComponent } from './review-drug-pick/review-drug-pick.component';
import {WjGridSearchModule} from '@grapecity/wijmo.angular2.grid.search'
@NgModule({
    imports: [
        CommonModule,
        DrugPickVerificationRoutingModule,
        CustFormElemnsModule,
        SharedModule,
        NgbModule,
        WjCoreModule,
        WjInputModule,
        WjGridModule,
        RphVerificationModule,
        WjGridSearchModule
    ],
    declarations: [DrugPickVerificationComponent, VerifyInfoComponent, ReviewDrugPickComponent],
    exports: [
        VerifyInfoComponent
    ],
    entryComponents: [
        VerifyInfoComponent
    ]
})
export class DrugPickVerificationModule {}
