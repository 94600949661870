<div class="modal-header">
    <div class="col-md-12">
      <h4 class="modal-title">Select Rx's</h4>
      <button type= button [tabindex]="-1" class="close" aria-label="close" (click)="closeModal('cancel')">
          <span aria-hidden="true" class="close-button">&times;</span>
      </button>
    </div>
</div>
<div class="modal-body">
<div class="eprx--block__content erx--body editpatient">
  <div class="row">
    <div class="col-md-12">
      <wj-flex-grid #flex *ngIf="hasData" [headersVisibility]="'Column'" [itemsSource]="controlRxWJ" [isReadOnly]="true" [columnPicker]="'rxBasicDisplayListWJ'"
        [selectionMode]="'None'">
        <wj-flex-grid-column [header]="''" [width]="57">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <span class="text-center padding_left_10px">
                <input class="form-check-input left_0_37rem" type="checkbox" [id]="item.PrescRefillId + item.RowId"
                    name="" [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                    (click)="optedRx($event?.target?.checked, item)" />
                <label class="form-check-label" [for]="item.PrescRefillId + item.RowId"></label>
            </span>

          </ng-template>
      </wj-flex-grid-column>
        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index"
          [width]="rxheader['width']">
                      <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <div [title]="(item[rxheader['hName']] + '' |  uppercase)">
                          {{item[rxheader['hName']]}}
                        </div>
                      </ng-template>
        </wj-flex-grid-column>
      </wj-flex-grid>
    </div>
  </div>
  </div>
</div>
<div class="modal-footer">
    <div class="row">
      <div class="col export--button">
        <button class="hotkey_success" (click)="pickRxs()" appShortcutKey [AltKey]="'P'">
          <span>P</span>   PICK
        </button>
        <button class="hotkey_success float-right" (click)="closeModal('cancel')" appShortcutKey [AltKey]="'c'">
         <span>C</span> CLOSE
        </button>
      </div>
    </div>
</div>

