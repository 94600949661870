import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService, AlertService } from "../services";
import {
    AccCategoryE,
    InsuranceFileE,
    FacilityFileE,
    FileMaintenance1E, ElectronicSignatureE, DMSE, LabelE
} from "../models";
import { MsgConfig } from "..";
import { BasePrivGetter } from "./access-privs.guard";

@Injectable()
export class InsuranceFileAddGuard extends BasePrivGetter
    implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.InsuranceFile, InsuranceFileE.Add, true);
        return reslt;
    }
}

@Injectable()
export class InsuFileChangeGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.InsuranceFile, InsuranceFileE.Edit, true);
        return reslt;
    }
}

@Injectable()
export class FacilityAddGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FacilityFile, FacilityFileE.Add, true);
        return reslt;
    }
}

@Injectable()
export class FacilityChangeGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FacilityFile, FacilityFileE.Edit, true);
        return reslt;
    }
}

@Injectable()
export class PrescriberNotesGuard extends BasePrivGetter
    implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.PrescriberNotesLog, true);
        return reslt;
    }
}

@Injectable()
export class PatientNotesGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.PatientNotesLog, true);
        return reslt;
    }
}

@Injectable()
export class DrugNotesGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.DrugNotesLog, true);
        return reslt;
    }
}

@Injectable()
export class InsuranceNotesGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.FileMaintenance1, FileMaintenance1E.InsuranceNotesLog, true);
        return reslt;
    }
}

@Injectable()
export class RphVerifyGuard implements CanActivate {
    constructor(private _userServ: UserService, private _alertServ: AlertService) {}

    canActivate() {
        const techName = this._userServ.getToken("TechUserName");
        if (!techName) {
            return true;
        } else {
            this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS);
            return false;
        }
    }
}

@Injectable()
export class SigByPatientGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.SignatureByPatient, true);
        return reslt;
    }
}
@Injectable()
export class SignatureByDateGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.SignatureByDate, true);
        return reslt;
    }
}
@Injectable()
export class SignatureByRxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.SignatureByRx, true);
        return reslt;
    }
}
@Injectable()
export class HIPAAAckgGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.HIPAAAcknowledgement, true);
        return reslt;
    }
}
@Injectable()
export class PrintRxPickUpLogGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.PrintRxPickupLog, true);
        return reslt;
    }
}
@Injectable()
export class SignatureClaimsLogGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.SignatureClaimsLog, true);
        return reslt;
    }
}
@Injectable()
export class RxPickupdetailsGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.RxPickupDetails, true);
        return reslt;
    }
}

@Injectable()
export class POSCopayCollectedGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.ElectronicSignature, ElectronicSignatureE.POSCopayCollected, true);
        return reslt;
    }
}
@Injectable()
export class DMSSubcategoryGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.DMS, DMSE.DMSSubcategory, true);
        return reslt;
    }
}
@Injectable()
export class BatchScanGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.DMS, DMSE.BatchScan, true);
        return reslt;
    }
}
@Injectable()
export class DocumentQueueGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.DMS, DMSE.DocumentQueue, true);
        return reslt;
    }
}

@Injectable()
export class PrintRxListWithNoAttachedDocumentsGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.DMS, DMSE.PrintRxListWithNoAttachedDocuments, true);
        return reslt;
    }
}

@Injectable()
export class PrintRxListWithAttachedDocumentsGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.DMS, DMSE.PrintRxListWithAttachedDocuments, true);
        return reslt;
    }
}

@Injectable()
export class LabelDesignGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Label, LabelE.LabelDesigner, true);
        return reslt;
    }
}

// @Injectable()
// export class LabelPrintGuard extends BasePrivGetter implements CanActivate {
//     constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
//         super(_userServ, _router, _alertServ);
//     }

//     canActivate() {
//         const reslt = super.validateAuth(AccCategoryE.Label, LabelE.LabelPrintSet, true);
//         return reslt;
//     }
// }
