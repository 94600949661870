import { Injectable } from "@angular/core";
import { Delta, NewAuditLog, SystemData } from "../models";
import { UserService, CommonService } from "../services";
import * as moment from "moment";
import { CommonUtil } from "./common.util";
import { HammerGestureConfig } from "@angular/platform-browser";
import { format } from "url";
import { FormatsUtil } from "./formats.util";


@Injectable({
    providedIn: "root"
})

export class NewAuditUtils {
    delta: Delta = new Delta();
    auditObj: NewAuditLog = new NewAuditLog();
    newChange: any;
    neworiginalChange: any;
    diff: any;
    checkIsPrefOrgnlVal: any;
    checkIsPrefChngdVal: any;
    alreadyIsPreferedAdded = false;
    systemData: SystemData;
    oldNDC: any;
    newNDC: any;
    constructor(private _userService: UserService, private _commonServ: CommonService,
        private _commonUtil: CommonUtil, private _formatsUtil: FormatsUtil) {
            this._commonServ.systemData$.subscribe(resp => {
                this.systemData = resp;
            });
    }

    saveAuditChange(original, changed, action, entity, entityid, model, frmMult?: boolean, dropdownsData?: any, othType?:any, isFrmFollowUpBilling?: any, morePatInfo? : any) {
        let oldArr = [];
        let newArr = [];
        model.map(val => {
            if ((action === "EditRx" || action === "MiscRxInfo") &&
                ["U&C", "Unit AWP", "Price", "P.Fee", "Oth. Amount", "Tot. Price", "Discount", "Balance Due",
                    "Professional Service Fee", "Incentive Amount", "Flat Sales Tax Amount", "Sales Tax Percentage",
                "Percentage Sales Tax Amount", "Patient Paid Amount", "Usual & Customary", "Coupon Value", "Profit Amount", "Ingredient Cost",
            "AWP", "Tax", "Cost Rx"].indexOf(val.HeaderName) > -1) {
                if (original && original[val.ParentName]) {
                    original[val.ParentName][val.ControlName] = original[val.ParentName][val.ControlName] ?
                        (parseFloat(original[val.ParentName][val.ControlName])).toFixed(2) : "0.00";
                }
                if (changed && changed[val.ParentName]) {
                    changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] ?
                        (parseFloat(changed[val.ParentName][val.ControlName])).toFixed(2) : "0.00";
                }
                if (!val.ParentName) {
                    if (original) {
                        original[val.ControlName] = original[val.ControlName] ?
                            (parseFloat(original[val.ControlName])).toFixed(2) : "0.00";
                    }
                    if (changed) {
                        changed[val.ControlName] = changed[val.ControlName] ?
                            (parseFloat(changed[val.ControlName])).toFixed(2) : "0.00";
                    }
                }
            }
            if ((action === "EditRx" || action === "MiscRxInfo") &&
            ["Qty Ordered", "Qty Disp", "Originally Prescribed Quantity"].indexOf(val.HeaderName) > -1) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = original[val.ParentName][val.ControlName] ?
                        (parseFloat(original[val.ParentName][val.ControlName])).toFixed(3) : "0.000";
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] ?
                        (parseFloat(changed[val.ParentName][val.ControlName])).toFixed(3) : "0.000";
                }
            }
            if ((action === "EditRx" && val.HeaderName === "Order Status")) {
                if (original && original[val.ParentName] && changed &&
                    changed[val.ParentName]) {
                    if (original[val.ParentName][val.ControlName] !== null &&
                         original[val.ParentName][val.ControlName].toString().trim() === "\u0000") {
                        original[val.ParentName][val.ControlName] = null;
                    }
                    if (changed[val.ParentName][val.ControlName] !== null &&
                         changed[val.ParentName][val.ControlName].toString().trim() === "\u0000") {
                        changed[val.ParentName][val.ControlName] = null;
                    }
                }
                if (original && original[val.ParentName] && changed &&
                    changed[val.ParentName] && ((original[val.ParentName][val.ControlName] || changed[val.ParentName][val.ControlName]))) {
                    if (original[val.ParentName][val.ControlName] !== changed[val.ParentName][val.ControlName]) {
                         original[val.ParentName][val.ControlName] = this.getOrderStatus(original[val.ParentName][val.ControlName]);
                         changed[val.ParentName][val.ControlName] = this.getOrderStatus(changed[val.ParentName][val.ControlName]);
                    }
                }
                // if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName] &&
                //      changed[val.ParentName][val.ControlName].trim()) {
                //     changed[val.ParentName][val.ControlName] = this.getOrderStatus(changed[val.ParentName][val.ControlName]);
                // }
            }
            if (action === "EditRx" && val.HeaderName === "Prescriber Name") {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName] &&
                     original[val.ParentName][val.ControlName].trim()) {
                    original[val.ParentName][val.ControlName] = (original[val.ParentName][val.ControlName]).replace(/_/gi, ", ");
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName] &&
                    changed[val.ParentName][val.ControlName].trim()) {
                    changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName].replace(/_/gi, ", ");
                }
            }
            if (action === "EditRx" && val.HeaderName === "Patient Name") {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName] &&
                     original[val.ParentName][val.ControlName].trim()) {
                    original[val.ParentName][val.ControlName] = (original[val.ParentName][val.ControlName]).replace(/_/gi, ", ");
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName] &&
                     changed[val.ParentName][val.ControlName].trim()) {
                    changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName].replace(/_/gi, ", ");
                }
            }
            if (action === "EditRx" && val.HeaderName === "Drug Name") {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName] &&
                     original[val.ParentName][val.ControlName].trim()) {
                    original[val.ParentName][val.ControlName] = (original[val.ParentName][val.ControlName]).replace(/_/gi, " ");
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName] &&
                     changed[val.ParentName][val.ControlName].trim()) {
                    changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName].replace(/_/gi, " ");
                }
            }
            if (action === "EditRx" && val.HeaderName === "Compound") {
                if (original && changed && original[val.ParentName] && changed[val.ParentName] && ((original[val.ParentName][val.ControlName] === 2 && changed[val.ParentName][val.ControlName] !== 2) ||
                    (original[val.ParentName][val.ControlName] !== 2 && changed[val.ParentName][val.ControlName] === 2))) {
                    const oldVal = { name: val.HeaderName, value: original[val.ParentName][val.ControlName] === 2 ? "Y" : "N" };
                    const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] === 2 ? "Y" : "N" };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }
            }
            if (action === "EditRx" && val.HeaderName === "FollowUp tag" && isFrmFollowUpBilling) {
                if (original && original[val.ParentName] && changed && changed[val.ParentName]) {
                    if (original[val.ParentName][val.ControlName] !== changed[val.ParentName][val.ControlName]) {
                        const oldVal = { name: val.HeaderName, value: original[val.ParentName][val.ControlName] };
                        const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                } else if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    const oldVal = { name: val.HeaderName, value: original[val.ParentName][val.ControlName] };
                    const newVal = { name: val.HeaderName, value: null };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                } else if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    const oldVal = { name: val.HeaderName, value: null };
                    const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }
            }
            if (action === "EditPatient" && val.HeaderName === "Parent Preferred Contacts") {
                if (original.PerPrefContact.length && changed.PerPrefContact.length) {
                    let isPreferedOrinal: any = [], isPreferedChanged: any = [];
                    isPreferedOrinal = original.PerPrefContact.filter((ele: any) => {
                        return (ele.ContactType !== "Telephone" && ele.ContactType !== "Mobile" && ele.ContactType !== "Work Number");
                    });
                    isPreferedChanged = changed.PerPrefContact.filter((ele: any) => {
                        return (ele.ContactType !== "Telephone" && ele.ContactType !== "Mobile" && ele.ContactType !== "Work Number");
                    });
                    if (isPreferedOrinal.length === isPreferedChanged.length) {
                        this.checkIsPrefOrgnlVal = isPreferedOrinal.filter((item) => {
                            return (item.IsPreferred === true);
                        });
                        this.checkIsPrefChngdVal = isPreferedChanged.filter((item) => {
                            return (item.IsPreferred === true);
                        });
                        if (this.checkIsPrefOrgnlVal.length === 0 && this.checkIsPrefChngdVal.length >= 1) {
                            oldArr.push({ name: "IsPreferred", value: ("false") });
                            newArr.push({ name: "IsPreferred", value: (this.checkIsPrefChngdVal[0]["IsPreferred"]) });
                        }
                        if (this.checkIsPrefOrgnlVal.length >= 1 && this.checkIsPrefChngdVal.length == 0) {
                            oldArr.push({ name: "IsPreferred", value: (this.checkIsPrefOrgnlVal[0]["IsPreferred"]) });
                            newArr.push({ name: "IsPreferred", value: ("false") });
                        }
                        if (this.checkIsPrefOrgnlVal.length >= 1 && this.checkIsPrefChngdVal.length >= 1 && (this.checkIsPrefOrgnlVal[0]["ContactType"] !== this.checkIsPrefChngdVal[0]["ContactType"])) {
                            oldArr.push({ name: "IsPreferred", value: ("false") });
                            newArr.push({ name: "IsPreferred", value: (this.checkIsPrefChngdVal[0]["IsPreferred"]) });
                        }
                    } else {
                        const arrayChange = [];
                    if(isPreferedChanged.length > 1){
                    this.diff = isPreferedChanged.length - 1;
                    arrayChange.push(isPreferedChanged[this.diff]);
                    this.newChange = arrayChange.filter((ele: any)=>{
                        return(ele.ContactType);
                     })
                        }
                        const originalArray = [];
                        if (isPreferedOrinal.length >= 1 && this.newChange && this.newChange.length > 0) {
                            originalArray.push(isPreferedOrinal.filter((item) => {
                                return (item.ContactType === this.newChange[0]["ContactType"]);
                            }))
                        }
                        original.PerPrefContact = original.PerPrefContact.filter((ele: any) => {
                            return (ele.ContactType !== "Telephone" && ele.ContactType !== "Mobile" && ele.ContactType !== "Work Number");
                        });
                        changed.PerPrefContact = changed.PerPrefContact.filter((ele: any) => {
                            return (ele.ContactType !== "Telephone" && ele.ContactType !== "Mobile" && ele.ContactType !== "Work Number");
                        });
                        if (isPreferedOrinal && isPreferedOrinal.length >= 0 && isPreferedChanged && isPreferedChanged.length >= 0) {
                            oldArr.push({
                                name: "IsPreferred", value: (isPreferedOrinal && isPreferedOrinal.length >= 1 && originalArray && originalArray.length > 0) ?
                                    ((originalArray && originalArray[0] && originalArray[0]["IsPreferred"] ? originalArray[0]["IsPreferred"].toString() : "false")) : "false"
                            });
                            newArr.push({
                                name: "IsPreferred", value: (isPreferedChanged && isPreferedChanged.length > 1 && this.newChange && this.newChange.length > 0) ?
                                    (this.newChange && this.newChange[0] && this.newChange[0]["IsPreferred"] ? this.newChange[0]["IsPreferred"].toString() : "false") : isPreferedChanged && isPreferedChanged[0] && isPreferedChanged[0]["IsPreferred"] ? isPreferedChanged[0]["IsPreferred"].toString() : "false"
                            });
                        }
                    }
                const addedcontactTypes: any = [], addedContactNames: any = [], addedcontactNumbers: any = [];
                const deletedContactTypes: any = [], deletedContactNames: any = [], deletedContactNumbers: any = [];
                let addedContacts: any = [], updatedContacts: any = [], deletedContacts: any = [],
                orinalUpdatedContacts: any = [];
                addedContacts  = changed.PerPrefContact.filter((ele: any) => {
                    return (ele.Action === "A");
                });
                updatedContacts  = changed.PerPrefContact.filter((ele: any) => {
                    return (ele.Action === "U");
                });
                orinalUpdatedContacts  = original.PerPrefContact.filter((ele: any) => {
                    return (ele.ContactType !== "Telephone" && ele.ContactType !== "Mobile" && ele.ContactType !== "Work Number");
                });
                deletedContacts  = changed.PerPrefContact.filter((ele: any) => {
                    return (ele.Action === "D");
                });
                if (addedContacts.length) {
                    addedContacts.map((elem: any) => {
                        addedcontactTypes.push(elem.ContactType);
                        addedContactNames.push(elem.Contactname);
                        addedcontactNumbers.push(elem.ContactNumber);
                    });
                }
                if (deletedContacts.length) {
                    deletedContacts.map((elem: any) => {
                        deletedContactTypes.push(elem.ContactType);
                        deletedContactNames.push(elem.Contactname);
                        deletedContactNumbers.push(elem.ContactNumber);
                    });
                }
                let updatedArrayValues: any = [];
                const orinalUpdatedArrayValues1: any = [];
                if (updatedContacts.length &&  orinalUpdatedContacts.length) {
                    if (orinalUpdatedContacts.length > 0) {
                        orinalUpdatedContacts.map(orgalg => {
                            if (!(updatedContacts.find(chgalg => (chgalg.ContactType === orgalg.ContactType) &&
                            (chgalg.Contactname === orgalg.Contactname) && (chgalg.ContactNumber === orgalg.ContactNumber)))) {
                                let update : any = (updatedContacts.filter(chgalg => (chgalg.Id === orgalg.Id)));
                                updatedArrayValues.push(update[0]);
                                orinalUpdatedArrayValues1.push(orgalg);
                            }
                        });
                    }
                }
                if (updatedArrayValues.length && orinalUpdatedArrayValues1.length) {
                    updatedArrayValues.map((org,index) => {
                        newArr.push({ name: "Contact updated Name-Type-Number",
                        value: (org.Contactname + "- " + org.ContactType + "-" + org.ContactNumber)});
                        oldArr.push({ name: "Contact updated Name-Type-Number",
                        value: (orinalUpdatedArrayValues1[index]["Contactname"] + "- " +
                        orinalUpdatedArrayValues1[index]["ContactType"] + "-" + orinalUpdatedArrayValues1[index]["ContactNumber"])});
                    });
                }

                    if (addedcontactTypes.length && addedContactNames.length && addedcontactNumbers.length) {
                        oldArr.push({ name: "added Contact Types", value: null });
                        newArr.push({ name: "added Contact Types", value: addedcontactTypes.toString() });
                        oldArr.push({ name: "added Contact Names", value: null});
                        newArr.push({ name: "added Contact Names", value: addedContactNames.toString() });
                        oldArr.push({ name: "added Contact Numbers", value: null });
                        newArr.push({ name: "added Contact Numbers", value: addedcontactNumbers.toString() });
                    }
                    if (deletedContactTypes.length && deletedContactNames.length && deletedContactNumbers.length) {
                        oldArr.push({ name: "deleted Contact Types", value: deletedContactTypes.toString() });
                        newArr.push({ name: "deleted Contact Types", value: null});
                        oldArr.push({ name: "deleted Contact Names", value: deletedContactNames.toString()});
                        newArr.push({ name: "deleted Contact Names", value: null });
                        oldArr.push({ name: "deleted Contact Numbers", value: deletedContactNumbers.toString() });
                        newArr.push({ name: "deleted Contact Numbers", value: null });
                    }
               }
            }
            if (action === "EditRx" && val.HeaderName === "Status") {
                let OldValue = null;
                let NewValue = null;
                if (original) {
                    OldValue = (original["Prescription"] && original["Prescription"]["RxStatusId"]) ?
                     this._commonUtil.getRxStatus(original["Prescription"]["RxStatusId"],
                        original["PrescReFill"]["StatusId"], original["RxBill"]["StatusId"]) : original["Name"];
                }
                if (changed) {
                    NewValue = (changed["Prescription"] && changed["PrescReFill"] && changed["Prescription"]["RxStatusId"] && changed["PrescReFill"]["StatusId"])  ?
                    this._commonUtil.getRxStatus(changed["Prescription"]["RxStatusId"],
                    changed["PrescReFill"]["StatusId"], changed["RxBill"]["StatusId"]) : changed["Name"];
                }
                if (OldValue !== NewValue) {
                    const oldVal = { name: val.HeaderName, value: OldValue };
                    const newVal = { name: val.HeaderName, value: NewValue };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }
            }

            if (action === "EditRx" && val.HeaderName === "Other Amount") {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName] &&
                    original[val.ParentName][val.ControlName].length && changed && changed[val.ParentName]
                    && changed[val.ParentName][val.ControlName] && changed[val.ParentName][val.ControlName].length) {
                    const mappingCntrl = original[val.ParentName][val.ControlName].length >
                        changed[val.ParentName][val.ControlName].length ? "changed" : "original";
                    const firstCntrlValue = mappingCntrl === "changed" ? changed[val.ParentName][val.ControlName] :
                        original[val.ParentName][val.ControlName];
                    const scnCntrlValue = mappingCntrl === "changed" ? original[val.ParentName][val.ControlName] :
                        changed[val.ParentName][val.ControlName];
                    firstCntrlValue.map((othrAmnt, i) => {
                        if (scnCntrlValue[i]) {
                            othrAmnt["Amount"] = othrAmnt["Amount"] ? (parseFloat(othrAmnt["Amount"])).toFixed(2) : "0.00";
                            scnCntrlValue[i]["Amount"] = scnCntrlValue[i]["Amount"] ?
                                (parseFloat(scnCntrlValue[i]["Amount"])).toFixed(2) : "0.00";
                            if (othrAmnt["Amount"] !== scnCntrlValue[i]["Amount"]) {
                                const oldVal = {
                                    name: "Other Amount" + (i + 1),
                                    value: mappingCntrl === "changed" ? scnCntrlValue[i]["Amount"] : othrAmnt["Amount"]
                                };
                                const newVal = {
                                    name: "Other Amount" + (i + 1),
                                    value: mappingCntrl === "changed" ? othrAmnt["Amount"] : scnCntrlValue[i]["Amount"]
                                };
                                oldArr.push(oldVal);
                                newArr.push(newVal);
                            }
                            if (othrAmnt["OtherAmntCatName"] !== scnCntrlValue[i]["OtherAmntCatName"]) {
                                const oldVal = {
                                    name: "Other Amount Category" + (i + 1), value: mappingCntrl === "changed" ?
                                        scnCntrlValue[i]["OtherAmntCatName"] : othrAmnt["OtherAmntCatName"]
                                };
                                const newVal = {
                                    name: "Other Amount Category" + (i + 1), value: mappingCntrl === "changed" ?
                                        othrAmnt["OtherAmntCatName"] : scnCntrlValue[i]["OtherAmntCatName"]
                                };
                                oldArr.push(oldVal);
                                newArr.push(newVal);
                            }
                        } else {
                            if (othrAmnt && othrAmnt["Amount"] && othrAmnt["Amount"] !== "0.00") {
                                const oldVal = {
                                    name: "Other Amount" + (i + 1),
                                    value: mappingCntrl === "changed" ? null : (parseFloat(othrAmnt["Amount"])).toFixed(2)
                                };
                                const newVal = {
                                    name: "Other Amount" + (i + 1),
                                    value: mappingCntrl === "changed" ? (parseFloat(othrAmnt["Amount"])).toFixed(2) : null
                                };
                                oldArr.push(oldVal);
                                newArr.push(newVal);
                            }
                            if (othrAmnt && othrAmnt["OtherAmntCatName"]) {
                                const oldVal = {
                                    name: "Other Amount Category" + (i + 1),
                                    value: mappingCntrl === "changed" ? null : othrAmnt["OtherAmntCatName"]
                                };
                                const newVal = {
                                    name: "Other Amount Category" + (i + 1),
                                    value: mappingCntrl === "changed" ? othrAmnt["OtherAmntCatName"] : null
                                };
                                oldArr.push(oldVal);
                                newArr.push(newVal);
                            }
                        }
                    });
                } else if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]
                    && original[val.ParentName][val.ControlName].length) {
                    original[val.ParentName][val.ControlName].map((othrAmnt, i) => {
                        if (othrAmnt && othrAmnt["Amount"] && othrAmnt["Amount"] !== "0.00") {
                            const oldVal = { name: "Other Amount" + (i + 1), value: (parseFloat(othrAmnt["Amount"])).toFixed(2) };
                            const newVal = { name: "Other Amount" + (i + 1), value: null };
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                        if (othrAmnt && othrAmnt["OtherAmntCatName"]) {
                            const oldVal = { name: "Other Amount Category" + (i + 1), value: othrAmnt["OtherAmntCatName"] };
                            const newVal = { name: "Other Amount Category" + (i + 1), value: null };
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                    });
                } else if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]
                    && changed[val.ParentName][val.ControlName].length) {
                    changed[val.ParentName][val.ControlName].map((othrAmnt, i) => {
                        if (othrAmnt && othrAmnt["Amount"] && othrAmnt["Amount"] !== "0.00") {
                            const newVal = { name: "Other Amount" + (i + 1), value: (parseFloat(othrAmnt["Amount"])).toFixed(2) };
                            const oldVal = { name: "Other Amount" + (i + 1), value: null };
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                        if (othrAmnt && othrAmnt["OtherAmntCatName"]) {
                            const newVal = { name: "Other Amount Category" + (i + 1), value: othrAmnt["OtherAmntCatName"] };
                            const oldVal = { name: "Other Amount Category" + (i + 1), value: null };
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                    });
                }
            }

            if (action === "AdditionalInsurance" && val.HeaderName === "IsActive") {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = original[val.ParentName][val.ControlName] === 1 ? "Yes" : "No";
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] === 1 ? "Yes" : "No";
                }
            }

            if (val.SubCntrlName) {
                if (original && original[val.ParentName] && original[val.ParentName].length > 0 &&
                    changed && changed[val.ParentName] && changed[val.ParentName].length) {
                    const mappingCntrl = original[val.ParentName].length > changed[val.ParentName].length ? "changed" : "original";
                    const firstCntrlValue = mappingCntrl === "changed" ? changed[val.ParentName] : original[val.ParentName];
                    const scnCntrlValue = mappingCntrl === "changed" ? original[val.ParentName] : changed[val.ParentName];
                    firstCntrlValue.map((subCntrl, i) => {
                        if (scnCntrlValue[i]) {
                            if (subCntrl[val.SubCntrlName] !== scnCntrlValue[i][val.SubCntrlName]) {
                                const oldVal = {
                                    name: val.HeaderName + (i + 1), value: mappingCntrl === "changed" ?
                                        scnCntrlValue[i][val.SubCntrlName] : subCntrl[val.SubCntrlName]
                                };
                                const newVal = {
                                    name: val.HeaderName + (i + 1), value: mappingCntrl === "changed" ?
                                        subCntrl[val.SubCntrlName] : scnCntrlValue[i][val.SubCntrlName]
                                };
                                oldArr.push(oldVal);
                                newArr.push(newVal);
                            }
                        } else {
                            if (subCntrl && subCntrl[val.SubCntrlName]) {
                                const oldVal = {
                                    name: val.HeaderName + (i + 1), value: mappingCntrl === "changed" ? null : subCntrl[val.SubCntrlName]
                                };
                                const newVal = {
                                    name: val.HeaderName + (i + 1), value: mappingCntrl === "changed" ? subCntrl[val.SubCntrlName] : null
                                };
                                oldArr.push(oldVal);
                                newArr.push(newVal);
                            }
                        }
                    });
                } else if (original && original[val.ParentName] && original[val.ParentName].length > 0) {
                    original[val.ParentName].map((subCntrl, i) => {
                        if (subCntrl && subCntrl[val.SubCntrlName]) {
                            const oldVal = { name: val.HeaderName + (i + 1), value: subCntrl[val.SubCntrlName]};
                            const newVal = { name: val.HeaderName + (i + 1), value: null};
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                    });
                } else if (changed && changed[val.ParentName] && changed[val.ParentName].length) {
                    changed[val.ParentName].map((subCntrl, i) => {
                        if (subCntrl && subCntrl[val.SubCntrlName]) {
                            const newVal = { name: val.HeaderName + (i + 1), value: subCntrl[val.SubCntrlName]};
                            const oldVal = { name: val.HeaderName + (i + 1), value: null};
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                    });
                }
            }

            if (["IsHandiChildPg", "IsFamilyPlan", "IsDisability", "IsRental", "IsCycleFillAllowed", "IsUnitDoseAllowed", "IsPrintMedSheet",
         "IdRefillReminded", "IsPAUsedForAllRefills", "IsPatAssignInd", "IsIncludedInMar", "IsPrivacyAcked",
          "IsFullyInsured", "IsDeceased", "IsPickedUp"].indexOf(val.ControlName) > -1) {
                if (original) {
                    if (!val.ParentName) {
                        original[val.ControlName] = original[val.ControlName] ? "Yes" : "No";
                    } else {
                        original[val.ParentName][val.ControlName] = original[val.ParentName][val.ControlName] ? "Yes" : "No";
                    }
                }
                if (changed) {
                    if (!val.ParentName) {
                    changed[val.ControlName] = changed[val.ControlName] ? "Yes" : "No";
                    } else {
                        changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] ? "Yes" : "No";
                    }
                }
            }

            /*if (val.HeaderName === "Measure Unit") {
                if (original) {
                    original[val.ControlName] = original[val.ControlName] ? moment(original[val.ControlName]).format("hh:mm a") : null;
                }
                if (changed) {
                    changed[val.ControlName] = changed[val.ControlName] ? moment(changed[val.ControlName]).format("hh:mm a") : null;
                }
            }*/

            if (action === "EditPatient" && val.ControlName === "xPhone") {
                if (val.HeaderName === "Telephone") {
                    const org = original["Phones"].filter(tele => tele.PhoneCatId === 3)[0];
                    const chang = changed["Phones"].filter(tele => tele.PhoneCatId === 3)[0];
                    if (org && chang && ((org["xPhone"] || chang["xPhone"]) && !(org["xPhone"] == chang["xPhone"]))) {
                        const oldVal = { name: val.HeaderName, value: org["xPhone"] };
                        const newVal = { name: val.HeaderName, value: chang["xPhone"] };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                } else if (val.HeaderName === "Mobile") {
                    const org = original["Phones"].filter(tele => tele.PhoneCatId === 2)[0];
                    const chang = changed["Phones"].filter(tele => tele.PhoneCatId === 2)[0];
                    if (org && chang && ((org["xPhone"] || chang["xPhone"]) && !(org["xPhone"] == chang["xPhone"]))) {
                        const oldVal = { name: val.HeaderName, value: org["xPhone"] };
                        const newVal = { name: val.HeaderName, value: chang["xPhone"] };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                } else if (val.HeaderName === "Work Number") {
                    const org = original["Phones"].filter(tele => tele.PhoneCatId === 1)[0];
                    const chang = changed["Phones"].filter(tele => tele.PhoneCatId === 1)[0];
                    if (org && chang && ((org["xPhone"] || chang["xPhone"]) && !(org["xPhone"] == chang["xPhone"]))) {
                        const oldVal = { name: val.HeaderName, value: org["xPhone"] };
                        const newVal = { name: val.HeaderName, value: chang["xPhone"] };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                }
                let checkIsPreferedOrigVal = [];
                let checkIsPreferedChngdVal = [];
                if (original.PerPrefContact.length && changed.PerPrefContact.length) {
                        checkIsPreferedOrigVal = original.PerPrefContact.filter((item) => {
                            return (item.IsPreferred === true);
                        });
                        checkIsPreferedChngdVal = changed.PerPrefContact.filter((item) => {
                            return (item.IsPreferred === true);
                        });
                        if (checkIsPreferedOrigVal.length && checkIsPreferedChngdVal.length && (checkIsPreferedOrigVal[0]["ContactType"] !== checkIsPreferedChngdVal[0]["ContactType"])
                            && !this.alreadyIsPreferedAdded) {
                                this.alreadyIsPreferedAdded = true;
                            oldArr.push({ name: "IsPreferred", value: (checkIsPreferedOrigVal[0]["ContactType"]) });
                            newArr.push({ name: "IsPreferred", value: ((checkIsPreferedChngdVal[0]["ContactType"]))});
                        }
                    }
                }

            if (action === "EditPatient" && val.HeaderName === "Allergies") {
                const orgTtlAllergy = [];
                const chgTtlAllergy = [];
                if (original.PatAllergies) {
                    original.PatAllergies.map(patalg => {
                        orgTtlAllergy.push(patalg["AllergyName"]);
                    });
                }
                if (original.Drugs) {
                    original.Drugs.map(patalg => {
                        orgTtlAllergy.push(patalg["Name"]);
                    });
                }
                if (original.OtherAllergies) {
                    original.OtherAllergies.map(patalg => {
                        orgTtlAllergy.push(patalg["Name"]);
                    });
                }
                if (changed.PatAllergies) {
                    changed.PatAllergies.map(patalg => {
                        chgTtlAllergy.push(patalg["AllergyName"]);
                    });
                }
                if (changed.Drugs) {
                    changed.Drugs.map(patalg => {
                        chgTtlAllergy.push(patalg["Name"]);
                    });
                }
                if (changed.OtherAllergies) {
                    changed.OtherAllergies.map(patalg => {
                        chgTtlAllergy.push(patalg["Name"]);
                    });
                }
                let doesnotExist = false;
                if (orgTtlAllergy.length !== chgTtlAllergy.length) {
                    const oldVal = { name: val.HeaderName, value: orgTtlAllergy.toString() };
                    const newVal = { name: val.HeaderName, value: chgTtlAllergy.toString() };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                } else if (orgTtlAllergy.length > 0) {
                    orgTtlAllergy.map(orgalg => {
                        if (!(chgTtlAllergy.find(chgalg => chgalg === orgalg))) {
                            doesnotExist = true;
                        }
                    });
                }
                if (doesnotExist) {
                    const oldVal = { name: val.HeaderName, value: orgTtlAllergy.toString() };
                    const newVal = { name: val.HeaderName, value: chgTtlAllergy.toString() };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }
            }
            if (((action === "EditPatient" && (val.HeaderName === "DOB" || val.HeaderName === "Card Expires"))
            || (val.ControlName === "ExpDtTm")) || (action === "EditRx" && (val.HeaderName === "Lot Exp Dt" ||
              val.HeaderName === "Due Date" || val.ControlName === "ExpDtTm")) ||
            (action === "MorePatientInfo" && val.HeaderName === "Privacy Acknowledged Date") ||
            (action === "FacilityInfo" &&  val.HeaderName === "Admit Date")) {

                if (val.ParentName) {
                    if (original && original[val.ParentName] && changed && changed[val.ParentName] &&
                        original[val.ParentName][val.ControlName] && changed[val.ParentName][val.ControlName]) {
                        if ((moment(original[val.ParentName][val.ControlName]).format("MM/DD/YYYY") ===
                        moment(changed[val.ParentName][val.ControlName]).format("MM/DD/YYYY")) ||
                         ((val.HeaderName === "Card Expires" || val.HeaderName === "Lot Exp Dt")
                          // tslint:disable-next-line:max-line-length
                          && (moment.utc(original[val.ParentName][val.ControlName]).local().format("MM/DD/YYYY") === changed[val.ParentName][val.ControlName]))) {
                            original[val.ParentName][val.ControlName]  = changed[val.ParentName][val.ControlName];
                        } else {
                            if (original[val.ParentName][val.ControlName] !== changed[val.ParentName][val.ControlName]) {
                                original[val.ParentName][val.ControlName] = original[val.ParentName][val.ControlName] ?
                                     original[val.ParentName][val.ControlName] : null;
                                        // moment(original[val.ParentName][val.ControlName]).format("MM/DD/YYYY") : null;
                                changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] ?
                                        moment(changed[val.ParentName][val.ControlName]).format("MM/DD/YYYY") : null;
                            }
                        }
                    }
                } else {
                    if (original && original[val.ControlName] && changed && changed[val.ControlName]) {
                        if ((moment(original[val.ControlName]).format("MM/DD/YYYY") ===
                        moment(changed[val.ControlName]).format("MM/DD/YYYY")) ||
                        ((val.HeaderName === "Privacy Acknowledged Date" || val.HeaderName === "Admit Date") &&
                        moment.utc(original[val.ControlName]).local().format("MM/DD/YYYY") === changed[val.ControlName])) {
                            original[val.ControlName]  = changed[val.ControlName];
                        } else {
                            if (original[val.ControlName] !== changed[val.ControlName]) {
                                original[val.ControlName] = original[val.ControlName] ?
                                     original[val.ControlName] : null;
                                        // moment(original[val.ControlName]).format("MM/DD/YYYY") : null;
                                changed[val.ControlName] = changed[val.ControlName] ?
                                        moment(changed[val.ControlName]).format("MM/DD/YYYY") : null;
                            }
                        }
                    }
                }
            }
            if (action === "EditPatient" && val.HeaderName === "Remarks") {
                if (original && original[val.ControlName] && changed && changed[val.ControlName] &&
                    (original[val.ControlName][val.ParentName] !==  changed[val.ControlName][val.ParentName])) {
                    const oldVal = { name: val.HeaderName, value: (original[val.ControlName][val.ParentName] ?
                        original[val.ControlName][val.ParentName] : null) };
                    const newVal = { name: val.HeaderName, value: (changed[val.ControlName][val.ParentName] ?
                        changed[val.ControlName][val.ParentName] : null) };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                }
            }
            if (action === "EditPatient" && val.HeaderName === "Gender" && dropdownsData) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getTextNameById(original[val.ParentName][val.ControlName], dropdownsData.Gender);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getTextNameById(changed[val.ParentName][val.ControlName], dropdownsData.Gender);
                }
            }
            if (action === "EditPatient" && val.HeaderName === "Active" && dropdownsData) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getTextNameById(original[val.ParentName][val.ControlName], dropdownsData.PatStatus);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getTextNameById(changed[val.ParentName][val.ControlName], dropdownsData.PatStatus);
                }
            }
            if (action === "AdditionalInsurance" && val.HeaderName === "Billing Priority" && dropdownsData) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getTextValueById(original[val.ParentName][val.ControlName], dropdownsData.billingOptions);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getTextValueById(changed[val.ParentName][val.ControlName], dropdownsData.billingOptions);
                }
            }
            if (action === "Edit Prescriber" && (["Primary Speciality", "Secondary Speciality", "Tertiary Speciality"].includes(val.HeaderName)) && dropdownsData) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getTextRemarksById(original[val.ParentName][val.ControlName], dropdownsData.Speciality);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getTextRemarksById(changed[val.ParentName][val.ControlName], dropdownsData.Speciality);
                }
            }
            if (action === "Edit Prescriber" && val.HeaderName === "Facility" && dropdownsData) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getTextNameById(original[val.ParentName][val.ControlName], dropdownsData.facilitiesList);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getTextNameById(changed[val.ParentName][val.ControlName], dropdownsData.facilitiesList);
                }
            }
            if (action === "Edit Prescriber" && val.HeaderName === "Prescriber Type" && dropdownsData) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getTextNameById(original[val.ParentName][val.ControlName], dropdownsData.prescType);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getTextNameById(changed[val.ParentName][val.ControlName], dropdownsData.prescType);
                }
            }
            if (action === "Edit Prescriber" && val.HeaderName === "Prescriber Id Type" && dropdownsData) {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getTextNameById(original[val.ParentName][val.ControlName], dropdownsData.prescIdType);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getTextNameById(changed[val.ParentName][val.ControlName], dropdownsData.prescIdType);
                }
            }
              if (action === "Edit Prescriber" && (val.HeaderName === "Telephone1" || val.HeaderName === "Telephone2" || val.HeaderName === "Mobile Number"||
            val.HeaderName === "Fax")) {
                const oldVal = { name: val.HeaderName, value: (original && original[val.ParentName]) ? original[val.ParentName][val.ControlName] : null };
                const newVal = { name: val.HeaderName, value: (changed && changed[val.ParentName]) ? changed[val.ParentName][val.ControlName] : null };
               if(((original && original[val.ParentName]) ? original[val.ParentName][val.ControlName] : null) !== 
               ((changed && changed[val.ParentName]) ? changed[val.ParentName][val.ControlName] : null)){
                oldArr.push(oldVal);
                newArr.push(newVal);
               }

            }
            if (val.HeaderName === "Language") {
                if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    original[val.ParentName][val.ControlName] = this.getLanguageText(original[val.ParentName][val.ControlName]);
                }
                if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    changed[val.ParentName][val.ControlName] = this.getLanguageText(changed[val.ParentName][val.ControlName]);
                }
            }
            if (action === "Edit Drug" && dropdownsData) {
                if (["NDC Qualifier", "Drug Form", "Unit Dose Indicator", "Drug Type", "FDA Approved?", "Cash Price Scheduled", "Preferred Vendor", "Quantity Qualifier", "Potency Unit Code", "Drug Strength Qualifier", "Dosage Form Code", "Compound Type", "Route of Administration(SNOMED)", "Inventory Location", "Automated Machine ID", "Dispensing Unit Form", "Dosage Form Description", "Route of Administration",].includes(val.HeaderName)) {
                    if (original && original[val.ParentName] && (original[val.ParentName][val.ControlName] || original[val.ParentName][val.ControlName] === 0 || original[val.ParentName][val.ControlName] === false)) {
                        original[val.ParentName][val.ControlName] = this.getDrugItemsTextById(val.HeaderName, original[val.ParentName][val.ControlName], dropdownsData);
                    }
                    if (changed && changed[val.ParentName] && (changed[val.ParentName][val.ControlName] || changed[val.ParentName][val.ControlName] === 0 || changed[val.ParentName][val.ControlName] === false)) {
                        changed[val.ParentName][val.ControlName] = this.getDrugItemsTextById(val.HeaderName, changed[val.ParentName][val.ControlName], dropdownsData);
                    }
                }
            }
            if (action === "Add Insurance Restrictions" && (val.HeaderName === "Insurances" || val.HeaderName === "Max Refills Allowed" || val.HeaderName === "Max Qty Allowed" ||
            val.HeaderName === "Apply To All Insurances?" || val.HeaderName === "Incentive Amount" || val.HeaderName === "Vaccine Group" || val.HeaderName === "Submission Clarification" || val.HeaderName === "Basis of Cost" || val.HeaderName === "Reason For Service" || val.HeaderName === "Professional Service" || val.HeaderName === "Result of Service" || val.HeaderName === "Level of Effort" || val.HeaderName === "Co-Agent" || val.HeaderName === "Co-Agent Type" || val.HeaderName === "Clinical Significance" || val.HeaderName === "Pharmacist Comment")) {
                const oldVal = { name: val.HeaderName, value: original ? original[val.ControlName] : null};
                const newVal = { name: val.HeaderName, value:  changed ? changed[val.ControlName] : null};
                if((( original ? original[val.ControlName] : null) !== (changed ? changed[val.ControlName] : null))){
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                   }
            }
            /*if (action === "Add Insurance Restrictions" && (val.HeaderName === "Insurances"||
            val.HeaderName === "Billing NDC" || val.HeaderName === "NDC Qualifier" || val.HeaderName === "Maximum Refills Allowed" || val.HeaderName === "Max Qty Allowed")) {
                const oldVal = { name: val.HeaderName, value: ((original && original[val.ControlName]) ? original[val.ControlName] :null)};
                const newVal = { name: val.HeaderName, value: ((changed && changed[val.ControlName]) ?changed[val.ControlName] :null)};
               if((( original ? original[val.ControlName] : null) !== (changed ? changed[val.ControlName] : null))){
                oldArr.push(oldVal);
                newArr.push(newVal);
               }

            }
            if (action === "Add Insurance Restrictions" && (val.HeaderName === "Apply To All Insurances?"  || val.HeaderName === "Allowed By Insurance?"||val.HeaderName === "Prior Approval Required?" )) {
                const oldVal = { name: val.HeaderName, value: original ? original[val.ControlName] : null};
                const newVal = { name: val.HeaderName, value:  changed ? changed[val.ControlName] : null};
               if((( original ? original[val.ControlName] : null) !== (changed ? changed[val.ControlName] : null))){
                oldArr.push(oldVal);
                newArr.push(newVal);
               }

            }*/
            if (action === "Edit Insurance" && dropdownsData) {
                if (["ID Qualifier", "Processor Type", "Payor Name", "Submission Type", "Submission Clarification", "Basis of Cost", "Price Code - Brand", "Price Code - Generic", "Copay Type", "Sales Tax Basis", "Prescriber ID Type", "Primary Care Prescriber ID", "Rph ID To Submit", "Payor ID Qualifier", "NCPDP Payer Set", "COB Method", "Payer ID Qualifier"].includes(val.HeaderName)) {
                    if (original && original[val.ParentName] && (original[val.ParentName][val.ControlName] || original[val.ParentName][val.ControlName] === 0 || original[val.ParentName][val.ControlName] === false)) {
                        original[val.ParentName][val.ControlName] = this.checkAndUpdateInsuranceData(val.HeaderName, original[val.ParentName][val.ControlName], dropdownsData);
                    }
                    if (changed && changed[val.ParentName] && (changed[val.ParentName][val.ControlName] || changed[val.ParentName][val.ControlName] === 0 || changed[val.ParentName][val.ControlName] === false)) {
                        changed[val.ParentName][val.ControlName] = this.checkAndUpdateInsuranceData(val.HeaderName, changed[val.ParentName][val.ControlName], dropdownsData);
                    }
                }
            }
            if (action === "Edit Insurance" && val.HeaderName =="Telephone") {
               const oldVal = { name: val.HeaderName, value: (original && original[val.ParentName] && original[val.ParentName][val.ControlName] ) ? original[val.ParentName][val.ControlName] :null};
               const newVal = { name: val.HeaderName, value: (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName] ) ?
                changed[val.ParentName][val.ControlName] : null};
               if(original && original[val.ParentName] && changed && changed[val.ParentName] && (original[val.ParentName][val.ControlName] !== changed[val.ParentName][val.ControlName])){
                oldArr.push(oldVal);
                newArr.push(newVal);
               }
            }
            if (!(val.HeaderName === "Compound" || val.HeaderName === "FollowUp tag" || val.HeaderName === "Other Amount" ||
                val.ControlName === "xPhone" || val.HeaderName === "Allergies" || val.HeaderName === "Status" || val.HeaderName === "Date Of Injury" || val.HeaderName === "Drug NDC" || val.HeaderName === "Drug Name") && !val.SubCntrlName) {
                if (val.ParentName) {
                    if ((original && (original[val.ParentName] || original[val.ParentName] === false) && changed && (changed[val.ParentName] || changed[val.ParentName] === false))) {
                        if (!(original[val.ParentName][val.ControlName] == changed[val.ParentName][val.ControlName])
                            && !(!original[val.ParentName][val.ControlName] && changed[val.ParentName][val.ControlName] === "")
                            && !(!changed[val.ParentName][val.ControlName] && original[val.ParentName][val.ControlName] === "")
                            && !(original[val.ParentName][val.ControlName] === false && !changed[val.ParentName][val.ControlName])
                            && !(changed[val.ParentName][val.ControlName] === false && !original[val.ParentName][val.ControlName])
                            && !((original[val.ParentName][val.ControlName] === "0.00"
                                || original[val.ParentName][val.ControlName] === 0) && !changed[val.ParentName][val.ControlName])
                            && !(changed[val.ParentName][val.ControlName] === "0.00" || changed[val.ParentName][val.ControlName] === 0
                                && !original[val.ParentName][val.ControlName])) {
                                const oldVal = { name: val.HeaderName, value: (val.HeaderName == 'Is Non Rx' || val.HeaderName == 'Is PRN' || val.HeaderName == 'Is 340B') ? (original[val.ParentName][val.ControlName] ? 'YES' : 'NO') : original[val.ParentName][val.ControlName]};
                                const newVal = { name: val.HeaderName, value: (val.HeaderName == 'Is Non Rx' || val.HeaderName == 'Is PRN' || val.HeaderName == 'Is 340B') ? (changed[val.ParentName][val.ControlName] ? 'YES' : 'NO') : changed[val.ParentName][val.ControlName]};
                                oldArr.push(oldVal);
                                newArr.push(newVal);
                        }
                    } else if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                        const oldVal = { name: val.HeaderName, value: original[val.ParentName][val.ControlName] };
                        const newVal = { name: val.HeaderName, value: val.HeaderName === "Auth Refill" ? "0" : null };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    } else if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                        const oldVal = { name: val.HeaderName, value: val.HeaderName === "Auth Refill" ? "0" : null  };
                        const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                } else if (!val.ParentName) {
                    if (action === "ClinicalInfo" && val.HeaderName === "Measure Time") {
                        if (original && original[val.ControlName]) {
                            var time: any = original[val.ControlName].split(":"), copyTime: any = "";
                            if (time && time.length > 0) {
                                time.map((t: any) => {
                                    const t1 = (t.length < 2) ? ("0" + t) : t;
                                    copyTime = (copyTime) ? copyTime + (":" + t1) : (t1);
                                });
                                original[val.ControlName] = copyTime;
                            }
                        }
                        if (changed && changed[val.ControlName]) {
                            var time: any = changed[val.ControlName].split(":"), copyTime: any = "";
                            if (time && time.length > 0) {
                                time.map((t: any) => {
                                    const t1 = (t.length < 2) ? ("0" + t) : t;
                                    copyTime = (copyTime) ? copyTime + (":" + t1) : (t1);
                                });
                                changed[val.ControlName] = copyTime;
                            }
                        }
                    }
                    if (action === "Documents" && val.HeaderName === "Subcategory" && dropdownsData) {
                        if (original && (original[val.ControlName] || original[val.ControlName] === 0)) {
                            original[val.ControlName] = this.getDocumentSubCategoryNameById(original[val.ControlName], dropdownsData);
                        }
                        if (changed && (changed[val.ControlName] || changed[val.ControlName] === 0)) {
                            changed[val.ControlName] = this.getDocumentSubCategoryNameById(changed[val.ControlName], dropdownsData);
                        }
                    }
                    if (action === "Notes" && val.HeaderName === "Category" && dropdownsData) {
                        if (original && (original[val.ControlName] || original[val.ControlName] === 0)) {
                            original[val.ControlName] = this.getCategoryNameById(original[val.ControlName], dropdownsData);
                        }
                        if (changed && (changed[val.ControlName] || changed[val.ControlName] === 0)) {
                            changed[val.ControlName] = this.getCategoryNameById(changed[val.ControlName], dropdownsData);
                        }
                    }
                    if (action === "Notes" && val.HeaderName === "Is Shown As Popup?") {
                        if (original && (original[val.ControlName] || original[val.ControlName] === false)) {
                            const oldVal = { name: val.HeaderName, value: original[val.ControlName] };
                            oldArr.push(oldVal);
                        }
                        if (changed && (changed[val.ControlName] || changed[val.ControlName] === false)) {
                            const newVal = { name: val.HeaderName, value: changed[val.ControlName] };
                            newArr.push(newVal);
                        }
                    } else if ((original && (original[val.ControlName] || original[val.ControlName] === false) && changed && (changed[val.ControlName] || changed[val.ControlName] === false))) {
                        if (!(original[val.ControlName] == changed[val.ControlName])
                            && !(!original[val.ControlName] && changed[val.ControlName] === "")
                            && !(original[val.ControlName] === false && !changed[val.ControlName])) {
                            const oldVal = { name: val.HeaderName, value: ((val.ControlName == "PickUpFromId") ? (this.systemData.pickUpFrom.find(v => v['Id'] === original[val.ControlName]).Remarks) : ((val.ControlName == "SpeciesCdId") ? morePatInfo.SpeciesCodes.find(v => v['Id'] === original[val.ControlName]).Name : original[val.ControlName])) };
                            const newVal = { name: val.HeaderName, value: (val.ControlName == "PickUpFromId") ? (this.systemData.pickUpFrom.find(v => v['Id'] === changed[val.ControlName]).Remarks) : (val.ControlName == "SpeciesCdId") ? morePatInfo.SpeciesCodes.find(v => v['Id'] === changed[val.ControlName]).Name : changed[val.ControlName] };
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                    } else if (original && (original[val.ControlName] || original[val.ControlName] === false)) {
                        const oldVal = { name: val.HeaderName, value: (val.ControlName == "PickUpFromId") ? this.systemData.pickUpFrom.find(v => v['Id'] === original[val.ControlName]).Remarks : ((val.ControlName == "SpeciesCdId") ? morePatInfo.SpeciesCodes.find(v => v['Id'] === original[val.ControlName]).Name: original[val.ControlName]) };
                        const newVal = { name: val.HeaderName, value: null };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    } else if (changed && (changed[val.ControlName] || changed[val.ControlName] === false)) {
                        const oldVal = { name: val.HeaderName, value: null };
                        const newVal = { name: val.HeaderName, value: (val.ControlName == "PickUpFromId") ? (this.systemData.pickUpFrom.find(v => v['Id'] === changed[val.ControlName]).Remarks) : ((val.ControlName == "SpeciesCdId") ? morePatInfo.SpeciesCodes.find(v => v['Id'] === changed[val.ControlName]).Name : changed[val.ControlName]) };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                }
            }
            if ((action === "EditRx" || action === "MiscRxInfo") && (val.HeaderName === "Rx Expiry Date") && changed?.rxExtra?.RxExpDt) {
                if (original && original[val.ParentName]) {
                    original[val.ParentName][val.ControlName] = original[val.ParentName][val.ControlName] ?
                     original[val.ParentName][val.ControlName] : null;
                }
                if (changed && changed[val.ParentName]) {
                    changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] ?
                        moment(changed[val.ParentName][val.ControlName]).format("MM/DD/YYYY") : null;
                }
                const oldVal = { name: val.HeaderName, value:  original[val.ParentName][val.ControlName] };
                const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] };
                if(oldVal !== newVal){
                    oldArr = [];
                    newArr = [];
                    oldArr.push(oldVal); 
                    newArr.push(newVal);
                }
            }
            if ((action === "WCOM" && val.HeaderName === "Date Of Injury")) {
                if (original && original[val.ParentName] && changed && changed[val.ParentName]) {
                    original[val.ParentName][val.ControlName] = original[val.ParentName][val.ControlName] ?
                    ((moment.utc(original[val.ParentName][val.ControlName]).local().format("MM/DD/YYYY")
                    !== changed[val.ParentName][val.ControlName]) ? original[val.ParentName][val.ControlName] : null) : null;
                     changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] ?
                     (((moment.utc(original[val.ParentName][val.ControlName]).local().format("MM/DD/YYYY")
                     !== changed[val.ParentName][val.ControlName]) ?
                        moment(changed[val.ParentName][val.ControlName]).format("MM/DD/YYYY") : null)) : null;
                        const oldVal = { name: val.HeaderName, value:  original[val.ParentName][val.ControlName] };
                        const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                }
                // if (changed && changed[val.ParentName]) {
                //     changed[val.ParentName][val.ControlName] = changed[val.ParentName][val.ControlName] ?
                //         moment(changed[val.ParentName][val.ControlName]).format("MM/DD/YYYY") : null;
                // }
                // if (moment.utc(original[val.ParentName][val.ControlName]).local().format("MM/DD/YYYY") !== changed[val.ParentName][val.ControlName]) {
                //     const oldVal = { name: val.HeaderName, value:  original[val.ParentName][val.ControlName] };
                //     const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] };
                //     oldArr.push(oldVal);
                //     newArr.push(newVal);
                // }
            }
            if ((action === "ClinicalInfo" && val.HeaderName === "Measure Date")) {
                // if (original && original[val.ControlName]) {
                //     original[val.ControlName] = original[val.ControlName] ?
                //      original[val.ControlName] : null;
                // }
                // if (changed && changed[val.ControlName]) {
                //     changed[val.ControlName] = changed[val.ControlName] ?
                //         moment(changed[val.ControlName]).format("MM/DD/YYYY") : null;
                // }
                // const oldVal = { name: val.HeaderName, value:  (original && original[val.ControlName]) ?
                //     original[val.ControlName] : null};
                // const newVal = { name: val.HeaderName, value: (changed && changed[val.ControlName])  ?
                //      moment(changed[val.ControlName]).format("MM/DD/YYYY") : null};
                // oldArr.push(oldVal);
                // newArr.push(newVal);
            }
            if (action === "EditRx" && val.HeaderName === "Rx#") {
                let OldValue = null;
                let NewValue = null;
                if (original) {
                    OldValue = (original["Prescription"] && original["Prescription"]["PrescNum"]) ?
                    original["Prescription"]["PrescNum"] : null;
                }
                if (changed) {
                    NewValue = (changed["Prescription"] && changed["Prescription"]["PrescNum"])  ?
                     changed["Prescription"]["PrescNum"] : null;
                }
                if (OldValue !== NewValue) {
                    const oldVal = { name: val.HeaderName, value: OldValue };
                    const newVal = { name: val.HeaderName, value: NewValue };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }
            }
            if (action === "EditRx" && val.HeaderName === "Drug NDC") {
                if (original && original[val.ParentName] && changed && changed[val.ParentName]) {
                    this.oldNDC = original[val.ParentName][val.ControlName] ? (original[val.ParentName][val.ControlName]).includes("-") ?
                    original[val.ParentName][val.ControlName] :
                    this._formatsUtil.getNDCFormat(original[val.ParentName][val.ControlName]) : null;
                    this.newNDC = changed[val.ParentName][val.ControlName] ?  (changed[val.ParentName][val.ControlName]).includes("-") ?
                    changed[val.ParentName][val.ControlName] :
                    this._formatsUtil.getNDCFormat(changed[val.ParentName][val.ControlName]) : null;
                    if (this.oldNDC !== this.newNDC) {
                        const oldVal = { name: val.HeaderName, value: this.oldNDC };
                        const newVal = { name: val.HeaderName, value: this.newNDC};
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                } else if (original && original[val.ParentName] && original[val.ParentName][val.ControlName]) {
                    this.oldNDC = (original[val.ParentName][val.ControlName]).includes("-") ?
                    original[val.ParentName][val.ControlName] :
                    this._formatsUtil.getNDCFormat(original[val.ParentName][val.ControlName]);
                    const oldVal = { name: val.HeaderName, value: this.oldNDC };
                    const newVal = { name: val.HeaderName, value: null };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                } else if (changed && changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    this.newNDC = (changed[val.ParentName][val.ControlName]).includes("-") ?
                         changed[val.ParentName][val.ControlName] :
                         this._formatsUtil.getNDCFormat(changed[val.ParentName][val.ControlName]);
                    const oldVal = { name: val.HeaderName, value: null };
                    const newVal = { name: val.HeaderName, value: this.newNDC };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }
            }
            if (action === "EditRx" && val.HeaderName === "Drug Name") {
                if (original && original[val.ParentName] && changed && changed[val.ParentName]) {
                    if ((original[val.ParentName][val.ControlName] !== changed[val.ParentName][val.ControlName]) ||
                    this.oldNDC !== this.newNDC) {
                        const oldVal = { name: val.HeaderName, value: original[val.ParentName][val.ControlName] };
                        const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] };
                        oldArr.push(oldVal);
                        newArr.push(newVal);
                    }
                    }
                    this.oldNDC = null;
                    this.newNDC = null;
                }
            if (action === "EditRx" && val.ParentName === "Prescriber") {
                let oldPresAddress: any;
                let newPresAddress: any;
                if (original && original[val.ParentName] && original[val.ParentName][val.SecondParentName] &&
                    changed && changed[val.ParentName] && changed[val.ParentName][val.SecondParentName]) {
                        if (original[val.ParentName][val.SecondParentName].length) {
                               oldPresAddress = original[val.ParentName][val.SecondParentName].find(address => {
                                if (original["Prescription"] && original["Prescription"]["PrescriberAddressId"] &&
                                (address["Id"] === original["Prescription"]["PrescriberAddressId"])) {
                                    return address;
                                }
                            });
                        }
                        if (changed[val.ParentName][val.SecondParentName].length) {
                                 newPresAddress = changed[val.ParentName][val.SecondParentName].find(address => {
                                 if (changed["Prescription"] && changed["Prescription"]["PrescriberAddressId"] &&
                                 (address["Id"] === changed["Prescription"]["PrescriberAddressId"])) {
                                     return address;
                                 }
                             });
                         }
                }
            if (action === "EditRx" && val.ParentName === "Prescriber" && (val.HeaderName === "Prescriber Address 1" ||
            val.HeaderName === "Prescriber Address 2" || val.HeaderName === "Prescriber State" || val.HeaderName === "Prescriber City" ||
             val.HeaderName === "Prescriber Zip Code" || val.HeaderName === "Prescriber Zip Extention" || val.HeaderName === "SPI#")) {
                if (oldPresAddress && newPresAddress && oldPresAddress[val.ControlName] && newPresAddress[val.ControlName]) {
                        if (oldPresAddress[val.ControlName] !== newPresAddress[val.ControlName]) {
                            const oldVal = { name: val.HeaderName, value: oldPresAddress[val.ControlName] };
                            const newVal = { name: val.HeaderName, value: newPresAddress[val.ControlName] };
                            oldArr.push(oldVal);
                            newArr.push(newVal);
                        }
                } else if (oldPresAddress && oldPresAddress[val.ControlName]) {
                    const oldVal = { name: val.HeaderName, value: oldPresAddress[val.ControlName] };
                    const newVal = { name: val.HeaderName, value: null };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }  else if (newPresAddress && newPresAddress[val.ControlName]) {
                    const oldVal = { name: val.HeaderName, value: null };
                    const newVal = { name: val.HeaderName, value: newPresAddress[val.ControlName]  };
                    oldArr.push(oldVal);
                    newArr.push(newVal);
                }
            }
        }
        });
        if (othType === "Edit Patient Insurance") {
            if(oldArr){
                oldArr.map(elm => {
                    elm["name"] = original["InsuCarrier"]["InsurerCode"] + "-" + elm["name"];
                    elm["value"] = elm["value"];
                    return elm;
                });
            }
            if(newArr){
                newArr.map(elm => {
                    elm["name"] = changed["InsuCarrier"]["InsurerCode"] + "-" + elm["name"];
                    return elm;
                });
            }
        }

        this.delta.original = oldArr;
        this.delta.changed = newArr;
        this.auditObj.delta = this.delta;
        this.auditObj.entity = entity;
        this.auditObj.action = action;
        this.auditObj.entityid = entityid;
        this.auditObj.created = moment().format("YYYY/MM/DD");
        this.auditObj.userid = Number(this._userService.getToken("UserId"));
        this.alreadyIsPreferedAdded = false;

        if (newArr && newArr.length > 0 && !frmMult) {
            this.saveAuditDetails([this.auditObj]);
        }
        return newArr && newArr.length > 0 ? this.auditObj : null;
    }

    async saveAuditDetails(data) {
        await this._commonServ.saveAuditChanges(data).toPromise();
    }

    async saveAuditOnAdd(changed, action, entity, entityid, model, dropdownsData?: any) {
        const oldArr = [];
        const newArr = [];

        model.map(val => {
            if ((entity === "New Rx" || entity === "Refill Rx") && val.HeaderName === "Compound") {
                if (changed[val.ParentName]) {
                    const newVal = { name: val.HeaderName, value: changed[val.ParentName][val.ControlName] === 2 ? "Y" : "N" };
                    newArr.push(newVal);
                }
            }
            if ((entity === "New Rx" || entity === "Refill Rx") && val.HeaderName === "FollowUp tag") {
                if (changed[val.ParentName]) {
                    const newVal = {
                        name: val.HeaderName,
                        value: changed[val.ParentName] ? changed[val.ParentName][0] ?
                            changed[val.ParentName][0][val.ControlName] : null : null
                    };
                    newArr.push(newVal);
                }
            }
            if ((entity === "Rx" || entity === "Refill Rx") && val.HeaderName === "Other Amount") {
                if (changed[val.ParentName] && changed[val.ParentName][val.ControlName]) {
                    (changed[val.ParentName][val.ControlName]).map((elm, index) => {
                        const newVal = { name: "Other Amount" + (index+1), value: elm.Amount ? elm.Amount : null };
                        // const newVal = [{ name: val.HeaderName + "-" + (index+1), value: elm.Amount ? elm.Amount : null },
                        //                 { name: val.HeaderName + "-" + (index+1) + " Category", value: elm.OtherAmntCatId ? elm.OtherAmntCatId : null }];
                        // OtherAmntCatId
                        newArr.push(newVal);
                    });
                }
            }
            if (entity === "Add Patient" && val.ControlName === "xPhone") {
                if (val.HeaderName === "Telephone") {
                    const chang = changed["Phones"].filter(tele => tele.PhoneCatId === 3)[0];
                    if (chang && (chang["xPhone"] && chang["xPhone"].length > 0)) {
                        const newVal = { name: val.HeaderName, value: chang["xPhone"] };
                        newArr.push(newVal);
                    }
                } else if (val.HeaderName === "Mobile") {
                    const chang = changed["Phones"].filter(tele => tele.PhoneCatId === 2)[0];
                    if (chang && (chang["xPhone"] && chang["xPhone"].length > 0)) {
                        const newVal = { name: val.HeaderName, value: chang["xPhone"] };
                        newArr.push(newVal);
                    }
                } else if (val.HeaderName === "Work Number") {
                    const chang = changed["Phones"].filter(tele => tele.PhoneCatId === 1)[0];
                    if (chang && (chang["xPhone"] && chang["xPhone"].length > 0)) {
                        const newVal = { name: val.HeaderName, value: chang["xPhone"] };
                        newArr.push(newVal);
                    }
                }
            }
            if (entity === "AddPatient" || entity == "Patient" && val.HeaderName === "Allergies") {
                const chgTtlAllergy = [];
                if (changed.PatAllergies) {
                    changed.PatAllergies.map(patalg => {
                        chgTtlAllergy.push(patalg["AllergyName"]);
                    });
                }
                if (changed.Drugs) {
                    changed.Drugs.map(patalg => {
                        chgTtlAllergy.push(patalg["Name"]);
                    });
                }
                if (changed.OtherAllergies) {
                    changed.OtherAllergies.map(patalg => {
                        chgTtlAllergy.push(patalg["Name"]);
                    });
                }
                if (chgTtlAllergy && chgTtlAllergy.length > 0) {
                    const newVal = { name: val.HeaderName, value: chgTtlAllergy.toString() };
                    newArr.push(newVal);
                }
            }
            if (entity === "Add Patient" && changed["PatientDiagnosis"] && changed["PatientDiagnosis"].length > 0) {
                const patdiagData = [];
                changed["PatientDiagnosis"].map(patDiag => {
                    patdiagData.push(patDiag["DiagnosisId"]);
                });
                const newVal = { name: "PatientDiagnosis", value: patdiagData.toString() };
                newArr.push(newVal);
            }
            if (action === "Add Drug" && dropdownsData) {
                if (["NDC Qualifier", "Drug Form", "Unit Dose Indicator", "Drug Type", "FDA Approved?", "Cash Price Scheduled"].includes(val.HeaderName)) {
                    if (changed && changed[val.ParentName] && (changed[val.ParentName][val.ControlName] || changed[val.ParentName][val.ControlName] === 0 || changed[val.ParentName][val.ControlName] === false)) {
                        changed[val.ParentName][val.ControlName] = this.getDrugItemsTextById(val.HeaderName, changed[val.ParentName][val.ControlName], dropdownsData);
                    }
                }
            }
            if (action === "Notes" && val.HeaderName === "Category" && dropdownsData) {
                if (changed && (changed[val.ControlName] || changed[val.ControlName] === 0)) {
                    changed[val.ControlName] = this.getCategoryNameById(changed[val.ControlName], dropdownsData);
                }
            }
            if (entity === "Patient" && changed["Phones"] && changed["Phones"].length > 0) {
                const phonesData = [];
                changed["Phones"].map(patDiag => {
                    phonesData.push(patDiag["xPhone"]);
                });
                if(val.HeaderName == "Mobile" && phonesData){
                    const newVal = { name: "Mobile", value: phonesData[0] };
                    newArr.push(newVal);
                }else if(val.HeaderName == "Telephone" && phonesData){
                    const newVal1 = { name: "Telephone", value: phonesData[1] };
                    newArr.push(newVal1);
                }if(val.HeaderName == "Work Number" && phonesData){
                    const newVal2 = { name: "Work Number", value: phonesData[2] };
                    newArr.push(newVal2);
                }
            }
            if (val.ParentName &&
                !(val.HeaderName === "Compound" || val.HeaderName === "FollowUp tag"
                || val.HeaderName === "Allergies" || val.ControlName === "xPhone" || val.HeaderName == "Other Amount")) {
                if (changed[val.ParentName] && (changed[val.ParentName][val.ControlName] || changed[val.ParentName][val.ControlName] === false)) {
                    let changedVal = changed[val.ParentName][val.ControlName];
                    if (entity === "Refill Rx" && val.HeaderName === "Ord Date") {
                        changedVal = moment(changedVal).format("MM/DD/YYYY");
                    }
                    if ((val.HeaderName === "Patient Name") || (val.HeaderName === "Prescriber Name")) {
                        changedVal = changedVal.replace(/_/gi, ", ");
                    }
                    if ((entity === "Rx" || entity === "Refill Rx") && (val.HeaderName === "Status")) {
                        changedVal = (changed["Prescription"] && changed["PrescReFill"] && changed["Prescription"]["RxStatusId"] && changed["PrescReFill"]["StatusId"])  ?
                        this._commonUtil.getRxStatus(changed["Prescription"]["RxStatusId"],
                        changed["PrescReFill"]["StatusId"], changed["RxBill"]["StatusId"]) : changed["Name"];
                    }
                    if ((entity === "Rx" || entity === "Refill Rx") && (val.HeaderName === "Order Status")) {
                        changedVal = this.getOrderStatus(changedVal);
                    }
                    if (val.HeaderName === "Language") {
                        changedVal = this.getLanguageText(changedVal);
                    }
                    if (entity === "Patient" && val.HeaderName === "Gender" && dropdownsData) {
                        changedVal = this.getTextNameById(changedVal, dropdownsData.Gender);
                    }
                    if (entity === "Patient" && val.HeaderName === "Active" && dropdownsData) {
                        changedVal = this.getTextNameById(changedVal, dropdownsData.PatStatus);
                    }
                    if (entity === "Prescriber" && (["Primary Speciality", "Secondary Speciality", "Tertiary Speciality"].includes(val.HeaderName)) && dropdownsData) {
                        changedVal = this.getTextRemarksById(changedVal, dropdownsData.Speciality);
                    }
                    if (entity === "Prescriber" && val.HeaderName === "Facility" && dropdownsData) {
                        changedVal = this.getTextNameById(changedVal, dropdownsData.facilitiesList);
                    }
                    if (entity === "Prescriber" && val.HeaderName === "Prescriber Type" && dropdownsData) {
                        changedVal = this.getTextNameById(changedVal, dropdownsData.prescType);
                    }
                    if (entity === "Prescriber" && val.HeaderName === "Prescriber Id Type" && dropdownsData) {
                        changedVal = this.getTextNameById(changedVal, dropdownsData.prescIdType);
                    }
                    if (entity === "Insurance" && dropdownsData) {
                        changedVal = this.checkAndUpdateInsuranceData(val.HeaderName, changedVal, dropdownsData);
                    }
                    const newVal = { name: val.HeaderName, value: changedVal};
                    newArr.push(newVal);
                }
            } else if (!val.ParentName) {
                if (changed && (changed[val.ControlName] || changed[val.ControlName] === false)) {
                    if (action === "ClinicalInfo" && val.HeaderName === "Measure Time") {
                        if (changed && changed[val.ControlName]) {
                            var time: any = changed[val.ControlName].split(":"), copyTime: any = "";
                            if (time && time.length > 0) {
                                time.map((t: any) => {
                                    const t1 = (t.length < 2) ? ("0" + t) : t;
                                    copyTime = (copyTime) ? copyTime + (":" + t1) : (t1);
                                });
                                changed[val.ControlName] = copyTime;
                                const newVal = { name: val.HeaderName, value: changed[val.ControlName] };
                                newArr.push(newVal);
                            }
                        }
                    } else {
                        const newVal = { name: val.HeaderName, value: changed[val.ControlName] };
                        newArr.push(newVal);
                    }
                }
                /*if (action === "NotesLog" && val.HeaderName === "Is Shown As Popup?" && (changed[val.ControlName] || changed[val.ControlName] === false)) {
                    const newVal = { name: val.HeaderName, value: changed[val.ControlName] };
                    newArr.push(newVal);
                }*/
            }
        });
        if (entity === "Patient" && changed && changed["PatientDiagnosis"] && changed["PatientDiagnosis"].length > 0) {
            const patdiagData = [];
            changed["PatientDiagnosis"].map(patDiag => {
                patdiagData.push(patDiag["DiagnosisId"]);
            });
            const newVal = { name: "PatientDiagnosis", value: patdiagData.toString() };
            newArr.push(newVal);
        }
        this.delta.original = null;
        this.delta.changed = newArr;
        this.auditObj.delta = this.delta;
        this.auditObj.entity = entity;
        this.auditObj.action = action;
        this.auditObj.entityid = entityid;
        this.auditObj.created = moment().format("YYYY/MM/DD");
        this.auditObj.userid = Number(this._userService.getToken("UserId"));
        if (newArr && newArr.length > 0) {
          this.saveAuditDetails([this.auditObj]);
        }
    }

    async SaveAuditOnDelete(original, changed, action, entity, entityid) {
        this.delta.original = original;
        this.delta.changed = changed;
        this.auditObj.delta = this.delta;
        this.auditObj.entity = entity;
        this.auditObj.action = action;
        this.auditObj.entityid = entityid;
        this.auditObj.created = moment().format("YYYY/MM/DD");
        this.auditObj.userid = Number(this._userService.getToken("UserId"));
        await this.saveAuditDetails([this.auditObj]);
    }

    async auditWithMultiEmtity(data) {
        const changesRes = [];
        if (data && data.length) {
              data.map(v => {
                const k = this.auditObj;
                this.delta.original = v.original;
                this.delta.changed = v.changed;
                k["delta"] = this.delta;
                k["entity"] = v.entity;
                k["action"] = v.action;
                k["entityid"] = v.entityid;
                k["created"] = moment().format("YYYY/MM/DD");
                k["userid"] = Number(this._userService.getToken("UserId"));
                changesRes.push(JSON.parse(JSON.stringify(k)));
                return k;
            });
        }
        if (changesRes && changesRes.length) {
            await this.saveAuditDetails(changesRes);
        }
    }

    getOrderStatus(changedVal){
        return (changedVal === "C") ? "Current" : (changedVal === "D") ? "Discontinued" : changedVal;
    }
    getLanguageText(code) {
        let langText = "";
        this.systemData.Lang.map(val => {
            if (val.LangCode === code) {
                langText = val.Name;
            }
        });
        return langText;
    }
    getDrugItemsTextById(HeaderName, idCode, dropdownsData) {
        let dropdownData: any;
        let text;
        if(HeaderName === "Automated Machine ID") {
            dropdownData = dropdownsData.AutoMc;
        } else if (HeaderName === "Inventory Location") {
            dropdownData = dropdownsData.InvLoc;
        } else if (HeaderName === "Dosage Form Code") {
            dropdownData = dropdownsData.DoseFrmCode
        } else if (HeaderName === "Drug Strength Qualifier") {
            dropdownData = dropdownsData.DrugStrQual;
        } else if (HeaderName === "Potency Unit Code") {
            dropdownData = dropdownsData.PotencyUnitCode;
        } else if (HeaderName === "Quantity Qualifier") {
            dropdownData = dropdownsData.QtyQual;
        } else if (HeaderName === "Dosage Form Description") {
            dropdownData = dropdownsData.DoseFrmDesc;
        } else if (HeaderName === "Dispensing Unit Form") {
            dropdownData = dropdownsData.DispUnitForm;
        } else if (HeaderName === "Route of Administration") {
            dropdownData = dropdownsData.AdmnstrnRoute;
        } else if (HeaderName === "Compound Type") {
            dropdownData = dropdownsData.CompoundTyp;
        } else if(HeaderName === "Route of Administration(SNOMED)") {
            dropdownData = dropdownsData.AdmnstrnRoute2;
        } else if(HeaderName === "NDC Qualifier") {
            dropdownData = dropdownsData.NdcQualifier;
        } else if(HeaderName === "Unit Dose Indicator") {
            dropdownData = dropdownsData.UnitDoseIndication;
        } else if(HeaderName === "Drug Form") {
            dropdownData = dropdownsData.FDA_Form;
        } else if(HeaderName === "Cash Price Scheduled") {
            dropdownData = dropdownsData.PriceScheduleList;
        } else if(HeaderName === "Preferred Vendor") {
            dropdownData = dropdownsData.Vendor;
        } else if (HeaderName === "Drug Type"){
            dropdownData = dropdownsData.DrugType;
        } else if (HeaderName === "FDA Approved?"){
            dropdownData = dropdownsData.fdaApproved;
        }

        if (dropdownData) {
            if (HeaderName === "Drug Form") {
                dropdownData.map(val=> {
                    if (val.FDAFormID === idCode) {
                        text = val.FormName;
                    }
                });
            } else if (HeaderName === "NDC Qualifier" || HeaderName === "Unit Dose Indicator" || HeaderName === "Drug Type") {
                dropdownData.map(val=> {
                    if(val.Id === idCode) {
                        text = val.Remarks;
                    }
                });
            } else if (HeaderName === "Preferred Vendor") {
                dropdownData.map(val=> {
                    if(val.Id === idCode) {
                        text = val.VendorName;
                    }
                });
            } else {
                dropdownData.map(val=> {
                    if(val.Id === idCode) {
                        text = val.Name;
                    }
                });
            }
        } else
            text = idCode;
        return text;
    }

    getDocumentSubCategoryNameById(idCode, dropdownsData) {
        let text = idCode;
        dropdownsData.map(val=> {
            if(val.Id === idCode) {
                text = val.Name;
            }
        });
        return text;
    }

    getCategoryNameById(idCode, dropdownsData) {
        let text = idCode;
        dropdownsData.map(val=> {
            if(val.Id === idCode) {
                text = val.Name;
            }
        });
        return text;
    }

    getTextNameById(idCode, dropdownsData) {
        let text = idCode;
        dropdownsData.map(val=> {
            if(val.Id === idCode) {
                text = val.Name;
            }
        });
        return text;
    }

    getTextValueById(idCode, dropdownsData) {
        let text = idCode;
        dropdownsData.map(val=> {
            if(val.Id === idCode) {
                text = val.value;
            }
        });
        return text;
    }
    getTextRemarksById(idCode, dropdownsData){
        let text = idCode;
        dropdownsData.map(val=> {
            if(val.Id === idCode) {
                text = val.Remarks;
            }
        });
        return text;
    }
    checkAndUpdateInsuranceData(HeaderName, changedVal, dropdownsData) {
        let UpdatedVal = changedVal;
        switch (HeaderName) {
            case "ID Qualifier":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["InsuPlanIdQualif"], "Id", "Remarks");
                break;
            case "Processor Type":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["ProcCat"], "Id", "Name");
                break;
            case "Payor Name":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PayorOrganizationModel"], "PayorId", "PayorName");
                break;
            case "Submission Type":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["InsuXmitSubCat"], "Id", "Remarks");
                break;
            case "Submission Clarification":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["subClarification"], "Id", "Remarks");
                break;
            case "Basis of Cost":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["CostBasis"], "Id", "Name");
                break;
            case "Price Code - Brand":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PriceSchedule"], "Id", "PriceCode");
                break;
            case "Price Code - Generic":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PriceSchedule"], "Id", "PriceCode");
                break;
            case "Copay Type":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["CoPayCat"], "Id", "Remarks");
                break;
            case "Sales Tax Basis":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["SalesTaxBasis"], "Id", "Remarks");
                break;
            case "Prescriber ID Type":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PrescIdCat"], "Id", "Name");
                break;
            case "Primary Care Prescriber ID":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PrescIdCat"], "Id", "Name");
                break;
            case "Rph ID To Submit":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PharmacistIdCat"], "Id", "Name");
                break;
            case "Payor ID Qualifier":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PayorIdCat"], "Id", "Name");
                break;
            case "NCPDP Payer Set":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["NCPDP"], "Id", "Name");
                break;
            case "COB Method":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["COBMethod"], "Id", "Remarks");
                break;
            case "Payer ID Qualifier":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["PayerQualifier"], "Id", "Remarks");
                break;
            case "Other Insurance Code":
                UpdatedVal = this.getDropdownTextData(changedVal, dropdownsData["OtherInsureCodes"], "id", "code");
                break;
            default:
                break;
        }
        return UpdatedVal;
    }
    getDropdownTextData(id, dropdownData, matchKey, matchText) {
        let text = id;
        dropdownData.map(res=>{
            if (res[matchKey] === id) {
                text = res[matchText];
            }
        });
        return text;
    }
}
