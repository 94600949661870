<ng-template #RXFWTG let-c="close" let-d="dismiss" class="el--popup modal-unset-overflow">
    <app-custom-modal (oncrossBtnClick)="cancelFollowupTag()">
        <h4 header> Set Rx Follow-up Tag </h4>
        <span body class="overflow_unset">
            <div class="row">
                <div class="col padding--right__7">
                    <div class="exprx--common-block">
                        <div class="eprx--block__content erx--body">
                            <div class="row">
                                <div class="col-12">
                                    <eprx-select [RxSelectId]="'followUpTagIDpop'" ngbAutofocus [LabelText]="'Follow-up Tag'" [PlaceHolder]="''" [ControlName]="'RxTagId'" [FormGroupName]="PrescRefill_RxTags"
                                        [List]="folwUpList" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [AutoFocus]="true" (TriggerSelectValue)="selectedRxTag($event)">
                                    </eprx-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <button footer class="hotkey_success" (click)="saveRxTag()" (keyup.enter)="saveRxTag()" *ngIf="rxFG?.value['rxType'] === 'er' || this.frmTrans" appShortcutKey [AltKey]="'s'"><span>S</span> Save</button>
        <button footer class="hotkey_success" (click)="saveFrNewRx()" (keyup.enter)="saveFrNewRx()" *ngIf="!(rxFG?.value['rxType'] === 'er' || this.frmTrans)" appShortcutKey [AltKey]="'s'"><span>S</span> Save</button>
        <button footer class="hotkey_success" (click)="cancelFollowupTag()" (keyup.enter)="cancelFollowupTag();" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
    </app-custom-modal>
</ng-template>
