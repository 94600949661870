<div *ngIf="showUserGuide && !isMaximized" appDrag appDragHandle class="userGuide-position-sm userGuidePopup">
    <div class="row userGuide-header">
        <div class="col-12">
            <div class="row">
                <div class="col-9">
                    <h4 class="userGuide-title">
                        <i class="fas fa-arrow-left mr-2 backArrow" (click)="navigateToBackScreen()" *ngIf="(idList.length > 1 || respForSSO)"></i>
                        {{ HeaderText ? HeaderText : 'User Guide' }}
                    </h4>
                </div>
                <div class="col-3">
                    <i class="fal fa-window-maximize mr-3 userGuide-icons padding_left_1_2rem" (click)="minimizeScreen()" *ngIf="!isMaximized"></i>
                    <i class="fa fa-times userGuide-icons" (click)="close()"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="userGuide-body">
        <div [innerHTML]="userGuideData" (click)="clickOnUserGuideLinks()"></div>
    </div>
</div>

<div *ngIf="showUserGuide && isMaximized" appDrag appDragHandle class="userGuide-position-lg userGuidePopup">
    <div class="userGuide-header">
        <h4 class="userGuide-title">
            <i class="fas fa-arrow-left mr-2 backArrow" (click)="navigateToBackScreen()" *ngIf="(idList.length > 1)"></i>
            {{ HeaderText ? HeaderText : 'User Guide' }}
        </h4>
        <div>
          <i class="fal fa-window-restore mr-3 userGuide-icons" (click)="maximizeScreen()" *ngIf="isMaximized"></i>
          <i class="fa fa-times userGuide-icons" (click)="close()"></i>
        </div>
    </div>
    <div class="userGuide-body">
        <div [innerHTML]="userGuideData" (click)="clickOnUserGuideLinks()"></div>
    </div>
</div>
