import { Injectable } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

declare let gtag: Function;
const googleApiKey = environment.GoogleAnalyticsKey;;
@Injectable({
    providedIn: "root"
})
export class AlertService {
    public subject = new Subject<any>();
    private keepAfterNavigationChange = false;
    public helpText = new Subject<any>();
    public userGuide = new Subject<any>();
    public inputFocus = "";
    constructor(private router: Router) {
        // clear alert message on route change
      /*  router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                    this.helpText.next();
                }
            } 
            
            else if (event instanceof NavigationEnd) {
                if (googleApiKey && googleApiKey.length) {
                    gtag('config', environment.GoogleAnalyticsKey,
                        {
                            'page_path': event.urlAfterRedirects
                        }
                    );
                }
            } 
        });*/
    }

    success(
        message: string,
        keepAfterNavigationChange = false,
        timeInterval?: string
    ) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({
            type: "success",
            text: message,
            interval: timeInterval
        });
    }
    unsuccess(
        message: string,
        keepAfterNavigationChange = false,
        timeInterval?: string
    ) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({
            type: "unsuccess",
            text: message,
            interval: timeInterval
        });
    }

    error(
        message: string,
        keepAfterNavigationChange = false,
        timeInterval?: string
    ) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({
            type: "error",
            text: message,
            interval: timeInterval
        });
    }

    warning(
        message: string,
        keepAfterNavigationChange = false,
        timeInterval?: string
    ) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({
            type: "warning",
            text: message,
            interval: timeInterval
        });
    }

    update(
        message: string,
        keepAfterNavigationChange = false,
        timeInterval?: string
    ) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({
            type: "update",
            text: message,
            interval: timeInterval
        });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    showHelpText(message) {
        this.helpText.next(message);
    }

    getHelpText() {
        return this.helpText.asObservable();
    }

    showUserGuide(msg) {
        this.userGuide.next(msg);
    }

    getUserGuide() {
        return this.userGuide.asObservable();
    }
}
