<div class="row scanner-section" *ngIf="!isFromLogin">
    <div class="col-md-12">
        <app-rx-select [LabelText]="'Scanner Source'" [FormGroupName]="ScannerFG" [ControlName]="'scannerSource'" [HasMultiple]="false" [BindValue]="'Id'" [BindLabel]="'Name'"
            [LabelForId]="'Name'" [List]="scannerSources">
        </app-rx-select>
    </div>
    <div class="col-md-4 pr-0">
        <app-rx-select [LabelText]="'Image Type'" [FormGroupName]="ScannerFG" [ControlName]="'pixelType'" [HasMultiple]="false" [BindValue]="'Id'" [BindLabel]="'Name'"
            [LabelForId]="'Name'" [List]="imageTypeDropdowns">
        </app-rx-select>
    </div>
    <div class="col-md-4">
        <app-rx-select [LabelText]="'Resolution'" [FormGroupName]="ScannerFG" [ControlName]="'resolution'" [HasMultiple]="false" [BindValue]="'Name'"
            [LabelForId]="'Name'" [List]="[100,150,200,300]">
        </app-rx-select>
    </div>
    <div class="col-md-4 pt-4" [formGroup]="ScannerFG">
        <input class="form-check-input" type="checkbox" id="duplex" formControlName="duplex">
        <label class="form-check-label" for="duplex">
            Duplex
        </label>
    </div>
    <div class="col-md-12 text-center" *ngIf="showUi">
        <button class="hotkey_primary" (click)="startScan()">
            Scan Document
        </button>
    </div>
    <!-- <div class="col-md-12 text-center">
        <button class="hotkey_primary" (click)="editImage()">
             Edit Image
          </button>
    </div> -->
    <!-- <div class="col-md-12">
        <img *ngIf="imageOutput" [src]="'data:image/png;base64,'+imageOutput" style="width: 100%;">
    </div> -->
</div>

<div class="d-none">
    <li class="d-none" id="pNoScanner">
        <a href="javascript: void(0)" class="ShowtblLoadImage color_orange" id="aNoScanner">(No TWAIN compatible drivers detected)</a>
    </li>
    <div id="dwtcontrolContainer" class="d-none"></div>
</div>


<!-- <app-image-editor *ngIf="showImageEditor" [ImageData]="'data:image/png;base64,'+imageOutput" (IsPopUpClosed)="showImageEditor=false"></app-image-editor> -->
