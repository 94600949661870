<ng-template #docSplitTemp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Split Document and Attach</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-6">
                <eprx-select [LabelText]="'Category'" [ControlName]="'CategoryId'"
                    [FormGroupName]="splitDocFG" [PlaceHolder]="'Category'" [HasMultiple]="false" [HasBindLabel2]="false"
                    [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'"
                    [List]="catDropdowns" [IsFirstElem]="true" (TriggerSelectValue)="getSubCatsByCatId($event)"
                    [IsRequired]="true" [ErrorDefs]="{required:'Category Required'}" [InputErrors]="splitDocFG?.controls?.CategoryId?.errors" [MarkAsTouched]="splitDocFG?.controls?.CategoryId?.touched">
                </eprx-select>
            </div>
            <div class="col-6">
                <eprx-select [LabelText]="'Subcategory'" [ControlName]="'SubCateogryId'"
                    [FormGroupName]="splitDocFG" [PlaceHolder]="'Subcategory'" [HasMultiple]="false" [HasBindLabel2]="false"
                    [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'"
                    [List]="subCatDropdowns" [IsRequired]="true" [ErrorDefs]="{required:'Subcategory Required'}" [InputErrors]="splitDocFG?.controls?.SubCateogryId?.errors"
                     [MarkAsTouched]="splitDocFG?.controls?.SubCateogryId?.touched"
                    >
                </eprx-select>
            </div>
            <div class="col-12" *ngIf="selectedCategory === 'Prescription'">
                <eprx-radio [LabelText]="''" [ControlName]="'AddType'" [FormGroupName]="splitDocFG"
                    [List]="['Add to Document', 'Link to Rx']" [ValueList]="['doc','rx']" [IDForList]="['docadd','rxadd']"
                    [LabelForId]="" >
                </eprx-radio>
            </div>
            <div class="col-12" *ngIf="selectedCategory==='Patient' || (selectedCategory==='Prescription' && splitDocFG.value.AddType === 'doc')">
                <label>Patient <span class="help--text__danger">*</span> </label>
                <div class="seconday-search--grid">
                    <app-searchbox-global id="Patient" [RxSelectId]="'Patient'" [SearchFor]="'Patient'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedPatient)="selectPatient($event)"
                        [ClearInputValue]="patientSearchValue">
                    </app-searchbox-global>
                </div>
            </div>
            <div class="col-12" *ngIf="selectedCategory==='Prescriber'">
                <label>Prescriber <span class="help--text__danger">*</span> </label>
                <div class="seconday-search--grid">
                    <app-searchbox-global id="presc" [RxSelectId]="'Prescriber'" [SearchFor]="'Prescriber'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedPrescriber)="selectPrescriber($event)"
                        [ClearInputValue]="prescSearchValue">
                    </app-searchbox-global>
                </div>
            </div>
            <div class="col-12" *ngIf="selectedCategory==='Drug'">
                <label>Drug <span class="help--text__danger">*</span> </label>
                <div class="seconday-search--grid">
                    <app-searchbox-global id="Patient" [RxSelectId]="'Drug'" [SearchFor]="'Drug'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedDrug)="selectDrug($event)"
                        [ClearInputValue]="drugSearchValue" [ShowOnlyPatName]="true">
                    </app-searchbox-global>
                </div>
            </div>
            <div class="col-12" *ngIf="selectedCategory==='Insurance'">
                <eprx-select [RxSelectId]="'Insura'" [LabelText]="'Insurance'" [PlaceHolder]="'Insurance'" [Title]="'Insurance'"
                    [ControlName]="'RxNum'" [FormGroupName]="splitDocFG" [HasMultiple]="false" [HasBindLabel2]="true"
                    [Show2Values]="true" [BindLabel2]="'InsuranceName'" [BindLabel]="'PlanCode'" [LabelTitle1]="'Plan Code'"
                    [BindValue]="'InsuCarrierId'" [LabelForId]="''" [List]="insuranceData | async" (TriggerSelectValue)="getInsuranceId($event)" [IsRequired]="selectedCategory==='Insurance' ? true : false" >
                </eprx-select>
            </div>
            <div class="col-6" *ngIf="selectedCategory==='Prescription' && splitDocFG.value.AddType === 'rx'">
                <eprx-input [LabelText]="'Rx#'" [PlaceHolder]="'Rx#'" [HasControl]="false" [InputValue]="rxNumValue" [ReadOnly]="true"></eprx-input>
            </div>
            <div class="col-6 pt-3" *ngIf="selectedCategory==='Prescription' && splitDocFG.value.AddType === 'rx'">
                <button type="button" class="hotkey_primary" (click)="openPrescriptionSearch()" >Search RX</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"  *ngIf="(patientSearchValue || prescSearchValue || drugSearchValue || rxNumValue || insSelectedValue); else elseBlock"  class="hotkey_success" (click)="splitDocument()" appShortcutKey
             [AltKey]="'o'"><span>O</span>OK</button>
           <ng-template #elseBlock>
            <button type="button"  class="inactive"
            >OK</button>
           </ng-template>
        <!-- <button class="inactive" *ngIf="!drugFG.value.Drug.IsInvMangd">Add Inventory</button> -->
        <button type="button" class="hotkey_success" (click)="closeModal(false)" appShortcutKey
             [AltKey]="'c'"><span>C</span>CANCEL</button>
    </div>
</ng-template>


<ng-template #rxSearchTemp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Prescription Search</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-2">
                <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'frmDate'" [LabelText]="'From Date'"
                    [PlaceHolder]="''" [ControlName]="'StartDate'" [FormGroupName]="rxSearchFG" [MinDate]=""
                    [MaxDate]="minDate">
                </eprx-date-picker>
            </div>
            <div class="col-2 ">
                <eprx-date-picker [RxSelectId]="'toDate'" [LabelText]="'To Date'" [PlaceHolder]="''"
                    [ControlName]="'EndDate'" [FormGroupName]="rxSearchFG" [MinDate]="" [MaxDate]="minDate">
                </eprx-date-picker>
            </div>
            <div class="col-4">
                <label>Patient</label>
                <div class="seconday-search--grid">
                    <app-searchbox-global id="Patient" [RxSelectId]="'Patient'" [SearchFor]="'Patient'"
                        [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true"
                        (SelectedPatient)="selctedPatientForRx($event)" [ClearInputValue]="rxPatSearchValue"
                        [ShowOnlyPatName]="true">
                    </app-searchbox-global>
                </div>
            </div>
            <div class="col-2">
                <eprx-input [LabelText]="'Rx#'" [PlaceHolder]="'Rx#'" [ControlName]="'PrescNum'"
                    [FormGroupName]="rxSearchFG" [InputType]="'number'">
                </eprx-input>
            </div>
            <div class="col-2 padding__top_12px">
                <button title="Search / Refresh" type="submit" class="hotkey_success float-right" (click)="searchRxs()"
                    (keydown.enter)="searchRxs()">
                    <span>S</span> Search</button>
            </div>
            <div class="col-12">
                <app-rx-history [ActiveScreen]="'DocQueue'" [ShowDateRange]="false" [MultiCheck]="false"
                    [PageSize]="'30'" [IsFromModal]="true" [ExternalSearchParams]="rxSearchParams"
                    (hasRxHistory)="hasRxHistory=$event" (SelectedPrescription)="prescriptionSelected($event)">
                </app-rx-history>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="d('Cross click')" appShortcutKey
            [AltKey]="'o'" ><span>O</span>OK</button>
    </div>
</ng-template>
