<div class="form-group custom--input custom--date" [ngClass]="{'has-error':errorMessage}">
    <label class="control-label" *ngIf="labelText">
        <span>{{labelText}}
            <span *ngIf="isRequired" class="help--text__danger">*</span>
        </span>
    </label>
    <span *ngIf="isDisabled" [formGroup]="inputFormGroup">
        <input class="form-control inactive" [value]="(inputFormGroup?.controls[custContrlName].value) ?
        (inputFormGroup?.controls[custContrlName].value | dateFormat) :
        (inputFormGroup?.value[custContrlName] | dateFormat)" placeholder="MM/DD/YYYY" [tabindex]="tabIndex" [readonly]="true">
    </span>
    <!-- <span *ngIf="hasControl && !isDisabled" [formGroup]="inputFormGroup" class="date-picker">
        <input autocomplete="off" [id]="rxID" class="form-control" [(ngModel)]="selected" placeholder="MM/DD/YYYY" name="dp" ngbDatepicker headless=true [tabindex]="tabIndex"
        #d="ngbDatepicker" [maxDate]="maxDate" [minDate]="minDate" [required]="true" [markDisabled]="isDisabled" (keyup.enter)="onEnter($event, d)"
        [appAutofocus]="autoFocus"  [readOnly]="isReadOnly" (dateSelect)="changeValue($event)" (keyup)="emitOnKeyDown($event)" (blur)="onTabbingEvent($event, d)"  (change)="changeNullValue($event?.target?.value,d)"
        (keydown.Tab)="onTabbingEvent($event, d)" (keydown.Tab)="emitAfterComplt($event)" (keyup.enter)="emitAfterComplt($event)"
        (keyup.shift.tab)="onShiftTabbingEvent($event)">
        <div class="input-btn-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" [tabIndex]="-1">
                <i class="far fa-calendar-alt"></i>
            </button>
        </div>
    </span> -->
    <span class="date-picker" *ngIf="!isDisabled">
        <input autocomplete="off" [id]="rxID"  class="form-control" [(ngModel)]="selected" [value]=" inputFormGroup?.value[custContrlName] | dateFormat" placeholder="MM/DD/YYYY" name="dp" ngbDatepicker headless=true [tabindex]="tabIndex"
        #d="ngbDatepicker" [maxDate]="maxDate" [minDate]="minDate" [required]="true" [markDisabled]="isDisabled"
        [appAutofocus]="autoFocus"  [readOnly]="isReadOnly" (dateSelect)="changeValue($event)" (keyup)="emitOnKeyDown($event)" (blur)="onTabbingEvent($event, d)"  (change)="changeNullValue($event?.target?.value)"
        (keydown.Tab)="emitAfterComplt($event, d)" (keyup.enter)="emitAfterComplt($event)" maxlength=10
        (keyup.shift.tab)="onShiftTabbingEvent($event)" (focus)="foucsDatePicker()">
        <div class="input-btn-group-append">
            <button [tabindex]="buttonTabIndex" class="btn btn-outline-secondary calendar" (click)="onDatePickerOpen(d)" type="button">
                <i class="far fa-calendar-alt"></i>
            </button>
        </div>
    </span>
    <span class="help-block line_height_0_9 " *ngIf="errorMessage">
        {{errorMessage}}
    </span>
</div>


<!--
 [(ngModel)]="selected"
        <input class="form-control" placeholder="yyyy-mm-dd"
        name="dp" [(ngModel)]="selected" ngbDatepicker #d="ngbDatepicker">
        <div class="input-btn-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" [tabIndex]="-1">
                <i class="far fa-calendar-alt"></i>
            </button>
        </div> -->
<!--
        <input autocomplete="off" [id]="rxID" class="form-control" [(ngModel)]="selected" placeholder="MM/DD/YYYY" name="dp" ngbDatepicker headless=true [tabindex]="tabIndex"
            #d="ngbDatepicker" [maxDate]="maxDate" [minDate]="minDate" [required]="true" [markDisabled]="isDisabled" (keyup.enter)="onEnter($event, d)"
            [appAutofocus]="autoFocus"  [readOnly]="isReadOnly" (dateSelect)="changeValue($event)" (keyup)="emitOnKeyDown($event)" (blur)="onTabbingEvent($event, d)"  (change)="changeNullValue($event?.target?.value,d)"
            (keydown.Tab)="onTabbingEvent($event, d)" (keydown.Tab)="emitAfterComplt($event)" (keyup.enter)="emitAfterComplt($event)"
            (keyup.shift.tab)="onShiftTabbingEvent($event)">

        <div class="input-btn-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" [tabIndex]="-1">
                <i class="far fa-calendar-alt"></i>
            </button>
        </div> -->
