import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DrugService } from './drug.services';
import { PrintService } from './print.service';

@Injectable({
  providedIn: 'root'
})
export class CommonPrintService {

    private _labelPrintInfo$: BehaviorSubject<any>;
    labelPrintInfo$: Observable<any>;

    private _labelPrintCompleted$: BehaviorSubject<any>;

    private medGuideSubscription: Subscription;

    constructor(
        private _drugService: DrugService,
        public _printServ: PrintService
    ) {
        this._labelPrintInfo$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.labelPrintInfo$ = this._labelPrintInfo$.asObservable();
        this._labelPrintCompleted$=<BehaviorSubject<any>>new BehaviorSubject(null);
    }

    printLabelToRx(rxInfo: any, skipModal: boolean = true, rmExtraSearch: boolean = false, isLabelQueue: boolean = false, type?: string, fromScreen?: any, label?) {
        this._labelPrintInfo$.next({ rxInfo: rxInfo, skipModal: skipModal, rmExtraSearch: rmExtraSearch, isLabelQueue: isLabelQueue, type: type, fromScreen: fromScreen, defaultLabel: label});
    }

    printMedGuide(drugId) {
        this.medGuideSubscription = this._drugService.getDrugMedguide(drugId).subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.sendMedGuideToPrint(resp);
            }
            this.medGuideSubscription.unsubscribe();
        });
    }

    sendMedGuideToPrint(datToPrint, type?: any) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data, type ? type : null);
        }.bind(this);
    }

    setPrintCompleted(data) {
        this._labelPrintCompleted$.next(data);
    }

    isLabelPrintCompleted() {
        return this._labelPrintCompleted$.asObservable();
    }
}
