import { Component, Input, OnInit, Output, ViewChild, EventEmitter, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MsgConfig } from "src/app";
import { DrugBucketInfo } from "src/app/models/drug-bucket.model";
import {
    AlertService, BucketService, DrugPriceCheckService, EditDrugService,
    InventoryService, PatPreDrugModalService, PreviousRouteService
} from "src/app/services";
import { DrugUtils } from "src/app/utils";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { FormatsUtil } from "src/app/utils/formats.util";
@Component({
    selector: "app-add-drug-to-bucket",
    templateUrl: "./add-drug-to-bucket.component.html"
})
export class AddDrugToBucketComponent implements OnInit, AfterViewInit {

    @ViewChild("ADDDRUGTOBUCKET", { static: true })
    ADDDRUGTOBUCKET: any;

    @Input() DrugBuckFG: FormGroup;
    @Input() DrugFG: FormGroup;
    @Input() DrugId: number;
    @Input() BucketId: any;
    // @Input() DrugInfo: any;
    @Input() BucketList: any;
    @Input() DrugBucketInfo: DrugBucketInfo;
    @Input() prefVendorList = [];
    @Input() IsFrmRx = false;
    @Input() IsFromPhysicalInventoryScreen = false;
    @Input() IsFromAddDrugScreen = false;
    @Output() emitAddDrugToBuckClose = new EventEmitter<any>();

    // DrugBucketInfo: any;
    isPhysicalInventEnabl = false;
    alertMsg: string;
    messageFor: string;
    drugFullName: string;
    DrugPO: FormGroup;
    ManageInventory: FormGroup;
    DrugInfo: any;
    unsubscribe$: Subject<void> = new Subject();
    DrugBucketInfoCopy: DrugBucketInfo;
    @ViewChild("PRIVANDMANG", { static: true })
    PRIVANDMANG: NgbModal;

    @ViewChild("vendorItemAdd", { static: true })
    vendorItemAdd: NgbModal;

    @ViewChild("UpdateCost", { static: true })
    UpdateCost: NgbModal;

    frmRxNopenPhyInv = false;
    vendorItemFG: FormGroup;
    vendorItemModal: any;
    vendorFgInvalid: boolean;
    vendorDetails: any;
    hasVendorDetails: boolean;
    dropDownValues: any;
    vendorName: any;
    updateCostModal: any;
    drugVendorType: any;
    selectVendList: any;
    constructor(private _fb: FormBuilder, private _drugBuckUtil: DrugBucketUtil, private _drugSvc: EditDrugService,
        private _modalService: NgbModal, private _buckService: BucketService, private _invService: InventoryService,
        private _prevSer: PreviousRouteService,public _formatsUtil:FormatsUtil,
        private _alertServ: AlertService, private _phyInvPopup: PatPreDrugModalService, private _drugUtils: DrugUtils,
        private _drugpriceChk: DrugPriceCheckService, private _drugTabUtil: DrugTabUtils) {
        this.DrugPO = this._drugBuckUtil.createDrugPoFG();
        this.ManageInventory = this._drugBuckUtil.createManageInvFG();
    }

    ngOnInit() {
        if (!this.DrugFG || !this.DrugFG.value.Drug) {
            this.getAndPatchDrugFG();
        } else if(this.DrugFG.value.Drug["QtyPack"]) {
                this.ManageInventory.controls["QtyPack"].patchValue(this.DrugFG.value.Drug["QtyPack"]);
        }
        
        if(this.DrugBucketInfo) {
            this.DrugBucketInfoCopy = this.DrugBucketInfo;
        }
        this.getDropDownData();
        this.getDrugBucketList(true);
        this.getDrugInfo();
    }

    async patchPriceInfoIfFrmRx() {
        if (this.IsFrmRx || this.IsFromPhysicalInventoryScreen) {
            // get price info
            this.ManageInventory.patchValue({ IsManaged: true });
        }

            const resp: any = await this._drugpriceChk.getDrugBuckPrice(this.DrugId, this.BucketId).toPromise();
            if (resp) {
                // patch into packcost, unitcost.
                this.ManageInventory.patchValue({
                    CostPack: resp.PackCost ? (resp.PackCost).toFixed(2) : "0.00",
                    UnitPriceCost: resp.UnitCost ? (resp.UnitCost).toFixed(3) : "0.000",
                });
                this.DrugPO.patchValue({
                    PrefVendId: resp.VendorId
                });
            }

        this.focusOnCost();
    }

    async getAndPatchDrugFG() {
        this.DrugFG = this._drugUtils.createDrugDataModal();
        const DrugInfo = await this._drugUtils.getDrugData(this.DrugId);
        if(DrugInfo && DrugInfo.Drug && DrugInfo.Drug.QtyPack) {
            this.ManageInventory.controls["QtyPack"].patchValue(DrugInfo.Drug.QtyPack.toFixed(3));
        }
        this._drugUtils.patchDrugInfo(DrugInfo, this.DrugFG, this.DrugId, "New");
    }

    async getDropDownData() {
        this.dropDownValues = await this._drugUtils.getDrugDefaultvalbyPost({DrugManufactNeeded  : false ,SigNeeded : false ,DrugFormNeeded : false, VendorsNeeded : true});
        if (this.dropDownValues && this.dropDownValues.Vendor) {
            this.prefVendorList = this.dropDownValues.Vendor;
        } else {
            this.prefVendorList = [];
        }
    }

    ngAfterViewInit() {
        this.focusOnCost();
    }

    focusOnCost() {
        if (document.getElementById("costPackId")) {
            const ele: any = document.getElementById("costPackId");
            ele.focus();
            setTimeout(() => {
                ele.select(); 
            }, 1000);
            
        }
    }

    async patchDrugPoMangInv() {
        // this._drugBuckUtil.patchDrugPOInfo(this.DrugPO, this.DrugBuckFG.value);
        this._drugBuckUtil.patchManageInvInfo(this.ManageInventory, this.DrugBuckFG.value);
        this._drugBuckUtil.patchDrugPOInfo(this.DrugPO, this.DrugBuckFG.value);
        this.DrugPO.patchValue({
            DrugId: this.DrugId
        });
        this.ManageInventory.patchValue({
            DrugId: this.DrugId
        });
        const resp = await this._invService.getDrugPoForBucket(this.DrugId, this.DrugBuckFG.value.Id).toPromise();
        if (resp) {
            this._drugBuckUtil.patchDrugBuckPO(this.DrugPO, resp);
        } else {
            this._drugBuckUtil.patchDrugPOInfo(this.DrugPO, this.DrugBuckFG.value);
        }
        this.patchPriceInfoIfFrmRx();
    }

    async getDrugInfo() { // for getting drugInfo
        const IsDrugInfo = await this._drugSvc.getDrugInfo(this.DrugId).toPromise();
        if (IsDrugInfo) {
            this.DrugInfo = IsDrugInfo;
        }
    }

    async getDrugBucketList(patchDrugPoMangInv = false) { // for getting drug bucket
        const resp = await this._drugBuckUtil.getDrugBucketList(this.DrugId);
        if (resp) {
            this.BucketList = resp;
            if (this.BucketList && this.BucketList.length && this.BucketId) {
                this.DrugBucketInfo = this.BucketList.find(item => item["Id"] === this.BucketId) || null;
                this._drugBuckUtil.patchDrugBucket(this.DrugBuckFG, this.DrugBucketInfo);
                if (patchDrugPoMangInv) {
                    this.patchDrugPoMangInv();
                }
                if(this.IsFromAddDrugScreen) {
                    this.ManageInventory.patchValue({
                        CostPack: this.DrugBucketInfo ? parseFloat(this.DrugBucketInfoCopy.CostPack).toFixed(2) : "0.00",
                        UnitPriceCost: this.DrugBucketInfo ? parseFloat(this.DrugBucketInfoCopy.UnitPriceCost).toFixed(3) : "0.000",
                    });
                }
            }
        }
    }

    closePopUp(event?: any) {
        //this._prevSer.routeBackToPrevScreen();
        this.emitAddDrugToBuckClose.emit(event);
    }

    checkIsBuckPhyInv() { // for managing phy inv
        const IsInvManaged: boolean = this._drugBuckUtil.checkIsInvManaged(this.ManageInventory.value);
        if (IsInvManaged !== this.DrugBuckFG.value.IsInvManaged) {
            if (IsInvManaged) { // open phy inv
                this.openPhysicalInv();
            } else if (!IsInvManaged) { // make api call to inactivate manage inv.
                const buckName = this.DrugBuckFG.value.Name.toUpperCase();
                this.alertMsg = "Manage Inventory for  " + buckName + " bucket is inactivated successfully";
                this.messageFor = "Are you sure, you no longer want to manage the Inventory for  <b>" + buckName + "</b>  Bucket?";
                this._modalService.open(this.PRIVANDMANG, { keyboard: false, centered: true, backdrop: false, windowClass: "large--content"});
            }
        }
    }

    async openPhysicalInv() {
        this._phyInvPopup._patPreDrugModal$.next("PhysicalInventory");
        const modelRef = await this._phyInvPopup.modalInstanceRef;
        modelRef.componentInstance.drugInfo = this.DrugInfo;
        modelRef.componentInstance.BucketInfo = {
            BucketId: this.DrugBuckFG.value.Id,
            DrugId: this.DrugId,
            IsInvMangd: false
        };
        modelRef.componentInstance.drugDefVal = this.dropDownValues;
        modelRef.componentInstance.CancelPhyscInven
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            modelRef.close();
            this._phyInvPopup._patPreDrugModal$.next(null);
            this.closePhyInv(resp);
        });
    }

    async inActvMangInv(event: any, isInActv: boolean) {
        if (isInActv) { // api call to manage inactive is handled while saving in api.
            this.DrugBuckFG.patchValue({
                IsInvManaged: false
            });

        } else { // set manage Inventory true
            this.DrugBuckFG.patchValue({
                IsInvManaged: true
            });
            this.ManageInventory.patchValue({
                IsManaged: true
            });
        }
        this.focusToEle();
    }

    closePhyInv(invMng: boolean) { // after closing physical inv
        this.DrugBuckFG.patchValue({
            IsInvManaged: invMng || false
        });
        this.ManageInventory.patchValue({
            IsManaged: invMng || false
        });
        this.focusToEle();
        if (this.IsFrmRx && this.frmRxNopenPhyInv) {
            this.frmRxNopenPhyInv = false;
            this.addDrugToBuck(null, false);
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    
    focusToEle(event?: any) {
        if (event) {
            event.preventDefault();
        }
        setTimeout(() => {
            if (document.getElementById("costPackId")) {
                document.getElementById("costPackId").focus();
            }
        }, 100);
    }

    async addDrugToBuck(event, isFrmRxOpenPhyInv?: boolean) {
        if (event) {
            event.preventDefault();
        }
        if (!this.IsFromPhysicalInventoryScreen && isFrmRxOpenPhyInv &&
            !this.frmRxNopenPhyInv && this.ManageInventory.value.IsManaged && !this.DrugBuckFG.value.IsInvManaged) {
            this.frmRxNopenPhyInv = true;
            this.openPhysicalInv();
            return;
        }
        if (this.IsFromPhysicalInventoryScreen) {
            this.ManageInventory.patchValue({
                IsManaged: true
            });
        }
        const payload = {
            DrugPO: this.DrugPO.value,
            ManageInventory: this.ManageInventory.value
        };
        const resp = await this._invService.drugBuckManagInv(payload).toPromise();
        if (resp) {
            this.closePopUp("ADDED");
        } else {
            this._alertServ.error("Unabe to add Drug Bucket pricing.");
        }

    }

    convertCostPackNUnitPrice(field: string) {
        const dataInfo: any = this.DrugFG.controls["Drug"];
        if (field === "COSTPACK") { // convert the unit price cost based on Qty in Pack
            if (
                ((
                    this.ManageInventory.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    this.ManageInventory.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                this.ManageInventory.controls["UnitPriceCost"].patchValue(
                    (this.ManageInventory.controls["CostPack"].value /
                        dataInfo.controls["QtyPack"].value).toFixed(3));
            }

        } else { // convert the pack cost based on Qty in pack
            this.ManageInventory.controls["CostPack"].patchValue(
                (this.ManageInventory.controls["UnitPriceCost"].value * dataInfo.controls["QtyPack"].value).toFixed(2));
        }

    }

    // Vendor Item Add

    async drugPreferredVendor(vendor) {
        if(vendor.value["Id"] !==  this.drugVendorType) {
            this.selectVendList = this.prefVendorList?.length ? this.prefVendorList.find((item: any) => {
                return item.ContractId === this.DrugPO.value.ContractId
            }) : null;
            this.DrugPO.controls["PrefVendId"].patchValue(this.selectVendList ? this.selectVendList.Id : null);
            this.drugVendorType = vendor.value["Id"];
            const dataPost ={
                drugId: this.DrugId,
                vendorId: this.DrugPO.value.PrefVendId,
                ContractId: this.DrugPO.value.ContractId
            };
            this.vendorDetails = await this._buckService.getInventoryPreferredVendorId(dataPost).toPromise();
            const vd = this.dropDownValues.Vendor.find(v => v["Id"] === this.DrugPO.value.PrefVendId);
            if (this.vendorDetails) {
                this.vendorDetails.VendorName = vd ? vd["ContractName"] + ", " + vd["VendorName"] : "";
            } else {
                this.vendorName = vd ? vd["ContractName"] + ", " + vd["VendorName"] : "";
            }
            this.hasVendorDetails = this.vendorDetails ? true : false;
            setTimeout(() => {
                if (document.getElementById("PreferrVednId")) {
                    document.getElementById("PreferrVednId").blur();
                }
            }, 100);
            this.updateCostModal = this._modalService.open(this.UpdateCost,
                 {backdrop: false, keyboard: false, centered: true,  windowClass: "large--content"});
        }
    }

    closeUpdateModal() {
        if (this.updateCostModal) {
            this.updateCostModal.close();
            this.updateCostModal = null;
        }
    }

    async patchToDrugUnitCost() {
        this.ManageInventory.patchValue({UnitPriceCost: (this.vendorDetails.UnitCost).toFixed(3),
            CostPack: (this.vendorDetails.UnitCost * this.DrugFG.value.Drug.QtyPack).toFixed(2)});
    }

    async openVendorItemModal() {
        this.closeUpdateModal();
        this.vendorItemFG = this._drugTabUtil.createVendorItemFG();
        this.vendorItemFG.patchValue({
            VendorId: this.DrugPO.value.PrefVendId,
            AWP: this.DrugFG.value.Drug.AWPPack,
            UnitAWP: this.DrugFG.value.Drug.UnitPriceAWP,
            UnitCost: this.ManageInventory.value.UnitPriceCost,
            PackCost: this.ManageInventory.value.CostPack,
            ContractId: this.DrugPO.value.ContractId
        });
        this.vendorItemModal = this._modalService.open(this.vendorItemAdd,
            { centered: true, backdrop: false, size: "lg", keyboard: false });
    }
    convertNumberFormat(control, type) {
        if (this.vendorItemFG.controls[control].dirty) {
            if (Number(this.vendorItemFG.value[control]) === 0 || !this.vendorItemFG.value[control]
                || Number(this.DrugFG.value.Drug.QtyPack) === 0) {
                this.vendorItemFG.controls[control].patchValue((0).toFixed(type));
            } else if (control === "UnitCost" || control === "UnitAWP") {
                this.vendorItemFG.controls[control].patchValue((parseFloat(this.vendorItemFG.value[control])).toFixed(type));
                if (control === "UnitCost") {
                    this.vendorItemFG.controls["PackCost"].patchValue((this.vendorItemFG.value[control] *
                        this.DrugFG.value.Drug.QtyPack).toFixed(2));
                } else {
                    this.vendorItemFG.controls["AWP"].patchValue((this.vendorItemFG.value[control] *
                        this.DrugFG.value.Drug.QtyPack).toFixed(2));
                }
            } else if (control === "AWP" || control === "PackCost") {
                this.vendorItemFG.controls[control].patchValue((parseFloat(this.vendorItemFG.value[control])).toFixed(type));
                if (control === "AWP") {
                    this.vendorItemFG.controls["UnitAWP"].patchValue((this.vendorItemFG.value[control] /
                        this.DrugFG.value.Drug.QtyPack).toFixed(3));
                } else {
                    this.vendorItemFG.controls["UnitCost"].patchValue((this.vendorItemFG.value[control] /
                        this.DrugFG.value.Drug.QtyPack).toFixed(3));
                }
            }
        }
    }

    async saveVendorItems() {
        if (this.vendorItemFG.valid) {
            this.vendorFgInvalid = false;
            this.vendorItemFG.controls["DrugId"].patchValue(this.DrugId);
            const result = await this._drugTabUtil.addOrEditVendorItems("VAdd", this.vendorItemFG.value);
            result === 1 ? this._alertServ.success(MsgConfig.SAVE_SUCCESS) : this._alertServ.error(MsgConfig.SAVE_FAIL);
            if (this.vendorItemModal) {
                this.vendorItemModal.close();
                this.vendorItemModal = null;
            }
            const dataPost ={
                drugId: this.DrugId,
                vendorId: this.DrugPO.value.PrefVendId,
                ContractId: this.DrugPO.value.ContractId
            };
            this.vendorDetails = await this._buckService.getInventoryPreferredVendorId(dataPost).toPromise();
            this.patchToDrugUnitCost();
        } else {
            this.vendorFgInvalid = true;
        }
    }

    closeVendoeItemModal() {
        if (this.vendorItemModal) {
            this.vendorItemModal.close();
            this.vendorItemModal = null;
        }
    }

}
