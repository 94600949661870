export const TabEnum = {
    Patient1: "PATIENTINFO",
    Patient2: "ADDITIONALINSURANCES",
    Patient3: "MOREPATIENTINFO",
    Patient4: "FACILITYINFO",
    Patient5: "DIAGNOSISDETAILS",
    Patient6: "DELIVERYADDRESS",
    Patient7: "NOTESLOG",
    Patient8: "DOCUMENTS",
    Patient9: "CLINICALINFO",
    Patient10: "CUSTOMCATEGORY",
    Patient11: "LIFESTYLECONCEPT",
    Patient12: "MESSAGE",
    Patient13: "TRACKPATIENT"
};

export const TabTitles = {
    PATIENTINFO: "Patient1",
    ADDITIONALINSURANCES: "Patient2",
    MOREPATIENTINFO: "Patient3",
    FACILITYINFO: "Patient4",
    DIAGNOSISDETAILS: "Patient5",
    DELIVERYADDRESS: "Patient6",
    NOTESLOG: "Patient7",
    DOCUMENTS: "Patient8",
    CLINICALINFO: "Patient9",
    CUSTOMCATEGORY: "Patient10",
    LIFESTYLECONCEPT: "Patient11",
    MESSAGE: "Patient12",
    TRACKPATIENT: "Patient13"
};

export const TabNames = {
    PATIENTINFO: "Patient Information",
    ADDITIONALINSURANCES: "Additional Insurance",
    MOREPATIENTINFO: "Miscellaneous Patient Information",
    FACILITYINFO: "Facility Info",
    DIAGNOSISDETAILS: "Diagnosis Details",
    DELIVERYADDRESS: "Delivery Address",
    NOTESLOG: "Notes",
    DOCUMENTS: "Documents",
    CLINICALINFO: "Clinical Info",
    CUSTOMCATEGORY: "Patient Category",
    LIFESTYLECONCEPT: "Life Style Concept",
    MESSAGE: "Messaging",
    TRACKPATIENT: "Track Patient"
}
