<ng-template #QuickLabel let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Print Rx Label</h4>
        <span body>
            <div class="col-md-12">
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Rx#</label>
                            <div class="label--data">{{rxInfo?.Prescription?.PrescNum ? rxInfo?.Prescription?.PrescNum : "--"}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Date</label>
                            <div class="label--data">{{rxInfo?.PrescReFill?.FillDtTm ? (rxInfo?.PrescReFill?.FillDtTm | date:'MM/dd/yyyy') : "--"}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Patient</label>
                            <div class="label--data">{{rxInfo?.Patient?.firstname ? (rxInfo?.Patient?.lastname +", "+rxInfo?.Patient?.firstname) :
                                "--"}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Ins. Type</label>
                            <div class="label--data">{{rxInfo?.insuranceInfo?.insuPlan?.PlanCode ? rxInfo?.insuranceInfo?.insuPlan?.PlanCode : "--"}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Prescriber</label>
                            <div class="label--data">{{rxInfo?.Prescriber?.prescriberfirstname ? (rxInfo?.Prescriber?.prescriberlastname+",
                                "+rxInfo?.Prescriber?.prescriberfirstname) : "--"}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Due</label>
                            <div class="label--data">{{rxInfo?.PrescReFill?.BalDue ? rxInfo?.PrescReFill?.BalDue : "--"}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Drug</label>
                            <div class="label--data">{{rxInfo?.Drug?.drugname ? rxInfo?.Drug?.drugname : "--"}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="exprx--common-block">
                                <div class="eprx--block__header">
                                    <div class="eprx--header__heading">
                                        <div class="col-sm-12 padding-0">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <span> Label Printing Options</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <eprx-input [LabelText]="'Default Label Quantity'" [PlaceHolder]="'Default Label Quantity'"
                                                    [ControlName]="'LabelQty'" [FormGroupName]="QuickLabelForm" [InputErrors]="LabelQty?.errors"
                                                    [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formSubmitted" [InputType]="'number'">
                                                </eprx-input>
                                            </div>
                                            <div class="col-md-4">
                                                <eprx-select [LabelText]="'Label Print Set'" [PlaceHolder]="'Label Print Set'" [ControlName]="'LabPrintSet'"
                                                    [FormGroupName]="QuickLabelForm" [List]="printSetDrops" [BindLabel]="'Name'" [BindValue]="'Id'"
                                                    [LabelForId]="'Name'" [InputErrors]="LabPrintSet?.errors" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                                                    [FormGroupInvalid]="formSubmitted" (TriggerSelectValue)="optedPrintSet($event.value)">
                                                </eprx-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content d-none">
                <canvas id="myCanvas2" class="canvas2" width="1000px" height="600px"></canvas>
            </div>
        </span>

        <button footer ngbAutofocus class="hotkey_success" (click)="print()" appShortcutKey  [AltKey]="'y'">
            <span>Y</span> Print
        </button>
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey  [AltKey]="'c'">
           <span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #CompoundList let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="closeModal();c('close')">
            <h4 header>Confirmation</h4>
            <div body>Would you like to Print the Compound Formulation for this Rx?</div>
            <button
                footer
                autofocus
                type="submit"
                class="hotkey_primary"
                appShortcutKey
                (click)="c('close')"
                (click)="printCompoundFormulation()"
                InputKey="y"><b>Y</b> YES
            </button>
            <button
                footer
                class="hotkey_primary"
                appShortcutKey InputKey="n"
                (click)="closeModal()"
            >
                <b>N</b> NO
            </button>
        </app-custom-modal>
</ng-template>

<!-- <app-default-label-printset *ngIf="showDefaultLabel" (IsPopUpClosed)="getLabelPrintSetData();showDefaultLabel= false"></app-default-label-printset> -->

