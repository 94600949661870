import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, CommonService, DMSService } from 'src/app/services';
import * as moment from "moment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginationModel } from 'src/app/models/elastc-rx.model';

@Component({
    selector: 'app-doc-split',
    templateUrl: './doc-split.component.html'
})
export class DocSplitComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    docName: any;
    patientSearchValue: string;
    prescSearchValue: string;
    drugSearchValue: string;
    modelRef: any;
    splitDocFG: any;
    insuranceData: any;
    catDropdowns: any[];
    selectedCategory: any;
    subCatDropdowns: any[];
    faxDocument: any;
    RefreshParent: any;
    unsubscribe$: Subject<void> = new Subject();

    @ViewChild("rxSearchTemp", { static: true })
    prescSearchTemp: any;

    @ViewChild("docSplitTemp", { static: true })
    docSplitTemp: any;
    pageNos: any;
    rxSearchFG: any;
    today: string;
    selectedPrescription: any;
    rxSearchParams: {
        patientId: number; Billstatus: string;
        PageNumber: number; PageSize: number; OrderByColumn: any; IsShowExpRefills: any;
    };
    rxPatSearchValue: any="";
    minDate: any;
    rxNumValue: string;
    insSelectedValue: any;

    @Input()
    set DocName(data: any) {
        if (data) {
            this.docName = data;
        }
    }

    @Input()
    set PageNos(data: any) {
        if (data) {
            this.pageNos = data;
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    constructor(
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _alertSer: AlertService,
        private _cdr: ChangeDetectorRef,
        private _commonServ: CommonService,
        private _dmsSer: DMSService
    ) { }

    ngOnInit() {
        this.createFg();
        this.getDocumentCategories();
        this.patientSearchValue = "";
        this.prescSearchValue = "";
        this.drugSearchValue = "";
        this.modelRef = this.modalService.open(this.docSplitTemp, { backdrop: "static", keyboard: false, centered: true, windowClass: "medium-custom-confirm-popup-max-width" });
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
    }

    createFg() {
        this.splitDocFG = this._fb.group({
            CategoryId: [],
            SubCateogryId: [],
            AssignToEntity: [true],
            FaxDocumentId: [],
            AddType: ["doc"],
            Pages: [],
            PatientName: [],
            PatientId: [],
            RxNum: []
        });
        this.insuranceData = this._commonServ.insuranceData$;
    }

    getDocumentCategories() {
        this._commonServ.getDocumentCategoryList()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.catDropdowns = resp;
        });
    }

    getSubCatsByCatId(eve) {
        this.selectedCategory = this.catDropdowns.find(elm => elm.Id === this.splitDocFG.value.CategoryId).Name;
        this.splitDocFG.controls.RxNum.patchValue(null);
        this.patientSearchValue = "";
        this.prescSearchValue = "";
        this.drugSearchValue = "";
        this.insSelectedValue = null;
        this._dmsSer.getSubCatById(this.splitDocFG.value.CategoryId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.splitDocFG.controls.SubCateogryId.patchValue(null);
                this.subCatDropdowns = resp;
            }
        });
    }

    getInsuranceId(event){
        this.insSelectedValue = event.value;
    }

    selectPatient(patItem) {
        if (patItem) {
            if (this.selectedCategory === 'Prescription' && this.splitDocFG.value.AddType == 'doc') {
                this.splitDocFG.patchValue({
                    PatientId: patItem.patientid,
                    PatientName: patItem.fullname ? ((patItem.fullname).toUpperCase()).replace("_", ",") : ""
                });
            } else {
                this.splitDocFG.patchValue({
                    RxNum: patItem.patientid,
                    PatientId: null,
                    PatientName: null
                });
            }
            this.patientSearchValue = ((patItem.fullname).toUpperCase()).replace("_", ",");
            //this._cdr.detectChanges();
        } else {
            this.patientSearchValue = null;
            this.splitDocFG.patchValue({
                RxNum: null,
                PatientId: null,
                PatientName: null
            });
        }
    }

    selectPrescriber(prescItem) {
        if (prescItem) {
            this.prescSearchValue = null;
            setTimeout(() => {
                this.prescSearchValue =
                prescItem.prescriberlastname + (prescItem.prescriberfirstname ? ", " + prescItem.prescriberfirstname : "");
            }, 100);
            this.splitDocFG.controls.RxNum.patchValue(prescItem.prescriberid);
        } else {
            this.splitDocFG.controls.RxNum.patchValue(null);
            this.prescSearchValue = "";
        }
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit() {
        this._cdr.detectChanges();
    }
    selectDrug(durgItem) {
        if (durgItem) {
            this.drugSearchValue = null;
            setTimeout(() => {
                this.drugSearchValue = durgItem.drugname;
            }, 100);
            this.splitDocFG.controls.RxNum.patchValue(durgItem.id);
        } else {
            this.splitDocFG.controls.RxNum.patchValue(null);
            this.drugSearchValue = "";
        }
    }

    createRxSearchFG() {
        this.rxSearchFG = this._fb.group({
            StartDate: [this.today],
            EndDate: [this.today],
            PrescNum: [],
            patientId: []
        });
    }

    openPrescriptionSearch() {
        this.selectedPrescription = null;
        this.createRxSearchFG();
        this.rxSearchParams = {
            patientId: 0,
            Billstatus: "a",
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize,
            OrderByColumn: null,
            IsShowExpRefills: null
        };
        this.rxSearchParams = { ...this.rxSearchParams, ...this.rxSearchFG.value };
        this.modalService.open(this.prescSearchTemp, { size: "lg", keyboard: false, backdrop: "static", windowClass: "md-x-lg" });
    }

    prescriptionSelected(insuInfo) {
        if (insuInfo) {
            this.selectedPrescription = insuInfo;
            this.splitDocFG.controls.RxNum.patchValue(this.selectedPrescription.PrescNum);
            this.rxNumValue = this.selectedPrescription["PrescNum"];
        } else {
            this.splitDocFG.controls.RxNum.patchValue(null);
            this.rxNumValue = "";
        }
    }

    selctedPatientForRx(patientItem) {
        if (patientItem) {
            this.rxSearchFG.controls.patientId.patchValue(patientItem.patientid);
            this.rxPatSearchValue = patientItem.fullname ? ((patientItem.fullname).toUpperCase()).replace("_", ",") : "";
        } else {
            this.rxSearchFG.controls.patientId.patchValue(null);
            this.rxPatSearchValue = "";
        }
    }

    searchRxs() {
        this.rxSearchParams = { ...this.rxSearchParams, ...this.rxSearchFG.value };
    }

    splitDocument() {
        if (this.splitDocFG.valid && (this.splitDocFG.value.RxNum || this.selectedCategory === 'Prescription' && this.splitDocFG.value.AddType == 'doc')) {
            let dataToPost = this.splitDocFG.value;
            dataToPost["FaxDocumentId"] = this.docName;
            if (this.selectedCategory === 'Prescription' && this.splitDocFG.value.AddType == 'doc') {
                dataToPost["AssignToEntity"] = false;
            } else {
                dataToPost["AssignToEntity"] = true;
            }
            dataToPost["Pages"] = this.pageNos;
            this._commonServ.splitDocAndAdd(dataToPost)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.closeModal(true);
            });
        } else {

        }
    }

    closeModal(splitted?) {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopUpClosed.emit(splitted);
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
       }
}
