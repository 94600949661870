import { Directive, AfterViewInit, ElementRef } from "@angular/core";

@Directive({
    selector: "[appAutofocusss]"
})
export class AutofocusDirectivee implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.focus();
    }
}
