<ng-template #COMPOUNDING let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Compound Ingredient List</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="IsPopUpClosed('cancel');activeModal.dismiss('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div class="row  edit--drug">
                    <div class="col-md-3 col-lg-3 col-xs-3 col-sm-3 inventory drug-inven inventory--color2">
                        <div class="inv--information">
                            <div class="smalldata-inv--value">
                                <h3>{{totalCount ? totalCount : "0.000"}}</h3>
                            </div>
                            <div class="inv--description">
                                <h5>Total Qty.(Active Drug)</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-xs-3 col-sm-3 inventory  drug-inven inventory--color3">
                        <div class="inv--information">
                            <div class="smalldata-inv--value">
                                <h3>$ {{totalAwp ? totalAwp : "0.000"}}</h3>
                            </div>
                            <div class="inv--description">
                                <h5>Total AWP (Active Drug)</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-xs-3 col-sm-3 inventory  drug-inven inventory--color4">
                        <div class="inv--information">
                            <div class="smalldata-inv--value">
                                <h3>$ {{totalCost ? totalCost : "0.000"}}</h3>
                            </div>
                            <div class="inv--description">
                                <h5>Total Cost (Active Drug)</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3">
                        <eprx-text-area [RxSelectId] = "'Instructions'" [LabelText]="'Instructions'" [PlaceHolder]="'Instructions'"
                            [ControlName]="'Notes'" [FormGroupName]="DrugIngredFG" [Rows]="4">
                        </eprx-text-area>
                    </div>
                </div>
                <div class="row" *ngIf="erxPrescribedDrugs">
                    <div class="col-12">
                        <div class="exprx--common-block">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Prescribed Compound Ingredients</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div class="col-12" *ngIf="erxPrescribedDrugs && erxPrescribedDrugs.length > 0 else noRecords">
                                        <wj-flex-grid #previewControl [headersVisibility]="'Column'" [itemsSource]="erxPrescribedDrugs" [isReadOnly]="true"
                                            [selectionMode]="'None'">
                                            <wj-flex-grid-column [header]="'DRUG NAME'" [width]="300" [binding]="'DrugName'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'NDC'" [width]="300" [binding]="'DrugNdc'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'STRENGTH'" [width]="'*'" [binding]="'DrugStrength'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'FORM'" [width]="'*'" [binding]="'StrengthForm'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'QUANTITY'" [width]="'*'" [binding]="'Quantity'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'CLASS'" [width]="'*'" [binding]="'DrugClass'">
                                            </wj-flex-grid-column>
                                        </wj-flex-grid>
                                    </div>
                                    <ng-template #noRecords>
                                        <div class="eprx--block__footer">
                                            <div>
                                                <span></span>
                                                <i class="fa fa-file"></i><br/>
                                                No Data Found!
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exprx--common-block erx--body insurance--body">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button *ngIf="!isBilled" class="hotkey_success hotkey_primary--Override" title="Add"
                                      autofocus id="Addbutton" (click)="openAddWindow()" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                                    <!-- <i aria-hidden="true" class="far fa-undo fa-lg refresh--icon" title="Refresh"></i> -->
                                    <button class="inactive" *ngIf="isBilled" title="Disabled as it is Billed Rx"><span>A</span>ADD</button>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <wj-flex-grid *ngIf="totalIngredLsit && totalIngredLsit.length > 0" #ingList
                            [headersVisibility]="'Column'" [itemsSource]="ingredWJ" [isReadOnly]="true"
                            [columnPicker]="'compoundIngListWJ'" [selectionMode]="'Row'" (updatedView)="init(ingList)"
                            (dblclick)="(!isBilled ? onRowDblclicked(ingList, $event) : '')" (keydown.enter)="(!isBilled ? keyeventOnWj(ingList): '')"
                            (keydown)="shortCutCOnWijimo($event)">
                            <wj-flex-grid-column [header]="'Action'" class="max-height_12rem" [width]="75">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <i *ngIf="!isBilled" class="far fa-edit fa-lg actions actions--Highlightfocus--icon" title="Edit"
                                        (click)="openEditWindow(item.RowId)"></i>
                                    <i *ngIf="!isBilled" class="far fa-trash-alt fa-lg actions danger-actve"
                                        (click)="deleteDrugIngred(item.Id, item.RowId)" title="Delete"></i>
                                    <i *ngIf="isBilled" class="far fa-edit fa-lg actions in-active" title="Disabled as it is Billed Rx"
                                        ></i>
                                    <i *ngIf="isBilled" class="far fa-trash-alt fa-lg actions in-active"
                                       title="Disabled as it is Billed Rx"></i>
    
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                            [width]="rxheader['width']"   *ngFor="let rxheader of wjHeaders; let i = index">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div class="text-center" *ngIf="(rxheader['hName'] === 'ACTIVE' || rxheader['hName'] === 'Qty Sufficient' || rxheader['hName'] === 'Optional')">
                                        <i *ngIf="item[rxheader['hName']]" class="far fa-check " title="True"></i>
                                        <i *ngIf="!item[rxheader['hName']]" class="far fa-times  fa-uncheck" title="False"></i>
                                    </div>
                                    <div
                                        *ngIf="!(rxheader['hName'] === 'ACTIVE' || rxheader['hName'] === 'Qty Sufficient' || rxheader['hName'] === 'Optional'
                                        || rxheader['hName'] === 'AWP' || rxheader['hName'] === 'COST' || rxheader['hName'] === 'QUANTITY')">
                                        {{item[rxheader['hName']]}}
                                    </div>
                                    <div  *ngIf="(rxheader['hName'] === 'AWP' || rxheader['hName'] === 'COST')">
                                        {{item[rxheader['hName']] ? (item[rxheader['hName']] | currency :"$":"symbol":"1.3-3") : "$0.000"}}
                                    </div>
                                    <div  *ngIf="(rxheader['hName'] === 'QUANTITY')">
                                            {{item[rxheader['hName']] ? (item[rxheader['hName']] | number: '1.3-3') : "0.000"}}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
    
    
                </div>
                <div class="exprx--common-block" *ngIf="openAddOrEdit">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--left__7">
                        <div class="box box-default box-solid">
                            <div class="eprx--block__header"></div>
                            <div>
                                <div class="box-body">
    
                                    <div class="row">
                                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 seconday-search--grid">
                                            <div class="row">
                                                <div class="col-4 color_cyan">
                                                    Drug</div>
                                                <div class="col-8">
                                                    <div class="text-right">
                                                        <div><i class="far fa-lg fa-long-arrow-alt-right background_orange_RGB"
                                                                ></i><span> for Equv ; </span><b
                                                                class="background_orange_RGB">Shift + <i
                                                                    class="far fa-lg fa-long-arrow-alt-right background_orange_RGB"
                                                                    ></i></b><span> for Gen
                                                                Equv</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <app-searchbox-global [RxSelectId]="'compDurg'" [IsFocused]="focusOnDrug"
                                              [disableBuck]="true"  [SearchFor]="'Drug'" [DisableRadioButtons]="true" [IsAddNew]="false" [restricSearch]="restricDrugSearch"
                                                [InputValue]="AddOrUpdateValue.value.Name" (emitRestrictionSucess)="removeRestrictionOnDrugSearch($event)"
                                                (SelectedDrug)="optedDrug($event,AddOrUpdateValue);" [ClearInputValue]="AddOrUpdateValue.value.Name" ></app-searchbox-global>
                                        </div>
                                        <div class="col-md-3 padding_top_10px">
                                            <eprx-input class="margin_top_10px" [LabelText]="'NDC'" [TabIndex]="-1"
                                                [PlaceHolder]="'NDC'" [ControlName]="'NDC'"
                                                [FormGroupName]="AddOrUpdateValue" [ReadOnly]="true" [InputType]="'ndc'">
                                            </eprx-input>
                                        </div>
                                        <div class="col-12" *ngIf="AddOrUpdateValue.value['DrugId']">
                                            <div class="row">
    
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <eprx-input [LabelText]="'Quantity'" [PlaceHolder]="'Quantity'"
                                                     [RxSelectId]="'IngQty'"
                                                        [ControlName]="'Qty'" [FormGroupName]="AddOrUpdateValue"
                                                        [IsRequired]="true" (TriggerOnChange)="calculatePriceOnQtyChange()"
                                                        [DecimalValues]="3" [InputType]="'NUMBER'"
                                                        (TriggerSearchValue)="setNumberFormat('Qty',AddOrUpdateValue,3)">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <eprx-input [LabelText]="'AWP'" [PlaceHolder]="'AWP'"
                                                        [ControlName]="'AWP'" [FormGroupName]="AddOrUpdateValue"
                                                        [TabIndex]="(!isAddDrug && !isFromDrug) ? '-1' : ''"
                                                        [DecimalValues]="3" [InputType]="'CURRENCY'" [ReadOnly]="(!isAddDrug && !isFromDrug)"
                                                        (TriggerSearchValue)="setNumberFormat('AWP',AddOrUpdateValue,3)">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="(!isAddDrug && !isFromDrug)">
                                                        <eprx-select [LabelText]="'Basis'" [PlaceHolder]="'Basis'" [ControlName]="'Basis'"
                                                        [FormGroupName]="AddOrUpdateValue"  [BindLabel]="'Name'"
                                                        [BindLabel2]="'Remarks'" [HasMultiple]="false" 
                                                        [LabelTitle2]="'Description'" [LabelForId]="'Id'"
                                                        [LabelTitle1]="'Code'" [IsTitle]="true"  [show2ValInInputhidden]="true"
                                                        [BindValue]="'Name'" [Show2Values]="true" [HasBindLabel2]="true"
                                                        [List]="compoundDrugCostBasis">
                                                    </eprx-select>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 form-group"
                                                    >
                                                    <label class="align-label">Drug Lot# </label>
                                                    <eprx-select
                                                    [RxSelectId]="'drugLotID'"
                                                    (focus)="slctdDrugId(AddOrUpdateValue.value.DrugId)"
                                                    placeholder="LOT NO" [ngbTypeahead]="searchDrugLot"
                                                    [resultTemplate]="rt"
                                                    [BindLabel]="'LotNum'"
                                                    [BindValue]="'LotNum'"
                                                    [bindScnCntrlV]="'LotNum'"
                                                    (selectItem)="openDrugLotModal($event,'LotNum',AddOrUpdateValue);"
                                                    [FormGroupName]="AddOrUpdateValue"
                                                    [ControlName]="'LotNum'" 
                                                    (TriggerSelectValue)="enteredDrugLot($event, AddOrUpdateValue, 'LotNum')"
                                                    (TriggerOnBlur)="enteredDrugLot($event, AddOrUpdateValue, 'LotNum')"
                                                    (TriggerFocusOutOnTabbing)="enteredDrugLot($event, AddOrUpdateValue, 'LotNum')"
                                                    (keydown.space)="drugLotRestriction($event)"
                                                    [AddItem]="true" [SelectOnTab]="true"
                                                    [ShowDefaultValue]="true"
                                                    [InputValue]="AddOrUpdateValue?.controls?.LotNum?.value"
                                                    (TriggerOnEnterValue)="getLotFocus($event)"
                                                    (TriggerOnTabbing)="getLotFocus($event)"
                                                    [List]="finalLotList"
                                                    [addItemMaxLength]="30">
                                                </eprx-select>
                                                    <ng-template #rt let-r="result" let-t="term">
                                                        <div class="card patient-card secondary-patient-card" *ngIf="r">
                                                            <div class="row">
                                                                {{r?.LotNum}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #changeDrugLot let-c="close" let-d="dismiss"
                                                        class="el--popup">
                                                        <app-custom-modal (oncrossBtnClick)="c('close');cancelDrugLot(selectedRecord,AddOrUpdateValue)">
                                                            <h4 header>Warning!!</h4>
                                                            <span body>
                                                                Lot# on NDC differs from the current value in the drug file,
                                                                do you wish to
                                                                update to the new value?
                                                            </span>
                                                            <button footer class="hotkey_primary"
                                                                (click)="optedDrugLot(selectedRecord,AddOrUpdateValue);c('close')"
                                                                appShortcutKey InputKey="y"><b>Y</b> YES </button>
                                                            <button footer class="hotkey_primary"
                                                                (click)="c('close');cancelDrugLot(selectedRecord,AddOrUpdateValue)"
                                                                appShortcutKey InputKey="n"><b>N</b>NO</button>
                                                        </app-custom-modal>
                                                    </ng-template>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <eprx-date-picker [LabelText]="'Expire Date'"
                                                        [FormGroupName]="AddOrUpdateValue" [ControlName]="'ExpDt'"
                                                        [PlaceHolder]="'Date'" [MinDate]="minDate" [RxSelectId]="'ExpDt'"
                                                        [IsRequired]="AddOrUpdateValue.get('LotNum')?.value"
                                                        [ErrorDefs]="{ required: 'Required'}"
                                                        (TriggerOnEnterValue)="enteredDrugLot($event, AddOrUpdateValue, 'ExpDt')"
                                                        [IsDisabled]="isDisabledEXP"
                                                        
                                                        [MarkAsTouched]="AddOrUpdateValue?.controls?.ExpDt?.touched">
                                                    </eprx-date-picker>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                                                    *ngIf="!isAddDrug && !isFromDrug">
                                                    <eprx-input [LabelText]="'Mod1'" [PlaceHolder]="'Mod1'"
                                                        [ControlName]="'Mod1'" [FormGroupName]="AddOrUpdateValue">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                                                    *ngIf="!isAddDrug && !isFromDrug">
                                                    <eprx-input [LabelText]="'Mod2'" [PlaceHolder]="'Mod2'"
                                                        [ControlName]="'Mod2'" [FormGroupName]="AddOrUpdateValue">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                                                    *ngIf="!isAddDrug && !isFromDrug">
                                                    <eprx-input [LabelText]="'Mod3'" [PlaceHolder]="'Mod3'"
                                                        [ControlName]="'Mod3'" [FormGroupName]="AddOrUpdateValue">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                                                    <eprx-input [LabelText]="'Ingredient Proportion%'"
                                                        [PlaceHolder]="'IngPropPer'" [ControlName]="'IngPropPer'"
                                                        [FormGroupName]="AddOrUpdateValue" [RxSelectId]="'Ingproportion'"
                                                       >
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                                                    <eprx-input [LabelText]="'Cost'" [PlaceHolder]="'Cost'"
                                                        [ControlName]="'Cost'" [FormGroupName]="AddOrUpdateValue"
                                                        [DecimalValues]="3" [InputType]="'CURRENCY'"
                                                        (TriggerSearchValue)="setNumberFormat('Cost',AddOrUpdateValue,3)">
                                                    </eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                                                    <eprx-radio [LabelText]="'Active'" [ControlName]="'IsActive'"
                                                        [FormGroupName]="AddOrUpdateValue" [List]="['Yes', 'No']"
                                                        [ValueList]="[true, false]" [IDForList]="['14', '15']"
                                                        [LabelForId]="">
                                                    </eprx-radio>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                                                    <eprx-radio [LabelText]="'Quantity Sufficient'"
                                                        [ControlName]="'IsQtySufficient'" [FormGroupName]="AddOrUpdateValue"
                                                        [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                                        [IDForList]="['12', '13']" [LabelForId]="">
                                                    </eprx-radio>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                                                    <eprx-radio [LabelText]="'Optional'" [ControlName]="'IsOptional'"
                                                        [FormGroupName]="AddOrUpdateValue" [List]="['Yes', 'No']"
                                                        [ValueList]="[true, false]" [IDForList]="['10', '11']"
                                                        [LabelForId]="">
                                                    </eprx-radio>
                                                </div>
    
                                        <div [ngClass]="AddOrUpdateValue.value['DrugId'] ? (!isAddDrug && !isFromDrug) ?
                                        'col-lg-10 col-md-10 col-sm-10 col-xs-10 text-right margin_top_10px' : 'col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right margin_top_10px' :
                                        'col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right margin_top_10px'">
                                                        <button class="hotkey_success" (click)="addIngredient()" appShortcutKey
                                                            [AltKey]="'d'" *ngIf="!editMode">
                                                            <span>D</span>Add </button>
                                                        <button class="hotkey_success" (click)="addIngredient()" appShortcutKey
                                                            [AltKey]="'u'" *ngIf="editMode">
                                                            <span>U</span>Update</button>
                                                        <button class="hotkey_success" (click)="CloseWindow()" appShortcutKey
                                                            [AltKey]="'o'"><span>O</span> Close </button>
                                                    </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!AddOrUpdateValue.value['DrugId']" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right margin_top_10px"
                                           >
                                            <button class="hotkey_success" (click)="CloseWindow()" appShortcutKey [AltKey]="'o'"><span>O</span> Close </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
    
    <div>
        <div class="row p-3">
            <div class="col-md-2">
                <eprx-select [LabelText]="'Level of effort'" [PlaceHolder]="'Level of effort'" [ControlName]="'EffortLvlId'"
                    [FormGroupName]="DrugIngredFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'"
                    [List]="systemData?.effortLvl" [IsDisabled]="isBilled" [Title]="isBilled ? 'Disabled as it is Billed Rx' : ''">
                </eprx-select>
            </div>
            <div class="col-md-2">
                <eprx-input [PlaceHolder]="'Dispensed Quantity'" [LabelText]="'Dispensed Quantity'" [ControlName]="'DispQty'"
                    [FormGroupName]="DrugIngredFG" [InputType]="'NUMBER'" [DecimalValues]="3"
                    [RxSelectId]="'DispQty'"
                    (TriggerSearchValue)="setNumberFormat('DispQty',DrugIngredFG, 3)" [IsDisabled]="isBilled" [Title]="isBilled ? 'Disabled as it is Billed Rx' : ''">
                </eprx-input>
            </div>
            <div class="col-md-2">
                <eprx-input [PlaceHolder]="'Beyond Use Days'" [LabelText]="'Beyond Use Days'"
                    [ControlName]="'BeyondUseDays'" [FormGroupName]="DrugIngredFG" [InputType]="'NUMBER'" [IsDisabled]="isBilled" [Title]="isBilled ? 'Disabled as it is Billed Rx' : ''">
                </eprx-input>
            </div>
            <div class="col-md-2">
                <eprx-input [PlaceHolder]="'Time To Make'" [LabelText]="'Time To Make'" [ControlName]="'TimeToMake'"
                    [FormGroupName]="DrugIngredFG" [InputType]="'NUMBER'" [IsDisabled]="isBilled" [Title]="isBilled ? 'Disabled as it is Billed Rx' : ''">
                </eprx-input>
            </div>
            <div class="col-md-2" *ngIf="this.dataFmRx?.['PriceCD']">
                <eprx-input [PlaceHolder]="'Base'" [LabelText]="'Base'" [ControlName]="'Base'"
                    [FormGroupName]="DrugIngredFG" [IsDisabled]="true">
                </eprx-input>
            </div>
            <div class="col-md-2" *ngIf="this.dataFmRx?.['PriceCD']">
                <eprx-input [PlaceHolder]="'Price'" [LabelText]="'Price'" [ControlName]="'Price'"
                    [FormGroupName]="DrugIngredFG" [IsDisabled]="true">
                </eprx-input>
            </div>
            <div class=" col-md-12 text-right" *ngIf="!isBilled">
                <button class="hotkey_success" (click)="saveDrugIngredients()" appShortcutKey [AltKey]="'s'">
                    <span>S</span>Save </button>
                <button class="hotkey_success" (click)="IsPopUpClosed('cancel');activeModal.dismiss('Cross click')" appShortcutKey
                    [AltKey]="'c'"><span>C</span> Cancel </button>
            </div>
            <div class=" col-md-12 text-right" *ngIf="isBilled">
                <button class="hotkey_success" (click)="IsPopUpClosed('cancel');activeModal.dismiss('Cross click')" appShortcutKey
                    [AltKey]="'o'"><span>O</span> OK </button>
            </div>
        </div>
    </div>
</ng-template>
    <ng-template #COMPOUNDINGREDIENTRESTRICTION let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="modalRef.close()">
            <h4 header>Warning!!</h4>
            <span body>
                {{message}}
            </span>
            <div footer class="row position-relative bottom-0 width_100per margin_1_0_0_1rem">
                <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <button ngbAutofocus class="hotkey_primary"  (click)="RemoveDrugANDAddDrug()" appShortcutKey
                        InputKey="y"><b>Y</b> YES</button>
                    <button class="hotkey_primary" (click)="modalRef.close();CloseWindow()" appShortcutKey InputKey="n">
                        <b>N</b> NO</button>
                </div>
            </div>
        </app-custom-modal>
    </ng-template>


