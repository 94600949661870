<form class="form-group custom--input was-validated custom--input-radio" [ngClass]="{'has-error':errorMessage}">
    <label class="control-label" *ngIf="labelText">
        <span>{{labelText}}</span>
    </label>
    <span *ngIf="hasControl && inputFormGroup" [formGroup]="inputFormGroup" class="inline-flex">
        <div class="custom-control custom-radio" *ngFor="let listVal of radioList; let i=index">
            <input type="radio"
            class="custom-control-input"
            [id]="labelForId? listVal[labelForId]:idForList[i]"
            [name]="name"
            [formControlName]="custContrlName"
            [attr.disabled]="isDisabled ? true : null"
            [required]="isRequired"
            [value]="bindValue?listVal[bindValue]:valueList[i]"
            (change)="changeValue($event)"
            (keyup.enter)="onEnter($event)"
            [tabindex]="tabIndex" [appAutofocus]="autoFocus"
            (keyup.shift.tab)="onShiftTabbingEvent($event)">
            <label class="custom-control-label" [for]="labelForId? listVal[labelForId]:idForList[i]">
                {{bindLabel?listVal[bindLabel]:listVal}}
            </label>
        </div>
    </span>

    <span *ngIf="hasControl && !inputFormGroup" class="inline-flex">
        <div class="custom-control custom-radio" *ngFor="let listVal of radioList; let i=index">
            <input type="radio"
            class="custom-control-input"
            [id]="labelForId? listVal[labelForId]:idForList[i]"
            [name]="name"
            [formControl]="custContrlName"
            [attr.disabled]="isDisabled ? true : null"
            [required]="isRequired"
            [value]="bindValue?listVal[bindValue]:valueList[i]"
            (change)="changeValue($event)"
            (keyup.enter)="onEnter($event)"
            [tabindex]="tabIndex" [appAutofocus]="autoFocus"
            (keyup.shift.tab)="onShiftTabbingEvent($event)">
            <label class="custom-control-label" [for]="labelForId? listVal[labelForId]:idForList[i]">
                {{bindLabel?listVal[bindLabel]:listVal}}
            </label>
        </div>
    </span>
    <span *ngIf="!hasControl" class="inline-flex">
        <div class="custom-control custom-radio" *ngFor="let listVal of radioList; let i=index">
            <input type="radio"
            class="custom-control-input"
            [id]="labelForId? listVal[labelForId]:idForList[i]"
            [name]="name"
            [attr.disabled]="isDisabled ? true : null"
            [required]="isRequired"
            [value]="bindValue?listVal[bindValue]:valueList[i]"
            (change)="changeValue($event)"
            (keyup.enter)="onEnter($event)"
            [tabindex]="tabIndex" [appAutofocus]="autoFocus" >
            <label class="custom-control-label" [for]="labelForId? listVal[labelForId]:idForList[i]"
            (keyup.shift.tab)="onShiftTabbingEvent($event)">
                {{bindLabel?listVal[bindLabel]:listVal}}
            </label>
        </div>
    </span>
    <div class="help-block" *ngIf="errorMessage">
        {{errorMessage}}
    </div>
</form>
