import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { checkBoxesenum, datePickerenum, dropDownenum } from 'src/app/models/reportWriter.enum';
import { AlertService, CommonService, PreviousRouteService, ReportsService } from 'src/app/services';
import { CommonStore } from 'src/app/store';
import { CommonUtil, ValidateFormFieldsUtils } from 'src/app/utils';
import { Location } from "@angular/common";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonWarnorconfirmPopupComponent } from '../common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { PrintService } from 'src/app/services/print.service';
import { Router } from "@angular/router";
import { ReportsUtil } from 'src/app/utils/reports.util';

@Component({
    selector: "app-custom-report-preview",
    templateUrl: "./custom-report-preview.component.html",
    styleUrls: ["./custom-report-preview.component.scss"],
})
export class CustomReportPreviewComponent implements OnInit {
    datePickers: any;
    previewFG: FormGroup;
    today: string;
    dropDowns: any;
    dropDownList: any;
    suggestFilters: any;
    Checkboxes: any;
    prescInputValue: any = false;
    drugInputValue: any = "";
    patientInputvalue: any = false;
    datePickerEnums: any;
    dropDownEnums: any;
    prevFilters: any;
    unsubscribe$: Subject<void> = new Subject();
    selcReportId: any;
    selcReprtName: any;
    filtersParamList: any;
    paramsList: any[] = [];
    drugClassList: any;
    drugBrandList: { id: number; code: string }[];
    systemData: any;
    systemData$: Observable<any>;
    pickUpList: { id: number; code: string }[];
    rxTransferList: { id: number; code: string }[];
    bucketList$: any;
    datePickDisplay: any;
    frmDateCntrl: any;
    toDateCntrl: any;
    displayTextIns: any;
    displayTextFac: any;
    displayTextPat: any;
    displayTextDgType: any;
    displayTextDgClass: any;
    displayTextDgBrnd: any;
    displayTextPyr: any;
    displayTextDAW: any;
    displayTextOrg: any;
    displayTextPckUp: any;
    displayTextTrans: any;
    displayTextPharm: any;
    displayTextDiagCd: any;
    displayTextRxSt: any;
    displayTextInvBck: any;
    displayTextExcIns: any;
    CntrlNameExcIns: any;
    CntrlNameInvBck: any;
    CntrlNameRxSt: any;
    CntrlNameDiagCd: any;
    CntrlNamePharm: any;
    CntrlNameTrans: any;
    CntrlNamePckUp: any;
    CntrlNameOrg: any;
    CntrlNameDAW: any;
    CntrlNamePyr: any;
    CntrlNameIns: any;
    CntrlNameFac: any;
    CntrlNamePat: any;
    CntrlNameDgType: any;
    CntrlNameDgClass: any;
    CntrlNameDgBrnd: any;
    fillDateDisplay: any;
    fillFrmDisplay: any;
    fillToDisplay: any;
    ordFrmDisplay: any;
    ordToDisplay: any;
    dueDtFrmDisplay: any;
    dueDtToDisplay: any;
    dueDtThrshFrmDisplay: any;
    dueDtThrshToDisplay: any;
    dueDtPckUpFrmDisplay: any;
    pickUpDtFrmDisplay: any;
    pickUpDtToDisplay: any;
    accDtFrmDisplay: any;
    accDtToDisplay: any;
    dueDtPckUpToDisplay: any;
    cntrlFillFrm: any;
    cntrlFillTo: any;
    cntrlOrdFrm: any;
    cntrlOrdTo: any;
    cntrlDueFrm: any;
    cntrlDueTo: any;
    cntrlDueDtThrshFrm: any;
    cntrlDueDtThrshTo: any;
    cntrlDueDtPckUpFrm: any;
    cntrlDueDtPckUpTo: any;
    cntrlPickUpDtFrm: any;
    cntrlPickUpDtTo: any;
    cntrlAccDtFrm: any;
    cntrlAccDtTo: any;
    reportIsInProgress: any;
    isprint: boolean;
    maxDate: { year: number; month: number; day: number };
    private subscription: Subscription;
    count = 0;
    alreadyReportGenerated: any;
    showPreview = false;
    pdfContent: any;
    CustomReportDet: any;
    totalInsurance: any;
    totalFacility: { code: string; description: string; }[];
    totalDrugType: { id: number; code: string; description: string; }[];
    totalRxStatus: { id: number; code: string; description: string; }[];
    patCatData: { code: string; description: string; }[];
    reportParamsFG: FormGroup;
    checkBoxEnums: typeof checkBoxesenum;
    constructor(
        private _fb: FormBuilder,
        private _cdr: ChangeDetectorRef,
        private _reportSvc: ReportsService,
        private _prevRouteSer: PreviousRouteService,
        private _actvRoute: ActivatedRoute,
        private _alertSvc: AlertService,
        private _reportUtil: ReportsUtil,
        private _cmnStore: CommonStore,
        private _cmnSvc: CommonService, private _commonUtils: CommonUtil,private _routerUrl: Router,
        private _formUtils: ValidateFormFieldsUtils,private _location: Location, private _modalService: NgbModal,private _sanitizer: DomSanitizer, public _printServ: PrintService,
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.systemData$ = this._cmnSvc.systemData$;
        this.previewFG = this._fb.group({
            PrescriberId: [],
            DrugId: [],
            PatientId: [],
        });
        this.maxDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date(),
        };
    }

    ngOnInit(): void {
        this._actvRoute.queryParams
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((params) => {
                if (params && params["reportId"] && params["reportNm"]) {
                    this.selcReportId = params["reportId"];
                    this.selcReprtName = params["reportNm"];
                    this.datePickerEnums = datePickerenum;
                    this.dropDownEnums = dropDownenum;
                    this.checkBoxEnums = checkBoxesenum;
                    this.showPreview = false;
                    this.paramsList = [];
                    this.getPreviewFilters();
                }
            });
    }

    async getPreviewFilters() {
        this.createReportParamsFG();
        const reportFiltersAll = await this._reportSvc
            .getAllReportFilters({ ReportId: +this.selcReportId })
            .toPromise();
        if (reportFiltersAll?.isReportActive) {
            this.prevFilters = reportFiltersAll ? reportFiltersAll.filters : [];
            this.reportParamsFG.controls["reportType"].patchValue(reportFiltersAll.reportTypeId);
            this.datePickers = this.prevFilters?.length
                ? this.prevFilters.filter(
                      (item: any) => item.filterType === "datepicker"
                  )
                : [];
            this.dropDowns = this.prevFilters?.length
                ? this.prevFilters.filter(
                      (item: any) => item.filterType === "dropdown"
                  )
                : [];
            this.suggestFilters = this.prevFilters?.length
                ? this.prevFilters.filter(
                      (item: any) => item.filterType === "suggest"
                  )
                : [];
            this.Checkboxes = this.prevFilters?.length ? this.prevFilters.filter((chckItem: any) => chckItem.filterType === "checkbox") : []
            this.filtersParamList = this.prevFilters?.length
                ? this.prevFilters.map((item: any, i) => {
                      if (item.filtersParams?.length) {
                          item.filtersParams.map((item1: any) =>
                              this.paramsList.push(item1.trim())
                          );
                      }
                      return item.filtersParams;
                  })
                : [];
            this.drugClassList = [
                { id: 0, code: "All" },
                { id: 1, code: "Class 2" },
                { id: 2, code: "Class 3" },
                { id: 3, code: "Class 4" },
                { id: 4, code: "Class 5" },
                { id: 5, code: "Exclude Controls" },
                { id: 6, code: "All Control Drugs(2-5)" },
            ];
            this.drugBrandList = [
                { id: 0, code: "All" },
                { id: 1, code: "Generic" },
                { id: 2, code: "Brand" },
            ];
            this.systemData = this.systemData$["source"]["value"];
            this.pickUpList = [
                { id: 0, code: "All" },
                { id: 1, code: "Picked Up" },
                { id: 2, code: "Not Picked Up" },
            ];
            this.rxTransferList = [
                { id: 0, code: "All" },
                { id: 1, code: "Transferred In" },
                { id: 2, code: "Transferred Out" },
            ];
            this.bucketList$ = this._cmnSvc.bucketList$;
            this.dropDowns.map((drpVal: any) => {
                if (drpVal.filterName === "Insurances") {
                    this.displayTextIns = dropDownenum.insuranceNm;
                    this.CntrlNameIns = dropDownenum?.insCode;
                }
                if (drpVal.filterName === "Facility") {
                    this.displayTextFac = dropDownenum.facilityNm;
                    this.CntrlNameFac = dropDownenum.facCode;
                }
                if (drpVal.filterName === "Patient Category") {
                    this.displayTextPat = dropDownenum.patCatNm;
                    this.CntrlNamePat = dropDownenum.patCatCode;
                }
                if (drpVal.filterName === "Drug Type") {
                    this.displayTextDgType = dropDownenum.drugTypNm;
                    this.CntrlNameDgType = dropDownenum.drugTypId;
                }
                if (drpVal.filterName === "Drug Class") {
                    this.displayTextDgClass = dropDownenum.drugClassNm;
                    this.CntrlNameDgClass = dropDownenum.drugClasId;
                }
                if (drpVal.filterName === "Drug Brand") {
                    this.displayTextDgBrnd = dropDownenum.drugBrndNm;
                    this.CntrlNameDgBrnd = dropDownenum.drugBrndId;
                }
                if (drpVal.filterName === "Payor") {
                    this.displayTextPyr = dropDownenum.payorNm;
                    this.CntrlNamePyr = dropDownenum.payorCd;
                }
                if (drpVal.filterName === "DAW Values") {
                    this.displayTextDAW = dropDownenum.dawNm;
                    this.CntrlNameDAW = dropDownenum.dawId;
                }
                if (drpVal.filterName === "Rx Origin") {
                    this.displayTextOrg = dropDownenum.rxOrgnNm;
                    this.CntrlNameOrg = dropDownenum.rxOrgId;
                }
                if (drpVal.filterName === "Pickup Status") {
                    this.displayTextPckUp = dropDownenum.pickUpStatNm;
                    this.CntrlNamePckUp = dropDownenum.pickUpStId;
                }
                if (drpVal.filterName === "Rx Transfer Status") {
                    this.displayTextTrans = dropDownenum.transfStatNm;
                    this.CntrlNameTrans = dropDownenum.transfStatus;
                }
                if (drpVal.filterName === "Pharmacist") {
                    this.displayTextPharm = dropDownenum.pharmacistNm;
                    this.CntrlNamePharm = dropDownenum.pharmacistId;
                }
                if (drpVal.filterName === "Diagnosis Code(s)") {
                    this.displayTextDiagCd = dropDownenum.diagCodeNm;
                    this.CntrlNameDiagCd = dropDownenum.diagCode;
                }
                if (drpVal.filterName === "Rx Status") {
                    this.displayTextRxSt = dropDownenum.rxStatNm;
                    this.CntrlNameRxSt = dropDownenum.rxStatusId;
                }
                if (drpVal.filterName === "Inventory Bucket") {
                    this.displayTextInvBck = dropDownenum.invBuckNm;
                    this.CntrlNameInvBck = dropDownenum.invBuckId;
                }
                if (drpVal.filterName === "Exclude Insurances") {
                    this.displayTextExcIns = dropDownenum.excldInsNm;
                    this.CntrlNameExcIns = dropDownenum.excludeIns;
                }
            });
            this.datePickers.map((datePckr: any) => {
                if (datePckr.filterName === "Fill Date") {
                    this.fillFrmDisplay = datePickerenum?.fillFrom;
                    this.fillToDisplay = datePickerenum?.fillTo;
                    this.cntrlFillFrm = datePickerenum.fillFrmDate;
                    this.cntrlFillTo = datePickerenum.fillToDate;
                }
                if (datePckr.filterName === "Ord Date") {
                    this.ordFrmDisplay = datePickerenum?.orderFrom;
                    this.ordToDisplay = datePickerenum?.orderTo;
                    this.cntrlOrdFrm = datePickerenum.ordFrmDate;
                    this.cntrlOrdTo = datePickerenum.ordToDate;
                }
                if (datePckr.filterName === "Due Date") {
                    this.dueDtFrmDisplay = datePickerenum?.dueDtFrom;
                    this.dueDtToDisplay = datePickerenum?.dueDtTo;
                    this.cntrlDueFrm = datePickerenum.dueFrmDate;
                    this.cntrlDueTo = datePickerenum.dueToDate;
                }
                if (
                    datePckr.filterName === "Due Date by Consumption Threshold"
                ) {
                    this.dueDtThrshFrmDisplay = datePickerenum?.dueDtThrshldFrm;
                    this.dueDtThrshToDisplay = datePickerenum?.dueDtThrshldTo;
                    this.cntrlDueDtThrshFrm =
                        datePickerenum.dueByThrshldFrmDate;
                    this.cntrlDueDtThrshTo = datePickerenum.dueByThrshldToDate;
                }
                if (
                    datePckr.filterName === "Due Date from based on Pickup Date"
                ) {
                    this.dueDtPckUpFrmDisplay = datePickerenum?.dueDtPckUpFrm;
                    this.dueDtPckUpToDisplay = datePickerenum?.dueDtPckUpTo;
                    this.cntrlDueDtPckUpFrm = datePickerenum.dueByPckUpFrmDate;
                    this.cntrlDueDtPckUpTo = datePickerenum.dueByPckUpToDate;
                }
                if (datePckr.filterName === "Picked Up Date") {
                    this.pickUpDtFrmDisplay = datePickerenum?.pickUpDtFrm;
                    this.pickUpDtToDisplay = datePickerenum?.pickUpDtTo;
                    this.cntrlPickUpDtFrm = datePickerenum.pickFrmDate;
                    this.cntrlPickUpDtTo = datePickerenum.pickToDate;
                }
                if (datePckr.filterName === "Date Of Accident") {
                    this.accDtFrmDisplay = datePickerenum?.accDtFrm;
                    this.accDtToDisplay = datePickerenum?.accDtTo;
                    this.cntrlAccDtFrm = datePickerenum.accFrmDate;
                    this.cntrlAccDtTo = datePickerenum.accToDate;
                }
            });
            const reportName = this.selcReprtName.replace(/\s/g, "")
            this._reportSvc.IsAsyncReportExistReprtWriter(this.selcReportId, reportName)
                .pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
                if (res.id) {
                    this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                } else {
                    this.reportIsInProgress = ""
                }
             });
        this.subscription =  this._cmnStore.reportGenerated$.subscribe(res => {
            if (res && res.length) {
                const reportName = this.selcReprtName.replace(/\s/g, "")
                const currentRoute = this._location.path();
                res.map((report) => {
                    if (report.ReportName === reportName && report.IsReportGenerated &&
                currentRoute.includes(report.ReportId)) {
                    const sessionAsyncReportId = sessionStorage.getItem("AsyncReportId") ? JSON.parse(sessionStorage.getItem("AsyncReportId")) : null;
                    if (report.AsyncReportId !== sessionAsyncReportId) {
                        sessionStorage.setItem("AsyncReportId", JSON.stringify(report.AsyncReportId))
                        this.alreadyReportGenerated = report;
                        this.generateReport();
                    }
                    }
                });
            }
        });
            this.dropDownList = reportFiltersAll
                ? reportFiltersAll.dropDownFilters
                : [];
            this.createFG();
            this.setDefaultValues();    
        } else {
            this._alertSvc.error(
                "The selected report " +
                    this.selcReprtName +
                    " has been deleted by a different user."
            );
            const data = {
                forSideMenu: true,
                pageNumber: null,
                pageSize: null,
            };
            const allReportsList: any = await this._reportSvc
                .getAllReportLists(data)
                .toPromise();
            if (
                allReportsList?.sideNavReportsData?.length &&
                allReportsList?.sideNavReportsData[0].reportId
            ) {
                this._cmnStore.storeAllCustmReportList(
                    allReportsList.sideNavReportsData
                );
            }
        }
    }
    createReportParamsFG() {
        this.reportParamsFG = this._fb.group({
            reportType: null,
            pageOrientation: true
        })
    }

    generateReport() {
        this.createReportParamsFG();
        const payLoad = {
            ReportName: this.alreadyReportGenerated.ReportName,
            AsyncReportId:  this.alreadyReportGenerated.AsyncReportId
        };
        if (this.alreadyReportGenerated.EventKey) {
            this._reportSvc.getAsyncFiltersReportWriter(payLoad)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                if (res) {
                    this.reportParamsFG.controls["reportType"].patchValue(res.reportTypeId);
                    this.reportParamsFG.controls["pageOrientation"].patchValue(res.isLandscape);
                    if (res.filters) {
                        this._alertSvc.error(MsgConfig.ASYNC_REPORT_ERROR + this.alreadyReportGenerated.EventKey);
                        this.setDefaultValues(res.filters);
                        this.resetReport();
                    } if (res.isReportReviewed) {
                       this.reportViewedPopup();
                    }
                }
            });
        } else {
        this._reportSvc.getAsyncFiltersReportWriter(payLoad)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
            if (res) {
                if (res.filters) {
                this._reportSvc.AsyncReportReportWriter(payLoad)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp && resp.size > 0 && resp.type === "application/ms-excel" || resp.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                        const blob = new Blob([resp], { type : resp.type === "application/ms-excel" ? 'application/ms-excel' :
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                         const file = new File([blob], this.alreadyReportGenerated.ReportName+"Export" + '.xlsx', { type: resp.type === "application/ms-excel" ? 'application/ms-excel' :
                         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        saveAs(file);
                    }  else if (resp && resp.size > 0 && resp.type === "application/pdf") {
                        if (this.alreadyReportGenerated.IsPrint) {
                            this.CustomReportDet = resp;
                            this.printCustomReportDet();
                        } else {
                            this.showPreview = true;
                            const urlCreator = window.URL;
                            this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                                urlCreator.createObjectURL(resp) + "#toolbar=0&zoom=115"
                            );
                        }
                    } else {
                        this.reportViewedPopup();
                    }
                    this.resetReport();
                });
                this.setDefaultValues(res.filters);
                this.reportParamsFG.controls["reportType"].patchValue(res.reportTypeId);
                this.reportParamsFG.controls["pageOrientation"].patchValue(res.isLandscape);
                } if (res.isReportReviewed) {
                   this.reportViewedPopup();
                }
            }
        });
    }
    }

    printCustomReportDet() {
        const newBlob = new Blob([this.CustomReportDet], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function() {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }

    resetReport() {
        const reportsData = this._cmnStore.reportGenerated$["source"]["value"];
        const reportName = this.selcReprtName.replace(/\s/g, "")
        reportsData.map(report => {
            if (report.ReportName === reportName && report.IsReportGenerated) {
                report.IsReportGenerated = false;
                report.AsyncReportId = null;
                report.Count = 0;
                report.EventKey = null;
                report.IsPrint = null;
                report.IsExport = false;
            }
        });
        this.count = 0;
        this.reportIsInProgress = null;
        this._cmnStore._reportGenerated$.next(reportsData);
    }
    reportViewedPopup() {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
            });
            modelRef.componentInstance.warnMsg = "The generated report has been previewed in another system.";
            modelRef.componentInstance.IsHeaderText = "Information";
            modelRef.componentInstance.okButton = true;
            modelRef.componentInstance.IsPopUpClosed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response: any) => {
            this.resetReport();
            modelRef.close();
            });
    }

    createFG() {
        this.paramsList.map((item22, i) => {
            const itemVal = item22;
            const valueCntrl = [];
            this.previewFG.addControl(itemVal, new FormControl(valueCntrl));
        });
    }

    setDefaultValues(res?) {
        if (res) {
            let itemVal:any;
            this.paramsList.map((item22, i) => {
                itemVal = item22.toString();
                this.previewFG.controls[item22].patchValue(!res[itemVal] ? ([dropDownenum.insCode, dropDownenum.facCode, dropDownenum.patCatCode].includes(item22) ? "All" : (item22 === dropDownenum.rxStatusId ? [0] : res[itemVal] === true ? res[itemVal] : 0)): res[itemVal])
            });
        } else {
            this.datePickers.map((val: any) => {
                if (val.filterName === "Fill Date") {
                    this.previewFG.controls[
                        datePickerenum.fillFrmDate
                    ].patchValue(this.today);
                    this.previewFG.controls[
                        datePickerenum.fillToDate
                    ].patchValue(this.today);
                }
                if (val.filterName === "Ord Date") {
                    this.previewFG.controls[
                        datePickerenum.ordFrmDate
                    ].patchValue(this.today);
                    this.previewFG.controls[
                        datePickerenum.ordToDate
                    ].patchValue(this.today);
                }
                if (val.filterName === "Due Date") {
                    this.previewFG.controls[
                        datePickerenum.dueFrmDate
                    ].patchValue(this.today);
                    this.previewFG.controls[
                        datePickerenum.dueToDate
                    ].patchValue(this.today);
                }
                if (val.filterName === "Due Date by Consumption Threshold") {
                    this.previewFG.controls[
                        datePickerenum.dueByThrshldFrmDate
                    ].patchValue(this.today);
                    this.previewFG.controls[
                        datePickerenum.dueByThrshldToDate
                    ].patchValue(this.today);
                }
                if (val.filterName === "Due Date from based on Pickup Date") {
                    this.previewFG.controls[
                        datePickerenum.dueByPckUpFrmDate
                    ].patchValue(this.today);
                    this.previewFG.controls[
                        datePickerenum.dueByPckUpToDate
                    ].patchValue(this.today);
                }
                if (val.filterName === "Picked Up Date") {
                    this.previewFG.controls[
                        datePickerenum.pickFrmDate
                    ].patchValue(this.today);
                    this.previewFG.controls[
                        datePickerenum.pickToDate
                    ].patchValue(this.today);
                }
                if (val.filterName === "Date Of Accident") {
                    this.previewFG.controls[
                        datePickerenum.accFrmDate
                    ].patchValue(this.today);
                    this.previewFG.controls[
                        datePickerenum.accToDate
                    ].patchValue(this.today);
                }
            });
            const insuranceAll = [{ code: "All", description: "Insurance"}];
            this.totalInsurance = insuranceAll.concat(this.dropDownList?.insurance);
            const facilityAll = [{ code: "All", description: "Facility" }];
            this.totalFacility = facilityAll.concat(this.dropDownList?.facility);
            const drugTypeAll = [{id:0, code: "All", description: "All"}];
            this.totalDrugType = drugTypeAll.concat(this.dropDownList?.drugType);
            const rxStatusAll = [{id:0, code: "All", description: "All"}];
            this.totalRxStatus = rxStatusAll.concat(this.dropDownList?.rxStatus);
            const patientCatData = [{ code: "All", description: "Patient Category" }];
            this.patCatData = patientCatData.concat(this.dropDownList?.patCustomCat)
            this.dropDowns.map((drpVal: any) => {
                if (drpVal.filterName === "Insurances") {
                    this.previewFG.controls[dropDownenum.insCode].patchValue("All");
                }
                if (drpVal.filterName === "Facility") {
                    this.previewFG.controls[dropDownenum.facCode].patchValue("All");
                }
                if (drpVal.filterName === "Drug Type") {
                    this.previewFG.controls[dropDownenum.drugTypId].patchValue(0);
                }
                if (drpVal.filterName === "Rx Status") {
                    this.previewFG.controls[dropDownenum.rxStatusId].patchValue([0]);
                }
                if (drpVal.filterName === "Drug Class") {
                    this.previewFG.controls[dropDownenum.drugClasId].patchValue(0);
                }
                if (drpVal.filterName === "Drug Brand") {
                    this.previewFG.controls[dropDownenum.drugBrndId].patchValue(0);
                }
                if (drpVal.filterName === "Pickup Status") {
                    this.previewFG.controls[dropDownenum.pickUpStId].patchValue(0);
                }
                if (drpVal.filterName === "Rx Transfer Status") {
                    this.previewFG.controls[dropDownenum.transfStatus].patchValue(0);
                }
                if (drpVal.filterName === "Patient Category") {
                    this.previewFG.controls[dropDownenum.patCatCode].patchValue("All");
                }
            });
            this.Checkboxes.map((chkBox: any) => {
                this.previewFG.controls[chkBox.parameterName].patchValue(false);
            })
        }
    }

    selectedPresInfo(val) {
        this.prescInputValue = true;
        if (val) {
            this._cdr.detectChanges();
            this.prescInputValue =
                val.prescriberlastname + ", " + val.prescriberfirstname;
            this.previewFG.controls["PrescriberId"].setValue(val.prescriberid);
        } else {
            this.prescInputValue = false;
            this.previewFG.controls["PrescriberId"].setValue(null);
        }
    }
    selectDrug(drugInfo: any) {
        this.drugInputValue = true;
        if (drugInfo) {
            this.previewFG.controls["DrugId"].patchValue(drugInfo.id);
            this._cdr.detectChanges();
            this.drugInputValue = drugInfo.drugname ? drugInfo.drugname : "";
        } else {
            this.previewFG.controls["DrugId"].patchValue(null);
            this.drugInputValue = "";
        }
    }

    selectPatient(patInfo: any) {
        this.patientInputvalue = true;
        if (patInfo) {
            this.previewFG.controls["PatientId"].patchValue(patInfo.patientid);
            this._cdr.detectChanges();
            this.patientInputvalue = patInfo.fullname
                ? patInfo.fullname.toUpperCase().replace("_", ",")
                : "";
        } else {
            this.previewFG.controls["PatientId"].patchValue(null);
            this.patientInputvalue = "";
        }
    }

    getStatusChange() {
        this.previewFG.controls[this.CntrlNameRxSt].patchValue(
            this._commonUtils.selectedRxStatus(this.previewFG.controls[this.CntrlNameRxSt].value));
    }
    async previewReport(val: boolean, IsExport: boolean = false, type?: string) {
        let resp:any;let stopCheckDateErrs: any;
        await this._formUtils.blurAndDelay();
        this.isprint = val;
        this.datePickers.map((datItem: any) => {
            if (datItem.filtersParams?.length && !stopCheckDateErrs) {
                resp = this._formUtils.checkDateErrors(this.previewFG, datItem.filtersParams[0], datItem.filtersParams[1], 7);
                stopCheckDateErrs = resp !== "VALID" ? true: false;
            }
        })
        if (resp === "DATEISSUE") {
            this._alertSvc.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSvc.error(MsgConfig.INVALID_DATES_ERROR);
        } else if (!resp || resp === "VALID") {
            let itemVal: any;
            this.paramsList.map((item22, i) => {
                itemVal = item22.toString();
                if (this.previewFG.controls[item22].value === "All" || (this.previewFG.controls[item22].value?.length && this.previewFG.controls[item22].value[0]=== 0) || this.previewFG.controls[item22].value === 0)
                    this.previewFG.value[item22] = null;
            });
            this.previewFG.value["IsExport"] = IsExport;
            const dataInfo = this.previewFG.value;
            const reportName = this.selcReprtName.replace(/\s/g, "")
            this._reportSvc.IsAsyncReportExistReprtWriter(this.selcReportId, reportName)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                const reportName = this.selcReprtName.replace(/\s/g, "")
                if (res.id)  {
                    this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                } else {
                        const payload = {
                            Filters: dataInfo,
                            ReportName: reportName.trim(),
                            IsNotificationReq: true,
                            IsPrint: this.isprint,
                            IsExport: IsExport,
                            ReportId: this.selcReportId,
                            IsLandscape: this.reportParamsFG.value["pageOrientation"]
                        };
                        this._reportSvc.getAsyncReportReprtWriter(payload).toPromise();
                        this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                }
            });
        }
    }
    closePrevReport() {
        this.paramsList = [];
        this._prevRouteSer.routeBackToPrevScreen();
        // this._routerUrl.navigate(['/eprx/Reports/ReportWriter']);
    }
    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        sessionStorage.removeItem("AsyncReportId");
    }
}
