import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { PageNotFoundComponent, LoginComponent, LandingComponent } from "./components";
import { IsActivUserGuard } from "./guards";
import { CustomPreloadingWithDelayStrategy } from "./services/customPreLoadingStrategy.service";
import { SsoErrorsComponent } from './components/sso-errors/sso-errors.component';
import { SsoRedirectionComponent } from "./components/sso-redirection/sso-redirection.component";

// Do not use "canLoad" guard, use can active for "PreLoading" strategy.
const APP_ROUTES: Routes = [
    { path: "index", component: LandingComponent},
    { path: "login", component: LoginComponent },
    { path: "login/:pharmacyID", component: LoginComponent },
    {
        path: "eprx",
        loadChildren: () => import('./modules/commonn/commonn.module').then(m => m.CommonnModule)
    },
    { path: "pageNotFound", component: PageNotFoundComponent },
    {path: "redirectpartner/:type", component: SsoErrorsComponent},
    { path: "redirectsso/:accesstoken/:redirectUrl", component: SsoRedirectionComponent},
    { path: "", redirectTo: "login", pathMatch: "full" },
    { path: "**", component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES, {
    preloadingStrategy: CustomPreloadingWithDelayStrategy, useHash: true,
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [RouterModule],
    providers: [IsActivUserGuard, CustomPreloadingWithDelayStrategy] // please provide your authguards in corresponding router modules
})
export class MainRoutesModule {}
