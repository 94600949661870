import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { diagnosis } from 'src/app/models';
import { AlertService, PatientService } from 'src/app/services';

@Component({
  selector: 'app-diagnis-code',
  templateUrl: './diagnis-code.component.html',
  styleUrls: ['./diagnis-code.component.scss']
})
export class DiagnisCodeComponent implements OnInit {
  @Input() ErxChangeReqFG: FormGroup;
  patDiagnis: any;
  diagListType: any;
  modelRef: any;
  @ViewChild("diagList", { static: true })
  diagList: any;
  unsubscribe$: Subject<void> = new Subject();

  get PrimaryDiagnosis1(): FormGroup {
    return this.ErxChangeReqFG.get("PrimaryDiagnosis1") as FormGroup;
  }
  get PrimaryDiagnosis2(): FormGroup {
      return this.ErxChangeReqFG.get("PrimaryDiagnosis2") as FormGroup;
  }
  get SecondaryDiagnosis1(): FormGroup {
      return this.ErxChangeReqFG.get("SecondaryDiagnosis1") as FormGroup;
  }
  get SecondaryDiagnosis2(): FormGroup {
      return this.ErxChangeReqFG.get("SecondaryDiagnosis2") as FormGroup;
  }
  constructor(  private _patientSVC: PatientService, private _modalService: NgbModal, private _alertServ: AlertService,) { }

  ngOnInit() {
      this.getPatientDiagnosis();
  }
  getPatientDiagnosis() {
    this.ErxChangeReqFG.controls["PatientId"].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((res: any) => {
        if (res) {
            this._patientSVC.getPatientDiagnosis(res)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resps => {
                if (resps) {
                    this.patDiagnis = resps;
                }
            });
        }
    });
  }
  checkIsExist(e, index) {
    let isExits = false;
    const primaryDiagnosis1 = this.PrimaryDiagnosis1.value["DiagnosisCode"];
    const primaryDiagnosis2 = this.PrimaryDiagnosis2.value["DiagnosisCode"];
    const secondaryDiagnosis1 = this.SecondaryDiagnosis1.value["DiagnosisCode"];
    const secondaryDiagnosis2 = this.SecondaryDiagnosis2.value["DiagnosisCode"];
    const diagnosis = e ? [primaryDiagnosis1,primaryDiagnosis2,secondaryDiagnosis1,secondaryDiagnosis2].filter((item: any) => { return (item && item === e.DIAGCODE)}) : [];
    if (e && e.DIAGCODE && diagnosis && diagnosis.length > 1) {
        isExits  = true;
    }
    if (isExits) {
        if (index === 1) {
            this.PrimaryDiagnosis1.controls["DiagnosisCode"].patchValue(null);
            this.PrimaryDiagnosis1.controls["DiagnosisDescription"].patchValue(null);
        } else if (index === 2) {
            this.PrimaryDiagnosis2.controls["DiagnosisCode"].patchValue(null);
            this.PrimaryDiagnosis2.controls["DiagnosisDescription"].patchValue(null);
        } else if (index === 3) {
            this.SecondaryDiagnosis1.controls["DiagnosisCode"].patchValue(null);
            this.SecondaryDiagnosis1.controls["DiagnosisDescription"].patchValue(null);
        } else if (index === 4) {
            this.SecondaryDiagnosis2.controls["DiagnosisCode"].patchValue(null);
            this.SecondaryDiagnosis2.controls["DiagnosisDescription"].patchValue(null);
        }
        this._alertServ.warning("Diagnosis code exist.");
    }
  }
  closeDiagList(value) {
    if (this.diagListType === "1") {
        this.PrimaryDiagnosis1.controls["DiagnosisCode"].patchValue(value["Code"]);
        this.PrimaryDiagnosis1.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
    } else if (this.diagListType === "2") {
        this.PrimaryDiagnosis2.controls["DiagnosisCode"].patchValue(value["Code"]);
        this.PrimaryDiagnosis2.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
    } else if (this.diagListType === "3") {
        this.SecondaryDiagnosis1.controls["DiagnosisCode"].patchValue(value["Code"]);
        this.SecondaryDiagnosis1.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
    } else if (this.diagListType === "4") {
        this.SecondaryDiagnosis2.controls["DiagnosisCode"].patchValue(value["Code"]);
        this.SecondaryDiagnosis2.controls["DiagnosisDescription"].patchValue(value["Diagnosis Description"]);
    }
    if (this.PrimaryDiagnosis1.value["DiagnosisCode"]) {
      this.PrimaryDiagnosis1.controls["Qualifier"].patchValue("ABF");
  }
  if (this.PrimaryDiagnosis2.value["DiagnosisCode"]) {
      this.PrimaryDiagnosis2.controls["Qualifier"].patchValue("ABF");
  }
  if (this.SecondaryDiagnosis1.value["DiagnosisCode"]) {
      this.SecondaryDiagnosis1.controls["Qualifier"].patchValue("ABF");
  }
  if (this.SecondaryDiagnosis2.value["DiagnosisCode"]) {
      this.SecondaryDiagnosis2.controls["Qualifier"].patchValue("ABF");
  }
    if (this.modelRef) {
        this.modelRef.close();
    }
}
openDiagList(type) {
  this.diagListType = type;
  this.modelRef = this._modalService.open(this.diagList, {
      size: "lg",
      windowClass: "modal-center-to-page",
      keyboard: false,
      backdrop: false
  });
}
ngOnDestroy() {
    this.unsubscribe$.next();
     this.unsubscribe$.complete();
   }
}
