import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rx-priv-thumbnails',
  templateUrl: './rx-priv-thumbnails.component.html',
  styleUrls: ['./rx-priv-thumbnails.component.scss']
})
export class RxPrivThumbnailsComponent implements OnInit {
  @Input() SasURLDocList;
  rxFormGroup: any;
  rxType: any;
  isNavigateFromDocumentQueue: any;
  prescNumber: any;
  refillNo: any;
  fillNumber: any;
  erxImage: string;
  
  @Input() set rxFG(pi) {
    this.rxFormGroup = pi;
    const rxValue = this.rxFormGroup.getRawValue();
    this.rxType = rxValue.rxType;
    this.isNavigateFromDocumentQueue = rxValue.isNavigateFromDocumentQueue;
    this.prescNumber = rxValue.Prescription.PrescNum;
    this.refillNo = rxValue.PrescReFill.ReFillNum;
    this.fillNumber = rxValue.PrescReFill.PartialFillNo;
}
@Output() PreviewDocFile = new EventEmitter();
@Output() EditDocFile = new EventEmitter();
@Output() DeleteDocFile = new EventEmitter();
@Output() PrintDocFile = new EventEmitter();
@Output() PreviewErxFile = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.erxImage = sessionStorage.getItem("erxImg")
  }

  geteditDocFile(item,ind) {
    let selectedDoc = { item:item, fromThumbnails:true, index:ind }
    this.EditDocFile.emit(selectedDoc);
  }

  deleteDocFile(item,ind,val) {
    let deletedDoc = { Id:item.id, data:item, row:ind, fromDeleteinfo:val }
    this.DeleteDocFile.emit(deletedDoc)
  }
  

  





}
