import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AccCategoryE, BucketE, EDrug, InventoryE } from "src/app/models";
import { AlertService, BucketService, CommonService, DrugService, PatPreDrugModalService, RxService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { RxStore } from "src/app/store";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder } from "@angular/forms";
import { PrivMaskGuard } from "src/app/guards";
import { MsgConfig } from "src/app/app.messages";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-low-inventory",
    templateUrl: "./low-inventory.component.html"
})
export class LowInventoryComponent implements OnInit {

    @Input() drugInfo: EDrug;
    @Input() rxFG: any;
    @Input() lowInvInfo: any;
    @Output() emitDrugSelected = new EventEmitter<any>();
    @Output() emitBucketSelected = new EventEmitter<any>();
    @Output() changeInQtyHand = new EventEmitter<any>();
    @Input() popUpClosed = new EventEmitter<any>();
    eqDrugList: EDrug[];
    eqDrugWJ: CollectionView;
    equActiveH: string[];
    equListWJ: wjcGrid.FlexGrid;
    _wijHeight: string;
    BucketList = [];
    buckInfo: any;
    bucketWJ: CollectionView;
    buckActiveH: string[];
    bucketListFlex: wjcGrid.FlexGrid;
    BucketListMangInv: any;
    unsubscribe$: Subject<void> = new Subject();

    @ViewChild("TransBucketPop", { static: true })
    TransBucketPop: any;

    @ViewChild("ERRORMSG", { static: true })
    ERRORMSG: any;

    transBucketFG: any;
    errorOn: any;
    errorMsg: string;
    model: any;
    transFGInvalid: boolean;

    constructor(private _drgSvc: DrugService, private _rxStore: RxStore, private _alertSvc: AlertService,
        private _modalSvc: NgbModal, private _fb: FormBuilder, private _BucketSer: BucketService,private _drugBuckUtil: DrugBucketUtil,
        private _cmmnServ: CommonService, private _rxSvc: RxService, private _invetoryPopup: PatPreDrugModalService,
        private _privMaskGuard: PrivMaskGuard, private _buckUtils: DrugBucketUtil, public _formatsUtil:FormatsUtil) { }

    ngOnInit() {
        this.getDrugEquivalents();
        this.getBucketList();
    }

    async getDrugBucketlistAndPatch(Id?) {
        const resp = await this._BucketSer.getBucketsForDropdowns(Id ? Id : 0).toPromise();
        if (resp) {
            this.BucketListMangInv = resp;
        } else {
            this.BucketListMangInv = null;
        }
    }

    getBucketList() {
        this.BucketList = this._rxStore.rxBucket$["source"]["value"];
        if (this.BucketList && this.BucketList.length) {
            this.buckInfo = this.BucketList.find(val => val["Id"] === this.lowInvInfo["bucketId"]);
            this.generateBuckWJ(this.BucketList);
        }
    }

    generateBuckWJ(data) {
        let List = Object.assign([], data);
        List = List.filter(val => val["Id"] !== this.lowInvInfo["bucketId"]);
        if (List && List.length) {
            this.bucketWJ = new CollectionView(
                data.map(val => {
                        const j = {};
                        j["Id"] = val["Id"];
                        j["Bucket Name"] = val["Name"];
                        j["Qty In Hand"] = val["Qty"] ? val["Qty"].toFixed(3) : "0.000";
                        return j;
                })
            );
        }
        this.buckActiveH = [
            "Action",
            "Bucket Name",
            "Qty In Hand"
        ];
    }

    optedBucket(value) {
        this.emitBucketSelected.emit(value["Id"]);
    }

    async getDrugEquivalents() {
        const resp = await this._drgSvc.getEquivalentDrug(this.drugInfo.id,
            this.drugInfo.drugcode, false, 1).toPromise();
        if (resp && resp.length) {
            this.eqDrugList = resp;
            this.generateEquiWJ(this.eqDrugList);
        } else {
            this.eqDrugList = [];
        }
    }

    generateEquiWJ(data) {
        this.eqDrugWJ = new CollectionView(
            data.map(val => {
                const j = {};
                j["Id"] = val["id"];
                j["Drug Name"] = (val["drugname"] ? (val["drugname"]).toUpperCase() + " " : "") +
                (val["strength"] ? (val["strength"]).toUpperCase() + " " : "") +
                (val["drugformname"] ? (val["drugformname"]).toUpperCase() : "");
                j["NDC#"] = j["NDC"] = this._formatsUtil.getNDCFormat(val["drugcode"]) ;
                j["Manufacture"] = val["manufacturername"];
                j["Pkg Size"] = val["qtypack"] ? parseFloat(val["qtypack"]).toFixed(3) : "0.000";
                j["AWP"] = val["unitpriceawp"] ? "$" + val["unitpriceawp"].toFixed(3) : "$0.000";
                j["Qty In Hand"] = this._buckUtils.getQtyInHand(val["manageinventory"]);
                j["Discontinued"] = val["Discontinued"] ? moment(val["Discontinued"]).format("MM/DD/YYYY") : null;
                j["U.Cost"] = val["unitpricecost"] ? "$" + val["unitpricecost"].toFixed(3) : "$0.000";
                j["Pref"] = val["ispreferred"] ? "Y" : "N";
                j["Last Dispensed"] = val["lastDispensedDate"] ? moment(val["lastDispensedDate"]).format("MM/DD/YYYY") : null;
                return j;
            })
        );
        this.equActiveH = [
            "Action",
            "Drug Name",
            "NDC",
            "Manufacture",
            "Qty In Hand",
            "Pkg Size",
            "AWP",
            "U.Cost",
            "Last Dispensed",
            "Preferred"
        ];
    }

    optedInvDrug(checkedVal: any) {
        const is340B =  this._cmmnServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        if(this.rxFG.PrescReFill.Is340B && this._drugBuckUtil.getInvSetForPatPrescDrg("Force_340B_NDC_for_340B_Rx") && checkedVal && !checkedVal.Is340B && is340B) {
            this._alertSvc.error("Non-340B Drug cannot be selected for 340B Rx.");
            return;
        } else {
            const data = this.eqDrugList.find((n: any) => n.id === checkedVal["Id"]);
            this.emitDrugSelected.emit(data);
        }
    }

    async openTransferPopup() {
        if (this._privMaskGuard.canActivate(AccCategoryE.Inventory, InventoryE.AllowTransferQtyBetweenInventoryBuckets)) {
            this.transBucketFG = this._fb.group({
                DrugId: this.drugInfo["id"], TransferDtTm: moment(Date.now()).format("MM/DD/YYYY"),
                FromBucketId: null, Buck1Qnty: 0, Qty: "0.000",
                ToBucketId: null, Buck2Qnty: 0, Buck2TransQnty: "0.000"
            });
            await this.getDrugBucketlistAndPatch(this.drugInfo["id"]);
            setTimeout(() => {
                this.model = this._modalSvc.open(this.TransBucketPop, {size: "lg", centered: true, backdrop: false});
            }, 200);
        } else {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
    }

    optedBucketFrTrans(type, value) {
        if (value && value["Id"]) {
            const fgValue = this.transBucketFG.value;
            if (fgValue[type === "Bucket1" ? "FromBucketId" : "ToBucketId"] ===
             fgValue[type === "Bucket1" ? "ToBucketId" : "FromBucketId"]) {
                this.errorOn = type;
                this.errorMsg = "Selected bucket is same as another one . Please select Another Bucket.";
                this._modalSvc.open(this.ERRORMSG, { centered: true, backdrop: false});
            } else if (value && value["Id"] && !this.checkInvMangOrNot(value["Id"])) {
                this.errorOn = type;
                this.errorMsg = " Inventory of  " + (this.drugInfo.drugname || "" + " ").toUpperCase() +
                    (this.drugInfo.strength || "" + " ").toUpperCase() + (this.drugInfo.drugformname || "" + " ").toUpperCase() +
                    "  drug has not been managed before for " +
                    value["Name"].toUpperCase() + " bucket. Please choose another bucket.";
                this._modalSvc.open(this.ERRORMSG, { centered: true, backdrop: false});
            } else if (type === "Bucket1" && !(parseFloat(value["Qty"]) > 0)) {
                this.errorOn = type;
                this.errorMsg = "Selected bucket does not have quantity to transfer . Please select Another Bucket.";
                this._modalSvc.open(this.ERRORMSG, { centered: true, backdrop: false});
            } else {
                if (value) {
                    this.transBucketFG.controls[type === "Bucket1" ? "Buck1Qnty" : "Buck2Qnty"].patchValue(value["Qty"] ? value["Qty"] : 0);
                } else {
                    this.transBucketFG.controls[type === "Bucket1" ? "Buck1Qnty" : "Buck2Qnty"].patchValue(0);
                }
                if (type === "Bucket1") {
                    this.transBucketFG.controls["Qty"].patchValue("0.000");
                }
                this.transBucketFG.controls["Buck2TransQnty"].patchValue(
                    ((this.transBucketFG.value["Qty"] ? parseFloat(this.transBucketFG.value["Qty"]) : 0) +
                    (this.transBucketFG.value["Buck2Qnty"] ? parseFloat(this.transBucketFG.value["Buck2Qnty"]) : 0)).toFixed(3));
            }
        } else {
            this.transBucketFG.controls[type === "Bucket1" ? "Buck1Qnty" : "Buck2Qnty"].patchValue(0);
        }
    }
    checkInvMangOrNot(Id: number): boolean {
        const bucket = this.BucketListMangInv.find(list => list.Id === Id);
        if (bucket && !bucket.IsInvManaged) {
            return false;
        }
        return true;
    }
    checkQtyValues() {
        const fgValue = this.transBucketFG.value;
        if (parseFloat(fgValue["Buck1Qnty"]) < parseFloat(fgValue["Qty"])) {
            this.errorOn = "Bucket1Qty";
            this.errorMsg = "Entered quantity to transfer is greater than avaiable Quantity of bucket.";
            this._modalSvc.open(this.ERRORMSG, {centered: true, backdrop: false});
        } else if (!(parseFloat(fgValue["Qty"]) > 0)) {
            this.errorOn = "Bucket1Qty";
            this.errorMsg = "Quantity to transfer must be greater than zero.";
            this._modalSvc.open(this.ERRORMSG, {centered: true, backdrop: false});
        } else {
            this.transBucketFG.controls["Qty"].patchValue((parseFloat(fgValue["Qty"])).toFixed(3));
            this.transBucketFG.controls["Buck2TransQnty"].patchValue(
                ((this.transBucketFG.value["Qty"] ? parseFloat(this.transBucketFG.value["Qty"]) : 0) +
                (fgValue["Buck2Qnty"] ? parseFloat(fgValue["Buck2Qnty"]) : 0)).toFixed(3));
        }
    }

    closeWarnPopup(event) {
        if (event && event.which === 13) {
            event.preventDefault();
        }
        if (this.errorOn === "Bucket1" || this.errorOn === "Bucket2") {
            this.transBucketFG.controls[this.errorOn === "Bucket1" ? "FromBucketId" : "ToBucketId"].patchValue(null);
            this.transBucketFG.controls[this.errorOn === "Bucket1" ? "Buck1Qnty" : "Buck2Qnty"].patchValue(0);
            if (document.getElementById(this.errorOn === "Bucket1" ? "Bucket1T" : "Bucket2T")) {
             const ele = document.getElementById(this.errorOn === "Bucket1" ? "Bucket1T" : "Bucket2T").getElementsByTagName("input")[0];
             ele.focus();
            }
        } else {
            this.transBucketFG.controls["Qty"].patchValue((0).toFixed(3));
            if (document.getElementById("Buck1TransQnty")) {
                document.getElementById("Buck1TransQnty").focus();
            }
        }
    }

    async transferQtyToBuck() {
        if (this.transBucketFG.valid) {
            this.transFGInvalid = false;
            if (this.model) {
                this.model.close();
                this.model = null;
            }
            const resp = await this._BucketSer.bucketTransfer(this.transBucketFG.value).toPromise();
            if (resp) {
                this._alertSvc.success("Transfer Success");
                await this.updateBucketLsitBH();
                this.getBucketList();
                this.updateBucketQtyInScreen();
            }
        } else {
            this.transFGInvalid = true;
        }
    }
    updateBucketQtyInScreen() {
        if (this.lowInvInfo) {
            this.lowInvInfo["qtyInHand"] = this.buckInfo["Qty"] || 0;
        }
    }
    async updateBucketLsitBH() {
        if (this._cmmnServ.bucketList$["source"]["value"] && this._cmmnServ.bucketList$["source"]["value"].length) {
            const bucketList = this._cmmnServ.bucketList$["source"]["value"].map(val => Object.assign(val, { Qty: 0 }));
            if (this.drugInfo["id"]) {
                const list = await this._rxSvc.getQtySpcToDrugBucket(this.drugInfo["id"]).toPromise();
                if (list && list.length > 0 && bucketList && bucketList.length > 0) {
                    list.map(val => {
                        const index = bucketList.findIndex(buck => buck["Id"] === val["BucketId"]);
                        if (index !== null && index > -1) {
                            bucketList[index]["Qty"] = val["BucketQty"];
                        }
                    });
                }
                this._rxStore.storeRxBuckList(bucketList);
            } else {
                this._rxStore.storeRxBuckList([]);
            }
        } else {
            this._rxStore.storeRxBuckList([]);
        }
    }

    async openEnterInvent() {
        if (this._privMaskGuard.canActivate(AccCategoryE.Inventory, InventoryE.EnterInventoryReceived)) {
            this._invetoryPopup._patPreDrugModal$.next("Inventory");
            const modelRef = await this._invetoryPopup.modalInstanceRef;
            modelRef.componentInstance.drugId = this.drugInfo.id;
            modelRef.componentInstance.frmEditDrug = true;
            modelRef.componentInstance.frmLow = true;
            modelRef.componentInstance.drugInfoData = this.drugInfo;
            modelRef.componentInstance.drugFullName = (this.drugInfo) ?
                (this.drugInfo.drugname + this.drugInfo.strength + this.drugInfo.drugformname) : " ";
            modelRef.componentInstance.BucketIdFromRx = this.rxFG.PrescReFill.BucketId;
            modelRef.componentInstance.emitInvClose
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                modelRef.close();
                this._invetoryPopup._patPreDrugModal$.next(null);
                if (resp && (resp === "Save" || resp === "SaveAndClose")) {
                    await this.updateDrugQty();
                    await this.updateBucketLsitBH();
                    this.getBucketList();
                    if(resp === "SaveAndClose" && this.lowInvInfo["qtyInHand"] >= this.rxFG.PrescReFill.DispQty) {
                        this.popUpClosed.emit(true);
                    }
                }
            });
        } else {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
    }

    async updateDrugQty() {
      const resp = await this._rxSvc.getQntyinHand(this.drugInfo.id, this.lowInvInfo.bucketId).toPromise();
      this.drugInfo["isinvmangd"] = true;
      this.lowInvInfo["qtyInHand"] = resp ? resp : 0;
      this.changeInQtyHand.emit(this.lowInvInfo["qtyInHand"]);
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    init(flex: wjcGrid.FlexGrid) {
        this.equListWJ = flex;
        flex.columnHeaders.rows[0].height = 25;
        flex.columnHeaders.rows[0].wordWrap = true;
        const element = document.getElementById("lowInvGrid");
        if (element) {
            this._wijHeight =
                window.innerHeight -
                element.getBoundingClientRect().top +
                window.scrollY -
                470 +
                "px";
        }
    }

    init1(flex: wjcGrid.FlexGrid) {
        this.bucketListFlex = flex;
        flex.columnHeaders.rows[0].height = 25;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    onRowDblclicked(s: any, e: any, type) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem && ht.cellType === 1) {
            type === "Bucket" ? this.optedBucket(s.selectedItems[0]) : this.optedInvDrug(s.selectedItems[0]);
        }
    }

    keyeventOnWj(type, grid) {
        if ((type === "Bucket" && this.BucketList && this.BucketList.length) ||
           (type !== "Bucket" && this.eqDrugList && this.eqDrugList.length !== 0)) {
            event.preventDefault();
            type === "Bucket" ? this.optedBucket(grid.selectedItems[0]) : this.optedInvDrug(grid.selectedItems[0]);
        }
    }

    focusOutFromWJ(event) {
        event.preventDefault();
        if (document.getElementById("lowInvCancel")) {
            setTimeout(() => {
                document.getElementById("lowInvCancel").focus();
            }, 20);
        }
    }

    closePopup() {
        this.popUpClosed.emit(true);
    }

}
