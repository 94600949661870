import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    HostListener
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxService, AlertService, CommonService } from "src/app/services";
import { RxInputParams } from "src/app/models";

@Component({
    selector: "app-rx-notes",
    templateUrl: "./rx-notes.component.html"
})
export class RxNotesComponent implements OnInit {
    modelRef: any;
    drugInfo: any;
    notes: string = "";
    rxInfo: any;
    rxInputParams: RxInputParams = new RxInputParams();

    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("RxNOTES", { static: true })
    RxNOTES: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }
    }


    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _rxSvc: RxService,
        private _alertService: AlertService,
        public _commonServ: CommonService
    ) {
        config.backdrop = "static";
    }

    ngOnInit() {
        this.getRxNotes();
        this.modelRef = this._modalService.open(this.RxNOTES,{keyboard:false , centered : true});

    }

    ngOnChanges() { }

    getRxNotes() {
        this._rxSvc.getRxNotes(this.rxInfo.Prescription.Id).subscribe(resp => {
            if (resp && resp.length > 0) {
                this.notes = resp;
            }
        });
    }

    async saveNotes() {
        this.rxInputParams.remarks = this.notes;
        this.rxInputParams.rxId = this.rxInfo.Prescription.Id;
        this.rxInputParams.prescNum = this.rxInfo.Prescription.PrescNum;
        this.rxInputParams.PartialfillNum = this.rxInfo.PrescReFill.PartialFillNo;
        this.rxInputParams.refillNum = this.rxInfo.PrescReFill.ReFillNum;

        const response = await this._rxSvc.updateRxNotes(this.rxInputParams).toPromise();
        if (response) {
            if (response === 1) {
                this._alertService.success("Notes saved successfully.");
            } else {
                this._alertService.error("Notes save unsuccessful.");
            }
        }
        // const prescription: any = this.rxFG.controls.Prescription;
        // prescription.controls["Remarks"].setValue(this.notes);
        this.closeModal();
    }

    changeNotes(notes: string) {
        this.notes = notes;
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }

    ngOnDestroy() {
        this._cdr.detach();
    }
}
