<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Pricing'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0 margin_bottom_10px">
                <button class="hotkey_success" id="SaveButton" (click)="update()"><span>S</span> Save</button>
                <button class="hotkey_success" (click)="CancelPopTaggle()" (keydown.tab)="focusOutFmCan($event)"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="row drug--price__info">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 padding-sm-15 exprx--common-block flex--one">
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Drug Price Information
                    </div>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                    <eprx-input [LabelText]="'AWP Pack'" [PlaceHolder]="'AWP Pack'" [ControlName]="'AWPPack'" [FormGroupName]="Drug"
                                   [AutoFocus]="true" [MaxLength]="10"
                                    [InputType]="'CURRENCY'" [DecimalValues]="2" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                    [RxSelectId]="'AWPPack'" (TriggerSearchValue)="convertByMouseDown('AWPPack')">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                    <eprx-input [LabelText]="'Cost Pack'" [DropSpclChar]="false" [PlaceHolder]="'Cost Pack'" [ControlName]="'CostPack'" [FormGroupName]="Drug"
                                    (TriggerSearchValue)="convertByMouseDown('CostPack')"  [MaskPattern]="'099999.00'" [InputType]="'CURRENCY'" [DecimalValues]="2" [RxSelectId]="'CostPack'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                    <eprx-input [LabelText]="'Unit Price_DIR'" [DropSpclChar]="false" [PlaceHolder]="'Unit Price_DIR'" [ControlName]="'DirectUnitPrice'" [FormGroupName]="Drug"
                                    [MaxLength]="10" (TriggerSearchValue)="numberFormat('DirectUnitPrice', 3)" [MaskPattern]="'09999.000'" [InputType]="'CURRENCY'" [DecimalValues]="3" [RxSelectId]="'DirectUnitPrice'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                    <eprx-input [LabelText]="'Unit AWP Price'" [DropSpclChar]="false" [PlaceHolder]="'Unit AWP Price'" [ControlName]="'UnitPriceAWP'" [FormGroupName]="Drug"
                                    [MaxLength]="10" (TriggerSearchValue)="convertByMouseDown('UnitPriceAWP')" [MaskPattern]="'09999.000'"
                                    [InputType]="'CURRENCY'" [DecimalValues]="3" [RxSelectId]="'UnitPriceAWP'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                    <eprx-input [LabelText]="'Unit Price Cost'"  [PlaceHolder]="'Unit Price Cost'" [ControlName]="'UnitPriceCost'"
                                    [FormGroupName]="Drug" [MaxLength]="10" [DropSpclChar]="false" [MaskPattern]="'09999.000'" (TriggerSearchValue)="convertByMouseDown('UnitPriceCost')"
                                    [InputType]="'CURRENCY'" [DecimalValues]="3" [RxSelectId]="'UnitPriceCost'" >
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-7 dinamic--fields" [formGroup]="drugPricingFG">
                            <div formArrayName="DrugMiscPrice" *ngIf="hasDrugMisc">
                                <div class="row" *ngFor="let drugMisc of DrugMiscPrice?.controls; let i=index" >
                                    <div class="col-lg-5  col-md-6 col-sm-12 col-xs-12 padding-0" *ngIf="drugPricingFG.controls['DrugMiscPrice']['value'][i].IsActive">
                                        <eprx-select [LabelText]="'Type Of Cost'" [PlaceHolder]="''" [ControlName]="'DrugMiscPriceCatId'" [FormGroupName]="drugMisc"
                                        [IsDisabled]="true" [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                            [List]="systemData?.DrugMiscPriceCat">
                                        </eprx-select>
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-12 padding-0 margin_left_20px"  *ngIf="drugPricingFG.controls['DrugMiscPrice']['value'][i].IsActive">
                                        <eprx-input [LabelText]="'Cost Amount'" [PlaceHolder]="''"
                                        [ControlName]="'Price'" [FormGroupName]="drugMisc" [MaskPattern]="'09999.099'" [DropSpclChar]="false"
                                         (TriggerSearchValue)="updateAltCost($event, i)" [InputType]="'CURRENCY'">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-1 text-center">

                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 exprx--common-block flex--one">
            <div class="row">
                <div class="col-md-12 padding-sm-15">
                    <div class="box box-default box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Cash Pricing
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-select [LabelText]="'Cash Price Scheduled'" [PlaceHolder]="'Cash Price Scheduled'" [ControlName]="'PriceSchId'" [FormGroupName]="Drug"
                                    [HasMultiple]="false" [BindLabel2]="'Name'" [BindLabel]="'PriceCode'" [BindValue]="'Id'"
                                    [LabelForId]="'Name'" [List]="priceScheduleList" [LabelTitle1]="'Price Code'" [LabelTitle2]="'Name'">
                                </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Surcharge'" [PlaceHolder]="'Surcharge'" [ControlName]="'Surcharge'" [MaskPattern]="'099.00'" [DropSpclChar]="false"
                                [InputType]="'NUMBER'" [FormGroupName]="Drug" (TriggerSearchValue)="numberFormat('Surcharge', 2)">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                                <eprx-input [LabelText]="'%Off Brand'" [PlaceHolder]="'%Off Brand'" [ControlName]="'PerOfBrand'"[MaskPattern]="'099.00'" [DropSpclChar]="false"
                                [InputType]="'NUMBER'" [FormGroupName]="Drug" (TriggerSearchValue)="numberFormat('PerOfBrand', 2)">
                                </eprx-input>
                            </div>
                            <div class="col-md-6 padding-0">
                                <label class="col-md-12 padding-0"> Discountable </label>
                                <eprx-radio [ControlName]="'IsDiscountable'" [FormGroupName]="Drug" [IsRequired]="true" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['yes', 'no']" [LabelForId]=""
                                    (keydown.tab)="focusToFirst($event)">
                                </eprx-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)">
</app-cancel-pop-up>
<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure, you want to delete?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteMiscPrice()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c"> <b>C</b>Cancel</button>
            </div>
        </div>
    </div>
</ng-template>
