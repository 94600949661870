import { constant } from "./../app.constants";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class MergePatientService {

    constructor(private _http: HttpClient) {}

    getData(patientId) {
        return this._http.get<any>(
            constant.GET_Patient.replace("{patientID}", "" + patientId)
        );
    }

    getRx(data) {
        return this._http.post<any>(constant.POST_Patient_RxHistory, data);
    }

    mergePatient(data) {
        return this._http.post<any>(constant.POST_Patient_Merge, data);
    }
}
