<!-- <ng-template
    #VIEWTRANSMSG
    let-c="close"
    let-d="dismiss"
    class="el--popup"
> -->
    <app-custom-modal (oncrossBtnClick)="closeModal()">
            <h4 header *ngIf="viewTransmData && !viewTransmData?.ErrorMessage"> View Transmission</h4>
        <span body *ngIf="viewTransmData && !viewTransmData?.ErrorMessage">
            <div class="drugtodrug">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">Header Information</div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row trasmit--rx">
                            <div class="col-lg-12 col-md-12 col-xs-12">
                                {{viewTransmData?.Header}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading"> Transmission String </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row trasmit--rx">
                            <div class="col-lg-12 col-md-12 col-xs-12 word_break">
                                {{viewTransmData?.TrString}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exprx--common-block trans_message">
                    <div class="eprx--block__header light_gray">
                        <div class="eprx--header__heading color_495057">
                            <div class="row">
                                <div class="col-1">SegID</div>
                                <div class="col-4">SegName</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <ngb-accordion [closeOthers]="true" activeIds="ngb-panel-0">
                                <ngb-panel [id]="'list0'">
                                    <ng-template ngbPanelTitle>
                                        <div class="row">
                                            <div class="col-1">0</div>
                                            <div class="col-4">Header</div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                            <table class="table table-bordered">
                                                <thead class="thead-light">
                                                    <tr>
                                                        <th scope="col">Seg ID</th>
                                                        <th scope="col">Field ID</th>
                                                        <th scope="col">Field Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let lstSegValue of headerSeg; let i = index">
                                                    <tr>
                                                        <th scope="row"> 0 </th>
                                                        <td> {{lstSegValue?.FieldID}} </td>
                                                        <td> {{lstSegValue?.FieldValue}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel [id]="'list'+lstsegName?.SegID"
                                    *ngFor="let lstsegName of viewTransmData?.lstSegmentName; let i = index">
                                    <ng-template ngbPanelTitle>
                                        <div class="row">
                                            <div class="col-1">{{lstsegName?.SegID}}</div>
                                            <div class="col-4">{{lstsegName?.SegName}}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                            <table class="table table-bordered">
                                                <thead class="thead-light">
                                                    <tr>
                                                        <th scope="col">Seg ID</th>
                                                        <th scope="col">Field ID</th>
                                                        <th scope="col">Field Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let lstSegValue of viewTransmData?.lstSegmentValue; let i = index">
                                                    <tr *ngIf="lstsegName?.SegID === lstSegValue?.SegID">
                                                        <th scope="row"> {{lstSegValue?.SegID}} </th>
                                                        <td> {{lstSegValue?.FieldID}} </td>
                                                        <td> {{lstSegValue?.FieldValue}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="initiateTransmitRxOnly()"
            appShortcutKey InputKey="t"
            *ngIf="isNotFromTrans === 'true' && viewTransmData && !viewTransmData?.ErrorMessage"
        >
            <b>T</b> Transmit
        </button>
        <button footer *ngIf="viewTransmData && !viewTransmData?.ErrorMessage" class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="c"><b>C</b> Close</button>


            <h4 header *ngIf="viewTransmData?.ErrorMessage"> Error Message </h4>
            <span body *ngIf="viewTransmData?.ErrorMessage color_red">
                {{viewTransmData?.ErrorMessage}}
            </span>
            <button *ngIf="viewTransmData?.ErrorMessage" footer class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="c"><b>C</b> Close</button>


    </app-custom-modal>

<!-- </ng-template> -->
