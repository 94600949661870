export class GroupTransmissionFilters {
    Filter: string = null;
    Fromdate: Date = null;
    Todate: Date = null;
    SearchText: string = null;
    PageNumber: number = null;
    PageSize: number = null;
}

export class RxTransmisParams {
    rxId: number = null;
    PrescRefillId: number = null;
    patientId: number = null;
    TransType: string = null;
    Ins_Code: string = null;
    BillAs: string = null;
    PrescNum: any = null;
    BillStatusId: any = null;
    InsuPlanId: any = null;
    InsuCarrierId: any = null;
    DrugTypeId: any = null;
    InsuranceId: any = null;
    RepriceSetting: any = null;
}
