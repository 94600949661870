<ng-template #MISSINGFIELDS let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 header>
            Please Select Missing Values
            {{
                drugMissingForm?.value?.DrugName
                    ? "for " + drugMissingForm?.value?.DrugName
                    : ""
            }}
        </h4>
        <button
            header
            type="button"
            [tabindex]="-1"
            class="close"
            aria-label="Close"
            (click)="closeModal('cancel')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="showDrugDescription">
                <eprx-select
                    [LabelText]="'Dosage Form Description'"
                    [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmDescId'"
                    [AutoFocus]="true"
                    [ShowLabel2]="true"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DoseFrmDesc"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm?.controls['DoseFrmDescId']?.errors
                    "
                    [IsRequired]="false"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Dosage Form Code'"
                    [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmCodeId'"
                    [AutoFocus]="true"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DoseFrmCode"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm?.controls['DoseFrmCodeId']?.errors
                    "
                    [IsRequired]="showDrugDescription"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Drug Strength Qualifier'"
                    [PlaceHolder]="'Drug Strength Qualifier'"
                    [ControlName]="'DrugStrQualId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.DrugStrQual"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm?.controls['DrugStrQualId']?.errors
                    "
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Potency Unit Code'"
                    [PlaceHolder]="'Potency Unit Code'"
                    [ControlName]="'PotencyUnitCodeId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.PotencyUnitCode"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm?.controls['PotencyUnitCodeId']?.errors
                    "
                    [IsRequired]="true"
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select
                    [LabelText]="'Quantity Qualifier'"
                    [PlaceHolder]="'Quantity Qualifier'"
                    [ControlName]="'QtyQualId'"
                    [FormGroupName]="drugMissingForm"
                    [List]="dropdownData?.QtyQual"
                    [ShowLabel2]="true"
                    [BindLabel2]="'Name'"
                    [BindLabel]="'Code'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Id'"
                    [InputErrors]="
                        drugMissingForm?.controls['QtyQualId']?.errors
                    "
                    [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="formSubmitted"
                >
                </eprx-select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="hotkey_success"
            appShortcutKey
            [AltKey]="'s'"
            (click)="saveDrugFields()"
        >
            <span>S</span> Save
        </button>
        <button
            class="hotkey_success"
            appShortcutKey
            [AltKey]="'c'"
            (click)="closeModal('cancel')"
        >
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>
