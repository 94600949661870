import { RxUtils } from "src/app/utils";
import { Subscription } from "rxjs/internal/Subscription";
import { PreviousRouteService } from "src/app/services";
import { CommonService } from "./../../../services/common.service";
import { DrugData } from "./../../../models/edit-drug.model";
import { DrugService } from "./../../../services/drug.services";
import { Component, OnInit, HostListener } from "@angular/core";
import { Drug, DrugInteraction } from "../../../models";
import { ActivatedRoute, Router } from "@angular/router";
import { EditDrugService } from "../../../services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-drug-notes-log",
    templateUrl: "./drug-notes-log.component.html"
})
export class DrugNotesLogComponent implements OnInit {
    drugInfo: Drug;
    drugdata: DrugData;
    drugName: string;
    drugId: number;
    drugInteractions: DrugInteraction;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    canDeactivateRoute = false;


    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.routeBackToPrevScreen();
            }
        }
    }

    constructor(
        private _actvRoute: ActivatedRoute,
        private _drgSvc: EditDrugService,
        private _commonSvc: CommonService,
        private _route: Router,
        private _rxutils: RxUtils,
        private _modalService: NgbModal
    ) {
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (params && params.fm) {
                    this.routeFrom = params.fm;
                }
                if (params && params.focus) {
                    if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                        this.focusOnDU = params.focus;
                    }
                }
            }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(params => {
            this.drugId = +params["pid"];
        }));
        this._commonSvc.getDrugInfo(this.drugId).subscribe(resp => {
            this._commonSvc._drugInfo$.next(resp);
            this.drugdata = resp;
            this.drugInfo = resp["Drug"];
        });
    }

    ngOnInit() { }
    routeBackToPrevScreen() {
        this.canDeactivateRoute = true;
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    focusOnCancel(event) {
        if (document.getElementById("durgNotesCancel")) {
            document.getElementById("durgNotesCancel").focus();
        }
    }
}
