export const RegEx = {
    AlphaNumeric : "^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 <>~'.,-]+$",
   // Email : "^[a-z0-9._]{6,35}@[a-z0-9.]+\.[a-z]{2,63}$",
    Email: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$",
    Dob: "MM/dd/yyyy",
    Numeric : "^[0-9]+$",
    NumericExceptZero : "^[1-9][0-9]*$",
    IdNumeric : "^[0-9a-zA-Z ]+$",
    NotOnlySpecialChar: "^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 <>~'.,!@#$%^&*()-]+$",
    PhoneregEx: "^(?!0{10})[0-9]{10}$",
    Zipregex: "^[0-9]{5}$",
    NDCregex: "^(?!0{10})[0-9]{11}$",
    NoSpaces: "^\S*$",
    DoseQty: "^([0-9]{1,4}(\.\d{1,2})?|\.\d{2})$",
    userPwdValidation: "/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$",
    deaRegex: "[a-zA-Z]{2}[0-9]{7}$",
    npiRegex: "^[0-9]{10}$",
    spiRegex: "^[0-9]{13}$",
};
