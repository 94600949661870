import { ToolsE, AccCategoryE, InventoryE, SecurityManagerE, SettingsE, DocumentE, FAXE, FileMaintenance1E, VendorFileE } from "./../models/access-privs.enum";
import { AlertService, UserService } from "../services";
import { Router, CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { BasePrivGetter } from "./access-privs.guard";

@Injectable()
export class MergePatGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Tools, ToolsE.MergePatients, true);
        return reslt;
    }
}

@Injectable()
export class RecallDltRxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Tools, ToolsE.RecallDeletedRx, true);
        return reslt;
    }
}
@Injectable()
export class PriceChckGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Tools, ToolsE.PriceCheck, true);
        return reslt;
    }
}

@Injectable()
export class DDIChckGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Tools, ToolsE.DDICheck, true);
        return reslt;
    }
}

@Injectable()
export class BsnIntelligenceGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Tools, ToolsE.BusinessIntelligence, true);
        return reslt;
    }
}

@Injectable()
export class InventoryGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Document, DocumentE.Inventory, true);
        return reslt;
    }
}

@Injectable()
export class BucketGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Inventory, InventoryE.Bucket, true);
        return reslt;
    }
}

@Injectable()
export class EnterInventoryGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Inventory, InventoryE.EnterInventoryReceived, true);
        return reslt;
    }
}

@Injectable()
export class PhysicalInvGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Inventory, InventoryE.PhysicalInventory, true);
        return reslt;
    }
}

@Injectable()
export class InventoryRecvGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Inventory, InventoryE.InventoryReceived, true);
        return reslt;
    }
}

@Injectable()
export class InventoryTypevGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Inventory, InventoryE.InventoryTransactionType, true);
        return reslt;
    }
}

@Injectable()
export class VendorFileGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(FileMaintenance1E.VendorFile, VendorFileE.AllowChangingInventoryBucket, true);
        return reslt;
    }
}

@Injectable()
export class EDIGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Inventory, InventoryE.EDI832PriceUpdateHistory, true);
        return reslt;
    }
}

@Injectable()
export class SecurityManagerGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Settings, SettingsE.SecurityManager, true);
        return reslt;
    }
}

@Injectable()
export class UsersGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.SecurityManager, SecurityManagerE.Users, true);
        return reslt;
    }
}

@Injectable()
export class RolesGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.SecurityManager, SecurityManagerE.Roles, true);
        return reslt;
    }
}

@Injectable()
export class UserHistGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.SecurityManager, SecurityManagerE.UsersLoginHistory, true);
        return reslt;
    }
}

@Injectable()
export class FaxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Document, DocumentE.Fax, true);
        return reslt;
    }
}

@Injectable()
export class FollowupRefillFax extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Fax, FAXE.RefillRequestFollowUp, true);
        return reslt;
    }
}

@Injectable()
export class FaxLogGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Fax, FAXE.FaxLog, true);
        return reslt;
    }
}

@Injectable()
export class IncomingFaxGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Fax, FAXE.IncomingFax, true);
        return reslt;
    }
}

@Injectable()
export class BatchedFaxesGuard extends BasePrivGetter implements CanActivate {
    constructor(_userServ: UserService, _router: Router, _alertServ: AlertService) {
        super(_userServ, _router, _alertServ);
    }

    canActivate() {
        const reslt = super.validateAuth(AccCategoryE.Fax, FAXE.BatchedFaxes, true);
        return reslt;
    }
}
