import { PatFamily, PatientMessageSettings } from "./../models/family-info.model";
import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import {
    Patient,
    Person,
    Gender,
    Email,
    Address,
    State,
    Lang,
    SplProgram,
    PatSetting,
    InsuCarrier,
    Organization,
    InsuPlan,
    InsuCards,
    RelationCat,
    CoPayCat,
    IdProof,
    Drug,
    OtherAllergy,
    GSDDAllergy,
    City,
    Insurances,
    Allergies,
    PatientSplPrograms,
    Family,
    FamilyInsurance,
    FamilyAddress,
    FamilyPhone,
    FamilyInsuGroup,
    FamilyInsuCard,
    PatientChartNums,
    PatientInsurances,
    PatPricings,
    Facility,
    InsuGroups,
    PatCustomCat,
    PatientInfo,
    Phone,
    PaginationModel
} from "../models";
import { CommonService, PatientService } from "../services";
import { PatientStore } from "../store";
import { CommonUtil } from ".";
import * as moment from "moment";
import { PerPrefContact } from "../models/patient-Info.model";
import { setMaxListeners } from "process";

@Injectable({
    providedIn: "root"
})
export class PatientInfoUtil {
    paginationModel: PaginationModel = new PaginationModel();
    [x: string]: any;
    patinsuceFilters = {
        patientid: null,
        PageNumber: this.paginationModel.pageNumber,
        PageSize: this.paginationModel.pageSize,
        OrderByColumn: "InsurerCode",
        SortDirection: "ASC",
        InsuId: null
    };
    tenatDetails$: any;

    constructor(
        private _fb: FormBuilder,
        private _commonServ: CommonService,
        private _patStore: PatientStore,
        private _commonUtil: CommonUtil,
        private _patientService: PatientService
    ) { }

    async getPatientInsurances(pid, patInfo?: any, latestPatInfo?: any, insId?, pgNo?, pgS?, ord?, sorDir?) {
        this.patinsuceFilters.patientid = pid;
        this.patinsuceFilters.InsuId = insId;
        this.patinsuceFilters.PageNumber = pgNo ? pgNo : 1;
        this.patinsuceFilters.PageSize = pgS ? pgS : 30;
        this.patinsuceFilters.OrderByColumn = ord ? ord : "InsurerCode";
        this.patinsuceFilters.SortDirection = sorDir ? sorDir : "ASC";
        if (patInfo) {
            latestPatInfo = latestPatInfo ? latestPatInfo : await this._patientService.getEditPatientInformation(pid).toPromise();
            patInfo["Insurances"] = latestPatInfo?.Insurances;
            patInfo["InsuCarrier"] = latestPatInfo?.InsuCarrier;
            patInfo["InsuCards"] = latestPatInfo?.InsuCards;
            patInfo["InsuGroups"] = latestPatInfo?.InsuGroups;
            patInfo["PatientInsurances"] = latestPatInfo?.["PatientInsurances"];
            this._patStore.storePatientInfo(patInfo);
        }
        const patInsuList = await this._commonServ.patientInsuranceList(this.patinsuceFilters).toPromise();
        this._patStore.storePatientInsuranceList(patInsuList);
        return patInsuList;
    }

    generatePatientInfoFG() {
        return this._fb.group({
            Patient: this._fb.group(new Patient()),
            Person: this._fb.group(new Person()),
            Genders: this._fb.group(new Gender()),
            Emails: this._fb.group(new Email()),
            Phones: this._fb.array([]),
            PerPrefContact: this._fb.array([]),
            Addresses: this._fb.group(new Address()),
            States: this._fb.group(new State()),
            Citites: this._fb.group(new City()),
            Languages: this._fb.group(new Lang()),
            Patient_SplProgs: [],
            SplPrograms: this._fb.group(new SplProgram()),
            PatSettings: this._fb.group(new PatSetting()),
            Insurances: this._fb.group(new Insurances()),
            InsuCarrier: this._fb.group(new InsuCarrier()),
            Organizations: this._fb.group(new Organization()),
            InsuPlans: this._fb.group(new InsuPlan()),
            InsuGroups: this._fb.group(new InsuGroups()),
            InsuCards: this._fb.group(new InsuCards()),
            RelationCatogiries: this._fb.group(new RelationCat()),
            CoPayCatogiries: this._fb.group(new CoPayCat()),
            IdProofs: this._fb.group(new IdProof()),
            Drugs: [],
            OtherAllergies: [],
            GSDDAllergies: this._fb.group(new GSDDAllergy()),
            PatAllergies: [],
            // PatientFamily: this._fb.group(new PatientFamily()),
            PatientSplPrograms: this._fb.group(new PatientSplPrograms()),
            Family: this._fb.group(new Family()),
            FamilyInsurance: this._fb.group(new FamilyInsurance()),
            FamilyAddress: this._fb.group(new FamilyAddress()),
            FamilyPhone: this._fb.group(new FamilyPhone()),
            FamilyInsuGroup: this._fb.group(new FamilyInsuGroup()),
            FamilyInsuCard: this._fb.group(new FamilyInsuCard()),
            PatientChartNums: this._fb.group(new PatientChartNums()),
            IsForceAllergySelection: [true],
            PatientInsurances: this._fb.group(new PatientInsurances()),
            PatPricings: this._fb.group(new PatPricings()),
            RxCount: null,
            PatientDiagnosis: [],
            Facility: this._fb.group(new Facility()),
            PatCustomCat: this._fb.group(new PatCustomCat()),
            PatFamily: this._fb.group(new PatFamily()),
            PatientMessageSettings : this._fb.group(new PatientMessageSettings()),
            SubscriptionTempId: null,
            SubscriptionMessage: null,
            isResendSubscription: false,
            IsSendSMS: false,
            OldInsuCardObject: this._fb.group(new InsuCards())
        });
    }

    patchPatientInfo(patientInfo: PatientInfo, patientFG: FormGroup) {
        for (const key in patientInfo) {
            if (key && patientInfo[key]) {
                if (
                    [
                        "PatAllergies",
                        "PatientDiagnosis",
                        "Drugs",
                        "OtherAllergies"
                    ].indexOf(key) > -1
                ) {
                    this._commonUtil.generateFA(key, patientInfo[key], patientFG);
                } else if (key === "PerPrefContact") {
                    this.addDeafaultExtraPhone(patientInfo[key], patientFG);
                } else if (key === "Phones") {
                    this.addDefaultPhones(patientInfo[key], patientFG);
                } else if (["PatientInsurances"].indexOf(key) > -1 && patientInfo[key]["Id"]) {
                    this._commonUtil.generateFA(key, patientInfo[key], patientFG);
                } else if (patientInfo[key] && patientFG.controls[key]) {
                    patientFG.controls[key].patchValue(
                        patientInfo[key]
                    );
                }
            } else if (["PerPrefContact", "Phones"].indexOf(key) > -1) {
                if (key === "PerPrefContact") {
                    this.addDeafaultExtraPhone(patientInfo[key], patientFG);
                } else if (key === "Phones") {
                    this.addDefaultPhones(patientInfo[key], patientFG);
                }
            }
        }
        if (patientInfo && patientInfo.Addresses) {
            patientInfo.Addresses ? this.patchCityAndStateValue(patientInfo.Addresses, patientFG) : this.makeCityAndStateNull();
        }
        // this.saveDefaultAllergy(patientFG);
        patientFG.controls["Emails"].patchValue({
            IsActive: true,
            eMailCatId: 1
        });
    }

    saveDefaultAllergy(patientFG) {
        const dftlAllergydata = this._commonServ.getSetttingValue("PatientSettings", "PAT_ALLERGY");
        if (dftlAllergydata) {
            const systemData = this._commonServ.systemData$["source"]["value"];
            const dftlAllergy = systemData["Allergies"].find(n => n.AllergyName === dftlAllergydata);
            if (dftlAllergy) {
                if (patientFG.value["PatAllergies"] && patientFG.value["PatAllergies"].length > 0 && dftlAllergy["ClassId"] !== 0 &&
                    patientFG.value["PatAllergies"].find(val => val["AllergyClassID"] === dftlAllergy["ClassId"]) === -1) {
                    const data = {
                        AllergyClassID: dftlAllergy["ClassId"],
                        AllergyName: dftlAllergy["AllergyName"],
                        AllergyDescription: dftlAllergy["AllergyName"]
                    };
                    patientFG.value["PatAllergies"].push(data);
                    this._commonUtil.generateFA("PatAllergies", patientFG.value["PatAllergies"], patientFG);
                } else if (!(patientFG.value["PatAllergies"] && patientFG.value["PatAllergies"].length > 0)) {
                    const data = {
                        AllergyClassID: dftlAllergy["ClassId"],
                        AllergyName: dftlAllergy["AllergyName"],
                        AllergyDescription: dftlAllergy["AllergyName"]
                    };
                    this._commonUtil.generateFA("PatAllergies", [data], patientFG);
                }
            }
        }
    }

    makeCityAndStateNull() {
        this._patStore.storePatState(null);
        this._patStore.storePatCity(null);
    }

    addDefaultPhones(values, patientInfoFG: FormGroup) {
        const phoneCatIds = [3, 2, 1];
        if (values && values.length > 0) {
            values.map(val => {
                const index = phoneCatIds.indexOf(val["PhoneCatId"]);
                phoneCatIds.splice(index, 1);
            });
        }
        values = values ? values : [];
        if (phoneCatIds && phoneCatIds.length > 0) {
            phoneCatIds.map(val => {
                const phn = new Phone();
                phn.PhoneCatId = val;
                phn.IsActive = true;
                values.push(phn);
            });
        }
        this._commonUtil.generateFA("Phones", values, patientInfoFG);

    }

    addDeafaultExtraPhone(values, patientInfoFG: FormGroup) {
        const defaultPhones = ["Telephone", "Mobile", "Work Number"];
        if (values && values.length > 0) {
            values.map(phone => {
                phone["Action"] = "U";
                const index = defaultPhones.indexOf(phone["ContactType"]);
                defaultPhones.splice(index, 1);
            });
        }
        values = values ? values : [];
        if (defaultPhones && defaultPhones.length > 0) {
            defaultPhones.map(val => {
                const phn = new PerPrefContact();
                phn.ContactType = val;
                phn.IsActive = true;
                phn.Action = "A";
                phn.PatientId = patientInfoFG.value["Patient"]["Id"];
                values.push(phn);
            });
        }
        this._commonUtil.generateFA("PerPrefContact", values, patientInfoFG);
    }

    patchPatAddDefaultVal(formGroupName) {
        if (formGroupName.controls.Phones && formGroupName.controls.Phones.length < 3) {
            this.patchDefaultValues(formGroupName);
        }
        this.getZipFromTenat(formGroupName);
        this.saveDefaultAllergy(formGroupName);
    }

    patchDefaultValues(formGroupName) {
        this.addDefaultPhones(null, formGroupName);
        formGroupName.controls["Person"].patchValue({
            ActiveStatusId: 1
        });
        const defPatRefillRem = this._commonServ.getSetttingValue("RxSettings", "DEF_PAT_REF_REM");
        formGroupName.controls["PatSettings"].patchValue({
            IsRefillRemSet: (defPatRefillRem === "1") ? true : false,
            IsEZCapReqd: false
        });
    }

    updateExtraPhones(formGroupName, isPrefered?: any) {
        const Phones = formGroupName.value["Phones"];
        const extraPhones: any = formGroupName.controls["PerPrefContact"];
        if (isPrefered) {
            this.patchPrefToHV(formGroupName, isPrefered);
        }
        Phones.map(phone => {
            const phoneType = phone["PhoneCatId"] === 3 ? "Telephone" : phone["PhoneCatId"] === 2 ? "Mobile" : "Work Number";
            const index = extraPhones["value"].findIndex(val => val["ContactType"] === phoneType);
            if (index > -1) {
                extraPhones.controls[index].patchValue({
                    ContactNumber: phone["xPhone"],
                    IsPreferred: (isPrefered && phone["PhoneCatId"] === isPrefered) ? true : false
                });
            }
        });
    }

    patchPrefToHV(formGroupName, PhoneCatId?: any) {
        const Phones = formGroupName.value["Phones"];
        let result = PhoneCatId ? PhoneCatId : 2;
        if (Phones && Phones.length) {
            const PrefS = Phones.find(v => v["PhoneCatId"] === PhoneCatId);
            if (PrefS && PrefS["xPhone"] && PrefS["xPhone"].length > 0) {
                result = PhoneCatId;
            } else {
                const Mobile = Phones.find(v => v["PhoneCatId"] === 2);
                const pHone = Phones.find(v => v["PhoneCatId"] === 3);
                const wrNum = Phones.find(v => v["PhoneCatId"] === 1);
                if (Mobile && Mobile["xPhone"] && Mobile["xPhone"].length > 0) {
                    result = 2;
                } else if (pHone && pHone["xPhone"] && pHone["xPhone"].length > 0) {
                    result = 3;
                } else if (wrNum && wrNum["xPhone"] && wrNum["xPhone"].length > 0) {
                    result = 1;
                } else {
                    result = 2;
                }
            }
        }
        return result;
    }

    getPhonesPrefered(values, formGroup) {
        let preferred = 2;
        if (values && values.length > 0) {
            const prfdContact = values.find(val => val["IsPreferred"] === true);
            if (prfdContact) {
                const prfd = ["Work Number", "Mobile", "Telephone","Legal Representative","Emergency Contact ","Parent/Guardian","Other"].indexOf(prfdContact["ContactType"]);
                preferred = prfd >= 0 ? this.patchPrefToHV(formGroup, prfdContact["ContactType"] === "Work Number" ? 1 :
                    prfdContact["ContactType"] === "Mobile" ? 2 : prfdContact["ContactType"] === "Telephone" ? 3 : prfdContact["ContactType"] === "Emergency Contact " ? 4 : prfdContact["ContactType"] === "Parent/Guardian" ? 5 : 6) : null;
            } else {
                preferred = 2;
            }
        }
        return preferred;
    }

    getZipFromTenat(formGroupName) {
        if (!formGroupName.value.Addresses.ZipCode) {
            this.tenatDetails$ = this._commonServ.newTenatInfo$;
            const data = this.tenatDetails$["source"]["value"];
            if (data && data["PharmacyDetails"]) {
                this.patchCityAndStateValue(data["PharmacyDetails"], formGroupName);
                formGroupName.controls["Addresses"].patchValue({
                    ZipCode: data["PharmacyDetails"]["ZipCode"]
                });
            } else {
                this.makeCityAndStateNull();
            }
        }
    }

    setDefndActvStatus(formGroupName, mode: string) {
        if (formGroupName.value["PatientSplPrograms"]) {
            formGroupName.controls["PatientSplPrograms"].patchValue({ IsActive: true });
        }
        formGroupName.controls["Addresses"].patchValue({
            IsActive: true,
            CountryId: 1
        });
        formGroupName.controls["Insurances"].patchValue({ ActiveStatusId: 1 });
        formGroupName.controls["InsuCards"].patchValue({ IsActive: true });
        if (formGroupName.value["PatientChartNums"] && formGroupName.value["PatientChartNums"]["ChartNum"]) {
            formGroupName.controls["PatientChartNums"].patchValue({
                PatientId: formGroupName.value["Patient"]["Id"],
                IsActive: true,
                ChartAssigneeId: 1
            });
        }
    }

    async patchPatientFrmErx(erxPatValues, formGroupName) {
        const newDate = moment(new Date(erxPatValues.PatBirthDt)).format("MM/DD/YYYY").toString();
        formGroupName.controls.Person.patchValue({
            LastName: erxPatValues.PatLastName,
            FirstName: erxPatValues.PatFirstName,
            MiddleName: erxPatValues.MiddleName,
            BirthDt: newDate,
            GenderId: erxPatValues.PatGender === "M" ? 2 : erxPatValues.PatGender === "F" ? 3 : 1
        });
        formGroupName.controls.Emails.patchValue({
            xeMail: erxPatValues.PatMail
        });
        this.patchDefaultValues(formGroupName);
        this.saveDefaultAllergy(formGroupName);
        const Phones = formGroupName.get("Phones") as FormArray;
        if (erxPatValues.PatPhone) {
            Phones.at(0).patchValue({ xPhone: erxPatValues.PatPhone });
        }
        formGroupName.controls.Addresses.patchValue({
            ZipCode: erxPatValues.PatZip,
            AddressLine1: erxPatValues.PatAddrL1,
            AddressLine2: erxPatValues.PatAddrL2
        });
        if (erxPatValues.PatZip &&
            erxPatValues.PatZip.trim() && erxPatValues.PatZip !== " ") {
            if (erxPatValues.PatZip.length < 5) {
                const dataInfo = {
                    StateName: erxPatValues.PatState,
                    CityName: erxPatValues.PatCity
                };
                const zipMaster = await this._commonServ.getZipCodeByStateCity(dataInfo).toPromise();
                if (zipMaster === null) {
                    formGroupName.controls.Addresses.patchValue({
                        ZipCode: erxPatValues.PatZip,
                        StateName: erxPatValues.PatState,
                        CityName: erxPatValues.PatCity
                    });
                } else {
                    formGroupName.controls.Addresses.patchValue({ ZipCode: zipMaster["ZipCode"] });
                    this.patchCityAndStateValue(zipMaster, formGroupName);
                }
            } else {
                const zipMaster = await this._commonServ.getStateCityDataByZip(erxPatValues.PatZip).toPromise();
                this.patchCityAndStateValue(zipMaster, formGroupName);
            }
        }
    }

    patchPatDataFrmFamily(data, formGroupName) {
        const cntrls = ["Insurances", "InsuCarrier", "InsuCards", "Addresses", "InsuGroups"];
        if (data) {
            cntrls.map(cntrlName => {
                if (cntrlName === "Insurances"  &&  data[cntrlName]) {
                    data[cntrlName]["InsuCardId"] = null;
                }
                if (data && data[cntrlName]) {
                    data[cntrlName]["Id"] = null;
                }
                if (data[cntrlName]) {
                    formGroupName.controls[cntrlName].patchValue(data[cntrlName]);
                }
            });
            formGroupName.controls["PatFamily"].patchValue(data["PatFamily"] ? data["PatFamily"] : { Name: data["Person"]["LastName"] });
            formGroupName.controls.Person.patchValue({ LastName: data["Person"]["LastName"] });
            const Phones = formGroupName.get("Phones") as FormArray;
            if (!data["Phones"] || (data["Phones"] && (!data["Phones"][0]["PhoneCatId"]
                || data["Phones"][0]["PhoneCatId"] === 0))) {
                const tele: any = Phones.controls[0];
                tele.controls["PhoneCatId"].patchValue(3);
            } else {
                data["Phones"][0]["Id"] = null;
                Phones.controls[0].patchValue(data["Phones"][0]);
                const tele: any = Phones.controls[0];
                tele.controls["PhoneCatId"].patchValue(3);
            }
            this.patchDefaultValues(formGroupName);
        }
    }

    patchPatientFrmFamily(data, formGroupName) {
        const cntrls = { Addresses: "FamilyAddress", PatFamily: "PatFamily", Insurances: "FamilyInsurance", InsuCards: "FamilyInsurance" };
        Object.keys(cntrls).map(patCntrl => {
            if (data[cntrls[patCntrl]][0]) {
                formGroupName.controls[patCntrl].patchValue(data[cntrls[patCntrl]][0]);
            }
        });
        if (data["FamilyAddress"][0] && data["FamilyAddress"][0]["ZipCode"]) {
            this.patchCityAndStateValue(data["FamilyAddress"][0], formGroupName);
        } else {
            this.getZipFromTenat(formGroupName);
        }
        formGroupName.controls.Person.patchValue({ LastName: data["PatFamily"][0].Name });
        formGroupName.controls.InsuCarrier.patchValue({ InsurerCode: data["FamilyInsuCarrier"][0].Name });
        const Phones = formGroupName.get("Phones") as FormArray;
        if (!data["FamilyPhone"][0]["PhoneCatId"] || data["FamilyPhone"][0]["PhoneCatId"] === 0) {
            const tele: any = Phones.controls[0];
            tele.controls["PhoneCatId"].patchValue(3);
        } else {
            Phones.controls[0].patchValue(data["FamilyPhone"][0]);
            const tele: any = Phones.controls[0];
            tele.controls["PhoneCatId"].patchValue(3);
        }
        this.patchDefaultValues(formGroupName);
    }

    patchPatFrmGlobalSearch(data, formGroupName) {
        this.patchDefaultValues(formGroupName);
        const Phones = formGroupName.get("Phones") as FormArray;
        const person = formGroupName.get("Person") as FormGroup;
        if (/^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})$/.test(data)) {
            person.controls["BirthDt"].patchValue(data ? data : null);
        } else if (/^([0-9]{10})$/.test(data)) {
            Phones.at(0).patchValue({ xPhone: data });
        } else if (data.indexOf("/") === -1) {
            const patientNames = data.split(",");
            if (patientNames[0] !== undefined && patientNames[0] != null) {
                person.controls["LastName"].patchValue(patientNames[0] ? patientNames[0].trim() : null);
            }
            if (patientNames[1] !== undefined && patientNames[1] != null) {
                person.controls["FirstName"].patchValue(patientNames[1] ? patientNames[1].trim() : null);
            }
        }
        return formGroupName;
    }

    async patchCityAndStateValue(data, formGroup, type?: any) {
        if (!data) {
            this.makeCityAndStateNull();
        } else {
            const resp = await this._commonUtil.patchStateAndCity(data, formGroup, type ? type : null, "Patient");
            if (!type || type === "State") {
                this._patStore.storePatState(resp["state"]);
            }
            this._patStore.storePatCity(resp["city"]);
        }
    }
    get340BInsurance(patientInfo) {
        const insId = +this._commonServ.getSetttingValue("ThreeFourtyBSettings", "Ins_Code_340BSettings");
        const patient = patientInfo["Patient"];
        let Insu340B: any;
        const primeInsurance = patientInfo["Insurances"];
        if (patient  && primeInsurance.Id && primeInsurance.InsurerId) {
            // get the data for 340b ins.
            if (primeInsurance.InsurerId !== insId) {
                const InsuranceList = this._commonServ.insuranceData$["source"]["value"];
                Insu340B = InsuranceList && InsuranceList.length ? InsuranceList.find(ins => ins.InsuCarrierId === insId) : null;
            }
        }
        return Insu340B;

    }
    getPatientAddress(rxPatientModel) {
        let address = "";
        if (rxPatientModel) {
            if (rxPatientModel?.address1)
                address = address + rxPatientModel?.address1 + ", ";;
             if (rxPatientModel?.address2)
                address = address + rxPatientModel?.address2 + ", ";
             if (rxPatientModel?.city && rxPatientModel?.city !== '###')
                address = address + rxPatientModel?.city+ ", ";;
             if (rxPatientModel?.state && rxPatientModel?.state !== '###')
                address = address + rxPatientModel?.state + ", ";;
             if (rxPatientModel?.zipcode && rxPatientModel?.zipcode !== '###')
                address = address + rxPatientModel?.zipcode;
        }
        return address;
    }
    getPatientAllergies(rxPatientModel) {
        if(rxPatientModel?.patallergies)
         return  rxPatientModel.patallergies.map(function(a) {return a.Name;});
        else 
            return [];
    }
    getPatientFullName(rxPatientModel) {
        if(rxPatientModel ) {
            return rxPatientModel.fullname.replace('_',", ").toUpperCase();
        } else 
            return  "";
    }
}

