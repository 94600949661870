<ng-template #QuickVer let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="IsQuickForRxVer">Rx Verification</h4>
        <!-- <h4 class="modal-title" *ngIf="!IsQuickForRxVer">Quick Verification</h4> -->
        <button type= button [tabindex]="-1" class="close quick-close-button" aria-label="close" (click)="openCancelPopUP()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div  [ngClass]="showErxDocs ? 'modal-body Quick-verificaition_eRx' : 'modal-body Quick-verificaition'">
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                <div class="exprx--common-block erx--body insurance--body margin-bottom-1Halfrem">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Prescriber Information</span>
                                    </div>
                                    <div class="col-md-2 text-right">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <label>Name</label>
                                    <div [title]="(rxInfoData?.Prescriber?.prescriberlastname + ',' + rxInfoData?.Prescriber?.prescriberfirstname) | uppercase" class="label--data">{{rxInfoData?.Prescriber?.prescriberlastname}},&nbsp; {{rxInfoData?.Prescriber?.prescriberfirstname}}</div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label>NPI#</label>
                                    <div class="label--data">{{rxInfoData?.Prescriber?.prescribernpinum ? rxInfoData?.Prescriber?.prescribernpinum:"--"}}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label class="align-label">DEA#</label>
                                    <div class="label--data">{{rxInfoData?.Prescriber?.prescriberdeanum ? _formatsUtil.getDeaFormat(rxInfoData?.Prescriber?.prescriberdeanum):"--"}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exprx--common-block erx--body insurance--body margin-bottom-1Halfrem">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Patient Information</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <label>Name</label>
                                    <div class="label--data">
                                        {{rxInfoData?.Patient?.lastname}},&nbsp;{{rxInfoData?.Patient?.firstname}}
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>DOB</label>
                                    <div class="label--data">{{rxInfoData?.Patient?.dob ? (rxInfoData?.Patient?.dob
                                        | date:'MM/dd/yyyy') :"--"}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exprx--common-block erx--body insurance--body margin-bottom-1Halfrem">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Rx Information</span>
                                    </div>
                                    <div class="col-md-2 text-right">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label>Drug Name</label>
                                        <div class="label--data">{{rxInfoData?.Drug?.drugname + '' + rxInfoData?.Drug?.strength + '' + rxInfoData?.Drug?.drugformname}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label>Fill Date</label>
                                        <div class="label--data">{{rxInfoData?.PrescReFill?.FillDtTm ? (rxInfoData?.PrescReFill?.FillDtTm
                                            | date:'MM/dd/yyyy') : '--'}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label>Rx#</label>
                                        <div class="label--data">{{rxInfoData?.Prescription?.PrescNum ? rxInfoData?.Prescription?.PrescNum
                                            : '--'}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label>NDC</label>
                                        <!-- <div class="label--data">{{ rxInfoData?.Drug?.ndc ? (rxInfoData?.Drug?.ndc | mask: "AAAA0-0000-00") : ""}} -->
                                        <div class="label--data">{{this._formatsUtil.getNDCFormat(rxInfoData?.Drug?.ndc)}}
                                        </div>
                                    </div>


                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label>Days</label>
                                        <div class="label--data">{{rxInfoData?.PrescReFill?.SupplyDays ? rxInfoData?.PrescReFill?.SupplyDays
                                            : '--'}}</div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <label>Quantity</label>
                                        <div class="label--data">{{rxInfoData?.PrescReFill?.DispQty ? rxInfoData?.PrescReFill?.DispQty
                                            : '--'}}</div>
                                    </div>

                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label class="custom--label padding-0">Drug Image</label>
                                        <div class="col-sm-12 padding-0">
                                            <img src="assets/dist/img/tablate.png" class="tablate--preview" style="max-width:100%;">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="exprx--common-block erx--body insurance--body">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Billing Information</span>
                                    </div>
                                    <div class="col-md-2 text-right">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Ins. Type</label>
                                    <div class="label--data">{{billToInsu?.insuType ? billToInsu?.insuType
                                        : '--'}}</div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Status</label>
                                    <span *ngIf="rxInfoData?.RxBill" class="float-right">
                                        <i *ngIf="rxInfoData?.RxBill?.StatusId==1" class="far fa-exclamation-circle text-orange"></i>
                                        <i *ngIf="rxInfoData?.RxBill?.StatusId==2" class="far fa-check text-success"></i>
                                    </span>
                                    <div class="label--data">{{rxInfoData?.RxBill?.StatusId ? (rxInfoData?.RxBill?.StatusId==1
                                        ? 'UNBILLED' : (rxInfoData?.RxBill?.StatusId==2 ? 'BILLED':''))
                                        : 'UNBILLED'}}</div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Cost</label>
                                    <div class="label--data">{{rxInfoData?.Drug?.costpack ? (rxInfoData?.Drug?.costpack | currency: "$":"symbol":"1.3-3")
                                        : "$0.000" }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                <div class="row">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                        <div class="exprx--common-block erx--body insurance--body" *ngIf="IsQuickForRxVer">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Rx Verification</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-12">
                                            <eprx-select [AutoFocus]="true" [LabelText]="'Rx Received By'" [ControlName]="'recvBy'"
                                                [FormGroupName]="quickFG" [PlaceHolder]="''" [BindLabel]="'UserId'" [BindLabel2]="'Name'"
                                                [BindLabel3]="'Role'" [LabelTitle1]="'USER ID'"
                                                [IsRequired]="IsQuickForRxVer" [LabelTitle2]="'NAME'"
                                                [LabelTitle3]="'ROLE'" [BindValue]="'UserId'" [LabelForId]="'AppUserId'"
                                                (TriggerSelectValue)="checkUserIsLogin('rxRec')" [List]="appUserData" [hasBindLabel3]="true"
                                                (TriggerOnEnterValue)="focustonext($event, 5)" [FormGroupInvalid]="formGroupInvalid"
                                                [IsFirstElem]="true"></eprx-select>
                                        </div>
                                        <div class="col-6">
                                            <label class="control-label col-sm-12 padding-0" [formGroup]="quickFG">
                                                <span> Verification Time </span>
                                                <input readonly type="text" name="userIdR" formControlName="recvTm"
                                                    class="form-control search--input__box" required tabindex="-1" placeholder="HH:mm" />
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <eprx-date-picker [LabelText]="'Received Date'" [ControlName]="'recvDt'" [PlaceHolder]="''"
                                                [IsRequired]="((isRxRequiredForOrginal && rxInfoData && (rxInfoData?.rxActnType === 'TransmitNow' || IsQuickForTransmit ||  rxInfoData?.rxActnType === 'TrnsmtPatent' || rxInfoData?.rxActnType === 'HoldRx')))?true:false"
                                                [FormGroupName]="quickFG" [RxSelectId]="5" [MaxDate]="minDate" [InputErrors]="recvDt?.errors"
                                                [ErrorDefs]="{required: 'Required'}" (keydown.enter)="focustonext($event, 'UserId')"
                                                [FormGroupInvalid]="formGroupInvalid" [MarkAsTouched]="recvDt?.touched">
                                            </eprx-date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <div class="exprx--common-block erx--body insurance--body" *ngIf="IsQuickForRxVer">
                                    <div class="eprx--block__header">
                                        <div class="eprx--header__heading">
                                            <div class="col-sm-12 padding-0">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <span>Observation</span>
                                                    </div>
                                                    <div class="col-md-2 text-right">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="eprx--block__content">
                                        <div class="col-12">
                                                <div class="row">
                                                        <table class="col-lg-12 col-md-12 col-xs-12 col-sm-12 table table-bordered">
                                                            <tbody>
                                                                <tr *ngIf="drugInteractions?.Allergies && drugInteractions?.AllergiesCount">
                                                                    <td><label>Allergy</label></td>
                                                                    <td class = "background_Red color_white cursor_pointer" (click)="getDrugAllergiesStatus()"
                                                                    [innerHTML]="drugAllergyData"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <label>Drug to Drug Interactions</label>
                                                                    </td>
                                                                    <td *ngIf="!drugToDrugInteractions1" >
                                                                        <span >
                                                                            No DDI detected
                                                                        </span>
                                                                    </td>
                                                                    <td (click)="getDrugToDrugInterationInfo()" *ngIf="drugToDrugInteractions1" class = "background_Red color_white cursor_pointer">
                                                                        <span>
                                                                                SEVERITY : {{drugToDrugInteractions1.SEVERITY}}-{{drugToDrugInteractions1.DRUGNAME}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <label>Duplicate Drug Therapy</label>
                                                                    </td>
                                                                    <td *ngIf="!(drugInteractions && drugInteractions.DuplicateDrugs && drugInteractions.DuplicateDrugs.length > 0)">
                                                                        <span >
                                                                            No Duplicate drug therapy detected
                                                                        </span>
                                                                    </td>
                                                                    <td (click)="getDuplicateDrugTherapyInfo()" *ngIf="(drugInteractions && drugInteractions.DuplicateDrugs && drugInteractions.DuplicateDrugs.length > 0)"class = "background_Red color_white cursor_pointer">
                                                                        <span>{{drugInteractions.DuplicateDrugs[0]?.DrugName | uppercase}}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="drugInteractions?.DiseaseContradication && drugInteractions?.DiseaseContradicationCount">
                                                                    <td>
                                                                        <label>Drug Disease Contraindication</label>
                                                                    </td>
                                                                    <td (click)="getDrugDeceaseContradictionInfo()" class = "background_Red color_white cursor_pointer">
                                                                        <span>Drug Disease Contraindication detected</span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="drugInteractions?.LifeStyle && drugInteractions.LifeStyle.length > 0">
                                                                    <td>
                                                                        <label>Drug Lifestyle</label>
                                                                    </td>
                                                                    <td class = "background_Red color_white cursor_pointer" (click)="getLifeStyle()">
                                                                        <span>
                                                                            {{drugInteractions.LifeStyle[0].LifestyleName}},{{drugInteractions.LifeStyle[0].SEVERITYTEXT}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="drugInteractions?.PregnancyLactationInfo && rxInfoData.Patient.Gender === 'Female'">
                                                                    <td>
                                                                        <label>Pregnancy Lactation</label>
                                                                    </td>
                                                                    <td (click)="getDrugPregnancyInfo()" class = "background_Red color_white cursor_pointer">
                                                                            <span>Pregnancy Lactation Alert</span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="drugInteractions?.AdverseReaction && drugInteractions.AdverseReaction.length > 0">
                                                                    <td>
                                                                        <label>Adverse Drug Reaction</label>
                                                                    </td>
                                                                    <td class = "background_Red color_white cursor_pointer" (click)="getAdverseReaction()">
                                                                        {{drugInteractions?.AdverseReaction[0].Severity}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                        </div>
                                    </div>
                                </div>

                        <!-- <div class="exprx--common-block erx--body insurance--body" *ngIf="!IsQuickForRxVer">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Drug Pick Verification</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-12">
                                            <eprx-select [LabelText]="'Drug Pick Verification Performed By'" [ControlName]="'drugBy'" [IsRequired]="isDpvRequired? true: false" [FormGroupName]="quickFG"
                                                [PlaceHolder]="''" [BindLabel]="'UserId'" [BindLabel2]="'Name'" [BindLabel3]="'Role'"
                                                [LabelTitle1]="'USER ID'" [LabelTitle2]="'NAME'" [LabelTitle3]="'ROLE'" [BindValue]="'UserId'"
                                                [LabelForId]="'UserId'" [List]="appUserData" [hasBindLabel3]="true" [InputErrors]="drugBy?.errors"
                                                [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid"
                                                (TriggerSelectValue)="checkUserIsLogin('drug')" (TriggerOnEnterValue) = "focustonext($event, 7)"></eprx-select>
                                        </div>
                                        <div [ngClass]="drugUserIsVerified ? 'col-6' : 'col-4'">
                                                <label class="control-label col-sm-12 padding-0" [formGroup]="quickFG">
                                                    <span> Verification Time </span>
                                                    <input readonly type="text" name="userIdU" formControlName="drugTm" class="form-control search--input__box" required
                                                        tabindex="-1" placeholder="HH:mm" />
                                                </label>
                                        </div>
                                        <div [ngClass]="drugUserIsVerified ? 'col-6' : 'col-5'">
                                            <eprx-date-picker *ngIf="!drugUserIsVerified" [LabelText]="'Verification Date'" [ControlName]="'drugDt'" [PlaceHolder]="''"
                                            [IsRequired]="isDpvRequired? true: false"
                                            [FormGroupName]="quickFG"
                                            [RxSelectId]="7" [MinDate]="minDate" [IsRequired]="true" [InputErrors]="drugDt?.errors" [ErrorDefs]="{required: 'Required'}" [MarkAsTouched]="drugDt?.touched"
                                                [FormGroupInvalid]="formGroupInvalid" (keydown.enter) = "focustonext($event, 'pwd')">
                                            </eprx-date-picker>
                                            <eprx-date-picker *ngIf="drugUserIsVerified" [LabelText]="'Verification Date'" [ControlName]="'drugDt'" [PlaceHolder]="''" [IsRequired]="true" [FormGroupName]="quickFG"
                                            [RxSelectId]="7" [MinDate]="minDate" [IsRequired]="true" [InputErrors]="drugDt?.errors" [ErrorDefs]="{required: 'Required'}" [MarkAsTouched]="drugDt?.touched"
                                                [FormGroupInvalid]="formGroupInvalid" (keydown.enter) = "focustonext($event, 'NAME')">
                                            </eprx-date-picker>
                                        </div>
                                        <div class="col-3" [formGroup]="quickFG" *ngIf="!drugUserIsVerified">
                                            <label class="control-label col-sm-12 padding-0">
                                                <eprx-input [RxSelectId]="'pwd'" [InputType]="'PASSWORD'" [LabelText]="'Password'" [PlaceHolder]="''" [IsRequired]="isDpvRequired? true: false"
                                                    [ControlName]="'drugPsw'" [FormGroupInvalid]="formGroupInvalid" [FormGroupName]="quickFG"
                                                    [MarkAsTouched]="quickFG['controls'].drugPsw?.touched" [ErrorDefs]="{required: 'Required', pattern: 'Invalid!'}"
                                                    (keydown.enter)="focustonext($event, 'NAME')" (TriggerOnBlur)="focustonext($event, 'NAME')">
                                                </eprx-input>
                                                <span class="help--text__danger" *ngIf="InvalidPassword2">Please enter correct password</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="exprx--common-block erx--body insurance--body" *ngIf="!IsQuickForRxVer">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>RPH Verification</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-12">
                                            <eprx-select [LabelText]="'Verified By Pharmacist'" [ControlName]="'rphBy'" [IsRequired]="isRPhRequired ? true : false" [FormGroupName]="quickFG"
                                                [PlaceHolder]="''" [BindLabel]="'UserId'" [BindLabel2]="'Name'" [BindLabel3]="'Role'"
                                                [LabelTitle1]="'USER ID'" [LabelTitle2]="'NAME'" [LabelTitle3]="'ROLE'" [BindValue]="'UserId'"
                                                [LabelForId]="'NAME'" [List]="pharmacistDet" [hasBindLabel3]="true" [InputErrors]="rphBy?.errors" (TriggerSelectValue)="checkUserIsLogin('rphBy')"  (TriggerOnEnterValue) = "focustonext($event, '6')"
                                                [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid"
                                                ></eprx-select>
                                        </div>
                                        <div [ngClass]="rphUserIsVerified ? 'col-6' : 'col-4'">
                                                <label class="control-label col-sm-12 padding-0" [formGroup]="quickFG">
                                                    <span> Verification Time </span>
                                                    <input readonly type="text" name="userId" formControlName="rphTm" class="form-control search--input__box" required
                                                        tabindex="-1" placeholder="HH:mm" />
                                                </label>
                                        </div>

                                        <div [ngClass]="rphUserIsVerified ? 'col-6' : 'col-5'">
                                            <eprx-date-picker *ngIf="!rphUserIsVerified"[LabelText]="'Verification Date'" [ControlName]="'rphDt'" [PlaceHolder]="''" [FormGroupName]="quickFG" [IsRequired]="isRPhRequired ? true : false"
                                            [RxSelectId]="6"    [MinDate]="minDate"  [InputErrors]="rphDt?.errors" [ErrorDefs]="{required: 'Required'}" [MarkAsTouched]="rphDt?.touched"
                                             [FormGroupInvalid]="formGroupInvalid" (keydown.enter) = "focustonext($event, 'pwd2')">
                                            </eprx-date-picker>
                                            <eprx-date-picker *ngIf="rphUserIsVerified" [LabelText]="'Verification Date'" [ControlName]="'rphDt'" [PlaceHolder]="''"  [FormGroupName]="quickFG" [IsRequired]="isRPhRequired ? true : false"
                                            [RxSelectId]="6"    [MinDate]="minDate" [InputErrors]="rphDt?.errors" [ErrorDefs]="{required: 'Required'}" [MarkAsTouched]="rphDt?.touched"
                                             [FormGroupInvalid]="formGroupInvalid" (keydown.enter) = "focustonext($event, 'okButton')">
                                            </eprx-date-picker></div>

                                        <div class="col-3" [formGroup]="quickFG" *ngIf="!rphUserIsVerified">
                                            <label class="control-label col-sm-12 padding-0">
                                                <eprx-input [RxSelectId]="'pwd2'" [InputType]="'PASSWORD'" [LabelText]="'Password'" [PlaceHolder]="''" [IsRequired]="isRPhRequired ? true : false"
                                                    [ControlName]="'rphPsw'" [FormGroupName]="quickFG" [InputErrors]="rphPsw?.errors"
                                                    [MarkAsTouched]="rphPsw?.touched" [FormGroupInvalid]="formGroupInvalid"
                                                    [ErrorDefs]="{required: 'Required', pattern: 'Invalid!'}" [MinLength]="2"
                                                    (keydown.enter)="focustonext($event, 'okButton')">
                                                </eprx-input>
                                                <span class="help--text__danger" *ngIf="InvalidPassword3">Please enter correct password</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                        <div class="exprx--common-block erx--body insurance--body">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Documents</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="IsQuickForRxVer ? 'eprx--block__content quickVerf__doc--grid' : (showErxDocs ? 'eprx--block__content QuickVerf--eRxDoc__height' : 'eprx--block__content QuickVerf--height_normal--grid')"
                            >

                                <app-document-display [IsFromQuickVerification]="true" 
                                 [totalDocs]="(rxInfoData && (rxInfoData?.rxType === 'nr')) ? (rxInfoData && rxInfoData?.Documents) : totalDocs" [showErxDocs]="showErxDocs" [rxFG]="rxFG"></app-document-display>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngIf="!IsQuickForRxVer">
                    <div class="exprx--common-block erx--body insurance--body">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Observation</span>
                                            </div>
                                            <div class="col-md-2 text-right">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="col-12">
                                        <div class="row" style="height: 11vh; overflow: scroll;">
                                                <table class="col-lg-12 col-md-12 col-xs-12 col-sm-12 table table-bordered">
                                                    <tbody>
                                                        <tr *ngIf="drugInteractions?.Allergies?.IsAllergy">
                                                            <td><label>Allergy</label></td>
                                                            <td style="background-color: #f6251a;color: white" (click)="getDrugAllergiesStatus()"
                                                            [innerHTML]="drugAllergyData"></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Drug to Drug Interactions</label>
                                                            </td>
                                                            <td *ngIf="!drugToDrugInteractions1" >
                                                                <span >
                                                                    No DDI detected
                                                                </span>
                                                            </td>
                                                            <td (click)="getDrugToDrugInterationInfo()" *ngIf="drugToDrugInteractions1" style="background-color: #f6251a;color: white">
                                                                <span>
                                                                        SEVERITY : {{drugToDrugInteractions1.SEVERITY}}-{{drugToDrugInteractions1.DRUGNAME}}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Duplicate Drug Therapy</label>
                                                            </td>
                                                            <td *ngIf="!(drugInteractions && drugInteractions.DuplicateDrugs && drugInteractions.DuplicateDrugs.length > 0)">
                                                                <span >
                                                                    No Duplicate drug therapy detected
                                                                </span>
                                                            </td>
                                                            <td (click)="getDuplicateDrugTherapyInfo()" *ngIf="(drugInteractions && drugInteractions.DuplicateDrugs && drugInteractions.DuplicateDrugs.length > 0)" style="background-color: #f6251a;color: white">
                                                                <span>{{drugInteractions.DuplicateDrugs[0]?.DrugName | uppercase}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="drugInteractions?.DiseaseContradication?.IsShowDDC">
                                                            <td>
                                                                <label>Drug Disease Contraindication</label>
                                                            </td>
                                                            <td (click)="getDrugDeceaseContradictionInfo()" style="background-color: #f6251a;color: white">
                                                                <span>Drug Disease Contraindication detected</span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="drugInteractions?.LifeStyle && drugInteractions.LifeStyle.length > 0">
                                                            <td>
                                                                <label>Drug Lifestyle</label>
                                                            </td>
                                                            <td style="background-color: #f6251a;color: white" (click)="getLifeStyle()">
                                                                <span>
                                                                    {{drugInteractions.LifeStyle[0].LifestyleName}},{{drugInteractions.LifeStyle[0].SEVERITYTEXT}}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="drugInteractions?.PregnancyLactationInfo && rxInfoData.Patient.Gender === 'Female'">
                                                            <td>
                                                                <label>Pregnancy Lactation</label>
                                                            </td>
                                                            <td (click)="getDrugPregnancyInfo()" style="background-color: #f6251a;color: white">
                                                                    <span>Pregnancy Lactation Alert</span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="drugInteractions?.AdverseReaction && drugInteractions.AdverseReaction.length > 0">
                                                            <td>
                                                                <label>Adverse Drug Reaction</label>
                                                            </td>
                                                            <td style="background-color: #f6251a;color: white" (click)="getAdverseReaction()">
                                                                {{drugInteractions?.AdverseReaction[0].Severity}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                </div>
                            </div>
                        </div>
            </div> -->
        </div>
    </div>
    <div class="modal-footer">
                <button class="hotkey_success" (keydown.enter)="saveQuickVerf()" (click)="saveQuickVerf()" id="okButton" *ngIf="(quickFG.controls.recvBy.value)"  appShortcutKey [AltKey]="'o'"><span>O</span>Ok</button>

                <button class="inactive"  id="okButton"  *ngIf="!(quickFG.controls.recvBy.value)"><span>O</span> Ok</button
                    >
                <button class="hotkey_success" (click)="openCancelPopUP()"  appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
    </div>
</ng-template>

<ng-template #DRUGALLERGIES let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Patient Allergy </h4>
        <span body>
            <span [innerHTML]="drugAllergyData">
            </span>
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="c('Cross click')" appShortcutKey InputKey="o">
          <b>O</b>  OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #DRUGDISEASECONTRIND let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Drug-Disease Contraindication </h4>
        <span body>
            <div class="col-xs-12">
                <div class="col-xs-12"> Drug-Disease Contraindication predicted for the Drug
                </div>
                <div class="col-xs-12">
                    <label>Aneurysm </label>
                    <div class="col-xs-12">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col"> ICD10 Code </th>
                                    <th scope="col"> ICD10 Name </th>
                                    <th scope="col"> Min Age Year </th>
                                    <th scope="col"> Max Age Year </th>
                                    <th scope="col"> Gender </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let drugDes of drugDeseContrd?.DrillDownData">
                                    <th scope="row"> {{drugDes?.ICD_CODE}}</th>
                                    <td>{{drugDes?.CHILDDISEASENAME}}</td>
                                    <td>{{drugDes?.MINAGE_YEARS}}</td>
                                    <td>{{drugDes?.MAXAGE_YEARS}}</td>
                                    <td>{{drugDes?.GENDER}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-xs-12">
                        <label> CONTRADICTION TEXT FROM {{drugInfoFromEditDrug?.Drug.Id?.Name}}'S MONOGRAPH </label>
                        <div class="col-xs-12">
                            <div class="label--data">{{drugDeseContrd?.MonographText}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <span footer>
            <button class="hotkey_primary" ngbAutofocus (click)="c('Cross click')" appShortcutKey InputKey="o">
              <b>O</b>  OK
            </button>
            <button class="hotkey_primary" (click)="c('Cross click')" appShortcutKey InputKey="c">
              <b>C</b>  Cancel
            </button>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #DRUGTODRUGINTERACTION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="checkAllDDIPopClosed()">
        <h4 header> Drug-Drug Interaction Information </h4>
        <span body>
                <div class="row">
                    <div class="col inline-flex">
                        <div class="col">
                            <label>Drug1: </label>
                            <div class="label--data">{{rxInfoData?.Drug?.drugname}}</div>
                        </div>
                        <div class="col">
                            <label>Drug2: </label>
                            <div class="label--data">{{drugToDrugInteractions?.DRUGNAME}}</div>
                        </div>
                        <div class="col">
                            <label>Rx No: </label>
                            <div class="label--data"> {{drugToDrugInteractions?.RXNO}} </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col inline-flex">
                        <div class="col">
                            <label>Class1: </label>
                            <div class="label--data"> {{drugToDrugInteractions?.DRUGINTERACTCLASSNAME1}} </div>
                        </div>
                        <div class="col">
                            <label>Class2: </label>
                            <div class="label--data">{{drugToDrugInteractions?.DRUGINTERACTCLASSNAME2}}</div>
                        </div>
                        <div class="col"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col">
                            <label>Effects: </label>
                            <div class="label--data">{{drugToDrugInteractions?.EFFECTS}} </div>
                        </div>
                        <div class="col">
                            <label>Mechanism: </label>
                            <div class="label--data">{{drugToDrugInteractions?.MECHANISM}}</div>
                        </div>
                        <div class="col">
                            <label>Management: </label>
                            <div class="label--data">{{drugToDrugInteractions?.MANAGEMENT}}</div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <label>
                        Onset
                    </label>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col">
                            <label>Severity -
                                <span *ngIf="drugToDrugInteractions?.SEVERITY === '3 - Moderate'" class="bg-light-red">
                                        {{drugToDrugInteractions?.SEVERITY}}
                                </span>
                                <span *ngIf="drugToDrugInteractions?.SEVERITY === '2 - Major'" class="bg-hard-red">
                                        {{drugToDrugInteractions?.SEVERITY}}
                                </span>
                            </label>
                            <div class="label--data"> {{drugToDrugInteractions?.SEVERITYDESCRIPTION}} </div>
                        </div>
                        <div class="col">
                            <label> Documentation Established: {{drugToDrugInteractions?.DOCUM}}</label>
                            <div class="label--data">{{drugToDrugInteractions?.DOCUMENTATIONDESCRIPTION}}</div>
                        </div>
                        <div class="col">
                            <label> Customer notes: </label>
                            <div class="label--data">{{drugToDrugInteractions?.CONSUMERNOTES}}</div>
                        </div>
                        <div class="col">
                            <label> Reference: </label>
                            <div class="label--data">{{drugToDrugInteractions?.REFERENCE}}</div>
                        </div>
                    </div>
                </div>
            </span>
        <button footer class="hotkey_primary" ngbAutofocus (click)="checkAllDDIPopClosed()" appShortcutKey InputKey="o">
          <b>O</b>  OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #DUPLICATEDRUG let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Duplicate Drug Therapy </h4>
        <span body>
            <div class="border--bottom" *ngFor="let duplDrugTherapyInfo of drugInteractions.DuplicateDrugs">
                <div class="row">
                    <div class="col">
                        <div><label>Drug Name:</label>&nbsp;{{ duplDrugTherapyInfo?.DrugName2 | uppercase }}</div>
                        <div class="label--data"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Filled Date: </label>
                        <div class="label--data">{{duplDrugTherapyInfo?.Filldate | datetimeUtc}}</div>
                    </div>
                    <div class="col">
                        <label>Quantity: </label>
                        <div class="label--data">{{(duplDrugTherapyInfo?.Quantity | number : "1.3-3")}}</div>
                    </div>
                    <div class="col">
                        <label>Days: </label>
                        <div class="label--data">{{duplDrugTherapyInfo?.Days}}</div>
                    </div>
                    <div class="col">
                        <label>Rx#: </label>
                        <div class="label--data"> {{duplDrugTherapyInfo?.rxNumber2}} </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="c('Cross click')" appShortcutKey InputKey="o"> <b>O</b>OK
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #DRUGPREGNENCY let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click')">
        <h4 header> Pregnancy and Lactation Information </h4>
        <span body>
            <div class="col-xs-12">
                <label> Pregnancy Information </label>
                <div class="col-xs-12">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> TRIMESTER </th>
                                <th scope="col"> PREGNANCY RATING CODE </th>
                                <th scope="col"> RATING DESCRIPTION </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pregnancyInfo of drugPregInfo?.dtPregnancyInformation">
                                <th scope="row"> {{pregnancyInfo?.TRIMESTER}}</th>
                                <td>{{pregnancyInfo?.PREGNANCYRATINGCODE}}</td>
                                <td>{{pregnancyInfo?.RATINGDESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <label> LACTATION Information </label>
                <div class="col-xs-12">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> IS SAFE FOR CHILD BEARING AGE </th>
                                <th scope="col"> IS SAFE FOR LABOUR AND DELIVERY </th>
                                <th scope="col"> LACTATION RATING </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                <td>{{lactationInfo['LACTATION RATING']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <label> ALTER LACTATION DRUGS ( LACTATION RATING= 'AVOID' OR LACTATION RATING="CAUTION") </label>
                <div class="col-xs-12">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> ALTERNATE PRODUCTION NAME </th>
                                <th scope="col"> ALTERNATE NDCB </th>
                                <th scope="col"> LACTATION RATING </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                <td>{{lactationInfo['LACTATION RATING']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="c('Cross click')" appShortcutKey InputKey="o">
           <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #ADVERSEDRUG let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Cross click');">
        <h4 header> Adverse Drug Reactions </h4>
        <span body>
            <div class="col-xs-12">
                <div class="col-xs-12"> Adverse Drug Reactions Detected for the Drug
                    <span class="font-weight-bold">
                    ({{rxInfoData?.Drug?.drugname ? rxInfoData?.Drug?.drugname
                        : '--'}})
                    </span>
                </div>
                <div class="col-xs-12" >
                        <div class="col-xs-12 max-height_40vh overflow_scroll"
                        >
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col"> Severity </th>
                                            <th scope="col"> OnSet </th>
                                            <th scope="col"> Disease Name </th>
                                            <th scope="col"> Incidence </th>
                                            <th scope="col"> Is Blackbox? </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let adv of drugInteractions.AdverseReaction">
                                            <th scope="row"> {{adv?.Severity}}</th>
                                            <td>{{adv?.Onset}}</td>
                                            <td>{{adv?.DISEASENAME | titlecase}}</td>
                                            <td>{{adv?.INCIDENCE}}</td>
                                            <td>{{adv?.ISBLACKBOX.trim() === 'True' ? 'Yes' : 'No'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                </div>
            </div>
            <div class="col-xs-12">
                <label> Adverse Drug Reaction text from ({{rxInfoData?.Drug?.drugname ? rxInfoData?.Drug?.drugname
                    : '--'}})'S monograph </label>
                <div class="col-xs-12">
                    <div class="label--data overflow_scroll height_25vh "
                    >{{adverseDataMonograph}}</div>
                </div>
            </div>
        </span>
        <span footer>
            <button class="hotkey_primary" autofocus appShortcutKey InputKey="o" (click)="c('Cross click')" >
                <b>O</b> OK
            </button>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #LIFESTYLE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (CloseModal)="c('Cross click'); checkLifeStyleComplete()">
        <h4 header>
            <div class="text-center">
                Drug-Lifestyle Interaction Detected  ({{currentLifeStyleIndex + 1}} of {{drugInteractions.LifeStyle.length}})
            </div>
        </h4>
        <span body>
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <label > Drug:  </label> {{rxInfoData?.Drug?.drugname ? rxInfoData?.Drug?.drugname
                            : '--'}}
                    </div>
                    <div class="col-6">
                        <label> Lifestyle Name:  </label>  {{ drugLifeStyle?.LifestyleName}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <label> Mechanism  </label>
                <div class="col-12">
                    <div class="label--data">{{drugLifeStyle?.ProfessionalNotes}}</div>
                </div>
            </div>
            <div class="col-12" >
                <label> Management </label>
                <div class="col-12">
                    <div class="label--data">{{drugLifeStyle?.CLINICALMANAGEMENTTEXT}}</div>
                </div>
            </div>
            <div class="col-12">
                    <label> Severity : {{drugLifeStyle?.SEVERITYTEXT}}</label>
                    <div class="col-12">
                        <div class="label--data">{{drugLifeStyle?.SEVERITYDESCRIPTION}}</div>
                    </div>
                </div>
                <div class="col-12">
                    <label> Documentation : {{drugLifeStyle?.DOCUMENTATIONTEXT}}</label>
                    <div class="col-12">
                        <div class="label--data">{{drugLifeStyle?.DocumentationDescription}}</div>
                    </div>
                </div>
                <div class="col-12">
                    <label> Consumer Notes </label>
                    <div class="col-12">
                        <div class="label--data">{{drugLifeStyle?.ConsumerNotes}} <br> {{drugLifeStyle?.ConsumerNotes1}}</div>
                    </div>
                </div>
        </span>
        <span footer>
            <button class="hotkey_primary" autofocus appShortcutKey InputKey="o" (click)="c('Cross click')" (click)="checkLifeStyleComplete()">
                <b>O</b> OK
            </button>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #CANCEL let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="backTOVerf(); c('Cross click')">
            <h4 header> Confirmation </h4>
            <span body>
                <div>
                   Are you sure you want to skip the verification?
                </div>
            </span>
            <button footer autofocus class="hotkey_primary" (click)="cancelVerf()" (click)="c('Cross click')"
            (keydown.enter)="cancelVerf(); c('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES
            </button>
            <button footer class="hotkey_primary" (click)="backTOVerf()" (click)="c('Cross click')"
            (keydown.enter)="backTOVerf(); c('Cross click')" appShortcutKey InputKey="n"> <b>N</b> NO
                </button>
        </app-custom-modal>
    </ng-template>
