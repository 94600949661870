export class Note {
    Id: number = null;
    TenantId: number = null;
    AppUserId: number = null;
    xNote: string = null;
    NoteCatId: number = null;
    IsShownAsPopup: boolean = null;
    IsActive: boolean = null;
    IsDeleted: boolean = null;
    CreateDtTm: string = null;
    ModifiedDtTm: string = null;
    Name: string = null;
    PopupScreenCodes: string = null;
    IsAddItem: boolean = false;
}

export class NoteCat {
    Id: number = null;
    TenantId: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreateDtTm: string = null;
    ModifiedDtTm: string = null;
    NoteCategoryId: number = null;
    IsAddItem: boolean = false;
}

export class NotesLog {
    Note: Note[] = [];
    NoteCat: NoteCat[] = [];
}