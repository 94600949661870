import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { RxModel } from "src/app/models";
import { RxPricingUtil, BaseUtils, NRxSaveUtils, NRxUtils } from "src/app/utils";
import { UdpCurrencyMaskPipe } from "src/app/pipes";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InsuranceService, CommonService, AlertService } from "src/app/services";
import { Subject } from "rxjs";
import { MsgConfig } from "src/app"
import { FormGroup } from "@angular/forms";
import { RxStore } from "src/app/store";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-bill-to-cash",
    templateUrl: "./bill-to-cash.component.html",
    styles: []
})
export class BillToCashComponent implements OnInit {
    @Input() rxInfo: RxModel;
    @Input() transmissionType: any;
    @Input() frmBatchEdit: any;
    @Input() rxFG: FormGroup;
    @Input() originalRxData: RxModel;
    @Input() isFromEditRx: any;
    modalOpend: any;
    @Output()
    CancelCashPopUp = new EventEmitter<any>();

    @Output()
    CloseBillToCashPopup = new EventEmitter<any>();

    @Output()
    IsBillToCashPopUpClosed = new EventEmitter<any>();

    @ViewChild("BILLTOCASH")
    BILLTOCASH: any;
    @ViewChild("WARNING", { static: true })
    WARNING: any;
    routeFrom: string;
    WarnMessage: string = "";
    modalRef: any;
    numberPipe = new UdpCurrencyMaskPipe();
    priceScheduleList: any[];
    insuSettings: any;
    calcInProgress = false;
    oldTotPrice: number;
    ChangePriceValuesManually: any;
    unsubscribe$: Subject<void> = new Subject();

    constructor(private _rxPricingUtil: RxPricingUtil, private _baseUtils: BaseUtils,
            private _modalService: NgbModal, private _insurService: InsuranceService, public _commonServ: CommonService,
             private _alertServ: AlertService, private _saveRxUtls: NRxSaveUtils, private _rxStore: RxStore, private _nrxUtls: NRxUtils) {
    }

    ngOnInit() {
            this._commonServ.priceSchedule$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp =>
                resp && resp.PriceScheduleList
                    ? (this.priceScheduleList = resp.PriceScheduleList)
                    : (this.priceScheduleList = [])
            )

            this._insurService.insuSettings$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.InsuPlanPrice) {
                    this.insuSettings = resp;
                }
        })
        if (!(this.rxInfo && this.rxInfo.PrescReFill && this.rxInfo.PrescReFill.PriceSchId)) {
            this.getPriceDetlsNdCalc(null);
        }

        this.routeFrom = this.rxInfo["rxType"];
        this.oldTotPrice = this?.rxInfo?.PrescReFill?.TotalAmt;
    }

    async getPriceDetlsNdCalc(e) {
        this.rxInfo.PrescReFill.PriceSchId = e && e.value ? e.value.Id : null;
        this.rxInfo = await this._rxPricingUtil.triggerPriceSchedule("PriceSch",
            this.rxInfo, this.priceScheduleList, this.insuSettings, null);
    }

    async initiateManualCal() {
        if (!this.calcInProgress) {
            this.calcInProgress = true;
            this.rxInfo = await this._rxPricingUtil.initiateManualCal(this.rxInfo, this.insuSettings, null); //  this.rxInfo["rxType"]
            this.calcInProgress = false;
        }
    }

    keytab(event: any, fg?: any, fc?: string) {
        this._baseUtils.keytab(event, true);
    }

    setNumberFormat(ControlName, event) {
        this.rxInfo.PrescReFill[ControlName] = this.numberPipe.transform(parseFloat(event.value), 2);
    }

    afterPriceValChange(val, type) {
        const val1 = val ? parseFloat(val) : 0;
        this.rxInfo.PrescReFill[type === "IC" ? "Price" : "ProfFee"] = val1;
        this.initiateManualCal();
    }

    crossCheckIngredientCost(val) {
        if (this.oldTotPrice !== val) {
            const result = +(+val - +this.rxInfo.PrescReFill.ProfFee);
            this.rxInfo.PrescReFill["Price"] = result > 0 ? result : 0;
        }
        this.initiateManualCal();
    }

    cancelSaveCashRx() {
        this.CancelCashPopUp.emit(this.frmBatchEdit ? this.rxInfo : null);
    }

    ngOnDestroy() {
	    this.unsubscribe$.next();
	    this.unsubscribe$.complete();
    }

    async saveCashInfoForNewRx() {
        const rxFG = await this._nrxUtls.createPatchStoreRxFG(this.rxInfo);
        let event = {
            rxFG : this.isFromEditRx ? rxFG : this.rxInfo,
            ChangePriceValuesManually: this.ChangePriceValuesManually
        }
       this.IsBillToCashPopUpClosed.emit(event);
    }
    closeWarnPopup() {
        if(this.modalOpend) {
            this.modalOpend.close();
            this.modalOpend = null;
        }
    }
    async saveCashInfo() {
        const rxValue:any = this.rxInfo;
        const data =  this.insuSettings;
        let allowZeroPr = rxValue["PrescReFill"]["Price"] && parseFloat(rxValue["PrescReFill"]["Price"]) > 0 ? true : false;
        if (!allowZeroPr) {
            if (data && data["InsuPlanPrice"] && data["InsuPlanPrice"]["IsZeroBillAllowed"]) {
                allowZeroPr = true;
            }
        }
        if(!allowZeroPr){
            this.WarnMessage = "Selected Insurance does not allow to create Rx with Ingredient Cost value as zero.";
            this.modalOpend = this._modalService.open(this.WARNING, {centered: true, backdrop: false, windowClass: "large--content"});
        } else {
            if (!this.frmBatchEdit) {
                if (this.rxFG && !(this.rxInfo["Prescription"]["RxStatusId"] === 2 || this.rxInfo["PrescReFill"]["StatusId"] === 2)) {
                    this.rxInfo["RxFile"] = null;
                    this.rxFG.controls["RxFile"].patchValue({});
                }
                this.rxInfo["RxBill"]["StatusId"] = 2;
                const currentDate1 = new Date();
                this.rxInfo["PrescReFill"]["ModifiedDtTm"] = currentDate1;
                const resp = await this._saveRxUtls.updateRxInfo(this.rxInfo);
                if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
                    let currentDate = new Date();
                    if(this.rxFG) {
                        this.rxFG.controls["PrescReFill"].patchValue({
                            ModifiedDtTm: currentDate
                        });
                        this.rxFG.controls["RxBill"].patchValue({StatusId: 2});
                    }
                    this.rxInfo["PrescReFill"]["ModifiedDtTm"] = currentDate;
                    this.rxInfo.RxBill.StatusId = 2;

                } else if (resp && isNaN(+resp.Message)) {
                    if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                        this._alertServ.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
                    } else if (typeof resp.Message === "string") {
                    this._alertServ.error(resp.Message);
                    }
                } else {
                    this._alertServ.error("Unable to convert Insurance to Cash");
                }
                if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
                    this._rxStore.storeRxInfo(this.rxInfo);

                } else if (this.rxFG) {
                    this._rxStore.storeRxInfo(this.rxFG.getRawValue());
                }
            }
            this.IsBillToCashPopUpClosed.emit(this.isFromEditRx ? this.rxInfo : null);
        }

    }

    focusOnPriceCodeForCashCnfm(e) {
        e.preventDefault();
        if (document.getElementById("cnfmrxPriceSchId")) {
            document.getElementById("cnfmrxPriceSchId").getElementsByTagName("input")[0].focus();
        }
    }

    changePriceValues(value){
        this.ChangePriceValuesManually = value;
    }
}
