import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";
import { InveTransType } from "../models";

@Injectable()
export class InveTransTypeService {
    constructor(private _http: HttpClient) {}

    getInventoryTransData(searchData: any): Observable<InveTransType[]> {
        return this._http.post<InveTransType[]>(
            constant.POST_Inventory_TransTypeSearch,
            searchData
        );
    }

    isTransNameAlreadyExists(transTypeName: string): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Inventory_IsTransNameExists.replace(
                "{transTypeName}",
                "" + transTypeName
            )
        );
    }

    addupdateTransType(drugTransdata: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Inventory_TransType,
            drugTransdata
        );
    }

    deleteTransType(transTypeIds: string): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Inventory_TransType.replace(
                "{transTypeIds}",
                "" + transTypeIds
            )
        );
    }

    getTransNameSuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Inventory_TransNameSuggest.replace("{searchText}", "" + value)
        );
    }
}
