import { HostListener, Injectable, Directive } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { RxService, AlertService, InsuranceService, CommonService } from "../services";
import { RxBillingCalculationInput } from "../models/rx-billing-calculation.model";
import { Subscription } from "rxjs";
import { RxStore } from "../store";
import { NRxUtils } from "./nRx.util";
import { DrugBucketUtil } from "./drug-bucket.util";
import { RxChecksForCommonLoigc } from "./rxCheck.util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LowInventoryComponent } from "../modules/shared/low-inventory/low-inventory.component";
import * as moment from "moment";
import { RxBillingUtil } from "./rx-billing.util";
import { Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { CommonUtil } from "./common.util";

@Directive()
@Injectable({
    providedIn: "root"
})
export class PopupsCommonLogicUtil {
    subscriptions = new Subscription();
    warnInvLow: number;
    insuSettings$: Observable<any>;
    pricSchdlLst$: Observable<any>;
    isAltKeyPress: boolean = false;
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        this.isAltKeyPress = event.altKey;
    }
    constructor(private _fb: FormBuilder, private _rxService: RxService, private _insuSvc: InsuranceService,
        private _alertSvc: AlertService,private _mdlServ: NgbModal, private _rxStore: RxStore, private _nRxUtil: NRxUtils, private _drugBuckUtil: DrugBucketUtil, private _cmmnServ: CommonService, private rxCheckUtil: RxChecksForCommonLoigc,
        private _comnSvc: CommonService, private rxBillingUtil: RxBillingUtil, private _commnUtil:CommonUtil) {
        this.insuSettings$ = this._insuSvc.insuSettings$;
        this.pricSchdlLst$ = this._comnSvc.priceSchedule$;

    }

    CheckRxPriceCalculationsforLarge(greateValues: any, rxInfo) {

        const BalDuemaxValue = 9999999999.00;
        const maxValue = 9999999999.99; // 9999999.99;
        let row = "<div class='row'> <div class='col-6'>";
        let rowClose = "</div></div>";
        let secondDiv = "</div> <div class='col-6'>";
        let messageHead = "The following values are greater than the selected range (" + maxValue + "), and will be reset to zero. Please check the values.<br> ";
         messageHead = messageHead + row + "<b>Item Name</b>" + secondDiv + "<b>Calculated value</b>" + rowClose;
        let message = "";
        let formGroupControls = [];

        if(greateValues  && greateValues.Price > maxValue) { // Ingredient Cost
            message += row + "Ingredient Cost" + secondDiv + greateValues.Price + rowClose;
            formGroupControls.push("Price");
        }
        if (greateValues  && greateValues.BalDue > maxValue) {
            message +=  row + "Balance Due" + secondDiv + greateValues.BalDue + rowClose;
            formGroupControls.push("BalDue");
        }
        if (greateValues &&  greateValues.ProfFee > maxValue) {
            message += row + "Professional Fee" + secondDiv + greateValues.ProfFee + rowClose;
            formGroupControls.push("ProfFee");
        }
        if (greateValues && greateValues.TotalAmt > maxValue) {
            message += row + "Total Price" + secondDiv + greateValues.TotalAmt + rowClose;
            formGroupControls.push("TotalAmt");
        }
        if (greateValues  && greateValues.UnitPriceAWP > maxValue) {
            message += row + "AWP" + secondDiv + greateValues.UnitPriceAWP + rowClose;
            formGroupControls.push("UnitPriceAWP");
        }
         // if(PrescRefillFG.value.TotalRxCost > maxValue) {
        //     message += "Total Rx Cost";
        // }
        if (greateValues  && greateValues.UnitPriceUNC > maxValue) {
            message += row + "U&C" + secondDiv + greateValues.UnitPriceUNC + rowClose;
            formGroupControls.push("UnitPriceUNC");
        }
        if(rxInfo && rxInfo.OtherAmount && rxInfo.OtherAmount > maxValue) {
            message += row + "Oth. Amount + Oth.Fee + Tax" + secondDiv + rxInfo.OtherAmount + rowClose;
            formGroupControls.push("OtherAmount");
        }
        if (message) {
            return { "messageText" : messageHead + message, "formControls": formGroupControls };
        } else {
            return message;
        }
    }
    CheckRxPriceCalculations(rxFG: FormGroup) {
        const PrescRefillFG: any = rxFG.controls["PrescReFill"] as FormGroup;
        const rxInfo = rxFG.getRawValue();
        const BalDuemaxValue = 9999999999.00;
        const maxValue = 9999999999.99; // 9999999.99;
        let rutfrm = false;
        let row = "<div class='row'> <div class='col-6'>";
        let rowClose = "</div></div>";
        let secondDiv = "</div> <div class='col-6'>";
        let messageHead = "The following values are greater than the selected range (" + maxValue + "), and will be reset to zero. Please check the values.<br> ";
        if(rxInfo["rxType"] === "rf") {
            rutfrm = true;
            messageHead ="<div class='custom_font_size_18 mb-2'>Price values have been changed since the last fill, and the calculated values are greater than the permissible range (" + maxValue + "). Please check the price values.</div>";
        } else {
            rutfrm = false;
        }
            messageHead = messageHead + row + "<b>Item Name</b>" + secondDiv + "<b>Calculated value</b>" + rowClose;
        let message = "";
        let formGroupControls = [];
        const InsStngs = this.insuSettings$["source"]["value"];
        if (!rutfrm || (rutfrm &&
            InsStngs &&
            InsStngs.InsuPlanOther &&
            InsStngs.InsuPlanOther.IsRefillRepriced)
        ) {
        if(PrescRefillFG && PrescRefillFG.value && PrescRefillFG.value.Price > maxValue) { // Ingredient Cost
            message += row + "Ingredient Cost" + secondDiv + PrescRefillFG.value.Price + rowClose;
            formGroupControls.push("Price");
        }
        if (PrescRefillFG && PrescRefillFG.value && PrescRefillFG.value.BalDue > maxValue) {
            message +=  row + "Balance Due" + secondDiv + PrescRefillFG.value.BalDue + rowClose;
            formGroupControls.push("BalDue");
        }
        if (PrescRefillFG && PrescRefillFG.value && PrescRefillFG.value.ProfFee > maxValue) {
            message += row + "Professional Fee" + secondDiv + PrescRefillFG.value.ProfFee + rowClose;
            formGroupControls.push("ProfFee");
        }
        if (PrescRefillFG && PrescRefillFG.value.TotalAmt > maxValue) {
            message += row + "Total Price" + secondDiv + PrescRefillFG.value.TotalAmt + rowClose;
            formGroupControls.push("TotalAmt");
        }
        if (PrescRefillFG && PrescRefillFG.value && PrescRefillFG.value.UnitPriceAWP > maxValue) {
            message += row + "AWP" + secondDiv + PrescRefillFG.value.UnitPriceAWP + rowClose;
            formGroupControls.push("UnitPriceAWP");
        }
         // if(PrescRefillFG.value.TotalRxCost > maxValue) {
        //     message += "Total Rx Cost";
        // }
        if (PrescRefillFG && PrescRefillFG.value && PrescRefillFG.value.UnitPriceUNC > maxValue) {
            message += row + "U&C" + secondDiv + PrescRefillFG.value.UnitPriceUNC + rowClose;
            formGroupControls.push("UnitPriceUNC");
        }
        if(rxInfo && rxInfo.OtherAmount && rxInfo.OtherAmount > maxValue) {
            message += row + "Oth. Amount + Oth.Fee + Tax" + secondDiv + rxInfo.OtherAmount + rowClose;
            formGroupControls.push("OtherAmount");
        }
        if (message) {
            return { "messageText" : messageHead + message, "formControls": formGroupControls };
        } else {
            return message;
        }
    } else {
        return message;
    }
    }
    CheckRxPriceCalculationsWithFG(rxFG) {
        const PrescRefillFG: any = rxFG["PrescReFill"];
        const rxInfo = rxFG;
        const BalDuemaxValue = 9999999999.00;
        const maxValue = 9999999999.99; // 9999999.99;
        let row = "<div class='row'> <div class='col-6'>";
        let rowClose = "</div></div>";
        let secondDiv = "</div> <div class='col-6'>";
        let messageHead = "The following values are greater than the selected range (" + maxValue + "), and will be reset to zero. Please check the values.<br> ";
         messageHead = messageHead + row + "<b>Item Name</b>" + secondDiv + "<b>Calculated value</b>" + rowClose;
        let message = "";
        let formGroupControls = [];

        if(PrescRefillFG &&  PrescRefillFG.Price > maxValue) { // Ingredient Cost
            message += row + "Ingredient Cost" + secondDiv + PrescRefillFG.Price + rowClose;
            formGroupControls.push("Price");
        }
        if (PrescRefillFG && PrescRefillFG.BalDue > maxValue) {
            message +=  row + "Balance Due" + secondDiv + PrescRefillFG.value.BalDue + rowClose;
            formGroupControls.push("BalDue");
        }
        if (PrescRefillFG &&  PrescRefillFG.ProfFee > maxValue) {
            message += row + "Professional Fee" + secondDiv + PrescRefillFG.value.ProfFee + rowClose;
            formGroupControls.push("ProfFee");
        }
        if (PrescRefillFG && PrescRefillFG.TotalAmt > maxValue) {
            message += row + "Total Price" + secondDiv + PrescRefillFG.value.TotalAmt + rowClose;
            formGroupControls.push("TotalAmt");
        }
        if (PrescRefillFG  && PrescRefillFG.UnitPriceAWP > maxValue) {
            message += row + "AWP" + secondDiv + PrescRefillFG.value.UnitPriceAWP + rowClose;
            formGroupControls.push("UnitPriceAWP");
        }
        if (PrescRefillFG && PrescRefillFG.value.UnitPriceUNC > maxValue) {
            message += row + "U&C" + secondDiv + PrescRefillFG.value.UnitPriceUNC + rowClose;
            formGroupControls.push("UnitPriceUNC");
        }
        if(rxInfo && rxInfo.OtherAmount && rxInfo.OtherAmount > maxValue) {
            message += row + "Oth. Amount + Oth.Fee + Tax" + secondDiv + rxInfo.OtherAmount + rowClose;
            formGroupControls.push("OtherAmount");
        }
        if (message) {
            return { "messageText" : messageHead + message, "formControls": formGroupControls };
        } else {
            return message;
        }
    }

    checkPopInNewRxAndEditRx(rxFG: FormGroup) {
        const rxInfo = rxFG.value;
        const Drug = rxFG.get("Drug") as FormGroup
        const maxQuantity = 9999999.000;
        const oldInfo = this._rxStore.rxInfo$["source"]["value"];
        const qtyInHand: any = this.rxCheckUtil.getQntyinHand(rxInfo);
        if (rxInfo["PrescReFill"].OrdQty < +(rxInfo["PrescReFill"].DispQty) && !rxInfo["PrescReFill"].IsOverFillRx) { //Quantity ordered cannot be less than Quantity dispensed.
            return true;
        } else if (!rxInfo["alreadyCheckedLowInventory"] && this.warnInvLow && Drug.value["manageinventory"] && Drug.value["manageinventory"].length && (this.rxCheckUtil.checkBucketIdInManageinventory(rxInfo)) && (qtyInHand && (qtyInHand.QtyInHand < +rxFG.value.PrescReFill.DispQty) && !rxFG.value["isLowInventoryCheck"])) {
            rxFG.controls["isLowInventoryCheck"].patchValue(true);
            return true;
        } else if ((rxInfo["Drug"]["drugtypeid"] == 2 && parseInt(rxInfo.PrescReFill.DispQty) &&
            ((+rxInfo.PrescReFill.DispQty < +rxInfo.PrescReFill.OrdQty) || (+rxInfo.PrescReFill.DispQty >+rxInfo.PrescReFill.OrdQty)))) //Compound Drugs cannot be filled Partially
            return true;
        else if ((rxFG.value["PrescReFill"]["PartialFillNo"] > 1 || rxFG.value["rxType"] === "er")
            && +rxFG.value.PrescReFill.DispQty &&
            +rxFG.value.PrescReFill.DispQty > +(rxInfo["TotalRemainingQty"]) &&
            +rxFG.value.PrescReFill.DispQty !== +rxFG.value.PrescReFill.OrdQty) { //Quantity dispensed cannot be greater than the Total Quantity Remaining
            return true;
        } else if ((+rxFG.value.PrescReFill.OrdQty && +rxFG.value.PrescReFill.OrdQty > maxQuantity) || (+rxFG.value.PrescReFill.DispQty > maxQuantity)) {
            return true;
        } else if(rxInfo.PrescReFill.OrderDays && rxInfo.PrescReFill.SupplyDays && (+rxInfo.PrescReFill.OrderDays < +rxInfo.PrescReFill.SupplyDays)){
             // Ordered Days cannot be less than Supply Days.
            return true;
        } else if (+rxInfo.PrescReFill.SupplyDays > 999 || +rxInfo.PrescReFill.OrderDays > 999) {
            return true; //OutOfRange
        } else {
            this.commonCalculationForSupplyOrderDays(rxFG);
            return false;
        }
    }
    async validationCheckForQtyField(rxFG: FormGroup) {
        const rxInfo = rxFG.value;
        let PRESCREFILL: any = rxInfo["PrescReFill"];
        const Drug = rxFG.get("Drug") as FormGroup
        const qtyInHand: any = this.rxCheckUtil.getQntyinHand(rxInfo);
        const maxQuantity = 9999999.000;
        if (rxInfo["PrescReFill"].OrdQty > maxQuantity || rxInfo["PrescReFill"].DispQty > maxQuantity) {
            return true;
        } else if (rxInfo["PrescReFill"].OrdQty < +(rxInfo["PrescReFill"].DispQty)) { //Quantity ordered cannot be less than Quantity dispensed.
            return true;
        } else if (!rxInfo["alreadyCheckedLowInventory"] && this.warnInvLow && Drug.value["manageinventory"] && Drug.value["manageinventory"].length && (this.rxCheckUtil.checkBucketIdInManageinventory(rxInfo)) && (qtyInHand && (qtyInHand.QtyInHand < +rxFG.value.PrescReFill.DispQty))) {
            rxFG.controls["isLowInventoryCheck"].patchValue(true);
            return true;
        } else if ((rxInfo["Drug"]["drugtypeid"] == 2 && +(rxInfo.PrescReFill.DispQty) &&
            ((+rxInfo.PrescReFill.DispQty < +rxInfo.PrescReFill.OrdQty) || (+rxInfo.PrescReFill.DispQty > +rxInfo.PrescReFill.OrdQty)))) { //Compound Drugs cannot be filled Partially
            return true;
        } else if((+rxInfo.PrescReFill.SupplyDays > 999) || (+rxInfo.PrescReFill.OrderDays > 999)) {
            return true;
        }
      else if(((rxInfo["PrescReFill"]["PartialFillNo"] > 1 || rxInfo["rxType"] === "er")
        && +rxInfo.PrescReFill.DispQty &&
           +rxInfo.PrescReFill.DispQty > PRESCREFILL["erQtyRem"] &&
            +rxInfo.PrescReFill.DispQty !== +rxInfo.PrescReFill.OrdQty)) {
                return true;
            }
         else {
            const vals: any = await this.CheckRxPriceCalculations(rxFG);
            return (vals) ? true : false;
        }
    }
    async checkValidationForRxFields(rxFG: FormGroup, isAltKeyPress: boolean = false) {
        const rxInfo = rxFG.value;
        const Drug = rxFG.get("Drug") as FormGroup
        const maxQuantity = 9999999.000;
        const qtyInHand: any = this.rxCheckUtil.getQntyinHand(rxInfo);
        let warnObj: any = {}, warningMessage: string = "";
        const orderDtTm = rxInfo.PrescReFill["OrderDtTm"];
        const fillDtTm = rxInfo.PrescReFill["FillDtTm"];
        const warnInvLow = this._cmmnServ.getSetttingValue("RxSettings", "WARN_INVEN_LOW") === "1";
        const qty = rxInfo['totalPartialQty'] + (+rxInfo.PrescReFill.DispQty);
        const qty1= rxInfo['totalPartialQtyForPar'] + (+rxInfo.PrescReFill.DispQty);
        const isRefillflow =  (rxInfo["rxType"] == "rf") && ((+rxInfo.PrescReFill.OrdQty) < qty1);
         const isEditRefillFlow = (rxInfo["rxType"] == "er")
		 && (rxInfo.isPreviousRefillPartialCreatedOrNot) &&
		 ((+rxInfo.PrescReFill.OrdQty) < qty);
         const totalRefillsQty = (+rxInfo.PrescReFill.OrdQty) ? (+rxInfo?.PrescReFill?.OrdQty * 
            (+rxInfo?.Prescription?.ReFillNum ? (+rxInfo?.Prescription?.ReFillNum + 1) : 1)) : 0;
            rxInfo["TotalRemainingQty"] = (( (rxInfo["rxType"] == "er" && !+rxInfo?.PrescReFill?.ReFillNum && 
                +rxInfo['islatestrefill'] && +rxInfo['islatestpartialrefill']) || rxInfo["rxType"] == "nr" || rxInfo["rxType"] == "nrf") ? 
        totalRefillsQty : rxInfo["TotalRemainingQty"]);
        if(rxFG.value["isAltKeyPress"]) {
            let isChanges:  number = 0;
            const prescReFill: FormGroup = rxFG.controls["PrescReFill"] as FormGroup;
            prescReFill.controls["DispQty"].valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
                isChanges++;
            });
            prescReFill.controls["OrdQty"].valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
                isChanges++;
            });
            if(isChanges || rxFG.get("priceCalculationsCheckAtSave").value)
                 this.commonCalculationForSupplyOrderDays(rxFG);
        }
        if (+rxInfo.PrescReFill.OrdQty > maxQuantity) {
            warningMessage = "Quantity ordered cannot be greater than " + maxQuantity;
            warnObj = { focusFieldID: "rxOrdrQty", warningMessage: warningMessage };
        } else if (+rxInfo.PrescReFill.DispQty > maxQuantity) {
            warningMessage = "Quantity dispensed cannot be greater than " + maxQuantity;
            warnObj = { focusFieldID: "rxDispQty", warningMessage: warningMessage };
        }  else if ((rxInfo["rxType"] === "nr" || rxInfo["rxType"] === "nrf") && +rxInfo.PrescReFill.OrdQty && +rxInfo.PrescReFill.DispQty &&  +rxInfo.TotalRemainingQty  && 
        (+rxInfo.TotalRemainingQty < +rxInfo.PrescReFill.DispQty))
        {
            warningMessage = "Quantity dispensed cannot be greater than Rx Quantity.";
            warnObj = { focusFieldID: "rxDispQty", warningMessage: warningMessage };
        } 
        else if (rxInfo["rxType"] === "er" && !(!+rxInfo?.PrescReFill?.ReFillNum && 
            +rxInfo['islatestrefill'] && +rxInfo['islatestpartialrefill'])  && (+rxInfo.totalRxRemQty  < +rxInfo.PrescReFill.DispQty))
        {
            warningMessage = "Quantity dispensed cannot be greater than Rx Quantity.";
            warnObj = { focusFieldID: (rxInfo["activeElementID"] && rxInfo["activeElementID"] === 'rxDispQty') ? "rxDispQty" : "rxOrdrQty", warningMessage: warningMessage };
         }   else if (rxInfo["rxType"] !== "nr" && rxInfo["rxType"] !== "er" && +rxInfo.TotalRemainingQty  && (+rxInfo.TotalRemainingQty < +rxInfo.PrescReFill.DispQty))
         {
            warningMessage = "Quantity dispensed cannot be greater than Rx Quantity.";
            warnObj = { focusFieldID: (rxInfo["activeElementID"] && rxInfo["activeElementID"] === 'rxDispQty') ? "rxDispQty" : "rxOrdrQty", warningMessage: warningMessage };
          }  else if (rxInfo["rxType"] === "er" &&  (!+rxInfo?.PrescReFill?.ReFillNum && 
        +rxInfo['islatestrefill'] && +rxInfo['islatestpartialrefill']) &&  +rxInfo.PrescReFill.OrdQty && +rxInfo.PrescReFill.DispQty && ((+rxInfo.TotalRemainingQty  && (+rxInfo.TotalRemainingQty < +rxInfo.PrescReFill.DispQty)) || (!+rxInfo.Prescription.ReFillNum && +rxInfo.PrescReFill.OrdQty < +rxInfo.PrescReFill.DispQty)))
       { 
        warningMessage = "Quantity dispensed cannot be greater than Rx Quantity.";
        warnObj = { focusFieldID: "rxDispQty" , warningMessage: warningMessage };

       }else if (+rxInfo.PrescReFill.SupplyDays > 999) {
            warningMessage = "Supply Days" + rxInfo["PrescReFill"]["SupplyDays"] + " is out of range. Please enter Supply Days manually";
            warnObj = { focusFieldID: "rxSupplyDays", warningMessage: warningMessage };
        } 
        else if (+rxInfo.PrescReFill.OrderDays > 999) {
            warningMessage = "Order Days" + rxInfo["PrescReFill"]["OrderDays"] + " is out of range. Please enter Order Days manually";
            warnObj = { focusFieldID: "rxOrderDays", warningMessage: warningMessage };

        }  else if (rxInfo["Drug"]["drugtypeid"] == 2 && +(rxInfo.PrescReFill.DispQty) &&
            ((+rxInfo.PrescReFill.DispQty < +rxInfo.PrescReFill.OrdQty) || (+rxInfo.PrescReFill.DispQty > +rxInfo.PrescReFill.OrdQty))) {
            warningMessage = "Compound Drugs cannot be filled Partially or overfilled.";
            warnObj = { focusFieldID: "rxDispQty", warningMessage: warningMessage };
        }  else if ((((rxInfo["isDisQtyNewValue"] !== rxInfo["isDisQtyOldValue"] || !rxInfo['IsIntialQtyDispGivining']) && rxInfo["rxType"] === "er") ||
        rxInfo["rxType"] !== "er") && isAltKeyPress && warnInvLow && Drug.value["manageinventory"] && Drug.value["manageinventory"].length && (this.rxCheckUtil.checkBucketIdInManageinventory(rxInfo)) && (qtyInHand && (qtyInHand.QtyInHand < +rxFG.value.PrescReFill.DispQty))) {
            warnObj = { focusFieldID: "", warningMessage: "" , isStopAction : true };
        } else if(rxFG.value.IsHoAReGenerate)
        {
            warnObj = { focusFieldID: "", warningMessage: "" , isStopFutherAction : true };
        }
        else if ((moment(fillDtTm, "MM/DD/YYYY", true).isValid()&& moment(moment(orderDtTm).format("YYYY-MM-DD")).isAfter(moment(fillDtTm).format("YYYY-MM-DD"))))
          {
            warningMessage =  "Filled Date cannot be less than Ordered Date.";
            warnObj = { focusFieldID: "OrdrFillDate", warningMessage: warningMessage };
         } else {
             if(rxFG.get("priceCalculationsCheckAtSave").value) {
                const insuSettings = this.insuSettings$["source"]["value"];
                const pricSchdlLst = this.pricSchdlLst$["source"]["value"];
                await this.rxBillingUtil.getPriceDetlsNdCalc(null, rxFG, pricSchdlLst,
                    insuSettings, rxFG.get("rxType").value);
            }
            const vals: any = await this.CheckRxPriceCalculations(rxFG);
            if(vals) {
                warnObj = { focusFieldID: "rxDispQty", warningMessage: vals.messageText, "formControls": vals.formControls };
            } else
                warnObj = null;
        }
        return warnObj;
    }
    commonCalculationForSupplyOrderDays(rxFG) {
        const prescReFill: FormGroup = rxFG.controls["PrescReFill"] as FormGroup;
        const prescription: FormGroup = rxFG.controls["Prescription"] as FormGroup;
        const dailyDose = rxFG.get("dailyDose").value;
        if (dailyDose && dailyDose !== 0) {
            const supplydays = Math.round(
                prescReFill.value.DispQty / dailyDose
            );
            prescReFill.controls["SupplyDays"].patchValue(supplydays);
            const ordDays = Math.round(
                prescReFill.value.OrdQty / dailyDose
            );
            prescReFill.controls["OrderDays"].patchValue(ordDays);
            prescription.controls["Days"].patchValue(supplydays);
        }
    }
    calculateSupplyAndOrderDays(rxFG, type, orgVal) {
        const prescReFill: FormGroup = rxFG.controls["PrescReFill"] as FormGroup;
        const prescription: FormGroup = rxFG.controls["Prescription"] as FormGroup;
        let supDays = null;
        if (prescReFill.value.SupplyDays) {
            supDays = +prescReFill.value.SupplyDays;
        }
        const dailyDose = rxFG.get("dailyDose").value;
        if (dailyDose && dailyDose !== 0) {
            if (type === "dispQty") {
                const supplydays = Math.round(orgVal / dailyDose); // prescReFill.value.DispQty
                prescription.controls["Days"].patchValue(supplydays);
                prescReFill.controls["SupplyDays"].patchValue(supplydays);
            }
            if (type === "ordQty") {
                const ordDays = Math.round(orgVal / dailyDose); // prescReFill.value.OrdQty
                prescReFill.controls["OrderDays"].patchValue(ordDays);
            }
        }
    }
    async LowInventoryPopCheck(rxFG) {
        const rxInfo = rxFG.value;
        const Drug: any = rxFG.get("Drug") as FormGroup
        const maxQuantity = 9999999.000;
        let warnObj: any = {}, warningMessage: string = "";
        let PRESCREFILL: any = rxInfo["PrescReFill"];
        const oldInfo = this._rxStore.rxInfo$["source"]["value"];
        const warnInvLow = this._cmmnServ.getSetttingValue("RxSettings", "WARN_INVEN_LOW") === "1";
        if (warnInvLow &&
            this.rxCheckUtil.checkBucketIdInManageinventory(rxFG.value)) {
            const resp = await this._rxService.getQntyinHand(Drug.get("id").value, rxFG.value.PrescReFill.BucketId).toPromise();
            if (resp < +rxFG.value.PrescReFill.DispQty) {
                const modalRefLowInv: any = this._mdlServ.open(LowInventoryComponent,
                    {size: "lg", windowClass: "modal-md modal-center-to-page", backdrop: false, keyboard: false});

            }
        }
    }
    calculateQtyRem(rxFG) {
        const rxInfo = rxFG.value;
        const Drug: any = rxFG.get("Drug") as FormGroup;
        const maxQuantity = 9999999.000;
        let warnObj: any = {}, warningMessage: string = "";
        let PRESCREFILL: any = rxFG.get("PrescReFill") as FormGroup; // rxInfo["PrescReFill"];
        const oldInfo = this._rxStore.rxInfo$["source"]["value"];
        if(parseFloat(PRESCREFILL.value["DispQty"]) && parseFloat(PRESCREFILL.value["OrdQty"])) {
          let QtyRem = 0;
          if (rxFG.value["PrescReFill"]["PartialFillNo"] === 1 && (rxFG.value["rxType"] !== "er" ||
          (rxFG.value["rxType"] === "er"))) {
              QtyRem = (+PRESCREFILL.value["OrdQty"] ? parseFloat(PRESCREFILL.value["OrdQty"]) : 0) -
              (+PRESCREFILL.value["DispQty"] ? parseFloat(PRESCREFILL.value["DispQty"]) : 0);
          } else {
              QtyRem = PRESCREFILL.value["erQtyRem"] -
              (+PRESCREFILL.value["DispQty"] ? parseFloat(PRESCREFILL.value["DispQty"]) : 0);
          }
                rxFG.controls["PrescReFill"].patchValue({
              QtyRemRef: +QtyRem ? (QtyRem).toFixed(3) : "0.000"
          });
        }
      }
      clearControlPatching(rxFG: FormGroup, focusKey, isYesClick : boolean = false, priceFormControls?: any) {
        const prescReFill: FormGroup = rxFG.controls[
            "PrescReFill"
        ] as FormGroup;
        const oldInfo = this._rxStore.rxInfo$["source"]["value"];
        if(focusKey === "rxOrdrQty")
                prescReFill.controls["OrdQty"].setValue(null);
         else if(focusKey === "rxDispQty")
              prescReFill.controls["DispQty"].setValue(null);
        else if(focusKey === "rxSupplyDays")
             rxFG.controls["PrescReFill"].patchValue({SupplyDays: 0});
        else if(focusKey === "rxOrderDays")
             prescReFill.controls["OrderDays"].setValue(null);
        else if(focusKey === "rxDispQtyLeftOver") {
            rxFG.controls["PrescReFill"].patchValue({DispQty: isYesClick ? rxFG.value["PrescReFill"]["DispQty"] :
            oldInfo["PrescReFill"]["DispQty"].toFixed(3)});
            if(isYesClick) {
                this.calculateQtyRem(rxFG);
                this.commonCalculationForSupplyOrderDays(rxFG);
            } else {
                rxFG.controls["PrescReFill"].patchValue({SupplyDays: oldInfo["PrescReFill"]["SupplyDays"]});
            }
        } else if(focusKey === "OrdrFillDate") {
            const orderDtTm = rxFG.value.PrescReFill["OrderDtTm"];
            prescReFill.controls["FillDtTm"].setValue(orderDtTm);
        }
        if (priceFormControls) {
            const PrescRefillFG: any = rxFG.controls["PrescReFill"] as FormGroup;
            if (priceFormControls.length > 0) {
                for (const i in priceFormControls) {
                    if (priceFormControls[i]) {
                        if (priceFormControls[i] === "OtherAmount") {
                            rxFG.controls[priceFormControls[i]].setValue("0.00");
                        } else {
                            PrescRefillFG.controls[priceFormControls[i]].setValue("0.00");
                        }
                    }
                }
                PrescRefillFG.controls["QtyRemRef"].patchValue("0.000");
            }
        }
    }

    // step-1
    async rxPriceValsInitiate(rxInfo, type, isPartialFill?: any) {
        const PrescRefillFG: any = rxInfo["PrescReFill"];
        const discntCodeList = this._nRxUtil.getDisctCodeList();
        rxInfo = await this.checkAndPatchDiscount(rxInfo);
        const resp = await this.getPriceCalculationValue(rxInfo, type, isPartialFill);
        if (resp) {
            PrescRefillFG["Price"] = resp.Price ? parseFloat(resp.Price).toFixed(2) : "0.00";
            PrescRefillFG["BalDue"] = resp.BalDue ? parseFloat(resp.BalDue).toFixed(2) : "0.00";
            PrescRefillFG["ProfFee"] = resp.ProFee ? parseFloat(resp.ProFee).toFixed(2) : (0).toFixed(2);
            PrescRefillFG["TotalAmt"] = resp.TotalAwp ? parseFloat(resp.TotalAwp).toFixed(2) : "0.00";
            let AWP = null;
            let rxCost = null;
            if (PrescRefillFG.DispQty) {
                AWP = +PrescRefillFG.DispQty * rxInfo.Drug.unitpriceawp;
                PrescRefillFG["UnitPriceAWP"] = AWP ? parseFloat(AWP).toFixed(2) : "0.00";

                if (rxInfo.Drug && rxInfo.Drug.manageinventory && rxInfo.Drug.manageinventory.length) {
                    const isBuckets = rxInfo.Drug.manageinventory.filter(buck =>
                        buck.BucketId === (rxInfo["PrescReFill"] &&  rxInfo["PrescReFill"]['BucketId']))
                        || [];
                    const rxCost = (isBuckets && isBuckets.length) ? (+rxInfo.PrescReFill.DispQty * isBuckets[0].UnitPriceCost).toFixed(2) : null;
                    PrescRefillFG["TotalRxCost"] = rxCost ? rxCost : "0.00";
                }
            } else {
                PrescRefillFG["TotalRxCost"] = "0.00";
            }
            PrescRefillFG["UnitPriceUNC"] = (resp && resp.UAndC ) ? parseFloat(resp.UAndC).toFixed(2) : "0.00";
            if (PrescRefillFG["DiscSchId"]) {
                rxInfo["PrescReFill"] = PrescRefillFG;
                await this.getDiscDtlsNdCalc(rxInfo, discntCodeList);
            } else {
                PrescRefillFG["Discount"] = (0).toFixed(2);
            }
            rxInfo["PrescReFill"] = PrescRefillFG;
            rxInfo = await this.initiateManualCal(rxInfo, resp);
            return this.checkRxPriceVals(rxInfo);
        }
    }
    // step-2
    async checkAndPatchDiscount(rxInfo) {
        const prescRefll = rxInfo["PrescReFill"];
        const isCash = this._rxStore.isCashRx$["source"]["value"];
        const discntCodeList = this._nRxUtil.getDisctCodeList();
        if (discntCodeList && discntCodeList.length > 0 && isCash && rxInfo["Drug"]["isdiscountable"]) {
            const discnt = discntCodeList.filter(x => x["DiscCode"] === rxInfo.Patient["DiscSchCd"])[0];
            if (discnt) {
                prescRefll["DiscSchId"] = discnt["Id"];
            } else {
                prescRefll["DiscSchId"] = null;
            }
        } else {
            prescRefll["DiscSchId"] = null;
            prescRefll["Discount"] = (0).toFixed(2);
        }
        rxInfo["PrescReFill"] = prescRefll;
        return rxInfo;
    }
    // step-3
    async getPriceCalculationValue(rxInfo, Type: string, isPartialFill) {
        let result = null;
        if (rxInfo.PrescReFill.DispQty && +rxInfo.PrescReFill.DispQty !== 0) {
            const dispQty = isPartialFill ? +rxInfo.TotalRemainingQty: +rxInfo.PrescReFill.OrdQty; // DispQty;;
            const inputData = new RxBillingCalculationInput();
            inputData.Discount = rxInfo.PrescReFill.DiscSchId;
            inputData.DrugId = rxInfo.Drug.id;
            inputData.OtherAmount = rxInfo.PrescReFill.OtherAmnt;
            inputData.PriceScheduleId = rxInfo.PrescReFill.PriceSchId;
            inputData.QuantityDispense = dispQty;
            inputData.BucketId = +rxInfo.PrescReFill.BucketId;
            if (Type === "PriceSch" ||  rxInfo.rxType === "er") {
                inputData.TypeOfCalculation = "PriceScheduleId";
            } else {
                inputData.TypeOfCalculation = "Drug";
            }
            inputData.PatientId = rxInfo.Patient.patientid;
            inputData.InsuranceId = rxInfo.RefillInsu.InsuId;
            inputData.prescReFillId = (rxInfo.rxType && rxInfo.rxType === "er" && rxInfo.Drug && rxInfo.Drug.drugtypeid === 2) ?
             rxInfo.PrescReFill.Id : 0;
            if (inputData.QuantityDispense) {
                result = await this._rxService.getPricingDetails(inputData).toPromise();
            }
        }
        return result;
    }
    // step-4
    async getDiscDtlsNdCalc(rxInfo, discntCodeList) {
        if (rxInfo.PrescReFill.DiscSchId) {
            const discode: any = discntCodeList.filter(x => x.Id === +rxInfo.PrescReFill.DiscSchId)[0];
            const resp = await this._rxService.getRxDiscount(discode.DiscCode).toPromise();
            if (resp) {
                return this.assignToDiscountSchId(rxInfo, resp);
            }
        } else {
            return this.assignToDiscountSchId(rxInfo, null);
        }
    }
    // step-5
    assignToDiscountSchId(rxInfo, result) {
        const prescReFill = rxInfo.PrescReFill;
        const rxBill = rxInfo.RxBill;
        let TotPrice = prescReFill.TotalAmt;
        let balDue = prescReFill.BalDue;

        if (TotPrice !== undefined && TotPrice !== null && TotPrice !== "") {
            TotPrice = parseFloat(TotPrice);
        }

        if (balDue !== undefined && balDue !== null && TotPrice !== "") {
            balDue = parseFloat(balDue);
        }

        if (result !== null && result !== false && rxInfo["Drug"]["isdiscountable"]) {
            let DiscountAmount = 0;
            let Copay = 0;
            let tamt = 0;
            let BalDue = 0;
            let DiscountAmtTemp = 0;
            DiscountAmount = result.DiscountAmount;
            Copay = rxBill.CoPayChrg;

            if (DiscountAmount === undefined || DiscountAmount === null || isNaN(DiscountAmount)) {
                DiscountAmount = 1;
            }
            if (!Copay) {
                Copay = 0;
            }
            if (result.DiscountType === "P") {
                if (DiscountAmount !== 0) {
                    DiscountAmount =
                        parseFloat(TotPrice) * (DiscountAmount / 100);
                }
            } else {
                DiscountAmount = Math.ceil(DiscountAmount * 100) / 100;
            };
            prescReFill["Discount"] = DiscountAmount.toFixed(2);
            DiscountAmtTemp = DiscountAmount + Copay;

            if (TotPrice > DiscountAmtTemp) {
                tamt = TotPrice - DiscountAmtTemp;
                BalDue = balDue - DiscountAmtTemp;
                BalDue = +BalDue > 0 ? +BalDue : 0;
                tamt = +tamt > 0 ? +tamt : 0;
                prescReFill["TotalAmt"] = tamt.toFixed(2);
                prescReFill["BalDue"] = BalDue.toFixed(2);
            } else {
                prescReFill["TotalAmt"] = "0.00";
                prescReFill["BalDue"] = "0.00";
            }
        } else {
            prescReFill["TotalAmt"] = TotPrice ? TotPrice.toFixed(2) : "0.00";
            prescReFill["BalDue"] = balDue ? balDue.toFixed(2) : "0.00";
            prescReFill["Discount"] = (0).toFixed(2);
        }
        rxInfo.PrescReFill = prescReFill;
        return rxInfo;
    }
    // step-6
    async initiateManualCal(rxInfo, resp) {
        // this.rxBillingUtil.getPriceCalculationValue(rxInfo, type);
         let patient = rxInfo.Patient;
         let insuList = null;
         let RxOtherAmount = rxInfo.RxOtherAmount;
         const prescRefill = rxInfo["PrescReFill"];
         if (patient.insurance && patient.insurance.length > 0) {
             insuList = patient.insurance.filter(insu => insu.insuranceid === +rxInfo.RefillInsu.InsuId)[0];
         }
         if (insuList && insuList.length === 0) {
             insuList = patient.insurance[0];
         }
         if (insuList && insuList.insuplanId) {
             const insurancSettings  = await this._insuSvc.getInsSettingsInfoForRx(insuList.insuplanId);
             if (insurancSettings && insurancSettings.InsuPlanPrice && ((rxInfo.Drug.drugtypeid === 4 && insurancSettings.InsuPlanPrice.IsTaxOnOTC) ||
             (rxInfo.Drug.drugtypeid === 1 && insurancSettings.InsuPlanPrice.IsTaxOnRx))) {
                 if (RxOtherAmount.SalesTaxBasis === 2) {
                    RxOtherAmount["SalesTax"] = (+rxInfo.PrescReFill.Price && +rxInfo.PrescReFill.Price > 0) ?
                     ((+rxInfo.PrescReFill.Price * (RxOtherAmount.SalesTaxPer ? +RxOtherAmount.SalesTaxPer : 0.0)) / 100).toFixed(2) : "0.00";
                 } else if (RxOtherAmount.SalesTaxBasis === 1 || RxOtherAmount.SalesTaxBasis === 3) {
                     const profPlusPrice = (rxInfo.PrescReFill.Price ? +rxInfo.PrescReFill.Price : 0.0) +
                      (rxInfo.Drug.drugtypeid === 4 ? (resp && resp.OrigProFee ? (rxInfo["rxType"] === 'er' ? rxInfo['PrescReFill']['OrigProfFee'] : resp.OrigProFee) : 0.0) :
                       (rxInfo.PrescReFill.ProfFee ? +rxInfo.PrescReFill.ProfFee : 0.0));
                     RxOtherAmount["SalesTax"] = (profPlusPrice && +profPlusPrice > 0) ? ((+profPlusPrice * (RxOtherAmount.SalesTaxPer ? +RxOtherAmount.SalesTaxPer : 0.0))/ 100).toFixed(2) : "0.00";
                 }
             }
         } else
            RxOtherAmount["SalesTax"] = "0.00";

         let tax = RxOtherAmount ? ((RxOtherAmount.FlatSalesTax ? +RxOtherAmount.FlatSalesTax : 0) + (RxOtherAmount.SalesTax ? +RxOtherAmount.SalesTax : 0)) : 0;
         rxInfo["TaxAmt"] = tax > 0 ? tax.toFixed(2) : "0.00";

         let otherAmount = 0;
         if (RxOtherAmount && RxOtherAmount.OtherAmntMisc && RxOtherAmount.OtherAmntMisc.length > 0) {
             otherAmount = (RxOtherAmount.OtherAmntMisc[0] ? +RxOtherAmount.OtherAmntMisc[0].Amount : 0)
                 + (RxOtherAmount.OtherAmntMisc[1] ? +RxOtherAmount.OtherAmntMisc[1].Amount : 0) +
                 (RxOtherAmount.OtherAmntMisc[2] ? +RxOtherAmount.OtherAmntMisc[2].Amount : 0);
         }

         let rxOthAmnt = RxOtherAmount ?
                 (RxOtherAmount.ProfFee ? +RxOtherAmount.ProfFee : 0)
                 + (RxOtherAmount.Incentive ? +RxOtherAmount.Incentive : 0)
                 + (RxOtherAmount.FlatSalesTax ? +RxOtherAmount.FlatSalesTax : 0)
                 + (RxOtherAmount.SalesTax ? +RxOtherAmount.SalesTax : 0)
                 + (otherAmount ? +otherAmount : 0) : 0;

         const tamt = (+rxOthAmnt - (RxOtherAmount && RxOtherAmount.FlatSalesTax ? +RxOtherAmount.FlatSalesTax : 0)) +
             (prescRefill.Price ? +prescRefill.Price + 0 : 0)
             + (prescRefill.ProfFee ? +prescRefill.ProfFee : 0);
             prescRefill["TotalAmt"] = +tamt > 0 ? (+tamt).toFixed(2) : "0.00";

         const balDue = ((prescRefill.Price ? +prescRefill.Price : 0) +
             (prescRefill.ProfFee ? +prescRefill.ProfFee : 0) +
             (rxInfo.OtherFee ? +rxInfo.OtherFee : 0) +
             (prescRefill.OtherAmnt ? +prescRefill.OtherAmnt : 0) +
             (tax ? +tax : 0)) -
             (prescRefill.Discount ? +prescRefill.Discount : 0);
             if (prescRefill["IsManuaBalanceDue"]) {
            } else {
                prescRefill["BalDue"] = balDue > 0 ? balDue.toFixed(2) : "0.00";
            }
            const proftamt =  (prescRefill["BalDue"] ? +prescRefill["BalDue"] : 0) -
            (rxInfo.PrescReFill.TotalRxCost ? +rxInfo.PrescReFill.TotalRxCost : 0);
            rxInfo["ProfitAmnt"] = +proftamt ? proftamt.toFixed(2) : "0.00";
            rxInfo["PrescReFill"] = prescRefill;
            return rxInfo;
     }
     // step-7
    async checkRxPriceVals(rxInfo) {
        const PrescRefillFG: any = rxInfo["PrescReFill"];
        // const rxInfo = rxFG.getRawValue();
        const BalDuemaxValue = 9999999999.00;
        const maxValue = 9999999999.99; // 9999999.99;
        let row = "<div class='row'> <div class='col-6'>";
        let rowClose = "</div></div>";
        let secondDiv = "</div> <div class='col-6'>";
        let messageHead = "The following values are greater than the selected range (" + maxValue + "), hence the selected <b>Rx#:"+ rxInfo.Prescription.PrescNum +"</b> cannot be processed as Quick refill.<br> ";

         if(rxInfo["rxType"] === "er") {
             messageHead = "The following values are greater than the selected range (" + maxValue + "), and will be reset to zero. Please check the values.<br> ";
        }

        messageHead = messageHead + row + "<b>Item Name</b>" + secondDiv + "<b>Calculated value</b>" + rowClose;
        let message = "";
        const InsStngs = this.insuSettings$["source"]["value"];
        if (
            InsStngs &&
            InsStngs.InsuPlanOther
        ) {
            if (
                PrescRefillFG &&
                PrescRefillFG.Price &&
                PrescRefillFG.Price > maxValue
            ) {
                // Ingredient Cost
                message +=
                    row +
                    "Ingredient Cost" +
                    secondDiv +
                    PrescRefillFG.Price +
                    rowClose;
            }
            if (
                PrescRefillFG &&
                PrescRefillFG.BalDue &&
                PrescRefillFG.BalDue > maxValue
            ) {
                message +=
                    row +
                    "Balance Due" +
                    secondDiv +
                    PrescRefillFG.BalDue +
                    rowClose;
            }
            if (
                PrescRefillFG &&
                PrescRefillFG.ProfFee &&
                PrescRefillFG.ProfFee > maxValue
            ) {
                message +=
                    row +
                    "Professional Fee" +
                    secondDiv +
                    PrescRefillFG.ProfFee +
                    rowClose;
            }
            if (PrescRefillFG && PrescRefillFG.TotalAmt > maxValue) {
                message +=
                    row +
                    "Total Price" +
                    secondDiv +
                    PrescRefillFG.TotalAmt +
                    rowClose;
            }
            if (
                PrescRefillFG &&
                PrescRefillFG.UnitPriceAWP &&
                PrescRefillFG.UnitPriceAWP > maxValue
            ) {
                message +=
                    row +
                    "AWP" +
                    secondDiv +
                    PrescRefillFG.UnitPriceAWP +
                    rowClose;
            }
            // if(PrescRefillFG.TotalRxCost > maxValue) {
            //     message += "Total Rx Cost";
            // }
            if (
                PrescRefillFG &&
                PrescRefillFG.UnitPriceUNC &&
                PrescRefillFG.UnitPriceUNC > maxValue
            ) {
                message +=
                    row +
                    "U&C" +
                    secondDiv +
                    PrescRefillFG.UnitPriceUNC +
                    rowClose;
            }
            if (rxInfo && (rxInfo.OtherAmount > maxValue  ||
                 rxInfo.TaxAmt  > maxValue))  {
                message +=
                    row +
                    "Oth. Amount + Oth.Fee + Tax" +
                    secondDiv +
                    (+rxInfo.OtherAmount)  + (+rxInfo.TaxAmt)
                    rowClose;
            }
            if (message) {
                return { messageText: messageHead + message };
            } else {
                return message;
            }
        } else {
            return message;
        }
    }
}
