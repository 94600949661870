import { RxUtils } from 'src/app/utils';
import { Subscription } from 'rxjs';
import { AlertService, PreviousRouteService } from "src/app/services";
import { Component, OnInit, HostListener } from "@angular/core";
import { CommonService, EditDrugService } from "../../../services";
import { SystemData, Drug } from "../../../models";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import * as _ from "lodash";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: "app-drug-notes",
    templateUrl: "./drug-notes.component.html"
})
export class DrugNotesComponent implements OnInit {
    drugInfo: Drug;
    drugName: string;
    drugId: number;
    drugNotesFG: FormGroup;
    drugData: any;
    originalData: any;
    sharedCancel = false;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    canDeactivateRoute = true;


    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                event.preventDefault();
                this.update();
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.CancelPopTaggle();
            }
        }
    }

    constructor(
        private _drg: EditDrugService,
        private _fb: FormBuilder,
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private _alertSer: AlertService,
        private _route: Router,
        private _auditUtils: AuditLogUtils,
        private _rxutils: RxUtils,
        private _modalService: NgbModal
    ) {
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
             if (params && params.fm) {
                 this.routeFrom = params.fm;
             }
             if (params && params.focus) {
                if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                    this.focusOnDU = params.focus;
                }
            }
         }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(params => {
            this.drugId = +params["pid"];
        }));
    }

    ngOnInit() {
        this.drugNotesFG = this._fb.group({
            Remarks: [" "]
        });
        this.getInfo();
    }
    getInfo() {
        this._drg.getDrugInfo(this.drugId).subscribe(resp => {
            this._commonServ._drugInfo$.next(resp);
            this.originalData = _.cloneDeep(resp);
            this.drugInfo = resp["Drug"];
            this.drugData = resp;
            this.drugName = this.drugInfo.Name;
            if (this.drugInfo.Remarks) {
                this.drugNotesFG.controls["Remarks"].patchValue(
                    this.drugInfo.Remarks
                );
            }
            this.drugNotesFG.markAsPristine();
            this.drugNotesFG.valueChanges.subscribe(value => {
                if (this.drugNotesFG.dirty) {
                    this.canDeactivateRoute = false;
                } else {
                    this.canDeactivateRoute = true;
                }
            });
        });
    }
    update() {
        const dataInfo = this.drugData;
        const data = this.drugNotesFG.value;
        dataInfo.Drug["Remarks"] = data.Remarks;
        this._drg.updateDrugInfo(dataInfo).subscribe(resp => {
            if (resp === 0 || resp === 3 || resp === 5) {
                this._auditUtils.getChangedValues(
                    this.originalData.Drug,
                    dataInfo.Drug,
                    "Drug Notes",
                    "Drug",
                    this.drugId
                );
                this._alertSer.success("Drug info updated successfully.");
                this.routeBackToPrevScreen("update");
            } else {
                this._alertSer.error("Drug info update unsuccessful.");
            }
        });
    }
    routeBackToPrevScreen(type?: any) {
        this.canDeactivateRoute = true;
        if (type && type === "update") {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "update", this.focusOnDU);
        } else {
            this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        }
        // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    CancelPopTaggle() {
        if (this.drugNotesFG.dirty) {
            this.sharedCancel = true;
        } else {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        }
    }

    CancelPopClose(val) {
        if (val) {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        } else {
            this.sharedCancel = false;
        }
    }

    focusOutFmCan(event) {
        if (document.getElementById("Therapeutic")) {
            event.preventDefault();
            setTimeout(() => {
            const data: any = document.getElementById("Therapeutic");
            data.focus();
            }, 10);
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
}
