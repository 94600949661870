import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener } from "@angular/core";

@Component({
    selector: "app-cancel-pop-up",
    templateUrl: "./cancel-pop-up.component.html"
})
export class CancelPopUpComponent implements OnInit {
    @ViewChild("Cancel", { static: true })
    CancelPopup: any;

    @Output()
    IsPopUpClosed = new EventEmitter<boolean>();

    modelRef: any;
    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            if (this.modelRef) {
                this.CancelEvent(false);
            }
            event.preventDefault();
        }
    }

    constructor(private _modalService: NgbModal) {}

    ngOnInit() {
        this.modelRef = this._modalService.open(this.CancelPopup, {
            centered: true,
            backdrop: false,
            keyboard: false,
            windowClass: "large--content"
        });
    }

    CancelEvent(type) {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopUpClosed.emit(type);
    }
}
