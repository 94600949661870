import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder } from "@angular/forms";
import { BucketService, InventoryService } from "src/app/services";
import { DrugPO, PaginationModel, PhysicalInventory } from "src/app/models";

@Component({
    selector: "app-physical-inventory1",
    templateUrl: "./physical-inventory.component.html",
    styles: []
})
export class PhysicalInventoryComponent implements OnInit {
    @Input() drugInfo: any;
    @Input() drugDefVal: any;

    @Output()
    CancelPhyscInven = new EventEmitter<boolean>();

    @ViewChild("PHYSICALINVEN", { static: true })
    PHYSICALINVEN: ElementRef;

    modelRef: any;
    physcInventFG: any;
    bucketSearchFil: {
        Code: any;
        Name: any;
        BucketType: any;
        SpecificType: any;
        pageNumber: number;
        pageSize: number;
    };
    bucketInfo: any[];
    totalQty: number;
    drugLotExpiryDate: any;
    paginationModel: PaginationModel = new PaginationModel();
    constructor(
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _bucketServ: BucketService,
        private _invenServ: InventoryService
    ) {
        this.createFG();
    }

    ngOnInit() {
        this.modelRef = this._modalService.open(this.PHYSICALINVEN, {
            size: "lg",
            keyboard: false
        });
        this.bucketSearchFil = {
            Code: null,
            Name: null,
            BucketType: null,
            SpecificType: null,
            pageNumber: this.paginationModel.pageNumber,
            pageSize: this.paginationModel.pageSize
        };
        this.bucketInfo = [];
        this.getBucketListByString();
        this.totalQty = 0.00;
        this.getLatestDrugLotExpiryDate();
    }

    createFG() {
        this.physcInventFG = this._fb.group({
            packs: [],
            looseQTY: [],
            bucketType: []
        });
    }

    calculatePhysicalInvent() {
        const physcInven = this.physcInventFG.getRawValue();
        const newQty = (this.drugInfo.Drug.QtyPack * +physcInven.packs) + +physcInven.looseQTY;
        this.totalQty = newQty;
        let physcInvenObj = new PhysicalInventory();
        physcInvenObj.oldQty = this.drugInfo["QtyInHand"];
        physcInvenObj.newQty = newQty;
        physcInvenObj.drugId = this.drugInfo.Drug.Id;
        physcInvenObj.bucketId = physcInven.bucketType;
        this._invenServ
            .addPhyscialInventoryData(physcInvenObj)
            .subscribe(resp => {
                if (resp) {
                    this.closeCanclPhysicalInven(false);
                }
            });
    }

    calculatePhysicInventTotalQty() {
        const physcInven = this.physcInventFG.getRawValue();
        const newQty = (this.drugInfo.Drug.QtyPack * +physcInven.packs) + +physcInven.looseQTY;
        this.totalQty = newQty;
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }

    closeCanclPhysicalInven(isCancel: boolean) {
        this.closeModal();
        this.CancelPhyscInven.emit(isCancel);
    }

    getBucketListByString(value?: string): void {
        this.bucketSearchFil.Name = value;
        this._bucketServ
            .getBucketsInfo(this.bucketSearchFil)
            .subscribe(resp => {
                if (resp) {
                    this.bucketInfo = resp;
                }
            });
    }

    getLatestDrugLotExpiryDate() {
        let drugpo = new DrugPO();
        const physcInven = this.physcInventFG.getRawValue();
        drugpo.DrugId = this.drugInfo.Drug.Id;
        drugpo.BucketId = physcInven.bucketType;
        this._bucketServ
            .getInventoryLatestDrugLotExpiryDate(drugpo)
            .subscribe(resp => {
                if (resp) {
                    this.drugLotExpiryDate = resp;
                }
            });
    }
}
