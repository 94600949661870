<div class="row bg-white login"   *ngIf="!isLoggedMultipleUser">
    <div class="offset-1 col-md-6 mt-0 padding_1_2_rem">
            <img width="216px" height="50px" src="assets/img/primeRx.png">
    </div>
    <div class="col-md-5 d-flex flex-row justify-content-end" *ngIf="!loggedIn">
        <span class="m-2">
            <app-user-guide-icon [ActiveTabId] = "'LoginPage'"></app-user-guide-icon>
        </span>
    </div>
    <div class="col-md-5 common_gray padding_2_rem margin_top_16px" *ngIf="loggedIn">
            <h3  class="text-right" ><span><a href="javascript:void(0)" class="common_gray" (click)="routingAfterLogin()">Launch application <span class="font_size_2_5rem">&#187;</span></a></span></h3>
    </div>
</div>
<div class="cust--login" *ngIf="!isLoggedMultipleUser">

    <div class="row">
       <div *ngIf="loggedIn" [ngClass]="[loggedIn ? 'col-md-12':'col-md-6']" class="welcome_eprimerx ">
            <h1  class="welcome_eprimerx2">
                Welcome to PrimeRx Cloud
            </h1>
            <p class="font_size_1_4rem eprimerx-text">
                    PrimeRx Cloud pharmacy management software is our signature technology solution for today’s efficient, patient-focused businesses.  PrimeRx Cloud will quickly become the backbone of your pharmacy and serve as the centralized processing center for all workflow operations. <br> <br>

                    The PrimeRx Cloud system is highly user-friendly and offers a complete suite of tools that can be adapted to meet your pharmacy’s specific needs.  Many of these features are unique to PrimeRx Cloud and are unmatched by our competitors.  PrimeRx Cloud stands apart in the pharmacy software category as an intelligent, comprehensive management solution. <br> <br>

                    PrimeRx Cloud is ideal for independent retail pharmacies and for multiple-pharmacy managers.  PrimeRx Cloud is also ideal for outpatient hospital pharmacies with customized workflows and automated fill, bill, and communication processes. PrimeRx Cloud is a pharmacy HIPAA-compliant, pharmacy management solution.
            </p>

        </div>

        <div *ngIf="!loggedIn" class="coffset-3 col-md-6 offset-3">
            <div class="login-box-body">
                <div class="col-12 padding-0" *ngIf="TimeOutSession">
                    <ngb-alert type="custom" [dismissible]="false">
                         <strong>Session has timed out. Please login to continue.</strong>
                      </ngb-alert>
                </div>                
                <div class="col-12 padding-0" *ngIf="errorMessage">
                    <ngb-alert type="custom" [dismissible]="false">
                         <strong>{{errorMessage}}</strong>
                      </ngb-alert>
                </div>
                <div class="login-logo">
                    <h1>SIGN IN</h1>
                </div>
                <div class="col-md-12 padding-0 custm--input">
                    <eprx-input [RxSelectId]="'login1'" [LabelText]="'Pharmacy NPI#'" PlaceHolder="Pharmacy NPI#"
                        [ControlName]="'PharmacyId'" [FormGroupName]="LoginFrmGrp" [AutoFocus]="pharmacyID ? false : true" [TabIndex]="1"
                        (TriggerOnEnterValue)="keytab($event, LoginFrmGrp, 'PharmacyId')" [MaskPattern]="'0999999999'">
                    </eprx-input>
                </div>
                <div class="col-md-12 padding-0 custm--input">
                    <eprx-input [RxSelectId]="'login2'" [LabelText]="'login.Pharmacist User Name' | translate" PlaceHolder="Pharmacist's User Name"
                        [ControlName]="'PharmUsername'" [FormGroupName]="LoginFrmGrp" [TabIndex]="2"
                        (TriggerOnEnterValue)="keytab($event, LoginFrmGrp, 'PharmUsername')" [AutoFocus]="pharmacyID ? true : false">
                    </eprx-input>
                </div>
                <div class="col-md-12 padding-0 custm--input">
                    <eprx-input [RxSelectId]="'login3'" [LabelText]="'login.Password' | translate" PlaceHolder="Password"
                        [ControlName]="'PharmPassword'" [FormGroupName]="LoginFrmGrp" [InputType]="'password'" [TabIndex]="3"
                        (TriggerOnEnterValue)="keytab($event, LoginFrmGrp, 'PharmPassword')">
                    </eprx-input>
                </div>
                <div class="col-md-12 padding-0 custm--input">
                    <eprx-input [RxSelectId]="'login4'" [LabelText]="'login.Technician User Name' | translate" PlaceHolder="Technician's User Name"
                        [ControlName]="'TechUsername'" [FormGroupName]="LoginFrmGrp" [TabIndex]="4"
                        (TriggerOnEnterValue)="keytab($event, LoginFrmGrp, 'TechUsername')">
                    </eprx-input>
                </div>
                <div class="col-md-12 padding-0 custm--input">
                    <eprx-input [RxSelectId]="'login5'" [LabelText]="'login.Password' | translate" PlaceHolder="Password"
                        [ControlName]="'TechPassword'" [FormGroupName]="LoginFrmGrp" [InputType]="'password'" (keydown.enter)="login($event)"
                        [TabIndex]="LoginFrmGrp?.value?.TechUsername ? 5 : -1">
                    </eprx-input>
                </div>
                <div class="col-md-12 padding-0 text-center">
                    <button class="submit" id="loginSubmt" [tabindex]="LoginFrmGrp?.value?.TechUsername ? '6' : '5'" (click)="login($event)" (keydown.enter)="login($event)">{{'login.Sign in' | translate}}
                    </button>
                </div>
            </div>

        </div>

    </div>
    <div class="row" *ngIf="loggedIn" >
        <div class="offset-1 col-5  px-0">
            <div class="margin_top_2rem">
                <button class="secondary extra_info" [tabindex]="-1">
                    <a href="https://www.micromerchantsystems.com/software/primerx"  target="_blank"> Know more</a></button>
                <button class="secondary extra_info" [tabindex]="-1">
                    <a href="https://www.micromerchantsystems.com/schedule-a-demo"  target="_blank">Contact Us</a>
                </button>
            </div>
        </div>
    </div>
    <div class="row " [ngClass]="loggedIn?'custom_margin_for_login': ''" >
        <div class="offset-4 col-md-4 offset-3 text-center">
            {{'login.Copyright' | translate}}
        </div>
    </div>

</div>


<!-- <div class="row">
    <div class="col-md-8 cust--login">

    </div>
            <div class="col-md-4 cust--login">
                    <div class="login-box-body" [formGroup]="LoginFrmGrp">
                      <div class="login-logo">
                        <img src="assets/img/Logo.png">
                      </div>
                      <div class="col-md-12 padding-0 custm--input">
                          <eprx-input [RxSelectId]="'login1'" [LabelText]="'login.Pharmacist User Name' | translate" PlaceHolder="Pharmacist's User Name" [ControlName]="'PharmUsername'"
                              [FormGroupName]="LoginFrmGrp" [AutoFocus]="true" [TabIndex]="1" (TriggerOnEnterValue)="keytab($event, LoginFrmGrp, 'PharmUsername')">
                          </eprx-input>
                      </div>
                      <div class="col-md-12 padding-0 custm--input">
                          <eprx-input [RxSelectId]="'login2'" [LabelText]="'login.Password' | translate" PlaceHolder="Password" [ControlName]="'PharmPassword'"
                              [FormGroupName]="LoginFrmGrp" [InputType]="'password'" [TabIndex]="2" (TriggerOnEnterValue)="keytab($event, LoginFrmGrp, 'PharmPassword')">
                          </eprx-input>
                      </div>
                      <div class="col-md-12 padding-0 custm--input">
                          <eprx-input [RxSelectId]="'login3'" [LabelText]="'login.Technician User Name' | translate" PlaceHolder="Technician's User Name" [ControlName]="'TechUsername'"
                              [FormGroupName]="LoginFrmGrp" [TabIndex]="3" (TriggerOnEnterValue)="keytab($event, LoginFrmGrp, 'TechUsername')">
                          </eprx-input>
                      </div>
                      <div class="col-md-12 padding-0 custm--input">
                          <eprx-input [RxSelectId]="'login4'" [LabelText]="'login.Password' | translate" PlaceHolder="Password" [ControlName]="'TechPassword'"
                              [FormGroupName]="LoginFrmGrp" [InputType]="'password'" (keydown.enter)="login()" [TabIndex]="4">
                          </eprx-input>
                      </div>
                      <div class="col-md-12 padding-0 text-center">
                        <button class="submit" (click)="login()" (keydown.enter)="login()">{{'login.Sign in' | translate}}
                        </button>
                      </div>
                      <div class="col-md-12 padding-0 text-center">
                            {{'login.Copyright' | translate}}
                        </div>
                    </div>
                  </div>
</div> -->
