import { RxService } from "./../../../services/rx.service";
import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    HostListener
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { UserService } from "../../../services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {CredentialsHeaderNames} from "../../../modules/rx-shared/nrx.constants";

@Component({
    selector: "app-login-verify",
    templateUrl: "./login-verify.component.html"
})
export class LoginVerifyComponent implements OnInit {
    modalRef: any;
    @ViewChild("DRUGVERIFY", { static: true })
    DRUGVERIFY: any;
    @Output()
    IsPopUpClosed = new EventEmitter();
    loginTab: any;
    drugVerify = false;
    patientDrugVerify = false;
    LoginVerifyFG: FormGroup;
    rphVerify = false;
    rxVerify = false;
    rphFromPat = false;
    rxFromPat = false;
    LabelText: string;
    formGroupInvalid = false;
    InvalidPassword = false;
    page: any;
    TechName: string;
    PharmName: string;
     UserLocked = false;
     unsubscribe$: Subject<void> = new Subject();
    fromRxAndPatient = false;
    warnMessage :string = "";
    
    @Output()
    setPopUpValue = new EventEmitter();
   

    @Input()
    set FromPage(data: any) {
        this.page = data;
        if (this.page === "dashboardDrug") {
            if (sessionStorage.getItem("drug-pick")) {
                this._router.navigate(["eprx/DrugPickVerification/"], {
                    queryParams:
                    {
                        from: "Dashboard" ,
                    }
                });
                this.emitPopUpValue();
            } else {
                this.drugVerify = true;
                this.createFG();
                this.openDrugVerify();
            }
        }
        else if (this.page === "dashboardRx") {

            if (sessionStorage.getItem("rx-verify")) {
                this._router.navigate(["eprx/RxVerification/"], {
                    queryParams:
                    {
                        from: "Dashboard",
                    }
                });
                this.emitPopUpValue();
            } else {
                this.rxVerify = true;
                this.rxFromPat = true;
                this.createFG();
                this.openDrugVerify();
            }
        }
        else if (this.page !== "PatientDrugPickVerification") {
            if (sessionStorage.getItem("rph-verify")) {
                this._router.navigate(["eprx/RphVerification/"], {
                    queryParams:
                    {
                        from: "Dashboard",
                    }
                });
                this.emitPopUpValue();
            } else {
                this.rphVerify = true;
                this.rphFromPat = true;
                this.createFG();
                this.openDrugVerify();
            }
        }
    }

    @Input()
    set LoginTab(data: any) {
        this.loginTab = data;
        if (this.loginTab === "DrugPickVerification") {
            if (sessionStorage.getItem("drug-pick")) {
                this._router.navigate(["eprx/DrugPickVerification"]);
                this.emitPopUpValue();
            } else {
                this.drugVerify = true;
                this.createFG();
                this.openDrugVerify();
            }
        }  else if (this.loginTab === "PatientDrugPickVerification") {
            if (sessionStorage.getItem("drug-pick")) {
                this.emitPopUpValue();
            } else {
                this.patientDrugVerify = true;
                this.createFG();
                this.openDrugVerify();
            }
        } else if (this.loginTab === "ReviewDrugPick") {
            if (sessionStorage.getItem("drug-pick")) {
                this._router.navigate(["eprx/DrugPickVerification/review"]);
                this.emitPopUpValue();
            } else {
                this.drugVerify = true;
                this.createFG();
                this.openDrugVerify();
            }
        } else if (this.loginTab === "RphVerification") {
            if (sessionStorage.getItem("rph-verify")) {
                if (!this.fromRxAndPatient) {
                    this._router.navigate(["eprx/RphVerification"]);
                }
                this.emitPopUpValue();
            } else {
                this.rphVerify = true;
                this.rphFromPat = true;
                this.createFG();
                this.openDrugVerify();
            }
        } else if (this.loginTab === "RxVerification") {
            if (sessionStorage.getItem("rx-verify")) {
                this._router.navigate(["eprx/RxVerification"]);
                this.emitPopUpValue();
            } else {
                this.rxVerify = true;
                this.rxFromPat = true;
                this.createFG();
                this.openDrugVerify();
            }
        } else {
            if (sessionStorage.getItem("rph-verify")) {
                if (this.loginTab==="ReviewRph") {
                    this._router.navigate(["eprx/RphVerification/review"]);
                } else {
                    this._router.navigate(["eprx/RphVerification"]);
                }
                this.emitPopUpValue();
            } else {
                this.rphVerify = true;
                this.createFG();
                this.openDrugVerify();
            }
        }
    }

    @Input()
    set FromRxAndPatient(bl: boolean) {
        this.fromRxAndPatient = bl;
    }

    @Input() FromVerftabPage: boolean;
    @Input() HeaderName: boolean;
    constantHeaderNames= CredentialsHeaderNames;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) { // esc
            if (this.modalRef) {
                this.closePopUp();
                event.preventDefault();
            }
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _router: Router,
        private _rxService: RxService,
        private _fb: FormBuilder,
        private _userService: UserService
    ) {
        // this.setPopUpValue.emit(false);
    }

    ngOnInit() {}

    createFG() {
        this.LoginVerifyFG = this._fb.group({
            UserName: null,
            Password: null
        });
    }

    get Password(): FormControl {
        return this.LoginVerifyFG.get("Password") as FormControl;
    }

    openDrugVerify() {
        this.PharmName = this._userService.getToken("PhUserName");
        this.TechName = this._userService.getToken("TechUserName");
        if (!this.TechName) {
            this.LoginVerifyFG.controls["UserName"].patchValue(this.PharmName);
            this.LabelText = "Pharmacist Initials";
        } else if (this.TechName) {
            // if (this.drugVerify || this.patientDrugVerify) {
                this.LoginVerifyFG.controls["UserName"].patchValue(this.TechName);
                this.LabelText = "Technician Initials";
            // } else {
            //     this.LoginVerifyFG.controls["UserName"].patchValue(null);
            //     this.LabelText = "Pharmacist Initials";
            // }
        }
        this.modalRef = this._modalService.open(this.DRUGVERIFY, {
                backdrop: false,
                keyboard: false,
                centered: true,
        });
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        if (this.page === "dashboardDrug") {
            this._router.navigate(["eprx/DrugPickVerification/"], {
                queryParams:
                {
                    from: "Dashboard" ,
                },
            });
        } else if (this.page === "dashboardRph") {
            this._router.navigate(["eprx/RphVerification/"], {
                queryParams:
                {
                    from: "Dashboard",
                }
            });
        }
        else if (this.page === "dashboardRx") {
            this._router.navigate(["eprx/RxVerification/"], {
                queryParams:
                {
                    from: "Dashboard",
                }
            });
        }
         else if (this.drugVerify && !this.FromVerftabPage) {
            if (this.loginTab === "ReviewDrugPick") {
                this._router.navigate(["eprx/DrugPickVerification/review"]);
            } else {
                this._router.navigate(["eprx/DrugPickVerification"]);
            }
        } else if (this.page === "RphDashboard") {
            //
        } else if (this.fromRxAndPatient) {
            //
        } else if (!this.patientDrugVerify && !this.FromVerftabPage) {
            if (this.loginTab === "ReviewRph") {
                this._router.navigate(["eprx/RphVerification/review"]);
            } else if (this.rxVerify) {
                this._router.navigate(["eprx/RxVerification"]);
            } else if (this.rphVerify)  {
                this._router.navigate(["eprx/RphVerification"]);
            }
        }
        this.emitPopUpValue();
    }

    verifyPassword() {
        this.warnMessage = "";
        if (this.LoginVerifyFG.valid) {
            this._rxService
                .checkTechicianPassword(
                    this.LoginVerifyFG.value.Password,
                    this.LoginVerifyFG.value.UserName
                )
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp && resp.Status === 1) {
                        if (this.drugVerify || this.patientDrugVerify) {
                            sessionStorage.setItem("drug-pick", resp.Jtoken);
                        } else if (this.rphVerify) {
                            sessionStorage.setItem("rph-verify", resp.Jtoken);
                        } else if (this.rxVerify) {
                            sessionStorage.setItem("rx-verify", resp.Jtoken);
                        }
                        this.closeModal();
                    } else {
                        if (resp && resp.Status ===  2) {
                            this.warnMessage = resp.IsAdminUser ?
                            "Admin user account is locked due to multiple attempts with wrong password. Please contact support team to unlock your account."  :
                            "Pharmacist/Technician user account is locked due to multiple attempts with wrong password. Please contact your Admin to unlock your account.";
                            if(document.getElementById("loginPwd"))
                                document.getElementById("loginPwd").focus();
                        } else if (resp && resp.Status ===  3) {
                            this.warnMessage = "Invalid User."
                            this.LoginVerifyFG.controls.UserName.reset();
                            if(document.getElementById("user"))
                                document.getElementById("user").focus();
                        } else {
                            this.warnMessage = "Please enter correct password.";
                            if(document.getElementById("loginPwd"))
                                document.getElementById("loginPwd").focus();
                        }
                        this.LoginVerifyFG.controls.Password.reset();
                        
                    }
                });
        } else {
            this.formGroupInvalid = true;
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closePopUp() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.emitPopUpValue();
    }

    emitPopUpValue() {
        this.setPopUpValue.emit(false);
    }
}
