<ng-template #BATCHEDIT  let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Rx Batch Edit </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click'); closeBatchEditPopup()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="rxtransfer--tabing navTabStyles">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs"
                    (tabChange)="beforeChange($event?.nextId)" #BatchEditTabs >
                    <li [ngbNavItem]="'Patient1'"  (click)="beforeChange('Patient1')">
                        <a ngbNavLink class="nav-link" id="Patient1">
                            <div class="txt">
                                <div>Rx</div>
                            </div>
                        </a>
                    </li>
                    <li [ngbNavItem]="'Patient2'"  (click)="beforeChange('Patient2')" *ngIf="!hasDurVal">
                        <a ngbNavLink class="nav-link">
                            <div class="txt">
                                <div >DUR </div>
                            </div>
                        </a>
                    </li>
                </ul>
            <div class="row">
                <div class="col-11 max-width_90_pr">
                    <div class="eprx--block__content" *ngIf="activeTabId === 'Patient1'">
                        <div class="editpatient--body newrx--body edit--drug edit--drug">
                            <div class="exprx--common-block">
                                <div class="eprx--block__header ">
                                    <div class="row">
                                        <div class="col">
                                            <span class="eprx--header__heading"> Rx </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-3">
                                            <eprx-select
                                                [RxSelectId]="'rxReFillNum'"
                                                [LabelText]="'Auth Refills'"
                                                [PlaceHolder]="''"
                                                [ControlName]="'AuthRefills'"
                                                [FormGroupName]="batchFG"
                                                [List]="this.authRefl$ | async"
                                                [HasMultiple]="false"
                                                [BindLabel]="'Name'"
                                                [BindValue]="'Id'"
                                                [LabelForId]="'Name'"
                                                [IsDisabled]="authRefDis"
                                                [AutoFocus]="true"
                                            >
                                            </eprx-select>
                                        </div>
                                        <div class="col-3">
                                            <eprx-date-picker [RxSelectId]="'rxBOrderDtTm'" [LabelText]="'Date Ordered'" [ControlName]="'OrderDtTm'"
                                              [IsDisabled]="authRefDis"  [FormGroupName]="batchFG" (TriggerOnEnterValue)="checkDateValidation('OrderDtTm')"
                                                (TriggerOnTabbing)="checkDateValidation('OrderDtTm')" [AutoFocus]="authRefDis"
                                                (TriggerOnDateSelect)="checkDateValidation('OrderDtTm')"></eprx-date-picker>
                                        </div>
                                        <div class="col-3">
                                            <eprx-date-picker [RxSelectId]="'rxBFillDtTm'" [LabelText]="'Fill Date'" [ControlName]="'FillDtTm'" [AutoFocus]="authRefDis ? false : true"
                                                [FormGroupName]="batchFG" (TriggerOnEnterValue)="checkDateValidation('rxBFillDtTm')"
                                                (TriggerOnTabbing)="checkDateValidation('rxBFillDtTm')"
                                                (TriggerOnDateSelect)="checkDateValidation('rxBFillDtTm')"></eprx-date-picker>
                                        </div>
                                        <div class="col-3">
                                            <eprx-select class="width_87per" [RxSelectId]="'rxOrdStatus'" [LabelText]="'Ord.Status'" [PlaceHolder]="''"
                                                [ControlName]="'OrderStatus'" [TabIndex]="-1" [FormGroupName]="batchFG"
                                                [List]="[{Id: 'C', Name: 'Current'}, {Id:'D', Name: 'Discontinued'}]" [HasMultiple]="false" [ShowLabel2]="true"
                                                [BindLabel2]="'Name'" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Id'">
                                            </eprx-select>
                                        </div>
                                        <div class="col-3">
                                            <eprx-input [RxSelectId]="'rxBPH'" [LabelText]="'R.PH'" [PlaceHolder]="''" [ControlName]="'RPH'"
                                                [FormGroupName]="batchFG"  (TriggerOnTabbing)="checkIsValidPharm($event)" (TriggerOnEnterValue)="checkIsValidPharm($event)"
                                                >
                                            </eprx-input>
                                        </div>
                                        <div class="col-3">
                                            <eprx-input [RxSelectId]="'rxBTechInitials'" [LabelText]="'Tech Init'" [PlaceHolder]="''" [ControlName]="'Tech'"
                                                [FormGroupName]="batchFG"  (TriggerOnEnterValue)="techCheck($event)" (TriggerOnTabbing)="techCheck($event)">
                                            </eprx-input>
                                        </div>
                                        <div class="col-3">
                                            <eprx-select [RxSelectId]="'rxInsuId'" [LabelText]="'Bill To'" [PlaceHolder]="''" [ControlName]="'InsuranceId'"
                                                [FormGroupName]="batchFG"
                                                [List]="(selectedList[0]?.Patient?.insurance | filterInsurance)" [HasMultiple]="false" [BindLabel]="'insuType'"
                                                [BindLabel2]="'insurancename'" [BindValue]="'insuranceid'"
                                                [LabelForId]="'insuranceid'" [secondCntrlN]="'InsuranceCode'" [bindScnCntrlV]="'insuType'">
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="exprx--common-block">
                                <div class="eprx--block__header ">
                                    <div class="row">
                                        <div class="col">
                                            <span class="eprx--header__heading"> Miscellanious </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-3">
                                            <eprx-select [LabelText]="'Subm. Clarification 1'" [PlaceHolder]="''" [ControlName]="'SubClarificationId'"
                                                [FormGroupName]="batchFG" [List]="systemData?.subClarificationData" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                                                [IsTitle]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [LabelTitle1]="'Name'" [LabelTitle2]="'Remarks'"
                                                [Show2Values]="true" [secondCntrlN]="'SubClarificationName'" [bindScnCntrlV]="'Remarks'">
                                            </eprx-select>
                                        </div>
                                        <div class="col-2">
                                            <eprx-select [LabelText]="'Rx Origin Code'" [PlaceHolder]="''" [ControlName]="'RxOriginId'" [FormGroupName]="batchFG"
                                                [List]="systemData?.rxOriginsData" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Id'"
                                                [LabelTitle1]="'Code'" [LabelTitle2]="'Description'" [ShowLabel2]="true" [secondCntrlN]="'RxOriginName'" [bindScnCntrlV]="'Name'">
                                            </eprx-select>
                                        </div>
                                        <div class="col-3">
                                            <eprx-select [LabelText]="'Other Coverage Cd'" [PlaceHolder]="''" [ControlName]="'otherCoverageId'"
                                                [FormGroupName]="batchFG" [List]="systemData?.otherCoverageData" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                                                [BindValue]="'Id'" [IsTitle]="true" [LabelForId]="'Id'" [LabelTitle1]="'Name'" [LabelTitle2]="'Remarks'"
                                                [Show2Values]="true" [bindScnCntrlV]="'Remarks'" [secondCntrlN]="'OtherCoverageName'">
                                            </eprx-select>
                                        </div>
                                        <div class="col-2 height_0_5rem">
                                            <eprx-radio [LabelText]="'Change RX Status to Deferred'" [ControlName]="'ChangeStatusToFiled'" [FormGroupName]="batchFG"
                                                [IsRequired]="true" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                                [IDForList]="['TFB', 'FFB']" [LabelForId]="" (TriggerSelectValue)="changeCheckFiled()">
                                            </eprx-radio>
                                        </div>
                                        <div class="col-2">
                                            <eprx-date-picker
                                                [RxSelectId]="'duedateDT'"
                                                [LabelText]="'Due Date'"
                                                [ControlName]="'DueDate'"
                                                [FormGroupName]="batchFG"
                                                [IsDisabled]="!batchFG?.value['ChangeStatusToFiled']"
                                            ></eprx-date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="eprx--block__content" *ngIf="activeTabId === 'Patient2'">
                        <div class="editpatient--body newrx--body edit--drug edit--drug">
                            <div class="exprx--common-block">
                                <div class="eprx--block__header ">
                                    <div class="row">
                                        <div class="col">
                                            <span class="eprx--header__heading">
                                                DUR Values </span>
                                        </div>
                                        <div class="col pull-right text-right">
                                            <button aria-hidden="true" class="hotkey_success hotkey_primary--Override"
                                                autofocus title="Add" (click)="addPopUp(DURADD)" appShortcutKey
                                                [AltKey]="'a'"><span>A</span>ADD DUR</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="eprx--block__content" *ngIf="totalCount">
                                    <wj-flex-grid #DURlist [headersVisibility]="'Column'" [itemsSource]="DURWJ"
                                        [isReadOnly]="true" [columnPicker]="''" [selectionMode]="'Row'">
                                        <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                                            *ngFor="
                                                                    let actvHeader of actvHeaders;
                                                                    let i = index
                                                                " [width]="'2*'">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="actvHeader === 'Actions'" class="text-center">
                                                    <i class="far fa-edit   actions" title="edit" (click)="editNewRxPopUpModal( DURADD,
                                                        item,
                                                        item.value)"></i>
                                                    <i class="far fa-trash-alt  actions danger-actve" title="delete"
                                                        (click)="openNewRxDeleteModal(delete, item.value)"></i>
                                                </div>
                                                <div *ngIf="!(actvHeader === 'Actions' || actvHeader === 'Id Q' || actvHeader === 'Agent Id')"
                                                    title={{item[actvHeader]}}>
                                                    {{ item[actvHeader] }}
                                                </div>
                                                <div
                                                    *ngIf="(actvHeader !== 'Actions' && (actvHeader === 'Id Q' || actvHeader === 'Agent Id'))">
                                                    {{ item[actvHeader] }}
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </div>
                                <div class="eprx--block__content text-center" *ngIf="!totalCount">
                                    DUR infomation not available to show
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mw-13 pl-0 font_size_medium">
                    <span>Selected Rx List</span>
                    <div class="eprx--block__content batchrefill max-height_23_8_rem overflow_y_scroll" *ngIf="selectedRxs?.length > 1">

                        <ul class="refill-inProgress">

                            <li *ngFor="let rxno of selectedRxs; let i = index" class="refill-prog--li notinProgress--refill">
                                {{ rxno }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>


        <!-- <span>
            <div class="eprx--block__content batchrefill grpTransm-inprogress" *ngIf="selectedRxs?.length > 1">
                <b>Selected Rx List</b>
                <ul class="refill-inProgress">
                    <li *ngFor="let rxno of selectedRxs; let i = index"
                        class="refill-prog--li notinProgress--refill">
                        {{ rxno }}
                    </li>
                </ul>
            </div>
        </span> -->
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-12 padding-0">
            <div>
                <button class="hotkey_success" (click)="saveBatchEdit()" (keydown.enter)="saveBatchEdit()" appShortcutKey
                [AltKey]="'s'"><span>S</span>Save</button>
                <button class="hotkey_success" (click)="d('Cross click'); closeBatchEditPopup('cancel')" (keydown.enter)="d('Cross click'); closeBatchEditPopup('cancel')" appShortcutKey
                [AltKey]="'c'"><span>C</span>Cancel</button>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>{{warningText?.deleteConfrmMsg}}</span>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-12 padding-0">
            <div>
                <button autofocus class="hotkey_primary" (click)="d('Cross click')" (click)="deleteNewRxDur()"
                    (keydown.enter)="deleteNewRxDur(); d('Cross click')" appShortcutKey InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" (keydown.enter)="d('Cross click')"
                    appShortcutKey InputKey="n"><b>N</b>NO</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #DURADD let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">DUR Entry</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body box-body modal-dropdown-overflow" [formGroup]="DurFG">
        <div class="box box-default box-solid">
            <div class="eprx--block__header">
                <span class="eprx--header__heading"></span>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [IsFirstElem]="true" [LabelText]="'Reason For Service'" [PlaceHolder]="''"
                            [ControlName]="'ServReasonId'" [FormGroupName]="DurFG" [secondCntrlN]="'ServReasonName'"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'"
                            [LabelForId]="'Id'" [bindScnCntrlV]="'Name'" [List]="systemData?.servReason"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7">
                        <eprx-select [LabelText]="'Professional Service'" [PlaceHolder]="''"
                            [ControlName]="'ProfServId'" [FormGroupName]="DurFG" [secondCntrlN]="'ProfServName'"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'"
                            [LabelForId]="'Id'" [bindScnCntrlV]="'Name'" [List]="systemData?.profServs"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Result Of Service'" [PlaceHolder]="''" [ControlName]="'ServResultId'"
                            [FormGroupName]="DurFG" [secondCntrlN]="'ServResult'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [bindScnCntrlV]="'Name'" [List]="systemData?.servResult"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7">
                        <eprx-select [LabelText]="'Level Of Effort'" [PlaceHolder]="''" [ControlName]="'EffortLvlId'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [secondCntrlN]="'EffortLvlName'"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.effortLvl" [bindScnCntrlV]="'Name'"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-input [LabelText]="'Co-Agent ID'" [PlaceHolder]="''" [ControlName]="'CoAgentNum'"
                            [MaxLength]="19" [InputType]="'ALPHANUMERIC'" [FormGroupName]="DurFG"></eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7">
                        <eprx-select [LabelText]="'Co-Agent ID Type'" id="CoAgentId" [PlaceHolder]="''"
                            [ControlName]="'CoAgentIdType'" [FormGroupName]="DurFG" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Name'" [LabelForId]="'Id'"
                            [List]="systemData?.coAgentIdCat" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [IsRequired]="DurFG?.value['CoAgentNum'] !== null"
                            [MarkAsTouched]="DurFG?.controls['CoAgentIdType']?.touched">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Clinical Significance'" [PlaceHolder]="''"
                            [ControlName]="'ClinicalSignfId'" [FormGroupName]="DurFG"
                            [secondCntrlN]="'ClinicalSignfName'" [bindScnCntrlV]="'Name'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.clinicalSignf" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7" [formGroup]="DurFG">
                        <label class="align-label"> Pharmacist Comment </label>
                        <textarea class="custom--text__area" rows="1" cols="73" formControlName="PhComment"
                            placeholder="Comments"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" *ngIf="!editMode" (click)="addDUR()" appShortcutKey [AltKey]="'s'">
                    <span>S</span> Save
                </button>
                <button class="hotkey_success" *ngIf="editMode" (click)="editNewRxDUR()" appShortcutKey [AltKey]="'u'">
                    <span>U</span> Update
                </button>
                <button class="hotkey_success" (keydown.enter)="d('Cross click')" (click)="d('Cross click')"
                    appShortcutKey [AltKey]="'c'">
                    <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ORDERSUPLDAYS let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeWarnPopup()">
        <h4 header>Warning!!</h4>
        <span body> {{warnMsg}} </span>
        <button footer autofocus class="hotkey_primary" (click)="closeWarnPopup()" appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>

<ng-template #TECHPASSWORD let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="validateTechPass(savedTechPass)">
        <h4 header>Technician Password</h4>
        <span body class="rx--tech-passwrd">
            <eprx-input
                [LabelText]="''"
                [RxSelectId]="'techPass'"
                [PlaceHolder]="'Tech Password'"
                [HasControl]="false"
                [InputType]="'Password'"
                (TriggerOnChange)="SaveTechPass($event)"
                (TriggerOnBlur)="SaveTechPass($event)"
                (TriggerOnEnterValue)="validateTechPass($event)"
                [AutoFocus]="true"
            >
            </eprx-input>
        </span>
        <button footer id="privAckPasswrd"  class="hotkey_success" (keydown.enter)="validateTechPass(savedTechPass)"
        (click)="validateTechPass(savedTechPass)" appShortcutKey [AltKey]="'o'"> <span>O</span> OK </button>
        <button footer id="privAckPasswrdCancel"  class="hotkey_success" (keydown.enter)="closePasswordpopup('rxBTechInitials')"
        (click)="closePasswordpopup('rxBTechInitials')" appShortcutKey [AltKey]="'c'"> <span>C</span> CANCEL </button>
    </app-custom-modal>
</ng-template>

<ng-template #PHARMPASSWORD let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="validatePharmPass(savedPharmPass)">
        <h4 header>Pharmacist Password</h4>
        <span body class="rx--tech-passwrd">
            <eprx-input
                [LabelText]="''"
                [PlaceHolder]="'Pharmacist Password'"
                [HasControl]="false"
                [InputType]="'Password'"
                (TriggerOnChange)="SavePharmPass($event)"
                (TriggerOnBlur)="SavePharmPass($event)"
                (TriggerOnEnterValue)="validatePharmPass($event)"
                [AutoFocus]="true"
            >
            </eprx-input>
        </span>
        <button footer id="privAckPasswrd"  class="hotkey_success" (keydown.enter)="validatePharmPass(savedPharmPass)"
        (click)="validatePharmPass(savedPharmPass)" appShortcutKey [AltKey]="'o'"><span>O</span> OK </button>
    </app-custom-modal>
</ng-template>

<ng-template #WARNING let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeWarnPopupForCashRx()">
        <h4 header>Warning!!</h4>
        <span body [innerHTML]="WarnMessage">
        </span>
        <button footer type="submit" class="hotkey_primary" ngbAutofocus appShortcutKey InputKey="o"
            (keydown.enter)="closeWarnPopupForCashRx()" (click)="closeWarnPopupForCashRx()">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>