import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'eprx-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
    titleMsg: any;
    @Input()
    set Name(nm: string) {
        this.name = nm;
    }
    @Input()
    set RxselectId(nm: string) {
        this.rxID = nm;
    }
    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }
    @Input()
    set IsDisabled(ir: boolean) {
        this.isDisabled = ir;
    }
   @Input()
   set IsChecked(val) {
        this.isChecked = val;
   }
    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }
    @Input()
    set AutoFocus(ti: boolean) {
        this.autoFocus = ti;
    }

    @Input()
    set TitleMsg(tm:any){
        this.titleMsg = tm;
    }
    @Input() withOutFrmGrp: boolean;
    @Input() LabelText: boolean;
    
    @Input() IsFrom: string;
    @Output()
    TriggerSelectValue = new EventEmitter();
    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();
    @Output()
    TriggerOnEnterValue = new EventEmitter<any>();
    @Output()
    TriggerOnTabbing = new EventEmitter<any>();
    autoFocus: boolean;
    isChecked: any;
    rxID: any;
    inputFormGroup: any;
    isDisabled = false;
    name: any;
    custContrlName: any;
  constructor() { }

  ngOnInit(): void {
  }
  changeValue(event) {
    if (!this.withOutFrmGrp) {
        this.inputFormGroup.controls[this.custContrlName].patchValue(event.target.checked);
        this.TriggerSelectValue.emit(event);
    } else {
        this.TriggerSelectValue.emit(event.target.checked);
    }
    
    
}
onKeyDownEvent(event) {
    if (event.which === 89) { // y
        if (!this.withOutFrmGrp) {
            this.inputFormGroup.controls[this.custContrlName].patchValue(true);
            this.TriggerSelectValue.emit(event);
        } else {
            this.TriggerSelectValue.emit(!event.target.checked);
        }
    } else if (event.which === 78) { // n
        if (!this.withOutFrmGrp) {
            this.inputFormGroup.controls[this.custContrlName].patchValue(false);
            this.TriggerSelectValue.emit(event);
        } else {
            this.TriggerSelectValue.emit(!event.target.checked);
        }
    } else if (event.which === 13) { // enter
        if (!this.withOutFrmGrp) {
            this.inputFormGroup.controls[this.custContrlName].patchValue(!event.target.checked);
            this.TriggerOnEnterValue.emit(event);
        } else {
            this.TriggerSelectValue.emit(!event.target.checked);
        }
    } else if (event.which === 9) {
        this.TriggerOnTabbing.emit(event);
    }
}

onShiftTabbingEvent(event) {
    if (event.shiftKey && event.key === "Tab") {
        this.TriggerOnShiftTabbing.emit(event);
    }
}


}
