<div class="pdf-view-block" [id]="'pdfBlock'">
    <div class="pdf-view-cont">
        <div class="row">
            <div class="col-3 thumb-block">
                <div class="text-center">
                    <label>Double click to select</label>
                </div>
                <div class="m-auto thumb-sect d-block" [style.border]="(currentPagenumber === i+1) ? '3px solid green' : ''" *ngFor="let numberItem of numbersArray;  let i = index" (click)="simpleClickFunction(i+1,$event)" (dblclick)="doubleClickFunction(numberItem,$event)">
                    <pdf-viewer [src]="pdfData" [render-text]="false" [page]="i+1" [original-size]="true" [show-all]="false" [fit-to-page] ="true">
                    </pdf-viewer>
                    <div *ngIf="numberItem.IsSelected" class="sel-triang"></div>
                    <i *ngIf="numberItem.IsSelected" class="fas fa-check sel-check"></i>
                </div>
            </div>
            <div class="col-9 view-block" >
                <div class="row rx--his__actions ml-2">
                    <div  class="secondary-actn-btn" placement="top" ngbTooltip="Split" *ngIf="totalPages > 1">                  
                        <a class="enable--hotkey__buttons"  appShortcutKey [AltKey]="'s'" (click)="splitDoc()">
                            <span class="hotkey--buttons text-center">S</span>
                            <span class="save--buttons">Split</span> 
                        </a>
                    </div>
                    <div  class="secondary-actn-btn" placement="top" ngbTooltip="Split" *ngIf="totalPages === 1">                  
                        <a class="disable--hotkey__buttons"  appShortcutKey [AltKey]="'s'">
                            <span class="hotkey--buttons text-center">S</span>
                            <span class="save--buttons">Split</span> 
                        </a>
                    </div>
                    <div class="secondary-actn-btn" placement="top" ngbTooltip="Zoom In">
                        <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'i'" (click)="zoomSize= zoomSize + 0.2">
                            <span class="hotkey--buttons text-center">I</span>
                            <span class="save--buttons">Zoom In</span>
                        </a>
                    </div>
                    <span class="secondary-actn-btn" placement="top" ngbTooltip="Zoom Out">
                        <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'o'" (click)="zoomSize= zoomSize - 0.2">
                            <span class="hotkey--buttons text-center">O</span>
                            <span class="save--buttons">Zoom Out</span>
                        </a>
                    </span>
                    <div class="secondary-actn-btn" placement="top" ngbTooltip="Print">
                        <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'p'" (click)="printPdf()">
                            <span class="hotkey--buttons text-center">P</span>
                            <span class="save--buttons">Print</span>
                        </a>
                    </div>
                    <span class="secondary-actn-btn" placement="top" ngbTooltip="Preview">
                        <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'v'" (click)="previewPdf()">
                            <span class="hotkey--buttons text-center">V</span>
                            <span class="save--buttons">Preview</span>
                        </a>
                    </span>
                    <span class="secondary-actn-btn" placement="top" ngbTooltip="Go To Doc Queue">
                        <a class="enable--hotkey__buttons" appShortcutKey [AltKey]="'g'" (click)="goToDocQueue()">
                            <span class="hotkey--buttons text-center">G</span>
                            <span class="save--buttons">Go To Doc Q</span>
                        </a>
                    </span>
                </div>
                <div class="pdf-container view-sect">
                    <pdf-viewer [src]="pdfData" [(page)]="currentPagenumber"  [fit-to-page] ="true" [original-size]="true"
                     [show-all]="false" class="pdf_viewwer d-block"  [show-borders]="true"
                     [autoresize]="true"  [zoom]="zoomSize" (after-load-complete)="afterLoadComplete($event)">
                    </pdf-viewer>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #PDFPreviewTemp let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close')">
        <h4 header> Refill Request Fax Document </h4>
        <span body>
            <div class="col-md-12">
                <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="750"
                    type="application/pdf"></iframe>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="c('close')" appShortcutKey InputKey="c"><b>C</b> Close
        </button>
    </app-custom-modal>
</ng-template>

<app-doc-split *ngIf="showSplitModal" [DocName]="docName" [PageNos]="pagesToSplit" (IsPopUpClosed)="closeSplitModal($event)"></app-doc-split>
