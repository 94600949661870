import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class RecallDeleteRxService {
    constructor(private _http: HttpClient) {}

    getRecalledDeletedRxs(recallSearchdata: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_RecallRx_RxSearch,
            recallSearchdata
        );
    }

    recalledDeletedRx(prescRefId: any, prescNum: any, cancelRxConfirmation: any, IgnorePrescAndDrugInactiveStatus: boolean, drugDiscontinuedGracePeriodWarning : any = 0,IsDruglotmandatory): Observable<any> {
        const recallDeletedMode1 = {
            "prescRefillId" : prescRefId,
            "prescNum": prescNum,
            "cancelRxConfirmation": cancelRxConfirmation,
            "IgnorePrescAndDrugInactiveStatus": IgnorePrescAndDrugInactiveStatus,
            "drugDiscontinuedGracePeriodWarning" : drugDiscontinuedGracePeriodWarning  ? drugDiscontinuedGracePeriodWarning  : 0,
            "IsDruglotmandatory":IsDruglotmandatory,

        };
        return this._http.put<any>(constant.PUT_RecallRx_Recall, recallDeletedMode1);
    }

    recalledControlRx(prescRefId: any, prescNum: any, IgnorePrescAndDrugInactiveStatus: any = 0): Observable<any> {
        const recallDeletedMode1 = {
            "prescRefillId" : prescRefId,
            "prescNum": prescNum,
            "IgnorePrescAndDrugInactiveStatus": IgnorePrescAndDrugInactiveStatus
        };
        return this._http.put<any>(constant.PUT_RecallRx_ControlRx, recallDeletedMode1);
    }

    replaceDrug(buckData: any): Observable<any> {
        return this._http.put<any>(constant.PUT_RecallRx_ReplaceDrug, buckData);
    }

    replaceBucket(BuckData: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_RecallRx_ReplaceBucket,
            BuckData
        );
    }

    createNewPresc(prescData: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_RecallRx_CreateRx,
            prescData
        );
    }

    deleteRxOnControlRx(dataToPost: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_DeleteRx_ControlRx,
            dataToPost
        );
    }

    CheckIOCDrugorNot(ndc: any, patientState: any) {
        return this._http.get<boolean>(
            constant.GET_CheckIOCDrug.replace("{ndc}", ndc).replace("{patientState}", patientState)
        );
    }


    updateFaxRefillStatus(dataToPost: any): Observable<any> {
        return this._http.put<any>( constant.PUT_UPDATE_FAXREFILL_STATUS, dataToPost );
    }


    getRxDetailsForRecall(dataToPost: any): Observable<any> {
        return this._http.post<any>( constant.GET_RX_DETAILS_FOR_RECALL, dataToPost );
    }

    postRecallRxDetails(dataToPost: any): Observable<any> {
        return this._http.post<any>( constant.POST_RECALLRXDETAILS, dataToPost );
    }

    getRecallRxDetailsForInactDrug(payLoadData: string): Observable<any> {
        return this._http.get<any>(
            constant.GET_RECALLDETAILS_INACTDRUG.replace("{RxEsId}", payLoadData)
        );
    }

}
