import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

    showHelpText: boolean = false;
    RxDrSt: any;

    constructor(private _alertService: AlertService) { }

    ngOnInit() {
        this._alertService.getHelpText().subscribe(resp => {
            if (resp) {
                this.RxDrSt = resp;
                this.showHelpText = !this.showHelpText;
            } else {
                this.showHelpText = false;
            }
        });
    }

    closeHelpText() {
        this.showHelpText = false;
    }
}
