import { RxUtils } from 'src/app/utils/rx.util';
import { Subscription } from 'rxjs';
import { PreviousRouteService } from './../../../services/previous-route.service';
import { MsgConfig } from "src/app/app.messages";
import { SystemData } from "src/app/models";
import { AlertService, CommonService } from "src/app/services";
import { DrugCategory } from "./../../../models/edit-drug.model";
import { FormArray } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { EditDrugService } from "./../../../services/edit-drug.service";
import { Component, OnInit, IterableDiffers, OnChanges, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import * as _ from "lodash";
import { DualListComponent } from "angular-dual-listbox";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: "app-drug-category",
    templateUrl: "./drug-category.component.html"
})
export class DrugCategoryComponent  implements OnInit , OnChanges {
    source = [];
    destination = [];
    editCategory = [];
    drugId: number;
    DrugSysdata: any;
    DrugCatData: any;
    drugCategoryFG: FormGroup;
    editCategoryFG: FormGroup;
    old = [];
    systemData: SystemData;
    editCatData = [];
    addCategoryFG: FormGroup;
    addCategory: any = [];
    sharedCancel = false;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    canDeactivateRoute = true;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                event.preventDefault();
                this.EditDrugCat();
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.CancelPopTaggle();
            }
        }
    }

    constructor(
        private _actvRoute: ActivatedRoute,
        private _drgSvc: EditDrugService,
        private _fb: FormBuilder,
        private _altSvc: AlertService,
        private _auditUtils: AuditLogUtils,
        private _commonSvc: CommonService,
        private _route: Router,
        private _rxutils: RxUtils,
        private _modalService: NgbModal
    ) {
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
             if (params && params.fm) {
                 this.routeFrom = params.fm;
             }
             if (params && params.focus) {
                if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                    this.focusOnDU = params.focus;
                }
            }
         }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugId = +params["pid"])
        ));
        this.addCategoryFG = this._fb.group({
            CreatedDtTm: null,
            DrugCatId: null,
            DrugId: this.drugId,
            Id: null,
            IsActive: null,
            ModifiedDtTm: null,
            Name: null,
            TenantId: null
        });
        this.drugCategoryFG = this._fb.group({
            drugCategory: this._fb.array([
                {
                    CreatedDtTm: null,
                    DrugCatId: null,
                    DrugId: this.drugId,
                    Id: null,
                    IsActive: true,
                    ModifiedDtTm: null,
                    Name: null,
                    TenantId: null
                }
            ])
        });
        this.editCategoryFG = this._fb.group({
            DrugCatToBucket: [],
            DrugId: this.drugId
        });
        this.getdrugCat();
    }
    ngOnChanges(changes: any) {
    }

    updateCandeActivate() {
        this.canDeactivateRoute = false;
    }

    ngOnInit() {
    }
    getDataSys() {
        this._commonSvc._systemData$.subscribe(resp => {
            this.systemData = resp;
            this.DrugSysdata = this.systemData["DrugCat"];
            if (resp) {
                if (this.source.length > 0) {
                    this.source = [];
                }
                for (const key in this.systemData["DrugCat"]) {
                    if (key) {
                        this.source.push(this.systemData["DrugCat"][key].Name);
                    }
                }
            }
        });
    }
    getdrugCat() {
        this._drgSvc.getDrugCategory(this.drugId).subscribe(resp => {
            if (resp && resp["length"] > 0) {
                this.DrugCatData = resp;
                if (this.destination.length > 0) {
                    this.destination = [];
                }
                if (this.source.length > 0) {
                    this.source = [];
                }
                this.DrugCatData.map(key => {
                    this.source.push(key.Name);
                    if (key["IsActive"]) {
                        this.destination.push(key.Name);
                    }
                });
            } else {
                this.getDataSys();
            }
        });
    }
    EditDrugCat() {
        if (this.DrugCatData) {
            {
                this.DrugCatData.map(key => {
                    if (_.includes(this.destination, key.Name)) {
                        if (!key["IsActive"]) {
                            key["IsActive"] = true;
                        }
                    } else {
                        if (key["IsActive"]) {
                            key["IsActive"] = false;
                        }
                    }
                });
                this._drgSvc
                    .editDrugCategory(this.DrugCatData)
                    .subscribe(resp => {
                        if (resp) {
                            const newCategory = _.filter(this.DrugCatData, {
                                IsActive: true
                            });
                            // tslint:disable-next-line:forin
                            const newArray = new Array();
                            // tslint:disable-next-line:forin
                            for (const keys in newCategory) {
                                const categoryAdded = {
                                    Name: newCategory[keys].Name
                                };
                                newArray.push(categoryAdded);
                            }
                            this._auditUtils.getChangedValues(
                                null,
                                newArray,
                                "Edit Drug Category",
                                "Drug",
                                this.drugId
                            );
                            this._altSvc.success(MsgConfig.SAVE_SUCCESS);
                            this.routeBackToPrevScreen("update");
                           // this.getdrugCat();
                        } else {
                            this._altSvc.error(MsgConfig.SAVE_FAIL);
                        }
                    });
            }
        } else if (this.DrugSysdata) {
            if (this.addCategory.length > 0) {
                this.addCategory = [];
            }
            this.DrugSysdata.map(key => {
                if (_.includes(this.destination, key.Name)) {
                    this.addCategoryFG.controls["Name"].patchValue(key.Name);
                    this.addCategoryFG.controls["DrugCatId"].patchValue(key.Id);
                    this.addCategoryFG.controls["IsActive"].patchValue(true);
                    this.addCategory.push(this.addCategoryFG.value);
                } else {
                    this.addCategoryFG.controls["Name"].patchValue(key.Name);
                    this.addCategoryFG.controls["DrugCatId"].patchValue(key.Id);
                    this.addCategoryFG.controls["IsActive"].patchValue(false);
                    this.addCategory.push(this.addCategoryFG.value);
                }
            });
            this._drgSvc.addDrugCategory(this.addCategory).subscribe(resp => {
                if (resp) {
                    this._auditUtils.getChangedValues(
                        null,
                        this.addCategory,
                        "Add Drug Category",
                        "Drug",
                        this.drugId
                    );
                    this._altSvc.success(MsgConfig.SAVE_SUCCESS);
                    this.routeBackToPrevScreen("update");
                  //  this.getdrugCat();
                } else {
                    this._altSvc.error(MsgConfig.SAVE_FAIL);
                }
            });
        }
    }
    routeBackToPrevScreen(type?: any) {
        this.canDeactivateRoute = true;
        if (type && type === "update") {
            this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "update", this.focusOnDU);
        } else {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        }
        // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }


    CancelPopTaggle() {
        this.sharedCancel = true;
    }

    CancelPopClose(val) {
        if (val) {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        } else {
            this.sharedCancel = false;
        }
    }


    focusOutFmCan(event) {
        if (document.getElementById("saveButton")) {
            event.preventDefault();
            setTimeout(() => {
            const data: any = document.getElementById("saveButton").focus();
            }, 10);
        }
    }


}
