import { DateTime } from "@grapecity/wijmo";
export class iouInfo{
    DrugId: number = null;
    drugNDC: number = null;
    actDispQty: number = null;
    owedQty: number = null;
    DtTm: Date = null;
    source: string = null;
    comments: string = null;
    gtin: string = null;
    serial: string = null;
    LotNo: string = null;
    ExpDt: Date = null;
    DPV?: string = null;
    userId: string = null;
    drugImg: string = null;
    startdate: Date = null;
    hours: DateTime = null;
    minutes: DateTime = null;
}