import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WijimoUtil } from 'src/app/utils';
import { MsgConfig } from 'src/app/app.messages';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
  selector: 'app-common-drugpick-verf-hist',
  templateUrl: './common-drugpick-verf-hist.component.html',
  styleUrls: ['./common-drugpick-verf-hist.component.scss']
})
export class CommonDrugpickVerfHistComponent implements OnInit {
    @Input() DPVVerfHist: any;
    @ViewChild("HistPopup", { static: true })
    HistPopup: any;
    dphVerifModif: {}[];
    modelRef: any;
    unsubscribe$: Subject<void> = new Subject();
    wjHeaders2: any;
    @Output() IsPopUpClosed = new EventEmitter();
  constructor(private _modalService: NgbModal, private _wijimoUtils: WijimoUtil, public _formatsUtil:FormatsUtil) { }

  ngOnInit() {
    if (this.DPVVerfHist && this.DPVVerfHist.length > 0) {
        this.generateDphHistWijmo();
    }
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(w => {
        if (w && w["WijmoKey"] && w["WijmoKey"] === "revHistoryListWj") {
            this.patchDefaultValHeaders();
        }
    });
    this.modelRef = this._modalService.open(this.HistPopup,
        {centered: true, backdrop: false, keyboard: false, size: "lg"});
  }
  ngOnDestroy() {
	this.unsubscribe$.next();
	this.unsubscribe$.complete();
  }

  async patchDefaultValHeaders() {
    const storedWJ2 = await this._wijimoUtils.getWJSavedData(
        "revHistoryListWj"
    );
    this.wjHeaders2 = this._wijimoUtils.patchDefHeader(
        "revHistoryListWj",
        storedWJ2);
}

  generateDphHistWijmo() {
    this.dphVerifModif = this.DPVVerfHist.map(patRx => {
        const obj = {};
        obj["Rx#"] = patRx.PrescNum;
        obj["Ref#"] = patRx.RefillNum;
        obj["Fill#"] = patRx.PartialfillNum;
        obj["User Initial"] = patRx.UserName;
        obj["Date Time Verified"] = patRx.VerifDtTm;
        obj["Bottle NDC"] = this._formatsUtil.getNDCFormat(patRx.BottleNDC) ;
        obj["Result"] = patRx.Result ? patRx.Result.trim() : "";
        obj["GTIN#"] = patRx.Gtin;
        obj["Serial#"]= patRx.SerialNo;
        obj["Verified Quantity"] = patRx.VerifiedQty ? patRx.VerifiedQty.toFixed(3) : "0.000";
        obj["Lot#"] = patRx.LotNum;
        obj["Expiration Date"] = patRx.LotExpiryDtTm ? moment.utc(patRx.LotExpiryDtTm).local().format(MsgConfig.DATE_FORMAT) : "";
        obj["Run Date Time"] = patRx.DSCSASerializationDtTm ? moment.utc(patRx.DSCSASerializationDtTm).local().format(MsgConfig.DATE_FORMAT + ' hh:mm:ss a') : ""; 
        obj["IsReverified"]=patRx.IsReverified;
        return obj;
    });
}

closePopup() {
    if (this.modelRef) {
        this.modelRef.close();
    }
    this.IsPopUpClosed.emit(null);
}

}
