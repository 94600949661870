import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { UdpCurrencyMaskPipe } from "src/app/pipes";
import { BaseUtils } from "src/app/utils/base.utils";
import { RxBillingUtil } from "src/app/utils";

@Component({
    selector: "app-uc",
    templateUrl: "./uc-calculation.component.html"
})
export class UCCalculationComponent implements OnInit {
    numberPipe = new UdpCurrencyMaskPipe();

    @Input() rxFG: FormGroup;
    @Input() drugInfo: any;
    @Input() isCash: boolean;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    ignoreContinueBtnEnable = false;

    constructor(private _baseUtils: BaseUtils, private _rxBillingUtls: RxBillingUtil) {}

    ngOnInit() {
        this.ignoreContinueBtnEnable = (this.isCash && this.rxFG.value["rxType"] === "rf") ? false : true;
    }

    setNumberFormat(ControlName) {
        const data: any = this.rxFG.controls["PrescReFill"];
        data.controls[ControlName].patchValue(
            this.numberPipe.transform(
                this.rxFG.controls["PrescReFill"].value[ControlName],
                2
            )
        );
    }

    undokeytab(event: any, fg?: any, fc?: string) {
        sessionStorage.setItem("ActiveId", event.target.id);
        let isFcValid = true;
        if (fg && fg.controls[fc]) {
            if (["DispQty", "OrdQty"].indexOf(fc) > -1) {
                if (+fg.value[fc] !== 0) {
                    isFcValid = fg.controls[fc].valid;
                } else {
                    isFcValid = false;
                }
            } else {
                isFcValid = fg.controls[fc].valid;
            }
        }
        this._baseUtils.Undokeytab(event, isFcValid);
    }

    keytab(event: any, fg?: any, fc?: string) {
        let isFcValid = true;
        if (fg && fg.controls[fc]) {
            if (["SupplyDays", "OrderDays"].indexOf(fc) > -1) {
                if (+fg.value[fc] !== 0) {
                    isFcValid = fg.controls[fc].valid;
                } else {
                    isFcValid = false;
                }
            } else {
                isFcValid = fg.controls[fc].valid;
            }
        }
        this._baseUtils.keytab(event, isFcValid);
    }

    emitPOPUpClosed() {
        this.IsPopUpClosed.emit();
    }

    makeUCsameasAWP() {
        const prescReFill = this.rxFG.controls.PrescReFill as FormGroup;
        prescReFill.controls["UnitPriceUNC"].setValue(prescReFill.value.UnitPriceAWP);
        this.emitPOPUpClosed();
    }

    makeUCsameasPrice() {
        const prescReFill = this.rxFG.controls.PrescReFill as FormGroup;
        prescReFill.controls["UnitPriceUNC"].setValue(prescReFill.value.Price);
        this.emitPOPUpClosed();
    }

    async reCalculateUC() {
        const resp = await this._rxBillingUtls.getPriceCalculationValue(this.rxFG.value, null);
        if (resp) {
            const PrescRefillFG: any = this.rxFG.controls["PrescReFill"] as FormGroup;
            PrescRefillFG.controls["UnitPriceUNC"].setValue(resp ? resp.UAndC.toFixed(2) : "0.00");
        }
        this.emitPOPUpClosed();
    }

    cancelUCPopup() {
        this.emitPOPUpClosed();
    }
}
