import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "StringSepWithSpaceOnCamCase" })
export class StringSepWithSpace implements PipeTransform {
    transform(string,arg1?:any) {
        let finalStr = ""
        if(arg1 && arg1 === "Consolidated"){
          if(string.search(",") != -1){
            const myArr = string.split(",")
            for(let i=0; i<myArr.length; i++){
              myArr[i] = myArr[i] + "";
              myArr[i] = myArr[i].replace(/([a-z])([A-Z])/g, "$1 $2");
              myArr[i] = myArr[i].replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
              if(i!= myArr.length-1){
                if( myArr[i].includes("Override")){
                  myArr[i] =  myArr[i].replace("Override"," ")
                  myArr[i] =  myArr[i].trim();
                }
                finalStr = finalStr+myArr[i] +", "
              }
              else{
                if( myArr[i].includes("Override")){
                  myArr[i] =  myArr[i].replace("Override"," ")
                  myArr[i] =  myArr[i].trim();
                }
                finalStr = finalStr+myArr[i]
              }
            }
            return finalStr
          
          }
          else{
            string = string + "";
            string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
            string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
            if(string.includes("Override")){
              string = string.replace("Override"," ")
              string = string.trim();
            }
            return string;
          }
        }

        else{
          string = string + "";
          string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
          string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
          return string;
        }
    }
}
