export class SaveDocInputParams {
    docCatId: number = null;
    patientId: number = null;
    docSubCatId: number = null;
    drugId: number = null;
    prescId: number = null;
    prescNum: number = null;
    insuCarrierId: number = null;
    name: string = null;
    remarks: string = null;
    requestFrom: string = null;
}
