<ng-template #eRxTRxREQ let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePopup()">
        <h4 header>
            eRx Transfer Request </h4>
        <div body class="eprx-transfer-req">
          <div class="row row-space" >
                <div class="col-9 row m-0">
                    <div class="col-4 row m-0" style="padding-top: 0px !important;">
                        <div class="col-7 px-0 text-right">
                            <i class="fa-kit fa-hospital-user-regular"
                                [ngClass]="selectedPatientDetails? 'eprx-selected_details' : 'eprx-nonselected_details'"></i>
                        </div>
                        <div class="col-5 px-2 text-left eRx-transfer_typedetails">
                            <div>Select</div>
                        <div [ngClass]="selectedPatientDetails ? 'font-weight-bold color_green' : ''">Patient</div>  
                        <div class="horizantal_line"
                        [ngClass]=" selectedPatientDetails ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                    </div>
                        </div>           
                      
                    </div>
                    <div class="col-4 text-center row pl-0" style="padding-top: 0px !important;">
                        <div class="col-7 px-0 text-right">
                            <i class="fa-kit fa-regular-prescription-bottle-circle-arrow-right"
                                [ngClass]="drugSelectedDetails? 'eprx-selected_details' : 'eprx-nonselected_details'"></i>
                        </div>
                        <div class="col-5 pl-2 pr-1 text-left eRx-transfer_typedetails">
                            <div>Select</div>
                            <div [ngClass]="drugSelectedDetails ? 'font-weight-bold color_green' : ''">Transf Type</div>
                            <div class="horizantal_line"
                                [ngClass]=" drugSelectedDetails ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-center row" style="padding-top: 0px !important;">
                        <div class="col-7 px-0 text-right">
                            <i class="fa-kit fa-house-medical-regular"
                                [ngClass]="selectedPharmacyDetails? 'eprx-selected_details' : 'eprx-nonselected_details'"></i>
                        </div>
                        <div class="col-5 px-2   text-left eRx-transfer_typedetails">
                            <div>Select</div>
                            <div [ngClass]="selectedPharmacyDetails ? 'font-weight-bold color_green' : ''">Pharmacy</div>
                        </div>
                    </div>
                </div>
                <div class="col-3"></div>
            </div>
            <div class="row row-space">
                <!--Patient Selection-->
                <div class="col-3  pr-0 eprx--block__content-card">
                    <div class="eprx--block__content-card-header">
                        <div class="col">
                            Patient  {{selectedPatientDetails ? '- '+ (selectedPatientDetails?.fullname) : ''}}
                        </div>
                    </div>
                    <div
                        class="eprx--block__content-body-eRx_transferReq  pt-0">
                            <div class="seconday-search--grid lg-secondary-search--grid pt-3 mb-0">
                        <app-searchbox-global class="w-100" #PatSear [SearchFor]="'Patient'" [DisableSerchFil]="true"
                        class="eRx_transfer-request" [DisableRadioButtons]="true" [IsHide]="true"
                        (SelectedPatient)="checkIsActivePatient($event)" [PatienteRxInfo]="selectedPatientDetails"
                        [inputdisabled]="isfromPatientHistory" [IsClearIConDisable]="isfromPatientHistory ? true: false"
                         id="NewRxPatSearforeRx" [IsFocused]="IsFocusField === 'patient'"  [ClearInputValue]="patientSearchValue"
                            ></app-searchbox-global>
                      </div> 
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{patientLabels.Name}}</label>
                                <div class="label--data"><span>{{selectedPatientDetails ?
                                        (selectedPatientDetails?.fullname) : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-6">
                                <label>{{patientLabels.DOB}}</label>
                                <div class="label--data"><span>{{selectedPatientDetails ? selectedPatientDetails?.dob :
                                        msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-6">
                                <label>{{patientLabels.Gender}}</label>
                                <div class="label--data"><span>{{ selectedPatientDetails ? selectedPatientDetails.gender
                                        : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{patientLabels.Insurance_Type_Code}}</label>
                                <div class="label--data"><span>{{(primaryInsu?.InsurerCode) ? (primaryInsu?.InsurerCode
                                        | uppercase) : msgConfig.EMPTY_Data}}({{(primaryInsu?.binnum) ? (primaryInsu?.binnum
                                            | uppercase) : msgConfig.EMPTY_Data}}/{{(primaryInsu?.ProcessorNum) ? (primaryInsu?.ProcessorNum
                                                | uppercase) : msgConfig.EMPTY_Data}})</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{patientLabels.Address}}</label>
                                <div class="label--data"> <span>{{(selectedPatientDetails?.address) ?
                                        selectedPatientDetails?.address : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{patientLabels.Patient_notes}}</label>
                                <div class="label--data"><span>{{(selectedPatientDetails?.notes) ?
                                        selectedPatientDetails?.notes : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{patientLabels.Diagnosis_codes}}</label>
                                <div class="label--data"><span>{{selectedPatientDetails?.Diagnosis?.length ?
                                        selectedPatientDetails?.Diagnosis.join(", ") : msgConfig.EMPTY_Data}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{patientLabels.Allergies}}</label>
                                <div class="label--data" [ngClass]="selectedPatientDetails?.AllergiesList?.includes(msgConfig.No_Known_Allergies) ? '' : 
                                                        (selectedPatientDetails?.AllergiesList?.length ? 'allergy-color' : '')">
                                    <span>
                                        {{selectedPatientDetails?.AllergiesList ?
                                        selectedPatientDetails?.AllergiesList.join(", ") : msgConfig.EMPTY_Data}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row row-space" *ngFor="let x of [1,2,3,4,5]"></div>
                    </div>

                </div>
                 <!--Drug Selection-->
                <div class="col-3  pr-0 eprx--block__content-card">
                    <div class="eprx--block__content-card-header ">
                        <div class="col"> Drug  {{ drugSelectedDetails ?  '- '+ (drugSelectedDetails?.fullName) : ''}}</div>
                    </div>
                    <div class="eprx--block__content-body-eRx_transferReq pt-0">
                            <div class="seconday-search--grid search--by__radiobtn pt-3">
                                <app-searchbox-global id="Drug" [RxSelectId]="'eRxTransferReqDrug'"
                                    class="eRx_transfer-request" [SearchFor]="'Drug'" [IsAddNew]="true" [IsHide]="true"
                                    [DisableSerchFil]="true"  [DisableRadioButtons]="true"
                                    (SelectedDrug)="selectDrug($event)" [IsFocused]="IsFocusField === 'drug'"
                                    [ClearInputValue]="drugInputValue" >
                                </app-searchbox-global>
                            </div>
                            <div class="row row-space">
                                <!-- <div class="col-12">
                                                                <eprx-radio [ControlName]="'SelctDrugType'" [FormGroupName]="eRxTRxREQFormGroup"
                                                                    [List]="['All transferable Prescriptions', 'Specific NDC/Drug']"
                                                                    [ValueList]="[true,false]" [IDForList]="[111,11]" [LabelForId]="" [Id]
                                                                    [LabelText]="''" (TriggerSelectValue)="radioSelection($event)">
                                                                </eprx-radio>
                                                            </div> -->
                                <div class="col-12">
                                    <label>{{drugLabels.Name}}</label>
                                    <div class="label--data"><span>{{(drugSelectedDetails?.fullName) ?
                                            drugSelectedDetails?.fullName : msgConfig.EMPTY_Data}}</span></div>
                                </div>
                            </div>
                            <div class="row row-space">
                                <div class="col-6">
                                    <label>{{drugLabels.DrugCode}}</label>
                                    <div class="label--data"><span>{{drugSelectedDetails?.drugcode ?
                                            drugSelectedDetails.drugcode : msgConfig.EMPTY_Data}}</span></div>
                                </div>
                                <div class="col-6">
                                    <label>{{drugLabels.Brand}}</label>
                                    <div class="label--data"><span>{{drugSelectedDetails ?
                                            (drugSelectedDetails?.isbranded ? msgConfig.YES_Display :
                                            msgConfig.NO_Display) : msgConfig.EMPTY_Data}}</span></div>
                                </div>
                            </div>
                            <div class="row row-space">
                                <div class="col-6">
                                    <label>{{drugLabels.AWP}}</label>
                                    <div class="label--data"> <span>{{(drugSelectedDetails?.unitpriceawp ?
                                            (drugSelectedDetails?.unitpriceawp | currency: "$":"symbol":"1.3-3") :
                                            "$0.000") + " ("+ (drugSelectedDetails?.awppack ?
                                            (drugSelectedDetails?.awppack | currency: "$":"symbol":"1.2-2") :
                                            "$0.00") +")"}} </span></div>
                                </div>
                                <div class="col-6">
                                    <label>{{drugLabels.COST}}</label>
                                    <div class="label--data"><span>{{(drugSelectedDetails?.unitpricecost ?
                                            (drugSelectedDetails?.unitpricecost | currency: "$":"symbol":"1.3-3") :
                                            "$0.000") + " ("+ (drugSelectedDetails?.costpack ?
                                            (drugSelectedDetails?.costpack | currency: "$":"symbol":"1.2-2") :
                                            "$0.00") +")"}}</span></div>
                                </div>
                            </div>
                            <div class="row row-space">
                                <div class="col-6">
                                    <label>{{drugLabels.Size}}</label>
                                    <div class="label--data"><span>{{drugSelectedDetails?.qtypack?
                                            drugSelectedDetails?.qtypack.toFixed(3) : "0.000"}}</span></div>
                                </div>
                                <div class="col-6">
                                    <label>{{drugLabels.Manufacturer}}</label>
                                    <div class="label--data"><span>{{drugSelectedDetails?.manufacturername?
                                            drugSelectedDetails?.manufacturername : msgConfig.EMPTY_Data}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-space">
                                <div class="col-6">
                                    <label>{{drugLabels.DrugClass}}</label>
                                    <div class="label--data"><span>{{drugSelectedDetails?.drugclass?
                                            drugSelectedDetails?.drugclass : msgConfig.EMPTY_Data}}</span></div>
                                </div>
                                <div class="col-6">
                                    <label>Qty in Hand</label>
                                    <div class="label--data"><span>
                                        {{ drugSelectedDetails ? (_buckUtils.getQtyInHand(drugSelectedDetails.manageinventory ? drugSelectedDetails.manageinventory : null, drugSelectedDetails.BucketId)) : msgConfig.DOT_Three_Fixed}}
                                    </span></div>
                                </div>
                            </div>
                            <div class="row row-space">
                                <div class="col-12">
                                    <label>{{drugLabels?.Drug_Notes}}</label>
                                    <div class="label--data"><span>{{drugSelectedDetails?.Remarks ?
                                            drugSelectedDetails?.Remarks : msgConfig.EMPTY_Data}}</span></div>
                                </div>
                            </div>
                    </div>
                </div>
                <!--Pharmacy Selection-->
                <div class="col-3  pr-0 eprx--block__content-card">
                    <div class="eprx--block__content-card-header">
                        <div class="col">
                            Pharmacy {{ selectedPharmacyDetails ? '- ' + (selectedPharmacyDetails.PharmName | uppercase): ""}}
                        </div>
                    </div>
                    <div class="eprx--block__content-body-eRx_transferReq pt-0 mb-2" style="height:313px !important">
                        <div class="row row-space">
                            <div class="col-9 pr-2 pharmacy-selection max-width" >
                                <eprx-select [LabelText]="''" [ControlName]="'XferPharmId'" [FormGroupName]="eRxTRxREQFormGroup"
                                [PlaceHolder]="'Search Pharmacy Name'" [BindLabel]="'PharmacyName'"  [BindValue]="'Id'"
                                [IsTitle]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [BindValue]="'PharmacyName'" 
                                [List]="PharmacyNames?.pharmNames" (TriggerSelectValue)="getPharmcyDetails($event)"
                                 [IsFocusedonField]="IsFocusField === 'pharmacy'"  
                                 [BindLabel]="'PharmacyName'"
                                 [BindLabel2] = "'Phone1'"
                                 [BindLabel3] = "'Phone2'"
                                 [BindLabel4] = "'Fax'"
                                 [BindLabel5]="'addresses'"
                                 [BindLabel6]="'NPINum'"
                                 [hasBindLabel5] = true
                                 [LabelTitle1]="'Pharmacy Name'"
                                 [LabelTitle2]="'Phone#1'"
                                 [phoneLabel]="true"
                                 [faxLabel]="true"
                                 [addressLabel]="true"
                                 [DropdownPosition]="'left'"
                                 [LabelTitle3]="'Phone#2'"
                                 [LabelTitle4]="'Fax#'"
                                 [LabelTitle5]="'Address'"
                                 [LabelTitle6]="'NPI#'"
                                [ErrorDefs]="{required: 'Required'}" [RxSelectId]="'eRxTransferPhamacy'"> </eprx-select> 
                            </div>
                            <div class="col-3" style="position: relative;left: -21px;">
                                <button class="hotkey_success  hotkey_primary--add-button" style="margin-top: 0px !important;"
                                                                (click)="openTransferPharmacy()" title="Add" appShortcutKey
                                                                [AltKey]="'a'"><span>A</span>ADD</button>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{pharmacyabels.Name}}</label>
                                <div class="label--data"><span>{{selectedPharmacyDetails?.PharmName ? (selectedPharmacyDetails.PharmName | uppercase): msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{pharmacyabels.Address}}</label>
                                <div class="label--data"><span>{{(selectedPharmacyDetails?.selectPharmAddress) ? selectedPharmacyDetails.selectPharmAddress : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-6">
                                <label>{{pharmacyabels.Phone}}</label>
                                <div class="label--data"><span>{{(selectedPharmacyDetails?.Telephone) ? selectedPharmacyDetails.Telephone : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-6">
                                <label>{{pharmacyabels.NPI}}</label>
                                <div class="label--data"> <span>{{(selectedPharmacyDetails?.NPINum) ? selectedPharmacyDetails.NPINum : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-6">
                                <label>{{pharmacyabels.NABP}}</label>
                                <div class="label--data"><span>{{(selectedPharmacyDetails?.NABPNum) ? selectedPharmacyDetails.NABPNum : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-6 row" >
                                <div class="col-6 pr-0">
                                    <label>{{pharmacyabels.DEA}}</label>
                                    <div class="label--data pr-0"><span>{{(selectedPharmacyDetails?.DEANum) ? selectedPharmacyDetails.DEANum : msgConfig.EMPTY_Data}}</span></div>
                                </div>
                                <div class="col-6">
                                    <i class="fa-kit fa-house-medical-circle-check-regular"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row row-space" *ngIf="false">
                            <i class="fa-kit fa-house-medical-circle-xmark-regular"></i>
                        </div>
                    </div>
                    <div class="eprx--block__content-card-header">
                        <div class="col">
                            Pharmacist Notes
                        </div>
                    </div>
                    <div class="eprx--block__content-body-eRx_transferReq pt-0" style="height:160px !important">
                       <div class="pt-3">
                        <eprx-text-area [LabelText]="''" [FormGroupName]="eRxTRxREQFormGroup"
                        [ControlName]="'eRxTransferNotes'" [PlaceHolder]="''" [MaxLength]="210"
                        [Rows]="5"></eprx-text-area>
                       </div>
                    </div>
                </div>
                 <!--Requesting By  Selection-->
                <div class="col-3 eprx--block__content-card">
                    <div class="eprx--block__content-card-header">
                        <div class="col">
                            Requesting By - {{requestPharmacyDetails.PharmacyName ?
                                (requestPharmacyDetails.PharmacyName | uppercase) : msgConfig.EMPTY_Data}}
                        </div>
                    </div>
                    <div class="eprx--block__content-body-eRx_transferReq pt-0">
                        <div class="row row-space" >
                            <div class="col-12 text-center pt-3">
                                <i class="fa-kit fa-house-medical-thin "></i>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{pharmacyabels.Name}}</label>
                                <div class="label--data"><span>{{requestPharmacyDetails.PharmacyName ?
                                        (requestPharmacyDetails.PharmacyName | uppercase) : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-12">
                                <label>{{pharmacyabels.Address}}</label>
                                <div class="label--data"><span>{{requestPharmacyDetails.pharmAddress ?
                                        requestPharmacyDetails.pharmAddress : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-6">
                                <label>{{pharmacyabels.Contact}}</label>
                                <div class="label--data"><span>{{requestPharmacyDetails.TelephoneNumber ?
                                        requestPharmacyDetails?.TelephoneNumber : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-6">
                                <label>{{pharmacyabels.NPI}}</label>
                                <div class="label--data"> <span>{{ (requestPharmacyDetails?.NPINum) ? requestPharmacyDetails.NPINum : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-6">
                                <label>{{pharmacyabels.NABP}}</label>
                                <div class="label--data"><span>{{(requestPharmacyDetails?.NABPNum) ? requestPharmacyDetails?.NABPNum: msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-6">
                                <label>{{pharmacyabels.DEA}}</label>
                                <div class="label--data"><span>{{ (requestPharmacyDetails?.DEARegistration) ? requestPharmacyDetails.DEARegistration : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space">
                            <div class="col-6">
                                <label>{{pharmacyabels.Registration}}</label>
                                <div class="label--data"><span>{{(requestPharmacyDetails?.RegistrationNum) ? requestPharmacyDetails.RegistrationNum : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-6">
                                <label>{{pharmacyabels.Pharmacist_Name}}</label>
                                <div class="label--data"><span>{{pharmacistName ? pharmacistName : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                   
                    </div>
                </div>
            </div> 
        </div>
        <button footer [ngClass]="eRxModelFG.valid? 'hotkey_success' : 'inactive'" 
        [ngbTooltip]="eRxModelFG.invalid?'':'eRx Transfer Request.'" triggers="mouseover:mouseleave" [autoClose]="true" placement="top" 
                        tooltipClass="search-tooltip title-tooltip"
        (click)="eRxModelFG.valid? closePopup() : ''" appShortcutKey
            [AltKey]="'q'"><span>Q</span> Send TRx REQ
        </button>
        <button footer class="hotkey_success" (click)="closePopup()" (click)="c('close')" appShortcutKey
            [AltKey]="'c'"><span>C</span> CANCEL
        </button>
    </app-custom-modal>
</ng-template>