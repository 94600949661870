<div class="thumnailsbg" *ngIf="SasURLDocList && SasURLDocList?.length">
    <carousel>
        <ng-container *ngFor="let doc of SasURLDocList; index as i">
            <div class="carousel-cell">
                <div class="position-relative thumbnailsmain">
                    <object data="doc?.sasUrl" type="application/pdf" *ngIf="doc?.extn === 'pdf'"   width="100%" height="260">
                    </object>
                    <img *ngIf="doc?.extn != 'pdf'" [src]="doc?.sasUrl">
                    <div *ngIf="doc?.type === 'custom'">               
                            <div class="icon-set icon-erx" (click)="PreviewErxFile.emit(true)">
                                <i class="fal fa-thin fa-expand-arrows" title="Expand"></i>
                            </div>          
                    </div>
                    <div *ngIf="doc?.type != 'custom'">                   
                        <div class="icon-set icon-set-{{doc?.extn == 'pdf' ? 1 : 2}}" (click)="PreviewDocFile.emit(i)">
                            <i class="fal fa-thin fa-expand-arrows" title="Expand"></i>
                        </div>
                        <div class="icon-set ep-icon-left icon-set-{{doc?.extn == 'pdf' ? 1 : 2}}" (click)="geteditDocFile(doc,i)">
                            <i class="far fa-thin fa-edit" title="Edit"></i>
                        </div>
                        <div class="icon-set tp-icon-top-{{doc?.extn == 'pdf' ? 1 : 2}}" *ngIf="!isNavigateFromDocumentQueue" (click)="deleteDocFile(doc,i,'deleteInfo')">
                            <i class="far fa-thin fa-trash" title="Delete"></i>
                        </div>
                        <div class="icon-set ep-icon-left tp-icon-top-{{doc?.extn == 'pdf' ? 1 : 2}}" (click)="PrintDocFile.emit(doc)">
                            <i class="far fa-thin fa-print" title="Print"></i>
                        </div>
                    </div> 
                    <div class="title title-img-{{doc?.extn == 'pdf' ? 1 : 2}}" container="body" ngbTooltip="{{doc?.name | uppercase}}"><span>{{doc?.name | uppercase}}</span></div>           
                </div>
            </div>
        </ng-container>        
    </carousel>    
</div>