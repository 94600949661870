import { Injectable } from "@angular/core";
import {
    CommonService,
    EditDrugService,
    EditPrescriberService,
    ErxService,
    UserService,
    PreviousRouteService,
    InsuranceService
} from "../services";
import { CloneRx, RxPatientModel, RxModel } from "../models";
import { Router } from "@angular/router";
import * as moment from "moment";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class RxUtils {
    rxDetailsIDs: CloneRx;
    rxPatientModel: RxPatientModel;
    drugInfo: any;
    prescriberInfo: any;
    rxDataByRxID: any;
    rxType: string;
    RxId: number;

    constructor(
        private _commonServ: CommonService,
        private _editDrugService: EditDrugService,
        private _router: Router,
        private _erxService: ErxService,
        private _userService: UserService,
        private _prevRoutServ: PreviousRouteService,
        private _insuSvc: InsuranceService
    ) {}

    saveInProgressRxs(totalPrivs: any) {}

    saveBrowseHostory(type: string, value: any, id: number) {
        // const history = {
        //     type: type,
        //     value: value,
        //     RxID: id
        // };
        // if (localStorage.getItem("UserId") !== this._userService.getToken("UserId")) {
        //     localStorage.setItem("UserId", this._userService.getToken("UserId"));
        //     localStorage.setItem(
        //         "InProgress",
        //         JSON.stringify([])
        //     );
        //     localStorage.setItem(
        //         "BrowseHistory",
        //         JSON.stringify([])
        //     );
        // }
        // let bHistory: any[] = JSON.parse(localStorage.getItem("BrowseHistory"));
        // if (!bHistory) {
        //     bHistory = [];
        // }
        // if (bHistory && bHistory.length > 0) {
        //     if (bHistory.length >= 10) {
        //         bHistory.splice(0, 1);
        //         bHistory.push(history);
        //     } else {
        //         bHistory.push(history);
        //     }
        //     localStorage.setItem("BrowseHistory", JSON.stringify(bHistory));
        // } else {
        //     bHistory = [];
        //     bHistory.push(history);
        //     localStorage.setItem("BrowseHistory", JSON.stringify(bHistory));
        // }
        // this._commonServ.storeInprogressBrowseHistory(bHistory);
    }

    saveInProgressData(
        type: string,
        value: any,
        id: number,
        otherInfo?: any,
        sessionInfo?: any
    ) {
        const progress = {
            type: type,
            value: value,
            RxID: id,
            otherRxInfo: otherInfo,
            sessionInfo: sessionInfo
        };

        // if (localStorage.getItem("UserId") !== this._userService.getToken("UserId")) {
        //     localStorage.setItem("UserId", this._userService.getToken("UserId"));
        //     localStorage.setItem(
        //         "InProgress",
        //         JSON.stringify([])
        //     );
        // }
        let inProgress: any[];
        if (localStorage.getItem("InProgress")) {
            inProgress = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("InProgress")));
        }
        if (inProgress && inProgress.length > 0) {
            if (inProgress.length >= 2) {
                inProgress.splice(0, 1);
                inProgress.push(progress);
            } else {
                inProgress.push(progress);
            }
            localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify(inProgress)));
        } else {
            inProgress = [];
            inProgress.push(progress);
            localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify(inProgress)));
        }
        this._commonServ.storeInprogressRxs(inProgress);
    }

    cloneRxInfo(rxId: number, from: string) {
        const cloneRx = new CloneRx();
        this.RxId = rxId;
        cloneRx.RxId = rxId;
        // this._commonServ.cloneRxInfo(cloneRx).subscribe(resp => {
        //     this.rxDetailsIDs = resp[0];
        //     this.rxType = from;
        //     localStorage.setItem(
        //         "patientId",
        //         JSON.stringify(this.rxDetailsIDs.PatientId)
        //     );
        //     this.getPatientInfo(this.rxDetailsIDs.PatientId);
        // });
    }

    cloneRxByeRxID(eRxId: number) {
        this._erxService.getProcessedRxIDsByeRxID(eRxId).subscribe(resp => {
            this.rxType = "erx";
            this.rxDetailsIDs = resp;
            localStorage.setItem(
                "patientId",
                this._commonServ.encryptData(JSON.stringify(this.rxDetailsIDs.PatientId))
            );
            this.getPatientInfo(this.rxDetailsIDs.PatientId);
        });
    }

    getPatientInfo(patientID: number): void {
        this._commonServ
            .getPatientInformationForRx(patientID)
            .subscribe(resp => {
                this.rxPatientModel = resp;
                this.getPrescriberInfo(this.rxDetailsIDs.PrescriberId);
            });
    }

    getPrescriberInfo(prescriberId: number) {
        this._commonServ.getPrescriberData(prescriberId).subscribe(resp => {
            let pres = {
                prescriberlastname: resp.Person.LastName,
                prescriberfirstname: resp.Person.FirstName,
                prescriberaddress1: resp.Address.AddressLine1,
                prescriberaddress2: resp.Address.AddressLine2,
                prescribercity: resp.Address.CityName,
                prescriberstate: resp.Address.StateName,
                prescriberzipcode: resp.Address.ZipCode,
                prescriberdeanum: resp.Prescriber.DEANum,
                prescribernpinum: resp.Person.NPINum,
                prescriberid: resp.Prescriber.Id
            };
            this.prescriberInfo = pres;
            this.getDrugInfo(this.rxDetailsIDs.DrugId);
        });
    }

    getDrugInfo(drugId: number) {
        this._editDrugService.getDrugInfo(drugId).subscribe(resp => {
            const drugInf = {
                drugcode: resp.Drug.DrugCode,
                qtypack: resp.Drug.QtyPack,
                unitpriceawp: resp.Drug.UnitPriceAWP,
                unitpricecost: resp.Drug.UnitPriceCost,
                manufacturername: null,
                Remarks: null,
                IsPreferred: true,
                id: drugId,
                drugtypeid: null
            };
            this.drugInfo = drugInf;
            if (this.rxType === "nr") {
                this.storeRxInfo();
            } else {
                this.getRxInfoByRxId(this.rxDetailsIDs.RxId);
            }
        });
    }

    getRxInfoByRxId(rxId: number) {
        this._commonServ.getRxInfoByRxNumber(rxId).subscribe(resp => {
            this.rxDataByRxID = resp;
            this.storeRxInfo();
        });
    }

    storeRxInfo() {
        const rxinfo = {
            RxPatientInfo: this.rxPatientModel,
            RxPrescInfo: this.prescriberInfo,
            RxDrugInfo: this.drugInfo,
            RxInfo: this.rxDataByRxID
        };

        this._commonServ.storePatientIdinBS(
            rxinfo.RxPatientInfo.PatientInfo.PatientId
        );

        if (
            this.rxType === "erx" ||
            this.rxType === "rf" ||
            this.rxType === "er"
        ) {
            this._commonServ.storeRxIdinBS(this.RxId);
        } else {
            this._commonServ.storeRxIdinBS(null);
        }

        localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rxinfo)));
        this._router.navigate(["eprx/rx"], {
            queryParams: { fm: this.rxType }
        });
    }

    /* ----------------------- New Code --------------------------------------------- */

    getRxDetailsByPrescNumber(
        rxId: number,
        from: string,
        PrescId?: any,
        isRecall: boolean = false,
        fmTrPopUp?: boolean
    ) {
        this.RxId = +rxId;
        this._commonServ
            .getRxDetailsByPrescNos([rxId], PrescId, isRecall)
            .subscribe(resp => {
                this.rxType = from;
                if (resp && resp.length > 0) {
                    localStorage.setItem(
                        "patientId",
                        this._commonServ.encryptData(JSON.stringify(resp[0].Patient.patientid))
                    );
                    this.storeERXInfo(resp[0], this.RxId, this.rxType);
                }
                // this.getPatientInfo(this.rxDetailsIDs.PatientId);
            });
    }

    storeERXInfo(rxInfo: RxModel, rxId?: number, rxType?: string) {
        this.clearRxInfoFromSessionStorage(rxType);
        if (rxType === "rf" || rxType === "er" || rxType === "brx") {
            // sessionStorage.setItem("DrugComplete", "" + true);
            this._commonServ.storeRxIdinBS(rxId);
        } else {
            this._commonServ.storeRxIdinBS(null);
        }

        localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rxInfo)));
        this._router.navigate(["eprx/rx"], {
            queryParams: { fm: rxType }
        });
        this._commonServ.storePatientIdinBS(rxInfo.Patient.patientid);
    }

    clearSessionStorage() {
        sessionStorage.removeItem("PatientComplete");
        sessionStorage.removeItem("PrescriberComplete");
        sessionStorage.removeItem("DrugComplete");
        sessionStorage.removeItem("DrugChange");
        sessionStorage.removeItem("PresChange");
        sessionStorage.removeItem("eRxFilterData");
        sessionStorage.removeItem("ErxType");
        sessionStorage.removeItem("isTransMSG");
        sessionStorage.removeItem("ActiveId");
    }

    clearRxInfoFromSessionStorage(rxType) {
        if (rxType === "er" || rxType === "brx") {
            sessionStorage.setItem("PatientComplete", "" + true);
            sessionStorage.setItem("PrescriberComplete", "" + true);
            sessionStorage.setItem("DrugComplete", "" + true);
        } else if (rxType === "rf") {
            sessionStorage.setItem("PatientComplete", "" + true);
            sessionStorage.setItem("PrescriberComplete", "" + true);
            sessionStorage.setItem("DrugComplete", "" + false);
        } else {
            sessionStorage.setItem("PatientComplete", "" + false);
            sessionStorage.setItem("PrescriberComplete", "" + false);
            sessionStorage.setItem("DrugComplete", "" + false);
        }
        sessionStorage.removeItem("DrugChange");
        sessionStorage.removeItem("PresChange");
        sessionStorage.removeItem("isTransMSG");
        sessionStorage.removeItem("ActiveId");
    }

    updateLatestRxWithEditPatInfo(
        patId: any,
        routeFrom: string,
        routeFrom2?: string,
        patType?: string,
        isNewRec?: boolean
    ) {
        this._commonServ.getRxPatientDetailsById(patId).subscribe(resp => {
            if (resp) {
                if (!isNewRec) {
                    if (localStorage.getItem("InProgress")) {
                        const inProgRxs = JSON.parse(
                            this._commonServ.DecryptData(localStorage.getItem("InProgress"))
                        );
                        if (
                            inProgRxs.length > 0 &&
                            (routeFrom === "nr" || routeFrom === "rnr")
                        ) {
                            const rx = inProgRxs[inProgRxs.length - 1];
                            rx.value.Patient = resp;
                            inProgRxs[inProgRxs.length - 1] = rx;
                            localStorage.setItem(
                                "InProgress",
                                this._commonServ.encryptData(JSON.stringify(inProgRxs))
                            );
                            localStorage.setItem(
                                "RxInfo",
                                this._commonServ.encryptData(JSON.stringify(rx.value))
                            );
                            localStorage.setItem(
                                "OtherRxInfo",
                                this._commonServ.encryptData(JSON.stringify(rx.otherRxInfo))
                            );
                            this._commonServ._showInProgressRx$.next(true);
                        } else {
                            if (localStorage.getItem("RxInfo")) {
                                const rxInfo = JSON.parse(
                                    this._commonServ.DecryptData(localStorage.getItem("RxInfo"))
                                );
                                rxInfo.Patient = resp;
                                localStorage.setItem(
                                    "RxInfo",
                                    this._commonServ.encryptData(JSON.stringify(rxInfo))
                                );
                            }
                        }
                    }
                }
                localStorage.setItem("PatientInfo", this._commonServ.encryptData(JSON.stringify(resp)));
                if (patType && patType === "add") {
                    sessionStorage.setItem("PatientComplete", "" + false);
                } else {
                    sessionStorage.setItem("PatientComplete", "" + true);
                }

                if (!isNewRec) {
                    if (localStorage.getItem("InProgress") && (routeFrom === "nr" || routeFrom === "rnr")) {
                    const inProgRxs = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("InProgress")));
                        if (inProgRxs.length > 0 && (routeFrom === "nr" || routeFrom === "rnr")) {
                            const rx = inProgRxs.pop();
                            this._commonServ.storePatientIdinBS(rx.value.Patient.patientid);
                            this._commonServ.storeRxIdinBS(rx.RxID);
                            localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                            if (inProgRxs.length > 0) {
                                localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify(inProgRxs)));
                                this._commonServ._showInProgressRx$.next(true);
                            } else {
                                localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify([])));
                                this._commonServ._showInProgressRx$.next(false);
                            }
                        }
                    }
                }
                this._prevRoutServ.popPreviousUrls("eprx/rx");
                if (routeFrom2) {
                    this._prevRoutServ.routeBackToPrevScreen();
                } else {
                    this._router.navigate(["eprx/rx"], {
                        queryParams: {
                            fm: routeFrom
                        }
                    });
                }
                // if (routeFrom2) {
                //     this.routeToRx(routeFrom, routeFrom2, null, isNewRec);
                // } else {
                //     this.routeToRx(routeFrom, null, null, isNewRec);
                // }
            }
        });
    }

    updateLatestRxWithEditPresInfo(
        prescId: any,
        routeFrom: string,
        routeFrom2?: string,
        type?: any,
        isNewRec?: boolean
    ) {
        if (
            routeFrom &&
            (routeFrom === "nr" ||
                routeFrom === "er" ||
                routeFrom === "rf" ||
                routeFrom === "nrf" ||
                routeFrom === "brx" ||
                routeFrom === "rnr")
        ) {
            if (isNewRec) {
                this._commonServ
                    .getRxPrescriberDetailsById(prescId)
                    .subscribe(resp => {
                        if (resp) {
                            if (routeFrom === "nr" || routeFrom === "rnr") {
                                let inProgRxs;
                                if (localStorage.getItem("InProgress")) {
                                    inProgRxs = JSON.parse(this._commonServ.DecryptData(
                                        localStorage.getItem("InProgress"))
                                    );
                                }
                                const rx = inProgRxs[inProgRxs.length - 1];
                                rx.value.Prescriber = resp;
                                inProgRxs[inProgRxs.length - 1] = rx;
                                localStorage.setItem(
                                    "InProgress",
                                    this._commonServ.encryptData(JSON.stringify(inProgRxs))
                                );
                                if (rx.value.Patient.patientid) {
                                    sessionStorage.setItem(
                                        "PatientComplete",
                                        "" + true
                                    );
                                }
                            } else {
                                if (localStorage.getItem("RxInfo")) {
                                    const rxInfo = JSON.parse(this._commonServ.DecryptData(
                                        localStorage.getItem("RxInfo"))
                                    );
                                    rxInfo.Prescriber = resp;
                                    localStorage.setItem(
                                        "RxInfo",
                                        this._commonServ.encryptData(JSON.stringify(rxInfo))
                                    );
                                    if (rxInfo.Patient.patientid) {
                                        sessionStorage.setItem(
                                            "PatientComplete",
                                            "" + true
                                        );
                                    }
                                }
                            }
                            this._commonServ._showInProgressRx$.next(true);
                            if (routeFrom2) {
                                this.routeToRx(routeFrom, routeFrom2);
                            } else {
                                this.routeToRx(routeFrom);
                            }
                        }
                    });
            } else {
                let inProgRxs;
                if (localStorage.getItem("InProgress")) {
                    inProgRxs = JSON.parse(this._commonServ.DecryptData(
                        localStorage.getItem("InProgress"))
                    );
                }
                const rx = inProgRxs[inProgRxs.length - 1];
                const resp = rx.value.Prescriber;

                if (routeFrom === "nr" || routeFrom === "rnr") {
                    rx.value.Prescriber = resp;
                    inProgRxs[inProgRxs.length - 1] = rx;
                    localStorage.setItem(
                        "InProgress",
                        this._commonServ.encryptData(JSON.stringify(inProgRxs))
                    );
                    if (rx.value.Patient.patientid) {
                        sessionStorage.setItem("PatientComplete", "" + true);
                    }
                } else {
                    if (localStorage.getItem("RxInfo")) {
                        const rxInfo = JSON.parse(this._commonServ.DecryptData(
                            localStorage.getItem("RxInfo"))
                        );
                        rxInfo.Prescriber = resp;
                        localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rxInfo)));
                        if (rxInfo.Patient.patientid) {
                            sessionStorage.setItem(
                                "PatientComplete",
                                "" + true
                            );
                        }
                    }
                }
                this._commonServ._showInProgressRx$.next(true);
                if (routeFrom2) {
                    this.routeToRx(routeFrom, routeFrom2);
                } else {
                    this.routeToRx(routeFrom);
                }
            }
        } else if (routeFrom && routeFrom === "SearchPrescriber") {
            this._router.navigate(["eprx/prescriber/SearchPrescriber"]);
        } else {
            if (type === "Add") {
                this._router.navigate([
                    "eprx/prescriber/" + prescId + "/prescriberinfo"
                ]);
            } else if (
                this._prevRoutServ.previousUrl ===
                "/eprx/prescriber/prescriberinfo"
            ) {
                this._router.navigate(["eprx/dashboard"]);
            } else {
                this._prevRoutServ.routeBackToPrevScreen();
                // this._router.navigate(["eprx/dashboard"]);
            }
        }
    }

    updateLatestRxWithEditDrugInfo(
        drugId: any,
        routeFrom: string,
        routeFrom2?: string,
        type?: any,
        hasFocus?: any
    ) {
        if (
            routeFrom === "nr" ||
            routeFrom === "er" ||
            routeFrom === "rf" ||
            routeFrom === "nrf" ||
            routeFrom === "brx" ||
            routeFrom === "rnr"
        ) {
            if (type && type === "add") {
                sessionStorage.setItem("DrugComplete", "" + false);
                sessionStorage.setItem("isFrmAddDrug", "" + true);
                sessionStorage.setItem("NavigateFromEditDrug", "" + true);
            } else {
                sessionStorage.setItem("DrugComplete", "" + true);
            }
            sessionStorage.setItem("DrugChange", "" + true);
            if (hasFocus) {
                sessionStorage.setItem("FrmFocus", "" + hasFocus);
                sessionStorage.setItem("DrugComplete", "" + false);
            }
            if (type && (type === "update" || type === "add")) {
                this._commonServ
                    .getRxDrugDetailsById(drugId)
                    .subscribe(resp => {
                        if (resp) {
                            const defRxStart = +this._commonServ.getSetttingValue(
                                "RxSettings",
                                "REPRICE_UNBILLEDRX_BEFORE_TRANSMITTING"
                            );
                            if (routeFrom === "nr" || routeFrom === "rnr") {
                                let inProgRxs;
                                if (localStorage.getItem("InProgress")) {
                                    inProgRxs = JSON.parse(this._commonServ.DecryptData(
                                        localStorage.getItem("InProgress"))
                                    );
                                }
                                const rx = inProgRxs[inProgRxs.length - 1];
                                /* if (defRxStart) {
                                   //   rx.value.Drug = resp;
                                 } */
                                inProgRxs[inProgRxs.length - 1] = rx;
                                localStorage.setItem(
                                    "InProgress",
                                    this._commonServ.encryptData(JSON.stringify(inProgRxs))
                                );
                                if (
                                    rx.value.Patient &&
                                    rx.value.Patient.patientid
                                ) {
                                    sessionStorage.setItem(
                                        "PatientComplete",
                                        "" + true
                                    );
                                }
                                if (
                                    rx.value.Prescriber &&
                                    rx.value.Prescriber.prescriberid
                                ) {
                                    sessionStorage.setItem(
                                        "PrescriberComplete",
                                        "" + true
                                    );
                                }
                            } else {
                                if (localStorage.getItem("RxInfo")) {
                                    const rxInfo = JSON.parse(this._commonServ.DecryptData(
                                        localStorage.getItem("RxInfo")
                                    ));
                                    if (
                                        !rxInfo.RxBill.StatusId ||
                                        (rxInfo.RxBill.StatusId &&
                                            +rxInfo.RxBill.StatusId !== 2)
                                    ) {
                                        rxInfo.Drug = resp;
                                    }
                                    localStorage.setItem(
                                        "RxInfo",
                                        this._commonServ.encryptData(JSON.stringify(rxInfo))
                                    );
                                    if (
                                        rxInfo.Patient &&
                                        rxInfo.Patient.patientid
                                    ) {
                                        sessionStorage.setItem(
                                            "PatientComplete",
                                            "" + true
                                        );
                                    }
                                    if (
                                        rxInfo.Prescriber &&
                                        rxInfo.Prescriber.prescriberid
                                    ) {
                                        sessionStorage.setItem(
                                            "PrescriberComplete",
                                            "" + true
                                        );
                                    }
                                }
                            }
                            this._commonServ._showInProgressRx$.next(true);
                            if (routeFrom2) {
                                this.routeToRx(routeFrom, routeFrom2);
                            } else {
                                this.routeToRx(routeFrom);
                            }
                        }
                    });
            } else {
                if (routeFrom !== "er") {
                    let inProgRxs;
                    if (localStorage.getItem("InProgress")) {
                        inProgRxs = JSON.parse(this._commonServ.DecryptData(
                        localStorage.getItem("InProgress"))
                        );
                    }
                    const rx = inProgRxs[inProgRxs.length - 1];
                    if (rx.value.Patient && rx.value.Patient.patientid) {
                        sessionStorage.setItem("PatientComplete", "" + true);
                    }
                    if (
                        rx.value.Prescriber &&
                        rx.value.Prescriber.prescriberid
                    ) {
                        sessionStorage.setItem("PrescriberComplete", "" + true);
                    }
                } else {
                    if (localStorage.getItem("RxInfo")) {
                    const rxInfo = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("RxInfo")));
                    if (rxInfo && rxInfo.Patient && rxInfo.Patient.patientid) {
                        sessionStorage.setItem("PatientComplete", "" + true);
                    }
                    if (rxInfo && rxInfo.Prescriber && rxInfo.Prescriber.prescriberid) {
                        sessionStorage.setItem("PrescriberComplete", "" + true);
                    }
                }
                }
                this._commonServ._showInProgressRx$.next(true);
                if (routeFrom2) {
                    this.routeToRx(routeFrom, routeFrom2);
                } else {
                    this.routeToRx(routeFrom);
                }
            }
        } else if (routeFrom && routeFrom === "SearchDrug") {
            this._router.navigate(["eprx/drug/drugSearch"]);
        } else if (routeFrom === "rphVerf") {
            this._router.navigate(["eprx/RphVerification"]);
        } else if (routeFrom === "SearchPatient") {
            this._router.navigate(["eprx/patient/searchPatient"]);
        } else if (routeFrom === "drugpricecheck") {
            this._router.navigate(["eprx/drugpricecheck"]);
        } else if (routeFrom === "inventory") {
            this._router.navigate(["eprx/inventory"]);
        } else if (type === "add") {
            this._router.navigate(["eprx/drug/" + drugId + "/druginfo"]);
        } else {
            // this._prevRoutServ.routeBackToPrevScreen();
            this._router.navigate(["eprx/dashboard"]);
        }
    }

    routeToRx(
        routeFrom?: string,
        routeFrom2?: string,
        hasFocus?: any,
        isNewRec?: boolean
    ) {
        if (
            routeFrom === "nr" ||
            routeFrom === "er" ||
            routeFrom === "rf" ||
            routeFrom === "nrf" ||
            routeFrom === "brx" ||
            routeFrom === "rnr"
        ) {
            // if (!isNewRec) {
                if (localStorage.getItem("InProgress") && (routeFrom === "nr" || routeFrom === "rnr")) {
                const inProgRxs = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("InProgress")));
                    if (inProgRxs.length > 0 && (routeFrom === "nr" || routeFrom === "rnr")) {
                        const rx = inProgRxs.pop();
                        this._commonServ.storePatientIdinBS(
                            rx.value.Patient.patientid
                        );
                        this._commonServ.storeRxIdinBS(rx.RxID);
                        localStorage.setItem(
                            "RxInfo",
                            this._commonServ.encryptData(JSON.stringify(rx.value))
                        );
                        if (inProgRxs.length > 0) {
                            localStorage.setItem(
                                "InProgress",
                                this._commonServ.encryptData(JSON.stringify(inProgRxs))
                            );
                            this._commonServ._showInProgressRx$.next(true);
                        } else {
                            localStorage.setItem(
                                "InProgress",
                                this._commonServ.encryptData(JSON.stringify([]))
                            );
                            this._commonServ._showInProgressRx$.next(false);
                        }
                    }
                }
            // }
            this._prevRoutServ.popPreviousUrls("eprx/rx");
            if (routeFrom2) {
                this._prevRoutServ.routeBackToPrevScreen();
            } else {
                this._router.navigate(["eprx/rx"], {
                    queryParams: {
                        fm: routeFrom
                    }
                });
            }
        } else if (routeFrom === "rphVerf") {
            this._router.navigate(["eprx/RphVerification"]);
        } else if (routeFrom === "SearchPatient") {
            this._router.navigate(["eprx/patient/searchPatient"]);
        } else if (routeFrom === "patBI") {
            this._router.navigate(["eprx/businessintelligence/patient"]);
        } else {
            // this._prevRoutServ.routeBackToPrevScreen();
            this._router.navigate(["eprx/dashboard"]);
        }
    }

    isRXInProgress() {
        return false;
        // const routeFrom = sessionStorage.getItem("toEdit");
        // if(routeFrom) {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    routeToRxFromOtherScreens(rxToRestore?: number) {
        sessionStorage.clear();
        rxToRestore = rxToRestore ? rxToRestore : 1;
        if (localStorage.getItem("InProgress")) {
            // this._commonServ._showInProgressRx$.next(false);
            let inProgRxs = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("InProgress")));
            if (inProgRxs.length !== rxToRestore) {
                rxToRestore = 1;
            }
            if (inProgRxs.length > 0) {
                const rx = inProgRxs[inProgRxs.length - rxToRestore];
                this._commonServ.storePatientIdinBS(rx.value.Patient.patientid);
                this._commonServ.storeRxIdinBS(rx.RxID);
                localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                localStorage.setItem(
                    "OtherRxInfo",
                    this._commonServ.encryptData(JSON.stringify(rx.otherRxInfo))
                );
                this.setsessionstorageInfo(rx.sessionInfo);
                inProgRxs.splice(inProgRxs.length - rxToRestore, 1);
                if (inProgRxs.length > 0) {
                    localStorage.setItem(
                        "InProgress",
                        this._commonServ.encryptData(JSON.stringify(inProgRxs))
                    );
                    this._commonServ._showInProgressRx$.next(true);
                } else {
                    localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify([])));
                    sessionStorage.removeItem("toEdit");
                    this._commonServ._showInProgressRx$.next(false);
                }
                this._router.navigate(["eprx/rx"], {
                    queryParams: { fm: rx.type }
                });
            }
        } else {
            this._router.navigate(["eprx/rx"], {
            queryParams: { fm: "nr" }
        });
}
    }

    setsessionstorageInfo(sessionInfo) {
        Object.keys(sessionInfo).map(key =>
            sessionStorage.setItem(key, sessionInfo[key])
        );
    }

    routeToRxFromOtherScreensback(rxToRestore?: number) {
        rxToRestore = rxToRestore ? rxToRestore : 1;
        const routeFrom = sessionStorage.getItem("toEdit");
        if (
            routeFrom === "nr" ||
            routeFrom === "er" ||
            routeFrom === "rf" ||
            routeFrom === "nrf" ||
            routeFrom === "brx" ||
            routeFrom === "rnr"
        ) {
            if (localStorage.getItem("InProgress")) {
                // this._commonServ._showInProgressRx$.next(false);
                let inProgRxs = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("InProgress")));
                if (inProgRxs.length > 0) {
                    const rx = inProgRxs.pop();
                    this._commonServ.storePatientIdinBS(
                        rx.value.Patient.patientid
                    );
                    this._commonServ.storeRxIdinBS(rx.RxID);
                    localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                    // inProgRxs.splice((inProgRxs.length - rxToRestore) , 1);
                    if (inProgRxs.length > 0) {
                        localStorage.setItem(
                            "InProgress",
                            this._commonServ.encryptData(JSON.stringify(inProgRxs))
                        );
                        this._commonServ._showInProgressRx$.next(true);
                    } else {
                        localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify([])));
                        sessionStorage.removeItem("toEdit");
                        this._commonServ._showInProgressRx$.next(false);
                    }
                    this._prevRoutServ.popPreviousUrls("eprx/rx");
                    this._router.navigate(["eprx/rx"], {
                        queryParams: { fm: rx.type }
                    });
                }
            }
        }
    }

    routeBackFromDrug(routeFrom?: string, hasFocus?: any, type?: any) {
        if (
            routeFrom === "nr" ||
            routeFrom === "er" ||
            routeFrom === "rf" ||
            routeFrom === "nrf" ||
            routeFrom === "brx" ||
            routeFrom === "rnr"
        ) {
            if (
                localStorage.getItem("InProgress") &&
                (routeFrom === "nr" || routeFrom === "rnr")
            ) {
                const inProgRxs = JSON.parse(this._commonServ.DecryptData(
                    localStorage.getItem("InProgress"))
                );
                if (inProgRxs.length > 0) {
                    const rx = inProgRxs.pop();
                    this._commonServ.storePatientIdinBS(
                        rx.value.Patient.patientid
                    );
                    this._commonServ.storeRxIdinBS(rx.RxID);
                    localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                    // inProgRxs.splice((inProgRxs.length - 1) , 1);
                    if (inProgRxs.length > 0) {
                        localStorage.setItem(
                            "InProgress",
                            this._commonServ.encryptData(JSON.stringify(inProgRxs))
                        );
                        this._commonServ._showInProgressRx$.next(true);
                    } else {
                        localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify([])));
                        this._commonServ._showInProgressRx$.next(false);
                    }
                }
            }
            sessionStorage.setItem("PatientComplete", "" + true);
            sessionStorage.setItem("PrescriberComplete", "" + true);
            if (type === "save") {
                sessionStorage.setItem("DrugChange", "" + true);
            }
            if (hasFocus) {
                sessionStorage.setItem("DrugComplete", "" + false);
                sessionStorage.setItem("FrmFocus", "" + hasFocus);
            } else {
                sessionStorage.setItem("DrugComplete", "" + true);
            }
            this._prevRoutServ.popPreviousUrls("eprx/rx");
            this._router.navigate(["eprx/rx"], {
                queryParams: { fm: routeFrom }
            });
        } else if (routeFrom === "inventory") {
            this._router.navigate(["/eprx/inventory"]);
        } else if (routeFrom === "drugpricecheck") {
            this._router.navigate(["/eprx/drugpricecheck"]);
        } else if (routeFrom === "inventory") {
            this._router.navigate(["eprx/inventory"]);
        } else if (routeFrom && routeFrom === "SearchDrug") {
            this._router.navigate(["eprx/drug/drugSearch"]);
        } else {
            this._prevRoutServ.routeBackToPrevScreen();

            // this._router.navigate(["eprx/dashboard"]);
        }
    }

    routeBackFromPresc(routeFrom?: string) {
        if (
            routeFrom === "nr" ||
            routeFrom === "er" ||
            routeFrom === "rf" ||
            routeFrom === "nrf" ||
            routeFrom === "brx" ||
            routeFrom === "rnr"
        ) {
            if (
                localStorage.getItem("InProgress") &&
                (routeFrom === "nr" || routeFrom === "rnr")
            ) {
                const inProgRxs = JSON.parse(this._commonServ.DecryptData(
                    localStorage.getItem("InProgress"))
                );
                if (inProgRxs.length > 0) {
                    const rx = inProgRxs.pop();
                    this._commonServ.storePatientIdinBS(
                        rx.value.Patient.patientid
                    );
                    this._commonServ.storeRxIdinBS(rx.RxID);
                    localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                    // inProgRxs.splice((inProgRxs.length - 1) , 1);
                    if (inProgRxs.length > 0) {
                        localStorage.setItem(
                            "InProgress",
                            JSON.stringify(inProgRxs)
                        );
                        this._commonServ._showInProgressRx$.next(true);
                    } else {
                        localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify([])));
                        this._commonServ._showInProgressRx$.next(false);
                    }
                }
            }
            this._prevRoutServ.popPreviousUrls("eprx/rx");
            this._router.navigate(["eprx/rx"], {
                queryParams: { fm: routeFrom }
            });
            sessionStorage.setItem("PatientComplete", "" + true);
            sessionStorage.setItem("PrescriberComplete", "" + true);
        } else if (routeFrom === "rphVerf") {
            this._router.navigate(["eprx/RphVerification"]);
        } else if (routeFrom === "SearchPrescriber") {
            this._router.navigate(["/eprx/prescriber/SearchPrescriber"]);
        } else if (
            this._prevRoutServ.previousUrl === "/eprx/prescriber/prescriberinfo"
        ) {
            this._router.navigate(["eprx/dashboard"]);
        } else {
            this._prevRoutServ.routeBackToPrevScreen();
            // this._router.navigate(["eprx/dashboard"]);
        }
    }

    routeBackFromInsu(routeFrom?: string, prevRoute?: any, insuranceId?: any) {
        if (
            routeFrom === "nr" ||
            routeFrom === "er" ||
            routeFrom === "rf" ||
            routeFrom === "nrf" ||
            routeFrom === "brx" ||
            routeFrom === "rnr"
        ) {
            if (
                localStorage.getItem("InProgress") &&
                (routeFrom === "nr" || routeFrom === "rnr")
            ) {
                const inProgRxs = JSON.parse(this._commonServ.DecryptData(
                    localStorage.getItem("InProgress"))
                );
                if (inProgRxs.length > 0) {
                    const rx = inProgRxs.pop();
                    this._commonServ.storePatientIdinBS(
                        rx.value.Patient.patientid
                    );
                    this._commonServ.storeRxIdinBS(rx.RxID);
                    localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                    // inProgRxs.splice((inProgRxs.length - 1) , 1);
                    if (inProgRxs.length > 0) {
                        localStorage.setItem(
                            "InProgress",
                            this._commonServ.encryptData(JSON.stringify(inProgRxs))
                        );
                        this._commonServ._showInProgressRx$.next(true);
                    } else {
                        localStorage.setItem("InProgress", this._commonServ.encryptData(JSON.stringify([])));
                        this._commonServ._showInProgressRx$.next(false);
                    }
                }
            }
            this._prevRoutServ.popPreviousUrls("eprx/rx");
            this._router.navigate(["eprx/rx"], {
                queryParams: { fm: routeFrom }
            });
            sessionStorage.setItem("PatientComplete", "" + true);
            sessionStorage.setItem("PrescriberComplete", "" + true);
            sessionStorage.setItem("DrugComplete", "" + true);
        } else if (routeFrom === "addPatient") {
            if (!prevRoute && !insuranceId) {
                this._router.navigate(["eprx/editpatient/patientinfo"], {
                    queryParams: { AddPatient: true }
                });
            } else if (prevRoute && !insuranceId) {
                this._router.navigate(["eprx/editpatient/patientinfo"], {
                    queryParams: { AddPatient: true, fm: prevRoute }
                });
            } else {
                this._router.navigate(["eprx/editpatient/patientinfo"], {
                    queryParams: {
                        AddPatient: true,
                        fm: prevRoute,
                        insuranceId: insuranceId
                    }
                });
            }
        } else if (routeFrom === "editPatient") {
            this._prevRoutServ.routeBackToPrevScreen();
            //     this._router.navigate([this._prevRoutServ.getPreviousUrl()], {
            //         queryParams: { AddPatient: true }
            //    });
        } else {
            this._prevRoutServ.routeBackToPrevScreen();
            //  this._router.navigate(["eprx/Insurance/SearchInsurance"]);
        }
    }

    routeBackFromErx(routeFrom?: String) {
        // sessionStorage.setItem("toEdit", "" + " ");
        if (routeFrom) {
            if (routeFrom === "PatHis") {
                this._prevRoutServ.routeBackToPrevScreen("PatHis");
            } else if (routeFrom === "nr") {
                this._router.navigate(["eprx/rx"], {
                    queryParams: { fm: routeFrom }
                });
            } else if (routeFrom === "Un") {
                this._router.navigate(["eprx/unbilledRx"]);
            } else if (routeFrom === "Br") {
                this._router.navigate(["eprx/browserx"]);
            } else if (routeFrom === "rph") {
                // this._router.navigate(["eprx/RphVerification"]);
                this._prevRoutServ.routeBackToPrevScreen("rph");
            } else if (routeFrom.includes("DispHis")) {
                if (routeFrom.split(":")[0] === "DispHis") {
                    if (routeFrom.split(":")[1].split(".")[1]) {
                        this._router.navigate(
                            [
                                "eprx/drug/" +
                                    routeFrom.split(":")[1].split(".")[0] +
                                    "/dispHistory"
                            ],
                            {
                                queryParams: {
                                    fm: routeFrom.split(":")[1].split(".")[1]
                                }
                            }
                        );
                    } else {
                        this._router.navigate([
                            "eprx/drug/" +
                                routeFrom.split(":")[1].split(".")[0] +
                                "/dispHistory"
                        ]);
                    }
                } else if (routeFrom.split(":")[0] === "DispHisF") {
                    if (routeFrom.split(":")[1].split(".")[1]) {
                        this._router.navigate(
                            [
                                "eprx/drug/" +
                                    routeFrom.split(":")[1].split(".")[0] +
                                    "/dispHistory"
                            ],
                            {
                                queryParams: {
                                    fm: routeFrom.split(":")[1].split(".")[1],
                                    focus: "drugUnit"
                                }
                            }
                        );
                    } else {
                        this._router.navigate(
                            [
                                "eprx/drug/" +
                                    routeFrom.split(":")[1].split(".")[0] +
                                    "/dispHistory"
                            ],
                            {
                                queryParams: { focus: "drugUnit" }
                            }
                        );
                    }
                }
            } else if (routeFrom === "Dash") {
                this._router.navigate(["eprx/dashboard"]);
            } else if (routeFrom === "TodaysRx") {
                this._router.navigate(["eprx/RphDash/TodayRx"]);
            } else if (routeFrom === "FiledQueue") {
                this._router.navigate(["eprx/FiledQueue"]);
            } else if (routeFrom === "erxHist") {
                this._router.navigate(["eprx/eRX/eRxActionList"]);
            } else {
                this._router.navigate(["eprx/rx"], {
                    queryParams: { fm: "nr" }
                });
            }
        } else {
            this._router.navigate(["eprx/rx"], {
                queryParams: { fm: "nr" }
            });
        }
    }

    checkIsRxDisbaled(frmGrp: any) {
        if (
            frmGrp.value.PrescReFill.GroupTransStatus === "Pending" ||
            (frmGrp.value.PrescReFill.GroupTransStatus === "Completed" &&
            frmGrp.value.RxPay.length)  ||
            frmGrp.value.PrescReFill.GroupTransStatus === "Transmitting"
        ) {
            return true;
        } else {
            return false;
        }
    }

    checkIsRxBilled(frmGrp: any) {
        if (
            frmGrp.value.PrescReFill.GroupTransStatus === "Pending" ||
            (frmGrp.value.PrescReFill.GroupTransStatus === "Completed" &&
            frmGrp.value.RxPay.length) ||
            frmGrp.value.PrescReFill.GroupTransStatus === "Transmitting" ||
            (!(frmGrp.value.PrescReFill.StatusId === 2 || frmGrp.value.PrescReFill.StatusId === 4 ||
                frmGrp.value.Prescription.RxStatusId === 2 || frmGrp.value.Prescription.RxStatusId === 4)
                 && +frmGrp.value.RxBill.StatusId === 2)
        ) {
            return true;
        } else {
            return false;
        }
    }

    checkInsuranceBilled(rxFG) {
        let disableBillTo: any;
        if (rxFG) {
        if (rxFG.value.RefillInsu && rxFG.value.RxPay && rxFG.value.RxPay.length) {
            if (rxFG.value.RefillInsu.InsuPriId === 1) {
                rxFG.value.RxPay.find(rec => {
                    if (rec && (rec.CoverageCD === 1 || rec.CoverageCD === 2) && rec.RecType === "P") {
                        disableBillTo = true;
                    }
                });
            } else if (rxFG.value.RefillInsu.InsuPriId === 2) {
                rxFG.value.RxPay.find(rec => {
                    if (rec && rec.CoverageCD === 2 && rec.RecType === "P") {
                        disableBillTo = true;
                    }
                });
            }
        }
        if (rxFG.value.PrescReFill && rxFG.value.Prescription  && (rxFG.value.PrescReFill.GroupTransStatus === "Pending" ||
            (rxFG.value.PrescReFill.GroupTransStatus === "Completed" &&
           (rxFG.value.RxPay && rxFG.value.RxPay.length)) ||
            rxFG.value.PrescReFill.GroupTransStatus === "Transmitting" ||
            (!(rxFG.value.PrescReFill.StatusId === 2 || rxFG.value.PrescReFill.StatusId === 4 ||
                rxFG.value.Prescription.RxStatusId === 2 || rxFG.value.Prescription.RxStatusId === 4)
                 && (rxFG.value.RxBill && +rxFG.value.RxBill.StatusId === 2))) || disableBillTo) {
            return true;
        } else {
            return false;
        }
        }
    }

    patchDefaultValues(rxFG: any, routeFrom: string) {
        const prescReFill: any = rxFG.controls.PrescReFill;
        const refillInsu: any = rxFG.controls.RefillInsu;
        const prescrip: any = rxFG.controls.Prescription;

        if (routeFrom === "nr") {
            prescReFill.controls["ReFillNum"].setValue(0);
            prescrip.controls["ReFillNum"].setValue(0);
        }
        if (!sessionStorage.getItem("ErxId")) {
            prescReFill.controls.SupplyDays.setValue(
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DEF_DAYS_SUPPLY"
                )
            );
            prescReFill.controls.OrderDays.setValue(
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DEF_DAYS_SUPPLY"
                )
            );
        }
        prescReFill.controls.BucketId.setValue(
            this._commonServ.getSetttingValue(
                "RxSettings",
                "DEF_GENERAL_BUCKET"
            )
                ? +this._commonServ.getSetttingValue(
                      "RxSettings",
                      "DEF_GENERAL_BUCKET"
                  )
                : null
        );
        prescReFill.controls.RxOriginId.setValue(
            this._commonServ.getSetttingValue("RxSettings", "DEF_RX_ORIG_CODE")
                ? +this._commonServ.getSetttingValue(
                      "RxSettings",
                      "DEF_RX_ORIG_CODE"
                  )
                : null
        );
        if(!rxFG.value["isFromDocuementQueue"]) {
            prescReFill.controls.DeliverModeId.setValue(
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DEF_DELIVERY_METHOD"
                )
                    ? +this._commonServ.getSetttingValue(
                          "RxSettings",
                          "DEF_DELIVERY_METHOD"
                      )
                    : this.getDelModeDefVal()
            );

        }
        prescReFill.controls.IsCopayPaid.setValue(
            this._commonServ.getSetttingValue(
                "RxSettings",
                "DEF_COPAY_PAID_STATUS"
            ) === "1"
                ? true
                : false
        );
        if (
            this._userService.getToken("TechUserName") &&
            this._userService.getToken("TechUserName") !== " "
        ) {
            prescReFill.controls.TechInitials.setValue(
                this._userService.getToken("TechUserName")
            );
        }
        if (
            this._userService.getToken("PhUserName") &&
            this._userService.getToken("PhUserName") !== " "
        ) {
            prescReFill.controls["RegPharmacist"].setValue(
                this._userService.getToken("PhUserName")
            );
        }

        // DEF_DOSE_TIME_INTERVAL
        //  Based on this card commented these changes EP-6860: UI: DAW: Remove and Update DAW Settings for Rx Entry
        // if (!prescReFill.value.DAWId) {
        //     const dawStng = +this._commonServ.getSetttingValue("RxSettings", "DEF_DAW");
        //     prescReFill.controls.DAWId.setValue(dawStng ? dawStng : null);
        // }
        if (routeFrom === "nr") {
            const today = moment(new Date());
            prescReFill.controls["FillDtTm"].setValue(today);
            prescReFill.controls["OrderDtTm"].setValue(
                this._commonServ.getSetttingValue("RxSettings", "MAKE_DT_ORDER_MNDT") === "0" ? today : null);
            prescReFill.controls["NoOfLabels"].setValue(1);
        }
    }

    getDelModeDefVal() {
        const Sdata = this._commonServ.systemData$["source"]["value"];
        let result = 3;
        if (Sdata && Sdata["DeliveryMode"].length) {
            const d = Sdata["DeliveryMode"].find(v => v["Code"] === "P");
            result = d ? d["Id"] : 3;
        }
        return result;
    }

    resetRxInfo(rxFG: any, routeFrom: string) {
        const prescRefFg: any = rxFG.controls.PrescReFill as FormGroup;
        Object.keys(prescRefFg.controls).map(i => [
            "DAWId", "LotNum", "ExpDt", "OrderDtTm", "FillDtTm", "SupplyDays", "OrderDays", "ReFillNum",
            "TechInitials", "RegPharmacist"].indexOf(i) >= -1 ? prescRefFg.controls[i].reset() : "");
    }

    focusInvalidFieldForErx(inputFormGroup:FormGroup) {
        const prescRefillFG = inputFormGroup.controls.PrescReFill as FormGroup;
        if (prescRefillFG.controls.DAWId.invalid) {
            this.focusToInputFieldById("rxdaw");
        } else if (prescRefillFG.controls.LotNum.invalid) {
            this.focusToInputFieldById("drugLotID");
        } else if (prescRefillFG.controls.LotExpiryDate.invalid) {
            this.focusToInputFieldById("rxExpires");
        } else if (prescRefillFG.controls.DispQty.invalid || +prescRefillFG.value.DispQty === 0) {
            this.focusToInputFieldById("rxDispQty");
        } else if (prescRefillFG.controls.OrdQty.invalid || +prescRefillFG.value.OrdQty === 0) {
            this.focusToInputFieldById("rxOrdrQty");
        } else if (prescRefillFG.controls.RxOriginId.invalid) {
            this.focusToInputFieldById("rxSigCode");
        } else if (prescRefillFG.controls.OrderDtTm.invalid) {
            this.focusToInputFieldById("rxOrderDtTm");
        } else if (prescRefillFG.controls.FillDtTm.invalid) {
            this.focusToInputFieldById("rxFillDtTm");
        } else if (prescRefillFG.controls.SupplyDays.invalid || +prescRefillFG.value.SupplyDays === 0) {
            this.focusToInputFieldById("rxSupplyDays");
        } else if ((<FormGroup>inputFormGroup.controls.Prescription).controls.ReFillNum.invalid) {
            this.focusToSelectById("rxReFillNum");
        } else if (prescRefillFG.controls.TechInitials.invalid) {
            this.focusToInputFieldById("rxTechInitials");
        } else if (prescRefillFG.controls.RegPharmacist.invalid) {
            this.focusToInputFieldById("rxRegPharmacist");
        } else if (prescRefillFG.controls.RxOriginId.invalid) {
            this.focusToSelectById("rxRxOriginId");
        } else if (prescRefillFG.controls.DeliverModeId.invalid) {
            this.focusToSelectById("rxDeliverModeId");
        } else if (prescRefillFG.controls.Price.invalid) {
            this.focusToInputFieldById("rxPrice");
        } else {
            this.focusToInputFieldById(inputFormGroup.value.rxType === "er" ? "actionInputer" : "actionInput");
        }
    }

    focusToInputFieldById(fieldToFocus) {
        if (document.getElementById(fieldToFocus)) {
            let elm;
            elm = document.getElementById(fieldToFocus);
            if (elm) {
                elm.focus();
                // elm.select();
            }
        }
    }

    focusToSelectById(fieldToFocus) {
        const element=document.getElementById(fieldToFocus);
        if (element && element.tagName === "NG-SELECT") {
            const ngSelectElem: any = document.getElementsByClassName("ng-input");
            const len = ngSelectElem.length;
            for (let i = 0; i < len; i++) {
                if (
                    ngSelectElem[i] &&
                    ngSelectElem[i]["parentElement"]["parentElement"]["parentElement"]["id"] === fieldToFocus
                ) {
                    ngSelectElem[i]["id"] = fieldToFocus;
                    ngSelectElem[i]["children"][0].focus();
                }
            }
            (<HTMLElement>element.firstElementChild.firstElementChild.children[1]).focus();
        }
    }

    filterItems(totalItems, filterRows, searchValue) {
        searchValue = searchValue.toLowerCase();
        let filteredItems = [];
        if (totalItems && totalItems.length > 0) {
            filteredItems = totalItems.filter(singleItem => {
                if (filterRows && filterRows.length > 0) {
                    if (filterRows.some(filterRow => singleItem[filterRow] && singleItem[filterRow].toString().toLowerCase().includes(searchValue))) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (Object.keys(singleItem).some(singleKey => singleItem[singleKey] && singleItem[singleKey].toString().toLowerCase().includes(searchValue))) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
        } else {
            filteredItems = totalItems;
        }
        return filteredItems;
    }


    filterInsurancesForSuggest(totalInsuranceData,term) {
        const searchTerms = term.split(",");
        let filteredData = [];
        const insuranceList = Object.assign([], totalInsuranceData);
        if (insuranceList && insuranceList.length > 0) {
            ["e1comm", "..."].map(codes => {
                const index = insuranceList.findIndex(val => (val["InsurerCode"]).toLowerCase() === codes);
                if (index > -1) {
                    insuranceList.splice(index, 1);
                }
            });
        }
        let planCodeData = insuranceList.filter(v => {
            let val = v["PlanCode"];
            return val.toString().toLowerCase().startsWith(searchTerms[0].toLocaleLowerCase());
        });
        planCodeData.sort((a, b) => (a.PlanCode > b.PlanCode) ? 1 : ((b.PlanCode > a.PlanCode) ? -1 : 0));
        filteredData = filteredData.concat(planCodeData);

        let insuNameData = insuranceList.filter(v => {
            let val = v["InsuranceName"];
            return (val.toString().toLowerCase().startsWith(searchTerms[0].toLocaleLowerCase()) && (filteredData.findIndex(elm=> elm.Id===v.Id) === -1));
        });
        insuNameData.sort((a, b) => (a.InsuranceName > b.InsuranceName) ? 1 : ((b.InsuranceName > a.InsuranceName) ? -1 : 0));
        filteredData = filteredData.concat(insuNameData);
        let binNumData = insuranceList.filter(v => {
            let val = v["BinNum"];
            return (val.toString().toLowerCase().startsWith(searchTerms[0].toLocaleLowerCase()) && (filteredData.findIndex(elm=> elm.Id===v.Id) === -1));
        });
        binNumData.sort((a, b) => (a.BinNum > b.BinNum) ? 1 : ((b.BinNum > a.BinNum) ? -1 : 0));
        filteredData = filteredData.concat(binNumData);
        if (searchTerms[1]) {
            filteredData = filteredData.filter(v => {
                let valExists = false;
                const val = v["ProcessorNum"] ? v["ProcessorNum"] : "";
                valExists =
                    valExists ||
                    val
                        .toString()
                        .toLowerCase()
                        .startsWith(searchTerms[1].toLocaleLowerCase());
                return valExists;
            });
        }
        return filteredData;
    }

    async getfindAndInsuDet(patData, code?, InsuId?) {
        let INS = null;
        let codeType = code ? code+"" : "";
        INS = codeType ? patData.insurance.find(type => type &&  type.insuType && (type.insuType.trim() === codeType.trim())) :
        patData.insurance.find(x => x.insuranceid === +InsuId);
        let InsuDet = null;
        if (INS) {
            InsuDet = await this._insuSvc.getEditInsuranceInformation(+INS.insuplanId).toPromise();
        }
        return InsuDet;
    }
}
