// tslint:disable-next-line: max-line-length
import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService, CommonService, RphVerificationService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import { RxModel, RxOrigin, SystemData } from "src/app/models";
import { CommonUtil } from "src/app/utils";
import * as _ from "lodash";
import { Subject, Subscription } from "rxjs";
import { SelectionModel } from "@ng-select/ng-select";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-actions-history",
  templateUrl: "./actions-history.component.html",
})
export class ActionsHistoryComponent implements OnInit, AfterViewInit {
  subscriptions = new Subscription();
  modalHeader: any;
  rxInfo: any;
  actnPopUp: any;
  insData: any;
  patientAl: any;
  address: any;
  showForAllActns: any;
  patientId: any;
  activeModal = false;
  processingErxReq: any;
  systemData: SystemData;
  selectedRxForReq: RxModel;
  hasButtons = false;
  openFor = "";
  selectedRxs: any[] = [];
  rxOriginList: RxOrigin[];
  printRefWJ: CollectionView;
  switchValue: any;
  showDue: boolean;
  unsubscribe$: Subject<void> = new Subject();
  clearcheckbox: boolean = false;

  @Input() ReviewXDays: any;
  @Input()
  set Modalheader(str: string) {
    this.modalHeader = str;
  }

  @Input()
  set RxInfo(data: any) {
    if (data) {
      this.rxInfo = data;
      this.patientId = this.rxInfo["Patient"].patientid;
      this.address = (this.rxInfo.Patient.address1 ? this.rxInfo.Patient.address1 : "") + ", " +
      (this.rxInfo.Patient.city ? this.rxInfo.Patient.city : "") + ", " + (this.rxInfo.Patient.state ?
      this.rxInfo.Patient.state : "") + ", " + (this.rxInfo.Patient.zipcode ? this.rxInfo.Patient.zipcode : "" );
    }
  }

  @Input()
  set Insurance(dt: any) {
    if (dt) {
      this.insData = dt;
    }
  }

  @Input()
  set PatientAlrgs(ptAl: any) {
    if (ptAl) {
      this.patientAl = ptAl;
    }
  }

  @Input()
  set SwitchValue(val: number) {
    this.switchValue = val;
  }

  @Output()
  IsPopupClosed = new EventEmitter<any>();

  @ViewChild("actionHistory", { static: true })
  actionHistory: any;

  constructor(private _modalService: NgbModal, private _rphServ: RphVerificationService, private _commonSer: CommonService,
             private _comnUtil: CommonUtil, private _alertServ: AlertService, private _cdr: ChangeDetectorRef) {
  // tslint:disable-next-line: deprecation
  this._commonSer.systemData$
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(resp => {
    if (resp) {
        this.rxOriginList = resp.RxOrigin;
        this.generateWijmo();
    }
});
   }

  ngOnInit() {
    this.actnPopUp = this._modalService.open(this.actionHistory,
      {
        size: "lg",
        windowClass: "md-x-lg_77",
        centered: true,
        keyboard: false,
        backdrop: "static"
      });
    this.getRxDetails();
  }

  getRxDetails() {
    this.getPatientHistoryDetails();
  }

  ngAfterViewInit() {

  }

  getPatientHistoryDetails() {
    const val = this.switchValue;
    this.activeModal = false;
    switch (val) {
        case 1:
          this.activeModal = true;
          this.showDue = true;
          this.showForAllActns = "Refill Due Rxs";
          break;
        case 2:
          this.activeModal = true;
          this.showDue = false;
          this.showForAllActns = "ExpiredRxs";
          break;
        case 3:
          this.activeModal = true;
          this.showDue = false;
          this.showForAllActns = "Awaiting Pickup Rxs";
          break;
        case 4:
          this.activeModal = true;
          this.showDue = false;
          this.showForAllActns = "Awaiting RPH Verification Rxs";
          break;
        case 5:
          this.activeModal = true;
          this.showDue = false;
          this.showForAllActns = "FiledRxs";
          break;
        default:
          break;
    }
  }


  closePopup(val) {
    this.activeModal = false;
    this.IsPopupClosed.emit(val);
  }

  getSelectedRxs(slRxs: any) {
    this.selectedRxs = slRxs;
    this.generateWijmo();
  }

  printRefillReqAndErx(type) {
    this.clearcheckbox = false;
    if (type === "printRefillReq") {
      if (this.selectedRxs && this.selectedRxs.length > 0) {
        this.openFor = type;
      } else {
        this._alertServ.error("Please Select atleast one Rx.");
      }
    } else if (type === "sendErx") {
      if (this.selectedRxs && this.selectedRxs.length > 0) {
        this.openModal(type);
      } else {
        this._alertServ.error("Please Select atleast one Rx.");
      }
    }
  }

  closePrintRefill(eve) {
    this.openFor = "";
    this._cdr.detectChanges()
    this.clearcheckbox = true;
  }


  openModal(modalType) {
    if (modalType === "sendErx") {
        this.processingErxReq = 0;
        this.processSendRefills();
    } else {
        this.activeModal = modalType;
    }
}

processSendRefills() {
    this.selectedRxForReq = this.selectedRxs[this.processingErxReq];
    this.openFor = "sendErx";
}

closeSendRefillReq() {
    this.processingErxReq++;
    this.openFor = "";
    if (this.processingErxReq < this.selectedRxs.length) {
      this._cdr.detectChanges();
        // setTimeout(() => {
            this.processSendRefills();
        // }, 1000);
    } else {
      this._cdr.detectChanges()
      this.clearcheckbox = true;
    }
}

emitPatienthistory(eve) {
  if (eve && eve.rxHistory && eve.rxHistory.length > 0) {
    this.hasButtons = true;
  }
}

generateWijmo() {
  this.printRefWJ = new CollectionView(
      this.selectedRxs.map((patRx, i) => {
          const k = {};
          k["RowId"] = i;
          k["PatientName"] =
              patRx.Patient.lastname + ", " + patRx.Patient.firstname;
          k["PrescriberName"] =
              patRx.Prescriber.prescriberlastname +
              ", " +
              patRx.Prescriber.prescriberfirstname;
          k["PrescPhone"] = patRx.Prescriber.faxnumber.length === 10 ? "1" + patRx.Prescriber.faxnumber : patRx.Prescriber.faxnumber;
          k["RxNo"] = patRx.Prescription.PrescNum.toString();
          k["RxOrigin"] = this.getRxOrgNameById(
              patRx.PrescReFill.RxOriginId
          );
          k["drugClass"] = patRx.Drug.drugclass;
          k["PrescriberId"] = patRx.Prescriber.prescriberid;
          k["FaxSubject"] = "Refill Request";
          k["Notes"] = "";
          // k["DrugClassID"] = patRx.Dru.drugclass;
          k["PrescRefillId"] = patRx.PrescReFill.Id
          return k;
      })
  );
}

getRxOrgNameById(id: number): string {
  let name = "";
  this.rxOriginList.forEach(element => {
      if (element.Id === id) {
          name = element.Name;
      }
  });
  return name;
}

closeActnScreen() {
    if (this.actnPopUp) {
        this.actnPopUp.close();
        this.actnPopUp = null;
    }
    this.IsPopupClosed.emit(false);
}
ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
