import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-prescriber-result-card",
    templateUrl: "./prescriber-result-card.component.html",
    styles: []
})
export class PrescriberResultCardComponent implements OnInit {
    showPrescName: boolean;
    PrescAddress: any;
    @Input() searchTerm: any;
    @Input() index: any;
    @Input()
    set PrescriberResultData(prescriberData: any) {
        this.inputData = prescriberData;
        if (this.inputData && this.inputData.PrescriberAddresses) {
            if (this.inputData.PrescriberAddresses.length > 1) {
                this.inputData.PrescriberAddresses.map(value => {
                    if (value["Id"] === this.inputData.primeAddressId) {
                        this.PrescAddress = value;
                    }
                });
            } else {
                this.PrescAddress =  this.inputData.PrescriberAddresses["0"];
            }
        }
    }

    @Input()
    set RouterURL(ru: string) {
        this.routerURL = ru;
    }

    @Input()
    set IsAllToShow(all: boolean) {
        this.isAllToShow = all;
    }

    @Input()
    set ShowOnlyPrescName(prescName: boolean) {
        this.showPrescName = prescName;
    }


    @Output() SelectedPrescriber = new EventEmitter();

    inputData: any;
    routerURL: string;
    isAllToShow: boolean;

    constructor(public _formatsUtil: FormatsUtil) {

    }

    ngOnInit() {}

    selectedPresc(prescriber: string): any {
        this.SelectedPrescriber.emit({ prescriber });
    }
}
