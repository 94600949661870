import { MsgConfig } from "./../../../app.messages";
import { AlertService } from "./../../../services/alert.service";
import { Drug } from "./../../../models/patient-Info.model";
import { CommonService } from "src/app/services";
import { PaginationModel, SystemData } from "src/app/models";
import {
    DrugData,
    DrugRefreshMMS,
    MiscInfo
} from "./../../../models/edit-drug.model";
import { CollectionView, DateTime } from "@grapecity/wijmo";
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    AfterViewInit,
    ChangeDetectorRef,
    OnChanges,
    ViewChild,
    HostListener
} from "@angular/core";
import { EditDrugService, DrugService } from "../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as wjcChart from "@grapecity/wijmo.chart";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import { FormGroup, FormBuilder } from "@angular/forms";
import * as _ from "lodash";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";

@Component({
    selector: "app-drug-common-header",
    templateUrl: "./drug-common-header.component.html"
})
export class DrugCommonHeaderComponent
    implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input()
    headingData: string;

    @ViewChild("drugSearch")
    drugSearch: any;

    @ViewChild("MMS", { static: true })
    MMS: any;

    @ViewChild("warning", { static: true })
    warning: any;
    paginationModel: PaginationModel = new PaginationModel();
    drugData: DrugData;
    drugInfo: Drug;
    drugName: string;
    drugId: number;
    mmsDataWJ: CollectionView;
    actvHeaders: string[];
    inactvHeader: string[];
    hasMMSData = false;
    WIJIMO: any;
    searchDrug: any;
    refreshMMS: any;
    DrugMMSdata: DrugRefreshMMS;
    selectedDrugMMs: any;
    refreshListToModify: any;
    refreshDrugCoun: any;
    refreshDrugMisc: any;
    equiWJ: CollectionView;
    drugType: string;
    drugForm: string;
    unitOfMes: string;
    unitDoseIndi: string;
    madeBy: string;
    doseForm: string;
    disUnit: string;
    routAdm: string;
    systemData: SystemData;
    drugMiscData: MiscInfo;
    drugCounData: any;
    route: any;
    hideFilterForm = false;
    drugSearchFG: FormGroup;
    updateValue: number;
    drugN: string;
    drugNDC: string;
    drugQ: string;
    copiedDrug: number;
    copiedDrugName: string;
    alertMSG: any;
    drugFG: FormGroup;
    drugdata: any;
    copiedData: DrugData;
    defVal: any;
    originalData: DrugData;
    orgCounData: any;
    orgMiscData: any;
    activeModal: any;
    drugNameOrg: any;
    today: any;
    strength: string;
    drugunit: string;
    totalMMsDrug: any;
    pageNu = this.paginationModel.pageNumber;
    Size = 15;
    SearchMMSLength = 0;
    isDrugSearchFocused = false;
    equiDurg: any;
    warningPopUp: any;
    Warning1PopUp: any;
    drugMMSDataGrid: wjcGrid.FlexGrid;
    refreshMMSDataGrid: wjcGrid.FlexGrid;
    selectedMMSSetData: any;
    @Input()
    set DrugData(di: FormGroup) {
        this.drugFG = di;
    }

    @ViewChild("DrugMMS")
    flex: wjcGrid.FlexGrid;


    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && (this.searchDrug || this.alertMSG || this.equiDurg ||
            this.refreshMMS || this.drugSearchFG.value || this.WIJIMO || this.warningPopUp || this.Warning1PopUp)) { // esc
            event.preventDefault();
            if (!(this.warningPopUp || this.Warning1PopUp)) {
                this.closeModal();
            }
            if (this.Warning1PopUp) {
                this.Warning1PopUp.close();
                this.Warning1PopUp = null;
            }
            if (this.warningPopUp) {
                this.warningPopUp.close();
                this.warningPopUp = null;
            }
        } else if (event.ctrlKey && this.modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                if (this.activeModal === "Add") {
                    event.preventDefault();
                    // this.addBucketPrice();
                }
                if (this.WIJIMO) {
                    this.show();
                    this.searchMMS(this.MMS);
                }
            }
            if (event.which === 76) {
                if (this.WIJIMO) {
                    this.clearSearchData();
                }
            }
            if (event.which === 85) { // u
                if (this.activeModal === "Update") {
                    event.preventDefault();
                    // this.updateBucketPrice();
                } if (this.refreshMMS) {
                    this.updatePopUp(1, this.warning);
                    this.refreshMMS.close();
                    this.refreshMMS = null;
                }
            }
            if (event.which === 78) {
                if (this.warningPopUp) {
                    event.preventDefault();
                    this.warningPopUp.close();
                    this.warningPopUp = null;
                }
                if (this.Warning1PopUp) {
                    event.preventDefault();
                    this.Warning1PopUp.close();
                    this.Warning1PopUp = null;
                }
            }
        }

    }

    constructor(
        private _drg: EditDrugService,
        private _actvRoute: ActivatedRoute,
        private modalService: NgbModal,
        private _drugService: DrugService,
        private _cdr: ChangeDetectorRef,
        private _commonServ: CommonService,
        private _router: Router,
        private _fb: FormBuilder,
        private _drugSvc: EditDrugService,
        private _alertService: AlertService,
        private _auditUtils: AuditLogUtils
    ) {
        this.drugSearchFG = this._fb.group({
            DrugName: [""],
            NDCCode: [""],
            QuickCode: [""]
        });
        if (this._actvRoute && this._actvRoute.parent) {
            this._actvRoute.parent.params.subscribe(params => {
                this.drugId = +params["pid"];
                if (this.drugId) {
                    this.getDrugData();
                } else {
                    this.drugInfo = new Drug();
                    this.drugData = new DrugData();
                    this.patchNewValue();
                }
            });
        } else {
            this.drugInfo = new Drug();
            this.drugData = new DrugData();
            this.patchNewValue();
        }
    }
    ngOnChanges(changes: any) {
        if (this.drugFG) {
        }
    }

    ngOnInit() {
        this._commonServ.systemData$.subscribe(resp => {
            this.systemData = resp;
        });
        this.route = window.location.href;
        this._actvRoute.queryParams.subscribe(params => {
            if (params && params.openMMS) {
                if (params.openMMS) {
                    this.patchNewDrugInfo();
                    this.drugInfoData(this.MMS);
                }
            }
        });
    }

    patchNewDrugInfo() {
        let drugInfo = sessionStorage.getItem("AddDrugInfo");
        sessionStorage.removeItem("AddDrugInfo");
        if (drugInfo && drugInfo !== "") {
            if (/^([0-9]{11})$/.test(drugInfo)) {
                this.drugSearchFG.controls["NDCCode"].patchValue(+drugInfo ? +drugInfo : null);
            } else {
                if (drugInfo.substring(0, 2) === "d:") {
                    drugInfo = drugInfo.substring(2, drugInfo.length + 1);
                }
                const drugNames = drugInfo.split(/[\s,]+/);
                this.drugSearchFG.controls["DrugName"].patchValue(drugNames[0] ? drugNames[0].trim() : null);
            }
        }
    }

    patchNewValue() {
        this.drugInfo["DrugTypeId"] = 1;
        this.drugInfo["DrugFormId"] = 1;
        this.drugInfo["DrugUnitId"] = 1;
        this.drugInfo["MeasureUnitId"] = 1;
        this.drugInfo["NDCQualiId"] = 1;
        this.drugInfo["UnitDoseIndId"] = 1;
        this.drugInfo["IsActive"] = true;
        this.drugInfo["IsPreferred"] = false;
        this.drugInfo["IsIncInRefilRem"] = false;
        this.drugInfo["IsFDAApprovedNULL"] = 1;
        this.drugInfo["IsExclFrmUpdate"] = false;
        this.drugInfo["IsPriceUpdAuto"] = true;
        this.drugInfo["IsBranded"] = true;
        this.drugInfo["DrugClass"] = 0;
        this.drugInfo["QtyPack"] = "0.00";
        this.drugInfo["UnitPriceAWP"] = "0.000";
        this.drugInfo["UnitPriceCost"] = "0.000";
        this.drugInfo["CostPack"] = "0.00";
        this.drugInfo["AWPPack"] = "0.000";
        this.drugInfo["DirectUnitPrice"] = "0.000";
    }

    getDrugData() {
        this._commonServ.getDrugInfo(this.drugId).subscribe(resp => {
            this.drugData = resp;
            this.originalData = _.cloneDeep(resp);
            this.drugInfo = resp["Drug"];
            this.drugName = this.drugInfo.Name ? this.drugInfo.Name : " ";
            this.strength = this.drugInfo.Strength ? this.drugInfo.Strength : "";
            this.drugunit = this.drugInfo.DrugUnit ? this.drugInfo.DrugUnit : "";
            this.drugForm = this.drugInfo.DrugFormName ? this.drugInfo.DrugFormName.substring(0, 3) : "";
        });
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.focus();
        this.drugMMSDataGrid = flex;
    }

    refreshInit(flex: wjcGrid.FlexGrid) {
        flex.focus();
        this.refreshMMSDataGrid = flex;
    }

    focusOndrugMMSGrid(event) {
        if (this.drugMMSDataGrid) {
            event.preventDefault();
            this.drugMMSDataGrid.focus();
        }
    }

    focusOnRefreshMMSGrid(event) {
        if (this.refreshMMSDataGrid) {
            event.preventDefault();
            this.refreshMMSDataGrid.focus();
        }
    }

    spaceEventOnWijimo(event, type, grid) {
        if (grid["selectedItems"][0]["link"]) {
            event.preventDefault();
            document.getElementById(grid["selectedItems"][0]["link"])["checked"] =
                !document.getElementById(grid["selectedItems"][0]["link"])["checked"];
            this.refreshMMSData(
                grid["selectedItems"][0],
                type,
                document.getElementById(grid["selectedItems"][0]["link"])["checked"]
            );
        }
    }

    focusOnMMSCancel(event) {
        if (document.getElementById("mmscancelbu")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("mmscancelbu").focus();
            }, 10);
        }
    }

    keyEventOnMMSWijimo(content, content1, event, grid) {
        if (grid["selectedItems"][0]) {
            event.preventDefault();
            this.selectMMSData(grid["selectedItems"][0]["Select"]);
            this.generateNewData(content, content1, 1);
        }
    }

    cancelEventOnMMSGrid(event, type) {
        if (event.ctrlKey) {
            if (event.which === 67) {
                event.preventDefault();
                if (type === "MMS") {
                    this.closeModal();
                }
                if (type === "REFRESH") {
                    if (this.refreshMMS) {
                        this.refreshMMS.close();
                        this.refreshMMS = null;
                    }
                }
            }
        }
    }
    /* Copy Code Start */

    copyPopUp(content) {
        if (this.drugId) {
            this._commonServ.getDrugInfo(this.drugId).subscribe(resp => {
                if (resp) {
                    this.originalData = resp;
                }
            });
        }
        this.searchDrug = this.modalService.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.isDrugSearchFocused = false;
        setTimeout(() => {
            this.isDrugSearchFocused = true;
        }, 20);
    }

    isFocusedOutofSearch() {
        this.isDrugSearchFocused = false;
    }

    copyDrugAlert(content, $event) {
        if ($event !== "") {
            this.copiedDrug = $event.id;
            this.copiedDrugName = $event.drugname;
            this.searchDrug.close();
            this.alertMSG = this.modalService.open(content, {
                centered: true,
                keyboard: false,
                backdrop: false
            });
        }
    }
    copyDrugInfo() {
        if (this.drugId) {
            this._drg.copyDrug(this.drugId, this.copiedDrug).subscribe(resp => {
                if (resp) {
                    this._alertService.success(MsgConfig.COPIED_SUCCESS);
                    if (this.route.includes("druginfo")) {
                        this._commonServ
                            .getDrugInfo(this.drugId)
                            .subscribe(resps => {
                                if (resps) {
                                    this.copiedData = resps;
                                    this._auditUtils.getChangedValues(
                                        this.originalData.Drug,
                                        this.copiedData.Drug,
                                        "Copy Drug",
                                        "Drug",
                                        this.drugId
                                    );
                                    this.drugInfo = resps["Drug"];
                                    this.patchValue(this.copiedData, "copy");
                                }
                            });
                    } else {
                        this._router.navigate([
                            "eprx/drug/" + this.drugId + "/druginfo"
                        ]);
                    }
                } else {
                    this._alertService.error(MsgConfig.COPIED_UNSUCCESS);
                }
            });
        } else {
            this._commonServ.getDrugInfo(this.copiedDrug).subscribe(resp => {
                if (resp) {
                    this.copiedData = resp;
                    this.drugInfo = resp["Drug"];
                    this.patchValue(this.copiedData, "copy");
                }
            });
        }
        if (document.getElementById("Name")) {
            document.getElementById("Name").focus();
        }
    }

    /* Copy Code End */

    closeModalFocus() {
        if (document.getElementById("SaveButton")) {
            document.getElementById("SaveButton").focus();
        }
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = 15;
    }

    closeModal() {
        if (this.WIJIMO) {
            this.WIJIMO.close();
            this.WIJIMO = null;
            if (!this.drugId) {
                this.drugSearchFG.reset();
                this.drugSearchFG.markAsUntouched();
            }
        }

        if (this.WIJIMO) {
            this.WIJIMO.close();
            this.WIJIMO = null;
        }
        if (this.drugSearchFG.value) {
            this.drugSearchFG.reset();
        }
        if (this.searchDrug) {
            this.searchDrug.close();
            this.searchDrug = null;
        }
        if (this.refreshMMS) {
            this.refreshMMS.close();
            this.refreshMMS = null;
        }
        if (this.alertMSG) {
            this.alertMSG.close();
            this.alertMSG = null;
        }

        if (this.equiDurg) {
            this.equiDurg.close();
            this.equiDurg = null;
        }

        if (this.warningPopUp) {
            this.warningPopUp.close();
            this.warningPopUp = null;
        }
    }

    show() {
        this.hideFilterForm = !this.hideFilterForm;
    }
    searchMMS(content) {
        this.defaultMMSData();
        this.drugN = this.drugSearchFG.controls["DrugName"].value;
        this.drugNDC = this.drugSearchFG.controls["NDCCode"].value;
        this.drugQ = this.drugSearchFG.controls["QuickCode"].value;
        this.MMSData(content);
    }
    MMSDataPopUp(content) {
        if (!this.WIJIMO) {
            this.WIJIMO = this.modalService.open(content, {
                size: "lg",
                keyboard: false,
                backdrop: false
            });
        }
    }
    MMSData(content) {
        // if (this.WIJIMO) {
        //     this.WIJIMO.close();
        // }
        if (
            !this.drugSearchFG.controls["DrugName"].value &&
            !this.drugSearchFG.controls["NDCCode"].value
        ) {
            this.drugSearchFG.controls["DrugName"].patchValue(
                this.drugInfo.Name ? this.drugInfo.Name.trim() : null
            );
            this.drugN = this.drugSearchFG.controls["DrugName"].value;
        }
        const dataInfo = this.drugSearchFG.value;
        this._drg.getDrugMmsData(dataInfo).subscribe(resp => {
            if (resp && resp["length"] > 0) {
                this.selectedDrugMMs = resp;
                this.totalMMsDrug = resp;
                this.mmsPagination();
                this.hasMMSData = true;
                this.DrugMMSdata = resp[0];
            } else {
                this.hasMMSData = false;
            }
            this.MMSDataPopUp(content);
        });
    }

    exportExcel() {
        wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
            this.flex,
            {formatItem: (args) =>  args.xlsxCell.formula = null},
            "DrugMMS.xlsx"
        );
    }

    mmsPagination() {
        const startIndex = (this.pageNu * this.Size) - this.Size;
        const endIndex = (startIndex + this.Size);
        this.selectedMMSSetData = this.totalMMsDrug.slice(startIndex, endIndex);
        this.generateWJ(this.selectedMMSSetData);
    }

    setPage(page: number) {
        this.pageNu = page;
        this.mmsPagination();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        this.mmsPagination();
    }

    defaultMMSData() {
        if (this.drugId) {
            this.getDrugData();
            this._drg.getDrugDefaultvalbyPost({DrugManufactNeeded  : true ,SigNeeded : false ,DrugFormNeeded : true, VendorsNeeded : true}).subscribe(resp => {
                if (resp) {
                    this.defVal = resp;
                    this.madeBy = this.drugInfo.ManufactId ?
                        this.defVal.Organization.find(val => val.Id === this.drugInfo.ManufactId)["Name"] : null;
                    this.drugForm = this.drugInfo.DrugFormId ?
                        this.defVal.FDA_Form.find(val => val.FDAFormID === this.drugInfo.DrugFormId)["FormName"] : null;
                }
            });
            this._drg.getDrugCodes(this.drugId, true).subscribe(resp => {
                this.drugCounData = resp;
                this.orgCounData = _.cloneDeep(resp);
            });
            this._drg.getMiscInfo(this.drugId).subscribe(resp => {
                this.drugMiscData = resp;
                this.orgMiscData = _.cloneDeep(resp);
                if (resp) {
                    this.routAdm = (this.drugMiscData.DrugMisc && this.drugMiscData.DrugMisc.AdmnstrnRouteId) ?
                        this.drugMiscData.AdmnstrnRoute.find(val => val.Id === this.drugMiscData.DrugMisc.AdmnstrnRouteId)["Name"]
                        : null;
                    this.doseForm = (this.drugMiscData.DrugMisc && this.drugMiscData.DrugMisc.DoseFrmCodeId) ?
                        this.drugMiscData.DoseFrmCode.find(val => val.Id === this.drugMiscData.DrugMisc.DoseFrmCodeId)["Name"]
                        : null;
                    this.disUnit = (this.drugMiscData.DrugMisc && this.drugMiscData.DrugMisc.DispUnitFormId) ?
                        this.drugMiscData.DispUnitForm.find(val => val.Id === this.drugMiscData.DrugMisc.DispUnitFormId)["Name"]
                        : null;
                }
            });
            this.drugType = this.drugInfo.DrugTypeId ?
                this.systemData.DrugType.find(val => val.Id === this.drugInfo.DrugTypeId)["Name"] : null;
            this.unitOfMes = this.drugInfo.DrugUnitId ?
                this.systemData.DrugUnit.find(val => val.Id === this.drugInfo.DrugUnitId)["Remarks"] : null;
            this.unitDoseIndi = this.drugInfo.UnitDoseIndId ?
                this.systemData.UnitDoseIndication.find(val => val.Id === this.drugInfo.UnitDoseIndId)["Remarks"] : null;
        } else {
            this._drg.getDrugDefaultvalbyPost({DrugManufactNeeded  : true ,SigNeeded : false ,DrugFormNeeded : true, VendorsNeeded : true}).subscribe(resp => {
                if (resp) {
                    this.defVal = resp;
                }
            });
        }
    }

    drugInfoData(content) {
        this.defaultMMSData();
        this.MMSData(content);
    }
    referesh(content) {
        this.hasMMSData = false;
        this.MMSDataPopUp(content);
    }
    generateWJ(resp: any) {
        this.hasMMSData = true;
        if (resp && resp.length > 0) {
            this.SearchMMSLength = resp.length;
            this.mmsDataWJ = new CollectionView(
                resp.map((drug, i) => {
                    const j = {};
                    j["QckCode"] = drug.QckCode;
                    j["DrugName"] = drug.DrugName;
                    j["DrugNDC"] = drug.DrugNDC;
                    j["BillNDC"] = drug.BillNDC;
                    j["BrandName"] = drug.BrandName;
                    j["GenericName"] = drug.GenericName;
                    j["Madeby"] = drug.Madeby;
                    j["DrugType"] = drug.DrugType;
                    j["Strength"] = drug.Strength;
                    j["DrugForm"] = drug.DrugForm;
                    j["unitofMeasure"] = drug.unitofMeasure;
                    j["Brand"] = drug.Brand;
                    j["QuantityPack"] = drug.Quanitypack;
                    j["upc"] = drug.upc;
                    j["UnitPrice"] = drug.UnitPrice;
                    j["DrugClass"] = drug.DrugClass;
                    j["DrugCategory"] = drug.DrugCategory;
                    j["TxrCode"] = drug.TxrCode;
                    j["Warning"] = drug.Warning;
                    j["DosageForm"] = drug.DosageForm;
                    j["RouteOfAdministration"] = drug.RouteOfAdministration;
                    j["Select"] = i;
                    return j;
                })
            );
            this.actvHeaders = [
                "Select",
                "DrugName",
                "DrugNDC",
                "BillNDC",
                "BrandName",
                "GenericName",
                "Madeby",
                "DrugType",
                "Strength",
                "DrugForm",
                "unitofMeasure",
                "Brand",
                "QuantityPack",
                "upc",
                "UnitPrice",
                "DrugClass",
                "DrugCategory",
                "TxrCode",
                "Warning",
                "DosageForm",
                "RouteOfAdministration"
            ];
        } else {
            this.SearchMMSLength = 0;
        }
    }
    selectMMSData(val: any) {
        this.DrugMMSdata = this.selectedMMSSetData[val];
    }
    generateNewData(content, content1, value: number) {
        this.today = moment().format("MM/DD/YYYY");
        if (this.refreshMMS) {
            this.refreshMMS.close();
        }


        if (this.drugId) {
            this.refreshListToModify = [
                {
                    head: "DRUG NAME",
                    currentvalue: this.drugInfo.Name ? this.drugInfo.Name : null,
                    MMsvalue: this.DrugMMSdata.DrugName
                        ? this.DrugMMSdata.DrugName.trim()
                        : null,
                    link:
                        !(
                            this.drugInfo.Name.trim().toLowerCase() === this.DrugMMSdata.DrugName.trim().toLowerCase()
                        ) && this.DrugMMSdata.DrugName
                            ? "A"
                            : null,
                    changeValue: "Name"
                },
                {
                    head: "DRUG TYPE",
                    currentvalue: this.drugType ? this.drugType : null,
                    MMsvalue: this.DrugMMSdata.DrugType
                        ? this.DrugMMSdata.DrugType.trim()
                        : null,
                    link:
                        !(this.drugType === this.DrugMMSdata.DrugType.trim()) &&
                            this.DrugMMSdata.DrugType
                            ? "B"
                            : null,
                    changeValue: "drugType"
                },
                {
                    head: "BRAND",
                    currentvalue: this.drugInfo.IsBranded,
                    MMsvalue: (this.DrugMMSdata.Brand === "y" || this.DrugMMSdata.Brand === "Y")
                        ? this.DrugMMSdata.Brand = "true" : this.DrugMMSdata.Brand = "false",
                    link: !((this.drugInfo.IsBranded).toString() === this.DrugMMSdata.Brand) ? "C" : null,
                    changeValue: "IsBranded"
                },
                {
                    head: "FORM",
                    currentvalue: this.drugForm,
                    MMsvalue: this.DrugMMSdata.DrugForm.trim(),
                    link: this.DrugMMSdata.DrugForm ? "D'" : null,
                    changeValue: "drugForm"
                },
                {
                    head: "STRENGTH",
                    currentvalue: this.drugInfo.Strength,
                    MMsvalue: this.DrugMMSdata.Strength.trim(),
                    link:
                        !(
                            this.drugInfo.Strength ===
                            this.DrugMMSdata.Strength.trim()
                        ) && this.DrugMMSdata.Strength
                            ? "E"
                            : null,
                    changeValue: "Strength"
                },
                {
                    head: "UNIT OF MEASURE",
                    currentvalue: this.unitOfMes,
                    MMsvalue: this.DrugMMSdata.unitofMeasure.trim(),
                    link:
                        !(
                            this.unitOfMes === this.DrugMMSdata.unitofMeasure.trim()
                        ) && this.DrugMMSdata.unitofMeasure
                            ? "F"
                            : null,
                    changeValue: "unitOfMes"
                },
                {
                    head: "UNIT DOSE IND",
                    currentvalue: this.unitDoseIndi,
                    MMsvalue: this.DrugMMSdata.unitdoesofInd.trim(),
                    link:
                        !(
                            this.unitDoseIndi ===
                            this.DrugMMSdata.unitdoesofInd.trim()
                        ) && this.DrugMMSdata.unitdoesofInd
                            ? "G"
                            : null,
                    changeValue: "unitDoseIndi"
                },
                {
                    head: "MADE BY",
                    currentvalue: this.madeBy,
                    MMsvalue: this.DrugMMSdata.Madeby.trim(),
                    link:
                        !(this.madeBy === this.DrugMMSdata.Madeby.trim()) &&
                            this.DrugMMSdata.Madeby
                            ? "H"
                            : null,
                    changeValue: "madeBy"
                },
                {
                    head: "QUANTITY-PACK",
                    currentvalue: this.drugInfo.QtyPack,
                    MMsvalue: this.DrugMMSdata.Quanitypack.trim(),
                    link:
                        !(
                            this.drugInfo.QtyPack ===
                            this.DrugMMSdata.Quanitypack.trim()
                        ) && this.DrugMMSdata.Quanitypack
                            ? "I"
                            : null,
                    changeValue: "QtyPack"
                },
                {
                    head: "BRAND NAME",
                    currentvalue: this.drugInfo.BrandName,
                    MMsvalue: this.DrugMMSdata.BrandName.trim(),
                    link:
                        !(
                            this.drugInfo.BrandName.trim().toLowerCase() ===
                            this.DrugMMSdata.BrandName.trim().toLowerCase()
                        ) &&
                            this.DrugMMSdata.BrandName.trim().toLowerCase().length > 0
                            ? "J"
                            : null,
                    changeValue: "BrandName"
                },
                {
                    head: "GENERIC NAME",
                    currentvalue: this.drugInfo.GenericName,
                    MMsvalue: this.DrugMMSdata.GenericName,
                    link:
                        !(
                            this.drugInfo.GenericName.trim().toLowerCase() ===
                            this.DrugMMSdata.GenericName.trim().toLowerCase()
                        ) && this.DrugMMSdata.GenericName.trim().toLowerCase().length > 0
                            ? "K"
                            : null,
                    changeValue: "GenericName"
                },
                {
                    head: "UPC",
                    currentvalue: this.drugInfo.UPC,
                    MMsvalue: this.DrugMMSdata.upc,
                    link: !(this.drugInfo.UPC === this.DrugMMSdata.upc.trim()) && this.DrugMMSdata.upc ? "L" : null,
                    changeValue: "UPC"
                },
                {
                    head: "UNIT PRICE-PACK",
                    currentvalue: this.drugInfo.UnitPriceCost,
                    MMsvalue: this.DrugMMSdata.CostPrice,
                    link: !(this.drugInfo.UnitPriceCost === this.DrugMMSdata.CostPrice.trim()) && this.DrugMMSdata.CostPrice ? "M" : null,
                    changeValue: "UnitPriceCost"
                },
                {
                    head: "UNIT PRICE-AWP",
                    currentvalue: this.drugInfo.UnitPriceAWP,
                    MMsvalue: this.DrugMMSdata.UnitPrice,
                    link: !(this.drugInfo.UnitPriceAWP === this.DrugMMSdata.UnitPrice.trim()) && this.DrugMMSdata.UnitPrice ? "N" : null,
                    changeValue: "UnitPriceAWP"
                },
                {
                    head: "DRUG CLASS",
                    currentvalue: this.drugInfo.DrugClass,
                    MMsvalue: this.DrugMMSdata.DrugClass,
                    link: !((this.drugInfo.DrugClass).toString() === this.DrugMMSdata.DrugClass.trim()) &&
                        this.DrugMMSdata.DrugClass ? "O'" : null,
                    changeValue: "DrugClass"
                },
                {
                    head: "DISCONTINUE DATE",
                    currentvalue: this.drugInfo.DiscontDt ? moment(this.drugInfo.DiscontDt).format(
                        "MM/DD/YYYY"
                    ) : null,
                    MMsvalue: this.DrugMMSdata.discontDate ? moment(this.DrugMMSdata.discontDate).format(
                        "MM/DD/YYYY"
                    ) : null,
                    link:
                        this.DrugMMSdata.discontDate && moment(this.DrugMMSdata.discontDate).format(
                            "MM/DD/YYYY"
                        ) > this.today
                            ? "P"
                            : null,
                    changeValue: "DiscontDt"
                }
            ];
            this.refreshDrugCoun = [
                {
                    head: "TXRCODE",
                    currentvalue: this.drugCounData ? this.drugCounData.TherapeuticCode
                        ? this.drugCounData.TherapeuticCode
                        : null : null,
                    MMsvalue: this.DrugMMSdata.TxrCode.match(/(0*[1-9]+0*)+/)
                        ? this.DrugMMSdata.TxrCode
                        : null,
                    link: this.DrugMMSdata.TxrCode.match(/(0*[1-9]+0*)+/)
                        ? "Q"
                        : null,
                    changeValue: "TherapeuticCode"
                },
                {
                    head: "WARNING",
                    currentvalue: this.drugCounData ? this.drugCounData.WarningCode
                        ? this.drugCounData.WarningCode
                        : null : null,
                    MMsvalue: this.DrugMMSdata.Warning.match(/(0*[1-9]+0*)+/)
                        ? this.DrugMMSdata.Warning
                        : null,
                    link: this.DrugMMSdata.Warning.match(/(0*[1-9]+0*)+/)
                        ? "R"
                        : null,
                    changeValue: "WarningCode"
                }
            ];
            this.refreshDrugMisc = [
                {
                    head: "DOSAGE FROM DESCRIPTION",
                    currentvalue: this.doseForm ? this.doseForm : null,
                    MMsvalue: this.DrugMMSdata.DosageForm,
                    link: this.DrugMMSdata.DosageForm ? "S" : null,
                    changeValue: "doseForm"
                },
                {
                    head: "DISPENSING UNIT FORM",
                    currentvalue: this.disUnit ? this.disUnit : null,
                    MMsvalue: this.DrugMMSdata.DispensUnit
                        ? this.DrugMMSdata.DispensUnit
                        : null,
                    link: this.DrugMMSdata.DispensUnit ? "T" : null,
                    changeValue: "disUnit"
                },
                {
                    head: "ROUTE OF ADMINISTRATION",
                    currentvalue: this.routAdm ? this.routAdm : null,
                    MMsvalue: this.DrugMMSdata.RouteOfAdministration
                        ? this.DrugMMSdata.RouteOfAdministration
                        : null,
                    link: this.DrugMMSdata.RouteOfAdministration ? "U" : null,
                    changeValue: "routAdm"
                }
            ];

            this.refreshMMS = this.modalService.open(content, {
                size: "lg"
            });
        } else {
            this.refreshListToModify = [
                {
                    head: "DRUG NAME",
                    currentvalue: this.drugInfo.Name ? this.drugInfo.Name : null,
                    MMsvalue: this.DrugMMSdata.DrugName
                        ? this.DrugMMSdata.DrugName
                        : null,
                    link: "A",
                    changeValue: "Name"
                },
                {
                    head: "NDC",
                    currentvalue: this.drugInfo.DrugCode ? this.drugInfo.DrugCode : null,
                    MMsvalue: this.DrugMMSdata.DrugNDC
                        ? this.DrugMMSdata.DrugNDC
                        : null,
                    link: "Q",
                    changeValue: "DrugCode"
                },
                {
                    head: "DRUG TYPE",
                    currentvalue: this.drugType ? this.drugType : null,
                    MMsvalue: this.DrugMMSdata.DrugType
                        ? this.DrugMMSdata.DrugType
                        : null,
                    link: "B",
                    changeValue: "drugType"
                },
                {
                    head: "BRAND",
                    currentvalue: this.drugInfo.IsBranded,
                    MMsvalue: (this.DrugMMSdata.Brand === "y" || this.DrugMMSdata.Brand === "Y")
                        ? this.DrugMMSdata.Brand = "true" : this.DrugMMSdata.Brand = "false",
                    link: "C",
                    changeValue: "IsBranded"
                },
                {
                    head: "FORM",
                    currentvalue: this.drugForm,
                    MMsvalue: this.DrugMMSdata.DrugForm,
                    link: "D'",
                    changeValue: "drugForm"
                },
                {
                    head: "STRENGTH",
                    currentvalue: this.drugInfo.Strength,
                    MMsvalue: this.DrugMMSdata.Strength,
                    link: "E",
                    changeValue: "Strength"
                },
                {
                    head: "UNIT OF MEASURE",
                    currentvalue: this.unitOfMes,
                    MMsvalue: this.DrugMMSdata.unitofMeasure,
                    link: "F",
                    changeValue: "unitOfMes"
                },
                {
                    head: "UNIT DOSE IND",
                    currentvalue: this.unitDoseIndi,
                    MMsvalue: this.DrugMMSdata.unitdoesofInd,
                    link: "G",
                    changeValue: "unitDoseIndi"
                },
                {
                    head: "MADE BY",
                    currentvalue: this.madeBy,
                    MMsvalue: this.DrugMMSdata.Madeby,
                    link: "H",
                    changeValue: "madeBy"
                },
                {
                    head: "QUANTITY-PACK",
                    currentvalue: this.drugInfo.QtyPack,
                    MMsvalue: this.DrugMMSdata.Quanitypack,
                    link: "I",
                    changeValue: "QtyPack"
                },
                {
                    head: "BRAND NAME",
                    currentvalue: this.drugInfo.BrandName,
                    MMsvalue: this.DrugMMSdata.BrandName,
                    link: "J",
                    changeValue: "BrandName"
                },
                {
                    head: "GENERIC NAME",
                    currentvalue: this.drugInfo.GenericName,
                    MMsvalue: this.DrugMMSdata.GenericName,
                    link: "K",
                    changeValue: "GenericName"
                },
                {
                    head: "UPC",
                    currentvalue: this.drugInfo.UPC,
                    MMsvalue: this.DrugMMSdata.upc,
                    link: "L",
                    changeValue: "UPC"
                },
                {
                    head: "UNIT PRICE-PACK",
                    currentvalue: this.drugInfo.UnitPriceCost,
                    MMsvalue: this.DrugMMSdata.CostPrice,
                    link: "M",
                    changeValue: "UnitPriceCost"
                },
                {
                    head: "UNIT PRICE-AWP",
                    currentvalue: this.drugInfo.UnitPriceAWP,
                    MMsvalue: this.DrugMMSdata.UnitPrice,
                    link: "N",
                    changeValue: "UnitPriceAWP"
                },
                {
                    head: "DRUG CLASS",
                    currentvalue: this.drugInfo.DrugClass,
                    MMsvalue: this.DrugMMSdata.DrugClass,
                    link: "O'",
                    changeValue: "DrugClass"
                },
                {
                    head: "DISCONTINUE DATE",
                    currentvalue: this.drugInfo.DiscontDt ? moment(this.drugInfo.DiscontDt).format(
                        "MM/DD/YYYY"
                    ) : null,
                    MMsvalue: this.DrugMMSdata.discontDate
                        ? moment(this.DrugMMSdata.discontDate).format(
                            "MM/DD/YYYY"
                        ) : null,
                    link: "P",
                    changeValue: "DiscontDt"
                }
            ];
            this.Warning1PopUp = this.modalService.open(content1, { centered: true });
        }
    }

    addNewDrugFrmMMS() {
        this.closeModal();
        if (this.refreshListToModify && this.refreshListToModify.length > 0) {
            this.refreshListToModify.map(val => {
                this.refreshMMSData(val, 1);
            });
        }
        let strengthUnit;
        let strength;
        let strengthUnit1;
        let strength1;
        if (this.DrugMMSdata.Strength) {
            this.DrugMMSdata.Strength.trim();
            strengthUnit = this.DrugMMSdata.Strength;
            strength = this.DrugMMSdata.Strength;
            strengthUnit1 = strengthUnit.replace(/\d/g, "");
            strength1 = strength.replace(/\D+/g, "");
            // this.DrugMMSdata.Strength = strength1.replace(/\%/g, "");
            this.drugInfo.Strength = strength1.replace(/\%/g, "");
        }
        this.drugInfo.Therapeutic = +this.DrugMMSdata.Therapeutic;
        this.drugInfo.TherapeuticCode = +this.DrugMMSdata.TherapeuticCode;
        this.drugInfo.DiscontDt = this.DrugMMSdata.ObsDate ? new Date(this.DrugMMSdata.ObsDate) : null;
        this.drugInfo.ManufactId = this.madeBy ? _.find(this.defVal.Organization, {
            Name: this.madeBy.trim()
        }) ? _.find(this.defVal.Organization, {
            Name: this.madeBy.trim()
        }).Id : null : this.drugInfo.ManufactId;
        this.drugInfo.DrugFormId = this.drugForm ? (this.defVal.FDA_Form.find(formVal =>
            (formVal.FormName).toUpperCase() === this.drugForm.trim())) ? (this.defVal.FDA_Form.find(formVal =>
                (formVal.FormName).toUpperCase() === this.drugForm.trim())).FDAFormID : null : this.drugInfo.DrugFormId;

        this.drugInfo.DrugTypeId = this.drugType ? _.find(this.systemData.DrugType, {
            Name: this.drugType.trim()
        }).Id : this.drugInfo.DrugTypeId;
        this.drugInfo.MeasureUnitId = strengthUnit1 ? _.find(this.systemData.DrugUnit, {
            Remarks: strengthUnit1.replace(/[\.-]/g, "")
        }) ? _.find(this.systemData.DrugUnit, {
            Remarks: strengthUnit1.replace(/[\.-]/g, "")
        }).Id : this.drugInfo.MeasureUnitId : this.drugInfo.MeasureUnitId;
        this.drugInfo.DrugUnitId = this.unitOfMes ? _.find(this.systemData.DrugUnit, {
            Remarks: this.unitOfMes.trim()
        }) ? _.find(this.systemData.DrugUnit, {
            Remarks: this.unitOfMes.trim()
        }).Id : this.drugInfo.DrugUnitId : this.drugInfo.DrugUnitId;
        this.drugInfo.UnitDoseIndId = this.unitDoseIndi ? _.find(this.systemData.UnitDoseIndication, {
            Remarks: this.unitDoseIndi.trim()
        }).Id : this.drugInfo.UnitDoseIndId;
        this.drugData.Drug = this.drugInfo;
        this.patchValueFrNew(this.drugData);
        if (this.WIJIMO) {
            this.WIJIMO.close();
        }
        if (this.refreshMMS) {
            this.refreshMMS.close();
        }
        this.clearSearchData();
        this.closeModalFocus();
    }

    refreshMMSData(val: any, value, content?: any) {
        const selectdata = _.find(this.refreshListToModify, { head: val.head });
        const hasKeyDrug = _.has(this.drugInfo, selectdata["changeValue"]);
        if (value === 1) {
            if (hasKeyDrug) {
                if (selectdata["changeValue"] === "IsBranded") {
                    if (selectdata["MMsvalue"] === "true") {
                        this.drugInfo[selectdata["changeValue"]] = true;
                    } else {
                        this.drugInfo[selectdata["changeValue"]] = false;
                    }
                } else {
                    if (
                        selectdata["MMsvalue"]
                    ) {
                        this.drugInfo[
                            selectdata["changeValue"]
                        ] = selectdata["MMsvalue"].trim();
                    } else {
                        this.drugInfo[
                            selectdata["changeValue"]
                        ] = selectdata["currentvalue"];
                    }
                }
            } else {
                if (selectdata["changeValue"] === "drugType") {
                    if (this.drugType !== selectdata["MMsvalue"]) {
                        this.drugType = selectdata["MMsvalue"];
                    } else {
                        this.drugType = selectdata["currentvalue"];
                    }
                } else if (selectdata["changeValue"] === "madeBy") {
                    if (this.madeBy !== selectdata["MMsvalue"]) {
                        this.madeBy = selectdata["MMsvalue"];
                    } else {
                        this.madeBy = selectdata["currentvalue"];
                    }
                } else if (selectdata["changeValue"] === "unitDoseIndi") {
                    if (
                        this.unitDoseIndi !== selectdata["MMsvalue"]
                    ) {
                        this.unitDoseIndi = selectdata["MMsvalue"];
                    } else {
                        this.unitDoseIndi = selectdata["currentvalue"];
                    }
                } else if (selectdata["changeValue"] === "unitOfMes") {
                    if (
                        this.unitOfMes !==
                        selectdata["MMsvalue"]
                    ) {
                        this.unitOfMes = selectdata["MMsvalue"];
                    } else {
                        this.unitOfMes = selectdata["currentvalue"];
                    }
                } else if (selectdata["changeValue"] === "drugForm") {
                    if (
                        this.drugForm !==
                        selectdata["MMsvalue"]
                    ) {
                        this.drugForm = selectdata["MMsvalue"];
                    } else {
                        this.drugForm = selectdata["currentvalue"];
                    }
                }
            }
        }
        if (value === 2) {
            for (const key in this.refreshDrugCoun) {
                if (key) {
                    if (this.refreshDrugCoun[key].head === val.head) {
                        for (const keys in this.drugCounData) {
                            if (
                                this.refreshDrugCoun[key].changeValue === keys
                            ) {
                                this.drugCounData[keys] = this.refreshDrugCoun[
                                    key
                                ].MMsvalue;
                            }
                        }
                    }
                }
            }
        }
        if (value === 3) {
            if (val.head === "DOSAGE FROM DESCRIPTION") {
                if (this.doseForm !== val.MMsvalue) {
                    this.doseForm = val.MMsvalue;
                } else {
                    this.doseForm = val.currentvalue;
                }
            }
            if (val.head === "ROUTE OF ADMINISTRATION") {
                if (this.routAdm !== val.MMsvalue) {
                    this.routAdm = val.MMsvalue;
                } else {
                    this.routAdm = val.currentvalue;
                }
            }
            if (val.head === "DISPENSING UNIT FORM") {
                if (this.disUnit !== val.MMsvalue) {
                    this.disUnit = val.MMsvalue;
                } else {
                    this.disUnit = val.currentvalue;
                }
            }
        }
        //this.generateNewData(content, value);
    }

    updatePopUp(val, content) {
        this.closeModal();
        let strengthUnit;
        let strength;
        let strengthUnit1;
        let strength1;
        if (this.DrugMMSdata.Strength) {
            this.DrugMMSdata.Strength.trim();
            strengthUnit = this.DrugMMSdata.Strength;
            strength = this.DrugMMSdata.Strength;
            strengthUnit1 = strengthUnit.replace(/\d/g, "");
            strength1 = strength.replace(/[A-Z][a-z]/g, "");
            this.DrugMMSdata.Strength = strength1.replace(/\%/g, "");
        }
        if (this.madeBy) {
            const manufactName = this.defVal.Organization.find(x => x.Name.trim() === this.madeBy.trim());
            if (manufactName) {
                this.drugInfo.ManufactId = manufactName.Id;
            } else {
                this.drugInfo.ManufactId = null;
                this.drugInfo.ManufactName = this.madeBy.trim();
            }
        }
        this.drugInfo.Therapeutic = +this.DrugMMSdata.Therapeutic;
        this.drugInfo.TherapeuticCode = +this.DrugMMSdata.TherapeuticCode;
        this.drugInfo.DrugFormId = this.drugForm ? (this.defVal.FDA_Form.find(formVal =>
            (formVal.FormName).toUpperCase() === this.drugForm.trim())) ? (this.defVal.FDA_Form.find(formVal =>
                (formVal.FormName).toUpperCase() === this.drugForm.trim())).FDAFormID : null : this.drugInfo.DrugFormId;

        this.drugInfo.DrugTypeId = this.drugType ? _.find(this.systemData.DrugType, {
            Name: this.drugType.trim()
        }).Id : this.drugInfo.DrugTypeId;
        this.drugInfo.MeasureUnitId = strengthUnit ? _.find(this.systemData.DrugUnit, {
            Remarks: strengthUnit1.replace(/[\.-]/g, "")
        }) ? _.find(this.systemData.DrugUnit, {
            Remarks: strengthUnit1.replace(/[\.-]/g, "")
        }).Id : this.drugInfo.MeasureUnitId : this.drugInfo.MeasureUnitId;
        this.drugInfo.DrugUnitId = this.unitOfMes ? _.find(this.systemData.DrugUnit, {
            Remarks: this.unitOfMes.trim()
        }) ? _.find(this.systemData.DrugUnit, {
            Remarks: this.unitOfMes.trim()
        }).Id : this.drugInfo.DrugUnitId : this.drugInfo.DrugUnitId;
        this.drugInfo.UnitDoseIndId = this.unitDoseIndi ? _.find(this.systemData.UnitDoseIndication, {
            Remarks: this.unitDoseIndi.trim()
        }).Id : this.drugInfo.UnitDoseIndId;
        if (this.drugMiscData.DrugMisc) {
            this.drugMiscData.DrugMisc.AdmnstrnRouteId = this.routAdm ? _.find(this.drugMiscData.AdmnstrnRoute, {
                Name: this.routAdm.trim()
            }).Id : this.drugMiscData.DrugMisc ? this.drugMiscData.DrugMisc.AdmnstrnRouteId : null;
            this.drugMiscData.DrugMisc.DoseFrmCodeId = this.doseForm ? _.find(this.drugMiscData.DoseFrmCode, {
                Name: this.doseForm.trim()
            }).Id : this.drugMiscData.DrugMisc ? this.drugMiscData.DrugMisc.DoseFrmCodeId : null;
            this.drugMiscData.DrugMisc.DispUnitFormId = this.disUnit ? _.find(this.drugMiscData.DispUnitForm, {
                Name: this.disUnit.trim()
            }).Id : this.drugMiscData.DrugMisc ? this.drugMiscData.DrugMisc.DispUnitFormId : null;
        }
        this.warningPopUp = this.modalService.open(content, {
            centered: true,
            keyboard: false
        });
        this.updateValue = val;
    }

    updateDrug() {
        this.drugData.Drug = this.drugInfo;
        this._drg.updateDrugInfo(this.drugData).subscribe(resp => {
            if (resp === 0 || resp === 3 || resp === 5) {
                this._drg
                    .updateDrugCoun(this.drugCounData)
                    .subscribe(resp1 => { });
                this._drg.updateMisc(this.drugMiscData).subscribe(resp2 => { });
                this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                if (!this.route.includes("druginfo")) {
                    this._router.navigate([
                        "eprx/drug/" + this.drugId + "/druginfo"
                    ]);
                } else {
                    this.getDrugData();
                    this.patchValue(this.drugData, "mms");
                }
                if (document.getElementById("SaveButton")) {
                    document.getElementById("SaveButton").focus();
                }
            } else {
                this._alertService.error(MsgConfig.UPDATE_FAIL);
            }
        });
    }
    convertTo2Decimal(changes: any): void {
        if (!this.drugFG.controls["Drug"].value[changes]) {
            this.drugData.Drug[changes] = "0.000";
        }
        if (this.drugFG.controls["Drug"].value[changes] !== 0) {
            this.drugData.Drug[changes] = parseFloat(
                this.drugFG.controls["Drug"].value[changes]
            ).toFixed(3);

            if (changes === "UnitPriceAWP") {
                this.drugData.Drug["AWPPack"] = (
                    this.drugData.Drug["QtyPack"] * this.drugData.Drug[changes]
                ).toFixed(3);
                this.drugFG.controls["Drug"].value[
                    "AWPPack"
                ] = this.drugData.Drug["AWPPack"];
            } else if (changes === "UnitPriceCost") {
                this.drugData.Drug["CostPack"] = (
                    this.drugData.Drug["QtyPack"] * this.drugData.Drug[changes]
                ).toFixed(3);
                this.drugFG.controls["Drug"].value[
                    "CostPack"
                ] = this.drugData.Drug["CostPack"];
            } else if (changes === "AWPPack") {
                this.drugData.Drug["UnitPriceAWP"] = (
                    this.drugData.Drug[changes] / this.drugData.Drug["QtyPack"]
                ).toFixed(3);
                this.drugFG.controls["Drug"].value[
                    "UnitPriceAWP"
                ] = this.drugData.Drug["UnitPriceAWP"];
            } else if (changes === "CostPack") {
                this.drugData.Drug["UnitPriceCost"] = (
                    this.drugData.Drug[changes] / this.drugData.Drug["QtyPack"]
                ).toFixed(3);
                this.drugFG.controls["Drug"].value[
                    "UnitPriceCost"
                ] = this.drugData.Drug["UnitPriceCost"];
            }
        } else {
            this.drugData.Drug[changes] = "0.000";
        }
        this.drugFG.controls["Drug"].value[changes] = this.drugData.Drug[
            changes
        ];
    }
    convertTo3Decimal(changes: any): void {
        if (!this.drugFG.controls["Drug"].value[changes]) {
            this.drugData.Drug[changes] = "0.000";
        }
        if (this.drugFG.controls["Drug"].value[changes] !== 0) {
            this.drugData.Drug[changes] = parseFloat(
                this.drugFG.controls["Drug"].value[changes]
            ).toFixed(3);
            if (changes === "QtyPack") {
                this.drugData.Drug["AWPPack"] = (
                    this.drugData.Drug["UnitPriceAWP"] *
                    this.drugData.Drug[changes]
                ).toFixed(2);
                this.drugData.Drug["CostPack"] = (
                    this.drugData.Drug["UnitPriceCost"] *
                    this.drugData.Drug[changes]
                ).toFixed(2);
            }
        } else {
            this.drugData.Drug[changes] = "0.000";
        }
        this.drugFG.controls["Drug"].value[changes] = this.drugData.Drug[
            changes
        ];
    }
    /* Equivalent Drug Code Start */
    getEquivalentDrugInfo(content) {
        if (this.drugId) {
            this._drugService.getEquivalentDrug(this.drugId, null, false).subscribe(resp => {
                this.generateEquiWJ(content, resp);
            });
        }
    }

    generateEquiWJ(content, info: any) {
        this.equiWJ = new CollectionView(info);
        this.equiDurg = this.modalService.open(content, {
            size: "lg",
            keyboard: false,
            backdrop: false
        });
    }

    /* Equivalent Drug Code End */

    patchValue(Data, type) {
        if (this.drugId) {
            const PriceHisdata: any = this.drugFG.controls["PriceHistory"];
            PriceHisdata.controls["NewAWPPrice"].patchValue(
                Data["Drug"]["AWPPack"]
            );
            PriceHisdata.controls["NewCostPack"].patchValue(
                Data["Drug"]["CostPack"]
            );
            PriceHisdata.controls["NewUnitDIR"].patchValue(
                Data["Drug"]["DirectUnitPrice"]
            );
            PriceHisdata.controls["OldAWPPrice"].patchValue(
                this.drugFG.value["Drug"].AWPPack
            );
            PriceHisdata.controls["OldCostPack"].patchValue(
                this.drugFG.value["Drug"].CostPack
            );
            PriceHisdata.controls["OldUnitDIR"].patchValue(
                this.drugFG.value["Drug"].DirectUnitPrice
            );
            if (Data["Drug"] && Data["Drug"]["QtyPack"] && type === "mms") {
                Data["Drug"]["AWPPack"] = (parseFloat(Data["Drug"]["QtyPack"]) *
                    parseFloat(Data["Drug"]["UnitPriceAWP"])).toFixed(2);
                Data["Drug"]["CostPack"] = (parseFloat(Data["Drug"]["CostPack"]) *
                    parseFloat(Data["Drug"]["UnitPriceCost"])).toFixed(2);
            }

            for (const key in Data) {
                if (["Drug"].indexOf(key) > -1 && Data[key]) {
                    for (const keys in Data[key]) {
                        if (!(keys === "Id")) {
                            if (
                                [
                                    "AWPPack",
                                    "DirectUnitPrice",
                                    "CostPack",
                                    "UnitPriceAWP",
                                    "UnitPriceCost"
                                ].indexOf(keys) > -1
                            ) {
                                if (!Data.Drug[keys]) {
                                    Data.Drug[keys] = "0.00";
                                } else {
                                    Data.Drug[keys] = parseFloat(Data.Drug[keys])
                                        .toFixed(2)
                                        .toString();
                                }
                            } else if (
                                ["DefaultQty", "QtyPack"].indexOf(keys) > -1
                            ) {
                                if (!Data.Drug[keys]) {
                                    Data.Drug[keys] = "0.00";
                                } else {
                                    Data.Drug[keys] = parseFloat(Data.Drug[keys])
                                        .toFixed(2)
                                        .toString();
                                }
                            } else if (["LastUpdateDt"].indexOf(keys) > -1) {
                                if (Data.Drug.LastUpdateDt) {
                                    Data.Drug.LastUpdateDt = moment(
                                        Data.Drug.LastUpdateDt
                                    ).format("MM-DD-YYYY");
                                } else {
                                    Data.Drug.LastUpdateDt = null;
                                }
                            }
                        }
                    }
                    this.drugFG.controls[key].patchValue(Data[key]);
                    this._drugSvc
                        .updateDrugInfo(this.drugFG.value)
                        .subscribe(resp2 => {
                            if (resp2 === 0 || resp2 === 3 || resp2 === 5) {
                            }
                        });
                }
            }
        } else {
            Data["Drug"].Name = " ";
            Data["Drug"].QuickCode = " ";
            Data["Drug"].DrugCode = " ";
            if (Data["Drug"] && Data["Drug"]["QtyPack"] && type === "mms") {
                Data["Drug"]["AWPPack"] = (parseFloat(Data["Drug"]["QtyPack"]) *
                    parseFloat(Data["Drug"]["UnitPriceAWP"])).toFixed(2);
                Data["Drug"]["CostPack"] = (parseFloat(Data["Drug"]["CostPack"]) *
                    parseFloat(Data["Drug"]["UnitPriceCost"])).toFixed(2);
            }
            for (const key in Data) {
                if (["Drug"].indexOf(key) > -1 && Data[key]) {
                    for (const keys in Data[key]) {
                        if (!(keys === "Id")) {
                            if (
                                [
                                    "AWPPack",
                                    "DirectUnitPrice",
                                    "CostPack",
                                    "UnitPriceAWP",
                                    "UnitPriceCost"
                                ].indexOf(keys) > -1
                            ) {
                                if (!Data.Drug[keys]) {
                                    Data.Drug[keys] = "0.00";
                                } else {
                                    Data.Drug[keys] = parseFloat(Data.Drug[keys])
                                        .toFixed(2)
                                        .toString();
                                }
                            } else if (
                                ["DefaultQty", "QtyPack"].indexOf(keys) > -1
                            ) {
                                if (!Data.Drug[keys]) {
                                    Data.Drug[keys] = "0.00";
                                } else {
                                    Data.Drug[keys] = parseFloat(Data.Drug[keys])
                                        .toFixed(2)
                                        .toString();
                                }
                            } else if (["LastUpdateDt"].indexOf(keys) > -1) {
                                if (Data.Drug.LastUpdateDt) {
                                    Data.Drug.LastUpdateDt = moment(
                                        Data.Drug.LastUpdateDt
                                    ).format("MM-DD-YYYY");
                                } else {
                                    Data.Drug.LastUpdateDt = null;
                                }
                            }
                        }
                    }
                    this.drugFG.controls[key].patchValue(Data[key]);
                }
            }
        }
    }

    patchValueFrNew(Data) {
        if (Data["Drug"] && Data["Drug"]["QtyPack"]) {
            Data["Drug"]["AWPPack"] = (parseFloat(Data["Drug"]["QtyPack"]) *
                parseFloat(Data["Drug"]["UnitPriceAWP"])).toFixed(2);
            Data["Drug"]["CostPack"] = (parseFloat(Data["Drug"]["CostPack"]) *
                parseFloat(Data["Drug"]["UnitPriceCost"])).toFixed(2);
        }
        for (const key in Data) {
            if (["Drug"].indexOf(key) > -1 && Data[key]) {
                for (const keys in Data[key]) {
                    if (!(keys === "Id")) {
                        if (
                            [
                                "DirectUnitPrice",
                                "UnitPriceAWP",
                                "UnitPriceCost",
                                "DefaultQty",
                                "QtyPack"
                            ].indexOf(keys) > -1
                        ) {
                            if (!Data.Drug[keys]) {
                                Data.Drug[keys] = "0.000";
                            } else {
                                Data.Drug[keys] = parseFloat(Data.Drug[keys])
                                    .toFixed(3)
                                    .toString();
                            }
                        } else if (
                            ["AWPPack", "CostPack"].indexOf(keys) > -1
                        ) {
                            if (!Data.Drug[keys]) {
                                Data.Drug[keys] = "0.00";
                            } else {
                                Data.Drug[keys] = parseFloat(Data.Drug[keys])
                                    .toFixed(2)
                                    .toString();
                            }
                        } else if (["LastUpdateDt"].indexOf(keys) > -1) {
                            if (Data.Drug.LastUpdateDt) {
                                Data.Drug.LastUpdateDt = moment(
                                    Data.Drug.LastUpdateDt
                                ).format("MM-DD-YYYY");
                            } else {
                                Data.Drug.LastUpdateDt = null;
                            }
                        }
                    }
                }
                this.drugFG.controls[key].patchValue(Data[key]);
            }
        }
    }

    clearSearchData() {
        this.drugSearchFG.controls["DrugName"].patchValue(null);
        this.drugSearchFG.controls["NDCCode"].patchValue(null);
    }
    /* NDCOCC Code Start */
    openPopupModal(content) {
        this.activeModal = content;
    }
    /* NDCOCC Code Start */
}
