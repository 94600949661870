import { SystemData } from "./system-data.model";

export class SystemSetting {
    DrugMonogram: DrugMonogram[];
    LabelMonogramSettings: LabelMonogram[];
    ControlSettings: any;
    ControlFileSettings: SystemStData[];
    DrugSettings: SystemStData[];
    LabelSettings: SystemStData[];
    ERxSettings: SystemStData[];
    DmsSettings: SystemStData[];
    DrugPriceUpdateSettings: SystemStData[];
    MessageSettings: null;
    PatientSettings: SystemStData[];
    PharmacySettings: SystemStData[];
    WorkFlowSettings: SystemStData[];
    RxSettings: SystemStData[];
    TenantSettings: SystemStData[];
    TransmissionSettings: SystemData[];
    FaxSettings: SystemStData[];
    InsuCarrier: InsuDropDown[];
    LabelPrntInfo: LabelPrntDropDown[];
    ResidenceCat: StngDropDown[];
    RxGen: StngDropDown[];
    ePrescVer: any;
    AccountSettingCount: string = null;
    PharmServiceCat: StngDropDown[];
    LblPrinter: any[];
    StateId: number = null;
    CityId: number = null;
    SubClarification: any[];
    InventorySettings: SystemStData[];
    ThreeFourtyBSettings: SystemStData[];
    OldStateName:any = null;
}

export class SystemStData {
    DefaultValues: string = null;
    DescShort: string = null;
    DisplayName: string = null;
    Key: string = null;
    Value: any = null;
    OldStateName:any = null;
}

export class StngDropDown {
    Code: string = null;
    CreatedDtTm: Date = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    Name: string = null;
    Remarks: string = null;
}

export class LabelPrntDropDown {
    Id: number = null;
    LblTemplateId: number = null;
    Name: string = null;
    Printer: string = null;
    Remarks: string = null;
}

export class InsuDropDown {
    InsurerCode: string = null;
    Name: string = null;
}

export class DrugMonogram {
    Code: string = null;
    Id: number = null;
    IsActive: boolean = null;
    Label: string = null;
    TenantId: number = null;
    Type: string = null;
}

export class LabelMonogram {
    IsLongMono: boolean = null;
    Label: string = null;
    MonogramCode: string = null;
    MonogramId: number = null;
}

export class RxNumGen {
    Id: number = null;
    PharmacyId: number = null;
    RxNoRngRegFrm: number = null;
    RxNoRngRegTo: number = null;
    RxNoRngNarFrm: number = null;
    RxNoRngNarTo: number = null;
    RxNoRngConFrm: number = null;
    RxNoRngConTo: number = null;
    RxGenSysId: number = null;
    ModifiedById: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class TenantInfo {
    Id: number = null;
    PharmGrpId: number = null;
    NABPNum: any = null;
    RegistrationNum: any = null;
    DEARegistration: any = null;
    StateSpecificCode: any = null;
    NCPDPId: any = null;
    NPINum: any = null;
    PharmServiceCatId: any = null;
    EmailAddress: any = null;
    PharmMailingAddId: any = null;
    ModifiedDtTm: Date = null;
    CreatedDtTm: Date = null;
    ModifiedById: any = null;
}

export class TenatRxGen {
    TenantInfo: TenantInfo;
    RxNumGen: RxNumGen;
}

export class ControlSetting {
    Bin: string = null;
    ControlFileExt: number = null;
    ControlRefillOption: null
    CreatedDtTm: Date = null;
    DefPatIdProofId: number = null;
    FieldSeperator: string = null;
    FileName: string = null;
    FileType: string = null;
    GenrateCtrlfileBy: number = null;
    ISOMsg: string = null;
    Id: number = null;
    IdToUse: string = null;
    Identifier: string = null;
    InfoRecId: string = null;
    InfoRecName: string = null;
    InfoSourceId: string = null;
    InfoSourceName: string = null;
    IsAllowElecRefReq: boolean = null;
    IsHardStopForMissvalue: boolean = null;
    IsInclAIRSeg: boolean = null;
    IsInclRPHSeg: boolean = null;
    IsPromptForResubmission: boolean = null;
    IsSubmitRefills: boolean = null;
    IsUseCurrDate: boolean = null;
    IsUseCurrTime: boolean = null;
    ModifiedById: number = null;
    ModifiedDtTm: Date = null;
    PharmacyId: number = null;
    SegmentTerm: string = null;
    SendPatId: number = null;
    StateId: number = null;
    TenantId: number = null;
    TransCode: number = null;
    Version: number = null;
    VersionNumber: number = null;
    WebsiteLink: string = null;
    Protocal: string = null;
    UploadFileToFTP: string = null;
    AddressFTP: string = null;
    UserNameFTP: string = null;
    PasswordFTP: string = null;
    FTPUploadNotf: string = null;
    DrugClasses: string = null;
    IncludeNonCtrlRx: string = null;
    ResubmtDeleteNonCtrlRx: string = null;
    PDMPIOC: boolean = null;
    Class2: boolean = null;
    Class3: boolean = null;
    Class4: boolean = null;
    Class5: boolean = null;
    Class6: boolean = null;
    CntrlSubMMS :string = null;
    ShowCount : string = null;
    DefaultTimeCounter : string = null;
    CntrlSourcePath : string = null;
    CntrlDesntPath : string = null;
    WebSVCUrl : string = null;
    WebUsername : string = null;
    WebPwd : string = null;
    ErrorPrmtAtLogin : string = null;
    TreatmentTypeId: number = null;
    IsTreatmentTypeRequired: string = null;
    copyStateFrom:any = null;
    IsActive:any=null;
    ERxSerialNo:any =null;
}

export class RefillOption {
    ControlSettingId: number = null;
    DrugClass: string = null;
    Id: number = null;
    MaxRefillAllow: number = null;
    OrderFillDays: number = null;
    RefillMaxDays: number = null;
    TenantId: number = null;
}

export class PharmacyDetails {
    AddressId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    CityId: number = null;
    CityName: string = null;
    ContPerson: string = null;
    DEARegistration: string = null;
    EmailAddress: string = null;
    NABPNum: number = null;
    NCPDPId: string = null;
    NPINum: string = null;
    PharmServiceCatId: number = null;
    PharmacyName: string = null;
    PhoneCatId: number = null;
    PhoneId: number = null;
    PrimePhoneId: number = null;
    RegistrationNum: string = null;
    StateId: number = null;
    StateName: string = null;
    StateSpecificCode: string = null;
    Telephone: string = null;
    ZipCode: string = null;
}
