export class PatientHistory {
  PrescNum: string = null;
  Auth: Boolean = null;
  Rf: Boolean = null;
  Insurance: string = null;
  RxStatus: string = null;
  DrugName: string = null;
  QtyDisp: number = null;
  FillDate: Date = null;
  Copay: number = null;
  Price: number = null;
  PrescriberName: string = null;
  BillType: string = null;
  NDC: string = null;
  Dvrf: string = null;
  PickedUp: string = null;
  DeliveryStatus: string = null;
  PrescriberID: number = null;
  NextFillDate: Date = null;
  PatientName: string = null;
  PatientId: number = null;
  OrderDays: number = null;
  SupplyDays: number = null;
  DrugClass: string = null;
  DrugId: number = null;
  RxStatusId: string = null;
  PrescStatus: string = null;
  TotalCount: number = null;
}

export class Vendors {
  VendorId: number = null;
  CityId: number = null;
  Name: string = null;
  VendorCode: string = null;
  City: string = null;
  WebUrl: string = null;
  State: string = null;
  Zip: string = null;
  Phone: string = null;
  Fax: string = null;
  ContactPerson: string = null;
  Status: boolean = null;
  TimeToOrder: string = null;
  IsAutoClose: boolean = null;
  LastorderDate: string = null;
  LastReceiveDate: string = null;
  IsSupportEDI: boolean = null;
  IsSendVendorCostPrice: boolean = null;
  ISUSeFor832: boolean = null;
  EdiVendorCode: string = null;
  IsTotalQtyFor810: boolean = null;
  PriceQualifier: string = null;
  IsUpdatePrice: boolean = null;
  CostQualifier: string = null;
  IsUpdateCost: boolean = null;
  Comments: string = null;
  Address: string = null;
  Email: string = null;
  DefaultBucket: string = null;
}

export class VendorParams {
  // VendorEDIRemarks: string = null;
  // StateId: number = null;
  // CityId: number = null;
  // ZipCode1: string = null;
  // ZipCode2: string = null;
  // PhoneNo: string= null;
  VendorCode: string = null;
  VendorId: string = null;
  Name: string = null;
}

export class RxHistoryParams {
  patientId: number = null;
  Billstatus: string = null;
  StartDate: string = null;
  EndDate: string = null;
  PageNumber: number = null;
  PageSize: number = null;
  OrderByColumn: string = null;
  IsShowExpRefills: boolean = null;
  drug: null;
  GroupView: boolean;
  showCurrentRx: boolean;
  AllowRefillingTransferRx: string = null;
  fromRph:boolean ;
}

export class GridParameters {
  SearchText: string = null;
  SearchBy: string = null;
  OrderByColumn: string = null;
  SortDirection: string = null;
  PageNumber: number = null;
  PageSize: number = null;
}
