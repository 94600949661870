



<ng-template #DRUGCONSELLING let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Drug Counselling</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeDrugCounselPopUp()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="eprx--block__content erx--body editpatient">
            <div class="col-12 drug--counsell-header" [formGroup]="drugCounselFG">
                <div class="row">
                    <div class="col-4">
                        <label > Drug:  </label> {{ drugCounsellingData?.DrugName}}
                    </div>
                    <div class="col-4">
                        <label> Drug Counselling Format :  </label>
                        <eprx-radio [ControlName]="'format'" [FormGroupName]="drugCounselFG"
                            [InputErrors]="format?.errors" [IsRequired]="true" [List]="['Long', 'Short']" [ValueList]="[true, false]"
                            [IDForList]="['Long', 'Short']" [LabelForId]="" (TriggerSelectValue)="getDrugCounsellingData()">
                        </eprx-radio>
                    </div>
                    <div class="col-4">
                        <label> Language :  </label>
                        <eprx-select [PlaceHolder]="'Name'" [ControlName]="'language'"
                            [FormGroupName]="drugCounselFG" [InputErrors]="language?.errors" [List]="systemData?.Lang"
                            [BindLabel]="'Name'" [BindValue]="'LangCode'" [IsRequired]="true" [LabelForId]="'Name'"
                            [ErrorDefs]="{required: 'Required'}" (TriggerSelectValue)="getDrugCounsellingData()">
                        </eprx-select>
                    </div>
                </div>
            </div>
            <div class="col-md-12 drug--counsell" *ngIf="drugCounsellingData?.DrugDescription">
                <div class="row">
                    <div class="col-md-8 questions">
                        <div class="row">
                            <div class="col-md-12" *ngFor="let coouncial of drugCounsellingData?.DrugCounsellingDescription">
                                <div class="counsell--header">
                                    <label class="padding_bottom_10px">{{coouncial?.HEADER}}</label>
                                </div>
                                <div class="counsell--body">
                                    <p>
                                        {{coouncial?.DESCRIPTION}}
                                    </p>
                                </div>
                                <div class="counsell--body">
                                    <p>
                                        {{coouncial?.FOOTER}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="drug--discription">
                            <div class="description">
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Shape</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.shape}}</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Color Front</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.color_frnt}}</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Color Back</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.color_back}}</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Side 1</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.id_front}}</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Side 2</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.id_back}}</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Flavor</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.flavorname}}</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Scoring</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.scoringname}}</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="drug-desc--hdr">
                                        <label>Coating</label>
                                        <span class="drug-desc--txt"> {{drugCounsellingData?.DrugDescription?.coatingname}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="drugId">
                            <div class="col-sm-12 padding-0">
                                <img src="{{imgURL}}" class="drug-img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center" *ngIf="!drugCounsellingData?.DrugDescription">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <h3> Drug Counselling not available for this Drug</h3>
            </div>
        </div>
        <div class="modal-footer eprx--block__content erx--body">
            <!-- <button  autofocus class="hotkey_primary" (click)="closeDrugCounselPopUp()" appShortcutKey [CtrlKey]="'o'"><span>O</span>OK</button> -->
            <button type="submit" class="hotkey_success" (click)="previewDrugCounLog(true)"
            appShortcutKey [AltKey]="'p'" id="Print">
            <span>P</span> Print
        </button>
        <button  type="submit" class="hotkey_success"  (click)="previewDrugCounLog(false)"
            appShortcutKey [AltKey]="'v'" id="Preview">
            <span>V</span> Preview
        </button>
        <button type="button" class="hotkey_success"
            (click)="closeDrugCounselPopUp()" appShortcutKey [AltKey]="'c'"
            id="Close">
            <span>C</span> Close
        </button>
        </div>
    </div>
</ng-template>
<ng-template #drugcousellingprint let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef.close(); modalRef=null">
        <h4 header>Drug Counselling Report</h4>
        <span body>
            <div class="col-md-12" >
                <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="800" type="application/pdf"></iframe>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="printDrugCounExp()" appShortcutKey  [AltKey]="'p'" id="Print"><span>P</span> Print </button>
        <button footer ngbAutofocus class="hotkey_success" (click)="modalRef.close() ; modalRef=null" appShortcutKey [AltKey]="'c'" id="Close"> <span>C</span> Close </button>
    </app-custom-modal>
</ng-template>