import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import {DrugData, Drug, DrugDesc, DrugMiscPrice, DrugPO, InvenHist, PriceHis } from "../models";
import { EditDrugService, CommonService, DrugService } from "../services";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { monthNames } from "../modules/drug/drug.constants";
import { CollectionView } from "@grapecity/wijmo";
import { CommonUtil } from "./common.util";
import { forkJoin } from "rxjs";
import { DrugStore } from "../store";
@Injectable({
    providedIn: "root"
})

export class DrugUtils {
    constructor(private _fb: FormBuilder, private _editDrugSvc: EditDrugService, private _drgSvc: DrugService,
        private _comSvc: CommonService, private _comnUtil: CommonUtil, private _drugStore: DrugStore) {

    }

    async saveDrugInfo(services) {
        return await forkJoin(services).toPromise();
    }

    createDrugDataModal() {
        return this._fb.group({
            Drug: this._fb.group(new Drug()),
            DrugDesc: this._fb.group(new DrugDesc()),
            DrugMiscPrice: this._fb.array([new DrugMiscPrice()]),
            DrugPO: this._fb.group(new DrugPO()),
            ImageName: null,
            QtyInHand: null,
            QuantityInHandEqui: null,
            LatestVendorName: null,
            PriceHistory: this._fb.group(new PriceHis()),
            imgURL: null,
            isFromMMS : false
        });
    }

    async getDrugData(Id) {
        return await this._editDrugSvc.getDrugInfo(Id).toPromise();
    }

    async getCompoundNDC() {
        return await this._editDrugSvc.getDrugCompoundNDC().toPromise();
    }

    async getDropDownValues() {
        return await this._editDrugSvc.getDrugDefaultval().toPromise();
    }

    async getDrugDefaultvalbyPost(data) {
        return await this._editDrugSvc.getDrugDefaultvalbyPost(data).toPromise();
    }
    async checkImageExist(drugImageFileSplit) {
        return await this._comSvc.isFileExist(drugImageFileSplit[0], drugImageFileSplit[1]).toPromise();
    }

    patchNewDrugInfo(formGroupName) {
        formGroupName.controls["Drug"].patchValue({
            DrugTypeId: 1,
            NDCQualiId: 4,
            UnitDoseIndId: 1,
            IsActive: true,
            IsPreferred: true,
            IsIncInRefilRem: false,
            IsFDAApprovedNULL: 1,
            IsExclFrmUpdate: false,
            IsPriceUpdAuto: true,
            IsBranded: true,
            DrugClass: 0,
            Instructions: " ",
            Therapeutic: 0,
            TherapeuticCode: 0,
            // IsTriplicate: false,
        });
    }

    updatePriceHistory(formGroupName) {
        formGroupName.controls["PriceHistory"].patchValue({
            NewPackAWPPrice: formGroupName.value["Drug"]["AWPPack"],
            NewCostPack: formGroupName.value["Drug"]["CostPack"],
            NewUnitAWPPrice : formGroupName.value["Drug"]["UnitPriceAWP"],
            NewUnitCost: formGroupName.value["Drug"]["UnitPriceCost"],
            NewUnitDIR: formGroupName.value["Drug"]["DirectUnitPrice"]
        });
    }

    patchDrugInfo(drugInfo, formGroupName, drugId?: any, type?: any) {
        if (type === "New") {
            formGroupName.controls["PriceHistory"].patchValue({
                OldPackAWPPrice: (drugInfo.Drug && drugInfo.Drug.AWPPack) ? drugInfo.Drug.AWPPack : 0,
                OldCostPack: (drugInfo.Drug && drugInfo.Drug.CostPack) ? drugInfo.Drug.CostPack : 0,
                OldUnitAWPPrice: (drugInfo.Drug && drugInfo.Drug.UnitPriceAWP) ? drugInfo.Drug.UnitPriceAWP : 0,
                OldUnitCost: (drugInfo.Drug && drugInfo.Drug.UnitPriceCost) ? drugInfo.Drug.UnitPriceCost : 0,
                OldUnitDIR: (drugInfo.Drug && drugInfo.Drug.DirectUnitPrice) ? drugInfo.Drug.DirectUnitPrice : 0
            });
        }
        const subControl = {3: ["DirectUnitPrice", "DefaultQty", "QtyPack", "DrugMiscPrice"] ,
        2: ["AWPPack", "CostPack", "Surcharge", "PerOfBrand", "AlternateCost"],
            4: ["UnitPriceCost", "UnitPriceAWP"], 1: ["Strength", "LastUpdateDt", "DiscontDt"]};
        Object.keys(subControl).map(value => {
            subControl[value].map(cntrl => {
                if (!(cntrl === "Strength" || cntrl === "LastUpdateDt" || cntrl === "DrugMiscPrice" || cntrl === "DiscontDt") && drugInfo && drugInfo["Drug"]) {
                    const val = parseFloat(drugInfo["Drug"][cntrl] ? drugInfo["Drug"][cntrl] : 0);
                    drugInfo["Drug"][cntrl] = val.toFixed(Number(value));
                } else if (cntrl === "Strength" && drugInfo && drugInfo["Drug"]) {
                    drugInfo["Drug"][cntrl] = drugInfo["Drug"][cntrl] ? drugInfo["Drug"][cntrl].trim() : null;
                } else if ((cntrl === "LastUpdateDt" || cntrl === "DiscontDt") && drugInfo && drugInfo["Drug"]) {
                    drugInfo["Drug"][cntrl] = drugInfo["Drug"][cntrl] ? moment.utc(drugInfo["Drug"][cntrl]).local().format("MM/DD/YYYY") : null;
                } else if (cntrl === "DrugMiscPrice" && drugInfo && drugInfo[cntrl]) {
                    drugInfo[cntrl].map(miscPrice => {
                        const val = miscPrice["Price"] ? parseFloat(miscPrice["Price"]) : 0;
                        miscPrice["Price"] = val.toFixed(3);
                    });
                }
            });
        });
        for (const key in drugInfo) {
            if (drugInfo[key] && !(key === "PriceHistory" || key === "DrugMiscPrice") && formGroupName.controls[key]) {
                formGroupName.controls[key].patchValue(drugInfo[key]);
            } else if (key === "DrugMiscPrice") {
                this.generateFA(key, drugInfo[key], formGroupName, drugId);
            } else if (key === "DrugPO" && !drugInfo[key]) {
                const drugPOData = new DrugPO();
                drugPOData["IsAutoPO"] = true;
                const cntrWithZeroValue = ["ReorderQty", "MinOrderQty", "OrderQty", "TargetQty"];
                cntrWithZeroValue.map(val => drugPOData[val] = 0);
                formGroupName.controls[key].patchValue(drugPOData);
            }
        }
        formGroupName.controls["Drug"].patchValue(
            { UnitPriceAWP3Digits: (drugInfo && drugInfo.Drug && drugInfo.Drug.UnitPriceAWP) ? (parseFloat(drugInfo.Drug.UnitPriceAWP)).toFixed(3) : "0.000",
            UnitPriceCost3Digits: (drugInfo  && drugInfo.Drug  && drugInfo.Drug.UnitPriceCost )? (parseFloat(drugInfo.Drug.UnitPriceCost)).toFixed(4) : "0.0000" });
        if (type === "Add") {
            this.patchNewDrugInfo(formGroupName);
        }
        this.getImageName(drugInfo, formGroupName);

    }

    patchDrugInfoForCopy(drugInfo, formGroupName, drugId?: any, type?: any) {
        if (type === "New") {
            formGroupName.controls["PriceHistory"].patchValue({
                OldPackAWPPrice: 0,
                OldCostPack: 0,
                OldUnitDIR: 0
            });
        }
        const subControl = {
            3: ["DirectUnitPrice", "DefaultQty", "QtyPack", "DrugMiscPrice"],
            2: ["AWPPack", "CostPack", "Surcharge", "PerOfBrand", "AlternateCost"],
            4: ["UnitPriceCost", "UnitPriceAWP"],
            1: ["Strength", "QuickCode", "LastUpdateDt", "DiscontDt", "Remarks", "NDCQualiId"]
        };
        Object.keys(subControl).map(value => {
            subControl[value].map(cntrl => {
                if (!(cntrl === "Strength" || cntrl === "LastUpdateDt" || cntrl === "DrugMiscPrice" || cntrl === "QuickCode" ||
                    cntrl === "DiscontDt" || cntrl === "Remarks" || cntrl === "NDCQualiId" ) && drugInfo && drugInfo["Drug"]) {
                    let val = 0;
                    if (cntrl === "QtyPack") {
                        val = drugInfo["Drug"][cntrl] ? drugInfo["Drug"][cntrl] : 0;
                    }
                    drugInfo["Drug"][cntrl] = val.toFixed(Number(value));
                } else if ((cntrl === "Strength" || cntrl === "Remarks") && drugInfo && drugInfo["Drug"]) {
                    drugInfo["Drug"][cntrl] = drugInfo["Drug"][cntrl] ? drugInfo["Drug"][cntrl].trim() :
                        formGroupName.controls["Drug"][cntrl] && formGroupName.controls["Drug"][cntrl].value ? formGroupName.controls["Drug"][cntrl].value.trim() : null;
                } else if ((cntrl === "LastUpdateDt" || cntrl === "DiscontDt" || cntrl === "QuickCode" || cntrl === "NDCQualiId" ) &&
                                drugInfo && drugInfo["Drug"]) {
                    if (cntrl === "LastUpdateDt" || cntrl === "QuickCode" || cntrl === "NDCQualiId" ) {
                        drugInfo["Drug"][cntrl] = null;
                    } else if (cntrl === "DiscontDt") {
                        drugInfo["Drug"][cntrl] = drugInfo["Drug"][cntrl] ? moment(drugInfo["Drug"][cntrl]).format("MM/DD/YYYY") :
                            formGroupName.controls["Drug"][cntrl] && formGroupName.controls["Drug"][cntrl].value ?
                        moment(formGroupName.controls["Drug"][cntrl].value).format("MM/DD/YYYY") : null;
                    }
                } else if (cntrl === "DrugMiscPrice" && drugInfo[cntrl]) {
                    drugInfo[cntrl].map(miscPrice => {
                        const val = 0;
                        miscPrice["Price"] = val.toFixed(3);
                    });
                }
            });
        });
        for (const key in drugInfo) {
            if (drugInfo[key] && !(key === "PriceHistory" || key === "DrugMiscPrice" || key === "QtyInHand" ||
             key === "QuantityInHandEqui") && formGroupName.controls[key]) {
                formGroupName.controls[key].patchValue(drugInfo[key]);
            } else if (key === "DrugMiscPrice") {
                this.generateFA(key, drugInfo[key], formGroupName, drugId);
            } else if (key === "QtyInHand" || key === "QuantityInHandEqui") {
                formGroupName.controls[key].patchValue(formGroupName.controls[key] && formGroupName.controls[key].value ?
                    formGroupName.controls[key].value : 0 );
            } else if (key === "DrugPO" && !drugInfo[key]) {
                const drugPOData = new DrugPO();
                drugPOData["IsAutoPO"] = formGroupName.controls[key] && formGroupName.controls[key]["IsAutoPO"] &&
                formGroupName.controls[key]["IsAutoPO"].value === false ? false : true;
                const cntrWithZeroValue = ["ReorderQty", "MinOrderQty", "OrderQty", "TargetQty"];
                cntrWithZeroValue.map(val => drugPOData[val] = formGroupName.controls[key] && formGroupName.controls[key][val] ?
                    formGroupName.controls[key][val].value : 0);
                formGroupName.controls[key].patchValue(drugPOData);
            }
        }
        formGroupName.controls["Drug"].patchValue(
            {
                UnitPriceAWP3Digits: "0.000",
                UnitPriceCost3Digits: "0.0000"
            });
        this.getImageName(drugInfo, formGroupName);
    }

    generateFA(key: string, values: any[], formGroupName, drugId) {
        if (values) {
            this._comnUtil.generateFA(key, values, formGroupName);
        } else {
            const defaultMiscVal = [1, 2, 3];
            const miscPrice = new DrugMiscPrice();
            const extraCostFGs = defaultMiscVal.map((val) => {
                miscPrice["DrugMiscPriceCatId"] = val;
                miscPrice["IsActive"] = true;
                miscPrice["DrugId"] = drugId ? drugId : null;
                miscPrice["Price"] = "0.000";
               return this._fb.group(miscPrice);
            });
            const extraCostFormArray = this._fb.array(extraCostFGs);
            formGroupName.setControl(key, extraCostFormArray);
        }
    }

    setNumberFormat(ControlName, number, formGroupName) {
        const controlName = "Drug." + ControlName;
        if (formGroupName.get(controlName).dirty) {
            formGroupName.controls["Drug"].patchValue({
                ControlName: (parseFloat(formGroupName.get(controlName).value)).toFixed(number)
            });
        }
    }

    changeValidation(isReq, formGroupName) {
        const dataInfo: any = formGroupName.controls["Drug"];
        const contrlName = ["DrugUnitId", "Strength"];
        if (isReq) {
            contrlName.map(val => {
                if (val) {
                    if (!dataInfo.value[val] || (val === "Strength" && !dataInfo.value[val].trim())) {
                        dataInfo.controls[val].setErrors({ "required": true });
                    }
                }
            });
        } else {
            contrlName.map(val => {
                if (val) {
                    dataInfo.controls[val].setValidators([
                        Validators.nullValidator
                    ]);
                    dataInfo.controls[val].setErrors(null);
                    dataInfo.controls[val].markAsTouched();
                }
            });
        }
    }

    async getImageName(drugInfo, FG, frmEDI810?: boolean, ImageName?: any, forIOU?: boolean) {
        const drugImage = environment.DrugImage;
        let imgURL: any;
        if ((drugInfo && drugInfo.ImageName) || ImageName) {
            const drugImageFileSplit = drugInfo ? drugInfo.ImageName.split(".") : ImageName.split(".") ;
            const isExist = await this.checkImageExist(drugImageFileSplit);
            if (isExist) {
                imgURL = drugImage.replace("{imagefile}", "" + drugImageFileSplit[0] + "." + drugImageFileSplit[1]);
            } else {
                imgURL = drugImage.replace("{imagefile}", "empty.jpg");
            }
        } else {
            imgURL = drugImage.replace("{imagefile}", "empty.jpg");
        }
        if (!frmEDI810 && !forIOU) {
            FG.controls["imgURL"].patchValue(imgURL);
        } else {
            return imgURL;
        }
    }

    convertByMouseDown(changes: any, formGroupName) {
        const dataInfo: any = formGroupName.controls["Drug"];
        if (changes === "UnitPriceAWP" && dataInfo.controls["UnitPriceAWP3Digits"].dirty) {
            if ((Number(dataInfo.controls["UnitPriceAWP3Digits"].value)).toFixed(3) !==
            (Number(dataInfo.controls["UnitPriceAWP"].value)).toFixed(3)) {
                dataInfo.controls["UnitPriceAWP"].patchValue(
                    (Number(dataInfo.controls["UnitPriceAWP3Digits"].value)).toFixed(4)
                );
            }
            dataInfo.controls["UnitPriceAWP3Digits"].patchValue(
                (Number(dataInfo.controls["UnitPriceAWP3Digits"].value)).toFixed(3)
            );
            dataInfo.controls["AWPPack"].patchValue(
                (dataInfo.controls["UnitPriceAWP"].value * dataInfo.controls["QtyPack"].value).toFixed(2));
        } else if (changes === "UnitPriceCost" && dataInfo.controls["UnitPriceCost3Digits"].dirty) {
            if ((Number(dataInfo.controls["UnitPriceCost3Digits"].value)).toFixed(4) !==
            (Number(dataInfo.controls["UnitPriceCost"].value)).toFixed(4)) {
                dataInfo.controls["UnitPriceCost"].patchValue(
                    (Number(dataInfo.controls["UnitPriceCost3Digits"].value)).toFixed(4)
                );
            }
            dataInfo.controls["UnitPriceCost3Digits"].patchValue(
                (Number(dataInfo.controls["UnitPriceCost3Digits"].value)).toFixed(4)
            );
            dataInfo.controls["CostPack"].patchValue(
                (dataInfo.controls["UnitPriceCost"].value * dataInfo.controls["QtyPack"].value).toFixed(2));
        } else if (changes === "AWPPack" && dataInfo.controls["AWPPack"].dirty) {
            dataInfo.controls["AWPPack"].patchValue(
                (Number(dataInfo.controls["AWPPack"].value)).toFixed(2)
            );
            if (
                ((
                    dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceAWP"].patchValue(
                    (dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(4));
                    dataInfo.controls["UnitPriceAWP3Digits"].patchValue(
                        (dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value).toFixed(3));
            }
        }  else if (changes === "DirectUnitPrice" && dataInfo.controls["DirectUnitPrice"].dirty) {
            dataInfo.controls["DirectUnitPrice"].patchValue(
                (Number(dataInfo.controls["DirectUnitPrice"].value)).toFixed(3)
            );
        }  else if (changes === "DefaultQty" && dataInfo.controls["DefaultQty"].dirty) {
            dataInfo.controls["DefaultQty"].patchValue(
                (Number(dataInfo.controls["DefaultQty"].value)).toFixed(3)
            );
        } else if (changes === "PerOfBrand" && dataInfo.controls["PerOfBrand"].dirty) {
            dataInfo.controls["PerOfBrand"].patchValue(
                (Number(dataInfo.controls["PerOfBrand"].value)).toFixed(2)
            );
        } else if (changes === "Surcharge" && dataInfo.controls["Surcharge"].dirty) {
            dataInfo.controls["Surcharge"].patchValue(
                (Number(dataInfo.controls["Surcharge"].value)).toFixed(2)
            );
        }
        else if (changes === "CostPack" && dataInfo.controls["CostPack"].dirty) {
            dataInfo.controls["CostPack"].patchValue(
                (Number(dataInfo.controls["CostPack"].value)).toFixed(2)
            );
            if (
                ((
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceCost"].patchValue(
                    (dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(4));
                    dataInfo.controls["UnitPriceCost3Digits"].patchValue(
                        (dataInfo.controls["CostPack"].value /
                        dataInfo.controls["QtyPack"].value).toFixed(4));
            } else {
                dataInfo.controls["UnitPriceCost"].patchValue("0.0000");
                dataInfo.controls["UnitPriceCost3Digits"].patchValue("0.0000");
            }
        } else if (changes === "QtyPack" && dataInfo.controls["QtyPack"].dirty) {
            dataInfo.controls["QtyPack"].patchValue(
                (Number(dataInfo.controls["QtyPack"].value)).toFixed(3)
            );
            if (
                ((dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceAWP"].patchValue(
                    (dataInfo.controls["AWPPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(4));
                    dataInfo.controls["UnitPriceAWP3Digits"].patchValue(
                        (dataInfo.controls["AWPPack"].value /
                        dataInfo.controls["QtyPack"].value).toFixed(3));
            }

            if (
                ((
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                ).toFixed(3)).toUpperCase() !== "INFINITY" &&
                !isNaN(
                    dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value
                )
            ) {
                dataInfo.controls["UnitPriceCost"].patchValue(
                    (dataInfo.controls["CostPack"].value /
                    dataInfo.controls["QtyPack"].value).toFixed(4));
                    dataInfo.controls["UnitPriceCost3Digits"].patchValue(
                        (dataInfo.controls["CostPack"].value /
                        dataInfo.controls["QtyPack"].value).toFixed(4));
            } else {
                dataInfo.controls["UnitPriceCost"].patchValue("0.0000");
                dataInfo.controls["UnitPriceCost3Digits"].patchValue("0.0000");
            }
        }
    }

    async defaultSalesData(drugId, showEquivalents = false) {
        const Xaxis: any = [];
        const countries: any = [];
        const data = [];
        let rxCount = 0;
        let quantity = 0;
        let amount = 0;
        let recvQty = 0;
        const today = new Date();
        const year = today.getFullYear() - 1;
        const Cyear = today.getFullYear();
        for (let i = today.getMonth() - 12 + 12 + 2; i <= 12; i++) {
            if (i <= 9) {
                const date = "01/0" + i + "/" + year;
                countries.push(date);
                const monthly =
                monthNames[i - 1].substring(0, 3) +
                    year.toString().substring(2, 4);
                Xaxis.push(monthly);
            } else {
                const date = "01/" + i + "/" + year;
                countries.push(date);
                const monthly =
                monthNames[i - 1].substring(0, 3) +
                    year.toString().substring(2, 4);
                Xaxis.push(monthly);
            }
        }
        for (let i = 1; i <= today.getMonth() + 1; i++) {
            if (i <= 9) {
                const date = "01/0" + i + "/" + Cyear;
                countries.push(date);
                const monthly =
                monthNames[i - 1].substring(0, 3) +
                    Cyear.toString().substring(2, 4);
                Xaxis.push(monthly);
            } else {
                const date = "01/" + i + "/" + Cyear;
                countries.push(date);
                const monthly =
                monthNames[i - 1].substring(0, 3) +
                    Cyear.toString().substring(2, 4);
                Xaxis.push(monthly);
            }
        }
        for (let i = 0; i < countries.length; i++) {
            data.push({
                country: Xaxis[i],
                SalesAmount: 0,
                SalesDispensed: 0,
                SalesReceived: 0
            });
        }
        const salesData = await this.getSalesData(drugId, showEquivalents);
        if (salesData && salesData.length) {
            salesData.map(val => {
                rxCount = rxCount + val["Rxcount"];
                quantity = quantity + val["DispQty"];
                amount = amount + val["SalesAmount"];
                recvQty = recvQty + val["RecvQty"];
                countries.map((country, i) => {
                    if (country === val["Datetime"]) {
                        data[i].SalesAmount = val["SalesAmount"];
                        data[i].SalesDispensed = val["DispQty"];
                        data[i].SalesReceived = val["RecvQty"];
                    }
                });
            });
        }

        return {salesResponse: salesData, graphData: data, RxCount: rxCount, Quantity: quantity, Amount: amount, RecvQty: recvQty};
    }

    async getSalesData(drugId, showEquivalents = false) {
        return await this._editDrugSvc.getSalesSummary(drugId, showEquivalents).toPromise();
    }

    async getNDCOCCData(iscomp, drugCode, pageNu, size) {
        return await this._editDrugSvc.getNDCOCC(iscomp, drugCode, pageNu, size).toPromise();
    }

    async getNdcoccData(iscomp, drugCode, pageNu, size) {
        const response: any = await this.getNDCOCCData(iscomp, drugCode, pageNu, size);
        let totalCount = 0;
        let itemSource = null;
        if (response) {
            itemSource = new CollectionView(
                response.map((NDC) => {
                    totalCount = totalCount + NDC.TotalCount;
                    const j = {};
                    j["NDC"] = NDC.NDCCode;
                    j["Drug Name"] = NDC.DrugName;
                    j["Compound Name"] = NDC.CompoundName;
                    j["Drug Type"] = NDC.DrugType;
                    j["Compound NDC"] = NDC.CompoudNDC;
                    j["Ingredient"] = NDC.Ingredient;
                    j["Billing NDC"] = NDC.BillingNdc;
                    j["Generic NDC"] = NDC.GenericNdc;
                    j["Replacement NDC"] = NDC.ReplacementNdc;
                    return j;
                })
            );
        }
        return {wijimoSource: itemSource, Count: totalCount};
    }

    async getEquivalentDrugs(drugId) {
        return await this._drgSvc.getEquivalentDrug(drugId, null, false).toPromise();
    }

    async patchErxValues(erxDrugValues, formGroupname) {
        this.patchNewDrugInfo(formGroupname);
        formGroupname.controls.Drug.patchValue({
            Name: erxDrugValues.DrugName.substring(0, 35),
            DrugCode: erxDrugValues.DrugNdc,
            DrugClass: erxDrugValues.DrugClass,
            IsActive: true,
            IsDeleted: false
        });
        this._drugStore.storePrescribedCompInfo(erxDrugValues.compoundIngredients);
        if (erxDrugValues.DrugNdc) {
            this.optedNDC(erxDrugValues.DrugNdc, formGroupname);
        }
        formGroupname.controls.Drug.controls["DrugFormId"].patchValue((erxDrugValues.DrugFormId) ? erxDrugValues.DrugFormId : null);
        formGroupname.controls.Drug.controls["DrugUnitId"].patchValue((erxDrugValues.DrugUnitId) ? erxDrugValues.DrugUnitId : null);
        formGroupname.controls.Drug.controls["Strength"].patchValue(erxDrugValues.strength);
        if (erxDrugValues.isCompoundDrug) {
            formGroupname.controls.Drug.controls.DrugTypeId.patchValue(2);
            //need to implement
            // this.selectDrugType({ Id: 2 });
        }
    }

    async optedNDC(drugData: string, formGroupname) {
        const re = /\-/gi;
        drugData = drugData.replace(re, "");
        const respDrugList = await this._comSvc.getDrugSuggest(drugData).toPromise();
        if (respDrugList && respDrugList.result && respDrugList.result.length === 1 && formGroupname) {
            const drug = respDrugList[0];
            if (drug) {
                formGroupname.controls["Drug"].patchValue({
                    QuickCode: drug.quickcode,
                    ManufactId: drug.manufactid,
                    DrugTypeId: drug.drugtypeid,
                    Strength: drug.strength,
                    DrugUnitId: drug.drugunitid,
                    GenericName: drug.genericname
                });
            }
        }
    }   
    getDrugFullName(drugInfo) {
        let drugfullname = "";
        if(drugInfo)
       {
        if(drugInfo?.drugname) 
            drugfullname = drugfullname +  drugInfo.drugname.toUpperCase();
         if(drugInfo.strength)  
            drugfullname = drugfullname + drugInfo.strength.toUpperCase() 
        if(drugInfo.drugformname)
            drugfullname = drugfullname + drugInfo.drugformname.toUpperCase() 
       }
        return drugfullname;
    }
    discontinuedDrug(drugInfo) {
        const days = +this._comSvc.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
     return   drugInfo["discontdt"] &&
         moment().startOf("day").isAfter(moment.utc(drugInfo["discontdt"]).local().add(days, "days"))
    }
}
