<app-custom-modal (oncrossBtnClick)="closeModal()">
    <h4 header>Customize Columns</h4>
    <span body>
        <wj-list-box #columnPicker class="column-picker wj-col-picker-dropdown" (dragstart)="handleDragStart($event)"
            (dragover)="handleDragOver($event)" (drop)="handleDrop($event)" (dragend)="handleDragEnd($event)" (click)="selectDeselectColumnsGridFit($event)">
        </wj-list-box>
    </span>
    <button *ngIf="WijmoKey" footer autofocus class="hotkey_primary" appShortcutKey InputKey="a" (click)="storeWijimoStatus()"><b>A</b> Apply Default
    </button>
    <button footer class="hotkey_primary" appShortcutKey InputKey="c" (click)="closeModal()"><b>C</b> Close
    </button>
</app-custom-modal>

