<div class="cust--login background_white">
    <div class="login-box-body margin_top_10p">
        <div class="login-logo">
            <img src="assets/img/Logo.png">
        </div>
        <div class="text-center">
            <a class="submit padding_1rem" href="#" (click)="navigateToLogin()" >Click here to login</a>
        </div>
    </div>
</div>
