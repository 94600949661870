import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trimStrn"
})
export class TrimStringsPipe implements PipeTransform {

  transform(value: any): any {
    let result = "";
    result = value ? value.trim() : "";
    return result;
  }

}
