<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Patient')"
    appShortcutKey
    [AltKey]="'x'"
>
    <span>X</span> Pat. Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Prescriber')"
    appShortcutKey
    [AltKey]="'y'"
>
    <span>Y</span> Doc Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Drug')"
    appShortcutKey
    [AltKey]="'z'"
>
    <span>Z</span> Drug Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Insurance')"
    appShortcutKey
    [AltKey]="'i'"
>
    <span class = "padding_left_10px"> I </span> Ins. Info
</button>
<app-override-log *ngIf="openOverride" [OverrideEndpoint]="overrideEndpoint" [PrivMask]="privType" [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

