import { Injectable } from "@angular/core";
import { BehaviorSubject ,  Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class InsuranceStore {
    _insuInfo$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    insuInfo$: Observable<any> = this._insuInfo$.asObservable();

    _insuCity$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    insuCity$: Observable<any> = this._insuCity$.asObservable();

    _insuState$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    insuState$: Observable<any> = this._insuState$.asObservable();

    constructor() {}

    storeInsuData(data) {
        this._insuInfo$.next(data);
    }

    storeInsuCity(data) {
        this._insuCity$.next(data);
    }

    storeInsuState(data) {
        this._insuState$.next(data);
    }
}
