import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ChangeDetectorRef,
    HostListener,
    Output,
    EventEmitter
} from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrintService } from "src/app/services/print.service";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { AlertService, CommonService, DrugService, UserService } from "src/app/services";
import { LabelPrintService } from "src/app/services/label-print.service";
import { PrintSet, LabelPageSetup, PaginationModel } from "src/app/models";
import { LabelDesignService } from "src/app/services/label-design.service";
import { fabric } from "fabric";
import { MsgConfig } from "../../../app.messages";
import { AuditLogUtils } from "src/app/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-print-rx-label",
    templateUrl: "./print-rx-label.component.html"
})
export class PrintRxLabelComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    rxInfo: any;
    QuickLabelForm: FormGroup;
    printSetDrops: PrintSet[] = [];
    previewTemplate: any;
    canvas2: any;
    selectedPrintSet: PrintSet;
    formSubmitted = false;
    pageSetup = LabelPageSetup;
    skipTemplSelct: any;
    hasToEmitCompleted = true;
    skipautoPrint: any;
    showDefaultLabel: boolean;
    drugId: any;
    prescNum: any;
    prescId: any;
    routeFrom: any;
    compoundPopUp: NgbModalRef;
    prescReFillId: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set RxInfo(data: any) {
        if (data) {
            this.rxInfo = data;
        }
    }

    @Input()
    set SkipTemplSelct(st: any) {
        if (st) {
            this.skipTemplSelct = st;
            // this.getLabelPrintSetData();
        }
    }

    @Input()
    set SkipAutoPrint(st: any) {
        if (st) {
            this.skipautoPrint = st;
            // this.getLabelPrintSetData();
        }
    }

    @Input()
    set IsPrintLabelEmitCompleted(ic: any) {
        this.hasToEmitCompleted = ic;
    }

    @Input()
    set RouteFrom(ic: any) {
        this.routeFrom = ic;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();


    @ViewChild("QuickLabel", { static: true })
    QuickLabel: any;

    @ViewChild("CompoundList", { static: true })
    CompoundList: any;

    modelRef: NgbModalRef;

    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27) { // yes
            this.closeModal();
            event.preventDefault();
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _printSer: PrintService,
        private _fb: FormBuilder,
        private _alertService: AlertService,
        private _printSetSer: LabelPrintService,
        private __labeDesign: LabelDesignService,
        private _commonSvc: CommonService,
        private _auditUtils: AuditLogUtils,
        private _drugService: DrugService,
        private _userService: UserService
    ) {
        this.QuickLabelForm = this._fb.group({
            Lmd: [],
            LabelQty: ["1"],
            LabPrintSet: []
        });
    }

    ngOnInit() {
        this.canvas2 = new fabric.Canvas("myCanvas2", { selection: false });
        this.getLabelPrintSetData();
    }

    get LabelQty() {
        return this.QuickLabelForm.get("LabelQty") as FormControl;
    }

    get LabPrintSet() {
        return this.QuickLabelForm.get("LabPrintSet") as FormControl;
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        } else if (this.compoundPopUp) {
            this.compoundPopUp.close();
            this.compoundPopUp = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getLabelPrintSetData() {
        let searchData = {
            pageNumber: this.paginationModel.pageNumber,
            pageSize: this.paginationModel.pageSize
        };
        this._printSetSer.getPrintSetData(searchData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                let s: any;
                this._commonSvc.systemSettings$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp1 => {
                     s = parseInt(this._commonSvc.getSetttingValue(
                        "LabelSettings",
                        "DEF_PRNT_LBL_SET"
                    ), 10);
                    // s = parseInt(s, 10);
                });
                if (resp.length > 0) {
                    let defaultSet;
                    if (localStorage.getItem("DefaultLabelPrinter")) {
                        defaultSet = this._commonSvc.DecryptData(localStorage.getItem("DefaultLabelPrinter"));
                    }
                    this.printSetDrops = resp.filter(elm => elm.LblTemplateId === s);
                    this.printSetDrops.map((priSet, i) => {
                        priSet.Name =
                            priSet.Name + " ( " + priSet.Printer + " )";
                        if (defaultSet && priSet.Id === defaultSet.Id) {
                            this.optedPrintSet(priSet);
                            this.QuickLabelForm.controls.LabPrintSet.patchValue(defaultSet.Id);
                        }
                    });
                    if (!this.selectedPrintSet) {
                        this.showDefaultLabel = true;
                        return;
                    }
                    if (this.skipTemplSelct === "false") {
                        this.modelRef = this._modalService.open(this.QuickLabel, {
                            size: "lg",
                            keyboard: false,
                            centered : true
                        });
                    } else {
                        if (!this.skipautoPrint) {
                            this.print();
                        }
                    }
                } else {
                    this._alertService.error("No label printsets to select.");
                    this.closeModal();
                }
            } else {
                this._alertService.error("No label printsets to select.");
                this.closeModal();
            }
        });
    }

    optedPrintSet(ipData: any) {
        this.selectedPrintSet = ipData;
    }

    print() {
        this.printMedguide();
        if (this.selectedPrintSet && this.selectedPrintSet.LblTemplateId) {
            this.formSubmitted = true;
            if (!this.QuickLabelForm.valid) {
                this._alertService.error(MsgConfig.MANDATORY_FIELDS);
                return;
            }
            this.__labeDesign
                .preview(
                    this.rxInfo?.Prescription?.Id,
                    this.rxInfo?.Prescription?.PrescNum,
                    this.selectedPrintSet.LblTemplateId
                )
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._auditUtils.getChangedValues(null, true, "Print Label", "Rx", this.rxInfo.Prescription.PrescNum);
                        this.previewTemplate = resp;
                        this.labelDesinPreview2();
                    } else {
                        this.closeModal();
                    }
                });
            // this.checkForCompoundDrug();
        } else {
            this._alertService.error("No label print set is selected to print.");
        }
    }

    labelDesinPreview2() {
        let stringToJson2 = JSON.parse(this.previewTemplate.DesignParams);
        let selectedSize = this.pageSetup.find(
            element => element.Id === this.previewTemplate.PageSetUp
        );
        this.canvas2.setWidth(selectedSize.Width);
        this.canvas2.setHeight(selectedSize.Height);
        if(stringToJson2 && stringToJson2.backgroundImage && !stringToJson2.backgroundImage.src)
        delete stringToJson2.backgroundImage;
        this.canvas2.loadFromJSON(
            stringToJson2,
            function() {
                this.canvas2.renderAll.bind(this.canvas2);
                // this.previewImage = this.canvas2.toDataURL({ format: "png" });
                this._printSer.printWithConfig(
                    this.canvas2.toDataURL("image/jpeg"),
                    this.selectedPrintSet.Printer,
                    parseInt(this.QuickLabelForm.value.LabelQty, 10),
                    { width: selectedSize.PrintWidth, height: selectedSize.PrintHeight },
                    this._userService.getToken("UniquePharmacyId") === "1912146507" || this._userService.getToken("UniquePharmacyId") === "1336440890" ? true : false
                );
                this.closeModal();
            }.bind(this)
        );
    }

    printMedguide() {
        if (this.routeFrom && this.routeFrom !== "er" &&
            (this._commonSvc.getSetttingValue("LabelSettings", "PRNT_MED_GUIDE") === "1") &&
            (this.rxInfo && this.rxInfo.Drug && this.rxInfo.Drug.id)
        ) {
            this.getMedGuide(this.rxInfo.Drug.id);
        }
    }


    getMedGuide(drugIdToPrint) {
        this._drugService.getDrugMedguide(drugIdToPrint)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.medguideToReportPrinter(resp);
            }
        });
    }

    medguideToReportPrinter(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printSer.printPdf(base64data);
            this.closeModal();
        }.bind(this);
    }

    checkForCompoundDrug() {
        if (this.rxInfo.Drug.drugtypeid == 2) {
            this.drugId = this.rxInfo.Drug.id;
            this.prescNum = this.rxInfo.Prescription.PrescNum;
            this.prescId = this.rxInfo.Prescription.Id;
            this.prescReFillId = this.rxInfo.PrescReFill.Id;
            this.compoundPopUp = this._modalService.open(this.CompoundList, {centered: true,
                keyboard: false, windowClass: "large--content"
            });
        }
    }

    printCompoundFormulation() {
        this.__labeDesign.getCompoundFormPrint(this.drugId, this.prescReFillId)
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.size > 0 && resp.type === "application/pdf") {
                    this.printCompoundForm(resp);
                }
            });
    }

    printCompoundForm(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printSer.printPdf(base64data);
            this.closeModal();
        }.bind(this);
    }
}
