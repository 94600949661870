import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, ErxService } from 'src/app/services';
import { PrintService } from 'src/app/services/print.service';
import * as moment from "moment";
import { CollectionView } from "@grapecity/wijmo";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
  selector: 'app-erx-result',
  templateUrl: './erx-result.component.html',
  styleUrls: ['./erx-result.component.scss']
})
export class ErxResultComponent implements OnInit {
  @ViewChild("ErxResult", { static: true })
  ErxResult: ElementRef;
  @ViewChild("compList", { static: true })
    compList: ElementRef;
    @ViewChild("erxExtraInfo", { static: true })
    erxExtraInfo: ElementRef;
  effectiveDate: any;
  modalRef: any;
  detailsInfo: any;
  unsubscribe$: Subject<void> = new Subject();
  rxOrdDt: any;
  @Input() detailsInfoObject: any;
  @Output()
  IsPopUpClosed = new EventEmitter<any>();
  insuranceWJ: CollectionView;
  insuranceData: any = [];
  activeHeaders: any = [];
  inactiveHeader: any =[];
  constructor( private modalService: NgbModal, private _erxService: ErxService,
    private _commonSvc: CommonService, public _printSer: PrintService, public _formatsUtil: FormatsUtil) { }

  ngOnInit() {
   this.openErxResult();
    if (this.detailsInfoObject && this.detailsInfoObject.ID) {
      this._erxService.getReadyErx(this.detailsInfoObject.ID, this.detailsInfoObject.eRxVersion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp) {
          this.detailsInfo = resp;
          if (this.detailsInfo && this.detailsInfo.RxOrdDt) {
            this.rxOrdDt = moment.utc(this.detailsInfo.RxOrdDt).local().format("MM/DD/YYYY");
          }
          this.GenerateInsuranceDetails([]);
          if (this.detailsInfo && this.detailsInfo.insuranceDetails) {
            this.GenerateInsuranceDetails(this.detailsInfo.insuranceDetails);
          }
          this.detailsInfo.RefillNum = this.detailsInfo && this.detailsInfo?.MsgType === "RxRenewalResponse" && this.detailsInfo?.RefillNum != "0" ?  (+(this.detailsInfo?.RefillNum) - 1).toString() :    this.detailsInfo?.RefillNum;
        }
      });
    }
    this.insuranceData = this._commonSvc.insuranceData$["source"]["value"];
  }
  openErxResult() {
    this.modalRef = this.modalService.open(this.ErxResult, {
      keyboard: false,
      centered: true,
      size: "lg"
  });
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.IsPopUpClosed.emit();
  }
  async printDocument() {
    let ErxOperationId;
    if(this.detailsInfoObject && this.detailsInfoObject['Records']) {
      this.detailsInfoObject['Records'].find(resp=>{
        if((resp.Id || resp.erxId) == this.detailsInfo.ErxId){
            ErxOperationId = resp.ErxOperationId;
        }
    })
    }
    if (this.detailsInfo && this.detailsInfo.ErxId && ErxOperationId) {
    const payload = [{
      eRxId: this.detailsInfo.ErxId,
      eRxOperationId : ErxOperationId
    }]
    const ErxResp = await this._erxService.getErxMultiReport(payload).toPromise();
      if (ErxResp && ErxResp.size > 0 && ErxResp.type === "application/pdf") {
          this.print(ErxResp);
      }
    }
}
ngOnDestroy() {
	this.unsubscribe$.next();
	this.unsubscribe$.complete();
}

openMoreInfo() {
  this.modalService.open(this.erxExtraInfo, { size: "lg", keyboard: false, backdrop: "static" });
}
print(datToPrint) {
  const newBlob = new Blob([datToPrint], { type: "application/pdf" });
  const reader = new FileReader();
  reader.readAsDataURL(newBlob);
  reader.onloadend = function () {
      const base64data = reader.result;
      this._printSer.printPdf(base64data);
  }.bind(this);
}

openCompDrug() {
  this.modalService.open(this.compList, { size: "lg", keyboard: false, backdrop: "static" });
}
GenerateInsuranceDetails(insuranceDetails) {
  this.insuranceWJ = new CollectionView(
      insuranceDetails.map((ins, index) => {
          const k = {};
          k["rowId"] = index;
          k["PAYER IDENTIFICATION"] = ins.PayerId ? ins.PayerId : "";
          k["Payer Identification"] = ins.PayerId ? ins.PayerId : "";
          k["PCN"] = ins.ProcessorIdentificationNumber ? ins.ProcessorIdentificationNumber : "";
          k["IIN/BIN"] = ins.BINLocationNumber ? ins.BINLocationNumber : "";
          k["BINLocationNumber"] = ins.BINLocationNumber ? ins.BINLocationNumber : "";
          k["ProcessorIdentificationNumber"] = ins.ProcessorIdentificationNumber ? ins.ProcessorIdentificationNumber : "";
          k["PAYER NAME"] = ins.PayerName ? ins.PayerName : "";
          k["IsSelected"] = false;
          k["Payer Name"] = ins.PayerName ? ins.PayerName : "";
          k["PAYER TYPE"] = ins.PayerType ? ins.PayerType : "";
          k["Payer Type"] = ins.PayerType ? ins.PayerType : "";
          k["CARD HOLDER ID"] = ins.CardHolderId ? ins.CardHolderId : "";
          k["Card Holder Id"] = ins.CardHolderId ? ins.CardHolderId : "";
          k["CARD HOLDER FIRSTNAME"] = ins.CardHolderFirstName ? ins.CardHolderFirstName : "";
          k["Card Holder First Name"] = ins.CardHolderFirstName ? ins.CardHolderFirstName : "";
          k["CARD HOLDER LASTNAME"] = ins.CardHolderLastName ? ins.CardHolderLastName : "";
          k["Card Holder Last Name"] = ins.CardHolderLastName ? ins.CardHolderLastName : "";
          k["CARD HOLDER SUFFIX"] = ins.CardHolderSuffix ? ins.CardHolderSuffix : "";
          k["Card Holder Suffix"] = ins.CardHolderSuffix ? ins.CardHolderSuffix : "";
          k["CARD HOLDER PREFIX"] = ins.CardHolderPrefix ? ins.CardHolderPrefix : "";
          k["Card Holder Prefix"] = ins.CardHolderPrefix ? ins.CardHolderPrefix : "";
          k["GROUP ID"] = ins.GroupId ? ins.GroupId : "";
          k["PBMMemberID"] = ins.PBMMemberID ? ins.PBMMemberID : "";
          k["Group Id"] = ins.GroupId ? ins.GroupId : "";
          k["GROUP NAME"] = ins.GroupName ? ins.GroupName : "";
          k["Group Name"] = ins.GroupName ? ins.GroupName : "";
          const aItem  = this.insuranceData.filter((item) => {
              return (((item['BinNum'] && ins['BINLocationNumber']) && item['BinNum'] == ins['BINLocationNumber']) &&
               ((item['ProcessorNum'] || ins['ProcessorIdentificationNumber']) && item['ProcessorNum'] == ins['ProcessorIdentificationNumber'])) });
          k["Ins. ID"] = aItem && aItem.length ? aItem[0]['InsuranceName'] : null;
          k["Ins. Code"] =  aItem && aItem.length ? aItem[0]['InsurerCode'] : null;
          k['InsId'] = aItem && aItem.length ? aItem[0]['InsurerCode'] : null;
          k['InsuCarrierId'] = aItem && aItem.length ? aItem[0]['InsuCarrierId'] : null;
          return k;
      })
  );
  this.activeHeaders = [
      "PAYER IDENTIFICATION",
      "PAYER NAME",
      "CARD HOLDER ID",
      "CARD HOLDER FIRSTNAME",
      "CARD HOLDER LASTNAME",
      "CARD HOLDER SUFFIX",
      "CARD HOLDER PREFIX",
      "GROUP ID"
  ];
  this.inactiveHeader = [
      "PCN",
      "IIN/BIN",
      "PAYER TYPE",
      "GROUP NAME"
  ];
}
}
