<ng-template #ncpdpDataPopup let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">NCPDP Payer Set</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');emitEditValue()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover ncpdp_height_changes">
                    <tbody>

                        <div class="row pl-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15">
                                General
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-md-4 ">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-4" *ngIf="editPopUp">
                                        <eprx-input [LabelText]="'Set ID'" [AutoFocus]="true" [PlaceHolder]="''" [ControlName]="'Code'" [FormGroupName]="Ncpdp"  [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-4" *ngIf="addPopUp">
                                        <eprx-input [LabelText]="'Set ID'" [PlaceHolder]="''" [ControlName]="'Code'" [FormGroupName]="Ncpdp"  [IsRequired]="true" 
                                            [InputErrors]="Code?.errors" [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                                        </eprx-input>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--right__7">
                                    <eprx-input [LabelText]="'Set Name'" [PlaceHolder]="''" [ControlName]="'Name'" [FormGroupName]="Ncpdp" [AutoFocus]="editPopUp?true:false" >
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--right__7">
                                    <eprx-select [LabelText]="'Transaction Type'" [ControlName]="'NCPDPCatId'" [FormGroupName]="Ncpdp" [HasMultiple]="false"
                                        [HasBindLabel2]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                        transsetingsDropValues
                                                ? transsetingsDropValues
                                                : ''
                                        " [IsRequired]="true" [InputErrors]="NCPDPCatId?.errors" [ErrorDefs]="{ required: 'Required' }"
                                        [FormGroupInvalid]="formGroupInvalid">
                                    </eprx-select>
                                </div>
                            </div>
                        </div>

                        <div class="row pl-2" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 mt-4">
                                Segment Information
                            </div>
                        </div>

                        <hr />
                        <div class="box-body  height_overflow">
                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">
                                        Patient
                                    </label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsPatientIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['IsPatientIncl1', 'IsPatientIncl2']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'Patient')
                                    ">
                                   </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Patient'" [ControlName]="'PatientStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showPatient"
                                    [AutoFocus]="showPatient && !editModeS && clickType === 'Patient'"    [Title]="'Patient'">
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">
                                        Insurance
                                    </label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsInsuIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp3', 'ncpdp4']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'Insurance')
                                    ">
                                   </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Insurance'" [ControlName]="'InsuStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showIns"
                                    [AutoFocus]="showIns && !editModeS && clickType === 'Insurance'"   [Title]="'Insurance'">
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">
                                        Claims</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsClaimsIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp5', 'ncpdp6']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'Claims')
                                    ">
                                   </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Claims'" [ControlName]="'ClaimsStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showClaim"
                                    [AutoFocus]="showClaim && !editModeS && clickType === 'Claims'"   [Title]="'Claims'">
                                    </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Pharmacy Provider</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsPharmProvIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp7', 'ncpdp8']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'Pharmacy')
                                    ">
                                </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Pharmacy Provider'" [ControlName]="'PharmProvStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showPhar"
                                        [AutoFocus]="showPhar && !editModeS && clickType === 'Pharmacy'"    [Title]="'Pharmacy Provider'">
                                        </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Prescriber</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsPrescIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['ncpdp9', 'ncpdp10']"
                                    [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'Prescriber')
                                    ">
                                </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Prescriber'" [ControlName]="'PrescStr'" [FormGroupName]="NcpdpSeg"  [ReadOnly]="!showPresc"
                                    [AutoFocus]="showPresc && !editModeS && clickType === 'Prescriber'"    [Title]="'Prescriber'">
                                    </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">
                                        COB</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsCOBIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['ncpdp11', 'ncpdp12']"
                                        [LabelForId]="" (TriggerSelectValue)="
                                            getVal($event, 'COB')
                                        ">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'COB'" [ControlName]="'COBStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showCOB"
                                    [AutoFocus]="showCOB && !editModeS && clickType === 'COB'" [Title]="'COB'">
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Worker's Comp</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsWCompIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['ncpdp13', 'ncpdp14']"
                                        [LabelForId]="" (TriggerSelectValue)="
                                            getVal($event, 'worker')
                                        ">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Worker'" [ControlName]="'WCompStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showworker"
                                    [AutoFocus]="showworker && !editModeS && clickType === 'worker'"    [Title]="'Worker Comp'">
                                    </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">DUR</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsDURIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['ncpdp41', 'ncpdp42']"
                                    [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'DUR')
                                    ">
                                 </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'DUR'" [ControlName]="'DURStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showDUR"
                                        [AutoFocus]="showDUR && !editModeS && clickType === 'DUR'" [Title]="'DUR'">
                                        </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Pricing</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsPricingIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp17', 'ncpdp18']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'pricing')
                                    ">
                                </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0 ">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Pricing'" [ControlName]="'PricingStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showpricing"
                                        [AutoFocus]="showpricing && !editModeS && clickType === 'pricing'"   [Title]="'Pricing'">
                                        </eprx-input>
                                </div>
                            </div>


                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Coupon</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsCouponIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                        [IDForList]="['ncpdp19', 'ncpdp20']" [LabelForId]="" (TriggerSelectValue)="
                                            getVal($event, 'Coupon')
                                        ">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Coupon'" [ControlName]="'CouponStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showCoupon"
                                        [AutoFocus]="showCoupon && !editModeS && clickType === 'Coupon'"    [Title]="'Coupon'">
                                        </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Compound</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsCompoundIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                        [IDForList]="['ncpdp21', 'ncpdp22']" [LabelForId]="" (TriggerSelectValue)="
                                            getVal($event, 'Compound')
                                        ">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Compound'" [ControlName]="'CompoundStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showCompound"
                                    [AutoFocus]="showCompound && !editModeS && clickType === 'Compound'"    [Title]="'Compound'">
                                    </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Prior Approval</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsPriorApprIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp23', 'ncpdp24']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'prior')
                                    ">
                                </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Prior Approval'" [ControlName]="'PriorApprStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showPrior"
                                        [AutoFocus]="showPrior && !editModeS && clickType === 'prior'"    [Title]="'Prior Approval'">
                                        </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Clinical</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsClinicalIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp25', 'ncpdp26']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'Clinical')
                                    ">
                                </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Clinical'" [ControlName]="'ClinicalStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showClinical"
                                    [AutoFocus]="showClinical && !editModeS && clickType === 'Clinical'"    [Title]="'Clinical'">
                                    </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Additional Doc</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsAddDocIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp27', 'ncpdp28']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'additional')
                                    ">
                                </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0 ">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Additional Doc'" [ControlName]="'AddDocStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showAdd"
                                    [AutoFocus]="showAdd && !editModeS && clickType === 'additional'"    [Title]="'Additional Doc'">
                                    </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Facility</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsFacilityIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['ncpdp29', 'ncpdp303']" [LabelForId]="" (TriggerSelectValue)="
                                        getVal($event, 'facility')
                                    ">
                                </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Facility'" [ControlName]="'FacilityStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showFacility"
                                    [AutoFocus]="showAdd && !editModeS && clickType === 'facility'"    [Title]="'Facility'">
                                    </eprx-input>
                                </div>
                            </div>

                            <div class="row mr-0">
                                <div class="col-lg-2">
                                    <label class="col-md-12 padding-0">Narrative</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <eprx-radio [ControlName]="'IsNarrativeIncl'" [FormGroupName]="NcpdpSeg" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                        [IDForList]="['ncpdp31', 'ncpdp32']" [LabelForId]="" (TriggerSelectValue)="
                                            getVal($event, 'narrative')
                                        ">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-8 pr-0">
                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Narrative'" [ControlName]="'NarrativeStr'" [FormGroupName]="NcpdpSeg" [ReadOnly]="!showNarr"
                                        [AutoFocus]="showNarr && !editModeS && clickType === 'narrative'"   [Title]="'Narrative'">
                                        </eprx-input>
                                </div>
                            </div>

                        </div>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="copyTransPopUp()" appShortcutKey [AltKey]="'o'">
           <span>O</span> COPY
        </button>
        <button *ngIf="editPopUp" type="button" class="hotkey_success" (click)="updateNcpdpInfo()" appShortcutKey [AltKey]="'u'">
           <span>U</span> UPDATE
        </button>
        <button *ngIf="addPopUp" type="button" class="hotkey_success" (click)="saveNcpdpInfo()" appShortcutKey [AltKey]="'s'">
            <span>S</span>SAVE
        </button>
        <button type="button" class="hotkey_success" (click)="emitEditValue()" (click)="c('Close click')" appShortcutKey [AltKey]="'c'">
           <span>C</span> CANCEL
        </button>
    </div>
</ng-template>

<!-- <app-copyncdp *ngIf="copyTans" (CopyValue)="copy($event)"></app-copyncdp> -->

<!-- <ng-template #copyTransData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Search</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--right__7">
                                <app-rx-select [LabelText]="'NCPDP Payer Set'" [PlaceHolder]="'NCPDP Payer Set'" [ControlName]="'NCDPId'"
                                [FormGroupName]="copyInfoFG" [List]="transsetingsDropValues
                                ? transsetingsDropValues.NCPDP
                                : ''" [BindLabel]="'Code'" [BindValue]="'Id'" [HasBindLabel2]="true"
                                 [LabelForId]="'Name'"  [AutoFocus]="true"  [BindLabel2]="'Name'" [ShowLabel2]="true"
                                >
                            </app-rx-select>
                            <eprx-select #select [RxSelectId]="'select'" [AutoFocus]="true" [LabelText]="'NCPDP Payer Set'" [PlaceHolder]="'NCPDP Payer Set'"
                             [ControlName]="'NCDPId'" [FormGroupName]="copyInfoFG"
                                [HasMultiple]="false" [Title]="'NCPDP Payer Set'" [HasBindLabel2]="true" [BindLabel]="'Code'"
                                [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                    transsetingsDropValues
                                        ? transsetingsDropValues.NCPDP
                                        : ''
                                ">
                            </eprx-select>
                        </div>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="submit" (click)="c('Close click')" (click)="copy()">
            OK
        </button>
        <button type="button" class="secondary" (click)="c('Close click')">
            CANCEL
        </button>
    </div>
</ng-template> -->
