import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";
import { CommonService, RphVerificationService } from "../services";

@Injectable({
    providedIn: "root"
})
export class CommonLogicRxInfoUtil {
    beforeRph: number;
    // orginal: number;
    warnMsg: any;
    response: any;
    reqRxVerfStng: number;

    constructor(private _commonSrv: CommonService, private _rphService: RphVerificationService) { }

    async checkotherVerifications(isRxVerf, rxInfo, frmVerfBtn: boolean, isPatientHistory: boolean) {
        this.warnMsg = null;
        // this.orginal = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        // this.beforeRph = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings",
        //  "Rx_Verification_Required_Before_RPh_Verification"), 0);
        this.reqRxVerfStng = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        // const workFlwPatStng = parseInt(this._commonSrv.getSetttingValue("WorkFlowSettings","Require_Rx_Verification_for_workflow_patient_only"), 0);
         const rxNo = "<label class='font-weight-bold'>" + (rxInfo["Prescription"]["PrescNum"] + "-" + rxInfo["PrescReFill"]["ReFillNum"] +
                        (this._commonSrv.checkIsPartialFill(rxInfo["PrescReFill"], rxInfo["Prescription"]) ? "-" + rxInfo["PrescReFill"]["PartialFillNo"] : " ")) + "</label>";
         if (rxInfo && (rxInfo["PrescReFill"]["StatusId"] === 2 || rxInfo["Prescription"]["RxStatusId"] === 2)) {
            this.warnMsg = "Selected Rx#: " + rxNo + " " + "is Filed, Cannot be verified.";
        } else if (rxInfo && (rxInfo["PrescReFill"]["StatusId"] === 4 || rxInfo["Prescription"]["RxStatusId"] === 4)) {
            this.warnMsg = "Selected Rx#: " +  rxNo + " " + "is Transfered, Cannot be verified.";
        } else if (!isRxVerf && rxInfo && (rxInfo["RxBill"]["StatusId"] === 1) && (this._commonSrv.getSetttingValue("WorkFlowSettings","ALLOW_RPH_VERF_UNB_RX" ) === "0")) {
            this.warnMsg = "Selected Rx#: " +  rxNo + " " + "is Unbilled, Cannot be verified.";
        } else if (this.reqRxVerfStng && isPatientHistory) {
           /* if (workFlwPatStng) {
                if (isRxVerf && rxInfo && rxInfo.Patient && rxInfo.Patient.IsWorkflowPatient && this.orginal &&
                     (rxInfo && !(rxInfo["PrescReFill"]["ReFillNum"] === 0))) {
                    this.warnMsg = "<label>" + rxNo + " " + "</label>" +
                     " Rx verification is required only for Original Rx's and not for Refills.";
                }
                // if (rxInfo && rxInfo.Patient && rxInfo.Patient.IsWorkflowPatient && !isRxVerf && this.orginal && this.beforeRph && !(rxInfo.rph1VerificationStatus &&
                //     rxInfo.rph1VerificationStatus.toLowerCase() === "r") && (rxInfo && (rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
                //         this.warnMsg = "Rx verification is required before R.Ph  verification for selected Rx#:" + rxNo + ".";
                // } else if (rxInfo && rxInfo.Patient && rxInfo.Patient.IsWorkflowPatient && !isRxVerf && !(this.orginal) && this.beforeRph && !(rxInfo.rph1VerificationStatus &&
                //     rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
                //         this.warnMsg = "Rx verification is required before R.Ph verification for selected Rx#:" + rxNo + ".";
                // }
                if (rxInfo && !((rxInfo.rph1VerificationStatus && (rxInfo.rph1VerificationStatus.trim().toUpperCase() === "RV")) ||
                (rxInfo.rph2VerificationStatus && (rxInfo.rph2VerificationStatus.trim().toUpperCase() === "RV")))) {
                    this.response = await this._rphService.IsRphVerifiedAlready(rxInfo.Prescription.Id, rxInfo.PrescReFill.Id,
                         isRxVerf).toPromise();
                 }
                if (this.response && this.response["Status"] && (this.response["Status"].trim() === "V" ||
                 this.response["Status"].trim() === "R") && !frmVerfBtn) {
                    this.warnMsg = this.response;
                } else if (rxInfo && rxInfo.Prescription.IsDiscontinued) {
                    this.warnMsg = "DiscontinuedRx";
                } else {
                    this.warnMsg = null;
                }
            } else if (isRxVerf && this.orginal &&
                (rxInfo && !(rxInfo["PrescReFill"]["ReFillNum"] === 0))) {
                    this.warnMsg = "<label>" + rxNo + " " + "</label>" +
                     " Rx verification is required only for Original Rx's and not for Refills.";
            // } else if (!isRxVerf && this.orginal && this.beforeRph && !(rxInfo.rph1VerificationStatus &&
            //     rxInfo.rph1VerificationStatus.toLowerCase() === "r") && (rxInfo && (rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
            //         this.warnMsg = "Rx verification is required before R.Ph  verification for selected Rx#:" + rxNo + ".";
            // } else if (!isRxVerf && !(this.orginal) && this.beforeRph && !(rxInfo.rph1VerificationStatus &&
            //     rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
            //         this.warnMsg = "Rx verification is required before R.Ph verification for selected Rx#:" + rxNo + ".";
            }*/
        } else if (!isRxVerf && this._commonSrv.getSetttingValue("WorkFlowSettings", "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF") === "1" &&
         (!rxInfo.drugPickVerificationStatus || (rxInfo["drugPickVerificationStatus"] && (rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "rv" ||rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "nv")))) {
                this.warnMsg = "Drug Pick Verification has not been performed on this Rx#: " + rxNo +
                 ". Please perform Drug Pick Verification before Pharmacist Verification.";
        } else {
            // tslint:disable-next-line:max-line-length
            if (rxInfo && !((rxInfo.rph1VerificationStatus && (rxInfo.rph1VerificationStatus.trim().toUpperCase() === "RV")) ||
            (rxInfo.rph2VerificationStatus && (rxInfo.rph2VerificationStatus.trim().toUpperCase() === "RV")))) {
                this.response = await this._rphService.IsRphVerifiedAlready(rxInfo.Prescription.Id, rxInfo.PrescReFill.Id,
                     isRxVerf).toPromise();
             }
            if (this.response && this.response["Status"] && (this.response["Status"].trim() === "V" ||
             this.response["Status"].trim() === "R") && !frmVerfBtn) {
                this.warnMsg = this.response;
            } else if (rxInfo && rxInfo.Prescription.IsDiscontinued) {
                this.warnMsg = "DiscontinuedRx";
            } else {
                this.warnMsg = null;
            }
        }
        return this.warnMsg;
    }

    patchRxSerialCmnLogic(rxFG, frmeRx, systemData, CntrlRefillStng) {
        const pharmacyState = this._commonSrv.getSetttingValue("TenantSettings", "TNT_STATE");
        const RxOrginData = systemData.RxOrigin.find((item: any) => item.Id === rxFG.controls["PrescReFill"].value["RxOriginId"]);
        if (pharmacyState === "NY") {
            if (frmeRx) {
                rxFG.controls["Prescription"].patchValue({PrescSN: "EEEEEEEE"});
            } else {
                if ((RxOrginData && RxOrginData.Name === "Telephone")) {
                    rxFG.controls["Prescription"].patchValue({PrescSN: "99999999"});
                } else if ((RxOrginData && RxOrginData.Name === "Pharmacy")) {
                    rxFG.controls["Prescription"].patchValue({PrescSN: "TTTTTTTT"});
                } else if ((RxOrginData && RxOrginData.Name === "Electronic")) {
                    rxFG.controls["Prescription"].patchValue({PrescSN: "EEEEEEEE"});
                } else {
                    rxFG.controls["Prescription"].patchValue({PrescSN: null});
                }
            }
        } else if (pharmacyState === "TX" && rxFG.value["Drug"]["drugclass"] === 2) {
            rxFG.controls["Prescription"].patchValue({PrescSN: CntrlRefillStng && CntrlRefillStng["ERxSerialNo"]
                ? CntrlRefillStng["ERxSerialNo"] : null});
        } else {
            rxFG.controls["Prescription"].patchValue({PrescSN: null});
        }
    }
}
