import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { CommonService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { CommonStore } from "src/app/store";

@Component({
  selector: "app-default-label-printset",
  templateUrl: "./default-label-printset.component.html"
})
export class DefaultLabelPrintsetComponent implements OnInit {
    totalPrintSets: any;
    filteredPrintsets: any;
    DefaultLabelForm: FormGroup;

    @ViewChild("DefaultLabel", { static: true })
    DefaultLabel: any;
    selectedPrintset: any;
    optedPrintset: any;
    unsubscribe$: Subject<void> = new Subject();
    modalRef: any;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();
    printerList: any;
    selectedDefaultPrinter: any;
    selectedReportPrinter: any;

    constructor(
        private _commonSvc: CommonService,
        private _modalService: NgbModal, private _commonStore: CommonStore
        ) { }

    ngOnInit() {
        this._commonStore.allPrinters$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.printerList = resp;
                this.checkForDefaultLabelPrinter();
            }
        });
        sessionStorage.setItem('FocusOnprinter','true');
    }

    checkForDefaultLabelPrinter() {
        if (this._commonSvc.DecryptData(localStorage.getItem("DefaultLabelPrinter"))) {
            this.selectedDefaultPrinter = this._commonSvc.DecryptData(localStorage.getItem("DefaultLabelPrinter"));
            if (this.printerList.find(elm => elm === this.selectedDefaultPrinter)) {
                this.IsPopUpClosed.emit(true);
            } else {
                this.modalRef = this._modalService.open(this.DefaultLabel, { centered: true, keyboard: false, backdrop: false, windowClass: "z-index-1055" });
            }
        } else {
            this.modalRef = this._modalService.open(this.DefaultLabel, { centered: true, keyboard: false, backdrop: false, windowClass: "z-index-1055" });
        }
    }

    optedPrinter(eve) {
        this.selectedDefaultPrinter = eve;
        if (eve) {
            localStorage.setItem("DefaultLabelPrinter", this._commonSvc.encryptData(eve));
        } else {
            localStorage.removeItem("DefaultLabelPrinter");
        }
    }

    // optedReportPrinter(eve) {
    //     this.selectedReportPrinter = eve;
    //     if (eve) {
    //         localStorage.setItem("DefaultReportPrinter", eve);
    //     } else {
    //         localStorage.removeItem("DefaultReportPrinter");
    //     }
    // }

    closeModal() {
        // if (!this.selectedDefaultPrinter) {
        //     this._alertService.error("Default Label Printer not selected");
        // }
        if (this.modalRef) {
        this.modalRef.close();
        this.modalRef = null;
        }
        this.IsPopUpClosed.emit(true);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
         this.unsubscribe$.complete();
         sessionStorage.removeItem('FocusOnprinter')
       }
}
