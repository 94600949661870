<ng-template #DRUGVERIFY let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');closePopUp()">
        <h4 header>Enter Credentials <span *ngIf="HeaderName">
            for {{constantHeaderNames[HeaderName]}}
        </span></h4>
        <span body>
            <div class="row" [formGroup]="LoginVerifyFG">
                <div
                    class="col-md-12 col-sm-12 col-xs-12 "
                    [formGroup]="LoginVerifyFG"
                >
                    <label class="control-label col-sm-12 padding-0">
                        <span> {{ LabelText }} </span>
                        <input
                            type="text"
                            name="RxNum"
                            formControlName="UserName"
                            class="form-control search--input__box"
                            value=""
                            id="user"
                            [readOnly]="TechName ? false : true"
                        />
                    </label>
                </div>
                <div
                    class="col-md-12 col-sm-12 col-xs-12 padding_top_0_8rem"
                   
                >
                    <eprx-input
                        [LabelText]="'Password'"
                        PlaceHolder="Password"
                        [ControlName]="'Password'"
                        [FormGroupName]="LoginVerifyFG"
                        [InputType]="'password'"
                        [Title]="'Password'"
                        [RxSelectId]="'loginPwd'"
                        [IsRequired]="true"
                        [InputErrors]="Password?.errors"
                        [ErrorDefs]="{ required: 'Required' }"
                        [FormGroupInvalid]="formGroupInvalid"
                        [AutoFocus]="true"
                    >
                    </eprx-input>
                    <span class="help--text__danger" *ngIf="warnMessage"
                        >{{warnMessage}}
                        </span
                    >

                </div>
            </div>
        </span>
        <!-- <button footer autofocus class="hotkey_primary" (click)="verifyPassword()" appShortcutKey [CtrlKey]="'s'"> -->
        <button footer class="hotkey_success" (click)="verifyPassword()" appShortcutKey [AltKey]="'s'">
           <span>S</span> submit
        </button>
        <button footer class="hotkey_success" (click)="c('Close click');closePopUp()" appShortcutKey [AltKey]="'c'"><span>C</span>CANCEL</button>
    </app-custom-modal>
</ng-template>
