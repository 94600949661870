import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CollectionView } from "@grapecity/wijmo";
import { AlertService, RxService } from 'src/app/services';
import { WijimoUtil } from 'src/app/utils';
import * as moment from 'moment';
import { MsgConfig } from 'src/app/app.messages';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as wjcGrid from "@grapecity/wijmo.grid";
import { PrintService } from 'src/app/services/print.service';
import { element } from 'protractor';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notes-history',
  templateUrl: './notes-history.component.html',
  styleUrls: ['./notes-history.component.scss']
})
export class NotesHistoryComponent implements OnInit {
  @Input() SelectedItemForNoteHistory: any;
  @Output() CloseNoteHistModal = new EventEmitter();

  @ViewChild("NoteHistory", { static: true })
  NoteHistory: any;



  @ViewChild("flex")
    flex: wjcGrid.FlexGrid;



  modelRef: any;
  notesHistory: CollectionView;
  wjHeaders: any;
  isAdd: boolean = false;
  selectedItem: object = {};
  selectedNotesIds : any =[];
  unsubscribe$: Subject<void> = new Subject();

  noteHistoryFormGroup: FormGroup;

  constructor(private _modalService: NgbModal,  private _fb: FormBuilder,
    private _alrtSev: AlertService, private _cdr: ChangeDetectorRef, private _printServ: PrintService,
    private rxService: RxService, private _wijimoUtils: WijimoUtil) {
        this.noteHistoryFormGroup = this._fb.group({
          annotationNotes: null
      });
   }

  ngOnInit(): void {
    this.modelRef = this._modalService.open(
      this.NoteHistory,
      {
        backdrop: false,
        keyboard: false,
        size: "lg",
        centered: true,
        windowClass :"max-modal-1267"
      }
    );
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(w => {
      if (w && w["WijmoKey"] && (w["WijmoKey"] === "NoteHistory")) {
        this.patchDefaultValHeaders();
      }
    });
    this.getRxAnnotationHistory();
    
  }
  getRxAnnotationHistory() {
    this.rxService.getRxAnnotationHistory(this.SelectedItemForNoteHistory["prescReFillId"])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp: any) => {
      if (resp && resp.length) {
        this.generateNotesHistory(resp);
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("NoteHistory");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("NoteHistory", storedWJ);
  }
  closePopup() {
    if (this.modelRef) {
      this.modelRef.close();
      this.modelRef = null;
    }
    this.CloseNoteHistModal.emit(false);
  }
  generateNotesHistory(data) {
    this.notesHistory =  new CollectionView(
      data.map((item, i) => {
        const j = {};
        j["Created Date & Time"] = item.CreatedDtTm ?  moment.utc(item.CreatedDtTm).local().format(MsgConfig.DATE_FORMAT +  " hh:mm A") : null; 
        j["Created By"] = item.UserName ? item.UserName.toUpperCase() : '';
        j["Notes"] = item.Notes;
        j['IsSelected'] = true;
        j['IsSelected'] = true;
        j['Id'] = item.Id;
        if(j['IsSelected']) {
          this.selectedNotesIds.push(j['Id']);
        }
        j['RowId'] = i;
        if(i === 0)
           this.selectedItem = j;
        return j;
      })
    );
  }
  saveNotes() {
    this.selectedItem = {};
    if(this.noteHistoryFormGroup.value['annotationNotes']) {
      this.rxService.postRxAnnotationHistory({prescRefillId  : this.SelectedItemForNoteHistory["prescReFillId"], Notes: this.noteHistoryFormGroup.value['annotationNotes']})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: any) => {
        if(resp)
            this.getRxAnnotationHistory();
            this.isAdd = false;
      });
    } else {
      this._alrtSev.error(MsgConfig.ENTER_REQ_FIELD);
    }
   
  }
  previewNotes(item) {
    this.selectedItem = null;
    this._cdr.detectChanges();
    this.selectedItem = item;
    this.isAdd = false;
  }
  onRowDblclicked(s: any, e: any) {
    this.selectedItem  =  (s.selectedRows && s.selectedRows.length  && s.selectedRows[0].dataItem )  ? s.selectedRows[0].dataItem : null;
    this.isAdd = false;
}
newNotesOpen() {
  this.isAdd = true;
  this.noteHistoryFormGroup.controls['annotationNotes'].reset();
  this.selectedItem = {};
}
printNotes() {
    if(this.selectedNotesIds && this.selectedNotesIds.length) {
      this.rxService.postPrintAnnotationHistory({patientName  : this.SelectedItemForNoteHistory['Patient Name'] ,
      prescriberName : this.SelectedItemForNoteHistory['Prescriber Name']  , drugName  : this.SelectedItemForNoteHistory['Drug Name'] , 
      prescNum  : this.SelectedItemForNoteHistory['Rx#']  + "-" + this.SelectedItemForNoteHistory['Ref#'] + "-" + this.SelectedItemForNoteHistory['Fill#'] , annotationIds : this.selectedNotesIds})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
         if(res) {
           const newBlob = new Blob([res], { type: "application/pdf" });
           const reader = new FileReader();
           reader.readAsDataURL(newBlob);
           reader.onloadend = function () {
               const base64data = reader.result;
               this._printServ.printPdf(base64data);
           }.bind(this);
         }
     });
    } else {
      this._alrtSev.error("Please select atleast one Notes to Print.");
    }
   
}
  selecteNotes(checked, item) {
    if (checked) {
      this.selectedNotesIds.push(item["Id"]);
    }
    else {
      const index = this.selectedNotesIds.findIndex((element) => { return element == item["Id"] });
      this.selectedNotesIds.splice(index, 1);
    }
  }
}
