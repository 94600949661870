<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Drug Documents'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                <button class="hotkey_success" (click)="routeBackToPrevScreen()" id="docCancelBut"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- <app-document-ref></app-document-ref> -->
