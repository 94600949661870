import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxModel } from "src/app/models";

@Component({
    selector: "app-common-modal",
    templateUrl: "./common-modal.component.html",
    styles: []
})
export class CommonModalComponent implements OnInit {
    @Input()
    set commonModalName(mn: any) {
        if (mn) {
            if (mn === "TRANSMISSIONCONF") {
                this.openModal(this.TRANSMISSIONCONF);
            } else if (mn === "CMMPriorApproval") {
                this.openModal(this.CMMPriorApproval);
            } else {
            }
        }
    }

    @Input() rxInfo: RxModel;

    @Output()
    CancelPopUp = new EventEmitter<any>();

    @Output()
    saveTransmitInfo = new EventEmitter<any>();

    @ViewChild("CMMPriorApproval", { static: true })
    CMMPriorApproval: any;

    @ViewChild("TRANSMISSIONCONF", { static: true })
    TRANSMISSIONCONF: any;

    constructor(private _modalService: NgbModal) {}

    ngOnInit() {}

    openModal(modalName: any) {
        const modalRef = this._modalService.open(modalName, {
            backdrop: false,
            size: "lg",
            windowClass: "md-x-lg",
            keyboard: false,
        });
    }

    saveTransmitClaims() {
        this.saveTransmitInfo.emit();
    }

    closeConfirmModal() {
        this.CancelPopUp.emit();
    }
}
