import { RxUtils } from 'src/app/utils';
import { Subscription } from 'rxjs/internal/Subscription';
import { PreviousRouteService } from 'src/app/services';
import { AuditLogUtils } from './../../../utils/audit-log.util';
import { SystemData } from "./../../../models/system-data.model";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { CollectionView } from "@grapecity/wijmo";
import {
    EditDrugService,
    CommonService,
    AlertService
} from "../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { Drug } from "../../../models";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegEx } from "./../../../app.regex";
import { UdpCurrencyMaskPipe } from '../../../pipes';

@Component({
    selector: "app-ins-restrictions",
    templateUrl: "./ins-restrictions.component.html"
})
export class InsRestrictionsComponent implements OnInit {
    drugEditFG: FormGroup;
    dispHisWJ: CollectionView;
    actvHeaders: string[];
    inActvHeaders: string[];
    Size = 15;
    pageNu = 1;
    totalCount: number;
    drugId: number;
    drugInfo: Drug;
    drugName: string;
    dropDownData: any;
    systemData: SystemData;
    insuResData: any;
    editMode = false;
    HasInsuFilter = false;
    deleteData: any;
    regex: any;
    hasData = true;
    formGroupInvalid: boolean;
    openPopUP: any;
    originalData: any;
    subscriptions = new Subscription();
    routeFrom: any;
    focusOnDU: any;
    numberPipe = new UdpCurrencyMaskPipe();
    activeModal: string;

    @ViewChild("insuResList")
    flex: wjcGrid.FlexGrid;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.routeBackToPrevScreen();
            }
        } else if (event.which === 27 && this.openPopUP) { // esc
            this.openPopUP.close();
            event.preventDefault();
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _drgSvc: EditDrugService,
        private _actvRoute: ActivatedRoute,
        private modalService: NgbModal,
        private _commonServ: CommonService,
        private _alertService: AlertService,
        private _auditUtils: AuditLogUtils,
        private _route: Router,
        private _rxutils: RxUtils
    ) {
        this.regex = RegEx;
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
             if (params && params.fm) {
                 this.routeFrom = params.fm;
             }
             if (params && params.focus) {
                if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                    this.focusOnDU = params.focus;
                }
            }
         }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugId = +params["pid"])
        ));
        this.drugEditFG = this._fb.group({
            Id: null,
            IsAllowed: null,
            BillingNDC: null,
            DrugId: this.drugId,
            InsurId: null,
            IsAllInsu: null,
            MaxQtyAllowed: null,
            MaxRefillsAllowed: null,
            NDCQualiId: null,
            IsPriorApprReqd: null,
            QtyConCal: 0,
            IsActive: true,
            IsDeleted: false,
            Result: null
        });
    }

    ngOnInit() {
        // this._commonServ.getSystemData();
        this._commonServ.systemData$.subscribe(resp => {
            this.systemData = resp;
        });
        this.getData();
        this._commonServ.getDrugInfo(this.drugId).subscribe(resp => {
            this._commonServ._drugInfo$.next(resp);
            this.drugInfo = resp["Drug"];
            this.drugName = this.drugInfo.Name;
        });
        this._drgSvc.getInsuranceDropDown().subscribe(resp => {
            this.dropDownData = resp;
        });
        this._drgSvc.insRescData$.subscribe(resp => {
            if (resp) {
                this.insuResData = resp;
                if (resp.length) {
                    this.generateWJ(resp);
                } else {
                    this.totalCount = 0;
                }
            } else {
                this.totalCount = 0;
            }
        });
    }

    getData() {
        this._drgSvc.getInsuranceRestriction(
            this.drugId,
            this.pageNu,
            this.Size
        );
    }

    setPage(page: number) {
        this.pageNu = page;
        this.getData();
    }

    setSize(size: any) {
        this.Size = size;
        this.pageNu = 1;
        this.getData();
    }

    get MaxRefillsAllowed(): FormControl {
        return this.drugEditFG.get("MaxRefillsAllowed") as FormControl;
    }
    get MaxQtyAllowed(): FormControl {
        return this.drugEditFG.get("MaxQtyAllowed") as FormControl;
    }
    get InsurId(): FormControl {
        return this.drugEditFG.get("InsurId") as FormControl;
    }

    init(flex: wjcGrid.FlexGrid) {
        // flex.columnHeaders.rows[0].height = 40;
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    generateWJ(info: any) {
        if (info) {
            this.totalCount = info[0].TotalCount;
            this.dispHisWJ = new CollectionView(
                info.map((drug, i) => {
                    const j = {};
                    j["Ins.Code"] = drug.InsurerCode;
                    j["Ins.Name"] = drug.InsName;
                    j["Billing NDC"] = drug.BillingNdc;
                    j["NDC Qualifier"] = drug.NdcQualifier;
                    if (drug.Allowed === true) {
                        j["Allowed"] = "YES";
                    } else {
                        j["Allowed"] = "NO";
                    }
                    if (drug.PriorApp === true) {
                        j["PA.?"] = "YES";
                    } else {
                        j["PA.?"] = "NO";
                    }
                    j["Max.Refills"] = drug.MaxRefillsAllowed;
                    if (drug.IsAllInsu === true) {
                        j["Apply to All Ins"] = "YES";
                    } else {
                        j["Apply to All Ins"] = "NO";
                    }
                    j["MaxQty"] = drug.MaxQtyAllowed ? (drug.MaxQtyAllowed).toFixed(3) : "0.000";
                    j["Actions"] = " ";
                    j["DrugInsuId"] = drug.DrugInsuId;
                    j["InsuranceId"] = drug.InsuranceId;
                    j["NdcQualiId"] = drug.NdcQualiId;
                    return j;
                })
            );
            this.actvHeaders = [
                "Actions",
                "Ins.Code",
                "Ins.Name",
                "Billing NDC",
                "NDC Qualifier",
                "Allowed",
                "PA.?",
                "Max.Refills",
                "MaxQty",
                "Apply to All Ins"
            ];
        } else {
            this.totalCount = 0;
        }
    }
    openPopUpModal(content) {
        this.HasInsuFilter = false;
        for (const key in this.drugEditFG.value) {
            if (
                [
                    "QtyConCal",
                    "DrugId",
                    "IsActive",
                    "IsDeleted"
                ].indexOf(key) > -1
            ) {
            } else {
                this.drugEditFG.controls[key].reset();
                this.drugEditFG.controls[key].markAsUntouched();
            }
        }
        this.drugEditFG.controls["IsAllowed"].patchValue(false);
        this.drugEditFG.controls["IsPriorApprReqd"].patchValue(false);
        this.drugEditFG.controls["IsAllInsu"].patchValue(false);
        this.editMode = false;
        this.openPopUP = this.modalService.open(content, {
            centered: true,
            size: "lg",
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Add";
    }
    addInsuRest() {
        this.drugEditFG.controls["DrugId"].patchValue(this.drugId);
        this.drugEditFG.controls["IsDeleted"].patchValue(false);
        this.drugEditFG.controls["IsActive"].patchValue(true);
        if (!this.drugEditFG.value["IsAllInsu"]) {
            if (this.drugEditFG.value["InsurId"]) {
                this.openPopUP.close();
                this._drgSvc.addInsuRest(this.drugEditFG.value).subscribe(resp => {
                    if (resp) {
                        this._auditUtils.getChangedValues(
                            null,
                            this.drugEditFG.value,
                            "Add Insurance Restrictions",
                            "Drug",
                            this.drugId
                        );
                        this.getData();
                        for (const key in this.drugEditFG.value) {
                            if (key !== "QtyConCal") {
                                this.drugEditFG.controls[key].reset();
                                this.drugEditFG.controls[key].markAsUntouched();
                            }
                        }
                    } else {
                        this._alertService.error("Add Insurance restrictions save unsuccessful.");
                    }
                });
            } else {
                this.formGroupInvalid = true;
                this._alertService.error("Please enter required fields.");
            }
        } else {
            this.openPopUP.close();
            this._drgSvc.addInsuRest(this.drugEditFG.value).subscribe(resp => {
                if (resp) {
                    this._auditUtils.getChangedValues(
                        null,
                        this.drugEditFG.value,
                        "Add Insurance Restrictions",
                        "Drug",
                        this.drugId
                    );
                    this.getData();
                    for (const key in this.drugEditFG.value) {
                        if (key !== "QtyConCal") {
                            this.drugEditFG.controls[key].reset();
                            this.drugEditFG.controls[key].markAsUntouched();
                        }
                    }
                } else {
                    this._alertService.error("Add Insurance restrictions save unsuccessful.");
                }
            });
        }
    }
    deleteInsuRest() {
        const dataInfo = this.deleteData;
        if (dataInfo) {
            this._drgSvc
                .deleteInsuRest(dataInfo["DrugInsuId"])
                .subscribe(resp => {
                    if (resp) {
                        this._auditUtils.deletedValues(
                            dataInfo["DrugInsuId"],
                            "Delete Insurance Restrictions",
                            "Drug",
                            this.drugId
                        );
                        this.getData();
                    } else {
                        this._alertService.error("Delete Insurance restrictions save unsuccessful.");
                    }
                });
        }
    }
    editInsuRest() {
        if (!this.drugEditFG.value["IsAllInsu"]) {
            if (this.drugEditFG.value["InsurId"]) {
                this.openPopUP.close();
                this._drgSvc.editInsuRest(this.drugEditFG.value).subscribe(resp => {
                    if (resp) {
                        this._auditUtils.getChangedValues(
                            this.originalData,
                            this.drugEditFG.value,
                            "Edit Insurance Restrictions",
                            "Drug",
                            this.drugId
                        );
                        this.getData();
                        for (const key in this.drugEditFG.value) {
                            if (key !== "QtyConCal") {
                                this.drugEditFG.controls[key].reset();
                                this.drugEditFG.controls[key].markAsUntouched();
                            }
                        }
                    } else {
                        this._alertService.error("Edit Insurance restrictions save unsuccessful.");
                    }
                });
            } else {
                this.formGroupInvalid = true;
                this._alertService.error("Please enter required fields.");
            }
        } else {
            this.openPopUP.close();
            this._drgSvc.editInsuRest(this.drugEditFG.value).subscribe(resp => {
                if (resp) {
                    this._auditUtils.getChangedValues(
                        this.originalData,
                        this.drugEditFG.value,
                        "Edit Insurance Restrictions",
                        "Drug",
                        this.drugId
                    );
                    this.getData();
                    for (const key in this.drugEditFG.value) {
                        if (key !== "QtyConCal") {
                            this.drugEditFG.controls[key].reset();
                            this.drugEditFG.controls[key].markAsUntouched();
                        }
                    }
                } else {
                    this._alertService.error("Edit Insurance restrictions save unsuccessful.");
                }
            });
        }
    }
    applytoAll() {
        if (this.drugEditFG.controls["IsAllInsu"].value === true) {
            this.HasInsuFilter = true;
            this.drugEditFG.controls["InsurId"].patchValue("");
        } else {
            this.HasInsuFilter = false;
        }
    }
    deletePopUpModal(content, item: any) {
        this.openPopUP = this.modalService.open(content, { centered: true , keyboard: false,
                            backdrop: false});
        this.activeModal = "Delete";
        this.deleteData = item;
    }
    editPopModal(content: any, item: any) {
        if (item["Apply to All Ins"] === "YES") {
            this.HasInsuFilter = true;
        } else {
            this.HasInsuFilter = false;
        }
        for (const key in this.drugEditFG.value) {
            if (key !== "QtyConCal") {
                this.drugEditFG.controls[key].reset();
                this.drugEditFG.controls[key].markAsUntouched();
            }
        }
        this.editMode = true;
        const dataInfo = this.drugEditFG.value;
        this.drugEditFG.controls["DrugId"].patchValue(this.drugId);
        this.drugEditFG.controls["Id"].patchValue(item.DrugInsuId);
        if (item["Allowed"] === "YES") {
            this.drugEditFG.controls["IsAllowed"].patchValue(true);
        } else {
            this.drugEditFG.controls["IsAllowed"].patchValue(false);
        }
        this.drugEditFG.controls["BillingNDC"].patchValue(item["Billing NDC"]);
        dataInfo.DrugId = this.drugId;
        if (item["Apply to All Ins"] === "YES") {
            this.drugEditFG.controls["IsAllInsu"].patchValue(true);
        } else {
            this.drugEditFG.controls["IsAllInsu"].patchValue(false);
        }
        dataInfo.IsDeleted = false;
        this.drugEditFG.controls["NDCQualiId"].patchValue(item.NdcQualiId);
        this.drugEditFG.controls["InsurId"].patchValue(item.InsuranceId);
        if (item["PA.?"] === "YES") {
            this.drugEditFG.controls["IsPriorApprReqd"].patchValue(true);
        } else {
            this.drugEditFG.controls["IsPriorApprReqd"].patchValue(false);
        }
        this.drugEditFG.controls["MaxRefillsAllowed"].patchValue(
            item["Max.Refills"].toString()
        );
        this.drugEditFG.controls["MaxQtyAllowed"].patchValue(
            item["MaxQty"].toString()
        );
        this.originalData = this.drugEditFG.value;
        this.openPopUP = this.modalService.open(content, {
            centered: true,
            size: "lg",
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Update";
    }
    routeBackToPrevScreen() {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
       // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    setNumberFormat(ControlName) {
        this.drugEditFG.controls[ControlName].patchValue(
        this.numberPipe.transform(this.drugEditFG.value[ControlName], 3));
    }

    openAddModalOnEnter(content) {
        setTimeout(() => {
            this.openPopUpModal(content);
        }, 10);
    }

    focusOutFromAdd(event) {
        if (!this.totalCount) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.flex.focus();
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    keyEventOnWijimo(content, grid) {
        if (this.totalCount) {
            event.preventDefault();
            this.editPopModal(content, grid.selectedItems[0]);
        }
    }

    addEventOnWijimo(event, content) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openPopUpModal(content);
            }
        }
    }
}
