export class RxControlsModal {
    MAKE_DT_ORDER_MNDT = ["PrescReFill", "OrderDtTm"];
    MAKE_AUTH_REFILL_MNDT = ["Prescription", "ReFillNum"];
    MAKE_RX_ORIG_CODE_MNDT = ["PrescReFill", "RxOriginId"];
    MAKE_DELV_METHOD_MNDT = ["PrescReFill", "DeliverModeId"];
    MAKE_PHARMA_INITIALS_MNDT = ["PrescReFill", "RegPharmacist"];
    MAKE_TECH_INITIALS_MNDT = ["PrescReFill", "TechInitials"];

    MAKE_DRUG_LOT_MNDT = ["PrescReFill", "LotNum"];
    MAKE_DAW_MNDT = ["PrescReFill", "DAWId"];

    // Not using this
    // MAKE_MAX_DAILY_DOSE_CDRUG_MNDT = ["RxAdminDoseSchedule", "RxAdmin", "dosePerDay"];
}


// MAKE_MAX_DAILY_DOSE_CDRUG_MNDT = ["PrescReFill", "TechInitials"];
// MAKE_UNIQUE_RX_SERIAL_MNDT = ["PrescReFill", "TechInitials"];
