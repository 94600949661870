import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-sub-dur",
  templateUrl: "./sub-dur.component.html"
})
export class SubDurComponent implements OnInit, AfterViewInit {
    durFG: FormGroup;
    defaultval: any;
    InputErrorsName: any;
    formGroupInvalid: any;

    @ViewChild("subPriceList", { static: true })
    subPriceList: ElementRef;

    @Input()
    set DurFG(ed: FormGroup) {
      this.durFG = ed;
    }
    
    @Input()
    set Defaultval(ed: any) {
        this.defaultval = ed;
    }

    @Input()
    set InputErrors(ed: any) {
        this.InputErrorsName = ed;
    }

    
    @Input()
    set FormGroupInvalid(ed: any) {
        this.formGroupInvalid = ed;
    }

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const data: any = document.getElementById("subPriceList").getElementsByTagName("input")[0];
    data.focus();
}

}
