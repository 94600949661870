<div class="modal-header">
    <h4 class="modal-title">Please select default Report Printer</h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <eprx-select [LabelText]="'Default Report Printer'" [PlaceHolder]="'Default Report Printer'"
                [List]="printerList" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                [InputValue]="selectedReportPrinter" [HasControl]="false"
                (TriggerSelectValue)="optedReportPrinter($event.value)" [IsFirstElem]="true" [AutoFocus]="true">
            </eprx-select>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'o'">
        <span>O</span> OK
    </button>
</div>
