<div class="editpatient">
    <div class="row content--heading">
        <div class="col-md-10 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png" />
                </div>
                <div class="col-md-10 padding-0">
                    <div class="content--heading__title padding-0">
                        Clinical Information |
                        <span class="content--name">
                            {{
                                patientInfo?.Person?.LastName | titlecase
                            }},&nbsp;
                            {{
                                patientInfo?.Person?.FirstName | titlecase
                            }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-2 padding-0">
            <button class="hotkey_success" id="SaveButton" (click)="cancel()"><span>C</span> Cancel</button>
        </div>
    </div>
</div>
<div class="exprx--common-block insurance--body erx--body">
    <div class="eprx--block__header large_header">
        <div class="eprx--header__heading">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-md-10">
                        Clinical Info
                    </div>
                    <div class="col-md-2 text-right">
                            <button class="hotkey_success hotkey_primary--Override"
                            (keydown.tab)="focusOutFromAdd($event)" autofocus
                            (click)="openClinicalInfo(addclinicalInfo)" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content" *ngIf="totalCount > 0">
        <wj-flex-grid
            #Clinical
            (updatedView)="init(Clinical)"
            (keydown.enter)="keyeventOnWijimo(addclinicalInfo, Clinical, $event)"
            (keydown)="openPopOnWijimo(addclinicalInfo, $event)"
            [headersVisibility]="'Column'"
            [itemsSource]="patClinicalList"
            [isReadOnly]="true"
            [columnPicker]="''"
            [selectionMode]="'Row'"
            [allowSorting]="true"
        >
            <wj-flex-grid-column
                [header]="actvHeader"
                [binding]="actvHeader"
                [visible]="true"
                *ngFor="let actvHeader of actvHeaders; let i = index"
                title="{{ actvHeader }}"
                [width]="actvHeader === 'Actions' ? '1*' : '*'"
            >
                <ng-template
                    wjFlexGridCellTemplate
                    [cellType]="'Cell'"
                    let-item="item"
                >
                    <div *ngIf="actvHeader === 'Actions'">
                        <i
                            class="far  fa-edit fa-lg actions "
                            title="Edit"
                            (click)="
                                openeditClinicalInfo(addclinicalInfo, item)
                            "
                        ></i>
                        <i
                            class="far fa-trash-alt fa-lg actions danger-actve"
                            title="Delete"
                            (click)="openDeletePopUp(content, item)"
                        ></i>
                    </div>
                    <div *ngIf="!(actvHeader === 'Actions')">
                        {{ item[actvHeader] }}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
        <app-eprx-pagination
            [TotalCount]="totalCount"
            (OnPageSelected)="setPage($event)"
            (OnSizeSelected)="setSize($event)"
            [GridName]="'PatClinicalInfoGrid'"
            [WijmoName]="flex"
            (OnFocusOut)="focusToFirst($event)"
        ></app-eprx-pagination>
    </div>
    <div *ngIf="totalCount === 0" class="eprx--block__footer">
        <div>
            <span></span>
            <i class="fa fa-file"></i><br/>
            No Data Found!
        </div>
    </div>
    <ng-template #addclinicalInfo let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Clinical Info Measurement Details</h4>
            <button
                type="button"
                [tabindex]="-1" class="close"
                aria-label="Close"
                (click)="d('Cross click')"
            >
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body edit-patient">
            <div class="row">
                <div class="col-md-4">
                    <eprx-date-picker
                        [LabelText]="'Measure Date'"
                        [PlaceHolder]="'Measure Date'"
                        [ControlName]="'MeasureDt'"
                        [FormGroupName]="clinicalMeasureFG"
                        [MinDate]=""
                        [MaxDate]="minDate"
                        [IsDisabled]="false"
                        [AutoFocus]="true"
                        [IsRequired]="true"
                    >
                    </eprx-date-picker>

                    <eprx-input
                        [LabelText]="'Measure Time'"
                        [PlaceHolder]="'Measure Time'"
                        [MaxLength]="15"
                        [ControlName]="'MeasureTm'"
                        [Title]="'Measure Time'"
                        [FormGroupName]="clinicalMeasureFG"
                    >
                    </eprx-input>
                    <!--<eprx-input [LabelText]="'Rx Number'" [PlaceHolder]="'Rx Number'" [ControlName]="'PrescId'" [Title]="'Rx Number'"
                        [FormGroupName]="clinicalMeasureFG" [InputType]="'NUMBER'">
                    </eprx-input> -->
                    <!-- <label class="control-label col-sm-12 padding-0">
                        <span> Rx Number </span>
                        <input type="text" name="RxNum" class="form-control search--input__box" value="" />
                    </label> -->
                   <!-- <eprx-input [LabelText]="'Refill Number'" [PlaceHolder]="'Refill Number'" [ControlName]="'RefillNum'" [Title]="'Refill Number'"
                        [FormGroupName]="clinicalMeasureFG" [InputType]="'NUMBER'">
                    </eprx-input>-->
                    <!-- <label class="control-label col-sm-12 padding-0">
                        <span> Refill Number </span>
                        <input
                            type="text"
                            name="RefillNum"
                            class="form-control search--input__box"
                            value="0"
                        />
                    </label> -->
                </div>
                <div class="col-md-8">
                    <eprx-select
                        [LabelText]="'Select Dimension'"
                        [PlaceHolder]="'select dimension'"
                        [secondCntrlN]="'Dimension'"
                        [ControlName]="'MedTestId'"
                        [FormGroupName]="clinicalMeasureFG"
                        [HasMultiple]="false"
                        [BindLabel]="'Name'"
                        [BindValue]="'Id'"
                        [bindScnCntrlV]="'Remarks'"
                        [LabelForId]="'Id'"
                        [BindLabel2]="'Remarks'"
                        [List]="systemData?.MedTest"
                        [ShowLabel2]="true"
                        [IsRequired]="true"
                        [MarkAsTouched]="clinicalMeasureFG?.controls['MedTestId']?.touched"
                        [InputErrors]="MedTestId?.errors"
                        [ErrorDefs]="{ required: 'Required' }"
                        [FormGroupInvalid]="formGroupInvalid"
                        (TriggerSelectValue)="getUnit()"
                    >
                    </eprx-select>
                    <eprx-select
                        [LabelText]="'Select Unit'"
                        [PlaceHolder]="'select unit'"
                        [secondCntrlN]="'MeasurementUnit'"
                        [ControlName]="'TestUnitId'"
                        [FormGroupName]="clinicalMeasureFG"
                        [HasMultiple]="false"
                        [BindLabel]="'Name'"
                        [BindValue]="'Id'"
                        [bindScnCntrlV]="'Name'"
                        [LabelForId]="'Name'"
                        [List]="unitValues"
                        [IsRequired]="true"
                        [MarkAsTouched]="TestUnitId?.touched"
                        [InputErrors]="TestUnitId?.errors"
                        [ErrorDefs]="{ required: 'Required' }"
                        [FormGroupInvalid]="formGroupInvalid"
                    >
                    </eprx-select>

                    <eprx-input
                        [LabelText]="'Value'"
                        [PlaceHolder]="'Value'"
                        [MaxLength]="15"
                        [ControlName]="'ObsVal'"
                        [Title]="'Measure Time'"
                        [FormGroupName]="clinicalMeasureFG"
                        [InputType]="'NUMBER'"
                        [IsRequired]="true"
                        [MarkAsTouched]="ObsVal?.touched"
                        [InputErrors]="ObsVal?.errors"
                        [FormGroupInvalid]="formGroupInvalid"
                        [ErrorDefs]="{ required: 'Required' }"
                    >
                    </eprx-input>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="hotkey_success"
                (click)="saveclinicalData()"
                *ngIf="!editMode"
                appShortcutKey [AltKey]="'s'"
            >
            <span>S</span>     SAVE
            </button>
            <button
                type="button"
                class="hotkey_success"
                (click)="c('Close click')"
                (click)="updateclinicalData()"
                *ngIf="editMode" appShortcutKey [AltKey]="'u'"
            >
            <span>U</span>    Update
            </button>
            <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'">
             <span>C</span>   CANCEL
            </button>
        </div>
    </ng-template>
</div>
<ng-template #warningInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="d('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>Clinical Information Already Exists</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" appShortcutKey InputKey="o" class="hotkey_primary" (click)="c('Close click')">
         <b>O</b>   OK
        </button>
    </div>
</ng-template>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="d('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label
                            >{{warningText?.deleteConfrmMsg}}
                            {{ currentItem["Insurance Name"] }} ?</label
                        >
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            (click)="c('Close click')"
            (click)="deleteClinicalInfo()"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" class="hotkey_primary" appShortcutKey InputKey="n" (click)="c('Close click')">
          <b>N</b>  NO
        </button>
    </div>
</ng-template>
