import { Subscription } from 'rxjs';
import html2canvas from "html2canvas";
import { CollectionView } from "@grapecity/wijmo";
import { DrugPO } from "./../../../models/edit-drug.model";
import { FormGroup } from "@angular/forms";
import {
    Component,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
    OnDestroy,
    HostListener,
    ViewChild,
    ElementRef
} from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import {
    CommonService,
    EditDrugService,
    AlertService
} from "../../../services";
import { Drug } from "./../../../models/patient-Info.model";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VendorItemService } from "./../../../services/vendor-item.service";
import { Vendordruginfo, Vendor, AccCategoryE, PhysicalInventoryE, InventoryE } from "../../../models";
import { RegEx } from "../../../app.regex";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import { MsgConfig } from "../../..";
import * as _ from "lodash";
import { RxUtils } from '../../../utils';
import { UdpCurrencyMaskPipe } from '../../../pipes';
import { PrivMaskGuard } from 'src/app/guards';
import { PrintService } from 'src/app/services/print.service';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
    selector: "app-inventory",
    templateUrl: "./inventory.component.html"
})
export class InventoryComponent implements OnInit, AfterViewInit, OnDestroy {
    inventoryWJ: CollectionView;
    actvHeaders: string[];
    inActvHeaders: string[];
    orderInformationFG: FormGroup;
    inventoryInfo: any;
    lastReceived: string;
    lastDispensed: string;
    qtyinHand: any;
    qtyEqu: any;
    hasTrans = false;
    vendorItemFG: FormGroup;
    show = false;
    drugInformation: any;
    addvendor: any;
    vendorInfoWJ: CollectionView;
    vendoractvHeaders: string[];
    vendorDrugInfoData: any;
    data: any = {};
    regex: any;
    editMode = false;
    drugId: number;
    drugID: number;
    hasVendorItems = false;
    checkedvalue: boolean;
    vendorDeleteId: any;
    defVal: any;
    formGroupInvalid: boolean;
    vendorModal: any;
    disData: any;
    disContinue: any;
    hasVendor: boolean;
    originalData: any;
    sharedCancel = false;
    subscriptions = new Subscription();
    numberPipe = new UdpCurrencyMaskPipe();
    routeFrom: any;
    focusOnDU: any;
    isPhysicalInventEnabl: boolean;
    inventoryHistory: any;

    @ViewChild("error", { static: true })
    error: any;
    deleteVendor: any;
    disContPopUp: any;
    canDeactivateRoute = true;
    vendorModalSM: any;
    modalRefSm: any;
    modalRef: any;
    accessPrivCate: any;
    inventoryPrivs: any;

    @ViewChild("InventoryTrans", { static: true })
    InventoryTrans: ElementRef;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                event.preventDefault();
                this.updateDrug();
            } else if (event.which === 67) { // c
                event.preventDefault();
                this.CancelPopTaggle();
            }
        } else if (event.which === 27 && this.modalService.hasOpenModals()) {
            if (this.modalRefSm) {
            this.setFocus();
            event.preventDefault();
        } else if (this.vendorModal) {
            this.vendorModal.close();
            this.resetForm();
            event.preventDefault();
        } else if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }
    }
}

    constructor(
        private _fb: FormBuilder,
        private _data: CommonService,
        private _commonServ: CommonService,
        private _drugSvc: EditDrugService,
        private _cdr: ChangeDetectorRef,
        private _vendorItems: VendorItemService,
        private _actvRoute: ActivatedRoute,
        private modalService: NgbModal,
        private _alertSer: AlertService,
        private _route: Router,
        private _auditUtils: AuditLogUtils,
        private _rxutils: RxUtils,
        private _privMaskGuard: PrivMaskGuard,
        private _printSvc: PrintService,
        public _formatsUtil:FormatsUtil
    ) {
        this.isPhysicalInventEnabl = false;
        this.accessPrivCate = AccCategoryE;
        this.inventoryPrivs = InventoryE;
        this.createFG();
        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
             if (params && params.fm) {
                 this.routeFrom = params.fm;
             }
             if (params && params.focus) {
                if (params.focus === "drugUnit" || params.focus === "unitOfMeasure") {
                    this.focusOnDU = params.focus;
                }
            }
         }));
        this.subscriptions.add(this._actvRoute.parent.params.subscribe(
            params => (this.drugId = +params["pid"])
        ));
        this.regex = RegEx;
        this.vendorItemFG = this._fb.group(new Vendor());
    }
    ngOnInit() {
        this.getInfo(this.drugId);
        this.getDrugDefault();
        this.getVendorDrugInfo();
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }
    ngAfterViewInit() {
        this._cdr.detectChanges();
        const data: any = document.getElementById("ManageInv").getElementsByTagName("input")[0];
        data.focus();
    }
    ngOnDestroy() {
        this._cdr.detach();
    }
    createFG() {
        this.orderInformationFG = this._fb.group({
            Drug: this._fb.group(new Drug()),
            DrugPO: this._fb.group(new DrugPO()),
            lastVendor: []
        });
    }
    getDrugDefault() {
        this._drugSvc.getDrugDefaultval().subscribe(resp => {
            if (resp) {
                this.defVal = resp;
            }
        });
    }
    convertByMouseDown(changes: any) {
        if (changes === "UnitAWP") {
            if ((
                this.vendorItemFG.controls["AWP"].value /  this.inventoryInfo.Drug["QtyPack"]
            ).toFixed(2) !== "INFINITY" && !(isNaN(
                this.vendorItemFG.controls["AWP"].value /  this.inventoryInfo.Drug["QtyPack"]
            ))) {
                this.vendorItemFG.controls["UnitAWP"].patchValue((
                    this.vendorItemFG.controls["AWP"].value /  this.inventoryInfo.Drug["QtyPack"]
                ).toFixed(2));
            } else {
                this.vendorItemFG.controls["UnitAWP"].patchValue("0.00");
            }
        } else if (changes === "UnitCost") {
            if ((
                this.vendorItemFG.controls["CostPack"].value /  this.inventoryInfo.Drug["QtyPack"]
            ).toFixed(2) !== "INFINITY" && !(isNaN(
                this.vendorItemFG.controls["CostPack"].value /  this.inventoryInfo.Drug["QtyPack"]
            ))) {
                this.vendorItemFG.controls["UnitCost"].patchValue((
                    this.vendorItemFG.controls["CostPack"].value /  this.inventoryInfo.Drug["QtyPack"]
                ).toFixed(2));
            } else {
                this.vendorItemFG.controls["UnitCost"].patchValue("0.00");
            }
        } else if (changes === "AWP") {
            this.vendorItemFG.controls["AWP"].patchValue((
                this.vendorItemFG.controls["UnitAWP"].value *  this.inventoryInfo.Drug["QtyPack"]
            ).toFixed(2));
        } else if (changes === "PackCost") {
            this.vendorItemFG.controls["PackCost"].patchValue((
                this.vendorItemFG.controls["UnitCost"].value *  this.inventoryInfo.Drug["QtyPack"]
            ).toFixed(2));
        }
    }
    convertTo2Decimal(changes: any): void {
       if (!(changes === "UnitAWP" || changes === "UnitCost" || changes === "AWP" || changes === "PackCost")) {
           if (this.orderInformationFG.controls["DrugPO"].value[changes] !== 0) {
            this.inventoryInfo.DrugPO[changes] = parseFloat(
                this.orderInformationFG.controls["DrugPO"].value[changes]
            ).toFixed(2);
        } else {
            this.inventoryInfo.DrugPO[changes] = "0.00";
        }}
        if (changes === "UnitAWP") {
            this.vendorItemFG.controls["AWP"].patchValue(
                (
                    this.inventoryInfo.Drug["QtyPack"] *
                    this.vendorItemFG.controls["UnitAWP"].value
                ).toFixed(2)
            );
        } else if (changes === "UnitCost") {
            this.vendorItemFG.controls["PackCost"].patchValue(
                (
                    this.inventoryInfo.Drug["QtyPack"] *
                    this.vendorItemFG.controls["UnitCost"].value
                ).toFixed(2)
            );
        } else if (changes === "AWP") {
            this.vendorItemFG.controls["UnitAWP"].patchValue(
                (
                    this.vendorItemFG.controls["AWP"].value /
                    this.inventoryInfo.Drug["QtyPack"]
                ).toFixed(2)
            );
        } else if (changes === "PackCost") {
            this.vendorItemFG.controls["UnitCost"].patchValue(
                (
                    this.vendorItemFG.controls["PackCost"].value /
                    this.inventoryInfo.Drug["QtyPack"]
                ).toFixed(2)
            );
        }
    }
    convertTo3Decimal(changes: any): void {
        if (this.orderInformationFG.controls["DrugPO"].value[changes] !== 0) {
            this.inventoryInfo.DrugPO[changes] = parseFloat(
                this.orderInformationFG.controls["DrugPO"].value[changes]
            ).toFixed(3);
        } else {
            this.inventoryInfo.DrugPO[changes] = "0.000";
        }
    }
    convertDecimal(control: any, type: number) {
        if (type === 2) {
            this.convertTo2Decimal(control);
        } else {
            this.convertTo3Decimal(control);
        }
        this.patchValue();
    }
    changeValue(control: any, type: number, event: any) {
        if (event.which === 9 || event.which === 13 || event.which === 17) { // // tab-9, enter-13, ctrl-17
            this.convertDecimal(control, type);
        }
    }
    getInfo(id: number) {
        this._data.getDrugInfo(id).subscribe(resp => {
            this.inventoryInfo = resp;
            this._commonServ._drugInfo$.next(resp);
            if (
                this.inventoryInfo["QtyInHand"]
            ) {
                this.qtyinHand = this.inventoryInfo["QtyInHand"].toFixed(3);
            } else {
                this.qtyinHand = "0.000";
            }

            if (this.inventoryInfo["QuantityInHandEqui"]) {
                this.qtyEqu = this.inventoryInfo["QuantityInHandEqui"].toFixed(
                    3
                );
            } else {
                this.qtyEqu = "0.000";
            }

            this.lastReceived = moment(
                this.inventoryInfo.InventoryHistory.LastReceived
            ).format("MM/DD/YYYY");
            this.lastDispensed = moment(
                this.inventoryInfo.InventoryHistory.LastDispensed
            ).format("MM/DD/YYYY");
            this.patchValue();
            for (const key in this.inventoryInfo) {
                if (["DrugPO"].indexOf(key) > -1 && this.inventoryInfo[key]) {
                    for (const keys in this.inventoryInfo[key]) {
                        if (
                            [
                                "ReorderQty",
                                "MinOrderQty",
                                "OrderQty",
                                "TargetQty"
                            ].indexOf(keys) > -1
                        ) {
                            if (!this.inventoryInfo.DrugPO[keys]) {
                                this.inventoryInfo.DrugPO[keys] = "0.00";
                            } else {
                            this.convertTo3Decimal(keys);
                            }
                        }
                    }
                } else if (["Drug"].indexOf(key) > -1 && this.inventoryInfo[key]) {
                    for (const keys in this.inventoryInfo[key]) {
                        if (
                            [
                                "AWPPack",
                                "DirectUnitPrice",
                                "CostPack",
                                "UnitPriceAWP",
                                "UnitPriceCost"
                            ].indexOf(keys) > -1
                        ) {
                            if (!this.inventoryInfo.Drug[keys]) {
                                this.inventoryInfo.Drug[keys] = "0.00";
                            }
                        }
                    }
                }
            }
            this.patchValue();
            this.originalData = _.clone(this.orderInformationFG.value);
            delete this.originalData.lastVendor;
        });
    }
    get DrugPO(): FormGroup {
        return this.orderInformationFG.get("DrugPO") as FormGroup;
    }
    get Drug(): FormGroup {
        return this.orderInformationFG.get("Drug") as FormGroup;
    }
    generateGrid() {
        for (const keys in this.inventoryInfo) {
            if (["DrugTrans"].indexOf(keys) > -1) {
                this.generateWJ(this.inventoryInfo[keys]);
            }
        }
    }
    patchValue() {
        for (const key in this.inventoryInfo) {
            if (
                ["DrugPO"].indexOf(key) > -1
            ) {
                if (this.inventoryInfo[key]) {
                this.orderInformationFG.controls[key].patchValue(
                    this.inventoryInfo[key]
                );
                } else {
                    const data: any = this.orderInformationFG.controls["DrugPO"];
                    data.controls["IsAutoPO"].patchValue(true);
                    data.controls["ReorderQty"].patchValue(0);
                    data.controls["MinOrderQty"].patchValue(0);
                    data.controls["OrderQty"].patchValue(0);
                    data.controls["TargetQty"].patchValue("0.000");
                }
            } else if (key === "Drug") {
                this.orderInformationFG.controls[key].patchValue(
                    this.inventoryInfo[key]
                );
            } else if (key === "DrugTrans") {
                this.generateWJ(this.inventoryInfo[key]);
            }
        }
        this.orderInformationFG.controls["DrugPO"].markAsPristine();
        this.orderInformationFG.controls["DrugPO"].valueChanges.subscribe(value => {
            if (this.Drug.dirty) {
                this.canDeactivateRoute = false;
            } else {
                this.canDeactivateRoute = true;
            }
        });
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    generateWJ(info: any) {
        if (info) {
            this.inventoryWJ = new CollectionView(
                info.map((inventory, i) => {
                    const j = {};
                    j["Date Received."] = moment(inventory.CreatedDtTm).format(
                        "MM/DD/YY"
                    );
                    j["Vendor"] = inventory.VendorCode;
                    j["Qty.Received"] = inventory.Qty ? (inventory.Qty).toFixed(3) : "0.000";
                    j["Price"] = inventory.AqCostPerPkg;
                    j["Drug Lot"] = inventory.LotNum;
                    j["Reference"] = inventory.Ref;
                    j["PO Number"] = inventory.PONum;
                    j["Received By"] = inventory.Name;
                    j["Drug NDC"] = this.inventoryInfo.Drug.DrugCode;
                    return j;
                })
            );
            this.actvHeaders = [
                "Date Received.",
                "Vendor",
                "Qty.Received",
                "Price",
                "Drug Lot",
                "Reference",
                "PO Number",
                "Received By",
                "Drug NDC"
            ];
            this.hasTrans = true;
        }
    }

    setNumberFormat(ControlName) {
        this.DrugPO.controls[ControlName].patchValue(
        this.numberPipe.transform(this.DrugPO.value[ControlName], 3));
    }

    setFocus() {
       // const dataInfo = this.orderInformationFG.value;
       // dataInfo.DrugPO.OrderQty.focus();
        document.getElementById("MinOrderQty").focus();
        this.modalRefSm.close();
        this.modalRefSm = null;
        event.preventDefault();
    }
    updateDrug() {
        const dataInfo = this.orderInformationFG.value;
        for (const key in dataInfo) {
            if (key) {
                if (["DrugPO"].indexOf(key) > -1) {
                    dataInfo[key].DrugId = this.inventoryInfo.Drug.Id;
                }
            }
        }

        delete dataInfo["lastVendor"];

        if (dataInfo.DrugPO.OrderQty < dataInfo.DrugPO.MinOrderQty) {
           this.modalRefSm =  this.modalService.open(this.error, { centered: true });
        } else {
            this._drugSvc.updateDrugInfo(dataInfo).subscribe(resp => {
                if (resp === 0 || resp === 3 || resp === 5) {
                    this._auditUtils.getChangedValues(
                        this.originalData,
                        dataInfo,
                        "Inventory Info",
                        "Drug",
                        this.drugId
                    );
                    this._alertSer.success(MsgConfig.UPDATE_SUCCESS);
                  this.routeBackToPrevScreen("update");
                } else if (resp === 2) {
                    this._alertSer.error("Quick code must be unique.");
                } else if (resp === 1) {
                    this._alertSer.error("NDC code must be unique.");
                } else {
                    this._alertSer.error(MsgConfig.UPDATE_FAIL);
                }
            });
        }
    }
    routeToRxScreen(rx: any) {
        this._commonServ.storePatientIdinBS(rx.value.Patient.patientid);
        this._commonServ.storeRxIdinBS(rx.RxID);

        localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
        // localStorage.setItem("RxInfo", JSON.stringify(rx.value));
        this._route.navigate(["eprx/rx"], {
            queryParams: {
                fm: rx.type
            }
        });
    }
    get manageInventory(): FormControl {
        return this.orderInformationFG.get("manageInventory") as FormControl;
    }
    get reorderlevel(): FormControl {
        return this.orderInformationFG.get("reorderlevel") as FormControl;
    }
    get targetQty(): FormControl {
        return this.orderInformationFG.get("targetQty") as FormControl;
    }
    get preferredVendor(): FormControl {
        return this.orderInformationFG.get("preferredVendor") as FormControl;
    }
    get lastVendor(): FormControl {
        return this.orderInformationFG.get("lastVendor") as FormControl;
    }
    get minimumOrderQty(): FormControl {
        return this.orderInformationFG.get("minimumOrderQty") as FormControl;
    }
    get qtyOnOrder(): FormControl {
        return this.orderInformationFG.get("qtyOnOrder") as FormControl;
    }

    // vendor items codes controls
    get VendorId(): FormControl {
        return this.vendorItemFG.get("VendorId") as FormControl;
    }
    get ItemCode(): FormControl {
        return this.vendorItemFG.get("ItemCode") as FormControl;
    }
    get UnitAWP(): FormControl {
        return this.vendorItemFG.get("UnitAWP") as FormControl;
    }
    get AWP(): FormControl {
        return this.vendorItemFG.get("AWP") as FormControl;
    }
    get UnitCost(): FormControl {
        return this.vendorItemFG.get("UnitCost") as FormControl;
    }
    get PackCost(): FormControl {
        return this.vendorItemFG.get("PackCost") as FormControl;
    }

    getVendorItem(id) {
        this.show = !this.show;
        if (this.show) {
            this.getVendorDrugInfo();
            this._vendorItems.getDrugInformation(id).subscribe(resp => {
                this.drugInformation = resp;
            });
        }
    }
    discont(data, item: any, content) {
        this.disData = item;
        this.disContinue = data;
        this.modalRef = this.modalService.open(content, {centered: true});
    }
    vendorDisContinue() {
        this._drugSvc.updateVendorStatus(this.disData.Id, this.disContinue).subscribe(resp => {
            this.getVendorDrugInfo();
        });
    }
    addVendorDrugInfo() {
        this.setvalues();
        if (this.vendorItemFG.valid) {
            this.vendorModal.close();
            this.formGroupInvalid = false;
            this._drugSvc
            .addvendorItems(this.vendorItemFG.value)
            .subscribe(resp => {
                if (resp === 1) {
                    this._alertSer.success(MsgConfig.SAVE_SUCCESS);
                    this._auditUtils.getChangedValues(
                        null,
                        this.vendorItemFG.value,
                        "Add Vendor ItemCodes",
                        "Drug",
                        this.drugId
                    );
                    this.vendorItemFG.reset();
                    this.getVendorDrugInfo();
                } else if (resp === 2) {
                    this._alertSer.error("Vendor is already associated with the drug.");
                } else {
                    this._alertSer.error(MsgConfig.SAVE_FAIL);
                }
            });
        } else {
            this.formGroupInvalid = true;
        }
    }
    // this is temporary method
    setvalues() {
        this.vendorItemFG.controls.DrugId.setValue(this.drugId);
        this.vendorItemFG.controls.IsDiscont.setValue(false);
        this.vendorItemFG.controls.IsActive.setValue(true);
    }

    getVendorDrugInfo() {
        this._drugSvc.getVendorInfobyDrugId(this.drugId).subscribe(resp => {
            if (resp.length > 0) {
                this.hasVendor = true;
                this.vendorDrugInfoData = resp;
                    this.genrateVendorDrugInfoWJ(resp);
            } else {
                this.hasVendor = false;
            }
        });
    }

    genrateVendorDrugInfoWJ(vendorHist: Vendordruginfo[]) {
        this.vendorInfoWJ = new CollectionView(
            vendorHist.map((vend, i) => {
                const j = {};
                j["Id"] = vend.Id;
                j["Vendor Id"] = vend.VendorId;
                j["Vendor Name"] = vend.VendorName;
                j["Vendor Item Code"] = vend.ItemCode;
                j["Unit Price Cost"] = vend.UnitCost ? vend.UnitCost : 0;
                j["Cost Pack"] = vend.PackCost ? vend.PackCost : 0;
                j["AWP Pack"] = vend.AWP ? vend.AWP : 0;
                j["Unit Price AWP"] = vend.UnitAWP ? vend.UnitAWP : 0;
                j["Discontinue"] = vend.IsDiscont;
                this.checkedvalue = vend.IsDiscont;
                j["Actions"] = " ";
                return j;
            })
        );

        this.vendoractvHeaders = [
            "Actions",
            "Vendor Name",
            "Vendor Item Code",
            "Unit Price Cost",
            "Cost Pack",
            "AWP Pack",
            "Unit Price AWP",
            "Discontinue"
        ];
        this.hasVendorItems = true;
    }

    editVendorInformation(content, currentdata) {
        this.data.Id = currentdata["Id"];
        this.data.DrugId = this.drugId;
        this.data.VendorId = currentdata["Vendor Id"];
        this.data.ItemCode = currentdata["Vendor Item Code"];
        this.data.UnitCost = (currentdata["Unit Price Cost"]).toString();
        this.data.PackCost = (currentdata["Cost Pack"]).toString();
        this.data.AWP = (currentdata["AWP Pack"]).toString();
        this.data.UnitAWP = (currentdata["Unit Price AWP"]).toString();
        this.data.Remarks = "";
        this.data.IsDiscont = currentdata["Discontinue"];
        this.data.IsActive = true;
        this.vendorItemFG.patchValue(this.data);
        this.editPopUpModal(content);
    }

    updateVendorDrugInfo() {
        if (this.vendorItemFG.valid) {
            this.vendorModal.close();
            this.formGroupInvalid = false;
            this._drugSvc
            .updatevendorItems(this.vendorItemFG.value)
            .subscribe(resp => {
                if (resp === 1) {
                    this._alertSer.success(MsgConfig.UPDATE_SUCCESS);
                    this._auditUtils.getChangedValues(
                        this.data,
                        this.vendorItemFG.value,
                        "Edit Vendor ItemCodes",
                        "Drug",
                        this.drugId
                    );
                    this.vendorItemFG.reset();
                    this.getVendorDrugInfo();
                    this._alertSer.success(MsgConfig.UPDATE_SUCCESS);
                } else if (resp === 2) {
                    this._alertSer.error("Vendor is already associated with the drug.");
                } else {
                    this._alertSer.error(MsgConfig.UPDATE_FAIL);
                }
            });
        } else {
            this.formGroupInvalid = true;
        }
    }
    deleteWarnPopUp(s: any, e: any) {
        this.vendorDeleteId = e.Id;
        this.modalRef = this.modalService.open(s, { size: "sm", centered: true });
    }
    deleteVendorDrugInfo() {
        this._drugSvc
            .vendorInfoDelete(this.vendorDeleteId)
            .subscribe(resp => {
                if (resp) {
                    this._auditUtils.deletedValues(
                        this.vendorDeleteId,
                        "Delete Vendor ItemCodes",
                        "Drug",
                        this.drugId
                    );
                    this.getVendorDrugInfo();
                    this._alertSer.success(MsgConfig.DELETE_SUCCESS);
                }
            });
    }
    resetForm() {
        this.vendorItemFG.reset();
        this.vendorModal.close();
        event.preventDefault();
    }

    openPopUpModal(content) {
        this.editMode = true;
        this.vendorModal = this.modalService.open(content, { size: "lg", keyboard: false});
    }
    editPopUpModal(content) {
        this.editMode = false;
        this.vendorModal = this.modalService.open(content, { size: "lg", keyboard: false});
    }
    errorPopUpModal(content) {
        this.modalService.open(content, { centered: true });
    }
    addInvenRoute() {
        this._route.navigate([
            "/eprx/inventory/"
        ], {
            queryParams: {
                drugId: this.drugId
            }
        });
    }

    routeBackToPrevScreen(type?: any) {
        this.canDeactivateRoute = true;
        if (type && type === "update") {
        this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "update", this.focusOnDU);
        } else {
            this._rxutils.updateLatestRxWithEditDrugInfo(this.drugId, this.routeFrom, null, "cancel", this.focusOnDU);
        }
        // this._rxutils.routeBackFromDrug(this.routeFrom, this.focusOnDU);
    }

    CancelPopTaggle() {
        if (this.Drug.dirty) {
            this.sharedCancel = false;
        } else {
            this.routeBackToPrevScreen();
        }
    }

    CancelPopClose(val) {
        if (val) {
            this.routeBackToPrevScreen();
            this.sharedCancel = false;
        } else {
            this.sharedCancel = false;
        }
    }

    focusToFirst(event) {
         if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    focusOutFmCan(event) {
        if (document.getElementById("addButton")) {
            event.preventDefault();
            setTimeout(() => {
                const data: any = document.getElementById("addButton").focus();
            }, 10);
        }
    }

    checkIsPhysicalInventory(PRIVSWARNING?: any) {
        const alwDrugChng = this._privMaskGuard.canActivate(this.accessPrivCate.Inventory,
            this.inventoryPrivs.PhysicalInventory);
        if (alwDrugChng) {
            this.isPhysicalInventEnabl = true;
        } else {
            this.modalService.open(PRIVSWARNING, { keyboard: false});
            this.Drug.controls["IsInvMangd"].setValue("false");
        }
    }

    setFocusManageInvent() {
        document.getElementById("MinOrderQty").focus();
    }

    closendclearMangInv(isCancel?: boolean) {
        this.isPhysicalInventEnabl = false;
        if (isCancel) {
            this.Drug.controls["IsInvMangd"].setValue(false);
        }
    }

    getDrugData() {
        this._drugSvc.getInventoryhistory(this.Drug.value.Id).subscribe(resp => this.inventoryHistory = resp);
        this.modalRef = this.modalService.open(this.InventoryTrans, { size: "lg"});
    }

    print() {
        const data = document.getElementById("inventory");
        html2canvas(data).then(canvas => {
            const imgWidth = canvas.width;
            const pageHeight = canvas.height;
            const imgHeight = canvas.height;
            const heightLeft = imgHeight;
            const contentDataURL = canvas.toDataURL("image/jpeg");
            this._printSvc.print(contentDataURL);
        });
    }

    closeModal() {
        this.modalRef.close();
    }

}
