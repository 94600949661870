import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { constant } from "src/app";
import { share } from "rxjs/internal/operators/share";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
};
@Injectable({
    providedIn: "root"
})
export class NotesLogService {
    constructor(private _http: HttpClient) {}

    getNotesLog(parentPage, id, fromDate, toDate, params): Observable<any> {
        console.log(id , "idid");
        return this._http.post<any>(
            constant.GET_Utility_NotesLog.replace("{parentPage}", "" + parentPage).replace("{id}", "" + id)
                                         .replace("{fromDate}", "" + fromDate).replace("{toDate}", "" + toDate), params).pipe(
                                            share());
    }

    getNotesLogDetails(exportedData: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.GET_NotesLog, exportedData, {
            headers: headers,
            responseType: "blob"
        });
    }

    addNotesLog(parentPage, id, notesInfo): Observable<any> {
        return this._http.post<any>(
            constant.POST_Utility_NotesLog.replace(
                "{parentPage}",
                "" + parentPage
            ).replace("{id}", "" + id),
            notesInfo
        );
    }

    updateNotesLog(notesInfo): Observable<any> {
        return this._http.put<any>(constant.PUT_Utility_NotesLog, notesInfo);
    }

    deleteNotesLog(id): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Utility_NotesLog.replace("{id}", "" + id)
        );
    }

    updateNotesStatus(notesId, status, noteType, rxType:string = ""): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Utility_UpdateNotesStatus
                .replace("{notesId}", "" + notesId)
                .replace("{isShowpopup}", "" + status).replace("{RxType}", "" + rxType).replace("{NoteType}", "" + noteType), {}
        );
    }

    getNotesTrackChanges(noteId): Observable<any> {
        return this._http.post<any>(
            constant.GET_NOTES_TRACK_CHANGES.replace("{noteId}", "" + noteId), {}
        );
    }
}
