import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { constant } from "..";

@Injectable({
  providedIn: "root"
})
export class DrugWarningService {

  constructor(private _http: HttpClient) { }

  getDrugWarnings(data: any) {
    return this._http.post(constant.POST_Drug_Warning, data);
  }
}
