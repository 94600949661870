import * as wjcGrid from "@grapecity/wijmo.grid"
import { CollectionView } from "@grapecity/wijmo";
import { Component, OnInit, Input, ChangeDetectorRef, NgZone } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClinicalData, SystemData, Measurement, PatAuditLog, RxAuditLog, PaginationModel } from "src/app/models";
import { PatientService, AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NewAuditUtils, WijimoUtil } from "src/app/utils";
import * as moment from "moment";
import { MsgConfig } from "src/app";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
    selector: "app-clinical-info",
    templateUrl: "./clinical-info.component.html"
})
export class PClinicalInfoComponent implements OnInit {
    @Input() patientID: any;
    @Input() systemData: SystemData;
    patClinicalList: CollectionView;
    editMode: boolean;
    clinicalMeasureFG: FormGroup;
    clinicalData = new ClinicalData();
    totalCount: any;
    wjHeaders: any;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    today: any;
    time: any;
    clinicalInfo: any;
    clinicData: any;
    unitValues: any;
    minDate: { year: number; month: number; day: number };
    currentTime: string;
    clinicalId: any;
    CreatedDtTm: any;
    currentItem: any;
    formGroupInvalid: boolean;
    modelRef: any;
    wijimoId: wjcGrid.FlexGrid;
    editOldData: any;
    oldMeasureDate: any;
    oldMeasureTime: any;
    focusField: any;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    dateChecks: { control: string; minDate: string; maxDate: { year: number; month: number; day: number; }; isRequired: boolean; }[];
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    constructor(
        private modalService: NgbModal,
        private _editPatient: PatientService,
        private _fb: FormBuilder,
        private _alertService: AlertService,
        private _newAuditUtil: NewAuditUtils,
        private _cdr: ChangeDetectorRef,
        private _wijimoUtils: WijimoUtil,
        private _dateUtil: DateCheckUtil,
        private ngZone: NgZone,
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.currentTime = moment().format("hh:mm:ss");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
    }

    ngOnInit() {
        this.clinicalData.PageNumber = this.pageNumber;
        this.clinicalData.PageSize = this.pageSize;
        this.getClinicalData(this.clinicalData);
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "patClinicalInfoListWJ") {
                this.patchDefaultValHeaders();
            }
        });
        // this.getUnit();
        this.dateChecks = [{
            control: "MeasureDt",
            minDate: "",
            maxDate: this.minDate,
            isRequired: true
        }];
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    async getClinicalData(data: any) {
        const resp = await this._editPatient.getClinicalInfo(this.patientID, data).toPromise();
        this.generateList(resp);
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("patClinicalInfoListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("patClinicalInfoListWJ", storedWJ);
    }

    generateList(data: any) {
        if (data && data.length > 0) {
            this.totalCount = data[0].TotalCount;
            this.isDataExists = true;
            this.patClinicalList = new CollectionView(
                data.map((clinical) => {
                    this.oldMeasureDate  = clinical.MeasureDt;
                    this.oldMeasureTime  = clinical.MeasureDt;
                    const j = {};
                    j["Id"] = clinical.Id;
                    j["Date and Time"] = moment.utc(clinical.MeasureDt).local().format("MM/DD/YYYY hh:mm a");
                    j["Dimension"] = clinical.Dimension;
                    j["Measurement Unit"] = clinical.MeasurementUnit;
                    j["Measurement Value"] = clinical.MeasurementValue;
                    j["UnitId"] = clinical.MeasurementUnitId;
                    j["DimensionId"] = clinical.DimensionId;
                    j["CreatedDtTm"] = clinical.CreatedDtTm;
                    return j;
                })
            );
        } else {
            this.totalCount = 0;
            this.isDataExists = false;
            this.patClinicalList = new CollectionView(null)
        }
    }

    setPage(page: number) {
        this.clinicalData.PageNumber = page;
        this.getClinicalData(this.clinicalData);
    }

    setSize(size: any) {
        this.clinicalData.PageNumber = 1;
        this.clinicalData.PageSize = size;
        this.getClinicalData(this.clinicalData);
    }

    openClinicalInfo(addclinicalInfo) {
        this.editMode = false;
        this.createFG();
        this.unitValues = [];
        this.clinicalMeasureFG.patchValue({
            MeasureDt: this.today,
            MeasureTm: moment().format("hh:mm a"),
            PatientId: this.patientID,
            IsActive: true
        });
        this.clinicalMeasureFG.patchValue({MeasureTm: moment().format("hh:mm a")});
        this.modelRef = this.modalService.open(addclinicalInfo, { centered: true, backdrop: false });
    }

    async  openeditClinicalInfo(addclinicalInfo, val: any) {
        this.editMode = true;
        this.createFG();
        // this.oldMeasureDate  = val["Date and Time"];
        // this.oldMeasureTime  = val["Date and Time"];
        this.clinicalMeasureFG.patchValue({
            MeasureDt: moment(val["Date and Time"]).format("MM/DD/YYYY"),
            MeasureTm: moment(val["Date and Time"]).format("hh:mm a"),
            ObsVal: val["Measurement Value"],
            Id: val["Id"],
            PatientId: this.patientID,
            IsActive: true,
            MedTestId: val["DimensionId"],
            TestUnitId: val["UnitId"],
            CreatedDtTm: val["CreatedDtTm"],
            Dimension: val["Dimension"],
            MeasurementUnit: val["Measurement Unit"]
        });
        this.modelRef = this.modalService.open(addclinicalInfo, { centered: true, backdrop: false });
        await this.getUnit();
        this.editOldData = this.clinicalMeasureFG.value;

    }

    async getUnit() {
        if (this.clinicalMeasureFG.value["MedTestId"]) {
            this.unitValues = await this._editPatient.getPatientClinUnits(this.clinicalMeasureFG.value["MedTestId"]).toPromise();
            if (this.unitValues && this.unitValues.length > 0) {
                this.clinicalMeasureFG.patchValue({TestUnitId : this.unitValues[0].Id, MeasurementUnit: this.unitValues[0].Name});
            } else {
                this.clinicalMeasureFG.patchValue({TestUnitId : null, MeasurementUnit: null});
            }
        } else {
            this.unitValues = [];
            this.clinicalMeasureFG.patchValue({TestUnitId : null, MeasurementUnit: null});
        }
        this._cdr.detectChanges();
    }

    createFG() {
        this.clinicalMeasureFG = this._fb.group(new Measurement());
    }

    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openClinicalInfo(content);
            }
        }
    }

    openDeletePopUp(content, val: any) {
        this.currentItem = val;
        this.ngZone.run(() => {
            this.modelRef = this.modalService.open(content, { centered: true, windowClass: "large--content"});
        });
    }

    async deleteClinicalInfo() {
        const resp = await this._editPatient.deleteClinicalInfo(this.currentItem.Id, this.patientID).toPromise();
        if (resp) {
            this._newAuditUtil.SaveAuditOnDelete(
                [{ name: "Is Active", value: true }],
                [{ name: "Is Active", value: false}, {name: "Id", value: this.currentItem.Id}],
                "ClinicalInfo",
                "Patient",
                this.patientID
            );
            this._alertService.success(MsgConfig.DELETE_SUCCESS);
            this.getClinicalData(this.clinicalData);
        } else {
            this._alertService.error(MsgConfig.DELETE_FAIL);
        }
    }

    getTwentyFourHourTime(amPmString) {
        const d = new Date("1/1/2013 " + amPmString);
        return d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    }

    async saveclinicalData() {
        const clinicDatesFG = this._fb.group({MeasureDt: this.clinicalMeasureFG.controls["MeasureDt"].value});
         const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(clinicDatesFG, this.dateChecks);
        if (dateCheckFields) {
            return;
        } else if (this.clinicalMeasureFG.valid) {
            const measureTime = this.getTwentyFourHourTime(
                this.clinicalMeasureFG.value.MeasureTm
            );
            this.clinicalMeasureFG.value.MeasureTm = measureTime;
            const resp = await  this._editPatient.addClinicalInfo(this.clinicalMeasureFG.value).toPromise();
            if(this.modelRef) {
                this.modelRef.close();
                  this.modelRef = null;
            }
            if (resp === -1) {
                this._alertService.error("Clinical Information Already Exists", false, "long");
            } else if (resp === 1) {
                    const clinicalModel = new RxAuditLog().rxClinicalAuditModel;
                    this._newAuditUtil.saveAuditOnAdd(
                        this.clinicalMeasureFG.getRawValue(),
                        "ClinicalInfo",
                        "Patient",
                        this.patientID,
                        clinicalModel
                    );
                    this._alertService.success(MsgConfig.SAVE_SUCCESS);
                    this.getClinicalData(this.clinicalData);
                } else {
                    this._alertService.error(MsgConfig.SAVE_FAIL);
                }
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }
    async updateclinicalData() {
        const updateclinicDatesFG = this._fb.group({MeasureDt: this.clinicalMeasureFG.controls["MeasureDt"].value});
         const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(updateclinicDatesFG, this.dateChecks);
        if (dateCheckFields) {
            return;
        } else if (this.clinicalMeasureFG.valid) {
            this.clinicalMeasureFG.value.MeasureTm = this.getTwentyFourHourTime(this.clinicalMeasureFG.value.MeasureTm);
            const resp = await this._editPatient.updateClinicalInfo(this.clinicalMeasureFG.value).toPromise();
            if(this.modelRef) {
                this.modelRef.close();
                  this.modelRef = null;
            }
            if (resp === 1) {
                this.editOldData.MeasureTm = this.getTwentyFourHourTime(this.editOldData.MeasureTm);
                this.editOldData.MeasureDt = this.oldMeasureDate;
                this.editOldData.MeasureTm = this.oldMeasureTime;
                const clinicalModel = new RxAuditLog().rxClinicalAuditModel;
                this._newAuditUtil.saveAuditChange(
                    this.editOldData,
                    this.clinicalMeasureFG.getRawValue(),
                    "ClinicalInfo",
                    "Patient",
                    this.patientID,
                    clinicalModel
                );
                this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                this.getClinicalData(this.clinicalData);
            } else {
                this._alertService.error(MsgConfig.UPDATE_FAIL);
            }
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
    }

    Init(content,grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
          if (e.key == 'Enter') {
            this.openeditClinicalInfo(content, grid.collectionView["_src"][grid.selection.row]);
          }
        });
    }

    focusOutFromAdd(event) {
        if (!this.totalCount || this.totalCount === 0) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.wijimoId.focus();
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
