import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services';

@Component({
    selector: 'app-default-report-printer',
    templateUrl: './default-report-printer.component.html'
})
export class DefaultReportPrinterComponent implements OnInit {

    printerList: any;
    selectedReportPrinter: any;
    @Input()
    set PrinterList(pi: any) {
        this.printerList = pi;
    }


    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    constructor(
        private _commonSvc: CommonService
    ) { }

    ngOnInit() {
        // this._printSer.allPrinters$.subscribe(resp => {
        //     if (resp) {
        //         this.printerList = resp;
        //         this.checkForDefaultReportPrinter();
        //     }
        // });
        this.checkForDefaultReportPrinter();
    }

    checkForDefaultReportPrinter() {
        let defPrinter = this._commonSvc.DecryptData(localStorage.getItem("DefaultReportPrinter"));
        if (defPrinter && this.printerList && this.printerList.length && this.printerList.find(elm => elm === defPrinter)) {
            this.selectedReportPrinter = defPrinter;
        }
    }

    optedReportPrinter(eve) {
        this.selectedReportPrinter = eve;
        if (eve) {
            localStorage.setItem("DefaultReportPrinter", this._commonSvc.encryptData(eve));
        } else {
            localStorage.removeItem("DefaultReportPrinter");
        }
    }

    closeModal() {
        if (this.selectedReportPrinter) {
            this.IsPopUpClosed.emit(this.selectedReportPrinter);
        } else {
            this.IsPopUpClosed.emit(false);
        }
    }

}
