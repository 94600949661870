<!-- <label class="container">one
    <input type="checkbox" checked="checked">
    <span class="checkmark"></span>
</label> -->

<span [formGroup]="inputFormGroup">
    <input
        class="form-check-input"
        type="checkbox"
        [id]="rxSelectId ? rxSelectId : custContrlName"
        [formControlName]="custContrlName"
        [attr.disabled]="isDisabled ? true : null"
        (change)="triggerChange($event)"
        [appAutofocus]="autoFocus"
        (keyup.shift.tab)="onShiftTabbingEvent($event)"
        [checked]="IsChecked ? true : false"
    />
    <label class="form-check-label" [for]="rxSelectId ? rxSelectId : custContrlName">{{
        labelText
    }}</label>
</span>

