<div class="row">
    <div class="col-md-12">
        <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="pdfToShow"
            class="text-center width_100per border_3px_solid_black" toolbar="0" width="100%" height="501px"
            type="application/pdf"></iframe>

        <img *ngIf="fileTypeToShow == 'image'" [src]="imageToShow"
            class="width_100per border_3px_solid_black height_37_rem object_fit_contain">
        <app-electronic-rx-doc *ngIf="!(fileTypeToShow == 'image' || fileTypeToShow == 'pdf') && showErxDocs"
            [rxFG]="rxFG"></app-electronic-rx-doc>
        <app-electronic-rx-doc *ngIf="!(fileTypeToShow == 'image' || fileTypeToShow == 'pdf') && (!showErxDocs)"  [IsFromQuickVerification]="true"
            [RxInfoFromVerifications]="rxFG.value"></app-electronic-rx-doc>
    </div>
</div>
<!-- <div class="row row ml-5 mt-2" *ngIf="(!(totalDocs?.length || totalDocsforDisplay?.length) && !showErxDocs)">
    <h2>No Documents for this Rx</h2>
</div> -->
<div class="row">
    <div class="col-12 text-center">
        <div class="d-inline">
            <i class="far fa-step-backward actions text-primary font_size_1_5rem"
                (click)="navigateToNextImage(false)"></i>
        </div>
        <div class="d-inline px-2">
            <label class="font_size_1_5rem"> {{currentDocIndex + 1}} of {{totalDocsforDisplay?.length}}</label>
        </div>
        <div class="d-inline">
            <i class="far fa-step-forward actions text-primary font_size_1_5rem"
                (click)="navigateToNextImage(true)"></i>
        </div>
    </div>
</div>