import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    HostListener
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService, CommonService, RphVerificationService } from "src/app/services";
import { WijimoUtil } from "src/app/utils";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-rph-verify-hist",
    templateUrl: "./rph-verify-hist.component.html"
})
export class RphVerifyHistComponent implements OnInit {
    rxInfo: any;
    modelRef: any;
    verifyHist: any;
    verifyHistModified: any;
    wjHeaders: any;
    isForRxVerf: boolean = true;
    isForRphVerf: boolean = true;
    verifyHistory: any;
    isRxVerification: boolean;
    verfyHistCall = false;
    unsubscribe$: Subject<void> = new Subject();
    @Input()
    set IsRxVerification(data: any) {
        this.isRxVerification = data;
    }
    // @Input()  IsRxVerification: any;
    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
        this.getRxVerifyHist();
    }
    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("RxVerifHist", { static: true })
    RxVerifHist: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.modelRef.close();
            event.preventDefault();
        }
    }


    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private _rphService: RphVerificationService,
        private _wijimoUtils: WijimoUtil,
        private _cmnSvc: CommonService
    ) {
        config.backdrop = "static";
    }

    ngOnInit() {
        this.modelRef = this._modalService.open(this.RxVerifHist,
            {keyboard: false, centered: true, size: "lg"});
        if (!this.verfyHistCall) {
            this.getRxVerifyHist();
            this.verfyHistCall = true;
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "rphVeryHistListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("rphVeryHistListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("rphVeryHistListWJ", storedWJ);
    }

    getRxVerifyHist() {
        this.verfyHistCall = true;
        this._rphService
            .GetRxVerifyHistory(
                this.rxInfo.Prescription.PrescNum,
                this.rxInfo.PrescReFill.ReFillNum,
                this.rxInfo.PrescReFill.PartialFillNo)
                .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.verifyHist = resp.sort((a, b) => {
                        return <any>new Date(b.VerifDtTm) - <any>new Date(a.VerifDtTm);
                    });
                    if(this.isRxVerification) {
                        this.verifyHistory = this.verifyHist.filter((item: any) => {
                            const status = item.Status.trim().toUpperCase();
                            return ((status == "R") || (item.IsForRxVerification ==true && status === "RV"));
                        });
                        this.isForRxVerf = (this.verifyHistory.length >= 1) ? true : false;
                    } else {
                        this.verifyHistory = this.verifyHist.filter((item: any) => {
                            const status = item.Status.trim().toUpperCase();
                            return ((status == "V" || status == "F") || (item.IsForRxVerification ==false && status === "RV"));
                        });
                        this.isForRphVerf = (this.verifyHistory.length >= 1) ? true : false;
                    }
                    this.verifyHistModified = new CollectionView(
                        this.verifyHistory.map((item, i) => {
                            const k = {};
                            k["Rx#"] = this.rxInfo.Prescription.PrescNum;
                            k["Ref#"] = this.rxInfo.PrescReFill.ReFillNum;
                            k["Fill#"] = this._cmnSvc.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription) ? this.rxInfo.PrescReFill.PartialFillNo : " ";
                            k["Verified By"] = item["UserName"];
                            k["Date Time Verified"] = (item["VerifDtTm"]) ? moment.utc(item["VerifDtTm"]).local().format("MM/DD/YYYY hh:mm:ss a") : null;;
                            k["Status"] = item["Status"];
                            k["IsReverified"] = item["IsReverified"];
                            return k;
                        })
                    );
                } else {
                    this.verifyHistory = [];
                    if (this.isRxVerification) {
                        this.isForRxVerf = false;
                    } else {
                        this.isForRphVerf = false;
                    }
                }
            });
    }

    closeModal() {
        if (this.modelRef) {
        this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }
}
