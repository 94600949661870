<ng-template #HistPopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close');closePopup()">
        <h4 header>DPV Verification History</h4>
        <span body>
            <div class="row">
                <div class="col-12" *ngIf="DPVVerfHist && DPVVerfHist.length>0 else noRecords">
                    <div>
                    <wj-flex-grid #previewControl [headersVisibility]="'Column'" class="min-height_2rem" [itemsSource]="dphVerifModif"
                        [isReadOnly]="true" [selectionMode]="'None'" [columnPicker]="'revHistoryListWj'" >
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                        [width]="rxheader['width']"   *ngFor="let rxheader of wjHeaders2; let i = index">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div *ngIf="(rxheader['hName'] === 'Date Time Verified')">
                                {{
                                    item[rxheader['hName']]
                                    | localTime | date: "MM/dd/yyyy hh:mm:ss a"
                                }}
                                <!-- {{ item[rxheader['hName']] | localTime | date: "MM/dd/yyyy" }} -->
                            </div>
                            <div *ngIf="(rxheader['hName'] === 'Result')">
                                {{item[rxheader['hName']] ? ((item[rxheader['hName']].trim() | lowercase) === 'p' ? (item['IsReverified'] ? 'Re-Verified' : 'Verified' ):
                                (item[rxheader['hName']].trim() | lowercase)
                                === 'f' ? 'FAIL' : ((item[rxheader['hName']].trim() | lowercase)=== 'rv') ? 'Re-Verify': '' ) : ''}}
                            </div>
                            <div *ngIf="!(rxheader['hName'] === 'Date Time Verified'  || rxheader['hName'] === 'Result')"
                                title={{item[rxheader.hName]}}>
                                {{ item[rxheader['hName']] }}
                            </div>

                        </ng-template>

                      </wj-flex-grid-column>
                    </wj-flex-grid>
                    </div>
                </div>
                <ng-template #noRecords>
                    <div class="col-12 text-center">
                        <h5>No DPV Verification History available for this Rx.</h5>
                    </div>
                </ng-template>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="closePopup()" appShortcutKey [InputKey]="'o'">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>
