export enum GridLabels {    
    BrowseRx = "BrowseRx",
    RxVerification = "RphVerification",
    VerifiedListGrid = "VerifiedListGrid",
    notVerifiedListGrid = "notVerifiedListGrid",
    ReviewDrugPickVerfGrid = "ReviewDrugPickVerfGrid",
    ReviewPharmacistVerfGrid = "ReviewPharmacistVerfGrid",
    TransHistGrid = "TransHistGrid",
    IntakeQueueGrid = "IntakeQueueGrid",
    PatientSearchGrid = "PatientSearchGrid",
    PatientCategory = "PatientCategory",
    Facility = "Facility",
    DiagnosisListFile = "diagnosislistfile",
    DiagnosisList = "DiagnosisList",
    PrescriberSearchGrid = "PrescriberSearchGrid",
    PrescriberSpecialties = "PrescriberSpecalities",
    PrescriberTypeFile = "PrescriberTypeFileMaintenance",
    DrugSearchGrid = "DrugSearchGrid",
    DrugCategoriesGrid = "DrugCategrysGrid",
    WarningCodeList = "warningCodelist",
    DrugDescriptionGrid = "DrugDescriptionGrid",
    SigFileGrid = "SigFileGrid",
    PriceList = "priceList",
    PriceSchedule = "priceSchedule",
    DiscountScheduleGrid = "DiscountScheduleGrid",
    DoseSchedule = "DoseSchedule",
    Vendors = "Vendors",
    InsuranceGrid = "InsuranceGrid",
    PayorFileList = "Payor_File_List",
    NCPDPGrid = "NCPDP Grid",
    RxFollowUpTag = "rxFollowUpTag",
    DeferredList = "deferredList",
    TransferPharmacy = "TransferPharmacy",
    UsersList = "UsersList",
    RolesListGrid = "RolesListGrid",
    UsersLoginHistory = "UsersLoginHistory",
    OverrideLog = "auditLog",
    RecallDeletedRx = "Recall Deleted Rx",
    Buckets = "Buckets",
    TransTypeGrid = "TransType",
    DMSCategoryGrid = "DMSSubCategoryGrid"
}

export enum FileNames {
    BrowseRx = "Browse Rx",
    RxVerification = "Rx Verification",
    DrugPickVerification = "Drug Pick Verification - Verified",
    DrugPickVerificationNotVerified = "DrugPickVerification-NotVerified",
    RphVerification = "Rph Verification",
    DPVHistory = "DPV History",
    RPHVerificationHistory = "RPH Verification History",
    TransmissionDetails = "Transmission details",
    IntakeQueueFile = "Intake Queue File",
    PatientFile = "Patient File",
    PatientCategories = "Patient Categories",
    FacilityFile = "Facility File",
    DiagnosisFile = "Diagnosis File",
    DiagnosisList = "DiagnosisList",
    PrescriberFile = "Prescriber File",
    PrescriberSpecialties = "Prescriber Specialities List Maintenance",
    PrescriberTypeFileMaintenance = "Prescriber Type File Maintenance",
    DrugFile = "Drug File",
    DrugCategories = "Drug Categories",
    DrugWarning = "Drug Warning",
    DrugDescriptionGrid = "Drug Description",
    SigFile = "Sig File",
    PriceList = "price List",
    PriceSchedule = "price Schedule List",
    DiscountSchedule = "Discount Schedule",
    DoseSchedule = "DoseSchedule",
    Vendors = "Vendor File",
    InsuranceFile = "Insurance File",
    PayorFileMaintenance = "Payor File Maintenance",
    NCPDPPayerSet = "NCPDP Payer Set",
    RxFollowUpTag = "Search Rx FollowUp Tags",
    deferredList = "deferred List",
    TransferPharmacy = "Transfer Pharmacy",
    UsersList = "Users List",
    RolesListGrid = "Roles List",
    UsersLoginHistory = "Users Login History",
    OverrideLog = "Override Log",
    RecallDeletedRx = "Recall Deleted Rx",
    BucketSearch = "Bucket Search",
    TransTypeFile = "Search Inventory Transaction Type", 
    DMSSubCategory = "Search Document SubCategory Type"
}

export enum EntityNames {
    Rx = "Rx",
    Patient = "Patient",
    Prescriber = "Prescriber",
    Drug = "Drug",
    Insurance = "Insurance"
}
