<div class="editpatient--body newrx--body edit--drug edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <app-drug-common-header [headingData]="'Dispensed History'"></app-drug-common-header>
            </div>
            <div class="text-right pull-right col-md-3 padding-0">
                <button class="hotkey_success" id="SaveButton" (keydown.tab)="focusOutFmCan($event)"
                    (click)="routeBackToPrevScreen()"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__content">
            <div class="row">
                <div class="col-10 text-right pull-right">
                    <label class="col-12 padding-0">
                        Dispensing history for all equivalent drugs
                    </label>
                </div>
                <div class="col-1 text-right pull-right">
                    <eprx-radio [ControlName]="'includeEquivalentDrugs'" [FormGroupName]="drugEditFG" [AutoFocus]="true"
                        [IsRequired]="true" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                        [IDForList]="['Comp', 'All']" [LabelForId]="" (TriggerSelectValue)="setEqui()"
                        (keydown.tab)="focusOutFromRadio($event)">
                    </eprx-radio>
                </div>
            </div>

            <wj-flex-grid #inventorylist [headersVisibility]="'Column'" [itemsSource]="dispHisWJ" [isReadOnly]="true"
                [columnPicker]="''" [selectionMode]="'Row'" (keydown.enter)="keyEventOnWijimo(inventorylist)"
                (updatedView)="init(inventorylist)" *ngIf="totalCount">
                <wj-flex-grid-column [header]="'Action'" [width]="60">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                    *ngFor="let actvHeader of actvHeaders; let i = index" [width]="
                        (actvHeader === 'Rf' ||
                        actvHeader === 'Days')
                            ? '2*'
                            : (actvHeader === 'TotalAmount'|| actvHeader === 'Rx#' ||
                              actvHeader === 'QtyDisp' ||
                              actvHeader === 'Status' || actvHeader === 'Ins.Type' ||
                              actvHeader === 'Date Filled' || actvHeader === 'Copay')
                            ? '4*'
                            : '6*'
                    " [format]="actvHeader === 'TotalAmount' || actvHeader === 'Copay' ? 'c2' : 'd'">
                    <!-- <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div *ngIf="actvHeader === 'Actions'" style="text-align: center">
                            <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                            </div>
                            <div *ngIf="!((actvHeader === 'Actions'))">
                                    {{item[actvHeader]}}
                            </div>
                    </ng-template> -->
                </wj-flex-grid-column>
            </wj-flex-grid>
            <app-eprx-pagination [TotalCount]="totalCount" [PageSize]="Size" [WijmoName]="flex"
                [GridName]="'dispHistory'" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)"
                *ngIf="totalCount" (OnFocusOut)="focusToFirst($event)"></app-eprx-pagination>
        </div>
        <div *ngIf="!totalCount" class="eprx--block__footer">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
    </div>
</div>
<app-override-log *ngIf="openOverride" [PrivMask]="privType" [CatId]="categoryId"
    [OverrideEndpoint]="'POST_Rx_GetRxDetails'" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

<ng-template #Redirect let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close')">
        <h4 header>Warning!!</h4>
        <span body>
            The current action will discard the {{routeFrom === "er" ? "Edit Rx" : "Refill Rx"}} transaction. Do you
            wish
            to continue?
        </span>
        <button footer autofocus class="hotkey_primary" appShortcutKey
            (click)="routeToRx(true);c('close')" InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" appShortcutKey InputKey="c" (click)="c('close')"><b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>
