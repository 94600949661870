import { Component, OnInit, Output , EventEmitter, Input} from "@angular/core";
import { FormBuilder , FormGroup } from "@angular/forms";
import { WijimoUtil } from "src/app/utils";
import * as wjcCore from "@grapecity/wijmo";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BucketService } from "../../../services/bucket.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PaginationModel } from "src/app/models/elastc-rx.model";

@Component({
    selector: "app-bucket-list-display",
    templateUrl: "./bucket-list-display.component.html",
    styleUrls: ["./bucket-list-display.component.scss"]
})
export class BucketListDisplayComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    BucketListDisplayWJ: any;
    totalCount: number;
    @Input() DrugId: any;
    wjHeaders: any;
    InsuFiltersFrmGrp: FormGroup;
    modelRef: any;
    @Output()
    CloseBucketDisplayPopup = new EventEmitter();
    equivalentDrugWJ: any;
    totalList = { general: 0, bucket340b: 0 };
    totalSum: number;
    unsubscribe$: Subject<void> = new Subject();
    constructor(private _wijimoUtils: WijimoUtil, private _fb: FormBuilder, private _BucketSer: BucketService, public activeModal: NgbActiveModal) {
        this.createFG();
    }

    ngOnInit() {
        this.getBucketList();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "bucketListDisplaywj") {
                this.patchDefaultValHeaders();
            }
        });
    }
    createFG() {
        this.InsuFiltersFrmGrp = this._fb.group({
            pageNumber: this.paginationModel.pageNumber,
            pageSize: this.paginationModel.pageSize,
            Code: null,
            Name: null,
            Qty: null,
            BucketType: null,
            BucketContextType: "all",
        });
    }
    closeModal() {
        this.CloseBucketDisplayPopup.emit(" ");
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("bucketListDisplaywj");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("bucketListDisplaywj", storedWJ);
    }
    async getBucketList() {
        const resp = await this._BucketSer.getBucketsForDropdowns(this.DrugId).toPromise();
        if (resp) {
            if (resp.length > 0) {
                this.totalCount = resp[0]["TotalRecords"];
                this.calculateTotalGeneralAnd340bRecords(resp);
                this.generateBucketListWJ(resp);
            } else {
                this.totalCount = 0;
            }
        } else {
            this.totalCount = 0;
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    calculateTotalGeneralAnd340bRecords(resp) {
        resp.map((item: any) => {
            if (item.ContextTypeId === 1) {
                this.totalList.general = (+this.totalList.general) + (+item.QtyInHand);
            }
            if (item.ContextTypeId === 2) {
                this.totalList.bucket340b = (+this.totalList.bucket340b) + (+item.QtyInHand);
            }
           });
           this.totalSum = this.totalList.general + this.totalList.bucket340b;
        }

    generateBucketListWJ(BucketList: any[]) {
        this.BucketListDisplayWJ = new wjcCore.CollectionView(
            BucketList.map((Bucket, i) => {
                const j = {};
                j["Id"] = Bucket.Id;
                j["Bucket Code"] = Bucket.Code;
                j["Bucket Name"] = Bucket.Name;
                j["Qty"] = Bucket.QtyInHand ? Bucket.QtyInHand.toFixed(3) : "0.000";
                j["Bucket Context Type"] = Bucket.BucketContextType;
                return j;
            })
        );
    }

}
