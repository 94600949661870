import { DateTime } from "@grapecity/wijmo";
import { RxModel } from ".";

export class ControlRxFilterParams {
    StateId: number = null;
    State: string = null;
    FromDt: Date = null;
    ToDt: Date = null;
    IncRxNotSubmitted: boolean = null;
    IncRxResubmission: boolean = null;
    IsControlRxResubmissionList: boolean = null;
    PrescRefillIds: any = null;
    PrescNum: string[] = null;
    RefillNum: number = null;
    RxCount: number = null;
    erxOrderNo: string = null;
    erxRefNo: string = null;
    DrugClass: any = null;
    ControlFileBy: number = null;
    ItemOfConcern: boolean = null;
    FrmTime: Date = null;
    ToTime: Date = null;
    FrmPatHistory: boolean = null;
    uploadToFTP: boolean = null;
    selctedRow:any =null;
    CntrlToDate:Date = null;
    CntrlToDueDateDays:any = null;
    CntrlFromDate:Date = null;
    CntrlFromDueDateDays:number = null;
    subType:any = null;
    FrmTime1:any=null;
    ToTime1:any=null;
    isFromResubmitList:any=null;
}
export class DrugClass{
    Class2:any=null;
    Class3:any=null;
    Class4:any=null;
    Class5:any=null;
    Class6:any=null;
}

export class ResubmitlistinputParams {
    State: string = null;
    FromDt: Date = null;
    ToDt: Date = null;
}
export class ControlFileGenerationResultModel{
    hasErrors: boolean = false;
    errors: ControlFileError[] = [];
    tuple: any[] = [];
    rxData: RxModel[] = [];
    pharmacyError: string;
    errorFileName: string;
}
export class ControlFileError {
    Error: string;
    RxNumber: number;
    RefillNumber: number;
}
