import { FormBuilder } from "@angular/forms";
import { EditDrugService } from "./../../../../services/edit-drug.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, HostListener } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"

@Component({
    selector: "app-drug-ndcocc",
    templateUrl: "./drug-ndcocc.component.html"
})
export class DrugNDCOCCComponent implements OnInit, AfterViewInit, OnDestroy {
    drugId: any;
    NDCPOP: any;
    NDCFG: FormGroup;
    hasNDC = true;
    drugNDCOCC: any;
    pageNu = 1;
    Size = 15;
    actvHeader: string[];
    t1 = 0;
    t2 = 0;
    totalCount = 0;
    drugNDCOCCWJ: CollectionView;
    drugCode: any;
    @Input()
    set DrugId(data: any) {
        this.drugId = data;
    }
    @Input()
    set DrugCode(data: any) {
        this.drugCode = data;
    }
    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @ViewChild("NDCOOC", { static: true })
    NDCOOC: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.NDCPOP) { // esc
            this.closePopUp();
            event.preventDefault();
        }
    }


    constructor(
        private modalService: NgbModal,
        private _drg: EditDrugService,
        private _cdr: ChangeDetectorRef,
        private _fb: FormBuilder
    ) {
        this.NDCFG = this._fb.group({
            IsCompound: true
        });
    }

    ngOnInit() {
        this.getNDC(this.NDCOOC);
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
    }
    closePopUp() {
        this.NDCPOP.close();
        this.IsPopUpClosed.emit("close");
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    getNDC(content) {
        if (this.NDCPOP) {
            this.NDCPOP.close();
        }
        this.NDCPOP = this.modalService.open(content, {
            size: "lg",
            centered: true,
            keyboard: false
        });
        if (this.drugCode) {
            this._drg
                .getNDCOCC(
                    this.NDCFG.controls["IsCompound"].value,
                    this.drugCode,
                    this.pageNu,
                    this.Size
                )
                .subscribe(resp => {
                    if (resp && resp["length"] > 0) {
                        this.hasNDC = true;
                        this.drugNDCOCC = resp;
                        this.generateNDCWJ(resp);
                    } else {
                        this.hasNDC = false;
                    }
                });
        } else {

        this._drg
        .getNDCOCC(
            this.NDCFG.controls["IsCompound"].value,
            null,
            this.pageNu,
            this.Size
        )
        .subscribe(resp => {
            if (resp && resp["length"] > 0) {
                this.hasNDC = true;
                this.drugNDCOCC = resp;
                this.generateNDCWJ(resp);
            } else {
                this.hasNDC = false;
            }
        });
        }
    }
    setPage(page: number, content) {
        this.pageNu = page;
        this.getNDC(content);
    }
    setSize(size: any, content) {
        this.Size = size;
        this.pageNu = 1;
        this.getNDC(content);
    }
    generateNDCWJ(resp) {
        if (resp) {
            this.drugNDCOCCWJ = new CollectionView(
                resp.map((NDC, i) => {
                    if (NDC.DrugType === "P") {
                        this.t1 = NDC.TotalCount;
                    }
                    if (NDC.DrugType === "C") {
                        this.t2 = NDC.TotalCount;
                    }
                    this.totalCount = this.t1 + this.t2;
                    const j = {};
                    j["NDC"] = NDC.NDCCode;
                    j["Drug Name"] = NDC.DrugName;
                    j["Compound Name"] = NDC.CompoundName;
                    j["Drug Type"] = NDC.DrugType;
                    j["Compound NDC"] = NDC.CompoudNDC;
                    j["Ingredient"] = NDC.Ingredient;
                    j["Billing NDC"] = NDC.BillingNdc;
                    j["Generic NDC"] = NDC.GenericNdc;
                    j["Replacement NDC"] = NDC.ReplacementNdc;
                    return j;
                })
            );
            this.actvHeader = [
                "NDC",
                "Drug Name",
                "Compound Name",
                "Drug Type",
                "Compound NDC",
                "Ingredient",
                "Billing NDC",
                "Generic NDC",
                "Replacement NDC"
            ];
            this.t1 = 0;
            this.t2 = 0;
        }
    }
}
