export enum KeyCodes {
    Key_a = 65,
    Key_b = 66,
    Key_c = 67,
    Key_d = 68,
    Key_e = 69,
    Key_f = 70,
    Key_g = 71,
    Key_h = 72,
    Key_i = 73,
    Key_j = 74,
    Key_k = 75,
    Key_l = 76,
    Key_m = 77,
    Key_n = 78,
    Key_o = 79,
    Key_p = 80,
    Key_q = 81,
    Key_r = 82,
    Key_s = 83,
    Key_t = 84,
    Key_u = 85,
    Key_v = 86,
    Key_w = 87,
    Key_x = 88,
    Key_y = 89,
    Key_z = 90,
    a = 65,
    b = 66,
    c = 67,
    d = 68,
    e = 69,
    f = 70,
    g = 71,
    h = 72,
    i = 73,
    j = 74,
    k = 75,
    l = 76,
    m = 77,
    n = 78,
    o = 79,
    p = 80,
    q = 81,
    r = 82,
    s = 83,
    t = 84,
    u = 85,
    v = 86,
    w = 87,
    x = 88,
    y = 89,
    z = 90
}
