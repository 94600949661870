<ng-template #Refill let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!refDetails">Warning!!</h4>
        <span *ngIf="refDetails && !notPickedUp && warnCode">Refill Options</span>
    </div>
    <div class="modal-body" *ngIf="!refDetails">
        <p>Rx# {{selectedRx}} does not exits. Please enter another one.</p>
    </div>
    <div class="modal-body" *ngIf="!notPickedUp && warnCode">
        <div class="row">
            <div class="col-md-3 padding-0"></div>
            <div class=" padding-0" [ngClass]="(warnCode && warnCode === 10) ? 'col-md-8':'col-md-6'">
                <div class="alert alert-warning alert-dismissible " role="alert">
                    <h5 class="text-center" *ngIf="warnCode === 2"><strong>Warning!!</strong>
                        There is no Rx quantity remaining for this Rx.</h5>
                    <h5 class="text-center" *ngIf="warnCode === 1"><strong>Warning!!</strong>
                        More than {{daysCompletedSinceFilled}} days since ordered. MAY NOT BE PAID.</h5>
                    <h5 class="text-center" *ngIf="warnCode === 4"><strong>Warning!!</strong>
                        This Rx has been Transferred Out, cannot be refilled.</h5>
                    <h5 class="text-center" *ngIf="warnCode === 5">
                        <strong>Warning!!</strong> No more refills left for this Rx.</h5>
                    <h5 class="text-center" *ngIf="warnCode === 10">
                            <strong>Warning!!</strong> No more refills left for this Rx.  <div>
                                There is Rx quantity remaining. Please click on the Refill button to process partial fill.
                            </div></h5>
                    <h5 class="text-center" *ngIf="warnCode === 6"><strong>Warning!!</strong>
                         Rx Expired.</h5>
                    <h5 class="text-center" *ngIf="warnCode === 7"><strong>Warning!!</strong>
                         Refill Expired.
                        <span *ngIf="!(refDetails?.Drug.drugclass >= 2 && refDetails?.Drug.drugclass <= 5)">(Non-Control
                            Rx)</span>
                        <span *ngIf="refDetails?.Drug.drugclass >= 2 && refDetails?.Drug.drugclass <= 5">(Control Rx)</span>
                    </h5>
                    <h5 class="text-center" *ngIf="warnCode === 8">Hold Rx cannot be refilled. <br /> Please click on YES, if you wish to Unhold and proceed to refill.</h5>
                        <h5 class="text-center padding_top_2rem" *ngIf="prevFilledRx"> <strong>ATTENTION: </strong> There is a similar Deferred Rx for this patient <br/>
                             Rx#: {{prevFilledRx['PrescNum']}}  Date: {{prevFilledRx['RxReceiptDtTm'] | localTime | date: 'MM/dd/yyyy'}}</h5>
                </div>
            </div>
            <div class="col-md-3 padding-0"></div>
        </div>

        <div class="box box-default box-solid">
            <div class="box-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                        <label>Rx#</label>
                        <div class="label--data">{{selectedRx}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                        <label>Patient</label>
                        <div class="label--data">
                            {{refDetails?.Patient?.lastname}},&nbsp;{{  refDetails?.Patient?.firstname}}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--left__7">
                        <label>Drug</label>
                        <div class="label--data">{{refDetails?.Drug?.drugname}}
                            {{refDetails?.Drug?.strength}}
                            {{refDetails?.Drug?.drugformname}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                        <label>Date Ordered</label>
                        <div class="label--data">
                            {{refDetails?.PrescReFill?.OrderDtTm | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label>Ins</label>
                        <div class="label--data">{{rxrefDetails?.CarrierName}}</div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--left__7">
                        <label>Ord Qty</label>
                        <div class="label--data">
                            {{refDetails?.PrescReFill?.OrdQty | number : '1.3-3'}}</div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
                        <label>Days</label>
                        <div class="label--data">{{refDetails?.Prescription?.Days}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                        <label>Last Fill Date</label>
                        <div class="label--data">
                            {{refDetails?.PrescReFill?.FillDtTm | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <label>Auth Ref</label>
                        <div class="label--data">{{refDetails?.Prescription?.ReFillNum}}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 small_col-2">
                        <label>Last Refill Dispensed</label>
                        <div class="label--data">{{refDetails?.PrescReFill?.ReFillNum}}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 small_col-2">
                        <label>Last Fill Dispensed</label>
                        <div class="label--data">
                            {{_commonServ.checkIsPartialFill(refDetails?.PrescReFill, refDetails?.Prescription) ?
                                 refDetails?.PrescReFill?.PartialFillNo : "-"}}
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <label>Disp Qty</label>
                        <div class="label--data">
                            {{refDetails?.PrescReFill?.DispQty | number : '1.3-3'}}</div>
                    </div>
                    <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 px-0  padding--right__7" *ngIf="refDetails?.PrescReFill?.PartialFillNo || refDetails?.PrescReFill?.PartialIndStatus === 'P'">
                        <label>Remaining Qty</label>
                        <div class="label--data">
                            {{(!refDetails.PrescReFill.IsOverFillRx) ? (refDetails?.partialCummulativeDetails?.TotRemQty | number : '1.3-3') : rxrefDetails?.TotalRemainingQty}}</div>
                    </div> -->
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7" >
                        <label>Total Quantity Remaining</label>
                        <div class="label--data">
                            {{(refDetails?.isRxTransferred ? 0.00 :  rxrefDetails?.TotalRemainingQty) | number : '1.3-3'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="(SimilarRxs && SimilarRxs['dispensedHistory'] && SimilarRxs['dispensedHistory'].length > 0)">
                <div class="col-12 text-right">
                    <button class="submit print-med-guide mb-0 range_dark_1" (click)="toggleColorLegend()">Color
                        Legend</button>
                </div>
            <div class="col-12">
                <label>Rx with Equivalent drugs</label>
                <wj-flex-grid #inventorylist [headersVisibility]="'Column'" [itemsSource]="prevRxHist" [isReadOnly]="true"
                (itemsSourceChanged)="init(inventorylist)" [columnPicker]="'refillFlowComponent'" class="max-height_30rem" [selectionMode]="'None'" [itemFormatter]="itemFormatter">
                    <wj-flex-grid-column header="" [width]="50">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell" let-row="row">
                            <span class="text-center">
                                <input
                                class="form-check-input"
                                type="checkbox"
                                [id]="'prev'+item['PrescRefillId']"
                                name=""
                                [(ngModel)]="item.IsSelected"
                                (change)="
                                selectedPrevRef($event?.target?.checked, item)
                                "
                                [checked]="item.IsSelected"
                            />
                            <label class="form-check-label" [for]="'prev'+item['PrescRefillId']">
                            </label>
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                    *ngFor="let rxheader of wjHeaders; let i = index" [width]="rxheader['width']">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                {{ item[rxheader['hName']] }}
                        </ng-template>
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="!refDetails">
        <button class="hotkey_primary" appShortcutKey [InputKey]="'o'"
            (click)="cancelRefill(); c('Close click')"><b>o</b>Ok</button>
    </div>
    <div class="modal-footer" *ngIf="refDetails && !notPickedUp && warnCode && warnCode !== 8">
        <button class="hotkey_primary" appShortcutKey InputKey ="r" placement="top" ngbTooltip="Deferred Rx's Can be Filled, and non Deferred Rx's can be Refilled."
            (click)="checkHasPriv('Refill')" ngbAutofocus *ngIf="!disableRef"><b>R</b>Refill/Fill</button>
            <button class="inactive" disabled readonly *ngIf="disableRef" placement="top" triggers="mouseover:mouseleave" [ngbTooltip]="!TRxQtySelected ? 'Refill/Fill button enable when T.Rx Qty Rem is more than 0' : ''"><b>R</b>Refill/Fill</button>
            <button class="hotkey_primary" appShortcutKey InputKey ="n"
            (click)="checkHasPriv('New')" *ngIf="!disableRef && !(warnCode === 2 || warnCode === 3)"><b>N</b>Create New Rx</button>
            <button class="hotkey_primary" appShortcutKey InputKey ="n"
            (click)="checkHasPriv('New')" ngbAutofocus *ngIf="disableRef && !(warnCode === 3)"><b>N</b>Create New Rx</button>
            <button class="inactive" disabled readonly *ngIf="(warnCode === 3)"><b>N</b>Create New Rx</button>
            <button class="hotkey_primary" appShortcutKey InputKey ="f"
            (click)="fillSimilarFiled(); c('Close click')" *ngIf="prevFilledRx"><b>F</b>Fill Filed Rx</button>
            <button class="hotkey_primary" appShortcutKey InputKey ="q"
            (click)="openRefillReq('Send'); c('Close click')" *ngIf="warnCode === 5"><b>Q</b>Send E-Refill Request</button>
            <button class="hotkey_primary" appShortcutKey InputKey="p"
            (click)="openRefillReq('Print'); c('Close click')" *ngIf="warnCode === 5"><b>P</b>Print Refill Request</button>
        <button class="hotkey_primary" appShortcutKey InputKey="c"
            (click)="cancelRefill(); c('Close click')"><b>C</b>Cancel</button>
    </div>
    <div class="modal-footer" *ngIf="refDetails && !notPickedUp && warnCode && warnCode === 8">
        <button class="hotkey_primary" ngbAutofocus appShortcutKey InputKey ="y"
            (click)="unHoldRx();c('Close click')"><b>Y</b>YES</button>
        <button class="hotkey_primary" appShortcutKey InputKey ="n"
            (click)="cancelRefill(); c('Close click')"><b>N</b>NO</button>
    </div>
</ng-template>

<ng-template #RefillNew let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
    </div>
    <div class="modal-body" >
        <label class="align-label">Rx# </label>: {{selectedRx}}-{{refDetails?.PrescReFill?.ReFillNum}}<span *ngIf="_commonServ.checkIsPartialFill(refDetails?.PrescReFill, refDetails?.Prescription)">-{{refDetails?.PrescReFill?.PartialFillNo}}
        </span>
        <br>
        <label class="align-label"> Drug Name</label> :
        {{refDetails?.Drug?.drugname | uppercase}}
        {{refDetails?.Drug?.strength}}
        {{refDetails?.Drug?.drugformname}}
        <br>
            <label class="align-label"> Patient Name:</label>
            <span class="word-break col px-2">{{refDetails?.Patient?.lastname | uppercase}},&nbsp;{{refDetails?.Patient?.firstname | uppercase}}</span>
            <p class="mt-4"> The last refill of this Rx has not yet been picked-up by the patient. Do you wish to continue with this
                refill? </p>

    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey InputKey ="y"
        (keydown.enter)="checkRxIsFiled(); c('Close click')"
        (click)="checkRxIsFiled(); c('Close click')"><b>y</b>YES</button>
        <button class="hotkey_primary" appShortcutKey InputKey ="n"
        (keydown.enter)="cancelRefill(); c('Close click')"   (click)="cancelRefill(); c('Close click')"><b>n</b>NO</button>
    </div>
</ng-template>

<app-override-log *ngIf="openOverride" [PrivMask]="'AllowControlRxProcessing'" [OverrideEndpoint]="'POST_Rx'"
    [CatId]="'RefillRx'" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

<ng-template #FILE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="cancelRefill(); c('Close click');">
        <h4 header> Fill A Deferred Rx For: {{selectedRx}}-{{refDetails?.PrescReFill?.ReFillNum}}<span *ngIf="_commonServ.checkIsPartialFill(refDetails?.PrescReFill, refDetails?.Prescription)">-{{refDetails?.PrescReFill?.PartialFillNo}}
            </span> </h4>
        <span body class="overflow_unset">
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Fill date'" [PlaceHolder]="''"
                        [ControlName]="'dueDateTime'" [FormGroupName]="fileFG" [MaxDate]="" [IsRequired]="true"
                        [ErrorDefs]="{required: 'Required'}" [InputErrors]="fileFG.controls['dueDateTime']?.errors">
                    </eprx-date-picker>
                </div>
            </div>
            <br>
            <div>
                <eprx-radio [LabelText]="'Assign New'" [ControlName]="'createNewRefill'" [FormGroupName]="fileFG"
                [IsDisabled]="filedNew || !(refDetails?.PrescReFill?.ReFillNum === 0 && refDetails?.PrescReFill?.PartialFillNo === 1)"
                [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['assignNew1', 'assignNew2']">
                </eprx-radio>
            </div>
        </span>
        <button footer class="inactive" *ngIf="!fileFG?.valid">  <span>S</span> Save </button>
        <button footer class="hotkey_success" *ngIf="fileFG?.valid" appShortcutKey [AltKey]="'s'"
        (keydown.enter)="saveFileInfo(); c('Close click');" (click)="saveFileInfo(); c('Close click');">
            <span>S</span> Save </button>
        <button footer class="hotkey_success" (keydown.enter)="cancelRefill(); c('Close click');"
            (click)="cancelRefill(); c('Close click');" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #confirmParOrFull let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click');closeConfirmPartialFill()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <span>
        The Last Fill on this Rx was partially Filled. <br/>
        Would you like to complete the remaining partial fill or proceed with the next new refill?
        </span>
    </div>
    <div class="modal-footer">
        <button autofocus type="button" class="hotkey_primary" id="partialFill" (click)="c('Close click');closePartialFillPopup(false)" appShortcutKey InputKey="p"
         ><b>P</b> Last Partial Fill </button>
         <button autofocus type="button" class="hotkey_primary"  id="refillFill" (click)="c('Close click');closePartialFillPopup(true)" appShortcutKey InputKey="n"
         ><b>N</b> Next New Refill </button>
    </div>
</ng-template>

<app-refill-request *ngIf="activeModal" [rxInfo]="refDetails" [selectedRxs]="[refDetails]"
    [rxOriginList]="(systemData$ | async)?.RxOrigin" [refillHeader]="'Send/Print/Fax Refill Request'"
    (IsPopUpClosed)="openRefillReq(null)" [openDirectly]="activeModal"></app-refill-request>

<ng-template #IsDiscontinued let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span >Warning!!</span>
    </div>
    <div class="modal-body">
        <p>Rx is Discontinued. If you wish to continue the Rx will no more be marked as discontinued. Would you like to proceed?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
        (click)="setOrderStatus(); c('Close click')"><b>y</b>Yes</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
        (click)="cancelRefill(); c('Close click')"><b>n</b>No</button>
    </div>
</ng-template>
