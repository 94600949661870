
<ng-template #Refill let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!secondpop && !rxnotFound">Enter Rx# to Refill</h4>
        <h4 class="modal-title" *ngIf="rxnotFound">Warning!!</h4>
        <h4 class="modal-title" *ngIf="secondpop && !warn && !rxnotFound">Confirmation</h4>
        <h4 class="modal-title" *ngIf="warn">Refill Options</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal('Cancel')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="!secondpop && !rxnotFound">
        <eprx-input [RxSelectId]="'refillRx'" [LabelText]="'Enter Rx#'" [AutoFocus]="actvModal === 'first'" [PlaceHolder]="''" [ControlName]="'rxNum'"
        [FormGroupName]="rxNuFG" (TriggerOnEnterValue)="getRxNum()" (keydown.esc)="closeModal('Cancel')"></eprx-input>

    </div>
    <div class="modal-body" *ngIf="rxnotFound">
        <p>Rx# {{rxno}} does not exits</p>
        </div>
    <div class="modal-body" *ngIf="(secondpop && !warn) && !rxnotFound">
        <label class="align-label">Rx# </label>: {{rxno}}
        <br>
        <label class="align-label"> Drug Name</label> : {{rxDetails?.Refdetail?.Refilldetails[0]?.Drug?.drugname | uppercase}} {{rxDetails?.Refdetail?.Refilldetails[0]?.Drug?.strength}} {{rxDetails?.Refdetail?.Refilldetails[0]?.Drug?.drugformname}}
        <br>
        <label class="align-label"> Patient Name
            <span class="word-break col">: {{rxDetails?.Refdetail?.Refilldetails[0]?.Patient?.lastname | uppercase}}
                ,   {{  rxDetails?.Refdetail?.Refilldetails[0]?.Patient?.firstname | uppercase}}</span>
        </label>
        <p> The last refill of this Rx has not yet been picked-up by the patient. Do you wish to continue with this refill? </p>

    </div>
    <div class="modal-body" *ngIf="warn">
        <div class="row">
            <div class="col-md-3 padding-0"></div>
            <div class="col-md-6 padding-0">
                <div class="alert alert-warning alert-dismissible " role="alert">
                        <h5 class="text-center" *ngIf="warn === 3"><strong>WARNING</strong> This Rx is discontinued Rx, cannot be refilled.</h5>
                        <h5 class="text-center" *ngIf="warn === 2"><strong>WARNING</strong> {{rxDetails.FinalExpdate}} days early for refill</h5>
                        <h5 class="text-center" *ngIf="warn === 1"><strong>WARNING</strong> More than {{moredays}} days since ordered. MAY NOT BE PAID</h5>
                        <h5 class="text-center" *ngIf="warn === 4"><strong>WARNING</strong> This is Deferred Rx, cannot be refilled</h5>
                        <h5 class="text-center" *ngIf="warn === 5"><strong>WARNING</strong> This Rx has been transferred out, cannot be refilled.</h5>
                        <h5 class="text-center" *ngIf="warn === 6"><strong>WARNING</strong> There is a similar Deferred Rx for this Rx</h5>
                        <h5 class="text-center" *ngIf="warn === 7">
                            <strong>WARNING</strong> No more refills left for this Rx.
                            <br>
                            <span *ngIf="hasFileRx">
                                <strong>ATTENTION: </strong> There is a similar Deferred RX for this patient RX#: Date:
                            </span>                        </h5>
                        <h5 class="text-center" *ngIf="warn === 8"><strong>WARNING</strong> Rx Expired.</h5>
                        <h5 class="text-center" *ngIf="warn === 10"><strong>WARNING</strong> Refill Expired.
                            <span *ngIf="!(rxDetails?.Refdetail?.Refilldetails[0]?.Drug.drugclass >= 2 && rxDetails?.Refdetail?.Refilldetails[0]?.Drug.drugclass <= 5)">(Non-Control Rx)</span>
                            <span *ngIf="rxDetails?.Refdetail?.Refilldetails[0]?.Drug.drugclass >= 2 && rxDetails?.Refdetail?.Refilldetails[0]?.Drug.drugclass <= 5">(Control Rx)</span></h5>
                        <h5 class="text-center" *ngIf="warn === 9">Hold Rx cannot be refilled. <br/> Please click on OK, if you wish to Unhold and proceed to refill.</h5>
                </div>
            </div>
            <div class="col-md-3 padding-0"></div>
        </div>
        <div class="row" *ngIf="warn === 7">
            <div class="col-12">
                <wj-flex-grid class="height_10rem"
                    #inventorylist
                    [headersVisibility]="'Column'"
                    [itemsSource]="patHisWJ"
                    [isReadOnly]="true"
                    [columnPicker]="''"
                    [selectionMode]="'Row'"
                    (click)="gridClickHandler($event, inventorylist)"
                >
                    <wj-flex-grid-column
                        [header]="actvHeader"
                        [binding]="actvHeader"
                        [visible]="true"
                        *ngFor="let actvHeader of pdactvHeaders; let i = index"
                        [width]="'*'"
                        [format]="actvHeader === 'TotalAmount' ? 'c2' : ''"
                    >
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
        </div>

        <div class="box box-default box-solid">
            <div class="box-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                        <label>Rx#</label>
                        <div class="label--data">{{rxno}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                        <label>Patient</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.Patient?.lastname}},&nbsp;{{  rxDetails?.Refdetail?.Refilldetails[0]?.Patient?.firstname}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--left__7">
                        <label>Drug</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.Drug?.drugname}} {{rxDetails?.Refdetail?.Refilldetails[0]?.Drug?.strength}} {{rxDetails?.Refdetail?.Refilldetails[0]?.Drug?.drugformname}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                        <label>Date Ordered</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.PrescReFill?.OrderDtTm | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label>Ins</label>
                        <div class="label--data">{{rxDetails?.CarrierName}}</div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--left__7">
                        <label>Qty</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.Prescription?.Qty | number : '1.3-3'}}</div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
                        <label>Days</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.Prescription?.Days}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                        <label>Last Fill Date</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.PrescReFill?.FillDtTm | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label>Refs</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.Prescription?.ReFillNum}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--left__7">
                        <label>Last Refill Dispensed</label>
                        <div class="label--data">{{rxDetails?.Refdetail?.Refilldetails[0]?.PrescReFill?.ReFillNum}}</div>
                    </div>
                </div>
            </div>
            <div class="box box-default box-solid" *ngIf="warn === 6">
                <div class="box-body">
                    <wj-flex-grid #inventorylist [headersVisibility]="'Column'" [itemsSource]="filledWJ" [isReadOnly]="true"
                        [selectionMode]="'None'" (updatedView)="init(inventorylist)" *ngIf="warn === 6">
                        <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders  ; let i = index"
                            [width]="'*'">
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" ngbAutofocus *ngIf="warn === 9" (click)="unHoldRx()" (keydown.enter)="unHoldRx()" appShortcutKey InputKey="h"><b>H</b> UnHold </button>
                <button class="hotkey_primary" ngbAutofocus *ngIf="rxnotFound" (click)="goTofirstPopUp()"  appShortcutKey InputKey="o"><b>O</b> Ok</button>
                <button class="hotkey_primary" ngbAutofocus *ngIf="warn && !refilldis && !DisRefi && hasRefillRxPrivs" (keydown.enter)="cloneRxInfo(rxno)" (click)="cloneRxInfo(rxno)"  appShortcutKey InputKey="f"><b>F</b> Refill</button>
                <button class="hotkey_primary" ngbAutofocus *ngIf="warn && !refilldis && !DisRefi && !hasRefillRxPrivs" (keydown.enter)="openOveride('Rx', 'RefillRx')" (click)="openOveride('Rx', 'RefillRx')"  appShortcutKey InputKey="f"><b>F</b> Refill</button>
                <button class="hotkey_primary color_grey"  *ngIf="DisRefi" title="Rx cannot be Refilled">Refill</button>
                <button class="hotkey_primary" ngbAutofocus *ngIf="warn === 7 && isFiledRxSelected" (click)="filleRx()" (keydown.enter)="filleRx()"  appShortcutKey InputKey="r"><b>R</b> Fill Deferred Rx </button>
                <button class="inactive" ngbAutofocus *ngIf="warn === 7 && !isFiledRxSelected" (click)="filleRx()" (keydown.enter)="filleRx()"  appShortcutKey InputKey="r"><b>R</b> Fill Deferred Rx </button>
                <button class="hotkey_primary" ngbAutofocus *ngIf="warn === 7" (click)="sendRefillRequest()" (keydown.enter)="sendRefillRequest()"  appShortcutKey InputKey="r"><b>R</b> Refill Request </button>
                <button class="hotkey_primary" ngbAutofocus *ngIf="warn && !createdis && hasCreateRxPrivs" (click)="cloneNewRxInfo(rxno)" (keydown.enter)="cloneNewRxInfo(rxno)"  appShortcutKey InputKey="p"><b>P</b> Create New Rx</button>
                <button class="hotkey_primary"  title="User don't have privileges to access this feature." (keydown.enter)="openOveride('RefillRx', 'CreateNew')" (click)="openOveride('RefillRx', 'CreateNew')"
                *ngIf="warn && !createdis && !hasCreateRxPrivs"  appShortcutKey InputKey="p"
                > <b>P</b> Create New Rx</button>
                <button class="hotkey_primary" (keydown.enter)="closeModal('Cancel')" (click)="closeModal('Cancel')" *ngIf="warn"  appShortcutKey InputKey="c"><b>C</b> Cancel</button>
                <button class="hotkey_success" ngbAutofocus (click)="getRxNum()" *ngIf="!secondpop && !rxnotFound"  appShortcutKey [AltKey]="'o'"><span>O</span> Ok</button>
                <button class="hotkey_primary" ngbAutofocus (click)="warningpopup()" *ngIf="secondpop && !warn"  appShortcutKey InputKey="o"><b>O</b> OK</button>
                <button class="hotkey_primary" (keydown.enter)="goTofirstPopUp()" (click)="goTofirstPopUp()"  *ngIf="secondpop && !warn"  appShortcutKey InputKey="c"><b>C</b>  Cancel</button>
                <button class="hotkey_success" (click)="closeModal('Cancel')" (keydown.enter)="closeModal('Cancel')" *ngIf="!secondpop && !warn && !rxnotFound"  appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #FILE let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="c('Close click');closeModal('Cancel')">
            <h4 header> Fill A filed Rx </h4>
            <span body class="overflow_unset">
                <div class="row">
                    <div class="col padding--right__7">
                        <eprx-date-picker [AutoFocus]="true" [LabelText]="'Fill date'" [PlaceHolder]="''" [ControlName]="'dueDateTime'"
                            [FormGroupName]="fileFG" [MaxDate]="" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                            [InputErrors]="fileFG.controls['dueDateTime']?.errors">
                        </eprx-date-picker>
                    </div>
                </div>
                <div *ngIf="rxDetails.Refdetail.Refilldetails[0].PrescReFill.ReFillNum === 0">
                    Assign New
                    <form class="form-group custom--input was-validated">
                        <span class="inline-flex">
                            <div class="custom-control custom-radio">
                                <input type="radio"
                                class="custom-control-input"
                                id="s1"
                                name="newOrOld"
                                (change)="createNewFiledRx(true)"
                                >
                                <label class="custom-control-label" for="s1">
                                        OK
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio"
                                class="custom-control-input"
                                id="n1"
                                name="newOrOld"
                                (change)="createNewFiledRx(false)"
                                checked
                                >
                                <label class="custom-control-label" for="n1">
                                        CANCEL
                                </label>
                            </div>
                        </span>
                    </form>
                </div>
            </span>
            <button footer class="inactive" *ngIf="!fileFG?.valid"> <span>S</span> Save </button>
            <button footer class="hotkey_success" (keydown.enter)="saveFileToInfo()"
            (click)="saveFileToInfo()" *ngIf="fileFG?.valid" appShortcutKey [AltKey]="'s'">
                <span>S</span> Save </button>
            <button footer class="hotkey_success"  (keydown.enter)="c('Close click'); closeModal('Cancel')"
            (click)="c('Close click'); closeModal('Cancel')" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel </button>
        </app-custom-modal>
    </ng-template>

<app-override-log *ngIf="openOverride" [PrivMask]="privType" [OverrideEndpoint]="overrideEndpoint"
[CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>
