<!-- <div class="eprx--block__content" style="display:none;">
    <canvas id="myCanvas2" class="canvas2" width="1000px" height="600px"></canvas>
</div> -->

<!-- <ng-template #ImageEditTempp let-c="close" let-d="dismiss" class="el--popup"> -->
  <div class="row">
    <div class="col-1"  *ngIf="(IsFromDocumentQueue && showEditImage) || enableEdit">
        <ul class="p-0">
            <li class="submit btn-sm-width1"    (click)="modeSelected('pointer')" [ngbTooltip]="'Pointer'">
                <i class="far fa-mouse-pointer"></i>
            </li>
            <li class="submit btn-sm-width1"    (click)="modeSelected('pencil')" [ngbTooltip]="'Pencil'">
                <i class="far fa-pencil"></i>
            </li>
            <li class="submit btn-sm-width1"   (click)="modeSelected('brush')" [ngbTooltip]="'Brush'">
                <i class="far fa-paint-brush-alt"></i>
            </li>
            <li class="submit btn-sm-width1"   (click)="modeSelected('text')" [ngbTooltip]="'Text'">
                <i class="far fa-text"></i>
            </li>
            <li class="submit btn-sm-width1"  (click)="modeSelected('crop')" [ngbTooltip]="'Crop'">
                <i class="far fa-crop">
                </i>
            </li>
            <li class="submit btn-sm-width1"   (click)="modeSelected('zoomin')" [ngbTooltip]="'Zoom In'">
                <i class="far fa-search-plus"></i>
            </li>
            <li class="submit btn-sm-width1"   (click)="modeSelected('zoomout')" [ngbTooltip]="'Zoom Out'">
                <i class="far fa-search-minus"></i>
            </li>
            <li class="submit btn-sm-width1"   (click)="insertShape('line')" [ngbTooltip]="'Line'">
                <i class="far fa-horizontal-rule"></i>
            </li>
            <li class="submit btn-sm-width1" (click)="insertShape('circle')" [ngbTooltip]="'Circle'">
                <i class="far fa-circle"></i>
            </li>
            <li class="submit btn-sm-width1"   (click)="insertShape('rect')" [ngbTooltip]="'Rectangle'">
                <i class="far fa-rectangle-landscape"></i>
            </li>
            <li class="submit btn-sm-width1"   (click)="modeSelected('rotateRight')" [ngbTooltip]="'Rotate Image'">
                <i class="far fa-redo"></i>
            </li>
            <li class="submit btn-sm-width1"   (click)="modeSelected('undo')" [ngbTooltip]="'Undo'">
                <i class="far fa-undo"></i>
            </li>
            <li class="pl-2" [ngbTooltip]="'Color'">
                <input class="form-control width_3_2rem" [(colorPicker)]="color"
                 [style.background]="color" [ngClass]="IsFromRx ? 'margin_left_53' : 'margin_left_2'"
                (colorPickerChange)="colorChange($event)" title="Font Color" [appAutofocus]="false"/>
            </li>
        </ul>
    </div>
    <head></head>
    <!--<div  class="col-12" *ngIf="cropMode">
        <ul class="pull-right eprx--header__icons" *ngIf="(!cropMode)">
            <li>
                <a (click)="saveImage()">
                    <button class="hotkey_success--Override hotkey_primary--Override">SAVE</button>
                </a>
            </li>
            <li>
                <a (click)="closeModal()">
                    <button class="hotkey_success--Override hotkey_primary--Override">CANCEL</button>
                </a>
            </li>
        </ul>
    </div>-->
    <div  class="col-11" *ngIf="cropMode">
        <app-image-editor-crop (IsRotateDegrees)="IsRotateDegreesForSet($event)"  [InputImage]="cropInputImage" (CloseImageCropEditor)="closeCrop($event)" [IsCropMode]="cropMode"></app-image-editor-crop>
    </div>
    <div class="col-11 overflow_x_scroll height_82vh" [hidden]="cropMode" id="canvasDf"  >
        <canvas  id="canvasArea" width="908px"  class="canvasArea"></canvas>
    </div>
    <div class="col-12 text-right">
        <button footer  class="hotkey_success"  appShortcutKey  [AltKey]="'u'" (click)="saveImage()">
            <span>U</span> update
        </button>
        <button type="button" *ngIf="!enableEdit" [title]="IsPdf?'PDF File cannot be edited.':''" class="" [ngClass]="IsPdf?'inactive':'hotkey_success'" (click)="editImage()"  appShortcutKey
        [AltKey]="'e'"><span>E</span>Edit Image</button>
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey  [AltKey]="'c'">
           <span>C</span> Cancel
        </button>
    </div>
  </div>
    <app-custom-modal (oncrossBtnClick)="closeModal()" *ngIf="!IsFromDocumentQueue && !enableEdit">
        <h4 header>Edit Image</h4>
        <span body>
            <div class="row">
                <div class="col-12" >
                    <div class="exprx--common-block erx--body insurance--body">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Label Printing Options</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                        </div>
                    </div>
                </div>
                <div class="col-2" >
                    <div class="exprx--common-block scanner--options">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Tools</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content scanner--options--body">
                            <div class="row" >
                                <div class="col-6">
                                    <button class="hotkey_primary" (click)="modeSelected('pointer')" [ngbTooltip]="'Pointer'">
                                        <i class="far fa-mouse-pointer"></i>
                                    </button>
                                    <button class="hotkey_primary" (click)="modeSelected('pencil')" [ngbTooltip]="'Pencil'">
                                        <i class="far fa-pencil"></i>
                                    </button>
                                    <button class="hotkey_primary" (click)="modeSelected('text')" [ngbTooltip]="'Text'">
                                        <i class="far fa-text"></i>
                                    </button>
                                    <!-- <button class="hotkey_primary" (click)="modeSelected('rotateRight')" >
                                        <i class="far fa-undo"></i>
                                    </button> -->
                                    <button class="hotkey_primary" (click)="modeSelected('zoomin')" [ngbTooltip]="'Zoom In'">
                                        <i class="far fa-search-plus"></i>
                                    </button>
                                </div>
                                <div class="col-6 padding-0">
                                    <button class="hotkey_primary" (click)="modeSelected('crop')" [ngbTooltip]="'Crop'">
                                        <i class="far fa-crop"></i>
                                    </button>
                                    <button class="hotkey_primary" (click)="modeSelected('brush')" [ngbTooltip]="'Brush'">
                                        <i class="far fa-paint-brush-alt"></i>
                                    </button>
                                    <button class="hotkey_primary" (click)="modeSelected('shapes')" [ngbTooltip]="'Shapes'">
                                        <i class="far fa-shapes"></i>
                                    </button>
                                    <!-- <button class="hotkey_primary" (click)="modeSelected('rotateLeft')" >
                                        <i class="far fa-redo"></i>
                                    </button> -->
                                    <button class="hotkey_primary" (click)="modeSelected('zoomout')" [ngbTooltip]="'Zoom Out'">
                                        <i class="far fa-search-minus"></i>
                                    </button>
                                </div>
                                <div class="col-12">
                                    <input class="form-control" [(colorPicker)]="color" [style.background]="color"  (colorPickerChange)="colorChange($event)" title="Font Color" />
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
                <div class="col-10" *ngIf="cropMode">
                    <app-image-editor-crop [InputImage]="cropInputImage" (CloseImageCropEditor)="closeCrop($event)"></app-image-editor-crop>
                </div>
                <div *ngIf="!IsFromDocumentQueue"  class="col-10" [hidden]="cropMode">
                    <div class="exprx--common-block erx--body insurance--body">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Toolbar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content scanner--options">
                            <div class="row scanner--options--body" *ngIf="currentMode === 'shapes'">
                                <div class="col-6">
                                    <button class="hotkey_primary" (click)="insertShape('line')" [ngbTooltip]="'Line'">
                                        <i class="far fa-horizontal-rule"></i>
                                    </button>
                                    <button class="hotkey_primary" (click)="insertShape('circle')" [ngbTooltip]="'Circle'">
                                        <i class="far fa-circle"></i>
                                    </button>
                                    <button class="hotkey_primary" (click)="insertShape('rect')" [ngbTooltip]="'Rectangle'">
                                        <i class="far fa-rectangle-landscape"></i>
                                    </button>
                                    <button class="hotkey_primary" (click)="insertShape('triangle')" [ngbTooltip]="'Triangle'">
                                        <i class="far fa-triangle"></i>
                                    </button>
                                </div>
                                <div class="col-2">
                                    <button class="hotkey_primary" [(colorPicker)]="shapesFillColor"   (colorPickerChange)="fillColor($event)" [ngbTooltip]="'Color'">
                                        <i class="far fa-fill-drip" [style.color]="shapesFillColor"></i>
                                    </button>
                                </div>
                                <div class="col-3">
                                    <label>Opacity</label>
                                    <input type="range" id="opacity" min="0.1" max="1" step="0.1" value="1" (change)="changeOpacity($event?.target?.value)">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-canvas">
                        <canvas  id="canvasArea" class="canvasArea" width="971px" height="auto"></canvas>
                    </div >
                </div>
                <div class="container-canvas" *ngIf="IsFromDocumentQueue"  >
                    <canvas  id="canvasArea" class="canvasArea" width="971px" height="auto"></canvas>
                </div >
                <!-- <div class="col-10" [hidden]="!cropMode">
                    <div class="exprx--common-block erx--body insurance--body">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Toolbar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button  *ngIf="currentMode==='crop'" class="hotkey_primary" (click)="cropFromCropMode()">
                                        Crop
                                    </button>
                                    <button class="hotkey_primary" (click)="saveFromCropMode()">
                                        Save
                                    </button>
                                    <button class="hotkey_primary" (click)="cancelFromCropMode()">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <angular-cropper class="col-12" #angularCropper [cropperOptions]="config" [imageUrl]="inputImage"></angular-cropper>
                    </div>
                    <div class="row">
                        <img class="col-12" [src]="croppedImage">
                    </div>
                </div> -->
            </div>
        </span>

        <button footer ngbAutofocus class="hotkey_success"  appShortcutKey  [AltKey]="'s'" (click)="saveImage()">
            <span>S</span> Save
        </button>
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey  [AltKey]="'c'">
           <span>C</span> Cancel
        </button>
    </app-custom-modal>
<!-- </ng-template> -->

