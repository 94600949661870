import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AlertService, CommonService, UserService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AccCategoryE, RefillRxE, RxModel, RxOrigin } from "src/app/models";
import { PrintService } from "src/app/services/print.service";
import { PrintErxService } from "src/app/services/print-erx.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PrivMaskGuard } from "src/app/guards";
import { RegEx } from "src/app/app.regex";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { WijimoUtil } from "src/app/utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-print-rx-form",
    templateUrl: "./print-rx-form.component.html"
})
export class PrintRxFormComponent implements OnInit {

    @Input()
    set ModalType(data: boolean) {
        if (data) {
            this.isRefReqForm = true;
        } else {
            this.isRefReqForm = false;
        }
    }

    @Input() frmSendRefReqScreenToPrint = false;

    @Input()
    set RxInfo(data: any) {
        if (data) {
            this.selectedRx = data;
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @ViewChild("PrintRxForm", { static: true })
    PrintRxForm: any;

    @ViewChild("addToQueu", { static: true })
    addToQueu: any;

    @ViewChild("ALREADYVERIFIED", { static: true })
    ALREADYVERIFIED: any;
    @ViewChild("DAWVALID", { static: true })
    DAWVALID: any;
    @ViewChild("SPINOMODAL", { static: true })
    SPINOMODAL: any;
    @ViewChild("ADDSPI", { static: true })
    ADDSPI: any;
    @ViewChild("MISSINGFIELDS", { static: true })
    MISSINGFIELDS: any;

    prepareBatchedRxsWj: CollectionView;
    faxRecords: any;
    modalRef: any;
    selectedRx: RxModel[] = [];
    isRefReqForm: boolean;
    activeModal: any;
    printRefWJ: CollectionView;
    rxOriginList: RxOrigin[];
    processingErxReq: any;
    selectedRxForReq: RxModel;
    currentQueConfrm: RxModel;
    addToQList: RxModel[] = [];
    modalRef1: NgbModalRef;
    dropdownData: any;
    drugMissingForm: FormGroup;
    rxInfo: RxModel;
    AlreadyVerPopUp: any;
    missingPopUP: boolean;
    showDrugDescription: boolean;
    formSubmitted: boolean;
    drugIngredientsList: any;
    spiNumber: any;
    processingMissingDrug: any;
    checkSPIModal: boolean;
    checkDawModal: boolean;
    accessPrivCate: typeof AccCategoryE;
    refillRxPrivs: typeof RefillRxE;
    categoryId: any;
    privType: any;
    openOverride: boolean;
    overrideFor: any;
    overrideEndpoint: any;
    regex = RegEx;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    refWijmo: wjcGrid.FlexGrid;
    wjHeadersForprepareBatchedRxs: { hName: string; isVisible: boolean; width: number; }[];
    batchedRxssWijmo: wjcGrid.FlexGrid;
    unsubscribe$: Subject<void> = new Subject();

    constructor(
        private _userService: UserService,
        private _modalService: NgbModal,
        private _cmnServ: CommonService,
        private _printser: PrintService,
        private _erxServ: PrintErxService,
        private _fb: FormBuilder,
        private _alertSvc: AlertService,
        private _privMaskGuard: PrivMaskGuard,
        private _formUtils: ValidateFormFieldsUtils,
        private _wijimoUtils: WijimoUtil,

    ) { }

    ngOnInit() {
        if (!this.frmSendRefReqScreenToPrint) {
            const payload={
                flag: 0,
                isFromRefReq : true,
                patientids : this.selectedRx.map(rx => rx.Patient.patientid)
            }
            this._userService.getBatchedRxs(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.faxRecords = resp;
                if (resp && resp.length > 0) {
                    this.prepareBatchedRxs(resp);
                }
            });
            this.modalRef = this._modalService.open(this.PrintRxForm, {
                size: "lg",
                backdrop: false,
                keyboard: false,
                centered: true
            });
            this._cmnServ.systemData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.rxOriginList = resp.RxOrigin;
                    this.generateWijmo();
                }
            });
        } else if (this.frmSendRefReqScreenToPrint) {
            this.printRefReq();
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "printRefReqWJ" || w["WijmoKey"] === "printbatchedRxsRefReqWJ")) {
                this.patchDefaultValHeaders();
            }
        });
    }

    printRefReq() {
        const multipage = this._cmnServ.getSetttingValue("FaxAndReportsSettings", "Refill_Request_Fax_Print_Format") === "1" ? 2 : 1;
        const rxinfData = this.selectedRx.map(elm => {
            return {
                PrescNum: elm.Prescription.PrescNum,
                DrugId: elm.Drug.id,
                PrescriberId: elm.Prescriber.prescriberid,
                DrugClass: elm.Drug.drugclass,
                RefillNum: elm.PrescReFill.ReFillNum,
                IsControlRx: elm.Drug.drugclass >= 2 && elm.Drug.drugclass <= 5 ? true : false
            };
        });
        const payload = {
            Faxes: rxinfData,
            IncludeCoverPage: false,
            FaxFormat: multipage,
            IsBatchFax: true,
            PatientId: this.selectedRx[0].Prescription.PatientId
        };
        this._userService.printLocally(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.printReport(resp);
            }
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    openRefillQueuConfirm() {
        if (this.addToQList && this.addToQList.length) {
            this.currentQueConfrm = this.addToQList.shift();
            this._modalService.open(this.addToQueu, { keyboard: false, backdrop: "static" , windowClass: "large--content", centered: true});
        } else {
            this.closeModal();
        }
    }


    addToRefillQue() {
        // let payload = this.selectedRx.map(elm => {
        //     return {
        //         RefillNo: elm.PrescReFill.ReFillNum,
        //         RxNo:elm.Prescription.PrescNum
        //     }
        // })
        const payload = {
            RefillNo: this.currentQueConfrm.PrescReFill.ReFillNum,
            RxNo: this.currentQueConfrm.Prescription.PrescNum,
            PrescRefillId: this.currentQueConfrm.PrescReFill.Id
        };
        this._userService.followUpLog([payload])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
            this.openRefillQueuConfirm();
        });
    }

    printRxForm() {
        const payloadList = this.selectedRx.map(elm => ({ "PrescNums": [null], "IsRecall": false, "PrescRefillId": elm.PrescReFill.Id }));
        this._userService.printRxForm(payloadList)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.printReport(resp);
            }
        });
    }

    createMissingFG() {
        this.drugMissingForm = this._fb.group({
            DoseFrmDescId: [],
            DoseFrmCodeId: [],
            DrugStrQualId: [],
            PotencyUnitCodeId: [],
            QtyQualId: [],
            DrugId: [],
            DrugName: []
        });
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.checkIsAreadyVerified();
        } else {
           // this.resetActiveModal();
        }
    }

    openModal(modalType) {
        if (modalType === "sendEfill") {
            this.accessPrivCate = AccCategoryE;
            this.refillRxPrivs = RefillRxE;
            this.createMissingFG();
            this.processingErxReq = 0;
            this.processSendRefills();
        } else {
            this.activeModal = modalType;
        }
    }

    async saveDrugFields() {
        await this._formUtils.blurAndDelay();
        this.formSubmitted = true;
        if (!this.drugMissingForm.valid) {
            return;
        }
        this.closeModal1();
        const postData = this.drugMissingForm.value;
        postData["IsUnitDrug"] = false;
        postData["IsMedGuide"] = false;
        this._erxServ.saveMissingFields(postData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.showDrugDescription = false;
                this.formSubmitted = false;
                if (this.processingMissingDrug) {
                    this.checkEachCompMissingFields();
                } else {
                    this.checkCompoundDrugMissingFields();
                }
            }
        });
    }

    processSendRefills() {
        this.rxInfo = null;
        this.rxInfo = this.selectedRx[this.processingErxReq];
        this.checkHasPrivsForErxReq();
        // this.CheckIsAreadyVerified();
    }

    checkHasPrivsForErxReq() {
        if (!this.selectedRx || !this.selectedRx.length || !this.rxInfo) {
            return;
        }
        if (
            this.checkHasPrivsToAccess(
                this.accessPrivCate.RefillRx,
                this.refillRxPrivs.SendERefillRequest
            )
        ) {
            if (this.rxInfo["Drug"]["drugclass"] >= 2 && this.rxInfo["Drug"]["drugclass"] <= 5) {
                if (
                    this.checkHasPrivsToAccess(
                        this.accessPrivCate.RefillRx,
                        this.refillRxPrivs.SendERefillRequestForControls
                    )
                ) {
                    this.checkIsAreadyVerified();
                } else {
                    this.openOveride(
                        "RefillRx",
                        "SendERefillRequestForControls"
                    );
                }
            } else {
                this.checkIsAreadyVerified();
            }
        } else {
            this.openOveride(
                "RefillRx",
                "SendERefillRequest",
                "POST_ERX_XMLCreation"
            );
        }
    }

    checkHasPrivsToAccess(category: any, privType: any) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    checkIsAreadyVerified() { // check all validations from here..
        this._erxServ
            .checkErxRequest(this.rxInfo.Prescription.Id) // Already verified.
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.AlreadyVerPopUp = true;
                    this.openPopUps(this.ALREADYVERIFIED);
                } else {
                    this.chekcCompundDrug();
                }
            });
    }

    chekcCompundDrug() {
        this.closeModal1();
        if (
            this._cmnServ.getSetttingValue(
                "ERxSettings",
                "E-PRESCRIPTION_SCRIPT_VERSION"
            ) === "1"
        ) {
            this._erxServ
                .chekcCompundDrug(this.rxInfo.Drug.id)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._alertSvc.error(
                            "eRx refill request for Compound drugs can not be sent electronically."
                        );
                    } else {
                        this.checkPrescriber();
                    }
                });
        } else {
            this.checkPrescriber();
        }
    }

    checkDaw() {
        this._erxServ.checkDaw(this.rxInfo.PrescReFill.Id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.checkPrescriber();
            } else {
                this.checkDawModal = true;
                this.openPopUps(this.DAWVALID);
            }
        });
    }

    getDropdownsFormissing() {
        this._erxServ.getErxDropdowns()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.dropdownData = resp;
            }
        });
    }

    patchMissingFields(patchValues) {
        this.drugMissingForm.reset();
        this.drugMissingForm.controls.DoseFrmCodeId.clearValidators();
        this.drugMissingForm.patchValue(patchValues);
    }

    checkDrugValues() {
        this._erxServ.checkDrugValues(this.rxInfo.Drug.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.missingPopUP = true;
                this.getDropdownsFormissing();
                this.patchMissingFields(resp);
                if (this.rxInfo.Drug.drugtypeid === 2) {
                    this.showDrugDescription = true;
                }
                this.formSubmitted = false;
                if (this.drugMissingForm && (!this.drugMissingForm.value.PotencyUnitCodeId ||
                    (this.rxInfo.Drug.drugtypeid === 2 && !this.drugMissingForm.value.DoseFrmCodeId))) {
                    this.openPopUps(this.MISSINGFIELDS, "drugMissingFields");
                } else if (this.rxInfo.Drug.drugtypeid === 2) {
                    this.checkCompoundDrugMissingFields();
                } else {
                    this.openSendRefillReqPopUp();
                }
            }
        });
    }

    checkCompoundDrugMissingFields() {
        if (this.rxInfo.Drug.drugtypeid === 2) {
            this._erxServ
                .checkCompoundDrugValues(this.rxInfo.Drug.id, this.rxInfo.PrescReFill.Id)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(response => {
                    if (response) {
                        if (!this.dropdownData) {
                            this.getDropdownsFormissing();
                        }
                        this.showDrugDescription = false;
                        this.drugIngredientsList = response;
                        this.checkEachCompMissingFields();
                    } else {
                        this.openSendRefillReqPopUp();
                    }
                });
        } else {
            this.openSendRefillReqPopUp();
        }
    }


    changeSpiNumber(value: any) {
        this.spiNumber = value;
        this.rxInfo.Prescriber.prescriberspinum = value;
    }

    saveSPI() {
        const dataToPost = {
            prescriberId: this.rxInfo.Prescriber.prescriberid,
            SpiNumber: this.spiNumber,
            PrescNum: this.rxInfo.Prescription.PrescNum,
            RefillNum: this.rxInfo.PrescReFill.ReFillNum
        };
        this._erxServ.saveSPINumber(dataToPost)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.checkDrugValues();
            }
        });
    }

    checkEachCompMissingFields() { // recursive function to check missing values for each ingredients.
        if (this.drugIngredientsList.length > 0) {
            this.processingMissingDrug = this.drugIngredientsList.shift();
            this.patchMissingFields(this.processingMissingDrug);
            this.formSubmitted = false;
            if (this.drugMissingForm && !this.drugMissingForm.value.PotencyUnitCodeId) {
                this.openPopUps(this.MISSINGFIELDS, "drugMissingFields");
            } else {
                this.checkEachCompMissingFields();
            }
        } else {
            this.openSendRefillReqPopUp();
        }
    }

    checkPrescriber() {
        this._erxServ
            .checkPrescriber(this.rxInfo.Prescriber.prescriberid)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.checkDrugValues();
                } else {
                    this.checkSPIModal = true;
                    this.openPopUps(this.SPINOMODAL);
                }
            });
    }

    openPopUps(content, type?:any) {
        this.closeModal1();
        this.modalRef1 = this._modalService.open(content, {
            backdrop: false,
            keyboard: false,
            windowClass: type ? "" : "large--content", centered: true
        });
    }

    closeModal1(next?) {
        if (this.modalRef1) {
            this.modalRef1.close();
        }
        this.modalRef1 = null;
        if (next) {
            this.closeSendRefillReq();
        }
    }

    openSendRefillReqPopUp() {
        this.activeModal = "sendEfill";
    }

    closeSendRefillReq() {
        this.processingErxReq++;
        this.activeModal = null;
        if (this.processingErxReq < this.selectedRx.length) {
            setTimeout(() => {
                this.processSendRefills();
            }, 1000);
        } else {
            this.closeModal();
        }
    }

    printReport(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = () => {
            const base64data = reader.result;
            this._printser.printPdf(base64data);
            if (this.isRefReqForm) {
                if (this.modalRef) {
                    this.modalRef.close();
                    this.modalRef = null;
                }
                this.addToQList = [...this.selectedRx];
                this.openRefillQueuConfirm();
            } else {
                this.closeModal();
            }
        };
    }

    generateWijmo() {
        this.printRefWJ = new CollectionView(
            this.selectedRx.map((patRx, i) => {
                const k = {};
                k["RowId"] = i;
                k["PatientName"] = patRx.Patient.lastname + ", " + patRx.Patient.firstname;
                k["PrescriberName"] = patRx.Prescriber.prescriberlastname + ", " + patRx.Prescriber.prescriberfirstname;
                k["PrescPhone"] = patRx.Prescriber.faxnumber.length === 10 ? "1" + patRx.Prescriber.faxnumber : patRx.Prescriber.faxnumber;
                k["RxNo"] = patRx.Prescription.PrescNum.toString();
                k["RxOrigin"] = this.getRxOrgNameById(patRx.PrescReFill.RxOriginId);
                k["DrugId"] = patRx.PrescReFill.DrugId;
                k["IsControlRx"] = patRx.Drug.drugclass>=2 ? true :false;
                k["FROM NAME"] = patRx.Patient.lastname ? patRx.Patient.lastname + ", " + patRx.Patient.firstname : "";
                k["From Name"] = patRx.Patient.lastname ? patRx.Patient.lastname + ", " + patRx.Patient.firstname : "";
                k["TO NAME"] =  patRx.Prescriber.prescriberlastname ? patRx.Prescriber.prescriberlastname + ", " +
                patRx.Prescriber.prescriberfirstname : "";
                k["To Name"] =  patRx.Prescriber.prescriberlastname ? patRx.Prescriber.prescriberlastname + ", " +
                patRx.Prescriber.prescriberfirstname : "";
                k["TO FAX NO"] = patRx.Prescriber.faxnumber.length === 10 ? "1" + patRx.Prescriber.faxnumber : patRx.Prescriber.faxnumber;
                k["To Fax No"] =  k["To Fax#"] = patRx.Prescriber.faxnumber.length === 10 ? "1" + patRx.Prescriber.faxnumber : patRx.Prescriber.faxnumber;
                k["Rx#"] = patRx.Prescription.PrescNum ? patRx.Prescription.PrescNum.toString() : "";
                k["Ref#"] = ((patRx.PrescReFill.ReFillNum) || (patRx.PrescReFill.ReFillNum === 0)) ? patRx.PrescReFill.ReFillNum : "";
                k["Fill#"] = this._cmnServ.checkIsPartialFill(patRx.PrescReFill, patRx.Prescription) ? patRx.PrescReFill.PartialFillNo : " ";
                k["RX ORIGIN"] = this.getRxOrgNameById(patRx.PrescReFill.RxOriginId);
                k["Rx Origin"] = this.getRxOrgNameById(patRx.PrescReFill.RxOriginId);
                k["drugClass"] = patRx.Drug.drugclass ? patRx.Drug.drugclass : "";
                k["PrescriberId"] = patRx.Prescriber.prescriberid ? patRx.Prescriber.prescriberid : "";
                k["FaxSubject"] = "Refill Request";
                k["Notes"] = "";
                // k["DrugClassID"] = patRx.Dru.drugclass;
                k["PrescRefillId"] = patRx.PrescReFill.Id
                return k;
            })
        );
    }

    getRxOrgNameById(id: number): string {
        let name = "";
        this.rxOriginList.forEach(element => {
            if (element.Id === id) {
                name = element.Name;
            }
        });
        return name;
    }

    prepareBatchedRxs(batchedRxs) {
        this.prepareBatchedRxsWj = new CollectionView(
            batchedRxs.map((patRx, i) => {
                const k = {};
                k["Id"] = patRx.Id;
                k["FROM NAME"] = patRx.FromName ? patRx.FromName : "";
                k["From Name"] = patRx.FromName ? patRx.FromName : "";
                k["TO NAME"] = patRx.ToName ? patRx.ToName : "";
                k["To Name"] = patRx.ToName ? patRx.ToName : "";
                k["TO FAX NO"] = patRx.ToFaxNo ? patRx.ToFaxNo : "";
                k["To Fax No"] = k["To Fax#"] = patRx.ToFaxNo ? patRx.ToFaxNo : "";
                k["Rx#"] = patRx.RxNo ? patRx.RxNo.toString() : "";
                k["Ref#"] = ((patRx.RefillNo) || (patRx.RefillNo === 0)) ? patRx.RefillNo : "";
                k["Fill#"] = patRx.PartialFillNo ? patRx.PartialFillNo : "";
                k["Fax Date"] = (patRx.FaxDateTime) ? moment.utc(patRx.FaxDateTime).local().format("MM/DD/YYYY") : "";
                k["Time Info"] = (patRx.FaxDateTime) ? moment.utc(patRx.FaxDateTime).local().format("hh:mm:ss a") : "";
                return k;
            })
        );
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    init(flex: wjcGrid.FlexGrid) {
        this.refWijmo = flex;
    }
    initBatchedRxss(flex: wjcGrid.FlexGrid) {
        this.batchedRxssWijmo = flex;
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("printRefReqWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("printRefReqWJ", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("printbatchedRxsRefReqWJ");
        this.wjHeadersForprepareBatchedRxs = this._wijimoUtils.patchDefHeader("printbatchedRxsRefReqWJ", storedWJ1);
    }

}
