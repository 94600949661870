import { Injectable, Input } from "@angular/core";
import * as moment from "moment";
import { CommonService } from "../services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as wjcCore from "@grapecity/wijmo";
import { PrintService } from "../services/print.service";

@Injectable({
    providedIn: "root"
})



export class WijimoPrintUtil {

    printDoc(flex, HtmlData) {
        // create PrintDocument
        const doc = new wjcCore.PrintDocument();
        // add some simple text
        doc.append(HtmlData);
        // add a printer-friendly version of a FlexGrid to the document
        const tbl = this._renderTable(flex);
        doc.append(tbl);
        // print the document
        doc.print();
    }

    private _renderTable(flex: wjcGrid.FlexGrid) {
        // start table
        let tbl = "<table>";
        // headers
        if (flex.headersVisibility && wjcGrid.HeadersVisibility.Column) {
            tbl += "<thead>";
            for (let r = 0; r < flex.columnHeaders.rows.length; r++) {
                tbl += this._renderRow(flex.columnHeaders, r);
            }
            tbl += "</thead>";
        }
        // body
        tbl += "<tbody>";
        for (let r = 0; r < flex.rows.length; r++) {
            tbl += this._renderRow(flex.cells, r);
        }
        tbl += "</tbody>";
        // done
        tbl += "</table>";
        return tbl;
    }

    private _renderRow(panel: wjcGrid.GridPanel, r: number) {
        let tr = "";
        const  row = panel.rows[r];
        const excludeColumns = ["Actions", "Select", "Action"];
        if (row.renderSize > 0) {
            tr += "<tr>";
            for (let c = 0; c < panel.columns.length; c++) {
                    const col = panel.columns[c];
                    if (col.renderSize > 0 && !excludeColumns.includes(col.header)) {
                        // get cell style, content
                        const style = "width:" + col.renderSize + "px;";
                        let content = panel.getCellData(r, c, true);
                        if (!row.isContentHtml && !col.isContentHtml) {
                            content = wjcCore.escapeHtml(content);
                        }
                        // add cell to row
                        if (panel.cellType === wjcGrid.CellType.ColumnHeader) {
                            tr += "<th style=\"" + style + "\">" + content + "</th>";
                        } else {
                            // show boolean values as checkboxes
                            const raw = panel.getCellData(r, c, false);
                            if (raw === true) {
                                content = "&#9745;";
                            } else if (raw === false) {
                                content = "&#9744;";
                            }
                            tr += "<td style=\"" + style + "\">" + content + "</td>";
                        }
                }
            }
            tr += "</tr>";
        }
        return tr;
    }

}
