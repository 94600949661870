import * as _ from "lodash";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class BaseUtils {
    searchId = 1;
    constructor() {}

    keytab(event: any, isFcValid?: boolean, controlName: string = '') {
        if(event && !event.value) {
            event.preventDefault();
        }
        let targetTab;
        if (event &&  event.target && event.target.parentNode && event.target.parentNode.parentNode && event.target.parentNode.parentNode.parentNode.parentNode.tagName === "NG-SELECT") {
            event = event.target.parentNode.parentNode.parentNode.parentNode;
            targetTab = event.tabIndex;
        } else {
            if(event && event.target)
              targetTab = event.target.tabIndex;
        }

        if (event && event.id === "rxInsuId" || (event && event.target && event.target.id === "rxInsuId")) {
            targetTab = 16;
        }

        //     event.target.parentNode.parentNode.parentNode.parentNode.tagName);
        //  let index: number;
        let nextTabIndex: number;
        const pageSearchElems: any = document.querySelectorAll(
            "app-searchbox-global"
        );
        const pageNgSelectele: any = document.querySelectorAll("ng-select");
        const pageOtherElems: any = document.querySelectorAll(
            "input, select, button, checkbox, label"
        );
        const pageElems1: any = Array.from(pageOtherElems).concat(
            Array.from(pageNgSelectele)
        );
        const pageElems: any = Array.from(pageElems1).concat(
            Array.from(pageSearchElems)
        );
        const length = pageElems.length;
        while (!nextTabIndex && targetTab < 150 && targetTab !== -1) {
            for (let i = 0; i < length; i++) {
                if (
                    pageElems[i] &&
                    pageElems[i].tabIndex === targetTab + 1 && pageElems[i].id
                ) {
                    const el = document.getElementById(pageElems[i].id + "f");
                    if (!(el && el["disabled"])) {
                        nextTabIndex = +i;
                        break;
                    }
                }
            }
            targetTab = targetTab + 1;
        }
        let id = (event && event?.id) ? event?.id : event?.target?.id;
        if (targetTab === -1) {
            while (!nextTabIndex && targetTab < 200) {
                for (let i = 0; i < length; i++) {
                    if (
                        pageElems[i] &&
                        pageElems[i].id === id
                    ) {
                        for (let j = i + 1; j < length; j++) {
                            if (pageElems[j].id) {
                                nextTabIndex = +j;
                                break;
                            }
                        }
                    }
                }
                targetTab = targetTab + 1;
            }
        }
        const nextElement = pageElems[nextTabIndex];
        this.focusOnNextElement(nextElement, isFcValid, event);
    }

    focusOnNextElement(nextElement, isFcValid?: boolean, event?: any) {
        if (nextElement && nextElement.id) {
            sessionStorage.setItem("ActiveId", nextElement.id);
            const element: any = document.getElementById(nextElement.id);
            if (isFcValid) {
                if (element && element.tagName === "NG-SELECT") {
                    const ngSelectElem: any = document.getElementsByClassName("ng-input");
                    const len = ngSelectElem.length;
                    for (let i = 0; i < len; i++) {
                        if (
                            ngSelectElem[i] &&
                            ngSelectElem[i]["parentElement"]["parentElement"]["parentElement"]["id"] === nextElement.id
                        ) {
                            ngSelectElem[i]["id"] = nextElement.id;
                            ngSelectElem[i]["children"][0].focus();
                        }
                    }
                    element.firstElementChild.firstElementChild.children[1].focus();
                } else if (element?.parentNode?.parentNode?.tagName === "APP-SEARCHBOX-GLOBAL" || element.tagName === "APP-SEARCHBOX-GLOBAL") {
                    const searchElem = document.getElementsByName("searchBox");
                    const leng = searchElem.length;
                    for (let i = 1; i < leng; i++) {
                        if (searchElem[i].parentNode.parentNode["id"] === nextElement.parentNode.parentNode["id"]) {
                            document.getElementsByName("searchBox")[i].focus();
                        }
                    }
                } else {
                element.focus();
                if (element.type !== "select-one" && element.type !== "submit" && element.type !== "button" && element.type) {
                    element.select();
                }
            }
            } else if(event && event.target && event.target.id) {
                const element1: any = document.getElementById(event.target.id);
                if (element1?.tagName === "NG-SELECT") {
                    const ngSelectElem: any = document.getElementsByClassName("ng-input");
                    const len = ngSelectElem.length;
                    for (let i = 0; i < len; i++) {
                        if (
                            ngSelectElem[i] &&
                            ngSelectElem[i]["parentElement"]["parentElement"]["parentElement"]["id"] === nextElement.id
                        ) {
                            ngSelectElem[i]["id"] = nextElement.id;
                            ngSelectElem[i]["children"][0].focus();
                        }
                    }
                    element1.firstElementChild.firstElementChild.children[1].focus();
                } else if (element1?.parentNode?.parentNode?.tagName === "APP-SEARCHBOX-GLOBAL" ||
                        element1.tagName === "APP-SEARCHBOX-GLOBAL") {
                    const searchElem = document.getElementsByName("searchBox");
                    const leng = searchElem.length;
                    for (let i = 1; i < leng; i++) {
                        if (searchElem[i].parentNode.parentNode["id"] === nextElement.parentNode.parentNode["id"]) {
                            document.getElementsByName("searchBox")[i].focus();
                        }
                    }
                } else {
                element1.focus();
                if (element1.type !== "select-one" && element1.type !== "submit" && element1.type) {
                    element1.select();
                }
            }
            }
        }
    }

    Undokeytab(event: any, isFcValid?: boolean) {
        event.preventDefault();
        let targetTab;
        if (event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.tagName === "NG-SELECT") {
            event = event.target.parentNode.parentNode.parentNode.parentNode;
            targetTab = event.tabIndex;
        } else {
            targetTab = event.target.tabIndex;
        }

        if (event.id === "rxInsuId" || (event && event.target && event.target.id === "rxInsuId")) {
            targetTab = 16;
        }

        //     event.target.parentNode.parentNode.parentNode.parentNode.tagName);
        //  let index: number;
        let prevTabIndex: number;
        const pageSearchElems: any = document.querySelectorAll(
            "app-searchbox-global"
        );
        const pageNgSelectele: any = document.querySelectorAll("ng-select");
        const pageOtherElems: any = document.querySelectorAll(
            "input, select, button, checkbox, label"
        );
        const pageElems1: any = Array.from(pageOtherElems).concat(
            Array.from(pageNgSelectele)
        );
        const pageElems: any = Array.from(pageElems1).concat(
            Array.from(pageSearchElems)
        );
        const length = pageElems.length;

        while (!prevTabIndex) {
        for (let i = length; i >= 0; i--) {
            if (
                pageElems[i] &&
                pageElems[i].tabIndex === targetTab - 1 &&
                pageElems[i].tabIndex !== 0 &&
                pageElems[i].tabIndex !== -1
            ) {
                prevTabIndex = +i;
                break;
            }
        }
        targetTab = targetTab - 1;
        }
        const prevElement = pageElems[prevTabIndex];
        if (prevElement) {
            const element1: any = document.getElementById(prevElement.id);
            if (isFcValid) {
                if (element1 && element1.tagName === "NG-SELECT") {
                      const ngSelectElem: any = document.getElementsByClassName("ng-input");
                      const len = ngSelectElem.length;
                      for (let i = 0; i < len; i++) {
                          if (
                              ngSelectElem[i] &&
                              ngSelectElem[i]["parentElement"]["parentElement"]["parentElement"]["id"] === prevElement.id
                          ) {
                              ngSelectElem[i]["id"] = prevElement.id;
                              ngSelectElem[i]["children"][0].focus();
                          }
                      }
                      element1.firstElementChild.firstElementChild.children[1].focus();
                  } else if (element1 && element1.tagName === "APP-SEARCHBOX-GLOBAL") {
                   // element.focus();
                    document.getElementsByName("searchBox")[1]?.focus();
                   // element.children[0].focus();
                } else {
                element1.focus();
                if (element1.type !== "select-one" && element1.type) {
                    element1.select();
                }
            }
            }
        }
    }
}
