import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({name: "datetimeUtc"})
export class DatetimeUtcPipe implements PipeTransform {

  transform(value: any,args: any): any {
    let dateVal = null;

    if (value) {
        dateVal = (args && args == 'time') ? moment.utc(value).local().format("MM/DD/YYYY hh:mm:ss A") : moment.utc(value).local().format("MM/DD/YYYY");
        if ((args && args == 'time') ? moment(dateVal, "MM/DD/YYYY hh:mm:ss A", true).isValid() : moment(dateVal, "MM/DD/YYYY", true).isValid()) {
            return dateVal;
        } else {
            return null;
        }
    }
    return null;
  }

}
