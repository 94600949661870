import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "localTime"
})
export class LocalTimePipe implements PipeTransform {

    /*transform(value: string): string {
        if (value && typeof value === "string") {
            if (value.endsWith("Z") || value.endsWith("z")) {
                return value;
            } else {
                return value + "Z";
            }
        } else {
            return value;
        }
    }*/
    
    transform(value: string): string {
        let newDate;
        if (value && typeof value === "string") {
            if (value.endsWith("Z") || value.endsWith("z")) {
                newDate = value;
            } else {
                newDate = value + "Z";
            }            
            if (!isNaN(Date.parse(newDate))) { // check the date is valid or not
                return newDate;
            } else {
                return "";
            }
        } else {
            return value;
        }
    }
    

}
