import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-common-price-vals-change-popup',
  templateUrl: './common-price-vals-change-popup.component.html'
})
export class CommonPriceValsChangePopupComponent implements OnInit {

    @ViewChild("calChange", { static: true })
    calChange: any;

    @Input() headerText: string;
    @Input() priceChangeVals: any;
    @Input() footerText: string;
    @Input() rxFG: FormGroup;
    @Input() priceSchdlList: any;
    @Input() screenFrom: any;

    @Output()
    priceChangesPopupClose = new EventEmitter<any>();

    modelRef: any;
    constructor(private _modalService: NgbModal) { }

    ngOnInit() {
        this.modelRef = this._modalService.open(this.calChange, {centered: true, backdrop: false, keyboard: false, size:"lg", windowClass: "medium-custom-confirm-popup-max-width"});
    }
    closePop() {
        if(this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }
    closePriceConfirmation(isYes, type) {
        this.rxFG.controls["isInprogressRx"].setValue(false);
        this.rxFG.controls["inProgressRxPriceVals"].setValue(null);
        this.rxFG.controls["refillRxPriceValsForInprogress"].setValue(null);
        this.setVals(isYes);
        this.priceChangesPopupClose.emit('closed');
        if(this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }
    setVals(isYes) {
        const PrescRefillFG: any = this.rxFG.controls["PrescReFill"] as FormGroup;
        (this.priceChangeVals).map(val=>{
            const setVal = isYes ? val.NewValue : val.OldValue;
            if(val.Header === "Ingredient Cost") {
                PrescRefillFG.controls["Price"].setValue(setVal);
            } else if (val.Header === "Balance Due") {
                PrescRefillFG.controls["BalDue"].setValue(setVal);
            } else if (val.Header === "Pro. Fee") {
                PrescRefillFG.controls["ProfFee"].setValue(setVal);
            } else if (val.Header === "Total Price") {
                PrescRefillFG.controls["TotalAmt"].setValue(setVal);
            } else if (val.Header === "U&C") {
                PrescRefillFG.controls["UnitPriceUNC"].setValue(setVal);
            } else if (val.Header === "Price Schedule" && this.priceSchdlList && this.priceSchdlList.length) {
                const index = (this.priceSchdlList).findIndex(v => v.PriceCode === setVal);
                if (index > -1) {
                    const priceSchId = this.priceSchdlList[index]["Id"];
                    PrescRefillFG.controls["PriceSchId"].setValue(priceSchId);
                }
            } else if (val.Header === "Oth.Amount + Oth.Fee + Tax") {
                this.rxFG.controls["OtherAmount"].setValue(setVal);
            } else if (val.Header === "Awp") {
                PrescRefillFG.controls["UnitPriceAWP"].setValue(setVal);
            }
        });
    }

}
