import { FormBuilder, FormGroup } from "@angular/forms";
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
    HostListener
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import { AlertService, CommonService, PatPreDrugModalService, QuickRefillService, RxService } from "src/app/services";
import { ColorCode, WijimoUtil } from "../../../utils";
import { CommonStore} from "src/app/store";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { RxTotalQtyComponent } from "../rx-total-qty/rx-total-qty.component";
import { HoverOver } from "src/app/utils/hover-over.util";
import { AccCategoryE, Rx1E, RxE } from "src/app/models";
import { PrivMaskGuard } from "src/app/guards";
import { MsgConfig } from "src/app/app.messages";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-easy-refill-wizard",
    templateUrl: "./easy-refill-wizard.component.html"
})
export class EasyRefillWizardComponent
    implements OnInit, AfterViewInit, OnDestroy {


    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("EasyRefill", { static: true })
    EasyRefill: any;

    @ViewChild("warnOnlyOne", { static: true })
    warnOnlyOne: any;
    @ViewChild("quickRefill", { static: true })
    quickRefill: any;

    @ViewChild("warnAtleastOne", { static: true })
    warnAtleastOne: any;

    @ViewChild("searchRx")
    searchRx: ElementRef;

    @ViewChild("RxnotFound", { static: true })
    RxnotFound: any;

    @ViewChild("Rxnotoadd", { static: true })
    Rxnotoadd: any;

    @ViewChild("Rxexists", { static: true })
    Rxexists: any;

    modalRef: any;
    modalRef1: any;
    modalRef2: any;
    modalRef3: any;
    rxNumber: number;
    refillWJ: CollectionView;
    actvHeaders: any;
    newRxData: any;
    rxData: Array<any> = [];
    slctdRxObj: any = {};
    selectedRxs: any[] = [];
    rxCount: number;
    isSelected = false;
    refType: string;
    rxNum: number;
    initiateRegRefill = false;
    initiateQuickRef = false;
    rxNmbr = null;
    activeModal: string;
    selectedRxsFrReg: any[] = [];
    refTypeFG: FormGroup;
    easyWJ: CollectionView;
    hasData: boolean;
    rxNumberEntered: any;
    dummyFG: FormGroup;
    modalRefSM: any;
    count: number = 0;
    rxnoToadd = 0;
    found: any;
    foundRx: any[] = [];
    prescNumDup = 0;
    showRxNos: any[] = [];
    modelRef: any;
    rxInputParams: any;
    rxRefWizardWJ: CollectionView;
    wjHeaders: any;
    completeRx = [];
    RfOrRx: any;
    existedRxs = [];
    rxPatientModel: any;
    patientID: any;
    clearpatName: boolean = true;
    allSelect: boolean;
    newArr: any;
    gridData: any;
    selectedRxIds: any;
    rxNotFound: boolean;
    unsubscribe$: Subject<void> = new Subject();
    searchRxList = [];
    subscription: Subscription;


    @HostListener("window:keydown.control.g", ["$event"])
    keyEventG(event: KeyboardEvent) {
        event.preventDefault();
        this.refTypeFG.controls["type"].patchValue("rr");
        this.getRefillType();
    }

    @HostListener("window:keydown.control.q", ["$event"])
    keyEventQ(event: KeyboardEvent) {
        event.preventDefault();
        this.refTypeFG.controls["type"].patchValue("qr");
        this.getRefillType();
    }

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) { // esc
            if (this.modalRef) {
                this.closeModal();
                event.preventDefault();
           } else if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
                event.preventDefault();
            }
        }
    }

    constructor(
        private _modalService: NgbModal,
        private _alertService: AlertService,
        private _commonSvc: CommonService,
        private _cdr: ChangeDetectorRef,
        private _fb: FormBuilder,
        private _rxSvc: RxService,
        private _commonStore: CommonStore,
        private _patPreDrugModalServ: PatPreDrugModalService,
        private _colorCode: ColorCode,
        private _quickRefilsvc: QuickRefillService,
        private _wijimoUtils: WijimoUtil,
        private _hoverOver: HoverOver,
        private _privMaskGuard:PrivMaskGuard,
        private _routerUrl: Router
    ) {
        this.refTypeFG = this._fb.group({
            type: "rr"
        });
        this.dummyFG = this._fb.group({
            rxno: []
        });
        this.getRefillType();
    }

    ngOnInit() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.RfOrRx = this._commonSvc.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
        this.rxInputParams = {
            patientId: null,
            prescriberId: null,
            drugId: null,
            prescNum: null,
            refillNum: null,
            remarks: null,
            rxId: null,
            prescRefillId: null,
            IsFrmRxInputField:null
        };
        if (this._routerUrl.url === "/eprx/AccountSettings") {
            this._routerUrl.navigate(["eprx/dashboard"]);
        } else {
            this.modalRef = this._modalService.open(this.EasyRefill, {
                size: "lg",
                windowClass: "md-x-lg_77",
                centered: true,
                backdrop: false
            });
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "easyRefillWizardListWJ") {
                this.patchDefaultValHeaders();
            }
        });
        this. subscription = this._commonStore.rfProcessStage$.subscribe(rfStage => {
            let rfData = Object.assign({}, this._commonStore.rxSelectedForRf$["source"]["value"]);
            if (rfStage === "Pop" && rfData["quickRefillList"]?.length && (rfData["frm"] === "ERW") ) {
                if (this.subscription) {
                    this.subscription.unsubscribe();
                }
                this.modalRef = this._modalService.open(this.quickRefill, {
                    size: "lg",
                    windowClass: "md-x-lg_77",
                    centered: true,
                    backdrop: false
                });
                if (this.modalRef) {
                    this.modalRef.close();
                }
                this.selectedRxs = rfData.quickRefillList;
                this.initiateQuickRef = true;
                this.isSelected = true;
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("easyRefillWizardListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("easyRefillWizardListWJ", storedWJ);
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    toggleColorLegend() {
        this._alertService.showHelpText({RxStatus: true, DrugName: true, Status: false});
    }
    PatientHist(item: any) {
        if(item) {
            this._patPreDrugModalServ._patPreDrugModal$.next("patHist");
            const modalRef = this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.patientID = item["Patient Id"];
            modalRef.componentInstance.popUpOpenFr = "ERW";
            modalRef.componentInstance.IsPopUpClosedFromRedFlag
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(closed => {
                modalRef.close();
            });
                modalRef.componentInstance.IsPopUpClosed
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(closed => {
                modalRef.close();
                this.rxData = [];
                this.completeRx = [];
                this.afterClosePatientOrQuickRefillPopUp( _.uniq(this.existedRxs));
                if(closed && (closed === "refill") && this.modalRef) {
                    this.modalRef.close();
                    this.modalRef = null;
                }
                this._patPreDrugModalServ._patPreDrugModal$.next(null);
            });
            modalRef.componentInstance.emitPickedRx
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async emitPickedRx => {
                modalRef.close();
                if (emitPickedRx && emitPickedRx.length > 0) {
                    this.count = 0;
                    this.prescNumDup = 0;
                    const arrPrescNum = new Array();
                    emitPickedRx.map(async val => {
                        if (!(val["Prescription"]["PrescNum"] === this.prescNumDup)) {
                            this.prescNumDup = val["Prescription"]["PrescNum"];
                            arrPrescNum.push(val["Prescription"]["PrescNum"]);
                        }
                    });
                    if (arrPrescNum && arrPrescNum.length) {
                        const list = Object.assign([], arrPrescNum);
                        list.map(v => {
                            if (this.rxData && this.rxData.length > 0) {
                                this.found = this.rxData.find(element => element.Prescription.PrescNum === v);
                                if (this.found) {
                                    const index = arrPrescNum.findIndex(r => r === v);
                                    arrPrescNum.splice(index, 1);
                                    this.foundRx.push(this.found);
                                }
                            } else {
                                this.found = null;
                            }
                        });
                    }
                    if (this.foundRx && this.foundRx.length > 0) {
                        this.count += arrPrescNum ? arrPrescNum.length : 0;
                        this.foundRx.map(curr => {
                            const rxDet = curr["Prescription"]["PrescNum"] + "-" + curr["PrescReFill"]["ReFillNum"] +
                            (this._commonSvc.checkIsPartialFill(curr["PrescReFill"], curr["Prescription"]) ?
                            "-" + curr["PrescReFill"]["PartialFillNo"] : "");
                            this.showRxNos.push(rxDet);
                        });
                        this.modalRef3 = this._modalService.open(this.Rxexists, {
                            centered: true,
                            backdrop: false,
                            windowClass: "large--content"
                        });
                    }
                    if (arrPrescNum && arrPrescNum.length) {
                        const result = await this.getSelectedRfDetails(arrPrescNum.toString());
                        if (result && result.length > 0) {
                            await this.addSelectedRx(result);
                            if (this.rxData && this.rxData.length > 0) {
                                this.hasData = true;
                            } else {
                                this.hasData = false;
                            }
                            this.clearValueAndFocus();
                        }
                    }
                }
                this._patPreDrugModalServ._patPreDrugModal$.next(null);
            });
        }
    }
    openRxTotalQtyComponent(item) {
        const modalRef = this._modalService.open(
            RxTotalQtyComponent,
            {
                backdrop: false,
                size: "sm",
                windowClass: "mode_width_34 modal-center-to-page",
                keyboard: false
            }
        );
        modalRef.componentInstance.RxNumber = item["Rx#"];
        modalRef.componentInstance.AuthNumber = item["Auth#"];
        modalRef.componentInstance.RefNumber = item["Ref#"];
        modalRef.componentInstance.FromScreen = "ERW";
        modalRef.componentInstance.RxStatusObj ={RxHoverOver : item["RxHoverOver"] , RxColorCoding : item["rxColorCoding"]['classForBackground'] };
        modalRef.componentInstance.CloseRxTotalQtyComponentPop
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(x => {
            modalRef.close();
            if(!x) {
                if( this.modalRef)
                    this.modalRef.close();
            }
        });
    }

    openPatientHistory() {
        this._patPreDrugModalServ._patPreDrugModal$.next("patHist");
        const modalRef = this._patPreDrugModalServ.modalInstanceRef;
        modalRef.componentInstance.patientID = this.patientID;
        modalRef.componentInstance.popUpOpenFr = "ERW";
        modalRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(closed => {
            modalRef.close();
            this.clearpatName = null;
            this.rxData = [];
                this.completeRx = [];
                this.afterClosePatientOrQuickRefillPopUp( _.uniq(this.existedRxs));
            if(closed && (closed === "refill") && this.modalRef) {
                this.modalRef.close();
                this.modalRef = null;
            }
            this._patPreDrugModalServ._patPreDrugModal$.next(null);
        });
        modalRef.componentInstance.emitPickedRx
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async emitPickedRx => {
            modalRef.close();
            if (emitPickedRx && emitPickedRx.length > 0) {
                this.clearpatName = null;
                this.count = 0;
                this.prescNumDup = 0;
                const arrPrescNum = new Array();
                emitPickedRx.map(async val => {
                    if (!(val["Prescription"]["PrescNum"] === this.prescNumDup)) {
                        this.prescNumDup = val["Prescription"]["PrescNum"];
                        arrPrescNum.push(val["Prescription"]["PrescNum"]);
                    }
                });
                if (arrPrescNum && arrPrescNum.length) {
                    const list = Object.assign([], arrPrescNum);
                    list.map(v => {
                        if (this.rxData && this.rxData.length > 0) {
                            this.found = this.rxData.find(element => element.Prescription.PrescNum === v);
                            if (this.found) {
                                const index = arrPrescNum.findIndex(r => r === v);
                                arrPrescNum.splice(index, 1);
                                this.foundRx.push(this.found);
                            }
                        } else {
                            this.found = null;
                        }
                    });
                }
                if (this.foundRx && this.foundRx.length > 0) {
                    this.count += arrPrescNum ? arrPrescNum.length : 0;
                    this.foundRx.map(curr => {
                        const rxDet = curr["Prescription"]["PrescNum"] + "-" + curr["PrescReFill"]["ReFillNum"] +
                        (this._commonSvc.checkIsPartialFill(curr["PrescReFill"], curr["Prescription"]) ?
                        "-" + curr["PrescReFill"]["PartialFillNo"] : "");
                        this.showRxNos.push(rxDet);
                    });
                    this.modalRef3 = this._modalService.open(this.Rxexists, {
                        centered: true,
                        backdrop: false,
                        windowClass: "large--content"
                    });
                }
                if (arrPrescNum && arrPrescNum.length) {
                    const result = await this.getSelectedRfDetails(arrPrescNum.toString());
                    if (result && result.length > 0) {
                        await this.addSelectedRx(result);
                        if (this.rxData && this.rxData.length > 0) {
                            this.hasData = true;
                        } else {
                            this.hasData = false;
                        }
                        this.clearValueAndFocus();
                    }
                }
            }
            this._patPreDrugModalServ._patPreDrugModal$.next(null);
        });
    }

    async addToRefillQueue() {
        if (this.selectedRxsFrReg && this.selectedRxsFrReg.length > 0) {
            const resp = await this._rxSvc.addToRefillQueue(this.selectedRxsFrReg).toPromise();
            if (resp !== -1) {
                const dateParams = {
                    StartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
                    EndDate: moment().subtract(0, "days").format("YYYY-MM-DD")
                };
                this._commonSvc.getSideNavCounts(dateParams);
                if (resp > 0) {
                    this._alertService.success(
                    "The selected " + resp + " Rxs already exists in the Refill Queue, hence " +
                     (this.selectedRxsFrReg.length - resp) + " Rxs are added to Queue.",
                        false,
                        "long"
                    );
                }
                if (resp === 0) {
                    this._alertService.success("Rx added to queue successfully.");
                }
            }
            this.closeModal();
        }
    }

    getRefillType() {
        this.isSelected = true;
        this.refType = this.refTypeFG.value["type"] === "rr" ? "regular" : "quick";
    }

    initiateProcess() {
        if (!(this.selectedRxsFrReg.length)) {
           this.modelRef = this._modalService.open(this.warnAtleastOne, {
                backdrop: false,
                windowClass: "large--content"
            });
        } else {
            if (this.refType === "regular") {
                if (this.modalRef) {
                    this.modalRef.close();
                }
                this.openRegRef();
            } else if (this.refType === "quick") {
                if (!this._privMaskGuard.canActivate(AccCategoryE.Rx, RxE.QuickRefill)) {
                    this._alertService.error(MsgConfig.NO_PRIVS_ACCESS);
                } else{
                    this.initiateQuickRef = false;
                    this._cdr.detectChanges();
                    this.initiateQuickRef = true;
                }
            }
        }
        if(this.selectedRxs && this.selectedRxs.length && this.isSelected) {
            this._commonStore._prevoiusScreenInRefill$.next("EasyRefillWizard");
        }
        this.closeModal()
    }
    IsClosePopClosed(type) {
        this.initiateQuickRef = null;
        if(!type) {
            this.rxData = [];
            this.selectedRxsFrReg = [];
            this.selectedRxs = [];
            this.completeRx = [];
            this.refTypeFG.controls["type"].patchValue("rr");
            this.generateRefillWJ([]);
        } else {
            this.rxData = [];
            this.completeRx = [];
            this.afterClosePatientOrQuickRefillPopUp( _.uniq(this.existedRxs));
        }
    }
    openRegRef() {
        if (this.selectedRxsFrReg["length"] > 0) {
            const prescNum = this.selectedRxsFrReg.map(val => {
                return val["Prescnum"];
            });
            const data = {selectedRxs: prescNum, frm: "ERW", completeData: prescNum};
            this._commonStore.storeRefInfo(data);
            this._commonStore.storeRfStage("Start");
        }
    }

    async getRx(rxNo, type?: string) {
        const restToAlwNum = /^[0-9]*$/;
        if (!restToAlwNum.test(rxNo)) {
            this.openRxnotFoundPopup('rxNotFound');
            return;
        }
        this.dummyFG.controls["rxno"].patchValue(null);
        if (document.getElementById("searchRx")) {
            document.getElementById("searchRx").blur();
        }
        this.rxNumber = rxNo;
        if (this.rxNumber) {
            if (this.rxData && this.rxData.length > 0) {
                this.found = this.rxData.find(element => element.Prescription.PrescNum.toString() === this.rxNumber);
                if (this.found) {
                    this.foundRx.push(this.found);
                }
            } else {
                this.found = null;
            }
            if (type === "fromInput" && this.found) {
                const data = this.found.Prescription.PrescNum + "-" + this.found.PrescReFill.ReFillNum +
                 (this._commonSvc.checkIsPartialFill(this.found.PrescReFill, this.found.Prescription) ?
                  "-" + this.found.PrescReFill.PartialFillNo : "");
                this.showRxNos.push(data);
                this.modalRef3 = this._modalService.open(this.Rxexists, {
                    centered: true,
                    backdrop: false,
                    windowClass: "large--content"
                });
                return;
            } else if (!this.found) {
                this.count += 1;
                this.searchRxList.push(this.rxNumber)
                const result = await this.getSelectedRfDetails(this.rxNumber,'IsFromInputCheck');
                if (result && result.length > 0) {
                    if(result[0].IsRxNotExists){
                        this.openRxnotFoundPopup('rxNotFound');
                    } else{
                        await this.addSelectedRx(result);
                        if (!result[0].IsRefillDueRxsExists && type === "fromInput") {
                            this.openRxnotFoundPopup('noRefillDue');
                        }
                        if (this.rxData && this.rxData.length > 0) {
                            this.hasData = true;
                        } else {
                            this.hasData = false;
                        }
                        this.clearValueAndFocus();
                    }
                } else {
                    this.openRxnotFoundPopup('noRefillDue');
                }
            }
        } else {
            this.openEnterRxToAddpopup();
        }
    }

    async getSelectedRfDetails(data,type?) {
        this.rxInputParams.prescNum = data.toString();
        if(type == 'IsFromInputCheck'){
            this.rxInputParams.IsFrmRxInputField = true;
        }else{
            this.rxInputParams.IsFrmRxInputField = false;
        }
        const result = await this._quickRefilsvc.postRefillData(this.rxInputParams).toPromise();
        this.gridData = result;
        return result;
    }

    async addSelectedRx(list) {
        await list.map(async Rx => {
            const patRx = Rx && Rx.Refdetail && Rx.Refdetail.Refilldetails && Rx.Refdetail.Refilldetails[0]
                 ? Rx.Refdetail.Refilldetails[0] : null;
                 if (patRx) {
                    this.rxData.push(patRx);
                    this.completeRx.push(Rx);
                    this.rxData = _.uniqBy(this.rxData,'PrescReFill.Id')
                    this.completeRx = _.uniqBy(this.completeRx, 'Refdetail.Refilldetails[0].PrescReFill.Id')
                 }
        });

        this.checkAll(false);
        this.completeRx.map((item: any) => {
            if(item.Refdetail && item.Refdetail.Refilldetails && item.Refdetail.Refilldetails.length)
                 this.existedRxs.push(item.Refdetail.Refilldetails[0].Prescription.PrescNum);
        });
    }

    generateRefillWJ(refillInfo,selectAll?) {
        if (refillInfo && refillInfo.length) {
        this.rxRefWizardWJ = new CollectionView(
            refillInfo.map((Rx, i) => {
                let k = {};
                const patRx = Rx && Rx.Refdetail && Rx.Refdetail.Refilldetails && Rx.Refdetail.Refilldetails[0]
                ? Rx.Refdetail.Refilldetails[0] : null;
                k["RowId"] = i;
                k["Status"] =
                    patRx.PrescReFill.StatusId === 4 ||
                        patRx.Prescription.RxStatusId === 4
                        ? "T"
                        : patRx.Prescription.RxStatusId === 2 ||
                            patRx.PrescReFill.StatusId === 2 || patRx.RxBill.StatusId === 6
                            ? "D"
                            : (patRx.PrescReFill.StatusId === 3 ||
                                patRx.Prescription.RxStatusId === 3) &&
                                patRx.RxBill.StatusId === 2
                                ? "BI"
                                : (patRx.RxBill.StatusId === 2 && patRx.PrescReFill.StatusId !== 4
                                    && patRx.Prescription.RxStatusId !== 2
                                    && patRx.Prescription.RxStatusId !== 4 && patRx.PrescReFill.StatusId !== 2)
                                    ? "B"
                                    : (patRx.PrescReFill.StatusId === 3 ||
                                        patRx.Prescription.RxStatusId === 3) &&
                                        patRx.RxBill.StatusId === 1
                                        ? "UI"
                                        : "U";

                if (patRx.ResponseStatus === "Early To Refill" ||
                    patRx.ResponseStatus === "READYTOREFILL" ||
                    patRx.ResponseStatus === "Regular" || patRx.ResponseStatus) {

                    if ((patRx.Prescription.RxStatusId === 4 ||
                        patRx.PrescReFill.StatusId === 4)) {
                        if (this._commonSvc.getSetttingValue(
                            "PharmacySettings",
                            "ALLOW_REFILL_TRANS_OUT"
                        ) === "1") {
                            patRx.ResponseStatus = "Transferred";
                        } else {
                            patRx.ResponseStatus = "transferred";
                        }
                    } else if (patRx.Prescription.RxStatusId === 2 ||
                        patRx.PrescReFill.StatusId === 2 || patRx.RxBill.StatusId === 6) {
                        patRx.ResponseStatus = "Deferred Rx";
                    }

                }
                k["PartialQtyRemaining"] = Rx ? Rx.RefillCount * patRx.PrescReFill.OrdQty - Rx.TotalDispQtyIncludingFiledRx : 0;
                k["T.Qty Rem"] = (Rx && Rx.TotalRemainingQty) && ((!patRx.isRxTransferred && +this.RfOrRx) || !(+this.RfOrRx)) ? (Rx.TotalRemainingQty).toFixed(3) : "0.000";
                k["T.Rx Qty Rem"] = (Rx && Rx.TotalRemainingQty) && ((!patRx.isRxTransferred && +this.RfOrRx) || !(+this.RfOrRx)) ? (Rx.TotalRemainingQty).toFixed(3) : "0.000";
                k["TQty Remaining"] = (Rx && Rx.TotalRemainingQty) && ((!patRx.isRxTransferred && +this.RfOrRx) || !(+this.RfOrRx)) ? (Rx.TotalRemainingQty).toFixed(3) : "0.000";
               // k["WarnCode"] = this.checkRxStatus(Rx);

              /*  k["Refill Status"] = k["WarnCode"] === 1 ? "Regular" :
                                     k["WarnCode"] === 2 ? "Early To Refill" :
                                     k["WarnCode"] === 3 ? "Discontinued" :
                                     k["WarnCode"] === 4 ? "Transferred" :
                                     k["WarnCode"] === 5 ? "No Quantity" :
                                     k["WarnCode"] === 6 ? "Rx Expired" :
                                     k["WarnCode"] === 7 ? "Refill Expired" :
                                     k["WarnCode"] === 8 ? "Filed Rx" :
                                     k["WarnCode"] === 9 ? "transferred" :
                                     patRx.ResponseStatus;*/
               // k["Refill Status"] = patRx.ResponseStatus;
                k["Patient Name"] =
                    patRx.Patient.lastname + ", " + patRx.Patient.firstname;
                    k["Patient Id"] =
                    patRx.Patient.patientid;

                k["Rx"] = k["Rx#"] = patRx.Prescription.PrescNum;
                k["Ref#"] = patRx.PrescReFill.ReFillNum;
                k["Fill#"] = this._commonSvc.checkIsPartialFill(patRx.PrescReFill, patRx.Prescription) ? patRx.PrescReFill.PartialFillNo : " ";
                k["QtyRem"] = patRx.partialCummulativeDetails && patRx.partialCummulativeDetails.TotRemQty ?
        patRx.partialCummulativeDetails.TotRemQty : 0;
                k["Auth#"] = patRx.Prescription.ReFillNum;
                k["Auth Ref"] = patRx.Prescription.ReFillNum;
                if (
                    patRx.insuranceInfo &&
                    patRx.insuranceInfo.insuCarrier
                ) {
                    k["Insurance"] = patRx.insuranceInfo.insuCarrier.InsurerCode;
                }
                k["Qty Disp"] = patRx.PrescReFill.DispQty ? patRx.PrescReFill.DispQty : 0;
                k["Drug Name"] = (patRx.Drug.drugname ? (patRx.Drug.drugname).toUpperCase() : "") + " " +
                    (patRx.Drug.strength ? patRx.Drug.strength : "") +
                    (patRx.Drug.drugformname ? patRx.Drug.drugformname : "");
                k["Fill Date"] = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
                    "MM/DD/YYYY"
                );
                k["Ord Date"] = moment.utc(patRx.PrescReFill.OrderDtTm).local().format(
                    "MM/DD/YYYY"
                );
               k["Qty Rem Partial fill"] = (patRx.PrescReFill.PartialIndStatus === 'C') ?  "0.000" : (patRx.islatestpartialrefill ?
                    (patRx && patRx.partialCummulativeDetails ? patRx.partialCummulativeDetails.TotRemQty : 0).toFixed(3) : "0.000");
                k["Qty Rem P.fill"] = (patRx.PrescReFill.IsOverFillRx) ? Rx.TotalRemainingQty : k["Qty Rem Partial fill"] ;
                k["T.Rx Qty Rem"] = Rx.TotalRemainingQty ? Rx.TotalRemainingQty.toFixed(3) : 0;
                k["Copay"] = patRx.PrescReFill.CoPay ? patRx.PrescReFill.CoPay : 0;
                k["Price"] = patRx.PrescReFill.Price ? patRx.PrescReFill.Price : 0;
                k["Prescriber Name"] =
                    patRx.Prescriber.prescriberlastname +
                    ", " +
                    patRx.Prescriber.prescriberfirstname;
                const refillStatus = ['Expired(User)' , "No Quantity" , "Refill Expired", "Expired Rx", "Deferred and Expired(User) Rx", "Deferred and Expired Rx","Expired and Discontinued Rx", "Expired(User) and Discontinued Rx"];
                 k["Refill Status"] =  this._colorCode.checkRefillStatus(Rx);
                if(selectAll){
                    if (k["Refill Status"] && refillStatus.includes(k["Refill Status"])) {
                        k["IsSelected"] = false;
                    } else {
                        this.hasData = true;
                        k["IsSelected"] = true;
                    }
                } else{
                    k["IsSelected"] = false;
                }

                if (patRx.PrescReFill && patRx.PrescReFill.DeliverModeId) {
                    k["DeliveryMode"] =
                        patRx.PrescReFill.DeliverModeId === 1
                            ? "Deliver"
                            : patRx.PrescReFill.DeliverModeId === 2
                                ? "Ship"
                                : patRx.PrescReFill.DeliverModeId === 3
                                    ? "Pick"
                                    : patRx.PrescReFill.DeliverModeId === 4
                                        ? "Waiting"
                                        : "Not Specified";
                    const duedays = Math.round(patRx.PrescReFill.SupplyDays) -
                        moment().startOf("day").diff(moment.utc(patRx.PrescReFill.FillDtTm).startOf("day"), "days");
                    k["Due Days"] = (k["Status"] === "T" || (k["Auth Ref"] === 0 && k["Ref"] === 0)) ? 0 : duedays < 0 ? 0 : duedays;
                    k["DueDays"] = duedays;
                    k["Next Fill Date"] = moment(
                        patRx.PrescReFill.NextFillDt
                    ).format("MM/DD/YYYY");
                    k["DrugClass"] = patRx.Drug.drugclass;
                    const days = +this._commonSvc.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
                    const isDrugDiscontinued = patRx.Drug["discontdt"] && moment().startOf("day")
                        .isAfter(moment(patRx.Drug["discontdt"], "MM/DD/YYYY").add(days, "days"));
                    k["IsDrugDis"] = isDrugDiscontinued ? "YES" : "NO";
                    k["IsHoldRx"] = patRx.PrescReFill.IsRxHold;
                    k["RxExpirationDate"] = patRx.RxExpirationDate ?
                        patRx.RxExpirationDate === "0001-01-01T00:00:00" ? null :
                            moment(patRx.RxExpirationDate).format("MM/DD/YYYY") : null;
                    k["RxExpDate"] = patRx.RxExpirationDate ?
                            patRx.RxExpirationDate === "0001-01-01T00:00:00" ? null :
                            patRx.RxExpirationDate : null;
                    k["RxExpirationDue"] = k["RxExpirationDate"] ? moment().diff(
                        k["RxExpirationDate"],
                        "days"
                    ) : null;
                    k["IsPicked"] = patRx.pickupRx ? patRx.pickupRx.IsPickedUp === true ? true : false : false;
                    k["IsDiscountinued"] = k["IsDiscontinued"] = patRx.Prescription.IsDiscontinued;
                    k["IsDisctFromErx"] = patRx.Prescription.IsDisctFromErx;
                    k["Supply Days"] = k["S.Days"] = patRx.PrescReFill.SupplyDays;
                    k["PrescRefId"] = patRx["PrescReFill"]["Id"];
                    k["InsRefillDueConsumptionThreshold"] = (patRx.primaryInsurance && patRx.primaryInsurance.refillDueConsumptionThreshold) ? patRx.primaryInsurance.refillDueConsumptionThreshold : 0;
                    k["RxExpDateByUser"] = patRx.rxExpiryDateByUser ? patRx.rxExpiryDateByUser :  null;
                    k["rxExpiryDateByInsuranceOrControlSettings"] = patRx.rxExpiryDateByInsuranceOrControlSettings ? patRx.rxExpiryDateByInsuranceOrControlSettings === "0001-01-01T00:00:00" ? null : patRx.rxExpiryDateByInsuranceOrControlSettings :  null;
                    k["TransferredRxStatus"]= patRx.isRxTransferred ? "Rx Transferred" : "Refill Transferred";
                }
                if (k["IsSelected"]) {
                    this.optedRxFrRefill(true, k);
                }
                const OrdDate =  new Date(moment(patRx.PrescReFill.OrderDtTm).local().format("MM/DD/YYYY"));
                const FillDate =  new Date(moment(patRx.PrescReFill.FillDtTm).local().format("MM/DD/YYYY"));
                k["rxThreshold"] = patRx.rxThreshold ? patRx.rxThreshold : "";
                k["OrdFillDateDiff"] = (FillDate.getTime() - OrdDate.getTime()) / (1000 * 3600 * 24);
                k["primaryInsurance"] = patRx.primaryInsurance ? patRx.primaryInsurance : null;
                k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
                k["RxHoverOver"] = this._hoverOver.getRxHoverOver(k, "notRx");
                k["rxColorCoding"] = this._colorCode.showRxColorRefactoring(k, "notRx")
                k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                return k;
            })
        );
        }
    }

    checkRxStatus(data) {
        const rxInfo = data && data["Refdetail"] && data["Refdetail"]["Refilldetails"]
        ? data["Refdetail"]["Refilldetails"][0] : null;
        let warnCode = null;
        if (rxInfo) {
            const orderDate = moment(rxInfo.PrescReFill.OrderDtTm);
            const today = moment();
            const daysBeforeCreated = today.diff(orderDate, "days");
            data.MaxOrderToLastrefilldays = data.MaxOrderToLastrefilldays
            || data.MaxOrderToLastrefilldays === 0
                ? data.MaxOrderToLastrefilldays : 365;
            data["MAxRefillAllowed"] = data["MAxRefillAllowed"] || data["MAxRefillAllowed"] === 0
            ? data["MAxRefillAllowed"] : 99;
            if ((daysBeforeCreated < data.MaxOrderToLastrefilldays &&
                data.FinalExpdate > -data.MaxOrderToLastrefilldays &&
                data.FinalExpdate < 0) || (data.TotalRemainingQty)) {
                warnCode = 1;
            } else if (data.FinalExpdate > 0 && daysBeforeCreated < data.MaxOrderToLastrefilldays &&
                !(data.TotalRemainingQty)) {
                warnCode = 2;
            }
            if (rxInfo.Prescription.IsDiscontinued) {
                warnCode = 3;
            }
            if (rxInfo.PrescReFill.StatusId === 4 || rxInfo.Prescription.RxStatusId === 4) {
                if (this._commonSvc.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT") === "1") {
                    warnCode = 4;
                } else {
                    warnCode = 9;
                }
            }
            if (rxInfo.PrescReFill.ReFillNum >= data["MAxRefillAllowed"]) {
                warnCode = 7;
            }
            if (rxInfo.Prescription.ReFillNum <= rxInfo.PrescReFill.ReFillNum &&
                !(data.TotalRemainingQty)) {
                    warnCode = rxInfo.Prescription.IsDiscontinued ? 3 : 5;
            }
            if (daysBeforeCreated >= data.MaxOrderToLastrefilldays ||
                data.FinalExpdate < -data.MaxOrderToLastrefilldays) {
                    warnCode = rxInfo.Prescription.IsDiscontinued ? 3 : 6;
            }
            if (rxInfo.Prescription.RxStatusId === 2 || rxInfo.PrescReFill.StatusId === 2) {
                warnCode = 8;
            }

        }
        return warnCode;
    }

    openEnterRxToAddpopup() {
        this.modalRef2 = this._modalService.open(this.Rxnotoadd, {
            centered: true,
            backdrop: false,
            windowClass: "small--content"
        });
    }

    openRxnotFoundPopup(type) {
        this.rxNotFound = type == 'rxNotFound' ? true : false
        this.modalRef1 = this._modalService.open(this.RxnotFound, {
            centered: true,
            backdrop: false,
            windowClass: "small--content"
        });
    }

    clearValueAndFocus(type ?: string) {
        if (type === "RxnotFound" && this.modalRef1) {
            this.modalRef1.close();
            this.modalRef1 = null;
        }
        if (type === "Rxtoadd" && this.modalRef2) {
            this.modalRef2.close();
            this.modalRef2 = null;
        }
        if (type === "Rxexists" && this.modalRef3) {
            this.modalRef3.close();
            this.modalRef3 = null;
            this.found = null;
            this.foundRx = [];
            this.showRxNos = [];
            this.count = 0;
        }
        if (!(this.foundRx && this.foundRx.length > 0)) {
            const ele = document.getElementById("searchRx");
            if (ele) {
                document.getElementById("searchRx").focus();
        }
            this.count = 0;
        }
        this.dummyFG.controls["rxno"].patchValue(null);
        this.rxNmbr = null;
        this.rxNumber = null;
    }

    optedRxFrRefill(isChecked: boolean, checkedVal: any) {
        if (isChecked) {
            let hasVal = false;
            if (this.selectedRxsFrReg && this.selectedRxsFrReg.length &&
                this.selectedRxsFrReg.find(v => v["PrescrefillId"] === checkedVal["PrescRefId"])) {
                    hasVal = true;
            }
            if (!hasVal) {
                this.selectedRxsFrReg.push({
                    Prescnum: checkedVal["Rx#"],
                    PrescrefillId: checkedVal["PrescRefId"],
                    refillNumber: checkedVal["Ref#"]
                });
                this.selectedRxs.push(this.rxData[checkedVal["RowId"]]);
            }
        } else {
            const selcFrRegIndex = this.selectedRxsFrReg.findIndex(
                elem => checkedVal["Rx#"] === elem.Prescnum && checkedVal["PrescRefId"] === elem.PrescrefillId
            );
            const selcIndex = this.selectedRxs.findIndex(
                elem => checkedVal["Rx#"] === elem.Prescription.PrescNum && checkedVal["PrescRefId"] === elem["PrescReFill"]["Id"]
            );
            if (selcFrRegIndex > -1) {
                this.selectedRxsFrReg.splice(selcFrRegIndex, 1);
            }
            if (selcIndex > -1) {
                this.selectedRxs.splice(selcIndex, 1);
            }
        }
    }

    delRow2(deleteItem: any) {
        const rxIndex = this.rxData.findIndex(
            elem => deleteItem["Rx#"] === elem.Prescription.PrescNum && deleteItem["PrescRefId"] === elem.PrescReFill.Id
        );
        if (rxIndex > -1) {
            this.rxData.splice(rxIndex, 1);
            // this.existedRxs.splice(rxIndex, 1);
        }

        const rx1Index = this.completeRx.findIndex(elem1 => {
            const patRx = elem1 && elem1.Refdetail && elem1.Refdetail.Refilldetails && elem1.Refdetail.Refilldetails[0]
                 ? elem1.Refdetail.Refilldetails[0] : null;
               return deleteItem["Rx#"] === ((patRx.Prescription && patRx.Prescription.PrescNum) ? patRx.Prescription.PrescNum  : 0 ) &&
                deleteItem["PrescRefId"] === patRx.PrescReFill.Id;
            });
        if (rx1Index > -1) {
            this.completeRx.splice(rx1Index, 1);
            this.existedRxs.splice(rx1Index, 1);
        }

        const rxIndex2 = this.rxData.findIndex(ele =>
            deleteItem["Rx#"] === ele.Prescription.PrescNum && deleteItem["PrescRefId"] === ele["PrescReFill"]["Id"]
          );
          if (rxIndex2 > -1) {
              this.rxData.splice(rxIndex2, 1);
          }

        if (deleteItem.IsSelected) {
            const selcIndex = this.selectedRxsFrReg.findIndex(
                elem => (deleteItem["Rx#"] === elem?.Prescnum )  && (deleteItem["PrescRefId"] === elem["PrescrefillId"])
            );
            this.selectedRxs.splice(selcIndex, 1);
            this.selectedRxsFrReg.splice(selcIndex, 1);



        }
        this.checkAll(false);
    }

    closeModal() {
        this.IsPopUpClosed.emit(null);
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this._commonStore.previousRefillScreen(false);
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const colors = this._colorCode.showRxColorRefactoring(row.dataItem, "notRx");
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Refill Status") {
                const colors = this._colorCode.colorCodingForRefillStatus(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                   } else {
                        cell.style.background = "#FFF";
                        cell.style.color = "#000";
                    }

                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
            }
            let isRxFound = this.searchRxList.find((num) => num == row.dataItem.Rx)
            if (!(panel.columns[c].binding) && isRxFound) {
                cell.style.background = "#ffd3ae";
                row.dataItem.IsSelected = true;
                if(['READYTOREFILL', 'Early To Refill', 'Regular', 'Discontinued','No More Refills Left With P.Qty','Deferred Rx', 'Transferred','Deferred and Discontinued Rx', 'No More Refills Left With R.Qty'].indexOf(row.dataItem['Refill Status']) > -1) {
                    this.optedRxFrRefill(row.dataItem.IsSelected,row.dataItem)
                }
            }
        }
    };
    async afterClosePatientOrQuickRefillPopUp (arrPrescNum) {
        if(arrPrescNum && arrPrescNum.length) {
            const result = await this.getSelectedRfDetails(arrPrescNum.toString());
            if (result && result.length > 0) {
                await this.addSelectedRx(result);
                if (this.rxData && this.rxData.length > 0) {
                    this.hasData = true;
                } else {
                    this.hasData = false;
                }
                this.clearValueAndFocus();
            }
        } else {
            const IsFromRedFlag = this._commonSvc.DecryptData(localStorage.getItem("IsFromRedFlag"));
            if(this.modalRef && IsFromRedFlag) {
                localStorage.removeItem("IsFromRedFlag");
                this.modalRef.close();
                this.modalRef  = null;
            }
        }

    }

    async selectedPatientInfo(patientInfo: any) {
        if (patientInfo) {
            this.clearpatName = patientInfo.fullname ? ((patientInfo.fullname).toUpperCase()).replace("_", ",") : "";
            this.rxPatientModel = patientInfo;
            this.patientID = patientInfo.patientid;
            this.openPatientHistory()
        }
    }
    checkAll(value) {
        this.allSelect = value;
        if (value) {
            this.generateRefillWJ(this.completeRx, true);
        } else {
            this.selectedRxs = [];
            this.selectedRxsFrReg = [];
            this.generateRefillWJ(this.completeRx, false);
        }
    }

    focusToFirst(event) {
        if (document.getElementById("cancelButton")) {
            event.preventDefault();
            document.getElementById("cancelButton").focus();

        }
    }
}
