import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { AlertService } from "src/app/services";
import { SystemData } from "./../../../models/system-data.model";
import { CollectionView } from "@grapecity/wijmo";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import {
    Measurement,
    ClinicalData
} from "./../../../models/edit-patient.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    HostListener,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientInfo, RxAuditLog, RxModel } from "../../../models";
import { CommonService, PatientService } from "../../../services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as moment from "moment";
import { MsgConfig } from "../../..";
import { NewAuditUtils } from "src/app/utils";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-rx-clinical-info",
    templateUrl: "./rx-clinical-info.component.html",
    styles: []
})
export class RxClinicalInfoComponent implements OnInit {
    patClinicalList: CollectionView;
    patientID: number;
    patientInfo: PatientInfo;
    editMode: boolean;
    clinicalMeasureFG: FormGroup;
    clinicalData = new ClinicalData();
    totalCount: any;
    pageCount: number;
    pageSize = 15;
    actvHeaders: string[];
    pageNumber = 1;
    @ViewChild("Clinical")
    flex: wjcGrid.FlexGrid;
    today: any;
    time: any;
    clinicalInfo: any;
    erxRowInfo: any;
    clinicData: any;
    systemData: SystemData;
    unitValues: any;
    minDate: { year: number; month: number; day: number };
    currentTime: string;
    @ViewChild("warningInfo", { static: true })
    warningInfo: ElementRef;
    clinicalId: any;
    CreatedDtTm: any;
    currentItem: any;
    formGroupInvalid: boolean;
    prescNumber: any;
    rxFG: FormGroup;
    rxInfo: RxModel;
    model: any;
    unsubscribe$: Subject<void> = new Subject();
    originalData: any;
    oldMeasureDate: any;
    oldMeasureTime: any;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    @Input()
    set PrescNumber(pi: number) {
        this.prescNumber = pi;
    }

    @Input()
    set RxFG(rfg: FormGroup) {
        this.rxFG = rfg;
    }

    @Input()
    set RxInfo(ri: RxModel) {
        if (ri) {
            this.rxInfo = ri;
            this.patientID = this.rxInfo.Prescription.PatientId;
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();
    dateChecks: { control: string; minDate: string; maxDate: { year: number; month: number; day: number; }; isRequired: boolean; }[];

    constructor(
        private _actvRoute: ActivatedRoute,
        private _commonServ: CommonService,
        private modalService: NgbModal,
        private _editPatient: PatientService,
        private _fb: FormBuilder,
        private _alertService: AlertService,
        private _nwAudtUtl: NewAuditUtils,
        private _route: Router,
        private _cdr: ChangeDetectorRef,
        private _dateUtil: DateCheckUtil
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.currentTime = moment().format("hh:mm:ss");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
    }

    get MedTestId(): FormControl {
        return this.clinicalMeasureFG.controls["MedTestId"].get(
            "MedTestId"
        ) as FormControl;
    }

    get TestUnitId(): FormControl {
        return this.clinicalMeasureFG.controls["TestUnitId"].get(
            "TestUnitId"
        ) as FormControl;
    }

    get ObsVal(): FormControl {
        return this.clinicalMeasureFG.controls["ObsVal"].get(
            "ObsVal"
        ) as FormControl;
    }

    ngOnInit() {
        this.getInfo();
        this.createFG();
        // this.getUnit();
        this.clinicalData.PageNumber = 1;
        this.clinicalData.PageSize = 15;
        this.getClinicalData(this.clinicalData);
        this.dateChecks = [{
            control: "MeasureDt",
            minDate: "",
            maxDate: this.minDate,
            isRequired: true
        }];
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    getInfo() {
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
            resp => (this.systemData = resp)
        );
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getClinicalData(data: any) {
        this._editPatient
            .getRxClinicalInfo(this.rxInfo?.Prescription?.Id, data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.generateList(resp);
                } else {
                    this.generateList(null);
                }
            });
    }

    async getUnit(type?: any) {
        if (this.clinicalMeasureFG.value["MedTestId"]) {
            this.unitValues = await this._editPatient.getPatientClinUnits(this.clinicalMeasureFG.value["MedTestId"]).toPromise();
        } else {
            this.unitValues = [];
        }
        if (!type) {
            if (this.unitValues && this.unitValues.length > 0) {
                this.clinicalMeasureFG.patchValue({TestUnitId : this.unitValues[0].Id, MeasurementUnit: this.unitValues[0].Name});
            } else {
                this.clinicalMeasureFG.patchValue({TestUnitId : null, MeasurementUnit: null});
            }
        }
        this._cdr.detectChanges();
    }

    generateList(data: any) {
        if (data.length) {
            this.patClinicalList = new CollectionView(
                data.map((clinical, i) => {
                    this.oldMeasureDate  = clinical.MeasureDt;
                    this.oldMeasureTime  = clinical.MeasureDt;
                    const j = {};
                    j["Id"] = clinical.Id;
                    j["Date and Time"] = moment.utc(clinical.MeasureDt).local().format(
                        "MM/DD/YYYY hh:mm a"
                    );
                    j["Dimension"] = clinical.Dimension;
                    j["Measurement Unit"] = clinical.MeasurementUnit;
                    j["Measurement Value"] = clinical.MeasurementValue;
                    j["UnitId"] = clinical.MeasurementUnitId;
                    j["DimensionId"] = clinical.DimensionId;
                    j["CreatedDtTm"] = clinical.CreatedDtTm;
                    this.totalCount = clinical.TotalCount;
                    this.pageCount = Math.ceil(
                        clinical.TotalCount / this.pageSize
                    );
                    return j;
                })
            );
            this.actvHeaders = [
                "Actions",
                "Date and Time",
                "Dimension",
                "Measurement Unit",
                "Measurement Value"
            ];
        } else {
            this.totalCount = 0;
        }
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.clinicalData.PageNumber = this.pageNumber;
        this.getClinicalData(this.clinicalData);
    }

    setSize(size: any) {
        this.pageSize = size;
        this.pageNumber = 1;
        this.clinicalData.PageNumber = 1;
        this.clinicalData.PageSize = size;
        this.getClinicalData(this.clinicalData);
    }

    createFG() {
        this.clinicalMeasureFG = this._fb.group(new Measurement());
    }

    openClinicalInfo(addclinicalInfo) {
        this.time = moment().format("hh:mm a");
        this.editMode = false;
        // this.unitValues = null;
        this.clinicalMeasureFG.reset();
        if(this.rxInfo && this.rxInfo.Prescription && this.rxInfo.PrescReFill){
            this.clinicalMeasureFG.patchValue({
                MeasureDt: this.today, MeasureTm: this.time,
                PrescId: this.rxInfo.Prescription.Id ? this.rxInfo.Prescription.Id : "",
                RefillNum: this.rxInfo.PrescReFill.ReFillNum ? this.rxInfo.PrescReFill.ReFillNum : 0
            });
        }
        this.model = this.modalService.open(addclinicalInfo, { centered: true, backdrop: false });
    }

    async openeditClinicalInfo(addclinicalInfo, val: any) {
        this.editMode = true;
        this.clinicalInfo = this.clinicalMeasureFG.value;
        // this.oldMeasureDate  = val["Date and Time"];
        // this.oldMeasureTime  = val["Date and Time"];
        this.clinicalInfo.MeasureDt = moment(val["Date and Time"]).format(
            "MM/DD/YYYY"
        );
        this.clinicalInfo.MeasureTm = moment(val["Date and Time"]).format(
            "hh:mm a"
        );
        this.clinicalInfo.ObsVal = val["Measurement Value"];
        this.clinicalInfo.Id = val["Id"];
        this.clinicalInfo.PrescId = this.rxInfo.Prescription.Id;
        this.clinicalInfo.RefillNum = this.rxInfo.PrescReFill.ReFillNum;
        this.clinicalInfo.IsActive = true;
        this.clinicalInfo.CreatedDtTm = val["CreatedDtTm"];
        this.clinicalInfo.MedTestId = val["DimensionId"];
        this.clinicalInfo.TestUnitId = val["UnitId"];
        this.clinicalInfo.MeasurementUnit = val["Measurement Unit"];
        this.clinicalInfo.Dimension = val["Dimension"];
        await this.patchValue();
        await this.getUnit("Edit");
        this.originalData = Object.assign({}, this.clinicalMeasureFG.getRawValue());
        this.model = this.modalService.open(addclinicalInfo, { centered: true, backdrop: false });
    }

    openDeletePopUp(content, val: any) {
        this.currentItem = val;
        this.modalService.open(content, { centered: true, backdrop: false, windowClass:"large--content"});
    }

    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.clinicalInfo) {
            if (key && this.clinicalMeasureFG && this.clinicalMeasureFG.controls[key]) {
                this.clinicalMeasureFG.controls[key].patchValue(
                    this.clinicalInfo[key]
                );
            }
        }
    }

    deleteClinicalInfo() {
        this._editPatient
            .deleteRxClinicalInfo(this.currentItem.Id, this.rxInfo.Prescription.Id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    // TODO:
                    // this._auditUtils.deletedValues(
                    //     this.currentItem.Id,
                    //     "Delete Clinical Info",
                    //     "Patient",
                    //     this.patientID
                    // );
                    this._alertService.success(MsgConfig.DELETE_SUCCESS);
                    this.getClinicalData(this.clinicalData);
                } else {
                    this._alertService.error(MsgConfig.DELETE_FAIL);
                }
            });
    }

    getTwentyFourHourTime(amPmString) {
        const d = new Date("1/1/2013 " + amPmString);
        return d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    }

    saveclinicalData() {
        const clinicDatesFG = this._fb.group({MeasureDt: this.clinicalMeasureFG.controls["MeasureDt"].value});
        const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(clinicDatesFG, this.dateChecks);
       if (dateCheckFields) {
           return;
       } else if (this.clinicalMeasureFG.valid) {
        if (this.model) {
            this.model.close();
            this.model = null;
        }
            this.clinicalMeasureFG.patchValue({
                PatientId: this.patientID
            });
            this.clinicalMeasureFG.patchValue({
                PrescId: this.rxInfo.Prescription.Id
            });
            this.clinicalMeasureFG.patchValue({
                RefillNum: this.rxInfo.PrescReFill.ReFillNum ? this.rxInfo.PrescReFill.ReFillNum : 0
            });

            this.clinicalMeasureFG.patchValue({
                IsActive: true
            });
            const measureTime = this.getTwentyFourHourTime(
                this.clinicalMeasureFG.value.MeasureTm
            );
            this.clinicalMeasureFG.value.MeasureTm = measureTime;
            this._editPatient
                .addRxClinicalInfo(this.clinicalMeasureFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === -1) {
                        this.modalService.open(this.warningInfo, {
                            centered: true, backdrop: false, windowClass:"large--content"
                        });
                    } else if (resp === 1) {
                        const clinAudtMdl = new RxAuditLog().rxClinicalAuditModel;
                        this._nwAudtUtl.saveAuditChange(
                            null,
                            this.clinicalMeasureFG.getRawValue(),
                            "ClinicalInfo",
                            "Rx",
                            this.rxInfo.PrescReFill.Id,
                            clinAudtMdl
                        );
                        this._alertService.success(MsgConfig.SAVE_SUCCESS);
                        this.getClinicalData(this.clinicalData);
                    } else {
                        this._alertService.error(MsgConfig.SAVE_FAIL);
                    }
                });
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }

    resetClinicalInfo() {
        this.clinicalInfo = this.clinicalMeasureFG.value;
        this.clinicalInfo.MeasureDt = this.today;
        this.clinicalInfo.MeasureTm = this.time;
        this.clinicalInfo.MedTestId = 0;
        this.clinicalInfo.TestUnitId = 0;
        this.clinicalInfo.ObsVal = null;
        this.clinicalMeasureFG.reset();
        this.patchValue();
    }

    updateclinicalData() {
        const clinicDatesFG = this._fb.group({MeasureDt: this.clinicalMeasureFG.controls["MeasureDt"].value});
        const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(clinicDatesFG, this.dateChecks);
       if (dateCheckFields) {
           return;
       } else if (this.clinicalMeasureFG.valid) {
            if (this.model) {
                this.model.close();
                this.model = null;
            }
            this.clinicalMeasureFG.patchValue({
                PrescId: this.rxInfo.Prescription.Id
            });
            this.clinicalMeasureFG.patchValue({
                PatientId: this.patientID
            });
            this.clinicalMeasureFG.patchValue({
                PrescReFill: this.rxInfo.PrescReFill.ReFillNum
            });

            this.clinicalMeasureFG.patchValue({
                IsActive: true
            });
            const measureTime = this.getTwentyFourHourTime(
                this.clinicalMeasureFG.value.MeasureTm
            );
            this.clinicalMeasureFG.value.MeasureTm = measureTime;
            this.clinicalMeasureFG.value.Id = this.clinicalInfo.Id;
            this.clinicalMeasureFG.value.CreatedDtTm = this.clinicalInfo.CreatedDtTm;
            this._editPatient
                .updateRxClinicalInfo(this.clinicalMeasureFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp === 1) {
                        const time = this.getTwentyFourHourTime(
                            this.clinicalInfo.MeasureTm
                        );
                        this.clinicalInfo.MeasureTm = time;
                        this.originalData.MeasureDt = this.oldMeasureDate;
                        this.originalData.MeasureTm = this.oldMeasureTime;
                        const clinAudtMdl = new RxAuditLog().rxClinicalAuditModel;
                        this._nwAudtUtl.saveAuditChange(
                            this.originalData,
                            this.clinicalMeasureFG.getRawValue(),
                            "ClinicalInfo",
                            "Rx",
                            this.rxInfo.PrescReFill.Id,
                            clinAudtMdl
                        );
                        this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                        this.getClinicalData(this.clinicalData);
                    } else {
                        this._alertService.error(MsgConfig.UPDATE_FAIL);
                    }
                });
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }

    cancel() {
        // this._actvRoute.queryParams.subscribe(params => {
        //     if (params && params.fm) {
        //         if (
        //             params.fm === "nr" ||
        //             params.fm === "er" ||
        //             params.fm === "rf" ||
        //             params.fm === "nrf" ||
        //             params.fm === "brx"
        //         ) {
        //             this._route.navigate(["eprx/rx"], {
        //                 queryParams: { fm: params.fm }
        //             });
        //         }
        //     } else {
        //         this._route.navigate(["eprx/dashboard"]);
        //     }
        // });
        this.IsPopUpClosed.emit("Patient1");
    }

    focusOutFrmAdd(event) {
        if (!this.totalCount) {
            event.preventDefault();
            this.focusOnCancel(event);
        }
    }

    focusOnCancel(event) {
        if (document.getElementById("")) {
            event.preventDefault();
            document.getElementById("").focus();
        }
    }
}
