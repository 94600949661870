import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { RxService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-view-trans-msg",
    templateUrl: "./view-trans-msg.component.html",
    styles: []
})
export class ViewTransMsgComponent implements OnInit {
    rxInfo: any;
    viewTransmData: any;
    subscriptions = new Subscription();
    modelRef: any;
    isNotFromTrans: string;
    headerSeg: any[];
    unsubscribe$: Subject<void> = new Subject();
    @Input()
    set RxInfo(rc: any) {
        this.rxInfo = rc;
    }

    @Input()
    set IsNotFromTrans(it: string) {
        this.isNotFromTrans = it;
    }

    @Input()
    set ViewTransString(vts: any) {
        this.viewTransmData = vts;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    InitiateTransmitRxOnly = new EventEmitter<null>();

    constructor(private _rxService: RxService,
        private _modalService: NgbModal) {}

    ngOnInit() {
        if (!this.viewTransmData) {
            this.getViewTransmsnData();
        } else if (this.viewTransmData) {
            this.setHeaderVal();
            this.openViewTransMsgPopUp();
        }
    }

    getViewTransmsnData() {
        const insurence = this.rxInfo.Patient.insurance.filter(insu =>
                insu.insuranceid === +this.rxInfo.RefillInsu.InsuId)[0];

        let billType = null;
        const billAs = this.rxInfo.RefillInsu.InsuPriId;
        if (insurence) {
            billType = insurence.insuPriId
                ? insurence.insuPriId === 3
                    ? "T"
                    : insurence.insuPriId === 2
                    ? "S"
                    : "P"
                : "P";
        }
        let transmitData = {
            rxId: this.rxInfo.Prescription.Id,
            PrescRefillId: this.rxInfo.PrescReFill.Id,
            patientId: this.rxInfo.Prescription.PatientId,
            TransType: "B1",
            Ins_Code: insurence ? insurence.insuType : null,
            BillAs: billAs === 2 ? "S" : billAs === 3 ? "T" : "P",
            PrescNum: this.rxInfo.Prescription.PrescNum
        };

        this.subscriptions.add(this._rxService
            .viewTransmitMessage(transmitData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.viewTransmData = resp;
                    this.setHeaderVal();
                } else {
                    this.closeModal();
                }
            }));
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setHeaderVal() {
        const headerInfo = this.viewTransmData["Header"];
        if (headerInfo) {
            const splitVal = headerInfo.split(":");
            const fObj = [];
            if (splitVal && splitVal.length) {
                splitVal.map((v, i) => {
                    if (i !== splitVal.length - 1) {
                        const subSplit = v.split(" ");
                        subSplit.shift();
                        const subSplit2 = splitVal[i + 1].split(" ", 1);
                        fObj.push({
                            FieldID: subSplit && subSplit.length ? subSplit.join(" ") : v,
                            FieldValue: subSplit2 && subSplit2.length ? subSplit2[0] : splitVal[i + 1]
                        });
                    }
                });
            }
            this.headerSeg = fObj;
        }
    }

    openViewTransMsgPopUp() {
        // this.modelRef = this._modalService.open(this.VIEWTRANSMSG, {
        //     size: "lg",
        //     keyboard: false
        // });
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopUpClosed.emit(null);
    }


    initiateTransmitRxOnly() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.InitiateTransmitRxOnly.emit(null);
    }
}
