export class User {
    grant_type: string;
    username: string;
    password: string;
}

export class IpConfig {
    Id: number = null;
    IpAddress: string = null;
    Name: string = null;
    IsActive: boolean = null;
    PersonId: number = null;
}

export class PrimeESCKeyG {
    userName: string = null;
    password: string = null;
    ipAddress: string = null;
    pharmacyId: any = null;
    tenantId: any = null;
}

export class MessageHubClass {
    npi: string = null;
    erxType: string = null
}
