<div class="eprx--block__content presc-Sml"  *ngIf="((prescriberInfo?.prescriberid && (drugInfo?.id)) && !IsFromErxRequest) && (!rxFG?.value['frmInprog'] || rxFG?.value['afterDrugInactv'])">
    <div class="row">
        <div class="col sml-box semi-sml">
            <label> Address </label>
            <div class="label--data"> {{PrescAddress?.AddressLine1}}
                <span *ngIf="PrescAddress?.AddressLine2">, {{PrescAddress?.AddressLine2}}</span>
                <span *ngIf="PrescAddress?.CityName">, {{PrescAddress?.CityName}}</span>
                <span *ngIf="PrescAddress?.StateName">, {{PrescAddress?.StateName}} </span>
                <span *ngIf="PrescAddress?.ZipCode">, {{PrescAddress?.ZipCode}}</span>
            </div>
        </div>
        <div class="col-2 position-relative" *ngIf="Is340BStatus"> 
            <span class="position-340">
                340B 
            </span> 
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label> Telephone1# </label>
                    <div class="label--data"> {{prescriberInfo?.telephone === "###"? "--" : (prescriberInfo?.telephone | mask : "(000)000-0000")}} </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label> Mobile# </label>
                    <div class="label--data">{{prescriberInfo?.mobile === ""? "--" : (prescriberInfo?.mobile | mask : "(000)000-0000")}}</div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label> FAX# </label>
                    <div class="label--data">{{PrescAddress?.Fax ? (PrescAddress?.Fax  | mask : "(000)000-0000") : (prescriberInfo?.faxnumber === ""? "--" : (prescriberInfo?.faxnumber | mask : "(000)000-0000"))}}</div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                    <label> NPI# </label>
                    <div class="label--data"> {{prescriberInfo?.prescribernpinum? prescriberInfo?.prescribernpinum : "--"}}
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label> DEA# </label>
                    <div class="label--data"> {{prescriberInfo?.prescriberdeanum === "###"? "--" :
                        _formatsUtil.getDeaFormat(prescriberInfo?.prescriberdeanum)}}
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label> SPI# </label>
                    <div class="label--data"> {{prescriberInfo?.prescriberspinum === "###"? "--" :
                        prescriberInfo?.prescriberspinum}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 sml-box semi-sml">
            <label> Remarks </label>
            <div class="label--data text-display-notes"> {{prescriberInfo?.prescribernotes ? prescriberInfo?.prescribernotes : "--"}} </div>
        </div> -->
    </div>
</div>
<div class="eprx--block__content presc-Sml"  *ngIf="((prescriberInfo?.prescriberid && (!drugInfo?.id)) && !IsFromErxRequest) || (rxFG?.value['frmInprog']) && !rxFG?.value['afterDrugInactv']">
    <div class="row">
        <div class="col row">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <label> Telephone1# </label>
                        <div class="label--data"> {{prescriberInfo?.telephone === "###"? "--" : (prescriberInfo?.telephone | mask : "(000)000-0000") }} </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <label> Mobile# </label>
                        <div class="label--data">{{prescriberInfo?.mobile === ""? "--" : (prescriberInfo?.mobile | mask : "(000)000-0000")}}</div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <label> FAX# </label>
                        <div class="label--data">{{PrescAddress?.Fax ? (PrescAddress?.Fax  | mask : "(000)000-0000") : (prescriberInfo?.faxnumber === ""? "--" : (prescriberInfo?.faxnumber | mask : "(000)000-0000"))}}</div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                        <label> NPI# </label>
                        <div class="label--data"> {{prescriberInfo?.prescribernpinum? prescriberInfo?.prescribernpinum : "--"}}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pr-0">
                        <label> DEA# </label>
                        <div class="label--data"> {{prescriberInfo?.prescriberdeanum === "###"? "--" :
                            _formatsUtil.getDeaFormat(prescriberInfo?.prescriberdeanum)}}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <label> SPI# </label>
                        <div class="label--data"> {{prescriberInfo?.prescriberspinum === "###" ? "--" :
                            prescriberInfo?.prescriberspinum}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col sml-box">
            <label> Address </label>
            <div class="label--data"> {{PrescAddress?.AddressLine1}},
                <span *ngIf="PrescAddress?.AddressLine2">{{PrescAddress?.AddressLine2}}, </span>
                <span *ngIf="PrescAddress?.CityName">{{PrescAddress?.CityName}}, </span>
                <span *ngIf="PrescAddress?.StateName">{{PrescAddress?.StateName}}, </span>
                <span *ngIf="PrescAddress?.ZipCode">{{PrescAddress?.ZipCode}}</span>
            </div>
        </div>
        <!-- <div class="col sml-box">
            <label> Remarks </label>
            <div class="label--data"> {{prescriberInfo?.prescribernotes === ""? "--" :
                    prescriberInfo?.prescribernotes }} </div>
        </div> -->
        <div class="col-1 position-relative" *ngIf="Is340BStatus"> 
            <span class="position-340">
                340B 
            </span> 
        </div>
    </div>
</div>
<div class="eprx--block__content presc-Sml"    [ngClass]="IsFromErxRequest ? 'height_7rem_drug' : ''"  *ngIf="prescriberInfo  && isFromErx && IsFromErxRequest && !rxFG?.value['afterDrugInactv']">
    <div class="row width_100per">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
            <label> License# </label>
            <div class="label--data"> {{prescriberInfo?.prescribernpinum? prescriberInfo?.prescribernpinum : "--"}}
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
            <label> NPI# </label>
            <div class="label--data"> {{prescriberInfo?.prescribernpinum? prescriberInfo?.prescribernpinum : "--"}}
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4  sml-box semi-sml">
            <label> Address </label>
            <div class="label--data text--ellipsis text-nowrap overflow_hidden text-truncate" 
            >{{PrescAddress?.AddressLine1}},
                <span *ngIf="PrescAddress?.AddressLine2">{{PrescAddress?.AddressLine2}}, </span>
                <span *ngIf="PrescAddress?.CityName">{{PrescAddress?.CityName}}, </span>
                <span *ngIf="PrescAddress?.StateName">{{PrescAddress?.StateName}}, </span>
                <span *ngIf="PrescAddress?.ZipCode">{{PrescAddress?.ZipCode}}</span>
            </div>
        </div>


        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <label> DEA# </label>
            <div class="label--data"> {{prescriberInfo?.prescriberdeanum === "###"? "--" :
                _formatsUtil.getDeaFormat(prescriberInfo?.prescriberdeanum)}}
            </div>
        </div>


        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <label> FAX# </label>
            <div class="label--data">{{PrescAddress?.Fax ? (PrescAddress?.Fax  | mask : "(000)000-0000") : (prescriberInfo?.faxnumber === ""? "--" : (prescriberInfo?.faxnumber | mask : "(000)000-0000"))}}</div>
        </div>
        <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4  sml-box semi-sml">
            <label> Remarks </label>
            <div class="label--data text-display-notes"> {{prescriberInfo?.prescribernotes === ""? "--" :
                    prescriberInfo?.prescribernotes }} </div>
        </div> -->
    </div>
</div>
