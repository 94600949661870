<ng-template #PatientRec let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>{{ header }}</h4>
        <span body>
            <div class="eprx--block__content">
                <div class="row check--dur__block">
                    <div class="col-md-3 vertical--tabs ">
                        <h4 class="text-primary col-md-12">
                            Report Type
                            <hr />
                        </h4>

                        <ul ngbNav #nav="ngbNav"  class="nav-pills" orientation="vertical"
                        (tabChange)="beforeChange($event?.nextId)" #PRINTRECORDTABS >
                        <li  id="rxRecord" [ngbNavItem]="'rxRecord'"  (click)="beforeChange('rxRecord','Print Patient Rx Record')" *ngIf="hasPriv">
                            <a ngbNavLink class="nav-link" id="Patient1">
                                <div class="txt">
                                    <div><b>Print Patient Rx Record</b></div>
                                </div>
                            </a>
                        </li>
                        <li (click)="beforeChange('rxRecord')" *ngIf="!hasPriv">
                            <a class="nav-link">
                                <div class="txt">
                                    <div><b>Print Patient Rx Record</b></div>
                                </div>
                            </a>
                        </li>
                        <li id="rxDelivery"  [ngbNavItem]="'rxDelivery'" (click)="beforeChange('rxDelivery','Print Rx Delivery Slip')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt">
                                    <div><b>Print Rx Delivery Slip</b></div>
                                </div>
                            </a>
                        </li>
                        <li  id="rxReceipt" [ngbNavItem]="'rxReceipt'" (click)="beforeChange('rxReceipt','Print Rx Receipt')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt">
                                    <div><b>Print Rx Receipt</b></div>
                                </div>
                            </a>
                        </li>
                        <li id="copay" [ngbNavItem]="'copay'" (click)="beforeChange('copay','Print Bill For Unpaid Copay')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt">
                                    <div ><b>Print Bill For Unpaid Copay</b></div>
                                </div>
                            </a>
                        </li>
                        <li  id="expReport" [ngbNavItem]="'expReport'" (click)="beforeChange('expReport','Refill Expiration Report')">
                            <a ngbNavLink class="nav-link">
                                <div class="txt">
                                    <div ><b>Refill Expiration Report</b></div>
                                </div>
                            </a>
                        </li>
                        </ul>


                    </div>
                    <div class="col-md-9">
                        <h4 class="text-primary col-md-12 padding-0">
                            Report Parameters
                            <hr />
                        </h4>
                        <div class="row">
                            <div class="col-6">
                                <eprx-select [LabelText]="'Patient Drug'" [PlaceHolder]="'Patient Drug'" [ControlName]="'DrugId'"
                                    [FormGroupName]="InsuFiltersFrmGrp" [List]="patientDrugs" [BindLabel]="'drugFullName'" [BindValue]="'Id'" [AutoFocus]="true"
                                    [LabelForId]="'Id'" [BindLabel2]="'DrugCode'" [LabelTitle1]="'Name'" [LabelTitle2]="'NDC'"  (TriggerSelectValue)="optedDrug($event)">
                                </eprx-select>
                            </div>
                            <!-- <div class="col-md-9 seconday-search--grid" id="drugSearchElem">
                                <div class="row">
                                        <div class="col-4" style="color: #17a2b8 !important;"><label>Name</label> </div>
                                        <div class="col-8">
                                            <div class="text-right">
                                                <div><i class="far fa-lg fa-long-arrow-alt-right" style="color: rgb(247, 148,29);"></i><span> for Equv ; </span><b
                                                        style="color: rgb(247, 148,29);">Shift + <i class="far fa-lg fa-long-arrow-alt-right"
                                                            style="color: rgb(247, 148,29);"></i></b><span> for Gen Equv</span></div>
                                            </div>
                                        </div>
                                </div>
                                <app-searchbox-global id="drugSearchName" #DrugSearch [SearchFor]="'Drug'" [DisableRadioButtons]="true" [IsAddNew]="false"
                                (SelectedDrug)="optedDrug($event)" [ClearInputValue]="drugSearchInputValue" [IsFocused]="true"></app-searchbox-global>
                            </div> -->
                            <div class="col-md-6">
                                <eprx-input [LabelText]="'NDC'" [PlaceHolder]="'NDC'" [ControlName]="'NDC'" [FormGroupName]="InsuFiltersFrmGrp"
                                    [ReadOnly]="true" [InputType]="'ndc'">
                                </eprx-input>
                            </div>
                            <div class="col-md-6" *ngIf="rxList.length > 0 && selctdTab !=='expReport'">
                                <eprx-text-area [LabelText]="'Selected Rxs'"  [HasControl]="false"
                                    [PlaceHolder]="'Selected Rxs'" [Rows]="2" [ReadOnly]="true" [inputValue]="rxList.join(', ')">
                                </eprx-text-area>
                            </div>
                            <div class="col-md-6 seconday-search--grid mini-search-golbal">
                                <label>Prescriber Name</label>
                                <app-searchbox-global [SearchFor]="'Prescriber'" [DisableRadioButtons]="true" [IsAddNew]="false" (SelectedPrescriber)="optedPrescriber($event)"
                                [ClearInputValue]="prescSearchInputValue"></app-searchbox-global>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <eprx-select [LabelText]="'For Ins Type'" [PlaceHolder]="'For Ins Type'" [ControlName]="'InsuranceId'"
                                    [FormGroupName]="InsuFiltersFrmGrp" [List]="InsuranceDrops" [BindLabel]="'InsurerCode'" [BindLabel2]="'Name'"
                                    [BindValue]="'Id'" [LabelForId]="'Id'" [DropdownPosition]="'top'">
                                </eprx-select>
                            </div>
                            <div class="col-md-4" *ngIf="!(rxList && rxList.length > 0) || selctdTab !=='expReport'">
                                <eprx-date-picker [LabelText]="'From Date'" [PlaceHolder]="''" [ControlName]="'FromDate'" [FormGroupName]="InsuFiltersFrmGrp"
                                    [MinDate]="" [MaxDate]="maxDate" [IsDisabled]="false" [IsRequired]="true"  [InputErrors]="InsuFiltersFrmGrp?.controls?.FromDate?.errors"
                                    [ErrorDefs]="{required: 'Required'}">
                                </eprx-date-picker>
                            </div>
                            <div class="col-md-4" *ngIf="!(rxList && rxList.length > 0) || selctdTab !=='expReport'">
                                <eprx-date-picker [LabelText]="'To Date'" [PlaceHolder]="''" [ControlName]="'ToDate'" [FormGroupName]="InsuFiltersFrmGrp"
                                    [MinDate]="" [MaxDate]="maxDate" [IsDisabled]="false" [IsRequired]="true" [InputErrors]="InsuFiltersFrmGrp?.controls?.ToDate?.errors"
                                    [ErrorDefs]="{required: 'Required'}">
                                </eprx-date-picker>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab==='rxRecord'">
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Rx'" [ControlName]="'RxToPrintId'" [FormGroupName]="RxRecordFrmGrp" [List]="['All', 'Billed','UnBilled']"
                                    [ValueList]="[3,2,1]" [IDForList]="['Rx1', 'Rx2', 'Rx3']" [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Rx Sig'" [ControlName]="'IsRxSigPrinted'" [FormGroupName]="RxRecordFrmGrp" [List]="['Yes', 'No']"
                                    [ValueList]="[true, false]" [IDForList]="['IsRxSigPrinted1', 'IsRxSigPrinted0']" [LabelForId]="">
                                </eprx-radio>
                            </div>

                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Rx Price'" [ControlName]="'IsRxPricePrinted'" [FormGroupName]="RxRecordFrmGrp" [List]="['Yes', 'No']"
                                    [ValueList]="[true, false]" [IDForList]="['IsRxPricePrinted1', 'IsRxPricePrinted0']" [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Patient Pay Amount'" [ControlName]="'IsPatientPayAmntPrinted'" [FormGroupName]="RxRecordFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPatientPayAmntPrinted0', 'IsPatientPayAmntPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio LabelText="Print Pharmacist's Initials" [ControlName]="'IsPharmacistIniPrinted'" [FormGroupName]="RxRecordFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPharmacistIniPrinted0', 'IsPharmacistIniPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Prescriber Address'" [ControlName]="'IsPrescriberAddsPrinted'" [FormGroupName]="RxRecordFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPrescriberAddsPrinted0', 'IsPrescriberAddsPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-12">
                                <eprx-radio [LabelText]="'Prescriber ID to Print'" [ControlName]="'PrescriberIdToPrintId'" [FormGroupName]="RxRecordFrmGrp"
                                    [List]="['None', 'License#','DEA#','NPI#']" [ValueList]="[1,2,3,4]" [IDForList]="['PrescriberIdToPrintId0', 'PrescriberIdToPrintId1','PrescriberIdToPrintId2','PrescriberIdToPrintId3']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4" [formGroup]="RxRecordFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsCurrentRxOnlyShown'" formControlName="IsCurrentRxOnlyShown">
                                <label class="form-check-label" [for]="'IsCurrentRxOnlyShown'">Show Current Rx's Only
                                </label>
                            </div>
                            <div class="col-md-4" [formGroup]="RxRecordFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsFiledRxShown'" formControlName="IsFiledRxShown">
                                <label class="form-check-label" [for]="'IsFiledRxShown'">Include Deferred Rx(s)
                                </label>
                            </div>
                            <div class="col-md-4" [formGroup]="RxRecordFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsAllFamilyMemsShown'" formControlName="IsAllFamilyMemsShown">
                                <label class="form-check-label" [for]="'IsAllFamilyMemsShown'">Show All Family Member
                                </label>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab==='rxDelivery'">
                            <div class="col-md-4">
                                <eprx-radio LabelText="Print Pharmacist's Initials" [ControlName]="'IsPharmacistIniPrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPharmacistIniPrinted0', 'IsPharmacistIniPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Drug Name'" [ControlName]="'IsDrugNmPrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsDrugNmPrinted0', 'IsDrugNmPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Rx Barcode'" [ControlName]="'IsRxBarcodePrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsRxBarcodePrinted1', 'IsRxBarcodePrinted0']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Insurance Information'" [ControlName]="'IsInsuInfoPrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsInsuInfoPrinted1', 'IsInsuInfoPrinted0']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Facility Name & Address'" [ControlName]="'IsFacilityNmNAddsPrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsFacilityNmNAddsPrinted0', 'IsFacilityNmNAddsPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Prescriber Address'" [ControlName]="'IsPrescriberAddsPrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPrescriberAddsPrinted0', 'IsPrescriberAddsPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4">
                                <eprx-radio [LabelText]="'Print Patient Pay Amount'" [ControlName]="'IsPatientPayAmntPrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPatientPayAmntPrinted0', 'IsPatientPayAmntPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-8">
                                <eprx-select [LabelText]="'Patient Address'" [PlaceHolder]="'Patient Address'" [ControlName]="'AddressId'"
                                    [FormGroupName]="RxDeliveryFrmGrp" [List]="PatientAddresses" [BindLabel]="'AddressLine1'" [BindValue]="'Id'"
                                    [LabelForId]="'Id'" [IsDisabled]="RxDeliveryFrmGrp.value.IsFacilityNmNAddsPrinted">
                                </eprx-select>
                            </div>
                            <div class="col-md-2">
                                <eprx-radio LabelText="Print Today's Date" [ControlName]="'IsTodaysDtPrinted'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsTodaysDtPrinted0', 'IsTodaysDtPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-2">
                                <eprx-radio LabelText="Print Rx Serial #" [ControlName]="'printRxSerialNum'" [FormGroupName]="RxDeliveryFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['printRxSerialYes', 'printRxSerialNo']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4" [formGroup]="RxDeliveryFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsAllFamilyMemsShown'" formControlName="IsAllFamilyMemsShown">
                                <label class="form-check-label" [for]="'IsAllFamilyMemsShown'">Show All Family Member
                                </label>
                            </div>
                            <div class="col-md-4" [formGroup]="RxDeliveryFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsCurrentRxOnlyShown'" formControlName="IsCurrentRxOnlyShown">
                                <label class="form-check-label" [for]="'IsCurrentRxOnlyShown'">Show Current Rx's Only
                                </label>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab==='rxReceipt'">
                            <div class="col-md-4" [formGroup]="RxReceiptFrmGrp">
                                <eprx-radio LabelText="Print Pharmacist's Initials" [ControlName]="'IsPharmacistIniPrinted'" [FormGroupName]="RxReceiptFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPharmacistIniPrinted0', 'IsPharmacistIniPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4" [formGroup]="RxReceiptFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsAllFamilyMemsShown'" formControlName="IsAllFamilyMemsShown">
                                <label class="form-check-label" [for]="'IsAllFamilyMemsShown'">Show All Family Member
                                </label>
                            </div>
                            <div class="col-md-4" [formGroup]="RxReceiptFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsCurrentRxOnlyShown'" formControlName="IsCurrentRxOnlyShown">
                                <label class="form-check-label" [for]="'IsCurrentRxOnlyShown'">Show Current Rx's Only
                                </label>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab==='copay'">
                            <div class="col-md-4" [formGroup]="CopayFrmGrp">
                                <eprx-radio LabelText="Print Pharmacist's Initials" [ControlName]="'IsPharmacistIniPrinted'" [FormGroupName]="CopayFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPharmacistIniPrinted0', 'IsPharmacistIniPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4" [formGroup]="CopayFrmGrp">
                                <eprx-radio LabelText="Report Type" [ControlName]="'IsSummaryRepShown'" [FormGroupName]="CopayFrmGrp" [List]="['Detail', 'Summary']"
                                    [ValueList]="[false, true]" [IDForList]="['IsSummaryRepShown0', 'IsSummaryRepShown1']" [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4" [formGroup]="CopayFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsAllFamilyMemsShown'" formControlName="IsAllFamilyMemsShown">
                                <label class="form-check-label" [for]="'IsAllFamilyMemsShown'">Show All Family Member
                                </label>
                            </div>
                        </div>
                        <div class="row" *ngIf="selctdTab==='expReport'">
                            <div class="col-md-4">
                                <eprx-radio LabelText="Print Pharmacist's Initials" [ControlName]="'IsPharmacistIniPrinted'" [FormGroupName]="ExpReportFrmGrp"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPharmacistIniPrinted0', 'IsPharmacistIniPrinted1']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-4" [formGroup]="ExpReportFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsAllFamilyMemsShown'" formControlName="IsAllFamilyMemsShown">
                                <label class="form-check-label" [for]="'IsAllFamilyMemsShown'">Show All Family Member
                                </label>
                            </div>
                            <div class="col-md-4" [formGroup]="ExpReportFrmGrp">
                                <input class="form-check-input" type="checkbox" [id]="'IsCurrentRxOnlyShown'" formControlName="IsCurrentRxOnlyShown">
                                <label class="form-check-label" [for]="'IsCurrentRxOnlyShown'">Show Current Rx's Only
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="getRecords(true)" appShortcutKey [AltKey]="'p'">
            <span>P</span> Print
        </button>
        <button footer class="hotkey_success" (click)="getRecords()" appShortcutKey [AltKey]="'v'">
            <span>V</span> Preview
        </button>
        <button footer class="hotkey_success" (click)="saveConfig()" appShortcutKey [AltKey]="'d'">
           <span>D</span> Save As Default
        </button>
        <button *ngIf="hasFaxNumber" footer class="hotkey_success" (click)="Fax()" appShortcutKey [AltKey]="'f'">
            <span>F</span> Fax
         </button>
         <button *ngIf="!hasFaxNumber" footer class="inactive" ><span>F</span> Fax</button>
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'">
            <span>C</span> Close
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #PatientRxReport let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="modelRef2.close(); modelRef2=null">
            <h4 header> Patient Report</h4>
            <span body>
                <div class="col-md-12" >
                    <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="800" type="application/pdf"></iframe>
                </div>
            </span>
            <button footer class="hotkey_primary" (click)="print()" appShortcutKey InputKey="p"><b>P</b> Print </button>
            <button footer ngbAutofocus class="hotkey_primary" (click)="modelRef2.close() ; modelRef2=null" appShortcutKey InputKey="c"><b>C</b> Close </button>
        </app-custom-modal>
    </ng-template>

    <ng-template #Faxs let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="close()">
            <h4 header> Fax#</h4>
            <span body>
                <eprx-radio LabelText="" [ControlName]="'FaxandPrescriber'"
                    [FormGroupName]="faxFG" [List]="['To Fax# ', 'To Selected Prescriber']" [ValueList]="[1,0]"
                    [IDForList]="['To Fax No', 'To Selected Prescribe']" [LabelForId]="" (TriggerSelectValue)="onSelectRadioButton($event)">
                </eprx-radio>
                <eprx-input [LabelText]="'To Fax#'" [PlaceHolder]="'To Fax#'" [ControlName]="'ToFaxNumber'" [FormGroupName]="faxFG"
                   [MaskPattern]="'000000000000000'" [IsDisabled]="!disabled" (TriggerOnBlur)="toFaxNumber($event)" (TriggerChangeValue)="toFaxNumber($event)" >
                </eprx-input>
                <div class="seconday-search--grid">
                    <Label>Prescriber</Label>
                    <app-searchbox-global [inputdisabled]="disabled" [SearchFor]="'Prescriber'" [DisableRadioButtons]="true" [IsAddNew]="false" (SelectedPrescriber)="faxPrescriber($event)"
                    [ClearInputValue]="prescriberSearchInputValue"></app-searchbox-global>
                    <span class="help--text__danger" *ngIf="checkPrescriberOrFax">{{showErrorMsg}}</span>
                </div>
            </span>
            <button footer *ngIf="!okDisabled" class="hotkey_success" (click)="faxNow()" appShortcutKey [AltKey]="'o'"><span>O</span> Ok </button>
            <button footer *ngIf="okDisabled" class="inactive"><span>O</span>Ok</button>
            <button footer ngbAutofocus class="hotkey_success" (click)="close()" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel </button>
        </app-custom-modal>
    </ng-template>

    <ng-template #Missing let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="closeModalForMissing()">
            <h4 header></h4>
            <span body>
                {{prescriberNameOrFax}}
            </span>
            <button footer class="hotkey_success" appShortcutKey [AltKey]="'o'"><span>O</span> Ok </button>
            <button footer ngbAutofocus class="hotkey_success" (click)="closeModalForMissing()" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel </button>
        </app-custom-modal>
    </ng-template>
