<div class="exprx--common-block erx--body drug-verify-body" *ngIf="showFilters">
    <div class="eprx--block__header">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-11">
                        <span class="eprx--header__heading"> Drug Pick Verification</span>
                    </div>
                    <div class="col-1 padding_left_7rem">
                        <app-user-guide-icon [ActiveTabId]="'DpvVerification'"></app-user-guide-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content">
        <form [formGroup]="NotVerifyFG" (ngSubmit)="getNVInfo()">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                        <eprx-date-picker [LabelText]="'From Date'" [PlaceHolder]="''"
                            [ControlName]="'FromDate'" [FormGroupName]="NotVerifyFG" [MinDate]=""
                             [ErrorDefs]="{required: 'Required'}"
                            [MarkAsTouched]="NotVerifyFG?.get('FromDate')?.touched"
                            [MaxDate]="minDate" [IsDisabled]="false">
                        </eprx-date-picker>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                        <eprx-date-picker [LabelText]="'To Date'" [PlaceHolder]="''" [ControlName]="'ToDate'"
                            [FormGroupName]="NotVerifyFG" [MinDate]="" [MaxDate]="minDate"
                            [ErrorDefs]="{required: 'Required'}"
                            [MarkAsTouched]="NotVerifyFG?.get('ToDate')?.touched"   [IsDisabled]="false">
                        </eprx-date-picker>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-3 col-xs-3 " *ngIf="!notVerified">
                        <eprx-select [LabelText]="'Verification Status'" [PlaceHolder]="'Verification Status'"
                            [ControlName]="'VerifyStatus'" [Title]="'Verification Status'" (TriggerSelectValue)="getNVInfo()"
                            [FormGroupName]="NotVerifyFG" [HasMultiple]="false" [BindLabel]="'status'"
                            [BindValue]="'value'" [LabelForId]="'Id'" [List]="verifyStatus"></eprx-select>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3" *ngIf="searchRX">
                        <eprx-input [AutoFocus]="true" [LabelText]="'Enter Rx# to perform Drug Pick Verification'"
                            [RxSelectId]="'RxNumber'" [PlaceHolder]="'Rx#'" [Title]="'Rx#'"
                            [InputType]="'NUMSPC'" [FormGroupName]="RxNumFG" [ControlName]="'rxnum'"
                            (EmitOnChangeValue)="getRxDetails()" (TriggerOnShiftTabbing)="getRxDetails()" [DropSpclChar]="false">
                        </eprx-input>
                    </div>
                    <div class="col-1 radio-height">
                        <label class="col-md-12 padding-0 ">
                            Current User Only</label>
                            <eprx-toggle [ControlName]="'IsCurrentUser'" [FormGroupName]="NotVerifyFG"  [IsChecked] = "NotVerifyFG?.get('IsCurrentUser').value" (TriggerSelectValue)="getNVInfo()" >
                            </eprx-toggle>
                    </div>
                    <div class="col-md-2"  *ngIf="!notVerified">
                        <div class="row" [formGroup]="groupVerfFG">
                            <label class="col-12 mb-1">Group By</label>
                            <div class="col-md-3 padding_right_6rem">
                        <input class="form-check-input" type="checkbox" [id]="'DPHRxGrp1'" value="12"
                            formControlName="RxGrp" (change)="WijimoGroupByVerf()" />
                        <label class="form-check-label" [for]="'DPHRxGrp1'" id="RxGrp">
                            Rx
                        </label></div>
                        </div>
                    </div>
                    <div class="col-md-2"  *ngIf="notVerified">
                        <div class="row" [formGroup]="groupFG">
                            <label class="col-12 mb-1">Group By</label>
                            <div class="col-md-3 padding_right_6rem">
                        <input class="form-check-input" type="checkbox" [id]="'DPHpatientGrp1'" value="10"
                            formControlName="patientGrp" (change)="WijimoGroupBy()" />
                        <label class="form-check-label" [for]="'DPHpatientGrp1'" id="patientGrp">
                            Patient
                        </label></div>
                        <div class="col-md-3">
                        <input class="form-check-input" type="checkbox" [id]="'DPHDrugGrp2'" value="11"
                            formControlName="DrugGrp" (change)="WijimoGroupBy()" />
                        <label class="form-check-label" [for]="'DPHDrugGrp2'" id="DrugGrp">
                            Drug
                        </label>
                    </div>
                </div>
                </div>
                    <div class="col-lg-2 text-right padding_top_15px margin_left_auto">
                        <button title="Search / Refresh" type="submit" class="hotkey_success pull-right"  appShortcutKey [AltKey]="'f'">
                            <span>F</span> Search
                        </button>
                        <button type="button" (keydown.tab)="focusOutFrCancel($event)"
                            class="hotkey_success pull-right" (click)="reset()" appShortcutKey [AltKey]="'l'">
                            <span>L</span> Clear
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="eprx--block__content">
        <div class="col padding-0">
            <!-- <div class="col-md-5 offset-5  text-center position-absolute" *ngIf="totalRx > 0">
                <label>Total Rx(s)</label>
                <span> : {{ totalRx }} </span>
                <label  class = "margin_left_5_5rem margin_top_1rem">Rx(s) Verified</label>
                <span>
                    : {{ VerifiedRx }}
                </span>
                <label  class = "margin_left_5_5rem margin_top_1rem">Rx(s) Not Yet Verified
                </label>
                <span>
                    : {{ notVerifiedRx }}
                </span>
            </div> -->


                <div class="drug-verify--tabing navTabStyles">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId"  class="nav-tabs col-12"
                        (tabChange)="beforeChange($event?.nextId)" #drugPicVerificationTabs >
                        <li [ngbNavItem]="'Patient1'"  (click)="beforeChange('Patient1')">
                            <a ngbNavLink class="nav-link" id="Patient1">
                                <div class="txt">
                                    <div>Not Yet
                                        <br />Verified ({{notVerifiedRx}})</div>
                                </div>
                            </a>
                        </li>
                        <li [ngbNavItem]="'Patient2'"  (click)="beforeChange('Patient2')">
                            <a ngbNavLink class="nav-link" id="Patient2">
                                <div class="txt" >
                                    <div >Verification History  ({{VerifiedRx}}) </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>

            <div *ngIf="notVerified" [formGroup]="RxNumFG">
                <div class="erx--body exprx--common-block pt-0 mt-0">
                    <div>
                        <div class="row mt-0" *ngIf="totalCount">
                            <div class="col-12 text-right">
                                <button class="submit print-med-guide background_orange"
                                    (click)="toggleColorLegend()">Color Legend</button>
                            </div>
                        </div>
                        <wj-flex-grid #notVerifiedlist [headersVisibility]="'Column'" [itemsSource]="notVerifyListWJ"
                            [isReadOnly]="true" [columnPicker]="'drugVerfWJ1'" [selectionMode]="'Row'" [allowSorting]="true"
                            (loadedRows)="loadedWJRows(notVerifiedlist)" (initialized)="initnv(notVerifiedlist)"
                            [itemFormatter]="itemFormatter" (keydown.enter)="keyEventOnWijimo(notVerifiedlist, $event)"
                            (updatedView)="updateWJView(notVerifiedlist)" 
                            [ngClass]="[!totalCount ? 'no-data wjm-grd-custom-height-50' : 'wjm-grd-custom-height-50']">

                            <wj-flex-grid-column [header]="'Action'" [width]="(groupByNames && groupByNames?.length  && groupByNames?.length <= 2) ? 125 : 65">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <i class="far fa-prescription-bottle-alt actions color_orange text-center" title="Verify"
                                        (click)="
                                                checkIsDrugPickReady(item?.data, false)
                                                    "></i>
                                    <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                                </ng-template>
                            </wj-flex-grid-column>

                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                                *ngFor="let rxheader of wjHeaders; let i = index">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <div *ngIf="rxheader.hName === 'Patient' || rxheader.hName === 'Prescriber'"
                                        [title]="item[rxheader.hName] | uppercase">
                                        {{ item[rxheader.hName] }}
                                    </div>
                                    <div
                                        *ngIf="!(rxheader.hName === 'Action' || rxheader.hName === 'Patient' || rxheader.hName === 'Drug' || rxheader.hName === 'Prescriber'  || rxheader['hName'] === 'Status' || rxheader['hName'] === 'Rx#')"  [title]="item[rxheader.hName] ">
                                        {{ item[rxheader.hName] }}
                                    </div>
                                    <div *ngIf="(rxheader['hName'] === 'Drug' || rxheader['hName'] === 'Status' || rxheader['hName'] === 'Rx#')"  [ngbTooltip]="rxheader['hName'] === 'Drug' ? item['DrugHoverOver'] : rxheader['hName'] === 'Status' ? item['StatusHoverOver'] :item['RxHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 3) ? 'right' : (cell.col.index>=3 && cell.col.index <9)?'top':'left'" container="body" tooltipClass="tooltip-title">
                                        {{ item[rxheader['hName']]}}

                                    </div>
                                </ng-template>
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                    <div title={{rxheader.hName}}>
                                        {{ rxheader.hName }}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                        </wj-flex-grid>
                        <app-eprx-pagination *ngIf="totalCount" [TotalCount]="notVerifiedRx" [WijmoName]="flex" [GridName]="'notVerifiedListGrid'" (OnPageSelected)="setNVPage($event)"
                        (OnSizeSelected)="setNVSize($event)" (keydown.tab)="focusToFirst($event)" [PageNumber]="nVpageNumber" [screenTotalNVRecords]="notVerfList"></app-eprx-pagination>
                    </div>
                </div>
            </div>
            <div *ngIf="verifyHistory">
                <div class="erx--body exprx--common-block drug-verify-body">
                    <div>
                        <div class="row mt-0"  *ngIf="HisttotalCount">
                            <div class="col-12 text-right">
                                <!-- <button class="submit print-med-guide color_orange"
                                    (click)="toggleColorLegend()">Color Legend</button> -->
                                    <button class="submit print-med-guide background_orange"
                                    (click)="toggleColorLegend()">Color Legend</button>
                            </div>
                        </div>
                        <wj-flex-grid #VerifyHistorylist (initialized)="init(VerifyHistorylist)" [headersVisibility]="'Column'"
                            [itemsSource]="VerifyHistListWJ" [isReadOnly]="true" [columnPicker]="'drugVerfWJ2'"
                            [allowSorting]="true" [selectionMode]="'Row'" (loadedRows)="loadedRows(VerifyHistorylist)" (updatedView)="updateVerfWJView(VerifyHistorylist)" 
                            [itemFormatter]="itemFormatter" [ngClass]="[!HisttotalCount ? 'no-data wjm-grd-custom-height-50' : 'wjm-grd-custom-height-50']">

                            <!-- <wj-flex-grid-column [header]="'Action'" [width]="65">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>  // commented as Edit icon is not required in Verification HistoryE11-I1892
                                </ng-template>
                            </wj-flex-grid-column> -->

                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                                *ngFor="let rxheader of wjHeaders1; let i = index">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <div *ngIf="rxheader.hName === 'Result'" title={{item[rxheader.hName]}}>
                                        <span *ngIf="(item[rxheader.hName] === 'Verified' || item[rxheader.hName] === 'Re-Verified')"
                                            class="color_dark_green"><b>{{item[rxheader.hName]}}</b></span>
                                            <span *ngIf="item[rxheader.hName] === 'Re-Verify'"
                                            class="color_orange"><b>{{item[rxheader.hName]}}</b></span>
                                        <span *ngIf="item[rxheader.hName] !== 'Verified' && item[rxheader.hName] !== 'Re-Verified' && item[rxheader.hName] !== 'Re-Verify'"
                                            class="color_red"><b>{{item[rxheader.hName]}}</b></span>
                                    </div>
                                    <div *ngIf="!(rxheader.hName === 'Result' || rxheader.hName === 'Drug' || rxheader['hName'] === 'Rx#')" title={{item[rxheader.hName]}}>
                                        {{ item[rxheader.hName] }}
                                    </div>
                                    <div *ngIf="(rxheader['hName'] === 'Drug' || rxheader['hName'] === 'Rx#')"  [ngbTooltip]="rxheader['hName'] === 'Drug' ? item['DrugHoverOver'] :item['RxHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <10)?'top':'left'" container="body" tooltipClass="tooltip-title">
                                        {{ item[rxheader.hName]}}
                                    </div>
                                </ng-template>
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                    <div title={{rxheader.hName}}>
                                        {{ rxheader.hName }}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                            <app-no-data-found *ngIf="!isDataExists2"></app-no-data-found>
                        </wj-flex-grid>
                        <app-eprx-pagination  *ngIf="HisttotalCount" [TotalCount]="HisttotalCount" [WijmoName]="Histflex" [GridName]="'VerifiedListGrid'" (OnPageSelected)="setPage($event)"
                        (OnSizeSelected)="setSize($event)" (keydown.tab)="focusToFirst($event)" [PageNumber]="pageNu" [screenTotalVerfRecords]="verfList"></app-eprx-pagination>
                                    <!--<div class="col-md-2 modal-footer text-center">
                                                            <label>Total Rx(s)</label>
                                                            <span> : {{ totalRx }} </span>
                                                        </div>
                                                        <div class="col-md-2 modal-footer text-center">
                                                            <label>Rx(s) Verified</label>
                                                            <span>
                                                                : {{ VerifiedRx }}
                                                            </span>
                                                        </div>
                                                        <div class="col-md-2 modal-footer text-center">
                                                            <label>Rx(s) Not Yet Verified
                                                            </label>
                                                            <span>
                                                                : {{ notVerifiedRx }}
                                                            </span>
                                                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-verify-info *ngIf="showRxDetails" (TriggerChangeTab)="changeTab($event)" [RxInfo]="rxInfo"></app-verify-info>
<ng-template #invalidRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)=" c('Close click')">
        <h4 header>Warning!!</h4>
        <span body>
            <div class="row">
                <div class="col">
                    <table class="table table-hover">
                        <tbody>
                            <label> {{ warningInfo }}</label>
                        </tbody>
                    </table>
                </div>
            </div>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="c('Close click')"
           appShortcutKey InputKey="o"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>

<ng-template #alreadyVerified let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click')">
        <h4 header>Confirmation</h4>
        <span body>
            Rx#: <span class="font-weight-bold">  {{ rxInfo?.Prescription?.PrescNum  &nbsp;}} </span> Refill#:
            <span class="font-weight-bold">{{ rxInfo?.PrescReFill?.ReFillNum &nbsp;}}</span>
            Fill#:
            <span class="font-weight-bold"> {{ _commonService.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription) ? rxInfo?.PrescReFill?.PartialFillNo : "  --" &nbsp;}}</span> Already verified on
            {{ (rxInfo?.drugPickVerif)[0] ?.VerifDtTm | localTime | date : "MM/dd/yyyy hh:mm:ss a" }} by
            {{ (rxInfo?.drugPickVerif)[0] ?.UserName ? ((rxInfo?.drugPickVerif)[0] ?.UserName | uppercase) : " " }}. <div><br></div> 
                 <span class="mt-1">Do you want to verify again?</span>
        </span>
        <button footer autofocus class="hotkey_primary" (keydown.enter)="checkIsDrugPickReady(rxInfo, true);c('Close click')" (click)="checkIsDrugPickReady(rxInfo, true)" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #RxDiscontinued let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span>Warning!!</span>
    </div>
    <div class="modal-body">
        <p>Rx is Discontinued. If you wish to continue the Rx will no more be marked as discontinued. Would you like to
            proceed?</p>
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
            (click)="setOrderStatus(); c('Close click')"><b>Y</b>Yes</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
            (click)="c('Close click')"><b>N</b>No</button>
    </div>
</ng-template>

<app-override-log
    *ngIf="openOverride"
    [PrivMask]="privType"
    [CatId]="categoryId"
    [OverrideEndpoint]="'POST_Rx_GetRxDetails'"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>
