<a
    (click)="selectedDrug(inputData)"
    *ngIf="!isAllToShow && !showDrugName && !showOnlyImp"
    (keydown.enter)="selectedDrug(inputData)"
    (contextmenu)="onRightClick($event)"
>
    <div class="card" [ngClass]="getDrugStatus(inputData)">
        <div class="drug-row-no">{{index+1}}</div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-9">
                    <div class="row">
                        <div
                            class="card__subTit--title pl-0"
                        >
                        <span appHighlight [searchedWords]="searchTerm ? searchTerm.split(',')[0] :''" [text]='(inputData?.drugname | uppercase) + " "'></span>
                            <!-- {{ (inputData?.drugname | uppercase) + " " }} -->
                            <span appHighlight [searchedWords]="searchTerm && searchTerm.split(',')[1] ? searchTerm.split(',')[1].trim() : null" [text]='inputData?.strength ? inputData?.strength : " "'></span>
                            <!-- {{
                                inputData?.strength
                                    ? inputData?.strength : " "
                            }} -->
                            {{
                                inputData?.drugunit
                                    ? inputData?.drugunit + " "
                                    : " "
                            }}
                            {{
                                inputData?.drugformname
                                    ? inputData?.drugformname + " "
                                    : " "
                            }}
                            {{
                                inputData?.drugtype
                                    ? (inputData?.drugtype).substr(0, 1) + " "
                                    : " "
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.NDC" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            <span appHighlight [searchedWords]="searchTerm | mask: 'AAAAA-AAAA-AA'" [text]="this._formatsUtil.getNDCFormat(inputData?.drugcode)"></span>
                            <!-- {{ inputData?.drugcode ? (inputData?.drugcode | mask: 'AAAAA-AAAA-AA') : ""}} -->
                        </div>
                        <div class="drug-price-notExist" *ngIf="checkIsPriceExist(inputData?.manageinventory)" [title]="'Drug not associated with the selected Bucket.'"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Size" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.qtypack
                                    ? (inputData?.qtypack).toFixed(3)
                                    : "0.000"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Quick_Code" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            <span appHighlight [searchedWords]="searchTerm" [text]='inputData?.quickcode && inputData?.quickcode !== "###" ? (inputData?.quickcode | uppercase) : ""'></span>
                            <!-- {{
                                inputData?.quickcode &&
                                inputData?.quickcode !== "###"
                                    ? (inputData?.quickcode | uppercase)
                                    : ""
                            }} -->
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Cost" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{getBucketSpecificPrice(inputData?.manageinventory) | currency: "$":"symbol":"1.3-3"}}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.AWP" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{(inputData?.unitpriceawp ? (inputData?.unitpriceawp | currency: "$":"symbol":"1.3-3") : "$0.000") + " ("+ (inputData?.awppack ? (inputData?.awppack | currency: "$":"symbol":"1.2-2") : "$0.00") +")"}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.MadeBy" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.manufacturername
                                    ? inputData?.manufacturername
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Inventory" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                getQtyInHand(inputData?.manageinventory)
                            }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Last_Disp" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.lastDispensedDate
                                    ? (inputData?.lastDispensedDate
                                      | datetimeUtc)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.D/CDate" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.discontdt
                                    ? (inputData?.discontdt
                                      | datetimeUtc)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.BrandName" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.drugbandname
                                    ? inputData?.drugbandname.length > 16
                                        ? inputData?.drugbandname.substr(0, 16)
                                        : inputData?.drugbandname
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.GenericName" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.genericname
                                    ? inputData?.genericname.length > 14
                                        ? inputData?.genericname.substr(0, 14)
                                        : inputData?.genericname
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{
                                "drug-result-card.IsBrand"
                                    | translate
                            }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.isbranded ? "Y" : "N"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{
                                "drug-result-card.EquivalentAvailability"
                                    | translate
                            }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.equivalentAvailability
                                    ? (inputData?.equivalentAvailability
                                      | uppercase)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{
                                "drug-result-card.GenericAvailability"
                                    | translate
                            }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.genericAvailability
                                    ? (inputData?.genericAvailability
                                      | uppercase)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>

<a (click)="selectedDrug(inputData)" *ngIf="showDrugName">
    <div class="card drug-card">
        <div class="drug-row-no">{{index+1}}</div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="card__subTit--title">
                        {{ (inputData?.drugname | uppercase) + " " }}
                        {{ inputData?.strength ? inputData?.strength : " " }}
                        {{
                            inputData?.drugunit
                                ? inputData?.drugunit + " "
                                : " "
                        }}
                        {{
                            inputData?.drugformname
                                ? inputData?.drugformname + " "
                                : " "
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="card__subTit--head">
                        {{ "drug-result-card.NDC" | translate }}
                    </div>
                    <div class="card__subTit--title">
                         {{this._formatsUtil.getNDCFormat(inputData?.drugcode)}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>

<a
    (click)="selectedDrug(inputData)"
    *ngIf="showOnlyImp"
    (keydown.enter)="selectedDrug(inputData)"
    (contextmenu)="onRightClick($event)"
>
    <div class="card" [ngClass]="getDrugStatus(inputData)">
        <div class="drug-row-no">{{index+1}}</div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-9">
                    <div class="row">
                        <div
                            class="card__subTit--title pl-0"
                        >
                            {{ (inputData?.drugname | uppercase) + " " }}
                            {{
                                inputData?.strength
                                    ? inputData?.strength
                                    : " "
                            }}
                            {{
                                inputData?.drugunit
                                    ? inputData?.drugunit + " "
                                    : " "
                            }}
                            {{
                                inputData?.drugformname
                                    ? inputData?.drugformname + " "
                                    : " "
                            }}
                            {{
                                inputData?.drugtype
                                    ? (inputData?.drugtype).substr(0, 1) + " "
                                    : " "
                            }}
                        </div>
                    </div>
                </div>

                <div class="col-3">
                    <div class="row">
                        <!-- <div class="card__subTit--head">
                            {{ "drug-result-card.NDC" | translate }}
                        </div> -->
                        <div class="card__subTit--title">
                            {{this._formatsUtil(inputData?.drugcode)}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Size" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.qtypack
                                    ? (inputData?.qtypack).toFixed(3)
                                    : "0.000"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Quick_Code" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.quickcode &&
                                inputData?.quickcode !== "###"
                                    ? (inputData?.quickcode | uppercase)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.MadeBy" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.manufacturername
                                    ? inputData?.manufacturername
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Cost" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{getBucketSpecificPrice(inputData?.manageinventory) | currency: "$":"symbol":"1.3-3"}}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.AWP" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{(inputData?.unitpriceawp ? (inputData?.unitpriceawp | currency: "$":"symbol":"1.3-3") : "$0.000") + " ("+ (inputData?.awppack ? (inputData?.awppack | currency: "$":"symbol":"1.2-2") : "$0.00") +")"}}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Inventory" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.qtyInHand
                                    ? (inputData?.qtyInHand).toFixed(3)
                                    : "0.000"
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.Last_Disp" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.lastDispensedDate
                                    ? (inputData?.lastDispensedDate
                                      | datetimeUtc)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.D/CDate" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.discontdt
                                    ? (inputData?.discontdt
                                      | datetimeUtc)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{
                                "drug-result-card.IsBrand"
                                    | translate
                            }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.isbranded ? "Y" : "N"
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.BrandName" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.drugbandname
                                    ? inputData?.drugbandname.length > 19
                                        ? inputData?.drugbandname.substr(0, 19)
                                        : inputData?.drugbandname
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{ "drug-result-card.GenericName" | translate }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.genericname
                                    ? inputData?.genericname.length > 17
                                        ? inputData?.genericname.substr(0, 17)
                                        : inputData?.genericname
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{
                                "drug-result-card.EquivalentAvailability"
                                    | translate
                            }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.equivalentAvailability
                                    ? (inputData?.equivalentAvailability
                                      | uppercase)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="row">
                        <div class="card__subTit--head">
                            {{
                                "drug-result-card.GenericAvailability"
                                    | translate
                            }}
                        </div>
                        <div class="card__subTit--title">
                            {{
                                inputData?.genericAvailability
                                    ? (inputData?.genericAvailability
                                      | uppercase)
                                    : ""
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>
